import dayjs from "dayjs";

export const getConfigDataZoom = (xAxis = []) => ({
	dataZoom: [
		{
			type: "inside"
		},
		{
			show: true,
			realtime: true,
			start: 0,
			end: xAxis.length,
			type: "slider", // inside
			textStyle: {
				fontFamily: "TFSrivichai",
				fontSize: 14,
				width: 50,
				height: 50

			},
			handleIcon:
			"M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
			handleSize: "80%",
			dataBackground: {
				areaStyle: {
					color: "#8392A5"
				},
				lineStyle: {
					opacity: 0.8,
					color: "#8392A5"
				}
			},
			handleStyle: {
				color: "#fff",
				shadowBlur: 3,
				shadowColor: "rgba(0, 0, 0, 0.6)",
				shadowOffsetX: 2,
				shadowOffsetY: 2
			},
			labelFormatter: (value, date) => {
				const startValue = dayjs(date).format("HH.mm\nD MMM YYYY");
				return startValue;
			}
		}
	],
	grid: {
		left: "48px",
		right: "74px",
		bottom: "50px",
		containLabel: true
	}
});