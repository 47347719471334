<template>
	<div :class="['module-item', { active: isActive }]">
		<div class="module-item-header" @click="toggle">
			<h2 class="module-item-name"
			>
				{{ displayOrderType }} order <span v-if="isNormalOrder">({{orderIndex}}/5)</span>
			</h2>
			<div class="module-item-toggle">
				<a>
					<FontAwesomeIcon :icon="['far', 'chevron-up']" />
				</a>
			</div>
		</div>
		<div class="module-item-body container">
			<div class="row container-item">
				<div class="container-item-body">
					<div
						v-if="isDelete"
						class="item item-delete-order"
						@click="$emit('onDelete')"
					>
						<FontAwesomeIcon :icon="['far', 'minus-circle']" />
						Delete this order
					</div>

					<div class="item item-title">
						<strong>
							Product information
						</strong>
					</div>


					<div class="item">
						<div class="label">
							Ship-to <span class="icon-require">*</span>
						</div>
						<div class="form form-ship-to">
							<BaseDropdown
								rounded
								:list="forms.shipToOptions"
								v-model="forms.shipTo"
								:dropdownListContainerHeight="150"
								placeholder="Please select a ship-top"
								@input="handleShipToSelected"
							/>
						</div>
					</div>

					<div class="item">
						<div class="label">
							Product <span class="icon-require">*</span>
						</div>
						<div class="form form-product">
							<BaseDropdown
								rounded
								:list="forms.productOptions"
								:value="forms.product"
								:dropdownListContainerHeight="150"
								:disabled="!hasShipTo"
								placeholder="Please select a product"
								@input="handleProductSelected"
							/>
						</div>
					</div>

					<div class="item item-tank-no">
						<div class="label">
							Tank no. <span class="icon-require">*</span>
						</div>
						<div class="form form-tank-no">
							<BaseDropdown
								rounded
								:list="forms.tankNoOptions"
								:value="forms.tankNo"
								:dropdownListContainerHeight="150"
								:disabled="!hasProduct"
								placeholder="Please select a tank no."
								@input="handleTankNoSelected"
							/>
						</div>
					</div>

					<div class="item item-title">
						<strong>
							{{ displayOrderType }} order schedule information<span class="icon-require">*</span>
						</strong>
					</div>

					<div
						v-for="(schedule, index) in item.schedule"
						:key="index"
						class="item item-schedule"
					>
						<div class="label label-top">
							no.{{ index + 1 }}
						</div>
						<div class="form form-volume" >
							<BaseFormSchedule
								:order-index="order"
								:scheduleIndex="index"
								:order-type="orderType"
								:disabled="isDisabledFormSchedule"
								:item="schedule"
								:forms.sync="forms.schedule[index]"
								:is-delete="hasOneMoreFormSchedule"
								@onRemove="handleRemoveSchedule(index)"
							/>
						</div>
					</div>

					<div class="item item-btn-add-new">
						<div class="label">
						</div>
						<div class="form form-btn-add-new">
							<button
								v-if="!hasMaximumFormSchedule"
								class="btn-add-new"
								@click="handleAddSchedule"
							>
								<div
									:class="['add-schedule', { 'btn-disabled': isErrorFormSchedule }]"
								>
									<FontAwesomeIcon
										:icon="['far', 'plus-circle']"
									/>
									Add new schedule
									<span class="text-limit" v-if="isNormalOrder">
										(max {{ maximumSchedule }})
									</span>
								</div>
							</button>
						</div>
					</div>

					<div class="item item-po-no">
						<div class="label">
							PO No.
						</div>
						<div class="form form-po-no">
							<BaseFormInput
								class="input-po-no"
								v-model="forms.poNo"
								placeholder="Enter PO no."
								rounded
							/>
						</div>
					</div>

					<div class="item">
						<div class="label label-top">
							Note
						</div>
						<div class="form form-note">
							<BaseTextArea
								v-model="forms.note"
								:placeholder="`Note`"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseFormSchedule from "@/components/eOrdering/formGroup/BaseFormSchedule.vue";
import {
	getEOrderingRestrictions,
	getEOrderingShipToAPI,
	getEOrderingDevicesAPI
} from "../../services/api/eOrdering.api";
import { mapUnit } from "../../selectors/helpers/mapUnit";
import { ORDER, ORDER_TYPE } from "../../enums/eOrdering/order";

export default {
	name: "ModuleCreateOrder",

	components: {
		BaseTextArea,
		BaseFormSchedule
	},

	props: {
		item: {
			type: Object,
			default: () => ({})
		},
		order: {
			type: Number,
			default: 0
		},
		isDelete: {
			type: Boolean,
			default: false
		},
		formLength: {
			type: Number,
			default: 0
		}
	},

	data() {
		return {
			forms: this.item,
			formObj: {
				unit: this.item.schedule[0].unit,
				minimumVolume: this.item.schedule[0].minimumVolume,
				maximumVolume: this.item.schedule[0].maximumVolume
			},
			isActive: this.order === (this.formLength - 1)
		};
	},

	computed: {
		...mapState(["eOrderingInfo"]),
		...mapState("eOrderingCreate", {
			isMaximumDate: "isMaximumDate"
		}),

		hasShipTo() {
			return this.forms.shipTo > 0;
		},

		hasProduct() {
			return this.forms.product.length > 0;
		},

		orderIndex() {
			return this.order + 1;
		},

		isErrorFormSchedule() {
			return this.forms.schedule.some((form) => this.checkRequireForm(form) === false) || this.isMaximumDate;
		},

		hasOneMoreFormSchedule() {
			return this.forms.schedule.length > 1;
		},

		hasMaximumFormSchedule() {
			return this.forms.schedule.length === this.maximumSchedule;
		},

		isDisabledFormSchedule() {
			return !this.forms.shipTo || !this.forms.product || !this.forms.tankNo;
		},

		isNormalOrder() {
			return this.$route.query.orderType !== ORDER_TYPE.BATCH;
		},

		orderType() {
			return this.$route.query.orderType;
		},

		displayOrderType() {
			return this.isNormalOrder ? "Normal" : "Lot";
		},

		maximumSchedule() {
			return this.isNormalOrder ? ORDER.MAXIMUM_CREATE_SCHEDULE : 100;
		}
	},

	created() {
		this.handleSelectShipTo();
	},

	watch: {
		forms: {
			immediate: true,
			deep: true,
			handler(obj) {
				this.$emit("update:forms", obj);
			}
		},
		formLength: {
			immediate: true,
			handler(obj) {
				this.isActive = this.order === (obj - 1);
			}
		},
		"forms.productOptions": {
			handler(productOptions) {
				if (productOptions.length === 1) {
					this.forms.product = productOptions[0].value;
					this.handleProductSelected(this.forms.product);
				}
			}
		},
		"forms.tankNoOptions": {
			handler(tankNoOptions) {
				if (tankNoOptions.length === 1) {
					this.forms.tankNo = tankNoOptions[0].value;
					this.handleTankNoSelected(this.forms.tankNo);
				}
			}
		}
	},

	methods: {
		...mapActions("eOrderingCreate", {
			removeOrder: "removeOrder"
		}),

		async toggle() {
			this.isActive = !this.isActive;
		},

		handleAddSchedule() {
			if (!this.isErrorFormSchedule) {
				this.forms.schedule.push({
					volume: undefined,
					deliveriesDate: null,
					date: null,
					dates: [],
					deliveriesDates: [],
					dateShortcut: null,
					time: "",
					selectedVolume: null,
					isUrgent: false,
					...this.formObj
				});
			}
		},

		handleRemoveSchedule(key) {
			this.forms.schedule.splice(key, 1);
		},

		clearFormSchedule(val) {
			if (val === "product") {
				this.forms.productOptions = [];
				this.forms.product = "";
				this.forms.tankNoOptions = [];
				this.forms.tankNo = "";
			}

			if (val === "tank") {
				this.forms.tankNoOptions = [];
				this.forms.tankNo = "";
			}

			this.forms.schedule = [{
				volume: 0,
				deliveriesDate: null,
				date: null,
				dateShortcut: null,
				dates: [],
				deliveriesDates: [],
				time: "",
				selectedVolume: null,
				isUrgent: false,
				unit: "Kg",
				minimumVolume: 0,
				maximumVolume: 0
			}];
			this.removeOrder({
				orderIndex: this.orderIndex
			});
		},

		async handleSelectShipTo() {
			this.forms.shipToOptions = [];
			const { data } = await getEOrderingShipToAPI();
			data.map((item) => (
				this.forms.shipToOptions.push({
					label: `${item.nameEN} (${item.key})`,
					value: item.id
				})));
		},

		async handleShipToSelected(val) {
			this.forms.shipTo = val;
			this.clearFormSchedule("product");
			const { data } = await getEOrderingDevicesAPI(val);
			const result = data.reduce((unique, value) => {
				const duplicates = unique.some((obj) => obj.productId === value.productId && obj.name === value.name);

				// if not duplicate value
				if (!duplicates) {
					unique.push(value);
				}
				return unique;
			}, []);

			result.map((item) => (
				this.forms.productOptions.push({
					label: `${item.name} (${item.productId})`,
					value: item.productId,
					productId: item.productId
				})));
		},

		async handleProductSelected(val) {
			this.forms.product = val;
			this.clearFormSchedule("tank");
			const { data } = await getEOrderingDevicesAPI(this.forms.shipTo);
			const productOption = this.forms.productOptions.find((shipTo) => shipTo.value === val);
			const filterData = data.filter((item) => item.productId === productOption.productId);

			filterData.map((item) => (
				this.forms.tankNoOptions.push({
					label: item.shipId,
					value: item.shipId,
					productId: item.productId,
					module: item.module
				})));
		},

		async handleTankNoSelected(val) {
			await this.clearFormSchedule(null);
			const tankNo = this.forms.tankNoOptions.find((tank) => tank.value === val);
			this.forms.tankNo = val;

			const { data } = await getEOrderingRestrictions({
				shipId: val
			});

			const unit = mapUnit(tankNo, this.eOrderingInfo.configuration);

			this.formObj = {
				unit,
				minimumVolume: data.minimum || 0,
				maximumVolume: data.optimum
			};

			this.forms.schedule = this.forms.schedule.map((form) => {
				return { ...form, ...this.formObj };
			});
		},

		checkRequireForm(form) {
			if (!form.volume || !form.time) {
				return false;
			}

			if (this.isNormalOrder && (!form.deliveriesDate || !form.date)) {
				return false;
			} else if (!this.isNormalOrder && (form.dates.length === 0 || form.deliveriesDates.length === 0)) {
				return false;
			}

			return true;
		}

	}
};
</script>
<style lang="scss" scoped>
.module-item {
	border-radius: 4px;
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	margin-bottom: rem(16);
	line-height: 1.5;
	margin-top: rem(24);

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-toggle
	&-toggle {
		margin-left: auto;

		a {
			width: rem(120);
			display: inline-block;
			font-weight: 600;
			text-align: right;

			svg {
				font-size: $font-16;
				margin-left: rem(10);
				position: relative;
				top: rem(3);
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(16) rem(24);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-up {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.fa-chevron-up {
			transform: rotate(180deg);
		}
	}
}

.container-item {
	padding: 0 $spacing-5 $spacing-7 $spacing-5;

	// .container-item-head
	&-head {
		@include fontSub();

		display: flex;
		justify-content: space-between;
	}

	// .container-item-body
	&-body {
		margin-top: rem(14);

		/* stylelint-disable */
		&::v-deep .container-custom-main-table .action {
			text-align: right !important;
		}
		/* stylelint-enable */

		.item {
			margin-bottom: $spacing-6;
			display: flex;

			// .item-title
			&-title {
				margin-bottom: rem(16);
				font-size: $font-20;
			}

			// .item-tank-no
			&-tank-no {
				margin-bottom: rem(32);
			}

			// .item-po-no
			&-po-no {
				margin-top: rem(-16);
			}

			// .item-schedule
			&-schedule {
				margin-bottom: 0;
			}

			// .item-delete-order
			&-delete-order {
				font-size: $font-18;
				font-weight: bold;
				color: $color-cerulean;
				align-items: center;
				justify-content: flex-end;
				cursor: pointer;

				svg {
					margin-right: rem(4);
				}
			}

			.label {
				display: flex;
				align-items: center;
				min-width: rem(130);

				// .label-top
				&-top {
					align-items: flex-start;
				}
			}

			.form {
				flex-grow: 1;

				// .form-po-no
				&-po-no {
					.input-po-no {
						width: rem(200);
					}
				}

				// .form-btn-add-new
				&-btn-add-new {
					// .btn-add-new
					.btn-add-new {
						display: flex;
						align-self: flex-start;
						line-height: 1;
						margin-bottom: rem(32);
						cursor: pointer;
						border: 0;
						background: none;

						.add-schedule {
							display: none;
							font-size: $font-18;
							font-weight: bold;
							color: $color-cerulean;
							line-height: normal;

							&:last-child {
								display: block;
							}

							&.btn-disabled {
								color: $color-light-teal;
								cursor: not-allowed;
							}

							svg {
								transform: translateY(rem(4));
							}
						}

						.text-limit {
							font-size: $font-16;
							font-weight: normal;
							font-style: italic;
							color: $color-grey-4;
							margin-left: rem(4);
						}
					}

					.label-maximum {
						display: flex;
						justify-content: flex-end;
						font-size: $font-16;
						font-weight: normal;
						font-style: italic;
						color: $color-grey-4;
						padding-bottom: rem(32);
					}
				}

				// .form-schedule
				&-schedule {
					display: flex;
					margin-top: $spacing-3;
				}

				// .form-date-time
				&-date-time {
					margin-left: rem(60);
				}
			}
		}
	}
}

.icon-require {
	margin-left: rem(4);
	color: $color-lipstick;
}
</style>
