<template>
	<BaseLayout>
		<BaseBackBtn class="page-back-btn" :to="PAGE_NAME.CUSTOMERS" label="Back to customer account"/>
		<div class="wrapper-customer-create">
			<h1 class="create-customer-title">Create customer account</h1>
			<BaseCard class="card-customer-info">
				<template v-slot:header>
					<h4>Customer information</h4>
				</template>
				<template v-slot:body>
					<BaseFormInput
						v-model="$v.name.$model"
						:inputOptions="['small']"
						:is-error="isNameError"
						:error-message="getNameErrorMessage"
						class="customer-create-input"
						label="Name *"
						placeholder="e.g. John Doe"
						inline
					/>

					<BaseFormInput
						v-model="$v.email.$model"
						:inputOptions="['small']"
						:is-error="isEmailError"
						:error-message="getEmailErrorMessage"
						class="customer-create-input"
						label="Email *"
						placeholder="e.g. sample@email.com"
						inline
						@input="customersManageClearErrorsField"
					/>

					<BaseFormInput
						v-model="$v.phone.$model"
						:inputOptions="['small']"
						:is-error="isPhoneError"
						:error-message="getPhoneErrorMessage"
						class="customer-create-input"
						label="Phone"
						placeholder="e.g. 02 345 6789"
						inline
					/>

					<BaseFormInputAutoComplete
						v-model="$v.company.$model"
						:list="getCompaniesOption"
						:value="company"
						:inputOptions="['small']"
						:is-loading="isLoadingCompany"
						:is-error="isCompanyError"
						:error-message="getCompanyErrorMessage"
						class="customer-create-input"
						label="Company Name *"
						placeholder="Select Company"
						inline
						@changed="debounceFetchCompany"
						@blur="handleFetchPermissionLists"
						@selected="handleSelectCompany"
					/>

					<div class="container-form">
						<div class="head-label system-role">
							<strong>System role and permission</strong>
						</div>
					</div>

					<BaseDropdown
						v-model="$v.systemRole.$model"
						:searchable="false"
						:list="systemRoleOptions"
						:value="systemRole"
						:is-error="isSystemRoleError"
						:error-message="getSystemRoleErrorMessage"
						class="customer-create-input"
						label="System role *"
						placeholder="Select customer's system role"
						inline
						@input="handleSelectSystemRole"
					/>

					<Permission
						v-model="permissions"
						:role="systemRole"
						:permissionMasterData="permissionMasterData"
						:disabled="!systemRole"
						:autoSelectedByRole="true"
					/>

					<hr class="line"/>

					<div class="item-checkbox-test-account">
						<div class="form">
							<BaseCheckBox
								:checked="isTestAccount"
								label="Test account"
								helperText="*Test account will not display on system monitoring"
								@handleCheck="handleIsTestAccountChange"
							/>
						</div>
					</div>
				</template>
			</BaseCard>
			<BaseCard
				v-if="systemRole !== CUSTOMER_ROLES.CONTACT"
			>
				<template v-slot:header>
					<h4>Permission</h4>
				</template>
				<template v-slot:body>
					<BaseBadgeInfomation
						class="badge-info-permission"
						title="Visibility Permission"
					>
						<template #content-body>
							<ul class="permission-list">
								<li class="permission-list-item">Your first selection will be set as user visibility level. To reset level, please <strong>clear all permission setting</strong></li>
								<li class="permission-list-item">Every selection will effect to item list in other levels.</li>
								<li class="permission-list-item">Permission can be duplicated from staff email. <span v-if="showDuplicateButton" class="view-text clickable" @click="onClickOpenModal">Duplicate permission</span></li>
							</ul>
						</template>
					</BaseBadgeInfomation>
					<BaseLoading
						v-if="isLoadingCustomerPermissions"
					/>
					<template
						v-else
					>
						<div class="form-permission-header">
							<h3 class="permission-level">
								Level
							</h3>
							<div class="permission-visibility">
								<h3 class="visibility-label">
									Visibility
								</h3>
								<BaseButton
									size="small"
									type="secondary"
									link
									@click="handleClearPermission"
								>
									Clear all permission setting
								</BaseButton>
							</div>
						</div>
						<BaseMultipleSelect
							:options="getSoldToOptions"
							:value="getSoldToValues"
							:customLabel="getLabelSoldTo"
							:disabled="isDisabledSoldTo"
							:limit="10"
							:is-error="isSoldToError"
							:error-message="getSoldToErrorMessage"
							label="Sold-to *"
							placeholder="Search and select sold-to"
							multiple
							@onSelectValue="handleSelectSoldTo"
							@onRemoveSelected="handleRemoveSoldTo"
						></BaseMultipleSelect>
						<BaseMultipleSelect
							:options="getShipToOptions"
							:value="getShipToValues"
							:customLabel="getLabelShipTo"
							:disabled="isDisabledShipTo"
							:limit="10"
							:is-error="isShipToError"
							:error-message="getShipToErrorMessage"
							label="Ship-to *"
							placeholder="Search and select ship-to"
							multiple
							defaultHeight
							@onSelectValue="handleSelectShipTo"
							@onRemoveSelected="handleRemoveShipTo"
						></BaseMultipleSelect>
						<BaseCheckBox
							:disabled="isDisabledShipTo"
							:checked="isAutoUpdateShipTo"
							class="form-checkbox"
							label="Auto update ship-to that will be added in the future"
							@handleCheck="handleCheckUpdateShipTo"
						/>
						<BaseMultipleSelect
							:options="getApplicationOptions"
							:value="getApplicationValues"
							:disabled="isDisabledApplication"
							:limit="10"
							:is-error="isApplicationError"
							:error-message="getApplicationErrorMessage"
							label="Application *"
							placeholder="Search and select application"
							multiple
							@onSelectValue="handleSelectApplication"
							@onRemoveSelected="handleRemoveApplication"
						></BaseMultipleSelect>
						<BaseMultipleSelect
							:options="getDeviceOptions"
							:value="getDeviceValue"
							:disabled="isDisableDevice"
							:limit="10"
							:is-error="isDeviceError"
							:error-message="getDeviceErrorMessage"
							label="Device *"
							placeholder="Search and select device"
							multiple
							defaultHeight
							@onSelectValue="handleSelectDevice"
							@onRemoveSelected="handelRemoveDevice"
						></BaseMultipleSelect>
						<BaseCheckBox
							:disabled="isDisableDevice"
							:checked="isAutoUpdateDevice"
							class="form-checkbox"
							label="Auto update device that will be added in the future"
							@handleCheck="handleCheckUpdateDevice"
						/>
					</template>
				</template>
			</BaseCard>
			<BaseCard class="card-password">
				<template v-slot:header>
					<h4>Password</h4>
				</template>
				<template v-slot:body>
					<div class="wrapper-password-generate">
						<BaseBadgeInfomation
							title="The password will be crypted"
						>
							<template #content-body>
								<div>
									<p class="info-password-description">After you click ‘Create an account’ button, you won’t be able to see, copy, or access to the password. Please make sure that you make a copy and inform the account owner about the new password.</p>
								</div>
							</template>
						</BaseBadgeInfomation>
						<p class="generate-password-description">Generate or type in a new password for this account. Please note that the account owner can no longer access BIG portal with the old password.</p>
						<div class="wrapper-generate-password">
							<div class="container-generate-password">
								<BaseFormInput
									v-model="$v.password.$model"
									:inputOptions="['small']"
									:is-error="isPasswordError"
									:error-message="getPasswordErrorMessage"
									ref="password"
									class="customer-create-input"
									placeholder="Generate or type in a new password"
									merge="right"
									inline
								/>
								<BaseButton
									class="generate-password-btn"
									size="medium"
									type="secondary"
									outline
									merge="left"
									@click="handleGeneratePassword"
								>
									<FontAwesomeIcon class="redo-password-icon" :icon="['far', 'redo-alt']"/>
									Generate
								</BaseButton>
							</div>
							<BaseButton
								class="copy-password-btn"
								size="small"
								type="secondary"
								link
								@click="handleCopyPassword"
							>
								<FontAwesomeIcon class="copy-password-icon" :icon="['far', 'copy']"/>
								Copy password
							</BaseButton>
						</div>
						<div>
							<BaseInfoList
								:list="infoList"
								class="password-validate-infomation"
							/>
						</div>
					</div>
				</template>
			</BaseCard>
			<div class="wrapper-confirm-create-customer">
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleCancle"
				>
					Cancel
				</BaseButton>

				<BaseButton
					size="large"
					type="primary"
					@click="handleSubmit(handleSendPayload)"
				>
					Create an account
				</BaseButton>
			</div>
		</div>
		<ModalDuplicatePermission
			:modal-name="MODAL_NAME"
			:emails="customerEmails"
			:loading="isLoadingEmailList"
			:onReset.sync="onResetFormModalDup"
			@onSubmit="handleSubmitModal"
		/>
	</BaseLayout>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import BaseInfoList from "@/components/BaseInfoList.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";
import BaseBadgeInfomation from "@/components/BaseBadgeInfomation.vue";
import { validationMixin } from "vuelidate";
import BaseButton from "@/components/BaseButton.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModalDuplicatePermission from "@/components/Staff/ModalDuplicatePermission.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import Permission from "@/components/customer/Permission.vue";
import createCustomerValidateMixin from "../../selectors/formValidate/userManagement/createCustomerValidateMixin";
import systemRoleOptions from "../../enums/customerManagement/systemRoleOptions";
import packageOptions from "../../enums/customerManagement/packageOptions";
import { CUSTOMER_ROLES } from "../../enums/customerManagement/customerRole";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { generatePassword } from "../../selectors/helpers";
import devicePermissionHandle from "../../selectors/mixins/userManagement/devicePermissionHandle";
import { getHasLowwerCase, getHasUpperCase, getHasNumber } from "../../selectors/helpers/regex";

export default {
	name: "CustomerCreate",
	mixins: [
		validationMixin,
		createCustomerValidateMixin,
		devicePermissionHandle
	],
	components: {
		BaseCard,
		BaseBadgeInfomation,
		BaseButton,
		BaseBackBtn,
		BaseMultipleSelect,
		BaseCheckBox,
		BaseInfoList,
		ModalDuplicatePermission,
		BaseLoading,
		Permission
	},
	data() {
		return {
			PAGE_NAME,
			systemRoleOptions,
			packageOptions,
			CUSTOMER_ROLES,
			MODAL_NAME: "modal",
			name: "",
			email: "",
			phone: "",
			company: "",
			isSelectCompany: false,
			systemRole: "",
			packageName: "BASIC",
			password: "",
			isTestAccount: false,
			isLoadingCompany: false,
			infoList: [
				{
					text: "8 characters minimum",
					active: false
				},
				{
					text: "1 number (0-9)",
					active: false
				},
				{
					text: "1 lowercase letter (a-z)",
					active: false
				},
				{
					text: "1 uppercase letter (A-Z)",
					active: false
				}
			],
			isLoadingEmailList: false,
			isLoadingCustomerPermissions: false,
			onResetFormModalDup: null,
			permissions: []
		};
	},
	destroyed() {
		this.clearCompanyLists();
	},
	methods: {
		...mapActions("customerManagement", {
			fetchCompaniesLists: "fetchCompaniesLists",
			sendCreateCustomer: "sendCreateCustomer",
			customersManageClearErrorsField: "customersManageClearErrorsField",
			clearCompanyLists: "clearCompanyLists"
		}),
		...mapActions("customerRolePermission", {
			getCustomerRolePermissions: "getCustomerRolePermissions"
		}),
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions({
			fetchCustomerLists: "customer/fetchCustomerLists",
			postCustomerPermission: "customerManagement/postCustomerPermission"
		}),

		handleSelectCompany({ value }) {
			this.isSelectCompany = true;
			this.clearCompanyLists();
			this.company = value;
		},

		handleSelectSystemRole(value) {
			this.systemRole = value;
		},

		handleSelectPackage(value) {
			this.packageName = value;
		},

		async handleSendPayload() {
			const customerPayload = {
				name: this.name,
				email: this.email,
				companyName: this.company,
				password: this.password,
				roleCode: this.systemRole,
				packageCode: this.packageName,
				permissions: this.computePermission,
				...(this.phone ? {
					phoneNumber: this.phone
				} : {}),
				isTestAccount: this.isTestAccount,
				rolePermissions: this.permissions
			};
			await this.sendCreateCustomer(customerPayload);
			if (this.customerManagement.customerCreateSuccess) {
				this.clearCompanyLists();
				this.$router.push({
					name: PAGE_NAME.CUSTOMERS
				});
				this.showToast({
					type: TOAST_TYPES.CUSTOMER_CREATE,
					props: { email: this.email, delay: 6000 }
				});
			}
		},

		handleGeneratePassword() {
			const randomstring = generatePassword({
				legnth: 8,
				numbers: true,
				symbols: true,
				lowercase: true,
				uppercase: true,
				exclude: "/\\\"",
				strict: true
			});
			this.password = randomstring;
		},

		handleCopyPassword() {
			const copyText = this.$refs.password.$refs.textInput;
			copyText.select();
			copyText.setSelectionRange(0, 99999); /* For mobile devices*/
			document.execCommand("copy");
		},

		handleCancle() {
			this.$router.push({
				name: PAGE_NAME.CUSTOMERS
			});
		},

		debounceFetchCompany(company) {
			this.isSelectCompany = false;
			clearTimeout(this.debounce);
			this.company = company;
			this.isLoadingCompany = true;
			this.clearPermissionList();

			if (!company) {
				this.clearCompanyLists();
			}
			this.customersManageClearErrorsField();
			this.debounce = setTimeout(async () => {
				if (company) {
					const queryCompany = this.company;
					await this.fetchCompaniesLists({ q: queryCompany });
					this.isLoadingCompany = false;
				} else {
					this.isLoadingCompany = false;
				}
			}, 600);
			const isMatchCompany = this.getCompaniesOption.filter(({ label }) => label === this.company).length === 1;
			this.isSelectCompany = isMatchCompany;
		},

		onClickOpenModal() {
			this.$modal.show(this.MODAL_NAME);
		},

		async handleSubmitModal(email = "") {
			this.$modal.hide(this.MODAL_NAME);
			this.isLoadingCustomerPermissions = true;
			await this.postCustomerPermission({ email });
			await this.fetchPermissionList({});
			await this.setInitialPermission();
			this.isAutoUpdateShipTo = this.getIsUpdateShipTo;
			this.isAutoUpdateDevice = this.getIsUpdateDeivce;
			this.isLoadingCustomerPermissions = false;
		},

		handleIsTestAccountChange(value) {
			this.isTestAccount = value;
		}
	},
	watch: {
		password(newValue) {
			this.infoList[0].active = newValue.length >= 8;
			this.infoList[1].active = getHasNumber(newValue);
			this.infoList[2].active = getHasLowwerCase(newValue);
			this.infoList[3].active = getHasUpperCase(newValue);
		}
	},
	computed: {
		...mapState(["customerManagement", "customerRolePermission"]),
		...mapState({
			customers: (state) => {
				return state.customer.data;
			}
		}),
		...mapGetters("customerManagement", {
			getCustomerSoldToValues: "getCustomerSoldToValues",
			getCustomerShipToValues: "getCustomerShipToValues",
			getCustomerApplicationValues: "getCustomerApplicationValues",
			getCustomerDeviceValues: "getCustomerDeviceValues",
			getIsUpdateShipTo: "getIsUpdateShipTo",
			getIsUpdateDeivce: "getIsUpdateDeivce",
			isAllSoldTo: "isAllSoldTo",
			isAllShipTo: "isAllShipTo",
			isAllApplication: "isAllApplication",
			isAllDevice: "isAllDevice"
		}),
		getCompaniesOption() {
			return this.customerManagement.companies;
		},
		customerEmails() {
			if (!this.email) {
				return [];
			}
			return this.customers.map((item) => {
				try {
					const arr = this.email.split("@");
					const tail = arr[1];
					if (item.email.search(tail) >= 0) {
						return item.email;
					}
					return null;
				} catch (e) {
					return null;
				}
			}).filter((val) => val);
		},
		showDuplicateButton() {
			return true;
		},
		permissionMasterData() {
			return this.customerRolePermission.data;
		}
	},
	async created() {
		this.isLoadingEmailList = true;
		await Promise.all([
			this.fetchCustomerLists({ perPage: 10000 }),
			this.getCustomerRolePermissions()
		]);
		this.isLoadingEmailList = false;
	}
};
</script>
<style lang="scss" scoped>
.wrapper-customer-create {
	padding: rem(24) 0;
}

.password-validate-infomation {
	&.container-info {
		width: rem(343);
		padding: 0;
		background-color: transparent;
	}
}

.form-permission-header {
	display: flex;
	align-items: center;

	.permission {
		&-level {
			font-size: $font-20;
			width: rem(130);
		}

		&-visibility {
			display: flex;
			justify-content: space-between;
			flex-grow: 1;

			.visibility-label {
				font-size: $font-20;
			}
		}
	}
}

.badge-info {
	&-permission {
		margin-bottom: rem(24);
	}
}

.permission-list {
	margin-left: rem(18);
	list-style-position: inside;

	&-item {
		list-style-type: disc;
	}
}

.form-checkbox {
	margin-left: rem(130);
}

.create-customer-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.system-role-info-icon {
	font-size: rem(12);
	color: $color-cerulean;
	cursor: pointer;
	margin-left: rem(6);
}

.customer-create-input {
	margin: rem(24) 0;
}

.input-group-search {
	width: 100%;

	@include desktop {
		width: 100%;

		/* stylelint-disable */
		&::v-deep .dropdown-value {
			padding: 0;
		}
		/* stylelint-enable */
	}
}

.card-password {
	margin-bottom: rem(56);
}

.card-customer-info {
	margin-top: rem(40);
}

.info-password-description {
	font-size: rem(16);
	margin: 0;
	color: $color-dark-blue-grey;
}

.generate-password-description {
	color: $color-dark-blue-grey;
	margin: rem(32) 0 rem(-8) 0;
}

.wrapper-generate-password {
	display: flex;
	align-items: center;
}

.container-generate-password {
	display: flex;
	align-items: center;
	width: rem(494);

	.btn {
		display: flex;
		align-items: center;
	}

	.generate-password-btn {
		font-size: rem(20);
		min-width: rem(150);
		justify-content: center;
		font-weight: $font-weight-bold;
	}

	.redo-password-icon {
		margin-right: rem(8);
		font-weight: $font-weight-bold;
	}
}

.copy-password-btn {
	margin-left: rem(11);
}

.redo-password-icon,
.copy-password-icon {
	font-size: rem(16);
}

.copy-password-icon {
	margin-right: rem(4);
}

.wrapper-confirm-create-customer {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}

.item-checkbox-test-account {
	display: flex;
}

.container-form {
	display: flex;

	.label {
		width: 130px;
	}

	.body {
		flex: 1;
		display: flex;
	}

	.head-label {
		font-family: $font-family-base;
		font-size: $font-18;

		&.system-role {
			font-family: $font-family-sub;
			font-size: $font-20;
		}
	}
}
</style>
