import { getGraphOptions, getApiTagNameOptions, postStandardModuleCreateAPI, getApplicationOptions } from "@/services/api/standardModule.api";
import { parseGraphOptions } from "../../../services/api/transforms/parseModules";
import {
	GRAPH_OPTIONS_REQUEST,
	GRAPH_OPTIONS_SUCCESS,
	GRAPH_OPTIONS_FAILURE,
	API_TAG_NAME_OPTIONS_REQUEST,
	API_TAG_NAME_OPTIONS_SUCCESS,
	API_TAG_NAME_OPTIONS_FAILURE,
	APPLICATION_OPTIONS_REQUEST,
	APPLICATION_OPTIONS_SUCCESS,
	APPLICATION_OPTIONS_FAILURE,
	STANDARD_MODULE_CREATE_REQUEST,
	STANDARD_MODULE_CREATE_SUCCESS,
	STANDARD_MODULE_CREATE_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		isGraphOptionsLoading: false,
		isCreateStdModuleTemplateLoading: false,
		isSucesssCreateStdModuleTemplate: false,
		isSuccessCreateStdModuleTemplateData: [],
		createTemplateId: null,
		graphOptions: [],
		apiTagNameOptions: [],
		applicationOptions: []
	},

	mutations: {
		[GRAPH_OPTIONS_REQUEST](state) {
			state.isGraphOptionsLoading = true;
			state.graphOptions = [];
		},
		[GRAPH_OPTIONS_SUCCESS](state, options) {
			state.graphOptions = options;
			state.isGraphOptionsLoading = false;
		},
		[GRAPH_OPTIONS_FAILURE](state) {
			state.graphOptions = [];
			state.isGraphOptionsLoading = false;
		},
		[API_TAG_NAME_OPTIONS_REQUEST](state) {
			state.apiTagNameOptions = [];
		},
		[API_TAG_NAME_OPTIONS_SUCCESS](state, options) {
			state.apiTagNameOptions = options.data;
		},
		[API_TAG_NAME_OPTIONS_FAILURE](state) {
			state.apiTagNameOptions = [];
		},
		[APPLICATION_OPTIONS_REQUEST](state) {
			state.applicationOptions = [];
		},
		[APPLICATION_OPTIONS_SUCCESS](state, options) {
			state.applicationOptions = options;
		},
		[APPLICATION_OPTIONS_FAILURE](state) {
			state.applicationOptions = [];
		},
		[STANDARD_MODULE_CREATE_REQUEST](state) {
			state.isCreateStdModuleTemplateLoading = true;
			state.isSucesssCreateStdModuleTemplate = false;
			state.createTemplateId = null;
			state.isSuccessCreateStdModuleTemplateData = [];
		},
		[STANDARD_MODULE_CREATE_SUCCESS](state, { status, data }) {
			state.isSucesssCreateStdModuleTemplate = status === 200 || status === 204;
			state.isCreateStdModuleTemplateLoading = false;
			state.isSuccessCreateStdModuleTemplateData = data?.devicesDuplicate || [];
			state.createTemplateId = data.id ?? null;
		},
		[STANDARD_MODULE_CREATE_FAILURE](state) {
			state.isCreateStdModuleTemplateLoading = false;
		}
	},

	actions: {
		async sendStandardTemplateCreate({ commit }, params = {}) {
			try {
				commit(STANDARD_MODULE_CREATE_REQUEST);
				const data = await postStandardModuleCreateAPI(params);
				commit(STANDARD_MODULE_CREATE_SUCCESS, data);
			} catch (error) {
				commit(STANDARD_MODULE_CREATE_FAILURE);
			}
		},

		async getGraphOptions({ commit }, params = {}) {
			try {
				commit(GRAPH_OPTIONS_REQUEST);
				const { data } = await getGraphOptions(params);
				commit(GRAPH_OPTIONS_SUCCESS, parseGraphOptions(data.data));
			} catch (error) {
				commit(GRAPH_OPTIONS_FAILURE);
			}
		},

		async getApiTagNameOptions({ commit }, params = {}) {
			try {
				commit(API_TAG_NAME_OPTIONS_REQUEST);
				const { data } = await getApiTagNameOptions(params);
				commit(API_TAG_NAME_OPTIONS_SUCCESS, data);
			} catch (error) {
				commit(API_TAG_NAME_OPTIONS_FAILURE);
			}
		},

		async getApplicationOptions({ commit }, params = {}) {
			try {
				commit(APPLICATION_OPTIONS_REQUEST);
				const { data } = await getApplicationOptions(params);
				commit(APPLICATION_OPTIONS_SUCCESS, data);
			} catch (error) {
				commit(APPLICATION_OPTIONS_FAILURE);
			}
		}
	}
};
