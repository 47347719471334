import azzureHttp from "./azureHttp";
import httpClient from "./httpClient";

const qs = require("qs");

const AZZURE_TOKEN_ENDPOINT = "/token";
const USER_STAFF = "/staffs/sessions";
const STAFF_ENDPOINT = "/staffs";
const CUSTOMER_LOGIN_ENDPOINT = "/login";
const CUSTOMER_CHANGE_PASSWORD_ENDPOINT = "/me/password";
const getQueryString = (code) => qs.stringify({
	client_id: process.env.VUE_APP_AZURE_CLIENT_ID,
	scope: "user.read.all",
	code,
	grant_type: "authorization_code",
	redirect_uri: process.env.VUE_APP_AZURE_LOGIN_CALLBACK,
	client_secret: process.env.VUE_APP_AZZURE_CLIENT_SECRET
});

const getAzzureAccessTokenAPI = (code) => azzureHttp.post(`${AZZURE_TOKEN_ENDPOINT}`, getQueryString(code));

const getStaffSessionAPI = (accessToken) => httpClient.post(`${USER_STAFF}`, { accessToken });

const getUserInfoAPI = () => httpClient.get("/me");

const getUserModulesAPI = () => httpClient.get("/me/modules");

const getAzureUserList = (params) => httpClient.get(`${STAFF_ENDPOINT}/addable`, { params });

const postStaff = (params) => httpClient.post(`${STAFF_ENDPOINT}`, params);

const getUserLogoutAPI = () => httpClient.get("/logout");

const getCheckTokenExpire = () => httpClient.get("/sessions");

const postCustomerLogin = (params) => httpClient.post(`${CUSTOMER_LOGIN_ENDPOINT}`, params);

const postCustomerChangePasswordAPI = (params) => httpClient.put(`${CUSTOMER_CHANGE_PASSWORD_ENDPOINT}`, params);

export {
	getAzzureAccessTokenAPI,
	getStaffSessionAPI,
	getUserInfoAPI,
	getUserModulesAPI,
	getAzureUserList,
	postStaff,
	getUserLogoutAPI,
	getCheckTokenExpire,
	postCustomerLogin,
	postCustomerChangePasswordAPI
};
