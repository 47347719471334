<template>
	<div>
		<BaseLoading v-if="powerKwh.isLoading" />
		<KwhChart
			:data="powerKwh.detail.data"
			:updatedAt="powerKwh.detail.updatedAt"
			:loading="powerKwh.loading"
			:empty="powerKwh.empty"
			:kwhCounter="showKwhCounter"
			@filter="handleFilterKwh"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import KwhChart from "@/components/PwrModule/KwhChart.vue";
import userPermission from "../../mixins/userPermission";
import { formatCounter } from "../../selectors/helpers/formatCounter";

export default {
	name: "meterOrder",

	mixins: [userPermission],

	components: {
		KwhChart
	},

	data() {
		return {
			id: this.$route.params.id
		};
	},

	computed: {
		...mapState(["powerKwh"]),

		showKwhCounter() {
			return formatCounter(this.powerKwh.detail.meterCounter);
		}
	},

	async created() {
		await this.getPowerKwh({ id: this.id });
		this.$emit("loaded");
	},

	methods: {
		...mapActions("powerKwh", {
			getPowerKwh: "getPowerKwh"
		}),

		handleFilterKwh(params = {}) {
			this.getPowerKwh({ id: this.id, params });
		}
	}

};
</script>