<template>
	<div :class="['container-cer', size]">
		<div class="icon">
			<svg class="image" width="31px" height="72px" viewBox="0 0 31 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<g id="⚙-Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Illustration-/-CER" transform="translate(-6.000000, -8.000000)">
						<g id="CER" transform="translate(6.000000, 8.000000)">
							<path d="M15.5,4 C18.5375661,4 21,6.46243388 21,9.5 L21,51 L21,51 L10,51 L10,9.5 C10,6.46243388 12.4624339,4 15.5,4 Z" id="Rectangle" fill="#F2F2F2"></path>
							<path d="M15.5,0.5 C10.5294373,0.5 6.5,4.52943725 6.5,9.49999884 L6.50008151,44.4963175 L6.30679251,44.6464502 C2.66960996,47.471546 0.5,51.8060413 0.5,56.5 C0.5,64.7842712 7.21572875,71.5 15.5,71.5 C23.7842712,71.5 30.5,64.7842712 30.5,56.5 C30.5,51.8064699 28.3307891,47.472327 24.6941687,44.6471969 L24.5009186,44.4970694 L24.5,9.5 C24.5,4.52943725 20.4705627,0.5 15.5,0.5 Z" id="Combined-Shape" stroke="#103332" fill-rule="nonzero"></path>
							<path d="M21,29 L21.0006243,46.3983551 C24.5750376,48.3488488 27,52.1412342 27,56.5 C27,62.8512746 21.8512746,68 15.5,68 C9.14872538,68 4,62.8512746 4,56.5 C4,52.1408271 6.42541543,48.3481403 10.0003773,46.3978086 L10,29 L21,29 Z" id="Combined-Shape" fill="#00A8E1"></path>
						</g>
					</g>
				</g>
			</svg>
		</div>
		<div class="status">
			<div class="item">
				<span class="value">
					<span>{{ value }}</span>
					<span class="degree">
						<sup>°</sup>C
					</span>
				</span>
			</div>
			<div class="item">
				<span class="unit">{{ label }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CerIcon",

	props: {
		value: {
			type: Number,
			required: true,
			default: 0
		},

		label: {
			type: String,
			required: true
		},

		size: {
			type: String,
			required: true,
			default: "small",
			validator: (value) => [
				"smallest",
				"small",
				"large"
			].includes(value)
		}
	}
};
</script>

<style lang="scss" scoped>
// smallest
$container-smallest-size: rem(64);
$image-smallest-width: rem(23);
$image-smallest-height: rem(53);

// small
$container-small-size: rem(80);
$image-small-width: rem(29);
$image-small-height: rem(66);

// large
$container-large-size: rem(144);
$image-large-width: rem(51);
$image-large-height: rem(118);

.container {
	&-cer {
		display: flex;
		justify-content: center;
		align-items: center;
		color: $color-dark-blue-grey;

		&.small {
			width: $container-small-size;
			height: $container-small-size;

			.image {
				width: $image-small-width;
				height: $image-small-height;
			}

			.status {
				margin-left: $spacing-3;

				.value {
					font-size: $font-18;
				}

				.unit {
					font-size: $font-14;
				}

				.item:first-of-type {
					margin-bottom: -$spacing-5;
				}
			}
		}

		&.smallest {
			width: $container-smallest-size;
			height: $container-smallest-size;

			.image {
				width: $image-smallest-width;
				height: $image-smallest-height;
			}

			.status {
				margin-left: $spacing-1;

				.value {
					font-size: $font-18;
				}

				.unit {
					font-size: $font-12;
				}

				.item:first-of-type {
					margin-bottom: -$spacing-5;
				}
			}
		}

		&.large {
			width: $container-large-size;
			height: $container-large-size;

			.image {
				width: $image-large-width;
				height: $image-large-height;
			}

			.status {
				margin-left: $spacing-4;

				.value {
					font-size: $font-40;
				}

				.unit {
					font-size: $font-26;
				}

				.item:first-of-type {
					margin-bottom: -$spacing-6;
				}
			}
		}

		.icon {
			display: flex;
		}

		.status {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.item {
				font-weight: 600;
			}

			.value {
				@include fontSub();

				font-weight: 600;
			}
		}
	}
}
</style>
