<template>
	<BaseLayout pageTitle="Executive Report">
		<div class="main-content">
			<div class="row">
				<form class="filter-form"  @submit.prevent="handleSubmit">
					<div v-if="companiesOptions.length !== 1"  :class="['col-md-3 input-group input-group-company', { active: isCompanyActive }]">
						<BaseDropdown
							:list="companiesOptions"
							:value="query.company"
							type="ghost"
							placeholderSearchInput="Type Company name"
							placeholder="Company name: All"
							@input="filterSoldTo"
							@active="setFocus($event)"
						/>
					</div>
					<div v-else class="title-company">{{companies[0].companyName}}</div>
					<BaseButton v-show="companiesOptions.length !== 1" size="large" type="primary" width="136px" outline rounded>Show Report</BaseButton>
				</form>
			</div>
		</div>
		<template>
			<div>
				<div v-if="powerBI.isLoading" class="loading-wrapper">
					<BaseLoading />
				</div>
				<PowerBI :data="powerBI" v-if="!powerBI.isLoading && !powerBI.isEmpty"></PowerBI>
				<BaseNoData
					v-if="!powerBI.isLoading && powerBI.isEmpty"
					class="graph"
					description="BIG portal will start showing the PowerBI <br/> right after we have information"
				/>
			</div>

		</template>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import PowerBI from "@/components/PowerBI.vue";
import { cleanParams } from "../../selectors/helpers";
import { getCompaniesPowerBIExecutiveAPI } from "../../services/api/companies";
import { getCompaniesOptions } from "../../selectors/helpers/powerBIExecutive";

export default {
	name: "PowerBIExecutive",

	components: {
		BaseLayout,
		PowerBI,
		BaseNoData: () => import("@/components/BaseNoData.vue"),
		BaseLoading: () => import("@/components/BaseLoading.vue")
	},

	data() {
		return {
			query: {
				company: Number(this.$route.query.company) || null
			},
			companies: [],
			isCompanyActive: false
		};
	},
	async created() {
		await this.fetchCompanies();
		if (this.query.company !== null) {
			await this.getPowerBIExecutiveReport(this.query.company);
		}
		this.addActivityLogExecutiveReport();
	},
	computed: {
		...mapState(["powerBI"]),
		companiesOptions() {
			return getCompaniesOptions(this.companies);
		}
	},
	methods: {
		...mapActions("powerBI", {
			getPowerBIExecutiveReport: "getPowerBIExecutiveReport",
			changeEmptyData: "changeEmptyData"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogExecutiveReport: "addActivityLogExecutiveReport"
		}),
		async fetchCompanies() {
			this.changeEmptyData();
			const companies = await getCompaniesPowerBIExecutiveAPI();
			this.companies = companies.data.data;
			// check params in link that have companyId in company dropdownlist (It paramCompanyID has't data in list must redirect to Permission Denied but no Params in Url default show first report in CompanyList)
			if (!this.companies.some((item) => item.soldTosId === this.query.company)) {
				if (this.query.company !== null) {
					window.location = "/permission?requireAuth=true";
				}
				this.query.company = this.companies[0].soldTosId || null;
			}
			this.handleChangeQuery();
		},
		setFocus(value) {
			this.isSalesActive = value;
		},
		filterSoldTo(value) {
			this.query.company = value;
		},
		handleSubmit() {
			this.handleChangeQuery();
			this.getPowerBIExecutiveReport(this.query.company);
		},
		handleChangeQuery() {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.$route.query,
				...this.query
			})));
			this.$router.push({ query }).catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-company {
	width: rem(264);

	@include desktop {
		width: rem(160);

		/* stylelint-disable */
		&::v-deep .dropdown-value {
			padding: 0;
		}
		/* stylelint-enable */
	}
}

.filter-form {
	margin-bottom: 0;
}

.title-company {
	font-size: $font-24;
	font-weight: $font-weight-bold;
}
</style>
