
import { getAirCompHistoriesAPI } from "@/services/api/airComp.api";
import { parseAirCompHistories, parseAirCompHistorieTableHeaderlist } from "../../../services/api/transforms/parseModules";
import { AIR_COMP_HISTORY_REQUEST, AIR_COMP_HISTORY_SUCCESS, AIR_COMP_HISTORY_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: parseAirCompHistories([]),
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		},
		thead: parseAirCompHistorieTableHeaderlist([])
	},

	mutations: {
		[AIR_COMP_HISTORY_REQUEST](state) {
			state.data = parseAirCompHistories([]);
			state.isLoading = true;
			state.statusCode = null;
			state.isEmpty = false;
		},
		[AIR_COMP_HISTORY_SUCCESS](state, { data, status }) {
			const response = data;
			state.data = parseAirCompHistories(response.data);
			state.isEmpty = response.data.length === 0;
			state.isLoading = false;
			state.statusCode = status;
			state.pagination = response.pagination;
			state.thead = parseAirCompHistorieTableHeaderlist(response.data[0]);
		},
		[AIR_COMP_HISTORY_FAILURE](state, error) {
			state.data = parseAirCompHistories([]);
			state.isLoading = false;
			state.statusCode = error.response.status;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchAirCompHistories({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_HISTORY_REQUEST);
				const response = await getAirCompHistoriesAPI(id, params);
				commit(AIR_COMP_HISTORY_SUCCESS, response);
			} catch (error) {
				commit(AIR_COMP_HISTORY_FAILURE, error);
			}
		}
	}
};
