import { required, url, numeric } from "vuelidate/lib/validators";
import { getvalidateMessage, checkIfValid } from "../validateHelper";
import { checkDuplicatePriorityBannerAPI } from "../../../services/api/bigStoreManagement";

export default {
	validations: {
		url: {
			format(value) {
				return url(value);
			}
		},

		priorityNumber: {
			required,
			format(value) {
				return numeric(value);
			},
			minValue(value) {
				return numeric(value) && value > 0;
			},
			async duplicate(value) {
				if (numeric(value) && value !== "") {
					// Check case edit when duplicate eg. olddata 3 fill in input 3 give validate pass.
					if (this.priorityNumberOldData !== "" && this.priorityNumberOldData !== undefined) {
						if (this.priorityNumberOldData === Number(value)) {
							return true;
						}
					}
					const { data } = await checkDuplicatePriorityBannerAPI(value);
					return !data.priorityDuplicate;
				}
				return true;
			}
		}
	},
	methods: {
		checkIfValid(name) {
			const field = this.$v[name];
			if (!field.$dirty) {
				return null;
			}

			if (!("required" in field) && !field.$error) {
				return null;
			}

			return (field.$invalid || field.$error);
		},
		validatePermissionForm() {
			this.$v.$touch();
		}
	},
	computed: {
		isUrlError() {
			const field = this.$v.url;
			return checkIfValid(field);
		},

		getUrlErrorMessage() {
			const field = this.$v.url;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "This must input URL link."
			});
		},

		isPriorityNumberError() {
			const field = this.$v.priorityNumber;
			return checkIfValid(field);
		},

		getPriorityNumberError() {
			const field = this.$v.priorityNumber;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Invalid number. Only a positive number is allowed.",
				invalidMinValueMessage: "Invalid number. Only a positive number is allowed.",
				duplicateMessage: "This priority is currently in use. Please choose another number or re-adjust priority numbers in all items."
			});
		}
	}
};