<template>
	<BaseLayout pageTitle="Alert and notification">
		<BaseLoading
			v-if="loading"
			class="wrapper-loading"
		/>
		<div v-if="!loading">
			<div class="container-action-bar">
				<div class="wrapper-button-group">
					<BaseButtonGroup
						:items="buttonGroupOption.items"
						:active="buttonGroupOption.active"
						type="secondary"
						size="small"
						@click="onClickButtonGroup"
					/>
				</div>
				<BaseButton
					size="large"
					rounded
					@click="onClickOpenModal"
				>
					Create new rule
				</BaseButton>
			</div>
			<SearchFilterRule
				v-if="!isNoLayout"
				:keywordValue="keyword"
				:typeValue="typeSelected"
				:typeLists="dropdownTypeOptions"
				:moduleValue="moduleSelected"
				:moduleLists="dropdownModuleOptions"
				:statusValue="status"
				:statuses="statuses"
				@handleSelectType="handleSelectType"
				@handleSelectModule="handleSelectModule"
				@handleKeywordChange="handleKeyWordChange"
				@handleSelectStatus="handleStatusChange"
				@handleSubmit="handleSubmitForm"
				@handleClear="handleClear"
			/>
			<div
				v-if="isShowList"
				class="wrapper-list-customisation"
			>
				<template v-for="item in getParserItem">
					<div  :key="item.id" class="customisation-item">
						<h2 class="customisation-item-layout-name">
							{{ item.name }}
						</h2>
						<div class="customisation-item-ref">
							ref ID <span class="module-type">{{ item.id }}</span>
						</div>
						<div class="customisation-item-type">
							Type <span class="module-type">{{ item.type }}</span>
						</div>
						<div class="customisation-item-module">
							Module <span class="module-type">{{ item.module }}</span>
						</div>
						<div class="customisation-item-status">
							<span class="module-type">
								<BaseBadge v-if="item.isActive" text="Active" size="small" color="success-dark"/>
								<BaseBadge v-else text="Inactive" size="small" color="grey" outline/>
							</span>
						</div>
						<div class="customisation-item-edit-section btn-link">
							<router-link
								:to="{
									name: item.routeTo,
									params:{
										id: item.id
									},
									query:{ ...item.query }
								}"
								class="edit-btn"
								active-class="active"
								exact
							>
								<font-awesome-icon
									:icon="['far', 'edit']"
									class="edit-icon"
								>
								</font-awesome-icon>
								Edit
							</router-link>
						</div>
					</div>
				</template>
			</div>

			<BaseEmpty
				v-if="isEmptyWithSearch && !isNoLayout"
				class="wrapper-empty"
				icon="search"
				wrapperColor="very-pale-green"
				iconColor="emerald"
				title="No matching search results"
				subTitle="Try adjusting your search by changing your rule name or removing filters"
			/>

			<BaseEmpty
				v-if="isNoLayout"
				class="wrapper-empty"
				icon="file-times"
				wrapperColor="very-pale-green"
				iconColor="emerald"
				title="Please create rule"
				subTitle="Alert and notification list will be displayed once you created rule"
			/>

			<BasePagination
				v-if="pagination.lastPage > 1"
				:value="pagination.currentPage"
				:totalPage="pagination.lastPage"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>

			<ModalCreateModule
				class="modal-create-rule-module"
				header="Create new rule"
				modal-name="modal-create"
				@onSubmit="handleSubmitModule"/>
		</div>
	</BaseLayout>
</template>

<script>
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseEmpty from "@/components/BaseEmpty.vue";
import ModalCreateModule from "@/components/modal/ModalCreateModule.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import SearchFilterRule from "@/components/AlertAndNotification/SearchFilterRule.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { getModuleAvailable, getTypeAvailable, getNotificationLists } from "../../services/api/notification.api";
import { parseTypes } from "../../services/api/transforms/parseAlertNoti";
import { MODULE_TYPES } from "../../enums/modules";

const {
	ALERT_AND_NOTIFICATION_CREATE,
	ALERT_AND_NOTIFICATION_LIST,
	ALERT_AND_NOTIFICATION_EDIT
} = PAGE_NAME;

export default {
	components: {
		BaseButton,
		BaseEmpty,
		ModalCreateModule,
		BaseLayout,
		SearchFilterRule,
		BaseLoading,
		BaseButtonGroup,
		BaseBadge
	},
	data() {
		return {
			loading: true,
			isNoLayout: false,
			layoutLists: [],
			moduleLists: [
				{
					value: "",
					label: "Module: All"
				}
			],
			pagination: {
				currentPage: Number(this.$route.query.page) || null,
				lastPage: 1,
				perPage: 12,
				total: 1
			},
			searchFilter: true,
			typeSelected: this.$route.query.type || "",
			moduleSelected: this.$route.query.module || "",
			keyword: this.$route.query.keyword || "",
			status: this.$route.query.isActive,
			createModuleLists: [],
			typeAvailable: [],
			buttonGroupOption: {
				active: this.$route.query.view || "shipto",
				items: [
					{
						value: "shipto",
						text: "Ship-to view",
						icon: ["far", "list-ul"]
					},
					{
						value: "rule",
						text: "Rule view",
						icon: ["far", "list-ul"]
					}
				]
			},
			statuses: [
				{ label: "Status: All", value: null },
				{ label: "Active", value: 1 },
				{ label: "Inactive", value: 0 }
			]
		};
	},

	computed: {
		isShowList() {
			return this.layoutLists.length > 0;
		},

		isEmptyWithSearch() {
			return this.layoutLists.length === 0 && this.searchFilter;
		},

		getModuleTranslation() {
			const result = this.createModuleLists.reduce((acc, next) => {
				return {
					...acc,
					[next.value]: next.label === "GenGas" ? "Gen Gas" : next.label
				};
			}, {});

			return result;
		},

		getParserItem() {
			const resultList = [];
			for (let i = 0; i < this.layoutLists.length; i++) {
				const title = this.typeAvailable.reduce((acc, cur) => {
					const _item = cur.availableModules.find((_m) => _m.module === this.layoutLists[i].module);
					if (_item) {
						acc.str = _item.displayName;
					}
					return acc;
				}, { str: "" }).str;
				const module = this.layoutLists[i].module;
				const type = this.layoutLists[i].type;
				resultList.push({
					...this.layoutLists[i],
					/* eslint-disable */
					module: this.typeAvailable.reduce((acc, cur) => {
						const item = cur.availableModules.reduce((_acc, _cur) => {
							if (_cur.module === module) {
								_acc = _cur.displayName;
							}
							return _acc;
						}, null);
						if (item) {
							acc = item;
						}
						return acc;
					}, module),
					/* eslint-enable */
					type: this.typeAvailable.reduce((acc, cur) => {
						if (cur.type === type) {
							return cur.displayName;
						}
						return acc;
					}, type),
					routeTo: ALERT_AND_NOTIFICATION_EDIT,
					query: {
						title,
						type,
						module
					}
				});
			}

			return resultList;
		},

		getSortModule() {
			const application = [];
			const moduleType = [];
			for (let i = 0; i < this.createModuleLists.length; i++) {
				if (this.createModuleLists[i].label.includes("Application")) {
					application.push(this.createModuleLists[i]);
				} else {
					moduleType.push(this.createModuleLists[i]);
				}
			}
			const mergeSort = moduleType.sort().concat(application.sort());
			const result = mergeSort.map((v) => {
				if (v.label === MODULE_TYPES.GENGAS) {
					return {
						...v,
						label: "Gen Gas"
					};
				}
				return v;
			});
			return result;
		},

		getSearchModuleLists() {
			return [
				...this.moduleLists,
				...this.getSortModule
			];
		},

		dropdownTypeOptions() {
			return this.typeAvailable.reduce((acc, cur) => {
				acc.push({
					label: cur.displayName,
					value: cur.type
				});
				return acc;
			}, []);
		},
		dropdownModuleOptions() {
			const type = this.typeAvailable.find((item) => (
				item.type === this.typeSelected
			));
			return !type ? [] : type.availableModules.map((item) => {
				return {
					label: item.displayName,
					value: item.module
				};
			});
		}
	},

	async created() {
		this.loading = true;

		const computeQuery = {
			...(this.keyword ? { keyword: this.keyword } : {}),
			...(this.typeSelected ? { type: this.typeSelected } : {}),
			...(this.moduleSelected ? { module: this.moduleSelected } : {}),
			...(this.status !== undefined && this.status !== "" ? { isActive: this.status } : {}),
			...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {})
		};
		const { data: typeAvailable } = await getTypeAvailable();
		const { data: moduleAvailable } = await getModuleAvailable();
		const { data: notificationList } = await getNotificationLists(computeQuery);

		this.layoutLists = notificationList.data;
		this.createModuleLists = moduleAvailable;
		this.typeAvailable = parseTypes(typeAvailable);

		this.pagination = {
			lastPage: notificationList.pagination.lastPage,
			perPage: notificationList.pagination.perPage,
			total: notificationList.pagination.total,
			currentPage: Number(this.$route.query.page) || 1
		};

		if ((!this.keyword && !this.typeSelected && !this.moduleSelected && (this.status !== undefined && this.status !== "")) && notificationList.data.length === 0) {
			this.isNoLayout = true;
		}

		this.loading = false;
	},

	methods: {
		handleClear() {
			this.keyword = "";
			this.typeSelected = "";
			this.moduleSelected = "";
			this.status = "";
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					view: this.$route.query.view
				}
			}).catch(() => {});
		},

		handleKeyWordChange(value) {
			this.keyword = value;
		},

		handleStatusChange(value) {
			this.status = value;
		},

		handleSelectType(value) {
			this.typeSelected = value;
		},

		handleSelectModule(value) {
			this.moduleSelected = value;
		},

		handlePageChange(value) {
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					...this.$route.query,
					page: value
				}
			});
		},

		handleSubmitForm() {
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					...this.$route.query,
					...(this.keyword ? { keyword: this.keyword } : {}),
					...(this.typeSelected ? { type: this.typeSelected } : {}),
					...(this.moduleSelected ? { module: this.moduleSelected } : {}),
					...(this.status !== undefined || this.status !== "" ? { isActive: this.status } : {}),
					page: undefined
				}
			}).catch(() => {});
		},

		onClickOpenModal() {
			this.$modal.show("modal-create", {
				typeAvailable: this.typeAvailable
			});
		},

		handleSubmitModule(form) {
			const { typeSelected, moduleSelected } = form;
			const title = this.typeAvailable.reduce((acc, cur) => {
				const _item = cur.availableModules.find((_m) => _m.module === moduleSelected);
				if (_item) {
					acc.str = _item.displayName;
				}
				return acc;
			}, { str: "" }).str;
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_CREATE,
				query: {
					title,
					type: typeSelected,
					module: moduleSelected
				}
			});
		},

		onClickButtonGroup(value) {
			this.buttonGroupOption.active = value;
			this.$emit("change", value);
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					...this.$route.query,
					keyword: undefined,
					type: undefined,
					module: undefined,
					status: undefined,
					page: undefined
				}
			}).catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.wrapper-button-group {
	display: flex;
	align-items: center;
	margin-right: rem(32);
}

.wrapper-empty {
	margin-top: rem(102);
}

.wrapper-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: rem(500);
}

.wrapper-list-customisation {
	display: flex;
	flex-direction: column;
	margin-top: rem(20);
	margin-bottom: rem(40);
}

.customisation-item {
	display: flex;
	// justify-content: space-between;
	align-items: center;
	height: rem(56);
	// width: 100%;
	padding: rem(16);
	background-color: $color-alabaster;
	border: rem(1) solid $color-silver;
	border-radius: rem(4);
	margin: rem(4) 0;

	&-layout-name {
		@include ellipsisOneline;

		// width: rem(368);
		flex: 1;
		font-weight: $font-weight-regular;
		color: $color-dark-blue-grey;
	}

	&-ref {
		min-width: rem(100);
		color: $color-grey-4;
	}

	&-type {
		min-width: rem(170);
		color: $color-grey-4;
	}

	&-module {
		min-width: rem(200);
		color: $color-grey-4;
	}

	&-status {
		min-width: rem(100);
	}

	&-edit-section {
		display: flex;
		align-items: center;
	}

	.edit-btn {
		display: flex;
		align-items: center;
		color: $color-cerulean;
		font-size: rem(16);

		&:hover {
			color: $color-azure;
		}

		&:visited {
			color: $color-cerulean;
		}
	}

	.edit-icon {
		font-size: rem(12);
	}

	.module-type {
		color: $color-dark-blue-grey;
		font-weight: $font-weight-bold;
		margin-left: rem(8);
	}
}

.modal-create-rule-module {
	/* stylelint-disable */
	& v::deep {
		.container-form {
			max-height: rem(100);
		}
	}
	/* stylelint-enable */
}

.module-pagination {
	justify-content: flex-end;
}
</style>