<template>
	<BaseLayout pageTitle="Maintenance mode">
		<BaseCard
			rounded
			class="setting-maintenance-mode"
		>
			<template #header>
				<h4>Setting maintenance mode</h4>
			</template>
			<template #body>
				<div class="setting-content">
					<div class="setting-description">
						<div class="label label-description">
							Enable maintenance mode to inform customers that your site are under maintenace
						</div>
						<div class="label label-sub-description">
							In maintenance mode only users with permission are allowed to access
						</div>
					</div>
					<BaseSwitch
						name="status"
						:checked="enabled"
						@input="handleChangeStatus"
					/>
					<div class="label label-status">{{ showStatus }}</div>
				</div>
			</template>
		</BaseCard>


		<BaseCard
			rounded
			class="description-maintenance-page"
		>
			<template #header>
				<h4>Description maintenance page</h4>
			</template>
			<template #body>
				<div class="container-form">
					<div class="item mb-0">
						<div class="label label-message">
							Message
						</div>
						<div class="form">
							<BaseTextArea
								v-model="description"
								:maxLength="240"
								:placeholder="`e.g. We apologize for any inconvenience.`"
							/>
						</div>
					</div>
				</div>
			</template>
		</BaseCard>

		<BaseCard
			rounded
			class="preview-maintenance-page"
		>
			<template #header>
				<h4>Preview</h4>
			</template>
			<template #body>
				<div class="preview-content">
					<div class="preview-form">
						<div class="preview-describe">
							<div class="describe">
								<div class="title">
									BIG Portal has been under maintenance.
								</div>
								<div class="body">
									{{ showPreviewUnderMaintenance }}
								</div>
								<div class="footer">
									<img src="/assets/images/undraw-city-life-gnpr-copy@3x.png" alt="BIG-TH">
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</BaseCard>

		<div class="wrapper-confirm-create">
			<BaseButton
				class="btn-cancel"
				size="large"
				type="primary"
				outline
				@click="handleCancle"
			>
				Cancel
			</BaseButton>

			<BaseButton
				size="large"
				type="primary"
				@click="handleSubmit"
			>
				Apply change
			</BaseButton>
		</div>

		<ModalEnableMaintenanceMode
			:modal-name="modalName"
			:status="enabled"
			class="modal-enable-maintenance-mode"
			@onCancel="handlecancelEnableMaintenanceMode"
			@onSubmit="handleEnableMaintenanceMode"
		/>
	</BaseLayout>
</template>
<script>
import { mapState, mapActions } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import ModalEnableMaintenanceMode from "@/components/modal/ModalEnableMaintenanceMode.vue";
import { TOAST_TYPES } from "../../enums/toast";
import { MODAL_NAME } from "../../enums/modal";

export default {
	components: {
		BaseLayout,
		BaseCard,
		BaseTextArea,
		BaseSwitch,
		ModalEnableMaintenanceMode
	},

	data() {
		return {
			description: "",
			enabled: false,
			modalName: MODAL_NAME.MODAL_ENABLE_MAINTENANCE_MODE
		};
	},

	async created() {
		await this.getMaintenanceStatus();
		this.enabled = this.maintenanceStatus.data.status;
		this.description = this.maintenanceStatus.data.description;
	},

	methods: {
		...mapActions("maintenanceStatus", {
			getMaintenanceStatus: "getMaintenanceStatus",
			postMaintenanceStatus: "postMaintenanceStatus"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		handleChangeStatus() {
			this.enabled = !this.enabled;
		},

		handlecancelEnableMaintenanceMode() {
			this.handleCancle();

			this.$modal.hide(MODAL_NAME.MODAL_ENABLE_MAINTENANCE_MODE);
		},

		async handleEnableMaintenanceMode() {
			try {
				const params = {
					status: this.enabled,
					description: this.description
				};

				const maintenaceMode = this.enabled ? `Maintenance mode is turn on.` : `Maintenance mode is turn off.`;

				await this.postMaintenanceStatus(params);

				this.showToast({
					type: TOAST_TYPES.LAYOUT_CUSTOMISATION,
					props: { message: maintenaceMode, delay: 6000 }
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to save", message: "Maintenance mode unable to save.", delay: 6000 }
				});
			}

			this.$modal.hide(MODAL_NAME.MODAL_ENABLE_MAINTENANCE_MODE);
		},

		handleCancle() {
			this.enabled = this.maintenanceStatus.data.status;
			this.description = this.maintenanceStatus.data.description;
		},

		handleSubmit() {
			this.$modal.show(MODAL_NAME.MODAL_ENABLE_MAINTENANCE_MODE);
		}
	},

	computed: {
		...mapState(["maintenanceStatus"]),

		showStatus() {
			const status = this.enabled ? "Enabled" : "Disable";
			return status;
		},

		showPreviewUnderMaintenance() {
			const textPreview = this.description || "We apologize for any inconvenience.";
			return textPreview;
		}
	}
};
</script>
<style lang="scss" scoped>
.setting {
	&-maintenance {
		&-mode {
			margin-top: rem(24);
		}
	}

	&-content {
		display: flex;

		.label {
			letter-spacing: rem(0.5);

			&-description {
				font-size: $font-20;
				color: $color-dark-blue-grey;
			}

			&-sub-description {
				font-size: $font-18;
				color: $color-grey-4;
			}

			&-status {
				font-size: $font-20;
				color: $color-dark-blue-grey;
				margin-left: rem(8);
			}
		}
	}

	&-description {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
}

.container-form {
	.item {
		margin-bottom: rem(24);
		display: flex;

		&.mb-0 {
			margin-bottom: 0;
		}

		.label {
			display: flex;
			align-items: center;
			min-width: rem(130);

			&.label-message {
				align-items: flex-start;
				margin-top: rem(8);
			}
		}

		.form {
			flex-grow: 1;
		}
	}
}



.preview-content {
	display: flex;
	align-items: center;
	word-break: break-word;

	.preview-form {
		position: relative;
		background-color: $color-white;
		padding: rem(40) rem(36) rem(26);
		width: rem(699);
		margin: 0 auto;
		border-radius: rem(4);
		border: solid 1px $color-silver;
		overflow: hidden;

		&::before {
			content: " ";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: rem(8);
			background-color: $color-green-haze;
		}
	}

	.preview-describe {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.describe {
			.title {
				text-align: center;
				font-family: $font-family-sub;
				font-size: $font-32;
				color: $color-dark-blue-grey;
			}

			.body {
				text-align: center;
				font-family: $font-family-sub;
				font-size: $font-24;
				font-weight: normal;
				color: $color-dark-blue-grey;
			}

			.footer {
				margin-top: rem(80);
				margin-right: rem(-36);
				margin-left: rem(-36);

				img {
					width: 100%;
				}
			}
		}
	}
}


.wrapper-confirm-create {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}
</style>
