import { getConsumptionApprovalAPI, putUpdateConsumptionApprovalApproveAPI, putUpdateConsumptionApprovalVerifyAPI } from "@/services/api/meters.api";
import {
	METER_CONSUMPTION_APPROVALS_SUCCESS,
	METER_CONSUMPTION_APPROVALS_REQUEST,
	METER_CONSUMPTION_APPROVALS_FAILURE,
	METER_CONSUMPTION_HANDLE_APPROVE_REQUEST,
	METER_CONSUMPTION_HANDLE_APPROVE_SUCCESS,
	METER_CONSUMPTION_HANDLE_APPROVE_FAILURE,
	METER_CONSUMPTION_HANDLE_VERIFY_REQUEST,
	METER_CONSUMPTION_HANDLE_VERIFY_SUCCESS,
	METER_CONSUMPTION_HANDLE_VERIFY_FAILURE,
	METER_CONSUMPTION_NOTFOUND

} from "../../types";

const CONSUMPTION_APPROVED = "Approved";
const CUNSUMPTION_PENDDING = "Pendding";
const CONSUMPTION_VERIFYING = "Verfying";
export default {
	namespaced: true,

	state: {
		data: {
			status: CUNSUMPTION_PENDDING
		},
		isLoading: false,
		isApproveLoading: false,
		isApproveSuccess: false,
		isVerifyLoading: false,
		isRequestVerifySuccess: false,
		isRequestError: false,
		errors: {
			password: ""
		},
		isEmpty: false,
		statusCode: null
	},

	getters: {
		getRequestBy(state) {
			return state.data.requestBy || "-";
		},
		getComment(state) {
			return state.data.comment || "-";
		},
		getIsVerifying(state) {
			return state.data.status === CONSUMPTION_VERIFYING;
		},
		getIsApproved(state) {
			return state.data.status === CONSUMPTION_APPROVED;
		},
		getAttechment(state) {
			return {
				adjustFormLink: state.data.adjustFormLink || false,
				powerFormLink: state.data.powerFormLink || false
			};
		},
		getPrepareBy(state) {
			if (state.data.prepareBy && state.data.prepareBy !== "null") {
				return state.data.prepareBy;
			}
			return "-";
		},
		getDateRecord(state) {
			return state.data.dtrecord;
		},
		getCounterNumber(state) {
			return state.data.xcounter;
		},
		getTotalConsumption(state) {
			return state.data.total;
		},
		getConsumptionApprovalData(state) {
			const { data } = state;
			return [
				{
					head: "Current month meter",
					base1: data.currentBase1,
					base2: data.currentBase2,
					base3: data.currentBase3,
					premium1: data.currentPremium1,
					premium2: data.currentPremium2,
					premium3: data.currentPremium3
				},
				{
					head: "Previous month meter",
					base1: data.previousBase1,
					base2: data.previousBase2,
					base3: data.previousBase3,
					premium1: data.previousPremium1,
					premium2: data.previousPremium2,
					premium3: data.previousPremium3
				},
				{
					head: "Meter Volume",
					hint: `(current month - previous month) x counter`,
					base1: data.volumeBase1,
					base2: data.volumeBase2,
					base3: data.volumeBase3,
					premium1: data.volumePremium1,
					premium2: data.volumePremium2,
					premium3: data.volumePremium3
				},
				{
					head: "Adjusted Volume",
					base1: data.adjustBase1,
					base2: data.adjustBase2,
					base3: data.adjustBase3,
					premium1: data.adjustPremium1,
					premium2: data.adjustPremium2,
					premium3: data.adjustPremium3
				},
				{
					head: "Actual consumption",
					base1: data.consumptionBase1,
					base2: data.consumptionBase2,
					base3: data.consumptionBase3,
					premium1: data.consumptionPremium1,
					premium2: data.consumptionPremium2,
					premium3: data.consumptionPremium3
				}
			];
		}
	},
	mutations: {
		[METER_CONSUMPTION_APPROVALS_REQUEST](state) {
			state.isLoading = true;
			state.statusCode = null;
		},
		[METER_CONSUMPTION_APPROVALS_SUCCESS](state, { data, status }) {
			state.isLoading = false;
			state.data = data;
			state.statusCode = status;
		},
		[METER_CONSUMPTION_APPROVALS_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.isEmpty = true;
			state.statusCode = error.response.status;
		},

		[METER_CONSUMPTION_HANDLE_APPROVE_REQUEST](state) {
			state.isApproveLoading = true;
		},

		[METER_CONSUMPTION_HANDLE_APPROVE_SUCCESS](state) {
			state.isApproveLoading = false;
			state.isApproveSuccess = true;
		},

		[METER_CONSUMPTION_HANDLE_APPROVE_FAILURE](state, errors) {
			state.isApproveLoading = false;

			state.errors = {
				...errors
			};
		},
		[METER_CONSUMPTION_HANDLE_VERIFY_REQUEST](state) {
			state.isVerifyLoading = true;
		},
		[METER_CONSUMPTION_HANDLE_VERIFY_SUCCESS](state) {
			state.isVerifyLoading = false;
			state.isRequestVerifySuccess = true;
		},

		[METER_CONSUMPTION_HANDLE_VERIFY_FAILURE](state, errors) {
			state.isVerifyLoading = false;

			state.errors = {
				...errors
			};
		},
		[METER_CONSUMPTION_NOTFOUND](state) {
			state.isLoading = true;
		},
		CLEAR_ERROR(state) {
			state.errors = {
				password: ""
			};
		}
	},

	actions: {
		async getConsumptionApproval({ commit }, { id = "" }) {
			try {
				commit(METER_CONSUMPTION_APPROVALS_REQUEST);
				const response = await getConsumptionApprovalAPI(id);
				commit(METER_CONSUMPTION_APPROVALS_SUCCESS, response);
			} catch (e) {
				const isNotFound = e.response.status === 404;
				if (isNotFound) {
					commit(METER_CONSUMPTION_NOTFOUND);
				} else {
					commit(METER_CONSUMPTION_APPROVALS_FAILURE, e);
				}
			}
		},

		async updateApproveConsumption({ commit }, { id = "", payload = {} }) {
			try {
				commit(METER_CONSUMPTION_HANDLE_APPROVE_REQUEST);
				await putUpdateConsumptionApprovalApproveAPI(id, payload);
				commit(METER_CONSUMPTION_HANDLE_APPROVE_SUCCESS);
			} catch (e) {
				const { errors } = e.response.data;
				commit(METER_CONSUMPTION_HANDLE_APPROVE_FAILURE, errors);
			}
		},

		async updateVerifyConsumption({ commit }, { id = "", payload = {} }) {
			try {
				commit(METER_CONSUMPTION_HANDLE_VERIFY_REQUEST);
				await putUpdateConsumptionApprovalVerifyAPI(id, payload);
				commit(METER_CONSUMPTION_HANDLE_VERIFY_SUCCESS);
			} catch (e) {
				const { errors } = e.response.data;
				commit(METER_CONSUMPTION_HANDLE_VERIFY_FAILURE, errors);
			}
		},

		clearError({ commit }) {
			commit("CLEAR_ERROR");
		}
	}
};
