<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<ModuleHeaderExample :data="furnaceInfo.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_FURNACE_INFO }"
						active-class="active"
						class="nav-link"
					>
						Furnace information
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="{ name:  PAGE_NAME.EX_MODULE_FURNACE_OVERVIEW }"
						active-class="active"
						class="nav-link"
					>
						Overview
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="{ name:  PAGE_NAME.EX_MODULE_FURNACE_HISTORY }"
						active-class="active"
						class="nav-link"
					>
						History
					</router-link>
				</li>
				<li class="nav-item" v-if="permissions.dashboard.exportable">
					<router-link
						:to="{ name:  PAGE_NAME.EX_MODULE_FURNACE_REPORT }"
						active-class="active"
						class="nav-link"
					>
						Report
					</router-link>
				</li>
				<li v-if="permissions.powerBI.read" class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_FURNACE_POWERBI }"
						active-class="active"
						class="nav-link"
					>
						Analysis
					</router-link>
				</li>
			</ul>
			<router-view
				@loaded="handleContentLoaded"
				:shipToName="shipToName"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import ModuleHeaderExample from "@/components/dashboard/modules/ModuleHeaderExample.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import userPermission from "../../mixins/userPermission";
import { CONFIG } from "../../enums/config";

export default {
	name: "FurnaceExampleModule",

	components: {
		ModuleHeaderExample,
		BaseLoading
	},

	data() {
		return {
			PAGE_NAME,
			id: this.$route.params.id,
			isLoading: true,
			fetchDevices: () => {}
		};
	},

	mixins: [userPermission],

	computed: {
		...mapState(["furnaceInfo", "shipTos"]),
		...mapGetters("user", {
			getProfile: "getProfile"
		}),

		breadcrumb() {
			return {
				type: {
					name: "Process"
				},
				shiped: {
					name: this.shipTos.data.name
				}
			};
		},

		shipToId() {
			return this.furnaceInfo.data?.shipToId || null;
		},

		shipToName() {
			return this.shipTos.data.name;
		}
	},

	async created() {
		await this.fetchFurnaceInfo(this.id);

		this.autoRefreshData = setInterval(async () => {
			if (!this.furnaceInfo.isAutoFecthLoading) {
				await this.autoFetchFurnaceInfo(this.id);
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		// save log for tab info only
		if (this.$route.name === PAGE_NAME.FURNACE_INFO) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: this.furnaceInfo.statusCode
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);

				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("furnaceInfo", {
			fetchFurnaceInfo: "fetchFurnaceInfo",
			clearFurnaceInfo: "clearFurnaceInfo",
			autoFetchFurnaceInfo: "autoFetchFurnaceInfo"
		}),
		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		handleContentLoaded() {
			this.fetchDevices();
		}
	},

	destroyed() {
		this.clearFurnaceInfo();
	}
};
</script>
