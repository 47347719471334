import {
	getAzureUserList,
	postStaff
} from "../../../services/api/user.api";
import {
	USER_AZURE_LIST_REQUEST,
	USER_AZURE_LIST_SUCCESS,
	USER_AZURE_LIST_FAILURE,
	USER_AZURE_LIST_CLEAR,
	INSERT_STAFF_REQUEST,
	INSERT_STAFF_SUCCESS,
	INSERT_STAFF_FAILURE,
	INSERT_STAFF_CLEAR,
	SHOW_ERROR_EMAIL_MESSAGE
} from "../../types";
import { parseUserAzureList } from "../../../services/api/transforms/parseUser";

export default {
	namespaced: true,

	state: {
		userAzureList: [],
		isLoading: false,
		isInsertSuccess: false,
		errorEmailMessage: "",
		addedEmail: ""
	},

	getters: {
		getAzureUserList: ({ userAzureList }) => userAzureList.map(({ email: label, id: value }) => {
			return { label, value };
		})
	},

	mutations: {
		[USER_AZURE_LIST_REQUEST](state) {
			state.userAzureList = [];
		},
		[USER_AZURE_LIST_SUCCESS](state, data) {
			state.userAzureList = parseUserAzureList(data);
		},
		[USER_AZURE_LIST_FAILURE](state) {
			state.userAzureList = [];
		},
		[USER_AZURE_LIST_CLEAR](state) {
			state.userAzureList = [];
		},
		[INSERT_STAFF_REQUEST](state) {
			state.isLoading = true;
			state.isInsertSuccess = false;
			state.errorEmailMessage = "";
			state.addedEmail = "";
		},
		[INSERT_STAFF_SUCCESS](state, { email }) {
			state.isLoading = false;
			state.isInsertSuccess = true;
			state.errorEmailMessage = "";
			state.addedEmail = email;
		},
		[INSERT_STAFF_FAILURE](state, statusCode) {
			state.isLoading = false;
			state.isInsertSuccess = false;
			state.addedEmail = "";
			if (statusCode === 409) {
				state.errorEmailMessage = "This email already exists in BIG portal";
			}
		},
		[INSERT_STAFF_CLEAR](state) {
			state.isLoading = false;
			state.isInsertSuccess = false;
			state.errorEmailMessage = "";
			state.addedEmail = "";
		},
		[SHOW_ERROR_EMAIL_MESSAGE](state, message) {
			state.errorEmailMessage = message;
		}
	},

	actions: {
		async getAzureUserList({ commit }, params) {
			try {
				commit(USER_AZURE_LIST_REQUEST);

				const { data } = await getAzureUserList(params);

				commit(USER_AZURE_LIST_SUCCESS, data);
			} catch (error) {
				commit(USER_AZURE_LIST_FAILURE);
			}
		},

		clearAzureList({ commit }) {
			commit(USER_AZURE_LIST_CLEAR);
		},

		async postStaff({ commit }, data = {}) {
			try {
				commit(INSERT_STAFF_REQUEST);

				await postStaff(data);

				commit(INSERT_STAFF_SUCCESS, { email: data.email });
			} catch (error) {
				let statusCode;

				if (error.response !== undefined) {
					statusCode = error.response.status;
				}

				commit(INSERT_STAFF_FAILURE, statusCode);
			}
		},

		clearState({ commit }) {
			commit(INSERT_STAFF_CLEAR);
		},

		setErrorEmailMessage({ commit }, message) {
			commit(SHOW_ERROR_EMAIL_MESSAGE, message);
		}
	}
};
