<template>
	<modal :name="modalName" :scrollable="true" width="420" height="auto">
		<BaseModalContent>
			<template v-slot:header>
				Duplicate permission
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template #body>
				<BaseLoading
					v-if="loading"
				/>
				<div
					v-else
					:class="['container', { active: isOpenDropdown }]"
				>
					<div class="form">
						<div class="label">
							Email *
						</div>
						<BaseDropdown
							:list="emailOptions"
							:value="email"
							:searchable="true"
							:dropdownListContainerHeight="85"
							placeholder="Please select an email"
							@input="handleSelectEmail"
							@open="handleOpenDropdown"
							@close="handleCloseDropdown"
						/>
					</div>
				</div>
			</template>
			<template #footer>
				<BaseButton
					:disabled="loading || !email"
					size="large"
					@click="handleSubmit"
				>
					Apply
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";
import BaseLoading from "@/components/BaseLoading.vue";

export default {
	name: "ModalSetupTime",

	components: {
		BaseModalContent,
		BaseLoading
	},

	props: {
		modalName: {
			type: String,
			required: true
		},
		emails: {
			type: Array,
			default: () => ([])
		},
		loading: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			email: null,
			isOpenDropdown: false
		};
	},

	computed: {
		emailOptions() {
			return this.emails.map((email) => {
				return {
					label: email,
					value: email
				};
			});
		}
	},

	created() {
		this.$emit("update:onReset", this.reset);
	},

	methods: {
		handleSubmit() {
			this.$emit("onSubmit", this.email);
		},
		handleSelectEmail(value = "") {
			this.email = value;
		},
		handleOpenDropdown() {
			this.isOpenDropdown = true;
		},
		handleCloseDropdown() {
			this.isOpenDropdown = false;
		},
		reset() {
			this.email = null;
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	&.active {
		min-height: rem(80);
	}

	.form {
		display: flex;

		.label {
			width: rem(90);
			display: flex;
			align-items: center;
		}
	}
}
</style>