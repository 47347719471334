import { mappingTankLabel } from "../../enums/layoutCustomisation/tank";


export const parseTankOverview = ({ layout }) => {
	const keysArr = Object.keys(layout?.overview ?? {});
	const computeOverview = [];

	keysArr.forEach((_, i) => {
		computeOverview.push({
			key: keysArr[i],
			name: keysArr[i],
			title: mappingTankLabel[keysArr[i]],
			value: layout.overview[keysArr[i]],
			field: layout.hasOwnProperty("overviewField") && layout.overviewField.hasOwnProperty([`${keysArr[i]}Field`])
				? layout.overviewField[`${keysArr[i]}Field`]
				: null,
			unit: layout.hasOwnProperty("overviewField") && layout.overviewField.hasOwnProperty([`${keysArr[i]}FieldUnit`])
				? layout.overviewField[`${keysArr[i]}FieldUnit`]
				: null,
			show: true
		});
	});

	return computeOverview;
};

export const getConfigTankOverview = (overviewLists = []) => {
	const overview = {};
	const overviewField = {};

	overviewLists.forEach((_, i) => {
		if (overviewLists[i].show) {
			overview[overviewLists[i].key] = overviewLists[i].value;
			overviewField[`${overviewLists[i].key}Field`] = (overviewLists[i].field && overviewLists[i].field.trim()) || "";
			overviewField[`${overviewLists[i].key}FieldUnit`] = (overviewLists[i].unit && overviewLists[i].unit.trim()) || "";
		}
	});

	return {
		overview: { ...overview },
		overviewField: { ...overviewField }
	};
};
