import { getBhyDeliverieAPI } from "@/services/api/bulkHydrogen.api";
import {
	BHY_DELIVERIE_REQUEST,
	BHY_DELIVERIE_SUCCESS,
	BHY_DELIVERIE_FAILURE
} from "../../types";
import { transformDataBarChart } from "../../../selectors/transform/barChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getBhyMonthlyData: (state) => {
			const options = { dateReportFormat: "year" };
			const result = transformDataBarChart(state, options);
			return result;
		}
	},

	mutations: {
		[BHY_DELIVERIE_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BHY_DELIVERIE_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[BHY_DELIVERIE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getBhyDeliverie({ commit }, { id = "" }) {
			try {
				commit(BHY_DELIVERIE_REQUEST);

				const { data = [] } = await getBhyDeliverieAPI(id);

				commit(BHY_DELIVERIE_SUCCESS, data);
			} catch (error) {
				commit(BHY_DELIVERIE_FAILURE);
			}
		}
	}
};
