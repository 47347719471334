<template>
	<div class="module-item">
		<div class="module-item-header">
			<h2 class="module-item-name">{{ item.templateName }}</h2>
			<div class="module-item-edit">
				<span class="delete" @click="handleShowModal(item.id, item.templateName)"><TrashIcon></TrashIcon></span>
				<span @click="handleRouteToStandardModuleEdit(item.id)"><PenToSquareIcon></PenToSquareIcon>Edit</span>
			</div>
		</div>
	</div>
</template>

<script>
import PenToSquareIcon from "@/components/icon/PenToSquareIcon.vue";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	name: "StandardModuleListItem",

	components: {
		PenToSquareIcon,
		TrashIcon
	},

	props: {
		item: {
			type: Object,
			default: null
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	methods: {
		handleRouteToStandardModuleEdit(id) {
			this.$router.push({
				name: PAGE_NAME.STANDARD_MODULE_EDIT,
				params: {
					id
				}
			});
		},
		handleShowModal(id, templateName) {
			this.$emit("handleModal", { id, templateName });
		}
	}
};
</script>

<style lang="scss" scoped>
.module-item {
	border-radius: rem(8);
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	margin-bottom: rem(16);
	line-height: 1.5;
	overflow: hidden;

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-label
	&-label {
		font-size: $font-18;
		font-weight: 400;
		color: $color-gunmetal-two;
		margin-right: rem(8);
	}

	// .module-item-edit
	&-edit {
		display: flex;
		flex-direction: row;
		margin-left: auto;

		.delete {
			margin-right: rem(24);
		}

		span {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			cursor: pointer;
			color: $color-cerulean;
			font-weight: $font-weight-bold;
		}

		svg {
			margin: 2px rem(8) 0 0;
			fill: $color-cerulean;
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 100%;
		padding: rem(20) rem(16);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}
</style>
