import { getStandardModuleByDevice } from "@/services/api/standardModule.api";
import { parseStandModulePage } from "../../../services/api/transforms/parseModules";
import { STANDARD_MODULE_PAGE_REQUEST, STANDARD_MODULE_PAGE_SUCCESS, STANDARD_MODULE_PAGE_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false
	},

	mutations: {
		[STANDARD_MODULE_PAGE_REQUEST](state) {
			state.isLoading = true;
			state.data = {};
		},
		[STANDARD_MODULE_PAGE_SUCCESS](state, data) {
			state.data = parseStandModulePage(data);
			state.isLoading = false;
		},
		[STANDARD_MODULE_PAGE_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		async getStandardModuleByDevice({ commit }, params = {}) {
			try {
				commit(STANDARD_MODULE_PAGE_REQUEST);
				const { data } = await getStandardModuleByDevice(params.templateId, { device_id: params.id });
				commit(STANDARD_MODULE_PAGE_SUCCESS, data);
			} catch (error) {
				commit(STANDARD_MODULE_PAGE_FAILURE);
			}
		},

		async autoGetStandardModuleByDevice({ commit }, params = {}) {
			try {
				const { data } = await getStandardModuleByDevice(params.templateId, { device_id: params.id });
				commit(STANDARD_MODULE_PAGE_SUCCESS, data);
			} catch (error) {
				commit(STANDARD_MODULE_PAGE_FAILURE);
			}
		}
	}
};
