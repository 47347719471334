<template>
	<div :class="['wrapper-multi-select', errorClass]">
		<label v-if="isShowLabel" class="multi-select-label">
			{{ label }}
		</label>
		<Multiselect
			:value="value"
			:class="[
				'custom-multiple-select',
				{ 'default-height': defaultHeight },
			]"
			:options="options"
			:multiple="multiple"
			:searchable="true"
			:placeholder="placeholder"
			:maxHeight="maxHeight"
			:option-height="32"
			:preserve-search="preserveSearch"
			:show-no-results="true"
			:tag-placeholder="tagPlaceholder"
			:taggable="taggable"
			:disabled="disabled"
			:showLabels="false"
			:customLabel="customLabel"
			:limit="limit"
			:open-direction="openDirection"
			label="name"
			trackBy="code"
			@open="handleOpenDropdown"
			@tag="handleSelectTag"
			@select="handleSelect"
			@input="handleInput"
			@remove="handleRemove"
		>
			<template #option="{ option, search }">
				<div class="custom-option">
					<font-awesome-icon
						v-if="isShowCheckCircle(option)"
						:icon="['far', 'check-circle']"
					/>
					<span class="option-label">{{ option.name|| search }}</span>
				</div>
			</template>
			<template #caret>
				<span class="dropdown-arrow">
					<font-awesome-icon :icon="['far', 'angle-down']" />
				</span>
			</template>
			<template #noResult>
				<span>No results were found.</span>
			</template>
		</Multiselect>
		<span :class="['error-message', { 'has-label': label !== '' }]" v-if="isShowErrorMessage">
			{{ errorMessage }}
		</span>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	components: { Multiselect },
	props: {
		options: {
			type: Array,
			default: () => []
		},
		isShowLabel: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		openDirection: {
			type: String,
			default: () => "auto"
		},
		preserveSearch: {
			type: Boolean,
			default: false
		},
		taggable: {
			type: Boolean,
			default: false
		},
		tagPlaceholder: {
			type: String,
			default: "Add this as new tag"
		},
		selected: {
			type: Array,
			default: () => []
		},
		multiple: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},
		limit: {
			type: Number,
			default: 10
		},
		label: {
			type: String,
			default: ""
		},
		maxHeight: {
			type: Number,
			default: () => 300
		},
		optionHeight: {
			type: String,
			default: () => "32"
		},
		defaultHeight: {
			type: Boolean,
			default: () => false
		},
		customLabel: {
			type: Function,
			default: ({ key }) => key
		},
		value: {
			type: Array,
			default: () => []
		},
		errorMessage: {
			type: String,
			default: ""
		},
		isError: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		handleSelect(value) {
			this.$emit("onSelectValue", value);
		},

		handleRemove(value) {
			this.$emit("onRemoveSelected", value);
		},

		handleInput(value) {
			this.$emit("input", value);
		},

		handleSelectTag(value) {
			this.$emit("onSelectTag", value);
		},

		isShowCheckCircle(value) {
			const selected = this.value;
			return selected.some(({ code }) => code === value.code);
		},
		handleOpenDropdown() {
			this.$emit("onOpenDropdown");
		}
	},
	computed: {
		isShowErrorMessage() {
			return this.isError && this.errorMessage && !this.disabled;
		},
		errorClass() {
			return {
				"is-error": this.isError && !this.disabled
			};
		}
	}
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.error-message {
	height: rem(20);
	position: absolute;
	bottom: rem(-20);
	font-size: $font-14;
	color: $color-red;
	font-weight: $font-weight-bold;

	&.has-label {
		margin-left: rem(130);
	}
}

.wrapper-multi-select {
	display: flex;
	position: relative;
	align-items: flex-start;
	margin: rem(20) 0;

	.multi-select-label {
		color: $color-dark-blue-grey;
		min-width: rem(130);
		padding: rem(6) 0;
	}

	&.is-error {
		.multiselect__tags {
			border-color: $color-red;
		}
	}
}



.custom-multiple-select {
	&.multiselect--active {
		.multiselect__tags {
			border-radius: rem(4);
			border-color: $color-silver;
		}

		.dropdown-arrow {
			transform: rotate(-180deg);
		}
	}

	&.multiselect--disabled {
		opacity: 1;
		border-radius: rem(4);

		.multiselect__tag {
			background: $color-light-teal;
			font-weight: $font-weight-bold;
		}

		.multiselect__tags {
			background: $color-disabled-input;
		}
	}

	&.default-height {
		.multiselect__tags {
			min-height: rem(108);
		}
	}

	.multiselect__single {
		white-space: nowrap;
		overflow: hidden;
	}

	.multiselect__content-wrapper {
		margin: rem(8) 0;
		padding: rem(8) 0;
		border: 1px solid $color-silver;
		border-radius: rem(4);
	}

	.multiselect__option {
		min-height: rem(32);
		padding: rem(8) rem(16);

		.custom-option {
			display: flex;
			align-items: center;
		}

		.option-label {
			margin-left: rem(24);
		}

		&--highlight {
			color: $color-dark-blue-grey;
			background-color: $color-ice-two;
		}

		&--selected {
			color: $color-dark-blue-grey;
			font-weight: $font-weight-regular;
			background-color: $color-white;

			&:hover {
				background-color: $color-ice-two;
			}

			.option-label {
				margin-left: rem(8);
			}
		}

		&.multiselect__option--highlight {
			&::after {
				background: transparent;
				color: $color-grey-4;
				margin-top: rem(-4);
			}
		}
	}

	.multiselect__placeholder {
		font-size: $font-18;
		color: $color-boulder;
		margin: 0;
		padding: 0;
		margin-top: rem(-2);
		margin-left: rem(5);
	}

	.multiselect__input {
		font-size: $font-18;
		background: $color-grey-1;
		padding-top: rem(2);
	}

	.multiselect__tags {
		background: $color-grey-1;
		min-height: rem(40);
		border: 1px solid $color-silver;
	}

	.multiselect__tag {
		font-size: $font-16;
		height: rem(22);
		border-radius: rem(4);
		color: $color-dark-blue-grey;
		background: $color-silver;
		margin-bottom: 0;
	}

	.multiselect__tag-icon {
		line-height: rem(22);

		&:hover {
			background: transparent;
		}

		&::after {
			font-size: rem(26);
			color: $color-gunmetal-two;
		}
	}


	.dropdown-arrow {
		display: flex;
		position: absolute;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		right: rem(16);
		transition: transform 0.3s;
	}
}
</style>