<template>
	<div :class="[mainClasses]">
		<div class="gengas-icon-details">
			<div class="gengas-value">{{ flowRate }}</div>
			<div class="gengas-unit">Nm<sup>3</sup>/hr</div>
		</div>
		<svg class="gengas-icon-svg" viewBox="0 0 100 100">
			<path
				:d="getFlowRateStyle.pathString"
				:stroke-width="strokeWidth"
				:style="getFlowRateStyle.trailPathStyle"
				class="gengas-icon-svg-bg"
				fill-opacity="0"
			></path>
			<path
				:d="getFlowRateStyle.pathString"
				:stroke-width="strokeWidth"
				:style="getFlowRateStyle.strokePathStyle"
				class="gengas-icon-svg-progress"
				fill-opacity="0"
			></path>
			<path
				:d="getFlowRateStyle.pathString"
				:stroke-width="strokeWidth"
				:style="pathPointStyles.strokePathStyle"
				class="gengas-icon-svg-point-bg"
				fill-opacity="0"
			></path>
			<path
				:d="getFlowRateStyle.pathString"
				:stroke-width="strokeWidth"
				:style="pathPointStyles.strokePathStyle"
				:class="['gengas-icon-svg-point', { active: getValueOverMaximum}]"
				fill-opacity="0"
			></path>
		</svg>
	</div>
</template>

<script>
import Numeral from "numeral";

export default {
	name: "gengasIcon",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},

		percentagePoint: {
			type: Number,
			default: 0
		},

		maxValue: {
			type: Number,
			default: 0
		},

		size: {
			type: String,
			default: "small",
			validator: (value) => {
				return [
					"smallest",
					"small",
					"large"
				].includes(value);
			}
		}
	},

	computed: {
		getPercentagePoint() {
			const percentagePoint = (this.percentagePoint * 100) / 120;

			if (this.percentagePoint > 120) {
				return 100;
			}

			if (this.percentagePoint < 0) {
				return 0;
			}

			return percentagePoint;
		},

		mainClasses() {
			return ["gengas-icon-container", this.size];
		},

		flowRate() {
			if (this.value === "N/A") {
				return "N/A";
			}
			return Numeral(this.value).format("0,0");
		},

		strokeWidth() {
			return this.type === "small" ? 6 : 8;
		},

		getFlowRateStyle() {
			// percent flowrate will default 80
			const percent = 80;
			const gapDegree = 0;
			const radius = 50 - (this.strokeWidth / 2);
			const beginPositionX = 0;
			const beginPositionY = -radius;
			const endPositionX = 0;
			const endPositionY = -2 * radius;

			const pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
			a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
			a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;

			const len = Math.PI * 2 * radius;
			const trailPathStyle = {
				strokeDasharray: `${len - gapDegree - 78}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};
			const strokePathStyle = {
				strokeDasharray: `${(percent / 100) * (len - gapDegree - 78)}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};

			return { pathString, trailPathStyle, strokePathStyle };
		},

		getValueOverMaximum() {
			return this.getPercentagePoint > 84;
		},


		pathPointStyles() {
			// const percent = this.percentagePoint;
			const gapDegree = 0;
			const radius = 50 - (this.strokeWidth / 2);
			const beginPositionX = 0;
			const beginPositionY = -radius;
			const endPositionX = 0;
			const endPositionY = -2 * radius;

			const pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
			a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
			a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;

			const len = Math.PI * 2 * radius;

			const trailPathStyle = {
				strokeDasharray: `${len - gapDegree - 78}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};
			const rotateDeg = (264 * (this.getPercentagePoint)) / 100;

			const strokePathStyle = {
				strokeDasharray: `0px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`,
				transform: `rotate(${rotateDeg}deg)`,
				transformOrigin: "center"
			};

			return { pathString, trailPathStyle, strokePathStyle };
		}
	}
};
</script>

<style lang="scss" scoped>
	.gengas-icon {
		&-container {
			position: relative;
			width: rem(73);
			height: rem(73);

			&.smallest {
				width: rem(58);
				height: rem(58);

				.gengas-value {
					font-size: $font-18;
					margin-bottom: rem(-5);
				}

				.gengas-unit {
					font-size: $font-12;
				}
			}

			&.large {
				width: rem(130);
				height: rem(130);

				.gengas-value {
					font-size: $font-40;
				}

				.gengas-unit {
					font-size: $font-26;
				}
			}
		}

		&-svg {
			width: 110%;
			height: 100%;
			transform: rotate(230deg);
			transform-origin: center;
			margin-left: -10%;

			path {
				stroke-linecap: round;
			}

			&-bg {
				stroke: $color-azure;
			}

			&-progress {
				stroke: $color-emerald;
			}

			&-point {
				stroke: $color-emerald;

				&.active {
					stroke: $color-azure;
				}

				&-bg {
					stroke-width: rem(12);
					stroke: white;
				}
			}
		}

		&-details {
			display: flex;
			justify-content: center;
			flex-direction: column;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			color: $color-dark-blue-grey;
			text-align: center;
			white-space: nowrap;
			margin-left: -5%;

			.gengas-value {
				@include fontSub();

				color: $color-dark-blue-grey;
				font-size: $font-21;
				font-weight: 600;
				line-height: 1;
				margin-bottom: rem(-2);
			}

			.gengas-unit {
				font-size: $font-14;
				font-weight: 600;
				line-height: 1;
			}
		}
	}
</style>
