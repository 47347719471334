import { getShipTosAPI } from "@/services/api/shipTos.api";
import {
	SHIP_TO_REQUEST,
	SHIP_TO_SUCCESS,
	SHIP_TO_FAILURE
} from "../types";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false
	},

	getters: {
		getShiptoCompany(state) {
			return state.data.name;
		}
	},

	mutations: {
		[SHIP_TO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
		},

		[SHIP_TO_SUCCESS](state, payload) {
			state.data = payload;
			state.isLoading = false;
		},

		[SHIP_TO_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		// Get Ship to
		async getShipTo({ commit }, id) {
			try {
				commit(SHIP_TO_REQUEST);

				const { data } = await getShipTosAPI(id);

				commit(SHIP_TO_SUCCESS, data);
			} catch (error) {
				commit(SHIP_TO_FAILURE);
			}
		}
	}
};