<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:iconSize="iconSize"
	>
		<template #icon>
			<MeterIcon
				:key="data.shipId"
				:value="data.flowRate"
				:percentage="data.flowRatePercent"
				:size="iconSize"
			/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div class="container-detail-info-tag">
					<span>
						TAG No.
					</span>
					<span>
						{{ tagNo }}
					</span>
				</div>
				<div class="container-detail-info-avg">
					<span>
						M’ly Avg. Flow (Nm<sup>3</sup>/hr)
					</span>
					<span>
						{{ avgConsumptionMTD }}
					</span>
				</div>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import MeterIcon from "@/components/deviceIcons/MeterIcon.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";
import { numberFormat } from "../../../../selectors/helpers";

export default {
	name: "MeterDeviceWithCompany",

	components: {
		MeterIcon,
		BaseDeviceWithCompany
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	computed: {
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		},

		tagNo() {
			const { tagNo } = this.data;
			return tagNo !== undefined
				? tagNo
				: "-";
		},
		avgConsumptionMTD() {
			const { avgConsumptionMTD } = this.data;
			return avgConsumptionMTD !== undefined
				? numberFormat(avgConsumptionMTD)
				: "-";
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
::v-deep {
	.container-detail-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-right: rem(12);
		margin-left: rem(12);

		.wrapper-icon {
			margin-top: rem(4);
			margin-left: rem(4);
		}
	}
}
/* stylelint-enable */
.container-detail {
	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		&-tag, &-avg {
			display: flex;
			align-items: center;
			height: rem(14);
			margin-top: rem(4);
			font-size: $font-14;
			color: $color-dark-blue-grey;
			line-height: rem(16);

			span {
				&:first-of-type {
					display: flex;
					width: rem(100);

					sup {
						position: relative;
						top: rem(-3);
					}
				}

				&:last-of-type {
					flex: 1;
					font-weight: $font-weight-bold;
					text-align: right;
				}
			}
		}
	}
}
</style>