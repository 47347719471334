<template>
	<div class="group-date-time-picker">
		<div :class="['container-datepicker', 'position-right', { '-is-batch-order': !isNormalOrder  }]" v-click-outside="handleDone">
			<BaseFormInput
				:disabled="disabled"
				:readonly="true"
				:value="displayDate"
				:class="['custom-input', 'has-icons-left', 'medium']"
				type="text"
				iconLeft="calendar"
				:placeholder="placeholder"
				@click="handleInputClick"
			/>
			<div v-if="isShowDatePicker" class="container-datepicker-control">
				<div class="container-datepicker-control-inner" @click="isClickedDate = true">
					<div v-if="false" class="over-lay-disbled"></div>
					<v-date-picker
						v-if="isNormalOrder"
						color="green"
						:disabled="disabled"
						v-model="form.date"
						:min-date="minDate"
						:popover="{ placement: 'bottom', visibility: 'click' }"
						title-position="left"
						locale="en"
						is-inline
						is-expanded
						:mode="mode"
						class="custom-date-picker"
						@change="handleDoneDateRange"
					/>
					<v-calendar
						v-else
						color="green"
						:min-date="minDate"
						:attributes="attributes"
						@dayclick="handleDayClick"
					/>
					<div v-if="!isNormalOrder" class="container-datepicker-control-slider">
						<div class="container-datepicker-control-slider-select">
							<button
								v-for="(item, index) in dateShortcutOptions"
								:key="index"
								:class="['btn', {'active': item.value === form.dateShortcut}]"
								@click="handleClickOptionDate($event, item)"
							>
								{{ item.text }}
							</button>
						</div>
					</div>
				</div>
				<div class="container-datepicker-control-footer">
					<div class="control-footer-dropdown">
						<h4 class="title">
							Select delivery time <span class="icon-require">*</span>
						</h4>
						<BaseDropdown
							:value="form.timeType"
							:list="timeTypeOptions"
							:searchable="false"
							placeholder="Select time"
							class="container-dropdown"
							@input="handleSelectTimeType"
						/>
					</div>
					<div class="actions">
						<v-date-picker
							v-if="isShowTimePicker"
							is24hr color="green"
							mode="time"
							v-model="form.time"
						/>
						<BaseButton
							class="custom-done-btn"
							size="medium"
							@click="handleDone">
							Done
						</BaseButton>
					</div>
				</div>
			</div>
		</div>
		<ModalMaximumNumberOfOrder
		/>
	</div>
</template>
<script>
import dayjs from "dayjs";
import { mapState, mapGetters, mapActions } from "vuex";

import BaseDropdown from "@/components/BaseDropdown.vue";
import ModalMaximumNumberOfOrder from "@/components/eOrdering/modal/ModalMaximumNumberOfOrder.vue";

import { DISPLAY_TIME, TIME_TYPE, TIME_VALUE } from "../../../enums/eOrdering/timeType";
import { ORDER, ORDER_TYPE } from "../../../enums/eOrdering/order";

export default {
	name: "BaseFormDateTimePicker",

	props: {
		valueDates: {
			type: Array,
			default: () => []
		},
		valueDateShortcut: {
			type: [String, null],
			default: null
		},
		valueDate: {
			type: [Date, String],
			default: null
		},
		valueTime: {
			type: [Date, String],
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		valueTimeType: {
			type: String,
			default: ""
		},
		orderType: {
			type: String,
			default: ORDER_TYPE.NORMAL
		},
		orderIndex: {
			type: Number,
			default: 0
		},
		scheduleIndex: {
			type: Number,
			default: 0
		}
	},

	components: {
		BaseDropdown,
		ModalMaximumNumberOfOrder
	},

	data() {
		return {
			isShowDatePicker: false,
			placeholder: "Delivery date and time",
			minDate: dayjs().subtract(0, "day").toDate(),
			form: {
				dateShortcut: this.valueDateShortcut,
				dates: this.valueDates,
				date: !this.valueDate ? null : dayjs(this.valueDate).toDate(),
				time: this.valueTime || "",
				timeType: this.valueTimeType
			},
			timeTypeOptions: [
				{
					label: "Morning",
					value: TIME_TYPE.MORNING
				},
				{
					label: "Afternoon",
					value: TIME_TYPE.AFTERNOON
				},
				{
					label: "Nighttime",
					value: TIME_TYPE.NIGHTTIME
				},
				{
					label: "Specific time",
					value: TIME_TYPE.SPECIFIC_TIME
				}
			],
			mode: this.orderType === ORDER_TYPE.NORMAL ? "single" : "multiple",
			dateShortcutOptions: [
				{
					text: "Custom",
					value: null
				},
				{
					text: "Every day",
					value: "everyDay"
				},
				{
					text: "Working day",
					value: "workingDay"
				}
			]
		};
	},

	computed: {
		...mapState("eOrderingCreate", {
			countSelectDate: "countSelectDate"
		}),

		...mapGetters("eOrderingCreate", {
			countSelectedDateWithoutCurrent: "countSelectedDateWithoutCurrent"
		}),

		displayDate() {
			const time = dayjs(this.form.time).format("HH:mm");
			const displayTime = DISPLAY_TIME[time];

			const displaySelectTime = this.form.time ? displayTime || time : "";

			if (this.isNormalOrder) {
				return this.form.date ? `${dayjs(this.form.date).format("DD/MM/YYYY")}, ${displaySelectTime}`
					: "";
			}

			const displayDateShortcut = {
				everyDay: "Every day",
				workingDay: "Working day"
			};

			return this.form.dates.length > 0 ? `${displayDateShortcut[this.form.dateShortcut] || "Custom"}, ${displaySelectTime}` : "";
		},

		isShowTimePicker() {
			return this.form.timeType === TIME_TYPE.SPECIFIC_TIME;
		},

		dates() {
			return this.form.dates.map((day) => day.date);
		},

		attributes() {
			return this.dates.map((date) => ({
				highlight: true,
				dates: date
			}));
		},

		isNormalOrder() {
			return this.orderType === ORDER_TYPE.NORMAL;
		}

	},

	watch: {
		valueDate(value) {
			this.form.date = value;
		},
		valueTime(value) {
			this.form.time = value;
		},
		valueDateShortcut(value) {
			this.form.valueDateShortcut = value;
		},
		valueDates(value) {
			this.form.dates = value;
		},
		valueTimeType(value) {
			this.form.timeType = value;
		}
	},

	methods: {
		...mapActions("eOrderingCreate", {
			selectedDatesChange: "selectedDatesChange"
		}),

		handleDone() {
			if (this.isShowDatePicker) {
				this.handleInputBlur();
			}
			this.hideDatePicker();
		},
		hideDatePicker() {
			this.isShowDatePicker = false;
		},

		handleInputClick() {
			this.isShowDatePicker = !this.isShowDatePicker;
		},

		handleDoneDateRange(date) {
			this.form.date = date;
			this.handleInputBlur();
		},

		handleInputBlur() {
			this.$emit("blur", this.form);
		},

		handleSelectTimeType(timeType) {
			this.form.timeType = timeType;
			const date = new Date().toDateString();
			const time = {
				[TIME_TYPE.MORNING]: new Date(`${date} ${TIME_VALUE.MORNING}`),
				[TIME_TYPE.AFTERNOON]: new Date(`${date} ${TIME_VALUE.AFTERNOON}`),
				[TIME_TYPE.NIGHTTIME]: new Date(`${date} ${TIME_VALUE.NIGHTTIME}`),
				[TIME_TYPE.SPECIFIC_TIME]: ""
			};

			this.form.time = time[timeType];
		},

		handleDayClick(day) {
			let tempDates = [...this.form.dates];

			const idx = this.form.dates.findIndex((d) => d.id === day.id);

			// filter only month of current day
			tempDates = tempDates.filter((date) => {
				return day.date.getMonth() === date.date.getMonth();
			});

			if (idx >= 0) {
				tempDates.splice(idx, 1);
			} else {
				tempDates.push({
					id: day.id,
					date: day.date
				});
			}

			const orderScheduleKey = `order${this.orderIndex}${this.scheduleIndex}`;

			if (tempDates.length + this.countSelectedDateWithoutCurrent(orderScheduleKey) > ORDER.MAXIMUM_CREATE_BATCH_DATE) {
				return;
			}

			this.form.dateShortcut = null;
			this.selectedDatesChange({
				dates: tempDates,
				orderScheduleKey
			});
			this.form.dates = tempDates;
		},

		handleClickOptionDate(_, item) {
			this.isShowDatePicker = false;
			this.form.dateShortcut = item.value;
			this.isClickedDate = false;
			const orderScheduleKey = `order${this.orderIndex}${this.scheduleIndex}`;

			switch (this.form.dateShortcut) {
				case null: {
					this.form.dates = [];
					break;
				}
				case "everyDay": {
					const everyDatesOfMonth = this.getEveryDatesOfMonth();
					const countNextDates = this.countSelectedDateWithoutCurrent(orderScheduleKey) + everyDatesOfMonth.length;
					if (countNextDates > ORDER.MAXIMUM_CREATE_BATCH_DATE) {
						this.$modal.show("modal-maximum-number-of-order");
						const countAllowDate = ORDER.MAXIMUM_CREATE_BATCH_DATE - this.countSelectedDateWithoutCurrent(orderScheduleKey);
						this.form.dates = everyDatesOfMonth.slice(0, countAllowDate);
					} else {
						this.form.dates = everyDatesOfMonth;
					}
					this.selectedDatesChange({
						dates: this.form.dates,
						orderScheduleKey
					});
					break;
				}
				case "workingDay": {
					const workingDatesOfMonth = this.getWorkingDatesOfMonth();
					const countNextDates = this.countSelectedDateWithoutCurrent(orderScheduleKey) + workingDatesOfMonth.length;
					if (countNextDates > ORDER.MAXIMUM_CREATE_BATCH_DATE) {
						this.$modal.show("modal-maximum-number-of-order");
						const countAllowDate = ORDER.MAXIMUM_CREATE_BATCH_DATE - this.countSelectedDateWithoutCurrent(orderScheduleKey);
						this.form.dates = workingDatesOfMonth.slice(0, countAllowDate);
					} else {
						this.form.dates = workingDatesOfMonth;
					}
					this.selectedDatesChange({
						dates: this.form.dates,
						orderScheduleKey
					});
					break;
				}
				default:
			}

			setTimeout(() => {
				this.isShowDatePicker = true;
			}, 10);
		},

		getEveryDatesOfMonth() {
			const now = new Date();
			const lastDateOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

			return Array((lastDateOfMonth.getDate() - now.getDate()) + 1).fill({}).map((_, index) => {
				const currentDate = new Date();
				currentDate.setDate(now.getDate() + index);

				return {
					id: dayjs(currentDate).format("YYYY-MM-DD"),
					date: currentDate
				};
			});
		},

		getWorkingDatesOfMonth() {
			return this.getEveryDatesOfMonth().filter((day) => {
				return ![0, 6].includes(day.date.getDay());
			});
		}

	}
};
</script>
<style lang="scss" scoped>
.group-date-time-picker {
	display: flex;

	/* stylelint-disable */
	.custom-date-picker,
	.custom-time-picker {
		&::v-deep {
			.input {
				height: rem(40);
				background-color: $color-grey-1;
			}

			.max-length-message,
			.container-icon-right {
				display: none;
			}

			.control.max-length {
				margin-bottom: 0;
			}

			.container-datepicker-control {
				bottom: rem(100);
			}

			.icon {
				z-index: 1;
			}
		}
	}

	.custom-time-picker {
		width: rem(146);
		margin-left: rem(8);

		&::v-deep {
			.icon {
				height: rem(40);
			}

			svg {
				font-size: $font-14;
			}
		}
	}
	/* stylelint-enable */

	.container-datepicker {
		position: relative;
		width: rem(236);

		&.-is-batch-order {
			.container-datepicker-control {
				width: rem(386);
			}
		}

		&-control {
			position: absolute;
			bottom: rem(100);
			z-index: 5;

			display: flex;
			flex-wrap: wrap;

			transform: translateY(100%);
			border-radius: rem($border-radius-normal);
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
			background-color: $color-white;

			&-inner {
				position: relative;
				display: flex;
				width: rem(272);


				.over-lay-disbled {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 2;
					background: rgba(255, 255, 255, 0.5);
				}

				.vc-container {
					background: none;
					border: none;

					/* stylelint-disable */
					&::v-deep .vc-day {
						&-layer {
							.vc-highlight {
								&.vc-bg-blue-600 {
									background-color: $color-green-haze;
								}

								&.vc-border-blue-700 {
									border-color: $color-green-haze;
								}

								&-base {
									&-start, &-middle, &-end {
										background-color: $color-ice;
									}
								}
							}
						}

						&-content {
							@include fontBase();

							font-weight: normal;
							font-size: $font-20;

							&:first-child {
								&:hover {
									background-color: $color-ice;
								}
							}
						}
					}

					&::v-deep .is-today .vc-day-content {
						border: 1px solid $color-green-haze;
					}

					&::v-deep .vc-pane {
						.vc-title {
							@include fontSub();

							padding-right: rem(20);
							color: $color-dark-blue-grey;
							font-size: $font-20;
							font-weight: $font-weight-bold;

							&::after {
								content: " ";
								position: absolute;
								top: 8%;
								margin-left: rem(4);
								width: 0;
								height: 0;
								border-left: rem(5) solid transparent;
								border-right: rem(5) solid transparent;
								border-top: rem(5) solid $color-cerulean;
							}
						}
					}

					&::v-deep .vc-arrows-container {
						svg {
							color: $color-cerulean;
						}
					}

					&::v-deep .vc-weekday {
						@include fontBase();

						font-size: $font-16;
						font-weight: $font-weight-bold;
						color: $color-grey-4;
					}

					&::v-deep .vc-popover-content {
						background-color: $color-white;
						color: $color-mirage;

						.vc-grid-cell {
							.vc-nav-title {
								color: $color-mirage;
							}

							span.vc-grid-focus:not(.vc-nav-title):not(.vc-nav-arrow) {
								background-color: $color-green-haze;
								color: $color-white;
								border: none;
							}

							span {
								color: $color-mirage;

								&:hover {
									background-color: $color-ice;
									color: $color-mirage;
								}
							}
						}
					}

					&::v-deep .vc-grid-cell {
						&-col {
							&-1, &-7 {
								.vc-day-content {
									color: $color-grey-4;
								}
							}

							&-2, &-3, &-4, &-5, &-6 {
								.vc-day-content {
									color: $color-dark-blue-grey;
								}
							}
						}

						.vc-day-content {
							&.vc-text-white {
								color: $color-white;
							}

							&.vc-text-gray-400 {
								color: #cbd5e0;
								cursor: not-allowed;
							}
						}
					}
					/* stylelint-enable */
				}
			}

			&-footer {
				position: relative;

				padding: rem(16);
				width: 100%;
				display: flex;
				flex-direction: column;

				.control-footer-dropdown {
					padding: rem(16);

					border-radius: $border-radius-normal;
					background-color: $color-off-white;

					.title {
						font-size: $font-20;
					}

					.icon-require {
						color: $color-red;
					}
				}

				.actions {
					align-items: center;
					display: flex;
					margin-top: rem(24);

					.custom-done-btn {
						margin-left: auto;

						width: fit-content;

						border-radius: $border-radius-normal;
					}
				}

				.container-dropdown {
					.dropdown-value {
						border-radius: $border-radius-normal;
					}
				}
			}

			&-slider {
				position: relative;

				padding: rem(16);

				&::before {
					content: "";
					position: absolute;
					bottom: 3%;
					left: 0;

					width: 1px;
					height: 94%;

					background: $color-silver;
				}

				&-select {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: column;

					.btn {
						width: 100%;
						background: none;
						border: none;
						outline: none;
						color: $color-dark-blue-grey;
						padding: rem(2) 16px;
						box-sizing: border-box;
						border-radius: rem(4);
						margin-bottom: rem(6);
						text-align: left;
						font-size: $font-18;

						&:last-of-type {
							margin-bottom: 0;
						}

						&.active {
							background-color: $color-ice;
							font-weight: $font-weight-bold;
						}
					}
				}
			}
		}

		.custom-input {
			cursor: pointer;

			&.medium {
				&::v-deep .input {
					height: rem(40);
					line-height: rem(40);
					padding: rem(2) rem(10) 0 rem(40);
				}
			}

			&::v-deep .input {
				cursor: pointer;

				&:disabled {
					cursor: not-allowed;
				}
			}

			&::v-deep .is-left {
				width: rem(40);

				svg {
					width: rem(13);
					height: rem(16);
					color: $color-dark-blue-grey;
				}
			}
		}
	}
}
</style>
