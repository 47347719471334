<template>
	<div class="navigation-service-container">
		<swiper v-if="showSlider" ref="mySwiper" :options="swiperOptions" class="service-container">
			<swiper-slide v-for="(item,index) in media"  :key="`Service-${index}`">
				<div class="service-card">
					<div class="header-content" :style="{ backgroundColor: item.color }" :title="item.title"><span>{{item.title}}</span></div>
					<div class="image-content">
						<img :src="item.img">
					</div>
					<div class="app-content" :title="item.headHighlightTitle">{{item.headHighlightTitle}}</div>
					<div class="detail-content" :title="item.descriptions">{{item.descriptions}}</div>
					<div class="navigate-content">
						<BaseButton
							class="btn-cancel"
							size="small"
							type="primary"
							outline
							@click="handleSeeDetail(item.type, item.deviceId, item.id, item.productId, item.isStandardModule, item.templateId)"
						>
							See Details
						</BaseButton>
					</div>
				</div>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<div
			slot="button-next"
			class="swiper-button-next swiper-next-service swiper-button-black"
		></div>
		<div
			slot="button-prev"
			class="swiper-button-prev swiper-prev-service swiper-button-black"
		></div>
	</div>

</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { mapState } from "vuex";
import BaseButton from "@/components/BaseButton.vue";
import { CONFIG } from "../enums/config";
import { PAGE_NAME } from "../enums/pagePermission";


export default {
	components: {
		BaseButton,
		SwiperSlide,
		Swiper
	},

	directives: {
		swiper: directive
	},

	props: {
		media: {
			type: Array,
			default: () => ([])
		}
	},

	data() {
		return {
			showSlider: true,
			CONFIG,
			PAGE_NAME,
			swiperOptions: {
				slidesPerView: "auto",
				slidesPerGroup: 1,
				spaceBetween: 20,
				observeParents: true,
				navigation: {
					nextEl: ".swiper-next-service",
					prevEl: ".swiper-prev-service"
				},
				breakpoints: {
					0: {
						slidesPerView: 1,
						slidesPerGroup: 1,
						spaceBetween: 20
					},
					600: {
						slidesPerView: 2,
						slidesPerGroup: 1,
						spaceBetween: 20
					},
					900: {
						slidesPerView: 2,
						slidesPerGroup: 1,
						spaceBetween: 20
					},
					1200: {
						slidesPerView: 3,
						slidesPerGroup: 1,
						spaceBetween: 20
					},
					1500: {
						slidesPerView: "auto",
						slidesPerGroup: 1,
						spaceBetween: 20
					}
				}

			}
		};
	},

	watch: {
		"sidebar.isSidebarOpen": {
			handler() {
				setTimeout(() => {
					this.showSlider = false;
					this.$nextTick(() => {
						this.showSlider = true;
					});
				}, 300);
			}
		}
	},

	computed: {
		...mapState(["sidebar"]),

		swiper() {
			return this.$refs.mySwiper.$swiper;
		}
	},

	methods: {
		handleSeeDetail(type, id, serviceId, productId = "", isStandardModule = false, templateId = null) {
			const isBHY = productId === null ? false : productId.toLowerCase() === CONFIG.EXAMPLE_MODULE_ROUTE.BHY;
			const productType = isBHY
				? CONFIG.EXAMPLE_MODULE_ROUTE.BHY
				: CONFIG.EXAMPLE_MODULE_ROUTE[type];
			if (productType !== null && id !== null && serviceId !== null) {
				// This condition route to std module example device
				if (isStandardModule && templateId) {
					this.$router.push({
						path: `/marketplace/example-module/${id}/standard-module/${serviceId}/${productType}/${templateId}/overview`
					});
				} else {
					this.$router.push({
						path: `/marketplace/example-module/${id}/${productType}/${serviceId}/info?redirect=home`
					});
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.navigation-service-container {
	position: relative;
	width: fit-content;
	max-width: calc(100% - rem(48));
	margin: 0 auto;
}

.service-container {
	padding: rem(10);
	margin: rem(24) rem(64) rem(40) rem(64);

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: rem(6);
	}
}

.swiper-slide {
	z-index: 1;
	width: rem(300);
}

.swiper-pagination ::v-deep.swiper-pagination-bullet {
	width: rem(14);
	height: rem(14);
	background-color: $color-white;
	opacity: 1;
}

.swiper-pagination ::v-deep.swiper-pagination-bullet-active {
	background-color: $color-emerald;
}

.swiper-button-black {
	--swiper-navigation-size: rem(24);
}

.service-card {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	height: 100%;
	border-radius: rem(8);
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
	background-color: $color-white;



	.header-content {
		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(52);
		width: 100%;
		font-size: $font-24;
		font-weight: $font-weight-bold;
		color: $color-white;
		border-radius: rem(8) rem(8) 0 0;

		span {
			padding-left: rem(16);
			padding-right: rem(16);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.image-content {
		padding: rem(16) rem(16) 0 rem(16);

		img {
			height: rem(284);
			width: rem(284);
			min-width: rem(200);
			min-height: rem(200);
		}
	}

	.app-content {
		padding: 0 rem(16);
		font-weight: $font-weight-bold;
		text-align: center;

		@include ellipsis(1, break-word);
	}

	.detail-content {
		height: rem(60);
		padding: 0 rem(16);
		text-align: center;

		@include ellipsis(2, break-word);
	}

	.navigate-content {
		display: flex;
		justify-content: center;
		margin-bottom: rem(16);
	}
}
</style>
