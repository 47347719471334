<template>
	<BaseLayout pageTitle="Overall">
		<div class="container-action-bar">
			<span>
				Data collected since
				<b>
					{{ getCollectDate }}
				</b>
			</span>
			<span>
				<i>
					Last update: {{ getCurrentTimestamp }}
				</i>
			</span>
		</div>

		<BaseOverallTotal :data="overall" :isLoading="overallMonitor.isLoading" class="container-base-overall"/>

		<div class="row">
			<div class="col-12 col-xl-12">
				<CardBarChart
					:chartData="getModuleDevicesBarChart"
					:isLoading="moduleDevices.isLoading"
					:isEmpty="moduleDevices.isEmpty"
					:lastUpdate="moduleDevices.lastUpdate"
					chartTitle="Module devices"
				/>
			</div>
		</div>

		<ProductType :data="mapProductType" :isLoading="productType.isLoading"/>

		<div class="row">
			<div class="col-12 col-xl-6">
				<CardPieChart
					:chartData="getStaffRolePieChart"
					:isLoading="userRoles.isLoading"
					:isEmpty="userRoles.isEmpty"
					:lastUpdate="userRoles.lastUpdate"
					chartTitle="BIG Staff user roles"
				/>
			</div>
			<div class="col-12 col-xl-6">
				<CardPieChart
					:chartData="getCustomerRolePieChart"
					:isLoading="userRoles.isLoading"
					:isEmpty="userRoles.isEmpty"
					:lastUpdate="userRoles.lastUpdate"
					chartTitle="Customer user roles"
				/>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-6">
				<CardBarChart
					:chartData="getOperationSystemBarChart"
					:isLoading="userUsages.isLoading"
					:isEmpty="userUsages.isEmpty"
					:lastUpdate="userUsages.lastUpdate"
					headerIcon="phone-laptop"
					chartTitle="Top 5 OSs"
				/>
			</div>
			<div class="col-12 col-xl-6">
				<CardBarChart
					:chartData="getBrowserUsagesBarChart"
					:isLoading="userUsages.isLoading"
					:isEmpty="userUsages.isEmpty"
					:lastUpdate="userUsages.lastUpdate"
					headerIcon="browser"
					chartTitle="Top 5 browsers"
				/>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters, mapState } from "vuex";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import CardPieChart from "@/components/chart/CardPieChart.vue";
import BaseOverallTotal from "@/components/BaseOverallTotal.vue";
import ProductType from "@/components/monitor/overall/ProductType.vue";
import { transformDeviceModuleBarChart } from "../../../selectors/transform/barChart/OverAll/moduleDevices";
import { transformStaffRolePieChart } from "../../../selectors/transform/pieChart/OverAll/staffRole";
import { transformCustomerRolePieChart } from "../../../selectors/transform/pieChart/OverAll/customerRole";
import { transformOperationSystemBarChart } from "../../../selectors/transform/barChart/OverAll/operationSystem";
import { transformBrowserUsagesBarChart } from "../../../selectors/transform/barChart/OverAll/borwserUsages";

export default {
	name: "MonitorOverall",

	components: {
		CardBarChart,
		CardPieChart,
		BaseOverallTotal,
		ProductType
	},

	computed: {
		...mapState(["moduleDevices", "userRoles", "userUsages", "productType", "overallMonitor"]),
		...mapGetters("productType", {
			mapProductType: "mapProductType"
		}),
		...mapGetters("overallMonitor", {
			overall: "overall"
		}),
		...mapGetters("moduleDevices", {
			getMonitorModuleDeviceData: "getMonitorModuleDeviceData"
		}),
		...mapGetters("userUsages", {
			getOperationSystemUsages: "getOperationSystemUsages",
			getBrowserUsages: "getBrowserUsages"
		}),
		...mapGetters("userRoles", {
			getStaffRole: "getStaffRole",
			getCustomerRole: "getCustomerRole"
		}),
		getModuleDevicesBarChart() {
			return transformDeviceModuleBarChart(this.getMonitorModuleDeviceData);
		},
		getStaffRolePieChart() {
			return transformStaffRolePieChart(this.getStaffRole);
		},
		getCustomerRolePieChart() {
			return transformCustomerRolePieChart(this.getCustomerRole);
		},
		getOperationSystemBarChart() {
			return transformOperationSystemBarChart({
				totalOs: this.getOperationSystemUsages,
				page: "overAll"

			});
		},
		getBrowserUsagesBarChart() {
			return transformBrowserUsagesBarChart({
				totalBrowsers: this.getBrowserUsages,
				page: "overAll"

			});
		},
		getCurrentTimestamp() {
			return dayjs().format("D MMM YYYY, HH:mm");
		},
		getCollectDate() {
			const stratDate = dayjs("2020-03-31").format("D MMM YYYY");
			const endDate = dayjs().format("D MMM YYYY");
			return `${stratDate} - ${endDate} (today)`;
		}
	},

	async created() {
		this.fetchData();
		this.addActivityOverall();
	},

	methods: {
		...mapActions("overallMonitor", {
			getOverall: "getOverall"
		}),
		...mapActions("moduleDevices", {
			getModuleDevices: "getModuleDevices"
		}),
		...mapActions("productType", {
			getProductType: "getProductType"
		}),
		...mapActions("userRoles", {
			getUserRole: "getUserRole"
		}),
		...mapActions("userUsages", {
			getUserUsages: "getUserUsages"
		}),
		...mapActions("activityLogAdd", {
			addActivityOverall: "addActivityOverall"
		}),
		fetchData() {
			Promise.all([
				this.getOverall(),
				this.getModuleDevices(),
				this.getProductType(),
				this.getUserRole({}),
				this.getUserUsages({})
			]);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: space-between;
	margin-bottom: rem(30);

	span {
		color: $color-dark-blue-grey;
	}
}

.container-base-overall {
	margin-bottom: rem(40);
}
</style>
