<template>
	<div class="no-gutter-top">
		<BaseLoading v-if="bhyInfo.isLoading" />

		<div :class="['row row-eq-height', { 'hide-content': bhyInfo.isLoading }]">
			<div class="col-12 col-xl-7 no-gutter-sm">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ bhyInfo.data.updatedAt}}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview :data="bhyInfo.data" />
					</template>
				</BaseCard>
			</div>
			<ModuleLocation :info="bhyInfo.data" />
		</div>

		<div class="tank-level-container">
			<CardLineChart
				:chartData="getLineChartConfig"
				:isLoading="bhyLevel.isLoading"
				:isEmpty="bhyLevel.isEmpty"
				:lastUpdate="bhyLevel.lastUpdate"
				:filterOptions="bhyLevelFilterOptions"
				chartTitle="Tank level"
				type="tank"
				@onSelectDatePicker="handleBhyLevelSelectDateRange"
				@onSelectDropDown="handleBhyLevelSelectDateDropDown"
			/>

			<CardBarChart
				:chartData="getMonthlyDataBarChart"
				:isLoading="bhyDeliverie.isLoading"
				:isEmpty="bhyDeliverie.isEmpty"
				:lastUpdate="bhyDeliverie.lastUpdate"
				chartTitle="Monthly delivery"
			/>
			<GrafanaDisplay
				:deviceId="bhyId"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import { transformBhyLevelLineChartConfig } from "../../selectors/transform/lineChart/BulkHydrogenInfo/bulkHydrogenLineChart";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { transformBhyBarChart } from "../../selectors/transform/barChart/BulkHydrogenInfo/bulkHydrogenBarChart";
import { getDateNumber, getDateDiff } from "../../selectors/helpers";
import setupDevice from "../../mixins/setupDevice";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export default {
	name: "BulkHydrogenInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		CardBarChart,
		CardLineChart,
		ModuleOverview,
		ModuleLocation
	},
	mixins: [setupDevice],
	data() {
		return {
			bhyId: this.$route.params.id,
			sortBy: perDay,
			bhyLevelFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 1 month",
								value: perThirtyDays
							},
							{
								label: "Last 3 months",
								value: perNinetyDays
							}
						]
					}
				}
			},
			device: "mobile"
		};
	},

	computed: {
		...mapState(["bhyInfo", "bhyLevel", "bhyDeliverie"]),
		...mapGetters("bhyLevel", {
			getBhyLevelData: "getBhyLevelData",
			getLevelStateData: "getLevelStateData"
		}),
		...mapGetters("bhyDeliverie", {
			getBhyMonthlyData: "getBhyMonthlyData"
		}),
		bhyLevelSelected() {
			const dateRange = this.bhyLevelFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},
		getMonthlyDataBarChart() {
			return transformBhyBarChart(this.getBhyMonthlyData);
		},
		getLineChartConfig() {
			const sortBy = this.sortBy;
			const { diff } = this.bhyLevelSelected;


			return transformBhyLevelLineChartConfig(sortBy, this.getBhyLevelData, {
				markLine: [
					{
						label: "Re-order",
						color: "#ffcb00",
						value: this.getLevelStateData.reorderLevel
					},
					{
						label: "Re-fill",
						color: "#ea7125",
						value: this.getLevelStateData.refillLevel
					},

					{
						label: "Empty",
						color: "#e03431",
						value: this.getLevelStateData.emptyLevel
					}],
				diffDay: diff
			}, this.isMobile);
		}
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded", this.fetchData);
	},

	methods: {
		...mapActions("bhyLevel", [
			"getBhyLevel"
		]),
		...mapActions("bhyDeliverie", {
			getBhyDeliverie: "getBhyDeliverie"
		}),
		fetchData() {
			const { bhyId } = this;
			return Promise.all([
				this.getBhyLevel({ id: bhyId, query: { filter: this.bhyLevelSelected.dateNumber } }),
				this.getBhyDeliverie({ id: bhyId })
			]);
		},
		handleBhyLevelSelectDateRange(date) {
			const { start, end } = date;

			this.bhyLevelFilterOptions.date.selected.range = {
				start,
				end
			};

			this.sortBy = "";
			this.getBhyLevel({
				id: this.bhyId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},
		handleBhyLevelSelectDateDropDown(filterBy = "1d") {
			this.sortBy = filterBy;
			this.getBhyLevel({
				id: this.bhyId,
				query: {
					filter: filterBy
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.row-eq-height,
.row-eq-height > [class^=col]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.card {
	width: 100%;
	height: calc(100% - rem(34));

	@include mobile {
		border-radius: 0;
	}
}

.no-gutter-top {
	margin-top: rem(-1);

	@include desktop-large {
		margin-top: 0;
	}
}

::v-deep {
	.custom-unit-dropdown {
		margin-left: rem(16);
		max-width: rem(120);

		@include mobile {
			margin-left: 0;
			margin-top: rem(12);
			max-width: 100%;
		}

		.dropdown-value {
			background-color: $color-white;

			.dropdown-placeholder,
			.dropdown-value-text {
				position: absolute;
				left: rem(50);
				top: 0;

				sup {
					line-height: rem(20);
				}
			}

			.prepend-icon {
				position: absolute;
			}
		}
	}

	.overview-info:nth-child(2) {
		border-bottom: solid 1px $color-silver;
		margin-bottom: rem(12);
	}

	.tank-level-container {
		background-color: $color-alabaster;
		border-radius: rem(8);
		padding: rem(16);
		margin-bottom: rem(16);

		@include mobile {
			border-radius: 0;
			margin-left: rem(-16);
			margin-right: rem(-16);
			padding: rem(16) 0;
		}

		.card {
			margin-bottom: rem(16);

			@include mobile {
				border-radius: 0;
			}

			&-body {
				background-color: $color-white;
				border-bottom-left-radius: rem(4);
				border-bottom-right-radius: rem(4);
			}
		}
	}
}
</style>
