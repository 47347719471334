import httpClient from "./httpClient";

const LAYOUT_CUSTOMISATION = "/customizations";

const getLayoutAddables = (params) => httpClient.get(`${LAYOUT_CUSTOMISATION}/addables`, { params });
const getLayoutTemplate = (params) => httpClient.get(`${LAYOUT_CUSTOMISATION}/templates`, { params });
const storeLayoutTemplate = (payload) => httpClient.post(`${LAYOUT_CUSTOMISATION}/`, payload);
const getLayoutLists = (query) => httpClient.get(`${LAYOUT_CUSTOMISATION}/`, { params: {
	...query
} });
const getLayoutById = (id) => httpClient.get(`${LAYOUT_CUSTOMISATION}/${id}`);
const updateLayoutById = (id, payload) => httpClient.put(`${LAYOUT_CUSTOMISATION}/${id}`, payload);
const deleteLayoutByid = (id) => httpClient.delete(`${LAYOUT_CUSTOMISATION}/${id}`);
export {
	getLayoutAddables,
	getLayoutTemplate,
	storeLayoutTemplate,
	getLayoutLists,
	getLayoutById,
	updateLayoutById,
	deleteLayoutByid
};
