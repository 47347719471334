<template>
	<div v-if="!deletedAt" ref="adjust">
		<BaseLine
			:class="`custom-base-line`"
		/>
		<DiagramInput
			:active="toggle.form"
			:input.sync="diagram.input"
			:validateFn.sync="validateInputFn"
			:removeable="removeable"
			:editMode="editMode"
			@onToggle="handleToggle"
			@onRemove="handleRemove"
		/>
		<DiagramTree
			v-if="showDiagramTree && !rerenderDiagramTree"
			:screenWidth="screen.width"
			:screenHeight="screen.height"
			:tree="diagram.tree"
			:zoomable="zoomable"
			:editMode="editMode"
			@onClickNode="handleClickNode"
			@onClickEmptyNode="handleClickEmptyNode"
			@onClickEditNode="handleClickEditNode"
			@onClickRemoveNode="handleClickRemoveNode"
		/>
		<ModalSelectDevice
			:modal-name="MODAL_NAME_SELECT_DEVICE"
			:electricity="electricity"
			:meter="meter"
			:selectedNodeIds="selectedNodeIds"
			:selectedModule="selected.module"
			@onSelect="handleSelectDevice"
		/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_NAME_CONFIRM_DELETE_DEVICE"
			:title="`Remove device`"
			:message="`
					<div>
						Are you sure to remove this device?
					</div>
					<div>
						Remove device will remove linked device below.
					</div>
				`"
			:submitButtonText="`Remove`"
			@onCancel="handleCancelDeleteDevice"
			@onConfirm="handleConfirmDeleteDevice"
		/>
	</div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import DiagramInput from "@/components/FlowDiagram/DiagramInput.vue";
import ModalSelectDevice from "@/components/FlowDiagram/ModalSelectDevice.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import DiagramTree from "@/components/FlowDiagram/DiagramTree.vue";
import BaseLine from "@/components/BaseLine.vue";
import { getNodeCurrent, getNodeParent, deleteNode, calculateSummaryTotal } from "../selectors/helpers/flowDiagram";
import { PAGE_NAME } from "../enums/pagePermission";

export default {
	components: {
		DiagramInput,
		ModalSelectDevice,
		BaseModalConfirmDelete,
		DiagramTree,
		BaseLine
	},

	props: {
		id: {
			type: String,
			default: null
		},
		input: {
			type: Object,
			default: () => ({})
		},
		tree: {
			type: Object,
			default: () => ({})
		},
		selectedNodeIds: {
			type: Array,
			default: () => ([])
		},
		electricity: {
			type: Array,
			default: () => ([])
		},
		meter: {
			type: Array,
			default: () => ([])
		},
		removeable: {
			type: Boolean,
			default: false
		},
		zoomable: {
			type: Boolean,
			default: false
		},
		deletedAt: {
			type: [Object, Date],
			default: null
		},
		editMode: {
			type: Boolean,
			default: false
		},
		collapse: {
			type: Boolean,
			default: false
		},
		focused: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			MODAL_NAME_SELECT_DEVICE: `modal-${uuidv4()}`,
			MODAL_NAME_CONFIRM_DELETE_DEVICE: `modal-${uuidv4()}`,
			ready: false,
			diagram: {
				input: {
					name: null
				},
				tree: {
					type: "empty",
					level: 1,
					ref: null,
					value: null,
					detail: {},
					childs: []
				}
			},
			selected: {
				// module type: meter, electricity
				module: null,
				// node option from tree
				nodeOption: null,
				// device info from modal
				deviceInfo: null,
				// state adding node or changing
				isAdding: false,
				isChanging: false
			},
			toggle: {
				form: true
			},
			screen: {
				width: 500,
				height: 500
			},
			rerenderDiagramTree: false,
			validateInputFn: null
		};
	},

	computed: {
		showDiagramTree() {
			return this.toggle.form && this.ready;
		}
	},

	watch: {
		"diagram.input": {
			handler(newValue) {
				this.$emit("update:input", newValue);
			},
			deep: true
		},
		"diagram.tree": {
			handler(newValue) {
				// side effect function.
				// re calculate summary total every times,
				// when diagram tree is changed.
				calculateSummaryTotal(newValue);
				this.$emit("update:tree", newValue);
			},
			deep: true
		},
		diagram: {
			handler() {
				this.$emit("update:validateInputFn", this.validateInputFn);
			},
			deep: true
		},
		tree: {
			handler(newValue) {
				this.diagram.tree = newValue;
				this.rerenderDiagramTree = true;
				this.$nextTick(() => {
					this.rerenderDiagramTree = false;
				});
			},
			deep: true
		},
		validateInputFn() {
			this.$emit("update:validateInputFn", this.validateInputFn);
		},
		editMode() {
			this.initToggleForm();
		}
	},

	mounted() {
		this.setState();
		this.initToggleForm();
		// set screen after doms/elements is updated
		this.$nextTick(() => this.setScreen());
	},

	methods: {
		setState() {
			if (this.input) {
				this.diagram.input = { ...this.input };
			}
			if (this.tree) {
				this.diagram.tree = { ...this.tree };
				if (["summary_meter", "device_meter", "device_nitrofas", "device_gengas"].includes(this.tree.type)) {
					this.selected.module = "meter";
				} else if (["summary_electricity", "device_power", "device_solor"].includes(this.tree.type)) {
					this.selected.module = "electricity";
				}
			}
		},
		setScreen() {
			const container = this.$refs.adjust;
			this.screen.width = container.offsetWidth;
			this.ready = true;
		},
		initToggleForm() {
			if (this.editMode) {
				this.toggle.form = true;
			} else {
				this.toggle.form = !this.collapse;
			}
		},
		handleSelectDevice({ item, summaryRouteInput }) {
			if (["summary_meter", "device_meter", "device_nitrofas"].includes(item.type)) {
				this.selected.module = "meter";
			} else if (["summary_electricity", "device_power", "device_solor"].includes(item.type)) {
				this.selected.module = "electricity";
			}
			if (["summary_meter", "summary_electricity"].includes(item.type)) {
				item.id += `-${uuidv4()}`;
				item.route = {
					name: summaryRouteInput
				};
			}
			this.selected.deviceInfo = { ...item };
			this.addNode(this.selected.nodeOption, this.selected.deviceInfo);
		},
		handleCancelDeleteDevice() {
			this.closseModalConfirmDeleteDevice();
		},
		handleConfirmDeleteDevice({ nodeOption, tree }) {
			const nodeCurrent = getNodeCurrent(nodeOption, tree);
			deleteNode(nodeCurrent, tree);
			if (!this.diagram.tree.childs.length) {
				this.selected.module = null;
			}
			this.closseModalConfirmDeleteDevice();
		},
		handleClickNode(e, nodeOption) {
			const isViewMode = !this.editMode;
			if (isViewMode) {
				const node = getNodeCurrent(nodeOption, this.diagram.tree);
				let route = null;
				switch (node.type) {
					case "device_meter":
						route = {
							name: PAGE_NAME.METERINFO,
							params: { id: node.value }
						};
						break;
					case "device_nitrofas":
						route = {
							name: PAGE_NAME.NITRO_FAS_INFO,
							params: { id: node.value }
						};
						break;
					case "device_gengas":
						route = {
							name: PAGE_NAME.GENGAS_INFO,
							params: { id: node.value }
						};
						break;
					case "device_power":
						route = {
							name: PAGE_NAME.PWR_INFO,
							params: { id: node.value }
						};
						break;
					case "device_solor":
						route = {
							name: PAGE_NAME.SOR_INFO,
							params: { id: node.value }
						};
						break;
					default:
						route = null;
				}
				if (route) {
					const { href } = this.$router.resolve(route);
					window.open(href, "_blank");
				}
			}
		},
		handleClickEmptyNode(e, nodeOption) {
			this.selected.nodeOption = { ...nodeOption };
			this.selected.isAdding = true;
			this.selected.isChanging = false;
			this.openModalSelectDevice();
		},
		handleClickEditNode(e, nodeOption) {
			this.selected.nodeOption = { ...nodeOption };
			this.selected.isAdding = false;
			this.selected.isChanging = true;
			this.openModalSelectDevice();
		},
		handleClickRemoveNode(e, nodeOption) {
			// use binding data intread of store data in the component,
			// because bugs of vue js or sometings we don't discover yet.
			// limited of times, this just solution to working around.
			this.openModalConfirmDeleteDevice(nodeOption, this.diagram.tree);
		},
		addNode(nodeOption, deviceInfo) {
			const nodeCurrent = getNodeCurrent(nodeOption, this.diagram.tree);
			nodeCurrent.type = deviceInfo.type;
			nodeCurrent.value = deviceInfo.id;
			nodeCurrent.detail = { ...deviceInfo };
			if (this.selected.isChanging) {
				for (let i = 0; i < nodeCurrent.childs.length; i++) {
					nodeCurrent.childs[i].ref = deviceInfo.id;
				}
			}
			if (this.selected.isAdding) {
				// add a empty node on current
				nodeCurrent.childs.push({
					type: "empty",
					level: nodeCurrent.level + 1,
					ref: nodeCurrent.value,
					value: uuidv4(),
					childs: []
				});
				// add a empty node if parent have no any empty nodes left
				if (nodeCurrent.level > 1) {
					const nodeParent = getNodeParent(nodeOption, this.diagram.tree);
					const hasEmptyNodes = nodeParent.childs.some((node) => node.type === "empty");
					if (!hasEmptyNodes) {
						nodeParent.childs.push({
							type: "empty",
							level: nodeParent.level + 1,
							ref: nodeParent.value,
							value: uuidv4(),
							childs: []
						});
					}
				}
			}
		},
		handleToggle(state) {
			this.toggle.form = state;
		},
		handleRemove() {
			this.$emit("onRemove", {
				id: this.id
			});
		},
		openModalSelectDevice() {
			this.$emit("update:focused", this.id);
			const node = (() => {
				if (this.selected.nodeOption) {
					return getNodeCurrent(this.selected.nodeOption, this.diagram.tree);
				}
				return null;
			})();
			this.$modal.show(this.MODAL_NAME_SELECT_DEVICE, { node });
		},
		openModalConfirmDeleteDevice(nodeOption, tree) {
			this.$modal.show(this.MODAL_NAME_CONFIRM_DELETE_DEVICE, { nodeOption, tree });
		},
		closseModalConfirmDeleteDevice() {
			this.$modal.hide(this.MODAL_NAME_CONFIRM_DELETE_DEVICE);
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-base-line {
	margin: rem(24) 0;
}
</style>

