<template>
	<modal
		:name="modalName"
		:scrollable="true"
		width="844"
		height="auto"
		class="custom-modal"
		@before-open="beforeOpen"
	>
		<BaseModalContent rounded>
			<template v-slot:header>
				Select device
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<DeviceList
					:meter="meterOptions"
					:meterCount="meter.length"
					:electricity="electricityOptions"
					:electricityCount="electricity.length"
					:selectedModule="selectedModule"
					:form.sync="form"
					:node="node"
				/>
			</template>
			<template v-slot:footer>
				<button
					class="cancel-btn"
					@click="onCancel"
				>
					Cancel
				</button>
				<BaseButton
					:disabled="disabledSubmitBtn"
					size="medium"
					@click="onSubmit"
				>
					Confirm
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import DeviceList from "@/components/FlowDiagram/DeviceList.vue";
import BaseModalContent from "@/components/BaseModalContent.vue";
import { getElectricityPercentage } from "../../selectors/helpers";
import { getNumeral, getDeviceName } from "../../selectors/helpers/flowDiagram";
import { MODULE_TYPES } from "../../enums/modules";

export default {
	components: {
		DeviceList,
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},
		electricity: {
			type: Array,
			default: () => ([])
		},
		meter: {
			type: Array,
			default: () => ([])
		},
		selectedNodeIds: {
			type: Array,
			default: () => ([])
		},
		selectedModule: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			form: {
				selectedItem: null,
				input: null
			},
			node: null
		};
	},

	computed: {
		meterOptions() {
			const items = this.meter
				.filter((item) => {
					return !this.selectedNodeIds.includes(item.id);
				})
				.map((item) => {
					const deviceName = getDeviceName(item.shipId, item.deviceCustomerName);
					return {
						id: item.id,
						type: (() => {
							if (item.type === MODULE_TYPES.METER) {
								return "device_meter";
							} else if (item.type === MODULE_TYPES.NITRO_FAS) {
								return "device_nitrofas";
							} else if (item.type === MODULE_TYPES.GENGAS) {
								return "device_gengas";
							}
							return "device_meter";
						})(),
						head: {
							name: item.name,
							id: deviceName ? `(${deviceName})` : ""
						},
						sub: {
							icon: (() => {
								if (item.type === MODULE_TYPES.METER) {
									return "meter";
								} else if (item.type === MODULE_TYPES.NITRO_FAS) {
									return "nitrofas";
								} else if (item.type === MODULE_TYPES.GENGAS) {
									return "gengas";
								}
								return "meter";
							})(),
							name: "N2 Flow rate",
							value: item.flowRatePercent
						},
						value: (() => {
							if ([MODULE_TYPES.METER, MODULE_TYPES.GENGAS].includes(item.type)) {
								return getNumeral(item.flowRate, "0,0", "0");
							} else if (item.type === MODULE_TYPES.NITRO_FAS) {
								return getNumeral(item.uph, "0,0", "0");
							}
							return getNumeral(item.flowRate, "0,0", "0");
						})(),
						info: [
							{
								label: 'MTD (Nm<span class="sub">3</span>)',
								value: getNumeral(item.consumptionMTD, "0,0", "-")
							}
						]
					};
				});
			return [
				{
					id: `summary-meter-${uuidv4()}`,
					type: "summary_meter",
					sub: {
						name: "N2 Flow rate"
					},
					value: this.meterSummary.value,
					info: [
						{
							label: 'MTD (Nm<span class="sub">3</span>)',
							value: this.meterSummary.mtd
						}
					]
				},
				...items
			];
		},
		electricityOptions() {
			const items = this.electricity
				.filter((item) => {
					return !this.selectedNodeIds.includes(item.id);
				})
				.map((item) => {
					const deviceName = getDeviceName(item.shipId, item.deviceCustomerName);
					return {
						id: item.id,
						type: (() => {
							if (item.type === MODULE_TYPES.PWR) {
								return "device_power";
							} else if (item.type === MODULE_TYPES.SOR) {
								return "device_solor";
							}
							return "device_power";
						})(),
						head: {
							name: item.name,
							id: deviceName ? `(${deviceName})` : ""
						},
						sub: {
							icon: "power",
							name: "Power (kW)",
							value: getElectricityPercentage(item.kw, item.maxRange)
						},
						value: getNumeral(item.kw, "0,0", "0"),
						info: [
							{
								label: "MTD Power (kWh)",
								value: getNumeral(item.mtdPower, "0,0", "-")
							},
							{
								label: "MTD Power Savings (Baht)",
								value: getNumeral(item.mtdPowerCost, "0,0", "-")
							}
						]
					};
				});
			return [
				{
					id: `summary-electricity-${uuidv4()}`,
					type: "summary_electricity",
					sub: {
						name: "Power"
					},
					value: this.electSummary.value,
					info: [
						{
							label: "MTD Power (kWh)",
							value: this.electSummary.mtdPower
						},
						{
							label: "MTD Power Savings (Baht)",
							value: this.electSummary.mtdPowerCost
						}
					]
				},
				...items
			];
		},
		disabledSubmitBtn() {
			if (this.form && this.form.selectedItem && this.form.selectedItem.id) {
				return false;
			}
			return true;
		},
		meterSummary() {
			return {
				value: (() => {
					if (this.node && this.node.type === "summary_meter" && this.node.detail.value) {
						return this.node.detail.value;
					}
					return 0;
				})(),
				mtd: (() => {
					if (this.node && this.node.type === "summary_meter" && this.node.detail.info[0].value) {
						return this.node.detail.info[0].value;
					}
					return 0;
				})()
			};
		},
		electSummary() {
			return {
				value: (() => {
					if (this.node && this.node.type === "summary_electricity" && this.node.detail.value) {
						return this.node.detail.value;
					}
					return 0;
				})(),
				mtdPower: (() => {
					if (this.node && this.node.type === "summary_electricity" && this.node.detail.info[0].value) {
						return this.node.detail.info[0].value;
					}
					return 0;
				})(),
				mtdPowerCost: (() => {
					if (this.node && this.node.type === "summary_electricity" && this.node.detail.info[1].value) {
						return this.node.detail.info[1].value;
					}
					return 0;
				})()
			};
		}
	},

	methods: {
		beforeOpen(event) {
			this.form = {
				selectedItem: null,
				input: null
			};
			if (event.params.node) {
				this.node = event.params.node;
			}
		},
		onCancel() {
			this.$modal.hide(this.modalName);
		},
		onSubmit() {
			this.$emit("onSelect", {
				item: this.form.selectedItem,
				summaryRouteInput: this.form.input
			});
			this.$modal.hide(this.modalName);
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.custom-modal {
	&::v-deep .container-modal .header {
		min-height: auto;
		height: rem(52);
		font-size: $font-24;
		font-weight: normal;
		font-family: $font-family-sub;
	}
}

.cancel-btn {
	background: none;
	border: none;
	font-family: $font-family-sub;
	font-size: $font-20;
	font-weight: $font-weight-bold;
	color: $color-cerulean;
	margin-right: rem(22);
}
</style>