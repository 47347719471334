import Numeral from "numeral";
import { transformPieChart } from "..";

const staffRoleColorConfig = {
	POWER_USER: "#009a44",
	PROCUREMENT: "#00a8e1",
	USER: "#ffcb00",
	LEVEL_2_USER: "#ea7125"
};
export const trasnformCustomerDataBarCharts = (totalCustomers = []) => {
	const getCustomerTotal = totalCustomers.reduce((acc, next) => acc + next.total, 0);

	const yAxis = [
		{
			name: "staffRoles",
			type: "pie",
			radius: ["50%", "70%"],
			center: ["50%", "40%"],
			avoidLabelOverlap: true,
			label: {
				show: true,
				position: "center",
				formatter: `{total|${Numeral(getCustomerTotal).format("0,0")}}\n{describe|Customer users}`,
				rich: {
					total: {
						fontSize: 48,
						color: "#103332",
						fontFamily: "DBHeavent"
					},
					describe: {
						fontSize: 20,
						color: "#586c63",
						fontWeight: "lighter",
						fontFamily: "DBHeavent"
					}
				}
			},
			labelLine: {
				show: false
			},
			data: totalCustomers.map((data) => ({
				value: data.total,
				name: data.displayName,
				tooltip: {
					show: data.total !== 0,
					formatter: ({ data: customerRole }) => `
						<div class="staff-role-over-all">
							<div class="role-name">${customerRole.name}</div>
							<div class="role-value">${customerRole.value}</div>
						</div>
					`
				},
				itemStyle: {
					color: getCustomerTotal === 0 ? "#cad9cf" : staffRoleColorConfig[data.code]
				}
			}))
		}
	];

	return {
		yAxis
	};
};

export const options = (totalCustomers) => {
	const getTotalStaff = totalCustomers.reduce((acc, next) => acc + next.total, 0);

	const staffObjectPercentage = totalCustomers.reduce((acc, next) => {
		return {
			...acc,
			[next.displayName]: (next.total / getTotalStaff) * 100 || 0
		};
	}, {});

	return {
		legend: {
			data: totalCustomers.map((data) => {
				return data.displayName;
			}),
			formatter: (name) => {
				return 	`${name} {percentage|${Numeral(staffObjectPercentage[name] / 100).format("0.00%")}}`;
			},
			icon: "circle",
			bottom: -10,
			left: 24,
			itemWidth: 10,
			itemHeight: 10,
			itemGap: 24,
			textStyle: {
				rich: {
					percentage: {
						color: "#103332",
						fontSize: 20,
						fontWeight: 600,
						fontFamily: "DBHeavent"
					}
				}

			}
		}
	};
};

export const transformCustomerRolePieChart = (totalCustomers = []) => {
	const data = trasnformCustomerDataBarCharts(totalCustomers);
	const pieChartOptions = options(totalCustomers);
	return transformPieChart(pieChartOptions)(data);
};
