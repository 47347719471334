import Users from "@/views/Staff/Index.vue";
import UsersCreate from "@/views/Staff/Create.vue";
import UsersEdit from "@/views/Staff/Edit.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

const { USERS, USERS_CREATE, USERS_EDIT } = PAGE_NAME;

export default [
	{
		path: "/users",
		name: USERS,
		component: Users,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/users/create",
		name: USERS_CREATE,
		component: UsersCreate,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/users/:id",
		name: USERS_EDIT,
		component: UsersEdit,
		meta: {
			requireAuth: true
		}
	}
];