import { getAirCompOverallAirVolumeAPI } from "@/services/api/airComp.api";
import { isEmpty } from "../../../selectors/helpers";
import { MILI_QUE_UNIT } from "./../../../selectors/transform/unit";

import {
	AIR_COMP_OVERALL_AIR_VOLUME_REQUEST,
	AIR_COMP_OVERALL_AIR_VOLUME_SUCCESS,
	AIR_COMP_OVERALL_AIR_VOLUME_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		empty: true,
		tagName: ""
	},

	mutations: {
		[AIR_COMP_OVERALL_AIR_VOLUME_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[AIR_COMP_OVERALL_AIR_VOLUME_SUCCESS](state, response) {
			state.data = response.data;
			state.tagName = (state.data[0]?.tagname || `Overall Air Volume (${MILI_QUE_UNIT}/day)`).replace(`m3`, MILI_QUE_UNIT);
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
			state.isEmpty = isEmpty(response.data);
		},
		[AIR_COMP_OVERALL_AIR_VOLUME_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchAirCompOverallAirVolumeData({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_OVERALL_AIR_VOLUME_REQUEST);

				const { data = [] } = await getAirCompOverallAirVolumeAPI(id, params);

				commit(AIR_COMP_OVERALL_AIR_VOLUME_SUCCESS, data);
			} catch (error) {
				commit(AIR_COMP_OVERALL_AIR_VOLUME_FAILURE);
			}
		}
	}
};
