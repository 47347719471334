/**
 * Enum for e-ordering status.
 * @readonly
 * @enum {EnumEOrderingStatus}
 */
export const STATUS = Object.freeze({
	NEW: "NEW",
	IN_TRANSIT: "IN_TRANSIT",
	CONFIRMED: "CONFIRMED",
	CANCELLED: "CANCELLED",
	COMPLETED: "COMPLETED",
	DELIVERED: "DELIVERED"
});

/**
 * Enum for e-ordering status label.
 * @readonly
 * @enum {EnumEOrderingStatusLabel}
 */
export const STATUS_LABEL = Object.freeze({
	[STATUS.NEW]: "New",
	[STATUS.IN_TRANSIT]: "In transit",
	[STATUS.CONFIRMED]: "Confirmed",
	[STATUS.CANCELLED]: "Cancelled",
	[STATUS.COMPLETED]: "Completed",
	[STATUS.DELIVERED]: "Delivered"

});

/**
 * Enum for e-ordering tab type.
 * @readonly
 * @enum {EnumEOrderingTabType}
 */
export const TAB_TYPE = Object.freeze({
	HISTORY: "history",
	LIST: "list"
});


/**
 * Enum for e-ordering dropdown status.
 * @readonly
 * @enum {EnumEOrderingDropDownStatus}
 */
export const DROP_DOWN_STATUS = Object.freeze({
	[TAB_TYPE.HISTORY]: [STATUS.CANCELLED, STATUS.DELIVERED],
	[TAB_TYPE.LIST]: [STATUS.NEW, STATUS.CONFIRMED, STATUS.IN_TRANSIT]
});

