import { getLngTripInfoAPI } from "../../../services/api/lng.api";
import {
	TRIP_INFO_REQUEST,
	TRIP_INFO_SUCCESS,
	TRIP_INFO_FAILURE,
	TRIP_INFO_UPDATE_TRUCK_SUCCESS,
	TRIP_INFO_UPDATE_TRUCK_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		statusCode: null
	},

	getters: {
		getLngTripInfoDrivers(state) {
			return state.data.info && state.data.info.drivers ? state.data.info.drivers : [];
		},

		getLngTripInfoType(state) {
			return state.data.type;
		},

		getLngTruckLocation(state) {
			if (state.data.truckGps) {
				return {
					lat: state.data.truckGps.latitude,
					lng: state.data.truckGps.longitude
				};
			}
			return false;
		},

		getLngTripInfoInfomation(state) {
			// TODO: single drop
			if (state.data.info) {
				return {
					deliveryOrderNo: state.data.info.doNumber ? state.data.info.doNumber.toString() : "-",
					tractorId: state.data.info.tractorId ? state.data.info.tractorId.toString() : "-",
					trailerId: state.data.info.trailerId ? state.data.info.trailerId.toString() : "-",
					tripId: state.data.info.tripId ? state.data.info.tripId.toString() : "-"
				};
			}
			return {
				deliveryOrderNo: "734613",
				tripId: "12423867",
				tractorId: "410",
				trailerId: "2534"
			};
		}
	},

	mutations: {
		[TRIP_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[TRIP_INFO_SUCCESS](state, { data, status }) {
			state.data = data;
			state.isLoading = false;
			state.statusCode = status;
		},
		[TRIP_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[TRIP_INFO_UPDATE_TRUCK_SUCCESS](state, { data }) {
			state.data.truckGps = data.truckGps;
		}
	},

	actions: {
		async getLngTripInfo({ commit }, { id }) {
			try {
				commit(TRIP_INFO_REQUEST);
				const response = await getLngTripInfoAPI(id);
				commit(TRIP_INFO_SUCCESS, response);
			} catch (error) {
				commit(TRIP_INFO_FAILURE, error);
			}
		},
		async getLngUpdateTruckLocation({ commit }, { id }) {
			try {
				const response = await getLngTripInfoAPI(id);
				commit(TRIP_INFO_UPDATE_TRUCK_SUCCESS, response);
			} catch (e) {
				commit(TRIP_INFO_UPDATE_TRUCK_FAILURE);
			}
		}
	}
};
