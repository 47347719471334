import Numeral from "numeral";
import { transformPieChart } from "..";

const staffRoleColorConfig = {
	SUPER_ADMIN: "#009a44",
	ADMIN: "#00a8e1",
	BUSINESS_SUPPORT: "#ffcb00",
	SALES: "#ea7125",
	SALES_PLUS: "#ea7125",
	IT: "#cf0360"
};
export const trasnformStaffDataBarCharts = (totalStaffs = []) => {
	const getTotalStaff = totalStaffs.reduce((acc, next) => acc + next.total, 0);

	const yAxis = [
		{
			name: "staffRoles",
			type: "pie",
			radius: ["50%", "70%"],
			center: ["50%", "40%"],
			avoidLabelOverlap: true,
			label: {
				show: true,
				position: "center",
				formatter: `{total|${Numeral(getTotalStaff).format("0,0")}}\n{describe|BIG staff users}`,
				rich: {
					total: {
						fontFamily: "DBHeavent",
						fontSize: 48,
						color: "#103332"
					},
					describe: {
						fontFamily: "DBHeavent",
						fontSize: 20,
						color: "#586c63",
						fontWeight: "lighter"
					}
				}
			},
			labelLine: {
				show: false
			},
			data: totalStaffs.map((data) => ({
				value: data.total,
				name: data.displayName,
				tooltip: {
					show: data.total !== 0,
					formatter: ({ data: staffRole }) => `
						<div class="staff-role-over-all">
							<div class="role-name">${staffRole.name}</div>
							<div class="role-value">${staffRole.value}</div>
						</div>
						`
				},
				itemStyle: {
					color: getTotalStaff === 0 ? "#cad9cf" : staffRoleColorConfig[data.code]
				}
			}))
		}
	];

	return {
		yAxis
	};
};

export const options = (totalStaffs) => {
	const getTotalStaff = totalStaffs.reduce((acc, next) => acc + next.total, 0);

	const staffObjectPercentage = totalStaffs.reduce((acc, next) => {
		return {
			...acc,
			[next.displayName]: (next.total / getTotalStaff) * 100 || 0
		};
	}, {});

	return {
		legend: {
			data: totalStaffs.map((data) => {
				return data.displayName;
			}),
			formatter: (name) => {
				return 	`${name} {percentage|${Numeral(staffObjectPercentage[name] / 100).format("0.00%")}}`;
			},
			icon: "circle",
			bottom: -10,
			itemWidth: 10,
			itemHeight: 10,
			itemGap: 16,
			textStyle: {
				rich: {
					percentage: {
						color: "#103332",
						fontSize: 20,
						fontWeight: 600,
						fontFamily: "DBHeavent"
					}
				}

			}
		}
	};
};

export const transformStaffRolePieChart = (totalStaffs = []) => {
	const data = trasnformStaffDataBarCharts(totalStaffs);
	const pieChartOptions = options(totalStaffs);
	return transformPieChart(pieChartOptions)(data);
};
