<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: this.isActive.keyword }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				:value="keywordValue"
				:inputOptions="['ghost input-filter small']"
				placeholder="Ship-to name, ship-to code, Location key"
				@input="handleKeywordChange"
				@focus="setFocus('keyword', true)"
				@blur="setFocus('keyword', false)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-status', { active: isActive.status }]">
			<BaseDropdown
				:searchable="false"
				:list="statuses"
				:value="statusValue"
				type="ghost"
				placeholder="Status: All"
				@input="handleStatusSelected"
				@active="setFocus('status', $event)"
			/>
		</div>
		<BaseButton class="search-btn" size="large" type="primary" outline rounded>Search</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>

export default {
	name: "SearchFilter",
	props: {
		keywordValue: {
			type: String,
			default: ""
		},
		typeValue: {
			type: [String, Number],
			default: ""
		},
		typeLists: {
			type: Array,
			default: () => ([])
		},
		moduleValue: {
			type: [String, Number],
			default: ""
		},
		moduleLists: {
			type: Array,
			default: () => ([])
		},
		statusValue: {
			type: [Number, String]
		},
		statuses: {
			type: Array,
			default: () => ([])
		}
	},

	data() {
		return {
			keyword: "",
			isActive: {
				keyword: false,
				type: false,
				module: false,
				status: false
			}
		};
	},

	computed: {
		moduleDisabled() {
			return !this.typeValue;
		}
	},

	methods: {
		handleSelectTypeDropdown(value) {
			this.$emit("handleSelectType", value);
			this.$emit("handleSelectModule", null);
		},

		handleSelectModuleDropdown(value) {
			this.$emit("handleSelectModule", value);
		},

		handleStatusSelected(value) {
			this.$emit("handleSelectStatus", value);
		},

		handleKeywordChange(value) {
			this.keyword = value;
			this.$emit("handleKeywordChange", value);
		},

		handleSubmit() {
			this.$emit("handleSubmit");
		},

		clearFilter() {
			this.$emit("handleClear");
		},

		setFocus(type, value) {
			this.isActive[type] = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.filter-form {
	margin: rem(24) 0 0;
}

.input-group-keyword {
	width: rem(628);
}

.input-group-type {
	width: rem(165);
}

.input-group-module {
	width: rem(200);
}

.search-btn {
	width: rem(115);
}

/* stylelint-disable */
.custom-datepicker {
	display: inline-flex;
	flex: 1;

	&::v-deep .container-datepicker-control {
		margin-left: -14px;
	}

	&::v-deep .input {
		border: none;
		background: none !important;
		padding-left: rem(26) !important;
	}

	&::v-deep .icon.is-left {
		width: rem(14) !important;
	}
}
/* stylelint-enable */
</style>
