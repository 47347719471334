<template>
	<div>
		<div class="col-12">
			<BaseCard class="chart">
				<template v-slot:header>
					<h4>
						{{ chartTitle }}
						<div v-if="showLastUpdate && lastUpdateLeft" class="last-update">
							Last update: {{ lastUpdateFormatted }}
						</div>
					</h4>
					<div :class="['detail-card-header', { 'hide-content': !showDateFilter }]">
						<div v-if="showLastUpdate && !lastUpdateLeft" class="last-update">
							Last update: {{ lastUpdateFormatted }}
						</div>
						<template v-if="showDateFilter">
							<BaseDatePicker
								v-model="pickerValue"
								:min-date="filterOptions.date.picker.minDate"
								:max-date="filterOptions.date.picker.maxDate"
								:mode="filterOptions.date.picker.mode"
								size="small"
								position="right"
								class="custom-date-picker"
								@done="handleDatePickerSelected"
							/>
							<span>
								{{ filterOptions.date.condition || "or" }}
							</span>
							<BaseDropdown
								:value="dropdownValue"
								:list="filterOptions.date.dropdown.items"
								:disabledItem="filterOptions.date.dropdown.disabledItems"
								:searchable="false"
								size="small"
								:placeholder="`${filterOptions.date.dropdown.placeholder || 'View by'}`"
								class="custom-date-dropdown"
								@input="handleDateDropdownSelected"
							/>
						</template>
					</div>
				</template>
				<template v-slot:body>
					<div v-if="isLoading" class="loading-wrapper">
						<BaseLoading />
					</div>
					<BaseNoData
						v-if="isEmpty"
						class="graph"
						description="BIG portal will start showing the graph <br/> right after we have information"
					/>
					<div
						v-else-if="!isLoading"
						class="chart-body"
					>
						<div
							class="chart-title"
							v-if="kwhData"
						>
							<div class="counter">{{ kwhData | currency }}</div>
							<div class="unit">Meter counter</div>
						</div>
						<div
							class="chart-title"
							v-if="timeDisplay"
						>

							<div class="counter">
								<button
									v-if="isShowBackwardForward"
									@click="$emit('backward')"
									:disabled="isDisableBackward"
									class="icon-button"
								>
									<font-awesome-icon
										:icon="['far', 'chevron-circle-left']"
										class="icon"
									/>
								</button>
								{{ timeDisplay }}
								<button
									v-if="isShowBackwardForward"
									@click="$emit('forward')"
									:disabled="isDisableForward"
									class="icon-button"
								>
									<font-awesome-icon
										:icon="['far', 'chevron-circle-right']"
										class="icon"
									/>
								</button>
							</div>
							<div class="unit">Time displayed</div>
						</div>
						<LineChart :chartData="chartData" />
					</div>
				</template>
			</BaseCard>
		</div>
	</div>
</template>

<script>
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import dayjs from "dayjs";
import LineChart from "./LineChart.vue";
import BaseCard from "../BaseCard.vue";
import { getDateNumber } from "../../selectors/helpers";

export default {
	components: {
		LineChart,
		BaseCard,
		BaseNoData: () => import("../BaseNoData.vue"),
		BaseLoading: () => import("../BaseLoading.vue"),
		BaseDatePicker
	},
	props: {
		isEmpty: {
			type: Boolean,
			default: false
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		lastUpdate: {
			type: String,
			default: ""
		},
		lastUpdateLeft: {
			type: Boolean,
			default: false
		},
		chartTitle: {
			type: String,
			default: "Delivery"
		},
		chartData: {
			type: Object,
			default: () => ({ xAxis: [], yAxis: [] })
		},
		filterOptions: {
			type: Object,
			default: () => ({})
		},
		kwhData: {
			type: String,
			default: ""
		},
		showDateTimeFilter: {
			type: Boolean,
			default: false
		},
		timeDisplay: {
			type: String,
			default: ""
		},
		selectedDropdown: {
			type: [Number, String],
			default: ""
		},
		isDisableBackward: {
			type: Boolean,
			default: false
		},
		isDisableForward: {
			type: Boolean,
			default: false
		},
		isShowBackwardForward: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			pickerValue: null,
			dropdownValue: null
		};
	},
	computed: {
		showDateFilter() {
			return this.filterOptions.date !== undefined;
		},
		showLastUpdate() {
			return this.lastUpdateFormatted !== "";
		},
		lastUpdateFormatted() {
			const format = "D MMM YYYY, HH:mm";
			const { lastUpdate } = this;
			return lastUpdate
				? dayjs(lastUpdate).format(format)
				: "";
		}
	},
	created() {
		if (this.showDateFilter) {
			const rangeDate = this.filterOptions.date.selected.range;
			const dateNumber = getDateNumber(rangeDate);
			this.dropdownValue = dateNumber;
		}
	},
	watch: {
		selectedDropdown: {
			handler(value) {
				this.dropdownValue = value;
			}
		}
	},

	methods: {
		handleDatePickerSelected(date) {
			this.dropdownValue = null;
			this.$emit("onSelectDatePicker", date);
		},
		handleDateDropdownSelected(dateNumber) {
			if (dateNumber) {
				if (!this.filterOptions?.date.condition) {
					this.pickerValue = null;
				}
				this.dropdownValue = dateNumber;
				this.$emit("onSelectDropDown", dateNumber);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.detail-card-header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: rem(16);

	@include mobile {
		align-items: flex-start;
		flex-direction: column;
		margin-bottom: 0;
		width: 100%;
	}

	& > * {
		margin-left: rem(10);

		@include mobile {
			margin-left: 0;
		}
	}

	span {
		color: $color-grey-4;
	}

	> span {
		@include mobile {
			display: none;
		}
	}

	.custom-date-picker {
		@include mobile {
			margin-bottom: rem(12);
		}
	}
}

.loading-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: rem(318);
}

.last-update {
	min-width: rem(170);
	font-family: $font-family-base;
	font-size: rem(18);
	font-style: italic;
	color: $color-dark-blue-grey;
	margin-right: rem(14);
}

h4 .last-update {
	margin-left: rem($spacing-3);
	font-weight: normal;
}

.custom-date-picker {
	min-width: rem(200);

	@include mobile {
		width: 100%;
	}

	/* stylelint-disable */
	&::v-deep .input {
		border-color: $color-silver;

		&, &::placeholder {
			color: $color-grey-4;
			font-size: $font-16;
			font-weight: $font-weight-bold;
		}
	}
	/* stylelint-enable */
}

.custom-date-dropdown {
	width: rem(130);

	@include mobile {
		width: 100%;
	}

	/* stylelint-disable */
	&::v-deep .dropdown-value {
		border-color: $color-silver;
		background-color: $color-white;
		padding: 0 rem(40) 0 rem(16);
	}

	&::v-deep .dropdown-value-text, &::v-deep .dropdown-placeholder {
		color: $color-grey-4;
		font-size: $font-16;
		font-weight: $font-weight-bold;
	}
	/* stylelint-enable */
}

.chart-body {
	display: flex;
	flex-direction: column;
	position: relative;

	.chart-title {
		position: absolute;
		text-align: center;
		left: 50%;
		transform: translate(-50%, 0);
		color: $color-dark-blue-grey;

		.counter {
			font-family: DBHeavent;
			font-size: $font-48;
			font-weight: $font-weight-bold;
			letter-spacing: rem(0.5);
			line-height: 0.5;
		}

		.icon-button {
			border: none;
			background: none;
			color: $color-gunmetal-two;

			&:disabled {
				color: $color-grey-4;
				cursor: not-allowed;
			}

			.icon {
				font-size: $font-14;

				vertical-align: middle;
			}
		}
	}
}

.hide-content {
	display: none !important;
}
</style>
