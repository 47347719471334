<template>
	<div :class="wrapperClass">

		<NitroFASDevice
			v-if="item.type === MODULE_TYPES.NITRO_FAS"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<TankDevice
			v-else-if="item.type === MODULE_TYPES.TANK"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<MeterDevice
			v-else-if="item.type === MODULE_TYPES.METER"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<WaterDevice
			v-else-if="item.type === MODULE_TYPES.ZLD2"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<WastWaterDevice
			v-else-if="item.type === MODULE_TYPES.O2_AERATION"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<WaterDevice
			v-else-if="item.type === MODULE_TYPES.ZLD"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<CerDevice
			v-else-if="item.type === MODULE_TYPES.CER"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<PowerDevice
			v-else-if="item.type === MODULE_TYPES.PWR"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>

		<NotFound
			v-else
			:data="item"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import TankDevice from "@/components/monitor/deviceHealth/device/TankDevice.vue";
import MeterDevice from "@/components/monitor/deviceHealth/device/MeterDevice.vue";
import WaterDevice from "@/components/monitor/deviceHealth/device/WaterDevice.vue";
import CerDevice from "@/components/monitor/deviceHealth/device/CerDevice.vue";
import WastWaterDevice from "@/components/monitor/deviceHealth/device/WastWaterDevice.vue";
import NitroFASDevice from "@/components/monitor/deviceHealth/device/NitroFASDevice.vue";
import PowerDevice from "@/components/monitor/deviceHealth/device/PowerDevice.vue";
import NotFound from "@/components/monitor/deviceHealth/device/NotFound.vue";

import { MODULE_TYPES } from "../../../enums/modules";
import { PAGE_NAME } from "../../../enums/pagePermission";

export default {
	name: "ModuleListItemDevice",

	components: {
		TankDevice,
		MeterDevice,
		WastWaterDevice,
		WaterDevice,
		NitroFASDevice,
		CerDevice,
		PowerDevice,
		NotFound
	},

	props: {
		item: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			MODULE_TYPES
		};
	},

	computed: {
		...mapState("sidebar", {
			isSidebarOpen: "isSidebarOpen"
		}),

		wrapperClass() {
			return [
				"col-sm-12",
				"col-md-6",
				"col-lg-6",
				this.isSidebarOpen ? "col-xl-4" : "col-xl-3"
			];
		},

		deviceLink() {
			let name = "";

			switch (this.item.type) {
				case MODULE_TYPES.TANK:
					name = PAGE_NAME.TANKINFO;
					break;

				case MODULE_TYPES.ZLD2:
					name = PAGE_NAME.ZLD_VINY_THAI_INFO;
					break;

				case MODULE_TYPES.METER:
					name = PAGE_NAME.METERINFO;
					break;

				case MODULE_TYPES.ZLD:
					name = PAGE_NAME.ZLDINFO;
					break;

				case MODULE_TYPES.CER:
					name = PAGE_NAME.CERINFO;
					break;

				case MODULE_TYPES.NITRO_FAS:
					name = PAGE_NAME.NITRO_FAS_INFO;
					break;

				case MODULE_TYPES.O2_AERATION:
					name = PAGE_NAME.O2_AERATION_INFO;
					break;

				case MODULE_TYPES.GENGAS:
					name = PAGE_NAME.GENGAS_INFO;
					break;

				case MODULE_TYPES.PWR:
					name = PAGE_NAME.PWR_INFO;
					break;

				case MODULE_TYPES.SOR:
					name = PAGE_NAME.SOR_INFO;
					break;

				case MODULE_TYPES.LNG:
					name = PAGE_NAME.LNG_INFO;
					break;

				default:
					break;
			}

			return {
				name,
				params: { id: this.item.id }
			};
		},
		iconSize() {
			const screenWidth = window.innerWidth
						|| document.documentElement.clientWidth
						|| document.body.clientWidth;

			if (screenWidth < 1200 || this.isSidebarOpen) {
				return "small";
			}

			return "smallest";
		}
	}
};
</script>
