<template>
	<div>
		<BaseLoading v-if="genGasInfo.isLoading" />
		<div :class="[{'row row-eq-height': isShowShipTo}, { 'hide-content': genGasInfo.isLoading }]">
			<div :class="['col-12 no-gutter-sm', { 'col-xl-7': isShowShipTo }]">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ genGasInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview
							v-if="genGasInfo.data.tagNo"
							:data="genGasInfo.data"
						/>
						<BaseNoData
							v-else
							description="BIG portal will start showing the overview right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
			<ModuleLocation v-if="isShowShipTo" :info="genGasInfo.data" />
		</div>
		<div class="gengas-level-container">
			<CardLineChart
				v-if="isShowFlowRate"
				:chartData="getGasFlowRateChart"
				:lastUpdate="genGasFlowRate.lastUpdate"
				:isLoading="genGasFlowRate.isLoading"
				:isEmpty="genGasFlowRate.isEmpty"
				:filterOptions="genGasFlowRateFilterOptions"
				chartTitle="Gas flow rate"
				@onSelectDatePicker="handleFlowRateDateSelected"
				@onSelectDropDown="handleGenGasFlowRateSelectDateDropDown"
			/>
			<CardBarChart
				v-if="isShowConsumption"
				:chartData="getConsumptionConfig"
				:lastUpdate="genGasConsumption.lastUpdate"
				:isLoading="genGasConsumption.isLoading"
				:filterOptions="genGasConsumptionFilterOption"
				class="gengas-consumption-chart-wrapper"
				chartTitle="Consumption"
				@dateSelected="handleConsumptionDateSelected"
			>
			</CardBarChart>
			<GrafanaDisplay
				:deviceId="genGasId"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import { transformGenGasFlowRateLineChartConfig } from "../../selectors/transform/lineChart/GenGasInfo/genGasFlowRate";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { getDateNumber, getDateDiff } from "../../selectors/helpers";
import {
	getConsumptionDateRange,
	transformConsumptionDataForBarChart,
	transformConsumptionStackBarChart
} from "../../selectors/transform/barChart/GenGasInfo/trasnformStackData";
import { tranfomGenGasConsumptionConfig } from "../../selectors/transform/barChart/GenGasInfo/genGasConsumptionBarChart";

const {
	perDay,
	perThreeDay,
	perWeek,
	perTwoWeek,
	perYear,
	perMonth
} = MODULE_SORT_DATE_CHART;

export default {
	name: "genGasInfo",
	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		BaseNoData,
		ModuleLocation,
		ModuleOverview,
		CardLineChart,
		CardBarChart
	},
	data() {
		return {
			genGasId: this.$route.params.id,
			genGasFlowRateSortBy: perDay,
			genGasFlowRateFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(30 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 1 month",
								value: perMonth
							}
						]
					}
				}
			},

			genGasConsumptionFilterOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			},

			genGasConsumptionFilter: perWeek
		};
	},

	computed: {
		...mapState(["genGasInfo", "genGasFlowRate", "genGasConsumption"]),
		...mapGetters("genGasInfo", {
			getLayout: "getLayout"
		}),
		...mapGetters("genGasFlowRate", {
			getGenGasFlowRateData: "getGenGasFlowRateData"
		}),
		...mapGetters("genGasConsumption", {
			getConsumptionData: "getConsumptionData",
			getBaseDatas: "getBaseDatas",
			getPremiumDatas: "getPremiumDatas"
		}),

		isShowShipTo() {
			return this.getLayout.shipToLocation;
		},

		isShowFlowRate() {
			return this.getLayout.flowRate;
		},

		isShowConsumption() {
			return this.getLayout.consumption;
		},

		genGasFlowRateDateSelected() {
			const dateRange = this.genGasFlowRateFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		getGasFlowRateChart() {
			const genGasFlowRateSortBy = this.genGasFlowRateSortBy;
			const { diff } = this.genGasFlowRateDateSelected;
			const result = transformGenGasFlowRateLineChartConfig(
				genGasFlowRateSortBy,
				this.getGenGasFlowRateData,
				{
					diffDay: diff
				}
			);

			return result;
		},

		getConsumptionConfig() {
			const baseBarConfig = {
				color: "#ea7125"
			};
			const premiumBarConfig = {
				color: "#ffcb00"
			};
			const mergeData = transformConsumptionDataForBarChart(this.getConsumptionData);

			const dateRange = getConsumptionDateRange(this.getConsumptionData);

			const consumptionBarChartConfig = transformConsumptionStackBarChart(
				baseBarConfig,
				premiumBarConfig
			)({
				firstBarData: this.getBaseDatas,
				secondBarData: this.getPremiumDatas,
				dateRange,
				mergeDataValue: mergeData,
				dateReportFormat: this.genGasConsumptionFilter
			});

			const genGasConsumptionConfig = tranfomGenGasConsumptionConfig(consumptionBarChartConfig);

			return genGasConsumptionConfig;
		},

		genGasConsumptionSelected() {
			const dateRange = this.genGasConsumptionFilterOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		}
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("genGasFlowRate", {
			fetchGenGasFlowRate: "fetchGenGasFlowRate"
		}),
		...mapActions("genGasConsumption", {
			fetchGenGasConsumption: "fetchGenGasConsumption"
		}),
		fetchData() {
			return Promise.all([
				this.fetchGenGasFlowRate({ id: this.genGasId, query: { filter: this.genGasFlowRateDateSelected.dateNumber } }),
				this.fetchGenGasConsumption({ id: this.genGasId, filterBy: this.genGasConsumptionSelected.dateNumber })
			]);
		},
		handleFlowRateDateSelected(date) {
			const { start, end } = date;

			this.genGasFlowRateFilterOptions.date.selected.range = {
				start,
				end
			};

			this.genGasFlowRateSortBy = "";

			this.fetchGenGasFlowRate({
				id: this.genGasId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},

		handleGenGasFlowRateSelectDateDropDown(filterBy = "1d") {
			this.genGasFlowRateSortBy = filterBy;

			this.fetchGenGasFlowRate({
				id: this.genGasId,
				query: { filter: filterBy }
			});
		},

		handleConsumptionDateSelected(date) {
			const { genGasId } = this;

			this.genGasConsumptionFilterOption.date.selected.range = date;
			this.genGasConsumptionFilter = this.genGasConsumptionSelected.dateNumber;

			this.fetchGenGasConsumption({
				id: genGasId,
				filterBy: this.genGasConsumptionSelected.dateNumber
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^="col"]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.gengas-consumption-chart-wrapper {
	/* stylelint-disable */
	&:v-deep {
		.gengas-consumption-section {
			display: flex;
			flex-direction: column;
			width: rem(150);
		}

		.base-section,
		.premium-section {
			@include fontBase();

			display: flex;
			justify-content: space-between;
			font-size: rem(16);
		}
	}
	/* stylelint-enable */
}

.gengas-level-container {
	@include mobile {
		border-radius: 0;
		margin-left: rem(-16);
		margin-right: rem(-16);
		padding: rem(16) 0;
	}

	/* stylelint-enable */
	::v-deep {
		.detail-card-header {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: rem(16);

			@include mobile {
				align-items: flex-start;
				flex-direction: column;
				padding: 0 rem(16);

				& > * {
					margin-left: 0;
				}

				.button-group-container {
					width: 100%;

					.btn {
						justify-content: center;
						width: calc(100% / 3);
					}
				}
			}
		}
	}
	/* stylelint-enable */
}
</style>
