<template>
	<modal :name="modalName" :scrollable="true" width="450" height="auto" @before-open="beforeOpen">
		<BaseModalContent rounded>
			<template v-slot:header>
				Create new diagram
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div :class="['container-form', { active: state.dropdownShipToIdActive }]">
					<div class="item">
						<div class="label">
							Select Ship-to
						</div>
						<div class="form">
							<BaseDropdown
								:list="shipToOptions"
								:value="form.shipToId"
								:searchable="true"
								:dropdownListContainerHeight="100"
								:placeholder="`Please select ship to`"
								rounded
								@input="handleShipToChange"
								@onActiveChange="handleActiveChange"
							/>
						</div>
					</div>
					<div class="item wrapper-helper-text">
						<div class="label"></div>
						<div class="form">
							<p class="helper-text">
								*Ship-to list contains only ship-to that have available devices for diagram
							</p>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					size="large"
					@click="handleSubmit"
					:disabled="disabledButton"
					rounded
				>
					Create
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},
		shipTos: {
			type: Array,
			default: () => ([])
		}
	},

	data() {
		return {
			state: {
				dropdownShipToIdActive: false
			},
			form: {
				shipToId: null
			}
		};
	},

	computed: {
		shipToOptions() {
			const items = this.shipTos.map((item) => ({
				label: `${item.name} (${item.key} - ${item.code})`,
				value: item.id
			}));
			return [
				...items
			];
		},
		disabledButton() {
			return this.form.shipToId === null;
		}
	},

	methods: {
		beforeOpen() {
			this.form.shipToId = null;
			this.state.dropdownShipToIdActive = false;
		},
		handleSubmit() {
			this.$emit("onSubmit", this.form);
		},
		handleShipToChange(value) {
			this.form.shipToId = value;
		},
		handleActiveChange(isActive) {
			this.state.dropdownShipToIdActive = isActive;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	&.active {
		margin-bottom: rem(70);
	}

	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			align-items: center;
			min-width: rem(105);
		}

		.form {
			flex-grow: 1;
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}

.wrapper-helper-text {
	margin-top: -16px;

	.helper-text {
		font-family: $font-family-base;
		font-size: $font-16;
		color: $color-grey-4;
		line-height: rem(16);
		margin: 0;
	}
}
</style>