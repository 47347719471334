import dayjs from "dayjs";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";

const { perWeek, perMonth, perSixMonth, perYear } = MODULE_SORT_DATE_CHART;


export const transformXaxisDateFormat = (sortDateBy = perWeek) => {
	const filterData = {
		[perWeek]: (date) => {
			const isFirstMonth = dayjs(date).get("month") === 0;
			const isFirstDay = dayjs(date).get("date") === 1;
			if (isFirstDay && isFirstMonth) {
				return dayjs(date).format("D MMM\nYYYY");
			}
			return dayjs(date).format("D MMM");
		},

		[perMonth]: (date) => {
			const isFirstMonth = dayjs(date).get("month") === 0;
			const isFirstDay = dayjs(date).get("date") === 1;

			if (isFirstDay && isFirstMonth) {
				return dayjs(date).format("D\nMMM YYYY");
			}
			if (isFirstDay) {
				return dayjs(date).format("D\nMMM");
			}
			return dayjs(date).format("D");
		},

		[perSixMonth]: (date) => {
			const isFirstMonth = dayjs(date).get("month") === 0;

			if (isFirstMonth) {
				return dayjs(date).format("MMM\nYYYY");
			}
			return dayjs(date).format("MMM");
		},

		[perYear]: (date) => {
			const isFirstMonth = dayjs(date).get("month") === 0;
			if (isFirstMonth) {
				return dayjs(date).format("MMM\nYYYY");
			}
			return dayjs(date).format("MMM");
		}
	};
	return filterData[sortDateBy];
};



export const transformDataStackBarChart = (
	firstBarConfig = {
		color: "#3bccec"
	},
	secondBarConfig = {
		color: "#009a44"
	}
) => ({
	firstBarData = [],
	secondBarData = [],
	dateRange = [],
	mergeDataValue = [],
	dateReportFormat = "years",
	show = "all"
}) => {
	const transformdate = transformXaxisDateFormat(dateReportFormat);
	const isShowCustomer = show === "customer";
	const firstData = {
		name: "Customer",
		type: "bar",
		stack: "one",
		barWidth: "12px",
		color: firstBarConfig.color,
		data: firstBarData.reduce((acc, { value }, index) => {
			const isValueGraterThanZero = Number(value) > 0;
			return [
				...acc,
				{
					value: Number(value),
					itemStyle: {
						color: firstBarConfig.color,
						...(isShowCustomer ? {
							barBorderRadius: isValueGraterThanZero ? [50, 50, 0, 0] : [0, 0, 50, 50]
						} : {})
					},
					...mergeDataValue[index]
				}
			];
		}, [])
	};

	const secondData = {
		name: "BIG Staff",
		type: "bar",
		stack: "one",
		barWidth: "12px",
		color: secondBarConfig.color,
		data: secondBarData.reduce((acc, { value }, index) => {
			const isValueGraterThanZero = Number(value) > 0;
			return [
				...acc,
				{
					value: Number(value),
					itemStyle: {
						color: secondBarConfig.color,
						barBorderRadius: isValueGraterThanZero ? [50, 50, 0, 0] : [0, 0, 50, 50]
					},
					...mergeDataValue[index]
				}
			];
		}, [])
	};
	const mapYaxis = {
		all: [
			{
				...firstData
			},
			{
				...secondData
			}
		],
		customer: [
			{
				...firstData
			}
		],
		staff: [
			{
				...secondData
			}
		]
	};

	return {
		xAxis: dateRange.map((dateValue) => transformdate(dateValue)),
		yAxis: mapYaxis[show]
	};
};
