import {
	getStaffsAPI,
	getStaffAPI,
	updateStaffsRoleAPI,
	deleteStaffAPI,
	postStaffPermissionAPI
} from "../../../services/api/staffManageApi";
import {
	STAFF_LIST_SUCCESS,
	STAFF_LIST_REQUEST,
	STAFF_LIST_FAILURE,
	STAFF_DETAIL_SUCCESS,
	STAFF_DETAIL_REQUEST,
	STAFF_DETAIL_FAILURE,
	POST_STAFF_PERMISSION_REQUEST,
	POST_STAFF_PERMISSION_SUCCESS,
	POST_STAFF_PERMISSION_FAILURE
} from "../../types";
import { getTransformStaffs } from "../../../selectors/transform/staff";
import { trasnformSoldToOptions } from "../../../selectors/transform/devicePermission";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		},
		detail: {
			data: {},
			permissions: {
				views: {
					soldTos: [
					],
					shipTos: [
					],
					applications: [
					],
					devices: [
					]
				},
				autoUpdate: {
					soldTos: false,
					shipTos: false,
					devices: false
				},
				all: {
					soldTos: false,
					shipTos: false,
					applications: false,
					devices: false
				}
			},
			isLoading: false
		}
	},
	getters: {
		getTransformStaffs(state) {
			return getTransformStaffs(state.data);
		},
		getStaffSoldToValues(state) {
			return trasnformSoldToOptions(state.detail.permissions.views.soldTos);
		},
		getStaffShipToValues(state) {
			return state.detail.permissions.views.shipTos;
		},
		getStaffApplication(state) {
			return state.detail.permissions.views.applications || [];
		},
		getStaffApplicationValues(state) {
			return state.detail.permissions.views.applications.reduce((acc, next) => {
				return [
					...acc,
					{
						code: next,
						name: next.toUpperCase(),
						key: next.toUpperCase()
					}
				];
			}, []);
		},
		getStaffDeviceValues(state) {
			return state.detail.permissions.views.devices.reduce((acc, { id, productId, shipId, app, shipToId, ...props }) => {
				const newValue = {
					id,
					code: id,
					name: `${productId} - ${shipId}`,
					key: `${productId} - ${shipId}`,
					shipToId,
					app,
					...props
				};
				return [
					...acc,
					newValue
				];
			}, []);
		},
		getIsUpdateShipTo(state) {
			return state.detail.permissions.autoUpdate.shipTos;
		},
		getIsUpdateDeivce(state) {
			return state.detail.permissions.autoUpdate.devices;
		},
		isAllSoldTo(state) {
			return state.detail.permissions.all.soldTos;
		},
		isAllShipTo(state) {
			return state.detail.permissions.all.shipTos;
		},
		isAllApplication(state) {
			return state.detail.permissions.all.applications;
		},
		isAllDevice(state) {
			return state.detail.permissions.all.devices;
		}
	},
	mutations: {
		[STAFF_LIST_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[STAFF_LIST_SUCCESS](state, { data, pagination }) {
			state.data = data;
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[STAFF_LIST_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[STAFF_DETAIL_REQUEST](state) {
			state.detail.data = {};
			state.detail.isLoading = true;
		},
		[STAFF_DETAIL_SUCCESS](state, data) {
			state.detail.data = data;
			state.detail.permissions = data.permissions;
			state.detail.isLoading = false;
		},
		[STAFF_DETAIL_FAILURE](state) {
			state.detail.data = {};
			state.detail.isLoading = false;
		},
		[POST_STAFF_PERMISSION_REQUEST](state) {
			state.detail.isLoading = true;
		},
		[POST_STAFF_PERMISSION_SUCCESS](state, data) {
			state.detail.permissions = data.permission;
		},
		[POST_STAFF_PERMISSION_FAILURE](state) {
			state.detail.isLoading = false;
		}
	},

	actions: {
		async fetchStaffLists({ commit }, query) {
			try {
				commit(STAFF_LIST_REQUEST);

				const { data } = await getStaffsAPI({ ...query });

				commit(STAFF_LIST_SUCCESS, data);
			} catch (e) {
				commit(STAFF_LIST_FAILURE);
			}
		},

		async fetchStaffDetail({ commit }, id) {
			try {
				commit(STAFF_DETAIL_REQUEST);
				const { data } = await getStaffAPI(id);
				commit(STAFF_DETAIL_SUCCESS, data);
			} catch (e) {
				commit(STAFF_DETAIL_FAILURE);
			}
		},

		async updateStaffRole(context, { id, data }) {
			await updateStaffsRoleAPI(id, data);
		},

		async deleteStaffRole(context, { id }) {
			await deleteStaffAPI(id);
		},

		async postStaffPermission({ commit }, params) {
			try {
				commit(POST_STAFF_PERMISSION_REQUEST);
				const { data } = await postStaffPermissionAPI(params);
				commit(POST_STAFF_PERMISSION_SUCCESS, data);
			} catch (e) {
				commit(POST_STAFF_PERMISSION_FAILURE);
			}
		}
	}
};
