/**
 * Enum for master data log icons
 * @readonly
 * @enum {EnumMasterDataLogIcons}
 */
export const INVOICE_STATUS = Object.freeze([
	{ value: null, label: "Status: All" },
	{ value: "IN PROCESS", label: "IN PROCESS" },
	{ value: "APPROVED", label: "APPROVED" },
	{ value: "PAID", label: "PAID" },
	{ value: "PAID (SAP)", label: "PAID (SAP)" },
	{ value: "CANCEL", label: "CANCEL" },
	{ value: "REJECT", label: "REJECT" },
	{ value: "ERROR", label: "ERROR" },
	{ value: "PARTIAL", label: "PARTIAL" },
	{ value: "OVERDUE", label: "OVERDUE" },
	{ value: "TEMPORARY BATCH", label: "TEMPORARY BATCH" }
]);