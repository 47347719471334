import Numeral from "numeral";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import { UNIT_TYPE, UNIT_DISPLAY_TYPE } from "../../../../enums/unit";
import { transformBarChart, getDateReportFormat, tooltipTransformDate } from "../../../../selectors/transform/barChart";

const {
	perThirtyDays,
	perNinetyDays
} = MODULE_SORT_DATE_CHART;

export const transformBarChartTankGasConsumptionData = (state, unitValue) => {
	const indexSelectValue = unitValue === "l" ? "kg" : unitValue; // this line for check if client select liter value change to kg value (cuz api send only 3 values -> sm3, nm3, kg)
	const { data = [] } = state;
	const xAxis = [];
	const yAxis = [];
	for (const val of data) {
		xAxis.push(val.date);
		yAxis.push(val.value[indexSelectValue] || 0);
	}
	return {
		xAxis,
		yAxis
	};
};

const optionTankBarChart = (yAxisText, displayUnit) => ({
	yAxisText,
	series: {
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#103332",
			axisPointer: {
				type: "none"
			},
			axisLabel: {
				rotate: 40
			},
			padding: 8,
			formatter: ([{ value, name }]) => {
				return `
                    <div>
                        <div class="bar-name meter">
                            ${name}
                        </div>
                        <div class="bar-value">
                            ${Numeral(value).format(0, 0)} ${displayUnit}
                        </div>
                    </div>
                `;
			}
		}
	}
});

const optionTankBarChart30days = (yAxisText, displayUnit, isMobile) => ({
	yAxisText,
	xAxisLabel: {
		interval: isMobile ? 5 : 1
	},
	series: {
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#103332",
			axisPointer: {
				type: "none"
			},
			axisLabel: {
				rotate: 40
			},
			padding: 8,
			formatter: ([{ value, name }]) => {
				return `
                    <div>
                        <div class="bar-name meter">
                            ${name}
                        </div>
                        <div class="bar-value">
                            ${Numeral(value).format(0, 0)} ${displayUnit}
                        </div>
                    </div>
                `;
			}
		}
	}
});

const optionTankBarChart90days = (yAxisText, displayUnit, isMobile) => ({
	yAxisText,
	xAxisLabel: {
		interval: isMobile ? 15 : 4
	},
	series: {
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#103332",
			axisPointer: {
				type: "none"
			},
			axisLabel: {
				rotate: 40
			},
			padding: 8,
			formatter: ([{ value, name }]) => {
				return `
                    <div>
                        <div class="bar-name meter">
                            ${name}
                        </div>
                        <div class="bar-value">
                            ${Numeral(value).format(0, 0)} ${displayUnit}
                        </div>
                    </div>
                `;
			}
		}
	}
});

export const transformTankConsumptionDataBarChart = (response, unitValue, options) => {
	const indexSelectValue = unitValue === "l" ? "kg" : unitValue; // this line for check if client select liter value change to kg value (cuz api send only 3 values -> sm3, nm3, kg)
	const defaultOptions = {
		dateReportFormat: "year",
		barColor: "#ea7125",
		barSize: 40
	};
	const mergedOptions = {
		...defaultOptions,
		...options
	};
	const { data = [] } = response;
	const transformdate = getDateReportFormat(mergedOptions.dateReportFormat);
	const transformTooltipDate = tooltipTransformDate(mergedOptions.dateReportFormat);
	const defaultResult = {
		xAxis: [],
		yAxis: []
	};
	const result = data.reduce((acc, { date, value }) => {
		const isValueGraterThanZero = Number(value) > 0;
		return {
			xAxis: [...acc.xAxis, transformdate(date)],
			yAxis: {
				name: "value",
				type: "bar",
				barWidth: "50%",
				barMaxWidth: 40,
				data: [
					...(acc.yAxis.data || []),
					{
						name: transformTooltipDate(date),
						value: Number(value[indexSelectValue]),
						itemStyle: {
							color: mergedOptions.barColor,
							barBorderRadius: isValueGraterThanZero ? [2, 2, 0, 0] : [0, 0, 2, 2]
						}
					}
				]
			}
		};
	}, defaultResult);
	return result;
};

export const transformTankConsumptionBarChartConfig = (sortBy, data = {}, unit = "kg", isMobile = false) => {
	let displayUnit = "";

	switch (unit) {
		case UNIT_TYPE.NM3:
			displayUnit = UNIT_DISPLAY_TYPE.NM3;
			break;
		case UNIT_TYPE.SM3:
			displayUnit = UNIT_DISPLAY_TYPE.SM3;
			break;
		case UNIT_TYPE.LITER:
			displayUnit = UNIT_DISPLAY_TYPE.LITER;
			break;
		default:
			displayUnit = UNIT_DISPLAY_TYPE.KG;
			break;
	}

	const yAxisText = `Estimate consumption (${displayUnit})`;
	const mappingOption = {
		[perThirtyDays]: optionTankBarChart30days(yAxisText, displayUnit, isMobile),
		[perNinetyDays]: optionTankBarChart90days(yAxisText, displayUnit, isMobile)
	};

	const functionTransform = mappingOption[sortBy] || optionTankBarChart(yAxisText, displayUnit);
	const transformFunc = transformBarChart(functionTransform);

	return transformFunc(data);
};
