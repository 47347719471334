<template>
	<BaseToastContent
		:message="message"
		title="Success"
	/>
</template>

<script>
import BaseToastContent from "@/components/BaseToastContent.vue";

export default {
	name: "ToastStaffManagement",

	props: ["email"],

	components: {
		BaseToastContent
	},

	computed: {
		message() {
			return `${this.email} was removed from BIG portal successfully.`;
		}
	}
};
</script>