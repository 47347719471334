<template>
	<BaseLayout :isLoading="isLoading">
		<BaseBackBtn :to="backToEOrderingList" label="Back to order list" />
		<div class="main-order-create">
			<div class="title">
				Edit order
			</div>

			<ModuleEdit
				:item="form"
				:formCreateOrder.sync="form"
				@onRequestCancellation="handleRequestCancellation"
			/>

			<div class="wrapper-confirm-create">
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="showModalDiscard"
				>
					Cancel
				</BaseButton>

				<BaseButton
					class="btn-edit"
					size="large"
					type="primary"
					@click="handleConfirmEdit"
				>
					Confirm edit
				</BaseButton>
			</div>
		</div>

		<BaseModalConfirmDelete
			:modal-name="MODAL_DISCARD"
			title="Page discard"
			:message="modalMessageDiscard"
			submitButtonText="Confirm"
			@onCancel="handleModalCancel"
			@onConfirm="handleModalConfirm"
		/>

		<BaseModalConfirmDelete
			:modal-name="MODAL_CANCEL_ORDER"
			title="Cancel order"
			message="Are you sure to cancel order?"
			submitButtonText="Confirm"
			@onCancel="handleModalCancelRequest"
			@onConfirm="handleModalConfirmRequest"
		/>

		<ModalCancelOrderStatusNotNew
			:modal-name="MODAL_CANCEL_ORDER_STATUS_NOT_NEW"
			title="Cancel order"
			:message="modalMessageCancelOrder"
			@onBack="handleModalCancelOrderStatusNotNew"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModuleEdit from "@/components/eOrdering/ModuleEdit.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import ModalCancelOrderStatusNotNew from "@/components/eOrdering/modal/ModalCancelOrderStatusNotNew.vue";
import { deleteEOrderingInfoAPI, putEOrderingInfoAPI } from "../../services/api/eOrdering.api";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { mapUnit } from "../../selectors/helpers/mapUnit";
import { TIME_TYPE, TYPE_FROM_TIME } from "../../enums/eOrdering/timeType";

export default {
	name: "EOrderingCreate",

	components: {
		BaseBackBtn,
		ModuleEdit,
		BaseModalConfirmDelete,
		ModalCancelOrderStatusNotNew
	},

	data() {
		return {
			isLoading: true,
			MODAL_DISCARD: "model-dicard",
			MODAL_CANCEL_ORDER: "model-cancel-order",
			MODAL_CANCEL_ORDER_STATUS_NOT_NEW: "modal-cancel-order-status-not-new",
			form: {
				orderNo: this.$route.params.id,
				shipTo: "",
				product: "",
				tankNo: "",
				volume: 0,
				deliveriesDate: null,
				poNo: "",
				note: "",
				isUrgent: false,
				date: null,
				time: "",
				minimumVolume: 0,
				maximumVolume: 0,
				selectedVolume: null,
				status: "",
				unit: "Kg"
			},
			deleteEOrderingInfoAPI,
			putEOrderingInfoAPI
		};
	},

	computed: {
		...mapState(["eOrderingInfo"]),

		backToEOrderingList() {
			return this.$route.meta.backTo.name;
		},

		modalMessageDiscard() {
			return `<div>
				<div>Are you sur to leave this page ? </div>
				<div>All  order information won’t be saved.</div>
			</div>`;
		},

		modalMessageCancelOrder() {
			return `<div>
				<div>This order has already been processed.</div>
				<div>To cancel order, please contact BIG to confirmation of cancel order.</div>
			</div>`;
		}
	},

	created() {
		this.fetchData();
	},

	methods: {
		...mapActions("eOrderingInfo", {
			postEOrderingCreate: "postEOrderingCreate",
			getEOrderingInfo: "getEOrderingInfo",
			getEOrderingConfiguration: "getEOrderingConfiguration"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		async fetchData() {
			await this.getEOrderingInfo(this.form.orderNo);
			await this.getEOrderingConfiguration();
			const sparateDateTime = this.eOrderingInfo.data.dateEdit.split(" ");
			const time = sparateDateTime[1];
			const date = sparateDateTime[0];
			const deliveriesDate = `${date} ${time}`;
			const unit = mapUnit(this.eOrderingInfo.data.device, this.eOrderingInfo.configuration);

			const timeType = TYPE_FROM_TIME[time] || TIME_TYPE.SPECIFIC_TIME;

			this.form = {
				orderNo: this.$route.params.id,
				time: this.eOrderingInfo.data.dateEdit,
				date,
				timeType,
				deliveriesDate,
				...this.eOrderingInfo.data,
				unit
			};

			this.isLoading = this.eOrderingInfo.isLoading;
		},

		showModalDiscard() {
			this.$modal.show(this.MODAL_DISCARD);
		},

		handleModalCancel() {
			this.$modal.hide(this.MODAL_DISCARD);
		},

		handleModalConfirm() {
			this.$router.push({
				name: PAGE_NAME.E_ORDERING_LIST
			});
			this.$modal.hide(this.MODAL_DISCARD);
		},

		async handleConfirmEdit() {
			try {
				const params = {
					amountType: this.form.selectedVolume,
					amount: parseFloat(this.form.volume),
					deliveryDate: this.form.deliveriesDate,
					isUrgent: this.form.isUrgent,
					poNumber: this.form.poNo,
					note: this.form.note
				};

				await this.putEOrderingInfoAPI(this.form.orderNo, params);

				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Your change has been saved",
						delay: 6000
					}
				});

				this.$router.push({
					name: this.$route.meta.backTo.name
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to save", message: "E-Ordering unable to save.", delay: 6000 }
				});
			}
		},

		handleBackToOrderList() {
			return this.$router.push({
				name: this.$route.meta.backTo.name
			});
		},

		handleRequestCancellation() {
			if (this.form.status === "NEW") {
				this.$modal.show(this.MODAL_CANCEL_ORDER);
			} else {
				this.$modal.show(this.MODAL_CANCEL_ORDER_STATUS_NOT_NEW);
			}
		},

		handleModalCancelRequest() {
			this.$modal.hide(this.MODAL_CANCEL_ORDER);
		},

		async handleModalConfirmRequest() {
			try {
				await this.deleteEOrderingInfoAPI(this.form.orderNo);

				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Your order has been cancelled",
						delay: 6000
					}
				});

				this.$router.push({
					name: this.$route.meta.backTo.name,
					query: { tabType: "history" }
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to cancel", message: "E-Ordering unable to cancel.", delay: 6000 }
				});
			}
			this.$modal.hide(this.MODAL_CANCEL_ORDER);
		},

		handleModalCancelOrderStatusNotNew() {
			this.$modal.hide(this.MODAL_CANCEL_ORDER_STATUS_NOT_NEW);
		}
	}
};
</script>
<style lang="scss" scoped>
.main-order {

	// .main-order-create
	&-create {
		display: flex;
		flex-direction: column;

		.title {
			font-family: $font-family-sub;
			font-size: $font-32;
			width: 100%;
			color: $color-dark-blue-grey;
		}

		.wrapper-confirm-create {
			display: flex;
			justify-content: flex-end;
			padding: rem(24) 0;
			border-top: 1px solid $color-silver;

			.btn-edit {
				border-radius: rem(8);
			}

			.btn-cancel {
				border-radius: rem(8);
				margin-right: rem(24);
			}
		}

		.table-order {
			display: flex;
			flex-direction: column;

			// .table-order-title-success
			&-title-success {
				display: flex;
				align-items: center;
				font-size: $font-24;
				font-weight: bold;
				font-family: $font-family-sub;
				color: $color-green-haze;

				svg {
					width: rem(16);
					height: rem(16);
					margin-left: rem(4);
				}
			}

			// .table-order-title-error
			&-title-error {
				display: flex;
				align-items: center;
				font-size: $font-24;
				font-weight: bold;
				font-family: $font-family-sub;
				margin-top: rem(80);
				color: $color-red;

				&-has-no-success {
					margin-top: 0;
				}

				svg {
					width: rem(16);
					height: rem(16);
					margin-left: rem(4);
				}
			}

			// .table-order-description
			&-description {
				// .table-order-description-success
				&-success {
					font-size: $font-20;
					color: $color-green-haze;
				}

				// .table-order-description-error
				&-error {
					font-size: $font-20;
					color: $color-red;
				}
			}
		}
	}
}
</style>
