<template>
	<BaseLayout pageTitle="BIG staff account">
		<div class="container-action-bar" v-if="isSuperAdmin">
			<router-link :to="{ name: PAGE_NAME.USERS_CREATE }">
				<BaseButton size="large">
					Create BIG staff account
				</BaseButton>
			</router-link>
		</div>
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:roles="roles"
					:types="types"
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>
			<BaseTableCustom
				:tableOptions="tableOptions"
				:pagination="staff.pagination"
				:loading="isLoading"
				@onPageChange="handlePageChange"
			>
				<template v-slot:thead>
					<tr>
						<th
							v-for="(value, index) in table.thead"
							:key="index"
						>
							{{ value }}
						</th>
					</tr>
				</template>
				<template v-slot:tbody>
					<tr
						v-for="(item, index) in table.tbody"
						:key="index"
						:class="[{ inactive: !item.isActive }]"
					>
						<td>
							{{ item.name }}
						</td>
						<td>
							<BaseBadge v-if="item.isTestAccount" text="Test" size="small" color="grey"/>
							{{ item.email }}
						</td>
						<td>
							{{ item.role }}
						</td>
						<td>
							<BaseBadge v-if="item.isActive" text="Active" size="small" color="success-dark"/>
							<BaseBadge v-else text="Inactive" size="small" outline/>
						</td>
						<td class="align-right">
							<router-link
								v-if="manageAble"
								:to="`/users/${item.id}`"
							>
								<button class="view-button">
									View detail
								</button>
							</router-link>
							<router-link
								v-if="manageAble"
								:to="{
									name: PAGE_NAME.ACTIVITYLOG,
									query: {
										page: 1,
										q: item.email,
										userType: 'staff',
									}
								}"
								target="_blank"
								class="ml-7"
							>
								<button class="view-button">
									View log
								</button>
							</router-link>
						</td>
					</tr>
				</template>
			</BaseTableCustom>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import SearchFilterForm from "@/components/usermanagement/SearchFilterForm.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import { cleanParams } from "../../selectors/helpers";
import { getStaffRolesFilter } from "../../selectors/transform/staff/staffRoledropdown";
import { getIsSuperAdmin } from "../../selectors/transform/staff";
import { TOAST_TYPES } from "../../enums/toast";
import { PAGE_NAME } from "../../enums/pagePermission";
import { STAFF_ROLES } from "../../enums/role";

export default {
	name: "Users",

	components: {
		SearchFilterForm,
		BaseTableCustom,
		BaseBadge
	},

	data() {
		return {
			TOAST_TYPES,
			PAGE_NAME,
			query: {
				page: this.$route.query.page || null,
				q: this.$route.query.q || null,
				role: this.$route.query.role || null,
				type: this.$route.query.type || null
			},
			tableOptions: [
				"is-striped is-fullwidth"
			],
			pagination: {
				currentPage: 1,
				lastPage: 5
			},
			isLoading: false
		};
	},

	computed: {
		...mapState(["staff", "user", "insertStaff"]),
		...mapGetters({
			getStaffs: "staff/getTransformStaffs",
			getProfile: "user/getProfile"
		}),
		roles() {
			return getStaffRolesFilter();
		},
		types() {
			return [
				{ label: "Type: All", value: null },
				{ label: "Normal", value: "normal" },
				{ label: "Test", value: "test" }
			];
		},
		table() {
			return {
				thead: [
					"Name",
					"Email",
					"System role",
					"Status",
					""
				],
				tbody: this.getStaffs
			};
		},
		isSuperAdmin() {
			return getIsSuperAdmin(this.user.role);
		},
		manageAble() {
			return [
				STAFF_ROLES.SUPER_ADMIN
			].includes(this.user.role);
		}
	},

	created() {
		this.fetchStaffs();
	},

	methods: {
		...mapActions({
			fetchStaffLists: "staff/fetchStaffLists",
			showToast: "toast/show"
		}),
		async fetchStaffs() {
			this.isLoading = true;
			const query = cleanParams(JSON.parse(JSON.stringify(this.query)));
			this.$router.push({ query }).catch(() => {});
			await this.fetchStaffLists(this.query);
			this.isLoading = false;
		},
		handleSubmitFilter(form = {}) {
			this.query.q = form.keyword ? form.keyword.trim() : form.keyword;
			this.query.role = form.role;
			this.query.type = form.type;
			this.query.page = 1;
			this.fetchStaffs();
		},
		handlePageChange(page = 1) {
			this.pagination.currentPage = page;
			this.query.page = page;
			this.fetchStaffs();
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}

table {
	thead tr:first-of-type {
		th:nth-child(1) {
			width: rem(220);

			svg {
				font-size: $font-14;
				margin: $spacing-1 0 0 $spacing-2;
				color: $color-light-teal;
				cursor: pointer;
			}
		}

		th:nth-child(2) {
			width: rem(200);
		}
	}
}

.ml-7 {
	margin-left: $spacing-7;
}
</style>
