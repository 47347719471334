import { getSorCurrentAPI } from "@/services/api/sor.api";
import { SOR_CURRENT_REQUEST, SOR_CURRENT_SUCCESS, SOR_CURRENT_FAILURE } from "../../types";
import { parsePowerCurrent } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parsePowerCurrent({}),
		loading: true,
		empty: false
	},

	mutations: {
		[SOR_CURRENT_REQUEST](state) {
			state.detail = parsePowerCurrent({});
			state.loading = true;
			state.empty = false;
		},
		[SOR_CURRENT_SUCCESS](state, data) {
			const detail = data;
			state.detail = parsePowerCurrent(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[SOR_CURRENT_FAILURE](state) {
			state.detail = parsePowerCurrent({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getSorCurrent({ commit }, { id, params }) {
			try {
				commit(SOR_CURRENT_REQUEST);
				const { data } = await getSorCurrentAPI(id, params);
				commit(SOR_CURRENT_SUCCESS, data);
			} catch (error) {
				commit(SOR_CURRENT_FAILURE, error);
			}
		}
	}
};
