<template>
	<BaseCard
		:bodyStyle="[
			{ display: 'grid' },
			{ padding: 0 },
		]"
	>
		<template #header>
			<h4>
				Activity log
				<router-link :to="{ name: PAGE_NAME.ACTIVITYLOG }" class="btn-link custom-btn-link">
					View all
				</router-link>
			</h4>
			<span class="last-update-text">
				Last 7 days
			</span>
		</template>
		<template #body>
			<BaseLoading
				v-if="isLoading"
				isFullSize
			/>
			<div
				v-else
				class="container-list"
			>
				<BaseTabPanel
					v-model="tabIndex"
					:items="tabs"
					class="custom-tab"
				/>
				<BaseNoData
					v-if="mapData.length === 0"
					class="empty"
					description="BIG portal will start showing the active customer right after we have information"
				/>
				<perfect-scrollbar
					v-else
					style="height: 317px;" :options="{ minScrollbarLength: 50 }"
				>
					<BaseListView class="custom-list-view">
						<component
							v-for="({ component: ListViewItemComponent, props }, index) in mapData"
							:key="index"
							:is="ListViewItemComponent"
							v-bind="{ ...props }"
						/>
					</BaseListView>
				</perfect-scrollbar>
			</div>
		</template>
	</BaseCard>
</template>

<script>
import dayjs from "dayjs";
import BaseCard from "@/components/BaseCard.vue";
import BaseListView from "@/components/BaseListView.vue";
import BaseListViewItemTime from "@/components/BaseListViewItemTime.vue";
import BaseListViewItemEvent from "@/components/BaseListViewItemEvent.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseTabPanel from "@/components/BaseTabPanel.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import { PAGE_NAME } from "../../../enums/pagePermission";
import { MASTERDATA_LOG_ACTIONS, MASTERDATA_LOG_ICONS } from "../../../enums/masterData";
import { getFriendlyDate, getFriendlyTime } from "../../../selectors/helpers";

export default {
	name: "ActivityLog",

	components: {
		BaseCard,
		BaseListView,
		BaseListViewItemTime,
		BaseListViewItemEvent,
		BaseLoading,
		BaseTabPanel,
		BaseNoData
	},

	props: {
		data: {
			type: Object,
			default: () => ({})
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			PAGE_NAME,
			tabIndex: 0,
			tabs: [
				{
					title: "BIG staff"
				},
				{
					title: "Customer"
				}
			]
		};
	},

	computed: {
		mapData() {
			const data = (() => {
				if (this.tabIndex === 0) {
					return this.data.staffs;
				}
				return this.data.customers;
			})();

			// this function use to group date into array.
			const groupDates = data.reduce((acc, item) => {
				const dateLocal = dayjs(item.loggedAt).format("DD-MM-YYYY");

				if (!acc.includes(dateLocal)) {
					acc.push(dateLocal);
				}

				return acc;
			}, []);

			// then reduce activity logs by group dates
			return data.reduce((acc, item) => {
				const dateLocal = dayjs(item.loggedAt).format("DD-MM-YYYY");

				if (groupDates.includes(dateLocal)) {
					groupDates.splice(0, 1);
					acc.push({
						component: BaseListViewItemTime,
						props: {
							time: getFriendlyDate(item.loggedAt)
						}
					});
				}
				const ACTION_TYPE = MASTERDATA_LOG_ACTIONS[item.action] || "OTHER";

				acc.push({
					component: BaseListViewItemEvent,
					props: {
						data: {
							icon: MASTERDATA_LOG_ICONS[ACTION_TYPE],
							email: item.email,
							detail: item.event,
							createdAt: getFriendlyTime(item.loggedAt, "HH:mm")
						}
					}
				});

				return acc;
			}, []);
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-btn-link {
	position: relative;
	top: rem(1);
	margin-left: rem(16);
	font-size: $font-16;
}

.last-update-text {
	font-size: $font-16;
	color: $color-grey-4;
}

.custom-tab {
	margin: rem(16) 0 rem(20) 0;
}

.custom-list-view {
	padding-left: rem(24);
	padding-right: rem(12);
}

.container-list {
	display: flex;
	flex-direction: column;
}

.empty {
	margin: rem(77) 0 rem(107) 0;
}

/* stylelint-disable */
.ps {
	&::v-deep .ps__rail-y {
		z-index: 2;
		margin-right: rem(8);
	}

	&::v-deep .ps__thumb-y {
		width: rem(5);
		background-color: $color-light-teal;
	}

	&::v-deep .ps__rail-y:hover, &::v-deep .ps--clicking {
		background-color: transparent;
	}
}
/* stylelint-enable */
</style>