<template>
	<div class="container-main">
		<div class="subtitle">
			Route
		</div>
		<div class="timeline">
			<ol class="timeline-list">
				<template
					v-for="(item, trackIndex) in tracks"
				>
					<li
						v-if="item.isInTransit"
						:key="trackIndex"
						:class="{'is-active': item.isActive }"
					>
						<div :class="['timeline-marker', {'is-active': item.isActive }]"></div>
						<div class="timeline-txt">{{ item.title }}</div>
					</li>
				</template>
			</ol>
		</div>
		<div class="estimate">
			<ul class="estimate-time">
				<li>
					<span class="label">ETA:</span> {{ estimateTime }}
				</li>
				<li>
					<span class="label">Duration:</span> {{ durationTime }}
				</li>
			</ul>
			<p class="helper-txt">* The estimated time of arrival (ETA) is the forecasted time for a transport system to arrive at its destination and is calculated based on distance, speed of travel, and road traffic.</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "Tacking",

	props: {
		tracks: {
			type: Array,
			default: () => [],
			validator: (tracks) => {
				const isValidTrack = ({ title }) => {
					return typeof title === "string";
				};
				return tracks.every(isValidTrack);
			}
		},
		duration: {
			type: String,
			default: "N/A"
		},
		estimateTimeStamp: {
			type: String,
			default: "N/A"
		},
		onTarget: {
			type: Number,
			default: 0
		},
		truckLocation: {
			type: Object,
			default: () => ({})
		}
	},

	computed: {
		estimateTime() {
			if (this.truckLocation === null || this.onTarget === 0) {
				return "N/A";
			}
			return this.estimateTimeStamp;
		},
		durationTime() {
			if (this.truckLocation === null || this.onTarget === 0) {
				return "N/A";
			}
			return this.duration;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-main {
	min-width: rem(286);

	.subtitle {
		@include fontSub();

		margin-bottom: $spacing-4;
		font-size: $font-20;
		font-weight: $font-weight-bold;
		color: $color-dark-blue-grey;
	}

	.timeline {
		&-txt {
			margin: 0;
			line-height: rem(16);
		}

		&-live {
			display: flex;

			> span {
				color: $color-dark-blue-grey;
				display: block;
				margin-left: rem(8);
				font-weight: bold;
				line-height: rem(16);
			}
		}

		&-list {
			.is-active {
				&::before {
					background-color: $color-green-haze;
				}
			}

			> li {
				display: flex;
				font-weight: bold;
				gap: rem(8);
				position: relative;
				padding: rem(8) 0;
				min-height: rem(65);

				&::before {
					content: "";
					width: rem(1);
					height: 100%;
					position: absolute;
					top: rem(8);
					left: calc(#{rem(8)} - #{rem(1)});
					background-color: $color-light-teal;
				}

				&:last-child::before {
					height: rem(16);
				}
			}
		}

		&-marker {
			width: rem(16);
			min-width: rem(16);
			height: rem(16);
			background-color: $color-light-teal;
			border-radius: 50%;

			&.is-active {
				background-color: $color-green-haze;
			}
		}
	}

	.estimate {
		margin-left: rem(24);

		&-time {
			background-color: $color-ice;
			border: rem(1) solid $color-light-teal;
			border-radius: rem(4);
			color: $color-dark-blue-grey;
			font-weight: bold;
			padding: rem(12) rem(24);
			margin-bottom: rem(4);

			.label {
				color: $color-grey-4;
				display: inline-block;
				min-width: rem(60);
			}
		}
	}

	.helper-txt {
		color: $color-grey-4;
		font-size: $font-14;
		margin: 0;
	}
}
</style>