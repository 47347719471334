import { mappingSolarLabel } from "../../enums/layoutCustomisation/solar";

export const parseSolarOverview = ({ layout }) => {
	const keysArr = Object.keys(layout.overview);
	const computeOverview = [];
	for (let i = 0; i < keysArr.length; i++) {
		computeOverview.push({
			key: keysArr[i],
			name: keysArr[i],
			title: mappingSolarLabel[keysArr[i]],
			titleIcon: keysArr[i] === "pf" ? `
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="21" viewBox="0 0 24 21">
                    <defs>
                        <path id="nt1p0mwzla" d="M0 0.135L23.971 0.135 23.971 20.444 0 20.444z"/>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g>
                            <g>
                                <g>
                                    <g transform="translate(-524 -578) translate(340 287) translate(184 291) translate(0 .16)">
                                        <mask id="h2ml5kw20b" fill="#fff">
                                            <use xlink:href="#nt1p0mwzla"/>
                                        </mask>
                                        <path fill="#A2A2A2" d="M17.74 19.5c-.379 0-.749-.3-1.109-.81 1.128-1.595 2.081-4.253 3.132-7.875h2.956c-1.613 5.514-2.981 8.685-4.98 8.685zm-3.94 0c-1.491 0-2.86-4.628-3.986-8.685h2.96c.878 3.176 1.7 6.049 2.71 7.814-.52.571-1.072.87-1.685.87zM3.075 4.57c.374-2.23.632-3.491 1.588-3.491.572 0 1.134.763 1.673 1.933-.092.443-.171.915-.253 1.402-.28 1.668-.596 3.548-1.451 5.266H1.738c.772-1.733 1.082-3.585 1.338-5.11zm5.445 5.11H5.677C6.433 7.985 6.747 6.175 7 4.668c.542 1.519 1.054 3.323 1.522 5.013zm.082-8.601c1.412 0 2.755 4.621 3.858 8.601H9.5c-.722-2.612-1.4-5.024-2.174-6.77.267-1.183.593-1.831 1.276-1.831zm10.176 9.735c-.916 3.133-1.754 5.51-2.673 6.977-.859-1.714-1.652-4.455-2.352-6.977h5.025zm5.192-.567c0-.241-.152-.446-.364-.527-.023-.012-.047-.022-.073-.03-.071-.02-.142-.021-.21-.01H19.54c-.053-.008-.104-.008-.154 0H13.44C12.015 4.521 10.757.135 8.603.135 7.602.135 7.054.75 6.7 1.688 6.104.711 5.443.135 4.664.135c-1.802 0-2.134 1.983-2.52 4.28-.28 1.668-.596 3.548-1.451 5.266H.567c-.313 0-.567.254-.567.567 0 .313.254.567.567.567h8.267c1.445 5.223 2.734 9.63 4.965 9.63.837 0 1.562-.344 2.22-1.013.512.646 1.077 1.012 1.72 1.012 2.643 0 4.167-3.419 5.994-9.737.144-.102.238-.27.238-.46z" mask="url(#h2ml5kw20b)"/>
                                    </g>
                                    <path fill="#A2A2A2" d="M2.26 13.691c-.326 0-.607.018-.607.018v2.413s.29.055.635.055c.935 0 1.388-.508 1.388-1.28 0-.78-.453-1.206-1.415-1.206m-.045 3.175c-.3 0-.563-.027-.563-.027v2.322H.82v-6.123S1.626 13 2.352 13c1.415 0 2.186.699 2.186 1.788 0 1.215-.87 2.077-2.322 2.077M6.444 13.727L6.444 15.714 8.612 15.714 8.612 16.386 6.444 16.386 6.444 19.162 5.609 19.162 5.609 13.038 8.73 13.038 8.73 13.727z" transform="translate(-524 -578) translate(340 287) translate(184 291)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            ` : null,
			value: layout.overview[keysArr[i]],
			field: layout.hasOwnProperty("overviewField") && layout.overviewField.hasOwnProperty([`${keysArr[i]}Field`])
				? layout.overviewField[`${keysArr[i]}Field`]
				: null,
			unit: layout.hasOwnProperty("overviewField") && layout.overviewField.hasOwnProperty([`${keysArr[i]}FieldUnit`])
				? layout.overviewField[`${keysArr[i]}FieldUnit`]
				: null,
			show: true
		});
	}
	return computeOverview;
};

export const getConfigSolarOverview = (overviewLists = []) => {
	const overview = {};
	const overviewField = {};
	for (let i = 0; i < overviewLists.length; i++) {
		if (overviewLists[i].show) {
			overview[overviewLists[i].key] = overviewLists[i].value;
			if (overviewLists[i].key !== "pf") {
				overviewField[`${overviewLists[i].key}Field`] = (overviewLists[i].field && overviewLists[i].field.trim()) || "";
				overviewField[`${overviewLists[i].key}FieldUnit`] = (overviewLists[i].unit && overviewLists[i].unit.trim()) || "";
			}
		}
	}
	return {
		overview: { ...overview },
		overviewField: { ...overviewField }
	};
};