<template>
	<BaseLayout pageTitle="Ship-to management">
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>
			<div class="row container-list">
				<ShipToList
					:loading="isLoading"
					:items="data"
					@statusChange="handleStatusChange"
					@powerBISave="handlePowerBISave"
				/>
				<BasePagination
					v-if="data.length > 0"
					:value="pagination.currentPage"
					:totalPage="pagination.lastPage"
					class="module-pagination"
					@onPageChange="handlePageChange"
				/>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SearchFilterForm from "@/components/ShipToStatus/SearchFilterForm.vue";
import ShipToList from "@/components/ShipToStatus/ShipToList.vue";
import { cleanParams } from "../../selectors/helpers/";
import { putShipToStatusAPI } from "../../services/api/shiptoStatus.api";
import { TOAST_TYPES } from "../../enums/toast";

export default {
	name: "ShopToStatusList",

	components: {
		SearchFilterForm,
		ShipToList
	},

	data() {
		return {
			query: {
				page: this.$route.query.page || null,
				keyword: this.$route.query.keyword || null
			}
		};
	},

	computed: {
		...mapState("shiptoStatus", {
			isLoading: "isLoading",
			data: "data",
			pagination: "pagination"
		})
	},

	created() {
		this.fetchData();
		this.addActivityLogShipToStatus();
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions("shiptoStatus", {
			getShiptoStatus: "getShiptoStatus"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogShipToStatus: "addActivityLogShipToStatus",
			addActivityLogSetUpPowerBI: "addActivityLogSetUpPowerBI"
		}),
		updateUrl() {
			const query = cleanParams(JSON.parse(JSON.stringify(this.query)));
			this.$router.push({ query }).catch(() => {});
		},
		async fetchData() {
			this.updateUrl();
			return this.getShiptoStatus(this.query);
		},
		handleSubmitFilter(form = {}) {
			this.query.keyword = form.keyword;
			this.query.page = 1;
			this.fetchData();
		},
		handlePageChange(pageNum = 1) {
			this.query.page = pageNum;
			this.fetchData();
		},
		handleStatusChange({ id, type, isVisible }) {
			putShipToStatusAPI({ id, type, isVisible });
		},
		async handlePowerBISave({ id, type, workspaceId, reportId, isVisible }) {
			const response = await putShipToStatusAPI({ id, type, workspaceId, reportId, isVisible });
			if (response?.status === 204) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: { title: "Saved workspace & report ID", message: "You can edit workspace & report ID in this page.", delay: 6000 }
				});
				this.addActivityLogSetUpPowerBI({ deviceId: id, note: `Workspace ID: ${workspaceId},Report ID: ${reportId}` });
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container-list {
	padding-left: $padding-base;
	padding-right: $padding-base;

	.module-pagination {
		display: flex;
		justify-content: flex-end;
	}
}
</style>
