<template>
	<div class="control in-line">
		<label class="input-label" :for="label"> {{label}} </label>
		<div :class="['input-file', errorClass, {'input-disabled' : disabled }]">
			<label :class="['button']" :for="label">Choose File</label>
			<input :id="label" type="file" :accept="accept"	@change="onFileChange" :disabled="disabled">
		</div>
		<span :class="['error-message', {'has-label': label !== ''}]" v-if="isShowErrorMessage">
			{{ errorMessage }}
		</span>
		<span :class="['info-message', {'has-label': label !== ''}]" v-else>
			{{ infoMessage }}
		</span>
	</div>
</template>

<script>


export default {
	props: {
		label: {
			type: String,
			default: "Upload"
		},
		infoMessage: {
			type: String,
			default: ""
		},
		errorMessage: {
			type: String,
			default: ""
		},
		isError: {
			type: Boolean,
			default: false
		},
		isInvalidFileType: {
			type: Boolean,
			default: false
		},
		isInvalidFileSize: {
			type: Boolean,
			default: false
		},
		accept: {
			type: String,
			default: ".png,.jpg,.jpeg,.svg"
		},
		disabled: Boolean
	},

	components: {
	},

	methods: {
		onFileChange(event) {
			const files = event.target.files;
			this.$emit("handleFileChange", {
				file: files[0]
			});
		}
	},

	computed: {
		errorClass() {
			return {
				"is-error": this.isInvalidFileType
			};
		},
		isShowErrorMessage() {
			return (this.isInvalidFileType || this.isInvalidFileSize) && this.errorMessage;
		}
	}

};
</script>

<style lang="scss" scoped>
.control {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: $font-20;
	clear: both;
	box-sizing: border-box;

	&.in-line {
		flex-direction: row;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}

.button {
	position: absolute;
	top: 4px;
	left: rem(6);
	z-index: 1;
	display: inline-block;
	padding: 4px rem(24);
	cursor: pointer;
	border-radius: 4px;
	background-color: $color-emerald;
	font-size: $font-16;
	font-weight: $font-weight-bold;
	color: $color-white;
}

input[type="file"] {
	position: absolute;
	top: rem(10);
	left: rem(8);
	font-size: $font-16;
	color: $color-black;
}

.input-file {
	position: relative;
	display: block;
	height: 40px;
	width: 100%;
	max-width: 100%;
	padding: $control-padding-vertical $control-padding-horizontal;
	color: $color-dark-blue-grey;
	background-color: $color-grey-1;
	border: rem(1) solid $color-silver;
	border-radius: 8px;
}

.input-disabled {
	background-color: $color-bg-press-outline;
	cursor: not-allowed;

	label {
		background-color: $color-light-teal;
		cursor: not-allowed;
	}
}

.is-error {
	border-color: $color-red;
}

.error-message {
	position: absolute;
	height: rem(20);
	bottom: rem(-20);
	font-size: $font-14;
	color: $color-red;
	font-weight: $font-weight-bold;

	&.has-label {
		margin-left: rem(130);
	}
}

.info-message {
	position: absolute;
	height: rem(20);
	bottom: rem(-20);
	font-size: $font-14;
	color: $color-black;
	font-weight: $font-weight-bold;

	&.has-label {
		margin-left: rem(130);
	}
}
</style>
