<template v-if="soldToOptions.length > 0 && shipToOptions.length > 0">
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div class="form-group">
			<div class="dropdown-wrapper sold-to">
				<BaseMultipleSelect
					:options="soldToOptions"
					:value="soldToValue"
					:disabled="false"
					:limit="4"
					:is-error="false"
					placeholder="Select sold-to"
					@onSelectValue="handleSelectSoldTo"
					@onRemoveSelected="handleRemoveSoldTo"
					:isShowLabel="false"
				/>
			</div>

			<div class="dropdown-wrapper ship-to">
				<BaseMultipleSelect
					:options="getShipToOption"
					:value="shipToValue"
					:disabled="false"
					:limit="2"
					:is-error="false"
					placeholder="Select ship-to"
					multiple
					@onSelectValue="handleSelectShipTo"
					@onRemoveSelected="handleRemoveShipTo"
					:isShowLabel="false"
				/>
			</div>
		</div>
		<div class="form-group large-desktop">
			<div :class="['col-md-3 input-group input-group-keyword', { active: isActive.keyword }]">
				<BaseFormInput
					v-model="invoiceNumber"
					:inputOptions="['ghost input-filter small']"
					placeholder="Invoice no. (optional)"
					@focus="setFocus('keyword', true)"
					@blur="setFocus('keyword', false)"
				/>
			</div>
			<div :class="['col-md-3 input-group wrapper-status-dropdown' ]">
				<BaseDropdown
					:searchable="false"
					:list="INVOICE_STATUS"
					:value="status"
					type="ghost"
					placeholder="Product type: All"
					@input="filterStatus"
					@active="setFocus('product', $event)"
				/>
			</div>
			<div :class="['col-md-3 input-group input-group-date wrapper-datepicker invoice-date']">
				<BaseDatePicker
					v-model="invoiceDate"
					:max-date="new Date()"
					size="medium"
					mode="range"
					clearable
					class="custom-datepicker"
					@open="setFocus('date', true)"
					@close="setFocus('date', false)"
					@done="handlePickInvoiceDate"
				/>
				<label class="datepicker-label">Invoice date (optional)</label>
			</div>
			<div :class="['col-md-3 input-group input-group-date wrapper-datepicker']">
				<BaseDatePicker
					v-model="dueDate"
					size="medium"
					mode="range"
					clearable
					class="custom-datepicker"
					@open="setFocus('date', true)"
					@close="setFocus('date', false)"
					@done="handlePickDueDate"
				/>
				<label class="datepicker-label">Due date (optional)</label>
			</div>
			<BaseButton
				class="search-button"
				size="large"
				type="primary"
				outline
				@click="handleFilter"
				:disabled="isDisabledFilter"
			>
				Filter
			</BaseButton>
		</div>
		<div class="form-group small-desktop">
			<div :class="['col-md-3 input-group input-group-date wrapper-datepicker invoice-date']">
				<BaseDatePicker
					v-model="invoiceDate"
					:max-date="new Date()"
					size="medium"
					mode="range"
					clearable
					class="custom-datepicker"
					@open="setFocus('date', true)"
					@close="setFocus('date', false)"
					@done="handlePickInvoiceDate"
				/>
				<label class="datepicker-label">Invoice date (optional)</label>
			</div>
			<div :class="['col-md-3 input-group input-group-date wrapper-datepicker due-date']">
				<BaseDatePicker
					v-model="dueDate"
					size="medium"
					mode="range"
					clearable
					class="custom-datepicker"
					@open="setFocus('date', true)"
					@close="setFocus('date', false)"
					@done="handlePickDueDate"
				/>
				<label class="datepicker-label">Due date (optional)</label>
			</div>
			<BaseButton
				class="search-button"
				size="large"
				type="primary"
				outline
				@click="handleFilter"
				:disabled="isDisabledFilter"
			>
				Filter
			</BaseButton>
		</div>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
import dayjs from "dayjs";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import { INVOICE_STATUS } from "../../enums/invoice";

export default {

	name: "SearchFilterForm",

	components: {
		BaseMultipleSelect,
		BaseDatePicker
	},
	props: {
		query: {
			type: Object,
			default: () => ({})
		},
		shipToOptions: {
			type: Array,
			default: () => []
		},
		soldToOptions: {
			type: Array,
			default: () => []
		},
		modules: {
			type: Array,
			default: () => []
		}
	},

	data() {
		let shipToIds = null;
		if (typeof this.$route.query.shipToIds === "string") {
			shipToIds = [
				this.$route.query.shipToIds
			];
		} else {
			shipToIds = [
				...(this.$route.query.shipToIds ? this.$route.query.shipToIds : [])
			];
		}

		return {
			INVOICE_STATUS,
			status: this.$route.query.status ?? null,
			invoiceNumber: this.$route.query.invoiceNumber ?? "",
			shipToValue: [],
			soldToValue: [],
			invoiceDate: this.$route.query.startInvoiceDate && this.$route.query.endInvoiceDate ? {
				start: dayjs(this.$route.query.startInvoiceDate),
				end: dayjs(this.$route.query.endInvoiceDate)
			} : null,
			dueDate: this.$route.query.startDueDate && this.$route.query.endDueDate ? {
				start: dayjs(this.$route.query.startDueDate),
				end: dayjs(this.$route.query.endDueDate)
			} : null,
			shipToSelected: shipToIds,
			soldToselected: this.$route.query.soldToId || "",

			isActive: {
				keyword: false,
				productName: false,
				moduleName: false
			}
		};
	},
	created() {
		this.soldToValue = this.getInitialSoldToValue;
		this.shipToValue = this.getInitialShipToValue;
	},
	computed: {
		getShipToOption() {
			if (this.soldToValue.length > 0) {
				return this.shipToOptions;
			}
			return [];
		},
		isDisabledFilter() {
			return this.soldToValue.length === 0 || this.shipToValue.length === 0;
		},

		getInitialSoldToValue() {
			return this.soldToOptions.filter(({ code }) => {
				if (this.$route.query.soldToId) {
					return code.toString() === this.$route.query.soldToId.toString();
				}
				return false;
			});
		},

		getInitialShipToValue() {
			return this.shipToOptions.filter(({ code }) => this.shipToSelected.indexOf(code.toString()) >= 0);
		}
	},

	methods: {
		filterStatus(value) {
			this.status = value;
		},
		handlePickInvoiceDate(date) {
			this.invoiceDate = date;
		},
		handlePickDueDate(date) {
			this.dueDate = date;
		},
		handleSubmit(e) {
			e.preventDefault();
			this.$emit("submit", this.form);
		},

		clearFilter() {
			this.shipToValue = [];
			this.soldToValue = [];
			this.invoiceNumber = "";
			this.invoiceDate = null;
			this.dueDate = null;
			this.status = null;
			this.$emit("clearFilter");
		},

		handleSelectShipTo(valueSelect) {
			if (valueSelect.code === "all") {
				this.shipToValue = [
					...this.shipToOptions
				];
			} else {
				this.shipToValue = [
					...this.shipToValue,
					valueSelect
				];
			}
		},

		handleSelectSoldTo(soldToselected) {
			this.soldToValue = [soldToselected];
			this.shipToValue = [];
			this.invoiceNumber = "";
			this.invoiceDate = null;
			this.dueDate = null;
			this.status = null;
			this.$emit("onSelectSoldTo", soldToselected.code);
		},


		handleRemoveShipTo(valueSelect) {
			if (valueSelect.code === "all") {
				this.shipToValue = [];
			} else {
				this.shipToValue = this.shipToValue
					.filter(({ code }) => code !== valueSelect.code)
					.filter(({ code }) => !(code === "all"));
			}
		},

		handleRemoveSoldTo() {
			this.soldToValue = [];
			this.shipToValue = [];
		},

		setFocus(type, value) {
			this.isActive[type] = value;
		},

		handleFilter() {
			const invoiceDate = this.invoiceDate && this.invoiceDate.start && this.invoiceDate.end ? {
				startInvoiceDate: this.invoiceDate.start,
				endInvoiceDate: this.invoiceDate.end
			} : {};

			const dueDate = this.dueDate && this.dueDate.start && this.dueDate.end ? {
				startDueDate: this.dueDate.start,
				endDueDate: this.dueDate.end
			} : {};

			this.$emit("onSearchFilter", {
				soldToId: this.soldToValue[0].code,
				shipToIds: this.shipToValue.map(({ code }) => code),
				invoiceNumber: this.invoiceNumber,
				status: this.status,
				...dueDate,
				...invoiceDate
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.filter-form {
	display: flex;
	flex-direction: column;
	margin: 0;
}

.search-shipto {
	display: flex;
	flex-grow: 2;
}

.search-button {
	/* stylelint-disable */

	&::v-deep {
		&.btn.large {
			font-size: rem(20);
			height: auto;
		}
	}
	/* stylelint-enable */
}

/* stylelint-disable */
.custom-datepicker {
	display: inline-flex;
	flex: 1;

	&::v-deep .container-datepicker-control {
		margin-left: -14px;
	}

	&::v-deep .input {
		border: none;
		background: none !important;
		padding-left: rem(26) !important;
	}

	&::v-deep .icon.is-left {
		width: rem(14) !important;
	}
}
/* stylelint-enable */

.form-group {
	display: flex;
	flex-wrap: wrap;

	.wrapper-status-dropdown {
		width: rem(160);
	}

	.wrapper-datepicker {
		display: flex;
		min-width: rem(212);
		position: relative;

		&.invoice-date {
			border-right: 1px solid $color-grey-3;
		}

		.datepicker-label {
			@include fontBase;

			color: $color-grey-4;
			font-weight: $font-weight-bold;
			position: absolute;
			top: 0;
			left: rem(14);
			font-size: rem(12);
		}
	}

	.dropdown-wrapper {
		display: flex;

		&.sold-to {
			flex-basis: rem(309);
			border-right: 1px solid $color-grey-3;
		}

		&.ship-to {
			flex-basis: rem(540);
			min-width: rem(365);
		}
		/* stylelint-disable */
		&::v-deep {
			.multiselect__single {
				background: transparent;
				font-size:  $font-18;
				margin-top: rem(6);
			}

			.wrapper-multi-select {
				margin: 0;
				width: 100%;
			}

			.custom-multiple-select {
				height: 100%;
			}

			.multiselect__tags-wrap {
				display: flex;
			}

			.multiselect__strong {
				margin: 0;
			}

			.multiselect__tags {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				height: 100%;
				border: 0;
				border-radius: 0;
				background: $color-alabaster;
				padding: rem(8) rem(40) rem(8) rem(8);
			}

			.multiselect__input {
				margin: 6px 0 0;
			}

			.multiselect__placeholder {
				margin-top: rem(2);
			}
		}
		/* stylelint-enable */
	}
}

.input-group-keyword {
	display: flex;
	width: rem(150);
	/* stylelint-disable */
	&::v-deep {
		.control {
			.input-filter {
				&.input {
					padding-left: 0;

					&.is-rounded {
						padding-left: 0;
					}
				}
			}
		}
	}
	/* stylelint-enable */
}

.clear-filter {
	margin-top: rem(-12);
	width: rem(848);
}

.small-desktop {
	display: none;
}

.input-group-module {
	width: rem(310);
}

@media screen and (max-width: 1158px) {
	/* stylelint-disable */
	::v-deep {
		.dropdown-bubble-container {
			z-index: 5;
		}
	}
	/* stylelint-enable */

	.form-group {
		.dropdown-wrapper {
			display: flex;

			&.sold-to {
				flex-basis: rem(225);
				border-right: 1px solid $color-grey-3;
			}

			&.ship-to {
				flex-basis: calc(100% - 225px);
				min-width: rem(365);
			}
		}
	}

	.large-desktop {
		.wrapper-datepicker {
			display: none;
		}

		.input-group-keyword {
			width: rem(225);
		}

		.wrapper-status-dropdown {
			width: calc(100% - 225px);

			&.input-group:not(:last-of-type)::after {
				display: none;
			}
		}

		.search-button {
			display: none;
		}
	}

	.small-desktop {
		display: flex;

		.wrapper-datepicker {
			width: rem(225);

			&.due-date {
				width: calc(100% - 340px);
			}
		}
	}

	.clear-filter {
		width: 100%;
	}
}
</style>
