<template>
	<div class="container-footer">
		<BaseHeadColumn class="order-head-column">
			<template #first-column>
				<div class="date">
					<BaseButton
						class="toggle-btn"
						size="small"
						type="secondary"
						link
						@click="onClickToggle"
					>
						{{ toggleState.text }}
						<FontAwesomeIcon
							:icon="['far', toggleState.icon]"
							class="toggle-icon"
						/>
					</BaseButton>
				</div>
			</template>
			<template #rest-column>
				<div class="value">
					<strong class="text-grey text-sm">Finished Good (Units)</strong>
				</div>
				<!-- //TODO: disable temporary - uncomment when client need to use Defect Value -->
				<div class="value">
					<!-- <strong class="text-grey text-sm">Defect (Units)</strong> -->
				</div>
				<div class="value">
					<strong class="text-grey text-sm">Solder feed (g)</strong>
				</div>
				<div class="value">
					<strong class="text-grey text-sm">Operating Hours (Hrs)</strong>
				</div>
			</template>
		</BaseHeadColumn>
		<BaseHeadColumn v-if="active" class="order-head-column">
			<template #first-column>
				<div class="date">
					<strong class="text-grey text-sm">Monthy</strong>
				</div>
			</template>
			<template #rest-column>
				<div class="value">
					<strong class="text-sm">
						{{ item.finishedGoodUnits | currency }}
					</strong>
				</div>
				<!-- //TODO: disable temporary - uncomment when client need to use Defect Value -->
				<div class="value">
					<!-- <strong class="text-sm">
						{{ item.defectUnits | currency }}
					</strong> -->
				</div>
				<div class="value">
					<strong class="text-sm">
						{{ item.solderFeed | currency }}
					</strong>
				</div>
				<div class="value">
					<strong class="text-sm">
						{{ item.operatingHours }}
					</strong>
				</div>
			</template>
		</BaseHeadColumn>
		<BaseHeadColumn v-if="active" class="order-head-column">
			<template #first-column>
				<div class="date">
					<strong class="text-grey text-sm">Avg.per Fg</strong>
				</div>
			</template>
			<template #rest-column>
				<div class="value">
					<strong class="text-sm">-</strong>
				</div>
				<!-- //TODO: disable temporary - uncomment when client need to use Defect Value -->
				<div class="value">
					<!-- <strong class="text-sm">
						{{ item.defectUnitsAvgPerFG | fullCurrency }}
					</strong> -->
				</div>
				<div class="value">
					<strong class="text-sm">
						{{ item.solderFeedAvgPerFG | fullCurrency }}
					</strong>
				</div>
				<div class="value">
					<strong class="text-sm">-</strong>
				</div>
			</template>
		</BaseHeadColumn>
	</div>
</template>

<script>
import BaseHeadColumn from "@/components/BaseHeadColumn.vue";

export default {
	components: {
		BaseHeadColumn
	},

	props: {
		item: {
			type: Object
		},
		index: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			active: false
		};
	},

	computed: {
		toggleState() {
			return {
				text: this.active ? "Hide detail" : "Show detail",
				icon: this.active ? "chevron-circle-up" : "chevron-circle-down"
			};
		}
	},

	mounted() {
		if (this.index === 0) {
			this.active = true;
		}
	},

	methods: {
		onClickToggle() {
			this.active = !this.active;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-footer {
	padding: rem(10) 0;
	background-color: $color-alabaster;
}

.text-right {
	text-align: right !important;
}

.date {
	margin-left: rem(24);
}

.value {
	margin-right: rem(24);
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.text-grey {
	color: $color-grey-4 !important;
}

.text-sm {
	font-size: $font-16;
}

.toggle {
	&-btn {
		padding: 0 !important;
	}

	&-icon {
		position: relative;
		width: rem(10);
		top: rem(3);
		margin-left: rem(2);
	}
}
</style>