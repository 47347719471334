<template>
	<BaseLayout pageTitle="Our service">
		<div class="container-action-bar" v-if="manageAble">
			<router-link
				:to="{ name: PAGE_NAME.BIG_STORE_OUR_SERVICE_CREATE }"
			>
				<BaseButton
					size="large"
				>
					Create new service
				</BaseButton>
			</router-link>
		</div>
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:statuses="statuses"
					:highlightServices="highlightServices"
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>
			<BaseNoResult v-if="isEmpty" title="No data. Please Add New Service"/>
			<BaseTableCustom
				v-else
				:tableOptions="tableOptions"
				:pagination="pagination"
				:loading="isLoading"
				@onPageChange="handlePageChange"
			>
				<template v-slot:thead>
					<tr>
						<th
							v-for="({value,key}, index) in table.thead"
							:key="index"
						>
							<div class="header-content">
								{{ value }}
								<BaseSort
									v-if="key"
									:sortName="key"
									:sortBy="getSortingBykey(key)"
									@handleSort="handleSort"
								/>
							</div>
						</th>
					</tr>
				</template>
				<template v-slot:tbody>
					<tr
						v-for="(item, index) in table.tbody"
						:key="index"
						:class="[
							{
								inactive: item.isActive === false
							}
						]"
					>
						<td class="max-width-sold-to">
							{{item.title}}
						</td>
						<td class="max-width-sold-to">
							{{item.module}}
						</td>
						<td class="max-width-default">
							{{ item.priority }}
						</td>
						<td>
							<BaseBadge v-if="item.status" text="Active" size="small" color="success-dark"/>
							<BaseBadge v-else text="Inactive" size="small" outline/>
						</td>
						<td>
							<BaseBadge v-if="item.statusHighlight" text="On" size="small" color="success-dark"/>
							<BaseBadge v-else text="Off" size="small" outline/>
						</td>
						<td class="max-width-default">
							{{ item.priorityHighlight === 0 ? "" : item.priorityHighlight }}
						</td>
						<td class="align-right action">
							<router-link
								v-if="manageAble"
								:to="`/big-store-our-service/${item.id}`"
							>
								<button class="view-button btn-link">
									Edit
								</button>
							</router-link>
							<a
								href="#"
								@click="showModalDeleteService(item.id)"
								class="ml-7"
							>
								<button class="view-button btn-link">
									Delete
								</button>
							</a>
						</td>
					</tr>
				</template>
			</BaseTableCustom>
		</div>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_SERVICE"
			title="Delete this service?"
			:message="modalMessageDeleteService"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleModalDeleteThisService"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SearchFilterForm from "@/components/bigStoreManagement/SearchFilterForm.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import BaseSort from "@/components/BaseSort.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { cleanParams, cloneDeep } from "../../selectors/helpers";
import { PAGE_NAME } from "../../enums/pagePermission";
import { STAFF_ROLES } from "../../enums/role";
import { TOAST_TYPES } from "../../enums/toast";

export default {
	name: "BigStoreOurService",

	components: {
		SearchFilterForm,
		BaseTableCustom,
		BaseSort,
		BaseModalConfirmDelete,
		BaseNoResult,
		BaseBadge
	},

	data() {
		return {
			query: {
				page: this.$route.query.page || null,
				title: this.$route.query.title || null,
				status: this.$route.query.status || null,
				highlight: this.$route.query.highlight || null,
				priority: this.$route.query.priority || null,
				sort: this.$route.query.sort || "title:asc"
			},
			tableOptions: [
				"is-striped is-fullwidth"
			],
			sort: {
				title: "asc",
				priority: "",
				highlight: ""
			},
			PAGE_NAME,
			MODAL_DELETE_SERVICE: "model-delete-banner"
		};
	},

	computed: {
		...mapState({
			response: (state) => {
				return state.ourServiceManagement;
			},
			user: (state) => {
				return state.user;
			}
		}),

		table() {
			return {
				thead: [
					{ value: "Title", key: "title" },
					{ value: "Group", key: "" },
					{ value: "Priority", key: "priority" },
					{ value: "Status", key: "" },
					{ value: "Highlight service", key: "" },
					{ value: "Highlight priority", key: "highlight" },
					{ value: "", key: "" }

				],
				tbody: this.response.data
			};
		},

		pagination() {
			return this.response.pagination;
		},

		isFiltered() {
			return !Object.values(this.query).every((v) => v === null);
		},

		isLoading() {
			return this.response.isLoading;
		},

		isEmpty() {
			return this.response.isEmpty && !this.isFiltered;
		},

		manageAble() {
			return [
				STAFF_ROLES.SUPER_ADMIN
			].includes(this.user.role);
		},

		getQuery() {
			const cloneQuery = cloneDeep(this.query);
			const query = cleanParams(cloneQuery);
			return query;
		},

		modalMessageDeleteService() {
			return `<div>
				<div>Are you sure to delete this service? </div>
			</div>`;
		},

		statuses() {
			return [
				{ label: "Status: All", value: null },
				{ label: "Active", value: "1" },
				{ label: "Inactive", value: "0" }
			];
		},

		highlightServices() {
			return [
				{ label: "Highlight service: All", value: null },
				{ label: "On", value: "1" },
				{ label: "Off", value: "0" }
			];
		}

	},

	async created() {
		const { sort = "" } = this.$route.query;
		const [sortName, sortBy] = sort.split(":");
		if (sortName && sortBy) {
			this.handleSetSortTable(sortName, sortBy);
		}
		await this.fetchOurServices();
		await this.fetchOurServicesWhenPageIsEmpty();
	},

	methods: {
		...mapActions({
			fetchOurServiceList: "ourServiceManagement/fetchOurServiceList",
			sendOurServiceDeleteAPI: "ourServiceManagement/sendOurServiceDeleteAPI",
			sendOurDeviceDeleteAPI: "bannerManagement/sendOurDeviceDeleteAPI"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		getSortingBykey(key) {
			return this.sort[key];
		},

		handleSetSortTable(sortName, sortBy) {
			Object.keys(this.sort).forEach((keyName) => {
				if (keyName === sortName) {
					this.sort[keyName] = sortBy;
				} else {
					this.sort[keyName] = "";
				}
			});
		},

		handleSort(value) {
			const { sortName, sortBy } = value;
			this.handleSetSortTable(sortName, sortBy);
			this.query.sort = `${sortName}:${sortBy}`;
			this.fetchOurServices();
		},

		async fetchOurServices() {
			this.$router.push({ query: this.getQuery }).catch(() => {});
			await this.fetchOurServiceList(this.getQuery);
		},

		handlePageChange(pageNum) {
			this.query.page = pageNum;
			this.fetchOurServices();
		},

		showModalDeleteService(key) {
			this.$modal.show(this.MODAL_DELETE_SERVICE, key);
		},

		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_SERVICE);
		},

		async handleModalDeleteThisService(key) {
			await this.sendOurServiceDeleteAPI(key);
			if (this.response.ourServiceDeleteSuccess) {
				await this.fetchOurServices();
				await this.fetchOurServicesWhenPageIsEmpty();
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Our Service delete successful."
					}
				});
				this.$modal.hide(this.MODAL_DELETE_SERVICE);
			}
		},

		handleSubmitFilter(form = {}) {
			this.query.title = form.keyword ? form.keyword.trim() : form.keyword;
			this.query.status = form.status;
			this.query.highlight = form.highlight;
			this.query.page = 1;
			this.fetchOurServices();
		},

		async fetchOurServicesWhenPageIsEmpty() {
			if ((this.response.pagination.currentPage > this.response.pagination.lastPage) && this.response.pagination.lastPage !== 0) {
				this.query.page = (Number(this.query.page) - 1) ?? 1;
				await this.fetchOurServices();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.header-content {
	display: flex;
}

.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}

td {
	word-wrap: break-word;
}

.max-width-sold-to {
	max-width: rem(204);
}

.max-width-default {
	max-width: rem(150);
}

.action {
	display: flex;
	justify-content: flex-end;
}

.ml-7 {
	margin-left: $spacing-7;
}
</style>
