<template>
	<BaseLayout :isLoading="eOrderingPendingOrderList.isLoading">
		<BaseBackBtn :to="PAGE_NAME.E_ORDERING_LIST" label="Back to order list" />
		<div class="pending-order-list-container">
			<h1 class="title">
				Pending to place orders list
			</h1>
			<section class="section-pending-orders" v-if="!eOrderingPendingOrderList.isPendingOrdersEmpty">
				<h2 class="title">
					<FontAwesomeIcon class="icon" :icon="['far', 'list-ul']" /> Pending to placed orders</h2>
				<h5 class="subtitle">
					Orders listed below have been pending to placed.
				</h5>
				<BaseTableCustom
					:tableOptions="tableOptions"
					:loading="false"
					class="table-pending-order"
				>
					<template v-slot:thead>
						<tr>
							<th
								v-for="(value, index) in tableHeader"
								:key="index"
							>
								{{ value }}
							</th>
						</tr>
					</template>
					<template v-slot:tbody>
						<tr
							v-for="(pendingOrder, index) in  eOrderingPendingOrderList.data.pendingOrders"
							:key="index"
						>
							<td>
								{{ index + 1}}
							</td>
							<td>
								{{ pendingOrder.shipToName || "-" }}
							</td>
							<td>
								{{ pendingOrder.productName || "-" }}
							</td>
							<td>
								{{ pendingOrder.shipId || "-" }}
							</td>
							<td>
								<div class="text-quantity">
									{{ showVolume(pendingOrder) || "-" }}
								</div>
							</td>
							<td>
								<div :class="['text-delivery', { '-error': pendingOrder.isUrgent }]">
									{{ pendingOrder.displayDeliveryDate }} {{ pendingOrder.displayDeliveryTime }}
									<FontAwesomeIcon
										v-if="pendingOrder.isUrgent"
										class="icon-error"
										:icon="['far', 'exclamation-triangle']"
									/>
								</div>
							</td>
							<td>
								{{ pendingOrder.poNo || "-"}}
							</td>
							<td>

							</td>
						</tr>
					</template>
				</BaseTableCustom>
			</section>
			<section class="section-failed-orders" v-if="!eOrderingPendingOrderList.isFailedOrdersEmpty">
				<div class="header">
					<h2 class="title">
						<FontAwesomeIcon class="icon" :icon="['fa', 'exclamation-circle']" /> 	Failed orders
					</h2>
					<button
						:disabled="isDiscardOrderLoading"
						@click="handleDiscardFailedOrders"
						class="discard-order view-button">
						Discard failed order
					</button>
				</div>
				<h5 class="subtitle">
					Unable to process orders listed below, please click Re-order to place these orders again.
				</h5>
				<BaseTableCustom
					:tableOptions="tableOptions"
					:loading="false"
					class="table-failed-order"
				>
					<template v-slot:thead>
						<tr>
							<th
								v-for="(value, index) in tableHeader"
								:key="index"
							>
								{{ value }}
							</th>
						</tr>
					</template>
					<template v-slot:tbody>
						<tr
							v-for="(failedOrder, index) in eOrderingPendingOrderList.data.failedOrders"
							:key="index"
						>
							<td>
								{{ index + 1}}
							</td>
							<td>
								{{ failedOrder.shipToName || "-" }}
							</td>
							<td>
								{{ failedOrder.productName || "-" }}
							</td>
							<td>
								{{ failedOrder.shipId || "-" }}
							</td>
							<td>
								<div class="text-quantity">
									{{ showVolume(failedOrder) || "-" }}
								</div>
							</td>
							<td>
								<div :class="['text-delivery', { '-error': failedOrder.isUrgent }]">
									{{ failedOrder.displayDeliveryDate }} {{ failedOrder.displayDeliveryTime }}
									<FontAwesomeIcon
										v-if="failedOrder.isUrgent"
										class="icon-error"
										:icon="['far', 'exclamation-triangle']"
									/>
								</div>
							</td>
							<td>
								{{ failedOrder.poNo || "-"}}
							</td>
							<td class="align-center">
								<button
									:disabled="isReOrderLoading || isDiscardOrderLoading"
									class="view-button"
									@click="handleReOrder(failedOrder.id)"
								>
									Re-order
								</button>
							</td>
						</tr>
					</template>
				</BaseTableCustom>
			</section>
			<BaseEmpty
				v-if="eOrderingPendingOrderList.isPendingOrdersEmpty && eOrderingPendingOrderList.isFailedOrdersEmpty"
				class="wrapper-empty"
				icon="search"
				wrapperColor="ice"
				iconColor="emerald"
				title="No matching results"
				subTitle="Lot order will be displayed once you created lot order and still pending or failed"
			/>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";

import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseEmpty from "@/components/BaseEmpty.vue";

import { PAGE_NAME } from "../../enums/pagePermission";
import { DISPLAY_TIME } from "../../enums/eOrdering/timeType";
import { numberFormat } from "../../services/api/transforms/parseModule";
import { mapUnit } from "../../selectors/helpers/mapUnit";
import { deleteEOrderingDiscardFailedOrdersAPI, putEOrderingReOrderAPI } from "../../services/api/eOrdering.api";
import { TOAST_TYPES } from "../../enums/toast";

export default {
	name: "EOrderingPendingOrderList",

	components: {
		BaseBackBtn,
		BaseTableCustom,
		BaseEmpty
	},

	data() {
		return {
			isReOrderLoading: false,
			isDiscardOrderLoading: false,
			PAGE_NAME,
			tableOptions: [
				"is-striped is-fullwidth"
			],
			tableHeader: [
				"Order",
				"Ship-to",
				"Product",
				"Tank No.",
				"Volume",
				"Delivery Date/Time",
				"PO No.",
				""
			]
		};
	},

	computed: {
		...mapState(["eOrderingPendingOrderList"]),

		...mapState(["eOrderingInfo"])
	},

	async created() {
		await Promise.all([
			this.getEOrderingConfiguration(),
			this.getEOrderingPendingOrderLists()
		]);
	},

	methods: {
		...mapActions("eOrderingPendingOrderList", {
			getEOrderingPendingOrderLists: "getEOrderingPendingOrderLists"
		}),

		...mapActions("eOrderingInfo", {
			getEOrderingConfiguration: "getEOrderingConfiguration"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		showDeliveryDate(dateTime) {
			const time = dayjs(dateTime).format("HH:mm");
			const date = dayjs(dateTime).format("DD/MM/YYYY");
			const displayTime = DISPLAY_TIME[time];

			return `${date} ${displayTime || time}`;
		},

		showVolume(value) {
			return `${numberFormat(value.amount, "0,0")} ${mapUnit({ module: value.module, productId: value.productName }, this.eOrderingInfo.configuration)}`;
		},

		async handleDiscardFailedOrders() {
			try {
				this.isReOrderLoading = true;
				await deleteEOrderingDiscardFailedOrdersAPI();
				await this.getEOrderingPendingOrderLists();
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Your order has been successfully canceled",
						delay: 6000
					}
				});
			} catch (error) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to discard", message: "E-Ordering unable to discard failed orders.", delay: 6000 }
				});
			} finally {
				this.isReOrderLoading = false;
			}
		},

		async handleReOrder(failedOrderId) {
			try {
				this.isReOrderLoading = true;
				await putEOrderingReOrderAPI(failedOrderId);
				await this.getEOrderingPendingOrderLists();
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Your order has been successfully placed",
						delay: 6000
					}
				});
			} catch (error) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to re-order", message: "E-Ordering unable to re-order.", delay: 6000 }
				});
			} finally {
				this.isReOrderLoading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.pending-order-list-container {
	.title {
		margin-bottom: rem(24);

		font-family: $font-family-sub;
		font-size: $font-32;
		color: $color-dark-blue-grey;
	}

	.section-pending-orders {
		.title {
			margin-bottom: 0;

			font-family: $font-family-sub;
			font-size: $font-24;
			color: $color-cerulean;

			.icon {
				margin-right: rem(4);
				font-size: $font-16;
			}
		}

		.subtitle {
			font-size: $font-20;
			color: $color-grey-4;
			font-weight: normal;
		}

		.table-pending-order {
			::v-deep {
				.container-custom-main-table {
					th {
						border: 1px solid $color-cerulean;
						border-width: 0 0 2px;
					}
				}
			}
		}
	}

	.section-failed-orders {
		margin: rem(56) 0;

		.header {
			display: flex;
			justify-content: space-between;

			.title {
				margin-bottom: 0;

				font-family: $font-family-sub;
				font-size: $font-24;
				color: $color-red;

				.icon {
					margin-right: rem(4);
					font-size: $font-16;
				}
			}
		}

		.subtitle {
			font-size: $font-20;
			color: $color-grey-4;
			font-weight: normal;
		}

		.table-failed-order {
			::v-deep {
				.container-custom-main-table {
					th {
						border: 1px solid $color-red;
						border-width: 0 0 2px;
					}
				}
			}
		}
	}

	.text-delivery {
		&.-error {
			color: $color-lipstick;

			svg {
				width: 0;
				height: rem(14);
			}
		}
	}
}
</style>
