<template>
	<div class="container-icon">
		<font-awesome-icon :icon="['far', 'info-circle']" />
	</div>
</template>

<script>
export default {
	name: "NotFoundIcon"
};
</script>

<style lang="scss" scoped>
.container-icon {
	display: flex;
	width: rem(48);
	height: rem(48);
	font-size: $font-48;
	color: $color-light-teal;
}
</style>