import { getEOrderingOrderListsAPI } from "@/services/api/eOrdering.api";
import {
	E_ORDERING_ORDER_LIST_REQUEST,
	E_ORDERING_ORDER_LIST_SUCCESS,
	E_ORDERING_ORDER_LIST_FAILURE
} from "../../types";
import { transformOrderList } from "../../../selectors/transform/eOrdering/transformList";

export default {
	namespaced: true,

	state: {
		data: transformOrderList([]),
		isLoading: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0,
			perPage: 8
		},
		message: "",
		isEmpty: false
	},

	mutations: {
		[E_ORDERING_ORDER_LIST_REQUEST](state) {
			state.isLoading = true;
			state.data = transformOrderList([]);
		},
		[E_ORDERING_ORDER_LIST_SUCCESS](state, { data, pagination, message }) {
			state.isLoading = false;
			state.data = transformOrderList(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.message = message;
		},
		[E_ORDERING_ORDER_LIST_FAILURE](state) {
			state.data = transformOrderList([]);
			state.isLoading = false;
		}
	},

	actions: {
		async getEOrderingOrderLists({ commit }, params) {
			try {
				commit(E_ORDERING_ORDER_LIST_REQUEST);
				const { data } = await getEOrderingOrderListsAPI(params);
				commit(E_ORDERING_ORDER_LIST_SUCCESS, data);
			} catch (error) {
				commit(E_ORDERING_ORDER_LIST_FAILURE);
			}
		}
	}
};
