<template>
	<div>
		<Empty v-if="empty" class="empty"/>
		<BaseFilterExport
			v-if="!empty && permissions.dashboard.exportable"
			mode="range"
			class="custom-filter-export"
			@onSubmit="handleSubmitExport"
		/>
		<BaseTableCustom
			v-if="!empty"
			:tableOptions="tableOptions"
			:pagination="pagination"
			:loading="loading"
			:stickyHeader="true"
			class="custom-table"
			@onPageChange="handlePageChange"
		>
			<template v-slot:thead>
				<tr>
					<th>
						<strong>
							Date
						</strong>
					</th>
					<th class="text-right">
						<strong>
							<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" style="position: relative; top: 2px;">
								<g fill="none" fill-rule="evenodd">
									<g fill="#103332">
										<g>
											<g>
												<g>
													<path d="M6.153 6.348c.361 0 .704.018 1.045-.005.338-.022.665-.108.944-.319.512-.386.482-1.129-.074-1.43-.605-.328-1.261-.23-1.915-.208v1.962zm-.007 1.086c0 .772-.001 1.52.006 2.267 0 .035.08.098.127.1.235.012.471.012.707.01.374-.006.74-.053 1.085-.204.436-.191.689-.51.683-1-.006-.476-.267-.776-.69-.957-.61-.26-1.251-.218-1.918-.216zm.348-4.14V2.182c0-.203.02-.222.222-.223h.775c.196.001.234.04.235.238.001.318.007.637-.003.955-.005.145.038.202.19.222.42.055.817.19 1.177.422 1.072.689 1.122 2.026.102 2.79-.097.073-.202.138-.308.21.169.096.336.176.486.28 1.147.787 1.14 2.378-.012 3.187-.443.31-.947.457-1.474.53-.135.02-.163.07-.16.191.007.274.003.548.002.821-.001.21-.038.249-.242.25-.264.002-.529-.002-.793.002-.143.002-.202-.059-.2-.202.006-.312.002-.624.002-.954-.187-.009-.364-.014-.54-.025-.331-.02-.662-.035-.992-.064-.239-.021-.267-.059-.268-.292l-.001-3.585V3.753c0-.256.02-.296.274-.326.435-.05.872-.083 1.308-.122.065-.006.132-.007.22-.012z" transform="translate(-366 -506) translate(342 377) translate(24 128) translate(0 1)"/>
													<path d="M6.964 12.244c-2.96 0-5.36-2.384-5.36-5.325 0-2.941 2.4-5.325 5.36-5.325 2.96 0 5.36 2.384 5.36 5.325 0 2.94-2.4 5.325-5.36 5.325M6.964 0C3.118 0 0 3.098 0 6.919c0 3.821 3.118 6.919 6.964 6.919 3.845 0 6.963-3.098 6.963-6.92C13.927 3.099 10.81 0 6.964 0" transform="translate(-366 -506) translate(342 377) translate(24 128) translate(0 1)"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</svg>
							Saving (Baht)
						</strong>
					</th>
					<th class="text-right">
						<strong>
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="16" viewBox="0 0 14 16" style="position: relative; top: 2px;">
								<defs>
									<path id="q3xdls03oa" d="M0.037 0.025L7.95 0.025 7.95 16 0.037 16z"/>
								</defs>
								<g fill="none" fill-rule="evenodd">
									<g>
										<g>
											<g>
												<g transform="translate(-366 -426) translate(342 377) translate(24 49) translate(2.864)">
													<mask id="pu57ttot8b" fill="#fff">
														<use xlink:href="#q3xdls03oa"/>
													</mask>
													<path fill="#103332" d="M4.808.025c.28.167.297.428.26.724-.086.68-.155 1.36-.231 2.041l-.259 2.32-.161 1.467H7.483c.415 0 .578.277.388.662l-4.153 8.448c-.146.295-.371.386-.612.253-.17-.093-.219-.247-.2-.432.054-.526.109-1.053.166-1.579.075-.69.153-1.382.229-2.073l.203-1.872c.009-.08.012-.163.02-.26h-.175L.56 9.722c-.44 0-.643-.34-.446-.75C1.497 6.096 2.882 3.216 4.273.34c.06-.124.194-.21.294-.315h.241z" mask="url(#pu57ttot8b)"/>
												</g>
												<path fill="#103332" d="M0 7.65c.03-.261.058-.523.094-.783.1-.739.33-1.438.666-2.101.542-1.074 1.31-1.956 2.299-2.639.826-.57 1.733-.95 2.723-1.126.08-.014.159-.025.263-.041l-.257.523c-.225.453-.446.909-.679 1.358-.038.074-.114.147-.19.182-1.442.661-2.453 1.729-2.94 3.242-.564 1.747-.284 3.383.794 4.867.62.854 1.445 1.452 2.434 1.822.067.025.139.034.126.14-.064.533-.123 1.067-.187 1.626-.197-.063-.383-.112-.562-.181-1.17-.453-2.164-1.154-2.965-2.124-.797-.965-1.294-2.07-1.515-3.3C.052 8.828.034 8.533 0 8.24v-.59zM8.64 2.98l.196-1.7c2.779.702 5.283 3.481 5.16 7.002-.14 3.986-3.313 6.452-6.041 6.758.026-.058.047-.108.071-.156.29-.568.581-1.135.865-1.705.055-.11.128-.174.238-.225 1.38-.641 2.36-1.666 2.853-3.107.634-1.85.331-3.567-.861-5.116-.602-.782-1.385-1.333-2.309-1.683l-.171-.067" transform="translate(-366 -426) translate(342 377) translate(24 49)"/>
											</g>
										</g>
									</g>
								</g>
							</svg>
							Power (kWh)
						</strong>
					</th>
					<th class="text-right">
						<strong>
							Daily Average Power (kWh)
						</strong>
					</th>
					<th class="text-center">
						<strong>
							Average Solar Cell Efficiency (%)
						</strong>
					</th>
				</tr>
			</template>
			<template v-slot:tbody>
				<tr
					v-for="(item, index) in data"
					:key="index"
				>
					<td>
						<span class="font-bold">
							{{ item.dateString }}
						</span>
					</td>
					<td>
						<div class="flex-justify-end">
							<span class="font-bold save-baht text-right">
								{{ item.amountTotal | currency }}
							</span>
						</div>
					</td>
					<td>
						<div class="flex-justify-end">
							<span class="font-bold power text-right">
								{{ item.total | currency }}
							</span>
						</div>
					</td>
					<td>
						<div class="flex-justify-end">
							<span class="daily-average-power text-right">
								{{ item.amountPartial | currency }}
							</span>
						</div>
					</td>
					<td>
						<div class="flex-justify-end">
							<span class="average-solar-cell-efficiency text-right">
								{{ `${item.amountReactive * 100}%` }}
							</span>
						</div>
					</td>
				</tr>
			</template>
		</BaseTableCustom>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
import Empty from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";
import { getUri } from "../../selectors/helpers";
import userPermission from "../../mixins/userPermission";

export default {
	name: "meterOrder",

	mixins: [userPermission],

	components: {
		Empty,
		BaseTableCustom,
		BaseFilterExport
	},

	data() {
		return {
			id: this.$route.params.id,
			query: {
				page: this.$route.query.page
			},
			tableOptions: [
				"is-striped is-fullwidth"
			]
		};
	},

	computed: {
		...mapState("sorHistory", {
			data: "data",
			pagination: "pagination",
			loading: "loading",
			empty: "empty",
			statusCode: "statusCode"
		})
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.id,
			httpStatus: this.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("sorHistory", {
			getSorHistory: "getSorHistory"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),
		updateUrl() {
			const { query } = this;
			this.$router.replace({ query }).catch(() => {});
		},
		async fetchData() {
			this.updateUrl();
			await this.getSorHistory({ id: this.id, params: this.query });
		},
		handlePageChange(page = 1) {
			this.query.page = page;
			this.fetchData();
		},
		handleSubmitExport(form) {
			// docs: https://bigportal.qa-api.bigth.com/doc/#api-SolarCell-GetSolarcellsIdExportDailyConsumptions
			const params = {
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).format("YYYY-MM-DD"),
						endDate: dayjs(form.dateRange.end).format("YYYY-MM-DD")
					} : null
				)
			};
			const path = `/solarCells/${this.id}/export-daily-consumptions`;
			const uri = getUri(path, params);
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.flex-justify-end {
	display: flex;
	justify-content: flex-end;
}

.font-bold {
	font-weight: $font-weight-bold;
}

.save-baht {
	width: rem(102);
	margin-right: 20%;
}

.power {
	width: rem(100);
	margin-right: 22%;
}

.daily-average-power {
	width: rem(164);
	margin-right: 30%;
}

.average-solar-cell-efficiency {
	width: rem(202);
	margin-right: 40%;
}

.custom-filter-export {
	margin-top: rem(-8);
	margin-bottom: rem(11);
	justify-content: flex-end;
}
</style>