<template>
	<div class="container-head">
		<BaseHeadColumn show-line-bottom class="order-head-column">
			<template #first-column>
				<strong>Date</strong>
			</template>
			<template #rest-column>
				<div class="text-right value">
					<strong>Monthly Dross (kg)</strong>
				</div>
				<div class="text-right value">
					<strong>Dross: Finished Good</strong>
				</div>
				<div class="text-right value">
					<strong>Monthly Nitrogen (Nm³)</strong>
				</div>
				<div class="text-right value">
					<strong>N2: Finished good</strong>
				</div>
			</template>
		</BaseHeadColumn>
	</div>
</template>

<script>
import BaseHeadColumn from "@/components/BaseHeadColumn.vue";

export default {
	components: {
		BaseHeadColumn
	}
};
</script>

<style lang="scss" scoped>
.text-right {
	text-align: right !important;
}

.date {
	margin-left: rem(24);
}

.value {
	margin-right: rem(24);
}

.order-head-column {
	padding: rem(12) 0;
}
</style>