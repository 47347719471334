import Numeral from "numeral";
import { NM_UNIT } from "../../unit";
import { transformBarChart } from "..";

const optionOxygenConsumption = {
	yAxisText: `Oxygen flow (${NM_UNIT})`,
	series: {
		tooltip: {
			formatter: ({ value, name }) => `
        <div>
            <div class="bar-name meter">
                ${name}
            </div>
            <div class="bar-value">
                ${Numeral(value).format(0, 0)} Nm<sup>3</sup>
            </div>
        </div>`

		}
	}
};
export const transformOxygenConsumptionBarchart = transformBarChart(optionOxygenConsumption);
