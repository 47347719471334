import { getEOrderingCheckFailedOrderAPI, getEOrderingPendingOrderListAPI } from "@/services/api/eOrdering.api";
import {
	E_ORDERING_PENDING_ORDER_LIST_REQUEST,
	E_ORDERING_PENDING_ORDER_LIST_SUCCESS,
	E_ORDERING_PENDING_ORDER_LIST_FAILURE,
	E_ORDERING_CHECK_FAILED_ORDER_REQUEST,
	E_ORDERING_CHECK_FAILED_ORDER_SUCCESS,
	E_ORDERING_CHECK_FAILED_ORDER_FAILURE
} from "../../types";
import { transformPendingOrderList } from "../../../selectors/transform/eOrdering/transformPendingOrderList";

export default {
	namespaced: true,

	state: {
		data: transformPendingOrderList([]),
		isLoading: false,
		isPendingOrdersEmpty: false,
		isFailedOrdersEmpty: false,
		hasFailedOrder: false
	},

	mutations: {
		[E_ORDERING_PENDING_ORDER_LIST_REQUEST](state) {
			state.isLoading = true;
			state.data = transformPendingOrderList([]);
		},
		[E_ORDERING_PENDING_ORDER_LIST_SUCCESS](state, data) {
			state.isLoading = false;
			state.data = transformPendingOrderList(data);
			state.isPendingOrdersEmpty = state.data?.pendingOrders.length === 0;
			state.isFailedOrdersEmpty = state.data?.failedOrders.length === 0;
		},
		[E_ORDERING_PENDING_ORDER_LIST_FAILURE](state) {
			state.data = transformPendingOrderList([]);
			state.isPendingOrdersEmpty = true;
			state.isFailedOrdersEmpty = true;
			state.isLoading = false;
		},
		[E_ORDERING_CHECK_FAILED_ORDER_REQUEST](state) {
			state.hasFailedOrder = false;
		},
		[E_ORDERING_CHECK_FAILED_ORDER_SUCCESS](state, hasFailedOrder) {
			state.hasFailedOrder = hasFailedOrder;
		},
		[E_ORDERING_CHECK_FAILED_ORDER_FAILURE](state) {
			state.hasFailedOrder = false;
		}
	},

	actions: {
		async getEOrderingPendingOrderLists({ commit }) {
			try {
				commit(E_ORDERING_PENDING_ORDER_LIST_REQUEST);
				const { data } = await getEOrderingPendingOrderListAPI();
				commit(E_ORDERING_PENDING_ORDER_LIST_SUCCESS, data);
			} catch (error) {
				commit(E_ORDERING_PENDING_ORDER_LIST_FAILURE);
			}
		},

		async checkFailedOrders({ commit }) {
			try {
				commit(E_ORDERING_CHECK_FAILED_ORDER_REQUEST);
				const { data } = await getEOrderingCheckFailedOrderAPI();
				commit(E_ORDERING_CHECK_FAILED_ORDER_SUCCESS, data.isFailed);
			} catch (error) {
				commit(E_ORDERING_CHECK_FAILED_ORDER_FAILURE);
			}
		}
	}
};
