import {
	OVER_ALL_USER_ROLES_REQUEST,
	OVER_ALL_USER_ROLES_SUCCESS,
	OVER_ALL_USER_ROLES_FAILURE
} from "../../types";

import { getUserRoleAPI } from "../../../services/api/monitor.api";

export default {
	namespaced: true,

	state: {
		staffRoles: [],
		customerRoles: [],
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getStaffRole(state) {
			return state.staffRoles;
		},

		getCustomerRole(state) {
			return state.customerRoles;
		}
	},
	mutations: {
		[OVER_ALL_USER_ROLES_REQUEST](state) {
			state.isLoading = true;
		},
		[OVER_ALL_USER_ROLES_SUCCESS](state, data) {
			state.isLoading = false;
			state.staffRoles = data.totalStaffs || [];
			state.customerRoles = data.totalCustomers || [];
		},
		[OVER_ALL_USER_ROLES_FAILURE](state) {
			state.isLoading = false;
			state.staffRoles = [];
			state.customerRoles = [];
			state.isEmpty = true;
		}

	},

	actions: {
		async getUserRole({ commit }, { filter = "" }) {
			try {
				commit(OVER_ALL_USER_ROLES_REQUEST);
				const computeFilter = filter ? { filter } : {};
				const { data } = await getUserRoleAPI(computeFilter);
				commit(OVER_ALL_USER_ROLES_SUCCESS, data);
			} catch (e) {
				commit(OVER_ALL_USER_ROLES_FAILURE);
			}
		},
		async getUserRoleWithFilter({ commit }, { filter }) {
			try {
				commit(OVER_ALL_USER_ROLES_REQUEST);
				const { data } = await getUserRoleAPI({ filter });
				commit(OVER_ALL_USER_ROLES_SUCCESS, data);
			} catch (e) {
				commit(OVER_ALL_USER_ROLES_FAILURE);
			}
		}
	}
};
