<template>
	<BaseLayout>
		<BaseBackBtn v-if="!viewOnly" :to="PAGE_NAME.FLOWDIAGRAM_LIST" label="Back to Diagram" class="page-back-btn"/>
		<BaseLoading v-if="loading"/>
		<div v-if="!loading" :class="['container-action-bar', { 'view-only': viewOnly }]">
			<strong>
				{{ title }}
			</strong>
			<BaseButton
				v-if="isEditMode"
				:disabled="disabledAddFlow"
				size="medium"
				rounded
				@click="onAddFlow"
			>
				Add new diagram
			</BaseButton>
			<BaseButton
				v-if="!isEditMode && !viewOnly"
				size="medium"
				rounded
				@click="onEdit"
			>
				Edit diagram
			</BaseButton>
		</div>
		<div v-if="!loading" class="flow-content">
			<BaseFlowDiagramShipToInfo
				:query="query"
				:shipTos="shipTos"
				:company="company.name"
				:shipToCode="company.code"
				:shipToKey="company.locationKey || company.key"
				:viewOnly="viewOnly"
				:class="`custom-shipto-info`"
			/>
			<BaseFlowDiagramDeviceList
				v-if="isEditMode && meterDevices.length"
				:title="`All Nitrogen`"
				:items="meterDevices"
				:selectedNodeIds="selectedNodeIds"
				:class="`custom-device-list`"
			/>
			<BaseFlowDiagramDeviceList
				v-if="isEditMode && electricityDevices.length"
				:title="`All Power`"
				:items="electricityDevices"
				:selectedNodeIds="selectedNodeIds"
				:class="`custom-device-list`"
			/>
			<BaseFlowDiagramAdjust
				v-for="(diagram, index) in diagrams"
				:key="index"
				:input.sync="diagram.input"
				:tree.sync="diagram.tree"
				:validateInputFn.sync="diagram.validateInputFn"
				:selectedNodeIds="selectedNodeIds"
				:id="diagram.id"
				:meter="meterDevices"
				:electricity="electricityDevices"
				:zoomable="zoomable"
				:removeable="diagramsState[index].removeable"
				:editMode="isEditMode"
				:deletedAt="diagram.deletedAt"
				:collapse="index > 0"
				:focused.sync="focused.diagramId"
				:class="`custom-flow-diagram`"
				@onRemove="handleRemove"
			/>
			<slot v-if="isEditMode" name="action"/>
		</div>
		<BaseModalConfirmDelete
			:modal-name="MODAL_NAME_CONFIRM_DELETE_THIS_FLOW"
			:title="`Delete Diagram`"
			:message="`
					<div>
						Are you sure to delete the diagram?
					</div>
					<div>
						This diagram will be deleted.
					</div>
				`"
			:isSummited="isSummited.confirmDelete"
			@onCancel="handleCancleDeleteThisFlow"
			@onConfirm="handleConfirmDeleteThisFlow"
		/>
	</BaseLayout>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseFlowDiagramAdjust from "@/components/BaseFlowDiagramAdjust.vue";
import BaseFlowDiagramShipToInfo from "@/components/BaseFlowDiagramShipToInfo.vue";
import BaseFlowDiagramDeviceList from "@/components/BaseFlowDiagramDeviceList.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { getElecDevices, getMeterDevices, getNodeIds } from "../../selectors/helpers/flowDiagram";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	components: {
		BaseBackBtn,
		BaseFlowDiagramAdjust,
		BaseFlowDiagramShipToInfo,
		BaseFlowDiagramDeviceList,
		BaseModalConfirmDelete,
		BaseLoading
	},

	props: {
		value: {
			type: Array,
			default: () => ([])
		},
		loading: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: null
		},
		query: {
			type: Object,
			default: () => ({})
		},
		initEmptyNode: {
			type: Boolean,
			default: false
		},
		editable: {
			type: Boolean,
			default: false
		},
		zoomable: {
			type: Boolean,
			default: false
		},
		viewOnly: {
			type: Boolean,
			default: false
		},
		devices: {
			type: Array,
			default: () => ([])
		},
		shipTos: {
			type: Array,
			default: () => ([])
		},
		isValid: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			PAGE_NAME,
			MODAL_NAME_CONFIRM_DELETE_THIS_FLOW: "MODAL_NAME_CONFIRM_DELETE_THIS_FLOW",
			isSummited: {
				confirmDelete: false
			},
			dataToDelete: null,
			isEditMode: false,
			diagrams: [],
			focused: {
				diagramId: null
			},
			selectedNodeIds: []
		};
	},

	computed: {
		company() {
			return this.shipTos.find((item) => {
				return item.id === parseInt(this.query.id, 10);
			}) || {};
		},
		electricityDevices() {
			return getElecDevices(this.devices);
		},
		meterDevices() {
			return getMeterDevices(this.devices);
		},
		diagramsState() {
			let index = -1;
			const undeletedItems = this.diagrams.filter((item) => !item.deletedAt);
			return this.diagrams.map((item) => {
				// count index only for undeleted item
				if (!item.deletedAt) {
					index++;
				}
				const removeable = (() => {
					const isFirstItem = index === 0;
					const isSecondItemOrAbove = index > 0;
					const hasItemsMoreThenOne = undeletedItems.length > 1;
					if (isFirstItem && hasItemsMoreThenOne) {
						return true;
					}
					if (isSecondItemOrAbove && hasItemsMoreThenOne) {
						return true;
					}
					return false;
				})();
				return { removeable };
			});
		},
		disabledAddFlow() {
			let disabled = true;
			const diagrams = this.diagrams.filter((item) => !item.deletedAt);
			for (let i = 0; i < diagrams.length; i++) {
				if (diagrams[i].tree && diagrams[i].tree.type !== "empty") {
					disabled = false;
					break;
				}
			}
			return disabled;
		}
	},

	watch: {
		diagrams: {
			handler(newValue) {
				this.setSelectedIds();
				this.$emit("input", newValue);
			},
			deep: true
		},
		"focused.diagramId": {
			handler() {
				this.setSelectedIds();
			},
			deep: true
		},
		value: {
			handler(newValue) {
				this.diagrams = newValue;
			},
			deep: true
		},
		editable(newValue) {
			this.isEditMode = newValue;
		}
	},

	mounted() {
		this.setState();
		this.$emit("update:validateFormFn", this.validateFormFn);
	},

	methods: {
		setState() {
			if (this.initEmptyNode) {
				this.addEmptyNode();
			}
			if (this.value.length) {
				this.diagrams = this.value;
			}
			if (this.editable) {
				this.isEditMode = true;
			}
		},
		addEmptyNode() {
			this.diagrams.push({
				id: uuidv4(),
				input: {
					name: null
				},
				tree: null,
				validateInputFn: null,
				deletedAt: null
			});
		},
		onAddFlow() {
			this.addEmptyNode();
		},
		onEdit() {
			this.isEditMode = true;
			this.$emit("onEdit", this.isEditMode);
			if (!this.diagrams.length) {
				this.addEmptyNode();
			}
		},
		handleCancleDeleteThisFlow() {
			this.$modal.hide(this.MODAL_NAME_CONFIRM_DELETE_THIS_FLOW);
		},
		handleConfirmDeleteThisFlow() {
			const { id } = this.dataToDelete;
			this.diagrams = this.diagrams.map((item) => {
				return {
					...item,
					deletedAt: !item.deletedAt && item.id === id
						? new Date()
						: item.deletedAt
				};
			});
			this.$modal.hide(this.MODAL_NAME_CONFIRM_DELETE_THIS_FLOW);
		},
		handleRemove(data) {
			this.dataToDelete = data;
			this.$modal.show(this.MODAL_NAME_CONFIRM_DELETE_THIS_FLOW);
		},
		validateFormFn() {
			let isValid = true;
			const diagrams = this.diagrams.filter((item) => !item.deletedAt);
			for (const diagram of diagrams) {
				const isValidInput = diagram.validateInputFn();
				if (!isValidInput) {
					isValid = false;
				}
			}
			return isValid;
		},
		setSelectedIds() {
			this.selectedNodeIds = this.diagrams
				.filter((item) => {
					return !item.deletedAt && item.id === this.focused.diagramId;
				})
				.reduce((acc, cur) => {
					if (cur.tree) {
						const nodeIds = getNodeIds(cur.tree);
						acc.push(...nodeIds);
					}
					return acc;
				}, []);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: rem(19);
	margin-bottom: rem(18);

	&.view-only {
		margin-top: rem(4);
	}

	strong {
		font-size: $font-32;
		font-family: $font-family-sub;
		color: $color-dark-blue-grey;
	}
}

.custom-shipto-info {
	margin-bottom: rem(8);
}

.custom-device-list {
	margin-bottom: rem(8);

	&:last-of-type {
		margin-bottom: 0;
	}
}

.custom-flow-diagram {
	margin-bottom: rem(24);
}
</style>