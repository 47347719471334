<template>
	<div class="col-12 card-pie-chart-wrapper">
		<BaseCard class='chart card-pie-chart'>
			<template v-slot:header>

				<h4>{{ chartTitle }}</h4>
				<div class="detail-card-header">
					<div v-if="showLastUpdate" class="last-update">
						Last update: {{ lastUpdateFormatted }}
					</div>
					<div v-if="showDateRangeLastUpdate" class="date-range">
						{{ showDateRangeLastUpdate }}
					</div>
					<template v-if="showDateFilter">
						<BaseButtonGroup  v-bind="mapDateDropdownItems" @click="handleDateDropdownSelected"/>
					</template>
				</div>
			</template>
			<template v-slot:body>
				<template v-if="isHassortSlot">
					<div class="wrapper-sort-tab">
						<slot name="sortTab"></slot>
					</div>
				</template>
				<div v-if="isLoading" class="loading-wrapper">
					<BaseLoading />
				</div>
				<BaseNoData
					v-if="isEmpty"
					class="graph"
					description="BIG portal will start showing the graph <br/> right after we have information"
				/>
				<PieChart v-else-if="!isLoading" :chartData="chartData"/>
			</template>
		</BaseCard>
	</div>
</template>

<script>
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import dayjs from "dayjs";
import PieChart from "./PieChart.vue";
import BaseCard from "../BaseCard.vue";
import { getDateNumber, getDateRange } from "../../selectors/helpers";

export default {
	components: {
		PieChart,
		BaseCard,
		BaseNoData: () => import("../BaseNoData.vue"),
		BaseLoading: () => import("../BaseLoading.vue"),
		BaseButtonGroup
	},
	props: {
		isEmpty: {
			type: Boolean,
			default: false
		},
		lastUpdate: {
			type: String,
			default: ""
		},
		dateRange: {
			type: String,
			default: ""
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		chartTitle: {
			type: String,
			default: "Delivery"
		},
		chartData: {
			type: Object,
			default: () => ({ xAxis: [], yAxis: [] })
		},
		filterOptions: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			form: {
				date: {
					dropdownValue: null
				}
			}
		};
	},
	created() {
		if (this.showDateFilter) {
			const rangeDate = this.filterOptions.date.selected.range;
			const dateNumber = getDateNumber(rangeDate);
			this.form.date.dropdownValue = dateNumber;
		}
	},
	computed: {
		isHassortSlot() {
			return this.$slots.sortTab;
		},
		showDateFilter() {
			return this.filterOptions.date !== undefined;
		},
		showLastUpdate() {
			return this.lastUpdateFormatted !== "";
		},
		showDateRangeLastUpdate() {
			return this.dateRange;
		},
		lastUpdateFormatted() {
			const format = "D MMM YYYY, HH:mm";
			const { lastUpdate } = this;
			return lastUpdate
				? dayjs(lastUpdate).format(format)
				: "";
		},
		mapDateDropdownItems() {
			if (!this.showDateFilter) {
				return null;
			}

			return {
				items: this.filterOptions.date.dropdown.items.map((item) => ({
					text: item.label,
					value: item.value
				})),
				active: this.form.date.dropdownValue
			};
		}
	},
	methods: {
		handleDateDropdownSelected(dateNumber) {
			const { start, end } = getDateRange(dateNumber);
			this.form.date.dropdownValue = dateNumber;
			this.$emit("dateSelected", { start, end });
		}
	}
};
</script>
<style lang="scss" scoped>
	.card-pie-chart-wrapper {
		height: 100%;
	}

	.wrapper-sort-tab {
		margin-bottom: rem(36);
	}

	.card-pie-chart {
		height: 100%;
	}


	.detail-card-header {
		display: flex;
		align-items: center;

		& > * {
			margin-left: rem(10);
		}

		span {
			color: $color-grey-4;
		}
	}

	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: rem(387);
	}

	.last-update {
		font-family: $font-family-base;
		font-size: rem(18);
		font-style: italic;
		color: $color-dark-blue-grey;
		margin-right: rem(14);
	}

	.custom-date-dropdown {
		width: auto;

		/* stylelint-disable */
		&::v-deep .dropdown-value {
			border-color: $color-silver;
			background-color: $color-white;
		}

		&::v-deep .dropdown-value-text, &::v-deep .dropdown-placeholder {
			color: $color-grey-4;
			font-size: $font-16;
			font-weight: $font-weight-bold;
		}
		/* stylelint-enable */
	}
</style>
