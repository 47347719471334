<template>
	<BaseLayout :isLoading="isLoading">
		<BaseBackBtn label="Back to Dashboard" />
		<BaseBreadcrumb :item="breadcrumb" class="has-dot-separator" />
		<ModuleHeader :data="airCompInfo.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<ul class="nav nav-tabs">
				<template v-for="item in navTabData">
					<li
						v-if="item.permissions"
						:key="item.index"
						class="nav-item"
					>
						<router-link
							:to="{ name: item.routerName }"
							active-class="active"
							class="nav-link"
						>
							{{ item.title }}
						</router-link>
					</li>
				</template>
			</ul>
			<router-view
				@loaded="handleContentLoaded"
				:shipToName="shipToName"
			/>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseBreadcrumb from "@/components/BaseBreadcrumb.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModuleHeader from "@/components/dashboard/modules/ModuleHeader.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import userPermission from "../../mixins/userPermission";
import { CONFIG } from "../../enums/config";

export default {
	name: "AirComp",

	components: {
		BaseBreadcrumb,
		BaseBackBtn,
		ModuleHeader
	},

	data() {
		return {
			PAGE_NAME,
			id: this.$route.params.id,
			isLoading: true,
			fetchDevices: () => {}
		};
	},

	mixins: [userPermission],

	computed: {
		...mapState(["airCompInfo", "shipTos"]),
		...mapGetters("user", {
			getProfile: "getProfile"
		}),

		breadcrumb() {
			return {
				type: {
					name: "Utilities"
				},
				shiped: {
					name: this.shipTos.data.name
				}
			};
		},
		shipToId() {
			return this.airCompInfo.data?.shipToId || null;
		},
		shipToName() {
			return this.shipTos.data.name;
		},
		navTabData() {
			return [
				{
					title: "Equipment information",
					routerName: PAGE_NAME.AIR_COMP_INFO,
					permissions: true
				},
				{
					title: "History",
					routerName: PAGE_NAME.AIR_COMP_HISTORY,
					permissions: true
				},
				{
					title: "Analysis",
					routerName: PAGE_NAME.AIRCOMP_POWERBI,
					permissions: this.permissions.powerBI.read && this.hasPowerBiReport
				}
			];
		},
		hasPowerBiReport() {
			return this.airCompInfo.data.reportId && this.airCompInfo.data.workspaceId;
		}
	},

	async created() {
		await this.fetchAirCompInfo(this.id);

		this.autoRefreshData = setInterval(async () => {
			if (!this.airCompInfo.isAutoFecthLoading) {
				await this.autoFetchAirCompInfo(this.id);
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		// save log for tab info only
		if (this.$route.name === PAGE_NAME.AIR_COMP_INFO) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: this.airCompInfo.statusCode
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);

				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("airCompInfo", {
			fetchAirCompInfo: "fetchAirCompInfo",
			autoFetchAirCompInfo: "autoFetchAirCompInfo",
			clearAirCompInfo: "clearAirCompInfo"
		}),
		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		handleContentLoaded() {
			this.fetchDevices();
		}
	},

	destroyed() {
		this.clearAirCompInfo();
	}
};
</script>