import httpClient from "./httpClient";

const SOLDTO_MANANGEMENT_END_POINT = "/sold-tos/managed";
const getSoldToManagementAPI = (params) => httpClient.get(`${SOLDTO_MANANGEMENT_END_POINT}`, { params });
const putSoldToManagementAPI = (payload) => httpClient.put(`${SOLDTO_MANANGEMENT_END_POINT}`, payload);

export {
	getSoldToManagementAPI,
	putSoldToManagementAPI
};
