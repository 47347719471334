<template>
	<div :class="['table-sidebar', ...wrapperClass]">
		<div class="container-close">
			<button
				class="button-close"
				@click="toggleUITabletSidebar"
			>
				<FontAwesomeIcon :icon="['far', 'times']" class="icon" />
			</button>
		</div>
		<div class="container-menu">
			<SidebarMenuList v-if="isUITabletSidebarOpen"/>
			<BaseContact
				mode="mobile"
				type="text"
				:modalCompanyOverView="MODAL_COMPANY_OVERVIEW_MOBILE"
				:modalBussinessPhilosophy="MODAL_BUSINESS_PHILOSOPHY_MOBILE"
				@openModal="showModal"
			/>
		</div>
	</div>
</template>

<script>
import SidebarMenuList from "@/components/TheMainSidebarMenu.vue";
import BaseContact from "@/components/BaseContact.vue";
import { mapState, mapActions } from "vuex";
import { MODAL_NAME } from "../../enums/modal";

const { MODAL_COMPANY_OVERVIEW_MOBILE, MODAL_BUSINESS_PHILOSOPHY_MOBILE } = MODAL_NAME;

export default {
	name: "TableSidebar",
	data() {
		return {
			MODAL_COMPANY_OVERVIEW_MOBILE,
			MODAL_BUSINESS_PHILOSOPHY_MOBILE
		};
	},
	components: {
		SidebarMenuList,
		BaseContact
	},

	computed: {
		...mapState(["sidebar"]),

		wrapperClass() {
			return { show: this.isUITabletSidebarOpen };
		},

		isUITabletSidebarOpen() {
			return this.sidebar.isUITabletSidebarOpen;
		}
	},

	methods: {
		...mapActions({
			toggleUITabletSidebar: "sidebar/toggleUITabletSidebar"
		}),
		showModal(modalName) {
			this.$modal.show(modalName);
		}
	}
};
</script>

<style lang="scss" scoped>
.table-sidebar {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: $z-index-tablet-sidebar;
	top: 0;
	left: 0;
	width: 100%;
	padding: rem(24);
	background-color: $sidebar-bg-color;
	opacity: 0;
	visibility: hidden;
	transform: scale(0);
	transition: $transition-fadeout-fastest;
	/* stylelint-disable */
	min-height: 100vh;
	min-height: var(--app-min-vh, 100vh);
	/* stylelint-enable */
	height: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: $transition-fadein-fastest;
	}

	.container {
		&-close {
			display: flex;
			justify-content: flex-end;

			.button-close {
				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(20);
				height: rem(20);
				background: none;
				border: none;
				color: $sidebar-menu-color;
			}
		}

		&-menu {
			margin-top: rem(24);
			overflow-y: auto;
		}
	}
}
</style>
