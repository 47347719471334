<template>
	<div class="chartbar-container">
		<v-chart
			ref="bar"
			:options="chartData"
			:manual-update="manualUpdate"
			autoresize
			theme="ovilia-green"/>
	</div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/dataset";
import "zrender/lib/svg/svg";

export default {
	components: {
		"v-chart": ECharts
	},
	props: {
		chartData: {
			type: Object
		},
		manualUpdate: {
			type: Boolean,
			default: true
		}

	}
};
</script>

<style lang="scss" scroped>
.chartbar-container {
	width: 100%;
	height: rem(318);
}

.echarts {
	width: 100%;
	height: 100%;
}

.meter {
	margin-bottom: rem(-8);
}

.bar-name {
	font-size: rem(16);
	font-family: $font-family-base;
	font-weight: bold;
}

.bar-value {
	font-family: $font-family-sub;
	font-size: rem(20);
	font-weight: bold;
}

.bar-container {
	display: flex;
	justify-content: space-between;
}
</style>