import httpClient from "./httpClient";

const E_ORDERING_END_POINT = "/e-ordering";

const getEOrderingOrderListsAPI = (params) => httpClient.get(E_ORDERING_END_POINT, { params });
const getEOrderingHistoriesAPI = (params) => httpClient.get(`${E_ORDERING_END_POINT}/histories`, { params });
const getEOrderingInfoAPI = (id) => httpClient.get(`${E_ORDERING_END_POINT}/infos/${id}`);
const postEOrderingCreateAPI = (data) => httpClient.post(`${E_ORDERING_END_POINT}/create`, data);
const getEOrderingRestrictions = (params) => httpClient.get(`${E_ORDERING_END_POINT}/restrictions`, { params });
const deleteEOrderingInfoAPI = (id) => httpClient.delete(`${E_ORDERING_END_POINT}/infos/${id}`);
const putEOrderingInfoAPI = (id, params) => httpClient.put(`${E_ORDERING_END_POINT}/infos/${id}`, params);
const getEOrderingShipToAPI = () => httpClient.get(`${E_ORDERING_END_POINT}/shipTos`);
const getEOrderingDevicesAPI = (id, params) => httpClient.get(`${E_ORDERING_END_POINT}/shipTos/${id}/devices`, { params });
const getEOrderingConfigurationAPI = () => httpClient.get(`${E_ORDERING_END_POINT}/configs`);
const getEOrderingTripInfoAPI = (params) => httpClient.get(`${E_ORDERING_END_POINT}/trip-infos`, { params });
const postEOrderingCreateBatchOrderAPI = (data) => httpClient.post(`${E_ORDERING_END_POINT}/batch-orders`, data);
const getEOrderingPendingOrderListAPI = () => httpClient.get(`${E_ORDERING_END_POINT}/batch-orders`);
const getEOrderingCheckFailedOrderAPI = () => httpClient.get(`${E_ORDERING_END_POINT}/batch-orders/has-failed`);
const putEOrderingReOrderAPI = (failedOrderId) => httpClient.put(`${E_ORDERING_END_POINT}/batch-orders/${failedOrderId}/re-order`);
const deleteEOrderingDiscardFailedOrdersAPI = () => httpClient.delete(`${E_ORDERING_END_POINT}/batch-orders/discard-orders`);

export {
	getEOrderingOrderListsAPI,
	getEOrderingHistoriesAPI,
	getEOrderingInfoAPI,
	postEOrderingCreateAPI,
	getEOrderingRestrictions,
	deleteEOrderingInfoAPI,
	putEOrderingInfoAPI,
	getEOrderingShipToAPI,
	getEOrderingDevicesAPI,
	getEOrderingConfigurationAPI,
	getEOrderingTripInfoAPI,
	postEOrderingCreateBatchOrderAPI,
	getEOrderingPendingOrderListAPI,
	getEOrderingCheckFailedOrderAPI,
	putEOrderingReOrderAPI,
	deleteEOrderingDiscardFailedOrdersAPI
};
