import dayjs from "dayjs";
import { getNumeral } from "../lineChart/helper";

export const getDateReportFormat = (format = "year") => {
	const calculateDayJS = {
		year: (date) => {
			const isStartOfYear = dayjs(date).get("month") === 0;
			return isStartOfYear ? dayjs(date).format("MMM\nYYYY") : dayjs(date).format("MMM");
		},
		day: (date) => {
			const isFirstDateOfYear = dayjs(date).get("date") === 1;
			const isFirstMonthOfYear = dayjs(date).get("month") === 0;
			return isFirstMonthOfYear && isFirstDateOfYear
				? dayjs(date).format("D MMM\nYYYY")
				: dayjs(date).format("D MMM");
		},
		month: (date) => {
			const isFirstDate = dayjs(date).get("date") === 1;
			const isFirstMonthOfYear = dayjs(date).get("month") === 0;
			if (isFirstMonthOfYear && isFirstDate) {
				return dayjs(date).format("D\nMMM\nYYYY");
			}

			return dayjs(date).format("D\nMMM");
		}
	};
	return calculateDayJS[format];
};

export const tooltipTransformDate = (format = "year") => {
	const calculateDayJS = {
		year: (date) => dayjs(date).format("MMM YYYY"),
		day: (date) => dayjs(date).format("D MMM YYYY"),
		month: (date) => dayjs(date).format("D MMM YYYY")
	};
	return calculateDayJS[format];
};

export const transformDataBarChart = (
	response,
	options = {}
) => {
	const defaultOptions = {
		dateReportFormat: "year",
		barColor: "#ea7125",
		barSize: 40
	};
	const mergedOptions = {
		...defaultOptions,
		...options
	};
	const { data = [] } = response;
	const transformdate = getDateReportFormat(mergedOptions.dateReportFormat);
	const transformTooltipDate = tooltipTransformDate(mergedOptions.dateReportFormat);
	const defaultResult = {
		xAxis: [],
		yAxis: []
	};
	const result = data.reduce((acc, { date, value }) => {
		const isValueGraterThanZero = Number(value) > 0;
		return {
			xAxis: [...acc.xAxis, transformdate(date)],
			yAxis: {
				name: "value",
				type: "bar",
				barWidth: `${mergedOptions.barSize}px`,
				data: [
					...(acc.yAxis.data || []),
					{
						name: transformTooltipDate(date),
						value: Number(value),
						itemStyle: {
							color: mergedOptions.barColor,
							barBorderRadius: isValueGraterThanZero ? [2, 2, 0, 0] : [0, 0, 2, 2]
						}
					}
				]
			}
		};
	}, defaultResult);
	return result;
};

export const transformDataBarChartNaturalGasConsumption = (
	response,
	options = {}
) => {
	const defaultOptions = {
		dateReportFormat: "year",
		barColor: "#ea7125",
		barSize: 40
	};
	const mergedOptions = {
		...defaultOptions,
		...options
	};
	const { data = [], tagName } = response;
	if (data.length <= 0) {
		return {
			xAxis: [],
			yAxis: []
		};
	}
	const transformdate = getDateReportFormat(mergedOptions.dateReportFormat);

	const firstData = {
		name: tagName.tagnameMelter,
		type: "bar",
		stack: "one",
		barWidth: mergedOptions.dateSort.includes("2w") ? "" : "30px",
		color: "#ea7125",
		data: data.reduce((acc, { ngMelter }) => {
			return [
				...acc,
				{
					value: Number(ngMelter),
					itemStyle: {
						color: "#ea7125",
						barBorderRadius: [2, 2, 0, 0]
					}
				}
			];
		}, [])
	};
	const secondData = {
		name: tagName.tagnameRefiner,
		type: "bar",
		stack: "two",
		barWidth: mergedOptions.dateSort.includes("2w") ? "" : "30px",
		color: "#00a8e1",
		data: data.reduce((acc, { ngRefiner }) => {
			return [
				...acc,
				{
					value: Number(ngRefiner),
					itemStyle: {
						color: "#00a8e1",
						barBorderRadius: [0, 0, 0, 0]
					}
				}
			];
		}, [])
	};
	return {
		xAxis: data.map((item) => transformdate(item.date)),
		yAxis: [
			{
				...firstData
			},
			{
				...secondData
			}
		]
	};
};

export const transformDataBarChartAirCompEnegySaving = (
	response,
	options = {}
) => {
	const defaultOptions = {
		dateReportFormat: "year",
		barColor: "#ea7125",
		barSize: 40
	};
	const mergedOptions = {
		...defaultOptions,
		...options
	};
	const { data = [], tagName } = response;
	if (data.length <= 0) {
		return {
			xAxis: [],
			yAxis: []
		};
	}
	const transformdate = getDateReportFormat(mergedOptions.dateReportFormat);
	const barWidthDateSort = {
		"2w": "20px",
		"7d": "30px",
		"1m": "15px"
	};
	const firstData = {
		name: tagName.tagNameSavingActual,
		type: "bar",
		stack: "one",
		barWidth: barWidthDateSort[mergedOptions.dateSort],
		color: "#fbb040",
		data: data.reduce((acc, { savingActual }) => {
			return [
				...acc,
				{
					value: Number(savingActual),
					itemStyle: {
						color: "#fbb040",
						barBorderRadius: [2, 2, 0, 0]
					}
				}
			];
		}, [])
	};
	const secondData = {
		name: tagName.tagNameSavingSaving,
		type: "bar",
		stack: "two",
		barWidth: barWidthDateSort[mergedOptions.dateSort],
		color: "#009949",
		data: data.reduce((acc, { savingSaving }) => {
			return [
				...acc,
				{
					value: Number(savingSaving),
					itemStyle: {
						color: "#009949",
						barBorderRadius: [0, 0, 0, 0]
					}
				}
			];
		}, [])
	};
	return {
		xAxis: data.map((item) => transformdate(item.date)),
		yAxis: [
			{
				...firstData
			},
			{
				...secondData
			}
		]
	};
};

export const transformBarChart = (option = {}) => (data) => {
	const defaultLegend = {
		show: false
	};
	const defaultGrid = {
		left: "24px",
		right: "24px",
		bottom: "0",
		containLabel: true
	};
	const { series = {}, yAxisText, xAxisText, xAxisLabel = {}, legend = defaultLegend, grid = defaultGrid } = option;
	const { xAxis = [], yAxis = [] } = data;

	let yAxisData = [];

	if (Array.isArray(yAxis)) {
		yAxisData = yAxis.map((val) => ({
			...val,
			barWidth: "40px",
			barMaxWidth: "50%"
		}));
	} else if (typeof yAxis === "object" && yAxis !== null) {
		yAxisData = {
			...yAxis,
			barWidth: "40px",
			barMaxWidth: "50%"
		};
	}

	return {
		legend,
		tooltip: {
			show: true,
			trigger: "item",
			backgroundColor: "#103332",
			axisPointer: {
				crossStyle: {
					color: "red"
				}
			},
			padding: 8,
			...(series.tooltip ?? {})
		},
		grid,
		xAxis: {
			name: xAxisText,
			type: "category",
			data: xAxis,
			nameTextStyle: {
				color: "#717171",
				fontFamily: "TFSrivichai",
				fontWeight: "bold",
				fontSize: 20
			},
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			axisLabel: {
				fontSize: 14,
				...xAxisLabel

			},
			axisTick: {
				show: false
			}
		},
		yAxis: {
			name: yAxisText,
			type: "value",
			color: "#59e07a",
			nameLocation: "end",
			nameTextStyle: {
				color: "#717171",
				fontFamily: "TFSrivichai",
				fontWeight: "bold",
				fontSize: 20,
				padding: [0, 0, 24, -23],
				align: "left"
			},
			axisLabel: {
				fontSize: 14,
				formatter: getNumeral
			},
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			axisTick: {
				length: 4,
				lineStyle: {
					width: 1,
					color: "#586c63"
				}
			}
		},
		axisLabel: {
			color: () => "#103332",
			fontSize: 14,
			fontFamily: "TFSrivichai"
		},
		series: yAxisData
	};
};


export const transformBulkHydrogenBarChart = (option = {}) => (data) => {
	const defaultLegend = {
		show: false
	};
	const defaultGrid = {
		left: "24px",
		right: "24px",
		bottom: "0",
		containLabel: true
	};
	const { series = {}, yAxisText, xAxisLabel = {}, legend = defaultLegend, grid = defaultGrid } = option;
	const { xAxis = [], yAxis = [] } = data;

	return {
		legend,
		tooltip: {
			show: true,
			trigger: "item",
			backgroundColor: "#103332",
			axisPointer: {
				crossStyle: {
					color: "red"
				}
			},
			padding: 8,
			...(series.tooltip ?? {})
		},
		grid,
		xAxis: {
			type: "category",
			data: xAxis,
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			axisLabel: {
				fontSize: 14,
				...xAxisLabel

			},
			axisTick: {
				show: false
			}
		},
		yAxis: {
			name: yAxisText,
			type: "value",
			color: "#59e07a",
			nameLocation: "end",
			nameTextStyle: {
				color: "#717171",
				fontFamily: "TFSrivichai",
				fontWeight: "bold",
				fontSize: 20,
				padding: [0, 0, 24, -23],
				align: "left"
			},
			axisLabel: {
				fontSize: 14,
				formatter: getNumeral
			},
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			axisTick: {
				length: 4,
				lineStyle: {
					width: 1,
					color: "#586c63"
				}
			}
		},
		axisLabel: {
			color: () => "#103332",
			fontSize: 14,
			fontFamily: "TFSrivichai"
		},
		series: {
			...yAxis,
			barMaxWidth: "40px",
			barWidth: "50%"

		}
	};
};
