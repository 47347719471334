<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<BaseTable
			v-else
			:item="item"
			:tableOptions="tableOptions"
			:columnOptions="columnOptions"
			:pagination="pagination"
			:loading="loading"
			:stickyHeader="true"
			@onPageChange="handlePageChange"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseTable from "@/components/BaseTable.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";

export default {
	name: "BulkHydrogenOrder",

	components: {
		BaseTable,
		EmptyOrderHistory
	},

	data() {
		return {
			id: this.$route.params.id,

			thead: [
				"Date",
				"D/O No.",
				"Trip ID",
				"Tractor ID",
				"Trailer ID",
				"Order amount (Nm³)",
				"Actual amount (Nm³)"
			],

			tableOptions: [
				"is-striped is-fullwidth"
			],

			columnOptions: [
				{ className: "bold" },
				null,
				null,
				null,
				null,
				{ className: "align-right" },
				{ className: "align-right bold" }
			],

			page: this.$route.query.page || 1
		};
	},

	computed: {
		...mapState({
			response: (state) => {
				return state.bhyOrderHistory;
			}
		}),

		data() {
			return this.response.data;
		},

		pagination() {
			return this.response.pagination;
		},

		loading() {
			return this.response.isLoading;
		},

		item() {
			return {
				thead: this.thead,
				tbody: this.data.map((item) => Object.values(item))
			};
		},

		isEmpty() {
			return this.response.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			httpStatus: this.response.statusCode,
			shopToId: this.id
		});
		this.$emit("loaded", this.fetchData);
	},

	methods: {
		...mapActions(["getBhyOrderHistories", "clearBhyHistory"]),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		async fetchData() {
			const { id, page } = this;
			return this.getBhyOrderHistories({ id, page });
		},

		handlePageChange(page = 1) {
			const id = this.id;
			this.fetchData(id, page);
			this.$router.replace({ query: { page } });
		}
	},

	destroyed() {
		this.clearBhyHistory();
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}
</style>
