/* eslint-disable func-names */
/* eslint-disable func-names */
<template>
	<div class="map-container">
		<div ref="maptripinfo" class="ship-to-map"></div>
		<div ref="popups" ><p class="map-companyname">{{ companyName }}</p></div>
	</div>
</template>

<script>
export default {
	name: "TripInfo",

	data() {
		return {
			map: null,
			marker: null
		};
	},
	watch: {
		truckLocation: {
			handler(newLocation) {
				const truckMarker = new google.maps.LatLng(newLocation.lat, newLocation.lng);
				this.marker.setPosition(truckMarker);
			},
			deep: true
		}
	},
	props: {
		truckLocation: {
			type: Object,
			default: () => ({})
		},
		shipToLocation: {
			type: Object,
			default: () => ({})
		},
		markStation: {
			type: Array,
			default: () => ([])
		},
		companyName: {
			type: String,
			default: "Faculty of Medicine Mahasarakham University"
		}

	},
	mounted() {
		this.map = new google.maps.Map(this.$refs.maptripinfo, {
			zoom: 16,
			scrollwheel: false,
			mapTypeControl: false,
			streetViewControl: false,
			disableDoubleClickZoom: true
		});


		const truckMarker = new google.maps.LatLng(this.truckLocation.lat, this.truckLocation.lng);
		const shipToMarker = new google.maps.LatLng(this.shipToLocation.lat, this.shipToLocation.lng);
		const [point1, point2] = this.sortBoundery(truckMarker, shipToMarker);

		const bounds = new google.maps.LatLngBounds(point1, point2);
		bounds.extend(truckMarker);
		bounds.extend(shipToMarker);
		this.map.fitBounds(bounds);

		this.marker = new google.maps.Marker({
			map: this.map,
			position: shipToMarker,
			icon: {
				url: "/assets/images/company-pin.svg",
				size: new google.maps.Size(100, 100),
				scaledSize: new google.maps.Size(100, 100),
				anchor: new google.maps.Point(18.3, 21.3)
			},
			optimized: false
		});

		this.marker = new google.maps.Marker({
			map: this.map,
			position: truckMarker,
			zIndex: 100,

			icon: {
				url: "/assets/images/truck.svg",
				size: new google.maps.Size(100, 100),
				scaledSize: new google.maps.Size(100, 100),
				anchor: new google.maps.Point(18.5, 21.5)
			},
			optimized: false
		});


		const Popup = this.createPopupClass();
		const popup = new Popup(
			shipToMarker,
			this.$refs.popups
		);
		popup.setMap(this.map);
	},
	methods: {
		sortBoundery(point1, point2) {
			if (point1.lng() > point2.lng()) {
				return [
					point2,
					point1
				];
			}
			return [
				point1,
				point2
			];
		},
		createPopupClass() {
			/**
			 * A customized popup on the map.
			 * @param {!google.maps.LatLng} position
			 * @param {!Element} content The bubble div.
			 * @constructor
			 * @extends {google.maps.OverlayView}
			 * ref: https://jsfiddle.net/1p9utnLo/
			 */
			function Popup(position, content) {
				this.position = position;

				content.classList.add("popup-bubble");

				// This zero-height div is positioned at the bottom of the bubble.
				const bubbleAnchor = document.createElement("div");
				bubbleAnchor.classList.add("popup-bubble-anchor");
				bubbleAnchor.appendChild(content);

				// This zero-height div is positioned at the bottom of the tip.
				this.containerDiv = document.createElement("div");
				this.containerDiv.classList.add("popup-container");
				this.containerDiv.appendChild(bubbleAnchor);

				// Optionally stop clicks, etc., from bubbling up to the map.
				google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
			}
			// ES5 magic to extend google.maps.OverlayView.
			Popup.prototype = Object.create(google.maps.OverlayView.prototype);

			/** Called when the popup is added to the map. */
			Popup.prototype.onAdd = function onAdd() {
				this.getPanes().floatPane.appendChild(this.containerDiv);
			};

			/** Called when the popup is removed from the map. */
			Popup.prototype.onRemove = function onRemove() {
				if (this.containerDiv.parentElement) {
					this.containerDiv.parentElement.removeChild(this.containerDiv);
				}
			};

			/** Called each frame when the popup needs to draw itself. */
			Popup.prototype.draw = function draw() {
				const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

				// Hide the popup when it is far out of view.
				const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

				if (display === "block") {
					this.containerDiv.style.left = `${divPosition.x}px`;
					this.containerDiv.style.top = `${divPosition.y}px`;
				}
				if (this.containerDiv.style.display !== display) {
					this.containerDiv.style.display = display;
				}
			};

			return Popup;
		}
	},
	computed: {
		mapLatLng() {
			if (this.lat && this.lng) {
				return {
					lat: this.lat,
					lng: this.lng
				};
			}

			return null;
		}
	}
};
</script>

<style lang="scss" scoped>
.map-container {
	width: 100%;
	height: 100%;

	.ship-to-map {
		width: 100%;
		height: 100%;
	}
}

/* Always set the map height explicitly to define the size of the div
 * element that contains the map. */
/* The popup bubble styling. */


</style>
