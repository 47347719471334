<template>
	<div :class="['tank-container', size]">
		<div class="tank-icon">
			<div class="tank-icon-bar">
				<div class="tank-icon-value" :style="tankValueHeight"></div>
			</div>
			<div class="tank-icon-bg"></div>
			<div class="tank-icon-bg-inner"></div>
		</div>
		<div class="tank-info">
			<div class="tank-info-percentage">
				<FontAwesomeIcon v-if="isLiquidLevelEmptySoonStatus" :icon="['fas', 'exclamation-circle']" />
				<span v-if="percentageText">
					{{ percentageText }}
				</span>
			</div>
			<div class="tank-info-value">
				{{ tankLevel }}<span v-html="unit"></span>
			</div>
		</div>
	</div>
</template>

<script>
import { parseAddCommaWhenIsNumber, parseToTypeNumber } from "../../../selectors/helpers/index";

export default {
	name: "TankIcon",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},

		percentage: {
			type: [Number, String],
			default: 0
		},

		size: {
			type: String,
			default: "small",
			validator: (value) => {
				return [
					"smallest",
					"small",
					"large"
				].includes(value);
			}
		},

		unit: {
			type: String,
			default: ""
		},

		tankColor: {
			type: String,
			default: "#009b44"
		},

		liquidLevelEmptySoonStatus: {
			type: [Number, String],
			default: 0
		}
	},

	computed: {
		tankValueHeight() {
			let height = parseToTypeNumber(this.percentage);
			if (height < 0) {
				height = 0;
			} else if (height > 100) {
				height = 100;
			}
			return {
				height: `${height}%`,
				backgroundColor: `${this.tankColor}`
			};
		},

		tankLevel() {
			return this.value !== null ? `${parseAddCommaWhenIsNumber(this.value)} ` : ``;
		},

		isLiquidLevelEmptySoonStatus() {
			return this.liquidLevelEmptySoonStatus === 1;
		},

		percentageText() {
			return this.percentage !== null ? `${parseAddCommaWhenIsNumber(this.percentage)}%` : ``;
		}
	}
};
</script>

<style lang="scss" scoped>
.tank-container {
	display: flex;
}

.tank-icon {
	position: relative;
	flex: 0 0 rem(30);
	width: rem(30);
	height: rem(51);

	&-bar {
		position: relative;
		top: rem(5);
		left: rem(5);
		width: rem(20);
		height: rem(41);
	}

	&-value {
		position: absolute;
		bottom: 0;
		width: rem(20);
		background-color: $color-emerald;
		z-index: 2;
	}

	&-bg {
		position: absolute;
		top: 0;
		background: url("/assets/images/tank-icon@2x.svg") no-repeat 0 0;
		background-size: rem(30) rem(51);
		width: rem(30);
		height: rem(51);
		z-index: 2;
	}

	&-bg-inner {
		position: absolute;
		top: rem(5);
		left: rem(4);
		background: url("/assets/images/tank-icon-inner@2x.png") no-repeat 0 0;
		background-size: rem(22) rem(42);
		width: rem(22);
		height: rem(42);
	}
}

.tank-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $color-dark-blue-grey;
	font-weight: 600;
	margin-left: rem(4);

	&-percentage {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-family: $font-family-sub;
		font-size: $font-21;
		line-height: $font-21;

		.fa-exclamation-circle {
			font-size: $font-16;
			color: $color-red;
			position: relative;
		}

		span {
			margin-top: rem(4);
		}
	}

	&-value {
		display: flex;
		flex-flow: row wrap;
		gap: rem(4);
		font-size: $font-14;
		line-height: 1;
	}
}

.smallest {
	.tank-icon {
		flex: 0 0 rem(23);
		width: rem(23);
		height: rem(39);

		&-bar {
			top: rem(3);
			left: rem(3);
			width: rem(17);
			height: rem(33);
		}

		&-value {
			width: rem(17);
		}

		&-bg {
			background-size: rem(23) rem(39);
			width: rem(23);
			height: rem(39);
		}

		&-bg-inner {
			position: absolute;
			top: rem(3);
			left: rem(3);
			background: url("/assets/images/tank-icon-inner@2x.png") no-repeat 0 0;
			background-size: rem(17) rem(33);
			width: rem(17);
			height: rem(33);
		}
	}

	.tank-info {
		margin-left: rem(4);

		&-percentage {
			font-size: $font-18;
			line-height: $font-18;

			.fa-exclamation-circle {
				width: rem(10);
				height: rem(10);
			}

			span {
				margin-top: rem(2);
			}
		}

		&-value {
			font-size: $font-12;
		}
	}
}

.large {
	.tank-icon {
		flex: 0 0 rem(52);
		width: rem(52);
		height: rem(88);

		&-bar {
			top: rem(8);
			left: rem(8);
			width: rem(36);
			height: rem(72);
		}

		&-value {
			width: rem(36);
		}

		&-bg {
			background-size: rem(52) rem(88);
			width: rem(52);
			height: rem(88);
		}

		&-bg-inner {
			position: absolute;
			top: rem(5);
			left: rem(5);
			background: url("/assets/images/tank-icon-inner@2x.png") no-repeat 0 0;
			background-size: rem(42) rem(78);
			width: rem(42);
			height: rem(78);
		}
	}

	.tank-info {
		margin-left: rem(8);

		&-percentage {
			font-size: $font-40;
			line-height: $font-40;

			.fa-exclamation-circle {
				font-size: $font-20;
				top: -2px;
			}
		}

		&-value {
			font-size: $font-26;
		}
	}
}
</style>
