<template>
	<BaseBadge v-if="status === STATUS_LABEL.CANCELLED" :text="STATUS_LABEL.CANCELLED" size="small" color="light"/>
	<BaseBadge v-else-if="status === STATUS_LABEL.COMPLETED" :text="STATUS_LABEL.COMPLETED" size="small" color="success"/>
	<BaseBadge v-else-if="status === STATUS_LABEL.IN_TRANSIT" :text="STATUS_LABEL.IN_TRANSIT" size="small" color="success-dark"/>
	<BaseBadge v-else-if="status === STATUS_LABEL.CONFIRMED" :text="STATUS_LABEL.CONFIRMED" size="small" color="secondary"/>
	<BaseBadge v-else-if="status === STATUS_LABEL.DELIVERED" :text="STATUS_LABEL.DELIVERED" size="small" color="warning"/>
	<BaseBadge v-else :text="STATUS_LABEL.NEW" size="small" color="danger"/>
</template>

<script>
import BaseBadge from "@/components/BaseBadge.vue";

import { STATUS_LABEL } from "../../enums/eOrdering/status";

export default {
	name: "EOrderStatus",

	components: {
		BaseBadge
	},

	props: {
		status: {
			validator: (status) => {
				return Object.values(STATUS_LABEL).includes(status);
			}
		}
	},

	data() {
		return {
			STATUS_LABEL
		};
	}
};
</script>