import {
	SIDEBAR_TOGGLE,
	UI_TABLET_ACCOUNT_TOGGLE,
	UI_TABLET_SIDEBAR_TOGGLE,
	UI_TABLET_ACCOUNT_CLOSE,
	UI_TABLET_SIDEBAR_CLOSE
} from "../../types";

import {
	hideBodyScrollbar,
	autoBodyScrollbar
} from "../../../selectors/helpers/dom";

export default {
	namespaced: true,

	state: {
		isSidebarOpen: false,
		isUITabletAccountOpen: false,
		isUITabletSidebarOpen: false
	},

	mutations: {
		[SIDEBAR_TOGGLE](state) {
			state.isSidebarOpen = !state.isSidebarOpen;
		},
		[UI_TABLET_ACCOUNT_TOGGLE](state) {
			const value = !state.isUITabletAccountOpen;
			state.isUITabletAccountOpen = value;

			// if open, than hide scrollbar on the web page.
			if (value) {
				hideBodyScrollbar();
			} else {
				autoBodyScrollbar();
			}
		},
		[UI_TABLET_SIDEBAR_TOGGLE](state) {
			const value = !state.isUITabletSidebarOpen;
			state.isUITabletSidebarOpen = value;

			// if open, than hide scrollbar on the web page.
			if (value) {
				hideBodyScrollbar();
			} else {
				autoBodyScrollbar();
			}
		},
		[UI_TABLET_ACCOUNT_CLOSE](state) {
			state.isUITabletAccountOpen = false;

			autoBodyScrollbar();
		},
		[UI_TABLET_SIDEBAR_CLOSE](state) {
			state.isUITabletSidebarOpen = false;

			autoBodyScrollbar();
		}
	},

	actions: {
		handleToggleSidebar({ commit }) {
			commit(SIDEBAR_TOGGLE);
		},
		toggleUITabletAccount({ commit }) {
			commit(UI_TABLET_ACCOUNT_TOGGLE);
		},

		toggleUITabletSidebar({ commit }) {
			commit(UI_TABLET_SIDEBAR_TOGGLE);
		},

		closeUITabletAccount({ commit }) {
			commit(UI_TABLET_ACCOUNT_CLOSE);
		},

		closeUITabletSidebar({ commit }) {
			commit(UI_TABLET_SIDEBAR_CLOSE);
		}
	}
};
