import { getGenGasHistoryAPI } from "@/services/api/genGas.api";
import { transformMeterHistoryHeader, transformMeterHistoryItems } from "../../../selectors/transform/meterHistory";
import {
	GENGAS_HISTORY_REQUEST,
	GENGAS_HISTORY_SUCCESS,
	GENGAS_HISTORY_FAILURE,
	GENGAS_HISTORY_CLEAR
} from "../../types";


export default {
	namespaced: true,
	state: {
		data: [],
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	getters: {
		getHeaderHistory(state) {
			const result = transformMeterHistoryHeader(state);
			return result;
		},
		getItemsHistory(state) {
			const result = transformMeterHistoryItems(state);
			return result;
		}
	},


	mutations: {
		[GENGAS_HISTORY_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.statusCode = null;
		},

		[GENGAS_HISTORY_SUCCESS](state, response) {
			const { data, pagination } = response.data;
			state.data = data;
			state.pagination = pagination;
			state.lastUpdate = data.updatedAt;
			state.isEmpty = data.length === 0;
			state.isLoading = false;
			state.statusCode = response.status;
		},
		[GENGAS_HISTORY_FAILURE](state, error) {
			state.data = [];
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[GENGAS_HISTORY_CLEAR](state) {
			state.data = [];
			state.loading = false;
			state.isEmpty = false;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		}
	},

	actions: {
		async getGenGasHistory({ commit }, { id, page }) {
			try {
				commit(GENGAS_HISTORY_REQUEST);

				const response = await getGenGasHistoryAPI(id, { page });

				commit(GENGAS_HISTORY_SUCCESS, response);
			} catch (error) {
				commit(GENGAS_HISTORY_FAILURE, error);
			}
		},
		clearGenGasHistory({ commit }) {
			commit(GENGAS_HISTORY_CLEAR);
		}
	}
};
