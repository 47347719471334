import { getTanksGasConsumptionAPI } from "@/services/api/tanks.api";
import {
	TANK_GAS_CONSUMPTION_REQUEST,
	TANK_GAS_CONSUMPTION_SUCCESS,
	TANK_GAS_CONSUMPTION_FAILURE
} from "../../types";
import { transformTankConsumptionDataBarChart } from "../../../selectors/transform/barChart/TankInfo/transformBarChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getTankGasConsumptionData: (state) => (unitValue, options) => {
			return transformTankConsumptionDataBarChart(state, unitValue, options);
		}
	},

	mutations: {
		[TANK_GAS_CONSUMPTION_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[TANK_GAS_CONSUMPTION_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[TANK_GAS_CONSUMPTION_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getTankGasConsumption({ commit }, { id = "", query = {} }) {
			try {
				commit(TANK_GAS_CONSUMPTION_REQUEST);

				const { data = [] } = await getTanksGasConsumptionAPI(id, query);

				commit(TANK_GAS_CONSUMPTION_SUCCESS, data);
			} catch (error) {
				commit(TANK_GAS_CONSUMPTION_FAILURE);
			}
		}
	}
};
