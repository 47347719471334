import { getAirCompEnergySavingAPI } from "@/services/api/airComp.api";

import { AIR_COMP_ENERGY_SAVING_REQUEST, AIR_COMP_ENERGY_SAVING_SUCCESS, AIR_COMP_ENERGY_SAVING_FAILURE } from "../../types";
import { parseAirCompEnergySavings } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: parseAirCompEnergySavings([]),
		lastUpdate: "",
		isLoading: false,
		isEmpty: false,
		tagName: {
			tagNameSaving: "",
			tagNameSavingActual: "",
			tagNameSavingSaving: ""
		}
	},
	mutations: {
		[AIR_COMP_ENERGY_SAVING_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[AIR_COMP_ENERGY_SAVING_SUCCESS](state, response) {
			state.data = parseAirCompEnergySavings(response.data);
			state.tagName = {
				tagNameSaving: state.data[0]?.tagnameSaving || "Energy Saving (MWh/day)",
				tagNameSavingActual: state.data[0]?.tagnameSavingActual || "Actual",
				tagNameSavingSaving: state.data[0]?.tagnameSavingSaving || "Saving"
			};
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[AIR_COMP_ENERGY_SAVING_FAILURE](state) {
			state.data = parseAirCompEnergySavings([]);
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getAirCompEnergySaving({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_ENERGY_SAVING_REQUEST);
				const { data } = await getAirCompEnergySavingAPI(id, params);
				commit(AIR_COMP_ENERGY_SAVING_SUCCESS, data);
			} catch (error) {
				commit(AIR_COMP_ENERGY_SAVING_FAILURE);
			}
		}
	}
};
