const regexUpperCase = /[A-Z]/;
const regexLowerCase = /[a-z]/;
const regexNumberContain = /[0-9]/;
const regexOnlyEnglishCharector = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;

export const isValidEmail = (value) => {
	const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

	if (isValid) {
		return true;
	}
	return false;
};

export const getSpecialCharArray = (value) => {
	const regexpCurrencyOrPunctuation = /\p{Sc}|\p{P}|[`^=><|+~]|[^A-Za-z0-9\s]/gu;
	return value.match(regexpCurrencyOrPunctuation) || [];
};

// aslo supported to `-1, +1`
export const isNumber = (value) => {
	return /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)$/.test(value);
};

export const getHasUpperCase = (value) => {
	return regexUpperCase.test(value);
};

export const getHasLowwerCase = (value) => {
	return regexLowerCase.test(value);
};

export const getHasNumber = (value) => {
	return regexNumberContain.test(value);
};

export const isOnlyEnglishCharector = (value) => {
	return regexOnlyEnglishCharector.test(value);
};