<template>
	<modal
		:name="modalName"
		:scrollable="true"
		width="550"
		height="auto"
		class="custom-modal"
	>
		<BaseModalContent rounded>
			<template v-slot:header>
				<span class="border-top"></span>
				<span class="title">
					<FontAwesomeIcon :icon="['fas', 'exclamation-circle']" class="icon" />
					Confirm the request
				</span>
				<span
					class="close-button"
					@click="onHide"
				>
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container">
					<div class="description">
						<span>
							Would you mind helping me fill in your requirements as in the below box?.
						</span>
					</div>
					<BaseTextArea
						v-model="$v.note.$model"
						:maxLength="255"
						placeholder="Add note (Optional)"
					/>
				</div>
			</template>
			<template v-slot:footer>
				<BaseLoading
					v-if="isLoading"
					class="loading"
				/>
				<div  v-else>
					<BaseButton
						class="btn-cancel"
						type="cancel"
						size="medium"
						rounded
						@click="onHide"
					>
						Cancel
					</BaseButton>
					<span class="padding-btw-button">
						<BaseButton
							width="126px"
							type="secondary"
							size="medium"
							rounded
							@click="handleSendRequest"
						>
							Send request
						</BaseButton>
					</span>
				</div>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import { mapActions } from "vuex";
import BaseModalContent from "@/components/BaseModalContent.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { postRequestServiceAPI } from "../../services/api/bigStoreManagement";
import { TOAST_TYPES } from "../../enums/toast";

export default {
	components: {
		BaseModalContent,
		BaseTextArea,
		BaseLoading
	},

	mixins: [
		validationMixin
	],

	data() {
		return {
			note: "",
			isLoading: false
		};
	},

	validations() {
		return {
			note: {
				maxLength: maxLength(255)
			}
		};
	},

	props: {
		modalName: {
			type: String,
			required: true
		},
		data: {
			type: Object,
			default: () => ({})
		}
	},

	methods: {
		...mapActions({
			showToast: "toast/show"
		}),

		onHide() {
			this.$modal.hide(this.modalName);
		},

		async handleSendRequest() {
			try {
				this.isLoading = true;
				await postRequestServiceAPI({ module: this.data.group, demoShipId: this.data.demoShipId, note: this.note });
				this.$modal.hide(this.modalName);
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Request successfully sent",
						message: "We will contact you as soon as possible."
					}
				});
				this.$emit("success");
			} catch {
				this.isLoading = false;
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: {
						title: "Request failed to sent",
						message: "Please try again."
					}
				});
			} finally {
				this.isLoading = true;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-modal {
	&::v-deep {
		.header {
			padding-top: rem(16);
		}

		.body {
			font-size: $font-20;
			color: $color-dark-blue-grey;
		}

		.v--modal-box {
			border-radius: $border-radius-normal;
			overflow: hidden;

			@include mobile {
				width: 100% !important;
				left: 0 !important;
			}
		}
	}
}

.container {
	.description {
		font-size: $font-20;
		font-family: $font-family-base;
	}
}

.view-button {
	font-size: $font-20;
	margin-right: rem(44);
}

.border-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: rem(8);
	background: $color-azure;
}

.title {
	display: flex;
	align-items: center;
	font-size: $font-20;

	.icon {
		position: relative;
		top: rem(-2);
		width: rem(22);
		height: rem(22);
		margin-right: rem(10);
		color: $color-azure;
	}
}

.padding-btw-button {
	margin-left: 10px;
}

.footer {
	div {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.loading {
	width: 100%;
}
</style>