export const getvalidateMessage = (
	fieldRule = {},
	fieldMessage = {
		existMessage: "",
		requiredMessage: "",
		lengthLimitMessage: "",
		invalidFormatMessage: "",
		invalidMinValueMessage: "",
		duplicateMessage: ""
	}
) => {
	const isExist = ("isExist" in fieldRule) && !fieldRule.isExist;
	const isRequired = ("required" in fieldRule) && !fieldRule.required;
	const isLimitLength = (("maxLength" in fieldRule) ||
		("maxLength" in fieldRule)) &&
		(!fieldRule.maxLength || !fieldRule.minLength);

	const isFormat = ("format" in fieldRule) && !fieldRule.format;
	const isMinValue = ("minValue" in fieldRule) && !fieldRule.minValue;
	const isDuplicate = ("duplicate" in fieldRule) && !fieldRule.duplicate;

	if (isRequired) {
		return fieldMessage.requiredMessage;
	}

	if (isLimitLength) {
		return fieldMessage.lengthLimitMessage;
	}

	if (isFormat) {
		return fieldMessage.invalidFormatMessage;
	}

	if (isExist) {
		return fieldMessage.existMessage;
	}

	if (isMinValue) {
		return fieldMessage.invalidMinValueMessage;
	}

	if (isDuplicate) {
		return fieldMessage.duplicateMessage;
	}

	return "";
};

export const checkIfValid = (field) => {
	if (!field.$dirty) {
		return null;
	}

	if (!("required" in field) && !field.$error) {
		return null;
	}

	return (field.$invalid || field.$error);
};