
import { getMeterGasFlowRate } from "@/services/api/meters.api";
import { transformFlowRateLineChartData } from "../../../selectors/transform/lineChart/transformLineChart";
import {
	METER_FLOWRATE_REQUEST,
	METER_FLOWRATE_SUCCESS,
	METER_FLOWRATE_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getGasFlowRateData: (state) => {
			return transformFlowRateLineChartData(state);
		}
	},

	mutations: {
		[METER_FLOWRATE_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[METER_FLOWRATE_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[METER_FLOWRATE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchGasFlowRate({ commit }, { id = "", query = {} }) {
			try {
				commit(METER_FLOWRATE_REQUEST);

				const { data = [] } = await getMeterGasFlowRate(id, query);

				commit(METER_FLOWRATE_SUCCESS, data);
			} catch (error) {
				commit(METER_FLOWRATE_FAILURE);
			}
		}
	}
};
