<template>
	<BaseLayout pageTitle="User monitoring" :pageSubtitle="dateNow">
		<div class="container-filter">
			<BaseButtonGroup
				:active.sync="filter"
				:items="groupButtons"
				@click="handleChangeDate"
			/>
			<span class="container-filter-range">
				{{ dateRange }}
			</span>
		</div>

		<BaseOverallUser
			:data="userOverall"
			:isLoading="userMonitorOverall.isLoading"
			class="container-base-overall"
		/>

		<div class="row">
			<div class="col-12 col-xl-12">
				<CardBarChart
					:key="getLoginChartShow"
					:chartData="getUsersLoginChart"
					:isLoading="userLogins.isLoading"
					:dateRange="dateRange"
					tooltipMessage="The data is counted from the total number of successful sign-in including new and repeated users"
					chartTitle="Sign in"
				>
					<template #sortTab>
						<BaseTabPanel
							v-model="userLoginTabIndex"
							:items="userLoginsTabs"
							@input="handleChageTab"
						/>
					</template>
				</CardBarChart>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-6">
				<ActivityLog
					:data="activityLog.data"
					:isLoading="activityLog.isLoading"
				/>
			</div>
			<div class="col-12 col-xl-6">
				<MostActiveUser
					:data="userMonitorMostActive.data"
					:isLoading="userMonitorMostActive.isLoading"
					:dateRange="dateRange"
				/>
			</div>
		</div>
		<div class="row user-info-section">
			<div class="col-12 col-xl-6">
				<CardPieChart
					:chartData="getUserTypesPieChartConfig"
					:isLoading="userTypes.isLoading"
					:dateRange="dateRange"
					class="user-type-pie-chart"
					chartTitle="New user type"
				>
				</CardPieChart>
			</div>
			<div class="col-12 col-xl-6">
				<CardPieChart
					:chartData="getUserRolesChartConfig"
					:key="getLoginChartShow"
					:isLoading="userLogins.isLoading"
					:dateRange="dateRange"
					class="user-role-pie-chart"
					chartTitle="New user role"
				>
					<template #sortTab>
						<BaseTabPanel
							v-model="userRolesTabIndex"
							:items="userRolesTabs"
						/>
					</template>
				</CardPieChart>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-6">
				<CardBarChart
					:chartData="getOperationSystemBarChart"
					:isLoading="userUsages.isLoading"
					:isEmpty="userUsages.isEmpty"
					:lastUpdate="userUsages.lastUpdate"
					headerIcon="phone-laptop"
					chartTitle="Top 5 OSs"
				/>
			</div>
			<div class="col-12 col-xl-6">
				<CardBarChart
					:chartData="getBrowserUsagesBarChart"
					:isLoading="userUsages.isLoading"
					:isEmpty="userUsages.isEmpty"
					:lastUpdate="userUsages.lastUpdate"
					headerIcon="browser"
					chartTitle="Top 5 browsers"
				/>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapGetters, mapActions } from "vuex";
import BaseOverallUser from "@/components/BaseOverallUser.vue";
import ActivityLog from "@/components/monitor/user/ActivityLog.vue";
import MostActiveUser from "@/components/monitor/user/MostActiveUser.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import CardPieChart from "@/components/chart/CardPieChart.vue";
import BaseTabPanel from "@/components/BaseTabPanel.vue";
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import { MODULE_SORT_DATE_CHART } from "../../../enums/filterChart";
import { getDateRange } from "../../../selectors/helpers";
import { transformMergeStaffAndCustomerData, transformSignInBarChart } from "../../../selectors/transform/barChart/UserMonitoring/signInBarChart";
import { transformDataStackBarChart } from "../../../selectors/transform/barChart/UserMonitoring/stackBar";
import { transformStaffRolePieChart } from "../../../selectors/transform/pieChart/OverAll/staffRole";
import { transformCustomerRolePieChart } from "../../../selectors/transform/pieChart/OverAll/customerRole";
import { transformUserTypesPieChart } from "../../../selectors/transform/pieChart/UserMonitor/userType";
import { transformOperationSystemBarChart } from "../../../selectors/transform/barChart/OverAll/operationSystem";
import { transformBrowserUsagesBarChart } from "../../../selectors/transform/barChart/OverAll/borwserUsages";

const { perWeek, perMonth, perSixMonth, perYear } = MODULE_SORT_DATE_CHART;

export default {
	name: "MonitorUser",

	components: {
		ActivityLog,
		MostActiveUser,
		BaseOverallUser,
		BaseButtonGroup,
		CardBarChart,
		BaseTabPanel,
		CardPieChart
	},

	data() {
		return {
			userRolesTabs: [
				{
					title: "BIG staff",
					value: "staff"
				},
				{
					title: "Customer",
					value: "customer"
				}
			],
			userRolesTabIndex: 0,
			userLoginsTabs: [
				{
					title: "All",
					value: "all"
				},
				{
					title: "BIG staff",
					value: "staff"
				},
				{
					title: "Customer",
					value: "customer"
				}
			],
			userLoginTabIndex: 0,
			groupButtons: [
				{
					text: "Last 7 days",
					value: perWeek
				},
				{
					text: "Last 1 month",
					value: perMonth
				},
				{
					text: "Last 6 months",
					value: perSixMonth
				},
				{
					text: "Last 1 year",
					value: perYear
				}
			],
			filter: perWeek,
			dateNow: null
		};
	},

	computed: {
		...mapState(["userMonitorOverall", "userMonitorMostActive", "userLogins", "userTypes", "userUsages"]),
		...mapGetters("userMonitorOverall", {
			userOverall: "overall"
		}),
		...mapGetters("userMonitor", {
			activityLog: "activityLog"
		}),
		...mapGetters("userTypes", {
			getTotalStaff: "getTotalStaff",
			getTotalCustomer: "getTotalCustomer"
		}),
		...mapGetters("userLogins", {
			getCustomerLogins: "getCustomerLogins",
			getStaffLogins: "getStaffLogins"
		}),

		...mapGetters("userLogins", {
			getCustomerLogins: "getCustomerLogins",
			getStaffLogins: "getStaffLogins"
		}),

		dateRange() {
			const { start, end } = getDateRange(this.filter);
			const format = "D MMM YYYY";
			return `${dayjs(start).format(format)} - ${dayjs(end).format(format)}`;
		},
		getLoginChartShow() {
			return this.userLoginsTabs[this.userLoginTabIndex].value;
		},
		getUsersLoginChart() {
			const customerBarConfig = {
				color: "#3bccec"
			};
			const staffBarConfig = {
				color: "#009a44"
			};
			const mergeData = transformMergeStaffAndCustomerData(this.getStaffLogins, this.getCustomerLogins);
			const dateRange = this.getStaffLogins.map(({ date }) => date);

			const userLoginsBarChartConfig = transformDataStackBarChart(customerBarConfig, staffBarConfig)({
				firstBarData: this.getCustomerLogins,
				secondBarData: this.getStaffLogins,
				dateRange,
				mergeDataValue: mergeData,
				dateReportFormat: this.filter,
				show: this.getLoginChartShow
			});
			return transformSignInBarChart(userLoginsBarChartConfig, this.getLoginChartShow);
		},
		...mapGetters("userRoles", {
			getStaffRole: "getStaffRole",
			getCustomerRole: "getCustomerRole"
		}),
		...mapGetters("userUsages", {
			getOperationSystemUsages: "getOperationSystemUsages",
			getBrowserUsages: "getBrowserUsages"
		}),
		getUserRolesChartConfig() {
			if (this.userRolesTabs[this.userRolesTabIndex].value === "staff") {
				return transformStaffRolePieChart(this.getStaffRole);
			}
			return transformCustomerRolePieChart(this.getCustomerRole);
		},
		getUserTypesPieChartConfig() {
			return transformUserTypesPieChart({
				totalStaff: this.getTotalStaff,
				totalCustomer: this.getTotalCustomer
			});
		},
		getOperationSystemBarChart() {
			return transformOperationSystemBarChart({
				totalOs: this.getOperationSystemUsages,
				page: "userMonitor"
			});
		},
		getBrowserUsagesBarChart() {
			return transformBrowserUsagesBarChart({
				totalBrowsers: this.getBrowserUsages,
				page: "userMonitor"
			});
		}
	},

	created() {
		this.fetchData();
		this.addActivityUserMonitoring();
		this.setDateNow();
	},

	methods: {
		...mapActions("userMonitorOverall", {
			getUserOverall: "getUserOverall"
		}),
		...mapActions("userUsages", {
			getUserUsages: "getUserUsages"
		}),
		...mapActions("userLogins", {
			getUserLogins: "getUserLogins"
		}),
		...mapActions("userMonitor", {
			getActivityLogs: "getActivityLogs"
		}),
		...mapActions("userMonitorMostActive", {
			getMostActiveUser: "getMostActiveUser"
		}),
		...mapActions("userRoles", {
			getUserRoleWithFilter: "getUserRoleWithFilter"
		}),

		...mapActions("userTypes", {
			getUserTypes: "getUserTypes"
		}),
		...mapActions("activityLogAdd", {
			addActivityUserMonitoring: "addActivityUserMonitoring"
		}),
		...mapActions("userRoles", {
			getUserRole: "getUserRole"
		}),

		...mapActions("userTypes", {
			getUserTypes: "getUserTypes"
		}),

		fetchData() {
			const { filter } = this;
			Promise.all([
				this.getUserOverall({ filter }),
				this.getActivityLogs(),
				this.getMostActiveUser({ filter }),
				this.getUserLogins({ filter }),
				this.getUserRole({ filter }),
				this.getUserTypes({ filter }),
				this.getUserUsages({ filter })
			]);
		},
		handleChangeDate(value) {
			this.filter = value;
			this.userLoginTabIndex = 0;
			this.fetchData();
			this.userLoginTabIndex = 0;
			this.userRolesTabIndex = 0;
			this.setDateNow();
		},

		handleChageTab(i) {
			this.userLoginTabIndex = i;
		},
		// side effect
		setDateNow() {
			this.dateNow = `Last update: ${dayjs().format("D MMM YYYY, HH:mm")}`;
		}
	}
};
</script>

<style lang="scss" scoped>
.user-info-section {
	margin-bottom: rem(35);
}

.container-filter {
	display: flex;
	align-items: center;
	margin-top: rem(32);
	margin-bottom: rem(24);

	&-range {
		margin-left: rem(24);
	}
}

.container-base-overall {
	margin-bottom: rem(40);
}

.user-role-pie-chart {
	height: rem(500);
}

.user-type-pie-chart {
	height: rem(500);
	/* stylelint-disable */
	&::v-deep {
		.chartpie-container {
			margin-top: rem(70)
		}
	}
	/* stylelint-enable */
}

@include desktop() {
	.user-type-pie-chart {
		margin-bottom: rem(36);
	}
}
</style>
