import httpClient from "./httpClient";

const STANDARD_MODULE_END_POINT = "/standard-module";

const getStandardModuleByDevice = (templateId, params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/${templateId}`, { params });

const getStandardModuleListAPI = (params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/templates`, { params });

const postStandardModuleCreateAPI = (params) => httpClient.post(`${STANDARD_MODULE_END_POINT}/templates`, params);

const deleteStandardModuleAPI = (id) => httpClient.delete(`${STANDARD_MODULE_END_POINT}/templates/${id}`);

const getGraphOptions = (params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/indicator-icons`, { params });

const getApiTagNameOptions = (params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/api-tag-names`, { params });

const getApplicationOptions = (params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/application`, { params });

const updateStandardModuleAPI = (templateId, params) => httpClient.put(`${STANDARD_MODULE_END_POINT}/templates/${templateId}`, params);

const getStandardModuleEditAPI = (id) => httpClient.get(`${STANDARD_MODULE_END_POINT}/templates/${id}`);

const checkDuplicateTitleAPI = (params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/check-duplicate-title`, { params });

const historyStandardModuleAPI = (id, params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/${id}/order-histories`, { params });

const getLayoutAddablesStdModule = (params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/addables`, { params });

const getStandardModuleDeviceSyncAPI = (templateId, params) => httpClient.get(`${STANDARD_MODULE_END_POINT}/${templateId}/refresh`, { params });

export {
	getStandardModuleByDevice,
	getStandardModuleListAPI,
	deleteStandardModuleAPI,
	getGraphOptions,
	getApiTagNameOptions,
	getApplicationOptions,
	postStandardModuleCreateAPI,
	checkDuplicateTitleAPI,
	getStandardModuleEditAPI,
	updateStandardModuleAPI,
	historyStandardModuleAPI,
	getLayoutAddablesStdModule,
	getStandardModuleDeviceSyncAPI
};
