<template>
	<BaseLayout v-if="!grafanaManagement.isLoading">
		<BaseBackBtn class="page-back-btn" :to="PAGE_NAME.SHIPTO_STATUS_LIST" label="Back to Ship-to management"/>
		<div class="head-content">
			Grafana/iframe Management
		</div>
		<div class="device-container">
			<div>
				<div class="ship-to-name">{{grafanaManagement.data.companyName || " "}}</div>
				<div class="details">
					<div class="details-header"><span>Ship ID</span>{{grafanaManagement.data.shipId}}<span v-if="isStandardModule" class="std-badge"><BaseBadge text="Standard Module" size="small" color="success-dark"/></span></div>
					<div class="details-header"><span>Product</span>{{grafanaManagement.data.productName}}</div>
					<div class="details-header"><span>Module</span>{{grafanaManagement.data.module}}</div>
				</div>
			</div>
			<div class="wrapper-button">
				<BaseButton
					v-if="isStandardModule"
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleOpenAddNewTab"
				>
					Add new tab
				</BaseButton>
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleOpenAddNewGraph"
				>
					Add grafana/iframe data
				</BaseButton>
			</div>

		</div>
		<div v-if="isStandardModule" class="filter-ddl mt-3">
			<div class="title">Filter</div>
			<BaseDropdown
				:searchable="false"
				v-model="query.tab"
				:list="grafanaFilter"
				:placeholder="`Please Select`"
				inline
				@input="handleChangeQuery"
			/>
		</div>
		<div v-if="grafanaList.length > 0">
			<div v-for="(data,index) in grafanaList" :key="index">
				<div class="body-content">
					<div>{{data.title}}</div>
					<div v-if="data.title !== OVERVIEW_TAB" class="handle-tab">
						<span @click="handleOpenEditTab(data,data.index)"> <PencilIcon /></span>
						<span @click="showModalDeleteTab(data.index)"> <TrashIcon/></span>
					</div>
				</div>
				<div class="preview-title">
					Preview Grafana/iframe Details
				</div>
				<EmptyResult
					v-if="data.data.length === 0"
					icon="chart-column"
					title="Please add the grafana/iframe data"
					description=""
				/>
				<BaseCard class="chart" v-for="(item,key) in data.data" :key="key">
					<template v-slot:header>
						<h4>
							<span class="title-iframe" :title="item.title">{{item.title}}</span>
						</h4>
						<div class="detail-card-header" >
							<span @click="handleOpenEditGraph(item,data.index,key)"> <PencilIcon /></span>
							<span @click="showModalDeleteGraph(data.index,key)"> <TrashIcon/></span>
							<BaseSwitch
								:checked="item.visible"
								@input="handleLabelSwitch(data.index,key)"
							/>
						</div>
					</template>
					<template v-slot:body>
						<div class="iframe-container">
							<div ref="iframe-information" class="iframe" v-html="item.url"></div>
						</div>
					</template>
				</BaseCard>
				<!-- Border line will show when not last Grafana Tab -->
				<div v-if="index+1 !== grafanaList.length" class="border-line"></div>
			</div>
		</div>
		<ModalAddNewGraph
			@submit="handleSubmitAddNewGraph"
		/>
		<ModalEditGraph
			@submit="handleSubmitEditGraph"
		/>
		<ModalEditTab
			@submit="handleSubmitEditTab"
		/>
		<ModalAddNewTab
			@submit="handleSubmitAddNewTab"
		/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_GRAPH"
			title="Delete the graph"
			:message="modalMessageDeleteGrafana"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleModalDeleteThisGraph"
		/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_TAB"
			title="Delete the tab"
			:message="modalMessageDeleteGrafanaTab"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDeleteTab"
			@onConfirm="handleModalDeleteThisTab"
		/>
	</BaseLayout>
	<BaseLoading v-else />
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import EmptyResult from "@/components/grafanaManagement/EmptyResult.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModalAddNewGraph from "@/components/grafanaManagement/modal/ModalAddNewGraph.vue";
import ModalAddNewTab from "@/components/grafanaManagement/modal/ModalAddNewTab.vue";
import ModalEditGraph from "@/components/grafanaManagement/modal/ModalEditGraph.vue";
import ModalEditTab from "@/components/grafanaManagement/modal/ModalEditTab.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import PencilIcon from "@/components/icon/PencilIcon.vue";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { cleanParams, isEmpty } from "../../selectors/helpers";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { postCreateGrafanaNewTabAPI, putUpdateGrafanaTabAPI, postCreateGrafanaByDeviceIdAPI, deleteGrafanaTabAPI } from "../../services/api/grafanaMangement.api";
import { GRAFANA_ACTION, CONFIG } from "../../enums/config";

export default {
	name: "GrafanaManagement",

	components: {
		BaseLayout,
		BaseBadge,
		BaseCard,
		EmptyResult,
		BaseButton,
		BaseBackBtn,
		BaseSwitch,
		ModalAddNewGraph,
		ModalEditGraph,
		ModalEditTab,
		PencilIcon,
		TrashIcon,
		BaseModalConfirmDelete,
		ModalAddNewTab,
		BaseLoading: () => import("@/components/BaseLoading.vue")
	},

	data() {
		return {
			id: Number(this.$route.query.id) || null,
			query: {
				tab: this.$route.query.tab || null
			},
			isdeviceActive: false,
			isStandardModule: false,
			PAGE_NAME,
			MODAL_DELETE_GRAPH: "model-delete-graph",
			MODAL_DELETE_TAB: "model-delete-tab",
			OVERVIEW_TAB: "Overview",
			titleDelete: ""
		};
	},

	async created() {
		if (this.id !== null) {
			await this.getGrafanaByDeviceId({ id: this.id, params: this.query });
			this.isStandardModule = this.grafanaManagement.data.isStandardModule ?? false;
		}
		this.addActivityLogGrafanaManagement({ deviceId: this.id, status: 200 });
		this.autoRefreshIframe = setInterval(() => {
			this.attachIframeListeners();
		}, CONFIG.AUTO_REFRESH_IFRAME);
	},

	computed: {
		...mapState(["grafanaManagement"]),
		...mapGetters("grafanaManagement", {
			getGrafanaData: "getGrafanaData",
			getFilterGrafanaTab: "getFilterGrafanaTab",
			getDropdownListGrafanaTab: "getDropdownListGrafanaTab"
		}),
		modalMessageDeleteGrafana() {
			return `<div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">Are you sure to delete the ${this.titleDelete}? </div>`;
		},
		modalMessageDeleteGrafanaTab() {
			return `<div><b>This action will delete all grafana graphs under this tab.</b></div>
			<div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">Are you sure to delete ${this.titleDelete}? </div>`;
		},

		grafanaFilter() {
			const filterDropdown = [
				{
					label: "All tabs",
					value: null
				}
			];
			const grafanaTab = this.grafanaManagement.data.grafana?.map((value) => {
				return {
					label: value.title,
					value: value.title
				};
			}) || [];

			return [...filterDropdown, ...grafanaTab];
		},

		grafanaList() {
			const grafana = this.grafanaManagement.data.grafana?.map((value, index) => {
				return {
					...value,
					index
				};
			});
			return (this.query.tab === null ? grafana : grafana.filter((val) => val.title === this.query.tab)) || [];
		}
	},

	beforeDestroy() {
		if (this.autoRefreshIframe) {
			clearInterval(this.autoRefreshIframe);
		}
	},

	methods: {
		...mapActions("activityLogAdd", {
			addActivityLogGrafanaManagement: "addActivityLogGrafanaManagement",
			addActivityLogGrafanaManagementAction: "addActivityLogGrafanaManagementAction"
		}),

		...mapActions("grafanaManagement", {
			getGrafanaByDeviceId: "getGrafanaByDeviceId",
			sendGrafanaCreateByDeviceId: "sendGrafanaCreateByDeviceId"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		handleChangeQuery() {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.$route.query,
				...this.query
			})));

			this.$router.push({ query }).catch(() => {});
		},

		handleOpenAddNewGraph() {
			this.$modal.show("modal-add-graph-grafana", { isStandardModule: this.isStandardModule ?? false, listGrafanaTab: this.getDropdownListGrafanaTab });
		},

		handleOpenEditGraph(item, keyTab, key) {
			this.$modal.show("modal-edit-graph-grafana", { item, keyTab, key, isStandardModule: this.isStandardModule ?? false, listGrafanaTab: this.getDropdownListGrafanaTab });
		},

		handleOpenEditTab(item, key) {
			this.$modal.show("modal-edit-tab-grafana", { item, key });
		},

		handleOpenAddNewTab() {
			this.$modal.show("modal-add-new-tab");
		},

		async handleSubmitAddNewTab({ priority, title }) {
			try {
				const { data } = await postCreateGrafanaNewTabAPI(this.id, { priority, title });
				await this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.ADD_GRAFANA_TAB, title: `${this.grafanaManagement.data.shipId} - ${title}`, deviceId: this.id, note: JSON.stringify(data) });
				this.grafanaManagement.data.grafana = [...this.grafanaManagement.data.grafana, data];
				this.grafanaManagement.data.grafana = this.getGrafanaData;
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Added a new tab successfully",
						message: "You can now add grafana/iframe data to this tab."
					}
				});
			} catch (error) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to add new tab", message: "Grafana/iframe mangement unable to add new tab", delay: 6000 }
				});
			}
		},

		async handleSubmitAddNewGraph({ urlIframe, title, keyTab, previewWithEmail, workspaceId, usePreviewEmail }) {
			const isBigth = urlIframe.includes("bigth.com");
			let replaceValue = urlIframe.replace(`width="450"`, `width="100%"`).replace(`monitoring.bigcloudth.com`, `apis.bigcloudth.com`);
			if (isBigth) {
				replaceValue = urlIframe.replace(`width="450"`, `width="100%"`).replace(`monitoring.bigth.com`, `apis.bigth.com`);
			}
			let grafanaTab = this.grafanaManagement.data.grafana[keyTab];
			const tempGrafana = {
				url: replaceValue,
				visible: true,
				title,
				priority: grafanaTab.data?.length <= 0 ? 1 : (grafanaTab.data?.length + 1),
				previewWithEmail,
				workspaceId,
				usePreviewEmail
			};
			grafanaTab = { ...grafanaTab, data: [...grafanaTab.data, tempGrafana] };
			this.grafanaManagement.data.grafana[keyTab] = { ...grafanaTab };
			this.grafanaManagement.data.grafana = this.getGrafanaData;

			await this.updateGrafanaTab(GRAFANA_ACTION.ADD_NEW_GRAPH_IN_GRAFANA_TAB, grafanaTab.id, grafanaTab);
			this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.ADD_NEW_GRAPH_IN_GRAFANA_TAB, title: `${this.grafanaManagement.data.shipId} in tab ${grafanaTab.title}`, deviceId: this.id, note: JSON.stringify(grafanaTab) });
		},

		parseEditDataGrafana(data, urlIframe, priority, title, key, previewWithEmail, workspaceId, usePreviewEmail) {
			const isBigth = urlIframe.includes("bigth.com");
			let replaceValue = urlIframe.replace(`width="450"`, `width="100%"`).replace(`monitoring.bigcloudth.com`, `apis.bigcloudth.com`);
			if (isBigth) {
				replaceValue = urlIframe.replace(`width="450"`, `width="100%"`).replace(`monitoring.bigth.com`, `apis.bigth.com`);
			}
			return data.map((item, index) => {
				if (index === key) {
					return {
						...item,
						url: replaceValue,
						title,
						priority: Number(priority),
						previewWithEmail,
						workspaceId,
						usePreviewEmail
					};
				}
				return item;
			});
		},

		async handleSubmitEditGraph({ urlIframe, priority, title, keyTab, key, indexNewEditGrafanaTab, previewWithEmail, workspaceId, usePreviewEmail }) {
			if (indexNewEditGrafanaTab === keyTab) {
				let grafanaTab = this.grafanaManagement.data.grafana[keyTab];
				grafanaTab = { ...grafanaTab, data: this.parseEditDataGrafana(grafanaTab.data, urlIframe, priority, title, key, previewWithEmail, workspaceId, usePreviewEmail) };
				this.grafanaManagement.data.grafana[keyTab] = { ...grafanaTab };
				this.grafanaManagement.data.grafana = this.getGrafanaData;

				await this.updateGrafanaTab(GRAFANA_ACTION.EDIT_DATA_IN_GRAFANA_TAB, grafanaTab.id, grafanaTab);

				this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.EDIT_DATA_IN_GRAFANA_TAB, title: `${this.grafanaManagement.data.shipId} in tab ${grafanaTab.title}`, deviceId: this.id, note: JSON.stringify(grafanaTab) });
			} else { // This else is Move Grafana Data to Other Tab
				// Retrieve Graph Data From Old Tab to New Tab
				const grafanaDataSource = this.grafanaManagement.data.grafana[keyTab].data[key];
				// Retrieve Tab Data For Move graph to new Tab
				let grafanaTabDesination = this.grafanaManagement.data.grafana[indexNewEditGrafanaTab];
				const isBigth = urlIframe.includes("bigth.com");
				let replaceValue = urlIframe.replace(`width="450"`, `width="100%"`).replace(`monitoring.bigcloudth.com`, `apis.bigcloudth.com`);
				if (isBigth) {
					replaceValue = urlIframe.replace(`width="450"`, `width="100%"`).replace(`monitoring.bigth.com`, `apis.bigth.com`);
				}
				// Create New Object copy Value Graph from Old Tab
				const tempGrafana = {
					url: replaceValue,
					visible: grafanaDataSource.visible,
					title,
					priority,
					previewWithEmail,
					workspaceId,
					usePreviewEmail
				};
				grafanaTabDesination = { ...grafanaTabDesination, data: [...grafanaTabDesination.data, tempGrafana] };
				this.grafanaManagement.data.grafana[indexNewEditGrafanaTab] = { ...grafanaTabDesination };
				await this.updateGrafanaTab(GRAFANA_ACTION.EDIT_DATA_IN_GRAFANA_TAB, grafanaTabDesination.id, grafanaTabDesination);

				// Delete Graph in Old Tab
				this.grafanaManagement.data.grafana[keyTab]?.data?.splice(key, 1);
				const grafanaTabForDelete = this.grafanaManagement.data.grafana[keyTab];
				await this.updateGrafanaTab("", grafanaTabForDelete.id, grafanaTabForDelete);

				this.grafanaManagement.data.grafana = this.getGrafanaData;
				this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.EDIT_DATA_IN_GRAFANA_TAB, title: `${this.grafanaManagement.data.shipId} in tab ${grafanaTabForDelete.title}`, deviceId: this.id, note: JSON.stringify(grafanaTabDesination) });
			}
		},

		sortGrafanaBeforeSendPayload(data) {
			return isEmpty(data) ? [] : data?.map((item, key) => {
				if (item.priority === undefined) return { ...item, priority: key + 1 };
				return { ...item };
			}).sort((a, b) => (a.priority) - b.priority);
		},

		async updateGrafanaTab(action, id, grafanaTab) {
			try {
				const payload = {
					...grafanaTab,
					data: this.sortGrafanaBeforeSendPayload(grafanaTab.data)
				};
				if (this.isStandardModule) {
					await putUpdateGrafanaTabAPI(id, { ...payload });
				} else {
					await postCreateGrafanaByDeviceIdAPI({ id: this.id, payload: { grafana: payload.data || [] } });
				}
				let title = "";
				let message = "";
				// # TODO: in future more GRAFANA_ACTION will add this.
				switch (action) {
					case GRAFANA_ACTION.EDIT_GRAFANA_TAB:
						title = "Updated tab data successfully";
						message = "You can come back to edit the tab name or priority at any time.";
						break;
					case GRAFANA_ACTION.ADD_NEW_GRAPH_IN_GRAFANA_TAB:
						title = "Added a new grafana graph successfully";
						message = "You can edit the box title and priority later.";
						break;
					case GRAFANA_ACTION.EDIT_DATA_IN_GRAFANA_TAB:
						title = "Updated grafana graph successfully";
						message = "You can come back to edit the graph name or priority at any time.";
						break;
					case GRAFANA_ACTION.EDIT_DATA_TOGGLE_STATUS_GRAFANA:
						title = "Updated status of grafana graph successfully";
						message = "";
						break;
					case GRAFANA_ACTION.DELETE_GRAFANA_DATA_IN_GRAFANA:
						title = "Deleted grafana graph successfully";
						message = "";
						break;
					default:
						break;
				}
				if (title !== "" || message !== "") {
					this.showToast({
						type: TOAST_TYPES.RESPONSE_SUCCESS,
						props: {
							title,
							message
						}
					});
				}
			} catch (error) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to update Grafana", message: `Grafana mangement unable to  ${action}`, delay: 6000 }
				});
			}
		},

		// this method for check if edit Name GrafanaTab equal filterGrafana will change queryTab following new title.
		checkFilterSameEditTab(oldTitle, newTitle) {
			if (this.query.tab === oldTitle) {
				this.query.tab = newTitle;
			}
		},

		async handleSubmitEditTab({ id, priority, title, key }) {
			try {
				let grafanaTab = this.grafanaManagement.data.grafana[key];
				// keep old Title grafana Tab before lost oldTitle Data
				const tempOldtitle = grafanaTab.title;
				grafanaTab = { ...grafanaTab, title, priority };
				this.grafanaManagement.data.grafana[key] = grafanaTab;
				this.grafanaManagement.data.grafana = this.getGrafanaData;
				this.checkFilterSameEditTab(tempOldtitle, title);

				await this.updateGrafanaTab(GRAFANA_ACTION.EDIT_GRAFANA_TAB, id, grafanaTab);
				this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.EDIT_GRAFANA_TAB, title: `${this.grafanaManagement.data.shipId} - ${title}`, deviceId: this.id, note: JSON.stringify(grafanaTab) });
				this.handleChangeQuery();
			} catch (error) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to edit tab", message: "Grafana mangement unable to edit tab", delay: 6000 }
				});
			}
		},

		parseEditDataToggleStatusGrafana(data, key) {
			return data.map((item, index) => {
				if (index === key) {
					return {
						...item,
						visible: !item.visible
					};
				}
				return item;
			});
		},

		async handleLabelSwitch(keyTab, key) {
			let grafanaTab = this.grafanaManagement.data.grafana[keyTab];
			grafanaTab = { ...grafanaTab, data: this.parseEditDataToggleStatusGrafana(grafanaTab.data, key) };
			this.grafanaManagement.data.grafana[keyTab] = { ...grafanaTab };
			this.grafanaManagement.data.grafana = this.getGrafanaData;
			const titleGraph = this.grafanaManagement.data.grafana[keyTab]?.title;

			await this.updateGrafanaTab(GRAFANA_ACTION.EDIT_DATA_TOGGLE_STATUS_GRAFANA, grafanaTab.id, grafanaTab);
			this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.EDIT_DATA_TOGGLE_STATUS_GRAFANA, title: `${this.grafanaManagement.data.shipId} in tab ${titleGraph}`, deviceId: this.id, note: JSON.stringify(grafanaTab) });
		},

		showModalDeleteTab(keyTab) {
			this.titleDelete = this.grafanaManagement.data.grafana[keyTab].title;
			this.$modal.show(this.MODAL_DELETE_TAB, { keyTab });
		},

		showModalDeleteGraph(keyTab, key) {
			this.titleDelete = this.grafanaManagement.data.grafana[keyTab]?.data[key]?.title;
			this.$modal.show(this.MODAL_DELETE_GRAPH, { keyTab, key });
		},

		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_GRAPH);
		},

		handleModalCancelDeleteTab() {
			this.$modal.hide(this.MODAL_DELETE_TAB);
		},

		async handleModalDeleteThisGraph({ keyTab, key }) {
			this.grafanaManagement.data.grafana[keyTab]?.data?.splice(key, 1);
			const grafanaTab = this.grafanaManagement.data.grafana[keyTab];
			await this.updateGrafanaTab(GRAFANA_ACTION.DELETE_GRAFANA_DATA_IN_GRAFANA, grafanaTab.id, grafanaTab);
			this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.DELETE_GRAFANA_DATA_IN_GRAFANA, title: `${this.grafanaManagement.data.shipId} in tab ${grafanaTab.title}`, deviceId: this.id, note: JSON.stringify(grafanaTab) });
			this.$modal.hide(this.MODAL_DELETE_GRAPH);
		},

		async handleModalDeleteThisTab({ keyTab }) {
			try {
				const grafanaTabId = this.grafanaManagement.data.grafana[keyTab]?.id;
				const title = this.grafanaManagement.data.grafana[keyTab]?.title;
				this.grafanaManagement.data.grafana?.splice(keyTab, 1);
				await deleteGrafanaTabAPI(grafanaTabId);
				this.addActivityLogGrafanaManagementAction({ action: GRAFANA_ACTION.DELETE_GRAFANA_TAB, title: `${this.grafanaManagement.data.shipId} - ${title}`, deviceId: this.id });
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Deleted tab data successfully",
						message: "All grafana graphs in this tab had also been deleted successfully."
					}
				});
				this.$modal.hide(this.MODAL_DELETE_TAB);
			} catch (error) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to delete Grafana", message: `Grafana mangement unable to delete`, delay: 6000 }
				});
			}
		},

		attachIframeListeners() {
			const iframeContainer = this.$refs["iframe-information"];
			if (iframeContainer) {
				iframeContainer.forEach((el) => {
					const iframe = el.querySelector("iframe");
					if (iframe) {
						const token = iframe.getAttribute("token");
						if (token) {
							if (iframe.contentDocument?.readyState === "complete") {
								this.sendTokenMassage(iframe, token);
							} else {
								this.sendTokenMassage(iframe, token);
							}
						}
					}
				});
			}
		},

		sendTokenMassage(iframe, token) {
			if (iframe.contentWindow) {
				iframe.contentWindow.postMessage(
					{ type: "jwt-token", content: token },
					"*"
				);
				clearInterval(this.autoRefreshIframe);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.head-content {
	font-family: $font-family-sub;
	font-size: $font-48;
	font-weight: $font-weight-regular;
}

.body-content {
	display: flex;
	justify-content: space-between;
	padding: rem(24) 0 rem(16) 0;
	font-family: $font-family-sub;
	font-size: $font-32;
	font-weight: $font-weight-regular;
}

.preview-title {
	padding-bottom: rem(24);
	font-size: $font-32;
	font-weight: $font-weight-regular;
}

.device-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: rem(16) 0;
	font-size: $font-20;
	border-top: 1px solid $color-grey-3;
	border-bottom: 1px solid $color-grey-3;

	.details {
		display: flex;

		//.details-header
		&-header {
			margin-right: rem(40);

			span:first-of-type {
				padding-right: rem(8);
				font-weight: $font-weight-bold;
			}

			.std-badge {
				margin-left: rem(8);
			}
		}
	}

	.wrapper-button {
		display: flex;
		flex-flow: row nowrap;
		gap: rem(16);
	}
}

.ship-to-name {
	font-weight: $font-weight-bold;
}

.iframe-container {
	padding: 0 rem(24);
}

.iframe {
	padding: rem(24);
	border: 1px solid $color-grey-3;
}

.detail-card-header {
	display: flex;
	align-items: center;

	& > * {
		margin-left: rem(24);
	}

	span {
		display: flex;
		color: $color-grey-4;
		cursor: pointer;
	}
}

.title-iframe {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.chart {
	h4 {
		width: 70%;
	}
}

.filter-ddl {
	display: flex;
	justify-content: flex-end;
	width: 100%;

	.title {
		display: flex;
		align-items: center;
		margin-right: rem(16);
	}

	.input-wrapper {
		width: rem(200);
	}
}

.border-line {
	height: 1px;
	width: 100%;
	margin: rem(16) 0 rem(8) 0;
	background-color: $color-silver;
}

.handle-tab {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	gap: rem(16);
	margin-top: rem(8);
	margin-right: rem(8);
	font-size: $font-18;

	span {
		cursor: pointer;
	}
}
</style>
