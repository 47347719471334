<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: isActive.keyword }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				v-model="form.keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Ship-to name, ship-to key or ship id"
				@input="handleKeywordChanged"
				@focus="setFocus('keyword', true)"
				@blur="setFocus('keyword', false)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-product', { active: isActive.productName }]">
			<BaseDropdown
				:searchable="false"
				:list="products"
				:value="form.productName"
				type="ghost"
				placeholder="Product type: All"
				@input="handleProductSelected"
				@active="setFocus('productName', $event)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-module', { active: isActive.moduleName }]">
			<BaseDropdown
				:searchable="false"
				:list="modules"
				:value="form.moduleName"
				type="ghost"
				placeholder="Module: All"
				@input="handleModuleSelected"
				@active="setFocus('productName', $event)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline>Search</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
export default {
	name: "SearchFilterForm",

	props: {
		query: {
			type: Object,
			default: () => ({})
		},
		products: {
			type: Array,
			default: () => []
		},
		modules: {
			type: Array,
			default: () => []
		}
	},

	data() {
		return {
			form: {
				keyword: this.query.keyword || null,
				productName: this.query.productName || null,
				moduleName: this.query.moduleName || null
			},
			isActive: {
				keyword: false,
				productName: false,
				moduleName: false
			}
		};
	},

	methods: {
		handleKeywordChanged(value = null) {
			this.form.keyword = value || null;
		},

		handleProductSelected(value = null) {
			this.form.productName = value;
		},

		handleModuleSelected(value = null) {
			this.form.moduleName = value;
		},

		handleSubmit() {
			this.$emit("submit", this.form);
		},

		clearFilter() {
			this.form.keyword = null;
			this.form.productName = null;
			this.form.moduleName = null;
			this.$emit("submit", this.form);
		},

		setFocus(type, value) {
			this.isActive[type] = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-keyword {
	width: rem(300);
}

.input-group-product {
	width: rem(220);
}

.input-group-module {
	width: rem(150);
}
</style>
