<template>
	<modal :name="modalName" width="496" height="auto" @before-open="beforeOpen">
		<BaseDialogContent
			title="Delete this account?"
			message="You can’t undo this. This user will not be able to access BIG portal."
			text-submit="Yes, delete"
			theme="danger"
			@onCancel="handleCancel"
			@onSubmit="handleSubmit"
		/>
	</modal>
</template>

<script>
import BaseDialogContent from "@/components/BaseDialogContent.vue";

export default {
	name: "ModalUserUpdate",

	components: {
		BaseDialogContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			id: "",
			email: ""
		};
	},

	methods: {
		handleCancel() {
			this.$modal.hide(this.modalName);
			this.$emit("onCancel");
		},

		beforeOpen(event) {
			this.id = event.params.id;
			this.email = event.params.email;
		},

		handleSubmit() {
			this.$modal.hide(this.modalName);
			this.$emit("onSubmit", { id: this.id, email: this.email });
		}
	}
};
</script>

<style lang="scss" scoped>
.footer-link {
	margin-right: $spacing-5;
	color: $color-cerulean;
	font-weight: $font-weight-bold;
	font-size: $font-18;
	cursor: pointer;
}
</style>