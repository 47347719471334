
import { getFurnaceHistoriesAPI } from "@/services/api/furnaces.api";
import { parseFurnaceHistories, parseFurnaceHistoriesTableHeaderlist } from "../../../services/api/transforms/parseModules";
import { FURNACE_HISTORY_REQUEST, FURNACE_HISTORY_SUCCESS, FURNACE_HISTORY_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: parseFurnaceHistories([]),
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		},
		thead: parseFurnaceHistoriesTableHeaderlist([])
	},

	mutations: {
		[FURNACE_HISTORY_REQUEST](state) {
			state.data = parseFurnaceHistories([]);
			state.isLoading = true;
			state.statusCode = null;
			state.isEmpty = false;
		},
		[FURNACE_HISTORY_SUCCESS](state, { data, status }) {
			const response = data;
			state.data = parseFurnaceHistories(response.data);
			state.isEmpty = response.data.length === 0;
			state.isLoading = false;
			state.statusCode = status;
			state.pagination = response.pagination;
			state.thead = parseFurnaceHistoriesTableHeaderlist(response.data);
		},
		[FURNACE_HISTORY_FAILURE](state, error) {
			state.data = parseFurnaceHistories([]);
			state.isLoading = false;
			state.statusCode = error.response.status;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchFurnaceHistories({ commit }, { id, params }) {
			try {
				commit(FURNACE_HISTORY_REQUEST);
				const response = await getFurnaceHistoriesAPI(id, params);
				commit(FURNACE_HISTORY_SUCCESS, response);
			} catch (error) {
				commit(FURNACE_HISTORY_FAILURE, error);
			}
		}
	}
};
