<template>
	<header>
		<h2>
			<div
				:class="['device-selection', { active: isOpenDeviceSelection }]"
				v-click-outside="hideDeviceSelection"
			>
				<a
					v-if="data.type === MODULE_TYPE_PROCESS"
					href=""
					@click.prevent="toggleDeviceOption"
				>
					{{ data.name | capitalize }}
					<small>
						{{ data.deviceCustomerName ? `(${data.deviceCustomerName})`: `(${data.tagNo})` }}
					</small>
					<FontAwesomeIcon
						v-if="!empty"
						:icon="['far', 'chevron-circle-down']"
					/>
				</a>
				<a
					v-else-if="data.type !== MODULE_TYPE_NITRO_FAS"
					href=""
					@click.prevent="toggleDeviceOption"
				>
					{{ data.name | capitalize }}
					<small>
						{{ data.deviceCustomerName ? `(${data.deviceCustomerName})`: `(${data.shipId})` }}
					</small>
					<FontAwesomeIcon
						v-if="!empty"
						:icon="['far', 'chevron-circle-down']"
					/>
				</a>
				<a
					v-else
					href=""
					@click.prevent="toggleDeviceOption"
				>
					{{ data.name }}
					<small>
						{{ data.deviceCustomerName ? `(${data.deviceCustomerName})`: `${data.shipId}` }}
					</small>
					<FontAwesomeIcon
						v-if="!empty"
						:icon="['far', 'chevron-circle-down']"
					/>
				</a>
				<ul
					v-if="!empty"
					class="device-options"
				>
					<li
						v-for="(device, index) in deviceListWithUrlLink"
						:key="`${index}-${device.name}`"
						class="device-option"
					>
						<router-link
							v-if="device.type"
							:to="device.urlLink"
						>
							<span class="device-info-type">
								{{ mappingModuleName(device.type) }}
							</span>
							<div
								v-if="device.type !== MODULE_TYPE_NITRO_FAS"
								class="device-info"
							>
								{{ device.name | capitalize }}
								<span>
									{{ device.deviceCustomerName ? `(${device.deviceCustomerName})`: `${device.shipId}` }}
								</span>
							</div>
							<div v-else class="device-info">
								{{ device.name }}
								<span>
									{{ device.deviceCustomerName ? `(${device.deviceCustomerName})`: `${device.shipId}` }}
								</span>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
		</h2>
		<BaseTag
			v-if="status"
			:class="tagClass"
		>
			{{ status }}
		</BaseTag>
	</header>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { MODULE_TYPES, MODULE_DEVICE_STATUSES } from "../../../enums/modules";

export default {
	name: "ModuleHeader",

	props: {
		data: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			MODULE_DEVICE_STATUSES,
			MODULE_TYPES,
			MODULE_TYPE_NITRO_FAS: "NitroFAS",
			MODULE_TYPE_PROCESS: "Furnace",
			isOpenDeviceSelection: false
		};
	},

	computed: {
		...mapState(["devices"]),
		shipToId() {
			return this.data.shipToId || null;
		},
		status() {
			if (this.data.deliveryStatus) {
				return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].title;
			} else if (this.data.status) {
				return MODULE_DEVICE_STATUSES[this.data.status].title;
			}
			return null;
		},
		tagClass() {
			if (this.data.deliveryStatus) {
				return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].tagClass;
			} else if (this.data.status) {
				return MODULE_DEVICE_STATUSES[this.data.status].tagClass;
			}
			return null;
		},
		deviceList() {
			const isCurrentShipToId = this.devices.isLoadedId === this.shipToId;
			const data = isCurrentShipToId ? this.devices.data : [];
			return data.filter((device) => device.id !== Number(this.$route.params.id));
		},
		empty() {
			return this.deviceList?.length === 0 ?? true;
		},
		deviceListWithUrlLink() {
			return this.deviceList.filter((value) => value.urlLink?.name);
		}
	},

	mounted() {
		this.$emit("update:fetch", this.fetchData);
	},

	methods: {
		...mapActions({
			getDevices: "devices/getDevices"
		}),

		fetchData() {
			const isLoaded = this.devices.isLoadedId === this.shipToId;
			if (!isLoaded) {
				this.getDevices({ id: this.shipToId });
			}
		},
		toggleDeviceOption() {
			if (!this.empty) {
				this.isOpenDeviceSelection = !this.isOpenDeviceSelection;
			} else {
				this.hideDeviceSelection();
			}
		},
		mappingModuleName(moduleName = "") {
			const mappingModuleName = {
				[MODULE_TYPES.O2_AERATION]: "O2 Aeration",
				[MODULE_TYPES.ZLD2]: "ZLD",
				[MODULE_TYPES.GENGAS]: "Gen Gas"
			};

			if (mappingModuleName[moduleName]) {
				return mappingModuleName[moduleName];
			}

			return moduleName;
		},
		hideDeviceSelection() {
			this.isOpenDeviceSelection = false;
		}
	}
};
</script>


<style lang="scss" scoped>
header {
	display: flex;
	align-items: center;
	margin-top: rem(16);

	@include mobile {
		align-items: flex-start;
		flex-direction: column;
	}
}

h2 {
	color: $color-h2;
	font-size: $font-48;
	line-height: 1;

	a {
		color: $color-h2;
		display: flex;
		align-items: center;

		[data-icon="angle-down"] {
			color: $color-arrow-blue;
			font-size: $font-26;
		}

		small {
			color: $color-small;
			font-size: $font-20;
			margin: 0 rem(16) 0 rem(8);
			position: relative;
			top: rem(4);
		}
	}
}

.device-selection {
	position: relative;

	.device-options {
		display: none;
		position: absolute;
		left: 0;
		width: rem(240);
		max-height: rem(320);
		overflow-x: scroll;
		padding: rem(8) 0;
		border-radius: 4px;
		border: 1px solid $color-silver;
		background-color: $color-white;
		z-index: 4;
	}

	.device-option {
		a {
			display: block;
			padding: rem(4) rem(16) rem(8);
			color: $color-dark-blue-grey;
			font-size: $font-24;
			font-weight: 400;

			&:hover {
				background-color: $color-ice-two;
			}

			span {
				font-size: $font-16;
				color: $color-grey-4;
			}
		}
	}

	&.active {
		.device-options {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}

	.fa-chevron-circle-down {
		color: $color-cerulean;
		font-size: rem(16);
		transition: 0.3s transform;
	}
}

.spinner {
	width: rem(14);
	height: rem(14);
	color: $color-grey-3;
	animation: spin 1s linear infinite;
}

.tag {
	@include mobile {
		margin-left: 0;
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
</style>
