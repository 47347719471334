import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";

export const getBarChartWidth = (filter) => {
	const { perWeek, perTwoWeek, perMonth, perYear } = MODULE_SORT_DATE_CHART;
	const defaultBarWidth = 12;
	const barWidth = {
		[perWeek]: 40,
		[perTwoWeek]: 40,
		[perMonth]: 18,
		[perYear]: 40
	};
	return barWidth[filter] || defaultBarWidth;
};