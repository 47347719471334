<template>
	<swiper v-if="showSlider" ref="mySwiper" :options="swiperOptions" class="banner-container" >
		<swiper-slide v-for="(item) in list"  :key="item.partImg">
			<a :href="item.url || '#'"><img :src="item.partImg"></a>
		</swiper-slide>
		<div class="swiper-pagination" slot="pagination"></div>
	</swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { mapState } from "vuex";

export default {
	name: "BaseSwiperBanner",

	components: {
		SwiperSlide,
		Swiper
	},

	directives: {
		swiper: directive
	},

	props: {
		list: {
			type: Array,
			default: () => ([])
		}
	},

	watch: {
		"sidebar.isSidebarOpen": {
			handler() {
				setTimeout(() => {
					this.showSlider = false;
					this.$nextTick(() => {
						this.showSlider = true;
					});
				}, 300);
			}
		}
	},

	data() {
		return {
			showSlider: true,
			swiperOptions: {
				initialSlide: 0,
				pagination: {
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true
				},
				autoplay: {
					delay: 5000,
					disableOnInteraction: false
				}
			}
		};
	},

	computed: {
		...mapState(["sidebar"]),

		swiper() {
			return this.$refs.mySwiper.$swiper;
		}
	}
};
</script>

<style lang="scss" scoped>
.banner-container {
	margin: rem(24) rem(24) rem(40) rem(30);

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: rem(6);
	}
}

.swiper-pagination ::v-deep.swiper-pagination-bullet {
	width: rem(14);
	height: rem(14);
	background-color: $color-white;
	opacity: 1;
}

.swiper-pagination ::v-deep.swiper-pagination-bullet-active {
	background-color: $color-emerald;
}
</style>