import LayoutCustomisation from "../../views/LayoutCustomisation/index.vue";
import LayoutCustomisationList from "../../views/LayoutCustomisation/list.vue";
import GenGasCustomisationCreate from "../../views/LayoutCustomisation/GenGasCustomisation/Create.vue";
import GenGasCustomisationEdit from "../../views/LayoutCustomisation/GenGasCustomisation/Edit.vue";
import ZldCustomisationCreate from "../../views/LayoutCustomisation/ZldCustomisation/Create.vue";
import ZldCustomisationEdit from "../../views/LayoutCustomisation/ZldCustomisation/Edit.vue";
import ElectricityCustomisationCreate from "../../views/LayoutCustomisation/Electricity/Create.vue";
import ElectricityCustomisationEdit from "../../views/LayoutCustomisation/Electricity/Edit.vue";
import SorCustomisationCreate from "../../views/LayoutCustomisation/Solar/Create.vue";
import SorCustomisationEdit from "../../views/LayoutCustomisation/Solar/Edit.vue";
import TankCustomisationCreate from "../../views/LayoutCustomisation/Tank/Create.vue";
import TankCustomisationEdit from "../../views/LayoutCustomisation/Tank/Edit.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

const {
	LAYOUT_CUSTOMISATION,
	LAYOUT_CUSTOMISATION_LIST,
	LAYOUT_CUSTOMISATION_GENGAS_CREATE,
	LAYOUT_CUSTOMISATION_GENGAS_EDIT,
	LAYOUT_CUSTOMISATION_ZLD_CREATE,
	LAYOUT_CUSTOMISATION_ZLD_EDIT,
	LAYOUT_CUSTOMISATION_POWER_CREATE,
	LAYOUT_CUSTOMISATION_POWER_EDIT,
	LAYOUT_CUSTOMISATION_SOR_CREATE,
	LAYOUT_CUSTOMISATION_SOR_EDIT,
	LAYOUT_CUSTOMISATION_TANK_CREATE,
	LAYOUT_CUSTOMISATION_TANK_EDIT
} = PAGE_NAME;
export default {
	path: "/layout-customisation",
	name: LAYOUT_CUSTOMISATION,
	component: LayoutCustomisation,
	children: [
		{
			path: "list",
			name: LAYOUT_CUSTOMISATION_LIST,
			component: LayoutCustomisationList,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "gengas/create",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_GENGAS_CREATE,
			component: GenGasCustomisationCreate,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "gengas/edit/:id",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_GENGAS_EDIT,
			component: GenGasCustomisationEdit,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "zld/create",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_ZLD_CREATE,
			component: ZldCustomisationCreate,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "zld/edit/:id",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_ZLD_EDIT,
			component: ZldCustomisationEdit,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "power/create",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_POWER_CREATE,
			component: ElectricityCustomisationCreate,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "power/edit/:id",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_POWER_EDIT,
			component: ElectricityCustomisationEdit,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "sor/create",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_SOR_CREATE,
			component: SorCustomisationCreate,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "sor/edit/:id",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_SOR_EDIT,
			component: SorCustomisationEdit,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "tank/create",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_TANK_CREATE,
			component: TankCustomisationCreate,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "tank/edit/:id",
			query: {
				create: true
			},
			name: LAYOUT_CUSTOMISATION_TANK_EDIT,
			component: TankCustomisationEdit,
			meta: {
				requireAuth: true
			}
		}
	],
	meta: {
		requireAuth: true
	}
};