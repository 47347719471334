<template>
	<div :class="['container-grid', { line: showLineBottom }]">
		<div v-if="this.$slots['first-column']" class="first-column">
			<slot name="first-column"></slot>
		</div>
		<div v-if="this.$slots['rest-column']" class="rest-column">
			<slot name="rest-column"></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		showLineBottom: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.container-grid {
	width: 100%;
	display: flex;
	justify-content: flex-end;

	&.line {
		border-bottom: 1px solid $color-emerald;
	}

	.first-column {
		width: rem(130);

		> * {
			right: rem(24);

			strong {
				color: $color-dark-blue-grey;
			}
		}
	}

	.rest-column {
		display: flex;
		width: 100%;
		border-radius: rem(8);

		> * {
			flex: 1;
			text-align: center;
			font-size: $font-18;

			strong {
				color: $color-dark-blue-grey;
			}

			svg {
				position: relative;
				top: rem(-1);
				right: rem(4);
				width: rem(11);
				height: rem(11);
			}
		}
	}
}
</style>