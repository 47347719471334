<template>
	<component
		:is="currentTabComponent"
		:viewType="viewType"
		:listViewOptions="listViewOptions"
		@handleSwitchView="handleSwitchView"
	/>
</template>

<script>
import DeviceView from "@/views/Dashboard/DeviceView.vue";
import ListView from "@/views/Dashboard/ListView.vue";
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import { mapState } from "vuex";
import { cleanParams } from "../../selectors/helpers";

export default {
	name: "Dashboard",

	components: {
		BaseButtonGroup
	},

	data() {
		return {
			viewType: this.$route.query.view || "device",
			listViewOptions: [
				{
					value: "device",
					text: "Device View",
					icon: ["far", "grip-horizontal"]
				},
				{
					value: "lists",
					text: "Company View",
					icon: ["far", "list-ul"]
				}
			]
		};
	},
	computed: {
		...mapState(["modules", "viewDevices"]),
		currentTabComponent() {
			if (this.viewType === "lists") {
				return ListView;
			}
			return DeviceView;
		}
	},
	methods: {
		handleSwitchView(value) {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.$route.query,
				view: value,
				page: null
			})));

			this.$router.push({
				...this.$route,
				query
			});
		}
	}

};
</script>

<style lang="scss" scoped>
.page-meta {
	font-family: $font-family-sub;
	color: $color-grey-2;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: rem(24);

	.group-pagination-sort-table {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.top-module-pagination {
			margin: 0 rem(-6) rem(10) 0;
		}
	}

	.sorting-selection {
		position: relative;

		.sorting-value {
			color: $color-cerulean;
			margin-left: rem(4);

			.fa-chevron-circle-down {
				position: relative;
				top: rem(2);
				font-size: $font-16;
				margin-left: rem(8);
			}
		}

		.sorting-options {
			@include fontBase();

			display: none;
			position: absolute;
			right: 0;
			width: 100%;
			padding: rem(8) 0;
			border-radius: 4px;
			border: 1px solid $color-silver;
			background-color: $color-white;
			z-index: 1;

			&.active {
				display: block;
			}
		}

		.sorting-option {
			a {
				display: block;
				padding: 0 rem(8);
				color: $color-dark-blue-grey;
				height: rem(32);
				line-height: rem(32);

				&:hover {
					background-color: $color-ice-two;
				}

				.svg-inline--fa {
					font-size: $font-16;
					color: $color-cerulean;
					margin-right: rem(8);
				}
			}
		}
	}
}

.module-pagination {
	justify-content: flex-end;
	margin-right: rem(-6);
}

.empty-list {
	font-size: $font-20;
	font-weight: $font-weight-bold;
	margin-top: rem(32);
	text-align: center;
}

.wrapper-select-view {
	display: flex;
	align-content: center;
	justify-content: center;
	position: absolute;
	top: rem(40);
	right: rem(30);
}
</style>
