<template>
	<div class="container-item">
		<ColumnBody :item="item"/>
		<ColumnFooter :item="item" :index="index"/>
	</div>
</template>

<script>

import ColumnBody from "@/components/dashboard/modules/NitroFASOrderHistory/ColumnBody.vue";
import ColumnFooter from "@/components/dashboard/modules/NitroFASOrderHistory/ColumnFooter.vue";

export default {
	name: "NitroFASOrderHistoryItem",

	components: {
		ColumnBody,
		ColumnFooter
	},

	props: {
		item: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.container-item {
	border: rem(1) solid $color-silver;
	border-radius: rem(4);
}
</style>