// Modules
export const MODULES_REQUEST = "MODULES_REQUEST";
export const MODULES_SUCCESS = "MODULES_SUCCESS";
export const MODULES_FAILURE = "MODULES_FAILURE";

// view Devices
export const VIEW_DEVICE_REQUEST = "VIEW_DEVICE_REQUEST";
export const VIEW_DEVICE_SUCCESS = "VIEW_DEVICE_SUCCESS";
export const VIEW_DEVICE_FAILURE = "VIEW_DEVICE_FAILURE";
export const UPDATE_DEVICE_PIN_STATUS = "UPDATE_DEVICE_PIN_STATUS";
export const DEVICE_LOAD_SYNC_SUCCESS = "UPDATE_DEVICE_LOAD_SYNC";


// Module devices list
export const DEVICES_REQUEST = "DEVICES_REQUEST";
export const DEVICES_SUCCESS = "DEVICES_SUCCESS";
export const DEVICES_FAILURE = "DEVICES_FAILURE";
export const UPDATE_SOLDTO_PIN_STATUS = "UPDATE_SOLDTO_PIN_STATUS";

// Tank
export const TANK_INFO_REQUEST = "TANK_INFO_REQUEST";
export const AUTO_TANK_INFO_REQUEST = "AUTO_TANK_INFO_REQUEST";
export const TANK_INFO_SUCCESS = "TANK_INFO_SUCCESS";
export const AUTO_TANK_INFO_SUCCESS = "AUTO_TANK_INFO_SUCCESS";
export const TANK_INFO_FAILURE = "TANK_INFO_FAILURE";
// Tank LEVEL
export const TANK_LEVEL_REQUEST = "TANK_LEVEL_REQUEST";
export const TANK_LEVEL_SUCCESS = "TANK_LEVEL_SUCCESS";
export const TANK_LEVEL_FAILURE = "TANK_LEVEL_FAILURE";
// Tank Gas Consumption
export const TANK_GAS_CONSUMPTION_REQUEST = "TANK_GAS_CONSUMPTION_REQUEST";
export const TANK_GAS_CONSUMPTION_SUCCESS = "TANK_GAS_CONSUMPTION_SUCCESS";
export const TANK_GAS_CONSUMPTION_FAILURE = "TANK_GAS_CONSUMPTION_FAILURE";
export const TANK_GAS_CONSUMPTION_RATE_REQUEST = "TANK_GAS_CONSUMPTION_RATE_REQUEST";
export const TANK_GAS_CONSUMPTION_RATE_SUCCESS = "TANK_GAS_CONSUMPTION_RATE_SUCCESS";
export const TANK_GAS_CONSUMPTION_RATE_FAILURE = "TANK_GAS_CONSUMPTION_RATE_FAILURE";
// Tank Monthly
export const TANK_MONTHLY_REQUEST = "TANK_MONTHLY_REQUEST";
export const TANK_MONTHLY_SUCCESS = "TANK_MONTHLY_SUCCESS";
export const TANK_MONTHLY_FAILURE = "TANK_MONTHLY_FAILURE";
// Tank History
export const TANK_HISTORY_REQUEST = "TANK_HISTORY_REQUEST";
export const TANK_HISTORY_SUCCESS = "TANK_HISTORY_SUCCESS";
export const TANK_HISTORY_FAILURE = "TANK_HISTORY_FAILURE";
export const TANK_HISTORY_CLEAR = "TANK_HISTORY_CLEAR";
// Tank Consumption History
export const TANK_CONSUMPTION_HISTORY_REQUEST = "TANK_CONSUMPTION_HISTORY_REQUEST";
export const TANK_CONSUMPTION_HISTORY_SUCCESS = "TANK_CONSUMPTION_HISTORY_SUCCESS";
export const TANK_CONSUMPTION_HISTORY_FAILURE = "TANK_CONSUMPTION_HISTORY_FAILURE";

// Trip Info
export const TRIP_INFO_SUCCESS = "TRIP_INFO_SUCCESS";
export const TRIP_INFO_FAILURE = "TRIP_INFO_FAILURE";
export const TRIP_INFO_REQUEST = "TRIP_INFO_REQUEST";

export const TRIP_INFO_UPDATE_TRUCK_SUCCESS = "TRIP_INFO_UPDATE_TRUCK_SUCCESS";
export const TRIP_INFO_UPDATE_TRUCK_FAILURE = "TRIP_INFO_UPDATE_TRUCK_FAILURE";
export const TRIP_INFO_UPDATE_TRUCK_REQUEST = "TRIP_INFO_UPDATE_TRUCK_REQUEST";

// Meter
export const METER_INFO_REQUEST = "METER_INFO_REQUEST";
export const AUTO_METER_INFO_REQUEST = "AUTO_METER_INFO_REQUEST";
export const METER_INFO_SUCCESS = "METER_INFO_SUCCESS";
export const AUTO_METER_INFO_SUCCESS = "AUTO_METER_INFO_SUCCESS";
export const METER_INFO_FAILURE = "METER_INFO_FAILURE";

// Meter Monthly
export const METER_MONTHLY_REQUEST = "METER_MONTHLY_REQUEST";
export const METER_MONTHLY_SUCCESS = "METER_MONTHLY_SUCCESS";
export const METER_MONTHLY_FAILURE = "METER_MONTHLY_FAILURE";

// Meter Gas Flowrate
export const METER_FLOWRATE_REQUEST = "METER_FLOWRATE_REQUEST";
export const METER_FLOWRATE_SUCCESS = "METER_FLOWRATE_SUCCESS";
export const METER_FLOWRATE_FAILURE = "METER_FLOWRATE_FAILURE";


// Meter order history
export const METER_ORDER_HISTORY_SUCCESS = "METER_ORDER_HISTORY_SUCCESS";
export const METER_ORDER_HISTORY_REQUEST = "METER_ORDER_HISTORY_REQUEST";
export const METER_ORDER_HISTORY_FAILURE = "METER_ORDER_HISTORY_FAILURE";

// Meter consumption approvals

export const METER_CONSUMPTION_APPROVALS_SUCCESS = "METER_CONSUMPTION_APPROVALS_SUCCESS";
export const METER_CONSUMPTION_APPROVALS_REQUEST = "METER_CONSUMPTION_APPROVALS_REQUEST";
export const METER_CONSUMPTION_APPROVALS_FAILURE = "METER_CONSUMPTION_APPROVALS_FAILURE";

export const METER_CONSUMPTION_HANDLE_APPROVE_REQUEST = "METER_CONSUMPTION_HANDLE_APPROVE_REQUEST";
export const METER_CONSUMPTION_HANDLE_APPROVE_SUCCESS = "METER_CONSUMPTION_HANDLE_APPROVE_SUCCESS";
export const METER_CONSUMPTION_HANDLE_APPROVE_FAILURE = "METER_CONSUMPTION_HANDLE_APPROVE_FAILURE";

export const METER_CONSUMPTION_HANDLE_VERIFY_REQUEST = "METER_CONSUMPTION_HANDLE_VERIFY_REQUEST";
export const METER_CONSUMPTION_HANDLE_VERIFY_SUCCESS = "METER_CONSUMPTION_HANDLE_VERIFY_SUCCESS";
export const METER_CONSUMPTION_HANDLE_VERIFY_FAILURE = "METER_CONSUMPTION_HANDLE_VERIFY_FAILURE";

export const METER_CONSUMPTION_NOTFOUND = "METER_CONSUMPTION_NOTFOUND";
// Ship to
export const SHIP_TO_REQUEST = "SHIP_TO_REQUEST";
export const SHIP_TO_SUCCESS = "SHIP_TO_SUCCESS";
export const SHIP_TO_FAILURE = "SHIP_TO_FAILURE";

// Power BI
export const POWER_BI_REQUEST = "POWER_BI_REQUEST";
export const POWER_BI_SUCCESS = "POWER_BI_SUCCESS";
export const POWER_BI_FAILURE = "POWER_BI_FAILURE";
export const POWER_BI_CLEAR = "POWER_BI_CLEAR";

// Zld
export const AUTO_ZLD_INFO_REQUEST = "AUTO_ZLD_INFO_REQUEST";
export const ZLD_INFO_REQUEST = "ZLD_INFO_REQUEST";
export const ZLD_INFO_SUCCESS = "ZLD_INFO_SUCCESS";
export const AUTO_ZLD_INFO_SUCCESS = "AUTO_ZLD_INFO_SUCCESS";
export const ZLD_INFO_FAILURE = "ZLD_INFO_FAILURE";

// Zld last month
export const ZLD_LAST_MONTH_REQUEST = "ZLD_LAST_MONTH_REQUEST";
export const ZLD_LAST_MONTH_SUCCESS = "ZLD_LAST_MONTH_SUCCESS";
export const ZLD_LAST_MONTH_FAILURE = "ZLD_LAST_MONTH_FAILURE";
export const ZLD_LAST_MONTH_CLEAR = "ZLD_LAST_MONTH_CLEAR";

// zld recycle water flow rate
export const ZLD_RECYCLE_WATER_REQUEST = "ZLD_RECYCLE_WATER_REQUEST";
export const ZLD_RECYCLE_WATER_SUCCESS = "ZLD_RECYCLE_WATER_SUCCESS";
export const ZLD_RECYCLE_WATER_FAILURE = "ZLD_RECYCLE_WATER_FAILUR";

// zld outlet recycle water
export const ZLD_OUTLETWATER_REQUEST = "ZLD_OUTLETWATER_REQUEST";
export const ZLD_OUTLETWATER_SUCCESS = "ZLD_OUTLETWATER_SUCCESS";
export const ZLD_OUTLETWATER_FAILURE = "ZLD_OUTLETWATER_FAILURE";

// zld electrical conductivity
export const ZLD_ELECTRICAL_REQUEST = "ZLD_ELECTRICAL_REQUEST";
export const ZLD_ELECTRICAL_SUCCESS = "ZLD_ELECTRICAL_SUCCESS";
export const ZLD_ELECTRICAL_FAILURE = "ZLD_ELECTRICAL_FAILURE";

// Cer
export const CER_INFO_REQUEST = "CER_INFO_REQUEST";
export const AUTO_CER_INFO_REQUEST = "AUTO_CER_INFO_REQUEST";
export const CER_INFO_SUCCESS = "CER_INFO_SUCCESS";
export const AUTO_CER_INFO_SUCCESS = "AUTO_CER_INFO_SUCCESS";
export const CER_INFO_FAILURE = "CER_INFO_FAILURE";
export const CER_INFO_CLEAR = "CER_INFO_CLEAR";

export const CER_WATERFLOW_REQUEST = "CER_WATERFLOW_REQUEST";
export const CER_WATERFLOW_SUCCESS = "CER_WATERFLOW_SUCCESS";
export const CER_WATERFLOW_FAILURE = "CER_WATERFLOW_FAILURE";

export const CER_MONEY_SAVING_REQUEST = "CER_MONEY_SAVING_REQUEST";
export const CER_MONEY_SAVING_SUCCESS = "CER_MONEY_SAVING_SUCCESS";
export const CER_MONEY_SAVING_FAILURE = "CER_MONEY_SAVING_FAILURE";

export const CER_HISTORY_REQUEST = "CER_HISTORY_REQUEST";
export const CER_HISTORY_SUCCESS = "CER_HISTORY_SUCCESS";
export const CER_HISTORY_FAILURE = "CER_HISTORY_FAILURE";
export const CER_HISTORY_CLEAR = "CER_HISTORY_CLEAR";

export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";

export const USER_SET_PROFILE = "USER_SET_PROFILE";
export const USER_ERROR = "USER_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_MODULE_REQUEST = "USER_MODULE_REQUEST";
export const USER_MODULE_SUCCESS = "USER_MODULE_SUCCESS";
export const USER_MODULE_FAILURE = "USER_MODULE_FAILURE";

// User azure list
export const USER_AZURE_LIST_REQUEST = "USER_AZURE_LIST_REQUEST";
export const USER_AZURE_LIST_SUCCESS = "USER_AZURE_LIST_SUCCESS";
export const USER_AZURE_LIST_FAILURE = "USER_AZURE_LIST_FAILURE";
export const USER_AZURE_LIST_CLEAR = "USER_AZURE_LIST_CLEAR";

// Staff insert
export const INSERT_STAFF_REQUEST = "INSERT_STAFF_REQUEST";
export const INSERT_STAFF_SUCCESS = "INSERT_STAFF_SUCCESS";
export const INSERT_STAFF_FAILURE = "INSERT_STAFF_FAILURE";
export const INSERT_STAFF_CLEAR = "INSERT_STAFF_CLEAR";
export const SHOW_ERROR_EMAIL_MESSAGE = "SHOW_ERROR_EMAIL_MESSAGE";

// Staff list
export const STAFF_LIST_REQUEST = "STAFF_LIST_REQUEST";
export const STAFF_LIST_SUCCESS = "STAFF_LIST_SUCCESS";
export const STAFF_LIST_FAILURE = "STAFF_LIST_FAILURE";

// Staff detail
export const STAFF_DETAIL_REQUEST = "STAFF_DETAIL_REQUEST";
export const STAFF_DETAIL_SUCCESS = "STAFF_DETAIL_SUCCESS";
export const STAFF_DETAIL_FAILURE = "STAFF_DETAIL_FAILURE";

// Staff permissions
export const POST_STAFF_PERMISSION_REQUEST = "POST_STAFF_PERMISSION_REQUEST";
export const POST_STAFF_PERMISSION_SUCCESS = "POST_STAFF_PERMISSION_SUCCESS";
export const POST_STAFF_PERMISSION_FAILURE = "POST_STAFF_PERMISSION_FAILURE";

// Toast
export const TOAST_SHOW = "TOAST_SHOW";
export const TOAST_HIDE = "TOAST_HIDE";

// Error
export const ERROR_ADD = "ERROR_ADD";

// UI
export const UI_TABLET_ACCOUNT_TOGGLE = "UI_TABLET_ACCOUNT_TOGGLE";
export const UI_TABLET_SIDEBAR_TOGGLE = "UI_TABLET_SIDEBAR_TOGGLE";
export const UI_TABLET_ACCOUNT_CLOSE = "UI_TABLET_ACCOUNT_CLOSE";
export const UI_TABLET_SIDEBAR_CLOSE = "UI_TABLET_SIDEBAR_CLOSE";

// Customer list
export const CUSTOMER_LIST_REQUEST = "CUSTOMER_LIST_REQUEST";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAILURE = "CUSTOMER_LIST_FAILURE";

export const COMPANY_LIST_REQUEST = "COMPANY_LIST_REQUEST";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_FAILURE = "COMPANY_LIST_FAILURE";

export const CUSTOMER_CREATE_REQUEST = "CUSTOMER_CREATE_REQUEST";
export const CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS";
export const CUSTOMER_CREATE_FAILURE = "CUSTOMER_CREATE_FAILURE";

export const CUSTOMER_DETAIL_REQUEST = "CUSTOMER_DETAIL_REQUEST";
export const CUSTOMER_DETAIL_SUCCESS = "CUSTOMER_DETAIL_SUCCESS";
export const CUSTOMER_DETAIL_FAILURE = "CUSTOMER_DETAIL_FAILURE";
export const CUSTOMER_CLEAR_COMPANY = "CUSTOMER_CLEAR_COMPANY";
export const CUSTOMER_UPDATE_FORM_REQUEST = "CUSTOMER_UPDATE_FORM_REQUEST";
export const CUSTOMER_UPDATE_FORM_SUCCESS = "CUSTOMER_UPDATE_FORM_SUCCESS";
export const CUSTOMER_UPDATE_FORM_FAILURE = "CUSTOMER_UPDATE_FORM_FAILURE";


export const CUSTOMER_UPDATE_PASSWORD_REQUEST = "CUSTOMER_UPDATE_PASSWORD_REQUEST";
export const CUSTOMER_UPDATE_PASSWORD_SUCCESS = "CUSTOMER_UPDATE_PASSWORD_SUCCESS";
export const CUSTOMER_UPDATE_PASSWORD_FAILURE = "CUSTOMER_UPDATE_PASSWORD_FAILURE";

export const CUSTOMER_CREATE_CLEAR = "CUSTOMER_CREATE_CLEAR";
// Customer login
export const CUSTOMER_LOGIN_REQUEST = "CUSTOMER_LOGIN_REQUEST";
export const CUSTOMER_LOGIN_SUCCESS = "CUSTOMER_LOGIN_SUCCESS";
export const CUSTOMER_LOGIN_FAILURE = "CUSTOMER_LOGIN_FAILURE";
export const CUSTOMER_CLEAR_STATE = "CUSTOMER_CLEAR_STATE";
// Customer change password
export const CUSTOMER_CHANGE_PASSWORD_REQUEST = "CUSTOMER_CHANGE_PASSWORD_REQUEST";
export const CUSTOMER_CHANGE_PASSWORD_SUCCESS = "CUSTOMER_CHANGE_PASSWORD_SUCCESS";
export const CUSTOMER_CHANGE_PASSWORD_FAILURE = "CUSTOMER_CHANGE_PASSWORD_FAILURE";
export const CUSTOMER_CHANGE_PASSWORD_CLEAR = "CUSTOMER_CHANGE_PASSWORD_CLEAR";

// customer permission
export const CUSTOMER_GET_PERMISSION_REQUEST = "CUSTOMER_GET_PERMISSION_REQUEST";
export const CUSTOMER_GET_PERMISSION_SUCCESS = "CUSTOMER_GET_PERMISSION_SUCCESS";
export const CUSTOMER_GET_PERMISSION_FAILURE = "CUSTOMER_GET_PERMISSION_FAILURE";

export const CUSTOMER_GET_ROLE_PERMISSION_REQUEST = "CUSTOMER_GET_ROLE_PERMISSION_REQUEST";
export const CUSTOMER_GET_ROLE_PERMISSION_SUCCESS = "CUSTOMER_GET_ROLE_PERMISSION_SUCCESS";
export const CUSTOMER_GET_ROLE_PERMISSION_FAILURE = "CUSTOMER_GET_ROLE_PERMISSION_FAILURE";

export const POST_CUSTOMER_PERMISSION_REQUEST = "POST_CUSTOMER_PERMISSION_REQUEST";
export const POST_CUSTOMER_PERMISSION_SUCCESS = "POST_CUSTOMER_PERMISSION_SUCCESS";
export const POST_CUSTOMER_PERMISSION_FAILURE = "POST_CUSTOMER_PERMISSION_FAILURE";

// Activity Log
export const ACTIVITYLOG_REQUEST = "ACTIVITYLOG_REQUEST";
export const ACTIVITYLOG_SUCCESS = "ACTIVITYLOG_SUCCESS";
export const ACTIVITYLOG_FAILURE = "ACTIVITYLOG_FAILURE";

// Activity Log Add
export const ACTIVITYLOG_ADD_REQUEST = "ACTIVITYLOG_ADD_REQUEST";
export const ACTIVITYLOG_ADD_SUCCESS = "ACTIVITYLOG_ADD_SUCCESS";
export const ACTIVITYLOG_ADD_FAILURE = "ACTIVITYLOG_ADD_FAILURE";


export const DEVICE_NOSIGNAL_REQUEST = "DEVICE_NOSIGNAL_REQUEST";
export const DEVICE_NOSIGNAL_SUCCESS = "DEVICE_NOSIGNAL_SUCCESS";
export const DEVICE_NOSIGNAL_FAILURE = "DEVICE_NOSIGNAL_FAILURE";

export const DEVICE_INACTIVE_REQUEST = "DEVICE_INACTIVE_REQUEST";
export const DEVICE_INACTIVE_SUCCESS = "DEVICE_INACTIVE_SUCCESS";
export const DEVICE_INACTIVE_FAILURE = "DEVICE_INACTIVE_FAILURE";

// overall
export const OVER_ALL_MODULE_DEVICE_REQUEST = "OVER_ALL_MODULE_DEVICE_REQUEST";
export const OVER_ALL_MODULE_DEVICE_SUCCESS = "OVER_ALL_MODULE_DEVICE_SUCCESS";
export const OVER_ALL_MODULE_DEVICE_FAILURE = "OVER_ALL_MODULE_DEVICE_FAILURE";

export const OVER_ALL_PRODUCT_TYPE_REQUEST = "OVER_ALL_PRODUCT_TYPE_REQUEST";
export const OVER_ALL_PRODUCT_TYPE_SUCCESS = "OVER_ALL_PRODUCT_TYPE_SUCCESS";
export const OVER_ALL_PRODUCT_TYPE_FAILURE = "OVER_ALL_PRODUCT_TYPE_FAILURE";

export const OVER_ALL_USER_ROLES_REQUEST = "OVER_ALL_USER_ROLES_REQUEST";
export const OVER_ALL_USER_ROLES_SUCCESS = "OVER_ALL_USER_ROLES_SUCCESS";
export const OVER_ALL_USER_ROLES_FAILURE = "OVER_ALL_USER_ROLES_FAILURE";

export const OVER_ALL_USAGES_REQUEST = "OVER_ALL_USAGES_REQUEST";
export const OVER_ALL_USAGES_SUCCESS = "OVER_ALL_USAGES_SUCCESS";
export const OVER_ALL_USAGES_FAILURE = "OVER_ALL_USAGES_FAILURE";
// No signal configs
export const NO_SIGNAL_CONFIG_GET_REQUEST = "NO_SIGNAL_CONFIG_GET_REQUEST";
export const NO_SIGNAL_CONFIG_GET_SUCCESS = "NO_SIGNAL_CONFIG_GET_SUCCESS";
export const NO_SIGNAL_CONFIG_GET_FAILURE = "NO_SIGNAL_CONFIG_GET_FAILURE";

export const NO_SIGNAL_CONFIG_PUT_REQUEST = "NO_SIGNAL_CONFIG_PUT_REQUEST";
export const NO_SIGNAL_CONFIG_PUT_SUCCESS = "NO_SIGNAL_CONFIG_PUT_SUCCESS";
export const NO_SIGNAL_CONFIG_PUT_FAILURE = "NO_SIGNAL_CONFIG_PUT_FAILURE";

// User Monitor
export const USER_MONITOR_ACTIVITY_REQUEST = "USER_MONITOR_ACTIVITY_REQUEST";
export const USER_MONITOR_ACTIVITY_SUCCESS = "USER_MONITOR_ACTIVITY_SUCCESS";
export const USER_MONITOR_ACTIVITY_FAILURE = "USER_MONITOR_ACTIVITY_FAILURE";

export const USER_MONITOR_OVERALL_REQUEST = "USER_MONITOR_OVERALL_REQUEST";
export const USER_MONITOR_OVERALL_SUCCESS = "USER_MONITOR_OVERALL_SUCCESS";
export const USER_MONITOR_OVERALL_FAILURE = "USER_MONITOR_OVERALL_FAILURE";

export const USER_MONITOR_MOST_ACTIVE_REQUEST = "USER_MONITOR_MOST_ACTIVE_REQUEST";
export const USER_MONITOR_MOST_ACTIVE_SUCCESS = "USER_MONITOR_MOST_ACTIVE_SUCCESS";
export const USER_MONITOR_MOST_ACTIVE_FAILURE = "USER_MONITOR_MOST_ACTIVE_FAILURE";

export const USER_MONITOR_USER_SIGNIN_REQUEST = "USER_MONITOR_USER_SIGNIN_REQUEST";
export const USER_MONITOR_USER_SIGNIN_SUCCESS = "USER_MONITOR_USER_SIGNIN_SUCCESS";
export const USER_MONITOR_USER_SIGNIN_FAILURE = "USER_MONITOR_USER_SIGNIN_FAILURE";

export const USER_MONITOR_USER_TYPE_REQUEST = "USER_MONITOR_USER_TYPE_REQUEST";
export const USER_MONITOR_USER_TYPE_SUCCESS = "USER_MONITOR_USER_TYPE_SUCCESS";
export const USER_MONITOR_USER_TYPE_FAILURE = "USER_MONITOR_USER_TYPE_FAILURE";

export const NITRO_FAS_INFO_REQUEST = "NITRO_FAS_INFO_REQUEST";
export const AUTO_NITRO_FAS_INFO_REQUEST = "AUTO_NITRO_FAS_INFO_REQUEST";
export const NITRO_FAS_INFO_SUCCESS = "NITRO_FAS_INFO_SUCCESS";
export const AUTO_NITRO_FAS_INFO_SUCCESS = "AUTO_NITRO_FAS_INFO_SUCCESS";
export const NITRO_FAS_INFO_FAILURE = "NITRO_FAS_INFO_FAILURE";
export const NITRO_FAS_INFO_CLEAR = "NITRO_FAS_INFO_CLEAR";

export const NITRO_FAS_PRODUCTION_LINE_CHART_REQUEST = "NITRO_FAS_PRODUCTION_LINE_CHART_REQUEST";
export const NITRO_FAS_PRODUCTION_LINE_CHART_SUCCESS = "NITRO_FAS_PRODUCTION_LINE_CHART_SUCCESS";
export const NITRO_FAS_PRODUCTION_LINE_CHART_FAILURE = "NITRO_FAS_PRODUCTION_LINE_CHART_FAILURE";

export const NITRO_FAS_NITROGEN_LINE_CHART_REQUEST = "NITRO_FAS_NITROGEN_LINE_CHART_REQUEST";
export const NITRO_FAS_NITROGEN_LINE_CHART_SUCCESS = "NITRO_FAS_NITROGEN_LINE_CHART_SUCCESS";
export const NITRO_FAS_NITROGEN_LINE_CHART_FAILURE = "NITRO_FAS_NITROGEN_LINE_CHART_FAILURE";

export const NITRO_FAS_ORDER_HISTORY_REQUEST = "NITRO_FAS_ORDER_HISTORY_REQUEST";
export const NITRO_FAS_ORDER_HISTORY_SUCCESS = "NITRO_FAS_ORDER_HISTORY_SUCCESS";
export const NITRO_FAS_ORDER_HISTORY_FAILURE = "NITRO_FAS_ORDER_HISTORY_FAILURE";

export const NITRO_FAS_NITROGEN_CONSUMPTION_REQUEST = "NITRO_FAS_NITROGEN_CONSUMPTION_REQUEST";
export const NITRO_FAS_NITROGEN_CONSUMPTION_SUCCESS = "NITRO_FAS_NITROGEN_CONSUMPTION_SUCCESS";
export const NITRO_FAS_NITROGEN_CONSUMPTION_FAILURE = "NITRO_FAS_NITROGEN_CONSUMPTION_FAILURE";

export const AUTO_O2_AERATION_INFO_REQUEST = "AUTO_O2_AERATION_INFO_REQUEST";
export const O2_AERATION_INFO_REQUEST = "O2_AERATION_INFO_REQUEST";
export const AUTO_O2_AERATION_INFO_SUCCESS = "AUTO_O2_AERATION_INFO_SUCCESS";
export const O2_AERATION_INFO_SUCCESS = "O2_AERATION_INFO_SUCCESS";
export const O2_AERATION_INFO_FAILURE = "O2_AERATION_INFO_FAILURE";
export const O2_AERATION_CLEAR = "O2_AERATION_CLEAR";

export const O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_REQUEST = "O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_REQUEST";
export const O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_SUCCESS = "O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_SUCCESS";
export const O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_FAILURE = "O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_FAILURE";

export const O2_AERATION_OXYGEN_CONSUMPTION_BAR_CHART_REQUEST = "O2_AERATION_OXYGEN_CONSUMPTION_BAR_CHART_REQUEST";
export const O2_AERATION_OXYGEN_CONSUMPTION_BAR_CHART_SUCCESS = "O2_AERATION_OXYGEN_CONSUMPTION_BAR_CHART_SUCCESS";
export const O2_AERATION_OXYGEN_CONSUMPTION_BAR_CHART_FAILURE = "O2_AERATION_OXYGEN_CONSUMPTION_BAR_CHART_FAILURE";

export const O2_AERATION_POWER_CONSUMPTION_LINE_CHART_REQUEST = "O2_AERATION_POWER_CONSUMPTION_LINE_CHART_REQUEST";
export const O2_AERATION_POWER_CONSUMPTION_LINE_CHART_SUCCESS = "O2_AERATION_POWER_CONSUMPTION_LINE_CHART_SUCCESS";
export const O2_AERATION_POWER_CONSUMPTION_LINE_CHART_FAILURE = "O2_AERATION_POWER_CONSUMPTION_LINE_CHART_FAILURE";

export const O2_AERATION_LAST_MONTH_REQUEST = "O2_AERATION_LAST_MONTH_REQUEST";
export const O2_AERATION_LAST_MONTH_SUCCESS = "O2_AERATION_LAST_MONTH_SUCCESS";
export const O2_AERATION_LAST_MONTH_FAILURE = "O2_AERATION_LAST_MONTH_FAILURE";
export const O2_AERATION_LAST_MONTH_CLEAR = "O2_AERATION_LAST_MONTH_CLEAR";

export const O2_AERATION_HISTORY_REQUEST = "O2_AERATION_HISTORY_REQUEST";
export const O2_AERATION_HISTORY_SUCCESS = "O2_AERATION_HISTORY_SUCCESS";
export const O2_AERATION_HISTORY_FAILURE = "O2_AERATION_HISTORY_FAILURE";
export const O2_AERATION_HISTORY_CLEAR = "O2_AERATION_HISTORY_CLEAR";


export const O2_AERATION_OXYGEN_FLOWRATE_LINE_CHART_REQUEST = "O2_AERATION_OXYGEN_FLOWRATE_LINE_CHART_REQUEST";
export const O2_AERATION_OXYGEN_FLOWRATE_LINE_CHART_SUCCESS = "O2_AERATION_OXYGEN_FLOWRATE_LINE_CHART_SUCCESS";
export const O2_AERATION_OXYGEN_FLOWRATE_LINE_CHART_FAILURE = "O2_AERATION_OXYGEN_FLOWRATE_LINE_CHART_FAILURE";

export const ZLD_VINY_THAI_INFO_REQUEST = "ZLD_VINY_THAI_INFO_REQUEST";
export const AUTO_ZLD_VINY_THAI_INFO_REQUEST = "AUTO_ZLD_VINY_THAI_INFO_REQUEST";
export const ZLD_VINY_THAI_INFO_SUCCESS = "ZLD_VINY_THAI_INFO_SUCCESS";
export const AUTO_ZLD_VINY_THAI_INFO_SUCCESS = "AUTO_ZLD_VINY_THAI_INFO_SUCCESS";
export const ZLD_VINY_THAI_INFO_FAILURE = "ZLD_VINY_THAI_INFO_FAILURE";

export const ZLD_WATER_FLOW_RATE_REQUEST = "ZLD_WATER_FLOW_RATE_REQUEST";
export const ZLD_WATER_FLOW_RATE_SUCCESS = "ZLD_WATER_FLOW_RATE_SUCCESS";
export const ZLD_WATER_FLOW_RATE_FAILURE = "ZLD_WATER_FLOW_RATE_FAILURE";

export const ZLD_SILICA_IN_RECYCLE_WATER_REQUEST = "ZLD_SILICA_IN_RECYCLE_WATER_REQUEST";
export const ZLD_SILICA_IN_RECYCLE_WATER_SUCCESS = "ZLD_SILICA_IN_RECYCLE_WATER_SUCCESS";
export const ZLD_SILICA_IN_RECYCLE_WATER_FAILURE = "ZLD_SILICA_IN_RECYCLE_WATER_FAILURE";

export const ZLD_ELECTRICAL_IN_RECYCLE_WATER_REQUEST = "ZLD_ELECTRICAL_IN_RECYCLE_WATER_REQUEST";
export const ZLD_ELECTRICAL_IN_RECYCLE_WATER_SUCCESS = "ZLD_ELECTRICAL_IN_RECYCLE_WATER_SUCCESS";
export const ZLD_ELECTRICAL_IN_RECYCLE_WATER_FAILURE = "ZLD_ELECTRICAL_IN_RECYCLE_WATER_FAILURE";

export const ZLD_ORDER_HISTORY_SUCCESS = "ZLD_ORDER_HISTORY_SUCCESS";
export const ZLD_ORDER_HISTORY_REQUEST = "ZLD_ORDER_HISTORY_REQUEST";
export const ZLD_ORDER_HISTORY_FAILURE = "ZLD_ORDER_HISTORY_FAILURE";
export const ZLD_ORDER_HISTORY_CLEAR = "ZLD_ORDER_HISTORY_CLEAR";

export const ZLD_ELECTRICAL_INLET_WATER_REQUEST = "ZLD_ELECTRICAL_INLET_WATER_REQUEST";
export const ZLD_ELECTRICAL_INLET_WATER_SUCCESS = "ZLD_ELECTRICAL_INLET_WATER_SUCCESS";
export const ZLD_ELECTRICAL_INLET_WATER_FAILURE = "ZLD_ELECTRICAL_INLET_WATER_FAILURE";


export const ZLD_RECYCLE_WATER_PRODUCTION_REQUEST = "ZLD_RECYCLE_WATER_PRODUCTION_REQUEST";
export const ZLD_RECYCLE_WATER_PRODUCTION_SUCCESS = "ZLD_RECYCLE_WATER_PRODUCTION_SUCCESS";
export const ZLD_RECYCLE_WATER_PRODUCTION_FAILURE = "ZLD_RECYCLE_WATER_PRODUCTION_FAILURE";


export const ZLD_VINY_THAI_APPROVALS_SUCCESS = "ZLD_VINY_THAI_APPROVALS_SUCCESS,";
export const ZLD_VINY_THAI_APPROVALS_REQUEST = "ZLD_VINY_THAI_APPROVALS_REQUEST,";
export const ZLD_VINY_THAI_APPROVALS_FAILURE = "ZLD_VINY_THAI_APPROVALS_FAILURE,";
export const ZLD_VINY_THAI_HANDLE_APPROVE_REQUEST = "ZLD_VINY_THAI_HANDLE_APPROVE_REQUEST,";
export const ZLD_VINY_THAI_HANDLE_APPROVE_SUCCESS = "ZLD_VINY_THAI_HANDLE_APPROVE_SUCCESS,";
export const ZLD_VINY_THAI_HANDLE_APPROVE_FAILURE = "ZLD_VINY_THAI_HANDLE_APPROVE_FAILURE,";
export const ZLD_VINY_THAI_HANDLE_VERIFY_REQUEST = "ZLD_VINY_THAI_HANDLE_VERIFY_REQUEST,";
export const ZLD_VINY_THAI_HANDLE_VERIFY_SUCCESS = "ZLD_VINY_THAI_HANDLE_VERIFY_SUCCESS,";
export const ZLD_VINY_THAI_HANDLE_VERIFY_FAILURE = "ZLD_VINY_THAI_HANDLE_VERIFY_FAILURE";

export const AUTO_GENGAS_INFO_REQUEST = "AUTO_GENGAS_INFO_REQUEST";
export const GENGAS_INFO_REQUEST = "GENGAS_INFO_REQUEST";
export const GENGAS_INFO_SUCCESS = "GENGAS_INFO_SUCCESS";
export const AUTO_GENGAS_INFO_SUCCESS = "AUTO_GENGAS_INFO_SUCCESS";
export const GENGAS_INFO_FAILURE = "GENGAS_INFO_FAILURE";
export const GENGAS_INFO_CLEAR = "GENGAS_INFO_CLEAR";

export const POWER_INFO_REQUEST = "POWER_INFO_REQUEST";
export const POWER_INFO_SUCCESS = "POWER_INFO_SUCCESS";
export const AUTO_POWER_INFO_REQUEST = "AUTO_POWER_INFO_REQUEST";
export const AUTO_POWER_INFO_SUCCESS = "AUTO_POWER_INFO_SUCCESS";
export const POWER_INFO_FAILURE = "POWER_INFO_FAILURE";
export const POWER_INFO_CLEAR = "POWER_INFO_CLEAR";

export const POWER_TREND_REQUEST = "POWER_TREND_REQUEST";
export const POWER_TREND_SUCCESS = "POWER_TREND_SUCCESS";
export const POWER_TREND_FAILURE = "POWER_TREND_FAILURE";

export const POWER_PEAK_DEMAND_REQUEST = "POWER_PEAK_DEMAND_REQUEST";
export const POWER_PEAK_DEMAND_SUCCESS = "POWER_PEAK_DEMAND_SUCCESS";
export const POWER_PEAK_DEMAND_FAILURE = "POWER_PEAK_DEMAND_FAILURE";

export const POWER_VOLTAGE_REQUEST = "POWER_VOLTAGE_REQUEST";
export const POWER_VOLTAGE_SUCCESS = "POWER_VOLTAGE_SUCCESS";
export const POWER_VOLTAGE_FAILURE = "POWER_VOLTAGE_FAILURE";

export const POWER_CURRENT_REQUEST = "POWER_CURRENT_REQUEST";
export const POWER_CURRENT_SUCCESS = "POWER_CURRENT_SUCCESS";
export const POWER_CURRENT_FAILURE = "POWER_CURRENT_FAILURE";

export const POWER_HISTORY_REQUEST = "POWER_HISTORY_REQUEST";
export const POWER_HISTORY_SUCCESS = "POWER_HISTORY_SUCCESS";
export const POWER_HISTORY_FAILURE = "POWER_HISTORY_FAILURE";

export const POWER_CONSUMPTION_REQUEST = "POWER_CONSUMPTION_REQUEST";
export const POWER_CONSUMPTION_SUCCESS = "POWER_CONSUMPTION_SUCCESS";
export const POWER_CONSUMPTION_FAILURE = "POWER_CONSUMPTION_FAILURE";

export const POWER_KWH_REQUEST = "POWER_KWH_REQUEST";
export const POWER_KWH_SUCCESS = "POWER_KWH_SUCCESS";
export const POWER_KWH_FAILURE = "POWER_KWH_FAILURE";

export const SOR_INFO_REQUEST = "SOR_INFO_REQUEST";
export const AUTO_SOR_INFO_REQUEST = "AUTO_SOR_INFO_REQUEST";
export const SOR_INFO_SUCCESS = "SOR_INFO_SUCCESS";
export const AUTO_SOR_INFO_SUCCESS = "AUTO_SOR_INFO_SUCCESS";
export const SOR_INFO_FAILURE = "SOR_INFO_FAILURE";
export const SOR_INFO_CLEAR = "SOR_INFO_CLEAR";

export const SOR_TREND_REQUEST = "SOR_TREND_REQUEST";
export const SOR_TREND_SUCCESS = "SOR_TREND_SUCCESS";
export const SOR_TREND_FAILURE = "SOR_TREND_FAILURE";

export const SOR_VOLTAGE_REQUEST = "SOR_VOLTAGE_REQUEST";
export const SOR_VOLTAGE_SUCCESS = "SOR_VOLTAGE_SUCCESS";
export const SOR_VOLTAGE_FAILURE = "SOR_VOLTAGE_FAILURE";

export const SOR_CURRENT_REQUEST = "SOR_CURRENT_REQUEST";
export const SOR_CURRENT_SUCCESS = "SOR_CURRENT_SUCCESS";
export const SOR_CURRENT_FAILURE = "SOR_CURRENT_FAILURE";

export const SOR_GENERATION_REQUEST = "SOR_GENERATION_REQUEST";
export const SOR_GENERATION_SUCCESS = "SOR_GENERATION_SUCCESS";
export const SOR_GENERATION_FAILURE = "SOR_GENERATION_FAILURE";

export const SOR_HISTORY_REQUEST = "SOR_HISTORY_REQUEST";
export const SOR_HISTORY_SUCCESS = "SOR_HISTORY_SUCCESS";
export const SOR_HISTORY_FAILURE = "SOR_HISTORY_FAILURE";

export const SOR_KWH_REQUEST = "SOR_KWH_REQUEST";
export const SOR_KWH_SUCCESS = "SOR_KWH_SUCCESS";
export const SOR_KWH_FAILURE = "SOR_KWH_FAILURE";

export const GENGAS_FLOWRATE_REQUEST = "GENGAS_FLOWRATE_REQUEST";
export const GENGAS_FLOWRATE_SUCCESS = "GENGAS_FLOWRATE_SUCCESS";
export const GENGAS_FLOWRATE_FAILURE = "GENGAS_FLOWRATE_FAILURE";

export const GENGAS_HISTORY_REQUEST = "GENGAS_HISTORY_REQUEST";
export const GENGAS_HISTORY_SUCCESS = "GENGAS_HISTORY_SUCCESS";
export const GENGAS_HISTORY_FAILURE = "GENGAS_HISTORY_FAILURE";
export const GENGAS_HISTORY_CLEAR = "GENGAS_HISTORY_CLEAR";

export const GENGAS_CONSUMPTION_FAILURE = "GENGAS_CONSUMPTION_FAILURE";
export const GENGAS_CONSUMPTION_REQUEST = "GENGAS_CONSUMPTION_REQUEST";
export const GENGAS_CONSUMPTION_SUCCESS = "GENGAS_CONSUMPTION_SUCCESS";


export const NITRO_FAS_DROSS_GENERATION_REQUEST = "NITRO_FAS_DROSS_GENERATION_REQUEST";
export const NITRO_FAS_DROSS_GENERATION_SUCCESS = "NITRO_FAS_DROSS_GENERATION_SUCCESS";
export const NITRO_FAS_DROSS_GENERATION_FAILURE = "NITRO_FAS_DROSS_GENERATION_FAILURE";

export const NITRO_FAS_SOLDER_CONSUMPTION_REQUEST = "NITRO_FAS_SOLDER_CONSUMPTION_REQUEST";
export const NITRO_FAS_SOLDER_CONSUMPTION_SUCCESS = "NITRO_FAS_SOLDER_CONSUMPTION_SUCCESS";
export const NITRO_FAS_SOLDER_CONSUMPTION_FAILURE = "NITRO_FAS_SOLDER_CONSUMPTION_FAILURE";

export const NITRO_FAS_TOTAL_FINISHED_GOOD_REQUEST = "NITRO_FAS_TOTAL_FINISHED_GOOD_REQUEST";
export const NITRO_FAS_TOTAL_FINISHED_GOOD_SUCCESS = "NITRO_FAS_TOTAL_FINISHED_GOOD_SUCCESS";
export const NITRO_FAS_TOTAL_FINISHED_GOOD_FAILURE = "NITRO_FAS_TOTAL_FINISHED_GOOD_FAILURE";

export const NITRO_FAS_DROSS_TOTAL_FINISHED_GOOD_REQUEST = "NITRO_FAS_DROSS_TOTAL_FINISHED_GOOD_REQUEST";
export const NITRO_FAS_DROSS_TOTAL_FINISHED_GOOD_SUCCESS = "NITRO_FAS_DROSS_TOTAL_FINISHED_GOOD_SUCCESS";
export const NITRO_FAS_DROSS_TOTAL_FINISHED_GOOD_FAILURE = "NITRO_FAS_DROSS_TOTAL_FINISHED_GOOD_FAILURE";

export const NITRO_FAS_NITROGEN_TOTAL_FINISHED_GOOD_REQUEST = "NITRO_FAS_NITROGEN_TOTAL_FINISHED_GOOD_REQUEST";
export const NITRO_FAS_NITROGEN_TOTAL_FINISHED_GOOD_SUCCESS = "NITRO_FAS_NITROGEN_TOTAL_FINISHED_GOOD_SUCCESS";
export const NITRO_FAS_NITROGEN_TOTAL_FINISHED_GOOD_FAILURE = "NITRO_FAS_NITROGEN_TOTAL_FINISHED_GOOD_FAILURE";

export const SHIPTO_STATUS_REQUEST = "SHIPTO_STATUS_REQUEST";
export const SHIPTO_STATUS_SUCCESS = "SHIPTO_STATUS_SUCCESS";
export const SHIPTO_STATUS_FAILURE = "SHIPTO_STATUS_FAILURE";

export const SOLDTO_MANAGEMENT_REQUEST = "SOLDTO_MANAGEMENT_REQUEST";
export const SOLDTO_MANAGEMENT_SUCCESS = "SOLDTO_MANAGEMENT_SUCCESS";
export const SOLDTO_MANAGEMENT_FAILURE = "SOLDTO_MANAGEMENT_FAILURE";

export const STANDARD_MODULE_LIST_REQUEST = "STANDARD_MODULE_LIST_REQUEST";
export const STANDARD_MODULE_LIST_SUCCESS = "STANDARD_MODULE_LIST_SUCCESS";
export const STANDARD_MODULE_LIST_FAILURE = "STANDARD_MODULE_LIST_FAILURE";

export const STANDARD_MODULE_PAGE_REQUEST = "STANDARD_MODULE_PAGE_REQUEST";
export const STANDARD_MODULE_PAGE_SUCCESS = "STANDARD_MODULE_PAGE_SUCCESS";
export const STANDARD_MODULE_PAGE_FAILURE = "STANDARD_MODULE_PAGE_FAILURE";

export const STANDARD_MODULE_HISTORY_REQUEST = "STANDARD_MODULE_HISTORY_REQUEST";
export const STANDARD_MODULE_HISTORY_SUCCESS = "STANDARD_MODULE_HISTORY_SUCCESS";
export const STANDARD_MODULE_HISTORY_FAILURE = "STANDARD_MODULE_HISTORY_FAILURE";

export const GRAPH_OPTIONS_REQUEST = "GRAPH_OPTIONS_REQUEST";
export const GRAPH_OPTIONS_SUCCESS = "GRAPH_OPTIONS_SUCCESS";
export const GRAPH_OPTIONS_FAILURE = "GRAPH_OPTIONS_FAILURE";


export const STANDARD_MODULE_CREATE_REQUEST = "STANDARD_MODULE_CREATE_REQUEST";
export const STANDARD_MODULE_CREATE_SUCCESS = "STANDARD_MODULE_CREATE_SUCCESS";
export const STANDARD_MODULE_CREATE_FAILURE = "STANDARD_MODULE_CREATE_FAILURE";

export const STANDARD_MODULE_EDIT_REQUEST = "STANDARD_MODULE_EDIT_REQUEST";
export const STANDARD_MODULE_EDIT_SUCCESS = "STANDARD_MODULE_EDIT_SUCCESS";
export const STANDARD_MODULE_EDIT_FAILURE = "STANDARD_MODULE_EDIT_FAILURE";

export const STANDARD_MODULE_UPDATE_REQUEST = "STANDARD_MODULE_UPDATE_REQUEST";
export const STANDARD_MODULE_UPDATE_SUCCESS = "STANDARD_MODULE_UPDATE_SUCCESS";
export const STANDARD_MODULE_UPDATE_FAILURE = "STANDARD_MODULE_UPDATE_FAILURE";

export const API_TAG_NAME_OPTIONS_REQUEST = "API_TAG_NAME_OPTIONS_REQUEST";
export const API_TAG_NAME_OPTIONS_SUCCESS = "API_TAG_NAME_OPTIONS_SUCCESS";
export const API_TAG_NAME_OPTIONS_FAILURE = "API_TAG_NAME_OPTIONS_FAILURE";

export const APPLICATION_OPTIONS_REQUEST = "APPLICATION_OPTIONS_REQUEST";
export const APPLICATION_OPTIONS_SUCCESS = "APPLICATION_OPTIONS_SUCCESS";
export const APPLICATION_OPTIONS_FAILURE = "APPLICATION_OPTIONS_FAILURE";

export const STANDARD_MODULE_DELETE_REQUEST = "STANDARD_MODULE_DELETE_REQUEST";
export const STANDARD_MODULE_DELETE_SUCCESS = "STANDARD_MODULE_DELETE_SUCCESS";
export const STANDARD_MODULE_DELETE_FAILURE = "STANDARD_MODULE_DELETE_FAILURE";

export const FLOW_DIAGRAM_LIST_REQUEST = "FLOW_DIAGRAM_LIST_REQUEST";
export const FLOW_DIAGRAM_LIST_SUCCESS = "FLOW_DIAGRAM_LIST_SUCCESS";
export const FLOW_DIAGRAM_LIST_FAILURE = "FLOW_DIAGRAM_LIST_FAILURE";

export const FLOW_DIAGRAM_DEVICE_LIST_REQUEST = "FLOW_DIAGRAM_DEVICE_LIST_REQUEST";
export const FLOW_DIAGRAM_DEVICE_LIST_SUCCESS = "FLOW_DIAGRAM_DEVICE_LIST_SUCCESS";
export const FLOW_DIAGRAM_DEVICE_LIST_FAILURE = "FLOW_DIAGRAM_DEVICE_LIST_FAILURE";

export const FLOW_DIAGRAM_SINGLE_REQUEST = "FLOW_DIAGRAM_SINGLE_REQUEST";
export const FLOW_DIAGRAM_SINGLE_SUCCESS = "FLOW_DIAGRAM_SINGLE_SUCCESS";
export const FLOW_DIAGRAM_SINGLE_FAILURE = "FLOW_DIAGRAM_SINGLE_FAILURE";

export const FLOW_DIAGRAM_CREATE_REQUEST = "FLOW_DIAGRAM_CREATE_REQUEST";
export const FLOW_DIAGRAM_CREATE_SUCCESS = "FLOW_DIAGRAM_CREATE_SUCCESS";
export const FLOW_DIAGRAM_CREATE_FAILURE = "FLOW_DIAGRAM_CREATE_FAILURE";

export const FLOW_DIAGRAM_UPDATE_REQUEST = "FLOW_DIAGRAM_UPDATE_REQUEST";
export const FLOW_DIAGRAM_UPDATE_SUCCESS = "FLOW_DIAGRAM_UPDATE_SUCCESS";
export const FLOW_DIAGRAM_UPDATE_FAILURE = "FLOW_DIAGRAM_UPDATE_FAILURE";

export const FLOW_DIAGRAM_DELETE_REQUEST = "FLOW_DIAGRAM_DELETE_REQUEST";
export const FLOW_DIAGRAM_DELETE_SUCCESS = "FLOW_DIAGRAM_DELETE_SUCCESS";
export const FLOW_DIAGRAM_DELETE_FAILURE = "FLOW_DIAGRAM_DELETE_FAILURE";

export const FLOW_DIAGRAM_SHIPTO_REQUEST = "FLOW_DIAGRAM_SHIPTO_REQUEST";
export const FLOW_DIAGRAM_SHIPTO_SUCCESS = "FLOW_DIAGRAM_SHIPTO_SUCCESS";
export const FLOW_DIAGRAM_SHIPTO_FAILURE = "FLOW_DIAGRAM_SHIPTO_FAILURE";

export const NOTI_GROUP_CONFIG_REQUEST = "NOTI_GROUP_CONFIG_REQUEST";
export const NOTI_GROUP_CONFIG_SUCCESS = "NOTI_GROUP_CONFIG_SUCCESS";
export const NOTI_GROUP_CONFIG_FAILURE = "NOTI_GROUP_CONFIG_FAILURE";

// Maintenance
export const MAINTENANCE_STATUS_REQUEST = "MAINTENANCE_STATUS_REQUEST";
export const MAINTENANCE_STATUS_SUCCESS = "MAINTENANCE_STATUS_SUCCESS";
export const MAINTENANCE_STATUS_FAILURE = "MAINTENANCE_STATUS_FAILURE";
export const POST_MAINTENANCE_STATUS_REQUEST = "POST_MAINTENANCE_STATUS_REQUEST";
export const POST_MAINTENANCE_STATUS_SUCCESS = "POST_MAINTENANCE_STATUS_SUCCESS";
export const POST_MAINTENANCE_STATUS_FAILURE = "POST_MAINTENANCE_STATUS_FAILURE";

// Bulk Hydrogens
export const BHY_INFO_REQUEST = "BHY_INFO_REQUEST";
export const AUTO_BHY_INFO_REQUEST = "AUTO_BHY_INFO_REQUEST";
export const BHY_INFO_SUCCESS = "BHY_INFO_SUCCESS";
export const AUTO_BHY_INFO_SUCCESS = "AUTO_BHY_INFO_SUCCESS";
export const BHY_INFO_FAILURE = "BHY_INFO_FAILURE";

export const BHY_LEVEL_REQUEST = "BHY_LEVEL_REQUEST";
export const BHY_LEVEL_SUCCESS = "BHY_LEVEL_SUCCESS";
export const BHY_LEVEL_FAILURE = "BHY_LEVEL_FAILURE";

export const BHY_DELIVERIE_REQUEST = "BHY_DELIVERIE_REQUEST";
export const BHY_DELIVERIE_SUCCESS = "BHY_DELIVERIE_SUCCESS";
export const BHY_DELIVERIE_FAILURE = "BHY_DELIVERIE_FAILURE";

export const BHY_TRIP_INFOS_SUCCESS = "BHY_TRIP_INFOS_SUCCESS";
export const BHY_TRIP_INFOS_FAILURE = "BHY_TRIP_INFOS_FAILURE";
export const BHY_TRIP_INFOS_REQUEST = "BHY_TRIP_INFOS_REQUEST";

export const BHY_TRIP_INFOS_UPDATE_TRUCK_SUCCESS = "BHY_TRIP_INFOS_UPDATE_TRUCK_SUCCESS";
export const BHY_TRIP_INFOS_UPDATE_TRUCK_FAILURE = "BHY_TRIP_INFOS_UPDATE_TRUCK_FAILURE";
export const BHY_TRIP_INFOS_UPDATE_TRUCK_REQUEST = "BHY_TRIP_INFOS_UPDATE_TRUCK_REQUEST";

export const BHY_ORDER_HISTORIES_REQUEST = "BHY_ORDER_HISTORIES_REQUEST";
export const BHY_ORDER_HISTORIES_SUCCESS = "BHY_ORDER_HISTORIES_SUCCESS";
export const BHY_ORDER_HISTORIES_FAILURE = "BHY_ORDER_HISTORIES_FAILURE";
export const BHY_ORDER_HISTORIES_CLEAR = "BHY_ORDER_HISTORIES_CLEAR";

// E-Ordering
export const E_ORDERING_ORDER_LIST_REQUEST = "E_ORDERING_ORDER_LIST_REQUEST";
export const E_ORDERING_ORDER_LIST_SUCCESS = "E_ORDERING_ORDER_LIST_SUCCESS";
export const E_ORDERING_ORDER_LIST_FAILURE = "E_ORDERING_ORDER_LIST_FAILURE";

export const E_ORDERING_HISTORIES_REQUEST = "E_ORDERING_HISTORIES_REQUEST";
export const E_ORDERING_HISTORIES_SUCCESS = "E_ORDERING_HISTORIES_SUCCESS";
export const E_ORDERING_HISTORIES_FAILURE = "E_ORDERING_HISTORIES_FAILURE";
export const E_ORDERING_HISTORIES_TOGGLE = "E_ORDERING_HISTORIES_TOGGLE";

export const E_ORDERING_INFO_REQUEST = "E_ORDERING_INFO_REQUEST";
export const E_ORDERING_INFO_SUCCESS = "E_ORDERING_INFO_SUCCESS";
export const E_ORDERING_INFO_FAILURE = "E_ORDERING_INFO_FAILURE";

export const E_ORDERING_POST_REQUEST = "E_ORDERING_POST_REQUEST";
export const E_ORDERING_POST_SUCCESS = "E_ORDERING_POST_SUCCESS";
export const E_ORDERING_POST_FAILURE = "E_ORDERING_POST_FAILURE";

export const E_ORDERING_PUT_REQUEST = "E_ORDERING_PUT_REQUEST";
export const E_ORDERING_PUT_SUCCESS = "E_ORDERING_PUT_SUCCESS";
export const E_ORDERING_PUT_FAILURE = "E_ORDERING_PUT_FAILURE";

export const E_ORDERING_CONFIGURATION_REQUEST = "E_ORDERING_CONFIGURATION_REQUEST";
export const E_ORDERING_CONFIGURATION_SUCCESS = "E_ORDERING_CONFIGURATION_SUCCESS";
export const E_ORDERING_CONFIGURATION_FAILURE = "E_ORDERING_CONFIGURATION_FAILURE";

export const E_ORDERING_TRIP_INFOS_REQUEST = "E_ORDERING_TRIP_INFOS_REQUEST";
export const E_ORDERING_TRIP_INFOS_SUCCESS = "E_ORDERING_TRIP_INFOS_SUCCESS";
export const E_ORDERING_TRIP_INFOS_FAILURE = "E_ORDERING_TRIP_INFOS_FAILURE";

export const E_ORDERING_TRIP_INFOS_UPDATE_SUCCESS = "E_ORDERING_TRIP_INFOS_UPDATE_SUCCESS";
export const E_ORDERING_TRIP_INFOS_UPDATE_FAILURE = "E_ORDERING_TRIP_INFOS_UPDATE_FAILURE";

export const E_ORDERING_CREATE_SELECTED_DATES_CHANGE = "E_ORDERING_CREATE_SELECTED_DATES_CHANGE";
export const E_ORDERING_CREATE_REMOVE_ORDER_SCHEDULE = "E_ORDERING_CREATE_REMOVE_ORDER_SCHEDULE";
export const E_ORDERING_CREATE_REMOVE_ORDER = "E_ORDERING_CREATE_REMOVE_ORDER";
export const E_ORDERING_CREATE_COUNT_SELECT_DATE = "E_ORDERING_CREATE_COUNT_SELECT_DATE";
export const E_ORDERING_CREATE_RESET_SELECT_DATE = "E_ORDERING_CREATE_RESET_SELECT_DATE";

export const E_ORDERING_PENDING_ORDER_LIST_REQUEST = "E_ORDERING_PENDING_ORDER_LIST_REQUEST";
export const E_ORDERING_PENDING_ORDER_LIST_SUCCESS = "E_ORDERING_PENDING_ORDER_LIST_SUCCESS";
export const E_ORDERING_PENDING_ORDER_LIST_FAILURE = "E_ORDERING_PENDING_ORDER_LIST_FAILURE";

export const E_ORDERING_CHECK_FAILED_ORDER_REQUEST = "E_ORDERING_CHECK_FAILED_ORDER_REQUEST";
export const E_ORDERING_CHECK_FAILED_ORDER_SUCCESS = "E_ORDERING_CHECK_FAILED_ORDER_SUCCESS";
export const E_ORDERING_CHECK_FAILED_ORDER_FAILURE = "E_ORDERING_CHECK_FAILED_ORDER_FAILURE";

export const FURNACE_INFO_REQUEST = "FURNACE_INFO_REQUEST";
export const AUTO_FURNACE_INFO_REQUEST = "AUTO_FURNACE_INFO_REQUEST";
export const FURNACE_INFO_SUCCESS = "FURNACE_INFO_SUCCESS";
export const AUTO_FURNACE_INFO_SUCCESS = "AUTO_FURNACE_INFO_SUCCESS";
export const FURNACE_INFO_FAILURE = "FURNACE_INFO_FAILURE";
export const FURNACE_INFO_CLEAR = "FURNACE_INFO_CLEAR";

export const FURNACE_TEMPERATURE_CHART_REQUEST = "FURNACE_TEMPERATURE_CHART_REQUEST";
export const FURNACE_TEMPERATURE_CHART_SUCCESS = "FURNACE_TEMPERATURE_CHART_SUCCESS";
export const FURNACE_TEMPERATURE_CHART_FAILURE = "FURNACE_TEMPERATURE_CHART_FAILURE";
export const FURNACE_TEMPERATURE_CHART_LIST_REQUEST = "FURNACE_TEMPERATURE_CHART_LIST_REQUEST";
export const FURNACE_TEMPERATURE_CHART_LIST_SUCCESS = "FURNACE_TEMPERATURE_CHART_LIST_SUCCESS";
export const FURNACE_TEMPERATURE_CHART_LIST_FAILURE = "FURNACE_TEMPERATURE_CHART_LIST_FAILURE";

export const FURNACE_HISTORY_REQUEST = "FURNACE_HISTORY_REQUEST";
export const FURNACE_HISTORY_SUCCESS = "FURNACE_HISTORY_SUCCESS";
export const FURNACE_HISTORY_FAILURE = "FURNACE_HISTORY_FAILURE";

export const FURNACE_CONSUMPTION_REQUEST = "FURNACE_CONSUMPTION_REQUEST";
export const FURNACE_CONSUMPTION_SUCCESS = "FURNACE_CONSUMPTION_SUCCESS";
export const FURNACE_CONSUMPTION_FAILURE = "FURNACE_CONSUMPTION_FAILURE";

export const FURNACE_PRESSURE_REQUEST = "FURNACE_PRESSURE_REQUEST";
export const FURNACE_PRESSURE_SUCCESS = "FURNACE_PRESSURE_SUCCESS";
export const FURNACE_PRESSURE_FAILURE = "FURNACE_PRESSURE_FAILURE";

export const FURNACE_NATURAL_GAS_REQUEST = "FURNACE_NATURAL_GAS_REQUEST";
export const FURNACE_NATURAL_GAS_SUCCESS = "FURNACE_NATURAL_GAS_SUCCESS";
export const FURNACE_NATURAL_GAS_FAILURE = "FURNACE_NATURAL_GAS_FAILURE";

export const FURNACE_GLASS_LEVEL_REQUEST = "FURNACE_GLASS_LEVEL_REQUEST";
export const FURNACE_GLASS_LEVEL_SUCCESS = "FURNACE_GLASS_LEVEL_SUCCESS";
export const FURNACE_GLASS_LEVEL_FAILURE = "FURNACE_GLASS_LEVEL_FAILURE";

export const FURNACE_REPORT_REQUEST = "FURNACE_REPORT_REQUEST";
export const FURNACE_REPORT_SUCCESS = "FURNACE_REPORT_SUCCESS";
export const FURNACE_REPORT_FAILURE = "FURNACE_REPORT_FAILURE";


export const AUTO_AIR_COMP_INFO_REQUEST = "AUTO_AIR_COMP_INFO_REQUEST";
export const AIR_COMP_INFO_REQUEST = "AIR_COMP_INFO_REQUEST";
export const AUTO_AIR_COMP_INFO_SUCCESS = "AUTO_AIR_COMP_INFO_SUCCESS";
export const AIR_COMP_INFO_SUCCESS = "AIR_COMP_INFO_SUCCESS";
export const AIR_COMP_INFO_FAILURE = "AIR_COMP_INFO_FAILURE";
export const AIR_COMP_INFO_CLEAR = "AIR_COMP_INFO_CLEAR";

export const AIR_COMP_PRESSURE_REQUEST = "AIR_COMP_PRESSURE_REQUEST";
export const AIR_COMP_PRESSURE_SUCCESS = "AIR_COMP_PRESSURE_SUCCESS";
export const AIR_COMP_PRESSURE_FAILURE = "AIR_COMP_PRESSURE_FAILURE";

export const AIR_COMP_SEC_REQUEST = "AIR_COMP_SEC_REQUEST";
export const AIR_COMP_SEC_SUCCESS = "AIR_COMP_SEC_SUCCESS";
export const AIR_COMP_SEC_FAILURE = "AIR_COMP_SEC_FAILURE";

export const AIR_COMP_POWER_REQUEST = "AIR_COMP_POWER_REQUEST";
export const AIR_COMP_POWER_SUCCESS = "AIR_COMP_POWER_SUCCESS";
export const AIR_COMP_POWER_FAILURE = "AIR_COMP_POWER_FAILURE";

export const AIR_COMP_FLOW_RATE_REQUEST = "AIR_COMP_FLOW_RATE_REQUEST";
export const AIR_COMP_FLOW_RATE_SUCCESS = "AIR_COMP_FLOW_RATE_SUCCESS";
export const AIR_COMP_FLOW_RATE_FAILURE = "AIR_COMP_FLOW_RATE_FAILURE";

export const AIR_COMP_POWER_COST_REQUEST = "AIR_COMP_POWER_COST_REQUEST";
export const AIR_COMP_POWER_COST_SUCCESS = "AIR_COMP_POWER_COST_SUCCESS";
export const AIR_COMP_POWER_COST_FAILURE = "AIR_COMP_POWER_COST_FAILURE";

export const AIR_COMP_OVERALL_ENERGY_REQUEST = "AIR_COMP_OVERALL_ENERGY_REQUEST";
export const AIR_COMP_OVERALL_ENERGY_SUCCESS = "AIR_COMP_OVERALL_ENERGY_SUCCESS";
export const AIR_COMP_OVERALL_ENERGY_FAILURE = "AIR_COMP_OVERALL_ENERGY_FAILURE";

export const AIR_COMP_OVERALL_AIR_VOLUME_REQUEST = "AIR_COMP_OVERALL_AIR_VOLUME_REQUEST";
export const AIR_COMP_OVERALL_AIR_VOLUME_SUCCESS = "AIR_COMP_OVERALL_AIR_VOLUME_SUCCESS";
export const AIR_COMP_OVERALL_AIR_VOLUME_FAILURE = "AIR_COMP_OVERALL_AIR_VOLUME_FAILURE";

export const AIR_COMP_ENERGY_SAVING_REQUEST = "AIR_COMP_ENERGY_SAVING_REQUEST";
export const AIR_COMP_ENERGY_SAVING_SUCCESS = "AIR_COMP_ENERGY_SAVING_SUCCESS";
export const AIR_COMP_ENERGY_SAVING_FAILURE = "AIR_COMP_ENERGY_SAVING_FAILURE";

export const AIR_COMP_HISTORY_REQUEST = "AIR_COMP_HISTORY_REQUEST";
export const AIR_COMP_HISTORY_SUCCESS = "AIR_COMP_HISTORY_SUCCESS";
export const AIR_COMP_HISTORY_FAILURE = "AIR_COMP_HISTORY_FAILURE";


export const GRAFANA_MANAGEMENT_REQUEST = "GRAFANA_MANAGEMENT_REQUEST";
export const GRAFANA_MANAGEMENT_SUCCESS = "GRAFANA_MANAGEMENT_SUCCESS";
export const GRAFANA_MANAGEMENT_FAILURE = "GRAFANA_MANAGEMENT_FAILURE";

export const GRAFANA_MANAGEMENT_SAVE_REQUEST = "GRAFANA_MANAGEMENT_SAVE_REQUEST";
export const GRAFANA_MANAGEMENT_SAVE_SUCCESS = "GRAFANA_MANAGEMENT_SAVE_SUCCESS";
export const GRAFANA_MANAGEMENT_SAVE_FAILURE = "GRAFANA_MANAGEMENT_SAVE_FAILURE";

export const GRAFANA_MANAGEMENT_SAVE_TAB_REQUEST = "GRAFANA_MANAGEMENT_SAVE_TAB_REQUEST";
export const GRAFANA_MANAGEMENT_SAVE_TAB_SUCCESS = "GRAFANA_MANAGEMENT_SAVE_TAB_SUCCESS";
export const GRAFANA_MANAGEMENT_SAVE_TAB_FAILURE = "GRAFANA_MANAGEMENT_SAVE_TAB_FAILURE";

// big store management
export const BIG_STORE_REQUEST = "BIG_STORE_REQUEST";
export const BIG_STORE_SUCCESS = "BIG_STORE_SUCCESS";
export const BIG_STORE_FAILURE = "BIG_STORE_FAILURE";

export const BIG_STORE_MODULE_DETAIL_REQUEST = "BIG_STORE_MODULE_DETAIL_REQUEST";
export const BIG_STORE_MODULE_DETAIL_SUCCESS = "BIG_STORE_MODULE_DETAIL_SUCCESS";
export const BIG_STORE_MODULE_DETAIL_FAILURE = "BIG_STORE_MODULE_DETAIL_FAILURE";

export const BIG_STORE_GROUP_DETAIL_REQUEST = "BIG_STORE_GROUP_DETAIL_REQUEST";
export const BIG_STORE_GROUP_DETAIL_SUCCESS = "BIG_STORE_GROUP_DETAIL_SUCCESS";
export const BIG_STORE_GROUP_DETAIL_FAILURE = "BIG_STORE_GROUP_DETAIL_FAILURE";

export const BIG_STORE_BANNER_REQUEST = "BIG_STORE_BANNER_REQUEST";
export const BIG_STORE_BANNER_SUCCESS = "BIG_STORE_BANNER_SUCCESS";
export const BIG_STORE_BANNER_FAILURE = "BIG_STORE_BANNER_FAILURE";

export const BIG_STORE_BANNER_CREATE_REQUEST = "BIG_STORE_BANNER_CREATE_REQUEST";
export const BIG_STORE_BANNER_CREATE_SUCCESS = "BIG_STORE_BANNER_CREATE_SUCCESS";
export const BIG_STORE_BANNER_CREATE_FAILURE = "BIG_STORE_BANNER_CREATE_FAILURE";

export const BIG_STORE_BANNER_UPDATE_REQUEST = "BIG_STORE_BANNER_UPDATE_REQUEST";
export const BIG_STORE_BANNER_UPDATE_SUCCESS = "BIG_STORE_BANNER_UPDATE_SUCCESS";
export const BIG_STORE_BANNER_UPDATE_FAILURE = "BIG_STORE_BANNER_UPDATE_FAILURE";

export const BIG_STORE_BANNER_EDIT_REQUEST = "BIG_STORE_BANNER_EDIT_REQUEST";
export const BIG_STORE_BANNER_EDIT_SUCCESS = "BIG_STORE_BANNER_EDIT_SUCCESS";
export const BIG_STORE_BANNER_EDIT_FAILURE = "BIG_STORE_BANNER_EDIT_FAILURE";

export const BIG_STORE_BANNER_DELETE_REQUEST = "BIG_STORE_BANNER_DELETE_REQUEST";
export const BIG_STORE_BANNER_DELETE_SUCCESS = "BIG_STORE_BANNER_DELETE_SUCCESS";
export const BIG_STORE_BANNER_DELETE_FAILURE = "BIG_STORE_BANNER_DELETE_FAILURE";

export const BIG_STORE_OUR_SERVICE_REQUEST = "BIG_STORE_OUR_SERVICE_REQUEST";
export const BIG_STORE_OUR_SERVICE_SUCCESS = "BIG_STORE_OUR_SERVICE_SUCCESS";
export const BIG_STORE_OUR_SERVICE_FAILURE = "BIG_STORE_OUR_SERVICE_FAILURE";

export const BIG_STORE_OUR_SERVICE_CREATE_REQUEST = "BIG_STORE_OUR_SERVICE_CREATE_REQUEST";
export const BIG_STORE_OUR_SERVICE_CREATE_SUCCESS = "BIG_STORE_OUR_SERVICE_CREATE_SUCCESS";
export const BIG_STORE_OUR_SERVICE_CREATE_FAILURE = "BIG_STORE_OUR_SERVICE_CREATE_FAILURE";

export const BIG_STORE_OUR_SERVICE_UPDATE_REQUEST = "BIG_STORE_OUR_SERVICE_UPDATE_REQUEST";
export const BIG_STORE_OUR_SERVICE_UPDATE_SUCCESS = "BIG_STORE_OUR_SERVICE_UPDATE_SUCCESS";
export const BIG_STORE_OUR_SERVICE_UPDATE_FAILURE = "BIG_STORE_OUR_SERVICE_UPDATE_FAILURE";

export const BIG_STORE_OUR_SERVICE_EDIT_REQUEST = "BIG_STORE_OUR_SERVICE_EDIT_REQUEST";
export const BIG_STORE_OUR_SERVICE_EDIT_SUCCESS = "BIG_STORE_OUR_SERVICE_EDIT_SUCCESS";
export const BIG_STORE_OUR_SERVICE_EDIT_FAILURE = "BIG_STORE_OUR_SERVICE_EDIT_FAILURE";

export const BIG_STORE_OUR_SERVICE_DELETE_REQUEST = "BIG_STORE_OUR_SERVICE_DELETE_REQUEST";
export const BIG_STORE_OUR_SERVICE_DELETE_SUCCESS = "BIG_STORE_OUR_SERVICE_DELETE_SUCCESS";
export const BIG_STORE_OUR_SERVICE_DELETE_FAILURE = "BIG_STORE_OUR_SERVICE_DELETE_FAILURE";

export const BIG_STORE_OUR_DEVICE_REQUEST = "BIG_STORE_OUR_DEVICE_REQUEST";
export const BIG_STORE_OUR_DEVICE_SUCCESS = "BIG_STORE_OUR_DEVICE_SUCCESS";
export const BIG_STORE_OUR_DEVICE_FAILURE = "BIG_STORE_OUR_DEVICE_FAILURE";

export const BIG_STORE_OUR_DEVICE_CREATE_REQUEST = "BIG_STORE_OUR_DEVICE_CREATE_REQUEST";
export const BIG_STORE_OUR_DEVICE_CREATE_SUCCESS = "BIG_STORE_OUR_DEVICE_CREATE_SUCCESS";
export const BIG_STORE_OUR_DEVICE_CREATE_FAILURE = "BIG_STORE_OUR_DEVICE_CREATE_FAILURE";

export const BIG_STORE_OUR_DEVICE_UPDATE_REQUEST = "BIG_STORE_OUR_DEVICE_UPDATE_REQUEST";
export const BIG_STORE_OUR_DEVICE_UPDATE_SUCCESS = "BIG_STORE_OUR_DEVICE_UPDATE_SUCCESS";
export const BIG_STORE_OUR_DEVICE_UPDATE_FAILURE = "BIG_STORE_OUR_DEVICE_UPDATE_FAILURE";

export const BIG_STORE_OUR_DEVICE_EDIT_REQUEST = "BIG_STORE_OUR_DEVICE_EDIT_REQUEST";
export const BIG_STORE_OUR_DEVICE_EDIT_SUCCESS = "BIG_STORE_OUR_DEVICE_EDIT_SUCCESS";
export const BIG_STORE_OUR_DEVICE_EDIT_FAILURE = "BIG_STORE_OUR_DEVICE_EDIT_FAILURE";

export const BIG_STORE_OUR_DEVICE_DELETE_REQUEST = "BIG_STORE_OUR_DEVICE_DELETE_REQUEST";
export const BIG_STORE_OUR_DEVICE_DELETE_SUCCESS = "BIG_STORE_OUR_DEVICE_DELETE_SUCCESS";
export const BIG_STORE_OUR_DEVICE_DELETE_FAILURE = "BIG_STORE_OUR_DEVICE_DELETE_FAILURE";
