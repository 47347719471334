/**
 * Enum for unit type.
 * @readonly
 * @enum {EnumUnitType}
 */

export const UNIT_TYPE = Object.freeze({
	NM3: "nm3",
	SM3: "sm3",
	KG: "kg",
	LITER: "l"
});

export const UNIT_DISPLAY_TYPE = Object.freeze({
	NM3: "Nm³",
	SM3: "Sm³",
	KG: "Kg",
	LITER: "Liter"
});