<template>
	<BaseLoading
		v-if="isLoading"
	/>
	<div
		v-else
		class="container-overall"
	>
		<div
			v-for="(item, index) in data"
			:key="index"
			:class="['container-overall-item', `growth-col-${item.growth.length}`]"
		>
			<div class="container-overall-item-inner">
				<div class="container-overall-item-inner-icon">
					<div class="container-overall-item-inner-icon-wrapper">
						<div :style="[{
							width: item.icon.width,
							height: item.icon.height,
							top: item.icon.top,
							left: item.icon.left
						}]" class="container-overall-item-inner-icon-wrapper-outer">
							<FontAwesomeIcon :icon="[item.icon.type, item.icon.name]" />
						</div>
					</div>
				</div>
				<div class="container-overall-item-inner-title">
					{{ item.title }}
				</div>
				<div class="container-overall-item-inner-total">
					<div class="container-overall-item-inner-total-number">
						{{ item.total.number | friendlyNumber }}
					</div>
					<div class="container-overall-item-inner-total-unit">
						{{ item.total.unit }}
					</div>
				</div>
				<div class="container-overall-item-inner-growth">
					<div
						v-for="(growthItem, growthIndex) in item.growth"
						:key="growthIndex"
						class="container-overall-item-inner-growth-period"
					>
						<div
							:class="[
								'container-overall-item-inner-growth-period-number',
								growthItem.number >= 0 ? 'up' : 'down'
							]"
						>
							<template>
								<span v-if="growthItem.showIntegerType">
									{{ growthItem.number | friendlyNumberWithIntegerType }}
								</span>
								<span v-else>
									{{ growthItem.number }}
								</span>
							</template>
							<template v-if="growthItem.showRank">
								<font-awesome-icon v-if="growthItem.number >= 0" :icon="['fas', 'sort-up']" />
								<font-awesome-icon v-else :icon="['fas', 'sort-down']" />
							</template>
						</div>
						<div class="container-overall-item-inner-growth-period-title">
							{{ growthItem.title }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";

export default {
	name: "BaseOverallTotal",

	props: {
		/**
		 * { Object } icon
		 * { String } title
		 * { Object } total
		 * { Array } growth
		 */
		data: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},

	components: {
		BaseLoading
	}
};
</script>

<style lang="scss" scoped>
.container-overall {
	display: flex;
	height: rem(214);
	border-radius: 8px;
	background-color: $color-dark-blue-grey;

	&-item {
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 0 rem(16);
		align-items: center;
		color: $color-white;

		&.growth {
			&-col-1 {
				.container-overall-item-inner {
					min-width: rem(136);
				}
			}

			&-col-2 {
				.container-overall-item-inner {
					min-width: rem(170);
				}

				.container-overall-item-inner-growth-period {
					&:last-of-type {
						margin-left: rem(20);
					}
				}
			}

			&-col-3 {
				.container-overall-item-inner {
					min-width: rem(195);
				}

				.container-overall-item-inner-growth-period {
					&:nth-child(2) {
						margin-left: rem(18);
					}
				}
			}
		}

		&::after {
			content: "";
			position: absolute;
			width: rem(1);
			height: rem(166);
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background-color: $color-gunmetal-two;
		}

		&:last-of-type::after {
			display: none;
		}

		&-inner {
			min-width: rem(136);

			&-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(32);
				height: rem(32);
				background-color: $color-baby-green;
				border-radius: 50%;
				margin-top: rem(24);

				&-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					width: rem(20);
					height: rem(20);

					&-outer {
						position: relative;
						display: flex;

						svg {
							width: 100%;
							height: 100%;
							color: $color-dark-blue-grey;
						}
					}
				}
			}

			&-title {
				@include ellipsis(1);

				font-size: $font-20;
				margin-top: rem(4);
			}

			&-total {
				@include fontSub();

				display: flex;
				align-items: baseline;
				margin-top: rem(-16);

				&-number {
					font-size: $font-48;
					font-weight: $font-weight-bold;
					margin-right: rem(4);
				}

				&-unit {
					@include ellipsis(1);

					font-size: $font-20;
				}
			}

			&-growth {
				display: flex;
				height: rem(48);
				padding: rem(8);
				border-radius: 4px;
				background-color: $color-te-papa-green;

				&-period {
					flex: 1;
					display: flex;
					flex-direction: column;

					&-number {
						@include ellipsis(1);

						display: flex;
						height: rem(16);
						line-height: rem(16);
						margin-bottom: rem(2);
						font-size: $font-16;
						font-weight: $font-weight-bold;

						&.up {
							color: $color-cool-green;
						}

						&.down {
							color: $color-red;
						}

						svg {
							position: relative;
							margin-left: rem(2);
						}

						.fa-sort-up {
							top: rem(2);
						}

						.fa-sort-down {
							top: rem(-5);
						}
					}

					&-title {
						@include ellipsis(1);

						height: rem(14);
						line-height: rem(14);
						font-size: $font-14;
					}
				}
			}
		}
	}
}
</style>