<template>
	<div :class="['container-notify', color, {'drop-shadow': showShadow}]">
		<div class="container-body">
			<div class="container-icon">
				<FontAwesomeIcon v-if="color === 'success'" :icon="['fas', 'check-circle']"/>
				<FontAwesomeIcon v-else :icon="['fas', 'exclamation-circle']"/>
			</div>
			<div class="container-content">
				<div class="title">
					<div v-if="$slots.title">
						<slot name="title"></slot>
					</div>
					<strong v-else>
						{{ title }}
					</strong>
				</div>
				<div class="message" v-if="message">
					<div v-if="$slots.message">
						<slot name="message"></slot>
					</div>
					<span v-else>
						{{ message }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseNotifyContent",

	props: {
		title: {
			type: String,
			default: ""
		},
		message: {
			type: String,
			default: ""
		},
		color: {
			type: String,
			default: "success",
			validator: (value) => ["success", "warning", "danger"].includes(value)
		},
		showShadow: {
			type: Boolean,
			default: true
		}
	}
};
</script>

<style lang="scss" scoped>
$border-radius-size: rem(4);

.container {
	&-notify {
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: rem(390);
		min-height: rem(74);
		background: $color-white;
		border-radius: $border-radius-size;
		overflow: hidden;

		&::before {
			content: " ";
			display: block;
			position: absolute;
			width: 100%;
			height: rem(8);
		}

		&.success {
			&::before {
				background: $color-emerald;
			}

			.container-icon {
				color: $color-emerald;
			}
		}

		&.warning {
			&::before {
				background: $color-marigold;
			}

			.container-icon {
				color: $color-marigold;
			}
		}

		&.danger {
			&::before {
				background: $color-red;
			}

			.container-icon {
				color: $color-red;
			}
		}

		&.drop-shadow {
			box-shadow: $box-shadow;

			.container-body {
				border: none;
			}
		}
	}

	&-body {
		display: flex;
		padding: $spacing-5 0 $spacing-5 0;
		margin-top: rem(8);
		border: 1px solid $color-silver;
		border-top: none;
		border-radius: 0 0 $border-radius-size $border-radius-size;
	}

	&-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: rem(90);
		font-size: $font-32;
	}

	&-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		.title, .message {
			display: flex;
			flex-grow: 1;

			span, strong {
				display: flex;
				align-items: center;
				line-height: rem(16);
			}
		}

		.message {
			margin-top: rem(8);
		}
	}
}
</style>