<template>
	<div class="row">
		<div class="col-12 col-xl-12">
			<BaseCard>
				<template #header>
					<h4>
						Product type
						<BaseTooltip
							message="Only product from active devices, inactive devices will be excluded."
							width="260px"
							class="tooltip"
						>
							<font-awesome-icon :icon="['far', 'info-circle']" class="icon-info" />
						</BaseTooltip>
					</h4>
				</template>
				<template #body>
					<BaseTableCustom
						:tableOptions="tableOptions"
						:pagination="pagination"
						:loading="isLoading"
						class="custom-table"
						@onPageChange="handlePageChange"
					>
						<template v-slot:thead>
							<tr>
								<th
									v-for="(headColumn, index) in table.thead"
									:key="index"
								>
									<div>
										<strong>
											{{ headColumn.title }}
										</strong>
										<span>
											{{ headColumn.subtitle }}
										</span>
									</div>
								</th>
							</tr>
						</template>
						<template v-slot:tbody>
							<tr
								v-for="(bodyRow, index) in table.tbody"
								:key="index"
							>
								<td
									v-for="(bodyColumn, _index) in bodyRow"
									:key="_index"
									:class="[
										_index === 0 ? 'width-130' : 'width-120'
									]"
								>
									<template v-if="_index === 0">
										<p>
											<font-awesome-icon
												:icon="['fas', 'circle']"
												:class="['device-icon', bodyColumn.toString().toLowerCase()]"
											/>
											<span class="device-name">
												<b>
													{{ bodyColumn | capitalize }}
												</b>
											</span>
										</p>
									</template>
									<template v-else-if="_index === 1">
										<p>
											<b>
												{{ bodyColumn }}
											</b>
										</p>
									</template>
									<template v-else>
										<p>
											{{ bodyColumn }}
										</p>
									</template>
								</td>
							</tr>
						</template>
					</BaseTableCustom>
				</template>
			</BaseCard>
		</div>
	</div>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseTooltip from "@/components/BaseTooltip.vue";

export default {
	name: "ProductType",

	props: {
		/**
		 * { Array } headCols
		 * { Array } bodyRows
		 */
		data: {
			type: Object,
			default: () => ({})
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},

	components: {
		BaseCard,
		BaseTableCustom,
		BaseTooltip
	},

	data() {
		return {
			tableOptions: [
				"is-striped is-fullwidth is-padding-none"
			]
		};
	},

	computed: {
		pagination() {
			return {
				currentPage: 1,
				lastPage: 1,
				total: 0
			};
		},

		table() {
			return {
				thead: this.data.headCols,
				tbody: this.data.bodyRows
			};
		}
	},

	methods: {
		handlePageChange(pageNum) {
			return pageNum;
		}
	}
};
</script>

<style lang="scss" scoped>
$cell-spacing: 0 rem(24) 0 rem(16);

/* stylelint-disable */
.custom-table::v-deep table {
	border-spacing: 0 rem(16);
	border-collapse: separate;
}
/* stylelint-enable */

th {
	height: rem(58);

	div {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: $cell-spacing;

		strong {
			@include ellipsis(1);

			height: rem(26);
			font-size: $font-20;
			font-weight: $font-weight-bold;
		}

		span {
			@include ellipsis(1);

			font-size: $font-14;
			color: $color-grey-4;
		}
	}
}

td {
	height: rem(42);

	&:first-of-type {
		p {
			justify-content: flex-start;
		}
	}

	p {
		display: flex;
		justify-content: flex-end;
		height: 100%;
		padding: $cell-spacing;
		margin: 0;
		align-items: center;

		.device-name {
			font-size: $font-20;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		.device-icon {
			width: rem(8);
			height: rem(8);
			margin-right: rem(8);

			&.oxygen {
				color: $color-lightish-green;
			}

			&.hydrogen {
				color: $color-dull-orange;
			}

			&.nitrogen {
				color: $color-azure;
			}

			&.co2, &.carbon {
				color: $color-marigold;
			}

			&.argon {
				color: $color-deep-pink;
			}

			&.electricity {
				color: $color-red;
			}

			&.energy {
				color: $color-red;
			}

			&.other {
				color: $color-te-papa-green;
			}
		}
	}
}

.width-120 {
	width: rem(120);
}

.width-130 {
	width: rem(130);
}

.tooltip {
	position: relative;
	top: rem(-1);
	margin-left: rem(14);

	&:hover > .icon-info {
		color: $color-dark-blue-grey;
		cursor: pointer;
	}

	.icon-info {
		width: rem(12);
		height: rem(13);
		color: $color-gunmetal-two;
	}
}
</style>