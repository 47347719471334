import httpClient from "./httpClient";

const NOTIFICATION_GROUP_ENDPOINT = "/notification-groups";

const getAllNotificationGroups = () => httpClient.get(`${NOTIFICATION_GROUP_ENDPOINT}/addables`);
const getChannelContactLists = () => httpClient.get(`${NOTIFICATION_GROUP_ENDPOINT}/addables/emails`);
const getNotificationGroupLists = (query) => httpClient.get(`${NOTIFICATION_GROUP_ENDPOINT}/`, {
	params: {
		...query
	}
});
const deleteNotificationGroupId = (id) => httpClient.delete(`${NOTIFICATION_GROUP_ENDPOINT}/${id}`);
const getNotificationGroupId = (id) => httpClient.get(`${NOTIFICATION_GROUP_ENDPOINT}/${id}`);
const createNotificationGroup = (payload) => httpClient.post(`${NOTIFICATION_GROUP_ENDPOINT}/`, payload);
const updateNotificationGroupId = (id, payload) => httpClient.put(`${NOTIFICATION_GROUP_ENDPOINT}/${id}`, payload);
const getNotificationConfigs = () => httpClient.get(`${NOTIFICATION_GROUP_ENDPOINT}/configs`);

export {
	getAllNotificationGroups,
	getChannelContactLists,
	createNotificationGroup,
	getNotificationGroupLists,
	getNotificationGroupId,
	updateNotificationGroupId,
	deleteNotificationGroupId,
	getNotificationConfigs
};
