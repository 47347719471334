<template>
	<div class="container">
		<div class="container-company">
			<BaseDropdownLink
				v-if="viewOnly && shipTos.length > 1"
				v-model="shipToId"
				:title="company"
				:options="options"
				:searchable="true"
				:searchKey="`searchText`"
				:class="`custom-dropdown-link`"
				@change="handleChange"
			>
				<template v-slot:item="slotProps">
					<div class="wrapper-dropdown-item">
						<span class="company">
							{{ slotProps.item.text }}
						</span>
						<span class="detail">
							Ship-to code: <strong>{{ slotProps.item.detail.code }}</strong>,
							Location key: <strong>{{ slotProps.item.detail.locationKey || slotProps.item.detail.key }}</strong>
						</span>
					</div>
				</template>
			</BaseDropdownLink>
			<strong v-else>
				{{ company }}
			</strong>
		</div>
		<div class="container-footer">
			<div class="container-shipto">
				<div class="saction">
					<span class="label">
						Ship-to code:
					</span>
					<strong class="value">
						{{ shipToCode }}
					</strong>
				</div>
				<div class="saction">
					<span class="label">
						Location key:
					</span>
					<strong class="value">
						{{ shipToKey }}
					</strong>
				</div>
			</div>
			<div class="container-update">
				<i>
					Last device sync: {{ dateNow }}
				</i>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";
import BaseDropdownLink from "@/components/BaseDropdownLink.vue";
import { PAGE_NAME } from "../enums/pagePermission";

export default {
	components: {
		BaseDropdownLink
	},

	props: {
		query: {
			type: Object,
			default: () => ({})
		},
		shipTos: {
			type: Array,
			default: () => ([])
		},
		company: {
			type: String,
			default: null
		},
		shipToCode: {
			type: String,
			default: null
		},
		shipToKey: {
			type: String,
			default: null
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			shipToId: this.query.id
				? parseInt(this.query.id, 10)
				: null
		};
	},

	computed: {
		options() {
			return this.shipTos.map((item) => {
				return {
					text: item.name,
					searchText: `${item.name} ${item.code} ${item.locationKey || item.key}`,
					value: item.id,
					detail: { ...item }
				};
			});
		},
		dateNow() {
			return dayjs().format("D MMM YYYY, HH:mm");
		}
	},

	methods: {
		handleChange(value) {
			if (value !== parseInt(this.query.id, 10)) {
				this.$router.push({
					name: PAGE_NAME.FLOWDIAGRAM_VIEW,
					query: {
						id: value
					}
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	flex-direction: column;
	padding: 0;

	&-company {
		display: flex;

		strong {
			font-family: $font-family-sub;
			font-size: $font-24;
			color: $color-dark-blue-grey;
			margin-bottom: rem(2);
		}
	}

	&-footer {
		display: flex;
		justify-content: space-between;
	}

	&-update {
		font-family: $font-family-base;
		font-size: $font-18;
		color: $color-dark-blue-grey;
	}

	&-shipto {
		display: flex;

		.saction {
			position: relative;
			margin-right: rem(20);

			&:first-of-type::before {
				content: "";
				position: absolute;
				top: calc(50% - 1px);
				right: rem(-12);
				width: 4px;
				height: 4px;
				background-color: #009a44;
				transform: translateY(-50%);
				border-radius: 50%;
			}

			.label, .value {
				font-family: $font-family-base;
				font-size: $font-18;
			}
		}
	}
}

.custom-dropdown-link {
	/* stylelint-disable */
	&::v-deep .dropdown-options-item-link {
		padding: rem(18);
		font-family: $font-family-sub;
		font-weight: $font-weight-bold;
		font-size: $font-20;
	}
	/* stylelint-enable */
}

.wrapper-dropdown-item {
	display: flex;
	flex-direction: column;

	.company {
		@include ellipsis(1);
	}

	.detail {
		font-family: $font-family-base;
		font-size: $font-18;
		color: $color-dark-blue-grey;

		strong {
			font-family: $font-family-base;
			font-size: $font-18;
		}
	}
}
</style>