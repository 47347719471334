/**
 * Enum for master data log actions
 * @readonly
 * @enum {EnumMasterDataActions}
 */
export const MASTERDATA_LOG_ACTIONS = Object.freeze({
	"Customer.Create": "USER_MANAGEMENT",
	"Customer.List": "USER_MANAGEMENT",
	"Customer.Update": "USER_MANAGEMENT",
	"Customer.UpdatePassword": "USER_MANAGEMENT",
	"Customer.View": "USER_MANAGEMENT",
	"Dashboard.View": "DASHBOARD",
	"Me.Login": "LOGIN",
	"Me.Logout": "LOGOUT",
	"Me.UpdatePassword": "CHANGE_PASSWORD",
	"Me.View": "VIEW_PROFILE",
	"Log.List": "USER_MANAGEMENT",
	"ShipTo.View": "DASHBOARD",
	"Staff.Create": "USER_MANAGEMENT",
	"Staff.List": "USER_MANAGEMENT",
	"Staff.Login": "LOGIN",
	"Staff.Delete": "USER_MANAGEMENT",
	"Staff.Update": "USER_MANAGEMENT",
	"Staff.View": "USER_MANAGEMENT",
	"SystemMonitoring.Overall": "SYSTEM_MONITORING",
	"SystemMonitoring.UserMonitoring": "SYSTEM_MONITORING",
	"SystemMonitoring.HealthMonitoring": "SYSTEM_MONITORING",
	"NoSignalConfig.Update": "SYSTEM_MONITORING",
	"Invoice.List": "INVOICE",
	"ConsumptionApproval.View": "CONSUMPTION_APPROVAL",
	"ConsumptionApproval.Approve": "CONSUMPTION_APPROVAL",
	"ConsumptionApproval.Verify": "CONSUMPTION_APPROVAL",
	"DailyConsumption.Export": "DAILLYCONSUMPTION_EXPORT"
});

/**
 * Enum for master data log icons
 * @readonly
 * @enum {EnumMasterDataLogIcons}
 */
export const MASTERDATA_LOG_ICONS = Object.freeze({
	LOGIN: {
		fa: ["far", "sign-in"],
		width: "12px",
		height: "13px",
		colorBg: "#c7f9ca",
		colorSvg: "#009a44"
	},
	LOGOUT: {
		fa: ["far", "sign-out"],
		width: "12px",
		height: "13px",
		colorBg: "#c7f9ca",
		colorSvg: "#009a44"
	},
	SYSTEM_MONITORING: {
		fa: ["far", "laptop-code"],
		width: "15px",
		height: "13px",
		colorBg: "#fff8cc",
		colorSvg: "#dba900"
	},
	DASHBOARD: {
		fa: ["far", "tachometer"],
		width: "14px",
		height: "13px",
		colorBg: "#fccacd",
		colorSvg: "#cf0360"
	},
	USER_MANAGEMENT: {
		fa: ["far", "users-cog"],
		width: "15px",
		height: "13px",
		colorBg: "#cafdfc",
		colorSvg: "#0082c1"
	},
	INVOICE: {
		fa: ["far", "file-invoice-dollar"],
		width: "9px",
		height: "13px",
		colorBg: "#fdedd3",
		colorSvg: "#ea7125"
	},
	VIEW_PROFILE: {
		fa: ["far", "user-circle"],
		width: "12px",
		height: "13px",
		colorBg: "#c7f9ca",
		colorSvg: "#009a44"
	},
	CHANGE_PASSWORD: {
		fa: ["far", "lock-alt"],
		width: "12px",
		height: "13px",
		colorBg: "#c7f9ca",
		colorSvg: "#009a44"
	},
	CONSUMPTION_APPROVAL: {
		fa: ["far", "tachometer"],
		width: "14px",
		height: "13px",
		colorBg: "#fccacd",
		colorSvg: "#cf0360"
	},
	DAILLYCONSUMPTION_EXPORT: {
		fa: ["far", "tachometer"],
		width: "14px",
		height: "13px",
		colorBg: "#fccacd",
		colorSvg: "#cf0360"
	},
	OTHER: {
		fa: ["far", "question-circle"],
		width: "12px",
		height: "13px",
		colorBg: "#cccccc",
		colorSvg: "#111111"
	}
});

/**
 * Enum for master data permissions
 * @readonly
 * @enum {EnumMasterDataPermission}
 */
export const MASTERDATA_PERMISSIONS = Object.freeze({
	DashboardRead: "Dashboard.Read",
	ConsumptionExport: "Consumption.Export",
	ConsumptionApprovalManage: "ConsumptionApproval.Manage",
	FlowDiagramRead: "FlowDiagram.Read",
	FlowDiagramManage: "FlowDiagram.Manage",
	InvoiceRead: "Invoice.Read",
	EOrderingRead: "EOrdering.Read",
	EOrderingManage: "EOrdering.Manage",
	EOrderingExport: "EOrdering.Export",
	PowerBIDevicesRead: "PowerBIDevices.Read",
	PowerBIExecutiveRead: "PowerBIExecutive.Read"
});