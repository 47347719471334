<template>
	<div :class="wrapperClass">
		<StandardDeviceDetail
			v-if="item.isStandardModule"
			:deviceLink="deviceLink"
			:data="item"
			@onClickSync="onClickSync"
		/>
		<NitroFASDetail
			v-else-if="item.type === MODULE_TYPES.NITRO_FAS"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<TankDetail
			v-else-if="isTankType"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<BulkHydrogenDetail
			v-else-if="isBulkHydrogenType"
			:deviceLink="redirectToBulkHydrogenType"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<MeterDetail
			v-else-if="item.type === MODULE_TYPES.METER"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<GenGasDetail
			v-else-if="item.type === MODULE_TYPES.GENGAS"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<WastWaterDetail
			v-else-if="item.type === MODULE_TYPES.O2_AERATION"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<WaterDetail
			v-else-if="item.type === MODULE_TYPES.ZLD"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<WaterDetail
			v-else-if="item.type === MODULE_TYPES.ZLD2"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<CerDetail
			v-else-if="item.type === MODULE_TYPES.CER"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<PowerDetail
			v-else-if="[MODULE_TYPES.PWR, MODULE_TYPES.SOR].includes(item.type)"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<LngDetail
			v-else-if="item.type === MODULE_TYPES.LNG"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<FurnaceDetail
			v-else-if="item.type === MODULE_TYPES.FURNACE"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<AirCompDetail
			v-else-if="item.type === MODULE_TYPES.AIR_COMP"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
			@onClickSync="onClickSync"
		/>
		<NotFound
			v-else
			:data="item"
		/>
		<div v-if="item.lastUpdate !== ''" class="current-time">Last update: {{item.lastUpdate}}</div>
		<div v-else class="inactive">INACTIVE</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import StandardDeviceDetail from "@/components/dashboard/modules/details/StandardDeviceDetail.vue";
import TankDetail from "@/components/dashboard/modules/details/TankDetail.vue";
import MeterDetail from "@/components/dashboard/modules/details/MeterDetail.vue";
import WaterDetail from "@/components/dashboard/modules/details/WaterDetail.vue";
import CerDetail from "@/components/dashboard/modules/details/CerDetail.vue";
import NitroFASDetail from "@/components/dashboard/modules/details/NitroFASDetail.vue";
import WastWaterDetail from "@/components/dashboard/modules/details/WastWaterDetail.vue";
import GenGasDetail from "@/components/dashboard/modules/details/GenGasDetail.vue";
import PowerDetail from "@/components/dashboard/modules/details/PowerDetail.vue";
import LngDetail from "@/components/dashboard/modules/details/LngDetail.vue";
import FurnaceDetail from "@/components/dashboard/modules/details/FurnaceDetail.vue";
import AirCompDetail from "@/components/dashboard/modules/details/AirCompDetail.vue";
import NotFound from "@/components/dashboard/modules/details/NotFound.vue";
import BulkHydrogenDetail from "@/components/dashboard/modules/details/BulkHydrogen.vue";

import { CONFIG } from "../../enums/config";
import { MODULE_TYPES } from "../../enums/modules";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	name: "ModuleListItemDevice",

	components: {
		StandardDeviceDetail,
		TankDetail,
		MeterDetail,
		WaterDetail,
		CerDetail,
		NitroFASDetail,
		WastWaterDetail,
		GenGasDetail,
		PowerDetail,
		LngDetail,
		NotFound,
		BulkHydrogenDetail,
		FurnaceDetail,
		AirCompDetail
	},

	props: {
		item: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			CONFIG,
			MODULE_TYPES,
			VINY_THAI_SHIP_ID: "ZLD-VNT01"
		};
	},

	methods: {
		async onClickSync(data) {
			this.$emit("onClickSync", data);
		}
	},

	computed: {
		...mapState("sidebar", {
			isSidebarOpen: "isSidebarOpen"
		}),

		wrapperClass() {
			return [
				"col-sm-12",
				"col-md-6",
				"col-lg-6",
				this.isSidebarOpen ? "col-xl-4" : "col-xl-3"
			];
		},

		deviceLink() {
			let name = "";
			if (this.item.isStandardModule) {
				name = PAGE_NAME.STANDARD_MODULE_OVERVIEW;
				const app = this.item.productId === MODULE_TYPES.BHY ? this.item.productId : this.item.type;
				return {
					name,
					params: { app, id: this.item.id, templateId: this.item.templateId }
				};
			}
			switch (this.item.type) {
				case MODULE_TYPES.TANK:
					name = PAGE_NAME.TANKINFO;
					break;

				case MODULE_TYPES.ZLD2:
					name = PAGE_NAME.ZLD_VINY_THAI_INFO;
					break;

				case MODULE_TYPES.METER:
					name = PAGE_NAME.METERINFO;
					break;

				case MODULE_TYPES.ZLD:
					name = PAGE_NAME.ZLDINFO;
					break;

				case MODULE_TYPES.CER:
					name = PAGE_NAME.CERINFO;
					break;

				case MODULE_TYPES.NITRO_FAS:
					name = PAGE_NAME.NITRO_FAS_INFO;
					break;

				case MODULE_TYPES.O2_AERATION:
					name = PAGE_NAME.O2_AERATION_INFO;
					break;

				case MODULE_TYPES.GENGAS:
					name = PAGE_NAME.GENGAS_INFO;
					break;

				case MODULE_TYPES.PWR:
					name = PAGE_NAME.PWR_INFO;
					break;

				case MODULE_TYPES.SOR:
					name = PAGE_NAME.SOR_INFO;
					break;

				case MODULE_TYPES.LNG:
					name = PAGE_NAME.LNG_INFO;
					break;

				case MODULE_TYPES.FURNACE:
					name = PAGE_NAME.FURNACE_INFO;
					break;

				case MODULE_TYPES.AIR_COMP:
					name = PAGE_NAME.AIR_COMP_INFO;
					break;

				default:
					break;
			}

			return {
				name,
				params: { id: this.item.id }
			};
		},
		iconSize() {
			const screenWidth = window.innerWidth
						|| document.documentElement.clientWidth
						|| document.body.clientWidth;

			if (screenWidth < 1200 || this.isSidebarOpen) {
				return "small";
			}

			return "smallest";
		},
		isTankType() {
			return this.item.type === MODULE_TYPES.TANK && this.item.productId !== MODULE_TYPES.BHY;
		},
		isBulkHydrogenType() {
			return this.item.type === MODULE_TYPES.TANK && this.item.productId === MODULE_TYPES.BHY;
		},
		redirectToBulkHydrogenType() {
			const name = PAGE_NAME.BHY_INFO;
			return {
				name,
				params: { id: this.item.id }
			};
		}
	}
};
</script>

<style lang="scss" scoped>
	::v-deep {
		.container-detail {
			height: rem(148) !important;
		}
	}

	.current-time {
		position: absolute;
		bottom: rem(20);
		right: rem(26);
		color: $color-dark-blue-grey;
		font-style: italic;
		font-size: $font-14;
	}

	.inactive {
		position: absolute;
		bottom: rem(22);
		right: rem(26);
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(50);
		height: rem(18);
		border-radius: rem(4);
		color: $color-grey-4;
		background-color: $color-light-silver;
		font-size: $font-14;
		font-weight: $font-weight-bold;
	}
</style>