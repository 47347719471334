
const initialLegendSeries = [{
	name: "Current State"
}];


export const seriesMarkLineTransform = (markLine = []) => markLine.reduce((acc, { label, color, value }) => {
	return [
		...acc,
		{
			name: label,
			type: "line",
			data: [value],
			itemStyle: {
				color
			}
		}
	];
}, []);

export const legendSeriesDataTransform = (markLine = []) => markLine.reduce(
	(acc, { label }) => {
		return [
			...acc,
			{
				name: label,
				icon: "circle"
			}
		];
	},
	initialLegendSeries
);

export const markLineDataTransform = (markLine = []) => markLine.reduce((acc, { label, value, color }) => {
	return [
		...acc,
		{
			name: label,
			yAxis: value,
			lineStyle: {
				color
			},
			label: {
				backgroundColor: color,
				padding: [2, 8],
				width: 50,
				height: 14,
				borderRadius: 100
			}
		}
	];
}, []);


export const computeMarkLineLegendConfig = (markLine, isMobile) => {
	return {
		seriesMarkLine: seriesMarkLineTransform(markLine),
		legend: {
			show: true,
			selectedMode: false,
			bottom: 40,
			left: isMobile ? 16 : 32,
			data: legendSeriesDataTransform(markLine),
			itemGap: isMobile ? 5 : 20,
			textStyle: {
				fontSize: isMobile ? 10 : 12
			}
		}
	};
};

export const computeMarkLineConfig = ({ lineStyle = {} }) => (markLineData = {}) => {
	return {
		markLine: {
			silent: false,
			tooltip: {
				show: false
			},
			symbol: ["none", "none"],
			lineStyle: {
				type: "dotted",
				...lineStyle
			},
			label: {
				fontFamily: "TFSrivichai",
				color: "#fff",
				fontWeight: "bold",
				fontSize: 14,
				position: "insideMiddle"
			},
			data: markLineDataTransform(markLineData)
		}
	};
};