import { PAGE_NAME as PAGE } from "../enums/pagePermission";

export const mapRoutes = Object.freeze({
	Tank: PAGE.TANKINFO,
	TGEE: PAGE.METERINFO,
	GENGAS: PAGE.GENGAS_INFO,
	BURNER: null, // not implement in the system yet
	CER: PAGE.CERINFO,
	INFENCE: null, // not implement in the system yet
	ZLD: PAGE.ZLDINFO,
	PWR: PAGE.PWR_INFO,
	N2F: PAGE.NITRO_FAS_INFO,
	SOR: PAGE.SOR_INFO,
	O2A: PAGE.O2_AERATION_INFO,
	ZLD2: PAGE.ZLD_VINY_THAI_INFO
});