<template>
	<BaseCardIcon color="none" class="no-shipto-container">
		<template v-slot:icon>
			<FontAwesomeIcon :icon="['far', 'file-times']" />
		</template>
		<template v-slot:head>
			<div class="no-ship-to-head">
				No information yet
			</div>
		</template>
		<template v-slot:body>
			<div class="no-ship-to-body">
				Your dashboard will appear here once you are assign.
			</div>
		</template>
	</BaseCardIcon>
</template>

<script>
import BaseCardIcon from "@/components/BaseCardIcon.vue";

export default {
	components: {
		BaseCardIcon
	}
};
</script>

<style lang="scss" scoped>
.no-shipto-container {
	margin-top: rem(66);

	.no-ship-to-head {
		font-size: rem(48);
	}

	.no-ship-to-body {
		font-size: rem(20);
	}

	.icon-wrapper svg {
		width: rem(60);
		height: rem(60);
		color: $color-gunmetal-two;
	}
}
</style>