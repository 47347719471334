var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'control',
		_vm.controlOptions,
		_vm.errorClass,
		_vm.loadingClass, { 'in-line':_vm.inline },
		_vm.getMergeClass,
		{ 'max-length': _vm.maxLength }
	],on:{"click":function($event){return _vm.$emit('click', _vm.value)}}},[(_vm.isShowLabel)?_c('label',{staticClass:"input-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('Cleave',{ref:"cleave",class:['input is-rounded', _vm.inputOptions, _vm.getMergeClass, { rounded: _vm.rounded }],attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"value":_vm.value,"disabled":_vm.disabled,"readonly":_vm.readonly,"maxLength":_vm.maxLength,"options":_vm.options},on:{"input":_vm.handleInput,"keypress":_vm.handleKeyPress,"blur":function($event){return _vm.$emit('blur', _vm.value)},"focus":function($event){return _vm.$emit('focus', _vm.value)}}}),(_vm.iconLeft)?_c('span',{staticClass:"icon is-left",on:{"click":_vm.handleClickIconLeft}},[(_vm.iconLeft)?_c('FontAwesomeIcon',{attrs:{"icon":['far', _vm.iconLeft]}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"container-icon-right"},[(_vm.iconRight)?_c('span',{staticClass:"icon is-right",on:{"click":_vm.handleClickIconRight}},[(_vm.iconRight)?_c('FontAwesomeIcon',{attrs:{"icon":['far', _vm.iconRight]}}):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"icon loading is-right"},[_vm._t("loading",[_c('FontAwesomeIcon',{attrs:{"icon":['fas', 'spinner']}})])],2):_vm._e()]),(_vm.isShowErrorMessage)?_c('span',{class:['error-message', {'has-label': _vm.label !== ''}]},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.isShowMaxLengthMessage)?_c('span',{staticClass:"max-length-message"},[_vm._v(" "+_vm._s(_vm.lengthMessage)+"/"+_vm._s(_vm.maxLength)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }