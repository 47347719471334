<template>
	<div
		:class="[
			'container-form',
			{ 'required-field': showRequiredField },
			{ 'helper-text': helperText },
			{ 'error': errorHours || errorMinutes }
		]"
	>
		<div v-if="showTimeIcon" class="container-form-icon">
			<div class="container-form-icon-wrapper">
				<div class="container-form-icon-wrapper-inner">
					<font-awesome-icon :icon="['far', 'clock']" />
				</div>
			</div>
		</div>
		<div :class="['container-form-number hour', { error: errorHours }]">
			<input
				v-model="hours"
				type="text"
				class="container-form-number-input"
				ref="hours"
				@keydown="handleKeyDown($event, 'hours')"
				@keypress="handleKeyPress"
				@change="handleChange($event, 'hours')"
				@focus="handleFocus"
				@blur="handleBlur"
			/>
			<span class="container-form-number-unit">
				hours
			</span>
		</div>
		<div :class="['container-form-number minute', { error: errorMinutes }]">
			<input
				v-model="minutes"
				type="text"
				class="container-form-number-input"
				@keydown="handleKeyDown($event, 'minutes')"
				@keypress="handleKeyPress"
				@change="handleChange($event, 'minutes')"
				@focus="handleFocus"
				@blur="handleBlur"
			/>
			<span class="container-form-number-unit">
				min
			</span>
		</div>
		<div v-if="helperText" class="container-form-helper">
			{{ helperText }}
		</div>
		<div v-if="showRequiredField" class="container-form-error required-field">
			Require field
		</div>
		<div v-else-if="errorHours" class="container-form-error">
			{{ errorHours }}
		</div>
		<div v-else-if="errorMinutes" class="container-form-error">
			{{ errorMinutes }}
		</div>
	</div>
</template>

<script>
import { convertSecond2Times, padStart } from "../selectors/helpers";

export default {
	name: "BaseFormInputTime",

	props: {
		value: {
			type: Number,
			default: 0
		},
		minHours: {
			type: Number,
			default: null
		},
		maxHours: {
			type: Number,
			default: null
		},
		minMinutes: {
			type: Number,
			default: null
		},
		maxMinutes: {
			type: Number,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		isError: {
			type: Boolean,
			default: false
		},
		showTimeIcon: {
			type: Boolean,
			default: true
		},
		helperText: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			hours: 0,
			minutes: 0,
			showRequiredField: false
		};
	},

	computed: {
		seconds() {
			const aMinute = 60;
			const aHour = aMinute * 60;
			const HOUR_IN_SECOND = this.hours * aHour;
			const MINUTE_IN_SECOND = this.minutes * aMinute;
			const TIME_IN_SECOND = HOUR_IN_SECOND + MINUTE_IN_SECOND;

			return TIME_IN_SECOND;
		},
		errorHours() {
			if (!this.validFormat(this.hours)) {
				return "Invalid format";
			} else if (this.minHours !== null && this.hours < this.minHours) {
				return this.getErrorMessage({
					min: this.minHours,
					max: this.maxHours,
					unitType: "hour"
				});
			} else if (this.maxHours !== null && this.hours > this.maxHours) {
				return this.getErrorMessage({
					min: this.minHours,
					max: this.maxHours,
					unitType: "hour"
				});
			}

			return null;
		},
		errorMinutes() {
			if (!this.validFormat(this.minutes)) {
				return "Invalid format";
			} else if (this.minMinutes !== null && this.minutes < this.minMinutes) {
				return this.getErrorMessage({
					min: this.minMinutes,
					max: this.maxMinutes,
					unitType: "minute"
				});
			} else if (this.maxMinutes !== null && this.minutes > this.maxMinutes) {
				return this.getErrorMessage({
					min: this.minMinutes,
					max: this.maxMinutes,
					unitType: "minute"
				});
			}

			return null;
		}
	},

	watch: {
		seconds(newValue) {
			this.checkRequired();
			this.$emit("input", newValue);

			if (this.showRequiredField || this.errorHours || this.errorMinutes) {
				this.$emit("update:valid", false);
			} else {
				this.$emit("update:valid", true);
			}
		},
		isError(newValue) {
			if (newValue === true) {
				if (this.seconds === 0) {
					this.showRequiredField = true;
				} else {
					this.showRequiredField = false;
				}
			}
		},
		value() {
			this.setForm(this.value);
		}
	},

	created() {
		this.setForm(this.value);
	},

	methods: {
		setForm(seconds) {
			[this.hours, this.minutes] = convertSecond2Times(seconds);

			this.$nextTick(() => {
				this.hours = padStart(this.hours, 2, 0);
				this.minutes = padStart(this.minutes, 2, 0);
			});
		},
		handleKeyPress(e) {
			const regex = /[0-9]/g;

			if (!regex.test(e.key)) {
				e.preventDefault();
			}
		},
		handleKeyDown(e, prop) {
			switch (e.key) {
				case "ArrowUp":
					// check to stop decrease number
					if (prop === "minutes" && this.maxMinutes !== null && this.minutes >= this.maxMinutes) {
						return;
					}
					if (prop === "hours" && this.maxHours !== null && this.hours >= this.maxHours) {
						return;
					}
					this[prop]++;
					this[prop] = padStart(this[prop], 2, 0);
					break;

				case "ArrowDown":
					// check to stop increase number
					if (prop === "minutes" && this.minMinutes !== null && this.minutes <= this.minMinutes) {
						return;
					}
					if (prop === "hours" && this.minHours !== null && this.hours <= this.minHours) {
						return;
					}
					this[prop]--;
					this[prop] = padStart(this[prop], 2, 0);
					break;

				default:
			}
		},
		handleChange(e, prop) {
			const value = e.target.value;
			this[prop] = padStart(value, 2, 0);
		},
		handleFocus(e) {
			e.target.select();
		},
		handleBlur() {
			this.checkRequired();
		},
		// side effect function
		checkRequired() {
			if (!this.required) return;

			if (this.seconds === 0) {
				this.showRequiredField = true;
			} else {
				this.showRequiredField = false;
			}
		},
		getErrorMessage({ min, max, unitType }) {
			const OPTION_TIME = {
				minute: "Minute",
				hour: "Hour"
			};
			const isSetMin = min !== null;
			const isSetMax = max !== null;

			if (isSetMin && isSetMax) {
				return `${OPTION_TIME[unitType]} must be only ${min}-${max}`;
			} else if (!isSetMin && isSetMax) {
				return `${OPTION_TIME[unitType]} must be less than or equal to ${max}`;
			} else if (isSetMin && !isSetMax) {
				return `${OPTION_TIME[unitType]} must be greater than or equal to ${min}`;
			}

			return "";
		},
		validFormat(value) {
			const validFormatNumber = /^[0-9]*$/g.test(value);
			const validFormatNumberLeading = (() => {
				const chars = [...value.toString()];
				if (chars.length > 2 && chars[0] === "0") {
					return false;
				}
				return true;
			})();
			return validFormatNumber && validFormatNumberLeading;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	position: relative;
	display: flex;
	height: max-content;
	background-color: $color-white;
	border: solid 1px $color-silver;
	border-radius: 4px;

	&.required-field {
		border-color: $color-red;
	}

	&.helper-text.error {
		.container-form-helper {
			bottom: rem(-15);
		}
	}

	&-icon {
		width: rem(35);
		display: flex;
		border-right: solid 1px $color-silver;
		justify-content: center;
		align-items: center;

		&-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(20);
			height: rem(20);

			&-inner {
				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(12);
				height: rem(13);

				svg {
					width: 100%;
					height: 100%;
					color: $color-gunmetal-two;
				}
			}
		}
	}

	&-number {
		position: relative;
		display: flex;
		flex: 1;

		&.error::before {
			content: "";
			position: absolute;
			top: rem(-1);
			left: rem(-1);
			width: 100%;
			height: 100%;
			border: 1px solid $color-red;
			box-sizing: content-box;
			pointer-events: none;
			z-index: 1;
		}

		&.error.hour::before {
			width: calc(100% - #{rem(1)});
		}

		&.error.minute::before {
			border-radius: 0 4px 4px 0;
		}

		&.minute::after {
			display: none;
		}

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: rem(1);
			height: 50%;
			background-color: $color-silver;
		}

		&-input {
			flex: 1;
			width: 100%;
			padding: rem(7) rem(40) rem(7) rem(15);
			background-color: transparent;
			border: unset;
			font-size: $font-16;
			color: $color-dark-blue-grey;
			-moz-appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}
		}

		&-unit {
			position: absolute;
			top: 50%;
			right: rem(15);
			transform: translateY(-50%);
			font-size: $font-14;
			color: $color-grey-4;
			pointer-events: none;
		}
	}

	&-error {
		position: absolute;
		bottom: rem(-4);
		left: 0;
		transform: translateY(100%);
		font-size: $font-12;
		font-weight: $font-weight-bold;
		color: $color-red;
	}

	&-helper {
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateY(100%);
		font-size: $font-16;
		color: $color-grey-4;
	}
}
</style>