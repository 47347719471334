import dayjs from "dayjs";
import { transformLineChart } from "..";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	getForEachDay,
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachTwoHours,
	getForEachThreeDay,
	getForEachFiveDay,
	getForEachTwelveHours,
	xAxisLabelWithOutTime
} from "../helper";
import { yAxisConfig } from "../helper/yAxisConfig";
import { computeMarkLineConfig, computeMarkLineLegendConfig } from "../markLineTransform";
import { getConfigDataZoom } from "../helper/zoomConfig";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export const tooltipBhyLineChartFormat = (name, value) => {
	const format = `
			<div>
				<div class="bar-name">
					${name}
				</div>
				<div class="bar-value">
					${value === null ? "-" : value} psi
				</div>
			</div>`;
	return format;
};

const computeBhyMarkLine = computeMarkLineConfig({
	lineStyle: {
		type: "dotted"
	}
});

const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipBhyLineChartFormat(date, pointValue.data);
};

const optionBhyLineChartXaxisPerWeek = ({ markLine = {} }, { xAxis }, isMobile) => ({
	yAxisText: "Tank level (psi)",
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachTwelveHours
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 64,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeBhyMarkLine(markLine)
	},
	...computeMarkLineLegendConfig(markLine, isMobile)
});

const optionBhyLineChartXaxisPerThreeDay = ({ markLine = {} }, { xAxis }, isMobile) => ({
	yAxisText: "Tank level (psi)",
	axisLabel: {
		padding: [0, 0, 10, 0],
		formatter: axisXLabelFormatter,
		interval: isMobile ? getForEachTwelveHours : getForEachFourHours
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: isMobile ? getForEachFourHours : true
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 64,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeBhyMarkLine(markLine)
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

const optionBhyLineChartXaxisPerDay = ({ markLine = {} }, { xAxis }, isMobile) => ({
	yAxisText: "Tank level (psi)",
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: isMobile ? getForEachFourHours : getForEachTwoHours
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: () => true
	},


	grid: {
		left: 16,
		right: 16,
		bottom: 64,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeBhyMarkLine(markLine)
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

const optionBhyLineChartMoreThanTwentyDay = ({ markLine = {} }, { xAxis }, isMobile) => ({
	yAxisText: "Tank level (psi)",
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachThreeDay,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachThreeDay
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 64,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeBhyMarkLine(markLine)
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

const optionTankLineChartThreeMonths = ({ markLine = {} }, { xAxis }, isMobile) => ({
	yAxisText: "Tank level (psi)",
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachFiveDay,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachFiveDay
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 64,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeBhyMarkLine(markLine)
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

export const transformBhyLevelLineChartConfig = (sortBy = perDay, data = [], option = {}, isMobile = false) => {
	const { diffDay } = option;
	const mappingOption = {
		[perDay]: optionBhyLineChartXaxisPerDay(option, data, isMobile),
		[perThreeDay]: optionBhyLineChartXaxisPerThreeDay(option, data, isMobile),
		[perWeek]: optionBhyLineChartXaxisPerWeek(option, data, isMobile),
		[perThirtyDays]: optionBhyLineChartMoreThanTwentyDay(option, data, isMobile),
		[perNinetyDays]: optionTankLineChartThreeMonths(option, data, isMobile)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionBhyLineChartMoreThanTwentyDay(option, data);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}

	const functionTransform = mappingOption[sortBy] || optionBhyLineChartXaxisPerWeek(option, data);
	const transformFunc = transformLineChart(functionTransform);

	return transformFunc(data);
};
