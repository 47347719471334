<template>
	<div>
		<div class="container-form mb-10">
			<div class="head-label">
				<strong>Manage role permission</strong>
			</div>
		</div>
		<div
			v-for="(item, index) in mapPermissions"
			:key="index"
			class="container-form mb-6"
		>
			<div class="label">
				<span>{{ item.title }}</span>
			</div>
			<div class="body">
				<BaseCheckBox
					v-for="(action, actionIndex) in item.actions"
					:key="actionIndex"
					:label="action.title"
					:checked="action.checked"
					:disabled="disabled"
					@handleCheck="onToggleAction($event, item.key, action.key)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import BaseCheckBox from "@/components/BaseCheckBox.vue";

export default {
	components: {
		BaseCheckBox
	},

	props: {
		value: {
			type: Array,
			default: () => ([])
		},
		role: {
			type: String,
			default: ""
		},
		permissionMasterData: {
			type: Object,
			default: () => ({})
		},
		disabled: {
			type: Boolean,
			default: false
		},
		autoSelectedByRole: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			form: {}
		};
	},

	computed: {
		mapPermissions() {
			return Object.keys(this.permissionMasterData).reduce((accPages, keyPage) => {
				const actions = Object.keys(this.permissionMasterData[keyPage].actions).reduce((accActions, keyAction) => {
					if (this.permissionMasterData[keyPage].actions[keyAction].active) {
						const action = {
							key: keyAction,
							...this.permissionMasterData[keyPage].actions[keyAction],
							checked: this.form[this.getKey(keyPage, keyAction)]
						};
						accActions.push(action);
					}
					return accActions;
				}, []);
				const page = {
					...this.permissionMasterData[keyPage],
					key: keyPage,
					actions
				};
				accPages.push(page);
				return accPages;
			}, []);
		}
	},

	watch: {
		form: {
			handler() {
				const permissions = [];
				for (const key of Object.keys(this.form)) {
					const [keyPage, keyAction] = key.split(".");
					if (this.form[key]) {
						for (const item of this.permissionMasterData[keyPage].actions[keyAction].permissions) {
							if (!permissions.includes(item)) {
								permissions.push(item);
							}
						}
					}
				}
				this.$emit("input", permissions);
			},
			deep: true
		},
		role() {
			this.clearStateForm();
			this.initStateForm({ checkValue: false, autoSelectedByRole: true });
		}
	},

	mounted() {
		this.initStateForm({ checkValue: true, autoSelectedByRole: this.autoSelectedByRole });
	},

	methods: {
		initStateForm({ checkValue = false, autoSelectedByRole = false }) {
			for (const keyPage of Object.keys(this.permissionMasterData)) {
				for (const keyAction of Object.keys(this.permissionMasterData[keyPage].actions)) {
					const key = this.getKey(keyPage, keyAction);
					let checked = false;
					if (checkValue) {
						for (const permission of this.value) {
							if (this.permissionMasterData[keyPage].actions[keyAction].permissions.includes(permission)) {
								checked = true;
							}
						}
					}
					if (autoSelectedByRole && this.permissionMasterData[keyPage].actions[keyAction].preselect.includes(this.role)) {
						checked = true;
					}
					this.$set(this.form, key, checked);
				}
			}
		},
		clearStateForm() {
			for (const keyPage of Object.keys(this.permissionMasterData)) {
				for (const keyAction of Object.keys(this.permissionMasterData[keyPage].actions)) {
					const key = this.getKey(keyPage, keyAction);
					this.form[key] = false;
				}
			}
		},
		updateStateForm(checked, keyPage, keyAction) {
			const key = this.getKey(keyPage, keyAction);
			this.form[key] = checked;
		},
		onToggleAction(checked, keyPage, keyAction) {
			this.updateStateForm(checked, keyPage, keyAction);
			if (keyPage !== "powerBI") { // ignore powerBI auto selected and unselected action views
				// auto selected and unselected action views
				const keyActionCustom = "view";
				const key = this.getKey(keyPage, keyActionCustom);
				if (keyAction !== "view" && checked && !this.form[key]) {
					this.updateStateForm(true, keyPage, keyActionCustom);
				} else if (keyAction === "view" && !checked) {
					Object.key(this.form).forEach((keyForm) => {
						const [_keyPage, _keyAction] = keyForm.split(".");
						if (_keyPage === keyPage) {
							this.updateStateForm(false, _keyPage, _keyAction);
						}
					});
				}
			}
		},
		getKey(keyPage, keyAction) {
			const key = `${keyPage}.${keyAction}`;
			return key;
		},
		getPermissions(keyPage, keyAction) {
			const permissions = this.permissionMasterData[keyPage].actions[keyAction].permissions;
			return permissions;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	display: flex;

	.label {
		width: 130px;
	}

	.body {
		display: flex;

		.container {
			min-width: rem(125);
		}
	}

	.head-label {
		font-family: $font-family-base;
		font-size: $font-18;

		&.system-role {
			font-family: $font-family-sub;
			font-size: $font-20;
		}
	}
}

.mb-6 {
	margin-bottom: rem(6);
}

.mb-10 {
	margin-bottom: rem(10);
}
</style>