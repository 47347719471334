<template>
	<div :class="wrapperClass">
		<StandardModuleWithCompany
			v-if="item.isStandardModule"
			:deviceLink="deviceLink"
			:data="item"
		/>
		<NitroFASDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.NITRO_FAS"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<TankDeviceWithCompany
			v-else-if="isTankType"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<BulkHydrogenDeviceWithCompany
			v-else-if="isBulkHydrogenType"
			:deviceLink="redirectToBulkHydrogenType"
			:data="item"
			:iconSize="iconSize"
		/>
		<WastWaterDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.O2_AERATION"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<GenGasDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.GENGAS"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<MeterDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.METER"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<WaterDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.ZLD"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<WaterDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.ZLD2"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<CerDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.CER"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<PowerDeviceWithCompany
			v-else-if="[MODULE_TYPES.PWR, MODULE_TYPES.SOR].includes(item.type)"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<LngDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.LNG"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<FurnaceDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.FURNACE"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<AirCompDeviceWithCompany
			v-else-if="item.type === MODULE_TYPES.AIR_COMP"
			:deviceLink="deviceLink"
			:data="item"
			:iconSize="iconSize"
		/>
		<NotFound
			v-else
			:data="item"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import StandardModuleWithCompany from "@/components/dashboard/modules/deviceWithCompany/StandardModuleWithCompany.vue";
import TankDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/TankDeviceWithCompany.vue";
import MeterDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/MeterDeviceWithCompany.vue";
import WaterDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/WaterDeviceWithCompany.vue";
import CerDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/CerDeviceWithCompany.vue";
import WastWaterDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/WastWaterDeviceWithCompany.vue";
import NitroFASDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/NitroFASDeviceWithCompany.vue";
import GenGasDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/GenGasDeviceWithCompany.vue";
import PowerDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/PowerDeviceWithCompany.vue";
import LngDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/LngDeviceWithCompany.vue";
import BulkHydrogenDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BulkHydrogenDeviceWithCompany.vue";
import FurnaceDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/FurnaceDeviceWithCompany.vue";
import AirCompDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/AirCompWithCompany.vue";
import NotFound from "@/components/dashboard/modules/deviceWithCompany/NotFound.vue";

import { CONFIG } from "../../enums/config";
import { MODULE_TYPES } from "../../enums/modules";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	name: "ModuleListItemDevice",

	components: {
		StandardModuleWithCompany,
		TankDeviceWithCompany,
		MeterDeviceWithCompany,
		WaterDeviceWithCompany,
		CerDeviceWithCompany,
		NitroFASDeviceWithCompany,
		GenGasDeviceWithCompany,
		WastWaterDeviceWithCompany,
		PowerDeviceWithCompany,
		LngDeviceWithCompany,
		BulkHydrogenDeviceWithCompany,
		FurnaceDeviceWithCompany,
		AirCompDeviceWithCompany,
		NotFound
	},

	props: {
		item: {
			type: Object,
			default: null
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			MODULE_TYPES,
			CONFIG
		};
	},

	computed: {
		...mapState("sidebar", {
			isSidebarOpen: "isSidebarOpen"
		}),

		wrapperClass() {
			return [
				"col-sm-12",
				"col-md-6",
				"col-lg-6",
				this.isSidebarOpen ? "col-xl-4" : "col-xl-3"
			];
		},

		deviceLink() {
			let name = "";
			if (this.item.isStandardModule) {
				name = PAGE_NAME.STANDARD_MODULE_OVERVIEW;
				const app = this.item.productId === MODULE_TYPES.BHY ? this.item.productId : this.item.type;
				return {
					name,
					params: { app, id: this.item.id, templateId: this.item.templateId }
				};
			}
			switch (this.item.type) {
				case MODULE_TYPES.TANK:
					name = PAGE_NAME.TANKINFO;
					break;

				case MODULE_TYPES.ZLD2:
					name = PAGE_NAME.ZLD_VINY_THAI_INFO;
					break;

				case MODULE_TYPES.METER:
					name = PAGE_NAME.METERINFO;
					break;

				case MODULE_TYPES.ZLD:
					name = PAGE_NAME.ZLDINFO;
					break;

				case MODULE_TYPES.CER:
					name = PAGE_NAME.CERINFO;
					break;

				case MODULE_TYPES.NITRO_FAS:
					name = PAGE_NAME.NITRO_FAS_INFO;
					break;

				case MODULE_TYPES.O2_AERATION:
					name = PAGE_NAME.O2_AERATION_INFO;
					break;

				case MODULE_TYPES.GENGAS:
					name = PAGE_NAME.GENGAS_INFO;
					break;

				case MODULE_TYPES.PWR:
					name = PAGE_NAME.PWR_INFO;
					break;

				case MODULE_TYPES.SOR:
					name = PAGE_NAME.SOR_INFO;
					break;

				case MODULE_TYPES.LNG:
					name = PAGE_NAME.LNG_INFO;
					break;

				case MODULE_TYPES.FURNACE:
					name = PAGE_NAME.FURNACE_INFO;
					break;

				case MODULE_TYPES.AIR_COMP:
					name = PAGE_NAME.AIR_COMP_INFO;
					break;

				default:
					break;
			}


			return {
				name,
				params: { id: this.item.id }
			};
		},
		iconSize() {
			const screenWidth = window.innerWidth
						|| document.documentElement.clientWidth
						|| document.body.clientWidth;

			if (screenWidth < 1200 || this.isSidebarOpen) {
				return "small";
			}

			return "smallest";
		},

		isTankType() {
			return this.item.type === MODULE_TYPES.TANK && this.item.productId !== MODULE_TYPES.BHY;
		},

		isBulkHydrogenType() {
			return this.item.type === MODULE_TYPES.TANK && this.item.productId === MODULE_TYPES.BHY;
		},

		redirectToBulkHydrogenType() {
			const name = PAGE_NAME.BHY_INFO;
			return {
				name,
				params: { id: this.item.id }
			};
		}
	}
};
</script>
