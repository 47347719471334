import { mapActions, mapGetters } from "vuex";
import { PERMISSION_DROPDOWN_ALL } from "../../../enums/customerManagement/devicePermission";

const { ALL_APPLICATION, ALL_SHIP_TO, ALL_DEVICE, ALL_SOLD_TO } = PERMISSION_DROPDOWN_ALL;
export default {
	data() {
		return {
			soldToValues: [],
			shipToValues: [],
			applicationValues: [],
			deviceValues: [],
			applicationCombineShipTo: [],
			isAutoUpdateShipTo: false,
			isAutoUpdateDevice: false

		};
	},
	watch: {
		company(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.clearPermissionList();
				this.soldToValues = [];
				this.shipToValues = [];
				this.applicationValues = [];
				this.deviceValues = [];
				this.applicationCombineShipTo = [];
				this.isAutoUpdateShipTo = false;
				this.isAutoUpdateDevice = false;
			}
		}
	},
	methods: {
		...mapActions("devicePermissionLists", {
			clearPermissionList: "clearPermissionList",
			fetchPermissionList: "fetchPermissionList"
		}),
		async handleFetchPermissionLists() {
			const isDeclareCompany = this.company !== undefined;
			const isCompanyEmpty = !this.company;
			if ((isDeclareCompany && !isCompanyEmpty) || !isDeclareCompany) {
				if (this.isSelectCompany) {
					await this.fetchPermissionList({
						company: this.company
					});
				} else {
					await this.fetchPermissionList({});
				}
			}
		},

		setInitialPermission() {
			this.soldToValues = [
				...(this.isAllSoldTo ? [ALL_SOLD_TO] : []),
				...this.getCustomerSoldToValues
			];
			this.shipToValues = [
				...(this.isAllShipTo ? [ALL_SHIP_TO] : []),
				...this.getCustomerShipToValues
			];
			this.applicationValues = [
				...(this.isAllApplication ? [ALL_APPLICATION] : []),
				...this.getCustomerApplicationValues
			];
			this.deviceValues = [
				...(this.isAllDevice ? [ALL_DEVICE] : []),
				...this.getCustomerDeviceValues
			];
		},
		handleCheckUpdateDevice() {
			this.isAutoUpdateDevice = !this.isAutoUpdateDevice;
		},

		handleCheckUpdateShipTo() {
			this.isAutoUpdateShipTo = !this.isAutoUpdateShipTo;
		},

		async handleClearPermission() {
			this.isSelectCompany = false;
			this.soldToValues = [];
			this.shipToValues = [];
			this.applicationValues = [];
			this.deviceValues = [];
			this.applicationCombineShipTo = [];
			this.isAutoUpdateShipTo = false;
			this.isAutoUpdateDevice = false;
			this.clearPermissionList();
			this.onResetFormModalDup();
			await this.fetchPermissionList({});
		},

		handleSelectSoldTo(value) {
			if (value.code === "all") {
				this.soldToValues = [
					...this.getSoldToOptions
				];
			} else {
				this.soldToValues = [
					...this.soldToValues,
					value
				];
			}
		},

		handleRemoveSoldTo(value) {
			if (value.code === "all") {
				this.soldToValues = [];
			} else {
				this.soldToValues = this.soldToValues
					.filter(({ id }) => id !== value.id)
					.filter(({ code }) => !(code === "all"));
			}

			if (this.soldToValues.length === 0) {
				this.shipToValues = [];
				this.applicationValues = [];
				this.deviceValues = [];
				this.isAutoUpdateShipTo = false;
				this.isAutoUpdateDevice = false;
			}

			this.shipToValues = this.shipToValues.filter(({ soldToId }) => {
				return soldToId !== value.id;
			});
		},

		handleSelectShipTo(value) {
			if (value.code === "all") {
				this.shipToValues = [
					...this.getShipToOptions
				];
			} else {
				this.shipToValues = [
					...this.shipToValues,
					value
				];
			}
		},
		handleRemoveShipTo(value) {
			if (value.code === "all") {
				this.shipToValues = [];
			} else {
				this.shipToValues = this.shipToValues
					.filter(({ id }) => id !== value.id)
					.filter(({ code }) => !(code === "all"));
			}

			if (this.shipToValues.length === 0) {
				this.applicationValues = [];
				this.deviceValues = [];
				this.isAutoUpdateShipTo = false;
				this.isAutoUpdateDevice = false;
			}

			this.deviceValues = this.deviceValues.filter(({ shipToId }) => shipToId !== value.id);
		},

		handleSelectApplication(value) {
			if (value.code === "all") {
				this.applicationValues = [
					...this.getApplicationOptions
				];
			} else {
				this.applicationValues = [
					...this.applicationValues,
					value
				];
			}
		},

		handleRemoveApplication(value) {
			if (value.code === "all") {
				this.applicationValues = [

				];
			} else {
				this.applicationValues = this.applicationValues
					.filter(({ code }) => code !== value.code)
					.filter(({ code }) => !(code === "all"));
			}

			if (this.applicationValues.length === 0) {
				this.deviceValues = [];
				this.isAutoUpdateDevice = false;
			}

			this.deviceValues = this.deviceValues.filter(({ app }) => app !== value.code);
		},

		handleSelectDevice(value) {
			if (value.code === "all") {
				this.deviceValues = [
					...this.getDeviceOptions
				];
			} else {
				this.deviceValues = [
					...this.deviceValues,
					value
				];
			}
		},

		handelRemoveDevice(value) {
			if (value.code === "all") {
				this.deviceValues = [];
			} else {
				this.deviceValues = this.deviceValues
					.filter(({ code }) => code !== value.code)
					.filter(({ code }) => !(code === "all"));
			}
		},

		getLabelSoldTo({ key }) {
			return key;
		},

		getLabelShipTo({ key, code }) {
			if (code === "all") {
				return `${key}`;
			}
			return `${key} - ${code}`;
		}
	},

	computed: {
		...mapGetters("devicePermissionLists", {
			getListSoldTosOptions: "getListSoldTosOptions",
			getListShipTosOptions: "getListShipTosOptions",
			getListApplicationOptions: "getListApplicationOptions",
			getListDeviceOptions: "getListDeviceOptions"
		}),

		...mapGetters("customerManagement", {
			getCustomerApplication: "getCustomerApplication"
		}),

		getCombineApplicaitonAndShipTo() {
			if (this.applicationValues.length > 0) {
				const applicationCombineShipTo = this.applicationValues.reduce((acc, { code }) => {
					return [
						...acc,
						...this.shipToValues.map(({ id }) => `${code}-${id}`)
					];
				}, []);
				return applicationCombineShipTo;
			}
			return [];
		},
		getSoldToValues() {
			if (this.soldToValues.some(({ code }) => code === "all")) {
				this.soldToValues = this.getSoldToOptions;
			}
			return this.soldToValues;
		},
		getShipToValues() {
			if (this.shipToValues.some(({ code }) => code === "all")) {
				this.shipToValues = this.getShipToOptions;
			}
			return this.shipToValues;
		},
		getApplicationValues() {
			if (this.applicationValues.some(({ code }) => code === "all")) {
				this.applicationValues = this.getApplicationOptions;
			}
			this.applicationValues = this.applicationValues.filter(({ code }) => {
				if (code === "all") {
					return true;
				}
				return [...this.getCustomerApplication, ...this.getAvailableApplication].indexOf(code) !== -1;
			});
			return this.applicationValues;
		},
		getDeviceValue() {
			if (this.deviceValues.some(({ code }) => code === "all")) {
				this.deviceValues = this.getDeviceOptions;
			}
			const getShipToSelectedId = this.shipToValues.map(({ id }) => id);

			this.deviceValues = this.deviceValues.filter(({ shipToId, code }) => {
				if (code === "all") {
					return true;
				}
				return getShipToSelectedId.indexOf(shipToId) !== -1;
			});

			return this.deviceValues;
		},

		getDefaultSoldToOptions() {
			return this.isSelectCompany ? [] : [ALL_SOLD_TO];
		},

		getDefaultShipToOptions() {
			return this.isSelectCompany ? [] : [ALL_SHIP_TO];
		},

		getDefaultApplicationToOptions() {
			return this.isSelectCompany ? [] : [ALL_APPLICATION];
		},

		getDefaultDeviceToOptions() {
			return this.isSelectCompany ? [] : [ALL_DEVICE];
		},

		isDisabledSoldTo() {
			return !this.getSoldToOptions.length || !this.company;
		},

		isDisabledShipTo() {
			return !this.soldToValues.length;
		},

		isDisabledApplication() {
			return !this.shipToValues.length;
		},

		isDisableDevice() {
			return !this.applicationValues.length;
		},

		getSoldToOptions() {
			return [
				...this.getDefaultSoldToOptions,
				...this.getListSoldTosOptions
			];
		},

		getShipToOptions() {
			if (this.soldToValues.length === 0) {
				return [];
			}

			return this.soldToValues.reduce((acc, { id }) => {
				return [
					...acc,
					...(this.getListShipTosOptions[id] ? this.getListShipTosOptions[id] : [])
				];
			}, this.getDefaultShipToOptions);
		},

		getAvailableApplication() {
			const getApplication = this.shipToValues.reduce((acc, { id }) => {
				return [
					...acc,
					...(this.getListApplicationOptions[id] ? this.getListApplicationOptions[id] : [])
				];
			}, []);

			const uniqueApplication = getApplication.filter((value, index, self) => {
				return self.indexOf(value) === index;
			});

			if (uniqueApplication.length === 0) {
				return [];
			}

			return uniqueApplication;
		},

		getApplicationOptions() {
			return this.getAvailableApplication.reduce((acc, next) => {
				return [
					...acc,
					{
						code: next,
						name: next.toUpperCase(),
						key: next.toUpperCase()
					}
				];
			}, this.getDefaultApplicationToOptions);
		},

		getDeviceOptions() {
			return this.getCombineApplicaitonAndShipTo.reduce((acc, code) => {
				return [
					...acc,
					...(this.getListDeviceOptions[code] ? this.getListDeviceOptions[code] : [])
				];
			}, this.getDefaultDeviceToOptions);
		},

		computePermission() {
			return {
				views: {
					soldTos: this.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
					shipTos: this.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
					applications: this.applicationValues.filter(({ code }) => code !== "all").map(({ code }) => code),
					devices: this.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
				},
				autoUpdate: {
					soldTos: this.soldToValues.some(({ code }) => code === "all"),
					shipTos: this.isAutoUpdateShipTo,
					devices: this.isAutoUpdateDevice
				},
				all: {
					soldTos: this.soldToValues.some(({ code }) => code === "all"),
					shipTos: this.shipToValues.some(({ code }) => code === "all"),
					applications: this.applicationValues.some(({ code }) => code === "all"),
					devices: this.deviceValues.some(({ code }) => code === "all")
				}
			};
		}
	}
};