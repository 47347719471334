<template>
	<div class="furnace-report">
		<FurnaceFilterReport class="custom-filter-report" mode="range" @submit="handleFilter" />
		<BaseEmpty
			v-if="isNotSelect"
			class="wrapper-empty"
			icon="file-alt"
			wrapperColor="very-pale-green"
			iconColor="emerald"
			title="Please select Report type and date"
			subTitle="Report will be displayed once you make the selection"
		/>
		<EmptyOrderHistory v-else-if="isEmpty" class="empty" />
		<template v-else>
			<BaseTableCustom
				class="furnace-report-table"
				:tableOptions="tableOptions"
				:pagination="pagination"
				:loading="loading"
				@onPageChange="handlePageChange"
			>
				<template v-slot:tableTitle>
					<p class="company-name">{{ shipToName }}</p>
					<h2 class="table-title">Report</h2>
				</template>
				<template v-slot:thead>
					<tr>
						<th
							v-for="(value, index) in item.thead"
							:key="index"
							:class="columnOptions[index].className"
						>
							{{ value }}
							<button class="btn-sort" v-if="isShowSort(value)" @click="onClickSortChange">
								<FontAwesomeIcon
									class="icon"
									v-if="sortBy === 'desc'"
									:icon="['fas', 'caret-down']"
								/>
								<FontAwesomeIcon
									class="icon"
									v-if="sortBy === 'asc'"
									:icon="['fas', 'caret-up']"
								/>
							</button>
						</th>
					</tr>
				</template>
				<template v-slot:tbody>
					<tr v-for="({ type, product, date, startDate , endDate ,  pdfKey }, index) in item.tbody" :key="index">
						<td>
							{{ type }}
						</td>
						<td>
							{{ product }}
						</td>
						<td>
							{{ date }}
						</td>
						<td>
							<span v-if="startDate !== '-'">
								{{ startDate }}
							</span> -
							<span v-if="endDate !== '-'">
								{{ endDate }}
							</span>
						</td>
						<td class="align-right">
							<button v-if="permissions.dashboard.exportable" class="view-button" @click="onDownloadPdf(pdfKey)">
								Download Report (PDF)
							</button>
						</td>
					</tr>
				</template>
			</BaseTableCustom>
		</template>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";

import BaseEmpty from "@/components/BaseEmpty.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import FurnaceFilterReport from "@/components/FurnaceModule/FurnaceFilterReport.vue";

import userPermission from "../../mixins/userPermission";
import { getSignedReportUrl } from "../../services/api/furnaces.api";
import { REPORT_TYPE } from "../../enums/furnace/reportType";

export default {
	components: {
		EmptyOrderHistory,
		BaseEmpty,
		FurnaceFilterReport,
		BaseTableCustom
	},

	mixins: [userPermission],

	props: {
		shipToName: {
			type: String,
			default: ""
		}
	},

	data() {
		return {
			furnaceId: this.$route.params.id,
			reportType: REPORT_TYPE.daily,
			dateRange: {
				start: dayjs().subtract(7, "day").startOf("day").toDate(),
				end: dayjs().subtract(1, "day").endOf("day").toDate()
			},
			thead: [`Report type`, `Product`, `Generated date`, `Report from - to`, `Attached file (PDF)`],

			tableOptions: ["is-striped is-fullwidth"],

			columnOptions: [
				{ className: "line-height-1" },
				{ className: "line-height-1" },
				{ className: "line-height-1" },
				{ className: "line-height-1" },
				{ className: "line-height-1 align-right" }
			],

			page: this.$route.query.page || 1,
			sortBy: "desc"
		};
	},

	computed: {
		...mapState(["furnaceReports"]),
		data() {
			return this.furnaceReports.data;
		},
		pagination() {
			return this.furnaceReports.pagination;
		},
		loading() {
			return this.furnaceReports.isLoading;
		},
		item() {
			return {
				thead: this.thead,
				tbody: this.data
			};
		},
		isEmpty() {
			return this.furnaceReports.isEmpty;
		},
		isNotSelect() {
			return !this.reportType;
		}
	},

	created() {
		this.$emit("loaded");
		this.fetchData();
		this.addActivityLogReportView({
			shopToId: this.furnaceId,
			httpStatus: this.furnaceReports.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("furnaceReports", {
			fetchFurnaceReports: "fetchFurnaceReports"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogReportView: "addActivityLogReportView"
		}),

		isShowSort(column) {
			return column === "Generated date" && this.data.length > 1;
		},

		fetchData() {
			const { furnaceId, page, reportType, dateRange } = this;
			return this.fetchFurnaceReports({
				id: furnaceId,
				params: {
					page,
					reportType,
					startDate: dateRange?.start,
					endDate: dateRange?.end,
					sort: `date:${this.sortBy}`
				}
			});
		},

		handlePageChange(page = 1) {
			this.page = page;
			this.fetchData();
		},

		handleFilter(filterData) {
			this.dateRange = filterData.dateRange;
			this.reportType = filterData.reportType;
			if (!this.reportType) {
				return;
			}
			this.fetchData();
		},
		async onDownloadPdf(pdfKey) {
			const { data } = await getSignedReportUrl(this.furnaceId, {
				pdfKey
			});
			window.open(data);
		},

		onClickSortChange() {
			this.sortBy = this.sortBy === "desc" ? "asc" : "desc";
			this.fetchData();
		}
	}
};
</script>

<style lang="scss" scoped>
.furnace-report {
	.empty,
	.wrapper-empty {
		margin-top: $empty-order-margin-top;
	}

	.custom-filter-report {
		margin-top: rem(-8);
		margin-bottom: rem(11);
		justify-content: flex-end;
	}

	&-table {
		border: 1px solid $color-silver;
		border-radius: rem($border-radius-normal);

		::v-deep {
			.container-custom-main-table {
				background-color: $color-alabaster;
				border-radius: rem($border-radius-normal);

				thead {
					border-top: none;
					border-bottom: none;

					tr {
						background-color: $color-alabaster;

						.btn-sort {
							margin-left: 2px;

							border: none;
							background: none;
							color: $color-cerulean;
							vertical-align: text-top;
						}
					}
				}

				tbody {
					tr {
						background-color: transparent !important;

						&:first-child {
							border-top: none;
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}
</style>
