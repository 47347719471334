/**
 * Enum for mapping tank label.
 * @readonly
 * @enum {EnumMappingTankLabel}
 */
export const mappingTankLabel = Object.freeze({
	forecastReorderAt: "Forecasted Reorder",
	forecastEmptyAt: `Forecasted Empty`,
	remain: `Remaining`,
	usageRate: `Usage rate`,
	monthToDate: `Month to date consumed`
});