import httpClient from "./httpClient";

const GRAFANA_MANAGEMENT = "/grafanas";
const getGrafanaByDeviceIdAPI = (id) => httpClient.get(`${GRAFANA_MANAGEMENT}/${id}`);
const getGrafanaList = (id, params = {}) => httpClient.get(`${GRAFANA_MANAGEMENT}/${id}`, params);
const getGrafanaDashboardByDeviceIdAPI = (id) => httpClient.get(`${GRAFANA_MANAGEMENT}/${id}/dashboard`);
const postCreateGrafanaByDeviceIdAPI = ({ id, payload }) => httpClient.post(`${GRAFANA_MANAGEMENT}/${id}`, payload);
const postCreateGrafanaNewTabAPI = (id, payload) => httpClient.post(`${GRAFANA_MANAGEMENT}/${id}/tab`, payload);
const deleteGrafanaTabAPI = (id, payload) => httpClient.delete(`${GRAFANA_MANAGEMENT}/${id}/tab`, payload);
const putUpdateGrafanaTabAPI = (id, payload) => httpClient.put(`${GRAFANA_MANAGEMENT}/standard-module/${id}`, payload);

export {
	getGrafanaList,
	getGrafanaByDeviceIdAPI,
	postCreateGrafanaByDeviceIdAPI,
	getGrafanaDashboardByDeviceIdAPI,
	postCreateGrafanaNewTabAPI,
	putUpdateGrafanaTabAPI,
	deleteGrafanaTabAPI
};
