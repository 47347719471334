/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */
export const COOKIES_NAME = Object.freeze({
	TOKEN: "jwt",
	NOTICE_POLICY_KEY: "privacy_embeds",
	NOTICE_POLICY_VALUE: "consent"
});
