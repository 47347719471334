import { getNoSignalConfigsApi, putNoSignalConfigsApi } from "@/services/api/noSignalConfig.api";
import {
	NO_SIGNAL_CONFIG_GET_REQUEST,
	NO_SIGNAL_CONFIG_GET_SUCCESS,
	NO_SIGNAL_CONFIG_GET_FAILURE,
	NO_SIGNAL_CONFIG_PUT_REQUEST,
	NO_SIGNAL_CONFIG_PUT_SUCCESS,
	NO_SIGNAL_CONFIG_PUT_FAILURE
} from "../../types";
import { parseNoSigalConfig } from "../../../services/api/transforms/parseNoSignalConfig";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		isUpdateLoading: false,
		isUpdateSuccess: null
	},

	getters: {
		getConfigs: (state) => {
			const transformKeys = {
				Tank: "TANK_IN_SECOND",
				Meter: "METER_IN_SECOND",
				Application: "APPLICATION_IN_SECOND",
				Energy: "ELECTRICITY_IN_SECOND"
			};

			return state.data.reduce((acc, item) => {
				const key = transformKeys[item.module];
				const second = item.second;
				acc[key] = second;
				return acc;
			}, {});
		},
		isUpdateConfigsSuccess: (state) => state.isUpdateSuccess
	},

	mutations: {
		[NO_SIGNAL_CONFIG_GET_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
		},
		[NO_SIGNAL_CONFIG_GET_SUCCESS](state, data) {
			state.data = parseNoSigalConfig(data);
			state.isLoading = false;
		},
		[NO_SIGNAL_CONFIG_GET_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		},
		[NO_SIGNAL_CONFIG_PUT_REQUEST](state) {
			state.isUpdateLoading = true;
			state.isUpdateSuccess = null;
		},
		[NO_SIGNAL_CONFIG_PUT_SUCCESS](state) {
			state.isUpdateLoading = false;
			state.isUpdateSuccess = true;
		},
		[NO_SIGNAL_CONFIG_PUT_FAILURE](state) {
			state.isUpdateLoading = false;
			state.isUpdateSuccess = false;
		}
	},

	actions: {
		async getNoSigalConfigs({ commit }) {
			try {
				commit(NO_SIGNAL_CONFIG_GET_REQUEST);
				const { data } = await getNoSignalConfigsApi();
				commit(NO_SIGNAL_CONFIG_GET_SUCCESS, data);
			} catch (error) {
				commit(NO_SIGNAL_CONFIG_GET_FAILURE);
			}
		},
		async updateNoSigalConfigs({ commit }, param = []) {
			try {
				commit(NO_SIGNAL_CONFIG_PUT_REQUEST);
				await putNoSignalConfigsApi(param);
				commit(NO_SIGNAL_CONFIG_PUT_SUCCESS);
			} catch (error) {
				commit(NO_SIGNAL_CONFIG_PUT_FAILURE);
			}
		}
	}
};
