<template>
	<CardLineChart
		:selectedDropdown="selectedTime"
		:chartData="chartData"
		:filterOptions="filterOptions"
		:lastUpdate="furnaceTemperature.lastUpdate"
		:isEmpty="furnaceTemperature.isEmpty"
		:isLoading="furnaceTemperature.isLoading"
		chartTitle="Furnace temperature profile"
		:timeDisplay="timeDisplay"
		class="card-line-chart"
		:isShowBackwardForward="Boolean(selectedTemperature)"
		:isDisableBackward="isDisableBackward"
		:isDisableForward="isDisableForward"
		@onSelectDatePicker="handleSelectDate"
		@onSelectDropDown="handleSelectDateDropDown"
		@backward="handleBackward"
		@forward="handleForward"

	/>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

import CardLineChart from "@/components/chart/CardLineChart.vue";
import { transformFurnaceTemperatureLineChartConfig } from "../../selectors/transform/lineChart/FurnaceInfo/furnaceTemperature";

export default {
	props: {
		data: {
			type: Object,
			default: () => ({})
		},
		furnaceTemperature: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			selectedDate: null,
			selectedTime: null
		};
	},

	components: {
		CardLineChart
	},

	computed: {
		...mapGetters("furnaceTemperature", {
			getFurnaceTemperatureChartListFromDate: "getFurnaceTemperatureChartListFromDate"
		}),
		chartData() {
			if (this.selectedTemperature) {
				return transformFurnaceTemperatureLineChartConfig(
					this.selectedTemperatureData.keys,
					this.selectedTemperatureData.values,
					this.selectedTemperature.timestamp
				);
			}

			return transformFurnaceTemperatureLineChartConfig(
				this.data.keys,
				this.data.values,
				this.furnaceTemperature.lastUpdate
			);
		},
		filterOptions() {
			return {
				date: {
					condition: "and",
					selected: {
						range: dayjs().$d
					},
					picker: {
						mode: "single",
						minDate: dayjs().subtract(7 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: this.dropdownTimes,
						placeholder: "Select time"
					}
				}
			};
		},

		selectedDateData() {
			return this.getFurnaceTemperatureChartListFromDate(this.selectedDate) || [];
		},

		selectedTemperature() {
			return	this.selectedDateData.find((temperature) => temperature.timestamp === this.selectedTime);
		},

		isDisableBackward() {
			const currentIndex = this.selectedDateData.findIndex((temperature) => temperature.timestamp === this.selectedTime);
			return currentIndex === 0;
		},

		isDisableForward() {
			const currentIndex = this.selectedDateData.findIndex((temperature) => temperature.timestamp === this.selectedTime);

			return currentIndex === this.selectedDateData.length - 1;
		},

		selectedTemperatureData() {
			if (!this.selectedTemperature) {
				return {
					keys: [],
					values: []
				};
			}

			return {
				keys: this.furnaceTemperature.keyNames,
				values: this.furnaceTemperature.keyOfData.map((key) => Number(this.selectedTemperature[key]) || 0)
			};
		},

		dropdownTimes() {
			return this.selectedDateData.map((temperature) => {
				return {
					label: dayjs(temperature.timestamp).format("HH:mm"),
					value: temperature.timestamp
				};
			});
		},

		timeDisplay() {
			if (this.selectedTemperature) {
				return dayjs(this.selectedTemperature.timestamp).format("HH:mm");
			}

			return this.furnaceTemperature.timeUpdate;
		}
	},

	methods: {
		handleBackward() {
			const currentIndex = this.selectedDateData.findIndex((temperature) => temperature.timestamp === this.selectedTime);

			this.selectedTime = this.selectedDateData[currentIndex - 1].timestamp;
		},

		handleForward() {
			const currentIndex = this.selectedDateData.findIndex((temperature) => temperature.timestamp === this.selectedTime);

			this.selectedTime = this.selectedDateData[currentIndex + 1].timestamp;
		},

		handleSelectDate(date) {
			const lastDate = this.selectedDate;
			this.selectedDate = date;
			if (!this.selectedTime || lastDate !== date) {
				this.selectedTime = this.dropdownTimes[this.dropdownTimes.length - 1]?.value;
			}
		},
		handleSelectDateDropDown(time) {
			this.selectedTime = time;
		}
	}
};
</script>