/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */
export const BIG_TANK_STATION = Object.freeze([
	{
		name: "BIG1",
		lat: 12.699166,
		lng: 101.137841
	},
	{
		name: "BIG2",
		lat: 12.705969,
		lng: 101.151037
	},
	{
		name: "BIG3",
		lat: 13.05631,
		lng: 101.08879
	}
]);
