var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('BaseLoading'):_c('div',{staticClass:"container-overall"},_vm._l((_vm.data),function(section,index){return _c('div',{key:index,staticClass:"container-overall-section"},[_c('div',{staticClass:"container-overall-section-inner"},[_c('div',{staticClass:"container-overall-section-inner-topic"},[_vm._v(" "+_vm._s(section.topic)+" ")]),_c('div',{staticClass:"container-overall-section-inner-info"},_vm._l((section.items),function(item,_index){return _c('div',{key:_index,staticClass:"container-overall-section-inner-info-item"},[_c('div',{staticClass:"container-overall-section-inner-info-item-icon",style:([{
							backgroundColor: item.icon.colorBg
						}])},[_c('div',{staticClass:"container-overall-section-inner-info-item-icon-wrapper"},[_c('div',{staticClass:"container-overall-section-inner-info-item-icon-wrapper-outer",style:([{
								width: item.icon.width,
								height: item.icon.height,
								top: item.icon.top,
								left: item.icon.left
							}])},[_c('FontAwesomeIcon',{attrs:{"icon":[item.icon.type, item.icon.name]}})],1)])]),_c('div',{staticClass:"container-overall-section-inner-info-item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"container-overall-section-inner-info-item-total"},[_c('div',{staticClass:"container-overall-section-inner-info-item-total-number"},[_vm._v(" "+_vm._s(_vm._f("friendlyNumber")(item.total.number))+" ")]),_c('div',{staticClass:"container-overall-section-inner-info-item-total-unit"},[_vm._v(" "+_vm._s(item.total.unit)+" ")])])])}),0)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }