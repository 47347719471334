import dayjs from "dayjs";
import { transformLineChart } from "..";
import { NM_UNIT } from "../../unit";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	axisXLabelFormatter,
	getForEachTwoHours,
	getForEachFourHours,
	getForEachSixHours,
	getForEachDay,
	getForEachThirtyMinute,
	getForEachHour,
	getForEachFiveDay
} from "../helper";
import { getConfigDataZoom } from "../helper/zoomConfig";
import { yAxisConfig } from "../helper/yAxisConfig";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Gas flow rate (${NM_UNIT}/hr)`;

const tooltipLineChartFormat = (name, value) => {
	const format = `
	<div>
		<div class="bar-name meter">
			${name}
		</div>
		<div class="bar-value">
			${value === null ? "-" : value} Nm<sup>3</sup>/hr
		</div>
	</div>`;
	return format;
};
const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipLineChartFormat(date, pointValue.data);
};

const optionLineChartXaxisPerDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachTwoHours
	},

	yAxisConfig,

	axisTick: {
		interval: getForEachThirtyMinute
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

const optionLineChartXaxisForThreeDay = ({ xAxis = [] }, isMobile) => ({
	yAxisText,
	axisLabel: {
		padding: [0, 0, 10, 0],
		formatter: axisXLabelFormatter,
		interval: getForEachFourHours,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,

	axisTick: {
		interval: isMobile ? getForEachSixHours : getForEachHour
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

const optionLineChartXaxisPerWeek = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	yAxisConfig,

	axisTick: {
		interval: getForEachSixHours
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

const optionLineChartMoreThanTwentyDay = ({ xAxis }) => ({
	yAxisText,

	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachFiveDay
	},

	yAxisConfig,

	axisTick: {
		interval: (index, date) => {
			const hour = dayjs(date).get("hour");
			const minute = dayjs(date).get("minute");
			return hour === 0 && minute === 0;
		}
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});


export const transformGasFlowRateLineChartConfig = (sortBy = perDay, data = [], option = {}, isMobile = false) => {
	const { diffDay } = option;
	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay(data, isMobile),
		[perThreeDay]: optionLineChartXaxisForThreeDay(data, isMobile),
		[perWeek]: optionLineChartXaxisPerWeek(data, isMobile)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionLineChartMoreThanTwentyDay(data);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}

	const functionTransform = mappingOption[sortBy] || optionLineChartXaxisPerWeek(data);
	const transformFunc = transformLineChart(functionTransform);
	return transformFunc(data);
};
