<template>
	<div class="container-condition">
		<div
			v-for="(item, index) in transformItems"
			:key="index"
			class="item"
		>
			<div class="label">
				{{ item.label }}
			</div>
			<div class="form">
				<div class="custom-input-field">
					<BaseDropdown
						:searchable="false"
						:inline="true"
						:value="item.field"
						:list="fieldOptions"
						:placeholder="`Select field`"
						:isError="item.validate.field.error"
						:errorMessage="item.validate.field.message"
						@input="handleUpdateInput($event, { key: 'field', id: item.id })"
					/>
					<BaseTooltip
						v-if="item.tooltip.show"
						width="554px"
						class="tooltip"
						align="left"
					>
						<template>
							<div class="head">
								<font-awesome-icon :icon="['far', 'info-circle']" class="icon-info" />
								<span>
									Predefined formula
								</span>
							</div>
						</template>
						<template #message>
							<div class="body">
								<strong>
									Formula:
								</strong>
								<span>
									{{ item.tooltip.message }}
								</span>
							</div>
						</template>
					</BaseTooltip>
				</div>
				<div class="custom-input-function">
					<BaseDropdown
						:searchable="false"
						:inline="true"
						:value="item.function"
						:list="functionOptions"
						:placeholder="`Select format`"
						:isError="item.validate.function.error"
						:errorMessage="item.validate.function.message"
						:disabled="item.disabledDropdownFunction"
						@input="handleUpdateInput($event, { key: 'function', id: item.id })"
					/>
				</div>
				<div class="custom-input-operator">
					<BaseDropdown
						:searchable="false"
						:inline="true"
						:value="item.operator"
						:list="item.operatorOptions"
						:placeholder="`Select condition`"
						:isError="item.validate.operator.error"
						:errorMessage="item.validate.operator.message"
						@input="handleUpdateInput($event, { key: 'operator', id: item.id })"
					/>
				</div>
				<div class="custom-input-value">
					<BaseFormInputNumber
						:value="item.value1"
						:inputOptions="['small']"
						:disabled="item.disabledTextfieldValue"
						:inline="true"
						:isError="item.validate.value1.error"
						:errorMessage="item.validate.value1.message"
						:placeholder="item.placeholder"
						:decimalScale="5"
						class="custom-input-value"
						@input="handleUpdateInput($event, { key: 'value1', id: item.id })"
					/>
				</div>
				<div v-if="item.operator === 'between'" class="custom-input-value2">
					<strong>-</strong>
					<BaseFormInputNumber
						:value="item.value2"
						:inputOptions="['small']"
						:disabled="item.disabledTextfieldValue"
						:inline="true"
						:isError="item.validate.value2.error"
						:errorMessage="item.validate.value2.message"
						:placeholder="item.placeholder"
						:decimalScale="5"
						class="custom-input-value"
						@input="handleUpdateInput($event, { key: 'value2', id: item.id })"
					/>
				</div>
			</div>
			<div class="action">
				<span
					v-if="item.removeable"
					class="remove-btn"
					@click="onClickRemoveItem(item)"
				>
					<font-awesome-icon :icon="['fas', 'minus-circle']" />
				</span>
			</div>
		</div>
		<div class="item action">
			<div class="label"></div>
			<div class="form">
				<span
					:class="['add-btn', { disabled: disabledAddBtn }]"
					@click="onClickAddItem"
				>
					<font-awesome-icon :icon="['fas', 'plus-circle']" />
					<span>
						Add new condition
					</span>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import BaseFormInputNumber from "@/components/BaseFormInputNumber.vue";
import BaseTooltip from "@/components/BaseTooltip.vue";

export default {
	components: {
		BaseFormInputNumber,
		BaseTooltip
	},

	props: {
		initialConditions: {
			type: Array,
			default: () => ([])
		},
		initialItemCount: {
			type: Number,
			default: 0
		},
		addableCondition: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			items: [],
			isInit: false
		};
	},

	computed: {
		selectedOperators() {
			return this.items.reduce((acc, cur) => {
				if (!cur.deletedAt && cur.operator) {
					if (!acc[cur.field]) {
						acc[cur.field] = [];
					}
					acc[cur.field].push(cur.operator);
				}
				return acc;
			}, {});
		},
		fieldOptions() {
			return this.addableCondition.fields
				.map((item) => ({
					label: item.displayName,
					value: item.field
				}));
		},
		functionOptions() {
			return this.addableCondition.functions
				.map((item) => ({
					label: item.displayName,
					value: item.value
				}));
		},
		operatorOptions() {
			return this.addableCondition.operators.map((item) => ({
				label: item.displayName,
				value: item.value
			}));
		},
		transformItems() {
			return this.items
				.filter((item) => (
					!item.deletedAt
				))
				.map((item, index) => {
					return {
						...item,
						label: !index ? "When" : "And",
						removeable: index > 0,
						disabledTextfieldValue: item.operator === "nodata",
						disabledDropdownFunction: (() => {
							if (this.isPredefined(item)) {
								return true;
							}
							return false;
						})(),
						tooltip: {
							show: (() => {
								if (this.isPredefined(item)) {
									return true;
								}
								return false;
							})(),
							message: (() => {
								if (this.isPredefined(item)) {
									return this.getPredefinedText(item);
								}
								return null;
							})()
						},
						placeholder: item.operator === "nodata" ? "" : "Value",
						validate: {
							field: {
								error: item.error && !item.field,
								message: "Required"
							},
							function: {
								error: item.error && !item.function,
								message: "Required"
							},
							operator: {
								error: item.error && !item.operator,
								message: "Required"
							},
							value1: {
								error: item.error && !item.value1,
								message: "Required"
							},
							value2: {
								error: item.error && !item.value2,
								message: "Required"
							}
						},
						operatorOptions: (() => {
							const selected = (() => {
								if (item.field) {
									return this.selectedOperators[item.field] || [];
								}
								return [];
							})();
							const selectedDefault = (() => {
								if (item.operator) {
									return [
										{
											label: this.addableCondition.operators.find((_item) => _item.value === item.operator).displayName,
											value: item.operator
										}
									];
								}
								return [];
							})();
							const options = this.operatorOptions.filter((_item) => (
								!selected.includes(_item.value)
							));
							return [
								...selectedDefault,
								...options
							];
						})()
					};
				});
		},
		valid() {
			return this.items
				.filter((item) => (
					!item.deletedAt
				))
				.every((item) => (
					this.isValidForm(item)
				));
		},
		disabledAddBtn() {
			return !this.valid;
		}
	},

	watch: {
		items: {
			handler() {
				this.$emit("update:items", this.items);
				this.$emit("update:valid", this.valid);
			},
			deep: true
		},
		initialConditions: {
			handler() {
				if (!this.isInit) {
					this.items = this.initialConditions.map((item) => ({
						...item,
						id: uuidv4(),
						deletedAt: null,
						error: false
					}));
					this.isInit = true;
				}
			},
			deep: true
		}
	},

	mounted() {
		for (let i = 0; i < this.initialItemCount; i++) {
			this.addItem();
		}
	},

	methods: {
		getItemIndexById(id) {
			return this.items.findIndex((item) => item.id === id);
		},
		getItem(id, { key }) {
			const index = this.getItemIndexById(id);
			const found = index > -1;
			if (found) {
				return this[key][index];
			}
			return null;
		},
		isValidForm(item) {
			if (!item.field) {
				return false;
			}
			if (!item.function && !this.isPredefined(item)) {
				return false;
			}
			if (!item.operator) {
				return false;
			}
			if (item.operator === "between") {
				if (!item.value1) {
					return false;
				}
				if (!item.value2) {
					return false;
				}
			} else if (item.operator === "nodata") {
				if (item.value1) {
					return false;
				}
			} else if (!item.value1) {
				return false;
			}
			return true;
		},
		addItem() {
			this.items.push({
				id: uuidv4(),
				field: null,
				function: null,
				operator: null,
				value1: null,
				value2: null,
				deletedAt: null,
				error: false
			});
		},
		removeItem(id) {
			const index = this.getItemIndexById(id);
			const found = index > -1;
			if (found) {
				this.items[index].deletedAt = new Date();
			}
		},
		onClickAddItem() {
			if (this.valid) {
				this.addItem();
			}
		},
		onClickRemoveItem(item) {
			this.removeItem(item.id);
		},
		handleUpdateInput(event, { key, id }) {
			const index = this.getItemIndexById(id);
			const found = index > -1;
			if (found) {
				this.items[index][key] = event;
				if (key === "operator") {
					this.items[index].value1 = null;
					this.items[index].value2 = null;
				}
				if (key === "field") {
					Object.keys(this.selectedOperators).forEach((field) => {
						if (this.items[index].field === field) {
							const isDup = this.selectedOperators[field]
								.filter((operator) => (
									operator === this.items[index].operator
								)).length >= 2;
							if (isDup) {
								this.items[index].operator = null;
							}
						}
					});
					if (this.isPredefined(this.items[index])) {
						this.items[index].function = null;
					}
				}
			}
		},
		isPredefined(item) {
			if (item.field) {
				const fieldAPI = this.addableCondition.fields
					.find((fieldItem) => (
						fieldItem.field === item.field
					));
				if (fieldAPI.predefined) {
					return true;
				}
			}
			return false;
		},
		getPredefinedText(item) {
			if (item.field) {
				const fieldAPI = this.addableCondition.fields
					.find((fieldItem) => (
						fieldItem.field === item.field
					));
				if (fieldAPI.predefined) {
					return fieldAPI.predefined;
				}
			}
			return false;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-condition {
	margin-top: rem(36);

	.item {
		margin-bottom: rem(24);
		display: flex;

		&:last-of-type {
			margin-bottom: 0;
		}

		.label {
			display: flex;
			align-items: center;
			min-width: rem(130);
		}

		.form {
			display: flex;

			/* stylelint-disable */
			&::v-deep > div {
				margin-right: rem(8);
			}
			/* stylelint-enable */

			.custom-input-field {
				width: rem(200);
			}

			.custom-input-function {
				width: rem(150);
			}

			.custom-input-operator {
				width: rem(170);
			}

			.custom-input-value {
				width: rem(85);
			}

			.custom-input-value2 {
				display: flex;
				justify-content: space-around;
				align-items: baseline;
				margin-left: rem(-4);

				strong {
					margin-right: rem(4);
				}
			}
		}

		.action {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.remove-btn {
				color: $color-dark-blue-grey;
				cursor: pointer;
			}
		}

		.add-btn {
			display: flex;
			justify-items: center;
			color: $color-cerulean;
			cursor: pointer;

			svg {
				position: relative;
				top: rem(-1);
				height: auto;
				font-size: $font-14;
			}

			span {
				font-size: $font-18;
				font-weight: $font-weight-bold;
				margin-left: rem(4);
			}

			&.disabled {
				color: $color-light-teal;
				cursor: not-allowed;
			}
		}
	}
}

.tooltip {
	position: relative;
	display: flex;

	.head {
		cursor: default;

		.icon-info {
			width: rem(12);
			height: rem(13);
			color: $color-cerulean;
		}

		span {
			color: $color-dark-blue-grey;
			font-size: $font-16;
		}
	}

	.body {
		display: flex;
		flex-direction: column;

		strong {
			font-weight: $font-weight-bold;
		}

		span {
			color: $color-grey-4;
		}
	}
}
</style>