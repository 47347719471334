<template>
	<div v-if="!getDevicesNoSignalLoading" class="container-nosignal">
		<div class="action-bar">
			<BaseSortBy
				v-model="signalSortOptions.defaultAtIndex"
				:options="signalSortOptions"
				@change="handleChangeSortBy"
			/>
			<a class="btn-link" @click="showSetInactiveTimeModal">
				<font-awesome-icon :icon="['far', 'clock']" />Setup inactive time
			</a>
		</div>

		<div class="row devices" v-if="getDevicesNoSignalLists.length > 0">
			<Device v-for="(item, index) in getDevicesNoSignalLists" :key="index" :item="item" />
		</div>

		<EmptyDevices
			v-if="isShowAllGoodCondition"
			:message="getSearchResultMessage"
		/>

		<BaseNoResult
			v-if="!isShowAllGoodCondition && getDevicesNoSignalLists.length === 0"
			description="Try adjusting your search by changing your ship-to name or key, and removing filters."
		/>

		<ModalSetupTime :modal-name="MODAL_NAME_SETUP_TIME" @onSubmit="handleSubmitSetupTime" />
	</div>
	<div v-else>
		<BaseLoading />
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseSortBy from "@/components/BaseSortBy.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import ModalSetupTime from "@/components/monitor/deviceHealth/ModalSetupTime.vue";
import Device from "@/components/monitor/deviceHealth/Device.vue";
import EmptyDevices from "@/components/monitor/deviceHealth/EmptyDevices.vue";
import { cleanParams } from "../../../selectors/helpers";
import { TOAST_TYPES } from "../../../enums/toast";

export default {
	name: "NoSignal",

	components: {
		BaseSortBy,
		ModalSetupTime,
		Device,
		BaseLoading,
		BaseNoResult,
		EmptyDevices
	},

	data() {
		return {
			query: {
				module: this.$route.query.moduleName || null,
				sort: this.$route.query.sort || null,
				keyword: this.$route.query.keyword || null,
				productname: this.$route.query.productName || null
			},
			MODAL_NAME_SETUP_TIME: "modal-setup-time",
			signalSortOptions: {
				title: "Last signal sent",
				dropdown: [
					{
						index: 0,
						code: "receivedAt:desc",
						text: "Latest"
					},
					{
						index: 1,
						code: "receivedAt:asc",
						text: "Earliest"
					}
				],
				style: {
					position: "left",
					width: "150px"
				},
				defaultAtIndex: 0
			}
		};
	},
	created() {
		this.fetchData();
	},
	computed: {
		...mapGetters("devicesNoSignal", {
			getDevicesNoSignalLists: "getDevicesNoSignalLists",
			getDevicesNoSignalLoading: "getDevicesNoSignalLoading"
		}),
		...mapGetters("noSignalConfig", {
			getConfigs: "getConfigs",
			isUpdateConfigsSuccess: "isUpdateConfigsSuccess"
		}),
		isShowAllGoodCondition() {
			if (
				!this.query.module &&
				!this.query.keyword &&
				!this.query.productname &&
				this.getDevicesNoSignalLists.length === 0
			) {
				return true;
			}
			return false;
		},
		getSearchResultMessage() {
			return "All devices are in good condition";
		}
	},
	methods: {
		...mapActions("devicesNoSignal", {
			getDevicesNoSignal: "getDevicesNoSignal"
		}),
		...mapActions({
			getNoSigalConfigs: "noSignalConfig/getNoSigalConfigs",
			updateNoSigalConfigs: "noSignalConfig/updateNoSigalConfigs",
			showToast: "toast/show"
		}),
		async fetchData() {
			await this.getDevicesNoSignal({
				...this.query,
				q: this.query.keyword
			});

			const sortQuery = this.$route.query.sort;

			if (sortQuery) {
				this.signalSortOptions.defaultAtIndex = this.signalSortOptions.dropdown.find(({ code }) => sortQuery === code).index;
			}
		},
		handleChangeSortBy(item) {
			this.query.sort = item.code;
			this.handleChangeQuery();
		},
		async handleSubmitSetupTime(form) {
			const transformKeys = {
				TANK_IN_SECOND: "Tank",
				METER_IN_SECOND: "Meter",
				APPLICATION_IN_SECOND: "Application",
				ELECTRICITY_IN_SECOND: "Energy"
			};

			const dataSet = Object.keys(form).map((key) => {
				const moduleName = transformKeys[key];
				const TIME_IN_SECOND = form[key];
				return {
					module: moduleName,
					threshold: TIME_IN_SECOND
				};
			});

			await this.updateNoSigalConfigs(dataSet);

			if (this.isUpdateConfigsSuccess) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Successfully updated inactive time."
					}
				});

				this.hideModal(this.MODAL_NAME_SETUP_TIME);

				this.fetchData();
			}
		},
		hideModal(modalName) {
			this.$modal.hide(modalName);
		},
		async showSetInactiveTimeModal() {
			await this.getNoSigalConfigs();
			this.$modal.show(this.MODAL_NAME_SETUP_TIME, { ...this.getConfigs });
		},
		handleChangeQuery() {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.$route.query,
				...this.query
			})));

			this.$router.push({ query }).catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
.action-bar {
	display: flex;
	justify-content: space-between;
}

.devices {
	margin-top: rem(25);
}
</style>