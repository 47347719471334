
export const transformPieChart = (option = {}) => (data) => {
	const defaultLegend = {
		show: false
	};
	const defaultGrid = {
		left: "24px",
		right: "24px",
		bottom: "0",
		containLabel: true
	};
	const { series = {}, legend = defaultLegend, grid = defaultGrid } = option;
	const { yAxis = [] } = data;
	return {
		legend: {
			...legend
		},
		tooltip: {
			show: true,
			trigger: "item",
			backgroundColor: "#103332",
			axisPointer: {
				crossStyle: {
					color: "red"
				}
			},
			padding: 8,
			...(series.tooltip ?? {})
		},
		grid,
		series: yAxis
	};
};
