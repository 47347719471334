<template>
	<div class="container-info">
		<div class="container-info-title">
			<slot name="title">{{ title }}</slot>
		</div>
		<div class="container-info-list row">
			<div
				v-for="(item, index) in list"
				:key="index"
				:class="['container-info-list-item col-md-12 col-xl-6', { active: item.active }]"
			>
				<span class="container-info-list-item-icon">
					<font-awesome-icon :icon="['fas', 'check-circle']" />
				</span>
				<span class="container-info-list-item-text">
					{{ item.text }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseInfoList",

	props: {
		title: {
			type: String,
			default: ""
		},

		list: {
			type: Array,
			default: () => ([]),
			validator: (list) => {
				const containKeys = ["text", "active"];
				return list.every((item) => {
					return containKeys.every((key) => {
						return Object.keys(item).includes(key);
					});
				});
			}
		}
	},

	watch: {
		list: {
			handler() {
				const allActive = this.list.every((item) => item.active);
				this.$emit("update:valid", allActive);
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
.container-info {
	padding: $spacing-6;
	background-color: $color-ice-two;
	font-size: $font-16;
	border-radius: rem(4);

	&-title {
		margin-bottom: rem(16);
		color: $color-dark-blue-grey;
		font-weight: normal;
	}

	&-list {
		&-item {
			display: flex;
			align-items: center;
			color: $color-grey-4;
			font-weight: $font-weight-bold;
			min-width: rem(160);

			&.active {
				color: $color-green-haze;
			}

			&.active > &-icon {
				color: $color-green-haze;
			}

			&-icon {
				display: inline-flex;
				width: rem(11);
				height: rem(10);
				margin-right: rem(10);
				color: $color-light-teal;

				svg {
					width: 100%;
					height: 100%;
					position: relative;
					top: rem(-1);
				}
			}
		}
	}
}
</style>