/**
 * Parse No Signal Config
 * @param {Array} input no signal configs
 * @returns {NoSignalConfig}
 */
export function parseNoSigalConfig(input) {
	if (!input) {
		return [];
	}

	return input.map((item) => ({
		module: item.module,
		second: item.threshold
	}));
}