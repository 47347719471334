<template>
	<div class="container">
		<BaseLoading
			v-if="isLoading"
		/>
		<div
			v-else
			:class="['row', ...wrapperClass]"
		>
			<EmptyOrderHistory
				v-if="isEmpty"
				class="empty"
			>
				<template v-slot:head>No last month history</template>
				<template v-slot:body>Your  last month history will appear here once there is a record.</template>
			</EmptyOrderHistory>
			<LastMonth
				v-else
				:date="date"
				:beginning="beginning"
				:ending="ending"
				:netOutlet="netOutlet"
				subTitle="Meter - outlet water"
				describe="This is the summary of recycle water usage from last month. Please use this as reference."
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import LastMonth from "@/components/dashboard/modules/Zld/LastMonth.vue";

export default {
	name: "ZldLastMonth",

	components: {
		BaseLoading,
		EmptyOrderHistory,
		LastMonth
	},

	data() {
		return {
			zldId: this.$route.params.id
		};
	},

	computed: {
		...mapState(["zldLastMonth"]),

		...mapGetters("zldLastMonth", {
			isEmpty: "isEmpty"
		}),

		wrapperClass() {
			return { "hide-content": this.isLoading };
		},

		isLoading() {
			return this.zldLastMonth.isLoading;
		},

		date() {
			return this.zldLastMonth.data.date;
		},

		beginning() {
			return this.zldLastMonth.data.beginning;
		},

		ending() {
			return this.zldLastMonth.data.ending;
		},

		netOutlet() {
			return this.zldLastMonth.data.netOutlet;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogLastMonthView({
			shopToId: this.zldId,
			httpStatus: this.zldLastMonth.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("zldLastMonth", {
			getZldLastMonth: "getZldLastMonth",
			clearZldLastMonth: "clearZldLastMonth"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogLastMonthView: "addActivityLogLastMonthView"
		}),
		fetchData() {
			return this.getZldLastMonth({ id: this.zldId });
		}
	},

	destroyed() {
		this.clearZldLastMonth();
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}
</style>
