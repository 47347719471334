import { getMonitorMostActiveUserAPI } from "@/services/api/monitor.api";
import {
	USER_MONITOR_MOST_ACTIVE_REQUEST,
	USER_MONITOR_MOST_ACTIVE_SUCCESS,
	USER_MONITOR_MOST_ACTIVE_FAILURE
} from "../../types";
import { parseMostActiveUser } from "../../../services/api/transforms/parseUserMonitor";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false
	},

	mutations: {
		[USER_MONITOR_MOST_ACTIVE_REQUEST](state) {
			state.isLoading = true;
			state.data = {};
		},
		[USER_MONITOR_MOST_ACTIVE_SUCCESS](state, data) {
			state.data = parseMostActiveUser(data);
			state.isLoading = false;
		},
		[USER_MONITOR_MOST_ACTIVE_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		async getMostActiveUser({ commit }, { filter }) {
			try {
				commit(USER_MONITOR_MOST_ACTIVE_REQUEST);
				const { data } = await getMonitorMostActiveUserAPI({ filter });
				commit(USER_MONITOR_MOST_ACTIVE_SUCCESS, data);
			} catch (error) {
				commit(USER_MONITOR_MOST_ACTIVE_FAILURE);
			}
		}
	}
};
