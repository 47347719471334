import {
	TOAST_SHOW,
	TOAST_HIDE
} from "../../types";

export default {
	namespaced: true,

	state: {
		isShow: false,
		type: "",
		props: {}
	},

	mutations: {
		[TOAST_SHOW](state, { type = "", props = {} }) {
			state.isShow = true;
			state.type = type;
			state.props = props;
		},
		[TOAST_HIDE](state) {
			state.isShow = false;
		}
	},

	actions: {
		show({ commit }, options = {
		}) {
			const {
				props: {
					callback = false
				}
			} = options;
			commit(TOAST_SHOW, options);

			const { delay = 3800 } = options;

			setTimeout(() => {
				commit(TOAST_HIDE);
				if (callback) {
					callback();
				}
			}, delay);
		},

		hide({ commit }) {
			commit(TOAST_HIDE);
		}
	}
};
