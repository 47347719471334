<template>
	<div class="container-tab">
		<div
			v-for="(item, index) in items"
			:key="index"
			:class="['container-tab-item', { active: activeAtIndex === index }]"
			@click="onClickTabItem(index)"
		>
			<div v-if="item.icon" class="container-tab-item-icon">
				<div
					v-if="item.icon"
					:style="[
						{ width: item.icon.width || 0 },
						{ height: item.icon.height || 0 }
					]"
					class="container-tab-item-icon-wrapper"
				>
					<font-awesome-icon :icon="[item.icon.type, item.icon.name]" />
				</div>
			</div>
			<div class="container-tab-item-title">
				{{ item.title }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseTabPanel",

	props: {
		value: {
			type: Number,
			default: 0
		},
		items: {
			type: Array,
			default: () => []
		}
	},

	data() {
		return {
			activeAtIndex: 0
		};
	},

	watch: {
		activeAtIndex(newValue) {
			this.$emit("input", newValue);
		},
		// when pearent change the `v-model`, we have also to change `activeAtIndex`.
		value(newValue) {
			this.activeAtIndex = newValue;
		}
	},

	created() {
		this.activeAtIndex = this.value;
	},

	methods: {
		onClickTabItem(index) {
			this.activeAtIndex = index;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-tab {
	border-bottom: solid 1px $color-silver;
	display: flex;
	padding: 0 rem(30);

	&-item {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: rem(128);
		padding: 0 rem(24) rem(2) rem(24);
		color: $color-grey-4;
		cursor: pointer;

		&.active {
			color: $color-green-haze;

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: rem(3);
				background-color: $color-green-haze;
				border-radius: rem(3);
			}
		}

		&-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(16);
			height: rem(16);
			margin-right: rem(4);

			&-wrapper {
				display: flex;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		&-title {
			@include ellipsis(1);
			@include fontSub();

			font-size: $font-20;
			font-weight: $font-weight-bold;
		}
	}
}
</style>