<template>
	<div
		:class="['container-menu', { collapse: !sidebar.isSidebarOpen && !sidebar.isUITabletSidebarOpen }]"
		v-click-outside="onClickOutsideDropdown"
	>
		<ul class="container-menu-list">
			<li
				v-for="(item, index) in filterMenus"
				:key="index"
				:class="['container-menu-item', { active: item.active }, { nested: item.isNestedMenus }, { 'dropdown-shown': dropdownActivedAt === index }]"
				ref="menu"
				@click="toggleMenu(index)"
			>
				<VMenu
					v-if="item.isNestedMenus"
					:triggers="['click', 'hover']"
					placement='right'
					:delay="200"
				>
					<div
						class="container-menu-item-nested"
						@click="toggleDropdown(index)"
					>
						<div class="container-menu-item-nested-head">
							<span class="icon">
								<span class="icon-outer" :style="[
									{
										width: item.iconSize.width,
										height: item.iconSize.height
									}
								]">
									<ListCheckIcon v-if="item.icon === CONFIG.ICON_NAME.LIST_CHECK_ICON"></ListCheckIcon>
									<FontAwesomeIcon v-else :icon="['far', item.icon]" />
								</span>
							</span>
							<span class="title" v-if="item.isShowTitle">
								{{ item.text }}
							</span>
							<span class="arrow" v-if="sidebar.isSidebarOpen">
								<FontAwesomeIcon :icon="['far', 'chevron-circle-up']" />
							</span>
						</div>
						<template v-if="sidebar.isSidebarOpen || sidebar.isUITabletSidebarOpen">
							<div
								v-for="(submenu, indexSubMenu) in item.children"
								:key="indexSubMenu"
								class="container-menu-item-normal"
							>
								<router-link
									:to="{ name: submenu.name }"
									:class="['container-menu-item-link', { disabled: submenu.isDisabled }, { active: submenu.active }]"
									@click.native="closeUITabletSidebar"
								>
									<span class="icon">
										<span  class="icon-outer" :style="[
											{
												width: submenu.iconSize.width,
												height: submenu.iconSize.height
											}
										]">
											<HandHoldingHeartIcon v-if="submenu.icon === CONFIG.ICON_NAME.HAND_HOLDING_HEART_ICON"></HandHoldingHeartIcon>
											<ImageLandscapeIcon v-else-if="submenu.icon === CONFIG.ICON_NAME.IMAGE_LANDSPACE_ICON"></ImageLandscapeIcon>
											<HandHoldingBoxIcon v-else-if="submenu.icon === CONFIG.ICON_NAME.HAND_HOLDING_BOX_ICON"></HandHoldingBoxIcon>
											<TableLayoutIcon v-else-if="submenu.icon === CONFIG.ICON_NAME.TABLE_LAYOUT_ICON"></TableLayoutIcon>
											<FontAwesomeIcon v-else :icon="['far', submenu.icon]" />
										</span>
									</span>
									<span class="title">
										{{ submenu.text }}
									</span>
								</router-link>
							</div>
						</template>
					</div>
					<template v-if="!sidebar.isSidebarOpen && !sidebar.isUITabletSidebarOpen && !isMobile" #popper>
						<div class="container-menu-item-nested-dropdown-list">
							<div class="container-menu-item-title" v-if="item.isShowTitle">
								{{ item.text }}
							</div>
							<div
								v-for="(submenu, indexSubMenu) in item.children"
								:key="indexSubMenu"
								class="container-menu-item-normal"
							>
								<router-link
									:to="{ name: submenu.name }"
									:class="['container-menu-item-link', { disabled: submenu.isDisabled }, { active: submenu.active }]"
								>
									<span
										class="icon"
									>
										<span class="icon-outer" :style="[
											{
												width: submenu.iconSize.width,
												height: submenu.iconSize.height
											}
										]">
											<HandHoldingHeartIcon v-if="submenu.icon === CONFIG.ICON_NAME.HAND_HOLDING_HEART_ICON"></HandHoldingHeartIcon>
											<ImageLandscapeIcon v-else-if="submenu.icon === CONFIG.ICON_NAME.IMAGE_LANDSPACE_ICON"></ImageLandscapeIcon>
											<HandHoldingBoxIcon v-else-if="submenu.icon === CONFIG.ICON_NAME.HAND_HOLDING_BOX_ICON"></HandHoldingBoxIcon>
											<TableLayoutIcon v-else-if="submenu.icon === CONFIG.ICON_NAME.TABLE_LAYOUT_ICON"></TableLayoutIcon>
											<FontAwesomeIcon :icon="['far', submenu.icon]" />
										</span>
									</span>
									<span class="title">
										{{ submenu.text }}
									</span>
								</router-link>
							</div>
						</div>
					</template>
				</VMenu>
				<VMenu
					v-else
					:triggers="['click', 'hover']"
					placement='right'
					:delay="200"
				>
					<div
						class="container-menu-item-normal"
					>
						<router-link
							:to="{ name: item.name,
								query: {
									...(item.query ? item.query : {})
								}
							}"
							:class="['container-menu-item-link', { disabled: item.isDisabled }, { active: item.active }]"
							@click.native="closeUITabletSidebar"
						>
							<span class="icon">
								<span class="icon-outer" :style="[
									{
										width: item.iconSize.width,
										height: item.iconSize.height
									}
								]">
									<ChartSimpleIcon v-if="item.icon === CONFIG.ICON_NAME.CHART_SIMPLE_ICON"></ChartSimpleIcon>
									<StoreIcon v-else-if="item.icon === CONFIG.ICON_NAME.STORE_ICON"></StoreIcon>
									<FontAwesomeIcon v-else :icon="['far', item.icon]" />
								</span>
							</span>
							<span class="title" v-if="item.isShowTitle">
								{{ item.text }}
							</span>
						</router-link>
					</div>
					<template v-if="!sidebar.isSidebarOpen && !sidebar.isUITabletSidebarOpen && !isMobile" #popper>
						<div class="container-menu-item-nested-dropdown-list">
							<router-link
								:to="{ name: item.name,
									query: {
										...(item.query ? item.query : {})
									}
								}"
								:class="['container-menu-item-normal-link', { disabled: item.isDisabled }]"
							>
								<div class="container-menu-item-normal-title" v-if="item.isShowTitle">
									{{ item.text }}
								</div>
							</router-link>
						</div>
					</template>
				</VMenu>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SIDE_NAV } from "../enums/sideNavs";
import userPermission from "../mixins/userPermission";
import { getMenus, getMenuActiveAt } from "../selectors/helpers/sidebar";
import { getCompaniesPowerBIExecutiveAPI } from "../services/api/companies";
import ChartSimpleIcon from "../components/icon/ChartSimpleIcon.vue";
import HandHoldingBoxIcon from "../components/icon/HandHoldingBoxIcon.vue";
import ImageLandscapeIcon from "../components/icon/ImageLandscapeIcon.vue";
import HandHoldingHeartIcon from "../components/icon/HandHoldingHeartIcon.vue";
import ListCheckIcon from "../components/icon/ListCheckIcon.vue";
import StoreIcon from "../components/icon/StoreIcon.vue";
import TableLayoutIcon from "../components/icon/TableLayoutIcon.vue";
import { CONFIG } from "../enums/config";

export default {
	name: "TheMainSidebarMenu",

	components: {
		ChartSimpleIcon,
		HandHoldingBoxIcon,
		HandHoldingHeartIcon,
		ImageLandscapeIcon,
		ListCheckIcon,
		StoreIcon,
		TableLayoutIcon

	},

	mixins: [userPermission],

	data() {
		return {
			CONFIG,
			SIDE_NAV,
			// null is blank or not initial yet
			// -1 = inactive
			// > 0 = active at index
			menuActivedAt: null,
			dropdownActivedAt: null,
			isVisibleExecutiveReportMenu: false,
			isMobile: false,
			isPad: false
		};
	},

	async created() {
		await this.fetchCompanies();
	},

	computed: {
		...mapState(["user", "sidebar"]),

		filterMenus() {
			const options = {
				isFold: this.isFold,
				user: this.user,
				permissions: this.permissions,
				isVisibleExecutiveReportMenu: this.isVisibleExecutiveReportMenu
			};
			return getMenus(this.menuActivedAt, this.$route.name, options);
		},

		// this function check sidebar on left side of the page was opened or folded
		// if folded, hide only menu text title but the icon still away.
		isFold() {
			const tableAccountOpened = this.sidebar.isUITabletAccountOpen;
			const tabletSidebarOpened = this.sidebar.isUITabletSidebarOpen;
			const desktopSidebarOpened = this.sidebar.isSidebarOpen;
			return !desktopSidebarOpened && (tableAccountOpened && tabletSidebarOpened);
		}
	},

	mounted() {
		this.menuActivedAt = getMenuActiveAt(this.filterMenus, this.$route.name);
		this.dropdownActivedAt = -1;
	},

	methods: {
		...mapActions({
			toggleUITabletSidebar: "sidebar/toggleUITabletSidebar",
			closeUITabletSidebar: "sidebar/closeUITabletSidebar"
		}),

		async fetchCompanies() {
			const companies = await getCompaniesPowerBIExecutiveAPI();
			this.isVisibleExecutiveReportMenu = companies.data.isShow ?? false;
		},

		toggleMenu(index) {
			// if sidebar is open then toggle menu
			if (this.sidebar.isSidebarOpen) {
				this.menuActivedAt = this.menuActivedAt === index
					? -1
					: index;
			} else {
				// if not always active on that index
				this.menuActivedAt = index;
			}

			// close dropdown when click another menu
			if (this.menuActivedAt !== this.dropdownActivedAt) {
				this.dropdownActivedAt = -1;
			}
		},

		toggleDropdown(index) {
			// toggle dropdown
			this.dropdownActivedAt = this.dropdownActivedAt === index
				? -1
				: index;
		},

		onClickOutsideDropdown() {
			// close dropdown when clicked outside of sidebar
			this.dropdownActivedAt = -1;
		},

		checkDevice() {
			// >= 1200 = desktop
			if (window.matchMedia("(min-width: 959px)").matches) {
				this.isPad = true;
			}

			this.isMobile = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-menu {
	position: relative;
	z-index: 4;
	background-color: $sidebar-bg-color;

	&.collapse {
		.container-menu-item {
			&.active {
				.container-menu-item-nested-head {
					background-color: $color-ice;
				}
			}

			&-nested {
				&::before {
					background: none !important;
				}

				&-head {
					justify-content: center;
					border-radius: 4px;

					.icon {
						margin-right: 0;
					}
				}
			}

			&-normal {
				padding-left: 0;
			}
		}
	}

	&-list {
		display: flex;
		flex-direction: column;
		padding: 0 rem(16);
	}

	&-item {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: rem(8);

		&.active {
			.container-menu-item {
				&-normal {
					display: inherit;
				}

				&-nested {
					&-head {
						color: $color-green-haze;

						.arrow {
							transform: translateY(-50%) rotate(0deg);
						}
					}

					.container-menu-item-normal {
						padding-left: rem(16);
					}
				}
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&-normal, &-nested {
			display: flex;
			align-items: center;
			width: 100%;
		}

		&-nested {
			position: relative;
			flex-direction: column;

			.container-menu-item-normal {
				display: none;
				margin-bottom: rem(4);

				&:last-of-type {
					margin-bottom: rem(10);
				}
			}

			&-head {
				position: relative;
				display: flex;
				width: 100%;
				align-items: center;
				flex: 1;
				max-height: rem(46);
				padding: rem(12);
				color: $color-grey-4;
				font-weight: $font-weight-bold;
				cursor: pointer;

				.title {
					@include ellipsis(1);

					position: relative;
					top: rem(1);
				}

				.arrow {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					width: rem(24);
					height: rem(24);
					top: 50%;
					right: 0;
					transform: translateY(-50%) rotate(180deg);
					transition: all 100ms ease-in;
					color: $color-gunmetal-two;
				}
			}

			&-dropdown-list {
				width: rem(182);
				padding: rem(8) 0;
				background-color: $color-white;
				border-radius: 4px;
				box-shadow: 0 rem(2) rem(8) 0 rgba(16, 51, 50, 0.2);
				z-index: 4;
				display: block;

				.container-menu-item-normal {
					padding-left: 0 !important;
					margin-bottom: 0;

					&:last-of-type {
						margin-bottom: 0;
					}

					.container-menu-item-link {
						padding: rem(2) rem(8);
						font-size: $font-16;

						&.active, &:hover {
							background: none;
						}
					}
				}
			}
		}
	}
}

.container-menu-item-title {
	padding: 0 rem(12);
	font-weight: $font-weight-bold;
	border-bottom: 1px solid $color-silver;
}

.container-menu-item-normal-title {
	font-weight: $font-weight-bold;
	color: $color-black;
}

.container-menu-item-link {
	display: flex;
	align-items: center;
	width: 100%;
	max-height: rem(48);
	padding: rem(12);
	color: $color-grey-4;
	font-weight: $font-weight-bold;
	z-index: 1;

	&.active, &:hover {
		background-color: $sidebar-menu-hover-bg-color;
		border-radius: rem(4);
		color: $color-green-haze;
	}

	&.disabled {
		color: $color-light-teal;
		pointer-events: none;
	}

	.title {
		@include ellipsis(1);

		position: relative;
		top: rem(1);
	}
}

.container-menu-item-normal-link {
	display: flex;
	align-items: center;
	width: 100%;
	max-height: rem(48);
	padding: 0 rem(12);
	color: $color-grey-4;
	font-weight: $font-weight-bold;
	z-index: 1;

	&.disabled {
		color: $color-light-teal;
		pointer-events: none;
	}
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: rem(24);
	min-height: rem(24);
	margin-right: rem(12);

	&-outer {
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 100%;
			height: 100%;
			fill: $color-grey-4;
		}
	}
}



.v-popper.v-popper--theme-menu.v-popper--theme-dropdown {
	width: 100%;
}
</style>
