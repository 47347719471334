<template>
	<BaseTableCustom
		:tableOptions="table.options"
		:pagination="pagination"
		:loading="loading"
		@onPageChange="handlePageChange"
	>
		<template v-slot:thead>
			<tr>
				<th
					v-for="(value, index) in table.thead"
					:key="index"
				>
					{{ value }}
				</th>
			</tr>
		</template>
		<template v-slot:tbody>
			<tr
				v-for="(item, index) in table.tbody"
				:key="index"
			>
				<td>
					{{ item.shipTo.name }}
				</td>
				<td>
					{{ item.shipTo.code }}
				</td>
				<td>
					{{ item.shipTo.locationKey || item.shipTo.key }}
				</td>
				<td class="align-right">
					<router-link
						:to="{
							name: PAGE_NAME.FLOWDIAGRAM_EDIT,
							query: {
								id: item.shipTo.id,
							}
						}"
					>
						<button class="view-button">
							View diagram
						</button>
					</router-link>
				</td>
			</tr>
		</template>
	</BaseTableCustom>
</template>

<script>
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		items: {
			type: Array,
			default: () => ([])
		},
		pagination: {
			type: Object,
			default: () => ({})
		}
	},

	components: {
		BaseTableCustom
	},

	data() {
		return {
			PAGE_NAME
		};
	},

	computed: {
		table() {
			return {
				options: [
					"is-striped is-fullwidth"
				],
				thead: [
					"Ship-to name",
					"Ship-to code",
					"Location key",
					""
				],
				tbody: this.items
			};
		}
	},

	methods: {
		handlePageChange(pageNum) {
			return this.$emit("onPageChange", pageNum);
		}
	}
};
</script>

<style lang="scss" scoped>
.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}
</style>