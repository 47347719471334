/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */
export const MODULE_SORT_DATE_CHART = Object.freeze({
	perDay: "1d",
	perThreeDay: "3d",
	perWeek: "7d",
	perTwoWeek: "2w",
	perMonth: "1m",
	perThreeMonth: "3m",
	perSixMonth: "6m",
	perYear: "1y",
	perNinetyDays: "90d",
	perThirtyDays: "30d"
});

/**
 * Enum for sort date types.
 * @readonly
 * @enum {EnumSortDateTypes}
 */
export const SORT_DATE_TYPE = Object.freeze({
	perDay: "1",
	perThreeDay: "3",
	perWeek: "7",
	perTwoWeek: "14",
	perMonth: "30",
	perThreeMonth: "90",
	perSixMonth: "120"
});
