export const getPaginations = ({ firstPage = 1, lastPage, currentPage, offsetPage = 2 }) => {
	const paginators = [];

	if (lastPage < (offsetPage * 2) + 1) {
		for (let i = firstPage; i < lastPage + 1; i += 1) {
			paginators.push({
				value: i,
				enable: true
			});
		}
	} else if (currentPage - firstPage < offsetPage + 2) {
		// if currentPage near firstPage
		for (
			let i = firstPage;
			i < Math.max(offsetPage * 2, currentPage + offsetPage);
			i += 1
		) {
			paginators.push({
				value: i,
				enable: true
			});
		}

		paginators.push({
			value: "...",
			enable: false
		});

		paginators.push({
			value: lastPage,
			enable: true
		});
	} else if (lastPage - currentPage < offsetPage + 2) {
		// if currentPage near lastPage
		paginators.push({
			value: firstPage,
			enable: true
		});

		paginators.push({
			value: "...",
			enable: false
		});

		for (
			let i = Math.min(
				(lastPage - (offsetPage * 2)) + 2,
				currentPage - offsetPage
			); i < lastPage + 1; i += 1) {
			paginators.push({
				value: i,
				enable: true
			});
		}
	} else {
		// if currentPage in the middle
		paginators.push({
			value: firstPage,
			enable: true
		});

		paginators.push({
			value: "...",
			enable: false
		});

		for (
			let i = (currentPage - offsetPage) + 1;
			i < currentPage + offsetPage; i += 1) {
			paginators.push({
				value: i,
				enable: true
			});
		}

		paginators.push({
			value: "...",
			enable: false
		});

		paginators.push({
			value: lastPage,
			enable: true
		});
	}
	return paginators;
};

export default {
	getPaginations
};
