import { required, email } from "vuelidate/lib/validators";
import { getvalidateMessage, checkIfValid } from "../validateHelper";
import { STAFF_ROLES } from "../../../enums/role";

export default {
	validations: {
		email: {
			required,
			format(value) {
				return email(value);
			},
			isExist() {
				return false;
			}
		},
		systemRole: {
			required
		},
		soldToValues: {
			required(value) {
				return value.length > 0;
			}
		},
		shipToValues: {
			required(value) {
				return value.length > 0;
			}
		},
		applicationValues: {
			required(value) {
				return value.length > 0;
			}
		},
		deviceValues: {
			required(value) {
				return value.length > 0;
			}
		}
	},
	methods: {
		checkIfValid(name) {
			const field = this.$v[name];
			if (!field.$dirty) {
				return null;
			}

			if (!("required" in field) && !field.$error) {
				return null;
			}

			return (field.$invalid || field.$error);
		},
		handleTouchMainForm(mainForm = []) {
			mainForm.forEach((input) => input.$touch());
		},
		handleSubmitMainForm(callback) {
			const { soldToValues, shipToValues, applicationValues, deviceValues } = this.$v;
			const mainFormValid = [soldToValues, shipToValues, applicationValues, deviceValues];
			this.handleTouchMainForm(mainFormValid);

			const isMainFormValid = mainFormValid.every(({ $invalid }) => $invalid === false);
			if (isMainFormValid || this.$v.systemRole.$model === STAFF_ROLES.SALES) {
				callback();
			}
		},
		handleSubmitChangePassword(callback) {
			const { password } = this.$v;
			password.$touch();
			if (!this.$v.password.$invalid) {
				callback();
			}
		}
	},
	computed: {
		isSoldToError() {
			const field = this.$v.soldToValues;
			return checkIfValid(field);
		},

		getSoldToErrorMessage() {
			const field = this.$v.soldToValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isShipToError() {
			const field = this.$v.shipToValues;
			return checkIfValid(field);
		},

		getShipToErrorMessage() {
			const field = this.$v.shipToValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isApplicationError() {
			const field = this.$v.applicationValues;
			return checkIfValid(field);
		},

		getApplicationErrorMessage() {
			const field = this.$v.applicationValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isDeviceError() {
			const field = this.$v.deviceValues;
			return checkIfValid(field);
		},

		getDeviceErrorMessage() {
			const field = this.$v.deviceValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		}
	}
};