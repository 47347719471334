<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: isActive.keyword }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				v-model="form.keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Search email & company name"
				@input="handleKeywordChanged"
				@focus="setFocus('keyword', true)"
				@blur="setFocus('keyword', false)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-date', { active: isActive.date }]">
			<BaseDatePicker
				v-model="form.date"
				:max-date="new Date()"
				size="medium"
				mode="range"
				clearable
				class="custom-datepicker"
				@open="setFocus('date', true)"
				@close="setFocus('date', false)"
				@done="handlePickDate"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-type', { active: isActive.userType }]">
			<BaseDropdown
				:searchable="false"
				:list="userType"
				:value="form.userType"
				type="ghost"
				placeholder="User type: All"
				@input="handleUserTypeSelected"
				@active="setFocus('userType', $event)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline>Search</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import { getDate } from "../../selectors/helpers";

export default {
	name: "SearchFilterForm",

	components: {
		BaseDatePicker
	},

	props: {
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			form: {
				keyword: this.$route.query.q || null,
				date: this.$route.query.start && this.$route.query.end ? {
					start: getDate(this.$route.query.start),
					end: getDate(this.$route.query.end)
				} : null,
				userType: this.$route.query.userType || null
			},
			isActive: {
				keyword: false,
				date: false,
				userType: false
			}
		};
	},

	computed: {
		userType() {
			return [
				{ label: "User type: All", value: null },
				{ label: "BIG staff", value: "staff" },
				{ label: "Customer", value: "customer" }
			];
		}
	},

	methods: {
		handleKeywordChanged(value = null) {
			this.form.keyword = value || null;
		},

		handlePickDate(date) {
			this.form.date = date;
		},

		handleUserTypeSelected(value) {
			this.form.userType = value;
		},

		handleSubmit() {
			this.$emit("submit", this.form);
		},

		clearFilter() {
			this.form.keyword = null;
			this.form.date = null;
			this.form.userType = null;
			this.$emit("submit", this.form);
		},

		setFocus(type, value) {
			this.isActive[type] = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-keyword {
	width: rem(264);
}

.input-group-date {
	width: rem(214);
}

.input-group-type {
	width: rem(184);
}

/* stylelint-disable */
.custom-datepicker {
	display: inline-flex;
	flex: 1;

	&::v-deep .container-datepicker-control {
		margin-left: -14px;
	}

	&::v-deep .input {
		border: none;
		background: none !important;
		padding-left: rem(26) !important;
	}

	&::v-deep .icon.is-left {
		width: rem(14) !important;
	}
}
/* stylelint-enable */
</style>
