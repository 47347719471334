<template>
	<div class="sort-table-wrapper">
		<font-awesome-icon
			v-if="isShowArrowUp"
			:icon="['fas', 'sort-up']"
			:class="['icon-sort sort-up', { active : !isShowArrowDown && isShowArrowUp }]"
			@click="handleClick('desc')"
		/>
		<font-awesome-icon
			v-if="isShowArrowDown"
			:icon="['fas', 'sort-down']"
			:class="['icon-sort sort-down',{ active : isShowArrowDown && !isShowArrowUp }]"
			@click="handleClick('asc')"
		/>
	</div>
</template>

<script>
export default {
	props: {
		sortName: {
			type: String,
			default: ""
		},

		sortBy: {
			type: String,
			default: "",
			validator: (value) => ["asc", "desc", ""].includes(value)
		}
	},
	methods: {
		handleClick(sortBy) {
			if (this.isShowArrowDown && !this.isShowArrowUp) {
				return this.$emit("handleSort", {
					sortName: this.sortName,
					sortBy: "desc"
				});
			}
			if (!this.isShowArrowDown && this.isShowArrowUp) {
				return	this.$emit("handleSort", {
					sortName: this.sortName,
					sortBy: "asc"
				});
			}

			return this.$emit("handleSort", {
				sortName: this.sortName,
				sortBy
			});
		}
	},
	computed: {
		isShowArrowUp() {
			return !this.sortBy || this.sortBy === "desc";
		},
		isShowArrowDown() {
			return !this.sortBy || this.sortBy === "asc";
		}
	}
};
</script>

<style lang="scss" scoped>
.sort-table-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: rem(14);
	margin: 0 rem(8);

	.sort {
		&-up {
			margin-bottom: rem(-6);

			&.active {
				margin-bottom: 0;
				color: $color-cerulean;
			}
		}

		&-down {
			margin-top: rem(-6);

			&.active {
				margin-top: 0;
				color: $color-cerulean;
			}
		}
	}

	.icon-sort {
		color: $color-light-teal;
		cursor: pointer;

		&:hover {
			color: $color-cerulean;
		}
	}
}
</style>