<template>
	<modal :name="modalName" :scrollable="true" width="450" height="auto" @before-open="beforeOpen">
		<BaseModalContent rounded>
			<template v-slot:header>
				Create new group
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div :class="['container-form', { active: state.dropdownShipToIdActive }]">
					<div class="item">
						<div class="label">
							Select channel
						</div>
						<div class="form">
							<BaseDropdown
								:list="configOptions"
								:value="form.channel"
								:searchable="false"
								:dropdownListContainerHeight="100"
								:placeholder="`Please select`"
								rounded
								@input="handleShipToChange"
								@onActiveChange="handleActiveChange"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					size="large"
					@click="handleSubmit"
					:disabled="disabledButton"
					rounded
				>
					Continue
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},
		configs: {
			type: Array,
			default: () => ([])
		}
	},

	data() {
		return {
			state: {
				dropdownShipToIdActive: false
			},
			form: {
				channel: null
			}
		};
	},

	computed: {
		configOptions() {
			return this.configs
				.filter((item) => (
					item.isActive
				))
				.map((item) => ({
					label: item.displayName,
					value: item.channel
				}));
		},
		disabledButton() {
			return this.form.channel === null;
		}
	},

	methods: {
		beforeOpen() {
			this.form.channel = null;
			this.state.dropdownShipToIdActive = false;
		},
		handleSubmit() {
			this.$emit("onSubmit", this.form);
		},
		handleShipToChange(value) {
			this.form.channel = value;
		},
		handleActiveChange(isActive) {
			this.state.dropdownShipToIdActive = isActive;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	&.active {
		margin-bottom: rem(70);
	}

	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			align-items: center;
			min-width: rem(105);
		}

		.form {
			flex-grow: 1;
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}
</style>