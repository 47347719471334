<template>
	<div class="chartpie-container">
		<v-chart autoresize  theme="ovilia-green" :options="chartData" />
	</div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/axis";
import "echarts/lib/component/axisPointer";
import "echarts/lib/component/dataZoomInside";
import "echarts/lib/component/dataZoom/AxisProxy";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/markArea";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/legend/LegendView";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/grid";

export default {
	components: {
		"v-chart": ECharts
	},
	props: {
		chartData: {
			type: Object
		}
	}
};
</script>

<style lang="scss" scroped>
.chartpie-container {
	display: flex;
	width: 100%;
	min-height: rem(318);
}

.echarts {
	width: 100%;
	min-height: rem(318);
}

.meter {
	margin-bottom: rem(-8);
}

.bar-name {
	font-size: rem(16);
	font-family: $font-family-base;
	font-weight: bold;
}

.bar-value {
	font-family: $font-family-sub;
	font-size: rem(20);
	font-weight: bold;
}

.micro-unit {
	font-family: $font-family-base;
	font-size: rem(20);
	font-weight: 800;
}

.bar-container {
	display: flex;
	justify-content: space-between;
}
</style>