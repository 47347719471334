<template>
	<div :class="['wrapper-base', { 'over-limit': this.isOverLimit }, { 'toggle-extend': this.toggleExtend }]">
		<p :style="[{ 'max-height': this.maxHeight }]" class="wrapper-base-text" ref="p">
			<span class="wrapper-base-text-outer" ref="span">
				<slot>
					{{ text }}
				</slot>
			</span>
		</p>
	</div>
</template>

<script>

export default {
	name: "BaseText",

	props: {
		text: {
			type: String,
			default: ""
		},
		maxLine: {
			type: Number,
			default: -1
		}
	},

	data() {
		return {
			isOverLimit: false,
			maxHeight: "none",
			toggleExtend: undefined
		};
	}
};
</script>

<style lang="scss" scoped>
.wrapper-base {
	position: relative;

	&.over-limit > &-more-btn {
		display: flex;
	}

	&.over-limit.toggle-extend > &-more-btn {
		display: none;
	}

	&-more-btn {
		@include fontBase();

		position: absolute;
		top: 0;
		right: 0;
		display: none;
		justify-content: flex-start;
		align-items: flex-end;
		width: 100%;
		height: 100%;
		font-size: $font-18;
		color: $color-cerulean;
		cursor: pointer;

		background: none;
		border: none;

		span {
			position: relative;
			top: rem(20);
			font-weight: $font-weight-bold;
		}
	}

	&-text {
		position: relative;
		overflow-y: hidden;
		margin: 0 0 rem(16) 0;

		&-outer {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
		}
	}
}
</style>