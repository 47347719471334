<template>
	<BaseLayout>
		<BaseBackBtn :to="PAGE_NAME.USERS" class="page-back-btn" label="Back to staff account"/>
		<div class="wrapper-staff-create">
			<h1 class="create-staff-title">Create BIG staff account</h1>
			<BaseCard class="card-staff-info">
				<template v-slot:header>
					<h4>Staff information</h4>
				</template>
				<template v-slot:body>
					<BasicForm
						:email.sync="$v.email.$model"
						:azureId.sync="azureId"
						:role.sync="$v.systemRole.$model"
						:isTestAccount.sync="isTestAccount"
						:validateForm.sync="validateBasicForm"
						@clearPermission="handleClearPermission"
					/>
				</template>
			</BaseCard>
			<BaseCard>
				<template v-slot:header>
					<h4>Permission</h4>
				</template>
				<template v-slot:body>
					<BaseBadgeInfomation
						class="badge-info-permission"
						title="Visibility Permission"
					>
						<template #content-body>
							<ul class="permission-list">
								<li class="permission-list-item">Your first selection will be set as user visibility level. To reset level, please <strong>clear all permission setting</strong></li>
								<li class="permission-list-item">Every selection will effect to item list in other levels.</li>
								<li class="permission-list-item">Permission can be duplicated from staff email. <span v-if="showDuplicateButton" class="view-text clickable" @click="onClickOpenModal">Duplicate permission</span></li>
							</ul>
						</template>
					</BaseBadgeInfomation>
					<BaseLoading
						v-if="isLoadingStaffPermissions"
					/>
					<template
						v-else
					>
						<div class="form-permission-header">
							<h3 class="permission-level">
								Level
							</h3>
							<div class="permission-visibility">
								<h3 class="visibility-label">
									Visibility
								</h3>
								<BaseButton
									v-if="systemRole !== STAFF_ROLES.SALES"
									size="small"
									type="secondary"
									link
									@click="handleClearPermission"
								>
									Clear all permission setting
								</BaseButton>
							</div>
						</div>
						<BaseMultipleSelect
							:options="getSoldToOptions"
							:value="getSoldToValues"
							:customLabel="getLabelSoldTo"
							:disabled="$v.email.$invalid || $v.systemRole.$invalid || $v.systemRole.$model === STAFF_ROLES.SALES"
							:limit="10"
							:is-error="isSoldToError"
							:error-message="getSoldToErrorMessage"
							label="Sold-to *"
							placeholder="Search and select sold-to"
							multiple
							@onSelectValue="handleSelectSoldTo"
							@onRemoveSelected="handleRemoveSoldTo"
						></BaseMultipleSelect>
						<BaseMultipleSelect
							:options="getShipToOptions"
							:value="getShipToValues"
							:customLabel="getLabelShipTo"
							:disabled="isDisabledShipTo"
							:limit="10"
							:is-error="isShipToError"
							:error-message="getShipToErrorMessage"
							label="Ship-to *"
							placeholder="Search and select ship-to"
							multiple
							defaultHeight
							@onSelectValue="handleSelectShipTo"
							@onRemoveSelected="handleRemoveShipTo"
						></BaseMultipleSelect>

						<BaseCheckBox
							:disabled="isDisabledShipTo"
							:checked="isAutoUpdateShipTo"
							class="form-checkbox"
							label="Auto update ship-to that will be added in the future"
							@handleCheck="handleCheckUpdateShipTo"
						/>
						<BaseMultipleSelect
							:options="getApplicationOptions"
							:value="getApplicationValues"
							:disabled="isDisabledApplication"
							:limit="10"
							:is-error="isApplicationError"
							:error-message="getApplicationErrorMessage"
							label="Application *"
							placeholder="Search and select application"
							multiple
							@onSelectValue="handleSelectApplication"
							@onRemoveSelected="handleRemoveApplication"
						></BaseMultipleSelect>
						<BaseMultipleSelect
							:options="getDeviceOptions"
							:value="getDeviceValue"
							:disabled="isDisableDevice"
							:limit="10"
							:is-error="isDeviceError"
							:error-message="getDeviceErrorMessage"
							label="Device *"
							placeholder="Search and select device"
							multiple
							defaultHeight
							@onSelectValue="handleSelectDevice"
							@onRemoveSelected="handelRemoveDevice"
						></BaseMultipleSelect>
						<BaseCheckBox
							:disabled="isDisableDevice"
							:checked="isAutoUpdateDevice"
							class="form-checkbox"
							label="Auto update device that will be added in the future"
							@handleCheck="handleCheckUpdateDevice"
						/>
					</template>
				</template>
			</BaseCard>
			<div class="wrapper-confirm-create-staff">
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleCancle"
				>
					Cancel
				</BaseButton>

				<BaseButton
					size="large"
					type="primary"
					@click="handleSubmit(handleSendPayload)"
				>
					Create an account
				</BaseButton>
			</div>
		</div>
		<ModalDuplicatePermission
			:modal-name="MODAL_NAME"
			:emails="staffEmails"
			:loading="isLoadingStaffList"
			:onReset.sync="onResetFormModalDup"
			@onSubmit="handleSubmitModal"
		/>
	</BaseLayout>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";
import BaseBadgeInfomation from "@/components/BaseBadgeInfomation.vue";
import BasicForm from "@/components/Staff/BasicForm.vue";
import { validationMixin } from "vuelidate";
import BaseButton from "@/components/BaseButton.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModalDuplicatePermission from "@/components/Staff/ModalDuplicatePermission.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import createCustomerValidateCustomMixin from "../../selectors/formValidate/userManagement/createCustomerValidateCustomMixin";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { STAFF_ROLES } from "../../enums/role";
import devicePermissionHandleCustom from "../../selectors/mixins/userManagement/devicePermissionHandleCustom";

export default {
	name: "CustomerCreate",

	mixins: [
		validationMixin,
		createCustomerValidateCustomMixin,
		devicePermissionHandleCustom
	],

	components: {
		BaseCard,
		BaseBadgeInfomation,
		BaseButton,
		BaseBackBtn,
		BaseMultipleSelect,
		BaseCheckBox,
		BasicForm,
		ModalDuplicatePermission,
		BaseLoading
	},

	data() {
		return {
			PAGE_NAME,
			STAFF_ROLES,
			MODAL_NAME: "modal",
			email: "",
			azureId: "",
			systemRole: "",
			isTestAccount: false,
			validateBasicForm: null,
			isLoadingStaffList: false,
			isLoadingStaffPermissions: false,
			onResetFormModalDup: null
		};
	},

	computed: {
		...mapState(["customerManagement", "insertStaff"]),
		...mapGetters({
			getStaffs: "staff/getTransformStaffs"
		}),
		...mapGetters("staff", {
			getStaffSoldToValues: "getStaffSoldToValues",
			getStaffShipToValues: "getStaffShipToValues",
			getStaffApplicationValues: "getStaffApplicationValues",
			getStaffDeviceValues: "getStaffDeviceValues",
			getIsUpdateShipTo: "getIsUpdateShipTo",
			getIsUpdateDeivce: "getIsUpdateDeivce",
			isAllSoldTo: "isAllSoldTo",
			isAllShipTo: "isAllShipTo",
			isAllApplication: "isAllApplication",
			isAllDevice: "isAllDevice"
		}),
		getCompaniesOption() {
			return this.customerManagement.companies;
		},
		staffEmails() {
			return this.getStaffs.map((item) => item.email);
		},
		showDuplicateButton() {
			return this.$v.systemRole.$model !== STAFF_ROLES.SALES;
		}
	},

	async created() {
		this.handleFetchPermissionLists();

		this.isLoadingStaffList = true;
		await this.fetchStaffLists({ perPage: 10000 });
		this.isLoadingStaffList = false;
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions({
			fetchStaffLists: "staff/fetchStaffLists",
			postStaffPermission: "staff/postStaffPermission",
			postStaff: "insertStaff/postStaff"
		}),
		async handleSendPayload() {
			const data = {
				id: this.azureId,
				roleCode: this.systemRole,
				email: this.email,
				permissions: this.computePermission,
				isActive: true,
				isTestAccount: this.isTestAccount
			};
			await this.postStaff(data);
			if (this.insertStaff.isInsertSuccess) {
				this.$router.push({
					name: PAGE_NAME.USERS
				});
				this.showToast({
					type: TOAST_TYPES.CUSTOMER_CREATE,
					props: { email: this.email, delay: 6000 }
				});
			}
		},
		handleCancle() {
			this.$router.push({
				name: PAGE_NAME.USERS
			});
		},
		onClickOpenModal() {
			this.$modal.show(this.MODAL_NAME);
		},
		async handleSubmitModal(email = "") {
			this.$modal.hide(this.MODAL_NAME);
			this.isLoadingStaffPermissions = true;
			await this.postStaffPermission({ email });
			await this.fetchPermissionList({});
			await this.setInitialPermission();
			this.isAutoUpdateShipTo = this.getIsUpdateShipTo;
			this.isAutoUpdateDevice = this.getIsUpdateDeivce;
			this.isLoadingStaffPermissions = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.wrapper-staff-create {
	padding: rem(24) 0;
}

.password-validate-infomation {
	&.container-info {
		width: rem(343);
		padding: 0;
		background-color: transparent;
	}
}

.form-permission-header {
	display: flex;
	align-items: center;

	.permission {
		&-level {
			font-size: $font-20;
			width: rem(130);
		}

		&-visibility {
			display: flex;
			justify-content: space-between;
			flex-grow: 1;

			.visibility-label {
				font-size: $font-20;
			}
		}
	}
}

.badge-info {
	&-permission {
		margin-bottom: rem(24);
	}
}

.permission-list {
	margin-left: rem(18);
	list-style-position: inside;

	&-item {
		list-style-type: disc;
	}
}

.form-checkbox {
	margin-left: rem(130);
}

.create-staff-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.system-role-info-icon {
	font-size: rem(12);
	color: $color-cerulean;
	cursor: pointer;
	margin-left: rem(6);
}

.staff-create-input {
	margin: rem(24) 0;
}

.input-group-search {
	width: 100%;

	@include desktop {
		width: 100%;

		/* stylelint-disable */
		&::v-deep .dropdown-value {
			padding: 0;
		}
		/* stylelint-enable */
	}
}

.card-password {
	margin-bottom: rem(56);
}

.card-staff-info {
	margin-top: rem(40);
}

.info-password-description {
	font-size: rem(16);
	margin: 0;
	color: $color-dark-blue-grey;
}

.generate-password-description {
	color: $color-dark-blue-grey;
	margin: rem(32) 0 rem(-8) 0;
}

.wrapper-generate-password {
	display: flex;
	align-items: center;
}

.container-generate-password {
	display: flex;
	align-items: center;
	width: rem(494);

	.btn {
		display: flex;
		align-items: center;
	}

	.generate-password-btn {
		font-size: rem(20);
		min-width: rem(150);
		justify-content: center;
		font-weight: $font-weight-bold;
	}

	.redo-password-icon {
		margin-right: rem(8);
		font-weight: $font-weight-bold;
	}
}

.copy-password-btn {
	margin-left: rem(11);
}

.redo-password-icon,
.copy-password-icon {
	font-size: rem(16);
}

.copy-password-icon {
	margin-right: rem(4);
}

.wrapper-confirm-create-staff {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}
</style>
