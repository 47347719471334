<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<ModuleHeaderExample :data="genGasInfo.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_GENGAS_INFO }"
						active-class="active"
						class="nav-link"
					>
						Meter information
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_GENGAS_HISTORY }"
						active-class="active"
						class="nav-link"
					>
						History
					</router-link>
				</li>
				<li v-if="permissions.powerBI.read" class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_GENGAS_POWERBI }"
						active-class="active"
						class="nav-link"
					>
						Analysis
					</router-link>
				</li>
			</ul>
			<router-view
				@loaded="handleContentLoaded"
			/>
		</div>
	</div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import ModuleHeaderExample from "@/components/dashboard/modules/ModuleHeaderExample.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { CONFIG } from "../../enums/config";
import userPermission from "../../mixins/userPermission";

export default {
	name: "GenGasExampleModule",
	mixins: [userPermission],
	components: {
		ModuleHeaderExample,
		BaseLoading
	},

	data() {
		return {
			id: this.$route.params.id,
			isLoading: true,
			PAGE_NAME,
			fetchDevices: () => {}
		};
	},

	computed: {
		...mapState(["genGasInfo", "shipTos"]),

		breadcrumb() {
			return {
				type: {
					name: "Gen Gas"
				},
				shiped: {
					name: this.shipTos.data.name
				}
			};
		},

		shipToId() {
			return this.genGasInfo.data.shipToId || null;
		}
	},

	async created() {
		await this.getGenGasInfo({ id: this.id });

		this.autoRefreshData = setInterval(async () => {
			if (!this.genGasInfo.isAutoFecthLoading) {
				await this.autoGetGenGasInfo({ id: this.id });
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		if (this.$route.name === PAGE_NAME.GENGAS_INFO) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: this.genGasInfo.statusCode
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);

				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("genGasInfo", {
			getGenGasInfo: "getGenGasInfo",
			clearGenGasInfo: "clearGenGasInfo",
			autoGetGenGasInfo: "autoGetGenGasInfo"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		...mapActions("shipTos", ["getShipTo"]),
		handleContentLoaded() {
			this.fetchDevices();
		}
	},

	destroyed() {
		this.clearGenGasInfo();
	}
};
</script>
