<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<template
			v-else
		>
			<BaseLoading
				v-if="standardModuleHistory.isLoading"
			/>
			<BaseFilterExport
				v-if="!standardModuleHistory.isLoading && permissions.dashboard.exportable"
				mode="range"
				class="custom-filter-export"
				@onSubmit="handleSubmitExport"
			/>
			<BaseTableStandard
				:item="item"
				:tableOptions="tableOptions"
				:pagination="pagination"
				:loading="loading"
				:stickyHeader="true"
				@onPageChange="handlePageChange"
			/>
		</template>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseTableStandard from "@/components/BaseTableStandard.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";
import { getUri } from "../../selectors/helpers";
import userPermission from "../../mixins/userPermission";

export default {
	name: "StandardModuleHistory",
	components: {
		BaseLoading,
		EmptyOrderHistory,
		BaseFilterExport,
		BaseTableStandard
	},

	mixins: [userPermission],

	data() {
		return {
			id: this.$route.params.id,
			templateId: this.$route.params.templateId,
			app: this.$route.params.app,
			tableOptions: [
				"is-striped is-fullwidth"
			],
			page: this.$route.query.page || 1
		};
	},

	computed: {
		...mapState(["standardModuleHistory"]),
		pagination() {
			return this.standardModuleHistory.pagination;
		},
		loading() {
			return this.standardModuleHistory.isLoading;
		},
		item() {
			return {
				thead: this.standardModuleHistory.data.headers,
				tbody: this.standardModuleHistory.data.rows
			};
		},
		isEmpty() {
			return this.standardModuleHistory.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.id,
			httpStatus: this.standardModuleHistory.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("standardModuleHistory", {
			getHistoryStandardModule: "getHistoryStandardModule"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		fetchData() {
			const { id, page } = this;
			return this.getHistoryStandardModule({
				id,
				params: { page }
			});
		},

		handlePageChange(page = 1) {
			this.page = page;
			this.fetchData();
			this.$router.replace({ query: { page } }).catch(() => {});
		},

		handleSubmitExport(form) {
			const params = {
				templateId: this.templateId,
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).format("YYYY-MM-DD"),
						endDate: dayjs(form.dateRange.end).format("YYYY-MM-DD")
					} : null
				)
			};
			const path = `/standard-module/${this.id}/history/export`;
			const uri = getUri(path, params);
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.custom-filter-export {
	margin-top: rem(-8);
	margin-bottom: rem(11);
	justify-content: flex-end;
}
</style>
