import httpClient from "./httpClient";

const ACTIVITYLOG_END_POINT = "/logs";

const getActivityLogsApi = (params) => httpClient.get(`${ACTIVITYLOG_END_POINT}`, { params });
const postActivityLogsApi = (data) => httpClient.post(`${ACTIVITYLOG_END_POINT}`, data);

export {
	getActivityLogsApi,
	postActivityLogsApi
};
