<template>
	<div :class="['container-svg', size]">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="74" height="74" viewBox="0 0 74 74">
			<defs>
			</defs>
			<g fill="none" fill-rule="evenodd">
				<g transform="translate(.636 .636)">
					<mask :id="shipId" fill="#fff">
						<path id="a" d="M36.33 0c20.06 0 36.322 16.262 36.322 36.321 0 20.06-16.261 36.322-36.321 36.322S.01 56.38.01 36.32C.01 16.261 16.27 0 36.33 0zm.043 5.322C19.23 5.322 5.331 19.22 5.331 36.364c0 17.144 13.898 31.042 31.042 31.042 17.144 0 31.042-13.898 31.042-31.042 0-17.144-13.898-31.042-31.042-31.042z"/>
					</mask>
					<path :class="[color]" fill-rule="nonzero" d="M50 0v2.67c18.291 7.422 27.345 28.16 20.24 46.668C63.05 68.065 42.042 77.419 23.315 70.23 4.587 63.042-4.767 42.032 2.422 23.305a2.761 2.761 0 0 1 5.155 1.979c-6.096 15.88 1.836 33.695 17.716 39.791 15.88 6.096 33.696-1.836 39.792-17.716 5.749-14.977-.98-31.674-15.084-38.64L50 11.82H29.09l.004-5.466c-.334.153-.904.164-1.398.156l-.569-.01c-1.506 0-2.582-1.057-2.582-2.564 0-1.3.583-2.41 1.708-2.672.178-.041.668-.339.874-.339.187 0 .32-.034.51 0 .258.048 1.138-.803 1.455-.801L29.09 0H50z" :mask="`url(#${shipId})`"/>
				</g>
				<path :class="[color]" d="M9.574 17.706l2.034 8.37a.91.91 0 0 1-1.268 1.04l-9.754-4.55a.91.91 0 0 1-.019-1.638l7.72-3.822a.91.91 0 0 1 1.287.6zM18.364 20.636c4.27 0 7.727-3.113 7.727-6.957 0-4.367-4.383-5.988-6.443-12.18-.35-1.125-2.174-1.176-2.568 0-2.053 6.16-6.444 7.836-6.444 12.18 0 3.844 3.458 6.957 7.728 6.957z"/>
			</g>
		</svg>
		<div class="wrapper-status">
			<span class="value">
				{{ waterValue }}
			</span>
			<span class="unit" v-html="unit"></span>
		</div>
	</div>
</template>

<script>
import { HTML_FLOW_RATE_UNIT_PER_HR } from "../../../selectors/transform/unit";
import { parseAddCommaWhenIsNumber } from "../../../selectors/helpers/index";

export default {
	name: "WaterIcon",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},

		unit: {
			type: String,
			default: HTML_FLOW_RATE_UNIT_PER_HR
		},

		color: {
			type: String,
			required: true,
			default: "blue",
			validator: (value) => [
				"blue",
				"grey"
			].includes(value)
		},

		size: {
			type: String,
			required: true,
			default: "small",
			validator: (value) => [
				"smallest",
				"small",
				"large"
			].includes(value)
		},
		shipId: {
			type: String,
			default: "ZLD"
		}
	},

	data() {
		return {
			HTML_FLOW_RATE_UNIT_PER_HR
		};
	},

	computed: {
		waterValue() {
			return parseAddCommaWhenIsNumber(this.value ?? null);
		}
	}
};
</script>

<style lang="scss" scoped>
$svg-smallest-size: rem(58);
$svg-small-size: rem(74);
$svg-large-size: rem(131);

.container-svg {
	position: relative;

	svg {
		.blue {
			fill: $color-azure;
		}

		.grey {
			fill: $color-light-teal;
		}
	}

	.wrapper-status {
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		font-family: $font-family-base;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.value {
		@include fontSub();
	}

	.unit {
		@include fontBase();
	}

	.value, .unit {
		color: $color-dark-blue-grey;
		font-weight: bold;
	}

	&.smallest {
		width: $svg-smallest-size;
		height: $svg-smallest-size;

		svg {
			width: $svg-smallest-size;
			height: $svg-smallest-size;
		}

		.value {
			margin-bottom: rem(-8);
			font-size: $font-18;
		}

		.unit {
			font-size: $font-12;
		}
	}

	&.small {
		width: $svg-small-size;
		height: $svg-small-size;

		svg {
			width: $svg-small-size;
			height: $svg-small-size;
		}

		.value {
			margin-bottom: rem(-8);
			font-size: $font-21;
		}

		.unit {
			font-size: $font-14;
		}
	}

	&.large {
		width: $svg-large-size;
		height: $svg-large-size;

		svg {
			width: $svg-large-size;
			height: $svg-large-size;
		}

		.value {
			margin-bottom: rem(-16);
			font-size: $font-40;
		}

		.unit {
			font-size: $font-26;
		}
	}
}
</style>
