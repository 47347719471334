/**
 * Enum for maintenance status
 * @readonly
 * @enum {EnumMaintenanceStatus}
 */
export const MAINTENANCE_STATUS = Object.freeze({
	ENABLED: 1,
	DISABLE: 2,
	ENABLED_STATUS: "Enable",
	DISABLE_STATUS: "Disable"
});