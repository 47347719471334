import dayjs from "dayjs";
import { transformLineChart } from "..";
import { US_UNIT } from "../../unit";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachDay,
	getForEachSixHours,
	getForEachHour,
	getForEachThirtyMinute,
	getForEachTwoHours,
	xAxisLabelWithOutTime,
	getForEachFiveDay
} from "../helper";

import { getConfigDataZoom } from "../helper/zoomConfig";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Electrical conductivity - Recycle water (${US_UNIT}/cm)`;

const tooltipElectricalConductivityFormat = (name, value) => {
	const format = `
		<div>
			<div class="bar-name">
				${name}
			</div>
			<div class="bar-value">
				${value === null ? "-" : value} <span class="micro-unit">&micro;</span>s/cm
			</div>
		</div>`;
	return format;
};

const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipElectricalConductivityFormat(date, pointValue.data);
};

const optionLineChartXaxisPerDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachTwoHours
	},
	...getConfigDataZoom(xAxis),
	axisTick: {
		interval: getForEachThirtyMinute
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});


const optionLineChartForThreeDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachFourHours
	},
	axisTick: {
		interval: getForEachHour
	},
	...getConfigDataZoom(xAxis),

	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}

});


const optionLineChartXaxisPerWeek = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	axisTick: {
		interval: getForEachSixHours
	},
	...getConfigDataZoom(xAxis),

	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

const optionLineChartXaxisMoreThanTwentyDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachFiveDay
	},

	axisTick: {
		interval: (index, date) => {
			const hour = dayjs(date).get("hour");
			const minute = dayjs(date).get("minute");

			return hour % 24 === 0 && minute === 0;
		}
	},

	...getConfigDataZoom(xAxis),

	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}

});

export const transformElectricalConductivityLineChartConfig = (sortBy = perDay, data = [], option = {}) => {
	const { diffDay } = option;

	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay(data),
		[perThreeDay]: optionLineChartForThreeDay(data),
		[perWeek]: optionLineChartXaxisPerWeek(data)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionLineChartXaxisMoreThanTwentyDay(data);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}
	const functionTransform = mappingOption[sortBy] || optionLineChartXaxisPerWeek(option, data);

	const transformFunc = transformLineChart(functionTransform);
	return transformFunc(data);
};
