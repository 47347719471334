import { transformShipTo } from "./transformShipTo";

export const transformDevices = (item) => {
	return {
		currentLevel: item?.currentLevel ?? "-",
		deliveryStatus: item?.deliveryStatus ?? "-",
		deviceCustomerName: item?.deviceCustomerName ?? "-",
		deviceId: item?.id ?? "-",
		levelStatus: item?.levelStatus ?? "-",
		module: item?.module ?? "-",
		name: item?.name ?? "-",
		shipId: item?.shipId ?? "-",
		shipTo: transformShipTo(item?.shipTo) ?? null,
		shipToId: item?.shipToId ?? "-",
		tankPercent: item?.tankPercent ?? "-",
		type: item?.type ?? "-",
		productId: item?.productId ?? null,
		shipToLatitude: item?.shipToLatitude ?? null,
		shipToLongitude: item?.shipToLongitude ?? null
	};
};