<template>
	<div class="tablet-header">
		<div class="logo">
			<img src="/assets/images/logo-white.svg" alt="BIG-TH">
		</div>
		<div class="action">
			<ul class="action-list">
				<li class="action-list-item">
					<button @click="toggleUITabletAccount">
						<FontAwesomeIcon :icon="['far', 'user']" />
					</button>
				</li>
				<li class="action-list-item">
					<button @click="toggleUITabletSidebar">
						<FontAwesomeIcon :icon="['far', 'bars']" />
					</button>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "TabletHeader",

	methods: {
		...mapActions({
			toggleUITabletAccount: "sidebar/toggleUITabletAccount",
			toggleUITabletSidebar: "sidebar/toggleUITabletSidebar"
		})
	}
};
</script>

<style lang="scss" scoped>
.tablet-header {
	justify-content: space-between;
	align-items: center;
	padding: 0 rem(26);
	height: rem(64);
	background-color: $color-green-haze;
	display: none;

	@include tablet {
		display: flex;
	}

	.logo {
		display: flex;
		align-items: center;

		img {
			height: 100%;
			position: relative;
			top: rem(-2);
			left: rem(-14);
		}
	}

	.action {
		&-list {
			display: flex;

			&-item {
				display: flex;
				margin-left: rem(20);

				button {
					display: flex;
					justify-content: center;
					align-items: center;
					width: rem(24);
					height: rem(24);
					background: none;
					border: none;
					color: $color-white;
				}
			}
		}
	}
}
</style>