import httpClient from "./httpClient";

const BLUK_HYDROGENS_ENDPOINT = "/bulk-hydrogens";

const getBhyInfoAPI = (id) => httpClient.get(`${BLUK_HYDROGENS_ENDPOINT}/${id}`);
const getBhyLevelAPI = (id, params = {}) => httpClient.get(`${BLUK_HYDROGENS_ENDPOINT}/${id}/levels`, { params });
const getBhyDeliverieAPI = (id) => httpClient.get(`${BLUK_HYDROGENS_ENDPOINT}/${id}/deliveries`);
const getBhyOrderHistoriesAPI = (id, params = {}) => httpClient.get(`${BLUK_HYDROGENS_ENDPOINT}/${id}/order-histories`, { params });
const getBhyTripInfosAPI = (id) => httpClient.get(`${BLUK_HYDROGENS_ENDPOINT}/${id}/trip-infos`);

export {
	getBhyInfoAPI,
	getBhyLevelAPI,
	getBhyDeliverieAPI,
	getBhyOrderHistoriesAPI,
	getBhyTripInfosAPI
};
