<template>
	<span :class="['container-badge', color, size, { outline }]">
		<span class="container-badge-text">
			<slot>{{ text }}</slot>
		</span>
	</span>
</template>

<script>
export default {
	name: "BaseBadge",

	props: {
		text: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: "medium",
			validator: (value) => {
				return [
					"small",
					"medium"
				].includes(value);
			}
		},
		color: {
			type: String,
			default: "success",
			validator: (value) => {
				return [
					"success",
					"success-dark",
					"light",
					"grey",
					"danger",
					"secondary",
					"warning"
				].includes(value);
			}
		},
		outline: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.container-badge {
	&-text {
		@include fontBase();

		color: $color-white;
		font-size: $font-16;
		font-weight: $font-weight-bold;
		border-radius: rem(4);
	}

	&.success > &-text {
		background-color: $color-cool-green;
	}

	&.success-dark > &-text {
		background-color: $color-jungle-green-two;
	}

	&.light > &-text {
		background-color: $color-light-teal;
	}

	&.grey > &-text {
		background-color: $color-light-silver;
		color: $color-dark-blue-grey;
	}

	&.danger > &-text {
		background-color: $color-red;
	}

	&.secondary > &-text {
		background-color: $color-azure;
	}

	&.small > &-text {
		padding: rem(0) rem(6);
	}

	&.medium > &-text {
		padding: rem(2) rem(6);
	}

	&.outline > &-text {
		background: none;
		border: solid 1px $color-powder-ash;
		color: $color-grey-4;
	}

	&.warning > &-text {
		background-color: $color-mustard;
	}
}
</style>