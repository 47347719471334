<template>
	<div class="container-body">
		<BaseHeadColumn class="order-head-column">
			<template #first-column>
				<div class="date">
					<strong>
						{{ item.dateString }}
					</strong>
				</div>
			</template>
			<template #rest-column>
				<div class="value text-right">
					<strong>
						{{ item.drossMonthly | fullCurrency }}
					</strong>
				</div>
				<div class="value text-right">
					<strong>
						{{ item.drossFinishedGood | fullCurrency }}
					</strong>
				</div>
				<div class="value text-right">
					<strong>
						{{ item.nitrogenMonthly | currency }}
					</strong>
				</div>
				<div class="value text-right">
					<strong>
						{{ item.nitrogenFinishedGood | fullCurrency }}
					</strong>
				</div>
			</template>
		</BaseHeadColumn>
	</div>
</template>

<script>
import BaseHeadColumn from "@/components/BaseHeadColumn.vue";

export default {
	components: {
		BaseHeadColumn
	},

	props: {
		item: {
			type: Object
		}
	}
};
</script>

<style lang="scss" scoped>
.container-body {
	padding: rem(10) 0;
}

.text-right {
	text-align: right !important;
}

.date, .value {
	font-family: $font-family-sub !important;
	font-size: 24px !important;
}

.date {
	margin-left: rem(24);
}

.value {
	margin-right: rem(24);
}
</style>