import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard/index.vue";
import FlowDiagramList from "../views/FlowDiagram/List.vue";
import FlowDiagramCreate from "../views/FlowDiagram/Create.vue";
import FlowDiagramEdit from "../views/FlowDiagram/Edit.vue";
import FlowDiagramView from "../views/FlowDiagram/View.vue";
import Permission from "../views/Permission.vue";
import MonitorOverall from "../views/monitors/Overall/Index.vue";
import MonitorDeviceHealth from "../views/monitors/DeviceHelath/index.vue";
import MonitorUser from "../views/monitors/User/Index.vue";
import Customers from "../views/customers/Index.vue";
import CustomerCreate from "../views/customers/Create.vue";
import CustomerEdit from "../views/customers/Edit.vue";
import Activitylog from "../views/Activitylog.vue";
import Login from "../views/Login.vue";
import LoginCallback from "../views/LoginCallback.vue";
import Tank from "../views/Tank/index.vue";
import TankInfo from "../views/Tank/TankInfo.vue";
import TripInfo from "../views/TripInfo.vue";
import TankOrder from "../views/Tank/TankOrder.vue";
import TankConsumptionHistory from "../views/Tank/TankConsumptionHistory.vue";
import Meter from "../views/MeterModule/Meter.vue";
import MeterInfo from "../views/MeterModule/MeterInfo.vue";
import MeterOrder from "../views/MeterModule/MeterOrder.vue";
import MeterConsumptionApproval from "../views/MeterModule/MeterConsumptionApproval.vue";
import MeterDailyConsumption from "../views/MeterModule/MeterDailyConsumption.vue";

import Zld from "../views/zld/Zld.vue";
import ZldInfo from "../views/zld/ZldInfo.vue";
import ZldLastMonth from "../views/zld/ZldLastMonth.vue";
import ZldGrafana from "../views/zld/ZldGrafana.vue";
import PowerBI from "../views/PowerBI.vue";

import ZldVinyThai from "../views/zldVinythai/Zld.vue";
import ZldVinyThaiInfo from "../views/zldVinythai/ZldInfo.vue";
import ZldVinyThaiOrder from "../views/zldVinythai/ZldOrder.vue";
import ZldVinyThaiConsumptionApproval from "../views/zldVinythai/ZldConsumptionApproval.vue";

import Cer from "../views/Cer.vue";
import CerInfo from "../views/CerInfo.vue";
import CerHistory from "../views/CerHistory.vue";

import Nitro from "../views/NitroFAS/index.vue";
import NitroFASInfo from "../views/NitroFAS/NitroFASInfo.vue";
import NitroFASHistory from "../views/NitroFAS/NitroFASHistory.vue";

import O2Aeration from "../views/O2Aeration/index.vue";
import O2AeationInfo from "../views/O2Aeration/O2AerationInfo.vue";
import O2AerationLastmonth from "../views/O2Aeration/O2AerationLastMonth.vue";
import O2AerationHistory from "../views/O2Aeration/O2AerationHistory.vue";
import Invoice from "../views/invoice/index.vue";

import GenGas from "../views/GenGasModule/index.vue";
import GenGasInfo from "../views/GenGasModule/GenGasInfo.vue";
import GenGasHistory from "../views/GenGasModule/GenGasHistory.vue";

import Pwr from "../views/PwrModule/index.vue";
import PwrInfo from "../views/PwrModule/PwrInfo.vue";
import PwrHistory from "../views/PwrModule/PwrHistory.vue";
import PwrKwh from "../views/PwrModule/PwrKwh.vue";

import Sor from "../views/SorModule/index.vue";
import SorInfo from "../views/SorModule/SorInfo.vue";
import SorHistory from "../views/SorModule/SorHistory.vue";
import SorKwh from "../views/SorModule/SorKwh.vue";

import Lng from "../views/Lng.vue";
import LngInfo from "../views/LngInfo.vue";
import LngTripInfo from "../views/LngTripInfo.vue";
import LngOrder from "../views/LngOrder.vue";

import PasswordPage from "../views/PasswordPage/index.vue";
import ForgotPassword from "../views/PasswordPage/ForgotPassword.vue";
import ResetPassword from "../views/PasswordPage/ResetPassword.vue";
import ChangePassword from "../views/PasswordPage/ChangePassword.vue";

import MaintenanceMode from "../views/MaintenanceMode.vue";

import Maintenance from "../views/Maintenance/Index.vue";

import EOrdering from "../views/eOrdering/index.vue";
import EOrderingInfo from "../views/eOrdering/DetailView.vue";
import EOrderingCreate from "../views/eOrdering/Create.vue";
import EOrderingEdit from "../views/eOrdering/Edit.vue";
import EOrderingPendingOrderList from "../views/eOrdering/PendingOrderList.vue";

import PowerBIExecutive from "../views/PowerBIExecutive/index.vue";

import GrafanaManagement from "../views/GrafanaManagement/Index.vue";

import Page404 from "../views/Page404.vue";
import Page502 from "../views/Page502.vue";

import BulkHydrogen from "../views/BulkHydrogen/index.vue";
import BulkHydrogenInfo from "../views/BulkHydrogen/BulkHydrogenInfo.vue";
import BulkHydrogenOrder from "../views/BulkHydrogen/BulkHydrogenOrder.vue";
import BulkHydrogenTripInfo from "../views/BulkHydrogen/BulkHydrogenTripInfo.vue";

import Furnace from "../views/Furnace/index.vue";
import FurnaceInfo from "../views/Furnace/FurnaceInfo.vue";
import FurnaceOverview from "../views/Furnace/FurnaceOverview.vue";
import FurnaceHistory from "../views/Furnace/FurnaceHistory.vue";
import FurnaceReport from "../views/Furnace/FurnaceReport.vue";

import AirComp from "../views/AirComp/index.vue";
import AirCompInfo from "../views/AirComp/AirCompInfo.vue";
import AirCompHistory from "../views/AirComp/AirCompHistory.vue";

import BigStoreBanner from "../views/BigStoreBanner/Index.vue";
import BigStoreBannerCreate from "../views/BigStoreBanner/Create.vue";
import BigStoreBannerEdit from "../views/BigStoreBanner/Edit.vue";

import BigStoreOurService from "../views/BigStoreOurService/Index.vue";
import BigStoreOurServiceCreate from "../views/BigStoreOurService/Create.vue";
import BigStoreOurServiceEdit from "../views/BigStoreOurService/Edit.vue";

import BigStoreOurDevice from "../views/BigStoreOurDevice/Index.vue";
import BigStoreOurDeviceCreate from "../views/BigStoreOurDevice/Create.vue";
import BigStoreOurDeviceEdit from "../views/BigStoreOurDevice/Edit.vue";

import ModuleGroupDetail from "../views/Marketplace/ModuleGroupDetail.vue";
import ExampleModule from "../views/Marketplace/ExampleModule.vue";
import GenGasExampleModule from "../views/GenGasModule/GenGasExampleModule.vue";
import AirCompExampleModule from "../views/AirComp/AirCompExampleModule.vue";
import FurnaceExampleModule from "../views/Furnace/FurnaceExampleModule.vue";
import MeterExampleModule from "../views/MeterModule/MeterExampleModule.vue";
import NitroFASExampleModule from "../views/NitroFAS/NitroFASExampleModule.vue";
import O2AerationExampleModule from "../views/O2Aeration/O2AerationExampleModule.vue";
import PwrExampleModule from "../views/PwrModule/PwrExampleModule.vue";
import SorExampleModule from "../views/SorModule/SorExampleModule.vue";
import TankExampleModule from "../views/Tank/TankExampleModule.vue";
import ZldExampleModule from "../views/zld/ZldExampleModule.vue";
import Zld2ExampleModule from "../views/zldVinythai/Zld2ExampleModule.vue";
import CerExampleModule from "../views/CerExampleModule.vue";
import LngExampleModule from "../views/LngExampleModule.vue";
import BulkHydrogenExampleModule from "../views/BulkHydrogen/BulkHydrogenExampleModule.vue";

import StandardModuleExample from "../views/StandardModule/StandardModuleExample.vue";
import StandardModuleOverview from "../views/StandardModule/Overview.vue";
import StandardModuleGrafana from "../views/StandardModule/Grafana.vue";
import StandardModuleHistory from "../views/StandardModule/History.vue";

import { PAGE_NAME } from "../enums/pagePermission";
import { mapRoutes } from "../enums/mapRoutes";
import { USER_TYPE } from "../enums/user";

import store from "../store/index";
import NotifiationGroupRoute from "./NotificationGroupRoute";
import LayoutCostomisationRoute from "./LayoutCustomisationRoute";
import AlertAndNotification from "./AlertAndNotification";
import ShipToStatusRoute from "./ShipToStatusRoute";
import SoldToMangementRoute from "./SoldToMangementRoute";
import StandardModuleRoute from "./StandardModuleRoute";
import MarketplaceRoute from "./MarketplaceRoute";
import StaffRoute from "./StaffRoute";
import {
	beforeEnterMaintenanceModePage,
	beforeEnterConsumptionApprovalPage
} from "../middleware/guard";
import { getRedirectRouteName } from "../selectors/helpers/permission";

const {
	DASHBOARD,
	FLOWDIAGRAM_LIST,
	FLOWDIAGRAM_CREATE,
	FLOWDIAGRAM_EDIT,
	FLOWDIAGRAM_VIEW,
	MONITOROVERALL,
	MONITORDEVICEHEALTH,
	MONITORUSER,
	CUSTOMERS,
	CUSTOMER_CREATE,
	CUSTOMER_EDIT,
	ACTIVITYLOG,
	TANKINFO,
	TANKTRIPINFO,
	TANKHISTORY,
	TANK_CONSUMPTION_HISTORY,
	METERINFO,
	METERDAILYCONSUMPTION,
	METERCONSUMPTIONAPPROVAL,
	METERHISTORY,
	CERINFO,
	ZLDINFO,
	NITRO_FAS_INFO,
	NITRO_FAS_HISTORY,
	O2_AERATION_INFO,
	O2_AERATION_LAST_MONTH,
	O2_AERATION_HISTORY,
	ZLD_VINY_THAI_INFO,
	GENGAS_INFO,
	GENGAS_HISTORY,
	PWR_INFO,
	PWR_HISTORY,
	PWR_KWH,
	SOR_INFO,
	SOR_HISTORY,
	SOR_KWH,
	LOGIN_PAGE,
	PASSWORD_FORGOT,
	PASSWORD_RESET,
	PASSWORD_CHANGE,
	LNG_INFO,
	LNG_TRIPINFO,
	LNG_HISTORY,
	MAINTENANCE,
	MAINTENANCE_MODE,
	PERMISSION_REQUIRED,
	BHY_INFO,
	BHY_TRIP_INFO,
	BHY_HISTORY,
	E_ORDERING_LIST,
	E_ORDERING_INFO,
	E_ORDERING_CREATE,
	E_ORDERING_EDIT,
	E_ORDERING_PENDING_ORDER_LIST,
	FURNACE_INFO,
	FURNACE_OVERVIEW,
	FURNACE_HISTORY,
	FURNACE_REPORT,
	AIR_COMP_INFO,
	AIR_COMP_HISTORY,
	GENGAS_POWERBI,
	TANK_POWERBI,
	METER_POWERBI,
	BULKHYDROGEN_POWERBI,
	NITRO_FAS_POWERBI,
	CER_POWERBI,
	O2A_POWERBI,
	ZLD_POWERBI,
	ZLD_VINY_THAI_POWERBI,
	PWR_POWERBI,
	LNG_POWERBI,
	SOR_POWERBI,
	FURNACE_POWERBI,
	AIRCOMP_POWERBI,
	POWERBI,
	GRAFANA_MANAGEMENT,
	MARKETPLACE_MODULE_GROUP_DETAIL,
	BIG_STORE_BANNER,
	BIG_STORE_BANNER_CREATE,
	BIG_STORE_BANNER_EDIT,
	BIG_STORE_OUR_SERVICE,
	BIG_STORE_OUR_SERVICE_CREATE,
	BIG_STORE_OUR_SERVICE_EDIT,
	BIG_STORE_OUR_DEVICE,
	BIG_STORE_OUR_DEVICE_CREATE,
	BIG_STORE_OUR_DEVICE_EDIT,
	MARKETPLACE_EXAMPLE_MODULE,
	EX_MODULE_GENGAS,
	EX_MODULE_GENGAS_INFO,
	EX_MODULE_GENGAS_HISTORY,
	EX_MODULE_GENGAS_POWERBI,
	EX_MODULE_AIRCOMP,
	EX_MODULE_AIRCOMP_INFO,
	EX_MODULE_AIRCOMP_HISTORY,
	EX_MODULE_AIRCOMP_POWERBI,
	EX_MODULE_FURNACE,
	EX_MODULE_FURNACE_INFO,
	EX_MODULE_FURNACE_OVERVIEW,
	EX_MODULE_FURNACE_HISTORY,
	EX_MODULE_FURNACE_REPORT,
	EX_MODULE_FURNACE_POWERBI,
	EX_MODULE_METER,
	EX_MODULE_METER_INFO,
	EX_MODULE_METER_DAILY_CONSUMPTION,
	EX_MODULE_METER_CONSUMPTION_APPROVAL,
	EX_MODULE_METER_HISTORY,
	EX_MODULE_METER_POWERBI,
	EX_MODULE_NITRO,
	EX_MODULE_NITRO_INFO,
	EX_MODULE_NITRO_HISTORY,
	EX_MODULE_NITRO_POWERBI,
	EX_MODULE_O2A,
	EX_MODULE_O2A_INFO,
	EX_MODULE_O2A_LAST_MONTH,
	EX_MODULE_O2A_HISTORY,
	EX_MODULE_O2A_POWERBI,
	EX_MODULE_PWR,
	EX_MODULE_PWR_INFO,
	EX_MODULE_PWR_KWH,
	EX_MODULE_PWR_HISTORY,
	EX_MODULE_PWR_POWERBI,
	EX_MODULE_SOR,
	EX_MODULE_SOR_INFO,
	EX_MODULE_SOR_HISTORY,
	EX_MODULE_SOR_KWH,
	EX_MODULE_SOR_POWERBI,
	EX_MODULE_TANK,
	EX_MODULE_TANK_INFO,
	EX_MODULE_TANK_TRIP_INFO,
	EX_MODULE_TANK_ORDERS,
	EX_MODULE_TANK_POWERBI,
	EX_MODULE_ZLD,
	EX_MODULE_ZLD_INFO,
	EX_MODULE_ZLD_LAST_MONTH,
	EX_MODULE_ZLD_POWERBI,
	EX_MODULE_ZLD_VINY_THAI,
	EX_MODULE_ZLD_VINY_THAI_INFO,
	EX_MODULE_ZLD_VINY_THAI_CONSUMPTION_APPROVAL,
	EX_MODULE_ZLD_VINY_THAI_HISTORY,
	EX_MODULE_ZLD_VINY_THAI_POWERBI,
	EX_MODULE_CER,
	EX_MODULE_CER_INFO,
	EX_MODULE_CER_HISTORY,
	EX_MODULE_CER_POWERBI,
	EX_MODULE_LNG,
	EX_MODULE_LNG_INFO,
	EX_MODULE_LNG_TRIP_INFO,
	EX_MODULE_LNG_HISTORY,
	EX_MODULE_LNG_POWERBI,
	EX_MODULE_BHY,
	EX_MODULE_BHY_INFO,
	EX_MODULE_BHY_TRIP_INFO,
	EX_MODULE_BHY_HISTORY,
	EX_MODULE_BHY_POWERBI,
	EX_STANDARD_MODULE_PAGE,
	EX_STANDARD_MODULE_OVERVIEW,
	EX_STANDARD_MODULE_ANALYSIS,
	EX_STANDARD_MODULE_HISTORY,
	EX_STANDARD_MODULE_GRAFANA

} = PAGE_NAME;

Vue.use(VueRouter);

const routes = [
	NotifiationGroupRoute,
	LayoutCostomisationRoute,
	AlertAndNotification,
	ShipToStatusRoute,
	SoldToMangementRoute,
	...StandardModuleRoute,
	MarketplaceRoute,
	...StaffRoute,
	{
		path: "/",
		name: "homepage",
		component: Home,
		meta: {
			requireAuth: false
		}
	},
	{
		path: "/login",
		name: LOGIN_PAGE,
		component: Login,
		meta: {
			requireAuth: false
		}
	},
	{
		path: "/login/callback",
		name: "loginCallback",
		component: LoginCallback,
		meta: {
			requireAuth: false
		}
	},
	{
		path: "/dashboard",
		name: DASHBOARD,
		component: Dashboard,
		children: [
			{
				path: "module/:module",
				name: "dashboardModule",
				component: Dashboard,
				meta: {
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		},
		beforeEnter: (to, from, next) => {
			store.dispatch("activityLogAdd/addActivityLogDashboardView");
			return next();
		}
	},
	{
		path: "/diagram",
		name: FLOWDIAGRAM_LIST,
		component: FlowDiagramList,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/diagram/create",
		name: FLOWDIAGRAM_CREATE,
		component: FlowDiagramCreate,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/diagram/edit",
		name: FLOWDIAGRAM_EDIT,
		component: FlowDiagramEdit,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/diagram/view",
		name: FLOWDIAGRAM_VIEW,
		component: FlowDiagramView,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/monitor/overall",
		name: MONITOROVERALL,
		component: MonitorOverall,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/monitor/device-health",
		name: MONITORDEVICEHEALTH,
		component: MonitorDeviceHealth,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/monitor/user",
		name: MONITORUSER,
		component: MonitorUser,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/invoice",
		component: Invoice,
		name: PAGE_NAME.INVOICE,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/customers",
		name: CUSTOMERS,
		component: Customers,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/customers/create",
		name: CUSTOMER_CREATE,
		component: CustomerCreate,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/customers/:id(\\d+)",
		name: CUSTOMER_EDIT,
		component: CustomerEdit,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/activitylog",
		name: ACTIVITYLOG,
		component: Activitylog,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/tank/:id(\\d+)",
		component: Tank,
		children: [
			{
				path: "info",
				name: TANKINFO,
				component: TankInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "trip-info",
				name: TANKTRIPINFO,
				component: TripInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "consumption-history",
				name: TANK_CONSUMPTION_HISTORY,
				component: TankConsumptionHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "orders",
				name: TANKHISTORY,
				component: TankOrder,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: TANK_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/meter/:id(\\d+)",
		component: Meter,
		children: [
			{
				path: "info",
				name: METERINFO,
				component: MeterInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "trip-info",
				name: "meterTripInfo",
				component: TripInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "consumption-approval",
				name: METERCONSUMPTIONAPPROVAL,
				component: MeterConsumptionApproval,
				beforeEnter: beforeEnterConsumptionApprovalPage(store),
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "daily-consumption",
				name: METERDAILYCONSUMPTION,
				component: MeterDailyConsumption,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "orders",
				name: METERHISTORY,
				component: MeterOrder,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: METER_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/zld/:id(\\d+)",
		component: Zld,
		children: [
			{
				path: "info",
				name: ZLDINFO,
				component: ZldInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "last-month",
				name: "zldLastMonth",
				component: ZldLastMonth,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "grafana",
				name: "zldGrafana",
				component: ZldGrafana,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: ZLD_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/zld2/:id(\\d+)",
		component: ZldVinyThai,
		children: [
			{
				path: "info",
				name: ZLD_VINY_THAI_INFO,
				component: ZldVinyThaiInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "consumption-approval",
				name: "zldVinyThaiConsumption",
				component: ZldVinyThaiConsumptionApproval,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "orders",
				name: "zldOrder",
				component: ZldVinyThaiOrder,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: ZLD_VINY_THAI_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/gengas/:id(\\d+)",
		component: GenGas,
		children: [
			{
				path: "info",
				name: GENGAS_INFO,
				component: GenGasInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "orders",
				name: GENGAS_HISTORY,
				component: GenGasHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: GENGAS_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/cer/:id(\\d+)",
		component: Cer,
		children: [
			{
				path: "info",
				name: CERINFO,
				component: CerInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "history",
				name: "CerHistory",
				component: CerHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: CER_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/nitro/:id(\\d+)",
		component: Nitro,
		children: [
			{
				path: "info",
				name: NITRO_FAS_INFO,
				component: NitroFASInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},

			{
				path: "history",
				name: NITRO_FAS_HISTORY,
				component: NitroFASHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: NITRO_FAS_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/pwr/:id(\\d+)",
		component: Pwr,
		children: [
			{
				path: "info",
				name: PWR_INFO,
				component: PwrInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "history",
				name: PWR_HISTORY,
				component: PwrHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "kwh",
				name: PWR_KWH,
				component: PwrKwh,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: PWR_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/sor/:id(\\d+)",
		component: Sor,
		children: [
			{
				path: "info",
				name: SOR_INFO,
				component: SorInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "history",
				name: SOR_HISTORY,
				component: SorHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "kwh",
				name: SOR_KWH,
				component: SorKwh,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: SOR_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/lng/:id(\\d+)",
		component: Lng,
		children: [
			{
				path: "info",
				name: LNG_INFO,
				component: LngInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "trip-info",
				name: LNG_TRIPINFO,
				component: LngTripInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "orders",
				name: LNG_HISTORY,
				component: LngOrder,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: LNG_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/bhy/:id(\\d+)",
		component: BulkHydrogen,
		children: [
			{
				path: "info",
				name: BHY_INFO,
				component: BulkHydrogenInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "trip-info",
				name: BHY_TRIP_INFO,
				component: BulkHydrogenTripInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "orders",
				name: BHY_HISTORY,
				component: BulkHydrogenOrder,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: BULKHYDROGEN_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/password",
		component: PasswordPage,
		children: [
			{
				path: "forgot",
				name: PASSWORD_FORGOT,
				component: ForgotPassword,
				meta: {
					requireAuth: false
				}
			},
			{
				path: "reset",
				name: PASSWORD_RESET,
				component: ResetPassword,
				meta: {
					requireAuth: false
				}
			},
			{
				path: "change-password",
				name: PASSWORD_CHANGE,
				component: ChangePassword,
				meta: {
					requireAuth: false
				}
			}
		],
		meta: {
			requireAuth: false
		}
	},
	{
		path: "/dashboard/module/o2-aeration/:id(\\d+)",
		component: O2Aeration,
		children: [
			{
				path: "info",
				name: O2_AERATION_INFO,
				component: O2AeationInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "last-month",
				name: O2_AERATION_LAST_MONTH,
				component: O2AerationLastmonth,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "history",
				name: O2_AERATION_HISTORY,
				component: O2AerationHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: O2A_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/furnace/:id(\\d+)",
		component: Furnace,
		children: [
			{
				path: "info",
				name: FURNACE_INFO,
				component: FurnaceInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "overview",
				name: FURNACE_OVERVIEW,
				component: FurnaceOverview,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "history",
				name: FURNACE_HISTORY,
				component: FurnaceHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "report",
				name: FURNACE_REPORT,
				component: FurnaceReport,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: FURNACE_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/dashboard/module/air-comp/:id(\\d+)",
		component: AirComp,
		children: [
			{
				path: "info",
				name: AIR_COMP_INFO,
				component: AirCompInfo,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "history",
				name: AIR_COMP_HISTORY,
				component: AirCompHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: AIRCOMP_POWERBI,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/maintenance",
		name: MAINTENANCE,
		component: Maintenance,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/e-ordering",
		name: E_ORDERING_LIST,
		component: EOrdering,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/e-ordering/info/:id",
		name: E_ORDERING_INFO,
		component: EOrderingInfo,
		meta: {
			backTo: {
				name: E_ORDERING_LIST
			},
			requireAuth: true
		}
	},
	{
		path: "/e-ordering/create",
		name: E_ORDERING_CREATE,
		component: EOrderingCreate,
		meta: {
			backTo: {
				name: E_ORDERING_LIST
			},
			requireAuth: true
		}
	},
	{
		path: "/e-ordering/edit/:id",
		name: E_ORDERING_EDIT,
		component: EOrderingEdit,
		meta: {
			backTo: {
				name: E_ORDERING_LIST
			},
			requireAuth: true
		}
	},
	{
		path: "/e-ordering/pending-order",
		name: E_ORDERING_PENDING_ORDER_LIST,
		component: EOrderingPendingOrderList,
		meta: {
			backTo: {
				name: E_ORDERING_LIST
			},
			requireAuth: true
		}
	},
	{
		path: "/executive-report",
		name: POWERBI,
		component: PowerBIExecutive,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/grafana-management",
		name: GRAFANA_MANAGEMENT,
		component: GrafanaManagement,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-banner",
		name: BIG_STORE_BANNER,
		component: BigStoreBanner,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-banner/create",
		name: BIG_STORE_BANNER_CREATE,
		component: BigStoreBannerCreate,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-banner/:id(\\d+)",
		name: BIG_STORE_BANNER_EDIT,
		component: BigStoreBannerEdit,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-our-service",
		name: BIG_STORE_OUR_SERVICE,
		component: BigStoreOurService,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-our-device",
		name: BIG_STORE_OUR_DEVICE,
		component: BigStoreOurDevice,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-our-service/create",
		name: BIG_STORE_OUR_SERVICE_CREATE,
		component: BigStoreOurServiceCreate,
		meta: {
			requireAuth: true
		}
	},
	{

		path: "/big-store-our-device/create",
		name: BIG_STORE_OUR_DEVICE_CREATE,
		component: BigStoreOurDeviceCreate,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-our-service/:id(\\d+)",
		name: BIG_STORE_OUR_SERVICE_EDIT,
		component: BigStoreOurServiceEdit,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/big-store-our-device/:id(\\d+)",
		name: BIG_STORE_OUR_DEVICE_EDIT,
		component: BigStoreOurDeviceEdit,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/marketplace/module/:module",
		name: MARKETPLACE_MODULE_GROUP_DETAIL,
		component: ModuleGroupDetail,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/marketplace/example-module/:id(\\d+)",
		name: MARKETPLACE_EXAMPLE_MODULE,
		component: ExampleModule,
		children: [
			{
				path: "standard-module/:service/:app/:templateId(\\d+)",
				name: EX_STANDARD_MODULE_PAGE,
				component: StandardModuleExample,
				children: [
					{
						path: "overview",
						name: EX_STANDARD_MODULE_OVERVIEW,
						component: StandardModuleOverview,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_STANDARD_MODULE_HISTORY,
						component: StandardModuleHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_STANDARD_MODULE_ANALYSIS,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "grafana",
						name: EX_STANDARD_MODULE_GRAFANA,
						component: StandardModuleGrafana,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				],
				meta: {
					requireAuth: true
				}
			},
			{
				path: "gengas/:service(\\d+)",
				name: EX_MODULE_GENGAS,
				component: GenGasExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_GENGAS_INFO,
						component: GenGasInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_MODULE_GENGAS_HISTORY,
						component: GenGasHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_GENGAS_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "air/:service(\\d+)",
				name: EX_MODULE_AIRCOMP,
				component: AirCompExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_AIRCOMP_INFO,
						component: AirCompInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_MODULE_AIRCOMP_HISTORY,
						component: AirCompHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_AIRCOMP_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "fur/:service(\\d+)",
				name: EX_MODULE_FURNACE,
				component: FurnaceExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_FURNACE_INFO,
						component: FurnaceInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "overview",
						name: EX_MODULE_FURNACE_OVERVIEW,
						component: FurnaceOverview,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_MODULE_FURNACE_HISTORY,
						component: FurnaceHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "report",
						name: EX_MODULE_FURNACE_REPORT,
						component: FurnaceReport,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_FURNACE_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "meter/:service(\\d+)",
				name: EX_MODULE_METER,
				component: MeterExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_METER_INFO,
						component: MeterInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "consumption-approval",
						name: EX_MODULE_METER_CONSUMPTION_APPROVAL,
						component: MeterConsumptionApproval,
						beforeEnter: beforeEnterConsumptionApprovalPage(store),
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "daily-consumption",
						name: EX_MODULE_METER_DAILY_CONSUMPTION,
						component: MeterDailyConsumption,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "orders",
						name: EX_MODULE_METER_HISTORY,
						component: MeterOrder,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_METER_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "nitro/:service(\\d+)",
				name: EX_MODULE_NITRO,
				component: NitroFASExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_NITRO_INFO,
						component: NitroFASInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},

					{
						path: "history",
						name: EX_MODULE_NITRO_HISTORY,
						component: NitroFASHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_NITRO_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "o2-aeration/:service(\\d+)",
				name: EX_MODULE_O2A,
				component: O2AerationExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_O2A_INFO,
						component: O2AeationInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "last-month",
						name: EX_MODULE_O2A_LAST_MONTH,
						component: O2AerationLastmonth,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_MODULE_O2A_HISTORY,
						component: O2AerationHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_O2A_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "pwr/:service(\\d+)",
				name: EX_MODULE_PWR,
				component: PwrExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_PWR_INFO,
						component: PwrInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_MODULE_PWR_HISTORY,
						component: PwrHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "kwh",
						name: EX_MODULE_PWR_KWH,
						component: PwrKwh,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_PWR_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "sor/:service(\\d+)",
				name: EX_MODULE_SOR,
				component: SorExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_SOR_INFO,
						component: SorInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_MODULE_SOR_HISTORY,
						component: SorHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "kwh",
						name: EX_MODULE_SOR_KWH,
						component: SorKwh,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_SOR_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "tank/:service(\\d+)",
				name: EX_MODULE_TANK,
				component: TankExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_TANK_INFO,
						component: TankInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "trip-info",
						name: EX_MODULE_TANK_TRIP_INFO,
						component: TripInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "orders",
						name: EX_MODULE_TANK_ORDERS,
						component: TankOrder,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_TANK_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "zld/:service(\\d+)",
				name: EX_MODULE_ZLD,
				component: ZldExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_ZLD_INFO,
						component: ZldInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "last-month",
						name: EX_MODULE_ZLD_LAST_MONTH,
						component: ZldLastMonth,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_ZLD_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "zld2/:service(\\d+)",
				name: EX_MODULE_ZLD_VINY_THAI,
				component: Zld2ExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_ZLD_VINY_THAI_INFO,
						component: ZldVinyThaiInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "consumption-approval",
						name: EX_MODULE_ZLD_VINY_THAI_CONSUMPTION_APPROVAL,
						component: ZldVinyThaiConsumptionApproval,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "orders",
						name: EX_MODULE_ZLD_VINY_THAI_HISTORY,
						component: ZldVinyThaiOrder,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_ZLD_VINY_THAI_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "cer/:service(\\d+)",
				name: EX_MODULE_CER,
				component: CerExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_CER_INFO,
						component: CerInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "history",
						name: EX_MODULE_CER_HISTORY,
						component: CerHistory,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_CER_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "lng/:service(\\d+)",
				name: EX_MODULE_LNG,
				component: LngExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_LNG_INFO,
						component: LngInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "trip-info",
						name: EX_MODULE_LNG_TRIP_INFO,
						component: LngTripInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "orders",
						name: EX_MODULE_LNG_HISTORY,
						component: LngOrder,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_LNG_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			},
			{
				path: "bhy/:service(\\d+)",
				name: EX_MODULE_BHY,
				component: BulkHydrogenExampleModule,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				},
				children: [
					{
						path: "info",
						name: EX_MODULE_BHY_INFO,
						component: BulkHydrogenInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "trip-info",
						name: EX_MODULE_BHY_TRIP_INFO,
						component: BulkHydrogenTripInfo,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "orders",
						name: EX_MODULE_BHY_HISTORY,
						component: BulkHydrogenOrder,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					},
					{
						path: "analysis",
						name: EX_MODULE_BHY_POWERBI,
						component: PowerBI,
						meta: {
							backTo: {
								name: DASHBOARD
							},
							requireAuth: true
						}
					}
				]
			}

		],
		meta: {
			requireAuth: true
		}

	},
	{
		path: "/permission",
		component: Permission,
		name: PERMISSION_REQUIRED,
		beforeEnter: (to, from, next) => {
			// if params was not set, redirect to 404 pages
			if (Object.keys(to.query).length === 0) {
				return next({
					path: "/404"
				});
			}

			// if params is empty, redirect to 404 pages
			for (const key of Object.keys(to.query)) {
				if (to.query[key] === null || to.query[key] === "") {
					return next({
						path: "/404"
					});
				}
			}

			return next();
		}
	},
	{
		path: "/502",
		component: Page502,
		name: "page502"
	},
	{
		// this route use to handle external links such as: line notification apis
		path: "/goto",
		beforeEnter: (to, from, next) => {
			return next({
				name: mapRoutes[to.query.app] || PAGE_NAME.DASHBOARD,
				params: {
					id: to.query.id
				},
				query: {
					...to.query
				}
			});
		}
	},
	{
		path: "/under-maintenance",
		component: MaintenanceMode,
		name: MAINTENANCE_MODE,
		beforeEnter: beforeEnterMaintenanceModePage(store)
	},
	{
		path: "/*",
		component: Page404,
		name: "page404",
		beforeEnter: (to, from, next) => {
			if (to.path !== "/404") {
				return next({
					path: "/404"
				});
			}
			return next();
		}
	}
];

const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	mode: "history",
	routes
});

export const authManagement = (vuexStore) => async (to, from, next) => {
	try {
		const user = vuexStore.state.user;

		if (!user.isLoggedIn) {
			await vuexStore.dispatch("user/getUserInfo"); // getLogin Info
		}
		const pagePermission = vuexStore.state.pagePermission;
		const permission = pagePermission[to.name] || [];
		const userHasPermission = user.rolePermissions.some((rolePermission) => {
			return permission.includes(rolePermission);
		});
		const isAllow = permission.length > 0 ? (permission.includes(user.role) || userHasPermission) : true;
		if (to.name === "login" && user.isLoggedIn) {
			const redirectToRouteName = getRedirectRouteName(user.rolePermissions);
			return next({
				name: redirectToRouteName
			});
		}

		if (to.meta.requireAuth && to.meta.requireAuth !== user.isLoggedIn) {
			return next({
				path: "/login",
				query: {
					route: to.name,
					p: {
						id: to.query.id,
						...to.params
					},
					q: {
						...to.query
					}
				}
			});
		}

		// we have to redirect to permision page when
		// user logged in but don't have permission to access or route is disabled
		if ((user.isLoggedIn && !isAllow) || to.meta.isDisabled) {
			return next({
				path: "/permission",
				query: {
					requireAuth: true
				}
			});
		}

		// If under maintenance, redirect to under maintenance page for customer only
		if (to.name !== PAGE_NAME.MAINTENANCE_MODE) {
			// use callback instead of async await because
			// async await in beforeEach do not works with vue-router ^3.1.3
			// see similar case on github: https://github.com/ngrx/platform/issues/354
			vuexStore.dispatch("maintenanceStatus/getMaintenanceStatus").then(() => {
				const maintenanceStatus = vuexStore.state.maintenanceStatus.data;
				if (user.profile.type === USER_TYPE.CUSTOMER && maintenanceStatus.status) {
					next({
						path: "/under-maintenance"
					});
				}
			});
		}

		return next();
	} catch (e) {
		return next({
			path: "/permission"
		});
	}
};

router.beforeEach(authManagement(store));

export default router;
