import NotificationGroup from "../../views/NotificationGroup/index.vue";
import NotificationGroupLists from "../../views/NotificationGroup/list.vue";
import NotificationCreate from "../../views/NotificationGroup/Create.vue";
import NotificationEdit from "../../views/NotificationGroup/Edit.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

const {
	NOTIFICATION_GROUP,
	NOTIFICATION_GROUP_LIST,
	NOTIFICATION_GROUP_CREATE,
	NOTIFICATION_GROUP_EDIT
} = PAGE_NAME;


export default {
	path: "/notification-group",
	name: NOTIFICATION_GROUP,
	component: NotificationGroup,
	children: [
		{
			path: "list",
			name: NOTIFICATION_GROUP_LIST,
			component: NotificationGroupLists,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "create",
			name: NOTIFICATION_GROUP_CREATE,
			component: NotificationCreate,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "edit/:id",
			name: NOTIFICATION_GROUP_EDIT,
			component: NotificationEdit,
			meta: {
				requireAuth: true
			}
		}
	],
	meta: {
		requireAuth: true
	}
};