<template>
	<div ref="map" class="ship-to-map"></div>
</template>

<script>
export default {
	name: "ShipToMap",

	data() {
		return {
			map: null,
			marker: null
		};
	},

	props: {
		lat: {
			type: Number,
			default: null
		},

		lng: {
			type: Number,
			default: null
		}
	},

	mounted() {
		this.map = new google.maps.Map(this.$refs.map, {
			zoom: 16,
			scrollwheel: false,
			mapTypeControl: false,
			streetViewControl: false,
			disableDoubleClickZoom: true
		});

		if (this.mapLatLng) {
			const { lat, lng } = this.mapLatLng;

			this.map.setCenter(new google.maps.LatLng(lat, lng));
			this.marker = new google.maps.Marker({
				map: this.map,
				position: this.mapLatLng,
				icon: {
					url: "/assets/images/company-pin.svg",
					size: new google.maps.Size(37, 43),
					scaledSize: new google.maps.Size(37, 43),
					anchor: new google.maps.Point(18.5, 21.5)
				},
				optimized: false
			});
		}
	},

	computed: {
		mapLatLng() {
			if (this.lat && this.lng) {
				return {
					lat: this.lat,
					lng: this.lng
				};
			}

			return null;
		}
	}
};
</script>

<style lang="scss" scoped>
.ship-to-map {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: rem(250);

	@include desktop-large {
		min-height: auto;
	}
}
</style>
