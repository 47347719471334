<template>
	<modal :name="modalName" :scrollable="true" width="390" height="auto">
		<BaseModalContent>
			<template v-slot:header>
				Contact BIG customer support
				<span class="close-button" @click="hideModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-body">
					<div class="message">
						Please contact our customer support team for support
					</div>
					<div class="contact-list">
						<div class="contact-item email">
							<div class="type">
								<FontAwesomeIcon :icon="['far', 'envelope']" class="icon"/>
							</div>
							<div class="text">
								<a href="mailto: customer.support@bigth.com">
									dispatcher@bigth.com
								</a>
							</div>
						</div>
						<div class="contact-item tel">
							<div class="type">
								<FontAwesomeIcon :icon="['far', 'phone-alt']" class="icon"/>
							</div>
							<div class="text">
								098 258 5346
							</div>
						</div>
					</div>
				</div>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	name: "ModalContactBigth",

	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		}
	},

	methods: {
		hideModal() {
			this.$modal.hide(this.modalName);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-body {
	display: flex;
	flex-direction: column;

	.message {
		margin-bottom: $spacing-3;
		color: $color-dark-blue-grey;
	}

	.contact {
		&-list {
			display: flex;
			flex-direction: column;
		}

		&-item {
			display: flex;
			margin-bottom: $spacing-3;

			&:last-of-type {
				margin-bottom: 0;
			}

			&.email {
				.icon {
					position: relative;
					top: rem(-1);
				}
			}

			.type {
				width: rem(24);
				height: rem(24);
				margin-right: $spacing-3;
				background-color: $color-green-haze;
				border-radius: 50%;

				text-align: center;

				.icon {
					color: $color-white;
					font-size: $font-12;
				}
			}

			.text {
				display: flex;
				flex: 1;

				a {
					color: $color-cerulean;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}
</style>