import { getGenGasConsumptionAPI } from "@/services/api/genGas.api";
import { parseGenGasConsumptionChart } from "../../../services/api/transforms/parseChartData";
import {
	GENGAS_CONSUMPTION_REQUEST,
	GENGAS_CONSUMPTION_SUCCESS,
	GENGAS_CONSUMPTION_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		empty: true
	},
	getters: {
		getConsumptionData(state) {
			return state.data;
		},
		getBaseDatas(state) {
			return state.data.map(({ date, totalBase }) => {
				return {
					date,
					value: totalBase
				};
			});
		},
		getPremiumDatas(state) {
			return state.data.map(({ date, totalPremium }) => {
				return {
					date,
					value: totalPremium
				};
			});
		}
	},

	mutations: {
		[GENGAS_CONSUMPTION_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[GENGAS_CONSUMPTION_SUCCESS](state, response) {
			state.data = parseGenGasConsumptionChart(response.data);
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
			state.isEmpty = response.data.length === 0;
		},
		[GENGAS_CONSUMPTION_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchGenGasConsumption({ commit }, { id = "", filterBy = "7d" }) {
			try {
				commit(GENGAS_CONSUMPTION_REQUEST);
				const { data = [] } = await getGenGasConsumptionAPI(id, { filter: filterBy });

				commit(GENGAS_CONSUMPTION_SUCCESS, data);
			} catch (error) {
				commit(GENGAS_CONSUMPTION_FAILURE);
			}
		}
	}
};
