<template>
	<div class="container-device">
		<section
			v-for="(content, indexContent) in contents"
			:key="indexContent"
		>
			<div class="row d-flex module-title">
				<div class="col-12">
					{{ content.name }}
				</div>
			</div>
			<div class="row d-flex flex-wrap device-items">
				<div
					v-for="(item, index) in content.items"
					:key="index"
					:class="['col-4 item', { active: item.id === (form && form.selectedItem && form.selectedItem.id) }]"
					@click="onClickDevice(item)"
				>
					<!-- summary -->
					<div
						v-if="['summary_electricity', 'summary_meter'].includes(item.type)"
						class="box summary"
					>
						<div class="title">
							<span>
								Summary
							</span>
							<BaseFormInput
								v-if="item.type === 'summary_electricity'"
								v-model="input.summaryElectricity"
								:maxLength="20"
								placeholder="e.g. route 1"
								class="custom-input"
							/>
							<BaseFormInput
								v-if="item.type === 'summary_meter'"
								v-model="input.summaryMeter"
								:maxLength="20"
								placeholder="e.g. route 1"
								class="custom-input"
							/>
						</div>
						<div class="sub-title">
							<span class="icon"></span>
							<span class="name">
								{{ item.sub.name }}
							</span>
						</div>
						<div class="tail">
							<div class="value">
								{{ item.value }}
							</div>
							<div class="info">
								<div
									v-for="(itemInfo, indexInfo) in item.info"
									:key="indexInfo"
									class="section"
								>
									<div class="label" v-html="itemInfo.label"></div>
									<div class="value">
										{{ itemInfo.value }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Device -->
					<div
						v-if="['device_power', 'device_solor', 'device_meter', 'device_nitrofas', 'device_gengas'].includes(item.type)"
						:title="item.head.id"
						class="box device"
					>
						<div class="title">
							<strong>
								{{ item.head.name }}
							</strong>
							<span>
								{{ item.head.id }}
							</span>
						</div>
						<div class="sub-title">
							<span
								v-if="item.sub.icon === 'power'"
								class="icon power"
								v-html="dashboardSvgString(item.sub.value)"
							/>
							<span
								v-if="item.sub.icon === 'meter'"
								class="icon meter"
								v-html="circleProgressSvgString(item.sub.value)"
							/>
							<span
								v-if="item.sub.icon === 'nitrofas'"
								class="icon nitrofas"
								v-html="sevenSegmentSvgString()"
							/>
							<span
								v-if="item.sub.icon === 'gengas'"
								class="icon gengas"
								v-html="gengasSvgString(item.sub.value)"
							/>
							<span class="name">
								{{ item.sub.name }}
							</span>
						</div>
						<div class="tail">
							<div class="value">
								{{ item.value }}
							</div>
							<div class="info">
								<div
									v-for="(itemInfo, indexInfo) in item.info"
									:key="indexInfo"
									class="section"
								>
									<div class="label" v-html="itemInfo.label"></div>
									<div class="value">
										{{ itemInfo.value }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import BaseFormInput from "@/components/BaseFormInput.vue";
import {
	dashboardSvgString,
	sevenSegmentSvgString,
	circleProgressSvgString,
	gengasSvgString
} from "../../selectors/helpers/flowDiagram";

export default {
	components: {
		BaseFormInput
	},

	props: {
		meter: {
			type: Array,
			default: () => ([])
		},
		meterCount: {
			type: Number,
			default: 0
		},
		electricity: {
			type: Array,
			default: () => ([])
		},
		electricityCount: {
			type: Number,
			default: 0
		},
		selectedModule: {
			type: String,
			default: null
		},
		form: {
			type: Object,
			default: null
		},
		node: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			dashboardSvgString,
			sevenSegmentSvgString,
			circleProgressSvgString,
			gengasSvgString,
			input: {
				summaryElectricity: null,
				summaryMeter: null
			}
		};
	},

	computed: {
		contents() {
			const results = [];
			if (this.showMeter) {
				results.push({
					name: "Nitrogen",
					items: [...this.meter]
				});
			}
			if (this.showElectricity) {
				results.push({
					name: "Power",
					items: [...this.electricity]
				});
			}
			return results;
		},
		showMeter() {
			const selected = this.selectedModule === "meter" || this.selectedModule === null;
			const hasData = this.meterCount > 0;
			return selected && hasData;
		},
		showElectricity() {
			const selected = this.selectedModule === "electricity" || this.selectedModule === null;
			const hasData = this.electricityCount > 0;
			return selected && hasData;
		}
	},

	watch: {
		input: {
			handler() {
				this.syncForm();
			},
			deep: true
		}
	},

	mounted() {
		if (this.node && this.node.type === "summary_electricity") {
			this.input.summaryElectricity = this.node.detail?.route?.name;
		} else if (this.node && this.node.type === "summary_meter") {
			this.input.summaryMeter = this.node.detail?.route?.name;
		}
	},

	methods: {
		onClickDevice(item) {
			this.selectedItem = item;
			this.syncForm();
		},
		syncForm() {
			this.$emit("update:form", {
				selectedItem: this.selectedItem,
				input: (() => {
					if (this.selectedItem && this.selectedItem.type === "summary_electricity") {
						return this.input.summaryElectricity;
					} else if (this.selectedItem && this.selectedItem.type === "summary_meter") {
						return this.input.summaryMeter;
					}
					return null;
				})()
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.container-device {
	padding: 0 rem(7) 0 rem(7);

	section {
		margin-bottom: rem(28);

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.module-title {
		margin-left: rem(-8);
		font-size: $font-20;
		font-weight: $font-weight-bold;
		color: $color-dark-blue-grey;
	}

	.device-items {
		.item {
			padding: rem(4);

			&.active {
				.box {
					border: solid 1px $color-green-haze;
				}
			}

			.box {
				height: rem(93);
				border: 1px solid #cad9cf;
				border-radius: $border-radius-normal;
				overflow: hidden;
				cursor: pointer;

				&.summary {
					display: flex;
					flex-direction: column;
					padding: rem(4) rem(12);
					background-color: $color-background;

					.title {
						display: flex;
						height: rem(24);
						line-height: rem(24);
						font-size: $font-20;
						font-family: $font-family-base;
						font-weight: $font-weight-bold;
						color: $color-grey-4;

						/* stylelint-disable */
						.custom-input {
							margin-left: rem(8);

							&::v-deep {
								input {
									height: rem(24);
									padding: 0 rem(8);
									border: solid 1px #cad9cf;
									border-radius: rem(4);
									font-size: $font-16;
								}

								.max-length-message {
									bottom: rem(-10);
									font-size: $font-14;
								}
							}
						}
						/* stylelint-enable */
					}

					.sub-title {
						height: rem(14);
						line-height: rem(14);
						margin-top: rem(8);
						font-size: $font-14;
						font-weight: $font-weight-bold;
						font-family: $font-family-base;
						color: $color-dark-blue-grey;
					}

					.tail {
						display: flex;

						> .value {
							width: rem(80);
							font-size: $font-32;
							font-weight: $font-weight-bold;
							font-family: $font-family-sub;
							color: $color-dark-blue-grey;
						}

						.info {
							flex: 1;
							display: flex;
							flex-direction: column;

							.section {
								display: flex;
								align-items: baseline;
								height: rem(16);
								line-height: rem(16);

								.label {
									width: 80%;
									font-size: $font-14;
									font-family: $font-family-base;
									color: $color-dark-blue-grey;

									.sub {
										position: relative;
										top: rem(-4);
										font-size: $font-16;
										font-family: $font-family-base;
										color: $color-dark-blue-grey;
									}
								}

								.value {
									flex: 1;
									text-align: right;
								}
							}
						}
					}
				}

				&.device {
					display: flex;
					flex-direction: column;

					.title {
						display: flex;
						align-items: baseline;
						height: rem(28);
						padding: 0 rem(12);
						background-color: $color-alabaster;

						strong {
							font-size: $font-20;
							font-weight: $font-weight-bold;
							font-family: $font-family-sub;
							color: $color-dark-blue-grey;
						}

						span {
							@include ellipsis(1);

							font-size: $font-14;
							font-weight: $font-weight-bold;
							font-family: $font-family-base;
							color: $color-grey-4;
							margin-left: rem(4);
						}
					}

					.sub-title {
						display: flex;
						align-items: center;
						height: rem(14);
						line-height: rem(14);
						padding: 0 rem(12);
						margin-top: rem(8);
						font-size: $font-14;
						font-weight: $font-weight-bold;
						font-family: $font-family-base;
						color: $color-dark-blue-grey;

						.icon {
							display: flex;
							position: relative;
							margin-right: rem(4);

							&.meter {
								top: rem(-4);
							}
						}
					}

					.tail {
						display: flex;
						padding: 0 rem(12);

						> .value {
							display: flex;
							align-items: center;
							width: rem(80);
							height: rem(38);
							font-size: $font-32;
							font-weight: $font-weight-bold;
							font-family: $font-family-sub;
							color: $color-dark-blue-grey;
						}

						.info {
							flex: 1;
							display: flex;
							flex-direction: column;

							.section {
								display: flex;
								align-items: baseline;
								height: rem(16);
								line-height: rem(16);

								.label {
									width: 80%;
									font-size: $font-14;
									font-family: $font-family-base;
									color: $color-dark-blue-grey;

									.sub {
										position: relative;
										top: rem(-4);
										font-size: $font-16;
										font-family: $font-family-base;
										color: $color-dark-blue-grey;
									}
								}

								.value {
									flex: 1;
									text-align: right;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>