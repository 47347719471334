<template>
	<div class="no-gutter-top">
		<BaseLoading v-if="meterInfo.isLoading" />

		<div :class="['row row-eq-height', { 'hide-content': meterInfo.isLoading }]">
			<div class="col-12 col-xl-7 no-gutter-sm">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ meterInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview
							v-if="meterInfo.data.tagNo"
							:data="meterInfo.data"
						/>
						<BaseNoData
							v-else
							description="BIG portal will start showing the overview right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
			<ModuleLocation :info="meterInfo.data" />
		</div>

		<div class="meter-level-container">
			<div class="detail-card-header">
				<div v-if="showLastUpdate" class="last-update">
					Last update: {{ meterGasFlowRate.lastUpdate | dateTimeFormat  }}
				</div>
				<BaseDatePicker
					v-model="pickerValue"
					:min-date="gasFlowRateFilterOptions.date.picker.minDate"
					:max-date="gasFlowRateFilterOptions.date.picker.maxDate"
					:mode="gasFlowRateFilterOptions.date.picker.mode"
					size="small"
					position="right"
					class="custom-date-picker"
					@done="handleGasFlowRateDateSelected"
				/>
				<span>
					{{ gasFlowRateFilterOptions.date.condition || "or" }}
				</span>
				<BaseDropdown
					:value="dropdownValue"
					:list="gasFlowRateFilterOptions.date.dropdown.items"
					:disabledItem="gasFlowRateFilterOptions.date.dropdown.disabledItems"
					:searchable="false"
					size="small"
					:placeholder="`${gasFlowRateFilterOptions.date.dropdown.placeholder || 'View by'}`"
					class="custom-date-dropdown"
					@input="handleGasFlowRateSelectDateDropDown"
				/>
			</div>
			<CardLineChart
				:chartData="getGasFlowRateChart"
				:lastUpdate="meterGasFlowRate.lastUpdate"
				:isLoading="meterGasFlowRate.isLoading"
				:isEmpty="meterGasFlowRate.isEmpty"
				chartTitle="Gas flow rate"
				type="meter"
			/>

			<CardBarChart
				:chartData="consumptionChartData"
				:lastUpdate="meterConsumption.lastUpdate"
				:isLoading="meterConsumption.isLoading"
				:isEmpty="meterConsumption.isEmpty"
				:filterOptions="consumptionFilterOptions"
				chartTitle="Consumption"
				type="meter"
				@dateSelected="handleConsumptionDateSelected"
			/>
			<GrafanaDisplay
				:deviceId="meterId"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import { transformDataBarChart } from "../../selectors/transform/barChart";
import { transformGasFlowRateLineChartConfig } from "../../selectors/transform/lineChart/MeterInfo/meterLineChart";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { transformMeterBarChart } from "../../selectors/transform/barChart/MeterInfo/meterBarChart";
import { getDateNumber, getDateDiff } from "../../selectors/helpers";
import setupDevice from "../../mixins/setupDevice";

const { perDay, perThreeDay, perWeek, perTwoWeek, perYear } = MODULE_SORT_DATE_CHART;

export default {
	name: "meterInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		BaseNoData,
		BaseDatePicker,
		ModuleOverview,
		ModuleLocation,
		CardBarChart,
		CardLineChart
	},
	mixins: [setupDevice],
	data() {
		return {
			meterId: this.$route.params.id,
			gasFlowRateSortBy: perDay,
			gasFlowRateFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},
			consumptionFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			},
			pickerValue: null,
			dropdownValue: null
		};
	},
	computed: {
		...mapState(["meterInfo", "meterConsumption", "meterGasFlowRate"]),

		...mapGetters("meterGasFlowRate", {
			getGasFlowRateData: "getGasFlowRateData"
		}),

		gasFlowRateSelected() {
			const dateRange = this.gasFlowRateFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		consumptionSelected() {
			const dateRange = this.consumptionFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getGasFlowRateChart() {
			const gasFlowRateSortBy = this.gasFlowRateSortBy;
			const { diff } = this.gasFlowRateSelected;
			return transformGasFlowRateLineChartConfig(gasFlowRateSortBy, this.getGasFlowRateData, {
				diffDay: diff
			}, this.isMobile);
		},

		consumptionChartData() {
			const dateSort = this.consumptionSelected.dateNumber;
			const dateReportFormat = [perWeek, perTwoWeek].includes(dateSort) ? "day" : "year";
			const options = { dateReportFormat };
			const xyAxis = transformDataBarChart(this.meterConsumption, options);
			return transformMeterBarChart(xyAxis);
		},
		showLastUpdate() {
			return this.meterGasFlowRate.lastUpdate;
		}
	},

	async created() {
		await this.fetchData();
		const meterRangeDate = this.gasFlowRateFilterOptions.date.selected.range;

		if (meterRangeDate) {
			const dateNumber = getDateNumber(meterRangeDate);
			this.dropdownValue = dateNumber;
		}
		this.$emit("loaded");
	},

	methods: {
		...mapActions("meterGasFlowRate", {
			fetchGasFlowRate: "fetchGasFlowRate"
		}),

		...mapActions("meterConsumption", {
			fetchConsumptionData: "fetchConsumptionData"
		}),

		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),

		fetchData() {
			const { meterId } = this;
			return Promise.all([
				this.fetchGasFlowRate({ id: meterId, query: { filter: this.gasFlowRateSelected.dateNumber } }),
				this.fetchConsumptionData({ id: meterId, filterBy: this.consumptionSelected.dateNumber })
			]);
		},

		handleGasFlowRateDateSelected(date) {
			const { start, end } = date;

			this.gasFlowRateFilterOptions.date.selected.range = {
				start,
				end
			};

			this.dropdownValue = null;
			this.gasFlowRateSortBy = "";
			this.fetchGasFlowRate({
				id: this.meterId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},

		handleGasFlowRateSelectDateDropDown(dateNumber = "1d") {
			if (dateNumber) {
				if (!this.gasFlowRateFilterOptions?.date.condition) {
					this.pickerValue = null;
				}
				this.dropdownValue = dateNumber;
				this.gasFlowRateSortBy = dateNumber;
				this.fetchGasFlowRate({
					id: this.meterId,
					query: { filter: dateNumber }
				});
			}
		},

		handleConsumptionDateSelected(date) {
			const { meterId } = this;

			this.consumptionFilterOptions.date.selected.range = date;

			this.fetchConsumptionData({
				id: meterId,
				filterBy: this.consumptionSelected.dateNumber
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^=col]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.no-gutter-top {
	margin-top: rem(-1);

	@include desktop-large {
		margin-top: 0;
	}
}

::v-deep {
	.meter-level-container {
		background-color: $color-alabaster;
		border-radius: rem(8);
		padding: rem(16);
		margin-bottom: rem(16);

		@include mobile {
			border-radius: 0;
			margin-left: rem(-16);
			margin-right: rem(-16);
			padding: rem(16) 0;
		}

		.card {
			margin-bottom: rem(16);

			@include mobile {
				border-radius: 0;
			}

			&-body {
				background-color: $color-white;
				border-bottom-left-radius: rem(4);
				border-bottom-right-radius: rem(4);
			}
		}
	}

	.detail-card-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: rem(16);

		@include mobile {
			align-items: flex-start;
			flex-direction: column;
			padding: 0 rem(16);

			.button-group-container {
				width: 100%;

				.btn {
					justify-content: center;
					width: calc(100% / 3);
				}
			}
		}

		& > * {
			margin-left: rem(10);

			@include mobile {
				margin-left: 0;
			}
		}

		span {
			color: $color-grey-4;
		}

		> span {
			@include mobile {
				display: none;
			}
		}

		.custom-date-picker {
			@include mobile {
				margin-bottom: rem(12);
			}
		}
	}

	.last-update {
		min-width: rem(170);
		font-family: $font-family-base;
		font-size: rem(18);
		font-style: italic;
		color: $color-dark-blue-grey;
		margin-right: rem(14);

		@include mobile {
			margin-right: 0;
		}
	}

	.custom-date-picker {
		min-width: rem(200);

		@include mobile {
			width: 100%;
		}

		.input {
			border-color: $color-silver;

			&, &::placeholder {
				color: $color-grey-4;
				font-size: $font-16;
				font-weight: $font-weight-bold;
			}
		}
	}

	.custom-date-dropdown {
		width: rem(130);

		@include mobile {
			width: 100%;
		}

		.dropdown-value {
			border-color: $color-silver;
			background-color: $color-white;
			padding: 0 rem(40) 0 rem(16);
		}

		.dropdown-value-text,
		.dropdown-placeholder {
			color: $color-grey-4;
			font-size: $font-16;
			font-weight: $font-weight-bold;
		}
	}
}
</style>
