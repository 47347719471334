<template>
	<div :class="['nitro-fas-wrapper', this.size]">
		<img class="image-ic" src="~@/assets/images/nitro-fas-bg.svg"/>
		<BaseSevenSegment
			:class="['seven-segment', this.size]"
			:value="value"
			:digitHeight="digitHeight"
			digits="4"
		/>
		<div :class="['unit-nitro-fas', this.size]" v-html="unit">
		</div>
	</div>
</template>

<script>
import BaseSevenSegment from "@/components/BaseSevenSegment/index.vue";

export default {
	props: {
		size: {
			String
		},
		value: {
			type: [String, Number],
			default: 0
		},
		unit: {
			String,
			default: ""
		}
	},
	computed: {
		digitHeight() {
			if (this.size === "small") {
				return "20px";
			}
			if (this.size === "large") {
				return "40px";
			}
			return "16px";
		}
	},
	components: {
		BaseSevenSegment
	}
};
</script>

<style lang="scss" scoped>
.nitro-fas-wrapper {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: rem(4);

	&.small {
		padding: rem(4);
	}

	/* stylelint-disable */
	& v::deep {
		.digits-wrapper {
			height: rem(26);
		}
	}
	/* stylelint-enable */
}

.seven-segment {
	position: absolute;

	&.large {
		top: rem(28);
		width: rem(100);
	}

	&.small {
		width: rem(56);
		top: rem(20);
	}

	&.smallest {
		width: rem(50);
		left: rem(10);
		top: rem(16);
	}
}

.unit-nitro-fas {
	@include fontBase();

	position: absolute;
	color: $color-dark-blue-grey;
	font-weight: $font-weight-bold;
	margin-top: rem(-8);
	text-align: center;

	&.large {
		top: rem(86);
		font-size: rem(26);
	}

	&.small {
		top: rem(56);
		font-size: rem(14);
	}

	&.smallest {
		top: rem(46);
		font-size: rem(12);
	}
}
</style>