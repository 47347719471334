import { dateTimeFormat } from "../../../selectors/helpers";

/**
 * Parse CER History
 * @param {Array} input activity logs
 * @returns {ActivityLogs}
 */
export function parseActivityLogs(input) {
	if (!input) {
		return [];
	}

	return input.map((item) => ({
		loggedAt: dateTimeFormat(item.loggedAt),
		email: item.email,
		ip: item.ip,
		company: item.company,
		country: item.country,
		event: item.event,
		isSuccess: item.isSuccess,
		device: item.device,
		browser: item.browser,
		osName: item.osName,
		module: item.module,
		accessBy: item.accessBy,
		shipId: item.shipId,
		action: item.action,
		note: item.note
	}));
}