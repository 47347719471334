<template>
	<div :class="[...containerClass]">
		<div class="header">
			<span v-if="title">{{ title }}</span>
			<slot v-else name="header"></slot>
		</div>
		<div class="body">
			<slot name="body"></slot>
		</div>
		<div class="footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseModalContent",

	props: {
		theme: {
			type: String,
			default: "light",
			validator: (value) => [
				"light",
				"success"
			].includes(value)
		},
		title: {
			type: String,
			default: null
		},
		rounded: {
			type: Boolean,
			default: false
		},
		hideHeader: {
			type: Boolean,
			default: false
		},
		hideBody: {
			type: Boolean,
			default: false
		},
		hideFooter: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		containerClass() {
			return [
				"container-modal",
				this.theme,
				{ rounded: this.rounded },
				{ "hide-header": this.hideHeader },
				{ "hide-body": this.hideBody },
				{ "hide-footer": this.hideFooter }
			];
		}
	}
};
</script>

<style lang="scss" scoped>
.container-modal {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: rem(4);

	&.hide-header {
		.header {
			display: none;
		}
	}

	&.hide-body {
		.body {
			display: none;
		}
	}

	&.hide-footer {
		.footer {
			display: none;
		}
	}

	&.light {
		.header {
			background: $color-alabaster;
			color: $color-dark-blue-grey;
			border-top: 1px solid $color-silver;
			border-right: 1px solid $color-silver;
			border-left: 1px solid $color-silver;

			.close-button {
				color: $color-gunmetal-two;
			}
		}
	}

	&.success {
		.header {
			background: $color-emerald;
			color: $color-white;
		}
	}

	&.small {
		max-width: rem(390);
	}

	&.medium {
		max-width: rem(600);
	}

	&.large {
		max-width: rem(996);
	}

	&.rounded {
		border-radius: $border-radius-normal;
		overflow: hidden;
	}

	.header {
		@include fontSub();

		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: rem(58);
		padding: $spacing-4 $spacing-6;
		font-size: $font-24;
		font-weight: 600;
		border-radius: rem(4) rem(4) 0 0;

		.close-button {
			color: $color-white;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: $font-18;
			cursor: pointer;
		}
	}

	.body {
		flex-grow: 1;
		border-right: 1px solid $color-silver;
		border-left: 1px solid $color-silver;
		padding: $spacing-6;
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: rem(16) rem(24);
		border-top: 1px solid $color-silver;
		border-right: 1px solid $color-silver;
		border-bottom: 1px solid $color-silver;
		border-left: 1px solid $color-silver;
		border-radius: 0 0 rem(4) rem(4);
	}
}
</style>

<style scoped>
.footer:empty {
	display: none;
}
</style>>