import { getNitroFasInfoAPI } from "@/services/api/NitroFAS.api";
import {
	NITRO_FAS_INFO_REQUEST,
	AUTO_NITRO_FAS_INFO_REQUEST,
	NITRO_FAS_INFO_SUCCESS,
	AUTO_NITRO_FAS_INFO_SUCCESS,
	NITRO_FAS_INFO_FAILURE,
	NITRO_FAS_INFO_CLEAR
} from "../../types";
import { parseNitroFASInfo } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: {},
		loading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	mutations: {
		[NITRO_FAS_INFO_REQUEST](state) {
			state.data = {};
			state.loading = true;
			state.statusCode = null;
		},
		[AUTO_NITRO_FAS_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[NITRO_FAS_INFO_SUCCESS](state, { data, status }) {
			state.data = parseNitroFASInfo(data);
			state.loading = false;
			state.statusCode = status;
		},
		[AUTO_NITRO_FAS_INFO_SUCCESS](state, { data, status }) {
			state.data = parseNitroFASInfo(data);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[NITRO_FAS_INFO_FAILURE](state, error) {
			state.data = {};
			state.loading = false;
			state.statusCode = error.response.status;
		},
		[NITRO_FAS_INFO_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async getNitroFASInfo({ commit }, { id }) {
			try {
				commit(NITRO_FAS_INFO_REQUEST);
				const response = await getNitroFasInfoAPI(id);
				commit(NITRO_FAS_INFO_SUCCESS, response);
			} catch (error) {
				commit(NITRO_FAS_INFO_FAILURE, error);
			}
		},

		async autoGetNitroFASInfo({ commit }, { id }) {
			try {
				commit(AUTO_NITRO_FAS_INFO_REQUEST);
				const response = await getNitroFasInfoAPI(id);
				commit(AUTO_NITRO_FAS_INFO_SUCCESS, response);
			} catch (error) {
				commit(NITRO_FAS_INFO_FAILURE, error);
			}
		},

		clearNitroFAS({ commit }) {
			commit(NITRO_FAS_INFO_CLEAR);
		}
	}
};
