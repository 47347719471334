<template>
	<BaseLayout>
		<BaseBackBtn class="page-back-btn" :to="PAGE_NAME.BIG_STORE_OUR_DEVICE" label="Back to Our device"/>
		<div class="wrapper-our-devices-create">
			<h1 class="create-our-devices-title">Create new device group</h1>
			<BaseCard class="card-our-devices-info">
				<template v-slot:header>
					<h4>General information</h4>
				</template>
				<template v-slot:body>
					<div>
						<BaseDropdown
							label="Group *"
							v-model="$v.groupValue.$model"
							:searchable="false"
							placeholder="Select group"
							:list="groupsOption"
							:is-error="isGroupValueError"
							:error-message="getGroupValueErrorMessage"
							@input="handleSelectGroupDropdown"
							inline
							rounded
						/>
					</div>
					<div class="input-description in-line">
						<label class="input-label">
							Description
						</label>
						<BaseTextArea
							v-model="$v.description.$model"
							:maxLength="500"
						/>
					</div>
					<BaseFormInput
						v-model="$v.color.$model"
						:inputOptions="['small']"
						:is-error="isColorError"
						:error-message="getColorErrorMessage"
						class="our-devices-create-input"
						label="Color Code *"
						placeholder="e.g. #21efbc"
						inline
					/>
					<BaseUploadFile
						label="Upload icon *"
						:is-invalid-file-type="isInvalidFileTypeIcon"
						:is-invalid-file-size="isInvalidFileSizeIcon"
						:error-message="getUploadFileMessageIcon"
						accept=".png,.svg"
						info-message="Allow only png/svg file and file size must not over 500KB. Recommended resolution is 1040 x 1040px"
						@handleFileChange="handleFileChangeIcon"
					/>
					<div :class="['control in-line',iconClass]">
						<label class="input-label">  </label>
						<img v-if="iconUrl !== ''" :src="iconUrl" height="200">
					</div>
					<BaseUploadFile
						label="Upload banner *"
						:is-invalid-file-type="isInvalidFileTypeImage"
						:is-invalid-file-size="isInvalidFileSizeImage"
						:error-message="getUploadFileMessageImage"
						accept=".png,.jpg,.jpeg"
						info-message="Allow only jpg/jpeg/png file and file size must not over 2MB. Recommended resolution is 1280 x 720px"
						@handleFileChange="handleFileChangeImage"
					/>
					<div :class="['control in-line',imageClass]">
						<label class="input-label">  </label>
						<img v-if="imageUrl !== ''" :src="imageUrl" height="200">
					</div>
					<BaseFormInput
						v-model="$v.priorityNumber.$model"
						:inputOptions="['small']"
						:is-error="isPriorityNumberError"
						:error-message="getPriorityNumberError"
						info-message="Item will be displayed ordered by priority number."
						class="our-devices-create-input"
						label="Priority number *"
						placeholder="e.g. 1, 2, 3"
						inline
					/>
					<div class="control in-line margin-switch">
						<label class="input-label"> Status </label>
						<BaseSwitch
							class="switch-status"
							:checked="statusActive"
							@input="handleChangeStatus"
						/>
						<div>{{statusActive ? "Active":"Inactive"}}</div>
					</div>
				</template>
			</BaseCard>
			<div class="wrapper-confirm-create-our-devices">
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleCancle"
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="large"
					type="primary"
					@click="handleSubmit(handleSendPayload)"
					:disabled="created"
				>
					Create device group
				</BaseButton>
			</div>
		</div>
	</BaseLayout>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import { validationMixin } from "vuelidate";
import BaseButton from "@/components/BaseButton.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import BaseUploadFile from "@/components/BaseUploadFile.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import OurDevicesValidateCustomMixin from "../../selectors/formValidate/bigStoreManagement/OurDevicesValidateCustomMixin";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { getGroupDevices } from "../../services/api/bigStoreManagement";
import { CONFIG } from "../../enums/config";

export default {
	name: "BigStoreOurDeviceCreate",

	mixins: [
		validationMixin,
		OurDevicesValidateCustomMixin
	],

	components: {
		BaseCard,
		BaseButton,
		BaseSwitch,
		BaseBackBtn,
		BaseDropdown,
		BaseTextArea,
		BaseUploadFile
	},

	data() {
		return {
			PAGE_NAME,
			imageUrl: "",
			iconUrl: "",
			fileImage: null,
			fileIcon: null,
			priorityNumber: "",
			groupValue: null,
			color: "",
			description: "",
			statusActive: true,
			isInvalidFileTypeIcon: false,
			isInvalidFileSizeIcon: false,
			isInvalidFileTypeImage: false,
			isInvalidFileSizeImage: false,
			progressUpload: 0,
			groups: [],
			created: false
		};
	},

	async created() {
		await this.getGroupDevicesAPI();
	},

	methods: {
		...mapActions("ourDevicesManagement", {
			sendCreateOurDevice: "sendCreateOurDevice"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		handleCancle() {
			this.$router.push({
				name: PAGE_NAME.BIG_STORE_OUR_DEVICE
			});
		},

		handleChangeStatus() {
			this.statusActive = !this.statusActive;
		},

		handleSubmit(callback) {
			this.validatePermissionForm();
			if (this.fileImage == null) {
				this.isInvalidFileTypeImage = true;
				this.isInvalidFileSizeImage = true;
			}
			if (this.fileIcon == null) {
				this.isInvalidFileTypeIcon = true;
				this.isInvalidFileSizeIcon = true;
			}
			if (!this.$v.$invalid && (!this.isInvalidFileTypeImage && !this.isInvalidFileSizeImage) && (!this.isInvalidFileTypeIcon && !this.isInvalidFileSizeIcon)) {
				this.created = true;
				callback();
			}
		},

		async handleSendPayload() {
			const ourDevicePayload = {
				group: this.groupValue,
				descriptions: this.description,
				img: this.fileImage,
				icon: this.fileIcon,
				color: this.color,
				priority: this.priorityNumber,
				visible: this.statusActive === true ? 1 : 0
			};
			await this.sendCreateOurDevice(ourDevicePayload, (event) => {
				this.progressUpload = Math.round((100 * event.loaded) / event.total);
			});
			if (this.ourDevicesManagement.ourDeviceCreateSuccess) {
				this.$router.push({
					name: PAGE_NAME.BIG_STORE_OUR_DEVICE
				});
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Our Device created successful."
					}
				});
			} else {
				this.created = false;
			}
		},

		handleFileChangeImage({ file }) {
			let isInvalidFileType = true;
			let isInvalidFileSize = false;
			if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
				isInvalidFileType = false;
			}
			if (file.size > CONFIG.FILE_SIZE["2MB"]) { // 2MB
				isInvalidFileSize = true;
			}
			this.isInvalidFileTypeImage = isInvalidFileType;
			this.isInvalidFileSizeImage = isInvalidFileSize;
			this.fileImage = file;
			this.imageUrl = URL.createObjectURL(file);
		},

		handleFileChangeIcon({ file }) {
			let isInvalidFileType = true;
			let isInvalidFileSize = false;
			if (file.type === "image/svg+xml" || file.type === "image/png") {
				isInvalidFileType = false;
			}
			if (file.size > CONFIG.FILE_SIZE["500KB"]) { // 500KB
				isInvalidFileSize = true;
			}
			this.isInvalidFileTypeIcon = isInvalidFileType;
			this.isInvalidFileSizeIcon = isInvalidFileSize;
			this.fileIcon = file;
			this.iconUrl = URL.createObjectURL(file);
		},

		handleSelectGroupDropdown(value) {
			this.groupValue = value;
		},

		getOurDeviceOptions(groups = []) {
			const options = groups.map((option) => {
				return { value: option, label: option };
			});
			return options;
		},

		async getGroupDevicesAPI() {
			const groupDevices = await getGroupDevices();
			this.groups = groupDevices.data.create;
		}
	},

	computed: {
		...mapState(["ourDevicesManagement"]),

		groupsOption() {
			return this.getOurDeviceOptions(this.groups);
		},

		getUploadFileMessageImage() {
			if (this.fileImage == null) {
				return "No file chosen.";
			} else if (this.isInvalidFileTypeImage && this.isInvalidFileSizeImage) {
				return "The file is too large. The maximum size limit is 2MB. Invalid file type. Only jpg/jpeg/png are allowed.";
			} else if (this.isInvalidFileTypeImage) {
				return "Invalid file type. Only jpg/jpeg/png are allowed.";
			} else if (this.isInvalidFileSizeImage) {
				return "The file is too large. The maximum size limit is 2MB.";
			}
			return "";
		},

		getUploadFileMessageIcon() {
			if (this.fileIcon == null) {
				return "No file chosen.";
			} else if (this.isInvalidFileTypeIcon && this.isInvalidFileSizeIcon) {
				return "The file is too large. The maximum size limit is 500KB. Invalid file type. Only svg is allowed.";
			} else if (this.isInvalidFileTypeIcon) {
				return "Invalid file type. Only svg is allowed.";
			} else if (this.isInvalidFileSizeIcon) {
				return "The file is too large. The maximum size limit is 500KB.";
			}
			return "";
		},

		imageClass() {
			if (this.imageUrl !== "") return "image-detail-24";
			return "image-detail-16";
		},

		iconClass() {
			if (this.iconUrl !== "") return "icon-detail-24";
			return "icon-detail-40";
		}
	},

	beforeUnmount() {
		URL.revokeObjectURL(this.imageUrl);
		URL.revokeObjectURL(this.iconUrl);
	}
};
</script>
<style lang="scss" scoped>
.wrapper-our-devices-create {
	padding: rem(24) 0;
}

.switch-status {
	margin-right: rem(16);
}

.create-our-devices-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.our-devices-create-input {
	margin: rem(24) 0;
}

.card-our-devices-info {
	margin-top: rem(40);
}

.wrapper-confirm-create-our-devices {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.control {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: $font-20;
	clear: both;
	box-sizing: border-box;

	&.in-line {
		flex-direction: row;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}

.image-detail-24 {
	margin-top: rem(24);
}

.image-detail-16 {
	margin-top: rem(16);
}

.icon-detail-40 {
	margin-top: rem(40);
}

.icon-detail-24 {
	margin-top: rem(24);
	margin-bottom: rem(24);
}

.margin-switch {
	margin-top: rem(36);
}

.input-description {
	width: 100%;
	margin: rem(24) 0;

	&.in-line {
		display: flex;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}
</style>
