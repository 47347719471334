import { STAFF_ROLES } from "./role";
import { CUSTOMER_ROLES } from "./customerManagement/customerRole";
/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */
const { SALES, SALES_PLUS, ADMIN, IT, BUSINESS_SUPPORT, SUPER_ADMIN, PLANNER } = STAFF_ROLES;
const { POWERUSER, PROCUREMENT, USER, LEVEL_2_USER, ORDER } = CUSTOMER_ROLES;

export const PAGE_NAME = Object.freeze({
	MONITOROVERALL: "monitorOverall",
	MONITORDEVICEHEALTH: "monitorDeviceHealth",
	MONITORUSER: "monitorUser",
	DASHBOARD: "dashboard",
	DASHBOARD_MODULES: "dashboardModule",
	FLOWDIAGRAM_LIST: "FlowDiagramList",
	FLOWDIAGRAM_CREATE: "FlowDiagramCreate",
	FLOWDIAGRAM_EDIT: "FlowDiagramEdit",
	FLOWDIAGRAM_VIEW: "FlowDiagramView",
	INVOICE: "invoices",
	USERS: "users",
	USERS_CREATE: "usersCreate",
	USERS_EDIT: "usersEdit",
	CUSTOMERS: "customers",
	ZLDGRAFANA: "zldGrafana",
	CUSTOMER_CREATE: "customerCreate",
	CUSTOMER_EDIT: "customerEdit",
	ACTIVITYLOG: "activitylog",
	TANKINFO: "tankInfo",
	TANKTRIPINFO: "tankTripInfo",
	TANKHISTORY: "tankOrder",
	TANK_CONSUMPTION_HISTORY: "TankConsumptionHistory",
	GENGAS_INFO: "genGasInfo",
	METERINFO: "meterInfo",
	METERDAILYCONSUMPTION: "meterDailyConsumption",
	METERCONSUMPTIONAPPROVAL: "meterConsumptionApproval",
	METERHISTORY: "meterOrder",
	CERINFO: "cerInfo",
	ZLDINFO: "zldInfo",
	ZLDLASTMONTH: "zldLastMonth",
	ZLD_VINY_THAI_INFO: "zldVinyThaiInfo",
	ZLD_VINY_THAI_CONSUMPTION: "zldVinyThaiConsumption",
	ZLD_VINY_THAI_ORDER: "zldOrder",
	NITRO_FAS_INFO: "nitroFASInfo",
	NITRO_FAS_HISTORY: "nitroFASHistory",
	O2_AERATION_INFO: "o2AerationInfo",
	O2_AERATION_LAST_MONTH: "o2AerationLastMonth",
	O2_AERATION_HISTORY: "o2AerationHistory",
	GENGAS_HISTORY: "genGasHistory",
	PWR_INFO: "ElectricityInfo",
	PWR_HISTORY: "ElectricityHistory",
	PWR_KWH: "ElectricityKwh",
	SOR_INFO: "SorInfo",
	SOR_HISTORY: "SorHistory",
	SOR_KWH: "SorKwh",
	PASSWORD_FORGOT: "passwordForgot",
	PASSWORD_RESET: "passwordReset",
	PASSWORD_CHANGE: "passwordChange",
	LOGIN_PAGE: "login",
	LAYOUT_CUSTOMISATION: "layoutCustomisation",
	LAYOUT_CUSTOMISATION_LIST: "layoutCustomisationLists",
	LAYOUT_CUSTOMISATION_GENGAS_CREATE: "layoutCustomisationGenGasCreate",
	LAYOUT_CUSTOMISATION_GENGAS_EDIT: "layoutCustomisationGenGasEdit",
	LAYOUT_CUSTOMISATION_ZLD_CREATE: "layoutCustomisationZLDCreate",
	LAYOUT_CUSTOMISATION_ZLD_EDIT: "layoutCustomisationZLDEdit",
	LAYOUT_CUSTOMISATION_POWER_CREATE: "layoutCustomisationPowerCreate",
	LAYOUT_CUSTOMISATION_POWER_EDIT: "layoutCustomisationPowerEdit",
	LAYOUT_CUSTOMISATION_SOR_CREATE: "layoutCustomisationSorCreate",
	LAYOUT_CUSTOMISATION_SOR_EDIT: "layoutCustomisationSorEdit",
	LAYOUT_CUSTOMISATION_TANK_CREATE: "layoutCustomisationTankCreate",
	LAYOUT_CUSTOMISATION_TANK_EDIT: "layoutCustomisationTankEdit",
	NOTIFICATION_GROUP: "notificationGroup",
	NOTIFICATION_GROUP_LIST: "notificationGroupLists",
	NOTIFICATION_GROUP_CREATE: "notificationGroupCreate",
	NOTIFICATION_GROUP_EDIT: "notificationGroupEdit",
	ALERT_AND_NOTIFICATION: "alertAndNotification",
	ALERT_AND_NOTIFICATION_LIST: "alertAndNotificationList",
	ALERT_AND_NOTIFICATION_CREATE: "alertAndNotificationCreate",
	ALERT_AND_NOTIFICATION_EDIT: "alertAndNotificationEdit",
	SHIPTO_STATUS_LIST: "ShopToStatusList",
	LNG_INFO: "lngInfo",
	LNG_TRIPINFO: "lngTripInfo",
	LNG_HISTORY: "lngOrder",
	MAINTENANCE: "Maintenance",
	MAINTENANCE_MODE: "MaintenanceMode",
	PERMISSION_REQUIRED: "permission",
	BHY_INFO: "bulkHydrogenInfo",
	BHY_TRIP_INFO: "bulkHydrogenTripInfo",
	BHY_HISTORY: "bulkHydrogenOrder",
	E_ORDERING_LIST: "EOrderingList",
	E_ORDERING_CREATE: "EOrderingCreate",
	E_ORDERING_EDIT: "EOrderingEdit",
	E_ORDERING_INFO: "EOrderingInfo",
	E_ORDERING_PENDING_ORDER_LIST: "EOrderingPendingOrderList",
	FURNACE_INFO: "furnaceInfo",
	FURNACE_OVERVIEW: "furnaceOverview",
	FURNACE_HISTORY: "furnaceHistory",
	FURNACE_REPORT: "furnaceReport",
	AIR_COMP_INFO: "airCompInfo",
	AIR_COMP_HISTORY: "airCompHistory",
	POWERBI: "PowerBI",
	GENGAS_POWERBI: "genGasPowerBI",
	TANK_POWERBI: "tankPowerBI",
	METER_POWERBI: "meterPowerBI",
	BULKHYDROGEN_POWERBI: "bulkHydrogenPowerBI",
	NITRO_FAS_POWERBI: "nitroFasPowerBI",
	CER_POWERBI: "cerPowerBI",
	O2A_POWERBI: "o2APowerBI",
	ZLD_POWERBI: "zldPowerBI",
	ZLD_VINY_THAI_POWERBI: "zldVinyThaiPowerBI",
	PWR_POWERBI: "pwrPowerBI",
	LNG_POWERBI: "lngPowerBI",
	SOR_POWERBI: "sorPowerBI",
	FURNACE_POWERBI: "furnacePowerBI",
	AIRCOMP_POWERBI: "airCompPowerBI",
	SOLDTO_MANAGEMENT: "soldToManagementList",
	MARKETPLACE: "marketplace",
	MARKETPLACE_MODULE_GROUP_DETAIL: "marketplaceModuleGroupDetail",
	GRAFANA_MANAGEMENT: "grafanaManagement",
	BIG_STORE_BANNER: "bigStoreBanner",
	BIG_STORE_BANNER_CREATE: "bigStoreBannerCreate",
	BIG_STORE_BANNER_EDIT: "bigStoreBannerEdit",
	BIG_STORE_OUR_SERVICE: "bigStoreOurService",
	BIG_STORE_OUR_SERVICE_CREATE: "bigStoreOurServiceCreate",
	BIG_STORE_OUR_SERVICE_EDIT: "bigStoreOurServiceEdit",
	BIG_STORE_OUR_DEVICE: "bigStoreOurDevice",
	BIG_STORE_OUR_DEVICE_CREATE: "bigStoreOurDeviceCreate",
	BIG_STORE_OUR_DEVICE_EDIT: "bigStoreOurDeviceEdit",
	MARKETPLACE_EXAMPLE_MODULE: "marketplaceExampleModule",
	EX_MODULE_GENGAS: "exModuleGenGas",
	EX_MODULE_GENGAS_INFO: "exModuleGenGasInfo",
	EX_MODULE_GENGAS_HISTORY: "exModuleGenGasHistory",
	EX_MODULE_GENGAS_POWERBI: "exModuleGenGasPowerBi",
	EX_MODULE_AIRCOMP: "exModuleAirComp",
	EX_MODULE_AIRCOMP_INFO: "exModuleAirCompInfo",
	EX_MODULE_AIRCOMP_HISTORY: "exModuleAirCompHistory",
	EX_MODULE_AIRCOMP_POWERBI: "exModuleAirCompPowerBi",
	EX_MODULE_FURNACE: "exModuleFurnace",
	EX_MODULE_FURNACE_INFO: "exModuleFurnaceInfo",
	EX_MODULE_FURNACE_OVERVIEW: "exModuleFurnaceOverview",
	EX_MODULE_FURNACE_HISTORY: "exModuleFurnaceHistory",
	EX_MODULE_FURNACE_REPORT: "exModuleFurnaceReport",
	EX_MODULE_FURNACE_POWERBI: "exModuleFurnacePowerBi",
	EX_MODULE_METER: "exModuleMeter",
	EX_MODULE_METER_INFO: "exModuleMeterInfo",
	EX_MODULE_METER_DAILY_CONSUMPTION: "exModuleMeterDailyConsumption",
	EX_MODULE_METER_CONSUMPTION_APPROVAL: "exModuleMeterConsumptionApproval",
	EX_MODULE_METER_HISTORY: "exModuleMeterHistory",
	EX_MODULE_METER_POWERBI: "exModuleMeterPowerBi",
	EX_MODULE_NITRO: "exModuleNitro",
	EX_MODULE_NITRO_INFO: "exModuleNitroInfo",
	EX_MODULE_NITRO_HISTORY: "exModuleNitroHistory",
	EX_MODULE_NITRO_POWERBI: "exModuleNitroPowerBi",
	EX_MODULE_O2A: "exModuleO2A",
	EX_MODULE_O2A_INFO: "exModuleO2AInfo",
	EX_MODULE_O2A_LAST_MONTH: "exModuleO2ALastMonth",
	EX_MODULE_O2A_HISTORY: "exModuleO2AHistory",
	EX_MODULE_O2A_POWERBI: "exModuleO2APowerBi",
	EX_MODULE_PWR: "exModulePwr",
	EX_MODULE_PWR_INFO: "exModulePwrInfo",
	EX_MODULE_PWR_HISTORY: "exModulePwrHistory",
	EX_MODULE_PWR_KWH: "exModulePwrKwh",
	EX_MODULE_PWR_POWERBI: "exModulePwrPowerBi",
	EX_MODULE_SOR: "exModuleSor",
	EX_MODULE_SOR_INFO: "exModuleSorInfo",
	EX_MODULE_SOR_HISTORY: "exModuleSorHistory",
	EX_MODULE_SOR_KWH: "exModuleSorKwh",
	EX_MODULE_SOR_POWERBI: "exModuleSorPowerBi",
	EX_MODULE_TANK: "exModuleTank",
	EX_MODULE_TANK_INFO: "exModuleTankInfo",
	EX_MODULE_TANK_TRIP_INFO: "exModuleTankTripInfo",
	EX_MODULE_TANK_ORDERS: "exModuleTankHistory",
	EX_MODULE_TANK_POWERBI: "exModuleTankPowerBi",
	EX_MODULE_ZLD: "exModuleZld",
	EX_MODULE_ZLD_INFO: "exModuleZldInfo",
	EX_MODULE_ZLD_LAST_MONTH: "exModuleZldLastMonth",
	EX_MODULE_ZLD_POWERBI: "exModuleZldPowerBi",
	EX_MODULE_ZLD_VINY_THAI: "exModuleZldVinyThai",
	EX_MODULE_ZLD_VINY_THAI_INFO: "exModuleZldVinyThaiInfo",
	EX_MODULE_ZLD_VINY_THAI_CONSUMPTION_APPROVAL: "exModuleZldVinyThaiConsumptionApproval",
	EX_MODULE_ZLD_VINY_THAI_HISTORY: "exModuleZldVinyThaiHistory",
	EX_MODULE_ZLD_VINY_THAI_POWERBI: "exModuleZldVinyThaiPowerBi",
	EX_MODULE_CER: "exModuleCerComp",
	EX_MODULE_CER_INFO: "exModuleCerCompInfo",
	EX_MODULE_CER_HISTORY: "exModuleCerCompHistory",
	EX_MODULE_CER_POWERBI: "exModuleCerCompPowerBi",
	EX_MODULE_LNG: "exModuleLng",
	EX_MODULE_LNG_INFO: "exModuleLngInfo",
	EX_MODULE_LNG_TRIP_INFO: "exModuleLngTripInfo",
	EX_MODULE_LNG_HISTORY: "exModuleLngHistory",
	EX_MODULE_LNG_POWERBI: "exModuleLngPowerBi",
	EX_MODULE_BHY: "exModuleBhy",
	EX_MODULE_BHY_INFO: "exModuleBhyInfo",
	EX_MODULE_BHY_TRIP_INFO: "exModuleBhyTripInfo",
	EX_MODULE_BHY_HISTORY: "exModuleBhyHistory",
	EX_MODULE_BHY_POWERBI: "exModuleBhyPowerBi",
	STANDARD_MODULE_LIST: "standardModuleList",
	STANDARD_MODULE_CREATE: "standardModuleCreate",
	STANDARD_MODULE_EDIT: "standardModuleEdit",
	STANDARD_MODULE_PAGE: "standardModulePage",
	STANDARD_MODULE_OVERVIEW: "standardModuleOverview",
	STANDARD_MODULE_ANALYSIS: "standardModuleAnalysis",
	STANDARD_MODULE_HISTORY: "standardModuleHistory",
	STANDARD_MODULE_GRAFANA: "standardModuleGrafana",
	EX_STANDARD_MODULE_PAGE: "exStandardModulePage",
	EX_STANDARD_MODULE_OVERVIEW: "exStandardModuleOverview",
	EX_STANDARD_MODULE_ANALYSIS: "exStandardModuleAnalysis",
	EX_STANDARD_MODULE_HISTORY: "exStandardModuleHistory",
	EX_STANDARD_MODULE_GRAFANA: "exStandardModuleGrafana"
});

export const USER_PERMISSION_PAGE = Object.freeze({
	DASHBOARD: "Dashboard.Read",
	DIAGRAM: "FlowDiagram.Read",
	INVOICE: "Invoice.Read",
	E_ORDERING: "EOrdering.Read",
	POWERBI: "PowerBIExecutive.Read"
});

export const PAGE_PERMISSION = Object.freeze({
	[PAGE_NAME.FLOWDIAGRAM_LIST]: [USER_PERMISSION_PAGE.DIAGRAM], // permission
	[PAGE_NAME.MONITOROVERALL]: [SUPER_ADMIN, PLANNER],
	[PAGE_NAME.MONITORDEVICEHEALTH]: [SUPER_ADMIN, PLANNER],
	[PAGE_NAME.MONITORUSER]: [SUPER_ADMIN, PLANNER],
	[PAGE_NAME.DASHBOARD]: [
		// roles
		SUPER_ADMIN,
		ADMIN,
		BUSINESS_SUPPORT,
		SALES,
		SALES_PLUS,
		IT,
		POWERUSER,
		PROCUREMENT,
		USER,
		LEVEL_2_USER,
		PLANNER,
		// permission
		USER_PERMISSION_PAGE.DASHBOARD
	],
	[PAGE_NAME.INVOICE]: [
		// roles
		SUPER_ADMIN,
		ADMIN,
		POWERUSER,
		SALES,
		SALES_PLUS,
		PROCUREMENT,
		// permission
		USER_PERMISSION_PAGE.INVOICE
	],
	[PAGE_NAME.USERS]: [SUPER_ADMIN, ADMIN, IT],
	[PAGE_NAME.USERS_CREATE]: [SUPER_ADMIN, ADMIN, IT],
	[PAGE_NAME.USERS_EDIT]: [SUPER_ADMIN, ADMIN, IT],
	[PAGE_NAME.CUSTOMERS]: [SUPER_ADMIN, ADMIN, IT],
	[PAGE_NAME.CUSTOMER_CREATE]: [SUPER_ADMIN, ADMIN],
	[PAGE_NAME.CUSTOMER_EDIT]: [SUPER_ADMIN, ADMIN],
	[PAGE_NAME.ZLDGRAFANA]: [SALES, SALES_PLUS, ADMIN, IT, BUSINESS_SUPPORT, SUPER_ADMIN],
	[PAGE_NAME.ACTIVITYLOG]: [SUPER_ADMIN],
	[PAGE_NAME.MAINTENANCE]: [SUPER_ADMIN],
	[PAGE_NAME.E_ORDERING_LIST]: [
		// roles
		SUPER_ADMIN,
		POWERUSER,
		PLANNER,
		PROCUREMENT,
		ORDER,
		// permission
		USER_PERMISSION_PAGE.E_ORDERING
	],
	[PAGE_NAME.E_ORDERING_CREATE]: [SUPER_ADMIN, POWERUSER, PLANNER, PROCUREMENT, ORDER],
	[PAGE_NAME.E_ORDERING_EDIT]: [SUPER_ADMIN, POWERUSER, PLANNER, PROCUREMENT, ORDER],
	[PAGE_NAME.E_ORDERING_INFO]: [SUPER_ADMIN, POWERUSER, PLANNER, PROCUREMENT, ORDER],
	[PAGE_NAME.E_ORDERING_PENDING_ORDER_LIST]: [SUPER_ADMIN, POWERUSER, PLANNER, PROCUREMENT, ORDER],
	[PAGE_NAME.FURNACE_REPORT]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER, PLANNER],
	[PAGE_NAME.POWERBI]: [SUPER_ADMIN,
		// roles
		ADMIN,
		BUSINESS_SUPPORT,
		SALES,
		SALES_PLUS,
		IT,
		// permission
		USER_PERMISSION_PAGE.POWERBI
	],
	[PAGE_NAME.SOLDTO_MANAGEMENT]: [SUPER_ADMIN],
	// MARKETPLACE
	[PAGE_NAME.MARKETPLACE]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.MARKETPLACE_MODULE_GROUP_DETAIL]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.MARKETPLACE_EXAMPLE_MODULE]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_GENGAS]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_GENGAS_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_GENGAS_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_GENGAS_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_AIRCOMP]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_AIRCOMP_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_AIRCOMP_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_AIRCOMP_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_FURNACE]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_FURNACE_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_FURNACE_OVERVIEW]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_FURNACE_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_FURNACE_REPORT]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_FURNACE_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_METER]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_METER_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_METER_CONSUMPTION_APPROVAL]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_METER_DAILY_CONSUMPTION]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_METER_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_METER_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_NITRO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_NITRO_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_NITRO_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_NITRO_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_O2A]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_O2A_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_O2A_LAST_MONTH]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_O2A_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_O2A_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_PWR]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_PWR_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_PWR_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_PWR_KWH]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_PWR_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_SOR]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_SOR_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_SOR_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_SOR_KWH]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_SOR_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_TANK]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_TANK_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_TANK_TRIP_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_TANK_ORDERS]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_TANK_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_LAST_MONTH]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_VINY_THAI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_CONSUMPTION_APPROVAL]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_CER]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_CER_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_CER_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_CER_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_LNG]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_LNG_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_LNG_TRIP_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_LNG_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_LNG_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_BHY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_BHY_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_BHY_TRIP_INFO]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_BHY_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_MODULE_BHY_POWERBI]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_STANDARD_MODULE_PAGE]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_STANDARD_MODULE_OVERVIEW]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_STANDARD_MODULE_ANALYSIS]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_STANDARD_MODULE_HISTORY]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
	[PAGE_NAME.EX_STANDARD_MODULE_GRAFANA]: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],

	[PAGE_NAME.GRAFANA_MANAGEMENT]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_BANNER]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_BANNER_CREATE]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_BANNER_EDIT]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_OUR_SERVICE]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_OUR_SERVICE_CREATE]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_OUR_SERVICE_EDIT]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_OUR_DEVICE]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_OUR_DEVICE_CREATE]: [SUPER_ADMIN],
	[PAGE_NAME.BIG_STORE_OUR_DEVICE_EDIT]: [SUPER_ADMIN],
	[PAGE_NAME.STANDARD_MODULE_LIST]: [SUPER_ADMIN],
	[PAGE_NAME.STANDARD_MODULE_CREATE]: [SUPER_ADMIN],
	[PAGE_NAME.STANDARD_MODULE_EDIT]: [SUPER_ADMIN]
});

