import { deleteFlowDiagramAPI } from "@/services/api/flowdiagram.api";
import { FLOW_DIAGRAM_DELETE_REQUEST, FLOW_DIAGRAM_DELETE_SUCCESS, FLOW_DIAGRAM_DELETE_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		loading: true
	},

	mutations: {
		[FLOW_DIAGRAM_DELETE_REQUEST](state) {
			state.loading = true;
		},
		[FLOW_DIAGRAM_DELETE_SUCCESS](state) {
			state.loading = false;
		},
		[FLOW_DIAGRAM_DELETE_FAILURE](state) {
			state.loading = false;
		}
	},

	actions: {
		async deleteFlowDiagram({ commit }, { id }) {
			try {
				commit(FLOW_DIAGRAM_DELETE_REQUEST);
				await deleteFlowDiagramAPI(id);
				commit(FLOW_DIAGRAM_DELETE_SUCCESS);
			} catch (error) {
				commit(FLOW_DIAGRAM_DELETE_FAILURE);
			}
		}
	}
};
