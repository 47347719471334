import httpClient from "./httpClient";

const BIG_STORE_MANAGEMENT = "/marketplaces";

const getMarketplacesAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}`, { params });

const getMarketplaceModuleDetailAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/${params.id}/service/detail`);

const postRequestServiceAPI = (params) => httpClient.post(`${BIG_STORE_MANAGEMENT}/send-email`, params);

const getMarketplaceGroupModuleDetailAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/${params.module}/group-service`);

const getBannersAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/banner`, { params });

const getBannersByIdAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/banner`, { params });

const postBannerCreateAPI = (payload = {}, onUploadProgress) => {
	const formData = new FormData();
	formData.append("img", payload.img);
	formData.append("url", payload.url);
	formData.append("priority", payload.priority);
	formData.append("visible", payload.visible);
	return httpClient.post(`${BIG_STORE_MANAGEMENT}/banner`, formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress
	});
};

const putBannerUpdateAPI = (payload = {}, onUploadProgress) => {
	const formData = new FormData();
	formData.append("img", payload.img);
	formData.append("url", payload.url);
	formData.append("priority", payload.priority);
	formData.append("visible", payload.visible);
	return httpClient.put(`${BIG_STORE_MANAGEMENT}/${payload.id}/banner`, formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress
	});
};

const deleteBannerAPI = (id) => httpClient.delete(`${BIG_STORE_MANAGEMENT}/${id}/banner`);

const checkDuplicatePriorityBannerAPI = (id) => httpClient.get(`${BIG_STORE_MANAGEMENT}/${id}/priority-banner`);

const getOurDeviceAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/device`, { params });

const postOurDeviceCreateAPI = (payload = {}, onUploadProgress) => {
	const formData = new FormData();
	formData.append("group", payload.group);
	formData.append("descriptions", payload.descriptions);
	formData.append("img", payload.img);
	formData.append("icon", payload.icon);
	formData.append("color", payload.color);
	formData.append("priority", payload.priority);
	formData.append("visible", payload.visible);
	return httpClient.post(`${BIG_STORE_MANAGEMENT}/device`, formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress
	});
};

const putOurDeviceUpdateAPI = (payload = {}, onUploadProgress) => {
	const formData = new FormData();
	formData.append("group", payload.group);
	formData.append("descriptions", payload.descriptions);
	formData.append("img", payload.img);
	formData.append("icon", payload.icon);
	formData.append("color", payload.color);
	formData.append("priority", payload.priority);
	formData.append("visible", payload.visible);
	return httpClient.put(`${BIG_STORE_MANAGEMENT}/${payload.id}/device`, formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress
	});
};

const getGroupDevices = () => httpClient.get(`${BIG_STORE_MANAGEMENT}/group-device`);

const getGroupServices = () => httpClient.get(`${BIG_STORE_MANAGEMENT}/group-service`);

const checkDuplicatePriorityOurDeviceAPI = (id) => httpClient.get(`${BIG_STORE_MANAGEMENT}/${id}/priority-device`);

const getOurdeviceByIdAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/device`, { params });

const getOurServiceByIdAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/service`, { params });

const deleteDeviceAPI = (id) => httpClient.delete(`${BIG_STORE_MANAGEMENT}/${id}/device`);

const postOurServiceCreateAPI = (payload = {}, onUploadProgress) => {
	const formData = new FormData();
	formData.append("module", payload.module);
	formData.append("deviceId", payload.deviceId);
	formData.append("title", payload.title);
	formData.append("descriptions", payload.descriptions);
	formData.append("icon", payload.icon);
	formData.append("introTitle", payload.introTitle);
	formData.append("introDescriptions", payload.introDescriptions);
	formData.append("demoShipId", payload.demoShipId);
	formData.append("priority", payload.priority);
	formData.append("visible", payload.visible);
	formData.append("visibleDisplay", payload.visibleDisplay);
	formData.append("boxTitle", payload.boxTitle);
	formData.append("img", payload.img);
	formData.append("priorityHighlight", payload.priorityHighlight);
	formData.append("vdoLink", payload.vdoLink);
	return httpClient.post(`${BIG_STORE_MANAGEMENT}/service`, formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress
	});
};

const putOurServiceUpdateAPI = (payload = {}, onUploadProgress) => {
	const formData = new FormData();
	formData.append("module", payload.module);
	formData.append("deviceId", payload.deviceId);
	formData.append("title", payload.title);
	formData.append("descriptions", payload.descriptions);
	formData.append("icon", payload.icon);
	formData.append("introTitle", payload.introTitle);
	formData.append("introDescriptions", payload.introDescriptions);
	formData.append("demoShipId", payload.demoShipId);
	formData.append("priority", payload.priority);
	formData.append("visible", payload.visible);
	formData.append("visibleDisplay", payload.visibleDisplay);
	formData.append("boxTitle", payload.boxTitle);
	formData.append("img", payload.img);
	formData.append("priorityHighlight", payload.priorityHighlight);
	formData.append("vdoLink", payload.vdoLink);
	return httpClient.put(`${BIG_STORE_MANAGEMENT}/${payload.id}/service`, formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress
	});
};

const deleteOurServiceAPI = (id) => httpClient.delete(`${BIG_STORE_MANAGEMENT}/${id}/service`);

const getServicesAPI = (params = {}) => httpClient.get(`${BIG_STORE_MANAGEMENT}/service`, { params });

const checkDuplicatePriorityServiceAPI = (id) => httpClient.get(`${BIG_STORE_MANAGEMENT}/${id}/priority-service`);

const checkDuplicatePriorityServiceHightlightAPI = (id) => httpClient.get(`${BIG_STORE_MANAGEMENT}/${id}/priority-highlight`);

export {
	getMarketplacesAPI,
	getMarketplaceModuleDetailAPI,
	getMarketplaceGroupModuleDetailAPI,
	postRequestServiceAPI,
	postBannerCreateAPI,
	getBannersAPI,
	getBannersByIdAPI,
	putBannerUpdateAPI,
	deleteBannerAPI,
	checkDuplicatePriorityBannerAPI,
	postOurServiceCreateAPI,
	putOurServiceUpdateAPI,
	deleteOurServiceAPI,
	getServicesAPI,
	postOurDeviceCreateAPI,
	putOurDeviceUpdateAPI,
	getOurDeviceAPI,
	getGroupDevices,
	checkDuplicatePriorityOurDeviceAPI,
	getOurdeviceByIdAPI,
	deleteDeviceAPI,
	getGroupServices,
	checkDuplicatePriorityServiceAPI,
	checkDuplicatePriorityServiceHightlightAPI,
	getOurServiceByIdAPI
};
