import dayjs from "dayjs";
import { transformLineChart } from "../../lineChart";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachDay,
	getForEachSixHours,
	getForEachHour,
	getForEachThirtyMinute,
	getForEachTwoHours,
	xAxisLabelWithOutTime,
	getForEachFiveDay
} from "../../lineChart/helper";
import { getConfigDataZoom } from "../../lineChart/helper/zoomConfig";
import { computeMarkLineLegendConfig, computeMarkLineConfig } from "../markLineTransform";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Silica in recycle water (ppb)`;

const tooltipSilicaInRecycleWaterLineChartFormat = (name, value) => {
	const format = `
	<div>
		<div class="bar-name">
			${name}
		</div>
		<div class="bar-value">
			${value === null ? "-" : value} ppb
		</div>
	</div>`;
	return format;
};


const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipSilicaInRecycleWaterLineChartFormat(date, pointValue.data);
};


const computeSilicaInRecycleWaterMarkline = computeMarkLineConfig({
	lineStyle: {
		type: "dashed",
		width: 2
	}
});


const optionLineChartXaxisPerDay = ({ markLine = [] }, { xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachTwoHours
	},
	...getConfigDataZoom(xAxis),
	axisTick: {
		interval: getForEachThirtyMinute
	},
	grid: {
		left: 16,
		right: 16,
		bottom: 86,
		containLabel: true
	},
	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeSilicaInRecycleWaterMarkline(markLine)
	},
	...computeMarkLineLegendConfig(markLine)
});

const optionLineChartXaxisForThreeDay = ({ markLine = [] }, { xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachFourHours
	},
	axisTick: {
		interval: getForEachHour
	},
	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 86,
		containLabel: true
	},
	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeSilicaInRecycleWaterMarkline(markLine)
	},
	...computeMarkLineLegendConfig(markLine)
});

const optionLineChartXaxisPerWeek = ({ markLine = [] }, { xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	axisTick: {
		interval: getForEachSixHours
	},
	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 86,
		containLabel: true
	},
	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeSilicaInRecycleWaterMarkline(markLine)
	},
	...computeMarkLineLegendConfig(markLine)
});

const optionLineChartXaxisMoreThanTwentyDay = ({ markLine = [] }, { xAxis = [] }) => ({
	yAxisText,

	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachFiveDay
	},

	axisTick: {
		interval: (index, date) => {
			const hour = dayjs(date).get("hour");
			const minute = dayjs(date).get("minute");
			return hour % 24 === 0 && minute === 0;
		}
	},
	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 86,
		containLabel: true
	},
	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeSilicaInRecycleWaterMarkline(markLine)
	},
	...computeMarkLineLegendConfig(markLine)
});

export const transformSilicaInRecycleWaterLineChartConfig = (sortBy = perDay, { data = [], diffDay = "", option = {} }) => {
	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay(option, data),
		[perThreeDay]: optionLineChartXaxisForThreeDay(option, data),
		[perWeek]: optionLineChartXaxisPerWeek(option, data)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const optionConfig = optionLineChartXaxisMoreThanTwentyDay(option, data);
		const transformFunc = transformLineChart(optionConfig);
		return transformFunc(data);
	}

	const optionConfig = mappingOption[sortBy] || optionLineChartXaxisPerWeek(option, data);
	const transformFunc = transformLineChart(optionConfig);
	return transformFunc(data);
};
