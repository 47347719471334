<template>
	<CardBarChart
		:chartData="airCompSecChartData"
		:lastUpdate="airCompSEC.lastUpdate"
		:isLoading="airCompSEC.loading"
		:isEmpty="airCompSEC.empty"
		chartTitle="Specific Energy Consumption (SEC)"
	/>
</template>

<script>
import dayjs from "dayjs";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { transformDataBarChart, transformBarChart } from "../../selectors/transform/barChart";
import { getDateNumber, numberFormat } from "../../selectors/helpers";

const { perWeek, perTwoWeek, perMonth, perYear } = MODULE_SORT_DATE_CHART;

export default {
	components: {
		CardBarChart
	},

	props: {
		airCompSEC: {
			type: Object,
			default: () => ({})
		},
		tagName: {
			type: String,
			default: "Specific Energy Consumption (SEC) (kWh/m3)"
		},
		filterSelected: {
			required: true,
			type: String
		}
	},

	data() {
		return {
			filterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 12 months",
								value: perYear,
								isDisabled: true
							}
						]
					}
				}
			}
		};
	},

	computed: {
		airCompSecSelected() {
			const dateRange = this.filterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);

			return {
				dateNumber,
				dateRange
			};
		},

		airCompSecChartData() {
			const dateSort = this.filterSelected;
			const dateReportFormat = [perWeek, perTwoWeek, perMonth].includes(dateSort) ? "day" : "year";
			const barWidthDateSort = {
				"2w": 30,
				"7d": 40,
				"1m": 30
			};
			const options = { dateReportFormat, barColor: "#0082c1", barSize: barWidthDateSort[dateSort] };
			const xyAxis = transformDataBarChart(this.airCompSEC.detail, options);
			const optionAirCompBarChart = {
				bgColor: "#0082c1",
				yAxisText: this.tagName,
				xAxisText: "Date",
				grid: {
					left: "48px",
					right: "74px",
					bottom: 0,
					containLabel: true
				},
				series: {
					tooltip: {
						show: true,
						trigger: "axis",
						backgroundColor: "#103332",
						axisPointer: {
							type: "none"
						},
						padding: 8,
						formatter: ([{ value, name }]) => {
							return `
									<div>
										<div class="bar-name meter">
											${name}
										</div>
										<div class="bar-value">
											${value ? `${numberFormat(value, "0,0.000")} kWh/m³` : "N/A"}
										</div>
									</div>
                					`;
						}
					}
				}
			};
			return transformBarChart(optionAirCompBarChart)(xyAxis);
		}
	}
};
</script>
