<template>
	<div class="module-item active">
		<div class="module-item-header">
			<h2 class="module-item-name">
				Order #{{ item.orderNo }}
			</h2>
			<BaseBadge v-if="item.status === 'In transit'" text="In transit" size="small" color="success-dark"/>
			<BaseBadge v-if="item.status === 'Confirmed'" text="Confirmed" size="small" color="secondary"/>
			<BaseBadge v-else text="New" size="small" color="danger"/>
		</div>
		<div class="module-item-body container">
			<div class="row container-item">
				<div class="container-item-body">
					<div
						class="item item-delete-order"
						@click="$emit('onRequestCancellation')"
					>
						<FontAwesomeIcon :icon="['far', 'minus-circle']" />
						Request for cancellation
					</div>
					<div class="item">
						<div class="label">
							Ship-to *
						</div>
						<div class="form form-ship-to">
							{{ showShipTo }}
						</div>
					</div>

					<div class="item">
						<div class="label">
							Product *
						</div>
						<div class="form form-product">
							{{ showProduct }}
						</div>
					</div>

					<div class="item">
						<div class="label">
							Tank no. *
						</div>
						<div class="form form-tank-no">
							{{ item.tankNo }}
						</div>
					</div>

					<div class="item item-tank-no">
						<strong>
							Schedule information. *
						</strong>
						<div class="form form-tank-no"></div>
					</div>

					<div class="item item-schedule">
						<div class="label label-top">
						</div>
						<div class="form form-volume">
							<BaseFormSchedule
								:item="item"
								:formSchedule.sync="formSchedule"
							/>
						</div>
					</div>

					<div class="item item-po-no">
						<div class="label">
							PO No.
						</div>
						<div class="form form-po-no">
							<BaseFormInput
								class="input-po-no"
								v-model="forms.poNo"
								placeholder="Enter PO no."
								rounded
							/>
						</div>
					</div>

					<div class="item">
						<div class="label label-top">
							Note
						</div>
						<div class="form form-note">
							<BaseTextArea
								v-model="forms.note"
								:placeholder="`Note`"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseFormSchedule from "@/components/eOrdering/formGroup/BaseFormSchedule.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseBadge from "@/components/BaseBadge.vue";

export default {
	name: "ModuleEditOrder",

	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},

	components: {
		BaseTextArea,
		BaseBadge,
		BaseFormSchedule
	},

	data() {
		return {
			forms: {
				orderNo: this.item.orderNo,
				shipTo: this.item.shipTo,
				product: this.item.product,
				tankNo: this.item.tankNo,
				poNo: this.item.poNo,
				note: this.item.note,
				volume: this.item.volume,
				minimumVolume: this.item.minimumVolume,
				maximumVolume: this.item.maximumVolume
			},

			formSchedule: {
				volume: this.item.volume,
				deliveriesDate: this.item.deliveriesDate,
				date: this.item.date,
				time: this.item.time,
				selectedVolume: this.item.selectedVolume,
				isUrgent: this.item.isUrgent,
				unit: this.item.unit
			}
		};
	},

	computed: {
		showShipTo() {
			return `${this.item.shipTo} (${this.item.device.shipTo.key})`;
		},

		showProduct() {
			return `${this.item.product} (${this.item.device.productId})`;
		}
	},

	watch: {
		forms: {
			handler(obj) {
				const form = {
					...obj,
					...this.formSchedule
				};
				this.$emit("update:formCreateOrder", form);
			},
			deep: true
		},

		formSchedule: {
			handler(obj) {
				const form = {
					...this.forms,
					...obj
				};
				this.$emit("update:formCreateOrder", form);
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
.module-item {
	border-radius: 4px;
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	margin-bottom: rem(32);
	line-height: 1.5;
	margin-top: rem(24);

	// .module-item-name
	&-name {
		margin-right: rem(4);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(16) rem(24);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-up {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.fa-chevron-up {
			transform: rotate(180deg);
		}
	}
}

.container-item {
	padding: 0 $spacing-5 $spacing-7 $spacing-5;

	// .container-item-head
	&-head {
		@include fontSub();

		display: flex;
		justify-content: space-between;
	}

	// .container-item-body
	&-body {
		margin-top: rem(14);

		/* stylelint-disable */
		&::v-deep .container-custom-main-table .action {
			text-align: right !important;
		}
		/* stylelint-enable */

		.item {
			margin-bottom: $spacing-6;
			display: flex;

			// .item-po-no
			&-po-no {
				margin-top: rem(-16);
			}

			// .item-delete-order
			&-delete-order {
				font-size: $font-18;
				font-weight: bold;
				color: $color-cerulean;
				align-items: center;
				justify-content: flex-end;
				cursor: pointer;

				svg {
					margin-right: rem(4);
				}
			}

			.label {
				display: flex;
				align-items: center;
				min-width: rem(130);

				// .label-top
				&-top {
					align-items: flex-start;
				}
			}

			.form {
				flex-grow: 1;

				// .form-volume
				&-volume {
					/* stylelint-disable */
					&::v-deep {
						.text-hint {
							display: none;
						}
					}
					/* stylelint-enable */
				}

				// .form-po-no
				&-po-no {
					.input-po-no {
						width: rem(200);
					}
				}
			}
		}
	}
}
</style>
