import { getZldVinyThaiWaterFlowRate } from "../../../services/api/Zlds.api";
import {
	ZLD_WATER_FLOW_RATE_REQUEST,
	ZLD_WATER_FLOW_RATE_SUCCESS,
	ZLD_WATER_FLOW_RATE_FAILURE
} from "../../types";
import { transformLineChartWithStack } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,
	getters: {
		getDateRange(state) {
			const result = [];
			for (let i = 0; i < state.data.rawWater.length; i++) {
				for (let j = 0; j < state.data.rawWater[i].values.length; j++) {
					result.push(state.data.rawWater[i].values[j].timestamp);
				}
			}
			return result;
		},

		getRawWaterData(state) {
			return transformLineChartWithStack(state.data.rawWater);
		},

		getRecycleWaterData(state) {
			return transformLineChartWithStack(state.data.recycleWater);
		}
	},
	state: {
		data: {
			rawWater: [],
			recycleWater: []
		},
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},

	mutations: {
		[ZLD_WATER_FLOW_RATE_REQUEST](state) {
			state.data = {
				rawWater: [],
				recycleWater: []
			};
			state.isLoading = true;
			state.isEmpty = false;
		},
		[ZLD_WATER_FLOW_RATE_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = Object.keys(response.data).length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[ZLD_WATER_FLOW_RATE_FAILURE](state) {
			state.data = {
				rawWater: [],
				recycleWater: []
			};
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchZldVinyThaiWaterFlowrate({ commit }, { id = "", query = {} }) {
			try {
				commit(ZLD_WATER_FLOW_RATE_REQUEST);

				const { data = {} } = await getZldVinyThaiWaterFlowRate(id, query);
				commit(ZLD_WATER_FLOW_RATE_SUCCESS, data);
			} catch (error) {
				commit(ZLD_WATER_FLOW_RATE_FAILURE);
			}
		}
	}
};
