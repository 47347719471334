<template>
	<BaseLayout>
		<BaseBackBtn :to="PAGE_NAME.LAYOUT_CUSTOMISATION_LIST" class="page-back-btn" label="Back to Layout customisation"/>

		<h2 class="head-title">
			Create new layout
		</h2>


		<BaseCard rounded>
			<template #header>
				<h4>Gen Gas Layout</h4>
			</template>
			<template #body>
				<BaseFormInput
					v-model="$v.layoutName.$model"
					:inputOptions="['small']"
					:isError="isLayoutNameError"
					:errorMessage="getLayoutNameErrorMessage"
					class="customer-create-input"
					label="Layout name"
					inline
				/>
			</template>
		</BaseCard>
		<div
			:class="['row row-eq-height', { 'hide-content': false }]"
		>
			<div class="col-12 col-xl-7">
				<BaseCard rounded>
					<template v-slot:header>
						<h4>Overview</h4>
					</template>
					<template v-slot:body>
						<ModuleOverviewWithCustomisation
							:data="genGasInfo.data"
							@overrideTextField="overrideOverViewTextField"
							@override="overrideOverView"
							@overideSelectedDropdown="overrideOverViewSelectedDropdown"
						/>
					</template>
				</BaseCard>
			</div>
			<div class="col-12 col-xl-5">
				<BaseCard rounded class="card-ship-to-location">
					<template v-slot:header>
						<h4>Ship-to location</h4>
						<BaseSwitch
							:checked="genGasInfo.shipToLocation"
							name="shipToLocation"
							@input="handleToggle"
						/>
					</template>
					<template v-slot:body class="body-ship-to">
						<div class="ship-to-boday">
							<BaseIconInfo
								fontSize="40"
								information="Ship-to location map"
							/>
						</div>
					</template>
				</BaseCard>
			</div>
		</div>
		<BaseCard rounded class="card-chart">
			<template v-slot:header>
				<h4>Gas flow rate</h4>
				<BaseSwitch
					:checked="genGasInfo.flowRate"
					name="flowRate"
					@input="handleToggle"
				/>
			</template>
			<template v-slot:body class="body-ship-to">
				<div class="ship-to-boday">
					<BaseIconInfo
						iconName="chart-line"
						fontSize="40"
						information="Line graph"
					/>
				</div>
			</template>
		</BaseCard>
		<BaseCard rounded class="card-chart">
			<template v-slot:header>
				<h4>Consumption</h4>
				<BaseSwitch
					:checked="genGasInfo.consumption"
					name="consumption"
					@input="handleToggle"
				/>
			</template>
			<template v-slot:body class="body-ship-to">
				<div class="ship-to-boday">
					<BaseIconInfo
						iconName="chart-bar"
						fontSize="40"
						information="Bar graph"
					/>
				</div>
			</template>
		</BaseCard>
		<ApplyToDevice
			:applyToData="dataApplyTo"
			:soldToValues="soldToValues"
			:shipToValues="shipToValues"
			:deviceValues="deviceValues"
			:isDeviceError="isDevicesDuplicate"
			:deviceErrorMessage="getDeviceDuplicateErrorMessage"
			@handleChangeSoldTo="handleChangeSoldTo"
			@handleChangeShipTo="handleChangeShipTo"
			@handleChangeDevice="handleChangeDevice"
			module="GENGAS"
		/>
		<div class="wrapper-confirm-create">
			<BaseButton
				class="btn-cancel"
				size="large"
				type="primary"
				outline
				rounded
				@click="handleCancle"
			>
				Cancel
			</BaseButton>

			<BaseButton
				:disabled="isDisabledCreate"
				size="large"
				type="primary"
				width="180px"
				rounded
				@click="handleSubmit(handleSendPayload)"
			>
				Create layout
			</BaseButton>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import BaseLayout from "@/components/BaseLayout.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseFormInput from "@/components/BaseFormInput.vue";
import ModuleOverviewWithCustomisation from "@/components/layoutCustomisation/ModuleOverviewWithCustomisation.vue";
import ApplyToDevice from "@/components/layoutCustomisation/ApplyToDevice.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import BaseIconInfo from "@/components/BaseIconInfo.vue";
import { getLayoutAddables, getLayoutTemplate, storeLayoutTemplate } from "../../../services/api/layoutCustomisation.api";
import { checkIfValid, getvalidateMessage } from "../../../selectors/formValidate/validateHelper";
import { getConfigGenGasOverview, parseGenGasOverview } from "../../../selectors/layoutConsumption/parseGengas";
import { PAGE_NAME } from "../../../enums/pagePermission";
import { TOAST_TYPES } from "../../../enums/toast";

export default {
	components: {
		BaseLayout,
		BaseCard,
		BaseFormInput,
		ApplyToDevice,
		ModuleOverviewWithCustomisation,
		BaseSwitch,
		BaseIconInfo,
		BaseBackBtn
	},
	validations: {
		layoutName: {
			required,
			maxLength: maxLength(255)
		}
	},
	mixins: [
		validationMixin
	],
	data() {
		return {
			PAGE_NAME,
			dataApplyTo: {
				soldTos: [],
				shipTos: [],
				devices: []
			},
			layoutName: "",
			genGasInfo: {
				data: {
					id: 131,
					type: "GenGas",
					module: "GenGas",
					flowRate: "N/A",
					flowRatePercent: 79,
					maxRange: 1163,
					tagNo: "FERRO-GenGas",
					overview: []
				},
				// should array when dynamic component render
				shipToLocation: false,
				flowRate: false,
				consumption: false
			},
			soldToValues: [],
			shipToValues: [],
			deviceValues: [],
			duplicateDevice: []
		};
	},
	async created() {
		const configApplicationModule = {
			app: "GENGAS"
		};
		const responseApplyTo = await getLayoutAddables(configApplicationModule);
		const responseTemplate = await getLayoutTemplate(configApplicationModule);
		const overViewSection = parseGenGasOverview(responseTemplate.data);
		const { layout: { flowRate, consumption, shipToLocation } } = responseTemplate.data;
		this.genGasInfo.data.overview = overViewSection;
		this.genGasInfo.flowRate = flowRate;
		this.genGasInfo.consumption = consumption;
		this.genGasInfo.shipToLocation = shipToLocation;
		this.dataApplyTo = responseApplyTo.data;
	},
	computed: {
		isDisabledCreate() {
			return !this.deviceValues.length;
		},

		isLayoutNameError() {
			const field = this.$v.layoutName;
			return checkIfValid(field);
		},

		isDevicesDuplicate() {
			return this.duplicateDevice.length > 0;
		},

		getDeviceDuplicateErrorMessage() {
			let word = "";
			for (let i = 0; i < this.duplicateDevice.length; i++) {
				if (i === this.duplicateDevice.length - 1) {
					word += `${this.duplicateDevice[i].key}`;
				} else {
					word += `${this.duplicateDevice[i].key}, `;
				}
			}
			if (this.duplicateDevice.length > 0) {
				return `${word} already assigned to another layout, please remove the device to continue`;
			}
			return "";
		},

		getLayoutNameErrorMessage() {
			const field = this.$v.layoutName;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length: 1 - 255 Characters"
			});
		},

		getConfigLayout() {
			return {
				layout: {
					...getConfigGenGasOverview(this.genGasInfo.data.overview),
					shipToLocation: this.genGasInfo.shipToLocation,
					flowRate: this.genGasInfo.flowRate,
					consumption: this.genGasInfo.consumption
				}
			};
		},

		getApplyToData() {
			return {
				soldTos: this.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
				shipTos: this.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
				devices: this.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
			};
		}
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		// end ship to selected and remove
		overrideOverViewTextField(index, value) {
			this.genGasInfo.data.overview[index].field = value;
		},
		overrideOverViewSelectedDropdown(index, value) {
			this.genGasInfo.data.overview[index].type = value;
		},
		overrideOverView(index, value) {
			this.genGasInfo.data.overview[index].value = value;
		},

		handleToggle(value, name) {
			this.genGasInfo[name] = value;
		},

		handleChangeSoldTo(value) {
			this.soldToValues = value;
		},

		handleChangeShipTo(value) {
			this.shipToValues = value;
		},

		handleChangeDevice(value) {
			this.deviceValues = value;
			this.duplicateDevice = [];
		},

		handleCancle() {
			this.$router.back();
		},

		// eslint-disable-next-line consistent-return
		handleSubmit(callback) {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				return callback();
			}
		},


		async handleSendPayload() {
			try {
				const payload = {
					name: this.layoutName,
					app: "GENGAS",
					appliedTo: this.getApplyToData,
					...this.getConfigLayout
				};

				await storeLayoutTemplate(payload);

				this.$router.push({
					name: PAGE_NAME.LAYOUT_CUSTOMISATION_LIST
				});

				this.showToast({
					type: TOAST_TYPES.LAYOUT_CUSTOMISATION,
					props: { message: `Gen Gas layout create success.`, delay: 6000 }
				});
			} catch (e) {
				const { deviceIds } = e.response.data;
				this.duplicateDevice = this.deviceValues.filter((v) => {
					return deviceIds.indexOf(v.id) !== -1;
				});

				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to save", message: "Gen Gas layout unable to save.", delay: 6000 }
				});
			}
		}

	}
};
</script>

<style lang="scss" scoped>
.page-back-btn {
	margin-bottom: rem(24);
}

.card-chart,
.card-ship-to-location {
	/* stylelint-disable */
	&::v-deep {
		.card-body {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	/* stylelint-enable */
}

.card-chart {
	min-height: rem(216);
}

.card-ship-to-location {
	min-height: rem(350);
}

.wrapper-confirm-create {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.head-title {
	font-family: $font-family-sub;
	font-size: $font-32;
	color: $color-dark-blue-grey;
	margin: 0 0 rem(16) 0;
}
</style>