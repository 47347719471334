<template>
	<div>
		<BaseLoading v-if="tripInfo.isLoading"/>
		<div v-else class="container-trip-info" >
			<div class="container-left">
				<div v-if="getTruckLocation && getShipToLocation" class="google-maps">
					<TripInfoMap
						:companyName="getTripInfoInfomation.shipName"
						:truckLocation="getTruckLocation"
						:shipToLocation="getShipToLocation"
					/>
				</div>
				<TruckIcon
					v-else
					:status="truckStatus"
					:class="['truck-icon',{ active: isMultiDrop }]"
				/>
			</div>
			<div v-if="(isSingleDrop || isMultiDrop) && getShipToLocation" class="container-right">
				<div class="info">
					<DriverInfo
						:class="['drivers-info',{ active: isSingleDrop || isMultiDrop }]"
						:data="getTripInfoInfomation"
						:drivers="getTripInfoDrivers"
					/>
					<Tracking
						:tracks="getTrackingRoute"
						:duration="getTripInfoInfomation.duration"
						:estimate-time-stamp="getTripInfoInfomation.estimateTimeStamp"
						:on-target="this.getTripInfoInfomation.onTarget"
						:truck-location="getTruckLocation"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import TruckIcon from "@/components/dashboard/modules/TankTripInfo/TruckIcon.vue";
import TripInfoMap from "@/components/map/TripInfoMap.vue";
import DriverInfo from "@/components/dashboard/modules/TankTripInfo/DriverInfo.vue";
import Tracking from "@/components/dashboard/modules/TankTripInfo/Tracking.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { TRUCK_STATUS, TRIPINFO_MAP_TYPE } from "../enums/modules";

export default {
	name: "tripInfo",

	components: {
		TruckIcon,
		DriverInfo,
		Tracking,
		TripInfoMap,
		BaseLoading
	},

	data() {
		return {
			TRUCK_STATUS,
			TRIPINFO_MAP_TYPE,
			tankId: this.$route.params.id,
			updateTruck: ""
		};
	},

	computed: {
		...mapState(["tankInfo", "tripInfo"]),

		...mapGetters({
			getShipToLocation: "tankInfo/getShipToLocation",
			getTripInfoDrivers: "tripInfo/getTripInfoDrivers",
			getTripInfoType: "tripInfo/getTripInfoType",
			getTripInfoInfomation: "tripInfo/getTripInfoInfomation",
			getTruckLocation: "tripInfo/getTruckLocation"
		}),

		// trip type
		isNoDelivery() {
			return this.getTripInfoType === this.TRIPINFO_MAP_TYPE.NO_DELIVERY;
		},

		isSingleDrop() {
			return this.getTripInfoType === this.TRIPINFO_MAP_TYPE.SINGLE_DROP;
		},

		isMultiDrop() {
			return this.getTripInfoType === this.TRIPINFO_MAP_TYPE.MULTI_DROP;
		},

		truckStatus() {
			return this.TRUCK_STATUS[this.tankInfo.data.deliveryStatus];
		},

		getTrackingRoute() {
			return [
				{
					title: "BIG site",
					isInTransit: true,
					isActive: true
				},
				// Case 2: Multi Delivery
				{
					title: "In Transit",
					isInTransit: this.getTripInfoInfomation.onTarget === 0,
					isActive: false
				},
				{
					title: this.getTripInfoInfomation.shipName,
					isInTransit: true,
					isActive: false
				}
			];
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogTripInfoView({
			httpStatus: this.tripInfo.statusCode,
			shopToId: this.tankId
		});
		this.$emit("loaded");
	},

	destroyed() {
		clearInterval(this.updateTruck);
	},

	methods: {
		...mapActions({
			getTripInfo: "tripInfo/getTripInfo",
			getUpdateTruckLocation: "tripInfo/getUpdateTruckLocation"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogTripInfoView: "addActivityLogTripInfoView"
		}),
		async fetchData() {
			await this.getTripInfo({ id: this.tankId });
			await this.handleUpdateTruck();
		},
		handleUpdateTruck() {
			this.updateTruck = setInterval(() => {
				this.getUpdateTruckLocation({
					id: this.tankId
				});
			}, 15000 * 10);
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	&-trip-info {
		display: flex;
		margin-top: -$dashboard-module-nav-spacing;
		margin-left: -$main-content-padding;
		margin-bottom: -$main-content-padding;
	}

	&-left {
		display: flex;
		flex: 1;
		min-height: rem(500);
		justify-content: center;

		.truck-icon {
			margin-top: rem(64);
		}

		.google-maps {
			display: flex;
			flex: 1;
			position: relative;
			height: rem(767);
			overflow: hidden;
		}

		.google-maps iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}

	&-right {
		display: flex;
		align-items: flex-start;
		width: rem(345);
		border-left: 1px solid $color-silver;

		> .info {
			padding-left: rem(30);
			margin-bottom: rem(30);

			.drivers-info {
				border-bottom: 1px solid $color-silver;
				margin-bottom: $spacing-6;
			}

			hr {
				border: none;
				height: 1px;
				background: $color-silver;
				margin-bottom: $spacing-6;
			}
		}

		.info {
			.container-main {
				&:first-of-type {
					margin-top: $spacing-7;
				}
			}
		}
	}

	@include tablet {
		&-trip-info {
			display: flex;
			flex-direction: column;
			margin-top: -$dashboard-module-nav-spacing;
			margin-left: -$main-content-padding;
			margin-bottom: -$main-content-padding;
		}

		&-left {
			min-height: auto;
			margin-right: -$main-content-padding;

			.truck-icon {
				&.active {
					border-bottom: 1px solid $color-silver;
					padding-bottom: rem(20);
				}
			}
		}

		&-right {
			width: 100%;
			border: 0;
		}

		.info {
			width: 100%;
		}
	}
}
</style>
