<template>
	<BaseLayout pageTitle="Dashboard" :isLoading="isLoading">
		<div class="wrapper-select-view">
			<BaseButtonGroup
				:items="listViewOptions"
				:active="viewType"
				type="secondary"
				size="small"
				@click="handleSelectView"
			/>
		</div>
		<div class="main-content">
			<div class="row">
				<SearchFilterFormListView
					:owners="ownersOptions"
					:products="productOptions"
					:companies="companiesOptions"
					:query="query"
					:isShowSale="isShowSale"
					@submit="handleSubmitFilter"
				/>
			</div>
			<ul v-if="!isMobile" class="nav nav-tabs">
				<li class="nav-item">
					<router-link
						:to="{ name: 'dashboard', query: getQueryChangeTab }"
						:class="['nav-link', { active: query.module === null }]"
						exact
					>
						All
					</router-link>
				</li>
				<li class="nav-item" v-for="(tabModule,index) in user.module.data" :key="index">
					<router-link
						:to="{ name: 'dashboardModule', params: { module: tabModule}, query: getQueryChangeTab} "
						:class="['nav-link', { active: query.module === tabModule }]"
						exact
					>
						{{tabModule}}
					</router-link>
				</li>
			</ul>
			<BaseDropdown
				v-else
				:searchable="false"
				:list="moduleOptions"
				:value="query.module"
				placeholder="Device: All"
				@input="handleStatusSelected"
				class="modules-mobile"
			/>
			<div class="module-list">
				<div v-if="modules.data.length" class="page-meta module-meta">
					<BaseSortBy
						v-model="sortOptions.defaultAtIndex"
						:options="sortOptions"
						@change="handleChangeSortBy"
					/>
					<div v-if="!isMobile" class="group-pagination-sort-table">
						<BasePagination
							v-if="pagination.lastPage > 1"
							:value="pagination.currentPage"
							:totalPage="pagination.lastPage"
							class="top-module-pagination"
							@onPageChange="handlePageChange"
						/>
						<span class="page-total-meta">{{ getShipToLabel }} of {{ pagination.total }} sold-to</span>
					</div>
				</div>
				<!-- Module items -->
				<div class="module-items">
					<BaseLoading
						v-if="isLoading"
					/>
					<NoAvailableModule
						v-else-if="availableModules.length === 0"
					/>
					<EmptyShipTo
						v-else-if="getNoShipTo"
					/>
					<BaseNoResult
						v-else-if="modules.data.length === 0"
						description="Try adjusting your search by changing your ship-to name or location key, and removing filters."
					/>
					<template
						v-else
					>
						<ModuleList
							v-for="(item, index) in modules.data"
							:key="index"
							:data="item"
							:query="queryParams"
						/>
					</template>
				</div>
			</div>
			<BasePagination
				v-if="pagination.lastPage > 1"
				:value="pagination.currentPage"
				:totalPage="pagination.lastPage"
				:offsetPage="3"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>
			<span class="page-meta-mobile">{{ getShipToLabel }} of {{ pagination.total }} sold-to</span>
		</div>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModuleList from "@/components/dashboard/ModuleList.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import SearchFilterFormListView from "@/components/dashboard/SearchFilterFormListView.vue";
import EmptyShipTo from "@/components/dashboard/EmptyShipTo.vue";
import BaseSortBy from "@/components/BaseSortBy.vue";
import NoAvailableModule from "@/components/dashboard/NoAvailableModule.vue";
import { getOwnersAPI } from "../../services/api/owners.api";
import { getProductsAPI } from "../../services/api/products.api";
import { getCompaniesListAPI } from "../../services/api/companies";
import {
	getOwnersOptions,
	getCompaniesOptions,
	getShipToDisplay
} from "../../selectors/helpers/dashboard";
import { cleanParams } from "../../selectors/helpers";
import { dropdownProductTypeOptions } from "../../enums/productType";
import { MODULE_TYPES } from "../../enums/modules";
import { setDashBoardQuery } from "../../selectors/helpers/backtoDashBoard";
import { STAFF_ROLES } from "../../enums/role";
import { USER_TYPE } from "../../enums/user";
import setupDevice from "../../mixins/setupDevice";

export default {
	name: "DashBoardDeviceView",

	components: {
		BaseLoading,
		BaseNoResult,
		ModuleList,
		SearchFilterFormListView,
		EmptyShipTo,
		BaseButtonGroup,
		BaseSortBy,
		NoAvailableModule
	},

	mixins: [setupDevice],

	props: {
		viewType: {
			type: String,
			default: "lists"
		},
		listViewOptions: {
			type: Array,
			default: () => ([
				{
					value: "device",
					text: "Device View",
					icon: ["far", "grip-horizontal"]
				},
				{
					value: "lists",
					text: "Company View",
					icon: ["far", "list-ul"]
				}
			])
		}
	},
	data() {
		return {
			MODULE_TYPES,
			query: {
				page: this.$route.query.page || null,
				module: this.$route.params.module || null,
				sort: this.$route.query.sort || null,
				q: this.$route.query.q || null,
				owner: Number(this.$route.query.owner) || null,
				productname: this.$route.query.productname || null,
				company: Number(this.$route.query.company) || null
			},
			productList: [],
			dataPerPage: 12,
			ownerList: [],
			companiesList: [],
			sortOptions: {
				defaultAtIndex: 0,
				title: "Sort by",
				dropdown: [
					{
						index: 0,
						code: null,
						text: "Name (A-Z)"
					},
					{
						index: 1,
						code: "name:desc",
						text: "Name (Z-A)"
					}
				],
				style: {
					position: "left",
					width: "150px"
				}
			},
			isLoading: false
		};
	},

	computed: {
		...mapState(["modules", "user"]),

		pagination() {
			return this.modules.pagination;
		},

		isShowSale() {
			const isBigStaff = this.user.profile.type === USER_TYPE.STAFF;
			const isSaleRole = this.user.role === STAFF_ROLES.SALES;
			const isSalePlustRole = this.user.role === STAFF_ROLES.SALES_PLUS;
			return isBigStaff && !isSaleRole && !isSalePlustRole;
		},

		currentPageTotal() {
			if (this.pagination.currentPage === this.pagination.lastPage) {
				return this.pagination.total;
			}

			return this.pagination.currentPage * this.pagination.perPage;
		},

		ownersOptions() {
			return getOwnersOptions(this.ownerList);
		},

		companiesOptions() {
			return getCompaniesOptions(this.companiesList);
		},

		productOptions() {
			return [...dropdownProductTypeOptions];
		},

		queryParams() {
			const module = this.query.module;
			return { ...this.query, module };
		},

		getShipToLabel() {
			return getShipToDisplay(
				this.pagination.currentPage,
				this.dataPerPage,
				this.pagination.total
			);
		},

		getQueryChangeTab() {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.query,
				...this.$route.query,
				sort: null,
				page: null
			})));
			return query;
		},

		getNoShipTo() {
			const query = cleanParams(JSON.parse(JSON.stringify(this.query)));
			const isOnQuery = Object.keys(query).length < 1;
			return (
				(this.modules.isNoShipTo || this.modules.data.length < 1) && isOnQuery
			);
		},

		availableModules() {
			return this.user.module.data;
		},

		moduleOptions() {
			const newModules = this.availableModules.map((item) => {
				return {
					value: item,
					label: item
				};
			});
			return [
				{
					value: null,
					label: "Device: All"
				},
				...newModules
			];
		}
	},

	updated() {
		setDashBoardQuery({
			...this.query,
			...this.$route.query
		});
	},

	async created() {
		this.isLoading = true;
		this.setState();
		await this.fetchModules();
		await this.fetchCompaniesList();
		await this.fetchProducts();
		await this.fetchUserModules();
		if (this.isShowSale) {
			await this.fetchOwneers();
		}
		this.isLoading = false;
	},

	methods: {
		...mapActions(["getModules"]),
		...mapActions("user", {
			getUserModules: "getUserModules"
		}),

		setState() {
			const sortQuery = this.$route.query.sort;
			if (sortQuery) {
				this.sortOptions.defaultAtIndex = this.sortOptions.dropdown.find(({ code }) => code === sortQuery)?.index || 0;
			}
		},

		async fetchModules() {
			await this.getModules(this.queryParams);
		},

		async fetchProducts() {
			const products = await getProductsAPI();
			this.productList = products.data.data;
		},

		async fetchOwneers() {
			const owners = await getOwnersAPI();
			this.ownerList = owners.data.data;
		},

		async fetchCompaniesList() {
			const companies = await getCompaniesListAPI();
			this.companiesList = companies.data;
		},

		async fetchUserModules() {
			await this.getUserModules();
		},

		handlePageChange(page) {
			this.query.page = page;
			this.handleChangeQuery();
		},

		handleSelectView(value) {
			this.$emit("handleSwitchView", value);
		},

		setActiveModule(type) {
			this.query.module = type;
			this.query.page = null;
			this.query.sort = null;
			this.handleChangeQuery();
		},

		setSorting(value) {
			this.query.sort = value;
			this.query.page = null;
			this.handleChangeQuery();
		},

		handleChangeQuery() {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.$route.query,
				...this.query
			})));

			this.$router.push({ query }).catch(() => {});
		},

		handleSubmitFilter(filters = {}) {
			this.query.q = filters.keyword;
			this.query.productname = filters.product;
			this.query.company = filters.company;
			this.query.owner = filters.owner;
			this.query.page = null;
			this.handleChangeQuery();
		},

		handleChangeSortBy({ index, code }) {
			this.sortOptions.defaultAtIndex = index;
			this.setSorting(code);
		},

		handleStatusSelected(value) {
			this.$router.push({
				name: value ? "dashboardModule" : value ?? "dashboard",
				params: {
					module: value
				},
				query: this.getQueryChangeTab
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.main-content {
	padding: rem(12) 0;
}

.page-meta {
	font-family: $font-family-sub;
	color: $color-grey-2;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: rem(24);

	.group-pagination-sort-table {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.top-module-pagination {
			margin: 0 rem(-6) rem(10) 0;
		}
	}
}

.page-meta-mobile {
	font-family: $font-family-sub;
	color: $color-grey-2;
	display: flex;
	justify-content: flex-end;
	margin-bottom: rem(24);
}

.module-pagination {
	justify-content: flex-end;
	margin: rem(32) rem(-12) rem(8) 0;
}

.empty-list {
	font-size: $font-20;
	font-weight: 600;
	margin-top: rem(32);
	text-align: center;
}

.wrapper-select-view {
	display: flex;
	align-content: center;
	justify-content: center;
	position: absolute;
	top: rem(40);
	right: rem(30);

	@include tablet {
		top: rem(108);
	}

	@include mobile {
		position: relative;
		top: 0;
		right: auto;

		.button-group-container {
			width: 100%;

			&::v-deep .btn {
				font-size: $font-16;
				height: rem(48);
				justify-content: center;
				width: 50%;
			}
		}
	}
}

.modules-mobile {
	margin-bottom: rem(16);

	::v-deep &.input-wrapper {
		&.medium {
			.dropdown-value {
				background-color: $color-white;
				height: rem(44);
				line-height: rem(44);
			}

			.dropdown-value-text {
				height: rem(44);
				line-height: rem(44);
			}
		}
	}
}
</style>
