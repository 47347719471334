import { STAFF_ROLES } from "../../../enums/role";

export const getTransformStaffs = (data = []) => {
	return data.map(({ id, displayName, email, role: { code, displayName: roleName }, isActive, isTestAccount }) => ({
		id,
		name: displayName,
		email,
		role: roleName,
		roleCode: code,
		isActive,
		isTestAccount
	}));
};

export const getIsSuperAdmin = (role = "") => {
	return role === STAFF_ROLES.SUPER_ADMIN;
};