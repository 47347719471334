<template>
	<modal :name="modalName" :scrollable="true" width="454" height="auto">
		<BaseModalContent theme="light">
			<template v-slot:header>
				Change password
				<span class="close-button" @click="closeModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div class="item">
						<div class="label">
							Current password
						</div>
						<div class="form">
							<BaseFormInputPassword
								v-model="currentPassword"
								:valid.sync="isValidCurrentPassword"
								:showIconLeft="false"
								:customErrorMessage="currentPasswordErrorMessage"
								formatType="simple"
								placeholder=""
								class="custom-input"
							/>
						</div>
					</div>
					<div class="item">
						<div class="label">
							New password
						</div>
						<div class="form">
							<BaseFormInputPassword
								v-model="newPassword"
								:valid.sync="isValidNewPassword"
								:showIconLeft="false"
								formatType="compact"
								placeholder=""
								class="custom-input"
							/>
						</div>
					</div>
					<div class="item">
						<div class="label">
							Confirm password
						</div>
						<div class="form">
							<BaseFormInputPassword
								v-model="confirmPassword"
								:valid.sync="isValidConfirmPassword"
								:showIconLeft="false"
								:matchWith="newPassword"
								formatType="compact"
								placeholder=""
								class="custom-input"
							/>
						</div>
					</div>
				</div>
				<BaseInfoList
					:list="infoList"
					:valid.sync="isValidFormat"
				>
					<template v-slot:title>
						In order to <b>protect your account</b>, your password must include:
					</template>
				</BaseInfoList>
			</template>
			<template v-slot:footer>
				<BaseButton
					size="large"
					:disabled="disabledButton"
					@click="onChangePassword"
				>
					Change password
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseModalContent from "@/components/BaseModalContent.vue";
import BaseFormInputPassword from "@/components/BaseFormInputPassword.vue";
import BaseInfoList from "@/components/BaseInfoList.vue";
import { getHasUpperCase, getHasLowwerCase, getHasNumber } from "../../selectors/helpers/regex";
import { TOAST_TYPES } from "../../enums/toast";

export default {
	name: "ModalChangePassword",

	components: {
		BaseModalContent,
		BaseFormInputPassword,
		BaseInfoList
	},

	props: {
		modalName: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
			isValidCurrentPassword: null,
			isValidNewPassword: null,
			isValidConfirmPassword: null,
			isValidFormat: false,
			infoList: [
				{
					text: "8 characters minimum",
					active: false
				},
				{
					text: "1 number (0-9)",
					active: false
				},
				{
					text: "1 lowercase letter (a-z)",
					active: false
				},
				{
					text: "1 uppercase letter (A-Z)",
					active: false
				}
			]
		};
	},

	computed: {
		...mapState(["customer"]),

		disabledButton() {
			return !this.isValidCurrentPassword
				|| !this.isValidNewPassword
				|| !this.isValidConfirmPassword
				|| !this.isValidFormat
				|| !this.isMatched;
		},

		isMatched() {
			return this.newPassword === this.confirmPassword;
		},

		currentPasswordErrorMessage() {
			return this.customer.customerChangeErrorMessage;
		},

		customerChangeErrorUnexpected() {
			return this.customer.customerChangeErrorUnexpected;
		}
	},

	watch: {
		newPassword(value) {
			this.infoList[0].active = value.length >= 8;
			this.infoList[1].active = getHasNumber(value);
			this.infoList[2].active = getHasLowwerCase(value);
			this.infoList[3].active = getHasUpperCase(value);
		},
		currentPassword() {
			if (this.currentPasswordErrorMessage.length > 0) {
				this.clearChangePasswordError();
			}
		}
	},

	methods: {
		...mapActions({
			showToast: "toast/show",
			putChangePassword: "customer/putChangePassword",
			clearChangePasswordError: "customer/clearChangePasswordError"
		}),

		async onChangePassword() {
			await this.putChangePassword({
				currentPassword: this.currentPassword,
				password: this.newPassword,
				passwordConfirmation: this.confirmPassword
			});

			const success = this.customer.customerChangePasswordSuccess;

			if (success) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Password changed successful."
					}
				});
				this.closeModal();
			}

			if (this.customerChangeErrorUnexpected) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: {
						title: this.customerChangeErrorUnexpected
					}
				});
			}
		},

		closeModal() {
			this.$modal.hide(this.modalName);
			this.clearForm();
			this.clearChangePasswordError();
		},

		clearForm() {
			this.currentPassword = "";
			this.newPassword = "";
			this.confirmPassword = "";
			this.isValidCurrentPassword = null;
			this.isValidNewPassword = null;
			this.isValidConfirmPassword = null;
			this.isValidFormat = false;
			this.infoList[0].active = false;
			this.infoList[1].active = false;
			this.infoList[2].active = false;
			this.infoList[3].active = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	margin-bottom: $spacing-6;

	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			flex-grow: 1;
			align-items: center;
		}

		.form {
			width: rem(280);
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}

.custom-input {
	/* stylelint-disable */
	&::v-deep .input {
		padding: rem(4) rem(16);
	}

	&::v-deep .is-right {
		position: relative;
		width: rem(24);
		height: rem(24);
		margin-left: rem(6);

		&:first-of-type {
			margin-left: 0;
		}

		svg {
			height: rem(16);
		}
	}
	/* stylelint-enable */
}
</style>