<template>
	<BaseLayout>
		<BaseBackBtn label="Back" :to="PAGE_NAME.MARKETPLACE" />
		<ul class="nav nav-tabs">
			<li class="nav-item" v-for="(tabModule,index) in bigStoreManagement.groupDetail.modules" :key="index">
				<router-link
					:to="{ name: PAGE_NAME.MARKETPLACE_MODULE_GROUP_DETAIL, params: { module: tabModule.group}, query: getQueryChangeTab}"
					:style="{ color: query.module === tabModule.group ? tabModule.color : '#808c85' , '--bgcolor': tabModule.color}"
					:class="['nav-link', { active: query.module === tabModule.group }]"
					exact
				>
					{{tabModule.group}}
				</router-link>
			</li>
		</ul>
		<BaseLoading
			v-if="bigStoreManagement.isLoadingGroupDetail"
			class="devices-lists-loading"
		/>
		<div class="wrapper-content" v-else>
			<div class="image">
				<img :src="bigStoreManagement.groupDetail.groupDevice.partImg">
				<div class="header-title">{{bigStoreManagement.groupDetail.groupDevice.groupDisplay}}</div>
				<div class="backdrop"></div>
			</div>
			<div class="detail-content">
				<div class="detail-description">
					{{bigStoreManagement.groupDetail.groupDevice.description}}
				</div>
			</div>
			<div v-if="bigStoreManagement.groupDetail.features.length > 0" class="feature-content">
				<div class="feature-content-header">
					Features
				</div>
				<div class="feature-content-detail">
					<div v-for="(item,index) in bigStoreManagement.groupDetail.features" :key="index" class="card-service">
						<div class="icon-app" :style="{backgroundColor : item.color}" >
							<img :src="item.icon">
						</div>
						<div class="content">
							<div class="header-content" :title="item.title">{{item.title}}</div>
							<div class="detail-content" :title="item.descriptions">{{item.descriptions}}</div>
						</div>
						<div class="navigate-content">
							<BaseButton
								class="btn-cancel"
								size="small"
								type="primary"
								outline
								@click="handleSeeDetail(item.type, item.demoId, item.id, item.productId, item.isStandardModule, item.templateId)"
							>
								See Details
							</BaseButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { cleanParams } from "../../selectors/helpers";
import { CONFIG } from "../../enums/config";

export default {
	name: "ModuleGroupDetail",

	components: {
		BaseBackBtn,
		BaseLoading
	},

	data() {
		return {
			query: {
				module: this.$route.params.module || null
			},
			PAGE_NAME
		};
	},

	computed: {
		...mapState(["bigStoreManagement"])
	},

	async created() {
		await Promise.all([
			this.fetchMarketplaceGroupModuleDetail({ module: this.query.module }),
			this.addActivityLogMarketplaceModuleGroupDetail(this.query.module)
		]);
	},

	methods: {
		...mapActions("bigStoreManagement", {
			fetchMarketplaceGroupModuleDetail: "fetchMarketplaceGroupModuleDetail"
		}),

		...mapActions("activityLogAdd", {
			addActivityLogMarketplaceModuleGroupDetail: "addActivityLogMarketplaceModuleGroupDetail"
		}),

		getQueryChangeTab() {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.query,
				...this.$route.query
			})));
			return query;
		},

		handleSeeDetail(type, id, serviceId, productId = "", isStandardModule = false, templateId = null) {
			const isBHY = productId === null ? false : productId.toLowerCase() === CONFIG.EXAMPLE_MODULE_ROUTE.BHY;
			const productType = isBHY
				? CONFIG.EXAMPLE_MODULE_ROUTE.BHY
				: CONFIG.EXAMPLE_MODULE_ROUTE[type];

			if (productType !== null && id !== null && serviceId !== null) {
				// This condition route to std module example device
				if (isStandardModule && templateId) {
					this.$router.push({
						path: `/marketplace/example-module/${id}/standard-module/${serviceId}/${productType}/${templateId}/overview`
					});
				} else {
					this.$router.push({
						path: `/marketplace/example-module/${id}/${productType}/${serviceId}/info?redirect=home`
					});
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.nav-tabs {
	margin-top: rem(36);
	margin-left: 0;
	margin-right: 0;
}

.active::before {
	background-color: var(--bgcolor) !important;
}

.wrapper-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: rem(24);
	font-size: $font-48;
	font-weight: $font-weight-bold;

	.image {
		position: relative;
		width: 100%;

		.header-title {
			position: absolute;
			left: 5%;
			top: 50%;
			z-index: 2;
			font-size: $font-48;
			font-weight: $font-weight-bold;
			color: $color-white;
			transform: translate(0, -50%);
		}

		img {
			height: 100%;
			max-height: rem(240);
			width: 100%;
			border-radius: rem(8);
			object-fit: cover;
		}

		.backdrop {
			position: absolute;
			top: 0;
			z-index: 1;
			height: 100%;
			max-height: rem(240);
			width: 100%;
			border-radius: rem(8);
			background-color: rgba(0, 0, 0, 0.25);
		}
	}

	.detail-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.head-detail {
			padding-top: rem(16);
			font-size: $font-48;
			font-weight: $font-weight-bold;
		}

		.detail-description {
			padding-top: rem(12);
			width: 50%;
			font-size: $font-24;
			word-break: break-word;
			font-weight: $font-weight-regular;

			@include mobile {
				width: 100%;
			}
		}
	}

	.feature-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: rem(36) 0;

		//feature-content-header
		&-header {
			font-size: $font-32;
			font-weight: $font-weight-bold;
		}

		//feature-content-detail
		&-detail {
			display: flex;
			flex-wrap: wrap;
			gap: rem(12);
			margin-top: rem(16);
			width: 100%;
		}
	}

	.card-service {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: rem(300);
		height: rem(246);
		padding: rem(16);
		border: 1px solid $color-grey-3;
		border-radius: rem(8);

		.icon-app {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(80);
			height: rem(80);
			border-radius: rem(40);
			background-color: var(--bgThemecolor);

			img {
				width: rem(56);
				height: rem(56);
			}
		}

		.content {
			width: 100%;
			margin-top: rem(12);

			.header-content {
				font-size: $font-18;
				font-weight: $font-weight-bold;
				text-align: start;

				@include ellipsis(1, break-word);
			}

			.detail-content {
				height: rem(60);
				font-size: $font-18;
				font-weight: $font-weight-regular;
				text-align: start;

				@include ellipsis(2, break-word);
			}
		}

		.navigate-content {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}
}

@media (max-width: rem(670)) {
	::v-deep {
		.content-container {
			padding-left: 0;
			padding-right: 0;
		}

		.back {
			margin-left: rem(30);
		}
	}

	.detail-content {
		padding-left: rem(30);
		padding-right: rem(30);
	}

	.feature-content {
		padding-left: rem(30);
		padding-right: rem(30);

		// feature-content-detail
		&-detail {
			flex-flow: column nowrap;
			align-items: center;
		}
	}

	img {
		border-radius: 0 !important;
	}

	.backdrop {
		border-radius: 0 !important;
	}

	.card-service {
		width: 100% !important;
	}
}
</style>
