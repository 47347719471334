<template>
	<BaseCardIcon color="green-light" class="container-no-result">
		<template v-slot:icon>
			<svg xmlns="http://www.w3.org/2000/svg" width="63" height="62" viewBox="0 0 63 62">
				<g fill="none" fill-rule="evenodd">
					<path fill="#009A44" d="M38.846 41.523V55.4c0 2.295-1.858 4.152-4.153 4.152H20.816c2.295 0 4.152-1.857 4.152-4.152v-5.573c10.6 0 13.878-8.304 13.878-8.304z" transform="translate(0 .333)"/>
					<g fill="#103332" fill-rule="nonzero">
						<path d="M48.571 58.405H62.938V60.59H48.571zM34.693 58.405H41.632V60.59H34.693zM20.871 58.405H27.81V60.59H20.871zM0 58.405H15.298V60.59H0z" transform="translate(0 .333)"/>
						<path d="M20.87 60.59h-5.572c-2.896 0-5.245-2.349-5.245-5.245V17.92c0-4.097 2.896-6.665 4.644-7.812l.273-.164h6.228l.274.164c1.748 1.147 4.644 3.715 4.644 7.812v37.425c0 2.896-2.35 5.245-5.245 5.245zm-5.244-48.516c-1.366.984-3.388 2.95-3.388 5.846v37.425c0 1.694 1.366 3.06 3.06 3.06h5.573c1.693 0 3.06-1.366 3.06-3.06V17.92c0-2.895-2.022-4.862-3.388-5.846h-4.917z" transform="translate(0 .333)"/>
						<path d="M8.36 53.706H3.113V4.043c0-2.13 1.749-3.88 3.88-3.88h8.304c2.13 0 3.879 1.75 3.879 3.88v2.786h-2.185V4.043c0-.929-.765-1.694-1.694-1.694H6.993c-.928 0-1.693.765-1.693 1.694v47.423h3.06v2.24z" transform="translate(0 .333)"/>
						<path d="M4.207 18.248H8.359V20.433H4.207zM15.298 5.737H20.871000000000002V7.922000000000001H15.298zM4.207 26.553H8.359V28.738H4.207zM4.207 34.857H8.359V37.042H4.207zM4.207 43.162H8.359V45.347H4.207zM34.693 60.59h-6.938v-2.185h6.938c1.694 0 3.06-1.366 3.06-3.06V17.92c0-2.895-2.022-4.862-3.387-5.846h-4.863c-.492.328-.874.71-1.257 1.038l-.054.055c-.055.055-2.076 1.803-2.076 4.699H23.93c0-3.77 2.459-6.01 2.841-6.338.547-.546 1.148-1.038 1.803-1.475l.273-.164h6.229l.273.164c1.748 1.147 4.644 3.77 4.644 7.813V55.29c-.055 2.95-2.404 5.3-5.3 5.3zM33.054 6.83H30.87V4.042c0-.929-.765-1.694-1.694-1.694h-8.304V.164h8.304c2.131 0 3.88 1.748 3.88 3.879v2.786z" transform="translate(0 .333)"/>
						<path d="M29.175 5.737H34.748000000000005V7.922000000000001H29.175zM48.57 60.59h-6.938v-2.185h6.939c1.693 0 3.06-1.366 3.06-3.06V17.92c0-2.895-2.022-4.862-3.388-5.846H43.38c-.437.328-.874.71-1.256 1.038l-.055.055s-2.076 1.858-2.076 4.699h-2.185c0-3.66 2.458-6.01 2.786-6.338.546-.546 1.147-1.038 1.803-1.475l.273-.164h6.228l.274.164c1.748 1.147 4.644 3.77 4.644 7.813V55.29c0 2.95-2.35 5.3-5.245 5.3zM46.877 6.83h-2.185V4.042c0-.929-.765-1.694-1.694-1.694h-8.305V.164h8.305c2.13 0 3.879 1.748 3.879 3.879v2.786z" transform="translate(0 .333)"/>
						<path d="M42.998 5.737H48.571V7.922000000000001H42.998z" transform="translate(0 .333)"/>
					</g>
					<path fill="#103332" fill-rule="nonzero" d="M15.298 58.405H48.571000000000005V60.59H15.298z" transform="translate(0 .333)"/>
				</g>
			</svg>
		</template>
		<template v-slot:head>
			<div class="no-ship-to-head">
				{{ message }}
			</div>
		</template>
	</BaseCardIcon>
</template>

<script>
import BaseCardIcon from "@/components/BaseCardIcon.vue";

export default {
	name: "BaseNoRecord",

	components: {
		BaseCardIcon
	},

	props: {
		message: {
			type: String,
			default: ""
		}
	}
};
</script>

<style lang="scss" scoped>
.container-no-result {
	margin-top: rem(64);
}
</style>