import { PAGE_NAME } from "../enums/pagePermission";
import { getPermissionWithConsumptionApproval } from "../selectors/helpers/consumptioApproval";

export const beforeEnterMaintenanceModePage = (vuexStore) => async (to, from, next) => {
	await vuexStore.dispatch("maintenanceStatus/getMaintenanceStatus");

	const isInMaintenanceMode = vuexStore.state.maintenanceStatus.data.status;
	if (!isInMaintenanceMode) {
		return next({
			path: "/dashboard"
		});
	}

	return next();
};

export const beforeEnterConsumptionApprovalPage = (vuexStore) => async (to, from, next) => {
	await vuexStore.dispatch("user/getUserInfo");

	const rolePermissions = vuexStore.state.user.rolePermissions;

	if (getPermissionWithConsumptionApproval(rolePermissions)) {
		return next();
	}

	return next({
		name: PAGE_NAME.PERMISSION_REQUIRED
	});
};


