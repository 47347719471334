import dayjs from "dayjs";
import { numberFormat } from "../../../../selectors/helpers";

const yAxisText = `Furnace Temp (°C)`;

const tooltipLineChartFormat = (name, value) => {
	const format = `
                    <div>
                        <div class="bar-name meter">
                            ${name}
                        </div>
                        <br />
                        <div class="bar-value">
                            ${value !== undefined || value !== null ? numberFormat(value, "0,0") : "N/A"}°C
                        </div>
                    </div>`;

	return format;
};

const tooltipFormatter = ([pointValue], dataOfDateTime) => {
	const date = dayjs(dataOfDateTime).format("HH.mm, D MMM YYYY");
	return tooltipLineChartFormat(date, pointValue.data);
};


export const transformFurnaceTemperatureLineChartConfig = (keys = [], values = [], date = new Date()) => {
	return {
		grid: {
			left: 16,
			right: 16,
			bottom: 54,
			containLabel: true
		},
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#103332",
			axisPointer: {
				crossStyle: {
					color: "red"
				}
			},
			padding: 8,
			formatter: ((param) => tooltipFormatter(param, date))
		},
		color: ["#103332"],
		xAxis: {
			axisPointer: {
				show: true,
				triggerTooltip: true,
				label: {
					show: true
				}
			},
			axisLabel: {
				color: "#103332",
				margin: 8,
				fontSize: 14,
				show: true
			},
			axisTick: {
				length: 4,
				lineStyle: {
					width: 1,
					color: "#586c63"
				},
				show: false
			},
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			type: "category",
			data: keys
		},
		yAxis: {
			name: yAxisText,
			type: "value",
			data: {
				type: "value"
			},
			nameTextStyle: {
				color: "#717171",
				width: "100px",
				fontFamily: "TFSrivichai",
				fontWeight: "bold",
				fontSize: 20,
				padding: [
					0,
					0,
					24,
					-24
				],
				align: "left"
			},
			axisLabel: {
				color: "#103332",
				margin: 8,
				fontSize: 14
			},
			splitNumber: 4,
			splitLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			axisTick: {
				length: 4,
				lineStyle: {
					width: 1,
					color: "#586c63"
				}
			},
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			}
		},
		series: [
			{
				data: values,
				type: "line",
				smooth: true,
				lineStyle: { color: "#ea7125" },
				markArea: {
					itemStyle: {
						color: "rgba(216, 216, 216, 0.4)"
					},
					data: [
						[
							{
								xAxis: "Mlt1"
							},
							{
								xAxis: "Mlt3"
							}
						]
					]
				}
			}
		]
	};
};
