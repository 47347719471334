<template>
	<BaseToastContent
		title="Your session has timed out."
		color="danger"
	>
		<template v-slot:message>
			<span class="redirect-message ">Please <a class="redirect-login" :href=getReSigninURI>sign in</a> again.</span>
		</template>

	</BaseToastContent>
</template>

<script>
import BaseToastContent from "@/components/BaseToastContent.vue";

export default {
	name: "ToastStaffManagement",

	props: {
		userEmail: {
			type: String,
			default: ""
		},
		redirectName: {
			type: String,
			require: true,
			default: "users"
		}
	},

	components: {
		BaseToastContent
	},

	computed: {
		getReSigninURI() {
			const email = this.userEmail;
			const AZURE_ENDPOINT = process.env.VUE_APP_AZURE_AUTHORIZE_ENDPOINT;
			const CLIENT_ID = process.env.VUE_APP_AZURE_CLIENT_ID;
			const CALLBACK = process.env.VUE_APP_AZURE_LOGIN_CALLBACK;
			return `${AZURE_ENDPOINT}/authorize
			?client_id=${CLIENT_ID}
			&response_type=token
			&redirect_uri=${CALLBACK}
			&scope=user.read.all
			&state=${this.redirectName}
			&response_mode=fragment
			&prompt=none
			&login_hint=${email}`;
		}
	}
};
</script>

<style lang="scss" scoped>
	.redirect-message {
		@include fontBase();

		font-size: rem(16);
		font-weight: $font-weight-bold;
	}

	.redirect-login {
		color: $color-cerulean;
	}
</style>
