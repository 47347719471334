<template>
	<div :class="['control', labelPosition]">
		<div class="label">
			<slot></slot>
		</div>
		<div class="form">
			<BaseDropdown
				:list="list"
				:value="value"
				:controlOptions="controlOptions"
				:dropdownListContainerHeight="dropdownListContainerHeight"
				:searchable="searchable"
				:placeholder="placeholder"
				@input="handleRoleSelected"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseDropdownLabel",

	props: {
		list: {
			type: Array,
			default: () => ([])
		},
		controlOptions: {
			type: Array,
			default: () => ([])
		},
		dropdownListContainerHeight: {
			type: Number,
			default: 165
		},
		searchable: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: ""
		},
		labelPosition: {
			type: String,
			default: "left",
			validator: (value) => [
				"top",
				"left"
			].includes(value)
		}
	},

	data() {
		return {
			value: ""
		};
	},

	methods: {
		handleRoleSelected(value) {
			this.value = value;
			this.$emit("input", value);
		}
	}
};
</script>

<style lang="scss" scoped>
.control {
	display: flex;
	width: 100%;

	&.left {
		flex-direction: row;
	}

	&.top {
		flex-direction: column;
	}

	.label {
		display: flex;
		align-items: center;
		min-width: rem(120);
		color: $color-dark-blue-grey;
		font-size: $font-18;
	}

	.form {
		flex-grow: 1;
	}
}
</style>