<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:iconSize="iconSize"
	>
		<template #icon>
			<PowerIcon
				:kilowatt="kw"
				:value="value"
				:empty="empty"
				:size="newIconSize"
			/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div class="container-detail-info-tag">
					<span>
						MTD Power (kWh)
					</span>
					<span>
						{{ data.MTDPower | currency }}
					</span>
				</div>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import PowerIcon from "@/components/deviceIcons/PowerIcon.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";
import { getElectricityPercentage } from "../../../../selectors/helpers/";

export default {
	name: "CerDeviceWithCompany",

	components: {
		PowerIcon,
		BaseDeviceWithCompany
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	computed: {
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		},
		// set new icon because in PowerIcon component
		// it have supported only 'small', 'medium' and 'large' sizes
		newIconSize() {
			if (this.iconSize === "smallest") {
				return "small";
			} else if (this.iconSize === "small") {
				return "medium";
			}
			return this.this.iconSize;
		},
		empty() {
			return this.data.KW === undefined || this.data.KW === null;
		},
		kw() {
			return this.empty ? 0 : this.data.KW;
		},
		value() {
			return getElectricityPercentage(this.data.KW, this.data.maxRange);
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}
			return this.data.shipId;
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
::v-deep {
	.container-detail-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-right: rem(12);
		margin-left: rem(16);

		.wrapper-icon {
			margin-left: rem(5);
		}
	}
}
/* stylelint-enable */
.container-detail {
	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;

			span {
				line-height: rem(14);
			}
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		&-tag {
			display: flex;
			align-items: center;
			height: rem(14);
			margin-top: rem(4);
			font-size: $font-14;
			color: $color-dark-blue-grey;
			line-height: rem(16);

			span {
				&:first-of-type {
					display: flex;
					width: rem(100);

					sup {
						position: relative;
						top: rem(-3);
					}
				}

				&:last-of-type {
					flex: 1;
					font-weight: $font-weight-bold;
					text-align: right;
				}
			}
		}
	}
}
</style>