<template>
	<BaseLayout pageTitle="Activity log">
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>
			<div class="download-csv-wrapper-desktop">
				<font-awesome-icon  class="csv-icon" :icon="['far', 'file-csv']"/>
				<a class="btn-link export-link" :href="exportURI">
					Export as CSV
				</a>
			</div>
			<BaseTableCustom
				:tableOptions="tableOptions"
				:pagination="pagination"
				:loading="isLoading"
				@onPageChange="handlePageChange"
			>
				<template v-slot:thead>
					<tr>
						<th
							v-for="(value, index) in table.thead"
							:key="index"
						>
							{{ value }}
						</th>
					</tr>
				</template>
				<template v-slot:tbody>
					<tr
						v-for="(item, index) in logs"
						:key="index"
					>
						<td>
							<p class="date">
								<b>
									{{ item.loggedAt }}
								</b>
							</p>
						</td>
						<td class="max-width-156">
							<p class="email">
								{{ item.email }}
							</p>
							<p class="ip">
								{{ item.ip }}
							</p>
						</td>
						<td class="max-width-118">
							<p>
								{{ item.company }}
							</p>
						</td>
						<td>
							<p>
								{{ item.country }}
							</p>
						</td>
						<td class="max-width-130" :ref="`event-${index}`">
							<p>
								<BaseText
									:text="item.eventText"
									:max-line="2"
								/>
							</p>
						</td>
						<td>
							<BaseBadge v-if="item.isSuccess" text="Success" color="success"/>
							<BaseBadge v-else text="Failed" color="light"/>
						</td>
						<td class="max-width-72">
							<p>
								{{ item.osName }}
							</p>
						</td>
						<td class="max-width-72">
							<p>
								{{ item.browser }}
							</p>
						</td>
						<td class="max-width-72">
							<p>
								{{ item.accessBy }}
							</p>
						</td>
					</tr>
				</template>
			</BaseTableCustom>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import SearchFilterForm from "@/components/activitylog/SearchFilterForm.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import BaseText from "@/components/BaseText.vue";
import { cleanParams, cloneDeep, allNull, getDate, pipe } from "../selectors/helpers";
import { getEventText } from "../selectors/helpers/log";

export default {
	name: "activitylog",

	components: {
		SearchFilterForm,
		BaseTableCustom,
		BaseBadge,
		BaseText
	},

	data() {
		return {
			query: {
				page: this.$route.query.page || null,
				q: this.$route.query.q || null,
				date: this.$route.query.start && this.$route.query.end ? {
					start: getDate(this.$route.query.start),
					end: getDate(this.$route.query.end)
				} : null,
				userType: this.$route.query.userType || null
			},
			tableOptions: [
				"is-striped is-fullwidth"
			]
		};
	},

	computed: {
		...mapState({
			response: (state) => {
				return state.activityLog;
			}
		}),
		table() {
			return {
				thead: [
					"Date & Time",
					"Email",
					"Company",
					"Country",
					"Event",
					"Status",
					"OS",
					"Browser",
					"Access By"
				]
			};
		},
		logs() {
			return this.response.data.map((item) => {
				return {
					...item,
					eventText: getEventText(item)
				};
			});
		},
		pagination() {
			return this.response.pagination;
		},
		isLoading() {
			return this.response.isLoading;
		},
		params() {
			const initVal = {
				...this.query,
				...(
					this.query.date ? {
						start: dayjs(this.query.date.start).format("YYYY-MM-DD"),
						end: dayjs(this.query.date.end).format("YYYY-MM-DD")
					} : null
				),
				date: null
			};
			return pipe(cloneDeep, cleanParams)(initVal);
		},
		exportURI() {
			const requestURI = new URL(`${process.env.VUE_APP_BASE_API_URL}/logs/export`);
			if (this.params.q) {
				requestURI.searchParams.append("q", this.params.q);
			}
			if (this.params.start) {
				requestURI.searchParams.append("start", this.params.start);
			}
			if (this.params.end) {
				requestURI.searchParams.append("end", this.params.end);
			}
			if (this.params.userType) {
				requestURI.searchParams.append("userType", this.params.userType);
			}
			return requestURI;
		}
	},

	created() {
		this.fetchData();
	},

	methods: {
		...mapActions({
			fetchActivityLogs: "activityLog/fetchActivityLogs"
		}),

		async fetchData() {
			this.filterQuery();
			this.$router.push({ query: this.params }).catch(() => {});
			await this.fetchActivityLogs(this.params);
		},
		handleSubmitFilter(form = {}) {
			this.query.q = form.keyword;
			this.query.date = form.date;
			this.query.userType = form.userType;
			this.query.page = 1;
			this.fetchData();
		},
		handlePageChange(pageNum) {
			this.query.page = pageNum;
			this.fetchData();
		},
		// side effect
		filterQuery() {
			if (allNull(this.query.q, this.query.date) && Number(this.query.page) === 1) {
				this.query.page = null;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.download-csv-wrapper {
	&-desktop {
		display: flex;
		align-items: center;
		position: absolute;
		top: rem(44);
		right: rem(30);

		.csv-icon {
			margin-right: rem(8);
			font-size: rem(16);
			color: $color-cerulean;
			margin-top: rem(-8);
		}

		@include tablet {
			top: rem(112);
		}

		@include mobile {
			top: rem(116);
		}
	}
}

th {
	padding: rem(17) rem(16) !important;
}

td {
	word-wrap: break-word;
	padding: rem(16) !important;

	p {
		margin: 0;
		color: $color-dark-blue-grey;
	}

	.date {
		color: $color-dark-blue-grey;
	}

	.ip {
		color: $color-grey-4;
		font-size: $font-14;
	}
}

.max-width-72 {
	max-width: rem(72);
}

.max-width-100 {
	max-width: rem(100);
}

.max-width-130 {
	max-width: rem(130);
}

.max-width-118 {
	max-width: rem(140);
}

.max-width-156 {
	max-width: rem(156);
}
</style>