<template>
	<div :class="['module-list', { active: isActive }]">
		<div class="pin" @click="onClickPin">
			<FontAwesomeIcon v-if="isPinned" class="on" :icon="['fas', 'bookmark']" />
			<FontAwesomeIcon v-else class="off" :icon="['far', 'bookmark']" />
		</div>
		<div class="module-list-header" @click="toggleCompany()">
			<h2 class="module-list-name">{{ data.name }}</h2>
			<div class="module-list-toggle">
				<a v-if="!isMobile">
					{{ isActive ? 'Hide' : 'Show' }} product <FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
				</a>
				<a v-else>
					<FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
				</a>
			</div>
		</div>
		<div class="module-list-body container">
			<div class="row">
				<BaseLoading v-if="isLoading" />
				<template v-else-if="data.listShipTo.length">
					<ModuleListItem
						v-for="(item, index) in data.listShipTo"
						:key="`Company${index}`"
						:item="item"
						:query="query"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import ModuleListItem from "@/components/dashboard/ModuleListItem.vue";
import { updateSoldToPinAPI, updateSoldToUnpinAPI } from "@/services/api/soldTos.api";
import setupDevice from "../../mixins/setupDevice";

export default {
	name: "ModuleList",

	components: {
		BaseLoading,
		ModuleListItem
	},

	mixins: [setupDevice],

	props: {
		data: {
			type: Object,
			default: null
		},

		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			isActive: false,
			isLoading: false,
			isPinned: this.data.isPinned,
			device: "mobile"
		};
	},

	computed: {
	},

	methods: {
		...mapActions(["updateShipToPinStatus"]),
		async toggleCompany() {
			this.isActive = !this.isActive;
		},
		onClickPin() {
			this.isPinned = !this.isPinned;
			if (this.isPinned) {
				this.updateShipToPinStatus({ id: this.data.soldToId, status: true });
				updateSoldToPinAPI(this.data.soldToId);
			} else {
				this.updateShipToPinStatus({ id: this.data.soldToId, status: false });
				updateSoldToUnpinAPI(this.data.soldToId);
			}
		}

	}
};
</script>

<style lang="scss" scoped>
.module-list {
	position: relative;
	margin-bottom: rem(16);
	border-radius: rem(8);
	overflow: hidden;
	line-height: 1.5;
	border: 1px solid $color-silver;
	background-color: $color-grey-1;

	@include mobile {
		border-radius: 0;
		margin-left: rem(-16);
		margin-right: rem(-16);
	}

	.pin {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(46);
		height: rem(68);
		cursor: pointer;

		svg {
			position: relative;
			top: rem(-1);
			width: rem(12);
			color: red;
		}

		.on {
			color: $color-marigold;
		}

		.off {
			color: $color-dark-blue-grey;
		}
	}

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;

		@include mobile {
			margin-right: rem(16);
		}
	}

	// .module-item-label
	&-label {
		font-size: $font-18;
		font-weight: 400;
		color: $color-gunmetal-two;
		margin-right: rem(8);
	}

	// .module-item-code, .module-item-key
	&-code,
	&-key {
		margin-right: rem(64);
		font-weight: 600;
		color: $color-dark-blue-grey;

		@include desktop {
			min-width: rem(130);
			margin-right: rem(10);
		}
	}

	// .module-item-toggle
	&-toggle {
		margin-left: auto;

		@include mobile {
			width: rem(32);
		}

		a {
			width: rem(120);
			display: inline-block;
			color: $color-cerulean;
			font-weight: 600;

			@include desktop {
				width: rem(32);
			}

			svg {
				position: relative;
				top: rem(3);
				margin-left: rem(10);
				color: $color-cerulean;
				font-size: $font-16;
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(20) rem(16) rem(20) rem(36);
		cursor: pointer;
		justify-content: space-between;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-list-body {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}

.not-found-data {
	margin-bottom: rem(10);
	text-align: center;
	font-size: $font-20;
	color: $color-dark-blue-grey;
}
</style>
