<template>
	<div :class="['container-none-graph', size]">
		<div class="wrapper-status">
			<span class="value">
				{{ valueGraph }}
			</span>
			<span class="unit" v-html="unit"></span>
		</div>
	</div>
</template>

<script>
import { parseAddCommaWhenIsNumber } from "../../../selectors/helpers/index";

export default {
	name: "WaterIcon",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},

		unit: {
			type: String,
			default: ""
		},

		size: {
			type: String,
			required: true,
			default: "small",
			validator: (value) => [
				"smallest",
				"small",
				"large"
			].includes(value)
		}
	},

	computed: {
		valueGraph() {
			if (this.value === "N/A" || this.value === "-") {
				return this.value;
			}
			return parseAddCommaWhenIsNumber(this.value);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-none-graph {
	.wrapper-status {
		font-family: $font-family-base;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.value {
		@include fontSub();

		color: $color-dark-blue-grey;
		font-weight: bold;
	}

	.unit {
		color: $color-grey-4;

		::v-deep p {
			color: $color-grey-4;
		}
	}

	&.smallest {
		.value {
			margin-bottom: rem(-8);
			font-size: $font-18;
		}

		.unit {
			font-size: $font-12;
		}
	}

	&.small {
		.value {
			margin-bottom: rem(-8);
			font-size: $font-21;
		}

		.unit {
			font-size: $font-14;
		}
	}

	&.large {
		.value {
			margin-bottom: rem(-16);
			font-size: $font-40;
		}

		.unit {
			font-size: $font-26;
		}
	}
}
</style>
