<template>
	<div class="device-container">
		<router-link :to="deviceLink" :class="['container-detail', iconSize]" :style="{border: haveLiquidColor}">
			<div class="container-detail-icon">
				<div class="wrapper-icon">
					<BaseStandardIcon :data="data.graph" :iconSize="iconSize"/>
				</div>
			</div>
			<div class="container-detail-info">
				<div class="container-detail-info-ship-id">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div v-for="(item,index) in data.rows" :key="index" class="container-detail-info-detail">
					<span v-html="item.title">
					</span>
					<span v-html="item.value">
					</span>
				</div>
			</div>
		</router-link>
		<div class="pin-right" >
			<img src="/assets/icons/icons-default-font-small@2x.png" @click="onClickSync">
			<BaseTagStandardModule
				v-if="data.conditionStatus.status"
				size="small"
				:bgColor="data.conditionStatus.bgColor"
				:borderColor="data.conditionStatus.borderColor"
				:textColor="data.conditionStatus.textColor"
			>
				{{ data.conditionStatus.title }}
			</BaseTagStandardModule>
		</div>
	</div>

</template>

<script>
import { mapState } from "vuex";
import BaseStandardIcon from "@/components/BaseStandardIcon.vue";
import BaseTagStandardModule from "@/components/BaseTagStandardModule.vue";
import { MODULE_DEVICE_STATUSES } from "../../../../enums/modules";

export default {
	name: "StandardDeviceDetail",

	components: {
		BaseStandardIcon,
		BaseTagStandardModule
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		}
	},

	methods: {
		onClickSync() {
			this.$emit("onClickSync", this.data);
		}
	},

	computed: {
		...mapState("sidebar", {
			isSidebarOpen: "isSidebarOpen"
		}),

		haveLiquidColor() {
			return this.data?.graph?.liquid?.deviceCardBorderColor ? `1px solid ${this.data?.graph?.liquid?.deviceCardBorderColor}` : "";
		},

		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		},

		tagClass() {
			const status = this.data.deliveryStatus;
			return status
				? MODULE_DEVICE_STATUSES[status].tagClass
				: null;
		},

		status() {
			return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].title;
		},

		iconSize() {
			let size = "smallest";
			const screenWidth = window.innerWidth
						|| document.documentElement.clientWidth
						|| document.body.clientWidth;

			if (screenWidth < 1200 || this.isSidebarOpen) {
				size = "small";
			}

			if (size === "smallest") {
				size = "small";
			} else if (size === "small") {
				size = "medium";
			}
			return size;
		}
	}
};
</script>

<style lang="scss" scoped>
.device-container {
	position: relative;

	.pin-right {
		position: absolute;
		top: rem(8);
		right: rem(8);

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		gap: rem(8);
		height: 100%;
		cursor: pointer;

		img {
			width: rem(24);
			height: rem(24);
		}

		.tag {
			margin-right: rem(8);
		}
	}
}

.container-detail {
	@include fontBase();

	position: relative;

	display: flex;
	align-items: flex-start;
	padding-right: rem(12);
	margin-bottom: rem(16);
	height: rem(136);

	border-radius: rem(4);
	border: 1px solid $color-silver;

	&:hover {
		background-color: $color-ice-two;
	}

	&.smallest > &-icon {
		width: rem(64);
		height: rem(64);
	}

	&-icon {
		margin-top: rem(20);
		margin-right: rem(12);
		margin-left: rem(12);
		width: rem(80);
		height: rem(80);

		.wrapper-icon {
			margin-left: rem(4);
		}
	}

	// container-detail-info
	&-info {
		flex: 1;
		margin-top: rem(16);

		// container-detail-info-ship-id
		&-ship-id {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		// container-detail-info-name
		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		// container-detail-info-detail
		&-detail {
			margin-top: rem(4);
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: rem(14);

			font-size: $font-14;
			color: $color-dark-blue-grey;
			line-height: rem(16);
			white-space: nowrap;

			::v-deep span p {
				color: $color-dark-blue-grey;
				font-size: $font-14;
			}
		}
	}
}
</style>