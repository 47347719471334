/**
 * Enum for chart name.
 * @readonly
 * @enum {EnumChartNames}
 */

export const CHART_NAME = Object.freeze({
	CHART_DROSS_GENERATION: "Dross generation",
	CHART_SOLDER_CONSUMPTION: "Solder consumption",
	CHART_NITROGEN_CONSUMPTION: "Nitrogen consumption",
	CHART_NITROGEN_TOTAL_FINISH_GOOD: "Nitrogen : Total Finished Good",
	CHART_DROSS_TOTAL_FINISH_GOOD: "Dross: Total Finished Good",
	CHART_TOTAL_FINISHED_GOOD: "Total Finished Good"
});
