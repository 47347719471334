
export const trasnformSoldToOptions = (data = []) => {
	return data.reduce((acc, { name, key, ...props }) => {
		return [
			...acc,
			{
				name: `${key} - ${name}`,
				key,
				...props
			}
		];
	}, []);
};

export const transformShipTos = (data = []) => data.reduce((acc, { soldToId, name, id, key, code, ...props }) => {
	const newValue = 	{
		id,
		name: `${key} - ${code} - ${name}`,
		soldToId,
		key,
		code,
		...props
	};
	return {
		...acc,
		[soldToId]: acc[soldToId] ? [...acc[soldToId], newValue] : [newValue]
	};
}, {});

export const transformDeivce = (data = []) => data.reduce((acc, { id, productId, shipId, shipToId, app, ...props }) => {
	const newValue = {
		id,
		code: id,
		name: `${productId} - ${shipId}`,
		key: `${productId} - ${shipId}`,
		shipToId,
		app,
		...props
	};
	const keyObject = `${app}-${shipToId}`;
	return {
		...acc,
		[keyObject]: acc[keyObject] ? [...acc[keyObject], newValue] : [newValue]
	};
}, {});

export const transformApplication = (data = []) => data.reduce((acc, { app, shipToId }) => {
	const newValue = app;
	return {
		...acc,
		[shipToId]: acc[shipToId] ? [...acc[shipToId], newValue] : [newValue]
	};
}, {});