import { getZldInfoAPI, getZldVinyThaiInfoAPI } from "@/services/api/Zlds.api";
import {
	ZLD_INFO_REQUEST,
	AUTO_ZLD_INFO_REQUEST,
	ZLD_INFO_SUCCESS,
	AUTO_ZLD_INFO_SUCCESS,
	ZLD_INFO_FAILURE,
	ZLD_VINY_THAI_INFO_REQUEST,
	AUTO_ZLD_VINY_THAI_INFO_REQUEST,
	ZLD_VINY_THAI_INFO_SUCCESS,
	AUTO_ZLD_VINY_THAI_INFO_SUCCESS,
	ZLD_VINY_THAI_INFO_FAILURE
} from "../../types";
import { parseZldInfo } from "../../../services/api/transforms/parseModules";
import { getLayoutTemplate } from "../../../services/api/layoutCustomisation.api";
import { parseZldVinyThaiInfo } from "../../../services/api/transforms/parseModule/parseZldInfo";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	getters: {
		getLayout(state) {
			return state.data.layout;
		}
	},

	mutations: {
		[ZLD_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[AUTO_ZLD_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[ZLD_INFO_SUCCESS](state, { data, status }) {
			state.data = parseZldInfo(data);
			state.isLoading = false;
			state.statusCode = status;
		},
		[AUTO_ZLD_INFO_SUCCESS](state, { data, status }) {
			state.data = parseZldInfo(data);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[ZLD_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[ZLD_VINY_THAI_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[AUTO_ZLD_VINY_THAI_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[ZLD_VINY_THAI_INFO_SUCCESS](state, { info: { data, status }, template }) {
			state.data = parseZldVinyThaiInfo(data, template);
			state.isLoading = false;
			state.statusCode = status;
		},
		[AUTO_ZLD_VINY_THAI_INFO_SUCCESS](state, { info: { data, status }, template }) {
			state.data = parseZldVinyThaiInfo(data, template);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[ZLD_VINY_THAI_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		}
	},

	actions: {
		async getZldInfo({ commit }, id) {
			try {
				commit(ZLD_INFO_REQUEST);
				const response = await getZldInfoAPI(id);
				commit(ZLD_INFO_SUCCESS, response);
			} catch (error) {
				commit(ZLD_INFO_FAILURE, error);
			}
		},

		async autoGetZldInfo({ commit }, id) {
			try {
				commit(AUTO_ZLD_INFO_REQUEST);
				const response = await getZldInfoAPI(id);
				commit(AUTO_ZLD_INFO_SUCCESS, response);
			} catch (error) {
				commit(ZLD_INFO_FAILURE, error);
			}
		},

		async getZldVinyThaiInfo({ commit }, id) {
			try {
				commit(ZLD_VINY_THAI_INFO_REQUEST);

				const response = await getZldVinyThaiInfoAPI(id);
				const template = await getLayoutTemplate({
					app: "ZLD2"
				});

				commit(ZLD_VINY_THAI_INFO_SUCCESS, {
					info: response,
					template: template.data
				});
			} catch (error) {
				commit(ZLD_VINY_THAI_INFO_FAILURE, error);
			}
		},

		async autoGetZldVinyThaiInfo({ commit }, id) {
			try {
				commit(AUTO_ZLD_VINY_THAI_INFO_REQUEST);

				const response = await getZldVinyThaiInfoAPI(id);
				const template = await getLayoutTemplate({
					app: "ZLD2"
				});

				commit(AUTO_ZLD_VINY_THAI_INFO_SUCCESS, {
					info: response,
					template: template.data
				});
			} catch (error) {
				commit(ZLD_VINY_THAI_INFO_FAILURE, error);
			}
		}
	}
};
