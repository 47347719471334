<template>
	<modal class="modal-select-order-type" name="modal-select-order-type" :scrollable="true" width="420" height="auto" @before-close="handleCloseModal"  @before-open="beforeOpen">
		<BaseModalContent>
			<template v-slot:header>
				Create new order
				<span class="close-button" @click="hideModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div class="item">
						<div class="form">
							<BaseDropdown
								:searchable="false"
								:list="orderTypeList"
								:value="orderTypeSelected"
								:dropdownListContainerHeight="100"
								class="dropdown-order-type"
								label="Select order type"
								inline
								@input="handleSelectModule"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					size="large"
					@click="handleSubmit"
				>Create</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

import { ORDER_TYPE } from "../../../enums/eOrdering/order";

export default {
	name: "ModalSelectOrderType",

	components: {
		BaseModalContent
	},

	data() {
		return {
			orderTypeSelected: ORDER_TYPE.NORMAL,
			orderTypeList: []
		};
	},

	methods: {
		hideModal() {
			this.$modal.hide("modal-select-order-type");
		},

		beforeOpen(event) {
			const { orderTypeList } = event.params;
			this.orderTypeList = orderTypeList;
		},

		handleSelectModule(value) {
			this.orderTypeSelected = value;
		},

		handleSubmit() {
			this.$emit("submit", this.orderTypeSelected);
			this.hideModal();
		},

		handleCloseModal() {
			this.orderTypeSelected = ORDER_TYPE.NORMAL;
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-select-order-type {
	::v-deep {
		.v--modal-box {
			overflow: initial !important;
		}
	}


	.container-form {
		&.active {
			margin-bottom: rem(40);
		}

		.item {
			margin-bottom: $spacing-6;
			display: flex;

			.label {
				display: flex;
				align-items: center;
				min-width: rem(120);
			}

			.form {
				flex-grow: 1;
			}
		}

		.item:last-of-type {
			margin-bottom: 0;
		}
	}

	.notify-error {
		margin-bottom: $spacing-6;
	}
}


</style>