<template>
	<div v-if="handleSlot" class="bar-g-container">
		<div class="bar-g-chart">
			<span
				v-for="({ value, color }, index) in slots"
				:key="index"
				:style="`width: ${value}%`"
				:class="['bar-g-slot', color]"
			></span>
		</div>
		<div class="bar-g-indicator" :style="`left: calc(${barGValue}% - 5px)`"></div>
	</div>
</template>

<script>
export default {
	name: "MeterBarG",

	props: {
		value: {
			type: Number,
			default: 0
		},

		slots: {
			type: Array,
			default: () => ([]),
			validator: (slots) => {
				const colors = ["red", "yellow", "green", "blue", "orange", "cool-green"];
				return slots.every(({ color }) => colors.includes(color));
			}
		}
	},

	computed: {
		handleSlot() {
			return this.slots.every(({ value }) => value);
		},
		barGValue() {
			if (this.value < 0) {
				return 0;
			} else if (this.value > 100) {
				return 100;
			}

			return this.value;
		}
	}
};
</script>

<style lang="scss" scoped>
$border-radius: rem(4);

.bar-g {
	// .bar-g-container
	&-container {
		position: relative;
		top: rem(3);
		margin-left: rem(8);
		height: rem(16);
	}

	// .bar-g-chart
	&-chart {
		display: flex;
		width: rem(96);
		height: rem(16);
	}

	// .bar-g-slot
	&-slot {
		&:first-child {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}

		&:last-child {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}

		&.red {
			background-color: $color-red;
		}

		&.yellow {
			background-color: $color-marigold;
		}

		&.green {
			background-color: $color-emerald;
		}

		&.blue {
			background-color: $color-azure;
		}

		&.cool-green {
			background-color: $color-cool-green;
		}

		&.orange {
			background-color: $color-dull-orange;
		}
	}

	&-indicator {
		position: absolute;
		width: rem(11);
		height: rem(11);
		background: url("/assets/images/triangle-indicator.svg") 0 0 no-repeat;
		background-size: rem(11) rem(11);
		left: 0;
		bottom: rem(-5);
	}
}
</style>
