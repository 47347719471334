<template>
	<div :class="['container-main', color]">
		<div class="section icon" v-if="$slots.icon">
			<span class="icon-wrapper">
				<slot name="icon"></slot>
			</span>
		</div>
		<div class="section head" v-if="$slots.head">
			<slot name="head"></slot>
		</div>
		<div class="section body" v-if="$slots.body">
			<slot name="body"></slot>
		</div>
		<div class="section footer" v-if="$slots.footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseCardIcon",

	props: {
		color: {
			type: String,
			default: "green",
			validator: (value) => {
				return [
					"green",
					"green-light",
					"gray",
					"none"
				].includes(value);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container-main {
	display: flex;
	flex-direction: column;

	&.green {
		svg {
			path {
				fill: $color-green-haze;
			}
		}

		.icon-wrapper {
			background-color: $color-pale-green;
		}
	}

	&.gray {
		svg {
			path {
				fill: $color-gunmetal-two;
			}
		}

		.icon-wrapper {
			background-color: $color-light-teal;
		}
	}

	&.green-light {
		.icon-wrapper {
			background-color: $color-pale-green;
		}
	}

	.section {
		display: flex;
		justify-content: center;
		color: $color-dark-blue-grey;
		margin-bottom: $spacing-3;

		&.icon {
			margin-bottom: $spacing-5;

			.icon-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(114);
				height: rem(114);
				border-radius: 50%;
			}
		}

		&.head {
			@include fontSub();

			font-size: $font-48;
		}

		&.footer {
			button {
				background: none;
				border: none;
				font-weight: $font-weight-bold;
				color: $color-cerulean;
			}
		}
	}
}
</style>