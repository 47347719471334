import dayjs from "dayjs";
import { transformLineChart } from "..";
import { NM_UNIT } from "../../unit";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachDay,
	getForEachSixHours,
	getForEachHour,
	getForEachThirtyMinute,
	getForEachTwoHours,
	xAxisLabelWithOutTime,
	getForEachFiveDay
} from "../helper";
import { getConfigDataZoom } from "../helper/zoomConfig";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Recycle water flowrate (${NM_UNIT}/hr)`;

const tooltipRecycleWaterFormat = (name, value) => {
	const format = `
	<div>
		<div class="bar-name meter">
			${name}
		</div>
		<div class="bar-value">
			${value === null ? "-" : value} Nm<sup>3</sup>/hr
		</div>
	</div>`;
	return format;
};

const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipRecycleWaterFormat(date, pointValue.data);
};




const optionLineChartXaxisPerDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachTwoHours
	},
	...getConfigDataZoom(xAxis),
	axisTick: {
		interval: getForEachThirtyMinute
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});


const optionLineChartXaxisForThreeDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachFourHours
	},
	axisTick: {
		interval: getForEachHour
	},
	...getConfigDataZoom(xAxis),

	tooltip: {
		formatter: tooltipFormatter
	},
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});


const optionLineChartXaxisPerWeek = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	axisTick: {
		interval: getForEachSixHours
	},
	...getConfigDataZoom(xAxis),

	tooltip: {
		formatter: tooltipFormatter
	},
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}

});

const optionLineChartXaxisMoreThanTwentyDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachFiveDay
	},

	axisTick: {
		interval: (index, date) => {
			const hour = dayjs(date).get("hour");
			const minute = dayjs(date).get("minute");
			return hour % 24 === 0 && minute === 0;
		}
	},

	...getConfigDataZoom(xAxis),

	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	}
});

export const transformRecycleWaterLineChartConfig = (sortBy = perDay, data = [], option = {}) => {
	const { diffDay } = option;
	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay(data),
		[perThreeDay]: optionLineChartXaxisForThreeDay(data),
		[perWeek]: optionLineChartXaxisPerWeek(data)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionLineChartXaxisMoreThanTwentyDay(data);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}

	const functionTransform = mappingOption[sortBy] || optionLineChartXaxisPerWeek(option, data);
	const transformFunc = transformLineChart(functionTransform);
	return transformFunc(data);
};
