import { getSilicaInRecycleWaterAPI } from "../../../services/api/Zlds.api";
import {
	ZLD_SILICA_IN_RECYCLE_WATER_REQUEST,
	ZLD_SILICA_IN_RECYCLE_WATER_SUCCESS,
	ZLD_SILICA_IN_RECYCLE_WATER_FAILURE
} from "../../types";
import { transformValueChartData } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},
	getters: {
		getSilicaData: (state) => {
			return transformValueChartData(state);
		}
	},

	mutations: {
		[ZLD_SILICA_IN_RECYCLE_WATER_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[ZLD_SILICA_IN_RECYCLE_WATER_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[ZLD_SILICA_IN_RECYCLE_WATER_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchSilicaInRecycleWater({ commit }, { id = "", query = {} }) {
			try {
				commit(ZLD_SILICA_IN_RECYCLE_WATER_REQUEST);
				const { data } = await getSilicaInRecycleWaterAPI(id, query);

				commit(ZLD_SILICA_IN_RECYCLE_WATER_SUCCESS, data);
			} catch (error) {
				commit(ZLD_SILICA_IN_RECYCLE_WATER_FAILURE);
			}
		}
	}
};
