import httpClient from "./httpClient";

const SHIP_TO_END_POINT = "/ship-tos";
const getShipTosAPI = (id) => httpClient.get(`${SHIP_TO_END_POINT}/${id}`);
const getShipTosListAPI = (params) => httpClient.get(`${SHIP_TO_END_POINT}`, { params });
const getShipTosDevicesAPI = (id, params) => httpClient.get(`${SHIP_TO_END_POINT}/${id}/devices`, { params });
const updateShipToPinAPI = (id) => httpClient.put(`${SHIP_TO_END_POINT}/${id}/pin`);
const updateShipToUnpinAPI = (id) => httpClient.put(`${SHIP_TO_END_POINT}/${id}/unpin`);

export {
	getShipTosAPI,
	getShipTosListAPI,
	getShipTosDevicesAPI,
	updateShipToPinAPI,
	updateShipToUnpinAPI
};
