<template>
	<div class="container">
		<BaseTableCustom
			:tableOptions="tableOptions"
			:loading="false"
			:class="['table-order', color]"
		>
			<template v-slot:thead>
				<tr>
					<th
						v-for="(value, index) in thead"
						:key="index"
					>
						{{ value }}
					</th>
				</tr>
			</template>
			<template v-slot:tbody>
				<tr
					v-for="(item, index) in tbody"
					:key="index"
				>
					<td>
						{{ index + 1}}
					</td>
					<td>
						{{ item.shipTo || "-" }}
					</td>
					<td>
						{{ item.product || "-" }}
					</td>
					<td>
						{{ item.tankNo || "-" }}
					</td>
					<td>
						<div :class="['text-quantity', { '-error': checkErrorVolume(item.volume, item.minimumVolume) }]">
							{{ showVolumn(item) || "-" }}
							<FontAwesomeIcon
								v-if="checkErrorVolume(item.volume, item.minimumVolume)"
								class="icon-error"
								:icon="['far', 'exclamation-triangle']"
							/>
						</div>
					</td>
					<td>
						<div :class="['text-delivery', { '-error': item.isUrgent }]">
							{{ showDeliveryDate(item.deliveriesDate) }}
							<FontAwesomeIcon
								v-if="item.isUrgent"
								class="icon-error"
								:icon="['far', 'exclamation-triangle']"
							/>
						</div>
					</td>
					<td>
						{{ item.poNo || "-"}}
					</td>
				</tr>
			</template>
		</BaseTableCustom>
	</div>
</template>
<script>
import dayjs from "dayjs";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import { formatCounter } from "../../../selectors/helpers/formatCounter";
import { DISPLAY_TIME } from "../../../enums/eOrdering/timeType";

export default {
	name: "TableSummary",

	props: {
		thead: {
			type: Array,
			default: () => []
		},
		tbody: {
			type: Array,
			default: () => []
		},
		color: {
			type: String,
			default: "primary",
			validator: (value) => [
				"primary",
				"danger"
			].includes(value)
		}
	},

	data() {
		return {
			tableOptions: [
				"is-striped is-fullwidth"
			]
		};
	},

	components: {
		BaseTableCustom
	},

	methods: {
		checkErrorVolume(val, minimum) {
			return val < minimum;
		},

		showDeliveryDate(dateTime) {
			const time = dayjs(dateTime).format("HH:mm");
			const date = dayjs(dateTime).format("DD/MM/YYYY");
			const displayTime = DISPLAY_TIME[time];

			return `${date} ${displayTime || time}`;
		},

		showVolumn(val) {
			return `${formatCounter(val.volume)} ${val.unit}`;
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-direction: column;
	width: 100%;

	.table-order {
		margin: rem(16) 0 rem(32);

		&.danger {

			/* stylelint-disable */
			&::v-deep thead {
				border-bottom: rem(3) solid $color-red;
			}
			/* stylelint-enable */
		}
	}
}

.text-quantity.-error {
	color: $color-lipstick;

	svg {
		width: rem(14);
		height: rem(14);
	}
}

.text-delivery.-error {
	color: $color-lipstick;

	svg {
		width: rem(14);
		height: rem(14);
	}
}
</style>