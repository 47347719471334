<template>
	<BaseLayout :isLoading="isLoading">
		<BaseBackBtn label="Back to Dashboard" />
		<BaseBreadcrumb :item="breadcrumb" class="has-dot-separator" />
		<ModuleHeader :data="powerInfo.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<div class="row header-info">
				<ul  v-if="!isMobile"  class="nav nav-tabs">
					<template v-for="item in navTabData">
						<li
							v-if="item.permissions"
							:key="item.index"
							class="nav-item"
						>
							<router-link
								:to="{ name: item.routerName }"
								active-class="active"
								class="nav-link"
							>
								{{ item.title }}
							</router-link>
						</li>
					</template>
				</ul>
				<BaseDropdown
					v-else
					:searchable="false"
					:list="navTabDataOptions"
					:value="query.module"
					placeholder="All"
					@input="handleStatusSelected"
					class="modules-mobile"
				/>
			</div>
			<router-view
				@loaded="handleContentLoaded"
			/>
		</div>
	</BaseLayout>
</template>


<script>
import { mapState, mapActions } from "vuex";
import BaseBreadcrumb from "@/components/BaseBreadcrumb.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModuleHeader from "@/components/dashboard/modules/ModuleHeader.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { CONFIG } from "../../enums/config";
import userPermission from "../../mixins/userPermission";
import setupDevice from "../../mixins/setupDevice";

export default {
	name: "Pwr",
	mixins: [userPermission, setupDevice],
	components: {
		BaseBreadcrumb,
		BaseBackBtn,
		ModuleHeader
	},

	data() {
		return {
			PAGE_NAME,
			id: this.$route.params.id,
			isLoading: true,
			query: {
				module: this.$route.name || null
			},
			fetchDevices: () => {}
		};
	},

	computed: {
		...mapState(["powerInfo", "shipTos"]),
		breadcrumb() {
			return {
				type: {
					name: "Energy"
				},
				shiped: {
					name: this.shipTos.data.name
				}
			};
		},
		shipToId() {
			return this.powerInfo.data.shipToId || null;
		},
		navTabData() {
			return [
				{
					title: "Meter information",
					routerName: PAGE_NAME.PWR_INFO,
					permissions: true
				},
				{
					title: "History",
					routerName: PAGE_NAME.PWR_HISTORY,
					permissions: true
				},
				{
					title: "Graph kWh",
					routerName: PAGE_NAME.PWR_KWH,
					permissions: true
				},
				{
					title: "Analysis",
					routerName: PAGE_NAME.PWR_POWERBI,
					permissions: this.permissions.powerBI.read && this.hasPowerBiReport
				}
			];
		},
		hasPowerBiReport() {
			return this.powerInfo.data.reportId && this.powerInfo.data.workspaceId;
		},
		navTabDataOptions() {
			const list = [
				{
					label: "Meter information",
					value: PAGE_NAME.PWR_INFO
				},
				{
					label: "History",
					value: PAGE_NAME.PWR_HISTORY
				},
				{
					label: "Graph kWh",
					value: PAGE_NAME.PWR_KWH
				},
				this.permissions.powerBI.read && this.hasPowerBiReport && {
					label: "Analysis",
					value: PAGE_NAME.PWR_POWERBI
				}
			];
			return list.filter(Boolean);
		}
	},

	async created() {
		await this.getPowerInfo({ id: this.id });

		this.autoRefreshData = setInterval(async () => {
			if (!this.powerInfo.isAutoFecthLoading) {
				await this.autoGetPowerInfo({ id: this.id });
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		if (this.$route.name === PAGE_NAME.PWR_INFO) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: this.powerInfo.statusCode
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);
				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("powerInfo", {
			getPowerInfo: "getPowerInfo",
			clearPowerInfo: "clearPowerInfo",
			autoGetPowerInfo: "autoGetPowerInfo"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		handleContentLoaded() {
			this.fetchDevices();
		},
		handleStatusSelected(value) {
			this.$router.push({ name: value });
		}
	},

	destroyed() {
		this.clearPowerInfo();
	}
};
</script>

<style lang="scss" scoped>
.header-info {
	margin-left: rem(-30);
	margin-right: rem(-30);
	padding: 0 rem(30);
	margin-bottom: rem(32);

	@include mobile {
		border-bottom: rem(1) solid $color-silver;
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;
		padding: 0 rem(16) rem(32);
		margin-bottom: 0;
	}

	@include desktop {
		border-bottom: rem(1) solid $color-silver;
		padding-bottom: rem(32);
	}

	@include desktop-large {
		margin-bottom: 0;
	}
}
</style>
