export const parseGenGasConsumptionChart = (data = []) => {
	if (data.length === 0) {
		return [];
	}
	return data.map((value) => {
		return {
			date: value.date,
			totalBase: value.totalBase,
			totalPremium: value.totalPremium
		};
	});
};