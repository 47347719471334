import { getGrafanaList, getGrafanaDashboardByDeviceIdAPI, postCreateGrafanaByDeviceIdAPI } from "@/services/api/grafanaMangement.api";
import { GRAFANA_MANAGEMENT_REQUEST, GRAFANA_MANAGEMENT_SUCCESS, GRAFANA_MANAGEMENT_FAILURE,
	GRAFANA_MANAGEMENT_SAVE_REQUEST, GRAFANA_MANAGEMENT_SAVE_SUCCESS, GRAFANA_MANAGEMENT_SAVE_FAILURE } from "../../types";
import { parseGrafanaManagement, parseGrafanaDisplay, parseSortGrafanaGraph, getFilterGrafana, getDropdownListGrafana } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: parseGrafanaManagement([]),
		isLoading: false,
		saveGrafanaSuccess: false
	},

	getters: {
		getGrafanaDisplay: (state) => (tab) => {
			return parseGrafanaDisplay(state.data, tab);
		},
		getGrafanaData(state) {
			return parseSortGrafanaGraph(state.data.grafana);
		},
		getFilterGrafanaTab(state) {
			return getFilterGrafana(state.data.grafana);
		},
		getDropdownListGrafanaTab(state) {
			return getDropdownListGrafana(state.data.grafana);
		}
	},

	mutations: {
		[GRAFANA_MANAGEMENT_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
		},
		[GRAFANA_MANAGEMENT_SUCCESS](state, data) {
			state.data = parseGrafanaManagement(data);
			state.isLoading = false;
		},
		[GRAFANA_MANAGEMENT_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		},
		[GRAFANA_MANAGEMENT_SAVE_REQUEST](state) {
			state.isLoading = true;
			state.saveGrafanaSuccess = false;
		},
		[GRAFANA_MANAGEMENT_SAVE_SUCCESS](state, { status }) {
			state.saveGrafanaSuccess = status === 204;
			state.isLoading = false;
		},
		[GRAFANA_MANAGEMENT_SAVE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		}
	},

	actions: {
		async getGrafanaByDeviceId({ commit }, { id, params }) {
			try {
				commit(GRAFANA_MANAGEMENT_REQUEST);
				const { data } = await getGrafanaList(id, params);
				commit(GRAFANA_MANAGEMENT_SUCCESS, data);
			} catch (error) {
				commit(GRAFANA_MANAGEMENT_FAILURE);
			}
		},
		async getGrafanaDashboardByDeviceId({ commit }, id) {
			try {
				commit(GRAFANA_MANAGEMENT_REQUEST);
				const { data } = await getGrafanaDashboardByDeviceIdAPI(id);
				commit(GRAFANA_MANAGEMENT_SUCCESS, data);
			} catch (error) {
				commit(GRAFANA_MANAGEMENT_FAILURE);
			}
		},
		async sendGrafanaCreateByDeviceId({ commit }, params = {}) {
			try {
				commit(GRAFANA_MANAGEMENT_SAVE_REQUEST);
				const data = await postCreateGrafanaByDeviceIdAPI(params);
				commit(GRAFANA_MANAGEMENT_SAVE_SUCCESS, data);
			} catch (error) {
				commit(GRAFANA_MANAGEMENT_SAVE_FAILURE);
			}
		}
	}
};
