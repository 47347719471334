var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"furnace-report"},[_c('FurnaceFilterReport',{staticClass:"custom-filter-report",attrs:{"mode":"range"},on:{"submit":_vm.handleFilter}}),(_vm.isNotSelect)?_c('BaseEmpty',{staticClass:"wrapper-empty",attrs:{"icon":"file-alt","wrapperColor":"very-pale-green","iconColor":"emerald","title":"Please select Report type and date","subTitle":"Report will be displayed once you make the selection"}}):(_vm.isEmpty)?_c('EmptyOrderHistory',{staticClass:"empty"}):[_c('BaseTableCustom',{staticClass:"furnace-report-table",attrs:{"tableOptions":_vm.tableOptions,"pagination":_vm.pagination,"loading":_vm.loading},on:{"onPageChange":_vm.handlePageChange},scopedSlots:_vm._u([{key:"tableTitle",fn:function(){return [_c('p',{staticClass:"company-name"},[_vm._v(_vm._s(_vm.shipToName))]),_c('h2',{staticClass:"table-title"},[_vm._v("Report")])]},proxy:true},{key:"thead",fn:function(){return [_c('tr',_vm._l((_vm.item.thead),function(value,index){return _c('th',{key:index,class:_vm.columnOptions[index].className},[_vm._v(" "+_vm._s(value)+" "),(_vm.isShowSort(value))?_c('button',{staticClass:"btn-sort",on:{"click":_vm.onClickSortChange}},[(_vm.sortBy === 'desc')?_c('FontAwesomeIcon',{staticClass:"icon",attrs:{"icon":['fas', 'caret-down']}}):_vm._e(),(_vm.sortBy === 'asc')?_c('FontAwesomeIcon',{staticClass:"icon",attrs:{"icon":['fas', 'caret-up']}}):_vm._e()],1):_vm._e()])}),0)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.item.tbody),function(ref,index){
var type = ref.type;
var product = ref.product;
var date = ref.date;
var startDate = ref.startDate;
var endDate = ref.endDate;
var pdfKey = ref.pdfKey;
return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(type)+" ")]),_c('td',[_vm._v(" "+_vm._s(product)+" ")]),_c('td',[_vm._v(" "+_vm._s(date)+" ")]),_c('td',[(startDate !== '-')?_c('span',[_vm._v(" "+_vm._s(startDate)+" ")]):_vm._e(),_vm._v(" - "),(endDate !== '-')?_c('span',[_vm._v(" "+_vm._s(endDate)+" ")]):_vm._e()]),_c('td',{staticClass:"align-right"},[(_vm.permissions.dashboard.exportable)?_c('button',{staticClass:"view-button",on:{"click":function($event){return _vm.onDownloadPdf(pdfKey)}}},[_vm._v(" Download Report (PDF) ")]):_vm._e()])])})},proxy:true}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }