<template>
	<BaseLayout>
		<BaseBackBtn class="page-back-btn" :to="PAGE_NAME.BIG_STORE_OUR_SERVICE" label="Back to Our service"/>
		<div class="wrapper-our-services-edit">
			<h1 class="edit-our-services-title">Edit service</h1>
			<BaseCard class="card-our-services-info">
				<template v-slot:header>
					<h4>General information</h4>
				</template>
				<template v-slot:body>
					<div>
						<BaseDropdown
							label="Group *"
							v-model="$v.moduleValue.$model"
							:searchable="false"
							placeholder="Select group"
							:list="modulesOption"
							:is-error="isModuleValueError"
							:error-message="getModuleValueErrorMessage"
							@input="handleSelectModuleDropdown"
							inline
							disabled
						/>
					</div>
					<div>
						<BaseDropdown
							label="Module *"
							v-model="$v.appValue.$model"
							:searchable="false"
							class="our-services-edit-input"
							placeholder="Select module"
							:list="appsOption"
							:is-error="isAppValueError"
							:error-message="getAppValueErrorMessage"
							@input="handleSelectAppDropdown"
							inline
							disabled
						/>
					</div>
					<BaseFormInput
						v-model="$v.title.$model"
						:inputOptions="['small']"
						:is-error="isTitleError"
						:error-message="getTitleErrorMessage"
						class="our-services-edit-input"
						label="Title *"
						placeholder="e.g. Gas Generator"
						inline
					/>
					<BaseFormInput
						v-model="$v.description.$model"
						:inputOptions="['small']"
						:is-error="isDescriptionError"
						:error-message="getDescriptionErrorMessage"
						class="our-services-edit-input"
						label="Description *"
						placeholder="e.g. On-site Gas Generator"
						inline
					/>
					<BaseUploadFile
						label="Upload icon *"
						fileSize="512000"
						:is-invalid-file-type="isInvalidFileTypeIcon"
						:is-invalid-file-size="isInvalidFileSizeIcon"
						:error-message="getUploadFileMessageIcon"
						accept=".png,.svg"
						info-message="Allow only png/svg file and file size must not over 500KB. Recommended resolution is 1040 x 1040px"
						@handleFileChange="handleFileChangeIcon"
					/>
					<div :class="['control in-line',iconClass]">
						<label class="input-label">  </label>
						<img v-if="iconUrl !== ''" :src="iconUrl" height="200">
					</div>
					<BaseFormInput
						v-model="$v.introTitle.$model"
						:inputOptions="['small']"
						:is-error="isIntroTitleError"
						:error-message="getIntroTitleError"
						class="our-services-edit-input"
						label="Introduction Title"
						inline
					/>
					<BaseFormInput
						v-model="$v.introDescription.$model"
						:inputOptions="['small']"
						:is-error="isIntroDescriptionError"
						:error-message="getIntroDescriptionError"
						class="our-services-edit-input"
						label="Introduction Description"
						inline
					/>
					<BaseFormInput
						v-model="$v.vdoLink.$model"
						:inputOptions="['small']"
						:is-error="isUrlError"
						:error-message="getUrlErrorMessage"
						class="our-services-create-input"
						info-message="YouTube video or playlist you want to embed. click share and copy only src='xxxxxx'"
						label="Vdo link"
						placeholder="https://www.youtube.com/embed/xxxxxxxx"
						inline
					/>
					<BaseFormInput
						v-model="$v.demoDeviceId.$model"
						:inputOptions="['small']"
						:is-error="isDemoDeviceIdError"
						:error-message="getDemoDeviceIdError"
						class="our-services-edit-input"
						label="Demo device ID"
						placeholder="e.g. GY00122"
						inline
					/>
					<BaseFormInput
						v-model="$v.priorityNumber.$model"
						:inputOptions="['small']"
						:is-error="isPriorityNumberError"
						:error-message="getPriorityNumberError"
						info-message="Item will be displayed ordered by priority number."
						class="our-services-edit-input"
						label="Priority number *"
						placeholder="e.g. 1, 2, 3"
						inline
					/>
					<div class="control in-line margin-switch">
						<label class="input-label"> Status </label>
						<BaseSwitch
							class="switch-status"
							:checked="statusActive"
							@input="handleChangeStatus"
						/>
						<div>{{statusActive ? "Active":"Inactive"}}</div>
					</div>
				</template>
			</BaseCard>
			<BaseCard class="card-our-services-info">
				<template v-slot:header>
					<h4>Highlight service</h4>
				</template>
				<template v-slot:body>
					<div class="control in-line margin-switch">
						<label class="input-label"> Status </label>
						<BaseSwitch
							class="switch-status"
							:checked="statusDisplay"
							@input="handleChangeDisplayStatus"
						/>
						<div>{{statusDisplay ? "On":"Off"}}</div>
					</div>
					<BaseFormInput
						v-model="$v.boxTitle.$model"
						:inputOptions="['small']"
						:is-error="isBoxTitleError"
						:error-message="getBoxTitleErrorMessage"
						class="our-services-edit-input"
						label="Box title *"
						placeholder="e.g. Energy Saving"
						inline
						:disabled="!statusDisplay"

					/>
					<BaseUploadFile
						label="Upload image *"
						fileSize="2048000"
						:is-invalid-file-type="isInvalidFileTypeImage"
						:is-invalid-file-size="isInvalidFileSizeImage"
						:error-message="getUploadFileMessageImage"
						:accept="'.png,.jpg,.jpeg'"
						info-message="Allow only jpg/jpeg/png file and file size must not over 2MB. Recommended resolution is 512 x 512px"
						@handleFileChange="handleFileChangeImage"
						:disabled="!statusDisplay"
					/>
					<div :class="['control in-line',imageClass]">
						<label class="input-label">  </label>
						<img v-if="imageUrl !== ''" :src="imageUrl" height="200">
					</div>
					<BaseFormInput
						v-model="$v.priorityHighlightNumber.$model"
						:inputOptions="['small']"
						:is-error="isPriorityHighlightNumberError"
						:error-message="getPriorityHighlightNumberError"
						info-message="Item will be displayed in highlight services ordered by priority number."
						class="our-services-edit-input"
						label="Priority number *"
						placeholder="e.g. 1, 2, 3"
						inline
						:disabled="!statusDisplay"
					/>
				</template>
			</BaseCard>
			<div class="wrapper-confirm-edit-our-services">
				<a
					href="#"
					@click="showModalDeleteOurService()"
					class="delete-this-our-services"
				>
					<button class="view-button btn-link">
						Delete this service
					</button>
				</a>
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleCancle"
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="large"
					type="primary"
					@click="handleSubmit(handleSendPayload)"
				>
					Apply Change
				</BaseButton>
			</div>
		</div>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_OUR_SERVICE"
			title="Delete this service?"
			:message="modalMessageDeleteOurService"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleModalDeleteThisOurService"
		/>
	</BaseLayout>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import { validationMixin } from "vuelidate";
import { required, numeric, maxLength, helpers } from "vuelidate/lib/validators";
import BaseButton from "@/components/BaseButton.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import BaseUploadFile from "@/components/BaseUploadFile.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import OurServicesValidateCustomMixin from "../../selectors/formValidate/bigStoreManagement/OurServicesValidateCustomMixin";
import { getGroupServices } from "../../services/api/bigStoreManagement";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { CONFIG } from "../../enums/config";

export default {
	name: "BigStoreOurServiceEdit",

	mixins: [
		validationMixin,
		OurServicesValidateCustomMixin
	],

	components: {
		BaseCard,
		BaseButton,
		BaseSwitch,
		BaseBackBtn,
		BaseModalConfirmDelete,
		BaseDropdown,
		BaseUploadFile
	},

	validations() {
		if (this.statusDisplay) {
			return {
				moduleValue: {
					required
				},

				appValue: {
					required
				},

				title: {
					required,
					maxLength: maxLength(200)
				},

				description: {
					required,
					maxLength: maxLength(500)
				},

				boxTitle: {
					required,
					maxLength: maxLength(200)
				},

				introTitle: {
					maxLength: maxLength(400)
				},

				introDescription: {
					maxLength: maxLength(1000)
				},

				demoDeviceId: {
					maxLength: maxLength(40)
				},

				priorityNumber: {
					required,
					format(value) {
						return numeric(value);
					},
					minValue(value) {
						return numeric(value) && value > 0;
					}
				},

				priorityHighlightNumber: {
					required,
					format(value) {
						return numeric(value);
					},
					minValue(value) {
						return numeric(value) && value > 0;
					}
				},

				vdoLink: {
					// validate youtube url share with embed
					format: helpers.regex("vdoLink", /^https?:\/\/(?:www\.)?youtube\.com\/embed\/.+$/)
				}
			};
		}

		return {
			moduleValue: {
				required
			},

			appValue: {
				required
			},

			title: {
				required,
				maxLength: maxLength(200)
			},

			description: {
				required,
				maxLength: maxLength(500)
			},

			boxTitle: {
				maxLength: maxLength(200)
			},

			introTitle: {
				maxLength: maxLength(400)
			},

			introDescription: {
				maxLength: maxLength(1000)
			},

			demoDeviceId: {
				maxLength: maxLength(40)
			},

			priorityNumber: {
				required,
				format(value) {
					return numeric(value);
				},
				minValue(value) {
					return numeric(value) && value > 0;
				}
			},

			priorityHighlightNumber: {
			},

			vdoLink: {
				// validate youtube url share with embed
				format: helpers.regex("vdoLink", /^https?:\/\/(?:www\.)?youtube\.com\/embed\/.+$/)
			}
		};
	},

	data() {
		return {
			id: this.$route.params.id,
			moduleValue: null,
			appValue: null,
			title: "",
			description: "",
			iconUrl: "",
			fileIcon: null,
			introTitle: "",
			introDescription: "",
			vdoLink: "",
			demoDeviceId: "",
			priorityNumber: "",
			statusActive: true,

			statusDisplay: true,
			boxTitle: "",
			imageUrl: "",
			fileImage: null,
			priorityHighlightNumber: "",
			PAGE_NAME,

			isInvalidFileTypeIcon: false,
			isInvalidFileSizeIcon: false,
			isInvalidFileTypeImage: false,
			isInvalidFileSizeImage: false,
			progressUpload: 0,
			MODAL_DELETE_OUR_SERVICE: "model-delete-our-services",

			groupServices: { create: [], update: [] }
		};
	},

	async created() {
		await this.getGroupServicesAPI();
		await this.fetchOurServiceById(this.id);
		this.moduleValue = Number(this.ourServiceManagement.data[0]?.deviceId);
		this.appValue = this.ourServiceManagement.data[0]?.app;
		this.title = this.ourServiceManagement.data[0]?.title;
		this.description = this.ourServiceManagement.data[0]?.descriptions;
		this.iconUrl = this.ourServiceManagement.data[0]?.icon;
		this.introTitle = this.ourServiceManagement.data[0]?.introTitle;
		this.introDescription = this.ourServiceManagement.data[0]?.introDescriptions;
		this.vdoLink = this.ourServiceManagement.data[0]?.vdoLink;
		this.demoDeviceId = this.ourServiceManagement.data[0]?.demoShipId;
		this.priorityNumber = this.ourServiceManagement.data[0]?.priority;
		this.statusActive = this.ourServiceManagement.data[0]?.status;
		this.statusDisplay = this.ourServiceManagement.data[0]?.statusHighlight;
		this.boxTitle = this.ourServiceManagement.data[0]?.boxTitle;
		this.imageUrl = this.ourServiceManagement.data[0]?.img || "";
		this.priorityHighlightNumber = this.ourServiceManagement.data[0]?.priorityHighlight !== 0 ? this.ourServiceManagement.data[0]?.priorityHighlight : null;
		this.priorityNumber = this.ourServiceManagement.data[0]?.priority;
	},

	methods: {
		...mapActions("ourServiceManagement", {
			fetchOurServiceById: "fetchOurServiceById",
			sendOurServiceUpdate: "sendOurServiceUpdate",
			sendOurServiceDeleteAPI: "sendOurServiceDeleteAPI"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		handleCancle() {
			this.$router.push({
				name: PAGE_NAME.BIG_STORE_OUR_SERVICE
			});
		},

		handleChangeStatus() {
			this.statusActive = !this.statusActive;
		},

		handleChangeDisplayStatus() {
			this.statusDisplay = !this.statusDisplay;
		},

		handleSubmit(callback) {
			// validate app value match with module
			const filterApp = this.groupServices.update.filter((item) => { return Number(item.id) === Number(this.moduleValue); });
			if (!filterApp[0]?.app.includes(this.appValue)) {
				this.appValue = null;
			}

			if (this.fileImage == null) {
				this.isInvalidFileTypeImage = true;
				this.isInvalidFileSizeImage = true;
			}

			if (this.statusDisplay === false || this.imageUrl !== "") { // avoid validate Upload Image when Status Highlight Service is OFF || have Already Link ImageUrl
				this.isInvalidFileTypeImage = false;
				this.isInvalidFileSizeImage = false;
			}

			this.validatePermissionForm();

			if (!this.$v.$invalid && (!this.isInvalidFileTypeImage && !this.isInvalidFileSizeImage) && (!this.isInvalidFileTypeIcon && !this.isInvalidFileSizeIcon)) {
				callback();
			}
		},

		async handleSendPayload() {
			const ourServicePayload = {
				id: this.id,
				module: this.appValue,
				deviceId: this.moduleValue,
				title: this.title,
				descriptions: this.description,
				icon: this.fileIcon,
				introTitle: this.introTitle,
				introDescriptions: this.introDescription,
				vdoLink: this.vdoLink,
				demoShipId: this.demoDeviceId,
				priority: this.priorityNumber,
				visible: this.statusActive === true ? 1 : 0,
				visibleDisplay: this.statusDisplay === true ? 1 : 0,
				boxTitle: this.boxTitle,
				img: this.statusDisplay ? this.fileImage : null,
				priorityHighlight: this.priorityHighlightNumber || 0
			};
			await this.sendOurServiceUpdate(ourServicePayload, (event) => {
				this.progressUpload = Math.round((100 * event.loaded) / event.total);
			});
			if (this.ourServiceManagement.ourServiceUpdateSuccess) {
				this.$router.push({
					name: PAGE_NAME.BIG_STORE_OUR_SERVICE
				});
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Our Service edit successful."
					}
				});
			}
		},

		handleFileChangeImage({ file }) {
			let isInvalidFileType = true;
			let isInvalidFileSize = false;
			if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
				isInvalidFileType = false;
			}
			if (file.size > CONFIG.FILE_SIZE["500KB"]) { // 500KB
				isInvalidFileSize = true;
			}
			this.isInvalidFileTypeImage = isInvalidFileType;
			this.isInvalidFileSizeImage = isInvalidFileSize;
			this.fileImage = file;
			this.imageUrl = URL.createObjectURL(file);
		},

		handleFileChangeIcon({ file }) {
			let isInvalidFileType = true;
			let isInvalidFileSize = false;
			if (file.type === "image/svg+xml" || file.type === "image/png") {
				isInvalidFileType = false;
			}
			if (file.size > CONFIG.FILE_SIZE["2MB"]) { // 2MB
				isInvalidFileSize = true;
			}
			this.isInvalidFileTypeIcon = isInvalidFileType;
			this.isInvalidFileSizeIcon = isInvalidFileSize;
			this.fileIcon = file;
			this.iconUrl = URL.createObjectURL(file);
		},

		handleSelectGroupDropdown(value) {
			this.groupValue = value;
		},

		getModuleOptions(module = []) {
			const options = module.map((option) => {
				return { value: Number(option.id), label: option.module };
			});
			return options;
		},

		getAppOptions(module = []) {
			const options = module.map((option) => {
				return { value: option, label: option };
			});
			return options;
		},

		async getGroupServicesAPI() {
			const groupServices = await getGroupServices();
			this.groupServices = groupServices.data;
		},

		showModalDeleteOurService() {
			this.$modal.show(this.MODAL_DELETE_OUR_SERVICE);
		},

		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_OUR_SERVICE);
		},

		async handleModalDeleteThisOurService() {
			await this.sendOurServiceDeleteAPI(this.id);
			if (this.ourServiceManagement.ourServiceDeleteSuccess) {
				this.$router.push({
					name: PAGE_NAME.BIG_STORE_OUR_SERVICE
				});
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Our Service delete successful."
					}
				});
				this.$modal.hide(this.MODAL_DELETE_OUR_SERVICE);
			}
		},

		handleSelectModuleDropdown(value) {
			this.moduleValue = value;
		},

		handleSelectAppDropdown(value) {
			this.appValue = value;
		}
	},

	computed: {
		...mapState(["ourServiceManagement"]),

		modulesOption() {
			return this.getModuleOptions(this.groupServices.update);
		},

		appsOption() {
			if (this.moduleValue === null) return [];
			const filterApp = this.groupServices.update.filter((item) => { return Number(item.id) === Number(this.moduleValue); });
			return this.getAppOptions(filterApp[0]?.app);
		},

		disableAppDropdown() {
			if (this.moduleValue === null) return true;
			return false;
		},

		getUploadFileMessageImage() {
			if (this.fileImage == null) {
				return "No file chosen.";
			} else if (this.isInvalidFileTypeImage && this.isInvalidFileSizeImage) {
				return "The file is too large. The maximum size limit is 2MB. Invalid file type. Only jpg/jpeg/png are allowed.";
			} else if (this.isInvalidFileTypeImage) {
				return "Invalid file type. Only jpg/jpeg/png are allowed.";
			} else if (this.isInvalidFileSizeImage) {
				return "The file is too large. The maximum size limit is 2MB.";
			}
			return "";
		},

		getUploadFileMessageIcon() {
			if (this.fileIcon == null) {
				return "No file chosen.";
			} else if (this.isInvalidFileTypeIcon && this.isInvalidFileSizeIcon) {
				return "The file is too large. The maximum size limit is 500KB. Invalid file type. Only svg is allowed.";
			} else if (this.isInvalidFileTypeIcon) {
				return "Invalid file type. Only svg is allowed";
			} else if (this.isInvalidFileSizeIcon) {
				return "The file is too large. The maximum size limit is 500KB.";
			}
			return "";
		},

		imageClass() {
			if (this.imageUrl !== "") {
				return "image-detail-24";
			}
			return "image-detail-16";
		},

		iconClass() {
			if (this.iconUrl !== "") {
				return "image-detail-24";
			}
			return "image-detail-16";
		},

		modalMessageDeleteOurService() {
			return `<div>
				<div>Are you sure to delete this service? </div>
			</div>`;
		}
	},

	beforeUnmount() {
		URL.revokeObjectURL(this.imageUrl);
		URL.revokeObjectURL(this.iconUrl);
	}
};
</script>
<style lang="scss" scoped>
.wrapper-our-services-edit {
	padding: rem(24) 0;
}

.switch-status {
	margin-right: rem(16);
}

.edit-our-services-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.our-services-edit-input {
	margin: rem(24) 0;
}

.card-our-services-info {
	margin-top: rem(40);
}

.wrapper-confirm-edit-our-services {
	position: relative;
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.control {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: $font-20;
	clear: both;
	box-sizing: border-box;

	&.in-line {
		flex-direction: row;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}

.image-detail-24 {
	margin-top: rem(24);
}

.image-detail-16 {
	margin-top: rem(16);
}

.icon-detail-40 {
	margin-top: rem(40);
}

.icon-detail-24 {
	margin-top: rem(24);
	margin-bottom: rem(24);
}

.margin-switch {
	margin-top: rem(36);
}

.input-description {
	width: 100%;
	margin: rem(24) 0;

	&.in-line {
		display: flex;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}

.delete-this-our-services {
	position: absolute;
	left: 0;
}
</style>
