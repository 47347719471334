<template>
	<div></div>
</template>

<script>
import { mapActions } from "vuex";
import { PAGE_NAME } from "../enums/pagePermission";

export default {
	name: "LoginCallback",
	methods: {
		...mapActions("user", {
			getStaffSessionAPI: "getStaffSessionAPI"
		})
	},
	async created() {
		const isRequiredLogin = this.$route.hash.substring(1).split("&").find((v) => v.includes("login_required"));

		if (isRequiredLogin) {
			this.$router.push({
				name: PAGE_NAME.LOGIN_PAGE
			});
		}

		const findAccessToken = this.$route.hash.substring(1).split("&").find((v) => v.includes("access_token"));
		const accessToken = findAccessToken.split("=")[1];
		const findState = this.$route.hash.substring(1).split("&").find((v) => v.includes("state"));
		// state was provided from Login.vue component
		const state = JSON.parse(decodeURIComponent(findState.split("=")[1]));
		if (accessToken) {
			try {
				await this.getStaffSessionAPI({ accessToken });
				this.$router.replace({
					name: state.route,
					params: {
						...state.params
					},
					query: {
						...state.query
					}
				});
			} catch (error) {
				const status = error?.response?.status;
				const email = error?.response?.data?.email;

				if (status && status === 401) {
					this.$router.replace({
						name: "permission",
						query: {
							azureSignIn: true,
							email
						}
					});
				}
			}
		}
	}
};
</script>
