import { getPowerCurrentAPI } from "@/services/api/pwr.api";
import { POWER_CURRENT_REQUEST, POWER_CURRENT_SUCCESS, POWER_CURRENT_FAILURE } from "../../types";
import { parsePowerCurrent } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parsePowerCurrent({}),
		loading: true,
		empty: false
	},

	mutations: {
		[POWER_CURRENT_REQUEST](state) {
			state.detail = parsePowerCurrent({});
			state.loading = true;
			state.empty = false;
		},
		[POWER_CURRENT_SUCCESS](state, data) {
			const detail = data;
			state.detail = parsePowerCurrent(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[POWER_CURRENT_FAILURE](state) {
			state.detail = parsePowerCurrent({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getPowerCurrent({ commit }, { id, params }) {
			try {
				commit(POWER_CURRENT_REQUEST);
				const { data } = await getPowerCurrentAPI(id, params);
				commit(POWER_CURRENT_SUCCESS, data);
			} catch (error) {
				commit(POWER_CURRENT_FAILURE, error);
			}
		}
	}
};
