import ShipToStatusList from "../../views/ShipToStatus/List.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

const { SHIPTO_STATUS_LIST } = PAGE_NAME;

export default {
	path: "/shipto-status/list",
	name: SHIPTO_STATUS_LIST,
	component: ShipToStatusList,
	meta: {
		requireAuth: true
	}
};