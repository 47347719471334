<template>
	<modal
		name="modal-maximum-number-of-order"
		:scrollable="true"
		width="500"
		height="auto"
		class="custom-modal"
	>
		<BaseModalContent rounded>
			<template v-slot:header>
				<span class="border-top"></span>
				<span class="title">
					<FontAwesomeIcon :icon="['fas', 'exclamation-circle']" class="icon" />
					Your order has reached the maximum number of orders
				</span>
				<span
					class="close-button"
					@click="onHide"
				>
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container">
					<div class="description">
						<span>
							Maximum number of lot order is {{ MAX_ORDER }} order per create.
						</span>
						<br />
						<span>
							Your order has reached the maximum number.
						</span>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					type="secondary"
					size="medium"
					@click="onHide"
					rounded
				>
					OK
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

import { ORDER } from "../../../enums/eOrdering/order";


export default {
	components: {
		BaseModalContent
	},

	data() {
		return {
			MAX_ORDER: ORDER.MAXIMUM_CREATE_BATCH_DATE
		};
	},

	methods: {
		onHide() {
			this.$modal.hide("modal-maximum-number-of-order");
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-modal {
	&::v-deep .header {
		padding-top: rem(16);
	}

	&::v-deep .body {
		font-size: $font-20;
		color: $color-dark-blue-grey;
	}

	&::v-deep .v--modal-box {
		border-radius: $border-radius-normal;
		overflow: hidden;
	}
}

.container {
	.description {
		font-size: $font-20;
		font-family: $font-family-base;
	}
}

.view-button {
	font-size: $font-20;
	margin-right: rem(44);
}

.border-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: rem(8);
	background: $color-azure;
}

.title {
	display: flex;
	align-items: center;
	font-size: $font-20;

	.icon {
		position: relative;
		top: rem(-2);
		width: rem(22);
		height: rem(22);
		margin-right: rem(10);
		color: $color-azure;
	}
}
</style>