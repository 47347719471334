<template>
	<div>
		<BaseLoading v-if="sorInfo.isLoading" />
		<div
			:class="[
				'row',
				{ 'row-eq-height': sorInfo.data.layout.shipToLocation },
				{ 'hide-content': sorInfo.isLoading }
			]"
		>
			<div :class="['col-12', { 'col-xl-7': sorInfo.data.layout.shipToLocation }]">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ sorInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview
							v-if="sorInfo.data.id"
							:data="sorInfo.data"
						/>
						<BaseNoData
							v-else
							description="BIG portal will start showing the overview right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
			<ModuleLocation
				v-if="sorInfo.data.layout.shipToLocation"
				:info="sorInfo.data"
			/>
		</div>
		<TrendChart
			v-if="sorInfo.data.layout.powerTrend"
			:data="sorTrend.detail.data"
			:updatedAt="sorTrend.detail.updatedAt"
			:loading="sorTrend.loading"
			:empty="sorTrend.empty"
			@filter="handleFilterTrend"
		/>
		<VoltageChart
			v-if="sorInfo.data.layout.voltage"
			:data="sorVoltage.detail.data"
			:updatedAt="sorVoltage.detail.updatedAt"
			:loading="sorVoltage.loading"
			:empty="sorVoltage.empty"
			@filter="handleFilterVoltage"
		/>
		<CurrentChart
			v-if="sorInfo.data.layout.current"
			:data="sorCurrent.detail.data"
			:updatedAt="sorCurrent.detail.updatedAt"
			:loading="sorCurrent.loading"
			:empty="sorCurrent.empty"
			@filter="handleFilterCurrent"
		/>
		<GenerationChart
			v-if="sorInfo.data.layout.generation"
			:data="sorGeneration.detail.data"
			:updatedAt="sorGeneration.detail.updatedAt"
			:loading="sorGeneration.loading"
			:empty="sorGeneration.empty"
			:chartTitle="`Generation`"
			:chartYAxisLabel="`Power Generation (kWh)`"
			@filter="handleFilterGeneration"
		/>
		<GrafanaDisplay
			:deviceId="id"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import TrendChart from "@/components/PwrModule/TrendChart.vue";
import VoltageChart from "@/components/PwrModule/VoltageChart.vue";
import CurrentChart from "@/components/PwrModule/CurrentChart.vue";
import GenerationChart from "@/components/SorModule/GenerationChart.vue";

export default {
	data() {
		return {
			id: this.$route.params.id
		};
	},

	components: {
		GrafanaDisplay,
		BaseCard,
		BaseNoData,
		ModuleOverview,
		ModuleLocation,
		TrendChart,
		VoltageChart,
		CurrentChart,
		GenerationChart
	},

	computed: {
		...mapState(["sorInfo", "sorTrend", "sorVoltage", "sorCurrent", "sorGeneration"])
	},

	async mounted() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("sorTrend", {
			getSorTrend: "getSorTrend"
		}),
		...mapActions("sorVoltage", {
			getSorVoltage: "getSorVoltage"
		}),
		...mapActions("sorCurrent", {
			getSorCurrent: "getSorCurrent"
		}),
		...mapActions("sorGeneration", {
			getSorGeneration: "getSorGeneration"
		}),
		fetchData() {
			return Promise.all([
				this.getSorTrend({ id: this.id }),
				this.getSorVoltage({ id: this.id }),
				this.getSorCurrent({ id: this.id }),
				this.getSorGeneration({ id: this.id })
			]);
		},
		handleFilterTrend(params = {}) {
			this.getSorTrend({ id: this.id, params });
		},
		handleFilterVoltage(params = {}) {
			this.getSorVoltage({ id: this.id, params });
		},
		handleFilterCurrent(params = {}) {
			this.getSorCurrent({ id: this.id, params });
		},
		handleFilterGeneration(params = {}) {
			this.getSorGeneration({ id: this.id, params });
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^="col"]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.gengas-consumption-chart-wrapper {
	/* stylelint-disable */
	&:v-deep {
		.gengas-consumption-section {
			display: flex;
			flex-direction: column;
			width: rem(150);
		}

		.base-section,
		.premium-section {
			@include fontBase();

			display: flex;
			justify-content: space-between;
			font-size: rem(16);
		}
	}
	/* stylelint-enable */
}
</style>
