import generator from "generate-password";
import dayjs from "dayjs";
import Numeral from "numeral";
import { getSpecialCharArray, isNumber } from "./regex";

export const cleanParams = (params) => {
	// Remove null object and type param
	Object.keys(params).forEach((key) => (params[key] == null || key === "module") && delete params[key]);

	return params;
};

export const cleanQuery = (query) => {
	Object.keys(query).forEach((key) => (query[key] == null) && delete query[key]);
	return query;
};

export const hasKeys = (object = {}, properties = []) => {
	return properties.every((key) => object.hasOwnProperty(key));
};

export const cloneDeep = (data) => {
	return JSON.parse(JSON.stringify(data));
};

export const getInvalidSymboolArray = (value, allowedSymbols = "~!@#$%^&*()-+=?<>|[]{}_ :;.,`") => {
	const specialChars = getSpecialCharArray(value);

	return specialChars.filter((char) => {
		return !allowedSymbols.includes(char);
	});
};

export const allNull = (...args) => {
	return args.every((v) => v === null);
};

export const generatePassword = (option = {}) => {
	return generator.generate(option);
};

export const getPasswordValidate = (password = "") => {
	const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d~!@#$%^&*()\-+=?<>|[\]{}_ :;.,`]{8,}$/g;
	return regex.test(password);
};

export const isValidDate = (date) => {
	if (!date) {
		return false;
	}

	return new Date(decodeURIComponent(date)).toString() !== "Invalid Date";
};

export const getDate = (date) => {
	if (!isValidDate(date)) {
		return null;
	}

	return new Date(decodeURIComponent(date));
};

export const dateTimeFormat = (dateTime, format = "D MMM YYYY, HH:mm") => {
	return dateTime
		? dayjs(dateTime).format(format)
		: "-";
};

export const getDateUnitType = (start, end) => {
	const aHour = 60 * 60;
	const aDay = aHour * 24;
	const aWeek = aDay * 7;
	const twoWeek = aWeek * 2;
	const aYear = aDay * 365;
	const diffTime = (Math.abs(new Date(start) - new Date(end)) / 1000);
	let unitType = null;

	// note: this condition base on RESTful API if we want to change it
	// don't fotget to update unit test in: ./unit/selector/helper/index.spec.js
	// describe `getDateNumber`.
	if (diffTime >= aYear) {
		unitType = "y";
	} else if (diffTime >= twoWeek) {
		unitType = "w";
	} else if (diffTime >= aDay) {
		unitType = "d";
	} else {
		unitType = "s";
	}

	return unitType;
};

export const getDateNumber = ({ start, end }) => {
	const unitType = getDateUnitType(start, end);
	const diff = dayjs(end).diff(start, unitType);
	return `${diff}${unitType}`;
};

export const getDateDiff = ({ start, end }) => {
	return dayjs(end).diff(start, "d");
};

export const getDateRange = (dateNumberStr) => {
	// dateNumberStr can be like: `12d, 2w and 1y`
	// for example we provide `dateNumberStr` to be `12d`
	const dateNumberLength = dateNumberStr.length - 1; // length of number is `2`
	const number = dateNumberStr.substring(-1, dateNumberLength); // number is `12`
	let opUnitType = dateNumberStr.charAt(dateNumberLength); // unit type is `d`

	if (opUnitType === "m") {
		opUnitType = "M";
	}

	return {
		start: dayjs().subtract(number, opUnitType).$d,
		end: dayjs().$d
	};
};

export const numberFormat = (value, format = "0,0.00") => {
	return isNumber(value)
		? Numeral(value).format(format)
		: "-";
};

export const convertSecond2Times = (seconds) => {
	return [
		Math.floor(seconds / 3600), // Hours
		Math.floor((seconds % 3600) / 60) // Minutes
	];
};

export const padStart = (value, targetLength, padString) => {
	const valueLength = value.toString().length;
	let pad = "";

	if (valueLength < targetLength) {
		let count = targetLength - valueLength;

		while (count--) {
			pad += padString;
		}
	}

	return `${pad}${value}`;
};

export const getMinMaxNumber = (origin, { min = null, max = null }) => {
	if (origin > max && max !== null) {
		return max;
	} else if (origin < min && min !== null) {
		return min;
	}
	return origin;
};

export const sorterArray = (data, orderList = []) => {
	const foundTemps = [];
	const notfoundTemps = [];
	const results = [];

	data.forEach((cur) => {
		const findIndex = orderList.findIndex((v) => v === cur);
		if (findIndex > -1) {
			foundTemps.push({
				index: findIndex,
				value: cur
			});
		} else {
			notfoundTemps.push(cur);
		}
	});

	foundTemps.sort((a, b) => (a.index - b.index));

	foundTemps.forEach((v) => results.push(v.value));

	notfoundTemps.forEach((v) => results.push(v));

	return results;
};

export const sorterObject = (obj, orderList = []) => {
	const foundTemps = [];
	const notfoundTemps = [];
	const result = {};

	Object.keys(obj).forEach((key) => {
		const findIndex = orderList.findIndex((v) => key === v);
		if (findIndex > -1) {
			foundTemps.push({
				index: findIndex,
				key
			});
		} else {
			notfoundTemps.push(key);
		}
	});

	foundTemps.sort((a, b) => (a.index - b.index));

	foundTemps.forEach((item) => (
		result[item.key] = obj[item.key]
	));

	notfoundTemps.forEach((key) => (
		result[key] = obj[key]
	));

	return result;
};

export const getFriendlyNumber = (number, options = {}) => {
	if (typeof number !== "number") {
		return number;
	}

	const opts = {
		showIntegerType: false,
		locales: "en-US",
		...options
	};

	let integerType = "";

	if (opts.showIntegerType && number > 0) {
		integerType = "+";
	}

	if (number >= 1000000) {
		const n = Math.floor(number / 1000000).toLocaleString(opts.locales) * 1;
		return `${integerType}${n.toFixed(0)}m`;
	} else if (number >= 100000) {
		const n = Math.floor(number / 1000).toLocaleString(opts.locales) * 1;
		return `${integerType}${n.toFixed(0)}k`;
	}

	return `${integerType}${number.toLocaleString(opts.locales)}`;
};

export const getFriendlyDate = (dateInput) => {
	const now = dayjs();
	const compare = dayjs(dateInput).startOf("day");
	const diff = now.diff(compare, "day");
	const dateOutput = dayjs(dateInput).format("D MMM YYYY");
	let friendlyText = "";

	if (diff === 0) {
		friendlyText = " (Today)";
	} else if (diff === 1) {
		friendlyText = " (Yesterday)";
	}

	return `${dateOutput}${friendlyText}`;
};

export const getFriendlyTime = (dateInput, format = "D MMM YYYY") => {
	const now = dayjs();
	const diff = now.diff(dateInput, "second");
	const aMinute = 60;
	const tenMinutes = aMinute * 10;
	let dateOutput;

	if (diff < 60) {
		dateOutput = "Just now";
	} else if (diff <= (tenMinutes + 59)) {
		dateOutput = `${Math.floor(diff / 60)} mins`;
	} else {
		dateOutput = dayjs(dateInput).format(format);
	}

	return dateOutput;
};

export const getElectricityPercentage = (kw, maxRange) => {
	// return 0 if key `KW` or `maxRange` not provided
	if (kw === undefined || maxRange === undefined) return 0;
	// return max as 100 if result is over then max of value
	// refs: https://maqecom.atlassian.net/secure/RapidBoard.jspa?rapidView=226&projectKey=BIG&modal=detail&selectedIssue=BIG-1588
	const result = (kw * 100) / maxRange;
	if (result > 100) return 100;
	return result;
};

/**
 * replace value
 * @param {*} value
 * @param {Array} options
 * @returns {*} new value
 */
export const replaceTo = (value = null, options = []) => {
	return options.reduce((acc, cur) => {
		if (value === cur.from) {
			return cur.to;
		}
		return acc;
	}, value);
};

/**
 * replace value
 * @param {String} filterBy 1d, 3d, 7d, 30d, 4w, 90d
 * @returns {String} 1d, 3d, 7d, 1m, 3m
 */
export const convertFilterByOfPowerChart = (filterBy) => {
	const newValue = replaceTo(filterBy, [
		{ from: "30d", to: "1m" },
		{ from: "4w", to: "1m" },
		{ from: "90d", to: "3m" }
	]);
	return newValue;
};

export const getDateReportFormat = (format = "year") => {
	const calculateDayJS = {
		year: (date) => {
			const isStartOfYear = dayjs(date).get("month") === 0;
			return isStartOfYear ? dayjs(date).format("MMM\nYYYY") : dayjs(date).format("MMM");
		},
		day: (date) => {
			const isFirstDateOfYear = dayjs(date).get("date") === 1;
			const isFirstMonthOfYear = dayjs(date).get("month") === 0;
			return isFirstMonthOfYear && isFirstDateOfYear
				? dayjs(date).format("D MMM\nYYYY")
				: dayjs(date).format("D MMM");
		},
		month: (date) => {
			const isFirstDate = dayjs(date).get("date") === 1;
			const isFirstMonthOfYear = dayjs(date).get("month") === 0;
			if (isFirstMonthOfYear && isFirstDate) {
				return dayjs(date).format("D\nMMM\nYYYY");
			}

			return dayjs(date).format("D\nMMM");
		}
	};
	return calculateDayJS[format];
};

export const getDateTooltipFormat = (format = "year") => {
	const calculateDayJS = {
		year: (date) => {
			return dayjs(date).format("MMM\nYYYY");
		},
		day: (date) => {
			return dayjs(date).format("D MMM\nYYYY");
		},
		month: (date) => {
			return dayjs(date).format("D\nMMM\nYYYY");
		}
	};
	return calculateDayJS[format];
};

export const getDateReportType = (filter) => {
	switch (filter) {
		case "1d":
		case "2w":
		case "1m":
			return "day";
		case "1y":
			return "year";
		default:
			return "day";
	}
};

export const pipe = (...args) => (initValue) => {
	let result = null;
	for (let i = 0; i < args.length; i++) {
		const current = args[i];
		const param = i === 0 ? initValue : result;
		if (typeof current === "function") {
			const fn = current;
			result = fn(param);
		} else {
			result = current;
		}
	}
	return result;
};

export const getUri = (path, params) => {
	const requestURI = new URL(`${process.env.VUE_APP_BASE_API_URL}${path}`);
	return Object.keys(params).reduce((acc, cur) => {
		acc.searchParams.append(cur, params[cur]);
		return acc;
	}, requestURI);
};

export const isEmpty = (value) => {
	return value == null || (Object.keys(value) || value).length === 0;
};


export const groupBy = (array, property) => {
	const groupData = array.reduce((r, a) => {
		r[a[property]] = [...r[a[property]] || [], a];
		return r;
	}, {});
	return groupData;
};

export const parseAddCommaWhenIsNumber = (value) => {
	if (value === null) return value;
	const regex = /\B(?=(\d{3})+(?!\d))/g;
	// Explain Regex Replace Comma
	// /…/g creates a regular expression that matches all the occurrences of a pattern.
	// \B means do not match the beginning of the number string.
	// (?=(…)) does a positive lookahead. The captured match must be followed by what is specified inside the parenthesis, but that part is not captured.
	// (\d{3}) captures three digits.
	// (?!\d) does a negative lookahead. It matches with something not followed by a digit.
	if (isNaN(value)) { // Not a Number use this
		return value;
	}
	const parts = value.toString().split(".");
	parts[0] = parts[0].replace(regex, ",");

	return parts.join(".");
};

export const parseToTypeNumber = (value) => {
	if (typeof value === "number") { // if number just parse Tostring and Replace comma
		return value;
	} else if (typeof value === "string") { // if string Replace comma
		if (!isNaN(Number(value))) {
			return Number(value);
		}
		return value;
	}
	return value;
};