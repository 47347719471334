import dayjs from "dayjs";
import { MILI_QUE_UNIT } from "../../unit";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachDay,
	getForEachSixHours,
	getForEachHour,
	getForEachThirtyMinute,
	getForEachTwoHours,
	getForEachFiveDay
} from "../../lineChart/helper";
import { getConfigDataZoom } from "../../lineChart/helper/zoomConfig";
import { lineChartSymbol, lineChartSymbolEmerald } from "../lineChartUtils";
import { transformLineChartStack } from "../stackLine";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Raw water & Recycle water flow rate (${MILI_QUE_UNIT}/hr)`;

const tooltipFormatter = (data = []) => {
	const format = `
		<div>
			<div class="bar-name meter">
				${dayjs(data[0].name).format("HH.mm, D MMM YYYY")}
			</div>
			<div class="bar-value">
				${data[0].marker} Raw water ${data[0].value === null ? "-" : data[0].value} m<sup>3</sup>/hr
			</div>
			<div class="bar-value">
				${data[1].marker} Recycle water ${data[1].value === null ? "-" : data[1].value} m<sup>3</sup>/hr
			</div>
		</div>`;
	return format;
};


const trasnformDataSeriesStack = (data = []) => {
	const mappingColor = {
		0: "#00a8e1",
		1: "#009a44"
	};

	const mappingName = {
		0: "Raw water",
		1: "Recycle water"
	};

	const mappingLineChartSymbol = {
		0: lineChartSymbol,
		1: lineChartSymbolEmerald
	};
	const series = [];
	for (let i = 0; i < data.length; i++) {
		series.push({
			name: mappingName[i],
			type: "line",
			data: data[i].yAxis,
			symbolSize: 8,
			symbol: mappingLineChartSymbol[i],
			showAllSymbol: false,
			itemStyle: {
				color: mappingColor[i]
			}
		});
	}
	return series;
};

const optionLineChartXaxisPerDay = ({ data = [], dateRange }) => ({
	yAxisText,
	legend: {
		show: true,
		selectedMode: false,
		bottom: 50,
		left: 68
	},
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachTwoHours
	},
	...getConfigDataZoom(dateRange),
	axisTick: {
		interval: getForEachThirtyMinute
	},

	series: trasnformDataSeriesStack(data),
	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 88,
		containLabel: true
	}

});

const optionLineChartXaxisForThreeDay = ({ data = [], dateRange }) => ({
	yAxisText,
	legend: {
		show: true,
		selectedMode: false,
		bottom: 50,
		left: 68
	},
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachFourHours
	},
	axisTick: {
		interval: getForEachHour
	},
	...getConfigDataZoom(dateRange),

	series: trasnformDataSeriesStack(data),
	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 88,
		containLabel: true
	}

});

const optionLineChartXaxisPerWeek = ({ data = [], dateRange }) => ({
	yAxisText,
	legend: {
		show: true,
		selectedMode: false,
		bottom: 50,
		left: 68
	},
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	axisTick: {
		interval: getForEachSixHours
	},
	...getConfigDataZoom(dateRange),

	series: trasnformDataSeriesStack(data),
	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 88,
		containLabel: true
	}

});

const optionTankLineChartMoreThanTwentyDay = ({ data = [], dateRange }) => ({
	yAxisText,
	legend: {
		show: true,
		selectedMode: false,
		bottom: 50,
		left: 68
	},
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachFiveDay
	},

	axisTick: {
		interval: getForEachDay
	},
	...getConfigDataZoom(dateRange),

	series: trasnformDataSeriesStack(data),
	tooltip: {
		formatter: tooltipFormatter
	},

	grid: {
		left: 16,
		right: 16,
		bottom: 88,
		containLabel: true
	}

});

export const transformZldVinyThaiWaterFlowRateConfig = (sortBy = perDay, { data = [], dateRange = [], diffDay = "" }) => {
	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay({ data, dateRange }),
		[perThreeDay]: optionLineChartXaxisForThreeDay({ data, dateRange }),
		[perWeek]: optionLineChartXaxisPerWeek({ data, dateRange })
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionTankLineChartMoreThanTwentyDay({ data, dateRange });
		const transformFunc = transformLineChartStack(functionTransform);
		return transformFunc({ dateRange });
	}

	const functionTransform = mappingOption[sortBy] || optionLineChartXaxisPerWeek({ data, dateRange });

	const transformFunc = transformLineChartStack(functionTransform);
	return transformFunc({ dateRange });
};
