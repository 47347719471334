/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */
export const MODULE_TYPES = Object.freeze({
	TANK: "Tank",
	METER: "Meter",
	ZLD: "ZLD",
	ZLD2: "ZLD2",
	APPLICATION: "Application",
	CER: "CER",
	NITRO_FAS: "NitroFAS",
	O2_AERATION: "O2Aeration",
	GENGAS: "GenGas",
	ENERGY: "Energy",
	PWR: "PWR",
	SOR: "SOR",
	LNG: "LNG",
	BHY: "BHY",
	PROCESS: "Process",
	FURNACE: "Furnace",
	UTILITY: "Utility",
	AIR_COMP: "AIRCOMP"
});

/**
 * Enum for old app types map devices app to type for use route to old device (non-standard module).
 * @readonly
 * @enum {EnumOldAppTypes}
 */
export const OLD_APP_TYPES = Object.freeze({

	AIR_COMP: "AIR",
	TGEE: "TGEE",
	INFENCE: "INFENCE",
	GENGAS: "GENGAS",
	FURNACE: "FUR",
	NITRO_FAS: "N2F",
	O2_AERATION: "O2A"
});

/**
 * Enum for module device statuses.
 * @readonly
 * @enum {EnumModuleDeviceStatuses}
 */
export const MODULE_DEVICE_STATUSES = Object.freeze({
	OFF: { title: "Off", code: "OFF", tagClass: "" },
	ON: { title: "On", code: "ON", tagClass: "green" },
	IN_TRANSIT: { title: "In Transit", code: "IN_TRANSIT", tagClass: "green" },
	NO_DELIVERY: { title: "No Delivery", code: "NO_DELIVERY", tagClass: "" }
});

/**
 * Enum for tank status.
 * @readonly
 * @enum {EnumTankStatus}
 */
export const TANK_STATUS_CLASSES = Object.freeze({
	NORMAL: "status-green",
	REORDER: "status-yellow",
	REFILL: "status-orange",
	EMPTY: "status-red"
});


/**
 * Enum for truck status.
 * @readonly
 * @enum {EnumTruckStatus}
 */
export const TRUCK_STATUS = Object.freeze({
	IN_TRANSIT: "IN_TRANSIT",
	NO_DELIVERY: "NO_DELIVERY"
});

/**
 * Enum for truck status.
 * @readonly
 * @enum {EnumTruckStatus}
 */
export const TRIPINFO_MAP_TYPE = Object.freeze({
	MULTI_DROP: "MULTI_DROP",
	SINGLE_DROP: "SINGLE_DROP",
	NO_DELIVERY: "NO_DELIVERY"
});

/**
 * Enum for order status.
 * @readonly
 * @enum {EnumOrderStatus}
 */
export const ORDER_STATUS = Object.freeze({
	IN_TRANSIT: "IN_TRANSIT",
	NEW: "NEW",
	CONFIRMED: "CONFIRMED",
	DELIVERED: "DELIVERED"
});

/**
 * Enum for unit.
 * @readonly
 * @enum {EnumUnit}
 */
export const UNIT = Object.freeze({
	NM3: "nm3",
	SM3: "sm3",
	KG: "kg",
	LITER: "l"

});
