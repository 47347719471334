<template>
	<div class="bar-g-container">
		<div class="bar-g-chart" :style="{ backgroundImage : linearGradient }">
		</div>
		<div class="bar-g-indicator" :style="`left: calc(${barGValue}% - 5px)`"></div>
	</div>
</template>

<script>
export default {
	name: "BarColor",

	props: {
		data: {
			type: Object,
			default: () => ({})
		}
	},

	computed: {
		barGValue() {
			if (this.data?.pctValue < 0) {
				return 0;
			} else if (this.data?.pctValue > 100) {
				return 100;
			}

			return this.data?.pctValue;
		},
		linearGradient() {
			const colorBar1 = this.formatColor(this.data?.color1BgColor, this.data?.color1PctWidth);
			const colorBar2 = this.formatColor(this.data?.color2BgColor, this.data?.color2PctWidth);
			const colorBar3 = this.formatColor(this.data?.color3BgColor, this.data?.color3PctWidth);
			const colorBar4 = this.formatColor(this.data?.color4BgColor, this.data?.color4PctWidth);
			const colorBar5 = this.formatColor(this.data?.color5BgColor, this.data?.color5PctWidth);
			const ListColor = [colorBar1, colorBar2, colorBar3, colorBar4, colorBar5].filter((value) => value !== "");
			return `linear-gradient(to right,${ListColor.join(", ")})`;
		}
	},

	methods: {
		formatColor(color = "", width = "") {
			if (color !== "" && width !== "") {
				return `${color} ${width}%`;
			}
			return "";
		}
	}
};
</script>

<style lang="scss" scoped>
$border-radius: rem(4);

.bar-g {
	// .bar-g-container
	&-container {
		position: relative;
		top: rem(3);
		margin-left: rem(8);
		height: rem(16);
	}

	// .bar-g-chart
	&-chart {
		display: flex;
		width: rem(96);
		height: rem(16);
		border-radius: $border-radius;
	}

	// .bar-g-slot
	&-slot {
		&:first-child {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}

		&:last-child {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}

		&.red {
			background-color: $color-red;
		}

		&.yellow {
			background-color: $color-marigold;
		}

		&.green {
			background-color: $color-emerald;
		}

		&.blue {
			background-color: $color-azure;
		}

		&.cool-green {
			background-color: $color-cool-green;
		}

		&.orange {
			background-color: $color-dull-orange;
		}
	}

	&-indicator {
		position: absolute;
		width: rem(11);
		height: rem(11);
		background: url("/assets/images/triangle-indicator.svg") 0 0 no-repeat;
		background-size: rem(11) rem(11);
		left: 0;
		bottom: rem(-5);
	}
}
</style>
