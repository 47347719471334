<template>
	<div class="container-user">
		<div class="container-user-avatar">
			<div class="avatar">
				<FontAwesomeIcon :icon="['fas', 'user']" class="icon" />
			</div>
		</div>
		<div class="container-user-name">
			{{ name }}
		</div>
		<div class="container-user-info">
			<span class="container-user-info-number">
				{{ count }}
			</span>
			<span class="container-user-info-unit">
				activities
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseListViewItemUser",

	props: {
		avatar: {
			type: String,
			default: ""
		},
		name: {
			type: String,
			default: ""
		},
		count: {
			type: Number,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
.container-user {
	display: flex;
	align-items: center;
	height: rem(48);
	margin-bottom: rem(16);

	.avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(48);
		height: rem(48);
		margin-right: rem(8);
		background-color: $color-silver;
		border-radius: 50%;

		.icon {
			height: rem(26);
			color: $sidebar-menu-color;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}


	&-name {
		@include ellipsis(1);

		flex: 1;
		padding-right: rem(30);
		font-size: $font-18;
		font-weight: $font-weight-bold;
	}

	&-info {
		max-width: rem(140);
		text-align: right;

		&-number {
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-green-haze;
		}

		&-unit {
			font-size: $font-18;
			font-weight: $font-weight-bold;
			color: $color-grey-4;
		}
	}
}
</style>