<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:iconSize="iconSize"
	>
		<template #icon>
			<NitroFASIcon
				:key="data.shipId"
				:value="data.UPH"
				:size="iconSize"
			/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name }}
					</span>
				</div>
				<div class="container-detail-info-date">
					<span>
						Last signal:
					</span>
					<span>
						{{ data.signalReceivedAt }}
					</span>
				</div>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import NitroFASIcon from "@/components/deviceIcons/NitroFASIcon.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";

export default {
	name: "NitroFasDevice",

	components: {
		NitroFASIcon,
		BaseDeviceWithCompany
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	computed: {
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
::v-deep {
	.container-detail-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-right: rem(12);
		margin-left: rem(16);

		.wrapper-icon {
			margin-top: rem(4);
			margin-left: rem(1);
		}
	}
}
/* stylelint-enable */
.container-detail {
	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		&-date {
			height: rem(14);
			margin-top: rem(8);
			font-size: $font-16;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
			line-height: rem(16);
		}
	}
}
</style>