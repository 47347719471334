import { getSorKwhAPI } from "@/services/api/sor.api";
import { SOR_KWH_REQUEST, SOR_KWH_SUCCESS, SOR_KWH_FAILURE } from "../../types";
import { parseKwh } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parseKwh({}),
		loading: true,
		empty: false
	},

	mutations: {
		[SOR_KWH_REQUEST](state) {
			state.detail = parseKwh({});
			state.loading = true;
			state.empty = false;
		},
		[SOR_KWH_SUCCESS](state, data) {
			const detail = data;
			state.detail = parseKwh(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[SOR_KWH_FAILURE](state) {
			state.detail = parseKwh({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getSorKwh({ commit }, { id, params }) {
			try {
				commit(SOR_KWH_REQUEST);
				const { data } = await getSorKwhAPI(id, params);
				commit(SOR_KWH_SUCCESS, data);
			} catch (error) {
				commit(SOR_KWH_FAILURE, error);
			}
		}
	}
};