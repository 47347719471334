import { getAirCompOverallEnergyAPI } from "@/services/api/airComp.api";

import {
	AIR_COMP_OVERALL_ENERGY_REQUEST,
	AIR_COMP_OVERALL_ENERGY_SUCCESS,
	AIR_COMP_OVERALL_ENERGY_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		empty: true
	},

	mutations: {
		[AIR_COMP_OVERALL_ENERGY_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[AIR_COMP_OVERALL_ENERGY_SUCCESS](state, response) {
			state.data = response.data;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
			state.isEmpty = response.data.length === 0;
		},
		[AIR_COMP_OVERALL_ENERGY_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchAirCompOverallEnergyData({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_OVERALL_ENERGY_REQUEST);

				const { data = [] } = await getAirCompOverallEnergyAPI(id, params);

				commit(AIR_COMP_OVERALL_ENERGY_SUCCESS, data);
			} catch (error) {
				commit(AIR_COMP_OVERALL_ENERGY_FAILURE);
			}
		}
	}
};
