<template>
	<BaseLayout :isLoading="isLoading">
		<template v-if="!isCreate && !isCreated">
			<BaseBackBtn :to="PAGE_NAME.E_ORDERING_LIST" label="Back to order list" />
			<div class="main-order-create">
				<div class="title">
					Create new order
				</div>

				<div class="form-create-order">
					<ModuleCreateOrder
						v-for="(form, index) in forms"
						:ref="activeLastForm(index)"
						:key="index"
						:order="index"
						:item="form"
						:is-delete="hasFormMoreThanOne"
						:forms.sync="forms[index]"
						:form-length="forms.length"
						@onDelete="showModalDeteleOrder(index)"
					/>

					<div class="main-order-add" >
						<template>
							<button
								:disabled="isDisableAddNewOrder"
								v-if="!isFormLengthEqualFive"
								class="add-order"
								@click="handleAddNewOrder"
							>
								<FontAwesomeIcon
									:icon="['far', 'plus-circle']"
								/>
								Add new order
							</button>
							<span  v-if="isNormalOrder">
								(max {{ maximumForm }} orders)
							</span>
						</template>
					</div>
				</div>

				<div class="wrapper-confirm-create">
					<BaseButton
						class="btn-cancel"
						size="large"
						type="primary"
						outline
						@click="showModalDiscard"
					>
						Cancel
					</BaseButton>

					<BaseButton
						class="btn-create"
						size="large"
						type="primary"
						:disabled="isDisableOrderSummary"
						@click="handleCreateSummary"
					>
						Order Summary
					</BaseButton>
				</div>
			</div>
		</template>

		<template v-if="isCreate && !isCreated">
			<div class="main-order-create">
				<div class="title">
					Order Summary
				</div>

				<TableSummary
					class="table-order-list-success"
					:thead="tabelHeader"
					:tbody="tbodySummary" />

				<div class="wrapper-confirm-create">
					<BaseButton
						class="btn-edit"
						size="large"
						type="primary"
						outline
						@click="handleBackToEdit"
					>
						Back to edit
					</BaseButton>

					<BaseButton
						class="btn-create"
						size="large"
						type="primary"
						@click="handleCreateOrder"
					>
						Confirm order
					</BaseButton>
				</div>
			</div>
		</template>

		<template v-if="!isCreate && isCreated">
			<div class="main-order-create">
				<div class="title">
					Order Summary
				</div>

				<div
					v-if="hasTableBodySuccess"
					class="table-order"
				>
					<div class="table-order-title-success">
						Successfully placed orders
						<font-awesome-icon
							:icon="['far', 'check-circle']"
						/>
					</div>
					<div class="table-order-description-success">
						Orders listed below have been successfully placed. You can check order status and details from Order list.
					</div>
					<TableSummary
						class="table-order-list-success"
						:thead="tabelHeader"
						:tbody="tbodySuccess" />
				</div>

				<div
					v-if="hasTableBodyFailed"
					:class="['table-order', { '-has-no-success': !hasTableBodySuccess}]"
				>
					<div class="table-order-title-error">
						Failed orders
						<FontAwesomeIcon :icon="['far', 'exclamation-circle']" class="icon" />
					</div>
					<div class="table-order-description-error">
						Unable to process orders listed below, please cilck reorder place these orders again.
					</div>
					<TableSummary
						class="table-order-list-error"
						color="danger"
						:thead="tabelHeader"
						:tbody="tbodyFailed" />
				</div>

				<div class="wrapper-confirm-create">
					<BaseButton
						class="btn-edit"
						size="large"
						type="primary"
						outline
						@click="handleBackToOrderList"
					>
						Go to Order list
					</BaseButton>

					<BaseButton
						v-if="hasTableBodyFailed"
						clss="btn-re-order"
						size="large"
						type="danger"
						@click="handleConfirmOrder"
					>
						Re-order
					</BaseButton>
				</div>
			</div>
		</template>

		<BaseModalConfirmDelete
			:modal-name="MODAL_DISCARD"
			title="Page discard"
			:message="modalMessageDiscard"
			submitButtonText="Confirm"
			@onCancel="handleModalCancel"
			@onConfirm="handleModalConfirm"
		/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_ORDER"
			title="Delete order"
			:message="modalMessageDeleteOrder"
			submitButtonText="Confirm"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleModalDeleteThisOrder"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import moment from "moment";

import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModuleCreateOrder from "@/components/eOrdering/ModuleCreateOrder.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import TableSummary from "../../components/eOrdering/formGroup/TableSummary.vue";
import { TOAST_TYPES } from "../../enums/toast";
import { ORDER, ORDER_TYPE } from "../../enums/eOrdering/order";

export default {
	name: "EOrderingCreate",

	components: {
		BaseBackBtn,
		ModuleCreateOrder,
		BaseModalConfirmDelete,
		TableSummary
	},

	data() {
		return {
			PAGE_NAME,
			isLoading: false,
			tabelHeader: [
				"Order",
				"Ship-to",
				"Product",
				"Tank No.",
				"Volume",
				"Delivery Date/Time",
				"PO No."
			],
			tbodySummary: [],
			tbodySuccess: [],
			tbodyFailed: [],
			MODAL_DISCARD: "model-dicard",
			MODAL_DELETE_ORDER: "model-delete-order",
			isCreate: false,
			isCreated: false,
			forms: [{
				shipTo: "",
				product: "",
				tankNo: "",
				poNo: "",
				note: "",
				schedule: [
					{
						volume: 0,
						deliveriesDate: null,
						isUrgent: false,
						dates: [],
						dateShortcut: null,
						date: null,
						time: "",
						selectedVolume: null,
						unit: "Kg",
						minimumVolume: 0,
						maximumVolume: 0
					}
				],
				shipToOptions: [],
				productOptions: [],
				tankNoOptions: []
			}],
			maximumForm: ORDER.MAXIMUM_CREATE_FORM
		};
	},

	computed: {
		...mapState(["eOrderingInfo"]),

		...mapState("eOrderingCreate", {
			isMaximumDate: "isMaximumDate"
		}),

		modalMessageDiscard() {
			return `<div>
				<div>Are you sur to leave this page ? </div>
				<div>All  order information won’t be saved.</div>
			</div>`;
		},

		modalMessageDeleteOrder() {
			return `<div>
				<div>Are you sure you want to go delete this order? </div>
				<div>All  order information won’t be saved.</div>
			</div>`;
		},

		checkFailedOrder() {
			return this.eOrderingInfo.created.failed > 0;
		},

		hasTableBodySuccess() {
			return this.tbodySuccess.length > 0;
		},

		hasTableBodyFailed() {
			return this.tbodyFailed.length > 0;
		},

		isFormLengthEqualFive() {
			return this.forms.length === this.maximumForm;
		},

		hasFormMoreThanOne() {
			return this.forms.length > 1;
		},

		isDisableOrderSummary() {
			return this.forms.some((form) => this.checkRequireForm(form) === false);
		},

		isDisableAddNewOrder() {
			return this.checkRequireForm(this.forms[this.forms.length - 1]) === false || this.isMaximumDate;
		},

		isNormalOrder() {
			return this.$route.query.orderType !== ORDER_TYPE.BATCH;
		}
	},

	async created() {
		await this.getEOrderingConfiguration();
	},

	beforeDestroy() {
		this.resetSelectDate();
	},

	methods: {
		...mapActions("eOrderingInfo", {
			postEOrderingCreate: "postEOrderingCreate",
			getEOrderingConfiguration: "getEOrderingConfiguration",
			postEOrderingCreateBatchOrder: "postEOrderingCreateBatchOrder"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		...mapActions("eOrderingCreate", {
			removeOrder: "removeOrder",
			resetSelectDate: "resetSelectDate"
		}),

		showModalDiscard() {
			this.$modal.show(this.MODAL_DISCARD);
		},

		handleModalCancel() {
			this.$modal.hide(this.MODAL_DISCARD);
		},

		handleModalConfirm() {
			this.$router.push({
				name: PAGE_NAME.E_ORDERING_LIST
			});
			this.$modal.hide(this.MODAL_DISCARD);
		},

		showModalDeteleOrder(key) {
			this.$modal.show(this.MODAL_DELETE_ORDER, key);
		},

		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_ORDER);
		},

		handleModalDeleteThisOrder(key) {
			this.removeOrder({
				orderIndex: key
			});
			this.forms.splice(key, 1);
			this.$modal.hide(this.MODAL_DELETE_ORDER);
		},

		async handleCreateSummary() {
			this.tbodySummary = [];

			if (!this.eOrderingInfo.creating) {
				this.forms.forEach((form) => {
					form.schedule.forEach((formSchedule) => {
						if (this.isNormalOrder) {
							this.tbodySummary.push({
								shipTo: this.showShipTo(form),
								product: form.product,
								tankNo: form.tankNo,
								volume: formSchedule.volume,
								deliveriesDate: formSchedule.deliveriesDate,
								poNo: form.poNo,
								note: form.note,
								isUrgent: formSchedule.isUrgent,
								minimumVolume: formSchedule.minimumVolume,
								unit: formSchedule.unit
							});
						} else {
							formSchedule.deliveriesDates.forEach((deliveriesDate) => {
								const hours = moment().diff(moment(dayjs(deliveriesDate).toDate()), "hours");
								const diffHours = (hours * -1);

								this.tbodySummary.push({
									shipTo: this.showShipTo(form),
									product: form.product,
									tankNo: form.tankNo,
									volume: formSchedule.volume,
									deliveriesDate,
									poNo: form.poNo,
									note: form.note,
									isUrgent: diffHours < 24,
									minimumVolume: formSchedule.minimumVolume,
									unit: formSchedule.unit
								});
							});
						}
					});
				});
			}

			this.isCreate = true;
		},

		async handleCreateOrder() {
			try {
				const params = this.forms.map((form) => {
					let deliveries = form.schedule.map((val) => ({
						amountType: val.selectedVolume,
						amount: parseFloat(val.volume),
						deliveryDate: val.deliveriesDate,
						isUrgent: val.isUrgent
					}));

					if (!this.isNormalOrder) {
						deliveries = form.schedule.reduce((previous, current) => {
							const batchDeliveries = current.deliveriesDates.map((deliveryDate) => {
								const hours = moment().diff(moment(dayjs(deliveryDate).toDate()), "hours");
								const diffHours = (hours * -1);
								return {
									amountType: current.selectedVolume,
									amount: parseFloat(current.volume),
									deliveryDate,
									isUrgent: diffHours < 24
								};
							});

							return [...previous, ...batchDeliveries];
						}, []);
					}

					return {
						shipId: form.tankNo,
						deliveries,
						poNumber: form.poNo,
						note: form.note
					};
				});

				if (this.isNormalOrder) {
					await this.postEOrderingCreate(params);
				} else {
					await this.postEOrderingCreateBatchOrder(params);
				}

				if (this.eOrderingInfo.createdError) {
					this.showToast({
						type: TOAST_TYPES.RESPONSE_ERROR,
						props: { title: "Unable to save", message: "E-Ordering unable to save.", delay: 6000 }
					});

					this.createdFailed();
					return false;
				}

				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Your order has been successfully placed",
						delay: 6000
					}
				});

				return this.$router.push({
					name: this.isNormalOrder ? this.$route.meta.backTo.name : PAGE_NAME.E_ORDERING_PENDING_ORDER_LIST
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to save", message: "E-Ordering unable to save.", delay: 6000 }
				});
			}

			return true;
		},

		createdFailed() {
			this.tbodySuccess = [];
			this.tbodyFailed = [];

			const filterSuccess = this.forms.filter((val) => this.eOrderingInfo.created.success.includes(val.tankNo));
			const filterFailed = this.forms.filter((val) => this.eOrderingInfo.created.failed.includes(val.tankNo));

			filterSuccess.forEach((item) => {
				item.schedule.forEach((val) => {
					this.tbodySuccess.push({
						shipTo: this.showShipTo(item),
						product: item.product,
						tankNo: item.tankNo,
						volume: val.volume,
						deliveriesDate: val.deliveriesDate,
						poNo: item.poNo,
						note: item.note,
						isUrgent: val.isUrgent,
						minimumVolumn: val.minimumVolumn
					});
				});
			});

			filterFailed.forEach((item) => {
				item.schedule.forEach((val) => {
					this.tbodyFailed.push({
						shipTo: this.showShipTo(item),
						product: item.product,
						tankNo: item.tankNo,
						volume: val.volume,
						deliveriesDate: val.deliveriesDate,
						poNo: item.poNo,
						note: item.note,
						isUrgent: val.isUrgent,
						minimumVolumn: val.minimumVolumn
					});
				});
			});

			this.isCreated = true;
			this.isCreate = false;
		},

		handleBackToEdit() {
			this.tbodySummary = [];
			this.tbodySuccess = [];
			this.tbodyFailed = [];

			this.isCreate = false;
		},

		handleConfirmOrder() {
			this.showToast({
				type: TOAST_TYPES.PROCESSING,
				props: {
					title: "Processing",
					message: "Please don’t refresh this page while processing.",
					color: "info",
					delay: 6000
				}
			});
			this.handleCreateOrder();
		},

		handleBackToOrderList() {
			return this.$router.push({
				name: this.$route.meta.backTo.name
			});
		},

		handleInputVolume(val) {
			this.forms.volume = val;
		},

		handleSetUrgent(val) {
			this.forms.isUrgent = val;
		},

		async handleAddNewOrder() {
			this.forms.push({
				shipTo: "",
				product: "",
				tankNo: "",
				poNo: "",
				note: "",
				schedule: [
					{
						volume: 0,
						deliveriesDate: null,
						isUrgent: false,
						date: null,
						dates: [],
						dateShortcut: null,
						time: "",
						minimumVolume: 0,
						maximumVolume: 0,
						selectedVolume: null,
						unit: "Kg"
					}
				],
				shipToOptions: [],
				productOptions: [],
				tankNoOptions: []
			});

			this.$nextTick(() => {
				const indexLastForm = `form_${this.forms.length - 1}`;
				const element = this.$refs[indexLastForm][0]?.$el;
				const scrollTopPosition = element?.offsetTop ?? 0;
				window.scrollTo({ top: scrollTopPosition });
			});
		},

		showShipTo(value) {
			const shipTo = value.shipToOptions.find((val) => (
				value.shipTo === val.value
			));

			return shipTo.label;
		},

		checkRequireForm(form) {
			if (!form.shipTo || !form.product || !form.tankNo) {
				return false;
			}

			const checkSchedule = !form.schedule.find((val) => this.checkRequireFormSchedule(val) === false);
			if (!checkSchedule) {
				return false;
			}

			return true;
		},

		checkRequireFormSchedule(form) {
			if (!form.volume || !form.time) {
				return false;
			}
			if (this.isNormalOrder && (!form.deliveriesDate || !form.date)) {
				return false;
			} else if (!this.isNormalOrder && (form.dates.length === 0 || form.deliveriesDates.length === 0)) {
				return false;
			}

			return true;
		},

		activeLastForm(val) {
			return `form_${val}`;
		}
	}
};
</script>
<style lang="scss" scoped>
.main-order {

	// .main-order-create
	&-create {
		display: flex;
		flex-direction: column;

		.title {
			font-family: $font-family-sub;
			font-size: $font-32;
			width: 100%;
			color: $color-dark-blue-grey;
		}

		.form-create-order {
			/* stylelint-disable */
			&::v-deep {
				// FE guild
				// .module-item ~ .module-item
				.module-item {
					margin: 0 0 rem(8);

					&:first-child {
						margin-top: rem(24);
					}
				}
			}
			/* stylelint-enable */
		}

		.wrapper-confirm-create {
			display: flex;
			justify-content: flex-end;
			padding: rem(24) 0;
			border-top: 1px solid $color-silver;

			.btn-cancel,
			.btn-edit {
				margin-right: rem(24);
				border-radius: rem(8);
			}

			.btn-create,
			.btn-re-order,
			.btn-confirm {
				border-radius: rem(8);
			}
		}

		.table-order {
			display: flex;
			flex-direction: column;

			// .table-order-title-success
			&-title-success {
				display: flex;
				align-items: center;
				font-size: $font-24;
				font-weight: bold;
				font-family: $font-family-sub;
				color: $color-green-haze;

				svg {
					width: rem(16);
					height: rem(16);
					margin-left: rem(4);
				}
			}

			// .table-order-title-error
			&-title-error {
				display: flex;
				align-items: center;
				font-size: $font-24;
				font-weight: bold;
				font-family: $font-family-sub;
				margin-top: rem(80);
				color: $color-red;

				&-has-no-success {
					margin-top: 0;
				}

				svg {
					width: rem(16);
					height: rem(16);
					margin-left: rem(4);
				}
			}

			// .table-order-description
			&-description {
				// .table-order-description-success
				&-success {
					font-size: $font-20;
					color: $color-green-haze;
				}

				// .table-order-description-error
				&-error {
					font-size: $font-20;
					color: $color-red;
				}
			}
		}
	}

	// .main-order-add
	&-add {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		color: $color-grey-4;
		line-height: 1;
		padding-bottom: rem(32);

		.add-order {
			font-size: $font-18;
			font-weight: bold;
			color: $color-cerulean;
			line-height: normal;

			background: none;
			border: none;

			&:disabled {
				color: $color-light-teal;
				cursor: not-allowed;
			}

			svg {
				transform: translateY(rem(4));
			}
		}
	}
}
</style>
