import { BIG_STORE_BANNER_CREATE_REQUEST,
	BIG_STORE_BANNER_CREATE_SUCCESS,
	BIG_STORE_BANNER_CREATE_FAILURE,
	BIG_STORE_BANNER_REQUEST,
	BIG_STORE_BANNER_SUCCESS,
	BIG_STORE_BANNER_FAILURE,
	BIG_STORE_BANNER_EDIT_REQUEST,
	BIG_STORE_BANNER_EDIT_SUCCESS,
	BIG_STORE_BANNER_EDIT_FAILURE,
	BIG_STORE_BANNER_UPDATE_REQUEST,
	BIG_STORE_BANNER_UPDATE_SUCCESS,
	BIG_STORE_BANNER_UPDATE_FAILURE,
	BIG_STORE_BANNER_DELETE_REQUEST,
	BIG_STORE_BANNER_DELETE_SUCCESS,
	BIG_STORE_BANNER_DELETE_FAILURE } from "../../../store/types";
import { postBannerCreateAPI, getBannersByIdAPI, getBannersAPI, putBannerUpdateAPI, deleteBannerAPI } from "../../../services/api/bigStoreManagement"; // getBannersAPI
import { parseBannerlist } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: [],
		file: [],
		isLoading: false,
		bannerCreateSuccess: false,
		bannerUpdateSuccess: false,
		bannerDeleteSuccess: false,
		errors: {},
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[BIG_STORE_BANNER_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BIG_STORE_BANNER_SUCCESS](state, { data, pagination }) {
			state.data = parseBannerlist(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[BIG_STORE_BANNER_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[BIG_STORE_BANNER_CREATE_REQUEST](state) {
			state.isLoading = true;
			state.bannerCreateSuccess = false;
		},
		[BIG_STORE_BANNER_CREATE_SUCCESS](state, data) {
			state.data = data;
			state.isLoading = false;
			state.bannerCreateSuccess = true;
		},
		[BIG_STORE_BANNER_CREATE_FAILURE](state, errors) {
			state.errors = errors;
			state.bannerCreateSuccess = false;
		},
		[BIG_STORE_BANNER_UPDATE_REQUEST](state) {
			state.isLoading = true;
			state.bannerUpdateSuccess = false;
		},
		[BIG_STORE_BANNER_UPDATE_SUCCESS](state, { data, status }) {
			state.data = data;
			state.isLoading = false;
			state.bannerUpdateSuccess = status === 204;
		},
		[BIG_STORE_BANNER_UPDATE_FAILURE](state, errors) {
			state.errors = errors;
			state.bannerUpdateSuccess = false;
		},
		[BIG_STORE_BANNER_EDIT_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BIG_STORE_BANNER_EDIT_SUCCESS](state, { data, pagination }) {
			state.data = parseBannerlist(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[BIG_STORE_BANNER_EDIT_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[BIG_STORE_BANNER_DELETE_REQUEST](state) {
			state.isLoading = true;
			state.bannerDeleteSuccess = false;
		},
		[BIG_STORE_BANNER_DELETE_SUCCESS](state, { status }) {
			state.isLoading = false;
			state.bannerDeleteSuccess = status === 200;
		},
		[BIG_STORE_BANNER_DELETE_FAILURE](state, errors) {
			state.errors = errors;
			state.bannerDeleteSuccess = false;
		}

	},

	actions: {
		async sendBannerDeleteAPI({ commit }, id) {
			try {
				commit(BIG_STORE_BANNER_DELETE_REQUEST);
				const data = await deleteBannerAPI(id);
				commit(BIG_STORE_BANNER_DELETE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_BANNER_DELETE_FAILURE, errors);
			}
		},
		async sendBannerUpdateAPI({ commit }, payload) {
			try {
				commit(BIG_STORE_BANNER_UPDATE_REQUEST);
				const data = await putBannerUpdateAPI(payload);
				commit(BIG_STORE_BANNER_UPDATE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_BANNER_UPDATE_FAILURE, errors);
			}
		},
		async sendCreateBanner({ commit }, payload) {
			try {
				commit(BIG_STORE_BANNER_CREATE_REQUEST);
				const { data } = await postBannerCreateAPI(payload);
				commit(BIG_STORE_BANNER_CREATE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_BANNER_CREATE_FAILURE, errors);
			}
		},
		async fetchBannerList({ commit }, params = {}) {
			try {
				commit(BIG_STORE_BANNER_REQUEST);
				const { data } = await getBannersAPI(params);

				commit(BIG_STORE_BANNER_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_BANNER_FAILURE);
			}
		},
		async fetchBannerById({ commit }, id) {
			try {
				commit(BIG_STORE_BANNER_EDIT_REQUEST);
				const { data } = await getBannersByIdAPI({ page: 1, bannerId: id });
				commit(BIG_STORE_BANNER_EDIT_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_BANNER_EDIT_FAILURE);
			}
		}
	}
};
