<template>
	<modal
		:name="modalName"
		:scrollable="true"
		width="500"
		height="auto"
		class="custom-modal"
	>
		<BaseModalContent rounded>
			<template v-slot:header>
				<span class="border-top"></span>
				<span class="title">
					<FontAwesomeIcon :icon="['fas', 'exclamation-circle']" class="icon" />
					Request change password
				</span>
				<span
					class="close-button"
					@click="onHide"
				>
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div v-if="!isSentEmail" class="container">
					<div class="description">
						<span>
							We'll send a link to change the password to your email address.
						</span>
						<br />
						<span>
							<b>To: {{userEmail}}</b>
						</span>
					</div>
				</div>
				<div v-else class="container">
					<div class="description">
						<span>
							Please check your email. We have sent a link to reset the password <br>to your email address.
						</span>
						<br />
						<span>
							<b>To: {{userEmail}}</b>
						</span>
						<br />
						<span>
							If you didn't receive the email please click resend link.
						</span>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<div v-if="!isSentEmail">
					<BaseButton
						class="btn-cancel"
						type="cancel"
						size="medium"
						@click="onHide"
						rounded
					>
						Cancel
					</BaseButton>
					<span class="padding-btw-button">
						<BaseButton
							width="110px"
							type="secondary"
							size="medium"
							@click="handlSendLinkResetPassword"
							rounded
						>
							Confirm
						</BaseButton>
					</span>
				</div>
				<div v-else>
					<BaseButton
						class="btn-cancel"
						type="cancel"
						size="medium"
						@click="handlSendLinkResetPassword"
						rounded
					>
						Resend link
					</BaseButton>
					<span class="padding-btw-button">
						<BaseButton
							width="156px"
							type="secondary"
							size="medium"
							@click="onHide"
							rounded
						>
							Back to dashboard
						</BaseButton>
					</span>
				</div>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";
import { postChangePassword } from "../../services/api/password.api";

export default {
	components: {
		BaseModalContent
	},

	data() {
		return {
			userEmail: this.$cookies.get("loginEmail"),
			isSentEmail: false
		};
	},

	props: {
		modalName: {
			type: String,
			required: true
		}
	},

	methods: {
		onHide() {
			this.$modal.hide(this.modalName);
		},
		async handlSendLinkResetPassword() {
			const res = await postChangePassword(this.userEmail);
			if (res.status === 200) {
				this.isSentEmail = true;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-modal {
	&::v-deep .header {
		padding-top: rem(16);
	}

	&::v-deep .body {
		font-size: $font-20;
		color: $color-dark-blue-grey;
	}

	&::v-deep .v--modal-box {
		border-radius: $border-radius-normal;
		overflow: hidden;
	}
}

.container {
	.description {
		font-size: $font-20;
		font-family: $font-family-base;
	}
}

.view-button {
	font-size: $font-20;
	margin-right: rem(44);
}

.border-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: rem(8);
	background: $color-azure;
}

.title {
	display: flex;
	align-items: center;
	font-size: $font-20;

	.icon {
		position: relative;
		top: rem(-2);
		width: rem(22);
		height: rem(22);
		margin-right: rem(10);
		color: $color-azure;
	}
}

.padding-btw-button {
	margin-left: 10px;
}

.footer {
	div {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}
</style>