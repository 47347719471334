<template>
	<div>
		<BaseLoading v-if="loading" />

		<div v-else ref="table" class="table-container">
			<table
				:class="['table', tableOptions]"
			>
				<thead>
					<tr>
						<th
							v-for="(head, dataIndex) in item.thead"
							:key="head.key"
							:class="columnOptions[dataIndex] && columnOptions[dataIndex].className"
						>
							<span v-html="head"></span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="itemData in item.tbody"
						:key="itemData.key"
					>
						<td
							v-for="(item, dataIndex) in itemData"
							:key="item.key"
							:class="columnOptions[dataIndex] && columnOptions[dataIndex].className"
						>
							{{ item }}
						</td>
					</tr>
				</tbody>
			</table>

			<BasePagination
				v-if="pagination.lastPage > 1"
				:value="pagination.currentPage"
				:totalPage="pagination.lastPage"
				class="module-pagination"
				@onPageChange="$emit('onPageChange', $event)"
			/>
		</div>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import tableStickyMixin from "../mixins/tableSticky";

export default {
	name: "BaseTable",

	mixins: [tableStickyMixin],

	components: {
		BaseLoading
	},

	props: {
		loading: {
			type: Boolean,
			default: true
		},
		item: {
			type: [Object, Array],
			default: null
		},
		tableOptions: {
			type: Array,
			default: () => ([])
		},
		columnOptions: {
			type: Array,
			default: () => ([])
		},
		pagination: {
			type: Object,
			default: () => ({})
		},
		stickyHeader: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.table {
	background-color: $table-background-color;
	color: $table-color;

	td,
	th {
		padding: $table-cell-padding;
		vertical-align: top;
	}

	th {
		border: $table-cell-border;
		border-width: $table-cell-border-width;
		color: $table-cell-heading-color;

		&:not([align]) {
			text-align: left;
		}
	}

	thead {
		background-color: $color-white;

		td,
		th {
			color: $table-head-cell-color;
			border-width: $table-head-cell-border-width;
			font-size: $font-20;
			font-weight: 600;
		}

		.line-height-1 {
			line-height: 1;
		}

		.nowrap {
			white-space: nowrap;
		}
	}

	tbody {
		background-color: $table-body-background-color;

		tr {
			&:last-child {
				td,
				th {
					border-bottom-width: 0;
				}
			}
		}

		.bold {
			font-weight: 600;
		}

		.line-height-1 {
			line-height: 1;
		}

		.nowrap {
			white-space: nowrap;
		}
	}

	tfoot {
		background-color: $table-foot-background-color;

		td,
		th {
			border-width: $table-foot-cell-border-width;
			color: $table-foot-cell-color;
		}
	}

	&.is-selected {
		background-color: $table-row-active-background-color;
		color: $table-row-active-color;

		a,
		strong {
			color: inherit;
		}

		td,
		th {
			border-color: $table-row-active-color;
			color: inherit;
		}
	}

	// Modifiers
	&.is-bordered {
		td,
		th {
			border-width: 1px;
		}

		tr {
			&:last-child {
				td,
				th {
					border-bottom-width: 1px;
				}
			}
		}
	}

	&.is-fullwidth {
		width: 100%;
	}

	&.is-hoverable {
		tbody {
			tr:not(.is-selected) {
				&:hover {
					background-color: $table-row-hover-background-color;
				}
			}
		}

		&.is-striped {
			tbody {
				tr:not(.is-selected) {
					&:hover {
						background-color: $table-row-hover-background-color;

						&:nth-child(even) {
							background-color: $table-striped-row-even-hover-background-color;
						}
					}
				}
			}
		}
	}

	&.is-striped {
		tbody {
			tr:not(.is-selected) {
				&:nth-child(even) {
					background-color: $table-striped-row-even-background-color;
				}
			}
		}
	}

	&.is-responsive {
		overflow-x: auto;
		white-space: nowrap;
	}
}

.table-container {
	overflow: auto;
	overflow-y: hidden;
	max-width: 100%;
}
</style>
