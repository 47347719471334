export const mapUnit = (product, config) => {
	const productUnit = config?.find((val) => (
		product.module === val.module && product.productId === val.productId
	)) ?? null;

	if (!productUnit) {
		return "";
	}

	return productUnit.unit === "TUBE" ? "Tube" : "Kg";
};