<template>
	<div class="group-quantity">
		<div class="group-quantity-form">
			<BaseDropdown
				class="group-quantity-dropdown"
				:list="list"
				:value="selectedValue"
				:disabled="isTubeType || disabled"
				:searchable="false"
				@input="handleDropDownQuantity"
			/>

			<BaseFormInput
				v-if="isValueNotNull"
				v-model="form.volume"
				class="group-quantity-input-number custom-input has-icons-right medium"
				:readonly="isReadonly || isTubeType"
				@keypress="handleCheckCharacter"
				@blur="handleCheckValue"
			/>
			<div
				v-if="isValueNotNull"
				:class="['text-unit', { 'has-waring': isError} ]"
			>
				{{ unit }}
			</div>
			<div
				v-else
				class="group-quantity-input-number">
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "BaseFormQuantity",

	props: {
		fullLoad: {
			type: Number,
			default: 0
		},
		selectedValue: {
			type: String,
			default: null
		},
		defineVolume: {
			type: String,
			default: ""
		},
		isError: {
			type: Boolean,
			default: false
		},
		unit: {
			type: String,
			default: "Kg"
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			form: {
				volume: this.defineVolume
			},
			list: [
				{
					label: "Select quantity",
					value: null
				},
				{
					label: "Full load (เติมเต็ม)",
					value: "F"
				},
				{
					label: "Define (ระบุจำนวน)",
					value: "D"
				}
			]
		};
	},

	computed: {
		isValueNotNull() {
			return this.selectedValue !== null;
		},

		isReadonly() {
			return this.selectedValue === "F";
		},

		isTubeType() {
			return this.unit === "Tube";
		}
	},

	watch: {
		selectedValue: {
			immediate: false,
			handler(val) {
				if (val === "D") {
					this.form.volume = this.unit === "Tube" ? 1 : undefined;
				} else if (val === "F") {
					this.form.volume = this.fullLoad;
				}
				this.$emit("blur", this.form.volume);
			}
		},
		unit: {
			immediate: true,
			handler(val) {
				if (val === "Tube") {
					this.$emit("blur", this.form.volume);
					this.$emit("change", "D");
				}
			}
		}
	},

	methods: {
		handleDropDownQuantity(val) {
			this.$emit("change", val);
		},

		handleCheckValue() {
			if (parseFloat(this.form.volume) > this.fullLoad) {
				this.form.volume = this.fullLoad;
			}

			this.$emit("blur", this.form.volume);
		},

		handleCheckCharacter(event) {
			const regex = /^\d+$/;
			if (!regex.test(event.key)) {
				event.preventDefault();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.group-quantity {
	display: flex;
	flex-direction: column;

	// .group-quantity-dropdown
	&-dropdown {
		width: rem(160);
		align-self: center;

		/* stylelint-disable */
		&::v-deep {
			.dropdown-value {
				border-radius: rem(8);
			}

			.dropdown-bubble-container {
				z-index: $z-index-toast;
			}
		}
		/* stylelint-enable */
	}

	// .group-quantity-form
	&-form {
		display: flex;

		.text-unit {
			position: absolute;
			z-index: 1;
			right: 0;
			margin-right: rem(8);
			transform: translateY(rem(6));
			color: $color-grey-4;
		}
	}

	// .group-quantity-input-number
	.group-quantity-input-number {
		width: rem(120);
		margin-left: rem(8);

		/* stylelint-disable */
		&::v-deep .input {
			padding-top: rem(4);
			padding-bottom: rem(4);
		}
		/* stylelint-enable */

		position: relative;
	}
}
</style>