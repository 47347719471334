var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{attrs:{"pageTitle":"Our service"}},[(_vm.manageAble)?_c('div',{staticClass:"container-action-bar"},[_c('router-link',{attrs:{"to":{ name: _vm.PAGE_NAME.BIG_STORE_OUR_SERVICE_CREATE }}},[_c('BaseButton',{attrs:{"size":"large"}},[_vm._v(" Create new service ")])],1)],1):_vm._e(),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('SearchFilterForm',{attrs:{"statuses":_vm.statuses,"highlightServices":_vm.highlightServices,"query":_vm.query},on:{"submit":_vm.handleSubmitFilter}})],1),(_vm.isEmpty)?_c('BaseNoResult',{attrs:{"title":"No data. Please Add New Service"}}):_c('BaseTableCustom',{attrs:{"tableOptions":_vm.tableOptions,"pagination":_vm.pagination,"loading":_vm.isLoading},on:{"onPageChange":_vm.handlePageChange},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('tr',_vm._l((_vm.table.thead),function(ref,index){
var value = ref.value;
var key = ref.key;
return _c('th',{key:index},[_c('div',{staticClass:"header-content"},[_vm._v(" "+_vm._s(value)+" "),(key)?_c('BaseSort',{attrs:{"sortName":key,"sortBy":_vm.getSortingBykey(key)},on:{"handleSort":_vm.handleSort}}):_vm._e()],1)])}),0)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.table.tbody),function(item,index){return _c('tr',{key:index,class:[
						{
							inactive: item.isActive === false
						}
					]},[_c('td',{staticClass:"max-width-sold-to"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',{staticClass:"max-width-sold-to"},[_vm._v(" "+_vm._s(item.module)+" ")]),_c('td',{staticClass:"max-width-default"},[_vm._v(" "+_vm._s(item.priority)+" ")]),_c('td',[(item.status)?_c('BaseBadge',{attrs:{"text":"Active","size":"small","color":"success-dark"}}):_c('BaseBadge',{attrs:{"text":"Inactive","size":"small","outline":""}})],1),_c('td',[(item.statusHighlight)?_c('BaseBadge',{attrs:{"text":"On","size":"small","color":"success-dark"}}):_c('BaseBadge',{attrs:{"text":"Off","size":"small","outline":""}})],1),_c('td',{staticClass:"max-width-default"},[_vm._v(" "+_vm._s(item.priorityHighlight === 0 ? "" : item.priorityHighlight)+" ")]),_c('td',{staticClass:"align-right action"},[(_vm.manageAble)?_c('router-link',{attrs:{"to":("/big-store-our-service/" + (item.id))}},[_c('button',{staticClass:"view-button btn-link"},[_vm._v(" Edit ")])]):_vm._e(),_c('a',{staticClass:"ml-7",attrs:{"href":"#"},on:{"click":function($event){return _vm.showModalDeleteService(item.id)}}},[_c('button',{staticClass:"view-button btn-link"},[_vm._v(" Delete ")])])],1)])})},proxy:true}])})],1),_c('BaseModalConfirmDelete',{attrs:{"modal-name":_vm.MODAL_DELETE_SERVICE,"title":"Delete this service?","message":_vm.modalMessageDeleteService,"submitButtonText":"Yes, delete"},on:{"onCancel":_vm.handleModalCancelDelete,"onConfirm":_vm.handleModalDeleteThisService}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }