import { onlyUnique } from "../meterHistory";

const mappingObjectHeaderDailyCOnsumption = {
	accumulateBase1: "base1",
	accumulateBase2: "base2",
	accumulateBase3: "base3",
	accumulatePremium1: "premium1",
	accumulatePremium2: "premium2",
	accumulatePremium3: "premium3",
	consumptionBase1: "base1",
	consumptionBase2: "base2",
	consumptionBase3: "base3",
	consumptionPremium1: "premium1",
	consumptionPremium2: "premium2",
	consumptionPremium3: "premium3"
};
const mappingObjectColumn = {
	base1: ["accumulateBase1", "consumptionBase1"],
	base2: ["accumulateBase2", "consumptionBase2"],
	base3: ["accumulateBase3", "consumptionBase3"],
	premium1: ["accumulatePremium1", "consumptionPremium1"],
	premium2: ["accumulatePremium2", "consumptionPremium2"],
	premium3: ["accumulatePremium3", "consumptionPremium3"]
};

export const getHeaderDailyConsumption = (data = []) => {
	const listOfNoneZero = [];
	for (let i = 0; i < data.length; i++) {
		for (let j = 0; j < Object.keys(data[i]).length; j++) {
			const objkey = Object.keys(data[i])[j];
			if (objkey !== "date" && objkey !== "totalConsumption" && data[i][objkey] > 0) {
				listOfNoneZero.push(objkey);
			}
		}
	}
	const result = [];
	for (let i = 0; i < listOfNoneZero.length; i++) {
		result.push(mappingObjectHeaderDailyCOnsumption[listOfNoneZero[i]]);
	}
	return result.filter(onlyUnique);
};

export const getKeyBodyDataDailyConsumption = (header = []) => {
	const result = [];
	for (let i = 0; i < header.length; i++) {
		result.push(mappingObjectColumn[header[i]]);
	}
	return result;
};