<template>
	<div :class="['module-item', { active: isActive }]">
		<div class="module-item-header" @click="toggleDevices(item.id)">
			<h2 v-if="!isMobile" class="module-item-name">
				<LocationDotSolidIcon />
				{{ item.nameEN || item.nameTH }}
			</h2>
			<div v-else class="module-item-header-mobile">
				<h2 class="module-item-name">
					<LocationDotSolidIcon />
					{{ item.nameEN || item.nameTH }}
				</h2>
				<div class="module-item-toggle">
					<a>
						<FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
					</a>
				</div>
			</div>
			<div class="module-item-code"><span class="module-item-label">Location key</span> {{ item.key }}</div>
			<div class="module-item-code"><span class="module-item-label">Ship-to code</span> {{ item.code }}</div>
			<div v-if="!isMobile" class="module-item-toggle">
				<a>
					{{ isActive ? 'Hide' : 'Show' }} Module <FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
				</a>
			</div>
		</div>
		<div class="module-item-border"></div>
		<div class="module-item-body container">
			<div class="row">
				<BaseLoading v-if="isLoading" />
				<div v-else-if="data.length">
					<ModuleListModules v-for="(moduleData, index) in data"  :key="`${moduleData.module}${index}`" :data="moduleData"  @onClickSync="onClickSync"></ModuleListModules>
				</div>
				<template v-else>
					<div class="not-found-data">
						No devices
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import LocationDotSolidIcon from "@/components/icon/LocationDotSolidIcon.vue";
import ModuleListModules from "@/components/dashboard/ModuleListModules.vue";
import { groupBy } from "../../selectors/helpers";
import setupDevice from "../../mixins/setupDevice";

export default {
	name: "ModuleListItem",

	components: {
		ModuleListModules,
		BaseLoading,
		LocationDotSolidIcon
	},

	mixins: [setupDevice],

	props: {
		item: {
			type: Object,
			default: null
		},

		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			isActive: false,
			isLoading: false,
			data: [],
			device: "mobile"
		};
	},

	computed: {
		...mapState(["devices"]),
		dataByModules() {
			return this.data;
		}
	},

	methods: {
		...mapActions({
			getDevices: "devices/getDevices",
			updateDeviceLoadSync: "devices/updateDeviceLoadSync"
		}),

		async toggleDevices(id) {
			this.isActive = !this.isActive;

			// Fetch new devices when active
			if (this.isActive) {
				this.isLoading = true;
				this.data = [];

				const query = this.query;

				// Remove q param
				if (query.q) {
					delete query.q;
				}

				await this.getDevices({
					id,
					query: this.query
				});
				this.groupByModulesData();
				this.isLoading = false;
			}
		},
		groupByModulesData() {
			const groupDataModule = groupBy(this.devices.data, "module");
			this.data = Object.entries(groupDataModule).map((module) => {
				return {
					module: module[0],
					devices: module[1]
				};
			});
		},
		async onClickSync(deviceItem) {
			await this.updateDeviceLoadSync(deviceItem);
			this.groupByModulesData();
		}
	}
};
</script>

<style lang="scss" scoped>
.module-item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	line-height: 1.5;
	overflow: hidden;

	.pin {
		top: 0;
		left: 0;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(46);
		height: rem(68);
		cursor: pointer;

		svg {
			position: relative;
			top: rem(-1);
			width: rem(16);
			color: red;
		}

		.on {
			color: $color-marigold;
		}

		.off {
			color: $color-dark-blue-grey;
		}
	}

	// .module-item-name
	&-name {
		display: flex;
		flex-basis: rem(380);
		margin-right: auto;
		gap: rem(10);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-green-haze;

		svg {
			width: rem(16);
			fill: $color-green-haze;
		}
	}

	// .module-item-label
	&-label {
		font-size: $font-18;
		font-weight: 400;
		color: $color-gunmetal-two;
		margin-right: rem(8);
	}

	// .module-item-code, .module-item-key
	&-code,
	&-key {
		font-weight: 600;
		color: $color-dark-blue-grey;
		margin-right: rem(32);

		@include desktop {
			min-width: rem(130);
			margin-right: rem(10);
		}
	}

	// .module-item-toggle
	&-toggle {
		a {
			display: inline-block;
			width: rem(120);
			color: $color-cerulean;
			font-weight: 600;

			@include desktop {
				width: rem(32);
			}

			@include mobile {
				width: rem(32);
			}

			svg {
				position: relative;
				top: rem(3);
				margin-left: rem(10);
				color: $color-cerulean;
				font-size: $font-16;
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(16);
		cursor: pointer;

		@include desktop {
			flex-direction: column;
		}

		@include mobile {
			flex-direction: column;
		}

		&-mobile {
			display: flex;
			justify-content: space-between;
		}
	}

	// .module-item-body
	&-body {
		display: none;
		width: calc(100% - #{rem(32)});
		padding: rem(16) rem(16) 0 rem(16);
		background-color: $color-white;
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.module-item-border {
			width: calc(100% - #{rem(32)});
			border-bottom: 1px solid $color-silver;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}

.not-found-data {
	margin-bottom: rem(10);
	text-align: center;
	font-size: $font-20;
	color: $color-dark-blue-grey;
}
</style>
