import Numeral from "numeral";
import { transformBarChart } from "..";
import { NM_UNIT } from "../../unit";


const optionSigninBarChart = {
	all: {
		yAxisText: `Consumption (${NM_UNIT})`,
		grid: {
			left: "24px",
			right: "24px",
			bottom: "48px",
			containLabel: true
		},
		legend: {
			show: true,
			data: ["Premium", "Base"],
			icon: "circle",
			bottom: 0,
			left: 24,
			itemWidth: 10,
			itemHeight: 10,
			itemGap: 24,
			fontFamily: "TFSrivichai",
			fontSize: 16,
			fontColor: "#71717",
			fontWeight: "bold"
		},
		series: {
			tooltip: {
				show: true,
				trigger: "axis",
				backgroundColor: "#103332",
				axisPointer: {
					type: "none"
				},
				padding: 8,
				formatter: ([{ data }]) => {
					return `
						<div class="gengas-consumption-section">
							<div class="total-section">
								<div>
									Total
								</div>
								<div>
									${Numeral(data.baseVal + data.premiumVal).format(0, 0)}
								</div>
							</div>
							<div class="premium-section">
								<div>
									Premium
								</div>
								<div>
									${Numeral(data.premiumVal).format(0, 0)}
								</div>
							</div>
							<div class="base-section">
								<div>
									Base
								</div>
								<div>
									${Numeral(data.baseVal).format(0, 0)}
								</div>
							</div>
						</div>
					`;
				}
			}
		}
	}
};


export const tranfomGenGasConsumptionConfig = (data, showData = "all") => {
	return transformBarChart(optionSigninBarChart[showData])(data);
};
