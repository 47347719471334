import { HTML_FLOW_RATE_UNIT, M_UNIT, US_UNIT } from "../../../../selectors/transform/unit";
import { replaceDeviceName } from "../../../../selectors/helpers/replace";
import { dateTimeFormat, numberFormat, getIsNumber } from ".";


export const getOverViewZldVinyThaiInfo = (
	{
		inletWaterFlowRate = true,
		MTDRecycleWater = true,
		ECRecycleWater = true,
		ECRawWater = true,
		MTDRecovery = true,
		ph = true,
		silica = true
	},
	{
		inletWaterFlowRateField,
		MTDRecycleWaterField,
		ECRecycleWaterField,
		ECRawWaterField,
		MTDRecoveryField,
		phField,
		silicaField
	},
	input = {}
) => {
	const defaultFields = {
		inletWaterFlowRateField: inletWaterFlowRateField || "Inlet water flowrate",
		MTDRecycleWaterField: MTDRecycleWaterField || "MTD recycle water",
		ECRecycleWaterField: ECRecycleWaterField || "EC-Recycle water",
		ECRawWaterField: ECRawWaterField || "EC-Raw water",
		MTDRecoveryField: MTDRecoveryField || "MTD % Recovery",
		phField: phField || "pH",
		silicaField: silicaField || "Silica (ppb)"
	};

	const barG = {
		value: input.phPercent,
		slots: [
			{ value: input.barSlot1, color: "red" },
			{ value: input.barSlot2, color: "yellow" },
			{ value: input.barSlot3, color: "green" },
			{ value: input.barSlot4, color: "yellow" },
			{ value: input.barSlot5, color: "red" }
		]
	};

	const silicaBarG = getIsNumber(input.silica) ? {
		value: input.silica,
		slots: [
			{ value: 50, color: "green" },
			{ value: 10, color: "yellow" },
			{ value: 40, color: "red" }
		]
	} : false;

	const overview = [
		...(inletWaterFlowRate ? [{ title: `${defaultFields.inletWaterFlowRateField} (${M_UNIT}/hr)`, value: numberFormat(input.inletWaterFlowRate) }] : []),
		...(MTDRecycleWater ? [{ title: `${defaultFields.MTDRecycleWaterField} (${HTML_FLOW_RATE_UNIT})`, value: numberFormat(input.mtdRecycleWater) }] : []),
		...(ECRecycleWater ? [{ title: `${defaultFields.ECRecycleWaterField} (${US_UNIT}/cm)`, value: numberFormat(input.ecRecycleWater) }] : []),
		...(ECRawWater ? [{ title: `${defaultFields.ECRawWaterField} (${US_UNIT}/cm)`, value: numberFormat(input.ecRawWater) }] : []),
		...(MTDRecovery ? [{ title: `${defaultFields.MTDRecoveryField}`, value: input.MTDRecovery ? `${input.MTDRecovery}%` : "-" }] : []),
		...(ph ? [{ title: `${defaultFields.phField}`, value: input.phAmount, barG }] : []),
		...(silica ? [{ title: `${defaultFields.silicaField}`, value: getIsNumber(input.silica) ? input.silica : "-", barG: silicaBarG }] : [])
	];

	return overview;
};

export function parseZldVinyThaiInfo(input, defaultTemplate) {
	if (!input) {
		return {};
	}

	const overViewTemplate = input.layout?.overview || defaultTemplate.layout.overview;
	const overViewFieldTemplate = input.layout?.overviewField || defaultTemplate.layout.overviewField;
	const layoutTemplate = input.layout || defaultTemplate.layout;
	const overview = getOverViewZldVinyThaiInfo(overViewTemplate, overViewFieldTemplate, input);

	return {
		id: input.id,
		shipId: input.shipId,
		type: input.type,
		flowRate: input.flowRate,
		name: replaceDeviceName(input.name),
		shipToId: input.shipToId,
		phAmount: input.phAmount,
		phPercent: input.phPercent,
		mtdRecycleWater: input.mtdRecycleWater,
		ecRecycleWater: input.ecRecycleWater,
		ecRawWater: input.ecRawWater,
		shipToLatitude: input.shipToLatitude,
		shipToLongitude: input.shipToLongitude,
		updatedAt: dateTimeFormat(input.updatedAt),
		overview,
		layout: {
			...layoutTemplate
		},
		deviceCustomerName: input.deviceCustomerName,
		reportId: input.reportId,
		workspaceId: input.workspaceId
	};
}