<template>
	<nav class="breadcrumb" aria-label="breadcrumbs">
		<ul>
			<li
				v-for="breadcrumb in item"
				:key="breadcrumb.key"
			>
				<span class="breadcrumb-text">{{  breadcrumb.name }}</span>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: "BaseBreadcrumb",

	props: {
		item: {
			type: [Object, String],
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
.breadcrumb {
	display: block;
	font-size: $font-20;
	white-space: nowrap;

	ul,
	ol {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	li {
		align-items: center;
		display: flex;

		&:first-child a {
			padding-left: 0;
		}
	}

	&.has-dot-separator {
		li + li::before {
			content: "";
			width: rem(4);
			height: rem(4);
			background-color: $breadcrump-dot-color;
			border-radius: 50%;
		}
	}

	&-text {
		align-items: center;
		color: $breadcrump-color;
		display: flex;
		justify-content: center;
		padding: 0 rem(8);
	}
}
</style>
