<template>
	<div :class="['invoice-card-wrapper',{ active: isActive}]"
		@click="toggleCollapse"
	>
		<div
			class="invoice-card-header"
			data-allow-collapse="true"
		>
			<div class="arrow" >
				<font-awesome-icon
					data-allow-collapse="true"
					class="arrow-icon"
					:icon="['far', 'chevron-circle-down']"
				/>
			</div>
			<div
				class="thead-item head"
				data-allow-collapse="true"
			>
				{{ shipToName }}
			</div>
			<div class="thead-item"
				data-allow-collapse="true"
			>
				{{ invoiceNumber }}
				<div class="subtitle">{{ docType | allowDashString }}</div>
			</div>
			<div
				class="thead-item align-right"
				data-allow-collapse="true"
			>
				{{ getInvoiceDate }}
			</div>
			<div
				class="thead-item align-right"
				data-allow-collapse="true"
			>
				{{ getDueDate }}
			</div>
			<div
				class="thead-item align-right pr-3"
				data-allow-collapse="true"
			>
				{{ getTotal }}
			</div>
			<div
				:class="['thead-item status align-center', getStatusClass]"
				data-allow-collapse="true"
			>
				{{ getStatus }}
			</div>
			<div class="thead-item attach-wrapper">
				<BaseButton
					v-if="invoiceFileUrl"
					class="attach-item header btn btn-secondary link"
					size="small"
					data-allow-collapse="false"
					@click="handleOpenInvoice"
				>
					Invoice
				</BaseButton>
				<BaseButton
					v-if="receiptFileUrl"
					class="attach-item header btn btn-secondary link"
					size="small"
					data-allow-collapse="false"
					@click="handleOpenReceipt"
				>
					Receipt
				</BaseButton>
				<BaseButton
					v-if="otherFileUrl"
					class="attach-item header btn btn-secondary link"
					size="small"
					data-allow-collapse="false"
					@click="handleOpenOtherFile"
				>
					Other
				</BaseButton>
			</div>
		</div>
		<div
			class="invoice-card-body"
		>
			<div class="section-title">
				<h3 class="invoice-title">Delivery order (D/O)</h3>
			</div>
			<div class="invoice-list">
				<div class="thead invoice-header">
					<div class="thead-item font-weight-bold">
						D/O No.
					</div>
					<div class="thead-item font-weight-bold align-center">
						Item
					</div>
					<div class="thead-item font-weight-bold">
						Product
					</div>
					<div class="thead-item font-weight-bold align-right">
						Quantity
					</div>
					<div class="thead-item font-weight-bold align-right">
						Unit
					</div>
					<div class="thead-item font-weight-bold align-right">
						Total
					</div>
					<div class="thead-item font-weight-bold ml-10">
						Attached file
					</div>
				</div>
				<template
					v-if="deliveryOrders.length > 0"
				>
					<div
						class="tbody invoice-body"
						v-for="order in  deliveryOrders"
						:key="order.deliveryOrderNo+'-'+order.lineNo"
					>
						<div class="tbody-item-delivery-order">
							{{ order.deliveryOrderNo | allowDashString }}
						</div>
						<div class="tbody-item-delivery-order align-center">
							{{ order.lineNo }}
						</div>
						<div class="tbody-item-delivery-order">
							{{ order.productCode }} - {{ order.productName }}
						</div>
						<div class="tbody-item-delivery-order align-right">
							{{ order.qty | fullCurrency }}
						</div>
						<div class="tbody-item-delivery-order align-right">
							{{ order.saleUnit }}
						</div>
						<div class="tbody-item-delivery-order align-right">
							{{ order.netPrice | fullCurrency }}
						</div>
						<div class="tbody-item-delivery-order  develivery-order-attach-wrapper ml-10">
							<BaseButton
								:class="['attach-item btn btn-secondary link',{ disabled: !order.fileUrl }]"
								size="small"
								@click="handleOpenFileUrl(order.fileUrl)"
							>
								D/O file
							</BaseButton>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="empty-delivery-order">
						No delivery orders
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";
import Numberal from "numeral";

export default {
	name: "InvoiceCard",
	data() {
		return {
			isActive: false
		};
	},
	props: {
		status: {
			type: String,
			default: ""
		},
		shipToName: {
			type: String,
			default: ""
		},
		invoiceNumber: {
			type: [String, Number],
			default: ""
		},
		docType: {
			type: String,
			default: ""
		},
		dueDate: {
			type: [String, Date],
			default: ""
		},
		invoiceDate: {
			type: [String, Date],
			default: ""
		},
		invoiceTotal: {
			type: [String, Number],
			default: ""
		},
		invoiceFileUrl: {
			type: [String],
			default: ""
		},
		receiptFileUrl: {
			type: [String],
			default: ""
		},
		otherFileUrl: {
			type: [String],
			default: ""
		},
		deliveryOrders: {
			type: Array,
			default: () => []
		}
	},

	computed: {
		getInvoiceDate() {
			return dayjs(this.invoiceDate).format("D MMM YYYY");
		},
		getDueDate() {
			return dayjs(this.dueDate).format("D MMM YYYY");
		},
		getStatusClass() {
			const status = this.status;

			const mapClassStatus = {
				"PAID (SAP)": "paid",
				PAID: "paid",
				REJECT: "reject",
				"IN PROGRESS": "in-progress",
				APPROVE: "paid"
			};
			return mapClassStatus[status] ?? "";
		},
		getTotal() {
			return Numberal(this.invoiceTotal).format("0,0.00");
		},
		getStatus() {
			return this.status || "-";
		}
	},

	methods: {
		toggleCollapse(e) {
			const el = e.target;
			const { allowCollapse = "false" } = el.dataset;
			if (allowCollapse === "true") {
				this.isActive = !this.isActive;
			}
		},
		handleOpenInvoice() {
			window.open(this.invoiceFileUrl, "_blank");
		},
		handleOpenReceipt() {
			window.open(this.receiptFileUrl, "_blank");
		},
		handleOpenOtherFile() {
			window.open(this.otherFileUrl, "_blank");
		},
		handleOpenFileUrl(url) {
			if (url) {
				window.open(url, "_blank");
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.empty-delivery-order {
	display: flex;
	justify-content: center;
	align-items: center;
}

.thead {
	display: flex;
	padding: rem(10) rem(24);
	border-bottom: 1px solid $color-emerald;
	font-weight: $font-weight-bold;
	font-size: rem(16);
	color: $color-dark-blue-grey;
	padding-right: rem(104);

	&.invoice-header {
		padding: rem(10) 0 0 0;
		margin-bottom: rem(10);
	}
}

.tbody-item-delivery-order {
	width: calc(100% / 7);
	font-size: rem(16);
}

.in-progress {
	@include ellipsis(1);
}

.thead-item {
	width: calc(100% / 7);

	&.head {
		@include ellipsis(1);

		width: calc((100% /7) * 2);
	}
}

.develivery-order-attach-wrapper {
	margin-top: rem(-4);
	display: flex;
	/* stylelint-disable */

	&::v-deep {
		.btn {

			&.disabled {
				color: $color-grey-4;
				cursor: not-allow;
				pointer-events: none;
			}

			&.small {
				padding: 0;
			}
		}
	}
	/* stylelint-enable */
}



.attach-wrapper {
	display: flex;
	font-size: rem(16);
	margin-top: rem(-2);
	flex-wrap: wrap;
	justify-content: flex-start;
	/* stylelint-disable */
	&::v-deep {
		.btn {
			&.attach-item {
				&.disabled {
					color: $color-grey-4;
					cursor: not-allow;
				}

				&.header {
					margin: 0 rem(4);
				}
			}

			&.small {
				padding: 0;
				margin:0;
			}
		}
	}
	/* stylelint-enable */
}

.subtitle {
	font-size: rem(14);
	color: $color-grey-4;
	margin-top: rem(-4);

	@media screen and (min-width: 1011px) {
		position: absolute;
		width: rem(200);
	}

	@media screen and (max-width: 959px) {
		position: absolute;
		width: rem(200);
	}
}

.align {
	&-right {
		text-align: right;
	}

	&-center {
		text-align: center;
	}
}

.tbody-wrapper {
	margin-top: rem(8);
}

.invoice-body {
	display: flex;
	padding-right: rem(0);
}

.invoice-card {
	&-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		margin: rem(16) 0;
		border: 1px solid $color-silver;
		border-radius: rem(4);

		&.active {
			.invoice-card-body {
				display: block;
			}

			.arrow-icon {
				transform: rotate(180deg);
				transition: transform 0.5ms linear;
			}
		}
	}

	&-header {
		display: flex;
		align-items: flex-start;
		position: relative;
		padding: rem(4) rem(104) 0 rem(24);
		min-height: rem(54);

		.arrow {
			position: absolute;
			top: rem(8);
			right: rem(24);
			color: $color-cerulean;
			cursor: pointer;

			&-icon {
				transition: transform 0.5ms linear;
			}
		}
	}

	&-body {
		display: none;
		background-color: $color-alabaster;
		padding: rem(16) rem(24);
		border-radius: 0 0 rem(4) rem(4);

		.invoice-title {
			@include fontSub();

			font-size: rem(20);
			color: $color-dark-blue-grey;
			font-weight: $font-weight-bold;
		}
	}
}

.font-weight {
	&-bold {
		font-weight: $font-weight-bold;
	}
}

.status {
	font-weight: $font-weight-bold;

	&.in-progress {
		color: $color-grey-4;
	}

	&.paid {
		color: $color-emerald;
	}

	&.reject {
		color: $color-red;
	}
}

.icon {
	font-size: rem(14);
}

.ml {
	&-0 {
		margin-left: rem(0);
	}

	&-1 {
		margin-left: rem(4);
	}

	&-2 {
		margin-left: rem(8);
	}

	&-3 {
		margin-left: rem(12);
	}

	&-4 {
		margin-left: rem(16);
	}

	&-10 {
		margin-left: rem(40);
	}

	&-18 {
		margin-left: rem(72);
	}
}

.pl {
	&-10 {
		padding-left: rem(40);
	}

	&-14 {
		padding-left: rem(56);
	}

	&-18 {
		padding-left: rem(72);
	}
}

.pr {
	&-3 {
		padding-right: rem(12);
	}

	&-6 {
		padding-right: rem(24);
	}

	&-8 {
		padding-right: rem(32);
	}

	&-14 {
		padding-right: rem(56);
	}
}
</style>