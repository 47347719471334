import { getEOrderingHistoriesAPI } from "@/services/api/eOrdering.api";
import {
	E_ORDERING_HISTORIES_REQUEST,
	E_ORDERING_HISTORIES_SUCCESS,
	E_ORDERING_HISTORIES_FAILURE,
	E_ORDERING_HISTORIES_TOGGLE
} from "../../types";
import { transformHistories } from "../../../selectors/transform/eOrdering/transformHistories";

export default {
	namespaced: true,

	state: {
		data: transformHistories([]),
		isLoading: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0,
			perPage: 8
		},
		message: "",
		isEmpty: false
	},

	mutations: {
		[E_ORDERING_HISTORIES_REQUEST](state) {
			state.isLoading = true;
			state.data = transformHistories([]);
		},
		[E_ORDERING_HISTORIES_SUCCESS](state, { data, pagination, message }) {
			state.isLoading = false;
			state.data = transformHistories(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.message = message;
		},
		[E_ORDERING_HISTORIES_FAILURE](state) {
			state.data = transformHistories([]);
			state.isLoading = false;
		},
		[E_ORDERING_HISTORIES_TOGGLE](state, orderNo) {
			const orderHistoryIndex = state.data.findIndex((orderHistory) => orderHistory.orderNo === orderNo);
			if (orderHistoryIndex !== -1) {
				state.data[orderHistoryIndex].isExplain = !state.data[orderHistoryIndex].isExplain;
			}
		}
	},

	actions: {
		async getEOrderingHistories({ commit }, params) {
			try {
				commit(E_ORDERING_HISTORIES_REQUEST);
				const { data } = await getEOrderingHistoriesAPI(params);
				commit(E_ORDERING_HISTORIES_SUCCESS, data);
			} catch (error) {
				commit(E_ORDERING_HISTORIES_FAILURE);
			}
		},

		toggleEOrderingHistory({ commit }, orderNo) {
			commit(E_ORDERING_HISTORIES_TOGGLE, orderNo);
		}
	}
};
