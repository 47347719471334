import { getAirCompPowerAPI } from "@/services/api/airComp.api";
import { AIR_COMP_POWER_REQUEST, AIR_COMP_POWER_SUCCESS, AIR_COMP_POWER_FAILURE } from "../../types";
import { parseAirCompPower } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parseAirCompPower({}),
		loading: true,
		empty: false
	},

	mutations: {
		[AIR_COMP_POWER_REQUEST](state) {
			state.detail = parseAirCompPower({});
			state.loading = true;
			state.empty = false;
		},
		[AIR_COMP_POWER_SUCCESS](state, data) {
			const detail = data;
			state.detail = parseAirCompPower(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[AIR_COMP_POWER_FAILURE](state) {
			state.detail = parseAirCompPower({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async fetchAirCompPower({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_POWER_REQUEST);
				const { data } = await getAirCompPowerAPI(id, params);
				commit(AIR_COMP_POWER_SUCCESS, data);
			} catch (error) {
				commit(AIR_COMP_POWER_FAILURE, error);
			}
		}
	}
};