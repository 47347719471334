<template>
	<Empty v-if="empty" class="empty"/>
	<History
		v-else
		:data="data"
		:pagination="pagination"
		:loading="loading"
		@pageChange="handlePageChange"
		@export="handleExport"
	/>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
import Empty from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import History from "@/components/PwrModule/History.vue";
import { getUri } from "../../selectors/helpers";

export default {
	name: "meterOrder",

	components: {
		Empty,
		History
	},

	data() {
		return {
			id: this.$route.params.id,
			query: {
				page: this.$route.query.page
			}
		};
	},

	computed: {
		...mapState("powerHistory", {
			data: "data",
			pagination: "pagination",
			loading: "loading",
			empty: "empty",
			statusCode: "statusCode"
		})
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.id,
			httpStatus: this.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("powerHistory", {
			getPowerHistory: "getPowerHistory"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),
		updateUrl() {
			const { query } = this;
			this.$router.replace({ query }).catch(() => {});
		},
		async fetchData() {
			this.updateUrl();
			await this.getPowerHistory({ id: this.id, params: this.query });
		},
		handlePageChange(page = 1) {
			this.query.page = page;
			this.fetchData();
		},
		handleExport(form) {
			// docs: https://bigportal.qa-api.bigth.com/doc/#api-PWR-GetPwrsIdExportDailyConsumptions
			const params = {
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).format("YYYY-MM-DD"),
						endDate: dayjs(form.dateRange.end).format("YYYY-MM-DD")
					} : null
				)
			};
			const path = `/pwrs/${this.id}/export-daily-consumptions`;
			const uri = getUri(path, params);
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}
</style>