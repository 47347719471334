
export const transformHorizontalBarChart = (option = {}) => (data) => {
	const defaultLegend = {
		show: false
	};
	const defaultGrid = {
		left: "24px",
		right: "24px",
		bottom: "0",
		containLabel: true
	};

	const { tooltip = {}, legend = defaultLegend, grid = defaultGrid } = option;

	const { xAxis = [], yAxis = [], series = {} } = data;

	return {
		legend,
		tooltip: {
			show: true,
			trigger: "item",
			backgroundColor: "#103332",
			axisPointer: {
				crossStyle: {
					color: "red"
				}
			},
			padding: 8,
			...tooltip
		},
		grid,
		xAxis: {
			...xAxis
		},
		yAxis: {

			...yAxis
		},
		axisLabel: {
			color: () => "#103332",
			fontSize: 14,
			fontFamily: "TFSrivichai"
		},
		series
	};
};