<template>
	<div class="navigation-device-container">
		<swiper ref="deviceSwiper" :options="swiperOptions" class="device-container">
			<swiper-slide v-for="(item,index) in media"  :key="`Device-${index}`">
				<a href="#" @click.prevent="handleRoute(item.group)">
					<div :class="['device-card', { 'border-box-left': index === 0 },{ 'border-box-right': (index+1) === media.length }]" :style="{ backgroundColor: item.color}">
						<img :src="item.partIcon">
						<div class="text-title">{{item.group}}</div>
					</div>
				</a>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<div
			slot="button-next"
			class="swiper-button-next swiper-next-device swiper-button-black"
		></div>
		<div
			slot="button-prev"
			class="swiper-button-prev swiper-prev-device swiper-button-black"
		></div>
	</div>

</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { PAGE_NAME } from "../enums/pagePermission";

export default {
	components: {
		SwiperSlide,
		Swiper
	},

	directives: {
		swiper: directive
	},

	props: {
		media: {
			type: Array,
			default: () => ([])
		}
	},

	data() {
		return {
			swiperOptions: {
				slidesPerView: "auto",
				slidesPerGroup: 1,
				spaceBetween: 0,
				navigation: {
					nextEl: ".swiper-next-device",
					prevEl: ".swiper-prev-device"
				}
			}
		};
	},

	computed: {
		swiper() {
			return this.$refs.deviceSwiper.$swiper;
		}
	},

	methods: {
		handleRoute(module) {
			this.$router.push({
				name: PAGE_NAME.MARKETPLACE_MODULE_GROUP_DETAIL,
				params: {
					module
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.navigation-device-container {
	position: relative;
	width: fit-content;
	max-width: calc(100% - rem(48));
	margin: 0 auto;
}

.device-container {
	margin: rem(24) rem(64) rem(40) rem(64);
	border-radius: rem(8);
	padding: rem(25);

	::v-deep.swiper-wrapper {
		height: rem(165);
	}
}

.swiper-slide {
	z-index: 1;
	width: rem(165);
}

.border-box-left {
	border-radius: rem(8) 0 0 rem(8);
}

.border-box-right {
	border-radius: 0 rem(8) rem(8) 0;
}

.swiper-slide:hover {
	transform: scale(1.2);
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
	transition: 0.5s ease-in-out;
	z-index: 5;
}

.swiper-pagination ::v-deep.swiper-pagination-bullet {
	width: rem(14);
	height: rem(14);
	background-color: $color-white;
	opacity: 1;
}

.swiper-pagination ::v-deep.swiper-pagination-bullet-active {
	background-color: $color-emerald;
}

.swiper-button-black {
	--swiper-navigation-size: rem(24);
}

.device-card {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: rem(165);
	width: rem(165);
	background-color: $color-white;

	img {
		height: rem(80);
		width: rem(80);
	}

	.text-title {
		font-size: $font-24;
		font-weight: $font-weight-bold;
		color: $color-white;
		text-align: center;

		@include ellipsis(1);
	}
}
</style>
