export const productType = Object.freeze({
	oxygen: "Oxygen",
	hydrogen: "Hydrogen",
	nitrogen: "Nitrogen",
	carbonDioxide: "Carbon Dioxide",
	argon: "Argon"
});

export const dropdownProductTypeOptions = Object.freeze([
	{
		value: null,
		label: "Product type: All"
	},
	{
		value: "Aircompressure",
		label: "Air compressor"
	},
	{
		value: "Argon",
		label: "Argon"
	},
	{
		value: "Bulk Hydrogen",
		label: "Bulk Hydrogen"
	},
	{
		value: "Carbon Dioxide",
		label: "Carbon Dioxide"
	},
	{
		value: "Energy",
		label: "Energy"
	},
	{
		value: "Furnace",
		label: "Glass Furnace"
	},
	{
		value: "Hydrogen",
		label: "Hydrogen"
	},
	{
		value: "Nitrogen",
		label: "Nitrogen"
	},
	{
		value: "Oxygen",
		label: "Oxygen"
	},
	{
		value: "Other products",
		label: "Other products"
	}
]);

export const dropdownEOrderingProductTypeOptions = Object.freeze([
	{
		value: null,
		label: "Product type: All"
	},
	{
		value: "Energy",
		label: "LNG"
	},
	{
		value: "Oxygen",
		label: "Oxygen"
	},
	{
		value: "Nitrogen",
		label: "Nitrogen"
	},
	{
		value: "Bulk Hydrogen",
		label: "Hydrogen"
	},
	{
		value: "Argon",
		label: "Argon"
	},
	{
		value: "Carbon Dioxide",
		label: "CO2"
	}
]);