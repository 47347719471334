<template>
	<div>
		<BaseLoading v-if="zldInfo.isLoading" />

		<div :class="['row row-eq-height', { 'hide-content': zldInfo.isLoading }]">
			<div class="col-12 no-gutter-sm col-xl-7">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">Last update: {{ zldInfo.data.updatedAt }}</div>
					</template>
					<template v-slot:body>
						<ModuleOverview :data="zldInfo.data" />
					</template>
				</BaseCard>
			</div>
			<ModuleLocation :info="zldInfo.data" />
		</div>

		<div class="zld-level-container">
			<CardLineChart
				:chartData="getRecycleWaterData"
				:isLoading="recycleWaterFlowrate.isLoading"
				:isEmpty="recycleWaterFlowrate.isEmpty"
				:lastUpdate="recycleWaterFlowrate.lastUpdate"
				:filterOptions="recycleWaterFilterOptions"
				chartTitle="Recycle water flowrate"
				@onSelectDatePicker="handleRecycleWaterDateSelected"
				@onSelectDropDown="handleRecycleWaterSelectDateDropDown"
			/>

			<CardLineChart
				:chartData="getElectricalConductivity"
				:isLoading="electricalConductivity.isLoading"
				:isEmpty="electricalConductivity.isEmpty"
				:lastUpdate="electricalConductivity.lastUpdate"
				:filterOptions="electricalFilterOptions"
				chartTitle="Electrical conductivity"
				@onSelectDatePicker="handleElectricalDateSelected"
				@onSelectDropDown="handleElectricalSelectDateDropDown"
			/>

			<CardBarChart
				:chartData="getOutletRecycleWater"
				:lastUpdate="outletRecycleWater.lastUpdate"
				:isLoading="outletRecycleWater.isLoading"
				:isEmpty="outletRecycleWater.isEmpty"
				:filterOptions="outletRecycleWaterFilterOptions"
				chartTitle="Outlet recycle water"
				@dateSelected="handleOutletRecycleWaterDateSelected"
			/>
			<GrafanaDisplay
				:deviceId="zldId"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseCard from "@/components/BaseCard.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import { transformRecycleWaterLineChartData } from "../../selectors/transform/lineChart/transformLineChart";
import { transformRecycleWaterLineChartConfig } from "../../selectors/transform/lineChart/RecycleWater/recycleWaterLineChart";
import { transformElectricalConductivityLineChartConfig } from "../../selectors/transform/lineChart/ElectricalConductivity/ElectricalConductivityLineChart";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { transformDataBarChart } from "../../selectors/transform/barChart";
import { getDateNumber, getDateDiff } from "../../selectors/helpers";
import { transformOutletRecycleWater } from "../../selectors/transform/barChart/ZldInfo/outletRecycleWater";

const {
	perDay,
	perThreeDay,
	perWeek,
	perTwoWeek,
	perYear
} = MODULE_SORT_DATE_CHART;

export default {
	name: "zldInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		ModuleOverview,
		ModuleLocation,
		CardLineChart,
		CardBarChart
	},

	data() {
		return {
			zldId: this.$route.params.id,
			recycleWaterSortBy: perDay,
			electricalSortBy: perDay,
			recycleWaterFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},

			electricalFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},

			outletRecycleWaterFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			}
		};
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	computed: {
		...mapState([
			"zldInfo",
			"electricalConductivity",
			"recycleWaterFlowrate",
			"outletRecycleWater"
		]),

		...mapGetters("electricalConductivity", {
			getDataElectricalConductivity: "getDataElectricalConductivity"
		}),

		recycleWaterDateSelected() {
			const dateRange = this.recycleWaterFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		electricalDateSelected() {
			const dateRange = this.electricalFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		outletRecycleWaterSelected() {
			const dateRange = this.outletRecycleWaterFilterOptions.date.selected
				.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getRecycleWaterData() {
			const { diff } = this.recycleWaterDateSelected;
			const data = transformRecycleWaterLineChartData(this.recycleWaterFlowrate);
			return transformRecycleWaterLineChartConfig(
				this.recycleWaterSortBy,
				data,
				{
					diffDay: diff
				}
			);
		},

		getElectricalConductivity() {
			const { diff } = this.electricalDateSelected;
			return transformElectricalConductivityLineChartConfig(
				this.electricalSortBy,
				this.getDataElectricalConductivity,
				{
					diffDay: diff
				}
			);
		},

		getOutletRecycleWater() {
			const dateSort = this.outletRecycleWaterSelected.dateNumber;
			const dateReportFormat = [perWeek, perTwoWeek].includes(dateSort) ? "day" : "year";
			const options = { dateReportFormat };
			const xyAxis = transformDataBarChart(this.outletRecycleWater, options);
			return transformOutletRecycleWater(xyAxis);
		}
	},

	methods: {
		...mapActions("recycleWaterFlowrate", {
			fetchRecycleWaterFlowrate: "fetchRecycleWaterFlowrate"
		}),

		...mapActions("outletRecycleWater", {
			fetchOutletRecycleWater: "fetchOutletRecycleWater"
		}),

		...mapActions("electricalConductivity", {
			fetchElectricalConductivity: "fetchElectricalConductivity"
		}),

		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),

		fetchData() {
			const { zldId } = this;
			return Promise.all([
				this.fetchRecycleWaterFlowrate({ id: zldId, query: { filter: this.recycleWaterSortBy } }),
				this.fetchElectricalConductivity({ id: zldId, query: { filter: this.electricalSortBy } }),
				this.fetchOutletRecycleWater({ id: zldId, filterBy: this.outletRecycleWaterSelected.dateNumber })
			]);
		},

		handleRecycleWaterSelectDateDropDown(filterBy = "1d") {
			this.recycleWaterSortBy = filterBy;
			this.fetchRecycleWaterFlowrate({
				id: this.zldId,
				query: {
					filter: filterBy
				}
			});
		},

		handleRecycleWaterDateSelected(date) {
			const { zldId } = this;
			const { start, end } = date;

			this.recycleWaterFilterOptions.date.selected.range = {
				start,
				end
			};

			this.recycleWaterSortBy = "";

			this.fetchRecycleWaterFlowrate({
				id: zldId,
				query: {
					startDate: dayjs(start)
						.startOf("day")
						.toISOString(),
					endDate: dayjs(end)
						.endOf("day")
						.toISOString()
				}
			});
		},

		handleElectricalSelectDateDropDown(filterBy = perDay) {
			this.electricalSortBy = filterBy;
			this.fetchElectricalConductivity({
				id: this.zldId,
				query: {
					filter: filterBy
				}
			});
		},

		handleElectricalDateSelected(date) {
			const { start, end } = date;
			this.electricalSortBy = "";
			this.electricalFilterOptions.date.selected.range = {
				start,
				end
			};
			this.fetchElectricalConductivity({
				id: this.zldId,
				query: {
					startDate: dayjs(start)
						.startOf("day")
						.toISOString(),
					endDate: dayjs(end)
						.endOf("day")
						.toISOString()
				}
			});
		},

		handleOutletRecycleWaterDateSelected(date) {
			const { zldId } = this;

			this.outletRecycleWaterFilterOptions.date.selected.range = date;

			this.fetchOutletRecycleWater({
				id: zldId,
				filterBy: this.outletRecycleWaterSelected.dateNumber
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^="col"]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.zld-level-container {
	@include mobile {
		border-radius: 0;
		margin-left: rem(-16);
		margin-right: rem(-16);
		padding: rem(16) 0;
	}

	::v-deep {
		.detail-card-header {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: rem(16);

			@include mobile {
				align-items: flex-start;
				flex-direction: column;
				padding: 0 rem(16);

				& > * {
					margin-left: 0;
				}

				.button-group-container {
					width: 100%;

					.btn {
						justify-content: center;
						width: calc(100% / 3);
					}
				}
			}
		}
	}
}
</style>
