import { required, numeric } from "vuelidate/lib/validators";
import { getvalidateMessage, checkIfValid } from "../validateHelper";
import { checkDuplicatePriorityOurDeviceAPI } from "../../../services/api/bigStoreManagement";

export default {
	validations: {
		groupValue: {
			required
		},
		description: {
		},
		color: {
			required,
			format(value) {
				const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
				return regex.test(value);
				// ^ represents the starting of the string.
				// # represents the hexadecimal color code must start with a ‘#’ symbol.
				// ( represents the start of the group.
				// [A-Fa-f0-9]{6} represents the letter from a-f, A-F, or digit from 0-9 with a length of 6.
				// | represents the or.
				// [A-Fa-f0-9]{3} represents the letter from a-f, A-F, or digit from 0-9 with a length of 3.
				// ) represents the end of the group.
				// $ represents the ending of the string.
			}
		},
		priorityNumber: {
			required,
			format(value) {
				return numeric(value);
			},
			minValue(value) {
				return numeric(value) && value > 0;
			},
			async duplicate(value) {
				if (numeric(value) && value !== "") {
					// Check case edit when duplicate eg. olddata 3 fill in input 3 give validate pass.
					if (this.priorityNumberOldData !== "" && this.priorityNumberOldData !== undefined) {
						if (this.priorityNumberOldData === Number(value)) {
							return true;
						}
					}
					const { data } = await checkDuplicatePriorityOurDeviceAPI(value);
					return !data.priorityDuplicate;
				}
				return true;
			}
		}
	},
	methods: {
		checkIfValid(name) {
			const field = this.$v[name];
			if (!field.$dirty) {
				return null;
			}

			if (!("required" in field) && !field.$error) {
				return null;
			}

			return (field.$invalid || field.$error);
		},
		validatePermissionForm() {
			this.$v.$touch();
		}
	},
	computed: {
		isGroupValueError() {
			const field = this.$v.groupValue;
			return checkIfValid(field);
		},

		isColorError() {
			const field = this.$v.color;
			return checkIfValid(field);
		},

		getGroupValueErrorMessage() {
			const field = this.$v.groupValue;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		getColorErrorMessage() {
			const field = this.$v.color;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Invalid format. The color code must start with '#' and follow by 6 hexadecimal numbers. (e.g. #21efcd)"
			});
		},

		isPriorityNumberError() {
			const field = this.$v.priorityNumber;
			return checkIfValid(field);
		},

		getPriorityNumberError() {
			const field = this.$v.priorityNumber;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Invalid number. Only a positive number is allowed.",
				invalidMinValueMessage: "Invalid number. Only a positive number is allowed.",
				duplicateMessage: "This priority is currently in use. Please choose another number or re-adjust priority numbers in all items."
			});
		}
	}
};