<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group  input-group-search search-shipto', { active: isSearchActive }]">
			<span class="input-icon"><FontAwesomeIcon :icon="['far', 'search']" /></span>
			<BaseFormInput
				v-model="keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Ship-to name, location key or ship id"
				@input="updateKeyword"
				@focus="setFocus('search', true)"
				@blur="setFocus('search', false)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-product', { active: isProductActive }]">
			<BaseDropdown
				:searchable="false"
				:list="products"
				:value="productId"
				type="ghost"
				placeholder="Product type: All"
				@input="filterProduct"
				@active="setFocus('product', $event)"
			/>
		</div>
		<div v-if="isShowSale" :class="['col-md-3 input-group input-group-sales', { active: isSalesActive }]">
			<BaseDropdown
				:list="owners"
				:value="ownerId"
				type="ghost"
				placeholderSearchInput="Type sales name"
				placeholder="Sales: All"
				@active="setFocus('sales', $event)"
				@input="filterSales"
			/>
		</div>
		<BaseButton size="large" type="primary" outline rounded>Search</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
export default {
	name: "SearchFilterForm",

	props: {
		owners: {
			type: Array,
			default: () => ([])
		},
		products: {
			type: Array,
			default: () => ([])
		},
		query: {
			type: Object,
			default: () => ({})
		},
		isShowSale: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			keyword: this.query.q,
			productId: this.query.productname,
			ownerId: this.query.owner,
			isSalesActive: false,
			isProductActive: false,
			isSearchActive: false
		};
	},

	methods: {
		filterProduct(value) {
			this.productId = value;
		},

		filterSales(value) {
			this.ownerId = value;
		},

		setFocus(type, value) {
			switch (type) {
				case "sales":
					this.isSalesActive = value;
					break;

				case "product":
					this.isProductActive = value;
					break;

				case "search":
					this.isSearchActive = value;
					break;

				default:
					break;
			}
		},

		handleSubmit() {
			this.$emit("submit", {
				keyword: this.keyword,
				product: this.productId,
				owner: this.ownerId
			});
		},

		clearFilter() {
			this.keyword = null;
			this.productId = null;
			this.ownerId = null;

			this.$emit("submit", {
				keyword: null,
				product: null,
				owner: null
			});
		},

		updateKeyword(value) {
			this.keyword = value || null;
		}
	}
};
</script>

<style lang="scss" scoped>
.filter-form {
	@include mobile {
		border-bottom: rem(1) solid $color-silver;
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;
		padding: 0 rem(32);
		width: calc(100% + #{rem(32)});

		.btn.large {
			background-color: $color-emerald;
			border-radius: rem(8);
			color: $color-white;
			width: 100%;
		}

		.input-group {
			background-color: $color-white;
			border-color: $color-silver;
			border-radius: rem(8);
			margin-bottom: rem(12);

			&:not(:last-of-type)::after {
				display: none;
			}

			&::v-deep .dropdown-bubble-container {
				margin-left: 0;
				width: 100%;
			}
		}

		.clear-filter {
			margin: rem(12) 0;
		}

		&::v-deep .input-wrapper {
			&.medium {
				.dropdown-value,
				.dropdown-value-text {
					height: rem(44);
					line-height: rem(44);
				}
			}
		}
	}

	@include tablet {
		border-bottom: rem(1) solid $color-silver;
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;
		padding: 0 rem(32);
		width: calc(100% + #{rem(32)});

		.btn.large {
			background-color: $color-emerald;
			border-radius: rem(8);
			color: $color-white;
			width: 100%;
		}

		.clear-filter {
			margin: rem(12) 0;
		}

		.input-group {
			background-color: $color-white;
			border-color: $color-silver;
			border-radius: rem(8);
			margin-bottom: rem(12);

			&:not(:last-of-type)::after {
				display: none;
			}

			&::v-deep .dropdown-bubble-container {
				margin-left: 0;
				width: 100%;
			}
		}
	}

	.input-group-search {
		&.search-shipto {
			width: rem(258);

			@include desktop-large {
				width: rem(277);
			}

			@include tablet {
				width: 100%;
			}

			@include mobile {
				width: 100%;
			}
		}
	}

	.input-group-product {
		width: rem(180);

		@include desktop-large {
			width: rem(240);
		}

		@include tablet {
			margin-right: rem(14);
			width: 49%;
		}

		@include mobile {
			padding: 0;
			width: 100%;
		}
	}

	.input-group-sales {
		width: rem(180);

		@include desktop-large {
			width: rem(228);
		}

		@include tablet {
			width: 49%;
		}

		@include mobile {
			padding: 0;
			width: 100%;
		}
	}
}
</style>
