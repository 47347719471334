import { replaceDeviceName } from "../../../../selectors/helpers/replace";
import { productType } from "../../../../enums/productType";
import { dateTimeFormat, numberFormat } from ".";



export const getOverView = (
	{
		plantCapacity = true,
		todayConsumption = true,
		MTDBaseConsumption = true,
		MTDPremiumConsumption = true,
		linePressure = true,
		oxygen = true
	},
	{
		plantCapacityField,
		todayConsumptionField,
		MTDBaseConsumptionField,
		MTDPremiumConsumptionField,
		linePressureField,
		oxygenField,
		plantCapacityFieldUnit,
		todayConsumptionFieldUnit,
		MTDBaseConsumptionFieldUnit,
		MTDPremiumConsumptionFieldUnit,
		linePressureFieldUnit,
		oxygenFieldUnit
	},
	overViewDisplayTypeTemplate,
	input = {}
) => {
	const defaultFields = {
		plantCapacityField: plantCapacityField || "Plant capacity",
		todayConsumptionField: todayConsumptionField || "Today consumption",
		MTDBaseConsumptionField: MTDBaseConsumptionField || "MTD Base consumption",
		MTDPremiumConsumptionField: MTDPremiumConsumptionField || "MTD Premium consumption",
		linePressureField: linePressureField || "Line pressure",
		oxygenField: oxygenField || "Oxygen",
		plantCapacityFieldUnit: plantCapacityFieldUnit || "",
		todayConsumptionFieldUnit: todayConsumptionFieldUnit || "",
		MTDBaseConsumptionFieldUnit: MTDBaseConsumptionFieldUnit || "",
		MTDPremiumConsumptionFieldUnit: MTDPremiumConsumptionFieldUnit || "",
		linePressureFieldUnit: linePressureFieldUnit || "barG",
		oxygenFieldUnit: oxygenFieldUnit || "ppm"
	};

	const maxPPM = 6;
	const isShowMTD = input.shipId === "GG-POSCO";
	const isNitrogen = input.name === productType.nitrogen;

	const oxygenPercentage = input.oxygen ? `${input.oxygen}%` : "-";
	const isOxygenCanDisplay = input.oxygen && input.oxygenPercent;
	const oxygenBarGValue = isNitrogen && isOxygenCanDisplay ? numberFormat(input.oxygen, "0.0") : oxygenPercentage;
	const linePressureValue = input.linePressure !== null ? numberFormat(input.linePressure, "0.00") : "-";

	const linePressureBarG = {
		barG: {
			value: input.pressurePercent || 0,
			slots: [
				{ value: 12.5, color: "red" },
				{ value: 75, color: "cool-green" },
				{ value: 12.5, color: "red" }
			]
		}
	};

	const linePresureBarG = [
		{
			title: "PressureIcon",
			titleType: overViewDisplayTypeTemplate.linePressure || "icon",
			titleDisplay: `${defaultFields.linePressureField} (${defaultFields.linePressureFieldUnit || "barG"})`,
			value: linePressureValue,
			...(input.linePressure !== null ? linePressureBarG : {})
		}
	];

	const oxygenPercentageBarG = {
		barG: {
			value: input.oxygen,
			slots: [
				{ value: 50, color: "red" },
				{ value: 50, color: "cool-green" }
			]
		}
	};

	const nitrogenPercentageBarG = {
		barG: {
			value: ((input.oxygen / maxPPM) * 100),
			slots: [
				{ value: 50, color: "cool-green" },
				{ value: 50, color: "red" }
			]
		}
	};

	const oxygenBarG = [
		{
			title: isNitrogen ? "OxygenIcon" : "OxygenIcon",
			titleType: overViewDisplayTypeTemplate.oxygen || "icon",
			titleDisplay: `${defaultFields.oxygenField} (${defaultFields.oxygenFieldUnit || "ppm"})`,
			value: input.oxygen ? oxygenBarGValue : "-",
			...(!isNitrogen && isOxygenCanDisplay ? oxygenPercentageBarG : {}),
			...(isNitrogen && isOxygenCanDisplay ? nitrogenPercentageBarG : {})
		}
	];

	const overview = [
		{ title: `TAG No.`, value: input.tagNo },
		...(plantCapacity ? [{ title: `${defaultFields.plantCapacityField} (Nm<sup>3</sup>/hr)`, value: numberFormat(input.plantCapacity) }] : []),
		...(todayConsumption ? [{ title: `${defaultFields.todayConsumptionField} (Nm<sup>3</sup>)`, value: numberFormat(input.todayConsumption) }] : []),
		...(MTDBaseConsumption ? [{ title: `${defaultFields.MTDBaseConsumptionField} (Nm<sup>3</sup>)`, value: numberFormat(input.MTDBaseConsumption) }] : []),
		...(MTDPremiumConsumption ? [{ title: `${defaultFields.MTDPremiumConsumptionField} (Nm<sup>3</sup>)`, value: numberFormat(input.MTDPremiumConsumption) }] : []),
		...(isShowMTD ? [{ title: `MTD Opr. hours (Hr:mm)`, value: input.MTDOprHours }] : []),
		...(linePressure ? linePresureBarG : []),
		...(oxygen ? oxygenBarG : [])
	];
	return overview;
};

export function parseGenGasInfo(input, defaultTemplate) {
	if (!input) {
		return {

		};
	}

	const overViewTemplate = input.layout?.overview || defaultTemplate.layout.overview;
	const overViewFieldTemplate = input.layout?.overviewField || defaultTemplate.layout.overviewField;
	const overViewDisplayTypeTemplate = input.layout?.overviewDisplayType || defaultTemplate.layout.overviewDisplayType;
	const layoutTemplate = input.layout || defaultTemplate.layout;
	const overview = getOverView(overViewTemplate, overViewFieldTemplate, overViewDisplayTypeTemplate, input);

	return {
		id: input.id,
		shipId: input.shipId,
		type: input.type,
		shipToId: input.shipToId,
		module: input.module,
		name: replaceDeviceName(input.name),
		tagNo: input.tagNo,
		flowRate: input.flowRate,
		flowRatePercent: input.flowRatePercent,
		maxRange: input.maxRange,
		oxygenFlowRate: input.oxygenFlowRate,
		MTDOxygenFlow: input.MTDOxygenFlow,
		DO02: input.DO02 || 0,
		shipToLatitude: input.shipToLatitude,
		shipToLongitude: input.shipToLongitude,
		updatedAt: dateTimeFormat(input.updatedAt),
		overview,
		layout: {
			...layoutTemplate
		},
		deviceCustomerName: input.deviceCustomerName,
		reportId: input.reportId,
		workspaceId: input.workspaceId
	};
}
