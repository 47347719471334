import Numeral from "numeral";
import { transformBulkHydrogenBarChart } from "../index";

const optionBhyBarChart = {
	yAxisText: "Delivery (Nm³)",
	series: {
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#0062a2",
			axisPointer: {
				type: "none"
			},
			padding: 8,
			formatter: ([{ value, name }]) => {
				return `
                    <div>
                        <div class="bar-name">
                            ${name}
                        </div>
                        <div class="bar-value">
                            ${Numeral(value).format(0, 0)} Nm³</sup>
                        </div>
                    </div>
                `;
			}
		}
	}
};

export const transformBhyBarChart = transformBulkHydrogenBarChart(optionBhyBarChart);