<template>
	<header>
		<h2>
			<div
				:class="['device-selection', { active: isOpenDeviceSelection }]"
				v-click-outside="hideDeviceSelection"
			>
				<a
					href=""
					@click.prevent="toggleDeviceOption"
				>
					{{ data.productName }}
					<div class="device-dropdown">
						<small>
							{{ `(${data.shipId})` }}
						</small>
						<FontAwesomeIcon
							v-if="!empty"
							:icon="['far', 'chevron-circle-down']"
						/>
						<BaseTagStandardModule
							v-if="data.conditionStatus.status && isMobile"
							size="small"
							:bgColor="data.conditionStatus.bgColor"
							:borderColor="data.conditionStatus.borderColor"
							:textColor="data.conditionStatus.textColor"
						>
							{{ data.conditionStatus.title }}
						</BaseTagStandardModule>
					</div>
				</a>
				<ul
					v-if="!empty"
					class="device-options"
				>
					<li
						v-for="(device, index) in dataRelatedDevices"
						:key="`${index}-${device.name}`"
						class="device-option"
					>
						<router-link
							v-if="device.app"
							:to="device.urlLink"
						>
							<span class="device-info-type">
								{{ device.app }}
							</span>
							<div class="device-info">
								{{ device.productName }}
								<span>
									{{ device.deviceCustomerName ? `(${device.deviceCustomerName})`: `${device.shipId}` }}
								</span>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
		</h2>
		<BaseTagStandardModule
			v-if="data.conditionStatus.status && !isMobile"
			:bgColor="data.conditionStatus.bgColor"
			:borderColor="data.conditionStatus.borderColor"
			:textColor="data.conditionStatus.textColor"
		>
			{{ data.conditionStatus.title }}
		</BaseTagStandardModule>
	</header>
</template>

<script>
import BaseTagStandardModule from "@/components/BaseTagStandardModule.vue";
import { MODULE_TYPES, MODULE_DEVICE_STATUSES } from "../../enums/modules";
import { CONFIG } from "../../enums/config";
import setupDevice from "../../mixins/setupDevice";

export default {
	name: "ModuleHeaderStandard",

	components: {
		BaseTagStandardModule
	},

	props: {
		data: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			CONFIG,
			MODULE_DEVICE_STATUSES,
			MODULE_TYPES,
			MODULE_TYPE_NITRO_FAS: "NitroFAS",
			MODULE_TYPE_PROCESS: "Furnace",
			isOpenDeviceSelection: false
		};
	},

	mixins: [setupDevice],

	computed: {
		shipToId() {
			return this.data.shipToId || null;
		},
		status() {
			if (this.data.deliveryStatus) {
				return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].title;
			} else if (this.data.status) {
				return MODULE_DEVICE_STATUSES[this.data.status].title;
			}
			return null;
		},
		tagClass() {
			if (this.data.deliveryStatus) {
				return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].tagClass;
			} else if (this.data.status) {
				return MODULE_DEVICE_STATUSES[this.data.status].tagClass;
			}
			return null;
		},
		empty() {
			return this.data.relatedDevices?.length === 0 ?? true;
		},

		dataRelatedDevices() {
			return this.data.relatedDevices.filter((value) => value.urlLink?.name);
		}
	},

	methods: {
		toggleDeviceOption() {
			if (!this.empty) {
				this.isOpenDeviceSelection = !this.isOpenDeviceSelection;
			} else {
				this.hideDeviceSelection();
			}
		},
		hideDeviceSelection() {
			this.isOpenDeviceSelection = false;
		}
	}
};
</script>


<style lang="scss" scoped>
header {
	display: flex;
	align-items: center;
	margin-top: rem(16);
}

h2 {
	color: $color-h2;
	font-size: $font-48;
	line-height: 1;

	a {
		color: $color-h2;
		display: flex;
		align-items: center;

		[data-icon="angle-down"] {
			color: $color-arrow-blue;
			font-size: $font-26;
		}

		small {
			color: $color-small;
			font-size: $font-20;
			margin: 0 rem(16) 0 rem(8);
			position: relative;
			top: rem(4);

			@include mobile {
				top: 0;
				margin: rem(8);
			}
		}

		@include mobile {
			flex-direction: column;
			align-items: start;
		}
	}
}

.device-selection {
	position: relative;

	.device-options {
		display: none;
		position: absolute;
		left: 0;
		width: rem(240);
		max-height: rem(320);
		overflow-x: scroll;
		padding: rem(8) 0;
		border-radius: 4px;
		border: 1px solid $color-silver;
		background-color: $color-white;
		z-index: 4;
	}

	.device-option {
		a {
			display: block;
			padding: rem(4) rem(16) rem(8);
			color: $color-dark-blue-grey;
			font-size: $font-24;
			font-weight: 400;

			&:hover {
				background-color: $color-ice-two;
			}

			span {
				font-size: $font-16;
				color: $color-grey-4;
			}
		}
	}

	.device-dropdown {
		display: flex;
		align-items: center;
	}

	&.active {
		.device-options {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}

	.fa-chevron-circle-down {
		color: $color-cerulean;
		font-size: rem(16);
		transition: 0.3s transform;
	}
}

.spinner {
	width: rem(14);
	height: rem(14);
	color: $color-grey-3;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
</style>
