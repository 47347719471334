import { getAirCompSECAPI } from "@/services/api/airComp.api";
import { AIR_COMP_SEC_REQUEST, AIR_COMP_SEC_SUCCESS, AIR_COMP_SEC_FAILURE } from "../../types";
import { parseAirCompSEC } from "../../../services/api/transforms/parseModules";
import { MILI_QUE_UNIT } from "./../../../selectors/transform/unit";

export default {
	namespaced: true,

	state: {
		detail: parseAirCompSEC({}),
		loading: true,
		empty: false,
		tagName: ""
	},

	mutations: {
		[AIR_COMP_SEC_REQUEST](state) {
			state.detail = parseAirCompSEC({});
			state.loading = true;
			state.empty = false;
		},
		[AIR_COMP_SEC_SUCCESS](state, data) {
			const detail = data;
			state.detail = parseAirCompSEC(detail);
			state.tagName = (state.detail.data[0]?.tagname || "Specific Energy Consumption (SEC) (kWh/m³)").replace(`m3`, MILI_QUE_UNIT);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[AIR_COMP_SEC_FAILURE](state) {
			state.detail = parseAirCompSEC({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async fetchAirCompSEC({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_SEC_REQUEST);
				const { data } = await getAirCompSECAPI(id, { filter: params.filter });
				commit(AIR_COMP_SEC_SUCCESS, data);
			} catch (error) {
				commit(AIR_COMP_SEC_FAILURE, error);
			}
		}
	}
};