<template>
	<div
		:style="cardStyle"
		:class="['card', { scrollbar: isSetScrollbar }, roundedClass]"
	>
		<div class="card-header">
			<slot name="header"></slot>
		</div>
		<template>
			<perfect-scrollbar v-if="isSetScrollbar" :style="[...scrollbarStyle]" :options="{ minScrollbarLength: 50 }">
				<div :style="[...bodyStyle,]" class="card-body">
					<slot name="body"></slot>
				</div>
			</perfect-scrollbar>
			<div v-else :style="[...bodyStyle,]" class="card-body">
				<slot name="body"></slot>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: "BaseCard",

	props: {
		item: {
			type: Object,
			default: null
		},
		cardStyle: {
			type: Array,
			default: () => []
		},
		bodyStyle: {
			type: Array,
			default: () => []
		},
		scrollbarStyle: {
			type: Array,
			default: () => []
		},
		rounded: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		isSetScrollbar() {
			return this.scrollbarStyle.length > 0;
		},
		roundedClass() {
			return this.rounded ? "rounded" : null;
		}
	}
};
</script>

<style lang="scss" scoped>
.card {
	position: relative;
	display: flex;
	flex-direction: column;
	border: rem(1) solid $card-border-color;
	border-radius: rem(4);
	margin-bottom: rem(34);
	flex-grow: 1;

	&.rounded {
		border-radius: $border-radius-normal;

		.card-header {
			border-top-right-radius: rem($border-radius-normal);
			border-top-left-radius: rem($border-radius-normal);
		}

		.card-footer {
			border-bottom-right-radius: rem($border-radius-normal);
			border-bottom-left-radius: rem($border-radius-normal);
		}
	}

	&.scrollbar {
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: rem(8);
			background-color: $color-white;
		}

		.card-header {
			&::after {
				content: "";
				position: absolute;
				bottom: rem(-8);
				left: 0;
				z-index: 1;
				width: 100%;
				height: rem(8);
				background-color: $color-white;
			}
		}
	}

	// card-header
	&-header {
		position: relative;
		background-color: $card-bg-color;
		border-top-right-radius: rem(4);
		border-top-left-radius: rem(4);
		padding: rem(16);
		color: $card-header-color;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include mobile {
			align-items: flex-start;
			flex-direction: column;
		}

		h4 {
			@include fontSub();

			display: flex;
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
		}
	}

	// card-body
	&-body {
		flex: 1;
		padding: rem(16);
	}

	// card-footer
	&-footer {
		background-color: $card-bg-color;
		border-bottom-right-radius: rem(4);
		border-bottom-left-radius: rem(4);
		padding: rem(16);
	}
}


.split-col {
	.card-body {
		display: flex;
	}
}

.chart {
	.card-body {
		padding-right: 0;
		padding-left: 0;
	}
}

/* stylelint-disable */
.ps {
	&::v-deep .ps__rail-y {
		z-index: 2;
		margin-right: rem(8);
	}

	&::v-deep .ps__thumb-y {
		width: rem(5);
		background-color: $color-light-teal;
	}

	&::v-deep .ps__rail-y:hover, &::v-deep .ps--clicking {
		background-color: transparent;
	}
}
/* stylelint-enable */
</style>
