<template>
	<BaseLayout>
		<a href="#" class="back" @click="handlerBack(bigStoreManagement.moduleDetail.group)">
			<FontAwesomeIcon :icon="['far', 'chevron-circle-left']" />
			Back
		</a>
		<div class="wrapper-header-content">
			<div class="image">
				<img :src="bigStoreManagement.moduleDetail.headerBannerImage">
				<div class="header-title">{{bigStoreManagement.moduleDetail.group}}</div>
				<div class="backdrop"></div>
			</div>
			<div class="app-content">
				<div class="icon-app" :style="{ '--bgcolor': bigStoreManagement.moduleDetail.color }">
					<img :src="bigStoreManagement.moduleDetail.iconContent">
				</div>
				<h3 class="title" :title="bigStoreManagement.moduleDetail.appContent">{{bigStoreManagement.moduleDetail.appContent}}</h3>
				<div :class="['button-request', {'disable-button': isSentEmail}]" @click="showModal">
					<CommentLineIcon class="icon-comment-line"></CommentLineIcon> Request for more information
				</div>
				<div v-if="isSentEmail" class="text-send-email">
					<CircleCheckIcon></CircleCheckIcon> You already sent the request. We will contact you as soon as possible. Thank you.
				</div>
			</div>
			<div class="detail-content-container">
				<div v-if="showDetailContent" :class="['detail-content-info', { 'w-half': bigStoreManagement.moduleDetail.vdoLink }]">
					<div v-if="bigStoreManagement.moduleDetail.detailHeader" class="head-detail">
						{{ bigStoreManagement.moduleDetail.detailHeader }}
					</div>
					<div v-if="bigStoreManagement.moduleDetail.detailDescription" class="detail-description">
						{{ bigStoreManagement.moduleDetail.detailDescription }}
					</div>
				</div>
				<div v-if="bigStoreManagement.moduleDetail.vdoLink" :class="['vdo-container', { 'w-half': showDetailContent }]">
					<iframe class="responsive-iframe" :src="bigStoreManagement.moduleDetail.vdoLink" frameborder="0" allowfullscreen></iframe>
				</div>
			</div>
		</div>
		<router-view
		/>
		<ModalRequestChangePassword
			:modal-name="MODAL_REQUEST_EXAMPLE_MODULE"
			:data="bigStoreManagement.moduleDetail"
			@success="handleSentEmail"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CircleCheckIcon from "@/components/icon/CircleCheckIcon.vue";
import ModalRequestChangePassword from "@/components/bigStoreManagement/ModalRequest.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import CommentLineIcon from "../../components/icon/CommentLineIcon.vue";
import { MODAL_NAME } from "../../enums/modal";

const {
	MODAL_REQUEST_EXAMPLE_MODULE
} = MODAL_NAME;

export default {
	name: "ExampleModule",

	components: {
		CommentLineIcon,
		ModalRequestChangePassword,
		CircleCheckIcon
	},

	data() {
		return {
			MODAL_REQUEST_EXAMPLE_MODULE,
			redirect: this.$route.query.redirect || null,
			id: this.$route.params.id,
			serviceId: this.$route.params.service,
			PAGE_NAME,
			isSentEmail: false
		};
	},

	computed: {
		...mapState(["bigStoreManagement"]),

		showDetailContent() {
			return this.bigStoreManagement.moduleDetail.detailHeader || this.bigStoreManagement.moduleDetail.detailDescription;
		}
	},

	async created() {
		await this.fetchMarketplaceModuleDetail({ id: this.serviceId });
		await this.addActivityLogMarketplaceModuleDetail(this.bigStoreManagement.moduleDetail.appContent);
	},

	methods: {
		...mapActions("bigStoreManagement", {
			fetchMarketplaceModuleDetail: "fetchMarketplaceModuleDetail"
		}),

		...mapActions("activityLogAdd", {
			addActivityLogMarketplaceModuleDetail: "addActivityLogMarketplaceModuleDetail"
		}),

		showModal() {
			if (!this.isSentEmail) {
				this.$modal.show(MODAL_REQUEST_EXAMPLE_MODULE);
			}
		},

		handleSentEmail() {
			this.isSentEmail = true;
		},

		handlerBack(module) {
			// Back to Marketplace Landing Page
			if (this.redirect === "home") {
				this.$router.push({
					name: PAGE_NAME.MARKETPLACE
				});
			} else { // Back to Module Group Detail Page
				this.$router.push({
					name: PAGE_NAME.MARKETPLACE_MODULE_GROUP_DETAIL,
					params: {
						module
					}
				});
			}
		}

	}
};
</script>

<style lang="scss" scoped>
.wrapper-header-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: rem(24);
	font-size: $font-48;
	font-weight: $font-weight-bold;

	.image {
		position: relative;
		width: 100%;

		.header-title {
			position: absolute;
			left: 5%;
			top: 50%;
			z-index: 2;
			font-size: $font-48;
			font-weight: $font-weight-bold;
			color: $color-white;
			transform: translate(0, -50%);
		}

		img {
			height: 100%;
			max-height: rem(240);
			width: 100%;
			border-radius: rem(8);
			object-fit: cover;
		}

		.backdrop {
			position: absolute;
			top: 0;
			z-index: 1;
			height: 100%;
			max-height: rem(240);
			width: 100%;
			border-radius: rem(8);
			background-color: rgba(0, 0, 0, 0.25);
		}
	}

	.app-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-bottom: rem(24);
		border-bottom: 1px solid $color-silver;

		@include mobile {
			flex-flow: column wrap;

			.icon-app {
				align-self: center;
				margin-bottom: rem(16);
			}
		}

		.icon-app {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: rem(80);
			min-height: rem(80);
			margin-right: rem(24);
			border-radius: rem(40);
			background-color: var(--bgcolor);

			img {
				width: rem(56);
				height: rem(56);
			}
		}

		.title {
			color: $color-dark-blue-grey;
			font-size: $font-48;
			line-height: 1;

			@include mobile {
				max-width: 100%;
				display: -webkit-box;
				-webkit-line-clamp: unset !important;
				-webkit-box-orient: vertical;
			}

			@include desktop {
				@include ellipsis(1, break-word);
			}
		}

		.button-request {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: rem(280);
			margin-left: rem(24);
			background-color: $color-emerald;
			border-radius: rem(8);
			font-size: $font-24;
			color: $color-white;
			padding: rem(12);

			@include mobile {
				margin-left: 0;
			}

			.icon-comment-line {
				margin-right: rem(16);
				width: rem(16);
				height: rem(16);
				fill: $color-white;
			}

			&:hover {
				background-color: $color-jungle-green;
				cursor: pointer;
			}
		}

		.disable-button {
			background-color: $color-grey-3;

			&:hover {
				background-color: $color-light-teal;
				cursor: not-allowed;
			}
		}

		.text-send-email {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-left: rem(16);
			font-size: $font-16;
			color: $color-emerald;

			svg {
				margin-right: 4px;
				fill: $color-emerald;
			}
		}
	}

	.detail-content {
		&-container {
			color: $color-dark-blue-grey;
			display: flex;
			justify-content: space-between;
			padding: rem(24) 0;

			@include mobile {
				flex-direction: column;
			}
		}

		&-info {
			width: 100%;

			@include mobile {
				width: 100%;
			}

			&.w-half {
				width: 49%;

				@include mobile {
					width: 100%;
				}
			}

			.head-detail {
				word-break: break-word;
				font-size: $font-48;
				line-height: 1;
				font-weight: $font-weight-regular;
				margin-bottom: rem(16);
			}

			.detail-description {
				word-break: break-word;
				font-size: $font-24;
				font-weight: $font-weight-regular;
				margin-bottom: rem(36);

				@include mobile {
					width: 100%;
				}
			}
		}
	}
}

.vdo-container {
	max-height: rem(436);
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio */

	@include mobile {
		padding-top: 56.25%; /* 16:9 Aspect Ratio */
	}

	&.w-half {
		padding-top: 25%;
		width: 49%;

		@include mobile {
			padding-top: 56.25%; /* 16:9 Aspect Ratio */
			width: 100%;
		}
	}
}

.responsive-iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

.back {
	color: $back-color-btn;
	font-size: $font-18;
	font-weight: bold;
	display: inline-flex;
	align-items: center;

	.fa-chevron-circle-left {
		position: relative;
		top: -1px;
		font-size: $font-16;
		margin-right: rem(8);
	}
}

@media (max-width: rem(580)) {
	::v-deep {
		.container-filter {
			flex-flow: column-reverse nowrap;
		}
	}

	.image {
		width: calc(100% + 60px) !important;
		margin-right: -30px;
		margin-left: -30px;

		img {
			border-radius: 0 !important;
		}

		.backdrop {
			border-radius: 0 !important;
		}
	}
}
</style>
