<template>
	<div class="change-password-content">
		<div v-if="!isExpired" class="change-password-form">
			<h1 class="change-password-title">Create new password</h1>
			<form ref="form-login">
				<div class="form-group">
					<BaseFormInputPassword
						v-model="currentPassword"
						:valid.sync="isValidCurrentPassword"
						:showIconLeft="false"
						:customErrorMessage="currentPasswordErrorMessage"
						formatType="simple"
						placeholder=""
						class="custom-input"
					/>
				</div>
				<div class="form-group">
					<BaseFormInputPassword
						v-model="newPassword"
						:valid.sync="isValidNewPassword"
						:showIconLeft="false"
						formatType="compact"
						placeholder=""
						class="custom-input"
					/>
				</div>
				<div class="form-group">
					<BaseFormInputPassword
						v-model="confirmPassword"
						:valid.sync="isValidConfirmPassword"
						:showIconLeft="false"
						:matchWith="newPassword"
						formatType="compact"
						placeholder=""
						class="custom-input"
					/>
				</div>
				<BaseInfoList
					class="password-list-info"
					:list="infoList"
					:valid.sync="isValidFormat"
				>
					<template v-slot:title>
						In order to <b>protect your account</b>, your password must include:
					</template>
				</BaseInfoList>
				<button
					:disabled="disabledButton"
					type="button"
					class="btn btn-change-password"
					@click="onChangePassword"
				>
					Change Password
				</button>
			</form>
		</div>
		<div v-else class="box-notification-wrapper">
			<div class="box-notification-content">
				<div class="content">
					<font-awesome-icon class="icon" :icon="['fas', 'exclamation-circle']"/>
					<h1 class="change-password-title">The link has expired</h1>
					<p class="change-password-sub-title">
						The link you are trying to access has expired.
						Try going back to the
						<router-link
							:to="{ name: PAGE_NAME.LOGIN_PAGE }"
							class="btn-link"
						>
							login
						</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseFormInputPassword from "@/components/BaseFormInputPassword.vue";
import BaseInfoList from "@/components/BaseInfoList.vue";
import { getHasUpperCase, getHasLowwerCase, getHasNumber } from "../../selectors/helpers/regex";
import { TOAST_TYPES } from "../../enums/toast";
import { postVerifyTokenChangePassword } from "../../services/api/password.api";
import { PAGE_NAME } from "../../enums/pagePermission";


export default {
	name: "ModalChangePassword",

	components: {
		BaseFormInputPassword,
		BaseInfoList
	},

	data() {
		return {
			token: this.$route.query.token || "",
			isExpired: false,
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
			isValidCurrentPassword: null,
			isValidNewPassword: null,
			isValidConfirmPassword: null,
			isValidFormat: false,
			infoList: [
				{
					text: "8 characters minimum",
					active: false
				},
				{
					text: "1 number (0-9)",
					active: false
				},
				{
					text: "1 lowercase letter (a-z)",
					active: false
				},
				{
					text: "1 uppercase letter (A-Z)",
					active: false
				}
			],
			PAGE_NAME
		};
	},
	async created() {
		try {
			await postVerifyTokenChangePassword(this.token);
		} catch (e) {
			this.isExpired = true;
		}
	},
	computed: {
		...mapState(["customer"]),

		disabledButton() {
			return !this.isValidCurrentPassword
				|| !this.isValidNewPassword
				|| !this.isValidConfirmPassword
				|| !this.isValidFormat
				|| !this.isMatched;
		},

		isMatched() {
			return this.newPassword === this.confirmPassword;
		},

		currentPasswordErrorMessage() {
			return this.customer.customerChangeErrorMessage;
		},

		customerChangeErrorUnexpected() {
			return this.customer.customerChangeErrorUnexpected;
		}
	},

	watch: {
		newPassword(value) {
			this.infoList[0].active = value.length >= 8;
			this.infoList[1].active = getHasNumber(value);
			this.infoList[2].active = getHasLowwerCase(value);
			this.infoList[3].active = getHasUpperCase(value);
		},
		currentPassword() {
			if (this.currentPasswordErrorMessage.length > 0) {
				this.clearChangePasswordError();
			}
		}
	},

	methods: {
		...mapActions({
			showToast: "toast/show",
			putChangePassword: "customer/putChangePassword",
			clearChangePasswordError: "customer/clearChangePasswordError"
		}),

		async onChangePassword() {
			await this.putChangePassword({
				currentPassword: this.currentPassword,
				password: this.newPassword,
				passwordConfirmation: this.confirmPassword
			});

			const success = this.customer.customerChangePasswordSuccess;

			if (success) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Your password has changed successfully."
					}
				});
				setTimeout(() => {
					window.location = "/dashboard";
				}, 3000);
			}

			if (this.customerChangeErrorUnexpected) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: {
						title: this.customerChangeErrorUnexpected
					}
				});
			}
		},

		clearForm() {
			this.currentPassword = "";
			this.newPassword = "";
			this.confirmPassword = "";
			this.isValidCurrentPassword = null;
			this.isValidNewPassword = null;
			this.isValidConfirmPassword = null;
			this.isValidFormat = false;
			this.infoList[0].active = false;
			this.infoList[1].active = false;
			this.infoList[2].active = false;
			this.infoList[3].active = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.change-password-content {
	display: flex;
	align-items: center;

	.change-password-title,
	.change-password-sub-title {
		text-align: center;
	}

	.change-password-form {
		background-color: $color-white;
		padding: rem(40);
		width: 100%;
		max-width: rem(470);
		margin: 0 auto;
		border: solid rem(1) $color-silver;
		border-radius: rem(4);
	}

	.box-notification {
		&-wrapper {
			background-color: $color-white;
			width: 100%;
			max-width: rem(470);
			margin: 0 auto;
			border-radius: rem(4);
			min-width: rem(470);
			position: relative;

			&::before {
				content: " ";
				display: block;
				position: absolute;
				width: 100%;
				height: rem(12);
				background-color: $color-light-teal;
				border-radius: rem(4) rem(4) 0 0;
				top: 0;
				left: 0;
			}
		}

		&-content {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: white;
			padding: rem(40);
			border: 1px solid $color-silver;
			border-radius: rem(4) rem(4);
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: rem(-2);
		margin-bottom: rem(16);

		.change-password-title,
		.change-password-sub-title {
			margin: 0;
		}

		.btn-link {
			display: contents;
		}

		.icon {
			font-size: rem(70);
			color: $color-gunmetal-two;
		}
	}

	.change-password-title {
		color: $color-dark-blue-grey;
		margin-bottom: rem(16);
		font-weight: 600;
	}

	.password-list-info {
		margin-bottom: rem(24);
	}

	.change-password-sub-title {
		color: $color-dark-blue-grey;
		margin-top: rem(10);
		margin-bottom: rem(20);
		font-size: $font-24;
		word-break: break-word;
	}

	.btn-change-password {
		width: 100%;
		height: rem(48);
		background-color: $color-azure;
		color: $color-white;

		&:disabled {
			opacity: 0.5;
		}
	}

	.form-group {
		margin-bottom: rem(25);

		.form-control {
			height: rem(50);
		}
	}
		/* stylelint-disable */
	&::v-deep {
		.container-info {
			background-color: $color-white-smoke;
		}
	}
		/* stylelint-enable */
}

/* stylelint-disable */
// add amimation to working with `animationstart` event
.custom-input {
	:-webkit-autofill {
		animation-name: onAutoFillStart;
	}

	:not(:-webkit-autofill) {
		animation-name: onAutoFillCancel;
	}

	@keyframes onAutoFillStart {
		from {}
		to {}
	}

	@keyframes onAutoFillCancel {
		from {}
		to {}
	}
}
/* stylelint-enable */
</style>
