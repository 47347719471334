import standardModule from "../../views/StandardModule/List.vue";
import standardModuleCreate from "../../views/StandardModule/Create.vue";
import standardModuleEdit from "../../views/StandardModule/Edit.vue";
import StandardModulePage from "../../views/StandardModule/Index.vue";
import StandardModuleOverview from "../../views/StandardModule/Overview.vue";
import StandardModuleGrafana from "../../views/StandardModule/Grafana.vue";
import PowerBI from "../../views/PowerBI.vue";
import StandardModuleHistory from "../../views/StandardModule/History.vue";

import { PAGE_NAME } from "../../enums/pagePermission";

const {
	STANDARD_MODULE_LIST,
	STANDARD_MODULE_CREATE,
	STANDARD_MODULE_EDIT,
	STANDARD_MODULE_PAGE,
	STANDARD_MODULE_OVERVIEW,
	STANDARD_MODULE_ANALYSIS,
	STANDARD_MODULE_HISTORY,
	STANDARD_MODULE_GRAFANA,
	DASHBOARD

} = PAGE_NAME;

export default [
	{
		path: "/standard-module/list",
		name: STANDARD_MODULE_LIST,
		component: standardModule,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/standard-module/create",
		name: STANDARD_MODULE_CREATE,
		component: standardModuleCreate,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/standard-module/:id(\\d+)",
		name: STANDARD_MODULE_EDIT,
		component: standardModuleEdit,
		meta: {
			requireAuth: true
		}
	},
	{
		path: "/standard-module/:app/:templateId(\\d+)/:id(\\d+)",
		name: STANDARD_MODULE_PAGE,
		component: StandardModulePage,
		children: [
			{
				path: "overview",
				name: STANDARD_MODULE_OVERVIEW,
				component: StandardModuleOverview,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "history",
				name: STANDARD_MODULE_HISTORY,
				component: StandardModuleHistory,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "analysis",
				name: STANDARD_MODULE_ANALYSIS,
				component: PowerBI,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			},
			{
				path: "grafana",
				name: STANDARD_MODULE_GRAFANA,
				component: StandardModuleGrafana,
				meta: {
					backTo: {
						name: DASHBOARD
					},
					requireAuth: true
				}
			}
		],
		meta: {
			requireAuth: true
		}
	}
];