import { getGraphOptions, getApiTagNameOptions, getStandardModuleEditAPI, getApplicationOptions, updateStandardModuleAPI } from "@/services/api/standardModule.api";
import { parseGraphOptions, parseEditStandardModule } from "../../../services/api/transforms/parseModules";
import {
	GRAPH_OPTIONS_REQUEST,
	GRAPH_OPTIONS_SUCCESS,
	GRAPH_OPTIONS_FAILURE,
	API_TAG_NAME_OPTIONS_REQUEST,
	API_TAG_NAME_OPTIONS_SUCCESS,
	API_TAG_NAME_OPTIONS_FAILURE,
	APPLICATION_OPTIONS_REQUEST,
	APPLICATION_OPTIONS_SUCCESS,
	APPLICATION_OPTIONS_FAILURE,
	STANDARD_MODULE_EDIT_REQUEST,
	STANDARD_MODULE_EDIT_SUCCESS,
	STANDARD_MODULE_EDIT_FAILURE,
	STANDARD_MODULE_UPDATE_REQUEST,
	STANDARD_MODULE_UPDATE_SUCCESS,
	STANDARD_MODULE_UPDATE_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		isLoading: false,
		isGraphOptionsLoading: false,
		isEditStdModuleTemplateLoading: false,
		isSuccessUpdateStdModuleTemplate: false,
		isSuccessUpdateStdModuleTemplateData: [],
		data: parseEditStandardModule(),
		graphOptions: [],
		apiTagNameOptions: [],
		applicationOptions: []
	},

	mutations: {
		[GRAPH_OPTIONS_REQUEST](state) {
			state.isGraphOptionsLoading = true;
			state.graphOptions = [];
		},
		[GRAPH_OPTIONS_SUCCESS](state, options) {
			state.graphOptions = options;
			state.isGraphOptionsLoading = false;
		},
		[GRAPH_OPTIONS_FAILURE](state) {
			state.graphOptions = [];
			state.isGraphOptionsLoading = false;
		},
		[API_TAG_NAME_OPTIONS_REQUEST](state) {
			state.apiTagNameOptions = [];
		},
		[API_TAG_NAME_OPTIONS_SUCCESS](state, options) {
			state.apiTagNameOptions = options.data;
		},
		[API_TAG_NAME_OPTIONS_FAILURE](state) {
			state.apiTagNameOptions = [];
		},
		[APPLICATION_OPTIONS_REQUEST](state) {
			state.applicationOptions = [];
		},
		[APPLICATION_OPTIONS_SUCCESS](state, options) {
			state.applicationOptions = options;
		},
		[APPLICATION_OPTIONS_FAILURE](state) {
			state.applicationOptions = [];
		},
		[STANDARD_MODULE_EDIT_REQUEST](state) {
			state.isLoading = true;
		},
		[STANDARD_MODULE_EDIT_SUCCESS](state, { data }) {
			state.isLoading = false;
			state.data = parseEditStandardModule(data);
		},
		[STANDARD_MODULE_EDIT_FAILURE](state) {
			state.isLoading = false;
		},
		[STANDARD_MODULE_UPDATE_REQUEST](state) {
			state.isEditStdModuleTemplateLoading = true;
			state.isSuccessUpdateStdModuleTemplate = false;
			state.isSuccessUpdateStdModuleTemplateData = [];
		},
		[STANDARD_MODULE_UPDATE_SUCCESS](state, { status, data }) {
			state.isSuccessUpdateStdModuleTemplate = status === 204 || status === 200;
			state.isSuccessUpdateStdModuleTemplateData = data?.devicesDuplicate || [];
			state.isEditStdModuleTemplateLoading = false;
		},
		[STANDARD_MODULE_UPDATE_FAILURE](state) {
			state.isEditStdModuleTemplateLoading = false;
		}
	},

	actions: {
		async updateStandardTemplate({ commit }, params = {}) {
			try {
				commit(STANDARD_MODULE_UPDATE_REQUEST);
				const data = await updateStandardModuleAPI(params.id, params);
				commit(STANDARD_MODULE_UPDATE_SUCCESS, data);
			} catch (error) {
				commit(STANDARD_MODULE_UPDATE_FAILURE);
			}
		},
		async getStandardTemplateEdit({ commit }, id) {
			try {
				commit(STANDARD_MODULE_EDIT_REQUEST);
				const data = await getStandardModuleEditAPI(id);
				commit(STANDARD_MODULE_EDIT_SUCCESS, data);
			} catch (error) {
				commit(STANDARD_MODULE_EDIT_FAILURE);
			}
		},

		async getGraphOptions({ commit }, params = {}) {
			try {
				commit(GRAPH_OPTIONS_REQUEST);
				const { data } = await getGraphOptions(params);
				commit(GRAPH_OPTIONS_SUCCESS, parseGraphOptions(data.data));
			} catch (error) {
				commit(GRAPH_OPTIONS_FAILURE);
			}
		},

		async getApiTagNameOptions({ commit }, params = {}) {
			try {
				commit(API_TAG_NAME_OPTIONS_REQUEST);
				const { data } = await getApiTagNameOptions(params);
				commit(API_TAG_NAME_OPTIONS_SUCCESS, data);
			} catch (error) {
				commit(API_TAG_NAME_OPTIONS_FAILURE);
			}
		},

		async getApplicationOptions({ commit }, params = {}) {
			try {
				commit(APPLICATION_OPTIONS_REQUEST);
				const { data } = await getApplicationOptions(params);
				commit(APPLICATION_OPTIONS_SUCCESS, data);
			} catch (error) {
				commit(APPLICATION_OPTIONS_FAILURE);
			}
		}
	}
};
