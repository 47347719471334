<template>
	<modal :name="modalName" :scrollable="true" width="420" height="auto" @before-close="handleCloseModal"  @before-open="beforeOpen">
		<BaseModalContent>
			<template v-slot:header>
				{{ header }}
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div :class="['container-form', { active: state.dropdownShipToIdActive }]">
					<div class="item">
						<div class="form">
							<BaseDropdown
								:searchable="false"
								:list="moduleList"
								:value="moduleSelected"
								:dropdownListContainerHeight="100"
								class="customer-create-input"
								label="Module"
								inline
								@input="handleSelectModule"
								@onActiveChange="handleActiveChange"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					:disabled="isDisabledContinue"
					size="large"
					@click="handleSubmit"
				>Continue</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	name: "ModalCreateCustomisation",

	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},

		header: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			moduleSelected: "",
			moduleList: [],
			state: {
				dropdownShipToIdActive: false
			}
		};
	},

	computed: {
		isDisabledContinue() {
			return !this.moduleSelected;
		}
	},
	methods: {
		beforeOpen(event) {
			const { moduleList } = event.params;
			this.moduleList = moduleList;
			this.state.dropdownShipToIdActive = false;
		},

		handleSelectModule(value) {
			this.moduleSelected = value;
		},

		handleActiveChange(isActive) {
			this.state.dropdownShipToIdActive = isActive;
		},

		handleSubmit() {
			this.$emit("onSubmit", this.moduleSelected);
		},
		handleCloseModal() {
			this.moduleSelected = "";
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	&.active {
		margin-bottom: rem(40);
	}

	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			align-items: center;
			min-width: rem(120);
		}

		.form {
			flex-grow: 1;
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}

.notify-error {
	margin-bottom: $spacing-6;
}
</style>