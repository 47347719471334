<template>
	<BaseLayout pageTitle="Sold-to management">
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:companies="companiesOptions"
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>
			<div class="row container-list">
				<SoldToList
					:loading="isLoading"
					:items="data"
					@powerBISave="handlePowerBISave"
				/>
				<BasePagination
					v-if="data.length > 0"
					:value="pagination.currentPage"
					:totalPage="pagination.lastPage"
					class="module-pagination"
					@onPageChange="handlePageChange"
				/>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SearchFilterForm from "@/components/SoldToManagement/SearchFilterForm.vue";
import SoldToList from "@/components/SoldToManagement/SoldToList.vue";
import { cleanParams } from "../../selectors/helpers/";
import { putSoldToManagementAPI } from "../../services/api/soldToManagement.api";
import { TOAST_TYPES } from "../../enums/toast";
import { getCompaniesListAPI } from "../../services/api/companies";
import {
	getCompaniesOptions
} from "../../selectors/helpers/dashboard";

export default {
	name: "soldToManagementList",

	components: {
		SearchFilterForm,
		SoldToList
	},

	data() {
		return {
			query: {
				page: this.$route.query.page || null,
				company: Number(this.$route.query.company) || null
			},
			companiesList: []
		};
	},

	computed: {
		...mapState("soldToManagement", {
			isLoading: "isLoading",
			data: "data",
			pagination: "pagination"
		}),
		companiesOptions() {
			return getCompaniesOptions(this.companiesList);
		}
	},

	async created() {
		await this.fetchData();
		await this.fetchCompaniesList();
		this.addActivityLogSoldToManagement();
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions("soldToManagement", {
			getSoldToManagement: "getSoldToManagement"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogSoldToManagement: "addActivityLogSoldToManagement"
		}),
		updateUrl() {
			const query = cleanParams(JSON.parse(JSON.stringify(this.query)));
			this.$router.push({ query }).catch(() => {});
		},
		async fetchData() {
			this.updateUrl();
			return this.getSoldToManagement(this.query);
		},
		handleSubmitFilter(companyId) {
			this.query.company = companyId;
			this.query.page = 1;
			this.fetchData();
		},
		handlePageChange(pageNum = 1) {
			this.query.page = pageNum;
			this.fetchData();
		},
		async fetchCompaniesList() {
			const companies = await getCompaniesListAPI();
			this.companiesList = companies.data;
		},
		async handlePowerBISave({ id, workspaceId, reportId }) {
			const response = await putSoldToManagementAPI({ id, workspaceId, reportId });
			if (response?.status === 204) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: { title: "Saved workspace & report ID", message: "You can edit workspace & report ID in this page.", delay: 6000 }
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container-list {
	padding-left: $padding-base;
	padding-right: $padding-base;

	.module-pagination {
		display: flex;
		justify-content: flex-end;
	}
}
</style>
