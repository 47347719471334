import dayjs from "dayjs";
import { transformLineChart } from "../../lineChart";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachDay,
	getForEachSixHours,
	getForEachHour,
	getForEachThirtyMinute,
	getForEachTwoHours
} from "../../lineChart/helper";
import { getConfigDataZoom } from "../../lineChart/helper/zoomConfig";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Dissolve oxygen (ppm)`;

const tooltipO2AerationOxygenDissolveLineChartFormat = (name, value) => {
	const format = `
	<div>
		<div class="bar-name">
			${name}
		</div>
		<div class="bar-value">
			${value === null ? "-" : value} ppm
		</div>
	</div>`;
	return format;
};


const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipO2AerationOxygenDissolveLineChartFormat(date, pointValue.data);
};


const optionLineChartXaxisPerDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachTwoHours
	},
	...getConfigDataZoom(xAxis),
	axisTick: {
		interval: getForEachThirtyMinute
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	grid: {
		left: "58px",
		right: "80px",
		bottom: 88,
		containLabel: true
	}
});

const optionLineChartXaxisForThreeDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachFourHours
	},
	axisTick: {
		interval: getForEachHour
	},
	...getConfigDataZoom(xAxis),

	tooltip: {
		formatter: tooltipFormatter
	},
	grid: {
		left: "58px",
		right: "80px",
		bottom: 88,
		containLabel: true
	}
});

const optionLineChartXaxisPerWeek = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	axisTick: {
		interval: getForEachSixHours
	},
	...getConfigDataZoom(xAxis),

	tooltip: {
		formatter: tooltipFormatter
	},
	grid: {
		left: "58px",
		right: "80px",
		bottom: 88,
		containLabel: true
	}
});

export const transformO2AerationOxygenDissolveLineChart = (sortBy = perDay, data = []) => {
	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay(data),
		[perThreeDay]: optionLineChartXaxisForThreeDay(data),
		[perWeek]: optionLineChartXaxisPerWeek(data)
	};
	const transformFunc = transformLineChart(mappingOption[sortBy]);
	return transformFunc(data);
};
