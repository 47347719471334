import { getShipToStatusAPI } from "@/services/api/shiptoStatus.api";
import { SHIPTO_STATUS_REQUEST, SHIPTO_STATUS_SUCCESS, SHIPTO_STATUS_FAILURE } from "../../types";
import { transformShipToStatuses } from "../../../selectors/transform/shiptoStatus/transformShipToStatus";

export default {
	namespaced: true,

	state: {
		data: transformShipToStatuses([]),
		isLoading: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[SHIPTO_STATUS_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
		},
		[SHIPTO_STATUS_SUCCESS](state, { data, pagination }) {
			state.data = transformShipToStatuses(data);
			state.pagination = pagination;
			state.isLoading = false;
		},
		[SHIPTO_STATUS_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		}
	},

	actions: {
		async getShiptoStatus({ commit }, params = {}) {
			try {
				commit(SHIPTO_STATUS_REQUEST);
				const { data } = await getShipToStatusAPI(params);
				commit(SHIPTO_STATUS_SUCCESS, data);
			} catch (error) {
				commit(SHIPTO_STATUS_FAILURE);
			}
		}
	}
};
