<template>
	<div class="air-comp-info">
		<BaseLoading v-if="standardModulePage.isLoading" />
		<div :class="['row row-eq-height', { 'hide-content': standardModulePage.isLoading }]">
			<div class="col-12 no-gutter-sm col-xl-7">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ standardModulePage.data.lastUpdate }}
						</div>
					</template>
					<template v-slot:body>
						<StandardOverview
							v-if="standardModulePage.data.id && !standardModulePage.isLoading && standardModulePage.data.overview !== null"
							:data="standardModulePage.data"
						/>
						<BaseNoData
							v-else
							class="base-no-data"
							description="BIG portal will start showing the overview right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
			<div class="col-12 no-gutter-sm col-xl-5">
				<BaseCard>
					<template v-slot:header>
						<h4>Customer information</h4>
					</template>
					<template v-slot:body>
						<div v-if="standardModulePage.data.customerInfo !== null" class="overview-values">
							<div
								v-for="(item,key) in standardModulePage.data.customerView"
								:key="key"
								class="overview-info"
							>

								<p class="title">
									<component v-if="item.icon"  :is="item.icon" class="title-icon" />
									<span v-html="item.title" class="html"></span>
								</p>
								<p class="value">
									<span :title="item.value"  v-html="item.value" class="html"></span>
								</p>
							</div>
						</div>
						<BaseNoData
							v-else
							class="base-no-data"
							description="BIG portal will start showing the customer information right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
		</div>
		<GrafanaDisplay
			:deviceId="deviceId"
			:showNoData="false"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import StandardOverview from "@/components/StandardModule/StandardOverview.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BuildingIcon from "../../components/icon/BuildingIcon.vue";
import AtIcon from "../../components/icon/AtIcon.vue";
import IndustryWindowsIcon from "../../components/icon/IndustryWindowsIcon.vue";
import LocationDotIcon from "../../components/icon/LocationDotIcon.vue";
import PhoneIcon from "../../components/icon/PhoneIcon.vue";
import UserHeadsetIcon from "../../components/icon/UserHeadsetIcon.vue";

export default {
	name: "StandardModuleOverview",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseNoData,
		BaseCard,
		BuildingIcon,
		AtIcon,
		IndustryWindowsIcon,
		LocationDotIcon,
		PhoneIcon,
		UserHeadsetIcon,
		StandardOverview
	},

	data() {
		return {
			deviceId: this.$route.params.id
		};
	},

	computed: {
		...mapState(["standardModulePage"])
	}
};
</script>

<style lang="scss" scoped>
.container-filter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: rem(34);
	gap: rem(16);
}

.airComp-info {
	::v-deep {
		.chart-title {
			position: relative;
			left: initial;

			margin-top: rem(24);

			transform: initial;
			-webkit-transform: initial;
		}
	}
}

.card {
	width: 100%;
	height: calc(100% - rem(34));
}

.overview {
	&-container {
		display: flex;
		width: 100%;
	}

	&-info {
		display: flex;
		justify-content: space-between;
		margin-bottom: rem(10);

		p {
			margin: 0 0 rem(12);

			display: flex;
			flex-direction: row;
			gap: rem(16);
			justify-content: flex-start;

			color: $color-dark-blue-grey;

			&:nth-child(even) {
				display: flex;
				font-weight: 600;
			}

			span {
				overflow-wrap: anywhere;
				text-align: end;
			}
		}
	}

	&-info:last-of-type {
		p {
			margin: 0;
		}
	}

	&-values {
		flex: 1;

		.title {
			flex: none;
			width: 30%;

			svg {
				width: rem(18);
				height: rem(18);
			}

			&.-has-icon {
				width: 40%;
				text-align: right;
				margin-bottom: 0;
			}

			.title-icon {
				font-size: $font-26;
			}
		}

		.value {
			&.-has-bar-g {
				width: 50%;
				display: flex;

				.html {
					width: rem(50);

					text-align: right;
				}

				::v-deep {
					.bar-g-container {
						width: calc(100% - 50px);

						.bar-g-chart {
							width: 100%;
						}
					}
				}
			}

			.progress-bar {
				width: rem(96);
				position: relative;
				top: rem(2);
				margin-left: rem(8);
			}
		}

		.overview-group {

			// overview-group-header
			&-header {
				margin: rem(8) 0;
				padding: rem(4) rem(8);

				font-size: $font-18;
				font-weight: bold;

				background-color: $color-off-white;
			}

			//overview-group-header
			&-body {
				padding: 0 rem(8);
			}
		}

		.overview-attachment {
			margin-top: rem(8);
			padding: rem(12) rem(8) rem(8) rem(8);
			border-top: 1px solid $color-ice;

			.view-button {
				.icon-file {
					margin-right: 2px;
					font-size: rem(14);
				}
			}
		}
	}

	&-bar {
		min-width: rem(144);
		margin-right: rem(16);
		padding: rem(8);
		/* stylelint-disable */
		&::v-deep {
			.nitro-fas-wrapper {
				width: rem(128);
				height: rem(128);
			}
		}
		/* stylelint-enable */
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.base-no-data {
	height: rem(250);
}

</style>
