<template>
	<BaseHeadColumn show-line-bottom class="order-head-column">
		<template #first-column>
			<div></div>
		</template>
		<template #rest-column>
			<div class="off-peak">
				<FontAwesomeIcon :icon="['fas', 'circle']" />
				<strong>Off-Peak</strong>
			</div>
			<div class="partial-peak">
				<FontAwesomeIcon :icon="['fas', 'circle']" />
				<strong>Partial-Peak</strong>
			</div>
			<div class="on-peak">
				<FontAwesomeIcon :icon="['fas', 'circle']" />
				<strong>On-Peak</strong>
			</div>
			<div class="demand-peak">
				<FontAwesomeIcon :icon="['fas', 'circle']" />
				<strong>Demand Peak</strong>
			</div>
			<div class="reactive-factor">
				<FontAwesomeIcon :icon="['fas', 'circle']" />
				<strong>Reactive Factor</strong>
			</div>
		</template>
	</BaseHeadColumn>
</template>

<script>
import BaseHeadColumn from "@/components/BaseHeadColumn.vue";

export default {
	components: {
		BaseHeadColumn
	}
};
</script>

<style lang="scss" scoped>
.order-head-column {
	padding: rem(12) 0 rem(12) rem(18);
	margin-bottom: $spacing-6;

	/* stylelint-disable */
	&::v-deep .first-column {
		width: rem(200);
	}

	&::v-deep .rest-column {
		width: auto;
		flex: 1;
	}
	/* stylelint-enable */

	.off-peak svg {
		color: $color-silver-sand;
	}

	.partial-peak svg {
		color: $color-azure;
	}

	.on-peak svg {
		color: $color-green-haze;
	}

	.demand-peak svg {
		color: $color-marigold;
	}

	.reactive-factor svg {
		color: $color-lipstick;
	}

	/* stylelint-disable */
	&::v-deep .rest-column {
		background-color: $color-ice-two;
	}
}
</style>