import { getBhyOrderHistoriesAPI } from "@/services/api/bulkHydrogen.api";
import {
	BHY_ORDER_HISTORIES_REQUEST,
	BHY_ORDER_HISTORIES_SUCCESS,
	BHY_ORDER_HISTORIES_FAILURE,
	BHY_ORDER_HISTORIES_CLEAR
} from "../../types";

import { parseBhyHistory } from "../../../services/api/transforms/parseBhyModules";

export default {
	state: {
		data: [],
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[BHY_ORDER_HISTORIES_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.statusCode = null;
		},
		[BHY_ORDER_HISTORIES_SUCCESS](state, response) {
			const { data, pagination } = response.data;
			state.data = parseBhyHistory(data);
			state.pagination = pagination;
			state.isEmpty = data.length < 1;
			state.isLoading = false;
			state.statusCode = response.status;
		},
		[BHY_ORDER_HISTORIES_FAILURE](state, error) {
			state.data = [];
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[BHY_ORDER_HISTORIES_CLEAR](state) {
			state.data = [];
			state.loading = false;
			state.isEmpty = false;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		}
	},

	actions: {
		async getBhyOrderHistories({ commit }, { id, page }) {
			try {
				commit(BHY_ORDER_HISTORIES_REQUEST);

				const response = await getBhyOrderHistoriesAPI(id, { page });

				commit(BHY_ORDER_HISTORIES_SUCCESS, response);
			} catch (error) {
				commit(BHY_ORDER_HISTORIES_FAILURE, error);
			}
		},
		clearBhyHistory({ commit }) {
			commit(BHY_ORDER_HISTORIES_CLEAR);
		}
	}
};
