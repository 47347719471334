import Numeral from "numeral";
import { M_UNIT } from "../../unit";
import { transformBarChart } from "..";

const optionOutletRecycleWater = {
	yAxisText: `Outlet recycle water (${M_UNIT})`,
	series: {
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#103332",
			axisPointer: {
				type: "none"
			},
			padding: 8,
			formatter: ([{ value, name }]) => {
				return `
                    <div>
                        <div class="bar-name meter">
                            ${name}
                        </div>
                        <div class="bar-value">
                            ${Numeral(value).format(0, 0)} M<sup>3</sup>
                        </div>
                    </div>
                `;
			}
		}
	}
};
export const transformOutletRecycleWater = transformBarChart(optionOutletRecycleWater);
