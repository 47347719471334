import axios from "axios";

const azureHttp = axios.create({
	baseURL: process.env.VUE_APP_AZURE_AUTHORIZE_ENDPOINT,
	timeout: 60000,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		"Access-Control-Allow-Origin": "http://localhost"
	}
});


export default azureHttp;

