import { isNumeric } from "echarts/lib/util/number";
import { NM_UNIT } from "../unit";

const compareValues = (key, order = "asc") => {
	return function innerSort(a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			// property doesn't exist on either object
			return 0;
		}

		const beforeValue = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
		const afterValue = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (beforeValue > afterValue) {
			comparison = 1;
		} else if (beforeValue < afterValue) {
			comparison = -1;
		}

		return order === "desc" ? comparison * -1 : comparison;
	};
};

export const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

export const getAllHeaderKeyWithOutNull = (Obj) => {
	return Object.keys(Obj).filter((key) => key !== "total" && key !== "date" && !key.includes("Percent") && Obj[key] !== null);
};

export const getAllHeaderKeyWithNull = (Obj) => {
	return Object.keys(Obj).filter((key) => key !== "total" && key !== "date" && !key.includes("Percent"));
};

const mapBodyValues = (current, allHeaderAvaiableShow = []) => (mapValue) => {
	const value = current[`${mapValue}Percent`];
	if (allHeaderAvaiableShow.includes(mapValue)) {
		return {
			// check allHeader type include in allHeaderAvaiableShow
			value: isNumeric(current[mapValue]) ? current[mapValue] : "-",
			percentage: isNumeric(value) ? value : "-"
		};
	}

	return false;
};

const mapFooterPercentage = (current, allHeaderAvaiableShow = []) => (mapValue) => {
	const mappingColor = {
		base1: "base-1",
		base2: "base-2",
		base3: "base-3",
		premium1: "premium-1",
		premium2: "premium-2",
		premium3: "premium-3"
	};

	if (allHeaderAvaiableShow.includes(mapValue)) {
		return {
			// check allHeader type include in allHeaderAvaiableShow
			value: `${current[`${mapValue}Percent`]}%` || "0%",
			color: mappingColor[mapValue]
		};
	}
	return false;
};

const reduceAllHeaderAvaiableToShow = (acc, current) => {
	const allHeader = getAllHeaderKeyWithOutNull(current);

	return [...acc, ...allHeader];
};

export const transformMeterHistoryItems = (response) => {
	const { data = [] } = response;

	const allHeaderAvaiableShow = data
		.reduce(reduceAllHeaderAvaiableToShow, [])
		.sort()
		.filter(onlyUnique);


	return data.reduce((acc, current) => {
		// allHeaderAviableShow will contain array of header list to show ex. ['base1',base2',base3']

		const allHeader = getAllHeaderKeyWithNull(current);
		//
		const bodyValues = allHeader.map(mapBodyValues(current, allHeaderAvaiableShow));

		const footerPercentage = allHeader.map(mapFooterPercentage(current, allHeaderAvaiableShow));

		return [
			...acc,
			{
				body: {
					date: current.date,
					values: bodyValues.filter((value) => value)
				},

				footer: {
					total: `${current.total}`,
					percentages: footerPercentage.filter((value) => value)
				}
			}
		];
	}, []);
};

export const transformMeterHistoryHeader = (response) => {
	const { data = [] } = response;
	const mapMeterHistory = {
		base1: {
			text: `Base1 (${NM_UNIT})`,
			status: "base-1"
		},
		base2: {
			text: `Base2 (${NM_UNIT})`,
			status: "base-2"
		},
		base3: {
			text: `Base3 (${NM_UNIT})`,
			status: "base-3"
		},
		premium1: {
			text: `Premium1 (${NM_UNIT})`,
			status: "premium-1"
		},
		premium2: {
			text: `Premium2 (${NM_UNIT})`,
			status: "premium-2"
		},
		premium3: {
			text: `Premium3 (${NM_UNIT})`,
			status: "premium-3"
		}
	};
	// TODO: ask transformAllHistoryHeader can be some object is not same before

	const transformAllHistoryHeader = data.reduce((acc, current) => {
		const historyType = getAllHeaderKeyWithOutNull(current);
		return [...acc, ...historyType.map((key) => mapMeterHistory[key])];
	}, []);
	return transformAllHistoryHeader.filter(onlyUnique).sort(compareValues("text", "asc"));
};
