<template>
	<header>
		<h2>
			<div
				class="device-selection"
			>
				<div
					v-if="isStandardModule"
				>
					{{ data.productName | capitalize }}
					<small>
						{{ data.deviceCustomerName ? `(${data.deviceCustomerName})`: `(${data.shipId})` }}
					</small>
				</div>
				<div
					v-else-if="data.type === MODULE_TYPE_PROCESS"
				>
					{{ data.name | capitalize }}
					<small>
						{{ data.deviceCustomerName ? `(${data.deviceCustomerName})`: `(${data.tagNo})` }}
					</small>
				</div>
				<div
					v-else-if="data.type !== MODULE_TYPE_NITRO_FAS"
				>
					{{ data.name | capitalize }}
					<small>
						{{ data.deviceCustomerName ? `(${data.deviceCustomerName})`: `(${data.shipId})` }}
					</small>
				</div>
				<div
					v-else
				>
					{{ data.name }}
					<small>
						{{ data.deviceCustomerName ? `(${data.deviceCustomerName})`: `${data.shipId}` }}
					</small>
				</div>
			</div>
		</h2>
		<BaseTag
			v-if="status"
			:class="tagClass"
		>
			{{ status }}
		</BaseTag>
	</header>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { MODULE_TYPES, MODULE_DEVICE_STATUSES } from "../../../enums/modules";

export default {
	name: "ModuleHeaderExample",

	props: {
		data: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			MODULE_DEVICE_STATUSES,
			MODULE_TYPES,
			MODULE_TYPE_NITRO_FAS: MODULE_TYPES.NITRO_FAS,
			MODULE_TYPE_PROCESS: MODULE_TYPES.FURNACE,
			isOpenDeviceSelection: false
		};
	},

	computed: {
		...mapState(["devices"]),
		shipToId() {
			return this.data.shipToId || null;
		},
		status() {
			if (this.data.deliveryStatus) {
				return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].title;
			} else if (this.data.status) {
				return MODULE_DEVICE_STATUSES[this.data.status].title;
			}
			return null;
		},
		tagClass() {
			if (this.data.deliveryStatus) {
				return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].tagClass;
			} else if (this.data.status) {
				return MODULE_DEVICE_STATUSES[this.data.status].tagClass;
			}
			return null;
		},
		deviceList() {
			const isCurrentShipToId = this.devices.isLoadedId === this.shipToId;
			const data = isCurrentShipToId ? this.devices.data : [];
			return data.filter((device) => device.id !== Number(this.$route.params.id));
		},
		empty() {
			return this.deviceList.length === 0;
		},
		isStandardModule() {
			return this.data.templateId;
		}
	},

	mounted() {
		this.$emit("update:fetch", this.fetchData);
	},

	methods: {
		...mapActions({
			getDevices: "devices/getDevices"
		}),

		fetchData() {
			const isLoaded = this.devices.isLoadedId === this.shipToId;
			if (!isLoaded) {
				this.getDevices({ id: this.shipToId });
			}
		}
	}
};
</script>


<style lang="scss" scoped>
header {
	display: flex;
	align-items: center;
	margin-top: rem(16);
}

h2 {
	color: $color-h2;
	font-size: $font-48;
	line-height: 1;

	div {
		color: $color-h2;
		display: flex;
		align-items: center;

		[data-icon="angle-down"] {
			color: $color-arrow-blue;
			font-size: $font-26;
		}

		small {
			color: $color-small;
			font-size: $font-20;
			margin: 0 rem(16) 0 rem(8);
			position: relative;
			top: rem(4);
		}
	}
}

.device-selection {
	position: relative;

	.device-options {
		display: none;
		position: absolute;
		left: 0;
		width: rem(240);
		max-height: rem(320);
		overflow-x: scroll;
		padding: rem(8) 0;
		border-radius: 4px;
		border: 1px solid $color-silver;
		background-color: $color-white;
		z-index: 4;
	}

	.device-option {
		a {
			display: block;
			padding: rem(4) rem(16) rem(8);
			color: $color-dark-blue-grey;
			font-size: $font-24;
			font-weight: 400;

			&:hover {
				background-color: $color-ice-two;
			}

			span {
				font-size: $font-16;
				color: $color-grey-4;
			}
		}
	}

	&.active {
		.device-options {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}

	.fa-chevron-circle-down {
		color: $color-cerulean;
		font-size: rem(16);
		transition: 0.3s transform;
	}
}

.spinner {
	width: rem(14);
	height: rem(14);
	color: $color-grey-3;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
</style>
