import Numeral from "numeral";
import { transformBarChart } from "..";

export const transformMergeStaffAndCustomerData = (staff = [], customer = []) => {
	const result = [];
	for (let i = 0; i < staff.length; i++) {
		result.push({
			staffVal: staff[i].value,
			customerVal: customer[i].value
		});
	}
	return result;
};

const optionSigninBarChart = {
	all: {
		yAxisText: `Sign-in session`,
		grid: {
			left: "24px",
			right: "24px",
			bottom: "48px",
			containLabel: true
		},
		legend: {
			show: true,
			data: ["BIG Staff", "Customer"],
			icon: "circle",
			bottom: 0,
			left: 24,
			itemWidth: 10,
			itemHeight: 10,
			itemGap: 24,
			fontFamily: "TFSrivichai",
			fontSize: 16,
			fontColor: "#71717",
			fontWeight: "bold"
		},
		series: {
			tooltip: {
				formatter: ({ data }) => `
			<div class="sign-in-chart">
				<div class="total-section">
					<div>
						Total
					</div>
					<div>
						${Numeral(data.customerVal + data.staffVal).format(0, 0)}
					</div>
				</div>
				<div class="staff-section">
					<div>
					BIG staff
					</div>
					<div>
						${Numeral(data.staffVal).format(0, 0)}
					</div>
				</div>
				<div class="customer-section">
					<div>
						Customer
					</div>
				<div>
						${Numeral(data.customerVal).format(0, 0)}
				</div>
				</div>
			</div>`
			}
		}
	},
	staff: {
		grid: {
			left: "24px",
			right: "24px",
			bottom: "48px",
			containLabel: true
		},
		legend: {
			show: true,
			data: ["BIG Staff", "Customer"],
			icon: "circle",
			bottom: 0,
			left: 24,
			itemWidth: 10,
			itemHeight: 10,
			itemGap: 24
		},
		yAxisText: `Sign-in session`,
		series: {
			tooltip: {
				formatter: ({ data }) => `
			<div class="sign-in-chart">
				<div class="total-section">
					<div>
						BIG staff
					</div>
					<div>
						${Numeral(data.staffVal).format(0, 0)}
					</div>
				</div>
			</div>`
			}
		}
	},
	customer: {
		grid: {
			left: "24px",
			right: "24px",
			bottom: "48px",
			containLabel: true
		},
		legend: {
			show: true,
			data: ["BIG Staff", "Customer"],
			icon: "circle",
			bottom: 0,
			left: 24,
			itemWidth: 10,
			itemHeight: 10,
			itemGap: 24
		},
		yAxisText: `Sign-in session`,
		series: {
			tooltip: {
				formatter: ({ data }) => `
			<div class="sign-in-chart">
				<div class="total-section">
					<div>
						Customer
					</div>
					<div>
						${Numeral(data.customerVal).format(0, 0)}
					</div>
				</div>
			</div>`
			}
		}
	}
};


export const transformSignInBarChart = (data, showData = "all") => {
	return transformBarChart(optionSigninBarChart[showData])(data);
};
