<template>
	<div :class="['container-autocomplate', { 'open': isShowDropdown && isFocus }]">
		<BaseFormInput
			v-model="input"
			:type="type"
			:inputOptions="inputOptions"
			:placeholder="placeholder"
			:label="label"
			:is-error="isError"
			:error-message="errorMessage"
			:is-loading="isLoading"
			:inline="inline"
			:disabled="disabled"
			@input="handleInput"
			@focus="handleInputFocus"
			@blur="handleInputBlur"
		>
			<template v-slot:loading>
				<slot name="loading"><FontAwesomeIcon :icon="['fas', 'spinner']" /></slot>
			</template>
		</BaseFormInput>
		<div v-if="!disabled" :style="dropDownListContainerStyle" :class="['container-dropdown-list',{'in-line': inline}]">
			<ul class="list">
				<li
					v-for="({ label, value, html }, index) in filterList" :key="index"
					:class="[{active: index == 0}]"
					class="item"
					@mousedown="selectedItem(label, value)"
				>
					<div v-html="html"></div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseFormInputAutoComplete",

	props: {
		inputOptions: {
			type: Array,
			default: () => ([])
		},
		value: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},

		label: {
			type: String,
			default: ""
		},

		inline: {
			type: Boolean,
			default: false
		},

		type: {
			type: String,
			default: "text"
		},
		isError: {
			type: Boolean,
			default: false
		},
		errorMessage: {
			type: String,
			default: ""
		},
		list: {
			type: Array,
			default: () => ([])
		},
		dropdownListContainerHeight: {
			type: Number,
			default: 154
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			input: "",
			selected: "",
			isShowDropdown: false,
			isFocus: false
		};
	},

	computed: {
		filterList() {
			const input = this.input;
			const filter = this.list.filter(({ label }) => label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) !== -1);
			return filter.map((obj, index) => {
				return {
					...obj,
					index,
					html: this.highlight(obj.label, input)
				};
			});
		},

		dropDownListContainerStyle() {
			return { "max-height": `${this.dropdownListContainerHeight}px` };
		}
	},

	watch: {
		input() {
			this.isShowDropdown = this.filterList.length > 0;
		},

		list() {
			this.isShowDropdown = this.filterList.length > 0;
		},
		value(value) {
			this.input = value;
		}
	},

	methods: {
		selectedItem(label, value) {
			this.input = label;
			this.selected = value;
			this.$emit("selected", {
				label: this.input,
				value: this.selected
			});
			this.isShowDropdown = false;
		},

		handleInput() {
			this.$emit("input", this.input);
			this.$emit("changed", this.input);
		},

		handleInputFocus() {
			this.isFocus = true;
			const isEmptyInput = this.input.trim().length === 0;
			const isMatchedAny = this.filterList.length > 0;
			const hasList = this.list.length > 0;
			if ((isEmptyInput && hasList) || isMatchedAny) {
				this.isShowDropdown = true;
			}
		},

		handleInputBlur(value) {
			this.isFocus = false;
			this.input = value;
			this.isShowDropdown = false;
			this.$emit("blur", this.input);
		},

		highlight(originValue = "", replaceValue = "") {
			const replacer = (str) => `<strong>${str}</strong>`;

			return originValue.replace(replaceValue, replacer);
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	&-autocomplate {
		position: relative;

		&.open {
			.container-dropdown-list {
				display: block;

				&.in-line {
					width: calc(100% - #{rem(130)});
					margin-left: rem(130);
				}
			}
		}
	}

	&-dropdown-list {
		position: absolute;
		z-index: 2;
		top: auto;
		left: 0;
		width: 100%;
		margin-top: $spacing-2;
		padding: $spacing-4 0;
		border: 1px solid $color-silver;
		border-radius: rem(4);
		background: $color-white;
		overflow: auto;
		display: none;

		.list {
			.item {
				padding: $spacing-3 $spacing-5;
				cursor: pointer;

				&.active, &:hover {
					background: $color-ice-two;
				}
			}
		}
	}
}
</style>