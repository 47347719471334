<template>
	<div class="wrapper-empty">
		<div :class="['wrapper-icon', wrapperColor]">
			<font-awesome-icon :class="['icon', iconColor]" :icon="['far', icon]"/>
		</div>
		<h1 class="title">
			{{ title }}
		</h1>
		<p class="sub-title">
			{{ subTitle }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		subTitle: {
			type: String,
			required: true
		},
		wrapperColor: {
			type: String,
			validator: (value) => {
				return [
					"very-pale-green",
					"ice",
					"ice-two"
				].includes(value);
			}
		},
		icon: {
			type: String,
			required: true
		},
		iconColor: {
			type: String,
			validator: (value) => {
				return [
					"emerald"
				].includes(value);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.wrapper-empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.wrapper-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(114);
		height: rem(114);
		border-radius: 50%;
		margin-bottom: rem(16);

		.icon {
			font-size: rem(56);

			&.emerald {
				color: $color-emerald;
			}
		}

		&.very-pale-green {
			background-color: $color-pale-green;
		}

		&.ice {
			background-color: $color-ice;
		}

		&.ice-two {
			background-color: $color-ice-two;
		}
	}

	.title {
		@include fontSub();

		color: $color-dark-blue-grey;
		font-size: rem(48);
		font-weight: $font-weight-regular;
	}

	.sub-title {
		font-size: rem(20);
		margin: rem(8) 0;
	}
}
</style>