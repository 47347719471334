var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('BaseLoading'):_c('div',{staticClass:"container-overall"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,class:['container-overall-item', ("growth-col-" + (item.growth.length))]},[_c('div',{staticClass:"container-overall-item-inner"},[_c('div',{staticClass:"container-overall-item-inner-icon"},[_c('div',{staticClass:"container-overall-item-inner-icon-wrapper"},[_c('div',{staticClass:"container-overall-item-inner-icon-wrapper-outer",style:([{
						width: item.icon.width,
						height: item.icon.height,
						top: item.icon.top,
						left: item.icon.left
					}])},[_c('FontAwesomeIcon',{attrs:{"icon":[item.icon.type, item.icon.name]}})],1)])]),_c('div',{staticClass:"container-overall-item-inner-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"container-overall-item-inner-total"},[_c('div',{staticClass:"container-overall-item-inner-total-number"},[_vm._v(" "+_vm._s(_vm._f("friendlyNumber")(item.total.number))+" ")]),_c('div',{staticClass:"container-overall-item-inner-total-unit"},[_vm._v(" "+_vm._s(item.total.unit)+" ")])]),_c('div',{staticClass:"container-overall-item-inner-growth"},_vm._l((item.growth),function(growthItem,growthIndex){return _c('div',{key:growthIndex,staticClass:"container-overall-item-inner-growth-period"},[_c('div',{class:[
							'container-overall-item-inner-growth-period-number',
							growthItem.number >= 0 ? 'up' : 'down'
						]},[[(growthItem.showIntegerType)?_c('span',[_vm._v(" "+_vm._s(_vm._f("friendlyNumberWithIntegerType")(growthItem.number))+" ")]):_c('span',[_vm._v(" "+_vm._s(growthItem.number)+" ")])],(growthItem.showRank)?[(growthItem.number >= 0)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'sort-up']}}):_c('font-awesome-icon',{attrs:{"icon":['fas', 'sort-down']}})]:_vm._e()],2),_c('div',{staticClass:"container-overall-item-inner-growth-period-title"},[_vm._v(" "+_vm._s(growthItem.title)+" ")])])}),0)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }