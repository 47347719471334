<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: isActive.keyword }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				v-model="form.keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Company name, name, and email"
				@input="handleKeywordChanged"
				@focus="setFocus('keyword', true)"
				@blur="setFocus('keyword', false)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-role', { active: isActive.role }]">
			<BaseDropdown
				:searchable="false"
				:list="roles"
				:value="form.role"
				type="ghost"
				placeholder="Role: All"
				@input="handleRoleSelected"
				@active="setFocus('role', $event)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-status', { active: isActive.status }]">
			<BaseDropdown
				:searchable="false"
				:list="statuses"
				:value="form.status"
				type="ghost"
				placeholder="User status: All"
				@input="handleStatusSelected"
				@active="setFocus('status', $event)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-type', { active: isActive.type }]">
			<BaseDropdown
				:searchable="false"
				:list="types"
				:value="form.type"
				type="ghost"
				placeholder="Type: All"
				@input="handleTypeSelected"
				@active="setFocus('status', $event)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline>Search</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
export default {
	name: "SearchFilterForm",

	props: {
		roles: {
			type: Array,
			default: () => ([])
		},
		statuses: {
			type: Array,
			default: () => ([])
		},
		types: {
			type: Array,
			default: () => ([])
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			form: {
				keyword: this.query.q || null,
				role: this.query.role || null,
				status: this.query.status || null,
				type: this.query.type || null
			},
			isActive: {
				keyword: false,
				role: false,
				status: false,
				type: false
			}
		};
	},

	methods: {
		handleKeywordChanged(value = null) {
			this.form.keyword = value || null;
		},

		handleRoleSelected(value = null) {
			this.form.role = value;
		},

		handleStatusSelected(value = null) {
			this.form.status = value;
		},

		handleTypeSelected(value = null) {
			this.form.type = value;
		},

		handleSubmit() {
			this.$emit("submit", this.form);
		},

		clearFilter() {
			this.form.keyword = null;
			this.form.role = null;
			this.form.status = null;
			this.form.type = null;
			this.$emit("submit", this.form);
		},

		setFocus(type, value) {
			this.isActive[type] = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-keyword {
	width: rem(268);
}

.input-group-role, .input-group-status, .input-group-type {
	width: rem(184);
}
</style>
