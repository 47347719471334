import { USER_TYPE } from "../../../enums/user";

/**
 * Parse User Profile
 * @param {Object} input user profile data
 * @returns {UserProfile}
 */
export function parseUserProfile(input) {
	if (!input) {
		return {};
	}

	const result = {
		type: null,
		fullName: null,
		email: null,
		roleName: null,
		phoneNumber: null,
		companyName: null
	};

	if (input.type === USER_TYPE.STAFF) {
		result.type = input.type;
		result.fullName = input.displayName;
		result.email = input.email;
		result.roleName = input.role.displayName;
	} else if (input.type === USER_TYPE.CUSTOMER) {
		result.type = input.type;
		result.fullName = input.name;
		result.email = input.email;
		result.roleName = input.role.displayName;
		result.phoneNumber = input.phoneNumber;
		result.companyName = input.companyName;
	}

	return result;
}

/**
 * Parse User Azure List
 * @param {Object} input user azure list data
 * @returns {UserAzureList}
 */
export function parseUserAzureList(input) {
	if (!input) {
		return [];
	}

	return input.map((obj) => {
		return {
			id: obj.id,
			email: obj.email
		};
	});
}