import { getFurnaceConsumptionAPI } from "@/services/api/furnaces.api";
import { parseFurnaceConsumptionTagName } from "../../../services/api/transforms/parseModules";

import {
	FURNACE_CONSUMPTION_REQUEST,
	FURNACE_CONSUMPTION_SUCCESS,
	FURNACE_CONSUMPTION_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		empty: true,
		tagName: {
			tagnameMelter: "Melter",
			tagnameRefiner: "Refiner"
		}
	},

	mutations: {
		[FURNACE_CONSUMPTION_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[FURNACE_CONSUMPTION_SUCCESS](state, response) {
			state.data = response.data;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
			state.isEmpty = response.data.length === 0;
			state.tagName = parseFurnaceConsumptionTagName(response);
		},
		[FURNACE_CONSUMPTION_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchConsumptionData({ commit }, { id = "", filterBy = "7d" }) {
			try {
				commit(FURNACE_CONSUMPTION_REQUEST);

				const { data = [] } = await getFurnaceConsumptionAPI(id, { filter: filterBy });

				commit(FURNACE_CONSUMPTION_SUCCESS, data);
			} catch (error) {
				commit(FURNACE_CONSUMPTION_FAILURE);
			}
		}
	}
};
