import httpClient from "./httpClient";

const LNG_END_POINT = "/lngs";
const getLngInfoAPI = (id) => httpClient.get(`${LNG_END_POINT}/${id}`);
const getLngLevelAPI = (id, params = {}) => httpClient.get(`${LNG_END_POINT}/${id}/levels`, { params });
const getLngMonthlyAPI = (id) => httpClient.get(`${LNG_END_POINT}/${id}/deliveries`);
const getLngHistoryAPI = (id, params = {}) => httpClient.get(`${LNG_END_POINT}/${id}/order-histories`, { params });
const getLngTripInfoAPI = (id) => httpClient.get(`${LNG_END_POINT}/${id}/trip-infos`);

export {
	getLngInfoAPI,
	getLngLevelAPI,
	getLngMonthlyAPI,
	getLngHistoryAPI,
	getLngTripInfoAPI
};
