<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<BaseTable
			v-else
			:item="item"
			:tableOptions="tableOptions"
			:columnOptions="columnOptions"
			:pagination="pagination"
			:loading="loading"
			:stickyHeader="true"
			@onPageChange="handlePageChange"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseTable from "@/components/BaseTable.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import { M_UNIT } from "../selectors/transform/unit";

export default {
	name: "TankOrder",

	components: {
		BaseTable,
		EmptyOrderHistory
	},

	data() {
		return {
			id: this.$route.params.id,

			thead: [
				"Date",
				`Total water (${M_UNIT})`,
				"Total saving (kWH)",
				"Total saving (Baht)"
			],

			tableOptions: [
				"is-striped is-fullwidth"
			],

			columnOptions: [
				{ className: "bold" },
				{ className: "align-right" },
				{ className: "align-right" },
				{ className: "align-right bold" }
			],

			page: this.$route.query.page || 1
		};
	},

	computed: {
		...mapState({
			response: (state) => {
				return state.cerHistory;
			}
		}),

		data() {
			return this.response.data;
		},

		pagination() {
			return this.response.pagination;
		},

		loading() {
			return this.response.isLoading;
		},

		item() {
			return {
				thead: this.thead,
				tbody: this.data.map((item) => Object.values(item))
			};
		},

		isEmpty() {
			return this.response.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.id,
			httpStatus: this.response.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions({
			getCerHistory: "cerHistory/getCerHistory",
			clearCerHistory: "cerHistory/clearCerHistory"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		async fetchData() {
			const { id, page } = this;
			await this.getCerHistory({ id, page });
		},

		handlePageChange(page = 1) {
			this.page = page;
			this.fetchData();
			this.$router.replace({ query: { page } });
		}
	},

	destroyed() {
		this.clearCerHistory();
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}
</style>