<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:iconSize="iconSize"
	>
		<template #icon>
			<CerIcon
				:key="data.shipId"
				:value="data.outletTemperature"
				:size="iconSize"
				label="Outlet"
			/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import CerIcon from "@/components/deviceIcons/CerIcon.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";

export default {
	name: "CerDeviceWithCompany",

	components: {
		CerIcon,
		BaseDeviceWithCompany
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	computed: {
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
::v-deep {
	.container-detail-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-right: rem(12);
		margin-left: rem(16);

		.wrapper-icon {
			margin-left: rem(5);
		}
	}
}
/* stylelint-enable */
.container-detail {
	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}
	}
}
</style>