import { getBhyLevelAPI } from "@/services/api/bulkHydrogen.api";
import {
	BHY_LEVEL_REQUEST,
	BHY_LEVEL_SUCCESS,
	BHY_LEVEL_FAILURE
} from "../../types";
import { transformLineChartData } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false,
		stateLevel: {}
	},

	getters: {
		getBhyLevelData: (state) => {
			return transformLineChartData(state);
		},
		getLevelStateData: (state) => {
			return state.stateLevel;
		}
	},

	mutations: {
		[BHY_LEVEL_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BHY_LEVEL_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.stateLevel = {
				emptyLevel: response.emptyLevel || 0,
				refillLevel: response.refillLevel || 0,
				reorderLevel: response.reorderLevel || 0
			};
			state.isLoading = false;
		},
		[BHY_LEVEL_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getBhyLevel({ commit }, { id = "", query = {} }) {
			try {
				commit(BHY_LEVEL_REQUEST);

				const { data = [] } = await getBhyLevelAPI(id, query);
				commit(BHY_LEVEL_SUCCESS, data);
			} catch (error) {
				commit(BHY_LEVEL_FAILURE);
			}
		}
	}
};
