import { getBhyInfoAPI } from "@/services/api/bulkHydrogen.api";
import {
	BHY_INFO_REQUEST,
	AUTO_BHY_INFO_REQUEST,
	BHY_INFO_SUCCESS,
	AUTO_BHY_INFO_SUCCESS,
	BHY_INFO_FAILURE
} from "../../types";
import { parseBhyInfo } from "../../../services/api/transforms/parseBhyModules";

export default {

	state: {
		data: {},
		isLoading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	getters: {
		getBhyShipToLocation(state) {
			const position = {
				lat: state.data.shipToLatitude,
				lng: state.data.shipToLongitude
			};
			return position;
		}
	},

	mutations: {
		[BHY_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[AUTO_BHY_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[BHY_INFO_SUCCESS](state, { data, status }) {
			state.data = parseBhyInfo(data);
			state.isLoading = false;
			state.statusCode = status;
		},
		[AUTO_BHY_INFO_SUCCESS](state, { data, status }) {
			state.data = parseBhyInfo(data);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[BHY_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		}
	},

	actions: {
		async getBhyInfo({ commit }, id) {
			try {
				commit(BHY_INFO_REQUEST);
				const response = await getBhyInfoAPI(id);
				commit(BHY_INFO_SUCCESS, response);
			} catch (error) {
				commit(BHY_INFO_FAILURE, error);
			}
		},

		async autoGetBhyInfo({ commit }, id) {
			try {
				commit(AUTO_BHY_INFO_REQUEST);
				const response = await getBhyInfoAPI(id);
				commit(AUTO_BHY_INFO_SUCCESS, response);
			} catch (error) {
				commit(BHY_INFO_FAILURE, error);
			}
		}
	}
};
