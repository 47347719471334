import httpClient from "./httpClient";

const NO_SIGNAL_CONFIGS = "/no-signal-configs";

const getNoSignalConfigsApi = () => httpClient.get(`${NO_SIGNAL_CONFIGS}`);
const putNoSignalConfigsApi = (param = []) => httpClient.put(`${NO_SIGNAL_CONFIGS}`, param);

export {
	getNoSignalConfigsApi,
	putNoSignalConfigsApi
};
