import dayjs from "dayjs";
import { parseRowsStdModule } from "../../../services/api/transforms/parseModules";
import { replaceDeviceName } from "../../../selectors/helpers/replace";
import { DEFAULT_COLOR } from "../../../enums/config";
import { MODULE_TYPES } from "../../../enums/modules";
import { PAGE_NAME } from "../../../enums/pagePermission";

export const parseApiTags = (apiTags = []) => {
	// map value for use it with GraphIcon on Standard Module
	const formatApiTag = {
		apiValue1: apiTags[0]?.value ?? null,
		apiValue2: apiTags[1]?.value ?? null,
		apiValue3: apiTags[2]?.value ?? null,
		apiValue4: apiTags[3]?.value ?? null
	};

	return formatApiTag;
};

const deviceLink = ({
	id,
	type,
	productId = "",
	templateId = "",
	isStandardModule = false
}) => {
	let name = null;
	if (isStandardModule && templateId !== null) {
		name = PAGE_NAME.STANDARD_MODULE_OVERVIEW;
		return {
			name,
			params: { app: type, id, templateId }
		};
	}
	switch (type) {
		case MODULE_TYPES.TANK:
			if (productId === MODULE_TYPES.BHY) {
				name = PAGE_NAME.BHY_INFO;
			} else {
				name = PAGE_NAME.TANKINFO;
			}
			break;
		case MODULE_TYPES.METER:
			name = PAGE_NAME.METERINFO;
			break;
		case MODULE_TYPES.ZLD2:
			name = PAGE_NAME.ZLD_VINY_THAI_INFO;
			break;
		case MODULE_TYPES.ZLD:
			name = PAGE_NAME.ZLDINFO;
			break;
		case MODULE_TYPES.CER:
			name = PAGE_NAME.CERINFO;
			break;
		case MODULE_TYPES.NITRO_FAS:
			name = PAGE_NAME.NITRO_FAS_INFO;
			break;
		case MODULE_TYPES.O2_AERATION:
			name = PAGE_NAME.O2_AERATION_INFO;
			break;
		case MODULE_TYPES.GENGAS:
			name = PAGE_NAME.GENGAS_INFO;
			break;
		case MODULE_TYPES.PWR:
			name = PAGE_NAME.PWR_INFO;
			break;
		case MODULE_TYPES.SOR:
			name = PAGE_NAME.SOR_INFO;
			break;
		case MODULE_TYPES.LNG:
			name = PAGE_NAME.LNG_INFO;
			break;
		case MODULE_TYPES.FURNACE:
			name = PAGE_NAME.FURNACE_INFO;
			break;
		case MODULE_TYPES.AIR_COMP:
			name = PAGE_NAME.AIR_COMP_INFO;
			break;

		default:
			break;
	}
	return { name, params: { id } };
};

export const transformStdViewDevices = (data = []) => {
	return data.map((value) => {
		// check devices not available on related device for hide
		const urlLink = deviceLink(value);
		if (value.isStandardModule) {
			return { ...value,
				conditionStatus: {
					...value.conditionStatus,
					bgColor: value.conditionStatus?.bgColor ?? DEFAULT_COLOR.CONDITION_STATUS_BG_COLOR,
					borderColor: value.conditionStatus?.borderColor ?? DEFAULT_COLOR.CONDITION_STATUS_BORDER_COLOR,
					textColor: value.conditionStatus?.textColor ?? DEFAULT_COLOR.CONDITION_STATUS_TEXT_COLOR,
					status: value.conditionStatus?.title !== null ? !!value.conditionStatus?.status : false // check if title is null not show BaseTag on dashboard
				},
				isStandardModule: !!value.isStandardModule,
				rows: parseRowsStdModule(value.rows),
				graph: value.graph ?
					{ ...value.graph,
						liquid: {
							liquidLevelBgColor: value.graph?.liquid?.liquidLevelBgColor ?? DEFAULT_COLOR.LIQUID_LEVEL_BG_COLOR,
							deviceCardBorderColor: value.graph?.liquid?.deviceCardBorderColor ?? DEFAULT_COLOR.DEVICE_CARD_BORDER_COLOR,
							liquidLevelEmptySoonStatus: value.graph?.liquid?.liquidLevelEmptySoonStatus ?? 0
						},
						deviceId: value.id ?? null,
						apiTags: parseApiTags(value.graph?.api_tags) } : null,
				urlLink };
		}
		return {
			...value,
			urlLink,
			isStandardModule: !!value.isStandardModule
		};
	});
};

export const transformStdViewDevicesFromDeviceSync = (data = {}) => {
	const urlLink = deviceLink(data);
	if (data.isStandardModule) {
		// filter columnType = title only cuz dashboard want title only to show on Device Card
		let rows = data.rows.filter((item) => item.columnType === "title") || [];
		if (rows?.length > 3) {
			rows = rows.splice(0, 3);
		}
		return { ...data,
			isStandardModule: !!data.isStandardModule,
			conditionStatus: {
				...data.conditionStatus,
				bgColor: data.conditionStatus?.bgColor ?? DEFAULT_COLOR.CONDITION_STATUS_BG_COLOR,
				borderColor: data.conditionStatus?.borderColor ?? DEFAULT_COLOR.CONDITION_STATUS_BORDER_COLOR,
				textColor: data.conditionStatus?.textColor ?? DEFAULT_COLOR.CONDITION_STATUS_TEXT_COLOR,
				status: data.conditionStatus?.title !== null ? !!data.conditionStatus?.status : false // check if title is null not show BaseTag on dashboard
			},
			rows: parseRowsStdModule(rows),
			graph: data.graph ?
				{ ...data.graph,
					liquid: {
						liquidLevelBgColor: data.graph?.liquid.liquidLevelBgColor ?? DEFAULT_COLOR.LIQUID_LEVEL_BG_COLOR,
						deviceCardBorderColor: data.graph?.liquid.deviceCardBorderColor ?? DEFAULT_COLOR.DEVICE_CARD_BORDER_COLOR,
						liquidLevelEmptySoonStatus: data.graph?.liquid.liquidLevelEmptySoonStatus ?? 0
					},
					deviceId: data.id ?? null,
					apiTags: parseApiTags(data.graph?.api_tags) } : null,
			urlLink };
	}
	return {
		...data,
		urlLink,
		isStandardModule: !!data.isStandardModule
	};
};

export const transformViewDevices = (data = []) => data.reduce((acc, next) => {
	return [
		...acc,
		{
			...next,
			lastUpdate: next.lastUpdate ? dayjs(next.lastUpdate).format("D MMM YYYY, HH:mm") : "",
			name: replaceDeviceName(next.name),
			companyName: next.shipTo.name || "",
			type: next.type || "",
			productId: next?.productId ?? "",
			signalReceivedAt: next.signalReceivedAt ? dayjs(next.signalReceivedAt).format("DD MMM YYYY, HH:mm") : "",
			...(next.status ? {
				status: replaceDeviceName(next.status, [
					{
						from: "On",
						to: "ON"
					},
					{
						from: "OFF",
						to: "OFF"
					}
				])
			} : {
				status: "OFF"
			}
			)
		}
	];
}, []);