import {
	OVER_ALL_MODULE_DEVICE_FAILURE,
	OVER_ALL_MODULE_DEVICE_REQUEST,
	OVER_ALL_MODULE_DEVICE_SUCCESS
} from "../../types";

import { parseModuleDevice } from "../../../services/api/transforms/parseModuleDevices";
import { getMonitorModuleDevicesAPI } from "../../../services/api/monitor.api";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getMonitorModuleDeviceData(state) {
			return state.data;
		}
	},
	mutations: {
		[OVER_ALL_MODULE_DEVICE_REQUEST](state) {
			state.isLoading = true;
		},
		[OVER_ALL_MODULE_DEVICE_SUCCESS](state, data) {
			state.isLoading = false;
			state.data = parseModuleDevice(data);
		},
		[OVER_ALL_MODULE_DEVICE_FAILURE](state) {
			state.isLoading = false;
			state.data = [];
			state.isEmpty = true;
		}

	},

	actions: {
		async getModuleDevices({ commit }) {
			try {
				commit(OVER_ALL_MODULE_DEVICE_REQUEST);
				const { data } =	await getMonitorModuleDevicesAPI();
				commit(OVER_ALL_MODULE_DEVICE_SUCCESS, data);
			} catch (e) {
				commit(OVER_ALL_MODULE_DEVICE_FAILURE);
			}
		}
	}
};
