<template>
	<div
		:class="['input-wrapper', {'in-line': inline}, size, { rounded }]"
		v-click-outside="onClickOutSideDropdown"
	>
		<label v-if="isShowLabel" class="input-label">
			{{ label }}
			<slot name="info-label"></slot>
		</label>
		<div
			v-click-outside="closeDropdown"
			:class="['dropdown-container',
				defaultClassName,
				dropdownValueClass,
				{ 'is-error': isShowErrorMessage }
			]"
		>
			<div :class="['dropdown-value', dropdownValueClass]" @click="toggleDropdown">
				<slot class="prepend-icon" name="prepend"></slot>
				<span v-if="selectedLabel" class="dropdown-value-text" v-html="selectedLabel"></span>
				<span v-else class="dropdown-placeholder">{{ placeholder }}</span>
				<span class="dropdown-arrow">
					<font-awesome-icon :icon="['far', 'angle-down']" />
				</span>
			</div>
			<div v-show="isActive" class="dropdown-bubble-container">
				<div class="dropdown-bubble">
					<div v-if="searchable" class="dropdown-search">
						<BaseFormInput
							v-model="searchText"
							ref="searchInput"
							:placeholder="placeholderSearchInput"
							class="dropdown-search-input"
						/>
						<span v-if="searchText.length" class="dropdown-search-clear" @click.stop="clearSearch">
							<font-awesome-icon :icon="['far', 'times']" />
						</span>
					</div>
					<div
						v-if="listFiltered.length > 0"
						class="dropdown-list-container"
						:style="dropDownListContainerStyle"
					>
						<ul v-if="listFiltered.length > 0" class="dropdown-list">
							<li
								v-for="(list, index) in listFiltered"
								:key="`${index}-${list.value}`"
								:class="['dropdown-item', { 'is-active': list.isSelected, 'is-disabled': list.isDisabled }]"
								@click="!list.isDisabled ? selectItem(list.value) : null"
							>
								<span v-html="list.labelHtml || list.label"></span>
							</li>
						</ul>
					</div>
					<p v-else-if="searchText.length > 0" class="dropdown-no-data">No matching items found.</p>
					<p v-else class="dropdown-no-data">No data.</p>
				</div>
			</div>
			<span :class="['error-message']" v-if="isShowErrorMessage">
				{{ errorMessage }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseDropdown",

	props: {
		label: {
			type: String,
			default: ""
		},
		inline: {
			type: Boolean,
			default: false
		},
		value: {
			type: [String, Number],
			default: null
		},
		list: {
			type: Array,
			default: () => ([])
		},
		searchable: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: "Please select"
		},
		placeholderSearchInput: {
			type: String,
			default: "Search"
		},
		disabledItem: {
			type: Array,
			default: () => ([])
		},
		type: {
			type: String,
			default: null
		},
		controlOptions: {
			type: Array,
			default: () => ([])
		},
		dropdownListContainerHeight: {
			type: Number,
			default: 165
		},
		isError: {
			type: Boolean,
			default: false
		},
		errorMessage: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: "medium",
			validator: (value) => {
				return [
					"small",
					"medium"
				].includes(value);
			}
		},
		rounded: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			isActive: "",
			searchText: ""
		};
	},

	computed: {
		isShowErrorMessage() {
			return this.isError && this.errorMessage;
		},

		isShowLabel() {
			return this.label !== "";
		},
		listFormatted() {
			return this.list.map((v) => ({
				labelHtml: v.labelHtml,
				value: v.value,
				label: v.label,
				isSelected: this.isSelected(v.value),
				isDisabled: this.isDisabled(v.value)
			}));
		},

		listFiltered() {
			const list = this.listFormatted;

			return list.filter((v) => v.label.toLowerCase().includes(this.searchText.toLowerCase()));
		},

		dropdownValueClass() {
			return {
				"is-active": this.isActive,
				"is-clearable": this.value && this.clearable,
				"is-disabled": this.disabled
			};
		},

		selectedLabel() {
			const selected = this.listFormatted.find((v) => v.isSelected);
			return selected ? selected.label : "";
		},

		dropDownListContainerStyle() {
			return { "max-height": `${this.dropdownListContainerHeight}px` };
		},

		defaultClassName() {
			return [{ "dropdown-ghost": this.type === "ghost" }, ...this.controlOptions];
		}
	},

	watch: {
		isActive(newValue) {
			this.$emit("onActiveChange", newValue);
		}
	},

	methods: {
		selectValue(value) {
			this.$emit("input", value);
		},

		selectItem(value) {
			this.selectValue(value);
			this.isActive = false;
			this.searchText = "";
			this.$emit("close");
		},

		isSelected(value = null) {
			return this.value === value;
		},

		isDisabled(value = null) {
			return this.disabledItem.includes(value);
		},

		toggleDropdown() {
			if (this.disabled) {
				return;
			}

			this.isActive = !this.isActive;
			this.$emit("active", this.isActive);

			// Focus search input
			if (this.isActive) {
				this.focusSearch();
				this.$emit("open");
			} else {
				this.searchText = "";
				this.$emit("close");
			}
		},

		closeDropdown() {
			this.isActive = false;
			this.$emit("active", false);
			this.searchText = "";
			this.$emit("close");
		},

		focusSearch() {
			if (this.isActive &&
				this.$refs.searchInput &&
				this.$refs.searchInput.$refs.textInput
			) {
				this.$nextTick(() => this.$refs.searchInput.$refs.textInput.focus());
			}
		},
		clearSearch() {
			this.searchText = "";
			this.focusSearch();
		},
		onClickOutSideDropdown() {
			this.$emit("blur");
		}
	}
};
</script>

<style lang="scss" scoped>
$size-small: rem(32);
$size-medium: rem(40);

.input-wrapper {
	width: 100%;

	&.in-line {
		display: flex;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}

	&.small {
		.dropdown {
			&-value, &-value-text, &-placeholder {
				height: $size-small;
				line-height: $size-small;
			}
		}
	}

	&.medium {
		.dropdown {
			&-value, &-value-text, &-placeholder {
				height: $size-medium;
				line-height: $size-medium;
			}
		}
	}

	&.rounded .dropdown-value {
		border-radius: $border-radius-normal;
	}
}

.error-message {
	position: absolute;
	font-size: $font-14;
	color: $color-red;
	font-weight: $font-weight-bold;
}

.dropdown {
	// .dropdown-container
	&-container {
		position: relative;
		width: 100%;

		.is-disabled {
			background-color: $color-disabled-input;
		}

		&.dropdown-secondary {
			.dropdown-value {
				border-radius: 0;
				border-top: none;
				border-left: none;
				border-right: none;
				background-color: transparent;
				padding: 0 rem(24) 0 0;

				&.is-clearable {
					padding-right: rem(44);
				}

				// .dropdown-value-clear
				&-clear {
					right: rem(18);
				}

				.dropdown-arrow {
					right: 0;
				}

				// Active
				&.is-active {
					border-color: $color-silver;
				}
			}
		}

		&.dropdown-ghost {
			.dropdown-value {
				background-color: transparent;
				border: none;
			}
		}

		&.is-error {
			.dropdown-value {
				border-color: $color-red;
			}
		}
	}

	// .dropdown-value
	&-value {
		position: relative;
		z-index: 1;
		width: 100%;
		height: rem(40);
		line-height: rem(40);
		padding: 0 rem(42) 0 rem(16);
		background-color: $color-grey-1;
		border: 1px solid $color-silver;
		border-radius: rem(4);
		transition: border-color 0.3s;
		cursor: pointer;

		&.is-clearable {
			padding-right: rem(60);
		}

		// .dropdown-value-text
		&-text,
		.dropdown-placeholder {
			display: block;
			height: rem(40);
			line-height: rem(40);
			text-overflow: clip;
			overflow: hidden;
		}

		// .dropdown-value-text
		&-text {
			@include ellipsis(1);

			color: $color-black;
		}

		.dropdown-placeholder {
			color: $color-boulder;
		}

		// .dropdown-value-clear
		&-clear {
			position: absolute;
			z-index: 2;
			top: 0;
			right: rem(34);
			width: rem(10);
			height: 100%;
			display: inline-flex;
			align-items: center;
			color: $color-black;
			transition: transform 0.3s;

			svg {
				max-width: 100%;
				font-size: $font-14;
			}
		}

		.dropdown-arrow {
			position: absolute;
			z-index: 2;
			top: 0;
			right: rem(16);
			width: rem(10);
			height: 100%;
			display: inline-flex;
			align-items: center;
			color: $color-black;
			transition: transform 0.3s;

			svg {
				max-width: 100%;
				font-size: $font-14;
			}
		}

		// Active
		&.is-active {
			.dropdown-arrow {
				transform: rotate(-180deg);
			}
		}

		&.is-disabled {
			cursor: not-allowed;
		}

		.prepend-icon {
			position: absolute;
		}
	}

	// .dropdown-bubble-container
	&-bubble-container {
		position: absolute;
		z-index: 4;
		top: auto;
		left: auto;
		padding-top: rem(8);
		padding-bottom: rem(80);
		width: 100%;
	}

	// .dropdown-bubble
	&-bubble {
		background-color: $color-white;
		border: 1px solid $color-silver;
		border-radius: rem(4);
		overflow: hidden;
	}

	// .dropdown-search
	&-search {
		position: relative;

		// .dropdown-search-input
		&-input {
			font-size: $font-18;
			padding: rem(8);

			.text-input-field {
				border-radius: 0;
				border-top: none;
				border-left: none;
				border-right: none;
				padding-right: rem(42);

				&:focus {
					border-color: $color-silver;
				}
			}
		}

		// .dropdown-search-clear
		&-clear {
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			width: rem(42);
			height: 100%;
			padding: 0 rem(16);
			display: inline-flex;
			align-items: center;
			color: $color-black;
			cursor: pointer;

			svg {
				max-width: 100%;
				font-size: $font-14;
			}
		}
	}

	// .dropdown-list-container
	&-list-container {
		overflow-y: auto;
		max-height: rem(165);

		&.has-group {
			max-height: rem(200);
		}
	}

	// .dropdown-list
	&-list {
		padding: rem(8) 0;
	}

	// .dropdown-item
	// .dropdown-no-data
	&-item,
	&-no-data {
		display: flex;
		align-items: center;
		padding: rem(8);
		min-height: rem(32);
		line-height: 1;
	}

	// .dropdown-item
	&-item {
		color: $color-dark-blue-grey;
		font-size: $font-16;
		transition: all 0.3s;
		cursor: pointer;

		// Hover and Active
		&:hover,
		&.is-active {
			background-color: $color-ice-two;
		}

		// Disabled
		&.is-disabled {
			color: $color-black-30;
			background-color: $color-white;
			cursor: not-allowed;
		}
	}

	// .dropdown-no-data
	&-no-data {
		color: $color-black-30;
		margin: 0;
		justify-content: center;
	}
}
</style>
