import httpClient from "./httpClient";

const GENGAS_END_POINT = "/genGas";

const getGenGasHistoryAPI = (id, params) => httpClient.get(`${GENGAS_END_POINT}/${id}/order-histories`, { params });
const getGenGasInfoAPI = (id) => httpClient.get(`${GENGAS_END_POINT}/${id}`);
const getGenGasFlowRateAPI = (id, params) => httpClient.get(`${GENGAS_END_POINT}/${id}/flow-rates`, { params });
const getGenGasConsumptionAPI = (id, params) => httpClient.get(`${GENGAS_END_POINT}/${id}/consumptions`, { params });

export {
	getGenGasInfoAPI,
	getGenGasFlowRateAPI,
	getGenGasHistoryAPI,
	getGenGasConsumptionAPI
};
