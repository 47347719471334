export const mappingGengasLabel = {
	tagNumber: "TAG no.",
	plantCapacity: `Plant capacity`,
	todayConsumption: `Today consumption`,
	yesterdayConsumption: `Yesterday consumption`,
	monthToDate: `Month to date`,
	linePressure: "Line pressure",
	oxygen: "Oxygen",
	MTDBaseConsumption: `MTD Base consumption`,
	MTDPremiumConsumption: `MTD Premium consumption`
};
