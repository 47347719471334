import {
	getStaffSessionAPI,
	getUserInfoAPI,
	getUserLogoutAPI,
	postCustomerLogin,
	getUserModulesAPI
} from "../../../services/api/user.api";
import {
	USER_SET_PROFILE,
	USER_ERROR,
	USER_LOGOUT,
	CUSTOMER_LOGIN_REQUEST,
	CUSTOMER_LOGIN_SUCCESS,
	CUSTOMER_LOGIN_FAILURE,
	CUSTOMER_CLEAR_STATE,
	USER_MODULE_REQUEST,
	USER_MODULE_SUCCESS,
	USER_MODULE_FAILURE
} from "../../types";
import { parseUserProfile } from "../../../services/api/transforms/parseUser";

import { STAFF_ROLES } from "../../../enums/role";
import { CUSTOMER_ROLES } from "../../../enums/customerManagement/customerRole";

const { SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, IT, SALES, SALES_PLUS, PLANNER } = STAFF_ROLES;
const { POWERUSER, PROCUREMENT, USER, LEVEL_2_USER } = CUSTOMER_ROLES;

export default {
	namespaced: true,

	state: {
		role: "",
		isLoggedIn: false,
		profile: {},
		customerLoginLoading: false,
		customerLoginErrorMessage: "",
		module: {
			data: [],
			loading: true
		},
		rolePermissions: []
	},

	getters: {
		getProfile: ({ profile: { fullName, email, roleName, type, phoneNumber, companyName } }) => {
			return {
				fullName,
				email,
				roleName,
				type,
				phoneNumber,
				companyName
			};
		}
	},

	mutations: {
		[USER_SET_PROFILE](state, data) {
			state.role = data.role.code;
			state.isLoggedIn = true;
			state.profile = parseUserProfile(data);
			state.rolePermissions = ([SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER].includes(state.role) ? ["Marketplace.Read", ...data.rolePermissions] : data.rolePermissions) || []; // TODO: hardcode redirect to marketplace (if not Order role)
		},

		[USER_ERROR](state) {
			state.role = "";
			state.isLoggedIn = false;
		},

		[USER_LOGOUT](state) {
			state.role = "";
			state.isLoggedIn = false;
			state.profile = {};
		},

		[CUSTOMER_LOGIN_REQUEST](state) {
			state.customerLoginLoading = true;
			state.customerLoginErrorMessage = "";
		},

		[CUSTOMER_LOGIN_SUCCESS](state) {
			state.isLoggedIn = true;
			state.customerLoginLoading = false;
			state.customerLoginErrorMessage = "";
		},

		[CUSTOMER_LOGIN_FAILURE](state, statusCode) {
			state.isLoggedIn = false;
			state.customerLoginLoading = false;

			switch (statusCode) {
				case 401:
					state.customerLoginErrorMessage = "Username or password is incorrect";
					break;
				case 403:
					state.customerLoginErrorMessage = "This account is inactive, if you want to sign in please contact admin";
					break;
				case 429:
					state.customerLoginErrorMessage = "Temporary disable, please try again later";
					break;
				default:
			}
		},

		[CUSTOMER_CLEAR_STATE](state) {
			state.role = "";
			state.isLoggedIn = false;
			state.profile = {};
			state.customerLoginLoading = false;
			state.customerLoginErrorMessage = "";
		},

		[USER_MODULE_REQUEST](state) {
			state.module.loading = true;
			state.module.data = [];
		},
		[USER_MODULE_SUCCESS](state, data) {
			state.module.loading = false;
			state.module.data = data;
		},
		[USER_MODULE_FAILURE](state) {
			state.module.loading = false;
			state.module.data = [];
		}
	},

	actions: {
		async getStaffSessionAPI({ commit }, { accessToken }) {
			const { data } = await getStaffSessionAPI(accessToken);
			await localStorage.setItem("token", accessToken);
			commit("USER_SET_PROFILE", data);
		},

		async getUserInfo({ commit }) {
			try {
				const { data } = await getUserInfoAPI();
				commit(USER_SET_PROFILE, data);
			} catch (error) {
				commit(USER_ERROR);
			}
		},

		async getUserModules({ commit }) {
			try {
				commit(USER_MODULE_REQUEST);
				const { data } = await getUserModulesAPI();
				commit(USER_MODULE_SUCCESS, data);
			} catch (error) {
				commit(USER_MODULE_FAILURE);
			}
		},

		async getLogout({ commit }) {
			await getUserLogoutAPI();
			commit(USER_LOGOUT);
		},

		async postCustomerLogin({ commit }, { email, password }) {
			try {
				commit(CUSTOMER_LOGIN_REQUEST);
				await postCustomerLogin({ email, password });
				commit(CUSTOMER_LOGIN_SUCCESS);
			} catch (error) {
				commit(CUSTOMER_LOGIN_FAILURE, error.response.status);
			}
		},

		clearCustomerState({ commit }) {
			commit(CUSTOMER_CLEAR_STATE);
		}
	}
};
