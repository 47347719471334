<template>
	<div class="no-data">
		<div class="no-data-icon"><FontAwesomeIcon :icon="['far', icon]" /></div>
		<h4 class="no-data-title">{{ title }}</h4>
		<p class="no-data-description" v-html="description"></p>
	</div>
</template>

<script>
export default {
	name: "BaseNoData",

	props: {
		icon: {
			type: String,
			default: "file-times"
		},

		title: {
			type: String,
			default: "No information yet"
		},

		description: {
			type: String,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
.no-data {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	// .no-data-icon
	&-icon {
		svg {
			font-size: $font-40;
			color: $color-gunmetal-two;
		}
	}

	&.graph {
		padding: rem(104) 0;
	}

	// .no-data-title
	&-title {
		@include fontSub();

		font-size: $font-20;
		color: $color-dark-blue-grey;
		font-weight: 600;
		margin: rem(8) 0;
		line-height: 1.2;
	}

	// .no-data-description
	&-description {
		color: $color-grey-4;
		margin: 0;
		max-width: rem(256);
		text-align: center;
		font-weight: 600;
	}
}
</style>
