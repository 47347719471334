import { replaceDeviceName } from "../../../selectors/helpers/replace";
import { sorterObject } from "../../../selectors/helpers";

/**
 * Parse Product Type
 * @param {Array} input product type
 * @returns {ProductType}
 */
export function parseProductType(input) {
	if (!input) {
		return {};
	}

	// module name that we want to replace text
	const moduleReplacer = [
		{
			from: "GenGas",
			to: "Gen Gas"
		}
	];

	// sort object keys, we have to display order of module names following from design
	const sortOrderList = [
		"Tank",
		"Meter",
		"GenGas",
		"Application"
	];

	const sortedInput = sorterObject(input, sortOrderList);

	return Object.keys(sortedInput).reduce((acc, cur) => {
		acc[replaceDeviceName(cur, moduleReplacer)] = sortedInput[cur].map((item) => ({
			product: item.product,
			total: item.total
		}));
		return acc;
	}, {});
}
