import { historyStandardModuleAPI } from "@/services/api/standardModule.api";
import { STANDARD_MODULE_HISTORY_REQUEST, STANDARD_MODULE_HISTORY_SUCCESS, STANDARD_MODULE_HISTORY_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: {
			rows: [],
			headers: []
		},
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		},
		statusCode: null,
		isLoading: false,
		isEmpty: true
	},

	mutations: {
		[STANDARD_MODULE_HISTORY_REQUEST](state) {
			state.isLoading = true;
			state.data = {};
		},
		[STANDARD_MODULE_HISTORY_SUCCESS](state, { data, status }) {
			state.isEmpty = data.data[0].rows?.length === 0;
			state.data = data.data[0];
			state.statusCode = status;
			state.pagination = data.pagination;
			state.isLoading = false;
		},
		[STANDARD_MODULE_HISTORY_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		async getHistoryStandardModule({ commit }, { id, params }) {
			try {
				commit(STANDARD_MODULE_HISTORY_REQUEST);
				const { data, status } = await historyStandardModuleAPI(id, params);
				commit(STANDARD_MODULE_HISTORY_SUCCESS, { data, status });
			} catch (error) {
				commit(STANDARD_MODULE_HISTORY_FAILURE);
			}
		}
	}
};
