<template>
	<div>
		<div  :class="['module-type', { active: isActive }]">
			<div class="module-type-header" @click="toggleModules()">
				<h2 class="module-type-name">{{ data.module }}</h2>
				<div class="module-type-toggle">
					<a>
						<FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
					</a>
				</div>
			</div>
			<div class="module-type-body container">
				<div class="row">
					<BaseLoading v-if="isLoading" />
					<ModuleListItemDevice v-for="(item, index) in data.devices" :key="index" :item="item" @onClickSync="onClickSync"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import ModuleListItemDevice from "@/components/dashboard/ModuleListItemDevice.vue";

export default {
	name: "ModuleListModules",

	components: {
		BaseLoading,
		ModuleListItemDevice
	},

	props: {
		data: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			isActive: this.checkToggleShowOrHide(),
			isLoading: false
		};
	},

	methods: {
		toggleModules() {
			this.isActive = !this.isActive;
		},

		onClickSync(data) {
			this.$emit("onClickSync", data);
		},

		checkToggleShowOrHide() {
			return this.data.devices.length < 10;
		}

	}
};
</script>

<style lang="scss" scoped>
.module-type {
	position: relative;
	margin-bottom: rem(16);
	border-radius: rem(8);
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	line-height: 1.5;
	overflow: hidden;

	.pin {
		top: 0;
		left: 0;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(46);
		height: rem(68);
		cursor: pointer;

		svg {
			position: relative;
			top: rem(-1);
			width: rem(9);
			color: red;
		}

		.on {
			color: $color-marigold;
		}

		.off {
			color: $color-dark-blue-grey;
		}
	}

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-label
	&-label {
		margin-right: rem(8);
		font-size: $font-18;
		font-weight: 400;
		color: $color-gunmetal-two;
	}

	// .module-item-code, .module-item-key
	&-code,
	&-key {
		margin-right: rem(64);
		font-weight: 600;
		color: $color-dark-blue-grey;

		@include desktop {
			min-width: rem(130);
			margin-right: rem(10);
		}
	}

	// .module-item-toggle
	&-toggle {
		margin-left: auto;

		a {
			display: inline-block;
			color: $color-cerulean;
			font-weight: 600;

			svg {
				position: relative;
				top: rem(3);
				margin-left: rem(10);
				color: $color-cerulean;
				font-size: $font-16;
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(20) rem(16) rem(20) rem(20);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-type-body {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}

.not-found-data {
	margin-bottom: rem(10);
	text-align: center;
	font-size: $font-20;
	color: $color-dark-blue-grey;
}
</style>
