<template>
	<BaseLayout pageTitle="Layout customisation">
		<BaseLoading
			v-if="loading"
			class="wrapper-loading"
		/>
		<div v-if="!loading">
			<div class="container-action-bar">
				<BaseButton
					size="large"
					rounded
					@click="handleOpenCustomisationModal"
				>
					Create new layout
				</BaseButton>
			</div>
			<SearchFilter
				v-if="!isNoLayout"
				:keywordValue="keyword"
				:moduleValue="moduleSelected"
				:moduleLists="moduleLists"
				@handleSelectModule="handleSelectModule"
				@handleKeywordChange="handleKeyWordChange"
				@handleSubmit="handleSubmitForm"
				@handleClear="handleClear"
			/>
			<div
				v-if="isShowList"
				class="wrapper-list-customisation"
			>
				<template v-for="item in getParserItem">
					<div  :key="item.id" class="customisation-item">
						<h2 class="customisation-item-layout-name">
							{{ item.name }}
						</h2>
						<div class="customisation-item-module">
							Module <span class="module-type">{{ item.module }}</span>
						</div>
						<div class="customisation-item-edit-section btn-link">
							<router-link
								:to="{ name: item.routeTo, params:{ id: item.id} }"
								class="edit-btn"
								active-class="active"
								exact
							>
								<font-awesome-icon
									:icon="['far', 'edit']"
									class="edit-icon"
								>
								</font-awesome-icon>
								Edit
							</router-link>
						</div>
					</div>
				</template>
			</div>

			<BaseEmpty
				v-if="isEmptyWithSearch && !isNoLayout"
				class="wrapper-empty"
				icon="search"
				wrapperColor="very-pale-green"
				iconColor="emerald"
				title="No matching search results"
				subTitle="Try adjusting your search by changing your layout name or removing filters"
			/>

			<BaseEmpty
				v-if="isNoLayout"
				class="wrapper-empty"
				icon="file-times"
				wrapperColor="ice-two"
				iconColor="emerald"
				title="Please create new layout"
				subTitle="Layout customisation list will be displayed once you created new layout"
			/>
			<BasePagination
				v-if="pagination.lastPage > 1"
				:value="pagination.currentPage"
				:totalPage="pagination.lastPage"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>
			<ModalCreate
				header="Create new layout"
				modal-name="modal-layout-create"
				@onSubmit="handleSubmitModule"
			/>
		</div>
	</BaseLayout>

</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseEmpty from "@/components/BaseEmpty.vue";
import ModalCreate from "@/components/layoutCustomisation/ModalCreate.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import SearchFilter from "@/components/layoutCustomisation/SearchFilter.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { getLayoutLists } from "../../services/api/layoutCustomisation.api";

const {
	LAYOUT_CUSTOMISATION_GENGAS_CREATE,
	LAYOUT_CUSTOMISATION_GENGAS_EDIT,
	LAYOUT_CUSTOMISATION_ZLD_CREATE,
	LAYOUT_CUSTOMISATION_ZLD_EDIT,
	LAYOUT_CUSTOMISATION_POWER_CREATE,
	LAYOUT_CUSTOMISATION_POWER_EDIT,
	LAYOUT_CUSTOMISATION_SOR_CREATE,
	LAYOUT_CUSTOMISATION_SOR_EDIT,
	LAYOUT_CUSTOMISATION_TANK_CREATE,
	LAYOUT_CUSTOMISATION_TANK_EDIT,
	LAYOUT_CUSTOMISATION_LIST
} = PAGE_NAME;

export default {
	components: {
		BaseButton,
		BaseEmpty,
		ModalCreate,
		BaseLayout,
		SearchFilter,
		BaseLoading
	},
	data() {
		return {
			loading: true,
			isNoLayout: false,
			layoutLists: [],
			moduleLists: [
				{
					value: "",
					label: "Module: All"
				},
				{
					value: "GENGAS",
					label: "Gen Gas"
				},
				{
					value: "ZLD2",
					label: "Application - ZLD (Vinythai)"
				},
				{
					value: "PWR",
					label: "Electricity"
				},
				{
					value: "SOR",
					label: "Solar Cell"
				},
				{
					value: "TANK",
					label: "Tank"
				}
			],
			pagination: {
				currentPage: Number(this.$route.query.page) || null,
				lastPage: 1,
				perPage: 12,
				total: 1
			},
			searchFilter: true,
			moduleSelected: this.$route.query.module || "",
			keyword: this.$route.query.keyword || "",
			createModuleLists: [
				{
					label: "Gen Gas", value: "GEN_GAS"
				},
				{
					label: "Application - ZLD (Vinythai)", value: "ZLD"
				},
				{
					label: "Electricity", value: "PWR"
				},
				{
					label: "Solar Cell", value: "SOR"
				},
				{
					label: "Tank", value: "TANK"
				}
			]
		};
	},

	async created() {
		this.loading = true;
		const computeQuery = {
			...(this.keyword ? { keyword: this.keyword } : {}),
			...(this.moduleSelected ? { module: this.moduleSelected } : {}),
			...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {})
		};

		const { data: response } = await getLayoutLists(computeQuery);
		this.layoutLists = response.data;

		this.pagination = {
			lastPage: response.pagination.lastPage,
			perPage: response.pagination.perPage,
			total: response.pagination.total,
			currentPage: Number(this.$route.query.page) || 1
		};

		if ((!this.keyword && !this.moduleSelected) && response.data.length === 0) {
			this.isNoLayout = true;
		}

		this.loading = false;
	},

	computed: {
		isShowList() {
			return this.layoutLists.length > 0;
		},

		isEmptyWithSearch() {
			return this.layoutLists.length === 0 && this.searchFilter;
		},

		getParserItem() {
			const resultList = [];
			const mapModule = {
				GenGas: "Gen Gas",
				GENGAS: "Gen Gas",
				ZLD2: "ZLD"
			};
			const mapRoute = {
				GenGas: LAYOUT_CUSTOMISATION_GENGAS_EDIT,
				GENGAS: LAYOUT_CUSTOMISATION_GENGAS_EDIT,
				ZLD2: LAYOUT_CUSTOMISATION_ZLD_EDIT,
				PWR: LAYOUT_CUSTOMISATION_POWER_EDIT,
				SOR: LAYOUT_CUSTOMISATION_SOR_EDIT,
				TANK: LAYOUT_CUSTOMISATION_TANK_EDIT
			};

			for (let i = 0; i < this.layoutLists.length; i++) {
				resultList.push({
					...this.layoutLists[i],
					module: mapModule[this.layoutLists[i].app] || this.layoutLists[i].app,
					routeTo: mapRoute[this.layoutLists[i].app]
				});
			}

			return resultList;
		}
	},
	methods: {
		handleClear() {
			this.keyword = "";
			this.moduleSelected = "";
			this.$router.push({
				name: LAYOUT_CUSTOMISATION_LIST
			}).catch(() => {});
		},

		handleKeyWordChange(value) {
			this.keyword = value;
		},

		handleSelectModule(value) {
			this.moduleSelected = value;
		},

		handlePageChange(value) {
			this.$router.push({
				name: LAYOUT_CUSTOMISATION_LIST,
				query: {
					...this.$route.query,
					page: value
				}
			});
		},

		handleSubmitForm() {
			this.$router.push({
				name: LAYOUT_CUSTOMISATION_LIST,
				query: {
					...(this.keyword ? { keyword: this.keyword } : {}),
					...(this.moduleSelected ? { module: this.moduleSelected } : {})
				}
			}).catch(() => {});
		},

		handleOpenCustomisationModal() {
			this.$modal.show("modal-layout-create", { moduleList: this.createModuleLists });
		},

		handleSubmitModule(value) {
			const mappingRouter = {
				GEN_GAS: () => {
					this.$router.push({
						name: LAYOUT_CUSTOMISATION_GENGAS_CREATE
					});
				},
				ZLD: () => {
					this.$router.push({
						name: LAYOUT_CUSTOMISATION_ZLD_CREATE
					});
				},
				PWR: () => {
					this.$router.push({
						name: LAYOUT_CUSTOMISATION_POWER_CREATE
					});
				},
				SOR: () => {
					this.$router.push({
						name: LAYOUT_CUSTOMISATION_SOR_CREATE
					});
				},
				TANK: () => {
					this.$router.push({
						name: LAYOUT_CUSTOMISATION_TANK_CREATE
					});
				}
			};
			const nextRoute = mappingRouter[value];
			nextRoute();
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.wrapper-empty {
	margin-top: rem(102);
}

.wrapper-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: rem(500);
}

.wrapper-list-customisation {
	display: flex;
	flex-direction: column;
	margin-top: rem(20);
	margin-bottom: rem(40);
}

.customisation-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: rem(56);
	width: 100%;
	padding: rem(16);
	background-color: $color-alabaster;
	border: rem(1) solid $color-silver;
	border-radius: rem(4);
	margin: rem(4) 0;

	&-module {
		min-width: rem(120);
		color: $color-grey-4;
	}

	&-layout-name {
		@include ellipsisOneline;

		width: rem(368);
		font-weight: $font-weight-regular;
		color: $color-dark-blue-grey;
	}

	&-edit-section {
		display: flex;
		align-items: center;
	}

	.edit-btn {
		color: $color-cerulean;
		font-size: rem(16);

		&:hover {
			color: $color-azure;
		}

		&:visited {
			color: $color-cerulean;
		}
	}

	.edit-icon {
		font-size: rem(12);
	}

	.module-type {
		color: $color-dark-blue-grey;
		font-weight: $font-weight-bold;
		margin-left: rem(8);
	}
}
</style>