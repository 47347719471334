import httpClient from "./httpClient";

const OWN_SOLD_TO = "/sold-tos";
const INVOICE = "/invoices";

const getOwnSoldToAPI = (params) => httpClient.get(OWN_SOLD_TO, { params });
const getInvoiceListAPI = (params) => httpClient.get(INVOICE, {
	params
});

export {
	getOwnSoldToAPI,
	getInvoiceListAPI
};
