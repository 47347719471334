<template>
	<a :class="['container-detail']">
		<div class="container-detail-icon">
			<div class="wrapper-icon">
				<NotFoundIcon />
			</div>
		</div>
		<div class="container-detail-info">
			<div class="container-detail-info-shipid">
				<span>
					{{ data.shipId }}
				</span>
			</div>
			<div class="container-detail-info-name">
				<span class="product-not-available">
					Product not available
				</span>
			</div>
		</div>
	</a>
</template>

<script>
import NotFoundIcon from "@/components/deviceIcons/NotFoundIcon.vue";

export default {
	name: "Notfund",

	components: {
		NotFoundIcon
	},

	props: {
		data: {
			type: Object,
			required: true,
			default: () => ({})
		}
	}
};
</script>

<style lang="scss" scoped>
.product-not-available {
	font-size: rem(20);
	height: rem(32);
}

.container-detail {
	@include fontBase();

	position: relative;
	display: flex;
	align-items: center;
	padding-right: rem(11);
	margin-bottom: rem(16);
	border-radius: 4px;
	border: 1px solid $color-silver;
	height: rem(145);
	background: $color-background;
	cursor: not-allowed;

	&-icon {
		display: flex;
		justify-content: center;
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-left: rem(12);
		margin-right: rem(12);

		.wrapper-icon {
			margin-top: rem(6);
			margin-left: rem(4);
		}
	}

	&-info {
		flex: 1;

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}
	}
}
</style>