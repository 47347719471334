import AlertAndNotification from "../../views/AlertAndNotification/index.vue";
import AlertAndNotificationList from "../../views/AlertAndNotification/list.vue";
import AlertAndNotificationCreate from "../../views/AlertAndNotification/Create.vue";
import AlertAndNotificationEdit from "../../views/AlertAndNotification/Edit.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

const {
	ALERT_AND_NOTIFICATION,
	ALERT_AND_NOTIFICATION_LIST,
	ALERT_AND_NOTIFICATION_CREATE,
	ALERT_AND_NOTIFICATION_EDIT
} = PAGE_NAME;
export default {
	path: "/alert-and-notification",
	name: ALERT_AND_NOTIFICATION,
	component: AlertAndNotification,
	children: [
		{
			path: "list",
			name: ALERT_AND_NOTIFICATION_LIST,
			component: AlertAndNotificationList,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "create",
			name: ALERT_AND_NOTIFICATION_CREATE,
			component: AlertAndNotificationCreate,
			meta: {
				requireAuth: true
			}
		},
		{
			path: "edit/:id",
			name: ALERT_AND_NOTIFICATION_EDIT,
			component: AlertAndNotificationEdit,
			meta: {
				requireAuth: true
			}
		}
	],
	meta: {
		requireAuth: true
	}
};