<template>
	<BaseFormInput
		v-model="input"
		:type="inputType"
		:placeholder="placeholder"
		:iconLeft="iconLeft"
		:iconRight="iconRight"
		:is-error="!!customErrorMessage || isError"
		:error-message="customErrorMessage || errorMessage"
		:disabled="disabled"
		:class="['custom-input', ...containerClass]"
		@onClickIconRight="handleClickIconRight"
		@blur="handleBlur"
	/>
</template>

<script>
import { getInvalidSymboolArray } from "../selectors/helpers";
import { getHasUpperCase, getHasLowwerCase, getHasNumber } from "../selectors/helpers/regex";

export default {
	name: "BaseFormInputPassword",

	props: {
		value: {
			type: String,
			default: ""
		},

		showIconLeft: {
			type: Boolean,
			default: true
		},

		showIconRight: {
			type: Boolean,
			default: true
		},

		placeholder: {
			type: String,
			default: ""
		},

		validate: {
			type: Boolean,
			default: true
		},

		disabled: {
			type: Boolean,
			default: false
		},

		formatType: {
			type: String,
			default: "simple",
			validator: (value) => {
				const containValues = ["simple", "compact"];
				return containValues.includes(value);
			}
		},

		matchWith: {
			type: String,
			default: null
		},

		customErrorMessage: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			input: "",
			isShowPassword: false,
			isError: false
		};
	},

	computed: {
		containerClass() {
			return [
				{
					"has-icons-left": this.showIconLeft
				},
				{
					"has-icons-right": this.showIconRight
				}
			];
		},

		inputType() {
			return this.isShowPassword
				? "text"
				: "password";
		},

		iconLeft() {
			if (!this.showIconLeft) {
				return null;
			}

			return "lock-alt";
		},

		iconRight() {
			if (!this.showIconRight) {
				return null;
			}

			return this.isShowPassword
				? "eye"
				: "eye-slash";
		},

		errorMessage() {
			if (this.input.trim().length === 0) {
				return "Required field";
			}
			if (this.matchWith) {
				if (this.matchWith !== this.input) {
					return "Your password don’t match";
				}
			}
			if (this.formatType === "compact" && getInvalidSymboolArray(this.input).length) {
				return "Symbol must be only ~!@#$%^&*()-+=?<>|[]{}_ :;.,`";
			}
			return this.formatType === "simple"
				? "Password must be at least 8 characters"
				: "Password must be correct as specified format";
		}
	},

	watch: {
		input() {
			this.$emit("input", this.input);
			this.checkValid();
		},
		matchWith() {
			this.checkValid();
		}
	},

	created() {
		this.input = this.value;
	},

	methods: {
		handleClickIconRight() {
			this.isShowPassword = !this.isShowPassword;
		},

		handleBlur() {
			if (this.validate) {
				this.checkValid();
			}
		},

		validFormat() {
			let valid;

			if (this.formatType === "simple") {
				valid = this.input.length >= 8;
			} else {
				valid = [
					this.input.length >= 8,
					getHasNumber(this.input),
					getHasLowwerCase(this.input),
					getHasUpperCase(this.input),
					getInvalidSymboolArray(this.input).length === 0
				].every((v) => v);
			}

			if (this.matchWith && valid) {
				valid = this.matchWith === this.input;
			}

			return valid;
		},

		// side effect
		checkValid() {
			this.isError = !this.validFormat();
			this.$emit("update:valid", this.validFormat());
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.custom-input::v-deep .icon.is-right {
	cursor: pointer;
}
/* stylelint-enable */
</style>