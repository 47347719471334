<template>
	<div class="device-container">
		<router-link :to="deviceLink" :class="['container-detail', iconSize]">
			<div class="container-detail-icon">
				<div class="wrapper-icon">
					<PowerIcon
						:kilowatt="kw"
						:value="value"
						:empty="empty"
						:size="newIconSize"
					/>
				</div>
			</div>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div class="container-detail-info-tag">
					<span>
						MTD Power (kWh)
					</span>
					<span>
						{{ data.MTDPower | currency }}
					</span>
				</div>
			</div>
		</router-link>
		<div class="pin-right" >
			<img @click="onClickSync" src="/assets/icons/icons-default-font-small@2x.png">
		</div>
	</div>
</template>

<script>
import PowerIcon from "@/components/deviceIcons/PowerIcon.vue";
import { getElectricityPercentage } from "../../../../selectors/helpers/";

export default {
	name: "PowerDetail",

	components: {
		PowerIcon
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	methods: {
		onClickSync() {
			this.$emit("onClickSync", this.data);
		}
	},

	computed: {
		// set new icon because in PowerIcon component
		// it have supported only 'small', 'medium' and 'large' sizes
		newIconSize() {
			if (this.iconSize === "smallest") {
				return "small";
			} else if (this.iconSize === "small") {
				return "medium";
			}
			return this.this.iconSize;
		},
		empty() {
			return this.data.KW === undefined || this.data.KW === null;
		},
		kw() {
			return this.empty ? 0 : this.data.KW;
		},
		value() {
			return getElectricityPercentage(this.data.KW, this.data.maxRange);
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}
			return this.data.shipId;
		}
	}
};
</script>

<style lang="scss" scoped>
.device-container {
	position: relative;

	.pin-right {
		position: absolute;
		top: rem(8);
		right: rem(8);

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		gap: rem(8);
		height: 100%;
		cursor: pointer;

		img {
			width: rem(24);
			height: rem(24);
		}

		.tag {
			margin-right: rem(8);
		}
	}
}

.container-detail {
	@include fontBase();

	position: relative;
	display: flex;
	align-items: flex-start;
	padding-right: rem(11);
	margin-bottom: rem(16);
	border-radius: 4px;
	border: 1px solid $color-silver;
	height: rem(120);

	&:hover {
		background-color: $color-ice-two;

		/* stylelint-disable */
		&::v-deep .tank-icon-bg {
			background-image: url("/assets/images/tank-icon-hover@2x.svg");
		}
		/* stylelint-enable */
	}

	&.smallest > &-icon {
		width: rem(64);
		height: rem(64);
	}

	&-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-right: rem(12);
		margin-left: rem(12);

		.wrapper-icon {
			margin-top: rem(4);
			margin-left: rem(4);
		}
	}

	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;

			span {
				line-height: rem(14);
			}
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		&-tag {
			display: flex;
			align-items: center;
			height: rem(14);
			margin-top: rem(4);
			font-size: $font-14;
			color: $color-dark-blue-grey;
			line-height: rem(16);

			span {
				&:first-of-type {
					display: flex;
					width: rem(100);

					sup {
						position: relative;
						top: rem(-3);
					}
				}

				&:last-of-type {
					flex: 1;
					font-weight: $font-weight-bold;
					text-align: right;
				}
			}
		}
	}
}
</style>