import { render, staticRenderFns } from "./TheMainSidebarMenu.vue?vue&type=template&id=15986b97&scoped=true&"
import script from "./TheMainSidebarMenu.vue?vue&type=script&lang=js&"
export * from "./TheMainSidebarMenu.vue?vue&type=script&lang=js&"
import style0 from "./TheMainSidebarMenu.vue?vue&type=style&index=0&id=15986b97&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15986b97",
  null
  
)

export default component.exports