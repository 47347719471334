import { getWaterFlowAPI } from "../../../services/api/Cers.api";
import {
	CER_WATERFLOW_REQUEST,
	CER_WATERFLOW_SUCCESS,
	CER_WATERFLOW_FAILURE
} from "../../types";
import { transformFlowRateLineChartData } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},
	getters: {
		getTransformWaterFlowData: (state) => {
			return transformFlowRateLineChartData(state);
		}
	},
	mutations: {
		[CER_WATERFLOW_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[CER_WATERFLOW_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[CER_WATERFLOW_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchWaterFlow({ commit }, { id = "", query = {} }) {
			try {
				commit(CER_WATERFLOW_REQUEST);

				const { data } = await getWaterFlowAPI(id, query);

				commit(CER_WATERFLOW_SUCCESS, data);
			} catch (error) {
				commit(CER_WATERFLOW_FAILURE);
			}
		}
	}
};
