<template>
	<BaseLayout :isLoading="isLoading">
		<BaseBackBtn :to="NOTIFICATION_GROUP_LIST" class="page-back-btn"  label="Back to Notification group"/>

		<h2 class="head-title">
			Edit group
		</h2>

		<BaseLoading
			v-if="isLoading"
			class="wrapper-loading"
		/>
		<BaseCard
			class="card-notification-group"
		>
			<template #header>
				<h4>Notification group</h4>
			</template>
			<template #body>
				<div class="container-form">
					<div class="item">
						<div class="label">
							Channel
						</div>
						<div class="form">
							{{ channelName }}
						</div>
					</div>
				</div>
				<template v-if="isNotificationGroupEmailOrInAppTypes">
					<BaseFormInput
						v-model="$v.notificationGroupName.$model"
						:inputOptions="['small']"
						:isError="isNotificationNameError"
						:errorMessage="getNotificationNameErrorMessage"
						class="notification-name"
						label="Group name"
						placeholder="e.g. Email - Application alert"
						inline
					/>
					<BaseMultipleSelect
						:options="getNotificationContactLists"
						:value="contectSelected"
						:limit="10"
						:isError="isContactSelectError"
						:errorMessage="getContactMessage"
						label="Contacts"
						placeholder="Select contact or typing email"
						tagPlaceholder="Select to add email"
						multiple
						:taggable="!isInAppType"
						defaultHeight
						@onSelectTag="handleSelectTag"
						@onSelectValue="handleSelectContact"
						@onRemoveSelected="handleRemoveContact"
						@onOpenDropdown="handleOpendropdown"
					></BaseMultipleSelect>
				</template>
				<template v-else-if="channelSelect === 'line'">
					<BaseFormInput
						v-model="$v.notificationGroupName.$model"
						:inputOptions="['small']"
						:isError="isNotificationNameError"
						:errorMessage="getNotificationNameErrorMessage"
						class="notification-name"
						label="Group name"
						placeholder="e.g. Application alert"
						inline
					/>
					<div class="container-form">
						<div class="item mb-0">
							<div class="label label-token">
								Token
							</div>
							<div class="form">
								<BaseTextArea
									v-model="lineGroupToken"
									:placeholder="`Line group token`"
								/>
							</div>
						</div>
					</div>
				</template>
			</template>
		</BaseCard>
		<div
			v-if="!isLoading"
			class="wrapper-confirm-create"
		>
			<div class="wrapper-delete-btn">
				<BaseButton
					class="delete-btn"
					size="small"
					type="secondary"
					link
					@click="handleDeleteNotificationGroup"
				>
					Delete this group
				</BaseButton>
			</div>
			<div class="wrapper-save-and-cancle">
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleCancle"
				>
					Cancel
				</BaseButton>

				<BaseButton
					:disabled="isDisabledCreate"
					size="large"
					type="primary"
					@click="handleSubmit(handleSendPayload)"
				>
					Apply change
				</BaseButton>
			</div>
		</div>
		<DialogLayoutDelete
			:modal-name="MODAL_DELETE_NOTIFICATION"
			title="Delete this group?"
			message="Are you sure to delete this group? Deleting this group will also detach all Notification rules related."
			@onSubmit="handleSubmitDeleteLayout"
		/>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import BaseLayout from "@/components/BaseLayout.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import DialogLayoutDelete from "@/components/BaseDialogDelete.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import { checkIfValid, getvalidateMessage } from "../../selectors/formValidate/validateHelper";
import { deleteNotificationGroupId, getChannelContactLists, getNotificationGroupId, updateNotificationGroupId } from "../../services/api/notificationGroup.api";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { NOTIFICATION_GROUP_TYPES } from "../../enums/notiticationGroup";

const { NOTIFICATION_GROUP_LIST } = PAGE_NAME;

export default {
	components: {
		BaseLayout,
		BaseBackBtn,
		BaseCard,
		BaseFormInput,
		BaseMultipleSelect,
		BaseLoading,
		DialogLayoutDelete,
		BaseTextArea
	},
	mixins: [
		validationMixin
	],
	validations: {
		notificationGroupName: {
			required,
			maxLength: maxLength(255)
		},
		channelSelect: {
			required
		}
	},
	data() {
		return {
			MODAL_DELETE_NOTIFICATION: "modal-delete-notification",
			isLoading: false,
			notificationGroupId: this.$route.params.id,
			NOTIFICATION_GROUP_LIST,
			notificationGroupName: "",
			channelSelect: "",
			channelLists: [
				{
					label: "Email",
					value: "email"
				}
			],
			contactLists: [],
			contectSelected: [],
			contactType: "",
			lineGroupToken: "",
			loading: false
		};
	},
	async created() {
		this.isLoading = true;
		const [
			{ data: nitificationGroupDetail },
			{ data: contactLists }
		] = await Promise.all([
			getNotificationGroupId(this.notificationGroupId),
			getChannelContactLists(),
			this.getNotificationConfigs()
		]);
		const { name, contact = [], channel, token } = nitificationGroupDetail;
		this.notificationGroupName = name;
		this.channelSelect = channel;
		if (channel === NOTIFICATION_GROUP_TYPES.EMAIL || channel === NOTIFICATION_GROUP_TYPES.IN_APP) {
			this.contectSelected = contact.map((e) => ({
				key: e,
				name: e,
				code: e
			}));
		} else if (channel === "line") {
			this.lineGroupToken = token;
		}
		this.contactLists = contactLists;
		this.isLoading = false;
	},
	computed: {
		...mapState("notiGroupConfig", {
			configs: "data"
		}),

		getNotificationContactLists() {
			return this.contactLists.map((e) => ({
				key: e,
				name: e,
				code: e
			}));
		},

		isContactSelectError() {
			return !email(this.contactType) && this.contactType !== "";
		},

		getContactMessage() {
			if (this.contactType !== "" && !email(this.contactType)) {
				return "Invalid email format";
			}
			return "";
		},
		isChannelError() {
			const field = this.$v.channelSelect;
			return checkIfValid(field);
		},

		getChannelErrorMessage() {
			const field = this.$v.channelSelect;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isNotificationNameError() {
			const field = this.$v.notificationGroupName;
			return checkIfValid(field);
		},

		getNotificationNameErrorMessage() {
			const field = this.$v.notificationGroupName;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length: 1 - 255 Characters"
			});
		},
		isDisabledCreate() {
			if (this.isNotificationGroupEmailOrInAppTypes) {
				return this.contectSelected.length === 0;
			}
			if (this.channelSelect === NOTIFICATION_GROUP_TYPES.LINE) {
				return this.lineGroupToken.trim().length === 0;
			}
			return false;
		},
		getPayloadSend() {
			return {
				name: this.notificationGroupName,
				channel: this.channelSelect,
				...(() => {
					if (this.isNotificationGroupEmailOrInAppTypes) {
						return {
							contact: this.contectSelected.map((value) => value.name)
						};
					}
					if (this.channelSelect === NOTIFICATION_GROUP_TYPES.LINE) {
						return {
							token: this.lineGroupToken.trim()
						};
					}
					return null;
				})()
			};
		},
		channelName() {
			const config = this.configs.find((item) => item.channel === this.channelSelect);
			return !config
				? null
				: config.displayName;
		},

		isNotificationGroupEmailOrInAppTypes() {
			return !!(
				this.channelSelect === NOTIFICATION_GROUP_TYPES.EMAIL ||
				this.channelSelect === NOTIFICATION_GROUP_TYPES.IN_APP
			);
		},

		isInAppType() {
			return this.channelSelect === NOTIFICATION_GROUP_TYPES.IN_APP;
		}
	},
	methods: {
		...mapActions("notiGroupConfig", {
			getNotificationConfigs: "getNotificationConfigs"
		}),
		...mapActions("toast", {
			showToast: "show"
		}),

		handleDeleteNotificationGroup() {
			this.$modal.show(this.MODAL_DELETE_NOTIFICATION, {
				dataId: this.$route.params.id
			});
		},

		async handleSubmitDeleteLayout(data) {
			const { dataId } = data;
			await deleteNotificationGroupId(dataId);
			this.$router.push({
				name: PAGE_NAME.NOTIFICATION_GROUP_LIST
			});

			this.showToast({
				type: TOAST_TYPES.LAYOUT_CUSTOMISATION,
				props: { message: `Notification group ${this.notificationGroupName} delete success.`, delay: 6000 }
			});
		},

		handleSelectTag(value) {
			const tagEmail = {
				key: value,
				name: value,
				code: value
			};
			if (email(value)) {
				this.contectSelected = [
					...this.contectSelected,
					tagEmail
				];

				this.contactLists = [
					...this.contactLists,
					value
				];
			} else {
				this.contactType = value;
			}
		},
		handleOpendropdown() {
			this.contactType = "";
		},

		handleSelectChannel(value) {
			this.channelSelect = value;
		},

		handleSelectContact(value) {
			this.contectSelected = [
				...this.contectSelected,
				value
			];
		},

		handleRemoveContact(value) {
			this.contectSelected = this.contectSelected.filter(({ key }) => {
				return key !== value.code;
			});
		},

		handleCancle() {
			this.$router.push({
				name: this.NOTIFICATION_GROUP_LIST
			});
		},

		handleSubmit(callback) {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				callback();
			}
		},


		async handleSendPayload() {
			try {
				await updateNotificationGroupId(this.notificationGroupId, this.getPayloadSend);

				this.$router.push({
					name: this.NOTIFICATION_GROUP_LIST
				});

				this.showToast({
					type: TOAST_TYPES.LAYOUT_CUSTOMISATION,
					props: { message: `Notification group update success.`, delay: 6000 }
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to save", message: "Gen Gas layout unable to save.", delay: 6000 }
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.card-notification-group {
	margin-top: rem(24);
}

.wrapper-loading {
	display: flex;
	align-items: center;
	min-height: rem(500);
}

.notification-name,
.channel-dropdown {
	margin-bottom: rem(24);
}

.wrapper-save-and-cancle {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.delete-btn {
	font-size: rem(18);
}

.wrapper-confirm-create {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem(24) 0;
	border-top: rem(1) solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.head-title {
	font-family: $font-family-sub;
	font-size: $font-32;
	color: $color-dark-blue-grey;
	margin: rem(19) 0 rem(16) 0;
}

.container-form {
	.item {
		margin-bottom: rem(24);
		display: flex;

		&.mb-0 {
			margin-bottom: 0;
		}

		.label {
			display: flex;
			align-items: center;
			min-width: rem(130);

			&.label-token {
				align-items: flex-start;
				margin-top: rem(8);
			}
		}

		.form {
			flex-grow: 1;
		}
	}
}
</style>