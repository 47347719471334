<template>
	<BaseToastContent
		:message="message"
		:title="title"
		:color="color"
	/>
</template>

<script>
import BaseToastContent from "@/components/BaseToastContent.vue";

export default {
	name: "ToastProcessing",

	props: {
		title: {
			type: String,
			default: ""
		},
		color: {
			type: String,
			default: ""
		},
		message: {
			type: String,
			default: ""
		}
	},

	components: {
		BaseToastContent
	}
};
</script>