import {
	MAINTENANCE_STATUS_REQUEST,
	MAINTENANCE_STATUS_SUCCESS,
	MAINTENANCE_STATUS_FAILURE,
	POST_MAINTENANCE_STATUS_REQUEST,
	POST_MAINTENANCE_STATUS_SUCCESS,
	POST_MAINTENANCE_STATUS_FAILURE
} from "../../types";

import { parseMaintenanceStatus } from "../../../services/api/transforms/parseMaintenanceStatus";
import {
	getMaintenanceStatusAPI,
	postMaintenanceStatusAPI
} from "../../../services/api/maintenanceStatus.api";

export default {
	namespaced: true,

	state: {
		data: parseMaintenanceStatus(null),
		isUpdating: false
	},

	mutations: {
		[MAINTENANCE_STATUS_REQUEST](state) {
			state.data = parseMaintenanceStatus(null);
		},
		[MAINTENANCE_STATUS_SUCCESS](state, { data }) {
			state.data = parseMaintenanceStatus(data);
		},
		[MAINTENANCE_STATUS_FAILURE](state) {
			state.data = parseMaintenanceStatus(null);
		},
		[POST_MAINTENANCE_STATUS_REQUEST](state) {
			state.isUpdating = true;
		},
		[POST_MAINTENANCE_STATUS_SUCCESS](state) {
			state.isUpdating = false;
		},
		[POST_MAINTENANCE_STATUS_FAILURE](state) {
			state.isUpdating = false;
		}
	},

	actions: {
		async getMaintenanceStatus({ commit }) {
			try {
				commit(MAINTENANCE_STATUS_REQUEST);

				const response = await getMaintenanceStatusAPI();

				commit(MAINTENANCE_STATUS_SUCCESS, response);
			} catch (error) {
				commit(MAINTENANCE_STATUS_FAILURE);
			}
		},

		async postMaintenanceStatus({ commit }, params) {
			try {
				commit(POST_MAINTENANCE_STATUS_REQUEST);

				await postMaintenanceStatusAPI(params);

				commit(POST_MAINTENANCE_STATUS_SUCCESS);
			} catch (error) {
				commit(POST_MAINTENANCE_STATUS_FAILURE);
			}
		}
	}
};