import httpClient from "./httpClient";

const AIR_COMP_END_POINT = "/airComp";

const getAirCompInfoAPI = (id) => httpClient.get(`${AIR_COMP_END_POINT}/${id}`);

const getAirCompContactAPI = (id) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/contact`);

const getAirCompPressureAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/system-pressure`, { params });

const getAirCompSECAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/sec`, { params });

const getAirCompPowerAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/power`, { params });

const getAirCompFlowRateAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/air-flow`, { params });

const getAirCompPowerCostAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/powerCost`, { params });

const getAirCompOverallEnergyAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/overall-energy`, { params });

const getAirCompOverallAirVolumeAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/overall-air`, { params });

const getAirCompEnergySavingAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/energy-saving`, { params });

const getAirCompHistoriesAPI = (id, params) => httpClient.get(`${AIR_COMP_END_POINT}/${id}/order-histories`, { params });

export {
	getAirCompInfoAPI,
	getAirCompPressureAPI,
	getAirCompSECAPI,
	getAirCompPowerAPI,
	getAirCompFlowRateAPI,
	getAirCompPowerCostAPI,
	getAirCompHistoriesAPI,
	getAirCompOverallEnergyAPI,
	getAirCompOverallAirVolumeAPI,
	getAirCompEnergySavingAPI,
	getAirCompContactAPI
};
