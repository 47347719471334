<template>
	<div>
		<div class="container-head">
			<Sticky>
				<ColumnHead/>
			</Sticky>
		</div>
		<div class="container-items">
			<NitroFASOrderHistoryItem
				v-for="(item, index) in items"
				:key="index"
				:index="index"
				:item="item"
				class="item"
			/>
		</div>
	</div>
</template>

<script>
import ColumnHead from "@/components/dashboard/modules/NitroFASOrderHistory/ColumnHead.vue";
import NitroFASOrderHistoryItem from "@/components/dashboard/modules/NitroFASOrderHistory/NitroFASOrderHistoryItem.vue";
import Sticky from "@/components/Sticky.vue";

export default {
	name: "NitroFASOrderHistoryList",

	components: {
		ColumnHead,
		NitroFASOrderHistoryItem,
		Sticky
	},

	props: {
		items: {
			type: Array,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.container-head {
	display: flex;
}

.container-items {
	display: flex;
	flex-direction: column;
	margin: rem(24) auto;
	overflow: hidden;

	.item {
		margin-bottom: rem(18);
		overflow: hidden;
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}
</style>