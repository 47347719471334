import {
	OVER_ALL_USAGES_REQUEST,
	OVER_ALL_USAGES_SUCCESS,
	OVER_ALL_USAGES_FAILURE
} from "../../types";

import { getUserUsagesAPI } from "../../../services/api/monitor.api";

export default {
	namespaced: true,

	state: {
		osUsages: [],
		browserUsages: [],
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getOperationSystemUsages(state) {
			return state.osUsages;
		},
		getBrowserUsages(state) {
			return state.browserUsages;
		}
	},
	mutations: {
		[OVER_ALL_USAGES_REQUEST](state) {
			state.isLoading = true;
		},
		[OVER_ALL_USAGES_SUCCESS](state, data) {
			state.isLoading = false;
			state.osUsages = data.totalOs || [];
			state.browserUsages = data.totalBrowsers || [];
		},
		[OVER_ALL_USAGES_FAILURE](state) {
			state.isLoading = false;
			state.osUsages = [];
			state.browserUsages = [];
			state.isEmpty = true;
		}

	},

	actions: {
		async getUserUsages({ commit }, { filter = "" }) {
			try {
				commit(OVER_ALL_USAGES_REQUEST);
				const computeFilter = filter ? { filter } : {};
				const { data } = await getUserUsagesAPI(computeFilter);
				commit(OVER_ALL_USAGES_SUCCESS, data);
			} catch (e) {
				commit(OVER_ALL_USAGES_FAILURE);
			}
		}
	}
};
