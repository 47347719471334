<template>
	<modal
		class="modal-save-as-new-template"
		name="modal-save-as-new-template"
		:scrollable="true"
		width="420"
		height="auto"
		@before-close="handleCloseModal"
	>
		<BaseModalContent>
			<template v-slot:header>
				Save as new template
				<span class="close-button" @click="hideModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="title"
								:inputOptions="['small']"
								:is-error="isTitleError"
								:error-message="getTitleErrorMessage"
								label="Title"
								class="title"
								placeholder="Template title"
								:maxLength="40"
								inline
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					type="secondary"
					link
					size="medium"
					@click="hideModal"
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="medium"
					@click="handleSubmit"
					:disabled="$v.$invalid"
				>
					Save as new template
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";
import { required } from "vuelidate/lib/validators";
import { checkDuplicateTitleAPI } from "@/services/api/standardModule.api";
import { checkIfValid, getvalidateMessage } from "../../../selectors/formValidate/validateHelper";

export default {
	name: "ModalSaveAsNewTemplate",

	components: {
		BaseModalContent
	},

	data() {
		return {
			title: null,
			id: null
		};
	},

	validations: {
		title: {
			required,
			async duplicate(value) {
				try {
					if (value && value !== "") {
						this.$v.title.$touch();
						const params = {
							title: value,
							id: this.id
						};
						const { data } = await checkDuplicateTitleAPI(params);
						return !data.titleDuplicate;
					}
				} catch {
					return true;
				}
				return true;
			}
		}
	},

	methods: {
		hideModal() {
			this.$modal.hide("modal-save-as-new-template");
		},

		handleSubmit() {
			this.$emit("submit", this.title);
			this.hideModal();
		},

		handleCloseModal() {
			this.title = null;
		}
	},

	computed: {
		disabledConfirm() {
			return this.overviewTypeSelected ? false : this.overviewTypeSelected || true;
		},

		isTitleError() {
			const field = this.$v.title;
			return checkIfValid(field);
		},

		getTitleErrorMessage() {
			const field = this.$v.title;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				duplicateMessage: "This title is currently in use."
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-save-as-new-template {
	::v-deep {
		.v--modal-box {
			overflow: initial !important;
		}
	}

	.container-form {
		&.active {
			margin-bottom: rem(40);
		}

		.item {
			margin-bottom: $spacing-6;
			display: flex;

			.label {
				display: flex;
				align-items: center;
				min-width: rem(120);
			}

			.form {
				flex-grow: 1;
			}
		}

		.item:last-of-type {
			margin-bottom: 0;
		}
	}

	.notify-error {
		margin-bottom: $spacing-6;
	}

	::v-deep.error-message {
		margin-left: rem(130) !important;
	}
}
</style>