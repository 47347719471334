<template>
	<label class="switch" :disabled="disabled">
		<input
			:checked="checked"
			:name="name"
			:disabled="disabled"
			type="checkbox"
			@input="handleChange"
		>
		<span class="slider round" :disabled="disabled"></span>
	</label>
</template>

<script>
export default {
	name: "BaseSwitch",
	props: {
		checked: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		handleChange() {
			this.$emit("input", !this.checked, this.name);
		}
	}
};
</script>

<style lang="scss" scoped>
.switch {
	position: relative;
	display: inline-block;
	width: rem(48);
	height: rem(24);

	&[disabled] {
		cursor: not-allowed;
	}
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $color-mineral-green;
	-webkit-transition: $transition-duration;
	transition: $transition-duration;

	&::before {
		position: absolute;
		content: "";
		height: rem(16);
		width: rem(16);
		left: rem(4);
		bottom: rem(4);
		background-color: white;
		-webkit-transition: $transition-duration;
		transition: $transition-duration;
	}

	&.round {
		border-radius: rem(34);

		&::before {
			border-radius: 50%;
		}
	}

	&[disabled] {
		background-color: $color-grey-3;
		opacity: 0.6;
		cursor: not-allowed;
	}
}

input {
	&:checked {
		+ .slider {
			background-color: $color-azure;

			&::before {
				-webkit-transform: translateX(rem(24));
				-ms-transform: translateX(rem(24));
				transform: translateX(rem(24));
			}
		}
	}

	&:focus {
		+ .slider {
			box-shadow: 0 0 1px $color-azure;
		}
	}

	&[disabled] {
		cursor: not-allowed;
	}
}

</style>