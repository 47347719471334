import dayjs from "dayjs";
import { getDeviceInactiveAPI } from "@/services/api/devices.api";
import { DEVICE_INACTIVE_REQUEST, DEVICE_INACTIVE_SUCCESS, DEVICE_INACTIVE_FAILURE } from "../../types";
import { parseDevicesInactive } from "../../../services/api/transforms/parseDevice";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		},
		isEmpty: false,
		lastUpdate: null
	},

	getters: {
		getLastUpdate: (state) => state.lastUpdate
	},

	mutations: {
		[DEVICE_INACTIVE_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
			state.lastUpdate = null;
		},
		[DEVICE_INACTIVE_SUCCESS](state, { data, pagination }) {
			state.data = parseDevicesInactive(data);
			state.pagination = pagination;
			state.isEmpty = data.length === 0;
			state.lastUpdate = dayjs().format("D MMM YYYY, HH:mm");
			state.isLoading = false;
		},
		[DEVICE_INACTIVE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.lastUpdate = null;
		}
	},

	actions: {
		async getDevicesInactive({ commit }, { q, productname, module, page }) {
			try {
				commit(DEVICE_INACTIVE_REQUEST);
				const { data } = await getDeviceInactiveAPI({ q, productname, module, page });
				commit(DEVICE_INACTIVE_SUCCESS, data);
			} catch (error) {
				commit(DEVICE_INACTIVE_FAILURE);
			}
		}
	}
};
