import icon01d from "../../assets/images/weather/01d.svg";
import icon01n from "../../assets/images/weather/01n.svg";
import icon02d from "../../assets/images/weather/02d.svg";
import icon02n from "../../assets/images/weather/02n.svg";
import icon03d from "../../assets/images/weather/03d.svg";
import icon03n from "../../assets/images/weather/03n.svg";
import icon04d from "../../assets/images/weather/04d.svg";
import icon04n from "../../assets/images/weather/04n.svg";
import icon09d from "../../assets/images/weather/09d.svg";
import icon09n from "../../assets/images/weather/09n.svg";
import icon10d from "../../assets/images/weather/10d.svg";
import icon10n from "../../assets/images/weather/10n.svg";
import icon11d from "../../assets/images/weather/11d.svg";
import icon11n from "../../assets/images/weather/11n.svg";
import icon13d from "../../assets/images/weather/13d.svg";
import icon13n from "../../assets/images/weather/13n.svg";
import icon50d from "../../assets/images/weather/50d.svg";
import icon50n from "../../assets/images/weather/50n.svg";

export const getWeatherIcon = (code = "") => {
	const mapIcons = {
		// clear sky
		"01d": icon01d,
		"01n": icon01n,
		// 	few clouds
		"02d": icon02d,
		"02n": icon02n,
		// scattered clouds
		"03d": icon03d,
		"03n": icon03n,
		// broken clouds
		"04d": icon04d,
		"04n": icon04n,
		// shower rain
		"09d": icon09d,
		"09n": icon09n,
		// 	rain
		"10d": icon10d,
		"10n": icon10n,
		// thunderstorm
		"11d": icon11d,
		"11n": icon11n,
		// snow
		"13d": icon13d,
		"13n": icon13n,
		// mist
		"50d": icon50d,
		"50n": icon50n
	};
	return mapIcons[code];
};