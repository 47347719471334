import { MAINTENANCE_STATUS } from "../../../enums/maintenanceStatus";
/**
 * Parse maintenance status
 * @param {Object} input maintenance status data
 * @returns {MaintenanceStatus}
 */
export const parseMaintenanceStatus = (item) => {
	const status = item?.status === MAINTENANCE_STATUS.ENABLED ?? false;

	return {
		status,
		description: item?.description ?? null
	};
};