<template>
	<div>
		<BaseLoading
			v-if="loading"
		/>
		<BaseNoResult
			v-else-if="items.length === 0"
			description="Try adjusting your search by changing your template name."
		/>
		<template
			v-else
		>
			<StandardModuleListItem
				v-for="(item, index) in items"
				:key="index"
				:item="item"
				:query="query"
				@handleModal="handleShowModal"
			/>
		</template>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import StandardModuleListItem from "@/components/StandardModule/StandardModuleListItem.vue";

export default {
	components: {
		BaseLoading,
		BaseNoResult,
		StandardModuleListItem
	},

	props: {
		loading: {
			type: Boolean,
			default: false
		},
		items: {
			type: Array,
			default: () => ([])
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	methods: {
		handleShowModal(params) {
			this.$emit("handleModal", params);
		}
	}
};
</script>