<template>
	<div class="container">
		<div :style="style" class="progress"></div>
	</div>
</template>

<script>
export default {
	name: "BarProgress",

	props: {
		value: {
			type: Number,
			default: 0,
			validator: (value) => value >= 0 && value <= 100
		}
	},

	computed: {
		style() {
			return { width: `${this.value}%` };
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	height: rem(16);
	padding: 0;
	background-color: $color-silver;
	border-radius: rem(4);
	overflow: hidden;

	.progress {
		background-color: $color-emerald;
		height: 100%;
	}
}
</style>