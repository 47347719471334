<template>
	<div class="container">
		<div :style="style" class="progress"></div>
	</div>
</template>

<script>
export default {
	name: "BarProgress",

	props: {
		data: {
			type: Object,
			default: () => ({})
		}
	},

	computed: {
		style() {
			return {
				width: `${this.data?.pctValue ?? 0}%`,
				backgroundColor: this.data?.bgColor ?? `#009b44`
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	height: rem(16);
	padding: 0;
	background-color: $color-silver;
	border-radius: rem(4);
	overflow: hidden;

	.progress {
		background-color: $color-emerald;
		height: 100%;
	}
}
</style>