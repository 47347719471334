<template>
	<div ref="table" class="container-custom">
		<BaseLoading v-if="showLoading" />
		<BaseNoResult
			v-else-if="showNoResult"
			description="Try adjusting your search by changing your keyword or removing filters."
		/>
		<div v-else-if="showTable" class="container-custom-main">
			<slot name="companyName" class="company-name"></slot>
			<slot name="tableTitle"></slot>
			<table :class="['container-custom-main-table', tableOptions]">
				<thead>
					<slot name="thead"></slot>
				</thead>
				<tbody>
					<slot name="tbody"></slot>
				</tbody>
			</table>
		</div>
		<BasePagination
			v-if="showPagination && !loading"
			:value="pagination.currentPage"
			:totalPage="pagination.lastPage"
			class="module-pagination"
			@onPageChange="$emit('onPageChange', $event)"
		/>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import tableStickyMixin from "../mixins/tableSticky";

export default {
	name: "BaseTableCustom",

	mixins: [tableStickyMixin],

	components: {
		BaseLoading,
		BaseNoResult
	},

	props: {
		tableOptions: {
			type: Array,
			default: () => []
		},
		loading: {
			type: Boolean,
			default: true
		},
		pagination: {
			type: Object,
			default: () => ({})
		},
		stickyHeader: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			isScrollbarXVisible: false,
			tableHeight: 0
		};
	},

	computed: {
		showLoading() {
			return this.loading;
		},
		showNoResult() {
			return this.pagination.lastPage === 0 && !this.loading;
		},
		showTable() {
			return !this.loading && (this.pagination.lastPage ? this.pagination.lastPage > 0 : true);
		},
		showPagination() {
			return this.pagination.lastPage > 1;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-custom {
	position: relative;

	&-main {
		overflow: auto;
		overflow-y: hidden;
		max-width: 100%;

		.company-name {
			padding: rem(16);
			margin: 0;

			font-size: $font-18;
		}

		.table-title {
			padding: rem(16);
			background-color: $color-alabaster;

			font-size: $font-20;
		}
	}

	&-main-shadow-right {
		position: absolute;
		top: 0;
		right: rem(-12);
		width: rem(12);
		box-shadow: -5px 0 10px -5px #333;
	}

	&-main-table {
		background-color: $table-background-color;
		color: $table-color;

		td,
		th {
			padding: $table-cell-padding;
			vertical-align: top;
		}

		th {
			border: $table-cell-border;
			border-width: $table-cell-border-width;
			color: $table-cell-heading-color;

			&:not([align]) {
				text-align: left;
			}
		}

		thead {
			background-color: $table-head-background-color;
			position: relative;
			z-index: 3;

			tr {
				background-color: $table-background-color;

				td,
				th {
					color: $table-head-cell-color;
					border-width: $table-head-cell-border-width;
					font-size: $font-20;
					font-weight: 600;
				}
			}
		}

		tbody {
			background-color: $table-body-background-color;

			tr {
				&:last-child {
					td,
					th {
						border-bottom-width: 0;
					}
				}
			}

			.bold {
				font-weight: 600;
			}
		}

		tfoot {
			background-color: $table-foot-background-color;

			td,
			th {
				border-width: $table-foot-cell-border-width;
				color: $table-foot-cell-color;
			}
		}

		&.is-selected {
			background-color: $table-row-active-background-color;
			color: $table-row-active-color;

			a,
			strong {
				color: inherit;
			}

			td,
			th {
				border-color: $table-row-active-color;
				color: inherit;
			}
		}

		// Modifiers
		&.is-bordered {
			td,
			th {
				border-width: 1px;
			}

			tr {
				&:last-child {
					td,
					th {
						border-bottom-width: 1px;
					}
				}
			}
		}

		&.is-fullwidth {
			width: 100%;
		}

		&.is-hoverable {
			tbody {
				tr:not(.is-selected) {
					&:hover {
						background-color: $table-row-hover-background-color;
					}
				}
			}

			&.is-striped {
				tbody {
					tr:not(.is-selected) {
						&:hover {
							background-color: $table-row-hover-background-color;

							&:nth-child(even) {
								background-color: $table-striped-row-even-hover-background-color;
							}
						}
					}
				}
			}
		}

		&.is-striped {
			tbody {
				tr:not(.is-selected) {
					&:nth-child(even) {
						background-color: $table-striped-row-even-background-color;
					}
				}
			}
		}

		&.is-padding-none {
			th,
			td {
				padding: unset;
			}
		}

		&.is-responsive {
			overflow-x: auto;
			white-space: nowrap;
		}
	}
}
</style>
