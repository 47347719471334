import { getLngMonthlyAPI } from "@/services/api/lng.api";
import {
	TANK_MONTHLY_REQUEST,
	TANK_MONTHLY_SUCCESS,
	TANK_MONTHLY_FAILURE
} from "../../types";
import { transformDataBarChart } from "../../../selectors/transform/barChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getLngMonthlyData: (state) => {
			const options = { dateReportFormat: "year", barColor: "#00a8e1" };
			const result = transformDataBarChart(state, options);
			return result;
		}
	},

	mutations: {
		[TANK_MONTHLY_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[TANK_MONTHLY_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[TANK_MONTHLY_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getLngMonthly({ commit }, { id = "" }) {
			try {
				commit(TANK_MONTHLY_REQUEST);

				const { data = [] } = await getLngMonthlyAPI(id);

				commit(TANK_MONTHLY_SUCCESS, data);
			} catch (error) {
				commit(TANK_MONTHLY_FAILURE);
			}
		}
	}
};
