<template>
	<BaseLayout :pageTitle="pageTitle">
		<BaseLoading
			v-if="showLoading"
		/>
		<template
			v-else
		>
			<div class="container-action-bar">
				<BaseButton
					v-if="hasPermission || permissions.diagram.manage"
					:disabled="disabledCreateButton"
					size="large"
					rounded
					@click="onCreateButton"
				>
					Create new diagram
				</BaseButton>
			</div>
			<div class="main-content">
				<Search
					v-if="hasPermission"
					:query="query"
					@submit="onSumitSearch"
				/>
				<BaseEmpty
					v-if="empty && !query.keyword && hasPermission"
					class="wrapper-empty"
					icon="file-times"
					wrapperColor="ice"
					iconColor="emerald"
					title="Please create diagram"
					subTitle="Diagram list will be displayed once you created diagram"
				/>
				<BaseNoResult
					v-if="empty && query.keyword"
					description="Try adjusting your search by changing your keyword or removing filters."
				/>
				<BaseEmpty
					v-if="empty && !hasPermission"
					class="wrapper-empty"
					icon="file-times"
					wrapperColor="ice"
					iconColor="emerald"
					title="No information yet"
					subTitle="BIG portal will start showing diagram right after we have information"
				/>
				<Table
					v-if="!empty && hasPermission"
					:loading="loading"
					:items="data"
					:pagination="pagination"
					@onPageChange="handlePageChange"
				/>
			</div>
			<ModalShiptoCreate
				:modal-name="MODAL_NAME_CREATE"
				:shipTos="shipTos"
				@onSubmit="handleSubmitModalCreateShipTo"
			/>
		</template>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Search from "@/components/FlowDiagram/Search.vue";
import Table from "@/components/FlowDiagram/Table.vue";
import BaseEmpty from "@/components/BaseEmpty.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import ModalShiptoCreate from "@/components/FlowDiagram/ModalShiptoCreate.vue";
import { getIsSuperAdmin } from "../../selectors/transform/staff";
import { PAGE_NAME } from "../../enums/pagePermission";
import userPermission from "../../mixins/userPermission";

export default {
	components: {
		Search,
		Table,
		BaseEmpty,
		BaseNoResult,
		BaseLoading,
		ModalShiptoCreate
	},

	mixins: [userPermission],

	data() {
		return {
			MODAL_NAME_CREATE: "modal_create",
			query: {
				page: this.$route.query.page,
				keyword: this.$route.query.keyword
			}
		};
	},

	computed: {
		...mapState(["user"]),
		...mapState("flowDiagramList", {
			data: "data",
			pagination: "pagination",
			loading: "loading",
			empty: "empty"
		}),
		...mapState("flowDiagramShipTo", {
			shipTos: "data",
			shipTosLoading: "loading"
		}),
		disabledCreateButton() {
			return this.shipTosLoading;
		},
		hasPermission() {
			return getIsSuperAdmin(this.user.role) || this.permissions.diagram.manage;
		},
		pageTitle() {
			if (this.hasPermission || !this.loading) {
				return "Diagram";
			}
			return "";
		},
		showLoading() {
			return !this.hasPermission && this.loading;
		}
	},

	async created() {
		await this.fetchData();
		if (!this.hasPermission && !this.empty) {
			this.$router.push({
				name: PAGE_NAME.FLOWDIAGRAM_VIEW,
				query: {
					id: this.data[0].shipTo.id
				}
			});
		}
	},

	methods: {
		...mapActions("flowDiagramList", {
			getFlowDiagramList: "getFlowDiagramList"
		}),
		...mapActions("flowDiagramShipTo", {
			getFlowDiagramShipToList: "getFlowDiagramShipToList"
		}),
		async fetchData() {
			this.updateUrl();
			await this.getFlowDiagramList({
				page: this.query.page,
				q: this.query.keyword,
				ignoreDiagrams: true
			});
		},
		updateUrl() {
			this.$router.replace({ query: this.query }).catch(() => {});
		},
		onSumitSearch(form) {
			this.query.keyword = form.keyword;
			this.fetchData();
		},
		handlePageChange(pageNum) {
			this.query.page = pageNum;
			this.fetchData();
		},
		async onCreateButton() {
			await this.getFlowDiagramShipToList({ perPage: 10000, show: "empty" });
			this.$modal.show(this.MODAL_NAME_CREATE);
		},
		handleSubmitModalCreateShipTo(form) {
			this.$router.push({
				name: PAGE_NAME.FLOWDIAGRAM_CREATE,
				query: {
					id: form.shipToId
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: flex-end;

	/* stylelint-disable */
	&::v-deep .btn {
		margin-top: rem(-52);
	}
	/* stylelint-enable */
}

.wrapper-empty {
	margin-top: rem(150);
}
</style>
