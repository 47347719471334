<template>
	<div :class="['container-datepicker', ...wrapperClass]" v-click-outside="hideDatePicker">
		<BaseFormInput
			:disabled="disabled"
			:value="displayDate"
			:readonly="true"
			:class="['custom-input', 'has-icons-left', size]"
			type="text"
			iconLeft="calendar"
			:placeholder="placeholder"
			@click="handleInputClick"
		/>
		<div v-if="isShowDatePicker" class="container-datepicker-control">
			<v-date-picker
				color="green"
				:mode="mode"
				v-model="dateInput"
				:popover="{ placement: 'bottom', visibility: 'click' }"
				:min-date="minDate"
				:max-date="maxDate"
				title-position="left"
				locale="en"
				is-inline
				is-expanded
				:is-range="isRange"
			/>
			<div class="container-datepicker-control-footer">
				<a v-if="clearable" class="clear-link" @click.prevent="onClear">Clear dates</a>
				<BaseButton size="medium" @click="onDone">Done</BaseButton>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";

export default {
	name: "BaseDatePicker",

	props: {
		value: {
			type: [Object, Array, Date, String],
			default: null
		},
		size: {
			type: String,
			default: "medium",
			validator: (value) => {
				return [
					"small",
					"medium"
				].includes(value);
			}
		},
		mode: {
			type: String,
			default: "single",
			validator: (value) => {
				return [
					"single",
					"multiple",
					"range"
				].includes(value);
			}
		},
		minDate: {
			type: Date,
			default: null
		},
		maxDate: {
			type: Date,
			default: null
		},
		clearable: {
			type: Boolean,
			default: false
		},
		autohide: {
			type: Boolean,
			default: false
		},
		position: {
			type: String,
			default: "left",
			validator: (value) => {
				return [
					"left",
					"right"
				].includes(value);
			}
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			dateInput: null,
			isShowDatePicker: false
		};
	},

	computed: {
		wrapperClass() {
			return [
				this.positionClass
			];
		},

		positionClass() {
			return `position-${this.position}`;
		},

		placeholder() {
			let str = "";

			if (this.mode === "single") {
				str = "Select date";
			} else if (this.mode === "multiple") {
				str = "Select date multiple";
			} else {
				str = "Select date range";
			}

			return str;
		},

		displayDate() {
			const f = "DD/MM/YYYY";
			const date = this.dateInput;
			let str = "";

			if (this.mode === "single") {
				str = date
					? `${dayjs(date).format(f)}`
					: "";
			} else if (this.mode === "multiple") {
				str = date
					? date.map((d) => dayjs(d).format(f)).join(", ")
					: "";
			} else {
				str = date
					? `${dayjs(date.start).format(f)} - ${dayjs(date.end).format(f)}`
					: "";
			}

			return str;
		},

		isRange() {
			return this.mode === "range";
		}
	},

	watch: {
		dateInput() {
			this.$emit("change", this.dateInput);
			this.$emit("input", this.dateInput);
			if (this.autohide) {
				this.isShowDatePicker = false;
			}
		},
		value() {
			this.dateInput = this.value;
			this.$emit("input", this.dateInput);
		},
		isShowDatePicker() {
			if (this.isShowDatePicker) {
				this.$emit("open");
			} else {
				this.$emit("close");
			}
		}
	},

	created() {
		this.dateInput = this.value;
	},

	methods: {
		hideDatePicker() {
			this.isShowDatePicker = false;
		},
		handleInputClick() {
			this.isShowDatePicker = !this.isShowDatePicker;
		},
		onDone() {
			this.isShowDatePicker = false;
			this.$emit("done", this.dateInput);
		},
		onClear() {
			this.dateInput = null;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-datepicker {
	position: relative;

	&.position-left > &-control {
		left: 0;
	}

	&.position-right > &-control {
		right: 0;
	}

	&-control {
		position: absolute;
		z-index: 5;
		bottom: rem(-8);
		transform: translateY(100%);
		width: rem(272);
		border: 1px solid $color-silver;
		border-radius: rem(4);
		box-shadow: 0 rem(2) rem(8) 0 rgba(16, 51, 50, 0.2);

		.vc-container {
			border: none;

			/* stylelint-disable */
			&::v-deep .vc-day {
				&-layer {
					.vc-highlight {
						&.vc-bg-blue-600 {
							background-color: $color-green-haze;
						}

						&.vc-border-blue-700 {
							border-color: $color-green-haze;
						}

						&-base {
							&-start, &-middle, &-end {
								background-color: $color-ice;
							}
						}
					}
				}

				&-content {
					@include fontBase();

					font-weight: normal;
					font-size: $font-20;

					&:first-child {
						&:hover {
							background-color: $color-ice;
						}
					}
				}
			}

			&::v-deep .is-today .vc-day-content {
				border: 1px solid $color-green-haze;
			}

			&::v-deep .vc-pane {
				.vc-title {
					@include fontSub();

					padding-right: rem(20);
					color: $color-dark-blue-grey;
					font-size: $font-20;
					font-weight: $font-weight-bold;

					&::after {
						content: " ";
						position: absolute;
						top: 8%;
						margin-left: rem(4);
						width: 0;
						height: 0;
						border-left: rem(5) solid transparent;
						border-right: rem(5) solid transparent;
						border-top: rem(5) solid $color-cerulean;
					}
				}
			}

			&::v-deep .vc-arrows-container {
				svg {
					color: $color-cerulean;
				}
			}

			&::v-deep .vc-weekday {
				@include fontBase();

				font-size: $font-16;
				font-weight: $font-weight-bold;
				color: $color-grey-4;
			}

			&::v-deep .vc-popover-content {
				background-color: $color-white;
				color: $color-mirage;

				.vc-grid-cell {
					.vc-nav-title {
						color: $color-mirage;
					}

					span.vc-grid-focus:not(.vc-nav-title):not(.vc-nav-arrow) {
						background-color: $color-green-haze;
						color: $color-white;
						border: none;
					}

					span {
						color: $color-mirage;

						&:hover {
							background-color: $color-ice;
							color: $color-mirage;
						}
					}
				}
			}

			&::v-deep .vc-grid-cell {
				&-col {
					&-1, &-7 {
						.vc-day-content {
							color: $color-grey-4;
						}
					}

					&-2, &-3, &-4, &-5, &-6 {
						.vc-day-content {
							color: $color-dark-blue-grey;
						}
					}
				}

				.vc-day-content {
					&.vc-text-white {
						color: $color-white;
					}

					&.vc-text-gray-400 {
						color: #cbd5e0;
						cursor: not-allowed;
					}
				}
			}
			/* stylelint-enable */
		}

		&-footer {
			display: flex;
			align-items: center;
			padding: rem(8) rem(16);
			border-top: 1px solid $color-silver;
			background-color: $color-white;

			button {
				margin: 0 0 0 auto;
			}
		}
	}

	/* stylelint-disable */
	.custom-input {
		cursor: pointer;

		&.small {
			&::v-deep .input {
				height: rem(32);
				line-height: rem(32);
				padding: rem(1) rem(10) 0 rem(40);
			}
		}

		&.medium {
			&::v-deep .input {
				height: rem(40);
				line-height: rem(40);
				padding: rem(2) rem(10) 0 rem(40);
			}
		}

		&::v-deep .input {
			background-color: $color-white;
			cursor: pointer;
		}

		&::v-deep .is-left {
			width: rem(40);

			svg {
				width: rem(13);
				height: rem(16);
				color: $color-dark-blue-grey;
			}
		}
	}
	/* stylelint-enable */
}
</style>