<template>
	<modal class="modal-add-new-tab" name="modal-add-new-tab" :scrollable="true" width="600" height="auto">
		<BaseModalContent>
			<template v-slot:header>
				Add new tab
				<span class="close-button" @click="hideModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="$v.title.$model"
								:inputOptions="['small']"
								:is-error="isTitleError"
								:error-message="getTitleErrorMessage"
								label="Title"
								placeholder="Enter name of the grafana/iframe tab"
								inline
							/>
						</div>
					</div>
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="$v.priority.$model"
								:inputOptions="['small']"
								:is-error="isPriorityError"
								:error-message="getPriortyErrorMessage"
								label="Priority"
								placeholder="e.g. 1, 2, 3"
								inline
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					class="btn-cancel"
					type="cancel"
					size="medium"
					@click="hideModal"
					rounded
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="large"
					:disabled="isPriorityError"
					@click="handleSubmit"
				>
					Add grafana/iframe tab
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import BaseModalContent from "@/components/BaseModalContent.vue";
import { required, numeric, maxLength } from "vuelidate/lib/validators";
import { getvalidateMessage, checkIfValid } from "../../../selectors/formValidate/validateHelper";

export default {
	name: "ModalAddNewTab",

	components: {
		BaseModalContent
	},

	mixins: [
		validationMixin
	],

	data() {
		return {
			params: null,
			priority: null,
			title: ""
		};
	},

	methods: {
		hideModal() {
			this.params = null;
			this.priority = "";
			this.title = "";
			this.$v.$reset();
			this.$modal.hide("modal-add-new-tab");
		},

		handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			this.$emit("submit", { priority: this.priority, title: this.title });
			this.hideModal();
		}
	},

	computed: {
		getPriortyErrorMessage() {
			const field = this.$v.priority;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Must be a positive number only"
			});
		},

		getTitleErrorMessage() {
			const field = this.$v.title;
			return getvalidateMessage(field, {
				requiredMessage: "Please enter the title name",
				lengthLimitMessage: "Field length at max 255 characters"
			});
		},

		isPriorityError() {
			const field = this.$v.priority;
			return checkIfValid(field);
		},

		isTitleError() {
			const field = this.$v.title;
			return checkIfValid(field);
		}
	},

	validations: {
		priority: {
			required,
			format(value) {
				return (value > 0 && numeric(value));
			}
		},

		title: {
			required,
			maxLength: maxLength(255)
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-add-new-tab {
	::v-deep {
		.v--modal-box {
			overflow: initial !important;
		}
	}

	.container-form {
		&.active {
			margin-bottom: rem(40);
		}

		.item {
			margin-bottom: $spacing-6;
			display: flex;

			.label {
				display: flex;
				align-items: center;
				min-width: rem(120);
			}

			.form {
				flex-grow: 1;
			}
		}

		.item:last-of-type {
			margin-bottom: 0;
		}
	}

	.notify-error {
		margin-bottom: $spacing-6;
	}
}

.btn-cancel {
	margin-right: rem(24);
}

</style>
