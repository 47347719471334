<template>
	<modal
		class="modal-select-overview-type"
		name="modal-select-overview-type"
		:scrollable="true"
		width="420"
		height="auto"
		@before-close="handleCloseModal"
		@before-open="beforeOpen"
	>
		<BaseModalContent>
			<template v-slot:header>
				Add new column
				<span class="close-button" @click="hideModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div class="item">
						<div class="form">
							<BaseDropdown
								:searchable="false"
								:list="overviewTypeOptions"
								:value="overviewTypeSelected"
								:dropdownListContainerHeight="200"
								class="dropdown-overview-type"
								label="New Column"
								:is-error="isError"
								:error-message="errorMessage"
								inline
								@input="handleSelectOverviewType"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					type="secondary"
					link
					size="medium"
					@click="hideModal"
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="medium"
					@click="handleSubmit"
					:disabled="disabledConfirm"
				>
					Add new column
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	name: "ModalSelectOverviewType",

	components: {
		BaseModalContent
	},

	data() {
		return {
			overviewTypeSelected: null,
			overviewTypeOptions: [],
			isError: false,
			errorMessage: "Require field"
		};
	},

	methods: {
		hideModal() {
			this.$modal.hide("modal-select-overview-type");
		},

		beforeOpen(event) {
			const { overviewTypeOptions } = event.params;
			this.overviewTypeOptions = overviewTypeOptions;
		},

		handleSelectOverviewType(value) {
			this.overviewTypeSelected = value;
			this.isError = false;
		},

		handleSubmit() {
			if (!this.overviewTypeSelected) {
				this.isError = true;
			}

			if (!this.isError) {
				this.$emit("submit", this.overviewTypeSelected);
				this.hideModal();
			}
		},

		handleCloseModal() {
			this.overviewTypeSelected = null;
		}
	},

	computed: {
		disabledConfirm() {
			return this.overviewTypeSelected ? false : this.overviewTypeSelected || true;
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-select-overview-type {
	::v-deep {
		.v--modal-box {
			overflow: initial !important;
		}
	}

	.container-form {
		&.active {
			margin-bottom: rem(40);
		}

		.item {
			margin-bottom: $spacing-6;
			display: flex;

			.label {
				display: flex;
				align-items: center;
				min-width: rem(120);
			}

			.form {
				flex-grow: 1;
			}
		}

		.item:last-of-type {
			margin-bottom: 0;
		}
	}

	.notify-error {
		margin-bottom: $spacing-6;
	}
}
</style>