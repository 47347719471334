<template>
	<div class="head-column-container">
		<div class="head-column-left">
			<div class="item">
				<strong>{{ date }}</strong>
			</div>
		</div>
		<div :class="['head-column-right', isFullWidth]">
			<div
				v-for="({value, percentage}, index) in values"
				:key="index"
				class="item"
			>
				<span>{{ transformValue(value) }}</span>
				<span>
					<strong class="percentage">{{ trasnformPercentage(percentage) }}</strong>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import Numeral from "numeral";
import { isNumeric } from "echarts/lib/util/number";

export default {
	name: "MeterOrderHistoryColumnBody",

	props: {
		date: {
			type: String,
			required: true
		},
		values: {
			type: Array,
			required: true
		}
	},
	methods: {
		trasnformPercentage(value) {
			const percentage = isNumeric(value) ? Numeral(`${value}%`).format("0.00%") : value;
			return percentage;
		},
		transformValue(value) {
			const trasnfromValue = isNumeric(value) ? Numeral(value).format("0,0") : value;
			return trasnfromValue;
		}

	},
	computed: {

		isFullWidth() {
			return this.values.length > 6 ? "full-width" : null;
		}
	}
};
</script>

<style lang="scss" scoped>
.head-column {
	// .head-column-container
	&-container {
		display: flex;
		padding: rem(12) rem(18);
	}

	// .head-column-right
	&-right {
		display: flex;

		// .head-column-right.full-width
		&.full-width {
			width: 100%;
		}

		// .head-column-right.full-width .item
		&.full-width .item {
			flex-grow: 1;
		}

		// .head-column-right .item
		.item {
			width: rem(124);
			display: flex;
			flex-direction: column;
			padding: 0 0 0 rem(16);
			text-align: right;
			color: $color-dark-blue-grey;

			span:nth-child(1) {
				font-size: $font-18;
			}

			span:nth-child(2) {
				font-size: $font-16;
			}

			.percentage {
				color: $color-grey-4;
			}
		}
	}

	// .head-column-left
	&-left {
		width: rem(130);

		// .head-column-left .item
		.item {
			@include fontSub();

			margin-left: rem(24);
			margin-top: rem(-4);

			// .head-column-left .item strong


			strong {
				font-size: $font-24;
				color: $color-dark-blue-grey;
			}
		}
	}
}
</style>