import { getPowerVoltageAPI } from "@/services/api/pwr.api";
import { POWER_VOLTAGE_REQUEST, POWER_VOLTAGE_SUCCESS, POWER_VOLTAGE_FAILURE } from "../../types";
import { parsePowerVoltage } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parsePowerVoltage({}),
		loading: true,
		empty: false
	},

	mutations: {
		[POWER_VOLTAGE_REQUEST](state) {
			state.detail = parsePowerVoltage({});
			state.loading = true;
			state.empty = false;
		},
		[POWER_VOLTAGE_SUCCESS](state, data) {
			const detail = data;
			state.detail = parsePowerVoltage(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[POWER_VOLTAGE_FAILURE](state) {
			state.detail = parsePowerVoltage({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getPowerVoltage({ commit }, { id, params }) {
			try {
				commit(POWER_VOLTAGE_REQUEST);
				const { data } = await getPowerVoltageAPI(id, params);
				commit(POWER_VOLTAGE_SUCCESS, data);
			} catch (error) {
				commit(POWER_VOLTAGE_FAILURE, error);
			}
		}
	}
};
