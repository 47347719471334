<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<template
			v-else
		>
			<BaseLoading
				v-if="nitroFASOrderHistory.isLoading"
			/>
			<BaseFilterExport
				v-if="!nitroFASOrderHistory.isLoading && permissions.dashboard.exportable"
				mode="range"
				class="custom-filter-export"
				@onSubmit="handleSubmitExport"
			/>
			<NitroFASOrderHistoryList
				v-if="!nitroFASOrderHistory.isLoading"
				class="nitro-fas-history-wrapper"
				:items="nitroFASOrderHistory.data"
			/>
			<BasePagination
				v-if="nitroFASOrderHistory.totalPage > 1 && !nitroFASOrderHistory.isLoading"
				:value="page"
				:totalPage="nitroFASOrderHistory.totalPage"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>
		</template>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import NitroFASOrderHistoryList from "@/components/dashboard/modules/NitroFASOrderHistory/NitroFASOrderHistoryList.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";
import { getUri } from "../../selectors/helpers";
import userPermission from "../../mixins/userPermission";

export default {
	components: {
		NitroFASOrderHistoryList,
		BaseLoading,
		EmptyOrderHistory,
		BaseFilterExport
	},

	mixins: [userPermission],

	data() {
		return {
			nitroId: this.$route.params.id,
			page: Number(this.$route.query.page) || 1
		};
	},

	computed: {
		...mapState(["nitroFASOrderHistory"]),
		isEmpty() {
			return this.nitroFASOrderHistory.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.nitroId,
			httpStatus: this.nitroFASOrderHistory.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("nitroFASOrderHistory", {
			fetchNitroFASOrderHistory: "fetchNitroFASOrderHistory"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		fetchData() {
			const { nitroId, page } = this;
			return this.fetchNitroFASOrderHistory({
				id: nitroId,
				params: { page }
			});
		},

		handlePageChange(page = 1) {
			this.page = page;
			this.fetchData();
			this.$router.replace({ query: { page } }).catch(() => {});
		},

		handleSubmitExport(form) {
			// docs: https://bigportal.qa-api.bigth.com/doc/#api-NitroFAS-GetNitrofasIdExportDailyConsumptions
			const params = {
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).format("YYYY-MM-DD"),
						endDate: dayjs(form.dateRange.end).format("YYYY-MM-DD")
					} : null
				)
			};
			const path = `/nitrofas/${this.nitroId}/export-daily-consumptions`;
			const uri = getUri(path, params);
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.custom-filter-export {
	margin-top: rem(-8);
	margin-bottom: rem(11);
	justify-content: flex-end;
}
</style>