
<template>
	<div class="wrapper-content-badge">
		<div class="container-content">
			<div class="header">
				<div class="wrapper-header">
					<FontAwesomeIcon class="info-icon" :icon="['fas', 'info-circle']"/>
					<h4 class="title">{{ title }}</h4>
				</div>
			</div>
			<div class="wrapper-body">
				<slot name="content-body"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseBadgeInfomation",
	props: {
		title: {
			type: String,
			default: ""
		}
	}
};
</script>

<style lang="scss" scoped>
.wrapper-content-badge {
	display: flex;
	background-color: $color-white;
	position: relative;
	min-height: rem(84);
	border: 1px solid $color-silver;
	border-radius: rem(4);

	&::before {
		content: " ";
		display: block;
		position: absolute;
		left: rem(-1); //engagde border left
		top: 0;
		width: calc(100% + #{rem(2)});
		height: rem(8);
		background-color: $color-azure;
		border-radius: rem(4) rem(4) 0 0;
		margin-top: rem(-1);  //engagde border top
	}

	.container-content {
		width: 100%;
		padding: rem(16) rem(28);
	}

	.wrapper-header {
		display: flex;
		align-items: center;
		color: $color-cerulean;

		.info-icon {
			font-size: rem(18);
			margin-right: rem(18);
		}
	}

	.wrapper-body {
		padding: 0 rem(36);
	}
}
</style>