<template>
	<label :class="['container',{ 'disabled': disabled }, { 'show-helper': helperText }]">
		<span class="text">
			<span class="label">
				{{ label }}
			</span>
			<span v-if="helperText" class="helper">
				{{ helperText }}
			</span>
		</span>
		<input
			:checked="checked"
			:disabled="disabled"
			type="checkbox"
			@click="handleChecked"
		>
		<span
			:disabled="disabled"
			class="checkmark"
		/>
	</label>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ""
		},
		helperText: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		checked: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		handleChecked() {
			if (!this.disabled) {
				this.$emit("handleCheck", !this.checked);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	display: block;
	position: relative;
	padding-left: rem(35);
	margin-bottom: rem(12);
	cursor: pointer;
	font-size: $font-18;
	color: $color-dark-blue-grey;
	user-select: none;

	&.disabled {
		color: $color-grey-4;
	}
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: rem(25);
	width: rem(25);
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	border-radius: 4px;

	&[disabled] {
		cursor: not-allowed;
		background: $color-disabled-input;
	}
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: $color-azure;
}

.container input:checked ~ .checkmark[disabled] {
	background-color: $color-light-teal;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark::after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark::after {
	left: rem(8);
	top: rem(5);
	width: rem(6);
	height: rem(12);
	border: solid $color-white;
	border-width: 0 rem(2) rem(2) 0;
	transform: rotate(45deg);
}

.show-helper {
	top: rem(2);

	.text {
		display: flex;
		flex-direction: column;
	}

	.helper {
		font-size: $font-14;
		color: $color-grey-4;
		margin-top: rem(-4);
	}
}
</style>