<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<ModuleHeaderExample :data="lngInfo.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_LNG_INFO }"
						active-class="active"
						class="nav-link"
					>
						LNG information
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_LNG_TRIP_INFO }"
						active-class="active"
						class="nav-link"
					>
						Trip information
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_LNG_HISTORY }"
						active-class="active"
						class="nav-link"
					>
						History
					</router-link>
				</li>
				<li v-if="permissions.powerBI.read" class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_LNG_POWERBI }"
						active-class="active"
						class="nav-link"
					>
						Analysis
					</router-link>
				</li>
			</ul>
			<router-view
				@loaded="handleContentLoaded"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import ModuleHeaderExample from "@/components/dashboard/modules/ModuleHeaderExample.vue";
import { PAGE_NAME } from "../enums/pagePermission";
import { CONFIG } from "../enums/config";
import userPermission from "../mixins/userPermission";

export default {
	mixins: [userPermission],
	components: {
		BaseLoading,
		ModuleHeaderExample
	},

	data() {
		return {
			PAGE_NAME,
			id: this.$route.params.id,
			isLoading: true,
			fetchDevices: () => {}
		};
	},

	computed: {
		...mapState(["lngInfo", "shipTos"]),

		breadcrumb() {
			return {
				type: {
					name: this.lngInfo.data.type || ""
				},
				shiped: {
					name: this.shipTos.data.name
				}
			};
		},

		shipToId() {
			return this.lngInfo.data.shipToId || null;
		}
	},

	async created() {
		await this.getLngInfo(this.id);

		this.autoRefreshData = setInterval(async () => {
			if (!this.lngInfo.isAutoFecthLoading) {
				await this.autoGetLngInfo(this.id);
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		// save log for tab info only
		if (this.$route.name === PAGE_NAME.TANKINFO) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: this.lngInfo.statusCode
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);

				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions(["getLngInfo", "autoGetLngInfo"]),
		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		handleContentLoaded() {
			this.fetchDevices();
		}
	}
};
</script>
