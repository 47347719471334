<template>
	<BaseSevenSegment
		:value="parseValue"
		:digits="digits"
		:digitHeight="digitHeight"
		width="100"
		color-on="#009a44"
		color-off="#cccccc"
		color-back="transparent"
	/>
</template>

<script>
import BaseSevenSegment from "./VSevenseg.vue";

export default {
	props: {
		digits: {
			type: [Number, String],
			default: 3
		},
		digitHeight: {
			type: String,
			default: "50px"
		},
		value: {
			type: [Number, String],
			default: 0
		}
	},
	components: {
		BaseSevenSegment
	},
	computed: {
		parseValue() {
			const value = Number(this.value);
			if (value >= 0 && value < 2000) {
				return value;
			}
			return "_ovr";
		}
	}
};
</script>