/* eslint-disable */
// TODO: improve coding ESLint standard
import {
	ref,
	onMounted,
	watch
} from "@vue/composition-api";

const svgns = "http://www.w3.org/2000/svg";

const iconPlus = {
	d: "M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z",
	viewBox: "0 0 512 512"
};

const iconEdit = {
	d: 'M493.26 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.25 18.74l-74.49 74.49L256 127.98 12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.79-.05 2.69-.15l114.14-12.61L384.02 256l34.74-34.74 74.49-74.49c25-25 25-65.52.01-90.51zM118.75 453.39l-67.58 7.46 7.53-67.69 231.24-231.24 31.02-31.02 60.14 60.14-31.02 31.02-231.33 231.33zm340.56-340.57l-44.28 44.28-60.13-60.14 44.28-44.28c4.08-4.08 8.84-4.69 11.31-4.69s7.24.61 11.31 4.69l37.51 37.51c6.24 6.25 6.24 16.4 0 22.63z',
	viewBox: '0 0 512 512',
};

const iconTimes = {
	d: 'M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z',
	viewBox: '0 0 320 512',
};

const dashboardSvgString = (x, y, value) => {
	const angleStart = 210;
	const angleEnd = 330;
	// maxLength are length number between 210 to 330 degrees
	const maxLength = (360 - angleEnd) + angleStart; // 240
	const angleReduceLength = (maxLength * value) / 100;
	let angle = angleStart - angleReduceLength;
	if (angle <= 0) angle += 360;
	/* end of calculate an angle */
	const cx = 45;
	const cy = 45;
	const radius = 40;
	const radians = -((Math.PI / 180) * angle);
	const pointerX = (Math.cos(radians) * radius) + cx;
	const pointerY = (Math.sin(radians) * radius) + cy;
	/* end of calculate x and y to draw a line position */
	return `
      <svg viewBox="0 0 90 90" width="14" height="12" x="${x}" y="${y}">
        <defs>
          <marker id="helmet" viewBox="0 0 58 58" markerWidth="10" markerHeight="10" refX="15" refY="5" orient="auto">
            <g transform="rotate(90, 10, 10)">
              <path d="M0,6 A1,1 0 1,1 10,6
                      L10,10 L0,10 Z"
                    stroke="none"
                    fill="white" />
              <path d="M5,6 L5,10"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke="#00a8e1" />
            </g>
          </marker>
        </defs>
        <!-- semicircle -->
        <path d="M10,64 A40,40 0 1,1 80,64"
              fill="none"
              stroke-width="8"
              stroke-linecap="round"
              stroke="#f29d59" />
        <!-- pointer -->
        <line id="pointer"
              x1="45" y1="45"
              x2="${pointerX}" y2="${pointerY}"
              marker-end="url(#helmet)"
              stroke-width="6"
              stroke-linecap="round"
              stroke="#00a8e1" />
        <!-- small point on middle of the semicircle -->
        <circle cx="45" cy="45" r="6" fill="#00a8e1" />
      </svg>
    `;
};

const sevenSegmentSvgString = (x, y) => {
	return `
      <svg viewBox="0 0 14 14" width="14" height="14" x="${x}" y="${y}">
        <g fill="none" fill-rule="evenodd">
            <g fill="#D8D8D8" fill-rule="nonzero">
                <g>
                    <g>
                        <g>
                            <path d="M10.46.8c.277 0 .5.224.5.5v.548h.192c.552 0 1 .448 1 1l-.001.212h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v1.3h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v1.3h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v1.3h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v.192c0 .552-.447 1-1 1l-.191-.001v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-1.3v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-1.3v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-1.3v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-.212c-.552 0-1-.447-1-1v-.191H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-1.3H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-1.3H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-1.3H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-.212c0-.552.448-1 1-1h.212V1.3c0-.276.224-.5.5-.5.277 0 .5.224.5.5v.548h1.3V1.3c0-.276.224-.5.5-.5.277 0 .5.224.5.5v.548h1.3V1.3c0-.276.224-.5.5-.5.277 0 .5.224.5.5v.548h1.3V1.3c0-.276.224-.5.5-.5z" transform="translate(-544 -361) translate(240 222) translate(292 103) translate(12 36)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
      </svg>
    `;
};

const circleProgressSvgString = (x, y, value) => {
	const n = (240 * value) / 100;
	const strokeDashArray = `${n} 360`;
	const strokeWidth = value > 0 ? 4 : 0;
	return `
      <svg viewBox="0 0 80 80" class="rotate" style="transform: rotate(-90deg);" width="12" height="12" x="${x}" y="${y}">
        <circle cx="40"
                cy="40"
                r="38"
                fill="none"
                stroke="#dfece2"
                stroke-width="4"/>
        <circle cx="40"
                cy="40"
                r="38"
                fill="none"
                stroke="#009a44"
                stroke-width="${strokeWidth}"
                stroke-dashoffset="0"
                stroke-dasharray="${strokeDashArray}"
                stroke-linecap="round"/>
      </svg>
    `;
};

const gengasSvgString = (x, y, value) => {
	const n = (261 * value) / 100
	return `
	  <svg viewBox="0 0 100 100" style="transform: rotate(230deg);" width="12" height="12" x="${x}" y="${y}">
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="8" fill-opacity="0" stroke-linecap="round"  stroke="#62e5f6" style="stroke-dasharray: 211.027px, 289.027px; stroke-dashoffset: 0px;"></path>
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="8" fill-opacity="0" stroke="#009b44" stroke-linecap="round" style="stroke-dasharray: 168.821px, 289.027px; stroke-dashoffset: 0px;"></path>
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="12" stroke="#fff" fill-opacity="0" stroke-linecap="round" style="stroke-dasharray: 0px, 289.027px; stroke-dashoffset: 0px; transform: rotate(${n}deg); transform-origin: center center;"></path>
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="8" fill-opacity="0" stroke-linecap="round" stroke="#009b44" style="stroke-dasharray: 0px, 289.027px;stroke-dashoffset: 0px;transform: rotate(${n}deg);transform-origin: center center;"></path>
	  </svg>
	`
  }

const getTextMaxLength = (text, maxLength) => {
	const textStr = text || ""
	return [...textStr].reduce((acc, cur, idx) => {
		if(idx < maxLength) {
			acc.result += cur
		} else if(!acc.isDotted){
			acc.isDotted = true
			acc.result += "..."
		}
		return acc
	}, { result: "", isDotted: false }).result
}

const NODE_TYPE = {
	EMPTY: "empty",
	SUMMARY_ELECTRICITY: "summary_electricity",
	SUMMARY_METER: "summary_meter",
	DEVICE_POWER: "device_power",
	DEVICE_SOLOR: "device_solor",
	DEVICE_METER: "device_meter",
	DEVICE_NITROFAS: "device_nitrofas",
	DEVICE_GENGAS: 'device_gengas',
};

export default (element, props, context, options) => {
	const defaultOptions = {
		style: {
			nodeWidth: 50,
			nodeHeight: 50,
			nodeDistance: 100,
			nodeMargin: 2
		}
	};
    const mergeOptions = { ...defaultOptions, ...options };
	const { tree } = props;
	const nodes = ref([]);
	const temp = ref({});

	const getSvgElement = (element) => {
		return element.value.querySelector(".viewport");
	};

	const initialScreen = ({ screenWidth, screenHeight }) => {
		const svgElement = document.querySelector('.svg')
		const containerElement = element.value
		const viewportElement = containerElement.querySelector('.viewport')
		svgElement.setAttribute('viewBox', `0 0 ${screenWidth} ${screenHeight}`)
		containerElement.style.width = `${screenWidth}px`
		containerElement.style.height = `${screenHeight}px`
		viewportElement.style.width = `${screenWidth}px`
		viewportElement.style.height = `${screenHeight}px`
	}

	const getGroupActionElement = (x, y) => {
		const groupActionElement = document.createElementNS(svgns, 'g')
		const groupActionEditBtnElement = document.createElementNS(svgns, 'g')
		const groupActionRemoveBtnElement = document.createElementNS(svgns, 'g')
		const groupBackgroundElement = document.createElementNS(svgns, 'g')
		const squreEditOptions = {
			x,
			y,
			width: mergeOptions.style.nodeWidth,
			height: mergeOptions.style.nodeHeight,
			fill: 'rgba(16,51,50,.2)',
			rx: '8',
		}
		const squreButtonEditOptions = {
			x: x + mergeOptions.style.nodeWidth - 54 - 36,
			y: y + 4,
			width: 54,
			height: 24,
			fill: '#009a44',
			rx: '4',
		}
		const textEditOptions = {
			x: squreButtonEditOptions.x + 25,
			y: squreButtonEditOptions.y + 5,
			'dominant-baseline': 'hanging',
			'text-anchor': 'start',
			fill: '#fff',
		}
		const iconEditOptions = {
			x: squreButtonEditOptions.x + 8,
			y: squreButtonEditOptions.y + 5,
			width: 12,
			height: 13,
			fill: '#fff',
			class: 'icon',
		}
		const squreButtonRemoveOptions = {
			x: x + mergeOptions.style.nodeWidth - 24 - 4,
			y: y + 4,
			width: 24,
			height: 24,
			fill: '#fff',
			rx: '4',
		}
		const iconRemoveOptions = {
			x: squreButtonRemoveOptions.x + ((squreButtonRemoveOptions.width / 2) - (10 / 2)),
			y: squreButtonRemoveOptions.y + ((squreButtonRemoveOptions.height / 2) - (12 / 2)),
			width: 10,
			height: 12,
			class: 'icon',
			fill: '#586c63',
		}
		const squareEditElement = drawSquare(squreEditOptions)
		const squareButtonEditElement = drawSquare(squreButtonEditOptions)
		const iconEditElement = drawIcon({ ...iconEdit, ...iconEditOptions })
		const textEditElement = drawText('Edit', textEditOptions)
		const squareButtonRemoveElement = drawSquare(squreButtonRemoveOptions)
		const iconRemoveElement = drawIcon({ ...iconTimes, ...iconRemoveOptions })
		groupActionElement.setAttribute('class', 'action')
		groupActionEditBtnElement.setAttribute('class', 'edit-button')
		groupActionRemoveBtnElement.setAttribute('class', 'remove-button')
		groupBackgroundElement.setAttribute('class', 'background')
		groupBackgroundElement.appendChild(squareEditElement)
		groupActionEditBtnElement.appendChild(squareButtonEditElement)
		groupActionEditBtnElement.appendChild(iconEditElement)
		groupActionEditBtnElement.appendChild(textEditElement)
		groupActionRemoveBtnElement.appendChild(squareButtonRemoveElement)
		groupActionRemoveBtnElement.appendChild(iconRemoveElement)
		groupActionElement.appendChild(groupBackgroundElement)
		groupActionElement.appendChild(groupActionEditBtnElement)
		groupActionElement.appendChild(groupActionRemoveBtnElement)
		return groupActionElement
	}

	const getTooltipElement = (text) => {
		const titleElement = document.createElementNS(svgns, 'title')
		titleElement.innerHTML = text
		return titleElement
	}

	const drawSquare = (options = {}) => {
		const { x, y, width, height, ...rest } = options;
		const ele = document.createElementNS(svgns, "rect");
		ele.setAttribute("x", x);
		ele.setAttribute("y", y);
		ele.setAttribute("width", width);
		ele.setAttribute("height", height);
		for (const key in rest) {
			ele.setAttribute(key, rest[key]);
		}
		return ele;
	};

	const drawEmptyNode = (svg, x, y) => {
		const groupElement = document.createElementNS(svgns, "g");
		const squreOptions = {
			x,
			y,
			width: mergeOptions.style.nodeWidth,
			height: mergeOptions.style.nodeHeight,
			fill: "#f7fbf7",
			stroke: "#cad9cf",
			"stroke-dasharray": "5 2",
			rx: "8"
		};
		const text = "Select device";
		const textOptions = {
			x: x + (squreOptions.width / 2),
			y: y + (squreOptions.height / 2) + 12,
			"dominant-baseline": "middle",
			"text-anchor": "middle"
		};
		const iconPlusOptions = {
			x: x + (squreOptions.width / 2) - (14 / 2) - 1,
			y: y + (squreOptions.height / 2) - (14 / 2) - 13,
			width: "16px",
			height: "16px",
			class: "icon"
		};
		const squareElement = drawSquare(squreOptions);
		const textElement = drawText(text, textOptions);
		const iconPlusElement = drawIcon({ ...iconPlus, ...iconPlusOptions });
		groupElement.setAttribute("class", "node-empty");
		groupElement.appendChild(squareElement);
		groupElement.appendChild(textElement);
		groupElement.appendChild(iconPlusElement);
		svg.appendChild(groupElement);
		return groupElement;
	};

	const drawSummaryElectricityNode = (svg, x, y, node) => {
		const groupElement = document.createElementNS(svgns, 'g')
		const groupContentElement = document.createElementNS(svgns, 'g')
		const styles = {
			margin: 12,
		}
		const squareElement = drawSquare({
			x,
			y,
			width: mergeOptions.style.nodeWidth,
			height: mergeOptions.style.nodeHeight,
			fill: "#f2f2f2",
			stroke: "#cad9cf",
			rx: "8"
		});
		const textTitleElement = drawText("Summary", {
			class: "title",
			x: x + styles.margin,
			y: y + 23
		});
		const routeNameText = (() => {
			if(node.detail.route && node.detail.route.name) {
				return `(${node.detail.route.name})`;
			}
			return '';
		})()
		const textRouteElement = drawText(routeNameText, {
			class: 'route',
			x: x + styles.margin + 61 + 4,
			y: y + 23,
		});
		const textSubTitleElement = drawText("Power", {
			class: "sub-title",
			x: x + styles.margin,
			y: y + 45
		});
		const textDeviceValueElement = drawText(node.detail.value, {
			class: "device-value",
			x: x + styles.margin,
			y: y + 78
		});
		const textDeviceInfoLabel1Element = drawText("MTD Power (kWh)", {
			class: "device-info-label",
			x: x + 100,
			y: y + 60
		});
		const textDeviceInfoLabel2Element = drawText("Power Savings", {
			class: "device-info-label",
			x: x + 100,
			y: y + 60 + 20
		});
		const textDeviceInfoValue1Element = drawText(node.detail.info[0].value, {
			class: "device-info-value",
			x: x + mergeOptions.style.nodeWidth - styles.margin,
			y: y + 60,
			"text-anchor": "end"
		});
		const textDeviceInfoValue2Element = drawText(node.detail.info[1].value, {
			class: "device-info-value",
			x: x + mergeOptions.style.nodeWidth - styles.margin,
			y: y + 60 + 20,
			"text-anchor": "end"
		});
		const groupActionElement = getGroupActionElement(x, y)
		groupContentElement.setAttribute('class', 'content')
		groupElement.setAttribute('class', 'node-summary')
		groupContentElement.appendChild(squareElement)
		groupContentElement.appendChild(textTitleElement)
		groupContentElement.appendChild(textRouteElement)
		groupContentElement.appendChild(textSubTitleElement)
		groupContentElement.appendChild(textDeviceValueElement)
		groupContentElement.appendChild(textDeviceInfoLabel1Element)
		groupContentElement.appendChild(textDeviceInfoLabel2Element)
		groupContentElement.appendChild(textDeviceInfoValue1Element)
		groupContentElement.appendChild(textDeviceInfoValue2Element)

		const cloneContent = groupContentElement.cloneNode(true)
		cloneContent.setAttribute('class', 'clone')
		groupActionElement.prepend(cloneContent)

		groupElement.appendChild(groupContentElement)
		groupElement.appendChild(groupActionElement)
		svg.appendChild(groupElement)
		return groupElement
	};

	const drawSummaryMeterNode = (svg, x, y, node) => {
		const groupElement = document.createElementNS(svgns, 'g')
		const groupContentElement = document.createElementNS(svgns, 'g')
		const styles = {
			margin: 12,
		}
		const squareElement = drawSquare({
			x,
			y,
			width: mergeOptions.style.nodeWidth,
			height: mergeOptions.style.nodeHeight,
			fill: "#f2f2f2",
			stroke: "#cad9cf",
			rx: "8"
		});
		const textTitleElement = drawText("Summary", {
			class: "title",
			x: x + styles.margin,
			y: y + 23
		});
		const routeNameText = (() => {
			if(node.detail.route && node.detail.route.name) {
				return `(${node.detail.route.name})`;
			}
			return '';
		})()
		const textRouteElement = drawText(routeNameText, {
			class: 'route',
			x: x + styles.margin + 61 + 4,
			y: y + 23,
		});
		const textSubTitleElement = drawText("N2 Flow rate", {
			class: "sub-title",
			x: x + styles.margin,
			y: y + 45
		});
		const textDeviceValueElement = drawText(node.detail.value, {
			class: "device-value",
			x: x + styles.margin,
			y: y + 78
		});
		const textDeviceInfoLabel1Element = drawText("MTD (Nm3)", {
			class: "device-info-label",
			x: x + 100,
			y: y + 60
		});
		const textDeviceInfoValue1Element = drawText(node.detail.info[0].value, {
			class: "device-info-value",
			x: x + mergeOptions.style.nodeWidth - styles.margin,
			y: y + 60,
			"text-anchor": "end"
		});
		const groupActionElement = getGroupActionElement(x, y)
		groupElement.setAttribute('class', 'node-summary')
		groupContentElement.setAttribute('class', 'content')
		groupContentElement.appendChild(squareElement)
		groupContentElement.appendChild(textTitleElement)
		groupContentElement.appendChild(textRouteElement)
		groupContentElement.appendChild(textSubTitleElement)
		groupContentElement.appendChild(textDeviceValueElement)
		groupContentElement.appendChild(textDeviceInfoLabel1Element)
		groupContentElement.appendChild(textDeviceInfoValue1Element)

		const cloneContent = groupContentElement.cloneNode(true)
		cloneContent.setAttribute('class', 'clone')
		groupActionElement.prepend(cloneContent)

		groupElement.appendChild(groupContentElement)
		groupElement.appendChild(groupActionElement)
		svg.appendChild(groupElement)
		return groupElement
	};

	const drawDeviceElectricityNode = (svg, x, y, node) => {
		const groupElement = document.createElementNS(svgns, "g");
		const groupContentElement = document.createElementNS(svgns, 'g');
		const styles = {
			margin: 12
		};
		const squareElement = drawSquare({
			x,
			y,
			width: mergeOptions.style.nodeWidth,
			height: mergeOptions.style.nodeHeight,
			fill: "#ffffff",
			stroke: "#cad9cf",
			rx: "8"
		});
		const deviceHeaderElement = drawDeviceHeader(x, y);
		const textHeaderTitleElement = drawText(node.detail.head.name, {
			class: "device-header-title",
			x: x + styles.margin,
			y: y + 23
		});
		const textHeaderSubTitleElement = drawText(getTextMaxLength(node.detail.head.id, 20), {
			class: "device-header-sub-title",
			x: (() => {
				if(node.detail.head.name === 'Solar Cell') {
					return x + styles.margin + 61.5 + 4
				} else if(node.detail.head.name === 'Electricity') {
					return x + styles.margin + 63.56 + 4
				}
				return x + styles.margin + 61.5 + 4
			})(),
			y: y + 22,
		});
		const textDeviceValueElement = drawText(node.detail.value, {
			class: "device-value",
			x: x + styles.margin,
			y: y + 82
		});
		const textDeviceTitleElement = drawText("Power (kW)", {
			class: "device-title",
			x: x + styles.margin + 18,
			y: y + 55
		});
		const textDeviceInfoLabel1Element = drawText("MTD Power (kWh)", {
			class: "device-info-label",
			x: x + 100,
			y: y + 68
		});
		const textDeviceInfoLabel2Element = drawText("Power Savings", {
			class: "device-info-label",
			x: x + 100,
			y: y + 68 + 16
		});
		const textDeviceInfoValue1Element = drawText(node.detail.info[0].value, {
			class: "device-info-value",
			x: x + mergeOptions.style.nodeWidth - styles.margin,
			y: y + 68,
			"text-anchor": "end"
		});
		const textDeviceInfoValue2Element = drawText(node.detail.info[1].value, {
			class: "device-info-value",
			x: x + mergeOptions.style.nodeWidth - styles.margin,
			y: y + 68 + 16,
			"text-anchor": "end"
		});

		const deviceIconElement = drawDashboardIcon({
			x: x + styles.margin,
			y: y + 46,
			value: node.detail.sub.value
		});
		const tooltipElement = getTooltipElement(node.detail.head.id);
		const groupActionElement = getGroupActionElement(x, y);
		groupElement.setAttribute('class', 'node-device');
		groupContentElement.setAttribute('class', 'content');
		groupContentElement.appendChild(tooltipElement);
		groupContentElement.appendChild(squareElement);
		groupContentElement.appendChild(deviceHeaderElement);
		groupContentElement.appendChild(textHeaderTitleElement);
		groupContentElement.appendChild(textHeaderSubTitleElement);
		groupContentElement.appendChild(textDeviceValueElement);
		groupContentElement.appendChild(textDeviceTitleElement);
		groupContentElement.appendChild(textDeviceInfoLabel1Element);
		groupContentElement.appendChild(textDeviceInfoLabel2Element);
		groupContentElement.appendChild(textDeviceInfoValue1Element);
		groupContentElement.appendChild(textDeviceInfoValue2Element);
		groupContentElement.appendChild(deviceIconElement);

		const cloneContent = groupContentElement.cloneNode(true)
		cloneContent.setAttribute('class', 'clone')
		groupActionElement.prepend(cloneContent)

		groupElement.appendChild(groupContentElement);
		groupElement.appendChild(groupActionElement);
		svg.appendChild(groupElement);
		return groupElement;
	};

	const drawDeviceMeterNode = (svg, x, y, node) => {
		const groupElement = document.createElementNS(svgns, "g");
		const groupContentElement = document.createElementNS(svgns, 'g');
		const styles = {
			margin: 12
		};
		const squareElement = drawSquare({
			x,
			y,
			width: mergeOptions.style.nodeWidth,
			height: mergeOptions.style.nodeHeight,
			fill: "#ffffff",
			stroke: "#cad9cf",
			rx: "8"
		});
		const deviceHeaderElement = drawDeviceHeader(x, y);
		const textHeaderTitleElement = drawText(node.detail.head.name, {
			class: "device-header-title",
			x: x + styles.margin,
			y: y + 23
		});
		const textHeaderSubTitleElement = drawText(getTextMaxLength(node.detail.head.id, 20), {
			class: "device-header-sub-title",
			x: (() => {
				if(node.detail.head.name === 'Nitrogen') {
					return x + styles.margin + 55.3 + 4
				} else if(node.detail.head.name === 'NitroFAS') {
					return x + styles.margin + 57.69 + 4
				}
				return x + styles.margin + 55.3 + 4
			})(),
			y: y + 22,
		});
		const textDeviceValueElement = drawText(node.detail.value, {
			class: "device-value",
			x: x + styles.margin,
			y: y + 82
		});
		const textDeviceTitleElement = drawText("N2 Flow rate", {
			class: "device-title",
			x: x + styles.margin + 18,
			y: y + 56
		});
		const textDeviceInfoLabel1Element = drawText("MTD (Nm3)", {
			class: "device-info-label",
			x: x + 100,
			y: y + 68
		});
		const textDeviceInfoValue1Element = drawText(node.detail.info[0].value, {
			class: "device-info-value",
			x: x + mergeOptions.style.nodeWidth - styles.margin,
			y: y + 68,
			"text-anchor": "end"
		});

		const deviceIconElement = (() => {
			if ([NODE_TYPE.DEVICE_METER].includes(node.type)) {
				return drawCircleProgressIcon({
					x: x + styles.margin,
					y: y + 46,
					value: node.detail.sub.value
				});
			} else if ([NODE_TYPE.DEVICE_NITROFAS].includes(node.type)) {
				return drawSevenSegmentIcon({
					x: x + styles.margin,
					y: y + 45
				});
			} else if([NODE_TYPE.DEVICE_GENGAS].includes(node.type)) {
				return drawGenGasIcon({
				  x: x + styles.margin,
				  y: y + 45,
				  value: node.detail.sub.value,
				})
			  }
			return null;
		})();
		const tooltipElement = getTooltipElement(node.detail.head.id);
		const groupActionElement = getGroupActionElement(x, y);
		groupElement.setAttribute('class', 'node-device');
		groupContentElement.setAttribute('class', 'content');
		groupContentElement.appendChild(tooltipElement);
		groupContentElement.appendChild(squareElement);
		groupContentElement.appendChild(deviceHeaderElement);
		groupContentElement.appendChild(textHeaderTitleElement);
		groupContentElement.appendChild(textHeaderSubTitleElement);
		groupContentElement.appendChild(textDeviceValueElement);
		groupContentElement.appendChild(textDeviceTitleElement);
		groupContentElement.appendChild(textDeviceInfoLabel1Element);
		groupContentElement.appendChild(textDeviceInfoValue1Element);
		if (deviceIconElement) {
			groupContentElement.appendChild(deviceIconElement);
		}

		const cloneContent = groupContentElement.cloneNode(true)
		cloneContent.setAttribute('class', 'clone')
		groupActionElement.prepend(cloneContent)

		groupElement.appendChild(groupContentElement);
		groupElement.appendChild(groupActionElement);
		svg.appendChild(groupElement);
		return groupElement;
	};

	const drawDeviceHeader = (x, y) => {
		const headerElement = document.createElementNS(svgns, "path");
		headerElement.setAttribute("d", `
			M${9.5 + x},${0.5 + y}
			h222
			q8 0, 8 8
			v28
			h-239
			v-28,
			q0 -8, 8 -8
		`);
		headerElement.setAttribute("fill", "#f9f9f9");
		return headerElement;
	};

	const drawLines = ({ d, ...rest }) => {
		const ele = document.createElementNS(svgns, "path");
		ele.setAttribute("d", d);
		for (const key in rest) {
			ele.setAttribute(key, rest[key]);
		}
		return ele;
	};

	const drawArrow = (svg, { from, to }) => {
		const groupElement = document.createElementNS(svgns, "g");
		const nodeFrom = getNode(from);
		const nodeTo = getNode(to);
		const d = (() => {
			const nodeCurrentCenter = nodeFrom.y + (nodeFrom.height / 2);
			const nodeToCenter = nodeTo.y + (nodeTo.height / 2);
			let str = "";
			str += `M${nodeFrom.x + nodeFrom.width},${nodeFrom.y + (nodeFrom.height / 2)}`;
			if (nodeCurrentCenter === nodeToCenter) {
				str += `L${nodeTo.x},${nodeFrom.y + (nodeFrom.height / 2)}`;
			} else {
				str += `L${(nodeTo.x + (nodeFrom.x + nodeFrom.width)) / 2},${nodeFrom.y + (nodeFrom.height / 2)}`;
				str += `L${(nodeTo.x + (nodeFrom.x + nodeFrom.width)) / 2},${nodeTo.y + (nodeFrom.height / 2)}`;
				str += `L${nodeTo.x},${nodeTo.y + (nodeFrom.height / 2)}`;
			}
			return str;
		})();
		const lineElement = drawLines({
			d,
			fill: "none",
			stroke: "#009a44",
			"stroke-width": 2
		});
		groupElement.setAttribute("class", "line");
		groupElement.appendChild(lineElement);
		svg.appendChild(groupElement);
		return groupElement;
	};

	const drawText = (text, options) => {
		const { x, y, ...rest } = options;
		const ele = document.createElementNS(svgns, "text");
		ele.setAttribute("x", x);
		ele.setAttribute("y", y);
		ele.innerHTML = text;
		for (const key in rest) {
			ele.setAttribute(key, rest[key]);
		}
		return ele;
	};

	const drawIcon = ({ d, ...rest }) => {
		const svgElement = document.createElementNS(svgns, "svg");
		const pathElement = document.createElementNS(svgns, "path");
		for (const key in rest) {
			svgElement.setAttribute(key, rest[key]);
		}
		pathElement.setAttribute("d", d);
		svgElement.appendChild(pathElement);
		return svgElement;
	};

	const drawDashboardIcon = ({ x, y, value }) => {
		// convery string to dom or node in javascript
		const svgString = dashboardSvgString(x, y, value);
		const range = document.createRange();
		const contextFragment = range.createContextualFragment(svgString);
		const element = contextFragment.querySelector("svg");
		return element;
	};

	const drawSevenSegmentIcon = ({ x, y }) => {
		// convery string to dom or node in javascript
		const svgString = sevenSegmentSvgString(x, y);
		const range = document.createRange();
		const contextFragment = range.createContextualFragment(svgString);
		const element = contextFragment.querySelector("svg");
		return element;
	};

	const drawCircleProgressIcon = ({ x, y, value }) => {
		// convery string to dom or node in javascript
		const svgString = circleProgressSvgString(x, y, value);
		const range = document.createRange();
		const contextFragment = range.createContextualFragment(svgString);
		const element = contextFragment.querySelector("svg");
		return element;
	};

	const drawGenGasIcon = ({ x, y, value }) => {
		// convery string to dom or node in javascript
		const svgString = gengasSvgString(x, y, value)
		const range = document.createRange()
		const contextFragment = range.createContextualFragment(svgString)
		const element = contextFragment.querySelector('svg')
		return element
	};

	const drawNode = (node, element, options, drawCount = 0, marginNodes) => {
		const { type, level, ref, value } = node;
		const svg = getSvgElement(element);
		const center = getCenterOfScreen(element);
		let x = 0;
		let y = 0;
		let nodeMargin = options.style.nodeMargin
  
		if(marginNodes) {
		  const item =  marginNodes.find((item) => item.level === level)
		  if(item) {
			nodeMargin = ((options.style.nodeMargin * 2) + item.margin)
		  }
		}
  
		const tempKey = `${ref}-level-${level}`;
		if (!temp.value.hasOwnProperty(tempKey)) {
		  temp.value[tempKey] = {
			level,
			count: -1,
			focus: -1
		  };
		}
  
		if (level == 1) {
		  x = 0;
		  y = center.y - (options.style.nodeHeight / 2);
		} else {
		  const node = getNode(ref);
		  if(!node) {
			  return
		  }
		  const childs = getNodeChildes(ref);
		  const childsCount = childs.length;
		  let slot = 0;
		  let gap = 0;
  
		  if (childsCount > 1) {
			if (temp.value[tempKey].level === level) {
			  temp.value[tempKey].count++;
			} else {
			  temp.value[tempKey].count = 0;
			}
  
			slot = (options.style.nodeHeight / 2) * (childsCount - 1);
  
			const half = Math.floor(childsCount / 2);
			const isOdd = (childsCount % 2) !== 0;
			let isRevert = true;
  
			if (isOdd) {
			  if (temp.value[tempKey].focus === -1) {
				isRevert = true;
				temp.value[tempKey].focus = half;
			  } else if (temp.value[tempKey].count > half) {
				isRevert = false;
				temp.value[tempKey].focus++;
			  } else {
				isRevert = true;
				temp.value[tempKey].focus--;
			  }
  
			  gap = temp.value[tempKey].focus * nodeMargin;
  
			  if (isRevert) {
				gap = -(gap);
			  }
			} else {
			  if (temp.value[tempKey].focus === -1) {
				isRevert = true;
				temp.value[tempKey].focus = half;
			  } else if (temp.value[tempKey].count > half) {
				isRevert = false;
				temp.value[tempKey].focus++;
			  } else if (temp.value[tempKey].focus === 1) {
				isRevert = false;
				temp.value[tempKey].focus = 1;
			  } else {
				isRevert = true;
				temp.value[tempKey].focus--;
			  }
  
			  gap = (temp.value[tempKey].focus * nodeMargin) - (nodeMargin / 2);
  
			  if (isRevert) {
				gap = -(gap);
			  }
			}
		  } else {
			temp.value[tempKey].count = 0;
		  }
  
		  temp.value[tempKey].level = level;
  
		  x += node.x + node.width + options.style.nodeDistance;
		  y += node.y - slot + (options.style.nodeHeight * temp.value[tempKey].count) + gap;
		}
  
		const width = options.style.nodeWidth;
		const height = options.style.nodeHeight;
	
		nodes.value.push({
		  ...node,
		  el: null,
		  x,
		  y,
		  width,
		  height,
		  drawCount,
		});
	};

	const getCenterOfScreen = (element) => {
		return {
			x: parseFloat(element.value.style.width) / 2,
			y: parseFloat(element.value.style.height) / 2
		};
	};

	const getNode = (ref) => {
		return nodes.value.find((node) => node.value === ref);
	};

	const getNodeChildes = (ref) => {
		if (tree.value === ref) {
			return tree.childs;
		}

		let found = false;

		const reducer = (acc, cur) => {
			if (cur.value === ref) {
				found = true;
				return cur.childs;
			} else if (!found) {
				return cur.childs.reduce(reducer, []);
			}
			return acc;
		};

		return tree.childs.reduce(reducer, []);
	};

	const clear = (element) => {
		const svg = getSvgElement(element);
		svg.innerHTML = "";
		nodes.value = [];
		temp.value = {};
	};

	const isOverlap = (itemA, itemB) => {
		if(itemA.x < itemB.x+itemB.width && itemA.y < itemB.y+itemB.height &&
		  itemB.x < itemA.x+itemA.width && itemB.y < itemA.y+itemA.height) {
		  return true;
		}
		return false;
	}
  
	const drawCount = ref(0)
  
	const initialNodes = (node) => {
		drawCount.value++
		drawNode(node, element, mergeOptions, drawCount.value)
		for(const item of node.childs) {
		  initialNodes(item)
		}
	}
  
	const updateMarginNodes = (node, marginNodes) => {
		drawCount.value++
		drawNode(node, element, mergeOptions, drawCount.value, marginNodes)
		for(const item of node.childs) {
		  updateMarginNodes(item, marginNodes)
		}
	}
  
	const getConflictNodes = (nodes) => {
		const nodesResults = []
		for(let i = 0; i < nodes.length; i++) {
		  const nodeA = nodes[i]
		  for(let j = 0; j < nodes.length; j++) {
			const nodeB = nodes[j]
			if(nodeA.value !== nodeB.value) {
			  const fakeNodeA = { ...nodeA }
			  fakeNodeA.y += 10
			  if(isOverlap(fakeNodeA, nodeB)) {
				const hitteNodedY = (nodeA.y + nodeA.height)
				const currentNodeY = nodeB.y
				const overlapSize = (hitteNodedY - currentNodeY)
				const exist = nodesResults.reduce((acc, cur) => {
				  if(cur.nodeA.value === nodeB.value && cur.nodeB.value === nodeA.value) {
					return true
				  }
				  return acc
				}, false)
				const pass = (() => {
				  if(nodeA.level > 3) {
					if(!nodeA.childs.length && !nodeB.childs.length) {
					  return false
					}
				  }
				  return true
				})()
				if(!exist && pass) {
				  const parent = nodes.find((item) => item.value === nodeA.ref)
				  nodesResults.push({ level: parent.level, nodeA, nodeB, overlapSize });
				}
			  }
			}
		  }
		}
		return nodesResults
	  }
  
	const getNodesMargins = (conflictNodes, defaultMarginNodes = []) => {
		const margins = conflictNodes.reduce((acc, cur) => {
		  const findIndex = acc.findIndex((item) => item.level === cur.level)
		  if(findIndex === -1) {
			acc.push({ level: cur.level, margin: cur.overlapSize })
		  } else {
			if(defaultMarginNodes.length) {
			  acc[findIndex].margin += cur.overlapSize + mergeOptions.style.nodeMargin
			} else {
			  if(cur.overlapSize > acc[findIndex].margin) {
				acc[findIndex].margin = cur.overlapSize
			  }
			}
		  }
		  return [...acc]
		}, [...defaultMarginNodes])
		  .filter((item) => (
			item.margin > 1
		  ))
		  .sort((a, b) => {
			if (a.level > b.level) {
			  return -1;
			}
			if (a.level < b.level) {
			  return 1;
			}
			return 0;
		  })
		  .reduce((acc, cur, idx, src) => {
			const isDup = (() => {
			  const _item = src.find((item) => (
				item.margin === cur.margin && item.level !== cur.level
			  ))
			  if(!_item) {
				return false
			  }
			  return cur.level > _item.level
			})()
			if(!isDup) {
			  acc.push(cur)
			}
			return acc
		  }, [])
		const marginsClone = [...margins]
		return margins.map((marginItem) => {
		  const list = marginsClone.filter((marginCloneItem) => {
			return marginCloneItem.level > marginItem.level
		  })
		  const total = list.reduce((acc, cur) => acc + cur.margin, 0)
		  return {
			...marginItem,
			margin: marginItem.margin + total
		  }
		})
	}
  
	const transformNodes = (tree, defaultMarginNodes = []) => {
		const conflictNodes = getConflictNodes(nodes.value)
		const marginNodes = getNodesMargins(conflictNodes, defaultMarginNodes)
		defaultMarginNodes = [...marginNodes]
		for(let i = 1; i < 10; i++) {
		  const exist = defaultMarginNodes.some((item) => item.level === i)
		  const hasChilds = nodes.value
			.filter((item) => {
			  return item.level === i
			})
			.some((item) => {
			  const parent = nodes.value.find((_item) => _item.value === item.ref)
			  return parent && parent.childs.length > 1
			})
		  if(!exist && hasChilds) {
			defaultMarginNodes.push({
			  level: i,
			  margin: 0
			})
		  }
		}
		defaultMarginNodes = defaultMarginNodes.filter((itemMargin) => {
		  const hasChilds = nodes.value
		  .filter((item) => {
			return item.level === itemMargin.level
		  })
		  .some((item) => {
			const parent = nodes.value.find((_item) => _item.value === item.ref)
			return parent && parent.childs.length > 1
		  })
		  return hasChilds
		})
		if(defaultMarginNodes.length) {
		  const minMarginAtLevel = defaultMarginNodes.reduce((acc, cur) => {
			if(!acc) {
			  acc = cur
			} else if(cur.margin < acc.margin) {
			  acc = cur
			}
			return acc
		  }, null).level
		  for(let i = 0; i < defaultMarginNodes.length; i++) {
			if(defaultMarginNodes[i].level === minMarginAtLevel) {
			  defaultMarginNodes[i].margin += 1
			}
		  }
		}
		if(conflictNodes.length && marginNodes.length) {
		  clear(element)
		  updateMarginNodes(tree, defaultMarginNodes)
		  transformNodes(tree, defaultMarginNodes)
		} else {
		  defaultMarginNodes = []
		}
	}

	const getContainerElement = (event) => {
		return event.target
	}

	const handleMouseEnterNodeContent = (event) => {
		const isEditMode = props.zoomable === false
		if(isEditMode) {
			const containerElement = getContainerElement(event)
			if(containerElement) {
			containerElement.classList.add('show-action')
			}
		}
	}

	const handleMouseLeaveNodeContent = (event) => {
		const containerElement = getContainerElement(event)
		if(containerElement) {
			containerElement.classList.remove('show-action')
		}
	}

	const handleClickNodeEdit = (event, node) => {
		context.emit("onClickEditNode", event, {
			level: node.level,
			ref: node.ref,
			value: node.value,
		});
	}

	const handleClickNodeRemove = (event, node) => {
		context.emit("onClickRemoveNode", event, {
			level: node.level,
			ref: node.ref,
			value: node.value,
		});
	}

    const drawNodes = (results) => {
      const svg = getSvgElement(element)
      for(const node of results) {
        const { x, y, type, level, ref, value } = node;
		let nodeElement;
		if (type === NODE_TYPE.EMPTY) {
			nodeElement = drawEmptyNode(svg, x, y, node);
			nodeElement.addEventListener("click", (e) => {
				context.emit("onClickEmptyNode", e, { level, ref, value });
			});
		}
		else if ([NODE_TYPE.SUMMARY_ELECTRICITY].includes(type)) {
			nodeElement = drawSummaryElectricityNode(svg, x, y, node);
			nodeElement.addEventListener("mouseenter", handleMouseEnterNodeContent)
          	nodeElement.addEventListener("mouseleave", handleMouseLeaveNodeContent)
			nodeElement.querySelector('.action .edit-button').addEventListener("click", (event) => handleClickNodeEdit(event, node))
			nodeElement.querySelector('.action .remove-button').addEventListener("click", (event) => handleClickNodeRemove(event, node))
		}
		else if ([NODE_TYPE.SUMMARY_METER].includes(type)) {
			nodeElement = drawSummaryMeterNode(svg, x, y, node);
			nodeElement.addEventListener("mouseenter", handleMouseEnterNodeContent)
          	nodeElement.addEventListener("mouseleave", handleMouseLeaveNodeContent)
			nodeElement.querySelector('.action .edit-button').addEventListener("click", (event) => handleClickNodeEdit(event, node))
			nodeElement.querySelector('.action .remove-button').addEventListener("click", (event) => handleClickNodeRemove(event, node))
		}
		else if ([NODE_TYPE.DEVICE_POWER, NODE_TYPE.DEVICE_SOLOR].includes(type)) {
			nodeElement = drawDeviceElectricityNode(svg, x, y, node);
			nodeElement.addEventListener("mouseenter", handleMouseEnterNodeContent)
          	nodeElement.addEventListener("mouseleave", handleMouseLeaveNodeContent)
			nodeElement.querySelector('.action .edit-button').addEventListener("click", (event) => handleClickNodeEdit(event, node))
			nodeElement.querySelector('.action .remove-button').addEventListener("click", (event) => handleClickNodeRemove(event, node))
		}
		else if ([NODE_TYPE.DEVICE_NITROFAS, NODE_TYPE.DEVICE_METER, NODE_TYPE.DEVICE_GENGAS].includes(type)) {
			nodeElement = drawDeviceMeterNode(svg, x, y, node);
			nodeElement.addEventListener("mouseenter", handleMouseEnterNodeContent)
          	nodeElement.addEventListener("mouseleave", handleMouseLeaveNodeContent)
			nodeElement.querySelector('.action .edit-button').addEventListener("click", (event) => handleClickNodeEdit(event, node))
			nodeElement.querySelector('.action .remove-button').addEventListener("click", (event) => handleClickNodeRemove(event, node))
		}
		if(nodeElement) {
			nodeElement.addEventListener("click", (e) => {
				context.emit("onClickNode", e, { level, ref, value });
			});
		}
		nodes.value.reduce((acc, cur) => {
			if(cur.value === node.value) {
			cur.el = nodeElement
			}
			acc.push(cur)
			return acc
		}, [])
		if (level > 1) {
			drawArrow(svg, { from: ref, to: value });
		}
      }
    }

	const draw = (tree) => {
		clear(element)
		initialScreen(props)
		initialNodes(tree)
		transformNodes(tree)
		drawNodes(nodes.value)
	}

	onMounted(() => {
		draw(tree)
	})

	watch(() => props.tree, (newVal) => {
		draw(newVal)
	}, { deep: true })

	return {
		nodes,
		clear
	};
};