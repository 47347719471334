import { getPowerBIReport, getPowerBIExecutiveReport } from "@/services/api/powerbi.api";
import { POWER_BI_REQUEST, POWER_BI_SUCCESS, POWER_BI_FAILURE, POWER_BI_CLEAR } from "../types";
import { parsePowerBI } from "../../services/api/transforms/parsePowerBI";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		statusCode: null,
		isEmpty: false
	},

	getters: {
		getLayout(state) {
			return state.data.layout;
		}
	},

	mutations: {
		[POWER_BI_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[POWER_BI_SUCCESS](state, data) {
			state.data = parsePowerBI(data);
			state.isEmpty = state.data.isEmpty;
			state.isLoading = false;
			state.statusCode = data.status;
		},
		[POWER_BI_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.isEmpty = true;
			state.statusCode = error.response?.status;
		},
		[POWER_BI_CLEAR](state) {
			state.isEmpty = true;
		}
	},

	actions: {
		async getPowerBIReport({ commit }, id) {
			try {
				commit(POWER_BI_REQUEST);
				const { data } = await getPowerBIReport(id);
				commit(POWER_BI_SUCCESS, data);
			} catch (error) {
				commit(POWER_BI_FAILURE, error);
			}
		},
		async getPowerBIExecutiveReport({ commit }, id) {
			try {
				commit(POWER_BI_REQUEST);
				const { data } = await getPowerBIExecutiveReport(id);
				commit(POWER_BI_SUCCESS, data);
			} catch (error) {
				commit(POWER_BI_FAILURE, error);
			}
		},
		changeEmptyData({ commit }) {
			try {
				commit(POWER_BI_CLEAR);
			} catch (error) {
				commit(POWER_BI_FAILURE, error);
			}
		}

	}
};
