<template>
	<BaseCardIcon color="none" class="no-shipto-container">
		<template v-slot:icon>
			<div class="bg-icon">
				<FontAwesomeIcon
					:icon="['far', 'file-times']"
					color="file-times"
				/>
			</div>
		</template>
		<template v-slot:head>
			<div class="no-ship-to-head">
				No order information
			</div>
		</template>
		<template v-slot:body>
			<div class="no-ship-to-body">
				Your order will be appear here once you’ve created the order.
			</div>
		</template>
	</BaseCardIcon>
</template>

<script>
import BaseCardIcon from "@/components/BaseCardIcon.vue";

export default {
	components: {
		BaseCardIcon
	}
};
</script>

<style lang="scss" scoped>
.no-shipto-container {
	margin-top: rem(66);
	margin-left: auto;
	margin-right: auto;

	.no-ship-to-head {
		font-size: rem(48);
	}

	.no-ship-to-body {
		font-size: rem(20);
	}

	.bg-icon {
		background-color: $color-ice-two;
		padding: rem(22);
		border-radius: 50%;
	}

	.icon-wrapper svg {
		width: rem(60);
		height: rem(60);
		color: $color-green-haze;
	}
}
</style>