<template>
	<div id="power-bi-container" class="power-bi-container"></div>
</template>

<script>
import * as pbi from "powerbi-client";

export default {
	props: {
		data: {
			type: Object,
			default: null
		}
	},

	mounted() {
		const permissions = pbi.models.Permissions.All;
		const configPowerBI = {
			type: "report",
			tokenType: pbi.models.TokenType.Embed,
			accessToken: this.data?.data?.token,
			embedUrl: this.data?.data?.embedUrl,
			id: this.data?.data?.id,
			permissions
		};
		const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
		const dashboardContainer = document.getElementById("power-bi-container");
		const dashboard = powerbi.embed(dashboardContainer, configPowerBI);
		dashboard.off("loaded");
		dashboard.off("rendered");
		dashboard.on("error", () => {
			this.dashboard.off("error");
		});
	}
};
</script>

<style lang="scss" scoped>
	.power-bi-container {
		height: calc((73vw - rem(60)) * 0.59);
		width: 100%;
		margin-bottom: rem(40);
		overflow-y: clip;
	}
</style>