<template>
	<div class="overview-container">
		<div class="overview-bar">
			<NitroFASIcon
				v-if="data.type === MODULE_TYPES.NITRO_FAS"
				:value="data.UPH"
				size="large"
			/>
			<TankIcon
				v-if="isTankType"
				:status="data.tankStatus"
				:value="data.currentLevel"
				:percentage="data.tankPercent"
				size="large"
			/>
			<BulkHydrogenIcon
				v-if="isBulkHydrogenType"
				:status="data.levelStatus"
				:value="data.currentLevel"
				:percentage="data.tankPercent"
				size="large"
			/>
			<MeterIcon
				v-else-if="data.type === MODULE_TYPES.METER"
				:value="data.flowRate"
				:percentage="data.flowRatePercent"
				size="large"
			/>
			<WaterIcon
				v-else-if="data.type === MODULE_TYPES.O2_AERATION"
				:shipId="data.shipId"
				:value="data.DO02"
				:unit="WAST_WATER_UNIT"
				color="grey"
				size="large"
			/>
			<WaterIcon
				v-else-if="data.type === MODULE_TYPES.ZLD"
				:shipId="data.shipId"
				:value="data.flowRate"
				:unit="HTML_FLOW_RATE_UNIT_PER_HR"
				color="blue"
				size="large"
			/>
			<WaterIcon
				v-else-if="data.type === MODULE_TYPES.ZLD2"
				:shipId="data.shipId"
				:value="data.flowRate"
				:unit="HTML_FLOW_RATE_UNIT_PER_HR"
				color="blue"
				size="large"
			/>
			<CerIcon
				v-else-if="data.type === MODULE_TYPES.CER"
				:value="data.outletTemperature"
				label="Outlet"
				size="large"
				class="cer-icon"
			/>
			<GenGasIcon
				v-else-if="data.type === MODULE_TYPES.GENGAS"
				:value="data.flowRate"
				:unit="HTML_FLOW_RATE_UNIT_PER_HR"
				:percentagePoint="data.flowRatePercent"
				:maxValue="data.maxRange"
				label="Outlet"
				size="large"
				class="cer-icon"
			/>
			<PowerIcon
				v-else-if="[MODULE_TYPES.PWR, MODULE_TYPES.SOR].includes(data.type)"
				:kilowatt="data.kw"
				:value="data.percentage"
				:empty="data.kw === undefined || data.maxRange === undefined"
				size="large"
				class="pwr-icon"
			/>
		</div>
		<div class="overview-values">
			<div
				v-for="(item, index) in data.overview"
				:key="index"
				class="overview-info"
			>
				<p class="title" v-if="item.titleIcon" v-html="item.titleIcon"></p>
				<div class="title" v-else>
					<div class="title-dropdown" v-if="item.type === 'icon' || item.type === 'text'">
						<BaseDropdown
							:searchable="false"
							:list="item.typeOptions"
							:value="item.type"
							@input="(input) => handleChangeSelectedDropdown(index, input)"
							class="title-selectdropdown"
						/>
					</div>
					<div class="title-input">
						<BaseFormInput
							v-if="item.show && (item.type !== 'icon')"
							:value="item.field"
							:placeholder="item.title"
							:maxLength="40"
							@input="(input) => handleChangeTextField(index, input)"
						/>
					</div>
				</div>
				<p class="value">
					<BaseSwitch
						v-if="item.show"
						:name="item.key"
						:checked="item.value"
						@input="handleChange"
					/>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import TankIcon from "@/components/deviceIcons/TankIcon.vue";
import MeterIcon from "@/components/deviceIcons/MeterIcon.vue";
import WaterIcon from "@/components/deviceIcons/WaterIcon.vue";
import CerIcon from "@/components/deviceIcons/CerIcon.vue";
import GenGasIcon from "@/components/deviceIcons/GenGasIcon.vue";
import NitroFASIcon from "@/components/deviceIcons/NitroFASIcon.vue";
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import PowerIcon from "@/components/deviceIcons/PowerIcon.vue";
import BulkHydrogenIcon from "@/components/deviceIcons/BulkHydrogenIcon.vue";
import { MODULE_TYPES } from "../../enums/modules";
import { HTML_FLOW_RATE_UNIT_PER_HR, WAST_WATER_UNIT } from "../../selectors/transform/unit";

export default {
	name: "ModuleOverviewWithCustomisation",

	components: {
		TankIcon,
		WaterIcon,
		MeterIcon,
		CerIcon,
		NitroFASIcon,
		GenGasIcon,
		BaseSwitch,
		BaseFormInput,
		BaseDropdown,
		PowerIcon,
		BulkHydrogenIcon
	},

	props: {
		data: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			MODULE_TYPES,
			HTML_FLOW_RATE_UNIT_PER_HR,
			WAST_WATER_UNIT
		};
	},
	methods: {
		handleChange(value, name) {
			const length = this.data.overview.length;
			let indexOverview = 0;
			for (let i = 0; i < length; i++) {
				if (name === this.data.overview[i].key) {
					indexOverview = i;
					break;
				}
			}
			this.$emit("override", indexOverview, value);
		},
		handleChangeTextField(index, input) {
			this.$emit("overrideTextField", index, input);
		},
		handleChangeSelectedDropdown(index, input) {
			this.$emit("overideSelectedDropdown", index, input);
		}
	},

	computed: {
		isTankType() {
			return this.data.type === MODULE_TYPES.TANK && this.data.productId !== MODULE_TYPES.BHY;
		},
		isBulkHydrogenType() {
			return this.data.type === MODULE_TYPES.TANK && this.data.productId === MODULE_TYPES.BHY;
		}
	}
};
</script>

<style lang="scss" scoped>
.overview {
	&-container {
		display: flex;
		width: 100%;
	}

	&-info {
		display: flex;
		justify-content: space-between;

		p {
			margin: 0 0 rem(12);
			color: $color-dark-blue-grey;

			&:nth-child(even) {
				display: flex;
				font-weight: 600;
			}
		}

		.title {
			display: inline-flex;
			margin: 0 0 rem(12);
			gap: rem(12);
			flex-basis: auto;
			color: $color-dark-blue-grey;

			&-dropdown {
				flex-basis: 1;
				display: flex;
			}

			&-input {
				flex-grow: 3;
				display: flex;
			}
		}

		.value {
			display: flex;
			align-items: center;
		}
	}

	&-info:last-of-type {
		p {
			margin: 0;
		}
	}

	&-values {
		flex: 1;

		.title {
			flex: none;
			width: 65%;
		}

		.value {
			.progress-bar {
				width: rem(96);
				position: relative;
				top: rem(2);
				margin-left: rem(8);
			}
		}

		.title-dropdown {
			::v-deep {
				.dropdown-value {
					height: rem(45);
					border-radius: $border-radius-normal;
					margin-bottom: rem(14);
				}
			}
		}
	}

	&-bar {
		min-width: rem(144);
		margin-right: rem(16);
		padding: rem(8);
		/* stylelint-disable */
		&::v-deep {
			.nitro-fas-wrapper {
				width: rem(128);
				height: rem(128);
			}
		}
		/* stylelint-enable */
	}
}
</style>

<style scoped>
/* we have to create second style because scss not supported to deep selector */
.html >>> sup {
	line-height: 0;
}
</style>