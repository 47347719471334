<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<template
			v-else
		>
			<BaseLoading
				v-if="airCompHistories.isLoading"
			/>
			<BaseFilterExport
				v-if="!airCompHistories.isLoading && permissions.dashboard.exportable"
				mode="range"
				class="custom-filter-export"
				@onSubmit="handleSubmitExport"
			/>
			<BaseTable
				:item="item"
				:tableOptions="tableOptions"
				:columnOptions="columnOptions"
				:pagination="pagination"
				:loading="loading"
				:stickyHeader="true"
				@onPageChange="handlePageChange"
			/>
		</template>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";

import BaseLoading from "@/components/BaseLoading.vue";
import BaseTable from "@/components/BaseTable.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";

import { getUri } from "../../selectors/helpers";
import userPermission from "../../mixins/userPermission";
import { MILI_QUE_UNIT } from "../../selectors/transform/unit";

export default {
	components: {
		BaseLoading,
		EmptyOrderHistory,
		BaseFilterExport,
		BaseTable
	},

	mixins: [userPermission],

	data() {
		return {
			airCompId: this.$route.params.id,
			thead: [
				"Date",
				`Average SEC <br />(kW/${MILI_QUE_UNIT}/min)`,
				`C.A. index <br />(Baht/1,000 ${MILI_QUE_UNIT})`,
				`Total power <br />(kWh)`,
				"Total power cost <br /> (Baht)",
				"Total savings <br /> (Baht)"
			],

			tableOptions: [
				"is-striped is-fullwidth"
			],

			columnOptions: [
				{ className: "bold" },
				{ className: "" },
				{ className: "" },
				{ className: "" },
				{ className: "" }
			],

			page: this.$route.query.page || 1
		};
	},

	computed: {
		...mapState(["airCompHistories"]),
		data() {
			return this.airCompHistories.data;
		},
		pagination() {
			return this.airCompHistories.pagination;
		},
		loading() {
			return this.airCompHistories.isLoading;
		},
		item() {
			return {
				thead: this.airCompHistories.thead,
				tbody: this.data.map((item) => [item.dateString, item.specKw, item.kwConsumption, item.electricRate, item.savingConsumption])
			};
		},
		isEmpty() {
			return this.airCompHistories.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.airCompId,
			httpStatus: this.airCompHistories.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("airCompHistories", {
			fetchAirCompHistories: "fetchAirCompHistories"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		fetchData() {
			const { airCompId, page } = this;
			return this.fetchAirCompHistories({
				id: airCompId,
				params: { page }
			});
		},

		handlePageChange(page = 1) {
			this.page = page;
			this.fetchData();
			this.$router.replace({ query: { page } }).catch(() => {});
		},

		handleSubmitExport(form) {
			const params = {
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).format("YYYY-MM-DD"),
						endDate: dayjs(form.dateRange.end).format("YYYY-MM-DD")
					} : null
				)
			};
			const path = `/airComp/${this.airCompId}/export-order-histories`;
			const uri = getUri(path, params);
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.custom-filter-export {
	margin-top: rem(-8);
	margin-bottom: rem(11);
	justify-content: flex-end;
}
</style>