<template>
	<BaseLayout pageTitle="Device health monitoring" :pageSubtitle="getPageSubtitle">
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:query="query"
					:products="productOptions"
					:modules="moduleOptions"
					@submit="handleSubmitFilter"
				/>
			</div>

			<div class="tab">
				<BaseTabPanel v-model="tabIndex" :items="tabs" @input="handleChageTab"/>
			</div>

			<div class="devices">
				<component :is="currentTabComponent"></component>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseTabPanel from "@/components/BaseTabPanel.vue";
import SearchFilterForm from "@/components/monitor/deviceHealth/SearchFilterForm.vue";
import NoSignal from "@/views/monitors/DeviceHelath/NoSignal.vue";
import InactiveDevice from "@/views/monitors/DeviceHelath/InactiveDevice.vue";
import { cleanQuery } from "../../../selectors/helpers";
import { setDashBoardQuery } from "../../../selectors/helpers/backtoDashBoard";
import { dropdownProductTypeOptions } from "../../../enums/productType";
import { PAGE_NAME } from "../../../enums/pagePermission";


export default {
	name: "MonitorDeviceHealth",

	components: {
		SearchFilterForm,
		BaseTabPanel,
		NoSignal,
		InactiveDevice
	},
	updated() {
		setDashBoardQuery({
			pageName: PAGE_NAME.MONITORDEVICEHEALTH,
			...this.query
		});
	},

	data() {
		return {
			query: {
				keyword: this.$route.query.keyword || null,
				productName: this.$route.query.productName || null,
				moduleName: this.$route.query.moduleName || null,
				sort: this.$route.query.sort || null,
				tab: this.$route.query.tab || "active"
			},
			tabIndex: this.$route.query.tab === "inactive" ? 1 : 0,
			tabs: [
				{
					icon: {
						type: "far",
						name: "signal-slash",
						width: "15px",
						height: "13px"
					},
					title: "No signal"
				},
				{
					icon: {
						type: "far",
						name: "window-close",
						width: "12px",
						height: "13px"
					},
					title: "Inactive device"
				}
			]
		};
	},

	computed: {
		...mapGetters("devicesNoSignal", {
			getLastUpdateNoSignal: "getLastUpdate"
		}),
		...mapGetters("devicesInactive", {
			getLastUpdateInActive: "getLastUpdate"
		}),

		getPageSubtitle() {
			if (this.tabIndex === 0) {
				return this.getLastUpdateNoSignal
					? `Last update: ${this.getLastUpdateNoSignal}`
					: null;
			}
			return this.getLastUpdateInActive
				? `Last update: ${this.getLastUpdateInActive}`
				: null;
		},
		currentTabComponent() {
			if (this.tabIndex === 0) {
				return NoSignal;
			}

			return InactiveDevice;
		},
		productOptions() {
			return [...dropdownProductTypeOptions];
		},
		moduleOptions() {
			return [
				{
					value: null,
					label: "Module: All"
				},
				{
					value: "Tank",
					label: "Tank"
				},
				{
					value: "Meter",
					label: "Meter"
				},
				{
					value: "Application",
					label: "Application"
				},
				{
					value: "Energy",
					label: "Energy"
				}
			];
		}
	},

	created() {
		const isFirstTab = this.tabIndex === 0;

		if (isFirstTab) {
			this.addActivityLogHealthMonitoring();
		}
	},

	methods: {
		...mapActions("activityLogAdd", {
			addActivityLogHealthMonitoring: "addActivityLogHealthMonitoring"
		}),
		handleChageTab(tabIndex) {
			const query = cleanQuery(JSON.parse(JSON.stringify({
				...this.$route.query,
				...this.query,
				tab: tabIndex === 1 ? "inactive" : "active",
				sort: null
			})));

			this.$router.push({ query: {
				...query
			} }).catch(() => {});
		},
		handleChangeQuery() {
			const query = cleanQuery(JSON.parse(JSON.stringify({
				...this.$route.query,
				...this.query,
				page: null
			})));

			this.$router.push({ query: {
				...query
			} }).catch(() => {});
		},

		handleSubmitFilter(value) {
			this.query.keyword = value.keyword;
			this.query.moduleName = value.moduleName;
			this.query.productName = value.productName;
			this.handleChangeQuery();
		}
	}
};
</script>

<style lang="scss" scoped>
.tab {
	margin-right: rem(-30);
	margin-left: rem(-30);
}

.devices {
	margin-top: rem(24);
}
</style>