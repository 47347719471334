import { getAirCompPressureAPI } from "@/services/api/airComp.api";
import { AIR_COMP_PRESSURE_REQUEST, AIR_COMP_PRESSURE_SUCCESS, AIR_COMP_PRESSURE_FAILURE } from "../../types";
import { parseAirCompPressure } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parseAirCompPressure({}),
		loading: true,
		empty: false
	},

	mutations: {
		[AIR_COMP_PRESSURE_REQUEST](state) {
			state.detail = parseAirCompPressure({});
			state.loading = true;
			state.empty = false;
		},
		[AIR_COMP_PRESSURE_SUCCESS](state, data) {
			const detail = data;
			state.detail = parseAirCompPressure(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[AIR_COMP_PRESSURE_FAILURE](state) {
			state.detail = parseAirCompPressure({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async fetchAirCompPressure({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_PRESSURE_REQUEST);
				const { data } = await getAirCompPressureAPI(id, params);
				commit(AIR_COMP_PRESSURE_SUCCESS, data);
			} catch (error) {
				commit(AIR_COMP_PRESSURE_FAILURE, error);
			}
		}
	}
};