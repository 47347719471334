<template>
	<div>
		<CardBarChart
			:chartData="chartOptions"
			:isLoading="loading"
			:isEmpty="empty"
			:lastUpdate="updatedAt"
			:lastUpdateLeft="false"
			:chartTitle="chartTitle"
		/>
	</div>
</template>

<script>
import CardBarChart from "@/components/chart/CardBarChart.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { transformDataBarChartAirCompEnegySaving, transformBarChart } from "../../selectors/transform/barChart";

const { perWeek, perTwoWeek, perMonth } = MODULE_SORT_DATE_CHART;

export default {
	props: {
		data: {
			type: Array,
			default: () => ([])
		},
		updatedAt: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Boolean,
			default: false
		},
		chartTitle: {
			type: String,
			default: "Energy Saving"
		},
		chartYAxisLabel: {
			type: String,
			default: "Energy Saving (MWh/day)"
		},
		barWidth: {
			required: true,
			type: Number
		},
		filterSelected: {
			required: true,
			type: String
		},
		tagName: {
			required: true,
			type: Object
		}
	},

	components: {
		CardBarChart
	},

	computed: {
		chartOptions() {
			const dateSort = this.filterSelected;
			const dateReportFormat = [perWeek, perTwoWeek, perMonth].includes(dateSort) ? "day" : "year";
			const options = { dateReportFormat, dateSort };
			const xyAxis = transformDataBarChartAirCompEnegySaving(this.data, options);
			const optionFurnaceBarChart = {
				yAxisText: this.tagName.tagNameSaving,
				xAxisText: "Date",
				legend: {
					show: true,
					data: [
						this.tagName.tagNameSavingActual,
						this.tagName.tagNameSavingSaving
					],
					icon: "circle",
					bottom: 0,
					left: 24,
					itemWidth: 10,
					itemHeight: 10,
					itemGap: 24,
					fontFamily: "TFSrivichai",
					fontSize: 16,
					fontColor: "#71717",
					fontWeight: "bold"
				},
				grid: {
					left: "24px",
					right: "24px",
					bottom: "48px",
					containLabel: true
				}
			};
			return transformBarChart(optionFurnaceBarChart)(xyAxis);
		}
	}
};
</script>