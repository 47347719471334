<template>
	<div :style="{ height: `${height}px` }" class="segment">
		<div class="segment-wrapper">
			<div
				v-for="(item, index) in filterItems"
				:key="index"
				:style="{ width: `${item.width}`, backgroundColor: item.color }"
			>
				<span>
					{{ item.value }}%
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		/**
		 * value: Number,
		 * color: String
		 */
		items: {
			type: Array,
			default: () => ([])
		},
		height: {
			type: Number,
			default: 16
		}
	},

	computed: {
		filterItems() {
			return this.items.map((item) => {
				return {
					...item,
					value: item.value !== undefined && item.value !== null ? Number(item.value).toFixed(1) : "",
					width: item.value > 0 && item.value <= 5 ? "48px" : `${item.value}%`
				};
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.segment {
	position: relative;
	display: flex;
	width: 100%;

	&-wrapper {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: rem(4);
		overflow: hidden;

		> * {
			display: flex;
			height: 100%;
			font-family: $font-family-base;
			font-size: 18px;
			font-weight: bold;
			color: $color-white;
			line-height: rem(23);

			span {
				padding-left: rem(6);
				height: 100%;
			}
		}
	}
}
</style>