
import { getDevicesListsAPI, getDeviceSyncAPI } from "@/services/api/devices.api";
import { getStandardModuleDeviceSyncAPI } from "@/services/api/standardModule.api";
import dayjs from "dayjs";

import {
	VIEW_DEVICE_REQUEST,
	VIEW_DEVICE_SUCCESS,
	VIEW_DEVICE_FAILURE,
	UPDATE_DEVICE_PIN_STATUS,
	DEVICE_LOAD_SYNC_SUCCESS
} from "../../types";
import { transformViewDevices, transformStdViewDevices, transformStdViewDevicesFromDeviceSync } from "../../../services/api/transforms/parserViewDevices";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		pagination: {},
		isNoShipTo: false
	},

	mutations: {
		[VIEW_DEVICE_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isNoShipTo = false;
		},
		[VIEW_DEVICE_SUCCESS](state, { data, pagination }) {
			state.isLoading = false;
			state.data = transformStdViewDevices(transformViewDevices(data));
			state.pagination = pagination;
		},
		[VIEW_DEVICE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isNoShipTo = true;
		},
		[UPDATE_DEVICE_PIN_STATUS](state, { id, status }) {
			state.data = state.data.map((item) => {
				return {
					...item,
					isPinned: (() => {
						if (item.id === id) {
							return status;
						}
						return item.isPinned;
					})()
				};
			});
		},
		[DEVICE_LOAD_SYNC_SUCCESS](state, { currentDevice, newDevice }) {
			const changeFormatLastUpdate = { ...newDevice, lastUpdate: newDevice.lastUpdate ? dayjs(newDevice.lastUpdate).format("D MMM YYYY, HH:mm") : "" };
			state.data = state.data.map((device) => {
				if (device.id === currentDevice.id) {
					const tempDevice = { ...device, ...changeFormatLastUpdate, isStandardModule: !!device.isStandardModule };
					return transformStdViewDevicesFromDeviceSync(tempDevice);
				}

				return { ...device };
			});
		}
	},

	actions: {
		async getDevicesLists({ commit }, {
			page = null,
			module = null,
			sort = null,
			q = null,
			productname = null,
			owner = null
		}) {
			try {
				commit(VIEW_DEVICE_REQUEST);

				const { data } = await getDevicesListsAPI({
					page,
					module,
					sort,
					q,
					productname,
					owner
				});

				commit(VIEW_DEVICE_SUCCESS, data);
			} catch (error) {
				commit(VIEW_DEVICE_FAILURE);
			}
		},

		updateDevicePinStatus({ commit }, { id, status }) {
			commit(UPDATE_DEVICE_PIN_STATUS, { id, status });
		},

		async updateDeviceLoadSync({ commit }, currentDevice) {
			try {
				if (currentDevice.isStandardModule) {
					const { data } = await getStandardModuleDeviceSyncAPI(currentDevice.templateId, { device_id: currentDevice.id });
					commit(DEVICE_LOAD_SYNC_SUCCESS, { currentDevice, newDevice: data });
				} else {
					const isBHY = currentDevice.productId === "BHY";
					const productType = isBHY
						? "BHY"
						: currentDevice.type;
					const { data } = await getDeviceSyncAPI(currentDevice.id, productType);
					commit(DEVICE_LOAD_SYNC_SUCCESS, { currentDevice, newDevice: data });
				}
			} catch (error) {
				// error
			}
		}
	}
};
