<template>
	<div>
		<BaseLoading v-if="o2AerationInfo.loading"/>

		<div :class="['row row-eq-height', { 'hide-content': o2AerationInfo.loading }]">
			<div class="col-12 col-xl-7">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ o2AerationInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview :data="o2AerationInfo.data" />
					</template>
				</BaseCard>
			</div>
			<ModuleLocation :info="o2AerationInfo.data" />
		</div>
		<div class="section-title">
			<h3 class="section-title-text">Waste water</h3>
		</div>
		<CardLineChart
			:chartData="getOxygenDissolveLineChartConfig"
			:isLoading="o2AerationOxygenDissolve.isLoading"
			:isEmpty="o2AerationOxygenDissolve.isEmpty"
			:lastUpdate="o2AerationOxygenDissolve.lastUpdate"
			:filterOptions="oxygenDissolveFilterDateOptions"
			chartTitle="Dissolve oxygen - DO"
			@onSelectDatePicker="handleOxygenDissolveDateSelect"
			@onSelectDropDown="handleOxygenDissolveDateDropdown"
		/>
		<div class="section-title-oxygen">
			<h3 class="section-title-text">Oxygen</h3>
		</div>
		<CardLineChart
			:chartData="getOxygenFloRateLineChartConfig"
			:isLoading="o2AerationOxygenFlowRate.isLoading"
			:isEmpty="o2AerationOxygenFlowRate.isEmpty"
			:lastUpdate="o2AerationOxygenFlowRate.lastUpdate"
			:filterOptions="oxygenFlowRateFilterDateOption"
			chartTitle="Oxygen flowrate"
			@onSelectDatePicker="handleOxygenFlowRateDateSelect"
			@onSelectDropDown="handleOxygenFlowRateDateDropdown"
		/>
		<CardBarChart
			:chartData="getOxygenConsumptionBarChartConfig"
			:lastUpdate="o2AerationOxygenConsumption.lastUpdate"
			:isLoading="o2AerationOxygenConsumption.isLoading"
			:isEmpty="o2AerationOxygenConsumption.isEmpty"
			:filterOptions="oxygenConsumptionDateFilterOption"
			chartTitle="Oxygen consumption"
			@dateSelected="handlegetOxygenConsumptionDateSelected"
		/>
		<div class="section-title-power-consumption">
			<h3 class="section-title-text">Power consumption</h3>
		</div>
		<CardLineChart
			:chartData="getPowerConsumptionLineChartConfig"
			:isLoading="o2AerationPowerConsumption.isLoading"
			:isEmpty="o2AerationPowerConsumption.isEmpty"
			:lastUpdate="o2AerationPowerConsumption.lastUpdate"
			:filterOptions="powerConsumptionFilterDateOption"
			chartTitle="Power consumption"
			@onSelectDatePicker="handlePowerConsumptionDateSelect"
			@onSelectDropDown="handlePowerConsumptionDateDropdown"
		/>
		<GrafanaDisplay
			:deviceId="o2AerationId"
		/>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseCard from "@/components/BaseCard.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { getDateNumber } from "../../selectors/helpers";
import { transformO2AerationOxygenDissolveLineChart } from "../../selectors/transform/lineChart/O2Aeration/oxygenDissolve";
import { transformDataBarChart } from "../../selectors/transform/barChart";
import { transformOxygenConsumptionBarchart } from "../../selectors/transform/barChart/o2Aeration/oxygenConsumption";
import { transformO2AerationPowerConsumptionLineChart } from "../../selectors/transform/lineChart/O2Aeration/powerConsumption";
import { transformO2AerationOxygenFlowRateLineChart } from "../../selectors/transform/lineChart/O2Aeration/oxygenFlowRate";

const { perDay, perThreeDay, perWeek, perTwoWeek, perYear } = MODULE_SORT_DATE_CHART;

export default {
	name: "o2AerationInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		ModuleOverview,
		ModuleLocation,
		CardLineChart,
		CardBarChart
	},

	data() {
		return {
			o2AerationId: this.$route.params.id,
			oxygenDissolveSortBy: perDay,
			nitrogenFlowRateSortBy: perDay,
			powerConsumptionSortBy: perDay,
			oxygenFlowRateSortBy: perDay,
			oxygenFlowRateFilterDateOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(7 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},
			powerConsumptionFilterDateOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(7 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},
			oxygenDissolveFilterDateOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(7 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},

			oxygenConsumptionDateFilterOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			}
		};
	},

	computed: {
		...mapState([
			"o2AerationInfo",
			"o2AerationOxygenDissolve",
			"o2AerationOxygenConsumption",
			"o2AerationPowerConsumption",
			"o2AerationOxygenFlowRate"
		]),

		...mapGetters("o2AerationOxygenFlowRate", {
			getTransformOxygenFlowRate: "getTransformOxygenFlowRate"
		}),

		...mapGetters("o2AerationOxygenDissolve", {
			getTransformOxygenDissolve: "getTransformOxygenDissolve"
		}),

		...mapGetters("o2AerationPowerConsumption", {
			getTransformPowerConsumption: "getTransformPowerConsumption"
		}),

		...mapGetters("o2AerationOxygenConsumption", {
			getTrasnformNitrogen: "getTrasnformNitrogen"
		}),

		getOxygenFloRateLineChartConfig() {
			const oxygenFlowRateSortBy = this.oxygenFlowRateSortBy;
			return transformO2AerationOxygenFlowRateLineChart(oxygenFlowRateSortBy, this.getTransformOxygenFlowRate);
		},

		getOxygenFlowRateDateSelected() {
			const dateRange = this.oxygenFlowRateFilterDateOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getPowerConsumptionLineChartConfig() {
			const powerConsumptionSortBy = this.powerConsumptionSortBy;
			return transformO2AerationPowerConsumptionLineChart(powerConsumptionSortBy, this.getTransformPowerConsumption);
		},

		getPowerConsumptionDateSelected() {
			const dateRange = this.powerConsumptionFilterDateOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getOxygenDissolveLineChartConfig() {
			const oxygenDissolveSortBy = this.oxygenDissolveSortBy;
			return transformO2AerationOxygenDissolveLineChart(oxygenDissolveSortBy, this.getTransformOxygenDissolve);
		},



		getOxygenDissolveDateSelected() {
			const dateRange = this.oxygenDissolveFilterDateOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getOxygenConsumptionDateSelected() {
			const dateRange = this.oxygenConsumptionDateFilterOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getOxygenConsumptionBarChartConfig() {
			const dateSort = this.getOxygenConsumptionDateSelected.dateNumber;
			const dateReportFormat = [perWeek, perTwoWeek].includes(dateSort) ? "day" : "year";
			const options = { dateReportFormat };
			const xyAxis = transformDataBarChart(this.o2AerationOxygenConsumption, options);
			return transformOxygenConsumptionBarchart(xyAxis);
		}
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("o2AerationOxygenDissolve", {
			getOxygenDissolve: "getOxygenDissolve"
		}),

		...mapActions("o2AerationOxygenFlowRate", {
			getOxygenFlowRate: "getOxygenFlowRate"
		}),

		...mapActions("o2AerationOxygenConsumption", {
			getOxygenConsumption: "getOxygenConsumption"
		}),

		...mapActions("o2AerationPowerConsumption", {
			getPowerConsumption: "getPowerConsumption"
		}),

		fetchData() {
			return Promise.all([
				this.getOxygenDissolve({ id: this.o2AerationId, query: { filter: this.getOxygenDissolveDateSelected.dateNumber } }),
				this.getOxygenConsumption({ id: this.o2AerationId, filterBy: this.getOxygenConsumptionDateSelected.dateNumber }),
				this.getPowerConsumption({ id: this.o2AerationId, filterBy: this.getPowerConsumptionDateSelected.dateNumber }),
				this.getOxygenFlowRate({ id: this.o2AerationId, filterBy: this.getOxygenFlowRateDateSelected.dateNumber })
			]);
		},

		handleOxygenFlowRateDateSelect(date) {
			const { start, end } = date;
			this.oxygenFlowRateSortBy = perWeek;
			this.getOxygenFlowRate({
				id: this.o2AerationId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},

		handleOxygenFlowRateDateDropdown(filterBy) {
			this.oxygenFlowRateSortBy = filterBy;
			this.getOxygenFlowRate({
				id: this.o2AerationId,
				query: { filter: filterBy }
			});
		},

		handlePowerConsumptionDateSelect(date) {
			const { start, end } = date;
			this.powerConsumptionSortBy = perWeek;
			this.getPowerConsumption({
				id: this.o2AerationId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},

		handlePowerConsumptionDateDropdown(filterBy) {
			this.powerConsumptionSortBy = filterBy;
			this.getPowerConsumption({
				id: this.o2AerationId,
				query: { filter: filterBy }
			});
		},

		handleOxygenDissolveDateSelect(date) {
			const { start, end } = date;
			this.oxygenDissolveSortBy = perWeek;
			this.getOxygenDissolve({
				id: this.o2AerationId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},

		handleOxygenDissolveDateDropdown(filterBy) {
			this.oxygenDissolveSortBy = filterBy;
			this.getOxygenDissolve({
				id: this.o2AerationId,
				query: { filter: filterBy }
			});
		},

		handlegetOxygenConsumptionDateSelected(date) {
			const { o2AerationId } = this;

			this.oxygenConsumptionDateFilterOption.date.selected.range = date;

			this.getOxygenConsumption({
				id: o2AerationId,
				filterBy: this.getOxygenConsumptionDateSelected.dateNumber
			});
		}

	}
};
</script>

<style lang="scss" scoped>
.section-title {
	margin: rem(15) 0 rem(30);

	&-power-consumption,
	&-oxygen {
		margin: rem(50) 0 rem(30);
	}

	&-text {
		@include fontSub();

		font-size: rem(32);
		font-weight: $font-weight-bold;
		color: $color-dark-blue-grey;
	}
}

.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem($spacing-7);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^=col]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}
</style>
