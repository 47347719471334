import { CUSTOMER_ROLES, CUSTOMER_ROLES_LABEL } from "../../../enums/customerManagement/customerRole";

export const getCustomerRoles = () => {
	return Object.keys(CUSTOMER_ROLES_LABEL).map((key) => ({
		label: CUSTOMER_ROLES_LABEL[key],
		value: CUSTOMER_ROLES[key]
	}));
};

export const getCustomerRolesFilter = () => {
	return [
		{ label: "Role: All", value: null },
		...getCustomerRoles()
	];
};