import { getMoneySavingAPI } from "../../../services/api/Cers.api";
import {
	CER_MONEY_SAVING_REQUEST,
	CER_MONEY_SAVING_SUCCESS,
	CER_MONEY_SAVING_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},
	mutations: {
		[CER_MONEY_SAVING_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[CER_MONEY_SAVING_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[CER_MONEY_SAVING_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchMoneySaving({ commit }, { id = "", filterBy = "1d" }) {
			try {
				commit(CER_MONEY_SAVING_REQUEST);

				const { data } = await getMoneySavingAPI(id, filterBy);

				commit(CER_MONEY_SAVING_SUCCESS, data);
			} catch (error) {
				commit(CER_MONEY_SAVING_FAILURE);
			}
		}
	}
};
