<template>
	<label :class="['base-radio-button', { '-is-disabled' : $attrs.disabled }]">
		<input
			:value="value"
			:checked="checked === value"
			type="radio"
			v-bind="$attrs"
			@change="$emit('change', $event.target.value)"
		>

		<span class="checkmark"></span>

		<span
			v-if="label"
			class="label"
		>
			{{ label }}
		</span>
	</label>
</template>

<script>
export default {
	inheritAttrs: false,

	model: {
		prop: "checked",
		event: "change"
	},


	props: {
		value: {
			type: [String, Number, Boolean],
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		checked: {
			type: [String, Number, Boolean],
			default: ""
		},
		index: {
			type: Number,
			default: 0
		}
	}
};
</script>

<style lang="scss" scoped>
$radio-size: 20;
$radio-right-margin: 8;

.base-radio-button {
	position: relative;
	display: block;
	padding-left: rem(24);
	cursor: pointer;
	user-select: none;

	&.-is-disabled {
		cursor: not-allowed;

		> .label {
			color: $color-black;
		}
	}

	// Hide the browser's default radio
	input {
		display: none;
		opacity: 0;
		width: 0;
		height: 0;
		cursor: pointer;
	}

	// Create a custom radio
	.checkmark {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: rem($radio-size);
		width: rem($radio-size);

		display: flex;
		align-items: center;
		justify-content: center;

		background-color: transparent;
		border-radius: 50%;
		border: rem(1) solid $color-green-haze;
	}

	.label {
		color: $color-black;
		font-weight: bold;
	}

	& input:checked ~ .checkmark {
		border: rem(1) solid $color-green-haze;

		&::after {
			content: "";
			background-color: $color-green-haze;
			border-radius: rem(8);
			height: rem(12);
			width: rem(12);
		}
	}

	& input:focus ~ .checkmark {
		box-shadow: 0 0 0 rem(3) $color-green-haze;
	}


	& input:not(:checked):disabled ~ .checkmark {
		border: 2px solid yellow;
	}

	& input:checked:disabled ~ .label,
	& input:not(:checked):disabled ~ .label {
		color: yellow;
	}


	& input:checked:disabled ~ .checkmark {
		border: rem(7) solid yellow;
	}
}
</style>
