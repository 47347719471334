<template>
	<div>
		<BaseLoading v-if="zldInfo.isLoading" />
		<div :class="[{'row row-eq-height': isShowShipTo}, { 'hide-content': zldInfo.isLoading }]">
			<div :class="['col-12 no-gutter-sm', { 'col-xl-7': isShowShipTo }]">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">Last update: {{ zldInfo.data.updatedAt }}</div>
					</template>
					<template v-slot:body>
						<ModuleOverview :data="zldInfo.data" />
					</template>
				</BaseCard>
			</div>
			<ModuleLocation v-if="isShowShipTo" :info="zldInfo.data" />
		</div>
		<div class="zld-level-container">
			<CardLineChart
				v-if="isShowRawWaterRecycleWaterFlowRate"
				:chartData="getRecycleWaterDataLineChartConfig"
				:isLoading="zldVinyThaiWaterFlowRate.isLoading"
				:isEmpty="zldVinyThaiWaterFlowRate.isEmpty"
				:lastUpdate="zldVinyThaiWaterFlowRate.lastUpdate"
				:filterOptions="zldVinyThaiWaterFlowRateDateFilterOption"
				chartTitle="Raw water & Recycle water flow rate"
				@onSelectDatePicker="handleWaterFlowRateDateSelected"
				@onSelectDropDown="handleWaterFlowRateSelectDateDropDown"
			/>
			<CardLineChart
				v-if="isShowSilicaRecycleWater"
				:chartData="getSilicaInRecycleWaterLineChartConfig"
				:isLoading="silicaInReCycleWater.isLoading"
				:isEmpty="silicaInReCycleWater.isEmpty"
				:lastUpdate="silicaInReCycleWater.lastUpdate"
				:filterOptions="silicaDateFilterOption"
				chartTitle="Silica in recycle water"
				@onSelectDatePicker="handleSilicaDateSeleted"
				@onSelectDropDown="handleSilicaDateDropdown"
			/>
			<CardLineChart
				v-if="isShowElectricalConductivityRecycleWater"
				:chartData="getElectricalRecycleWaterLineChartConfig"
				:isLoading="electricalRecycleWater.isLoading"
				:isEmpty="electricalRecycleWater.isEmpty"
				:lastUpdate="electricalRecycleWater.lastUpdate"
				:filterOptions="electricalRecycleWaterFilterOption"
				chartTitle="Electrical conductivity in recycle water"
				@onSelectDatePicker="handleElectricalRecycleWaterDateSeleted"
				@onSelectDropDown="handleElectricalRecycleWaterDateDropdown"
			/>

			<CardLineChart
				v-if="isShowElectricalConductivityInletWater"
				:chartData="getElectricalInletWaterLineChartConfig"
				:isLoading="electricalInletWater.isLoading"
				:isEmpty="electricalInletWater.isEmpty"
				:lastUpdate="electricalInletWater.lastUpdate"
				:filterOptions="electrolyInletWaterFilterOption"
				chartTitle="Electrical conductivity in inlet water"
				@onSelectDatePicker="handleElectrolyInletWaterDateSeleted"
				@onSelectDropDown="handleElectrolyInletWaterDateDropdown"
			/>

			<CardBarChart
				v-if="isShowRecycleWaterProduction"
				:chartData="getRecycleWaterProduction"
				:lastUpdate="recycleWaterProduction.lastUpdate"
				:isLoading="recycleWaterProduction.isLoading"
				:isEmpty="recycleWaterProduction.isEmpty"
				:filterOptions="recycleWaterProductionFilterOptions"
				chartTitle="Recycle water production"
				@dateSelected="handleRecycleWaterProductionDateSelected"
			/>
			<GrafanaDisplay
				:deviceId="zldId"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseCard from "@/components/BaseCard.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import { transformZldVinyThaiWaterFlowRateConfig } from "../../selectors/transform/lineChart/ZldVinyThaiWaterFlowrate/index";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { getDateNumber, getDateDiff } from "../../selectors/helpers";
import { transformSilicaInRecycleWaterLineChartConfig } from "../../selectors/transform/lineChart/SilicaInRecycleWater";
import { transformElectricalRecycleWaterLineChartConfig } from "../../selectors/transform/lineChart/ElectricalRecycleWater";
import { transformElectricalInletWaterLineChartConfig } from "../../selectors/transform/lineChart/ElectricalInletWater/";
import { transformDataBarChart } from "../../selectors/transform/barChart";
import { transformRecycleWaterProduction } from "../../selectors/transform/barChart/ZldInfo/recycleWaterProduction";

const {
	perDay,
	perThreeDay,
	perTwoWeek,
	perWeek,
	perYear
} = MODULE_SORT_DATE_CHART;

export default {
	name: "zldInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		ModuleOverview,
		ModuleLocation,
		CardLineChart,
		CardBarChart
	},

	data() {
		return {
			markLineSilica: [{
				label: "Control line",
				color: "#ea7125",
				value: 70
			}],

			markLineECInRecycleWater: [{
				label: "Control line",
				color: "#ea7125",
				value: 5
			}],
			markLineECInletWater: [{
				label: "Control line",
				color: "#ea7125",
				value: 4000
			}],
			zldId: this.$route.params.id,
			zldVinyThaiWaterFlowRateSortBy: perDay,
			zldVinyThaiWaterFlowRateDateFilterOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(30 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},
			silicaSortBy: perDay,
			silicaDateFilterOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(30 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},
			electricalRecycleWaterSortBy: perDay,
			electricalRecycleWaterFilterOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(30 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},
			electrolyInletWaterSortBy: perDay,
			electrolyInletWaterFilterOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(30 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},

			recycleWaterProductionFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			}
		};
	},

	computed: {
		...mapState([
			"zldInfo",
			"zldVinyThaiWaterFlowRate",
			"silicaInReCycleWater",
			"electricalRecycleWater",
			"electricalInletWater",
			"recycleWaterProduction"
		]),

		...mapGetters("zldInfo", {
			getLayout: "getLayout"
		}),

		...mapGetters("zldVinyThaiWaterFlowRate", {
			getRawWaterData: "getRawWaterData",
			getRecycleWaterData: "getRecycleWaterData",
			getDateRange: "getDateRange"
		}),

		...mapGetters("silicaInReCycleWater", {
			getSilicaData: "getSilicaData"
		}),

		...mapGetters("electricalRecycleWater", {
			getElectricalRecycleWater: "getElectricalRecycleWater"
		}),
		...mapGetters("electricalInletWater", {
			getElectricalInletWater: "getElectricalInletWater"
		}),
		// "rawWaterRecycleWaterFlowRate": false,
		// "silicaRecycleWater": true,
		// "electricalConductivityRecycleWater": false,
		// "electricalConductivityInletWater": true,
		// "recycleWaterProduction": false
		isShowShipTo() {
			return this.getLayout.shipToLocation;
		},

		isShowRawWaterRecycleWaterFlowRate() {
			return this.getLayout.rawWaterRecycleWaterFlowRate;
		},

		isShowSilicaRecycleWater() {
			return this.getLayout.silicaRecycleWater;
		},

		isShowElectricalConductivityRecycleWater() {
			return this.getLayout.electricalConductivityRecycleWater;
		},

		isShowElectricalConductivityInletWater() {
			return this.getLayout.electricalConductivityInletWater;
		},

		isShowRecycleWaterProduction() {
			return this.getLayout.recycleWaterProduction;
		},



		recycleWaterProductionDateSelected() {
			const dateRange = this.recycleWaterProductionFilterOptions.date.selected
				.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getRecycleWaterProduction() {
			const dateSort = this.recycleWaterProductionDateSelected.dateNumber;
			const dateReportFormat = [perWeek, perTwoWeek].includes(dateSort) ? "day" : "year";
			const options = { dateReportFormat };
			const xyAxis = transformDataBarChart(this.recycleWaterProduction, options);
			return transformRecycleWaterProduction(xyAxis);
		},



		waterFlowRateDateSelected() {
			const dateRange = this.zldVinyThaiWaterFlowRateDateFilterOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},
		silicaDateSelected() {
			const dateRange = this.silicaDateFilterOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		electricalRecycleWaterDateSelected() {
			const dateRange = this.electricalRecycleWaterFilterOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		electrolyInletWaterDateSeleted() {
			const dateRange = this.electrolyInletWaterFilterOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		getElectricalInletWaterLineChartConfig() {
			const { diff } = this.electrolyInletWaterDateSeleted;
			return transformElectricalInletWaterLineChartConfig(
				this.electrolyInletWaterSortBy,
				{
					data: this.getElectricalInletWater,
					diffDay: diff,
					option: {
						markLine: this.markLineECInletWater
					}
				}
			);
		},
		getElectricalRecycleWaterLineChartConfig() {
			const { diff } = this.electricalRecycleWaterDateSelected;
			return transformElectricalRecycleWaterLineChartConfig(
				this.electricalRecycleWaterSortBy,
				{
					data: this.getElectricalRecycleWater,
					diffDay: diff,
					option: {
						markLine: this.markLineECInRecycleWater
					}
				}

			);
		},

		getSilicaInRecycleWaterLineChartConfig() {
			const { diff } = this.silicaDateSelected;
			return transformSilicaInRecycleWaterLineChartConfig(
				this.silicaSortBy,
				{
					data: this.getSilicaData,
					diffDay: diff,
					option: {
						markLine: this.markLineSilica
					}
				}
			);
		},

		getRecycleWaterDataLineChartConfig() {
			const { diff } = this.waterFlowRateDateSelected;
			return transformZldVinyThaiWaterFlowRateConfig(
				this.zldVinyThaiWaterFlowRateSortBy,
				{
					data: [
						this.getRawWaterData,
						this.getRecycleWaterData
					],
					dateRange: this.getDateRange,
					diffDay: diff
				}
			);
		}
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("zldVinyThaiWaterFlowRate", {
			fetchZldVinyThaiWaterFlowrate: "fetchZldVinyThaiWaterFlowrate"
		}),
		...mapActions("electricalRecycleWater", {
			fetchElectricalRecycleWater: "fetchElectricalRecycleWater"
		}),

		...mapActions("silicaInReCycleWater", {
			fetchSilicaInRecycleWater: "fetchSilicaInRecycleWater"
		}),

		...mapActions("electricalInletWater", {
			fetchElectricalInletWater: "fetchElectricalInletWater"
		}),
		...mapActions("recycleWaterProduction", {
			fetchRecycleWaterProduction: "fetchRecycleWaterProduction"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),

		fetchData() {
			const { zldId } = this;
			return Promise.all([
				this.fetchZldVinyThaiWaterFlowrate({ id: zldId, query: { filter: this.zldVinyThaiWaterFlowRateSortBy } }),
				this.fetchSilicaInRecycleWater({ id: zldId, query: { filter: this.silicaSortBy } }),
				this.fetchElectricalRecycleWater({ id: zldId, query: { filter: this.electricalRecycleWaterSortBy } }),
				this.fetchElectricalInletWater({ id: zldId, query: { filter: this.electrolyInletWaterSortBy } }),
				this.fetchRecycleWaterProduction({ id: zldId })
			]);
		},

		handleRecycleWaterProductionDateSelected(date) {
			const { zldId } = this;

			this.recycleWaterProductionFilterOptions.date.selected.range = date;

			this.fetchRecycleWaterProduction({
				id: zldId,
				query: { filter: this.recycleWaterProductionDateSelected.dateNumber }
			});
		},

		handleWaterFlowRateSelectDateDropDown(filterBy = "1d") {
			this.zldVinyThaiWaterFlowRateSortBy = filterBy;
			this.fetchZldVinyThaiWaterFlowrate({
				id: this.zldId,
				query: {
					filter: filterBy
				}
			});
		},

		handleWaterFlowRateDateSelected(date) {
			const { zldId } = this;
			const { start, end } = date;
			this.zldVinyThaiWaterFlowRateSortBy = "";

			this.zldVinyThaiWaterFlowRateDateFilterOption.date.selected.range = {
				start,
				end
			};

			this.fetchZldVinyThaiWaterFlowrate({
				id: zldId,
				query: {
					startDate: dayjs(start)
						.startOf("day")
						.toISOString(),
					endDate: dayjs(end)
						.endOf("day")
						.toISOString()
				}
			});
		},

		handleSilicaDateSeleted(date) {
			const { zldId } = this;
			const { start, end } = date;
			this.silicaSortBy = "";

			this.silicaDateFilterOption.date.selected.range = {
				start,
				end
			};

			this.fetchSilicaInRecycleWater({
				id: zldId,
				query: {
					startDate: dayjs(start)
						.startOf("day")
						.toISOString(),
					endDate: dayjs(end)
						.endOf("day")
						.toISOString()
				}
			});
		},
		handleSilicaDateDropdown(filterBy = "1d") {
			this.silicaSortBy = filterBy;
			this.fetchSilicaInRecycleWater({
				id: this.zldId,
				query: {
					filter: filterBy
				}
			});
		},


		handleElectricalRecycleWaterDateSeleted(date) {
			const { zldId } = this;
			const { start, end } = date;
			this.electricalRecycleWaterSortBy = "";

			this.electricalRecycleWaterFilterOption.date.selected.range = {
				start,
				end
			};

			this.fetchElectricalRecycleWater({
				id: zldId,
				query: {
					startDate: dayjs(start)
						.startOf("day")
						.toISOString(),
					endDate: dayjs(end)
						.endOf("day")
						.toISOString()
				}
			});
		},

		handleElectricalRecycleWaterDateDropdown(filterBy = "1d") {
			this.electricalRecycleWaterSortBy = filterBy;
			this.fetchElectricalRecycleWater({
				id: this.zldId,
				query: {
					filter: filterBy
				}
			});
		},


		handleElectrolyInletWaterDateSeleted(date) {
			const { zldId } = this;
			const { start, end } = date;
			this.electrolyInletWaterSortBy = "";

			this.electrolyInletWaterFilterOption.date.selected.range = {
				start,
				end
			};

			this.fetchElectricalInletWater({
				id: zldId,
				query: {
					startDate: dayjs(start)
						.startOf("day")
						.toISOString(),
					endDate: dayjs(end)
						.endOf("day")
						.toISOString()
				}
			});
		},

		handleElectrolyInletWaterDateDropdown(filterBy = "1d") {
			this.electrolyInletWaterSortBy = filterBy;
			this.fetchElectricalInletWater({
				id: this.zldId,
				query: {
					filter: filterBy
				}
			});
		}

	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^="col"]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.zld-level-container {
	@include mobile {
		border-radius: 0;
		margin-left: rem(-16);
		margin-right: rem(-16);
		padding: rem(16) 0;
	}

	::v-deep {
		.detail-card-header {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: rem(16);

			@include mobile {
				align-items: flex-start;
				flex-direction: column;
				padding: 0 rem(16);

				& > * {
					margin-left: 0;
				}

				.button-group-container {
					width: 100%;

					.btn {
						justify-content: center;
						width: calc(100% / 3);
					}
				}
			}
		}
	}
}
</style>
