export const parseTypes = (data = []) => {
	return data.map((notiItem) => {
		return {
			type: notiItem.type,
			displayName: notiItem.displayName,
			availableModules: !notiItem.availableModules ? [] : notiItem.availableModules.map((moduleItem) => {
				return {
					module: moduleItem.module,
					displayName: moduleItem.displayName
				};
			})
		};
	});
};