/**
 * Enum for e-ordering time type.
 * @readonly
 * @enum {EnumEOrderingTimeType}
 */
export const TIME_TYPE = Object.freeze({
	MORNING: "MORNING",
	AFTERNOON: "AFTERNOON",
	NIGHTTIME: "NIGHTTIME",
	SPECIFIC_TIME: "SPECIFIC_TIME"
});

// * Note: Client does not serve field time type so config time for referent time type
/**
 * Enum for e-ordering time value.
 * @readonly
 * @enum {EnumEOrderingTimeValue}
 */
export const TIME_VALUE = Object.freeze({
	MORNING: "10:59",
	AFTERNOON: "15:59",
	NIGHTTIME: "21:59"
});


/**
 * Enum for e-ordering display time.
 * @readonly
 * @enum {EnumEOrderingDisplayTime}
 */
export const DISPLAY_TIME = Object.freeze({
	[TIME_VALUE.MORNING]: "Morning",
	[TIME_VALUE.AFTERNOON]: "Afternoon",
	[TIME_VALUE.NIGHTTIME]: "Nighttime"
});

/**
 * Enum for e-ordering type from time.
 * @readonly
 * @enum {EnumEOrderingTypeFromTime}
 */
export const TYPE_FROM_TIME = Object.freeze({
	[TIME_VALUE.MORNING]: TIME_TYPE.MORNING,
	[TIME_VALUE.AFTERNOON]: TIME_TYPE.AFTERNOON,
	[TIME_VALUE.NIGHTTIME]: TIME_TYPE.NIGHTTIME
});
