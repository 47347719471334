<template>
	<CardLineChart
		:chartData="chartOptions"
		:filterOptions="filterOptions"
		:isLoading="loading"
		:isEmpty="empty"
		:lastUpdate="updatedAt"
		:chartTitle="`Current`"
		@onSelectDatePicker="handleSelectDateRange"
		@onSelectDropDown="handleSelectDateDropDown"
	/>
</template>

<script>
import dayjs from "dayjs";
import Numeral from "numeral";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { convertFilterByOfPowerChart } from "../../selectors/helpers";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export default {
	props: {
		data: {
			type: Array,
			default: () => ([])
		},
		updatedAt: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			sortBy: perDay,
			filterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 1 month",
								value: perThirtyDays
							},
							{
								label: "Last 3 months",
								value: perNinetyDays
							}
						]
					}
				}
			}
		};
	},

	components: {
		CardLineChart
	},

	computed: {
		chartOptions() {
			return {
				legend: {
					show: true,
					selectedMode: true,
					bottom: 46,
					left: 68,
					data: [
						{
							name: "A",
							icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAkCAYAAAB2UT9CAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAYKADAAQAAAABAAAAJAAAAAAEQKHHAAADDElEQVRoBe1bPW8TQRB9ez6BRBREGgrASZ2/4IAERQpEmjRO/gSiQKIltEgUiJ9Ak7hJE6oggQT4L1AnDkGiCQKBBIq9zKy9lW35Lnt7b5E8UnzKeefrPXt3b3Zs8L/IwdESfmUPALsOi2UJ+waM/KlYnMrrqfx/DJhDLAzeYGPlzL2X+ItJPD6g09uEHTwUYO/A2rxQvMacCysfYLJXaDf3C+mQBqVLwN6XNdjz54JLKxCbLkz+BFs3PwXaiaKeHgEd28Dg5AUweFRtxtlLZLceo2361doNs5YWAftn1/D3Z0emmvWwtKZoG1kfLi22sbn0fcqI2m+nQ4CC/+dHVxBYjYzCZ1y+2kqFhCxyssXM67Sjn/z44Gs8q86X+kxA0iBA5/xY084kkNWXW2cmvVnvPf4UNNztfKw37ZE3k99m747434DhVpOC/2iby/E98solQB+ywvf5IQC23INeiIVAXS4B7gk3MINQdXIMvDXA1XbMt8LlhVCgp+lr2WLBXmfVjnLs9Z5Oi+3C97eaz2bq/m5swPaL1XZmGgsYoPUljQV4PdNKBKxyKXTtzHRcfsBsAgb9OE+75WOVqoeLZTYBEbAirgGmeRGs4ujwYiESMKrlx0G0rNXhuUJZrQrG8wjwhykVJBFsghgLjwDZ/gQDV5kBXiy5nBrtVJZHGUPWfJVTq8UyKtHGulgKWI+AFe85YPf4nRBwt0DaNQwx77G9fK8GR2MueFOQO0Afi4dzgxgLkYDsLQftSV7lpIwkPAKu9A/guhdImXu3rhQhbSwk4RHg+nakdYQuEgOxh4hHgAKvfTtsIcfA2wV54HePtF8ntPfHWyt77WJ7Za2sUpXjud8AzUSbpljC9D3KmU+A61iTpqnaRXwm0C3HJ0CB1441bZqqS9SX+kxA+GuAB2HemOWRIF21XVA71mJ+E9R2Ql1xinQaU5DnXEkwzfsSVoQ1QWyq7YT6QjXtdKYgT4K/ztvTPRLk6/wHGmQCvHttY9HuBXeA7s5wx3+iBNtD1jiE1pmI5QUfcpHrP2iW2HnVI8J9AAAAAElFTkSuQmCC"
						},
						{
							name: "B",
							icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAkCAYAAAB2UT9CAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAYKADAAQAAAABAAAAJAAAAAAEQKHHAAAC2ElEQVRoBe1bv08UQRj95txAhMKzoSDR3n8BtPMKgwUU0JiYkCidoTCxFVsTC2MHJlY2WEgB1dKB/Av2mGBi4zVoJCfr+3aYiiPOZW72fYT7kmXD3s73473bmdmZd04ui32cuym/TuZEqg5Svo1jWiocak6O8FePQ/xTysTYjjza+akfWTdnPUFZ7ywA9GfI8x6OIjLfHu7bAxnvZKX8HNmGcptdAt53ZuVUXgP8mTRk3IG05IU8Kb+k+cnT2h4Bm4vXpNt9A+BXh1uyeyvt9nNZ+vR3uH7TvNki4MN8W3rHm1LV/XxaZf1aO4wPxeSSLG91+33MuGaHAA/+AcC/kxUI576ChBkrJLSyFhvrXLsd/83PC77mowRrLI1pwGwQoH1+rm6nH8gaqx5n+n3Y7DV+F1TPdqr9Zss+i9Zyd9mzI/4TUE81KfCLn+aSYofvADW8f8lKnOenVIB3jDqHFB9pbclPQP2Gm1ZBcmtuDrwxQNd2jv/8AH6xywvJUF/goCeT41OstSPeE/D75KEB8JWTQnwuF/CT9zKPgFPRVU0bRsyFR4CrbtlAH1kQcylk4/7LoQPxdPdVhE+/lh9xYwO3xOWSAasCmxprGQr8PwFhMyVD8IFdxuaSAStiFwTqrZjj5cIjQOS7FfyRBy0XJgG6h2vFaLkwCTi0gj7yoOVSQFGwRgHCuV0sQT+mxD4XFDtlMZYBq9FShMgVXYrwuh1IR+i2x1oH0sqZYwDCQ7dDN24OvC4oAL/egV4nVfsTnA16hmZopZwdtNUw7yc/AShFRVMsY8Y+q5lPQK1Yg2iqcUNMA2o5PgEKvCrWVDTVlGksjWnA+GNAAGEkzApIkM4qF1TFWs4nwUsTzajiFGkbXVDgXEm40X6A6WmGMQE+1bchXaiWbacLCiSE80ieHpAgn0c/0CATEMKrjEXVC7qB7vdwz/9EqXLf0KmWcn1sm7m8EFKOOf8DDXzMyuvZRlwAAAAASUVORK5CYII="
						},
						{
							name: "C",
							icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAkCAYAAAB2UT9CAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAYKADAAQAAAABAAAAJAAAAAAEQKHHAAADCElEQVRoBe1bMYvUQBT+Zm/FRkEbC0F7G0FWm3UtLUQbmyv9B4eCcK3aChbiv7CxUSzOTt1GD8HmeoU7sFHQRtxj/N4kC8uRY7NJJt8T70EuySXz5r3vS15m3rwN+EckfsZp/MFNRFynyee5P4vAzSRil8e7PPrC/RaO4VW4iO/pmvM/wbl9iNu4TYA3uF2jrcOa9s5IxFtuz8IIL2q2kdzmloD4AVeJyGNu45bITNl+M1zB+5Z6sjR3R0CMWMM2nvCJv9upxwFPMcL9ELDfqd6WylwRED/hFOF5TvAtzncv9n1Yw3q4hB/dK2+m0Q0BJfhTgn+hmSs1WwXskISxFxIGNc3OelsKO8WTnxd888IIZl+pz6xe1VPugoAy5ucJO1U4WIiz74wDkYegcrTzToTFRD068vAG2FBTJcq+k89SAtIkq/04vw1549KGNjpatZUSwA/iRivru2gstkH2DUi5nd/4Rgzrphe6gLtKxwzHcUaVO9K9ATPccgC+ETJEYUsVOdn/pyNgP9NstwlkQlt0BASca4JVljZCW4YcBTzo2immgB8t1Wn5fC9S05YsWHEiFLvGgZObpR/3+BE/2fOJrvtupC/gV7iMk8va5sBKF4Ji98QvA/DQ60JbdAQAe4cC0v8FmS06Aoo13P6hrupRaIuOAFtA9yMyW4YY4KEEhwHeMC9/R9L3wU5tpayOZMBq6Wiljl1N7jlKRRSoyUJQyr1Y6YhaaIMqD2SuywhIuLNuR42/1Q4pbZCFoLnTnNxYvU7b2p+5ulX3U04arf5IJto3oHB7U+Y9C7aEfaeu5QSkNVkrmupb2Kd6PdhclocgM6KshnvN5EQ/lRE27BzhhocqOfkbkJ4CKxdkxRofhx07zypFYda6B/DNTxcEmCGpUo0VaySh3qTIGq0qRWmim6o4M99FCFrE8X8rznXzBsxJsNDA3Pw9nk+4WWl5WzEdE9PpJewsOuTuDVg0zo5T3c7RDzQOwtL/ecodWfWCLaDbGm7VT5QivvJjvsU6h5fK9MIq6PwFYo+4Wy+5vfAAAAAASUVORK5CYII="
						}
					],
					itemHeight: 10,
					itemGap: 20
				},
				tooltip: {
					show: true,
					trigger: "axis",
					backgroundColor: "#103332",
					axisPointer: {
						crossStyle: {
							color: "red"
						}
					},
					padding: 8,
					formatter: (values) => {
						return this.showTooltip(values);
					}
				},
				color: ["#ea7125"],
				grid: {
					left: "48px",
					right: "74px",
					bottom: 82,
					containLabel: true
				},
				xAxis: [
					{
						type: "category",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.currents.reduce((_acc, _cur) => {
									_acc.push(_cur.timestamp);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})(),
						boundaryGap: false,
						axisPointer: {
							show: true,
							triggerTooltip: false,
							label: {
								show: false
							}
						},
						axisLabel: {
							margin: 8,
							fontSize: 14,
							show: true,
							formatter: (value) => {
								const startOfDate = dayjs(value).startOf("day").unix();
								const currentDate = dayjs(value).unix();
								return startOfDate === currentDate
									? dayjs(value).format("HH:mm\nD MMM")
									: dayjs(value).format("HH:mm");
							}
						},
						axisTick: {
							length: 4,
							lineStyle: {
								width: 1,
								color: "#586c63"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						}
					}
				],
				yAxis: [
					{
						name: "Current (A)",
						type: "value",
						color: "#59e07a",
						nameTextStyle: {
							color: "#717171",
							width: "100px",
							fontFamily: "TFSrivichai",
							fontWeight: "bold",
							fontSize: 20,
							padding: [0, 0, 24, -24],
							align: "left"
						},
						axisLabel: {
							margin: 8,
							fontSize: 14,
							formatter: (value) => Numeral(value).format("0[.]0a")
						},
						splitNumber: 4,
						splitLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						},
						axisTick: {
							length: 4,
							lineStyle: {
								width: 1,
								color: "#586c63"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						}
					}
				],
				axisLabel: {
					fontSize: 14,
					fontFamily: "TFSrivichai",
					color: "#103332"
				},
				dataZoom: [
					{
						type: "inside"
					},
					{
						show: true,
						realtime: true,
						start: 0,
						end: 25,
						type: "slider",
						textStyle: {
							fontFamily: "TFSrivichai",
							fontSize: 14,
							width: 50,
							height: 50
						},
						handleIcon: "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
						handleSize: "80%",
						dataBackground: {
							areaStyle: {
								color: "#8392A5"
							},
							lineStyle: {
								opacity: 0.8,
								color: "#8392A5"
							}
						},
						handleStyle: {
							color: "#fff",
							shadowBlur: 3,
							shadowColor: "rgba(0, 0, 0, 0.6)",
							shadowOffsetX: 2,
							shadowOffsetY: 2
						},
						labelFormatter: (value, date) => {
							return dayjs(date).format("HH.mm\nD MMM YYYY");
						}
					}
				],
				series: [
					{
						name: "A",
						type: "line",
						smooth: true,
						itemStyle: {
							color: "#00a8e1"
						},
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.currents.reduce((_acc, _cur) => {
									_acc.push(_cur.phaseA);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})()
					},
					{
						name: "B",
						type: "line",
						smooth: true,
						itemStyle: {
							color: "#009a44"
						},
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.currents.reduce((_acc, _cur) => {
									_acc.push(_cur.phaseB);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})()
					},
					{
						name: "C",
						type: "line",
						smooth: true,
						itemStyle: {
							color: "#ffcb00"
						},
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.currents.reduce((_acc, _cur) => {
									_acc.push(_cur.phaseC);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})()
					}
				]
			};
		}
	},

	methods: {
		handleSelectDateRange({ start, end }) {
			const params = {
				startDate: dayjs(start).startOf("day").toISOString(),
				endDate: dayjs(end).endOf("day").toISOString()
			};
			this.filterOptions.date.selected.range = { start, end };
			this.sortBy = "";

			this.$emit("filter", params);
		},
		handleSelectDateDropDown(filterBy = "1d") {
			const filter = convertFilterByOfPowerChart(filterBy);
			const params = { filter };
			this.sortBy = filterBy;
			this.$emit("filter", params);
		},
		showTooltip(values) {
			let result = "";

			values.forEach((value) => {
				result += `<div class="bar-value">
										${value.seriesName}: ${value.data || "-"} A
									</div>`;
			});

			return `
							<div>
								<div class="bar-name">
									${dayjs(values[0].axisValue).format("HH.mm, D MMM YYYY")}
								</div>
								${result}
							</div>
						`;
		}
	}
};
</script>