<template>
	<modal :name="modalName" width="496" height="auto">
		<BaseModalContent>
			<template v-slot:header>
				{{ showTtitleStatus }} maintenance mode
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="label-description">
					<label>
						Are you sure to {{ showStatus }} maintenance mode?
					</label>
					<label>
						{{ showDescription }}
					</label>
				</div>
			</template>
			<template v-slot:footer>
				<button
					class="view-button"
					@click="onCancel"
				>
					Cancel
				</button>
				<BaseButton
					:type="showButtonType"
					size="medium"
					@click="onConfirm"
					rounded
				>
					{{ showConfirm }}
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>
<script>
import BaseModalContent from "@/components/BaseModalContent.vue";
import { MAINTENANCE_STATUS } from "../../enums/maintenanceStatus";

export default {
	name: "ModalEnableMaintenanceMode",

	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},

		status: {
			type: Boolean,
			required: true,
			default: false
		}
	},

	computed: {
		showTtitleStatus() {
			return this.status ? MAINTENANCE_STATUS.ENABLED_STATUS : MAINTENANCE_STATUS.DISABLE_STATUS;
		},

		showStatus() {
			const status = this.status ? MAINTENANCE_STATUS.ENABLED_STATUS : MAINTENANCE_STATUS.DISABLE_STATUS;
			return status.toLowerCase();
		},

		showDescription() {
			return this.status ?
				"Maintenance mode will block user from viewing BIG portal website." :
				"All users can access to BIG Portal website.";
		},

		showButtonType() {
			return this.status ? "danger" : "secondary";
		},

		showConfirm() {
			return this.status ? "Yes, enable" : "Confirm disble";
		}
	},

	methods: {
		onCancel() {
			this.$emit("onCancel");
		},

		onConfirm() {
			this.$emit("onSubmit");
		}
	}
};
</script>
<style lang="scss" scoped>
.label-description {
	display: flex;
	flex-direction: column;

	font-size: $font-20;
	letter-spacing: rem(0.5);
	color: $color-dark-blue-grey;
}

.view-button {
	font-size: $font-20;
	margin-right: rem(44);
}
</style>