const systemRoleOptions = Object.freeze([
	{
		label: "Power User", value: "POWER_USER"
	},
	{
		label: "Procurement", value: "PROCUREMENT"
	},
	{
		label: "User", value: "USER"
	},
	{
		label: "Level 2 User", value: "LEVEL_2_USER"
	},
	{
		label: "Contact", value: "CONTACT"
	},
	{
		label: "Order", value: "ORDER"
	}
]);

export default systemRoleOptions;