<template>
	<div class="bar-g">
		<span
			v-for="({value, color}, index) in percentages"
			:key="index"
			:class="[color, size]"
			:style="{width: value}"
		></span>
	</div>
</template>

<script>
export default {
	name: "PercentageIcon",

	props: {
		size: {
			type: String,
			default: "medium",
			validator: (value) => ["small", "medium", "large"].includes(value)
		},
		percentages: {
			type: Array,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
$border-radius: rem(100);

.bar-g {
	display: flex;
	width: 100%;
	background-color: $color-silver;
	border-radius: rem(100);
	overflow: hidden;

	span {
		&:first-child {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}

		&:last-child {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}

	.small {
		height: rem(5);
	}

	.medium {
		height: rem(16);
	}

	.larger {
		height: rem(24);
	}

	.base-1,
	.emerald {
		background: $color-emerald;
	}

	.base-2 {
		background: $color-cool-green;
	}

	.base-3 {
		background: $color-lightish-green;
	}

	.premium-1 {
		background: $color-dull-orange;
	}

	.premium-2,
	.pastel-orage {
		background: $color-pastel-orange;
	}

	.premium-3 {
		background: $color-pale-peach;
	}
}
</style>