import dayjs from "dayjs";
import { STATUS_LABEL } from "../../../enums/eOrdering/status";
import { transformShipTo } from "./transformShipTo";
import { transformDevices } from "./transformDevices";
import { transformQuantityRestriction } from "./transformQuantityRestriction";

export const transformLists = (items) => {
	const shipTo = transformShipTo(items?.device?.shipTo).name ?? "-";
	const deliveryDate = dayjs(items?.deliveryDate).format("D MMM YYYY") ?? "-";
	const createdDate = dayjs(items?.createDate).format("D/MM/YY, HH:mm") ?? "-";
	const product = transformDevices(items?.device)?.name ?? "-";
	const	device = transformDevices(items?.device) ?? null;

	return {
		actualAmount: items?.actualAmount ?? "N/A",
		createdDate,
		deliveryDate,
		device,
		isExplain: false,
		isUrgent: items?.isUrgent ?? false,
		amount: items?.orderAmount ?? "-",
		orderNo: items?.orderNo ?? "-",
		poNo: items?.orderPONo ?? "-",
		remark: items?.orderRemark ?? "-",
		status: STATUS_LABEL[items?.orderStatus] ?? "-",
		isPdf: items?.isPdf ?? false,
		doPdf: items?.doPdf ?? false,
		productName: items?.productName ?? "-",
		shipId: items?.shipId ?? "-",
		shipTo,
		srNo: items?.srNo ?? "N/A",
		product,
		doNo: items?.doNo ?? "N/A",
		trailer: items?.trailerId ?? "N/A",
		quantityRestriction: transformQuantityRestriction(items?.quantityRestriction) ?? null
	};
};

export const transformHistories = (items) => {
	return items?.map(transformLists) ?? {};
};
