import dayjs from "dayjs";
import Numeral from "numeral";
import { getFriendlyNumber } from "./selectors/helpers";

export const dateTimeFormat = (timestamp) => dayjs(timestamp).format("D MMM YYYY, HH:mm");
export const dateFormat = (timestamp) => dayjs(timestamp).format("DD MMM YYYY");
export const monthFormat = (timestamp) => dayjs(timestamp).format("MMM YYYY");

export const capitalize = (value) => {
	if (!value) return "";
	return value.toString().split(" ").reduce((acc, cur) => {
		if (cur.toString()) {
			acc.push(cur.charAt(0).toUpperCase() + cur.slice(1));
		}
		return acc;
	}, []).join(" ");
};

export const allowDashString = (value) => {
	if (value && value !== "0") {
		return value;
	}
	return "-";
};

export const currency = (value) => {
	return Numeral(value).format("0,0");
};

export const fullCurrency = (value) => {
	return Numeral(value).format("0,0.000");
};

export const twoDecimalComma = (value) => {
	return Numeral(value).format("0,0.00");
};

export const percentageRoundUpDown = (value) => {
	return Numeral(value / 100).format("0.0%");
};

export const friendlyNumber = (value) => {
	return getFriendlyNumber(value);
};

export const friendlyNumberWithIntegerType = (value) => {
	return getFriendlyNumber(value, { showIntegerType: true });
};

export const nagativeCurrencyWithNoTApplicable = (value) => {
	if (value < 0) {
		return "N/A";
	}
	return currency(value);
};