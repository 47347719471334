<template>
	<modal
		:name="modalName"
		:scrollable="true"
		width="540"
		height="auto"
		class="custom-modal"
	>
		<BaseModalContent hide-header hide-footer rounded>
			<template v-slot:body>
				<span class="border-top"></span>
				<div class="container-body">
					<div class="icon">
						<FontAwesomeIcon :icon="[...faIcon]" />
					</div>
					<div class="title">
						{{ title }}
					</div>
					<div class="message">
						{{ message }}
					</div>
					<div class="action">
						<BaseButton
							type="danger"
							size="medium"
							rounded
							class="custom-button"
							@click="onClose"
						>
							{{ closeButtonText }}
						</BaseButton>
					</div>
				</div>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: null
		},
		faIcon: {
			type: Array,
			required: () => ([])
		},
		title: {
			type: String,
			required: null
		},
		message: {
			type: String,
			required: null
		},
		closeButtonText: {
			type: String,
			required: null
		}
	},

	methods: {
		onClose() {
			this.$emit("onClose");
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-modal {
	/* stylelint-disable */
	&::v-deep .body {
		padding-top: rem(16);
		font-size: $font-20;
		color: $color-dark-blue-grey;
	}
	/* stylelint-enable */
}

.border-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: rem(8);
	background: $color-red;
}

.container-body {
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon {
		margin-top: rem(23);

		svg {
			width: rem(40);
			height: rem(40);
			color: $color-red;
		}
	}

	.title {
		font-family: $font-family-sub;
		font-size: $font-24;
		font-weight: $font-weight-bold;
		color: $color-dark-blue-grey;
	}

	.message {
		font-family: $font-family-base;
		font-size: $font-20;
		color: $color-dark-blue-grey;
	}

	.action {
		display: flex;
		width: rem(178);
		margin-top: rem(32);

		.custom-button {
			width: 100%;
		}
	}
}
</style>