import store from "../../../store/index";
import { ERROR_ADD } from "../../types";
import { TOAST_TYPES } from "../../../enums/toast";

export default {
	namespaced: true,

	state: {
		stacks: []
	},

	getters: {
		latestError: ({ stacks }) => {
			if (stacks.length > 0) {
				return stacks[0];
			}
			return null;
		}
	},

	mutations: {
		[ERROR_ADD](state, error) {
			state.stacks.unshift(error);
		}
	},

	actions: {
		add({ commit, getters }, error) {
			// for user have loggedin only.
			// push error to stack at the first of an array
			if (store.state.user.isLoggedIn) {
				commit(ERROR_ADD, error);
			}

			// show toash when we have error in stack
			// than pick first one to show the error
			if (getters.latestError !== null) {
				const { title, message, ...props } = getters.latestError;
				store.dispatch("toast/show", {
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title, message, ...props }
				});
			}
		}
	}
};
