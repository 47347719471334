<template>
	<BaseLayout>
		<BaseBackBtn :to="PAGE_NAME.CUSTOMERS" class="page-back-btn"  label="Back to customer account"/>
		<div class="wrapper-customer-edit">
			<div class="title-customer-edit">
				<div class="edit-controller">
					<h4 class="customer-edit-title">
						{{ isEditable ? "Edit BIG customer detail" : "View BIG customer detail" }}
					</h4>
					<BaseButton
						v-if="!isEditable"
						:disabled="isEditable"
						size="medium"
						type="primary"
						@click="handleClickEdit"
					>
						Edit details
					</BaseButton>
				</div>
				<p class="customer-create-at">
					Account created at
					<span class="create-at-time">{{ getCreateAt }}</span>
				</p>
			</div>
			<BaseCard class="card-customer-info">
				<template v-slot:header>
					<h4>Customer information</h4>
				</template>
				<template v-slot:body>
					<BaseFormInput
						v-model="$v.name.$model"
						:inputOptions="['small']"
						:is-error="isNameError"
						:error-message="getNameErrorMessage"
						:disabled="!isEditable"
						class="customer-create-input"
						label="Name *"
						placeholder="e.g. John Doe"
						inline
					/>

					<BaseFormInput
						v-model="$v.email.$model"
						:inputOptions="['small']"
						:is-error="isEmailError"
						:error-message="getEmailErrorMessage"
						:disabled="!isEditable"
						class="customer-create-input"
						label="Email *"
						placeholder="e.g. sample@email.com"
						inline
						@input="customersManageClearErrorsField"
					/>

					<BaseFormInput
						v-model="$v.phone.$model"
						:inputOptions="['small']"
						:is-error="isPhoneError"
						:error-message="getPhoneErrorMessage"
						:disabled="!isEditable"
						class="customer-create-input"
						label="Phone"
						placeholder="e.g. 02 345 6789"
						inline
					/>

					<BaseFormInputAutoComplete
						v-model="$v.company.$model"
						:list="getCompaniesOption"
						:inputOptions="['small']"
						:is-loading="isLoadingCompany"
						:value="company"
						:is-error="isCompanyError"
						:error-message="getCompanyErrorMessage"
						:disabled="!isEditable"
						class="customer-create-input"
						label="Company Name *"
						placeholder="Select Company"
						inline
						@changed="debounceFetchCompany"
						@blur="handleFetchPermissionLists"
						@selected="handleSelectCompany"
					/>

					<div class="container-form">
						<div class="head-label system-role">
							<strong>System role and permission</strong>
						</div>
					</div>

					<BaseDropdown
						v-model="$v.systemRole.$model"
						:searchable="false"
						:list="systemRoleOptions"
						:value="systemRole"
						:is-error="isSystemRoleError"
						:error-message="getSystemRoleErrorMessage"
						:disabled="!isEditable"
						class="customer-create-input"
						label="System role *"
						placeholder="Select customer's system role"
						inline
						@input="handleSelectSystemRole"
					/>

					<Permission
						v-if="!isLoading"
						v-model="permissions"
						:role="systemRole"
						:permissionMasterData="permissionMasterData"
						:disabled="!systemRole || !isEditable"
						:autoSelectedByRole="false"
					/>

					<hr class="line"/>

					<div class="item-checkbox-test-account">
						<div class="form">
							<BaseCheckBox
								:checked="isTestAccount"
								:disabled="!isEditable"
								label="Test account"
								helperText="*Test account will not display on system monitoring"
								@handleCheck="handleIsTestAccountChange"
							/>
						</div>
					</div>
				</template>
			</BaseCard>
			<BaseCard
				v-if="systemRole !== 'CONTACT'"
			>
				<template v-slot:header>
					<h4>Permission</h4>
				</template>
				<template v-slot:body>
					<div v-if="permissionLoading" class="permission-loading-wrapper">
						<BaseLoading />
					</div>
					<div v-else>
						<BaseBadgeInfomation
							v-if="isEditable"
							class="badge-info-permission"
							title="Visibility Permission"
						>
							<template #content-body>
								<ul class="permission-list">
									<li class="permission-list-item">Your first selection will be set as user visibility level. To reset level, please <strong>clear all permission setting</strong></li>
									<li class="permission-list-item">Every selection will effect to item list in other levels.</li>
									<li class="permission-list-item">Permission can be duplicated from staff email. <span v-if="showDuplicateButton" class="view-text clickable" @click="onClickOpenModal">Duplicate permission</span></li>
								</ul>
							</template>
						</BaseBadgeInfomation>
						<BaseLoading
							v-if="isLoadingCustomerPermissions"
						/>
						<template
							v-else
						>
							<div class="form-permission-header">
								<h3 class="permission-level">
									Level
								</h3>
								<div class="permission-visibility">
									<h3 class="visibility-label">
										Visibility
									</h3>
									<BaseButton
										v-if="isEditable"
										size="small"
										type="secondary"
										link
										@click="handleClearPermission"
									>
										Clear all permission setting
									</BaseButton>
								</div>
							</div>
							<BaseMultipleSelect
								:options="getSoldToOptions"
								:value="getSoldToValues"
								:customLabel="getLabelSoldTo"
								:disabled="!isEditable || isDisabledSoldTo"
								:is-error="isSoldToError"
								:error-message="getSoldToErrorMessage"
								label="Sold-to *"
								placeholder="Search and select sold-to"
								multiple
								@onSelectValue="handleSelectSoldTo"
								@onRemoveSelected="handleRemoveSoldTo"
							></BaseMultipleSelect>
							<BaseMultipleSelect
								:options="getShipToOptions"
								:value="getShipToValues"
								:customLabel="getLabelShipTo"
								:disabled="!isEditable || isDisabledShipTo"
								:is-error="isShipToError"
								:error-message="getShipToErrorMessage"
								label="Ship-to *"
								placeholder="Search and select ship-to"
								multiple
								defaultHeight
								@onSelectValue="handleSelectShipTo"
								@onRemoveSelected="handleRemoveShipTo"
							></BaseMultipleSelect>
							<BaseCheckBox
								:disabled="!isEditable || isDisabledShipTo"
								:checked="isAutoUpdateShipTo"
								class="form-checkbox"
								label="Auto update ship-to that will be added in the future"
								@handleCheck="handleCheckUpdateShipTo"
							/>
							<BaseMultipleSelect
								:options="getApplicationOptions"
								:value="getApplicationValues"
								:disabled="!isEditable || isDisabledApplication"
								:is-error="isApplicationError"
								:error-message="getApplicationErrorMessage"
								label="Application *"
								placeholder="Search and select application"
								multiple
								@onSelectValue="handleSelectApplication"
								@onRemoveSelected="handleRemoveApplication"
							></BaseMultipleSelect>
							<BaseMultipleSelect
								:options="getDeviceOptions"
								:value="getDeviceValue"
								:disabled="!isEditable || isDisableDevice"
								:is-error="isDeviceError"
								:error-message="getDeviceErrorMessage"
								label="Device *"
								placeholder="Search and select device"
								multiple
								defaultHeight
								@onSelectValue="handleSelectDevice"
								@onRemoveSelected="handelRemoveDevice"
							></BaseMultipleSelect>
							<BaseCheckBox
								:disabled="!isEditable || isDisableDevice"
								:checked="isAutoUpdateDevice"
								class="form-checkbox"
								label="Auto update device that will be added in the future"
								@handleCheck="handleCheckUpdateDevice"
							/>
						</template>
					</div>
				</template>
			</BaseCard>
			<BaseCard>
				<template v-slot:header>
					<h4>Password</h4>
				</template>
				<template v-if="!isChangePassword || !isEditable" v-slot:body>
					<div class="wrapper-password-detail">
						<div class="password-defailt-container">
							<p class="password">••••••••</p>
							<p class="last-update-password-label">Last changed {{ getLastUpdatePassword }}</p>
						</div>
						<BaseButton
							v-if="isEditable"
							size="medium"
							type="primary"
							@click="handleEnablePasswordChange"
						>
							Change Password
						</BaseButton>
					</div>
				</template>
				<template v-else v-slot:body>
					<div class="wrapper-password-generate">
						<BaseBadgeInfomation
							title="The password will be crypted"
						>
							<template #content-body>
								<div>
									<p class="info-password-description">After you click ‘Apply change’ button, you won’t be able to see, copy, or access to the password. Please make sure that you make a copy and inform the account owner about the new password.</p>
								</div>
							</template>
						</BaseBadgeInfomation>
						<p class="generate-password-description">Generate or type in a new password for this account. Please note that the account owner can no longer access BIG portal with the old password.</p>
						<div class="wrapper-generate-password">
							<div class="container-generate-password">
								<BaseFormInput
									v-model="$v.password.$model"
									:inputOptions="['small']"
									:is-error="isPasswordError"
									:error-message="getPasswordErrorMessage"
									ref="password"
									class="customer-create-input"
									placeholder="Generate or type in a new password"
									merge="right"
									inline
								/>
								<BaseButton
									class="generate-password-btn"
									size="medium"
									type="secondary"
									outline
									merge="left"
									@click="handleGeneratePassword"
								>
									<FontAwesomeIcon class="redo-password-icon" :icon="['far', 'redo-alt']"/>
									Generate
								</BaseButton>
							</div>
							<BaseButton
								class="copy-password-btn"
								size="small"
								type="secondary"
								link
								@click="handleCopyPassword"
							>
								<FontAwesomeIcon class="copy-password-icon" :icon="['far', 'copy']"/>
								Copy password
							</BaseButton>
						</div>
						<div>
							<BaseInfoList
								:list="infoList"
								class="password-validate-infomation"
							/>
						</div>
						<div class="wrapper-submit-password">
							<BaseButton
								class="generate-password-btn"
								size="medium"
								type="secondary"
								@click="handleSubmitChangePassword(handleUpdatePassword)"
							>
								Apply change
							</BaseButton>
						</div>
					</div>
				</template>
			</BaseCard>
			<BaseCard class="card-active-status" >
				<template v-slot:header>
					<h4>Activate / Deactivate this account</h4>
				</template>
				<template v-slot:body>
					<div class="wrapper-customer-status">
						<div class="status-form-group">
							<p class="status-description">Activate or temporary deactivate this user account.</p>
							<div class="form-status">
								<BaseSwitch
									class="switch-status"
									:checked="statusActive"
									:disabled="!isEditable"
									@input="handleChangeStatus"
								/>
								<label class="status-label">{{ getStatusLabel }}</label>
							</div>
						</div>
						<p class="deactivate-description">Deactivate this user will only disable this user account to login the system.</p>
						<h4>Remark:</h4>
						<ol class="remark">
							<li>If the user does not log in within 90 days, the system will automatically deactivate the account.</li>
							<li>Incorrect password entries five times in a minute will lock the account for three minutes, after which it will auto-unlock for further attempts.</li>
						</ol>
					</div>
				</template>
			</BaseCard>
			<div class="wrapper-confirm-create-customer" v-if="isEditable">
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					@click="handleCancelForm"
					outline
				>
					Cancel
				</BaseButton>

				<BaseButton
					size="large"
					type="primary"
					@click="handleSubmitMainForm(handleSendUpdateCustomerMainform)"
				>
					Apply Change
				</BaseButton>
			</div>
		</div>
		<ModalDuplicatePermission
			:modal-name="MODAL_NAME"
			:emails="customerEmails"
			:loading="isLoadingEmailList"
			:onReset.sync="onResetFormModalDup"
			@onSubmit="handleSubmitModal"
		/>
	</BaseLayout>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import BaseInfoList from "@/components/BaseInfoList.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";
import { validationMixin } from "vuelidate";
import BaseBadgeInfomation from "@/components/BaseBadgeInfomation.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import ModalDuplicatePermission from "@/components/Staff/ModalDuplicatePermission.vue";
import Permission from "@/components/customer/Permission.vue";
import updateCustomerValidateMixin from "../../selectors/formValidate/userManagement/updateCustomerValidateMixin";
import systemRoleOptions from "../../enums/customerManagement/systemRoleOptions";
import packageOptions from "../../enums/customerManagement/packageOptions";
import { PAGE_NAME } from "../../enums/pagePermission";
import { generatePassword } from "../../selectors/helpers";
import { TOAST_TYPES } from "../../enums/toast";
import devicePermissionHandle from "../../selectors/mixins/userManagement/devicePermissionHandle";
import { getHasUpperCase, getHasLowwerCase, getHasNumber } from "../../selectors/helpers/regex";

export default {
	name: "CustomerCreate",
	components: {
		BaseCard,
		BaseBadgeInfomation,
		BaseButton,
		BaseBackBtn,
		BaseSwitch,
		BaseCheckBox,
		BaseMultipleSelect,
		BaseLoading,
		BaseInfoList,
		ModalDuplicatePermission,
		Permission
	},
	mixins: [
		validationMixin,
		updateCustomerValidateMixin,
		devicePermissionHandle
	],
	data() {
		return {
			PAGE_NAME,
			systemRoleOptions,
			packageOptions,
			MODAL_NAME: "modal",
			name: "",
			email: "",
			phone: "",
			company: "",
			systemRole: "",
			packageName: "",
			statusActive: false,
			password: "",
			isTestAccount: false,
			isLoadingCompany: false,
			isEditable: false,
			isChangePassword: false,
			isSelectCompany: false,
			permissionLoading: false,
			infoList: [
				{
					text: "8 characters minimum",
					active: false
				},
				{
					text: "1 number (0-9)",
					active: false
				},
				{
					text: "1 lowercase letter (a-z)",
					active: false
				},
				{
					text: "1 uppercase letter (A-Z)",
					active: false
				}
			],
			isLoadingEmailList: false,
			isLoadingCustomerPermissions: false,
			onResetFormModalDup: null,
			permissions: [],
			isLoading: true
		};
	},
	async created() {
		this.isLoading = true;
		this.permissionLoading = true;
		const { id } = this.$route.params;
		await Promise.all([
			this.fetchCustomerById(id),
			this.getCustomerRolePermissions()
		]);
		const { name = "", packageStatus = "", phoneNumber, isActive, isTestAccount, email, companyName, role = {}, rolePermissions = [] } = this.customerManagement.customerProfile;
		this.name = name;
		this.packageName = packageStatus;
		this.phone = phoneNumber;
		this.statusActive = isActive;
		this.isTestAccount = isTestAccount;
		this.email = email;
		this.company = companyName;
		this.isSelectCompany = false;
		await this.fetchPermissionList({});
		this.systemRole = role.code;
		this.permissions = rolePermissions;
		this.setInitialPermission();
		this.isAutoUpdateShipTo = this.getIsUpdateShipTo;
		this.isAutoUpdateDevice = this.getIsUpdateDeivce;
		this.permissionLoading = false;

		this.isLoadingEmailList = true;
		await this.fetchCustomerLists({ perPage: 10000 });
		this.isLoadingEmailList = false;
		this.isLoading = false;
	},
	methods: {
		...mapActions("customerManagement", {
			fetchCompaniesLists: "fetchCompaniesLists",
			sendCreateCustomer: "sendCreateCustomer",
			customersManageClearErrorsField: "customersManageClearErrorsField",
			fetchCustomerById: "fetchCustomerById",
			updateCustomerProfile: "updateCustomerProfile",
			updateCustomerPassword: "updateCustomerPassword",
			clearCompanyLists: "clearCompanyLists"
		}),
		...mapActions("customerRolePermission", {
			getCustomerRolePermissions: "getCustomerRolePermissions"
		}),
		...mapActions({
			fetchCustomerLists: "customer/fetchCustomerLists",
			postCustomerPermission: "customerManagement/postCustomerPermission"
		}),

		...mapActions("toast", {
			showToast: "show"
		}),

		handleClickEdit() {
			this.isEditable = !this.isEditable;
		},

		handleSelectCompany({ value }) {
			this.isSelectCompany = true;
			this.clearCompanyLists();
			this.company = value;
		},

		handleSelectSystemRole(value) {
			this.systemRole = value;
		},

		handleSelectPackage(value) {
			this.packageName = value;
		},

		handleChangeStatus() {
			if (this.isEditable) {
				this.statusActive = !this.statusActive;
			}
		},

		handleEnablePasswordChange() {
			this.isChangePassword = !this.isChangePassword;
		},

		handleGeneratePassword() {
			const randomstring = generatePassword({
				legnth: 8,
				numbers: true,
				symbols: true,
				lowercase: true,
				uppercase: true,
				exclude: "/\\\"",
				strict: true
			});
			this.password = randomstring;
		},

		handleCopyPassword() {
			const copyText = this.$refs.password.$refs.textInput;
			copyText.select();
			copyText.setSelectionRange(0, 99999); /* For mobile devices*/
			document.execCommand("copy");
		},

		async handleCancelForm() {
			this.clearPermissionList();

			const { name = "", packageStatus = "", phoneNumber, isActive, isTestAccount, email, companyName, role = {}, rolePermissions = [] } = this.customerManagement.customerProfile;
			this.isEditable = false;
			this.isChangePassword = false;
			this.isLoading = true;

			await this.fetchPermissionList({});


			this.isSelectCompany = false;
			this.name = name;
			this.packageName = packageStatus;
			this.phone = phoneNumber;
			this.statusActive = isActive;
			this.isTestAccount = isTestAccount;
			this.email = email;
			this.company = companyName;
			this.systemRole = role.code;
			this.permissions = rolePermissions;

			this.setInitialPermission();
			this.isAutoUpdateShipTo = this.getIsUpdateShipTo;
			this.isAutoUpdateDevice = this.getIsUpdateDeivce;
			this.password = "";
			this.$v.password.$reset();
			this.$v.$reset();
			this.isLoading = false;
		},
		debounceFetchCompany(company) {
			this.isSelectCompany = false;
			clearTimeout(this.debounce);
			this.company = company;
			this.isLoadingCompany = true;
			if (!company) {
				this.clearCompanyLists();
			}
			this.debounce = setTimeout(async () => {
				if (company) {
					const queryCompany = this.company;
					await this.fetchCompaniesLists({ q: queryCompany });
					this.isLoadingCompany = false;
				} else {
					this.isLoadingCompany = false;
				}
			}, 600);
			const isMatchCompany = this.getCompaniesOption.filter(({ label }) => label === this.company).length === 1;
			this.isSelectCompany = isMatchCompany;
		},

		async handleSendUpdateCustomerMainform() {
			const customerPayload = {
				name: this.name,
				email: this.email,
				companyName: this.company,
				roleCode: this.systemRole,
				packageCode: this.packageName,
				permissions: this.computePermission,
				...(this.phone ? {
					phoneNumber: this.phone
				} : {}),
				isActive: this.statusActive,
				isTestAccount: this.isTestAccount,
				rolePermissions: this.permissions
			};
			const { id } = this.$route.params;
			await this.updateCustomerProfile({ id, customerPayload });
			if (this.customerManagement.customerUpdateSuccess) {
				this.isEditable = false;
				this.isChangePassword = false;
				this.clearCompanyLists();
				this.customersManageClearErrorsField();
				await this.fetchCustomerById(id);
				await this.fetchPermissionList({});
				this.isSelectCompany = false;
				this.showToast({
					type: TOAST_TYPES.CUSTOMER_UPDATE,
					props: { email: this.email, delay: 6000 }
				});
				this.password = "";
				this.$v.password.$reset();
				this.$v.$reset();
			}
		},
		async handleUpdatePassword() {
			const customerPasswordPayload = {
				password: this.password
			};
			const { id } = this.$route.params;
			await this.updateCustomerPassword({
				id,
				customerPasswordPayload
			});

			if (this.customerManagement.customerUpdatePassowordSuccess) {
				this.clearCompanyLists();
				this.isChangePassword = false;
				this.password = "";
				this.$v.password.$reset();

				this.customersManageClearErrorsField();
				this.showToast({
					type: TOAST_TYPES.CUSTOMER_UPDATE_PASSWORD,
					props: { email: this.email, delay: 6000 }
				});
			}
		},

		onClickOpenModal() {
			this.$modal.show(this.MODAL_NAME);
		},

		async handleSubmitModal(email = "") {
			this.$modal.hide(this.MODAL_NAME);
			this.isLoadingCustomerPermissions = true;
			await this.postCustomerPermission({ email });
			await this.fetchPermissionList({});
			await this.setInitialPermission();
			this.isAutoUpdateShipTo = this.getIsUpdateShipTo;
			this.isAutoUpdateDevice = this.getIsUpdateDeivce;
			this.isLoadingCustomerPermissions = false;
		},

		handleIsTestAccountChange(value) {
			this.isTestAccount = value;
		}
	},
	watch: {
		password(newValue) {
			this.infoList[0].active = newValue.length >= 8;
			this.infoList[1].active = getHasNumber(newValue);
			this.infoList[2].active = getHasLowwerCase(newValue);
			this.infoList[3].active = getHasUpperCase(newValue);
		}
	},
	computed: {
		...mapState(["customerManagement", "customerRolePermission"]),
		...mapGetters("customerManagement", {
			getCreateAt: "getCreateAt",
			getLastUpdatePassword: "getLastUpdatePassword",
			getCustomerSoldToValues: "getCustomerSoldToValues",
			getCustomerShipToValues: "getCustomerShipToValues",
			getCustomerApplicationValues: "getCustomerApplicationValues",
			getCustomerDeviceValues: "getCustomerDeviceValues",
			getIsUpdateShipTo: "getIsUpdateShipTo",
			getIsUpdateDeivce: "getIsUpdateDeivce",
			isAllSoldTo: "isAllSoldTo",
			isAllShipTo: "isAllShipTo",
			isAllApplication: "isAllApplication",
			isAllDevice: "isAllDevice"
		}),
		...mapState({
			customers: (state) => {
				return state.customer.data;
			}
		}),
		...mapGetters("customerManagement", {
			getCustomerSoldToValues: "getCustomerSoldToValues",
			getCustomerShipToValues: "getCustomerShipToValues",
			getCustomerApplicationValues: "getCustomerApplicationValues",
			getCustomerDeviceValues: "getCustomerDeviceValues",
			getIsUpdateShipTo: "getIsUpdateShipTo",
			getIsUpdateDeivce: "getIsUpdateDeivce",
			isAllSoldTo: "isAllSoldTo",
			isAllShipTo: "isAllShipTo",
			isAllApplication: "isAllApplication",
			isAllDevice: "isAllDevice"
		}),
		getCompaniesOption() {
			return this.customerManagement.companies;
		},
		getStatusLabel() {
			if (this.statusActive) {
				return "Active";
			}
			return "Inactive";
		},
		customerEmails() {
			if (!this.email) {
				return [];
			}
			return this.customers.map((item) => {
				try {
					const arr = this.email.split("@");
					const tail = arr[1];
					if (item.email.search(tail) >= 0) {
						return item.email;
					}
					return null;
				} catch (e) {
					return null;
				}
			}).filter((val) => val);
		},
		showDuplicateButton() {
			return this.isEditable;
		},
		permissionMasterData() {
			return this.customerRolePermission.data;
		}
	}
};
</script>
<style lang="scss" scoped>
.permission-loading-wrapper {
	min-height: rem(562);
	display: flex;
	justify-content: center;
	align-items: center;
}

.password-validate-infomation {
	&.container-info {
		width: rem(343);
		padding: 0;
		background-color: transparent;
	}
}

.wrapper-customer-edit {
	padding: rem(24) 0;
}

.edit-controller {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.customer-create-at {
	margin: 0;
	font-size: rem(18);
	color: $color-dark-blue-grey;

	.create-at-time {
		font-weight: $font-weight-bold;
	}
}

.customer-edit-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.create-customer-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.form-permission-header {
	display: flex;
	align-items: center;

	.permission {
		&-level {
			font-size: $font-20;
			width: rem(130);
		}

		&-visibility {
			display: flex;
			justify-content: space-between;
			flex-grow: 1;

			.visibility-label {
				font-size: $font-20;
			}
		}
	}
}

.badge-info {
	&-permission {
		margin-bottom: rem(24);
	}
}

.permission-list {
	margin-left: rem(18);
	list-style-position: inside;

	&-item {
		list-style-type: disc;
	}
}

.form-checkbox {
	margin-left: rem(130);
}

.system-role-info-icon {
	font-size: rem(12);
	color: $color-cerulean;
	cursor: pointer;
	margin-left: rem(6);
}

.customer-create-input {
	margin: rem(24) 0;
}

.input-group-search {
	width: 100%;

	@include desktop {
		width: 100%;

		/* stylelint-disable */
		&::v-deep .dropdown-value {
			padding: 0;
		}
		/* stylelint-enable */
	}
}

.card-active-status {
	margin-bottom: rem(66);
}

.card-customer-info {
	margin-top: rem(40);
}

.info-password-description {
	font-size: rem(16);
	margin: 0;
	color: $color-dark-blue-grey;
}

.generate-password-description {
	color: $color-dark-blue-grey;
	margin: rem(32) 0 rem(-8) 0;
}

.wrapper-password-detail {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.password,
	.last-update-password-label {
		color: $color-dark-blue-grey;
		font-size: rem(18);
	}

	.password {
		margin-bottom: rem(16);
	}

	.last-update-password-label {
		margin: 0;
	}
}

.wrapper-generate-password {
	display: flex;
	align-items: center;
}

.wrapper-submit-password {
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid $color-silver;
	margin-top: rem(16);
	padding-top: rem(24);
}

.container-generate-password {
	display: flex;
	align-items: center;
	width: rem(494);

	.btn {
		display: flex;
		align-items: center;
	}

	.generate-password-btn {
		font-size: rem(20);
		min-width: rem(150);
		justify-content: center;
		font-weight: $font-weight-bold;
	}

	.redo-password-icon {
		margin-right: rem(8);
		font-weight: $font-weight-bold;
	}
}

.copy-password-btn {
	margin-left: rem(11);
}

.redo-password-icon,
.copy-password-icon {
	font-size: rem(16);
}

.copy-password-icon {
	margin-right: rem(4);
}

.wrapper-confirm-create-customer {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.status-form-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}


.form-status {
	display: flex;
}

.switch-status {
	margin-right: rem(16);
}

.status-description,
.status-label {
	font-size: rem(20);
	color: $color-dark-blue-grey;
	margin: 0;
}


.status-label {
	min-width: rem(45);
}

.deactivate-description {
	font-size: rem(18);
	color: $color-grey-4;
	margin: 0;
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}

.item-checkbox-test-account {
	display: flex;
}

.container-form {
	display: flex;

	.label {
		width: 130px;
	}

	.body {
		flex: 1;
		display: flex;
	}

	.head-label {
		font-family: $font-family-base;
		font-size: $font-18;

		&.system-role {
			font-family: $font-family-sub;
			font-size: $font-20;
		}
	}
}

.remark {
	list-style: decimal;
	padding-left: rem(24);
}

.remark li {
	list-style: decimal;
}
</style>
