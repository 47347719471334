<template>
	<div class="container-toast" :style="styleTransformX">
		<div class="container-body">
			<div class="container-content">
				<div class="container-header">
					<FontAwesomeIcon class="info-icon" :icon="['fas', 'info-circle']"/>
					<label class="title">
						BIG Portal website is under maintenance mode
					</label>
				</div>
				<div class="container-description">
					<label class="description">
						Only users with permission are allowed to access. Please disable maintenance mode when finished making changes.
					</label>
					<router-link :to="{ path: '/maintenance' }" class="setting-maintenance">
						Setting
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ToastMaintenanceMode",

	props: {
		collapse: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		styleTransformX() {
			// When Sidebar is opened, collapse props is true
			const sidebarWidth = this.collapse ? 280 : 72;

			const tranformX = `${"transform: translateX(calc(-50% + ("}${sidebarWidth}px / 2)));`;

			return tranformX;
		}
	}

};
</script>

<style lang="scss" scoped>
$border-radius-size: rem(4);

.container {

	// .container-toast
	&-toast {
		position: fixed;
		z-index: $z-index-toast;
		left: 50%;
		min-width: rem(600);
	}

	// .container-body
	&-body {
		display: flex;
		flex-direction: column;

		padding: $spacing-3 $spacing-3 0;
		margin-top: rem(4);
		background-color: white;
		border: 1px solid $color-red;
		border-radius: $border-radius-size;
	}

	// .container-content
	&-content {
		display: flex;
		flex-direction: column;
	}

	// .container-header
	&-header {
		display: flex;
		flex-direction: row;
		align-items: center;

		.info-icon {
			font-size: $font-12;
			color: $color-red;
		}

		.title {
			font-size: $font-16;
			font-weight: $font-weight-bold;
			color: $color-red;
			line-height: 1;

			margin-left: rem(4);
		}
	}

	// .container-description
	&-description {
		display: flex;
		flex-direction: row;

		font-size: $font-16;
		color: $color-dark-blue-grey;
		line-height: 1;

		.description {
			white-space: nowrap;
		}

		.setting-maintenance {
			font-weight: $font-weight-bold;
			color: $color-cerulean;

			margin-left: rem(2);
		}
	}
}
</style>