<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<template v-else>
			<BaseLoading
				v-if="genGasHistory.isLoading"
			/>
			<BaseHistoryListsWithBarG
				v-else
				:headColumns="getHeaderHistory"
				:items="getItemsHistory"
			/>

			<BasePagination
				v-if="pagination.totalPage > 1 && isLoading"
				:value="page"
				:totalPage="pagination.totalPage"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>
		</template>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseHistoryListsWithBarG from "@/components/BaseHistoryListsWithBarG.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import BasePagination from "@/components/BasePagination.vue";
import { NM_UNIT } from "../../selectors/transform/unit";

export default {
	name: "GenGasOrderHistory",

	components: {
		EmptyOrderHistory,
		BaseHistoryListsWithBarG,
		BasePagination,
		BaseLoading
	},

	data() {
		return {
			id: this.$route.params.id,

			thead: [
				"Date",
				`Total Volumn (${NM_UNIT})`
			],

			tableOptions: [
				"is-striped is-fullwidth"
			],

			columnOptions: [
				{ className: "bold" },
				{ className: "" },
				{ className: "" },
				{ className: "align-right" }
			],

			page: this.$route.query.page || 1
		};
	},

	computed: {
		...mapState(["genGasHistory"]),

		...mapGetters("genGasHistory", {
			getHeaderHistory: "getHeaderHistory",
			getItemsHistory: "getItemsHistory"
		}),

		pagination() {
			return this.genGasHistory.pagination;
		},

		loading() {
			return this.genGasHistory.isLoading;
		},

		isEmpty() {
			return this.genGasHistory.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.id,
			httpStatus: this.genGasHistory.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("genGasHistory", {
			getGenGasHistory: "getGenGasHistory",
			clearGenGasHistory: "clearGenGasHistory"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		async fetchData() {
			const { id, page } = this;
			await this.getGenGasHistory({ id, page });
		},

		handlePageChange(page = 1) {
			this.page = page;
			this.fetchData();
			this.$router.replace({ query: { page } });
		}
	},

	destroyed() {
		this.clearGenGasHistory();
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}
</style>
