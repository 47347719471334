import { render, staticRenderFns } from "./ApplyToDevice.vue?vue&type=template&id=19a74427&scoped=true&"
import script from "./ApplyToDevice.vue?vue&type=script&lang=js&"
export * from "./ApplyToDevice.vue?vue&type=script&lang=js&"
import style0 from "./ApplyToDevice.vue?vue&type=style&index=0&id=19a74427&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a74427",
  null
  
)

export default component.exports