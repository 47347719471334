import { getFlowDiagramListAPI } from "@/services/api/flowdiagram.api";
import { FLOW_DIAGRAM_LIST_REQUEST, FLOW_DIAGRAM_LIST_SUCCESS, FLOW_DIAGRAM_LIST_FAILURE } from "../../types";
import { parseFlowDiagrams } from "../../../services/api/transforms/parseFlowDiagram";
import { parsePagination } from "../../../services/api/transforms/parsePagination";

export default {
	namespaced: true,

	state: {
		data: parseFlowDiagrams([]),
		pagination: parsePagination({}),
		loading: false,
		empty: true
	},

	mutations: {
		[FLOW_DIAGRAM_LIST_REQUEST](state) {
			state.data = parseFlowDiagrams([]);
			state.loading = true;
			state.empty = false;
		},
		[FLOW_DIAGRAM_LIST_SUCCESS](state, { data, pagination }) {
			state.data = parseFlowDiagrams(data);
			state.pagination = parsePagination(pagination);
			state.loading = false;
			state.empty = !state.data.length;
		},
		[FLOW_DIAGRAM_LIST_FAILURE](state) {
			state.data = parseFlowDiagrams([]);
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getFlowDiagramList({ commit }, params) {
			try {
				commit(FLOW_DIAGRAM_LIST_REQUEST);
				const { data } = await getFlowDiagramListAPI(params);
				commit(FLOW_DIAGRAM_LIST_SUCCESS, data);
			} catch (error) {
				commit(FLOW_DIAGRAM_LIST_FAILURE);
			}
		}
	}
};
