import { getO2AerationInfoAPI } from "@/services/api/o2Aeration.api";
import {
	O2_AERATION_INFO_REQUEST,
	AUTO_O2_AERATION_INFO_REQUEST,
	O2_AERATION_INFO_SUCCESS,
	AUTO_O2_AERATION_INFO_SUCCESS,
	O2_AERATION_INFO_FAILURE,
	O2_AERATION_CLEAR
} from "../../types";
import { parseO2AerationInfo } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: {},
		loading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	mutations: {
		[O2_AERATION_INFO_REQUEST](state) {
			state.data = {};
			state.loading = true;
			state.statusCode = null;
		},
		[AUTO_O2_AERATION_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[O2_AERATION_INFO_SUCCESS](state, { data, status }) {
			state.data = parseO2AerationInfo(data);
			state.loading = false;
			state.statusCode = status;
		},
		[AUTO_O2_AERATION_INFO_SUCCESS](state, { data, status }) {
			state.data = parseO2AerationInfo(data);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[O2_AERATION_INFO_FAILURE](state, error) {
			state.data = {};
			state.loading = false;
			state.statusCode = error.response.status;
		},
		[O2_AERATION_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async getO2AerationInfo({ commit }, { id }) {
			try {
				commit(O2_AERATION_INFO_REQUEST);
				const response = await getO2AerationInfoAPI(id);
				commit(O2_AERATION_INFO_SUCCESS, response);
			} catch (error) {
				commit(O2_AERATION_INFO_FAILURE, error);
			}
		},

		async autoGetO2AerationInfo({ commit }, { id }) {
			try {
				commit(AUTO_O2_AERATION_INFO_REQUEST);
				const response = await getO2AerationInfoAPI(id);
				commit(AUTO_O2_AERATION_INFO_SUCCESS, response);
			} catch (error) {
				commit(O2_AERATION_INFO_FAILURE, error);
			}
		},

		clearO2AerationInfo({ commit }) {
			commit(O2_AERATION_CLEAR);
		}
	}
};
