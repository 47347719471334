<template>
	<CardLineChart
		class="card-line"
		:chartData="chartOptions"
		:filterOptions="filterOptions"
		:isLoading="loading"
		:isEmpty="empty"
		:lastUpdate="updatedAt"
		:chartTitle="`Graph kWh`"
		:kwhData="kwhCounter"
		@onSelectDatePicker="handleSelectDateRange"
		@onSelectDropDown="handleSelectDateDropDown"
	/>
</template>

<script>
import dayjs from "dayjs";
import Numeral from "numeral";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { convertFilterByOfPowerChart } from "../../selectors/helpers";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export default {
	props: {
		data: {
			type: Array,
			default: () => ([])
		},
		updatedAt: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Boolean,
			default: false
		},
		kwhCounter: {
			type: String,
			default: ""
		}
	},

	data() {
		return {
			sortBy: perDay,
			filterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 1 month",
								value: perThirtyDays
							},
							{
								label: "Last 3 months",
								value: perNinetyDays
							}
						]
					}
				}
			}
		};
	},

	components: {
		CardLineChart
	},

	computed: {
		chartOptions() {
			let index = -1;
			return {
				legend: {
					show: true,
					selectedMode: false,
					bottom: 30,
					left: 68,
					padding: [0, 0, 20, 0],
					data: [
						{
							name: "off-peak",
							icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAMCAYAAAADFL+5AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAADAAAAACot6D2AAABSElEQVQ4EWNkIBE43G/g+PDkUTkDA2Mo4///yv8ZGb8zMjCcYWBk6j9vM3s7icYxAPUSD0yPphr8+fd3+f//DBrYdDEyMs7jl5HNPqDY8AObPDYxJmyC2MTMT+Ty/fn3byMuy0F6/v//n/TxyeNWbPpxiRHtgF+/vncCLZDDZRBM/D8DQ4HJsWQzGJ8QTZQDQq82sP1n+B9DyDCw/P//TH//MCYSpRaoiMXgUBLQ0VgAI+OFC7ZzDUEydz8+Bvr8Pw8WVTiE/mshS+Czg6gQQDaM2myWC3bzCOYEZX7ZR7ffPv5CfCgwXkN2KD47iAqB1doNvxgZGJcgG4qTzcj4j5nl/3yc8mgSRDkApIeNjbMcmM8foenH4DIy/O8/YzX3FIYEDgGiHXDSYvInFiYmf0ZGhhs4zGKAFERyNbjksYkTjH90TdQuigHMFWxOkyfZjAAAAABJRU5ErkJggg=="
						},
						{
							name: "on-peak",
							icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAMBAMAAADxOqKKAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURUdwTMAkLtUyQMEjLsQlL8EjL8IkLsEkL8EkLsAmMcEkL8EjLsYjMsAjLh3ScHcAAAANdFJOUwDTCfMzl4adwUm64SS/XYaHAAAASElEQVQI12NgAAM1YasJDEiARfbu3esKSALad4EgAUkgFiRwBcFnsgUJXIVwgCwBHhD/7g0G3rt3L2IKYGjBaiimtRgOQ3I6ANoaNut3DLSdAAAAAElFTkSuQmCC"
						}
					],
					itemHeight: 10,
					itemGap: 20
				},
				tooltip: {
					show: true,
					trigger: "axis",
					backgroundColor: "#103332",
					axisPointer: {
						crossStyle: {
							color: "red"
						}
					},
					padding: 8,
					formatter: ([a]) => {
						return `
							<div>
								<div class="bar-name">
									${dayjs(a.axisValue).format("HH.mm, D MMM YYYY")}
								</div>
								<div class="bar-value">
									${a.data === null ? "-" : this.formatPrice(a.data)} kWh
								</div>
							</div>
						`;
					}
				},
				color: ["#ea7125"],
				grid: {
					left: "48px",
					right: "74px",
					top: 60,
					bottom: 82,
					containLabel: true
				},
				xAxis: [
					{
						type: "category",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.kWhs.reduce((_acc, _cur) => {
									_acc.push(_cur.timestamp);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})(),
						boundaryGap: false,
						axisPointer: {
							show: true,
							triggerTooltip: false,
							label: {
								show: false
							}
						},
						axisLabel: {
							margin: 8,
							fontSize: 14,
							show: true,
							formatter: (value) => {
								const startOfDate = dayjs(value).startOf("day").unix();
								const currentDate = dayjs(value).unix();
								return startOfDate === currentDate
									? dayjs(value).format("HH:mm\nD MMM")
									: dayjs(value).format("HH:mm");
							}
						},
						axisTick: {
							length: 4,
							lineStyle: {
								width: 1,
								color: "#586c63"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						}
					}
				],
				yAxis: [
					{
						name: "kWh",
						type: "value",
						color: "#59e07a",
						scale: true,
						boundaryGap: ["20%", "20%"],
						nameTextStyle: {
							color: "#717171",
							width: "100px",
							fontFamily: "TFSrivichai",
							fontWeight: "bold",
							fontSize: 20,
							align: "left"
						},
						axisLabel: {
							margin: 8,
							fontSize: 14,
							formatter: (value) => Numeral(value).format("0[.]000a")
						},
						splitNumber: 4,
						splitLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						},
						axisTick: {
							length: 4,
							lineStyle: {
								width: 1,
								color: "#586c63"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						}
					}
				],
				axisLabel: {
					fontSize: 14,
					fontFamily: "TFSrivichai",
					color: "#103332"
				},
				dataZoom: [
					{
						type: "inside"
					},
					{
						show: true,
						realtime: true,
						start: 0,
						end: 25,
						type: "slider",
						textStyle: {
							fontFamily: "TFSrivichai",
							fontSize: 14,
							width: 50,
							height: 50
						},
						handleIcon: "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
						handleSize: "80%",
						dataBackground: {
							areaStyle: {
								color: "#8392A5"
							},
							lineStyle: {
								opacity: 0.8,
								color: "#8392A5"
							}
						},
						handleStyle: {
							color: "#fff",
							shadowBlur: 3,
							shadowColor: "rgba(0, 0, 0, 0.6)",
							shadowOffsetX: 2,
							shadowOffsetY: 2
						},
						labelFormatter: (value, date) => {
							return dayjs(date).format("HH.mm\nD MMM YYYY");
						}
					}
				],
				visualMap: {
					show: false,
					dimension: 0,
					pieces: this.data.reduce((acc, cur) => {
						const arr = cur.kWhs.reduce((_acc, _cur) => {
							index += 1;
							_acc.push({
								gt: index,
								lt: index + 1,
								color: this.getLineColor(_cur.onPeak, _cur.offPeak)
							});
							return _acc;
						}, []);
						acc.push(...arr);
						return acc;
					}, [])
				},
				series: [
					{
						name: "off-peak",
						type: "line",
						smooth: true,
						itemStyle: {
							color: "#30c262"
						},
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.kWhs.reduce((_acc, _cur) => {
									_acc.push(_cur.kWh);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})()
					},
					{
						name: "on-peak",
						type: "line",
						smooth: true,
						itemStyle: {
							color: "#30c262"
						},
						data: []
					}
				]
			};
		}
	},

	methods: {
		getLineColor(onPeak, offPeak) {
			if (onPeak) {
				return "#c0232e";
			}
			if (offPeak) {
				return "#30c262";
			}
			return null;
		},

		handleSelectDateRange({ start, end }) {
			const params = {
				startDate: dayjs(start).startOf("day").toISOString(),
				endDate: dayjs(end).endOf("day").toISOString()
			};
			this.filterOptions.date.selected.range = { start, end };
			this.sortBy = "";
			this.$emit("filter", params);
		},
		handleSelectDateDropDown(filterBy = "1d") {
			const filter = convertFilterByOfPowerChart(filterBy);
			const params = { filter };
			this.sortBy = filterBy;
			this.$emit("filter", params);
		},

		formatPrice(value) {
			return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}
};
</script>
<style lang="scss" scoped>
.card-line {
	/* stylelint-disable */
	&::v-deep {
		.chart.card {
			height: rem(520);
		}

		.chartbar-container {
			height: rem(415);
		}
	}
}
</style>