import { isEmpty } from "../../../selectors/helpers";

export const parsePowerBI = (powerBIData) => {
	const checkCanShowPowerBI = isEmpty(powerBIData.embedUrl) && isEmpty(powerBIData.id) && isEmpty(powerBIData.name) && isEmpty(powerBIData.token);
	return {
		isEmpty: checkCanShowPowerBI,
		embedUrl: powerBIData.embedUrl,
		id: powerBIData.id,
		name: powerBIData.name,
		token: powerBIData.token
	};
};