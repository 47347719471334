import { getSorInfoAPI } from "@/services/api/sor.api";
import { getLayoutTemplate } from "@/services/api/layoutCustomisation.api";
import { SOR_INFO_REQUEST, AUTO_SOR_INFO_REQUEST, SOR_INFO_SUCCESS, AUTO_SOR_INFO_SUCCESS, SOR_INFO_FAILURE, SOR_INFO_CLEAR } from "../../types";
import { parseSorInfo } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: {},
		loading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	mutations: {
		[SOR_INFO_REQUEST](state) {
			state.data = {};
			state.loading = true;
			state.statusCode = null;
		},
		[AUTO_SOR_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[SOR_INFO_SUCCESS](state, { response, template }) {
			state.data = parseSorInfo(response.data, template.data);
			state.loading = false;
			state.statusCode = response.status;
		},
		[AUTO_SOR_INFO_SUCCESS](state, { response, template }) {
			state.data = parseSorInfo(response.data, template.data);
			state.isAutoFecthLoading = false;
			state.statusCode = response.status;
		},
		[SOR_INFO_FAILURE](state, error) {
			state.data = {};
			state.loading = false;
			state.statusCode = error.response.status;
		},
		[SOR_INFO_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async getSorInfo({ commit }, { id }) {
			try {
				commit(SOR_INFO_REQUEST);
				const response = await getSorInfoAPI(id);
				const template = await getLayoutTemplate({ app: "SOR" });
				commit(SOR_INFO_SUCCESS, { response, template });
			} catch (error) {
				commit(SOR_INFO_FAILURE, error);
			}
		},

		async autoGetSorInfo({ commit }, { id }) {
			try {
				commit(AUTO_SOR_INFO_REQUEST);
				const response = await getSorInfoAPI(id);
				const template = await getLayoutTemplate({ app: "SOR" });
				commit(AUTO_SOR_INFO_SUCCESS, { response, template });
			} catch (error) {
				commit(SOR_INFO_FAILURE, error);
			}
		},

		clearSorInfo({ commit }) {
			commit(SOR_INFO_CLEAR);
		}
	}
};
