import dayjs from "dayjs";
import Numeral from "numeral";

export const transformTripInformation = (item = {}) => {
	return {
		doNumber: item.doNumber ? item.doNumber : "-",
		tractorId: item.tractorId ? item.tractorId : "-",
		trailerId: item.trailerId ? item.trailerId : "-",
		tripId: item.tripId ? item.tripId : "-",
		duration: item.duration,
		shipName: item.shipName,
		onTarget: item.onTarget,
		inTransit: item.inTransit,
		estimateTimeStamp: item.estimateTimeStamp ? dayjs(item.estimateTimeStamp).format("D MMM YYYY, HH:mm") : "N/A",
		orderAmount: Numeral(item.orderAmount).format("0,0")
	};
};