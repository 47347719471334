import httpClient from "./httpClient";

const COMPANIES = "/companies/addable";
const COMPANIES_LIST = "/companies/lists";
const COMPANIES_POWERBI_EXECUTIVE = "/analysis/companies";

const getCompaniesAPI = (params = {}) => httpClient.get(`${COMPANIES}`, { params });
const getCompaniesListAPI = (params = {}) => httpClient.get(`${COMPANIES_LIST}`, { params });
const getCompaniesPowerBIExecutiveAPI = (params = {}) => httpClient.get(`${COMPANIES_POWERBI_EXECUTIVE}`, { params });
export {
	getCompaniesAPI,
	getCompaniesListAPI,
	getCompaniesPowerBIExecutiveAPI
};
