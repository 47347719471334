export const parseFlowDiagram = (item = {}) => {
	return {
		id: item.id,
		shipToId: item.shipToId,
		searchName: item.searchName,
		diagrams: item.diagrams,
		isActive: item.isActive,
		createdAt: item.createdAt,
		updatedAt: item.updatedAt,
		shipTo: {
			id: item.shipTo ? item.shipTo.id : null,
			code: item.shipTo ? item.shipTo.code : null,
			key: item.shipTo ? item.shipTo.key : null,
			name: item.shipTo ? item.shipTo.name : null,
			soldToId: item.shipTo ? item.shipTo.soldToId : null,
			locationKey: item.shipTo ? item.shipTo.locationKey : null
		}
	};
};

export const parseFlowDiagrams = (items = []) => {
	return items.map((item) => parseFlowDiagram(item));
};

export const parseShipTo = (item) => {
	return {
		id: item.id,
		code: item.code,
		key: item.key,
		name: item.name,
		soldToId: item.soldToId,
		locationKey: item.locationKey
	};
};

export const parseShipTos = (items = []) => {
	return items.map((item) => parseShipTo(item));
};

export const parseDevice = (item = {}) => {
	return {
		id: item.id,
		shipId: item.shipId,
		deviceCustomerName: item.deviceCustomerName,
		type: item.type,
		module: item.module,
		shipToId: item.shipToId,
		name: item.name,
		// meter
		flowRate: item.flowRate,
		flowRatePercent: item.flowRatePercent,
		tagNo: item.tagNo,
		avgConsumptionMTD: item.avgConsumptionMTD,
		consumptionMTD: item.consumptionMTD,
		// nitrofas
		uph: item.UPH,
		// electricity
		kw: item.KW,
		maxRange: item.maxRange,
		mtdPower: item.MTDPower,
		mtdPowerCost: item.MTDPowerCost
	};
};

export const parseDevices = (items = []) => {
	return items.map((item) => parseDevice(item));
};