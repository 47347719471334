<template>
	<div class="button-group-container">
		<BaseButton
			v-for="(item,index) in items"
			:class="['button-group', type, { 'active': item.value === active}]"
			:key="index"
			:disabled="item.value === active || item.isDisabled"
			:size="size"
			outline
			@click.native="handleClick(item.value)"
		>
			<FontAwesomeIcon  v-if="item.icon" :icon="item.icon" class="icon"/>
			{{item.text}}
		</BaseButton>
	</div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
	components: {
		BaseButton
	},
	props: {
		items: {
			type: Array,
			default: () => []
		},
		active: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: "medium",
			validator: (value) => ["small", "medium", "large"].includes(value)
		},
		type: {
			type: String,
			default: "primary",
			validator: (value) => ["primary", "secondary"].includes(value)
		}
	},
	methods: {
		handleClick(value) {
			this.$emit("click", value);
			this.$emit("update:active", value);
		}
	}
};
</script>

<style lang="scss" scoped>
.button-group-container {
	display: flex;

	.button-group {
		display: flex;
		align-items: center;
		color: $color-grey-4;

		&.secondary {
			&.btn-primary {
				&.outline {
					border: 1px solid $color-silver;

					&.active {
						background-color: transparent;
						color: $color-emerald;
						border-color: $color-emerald;
					}
				}
			}
		}

		&.primary {
			&.btn-primary {
				&.outline {
					border: 1px solid $color-silver;

					&.active {
						border-color: $color-emerald;
					}
				}
			}
		}

		&:nth-child(n) {
			border-radius: 0;
		}

		&:first-child {
			border-radius: rem(8) 0 0 rem(8);
		}

		&:last-child {
			border-radius: 0 rem(8) rem(8) 0;
		}

		.icon {
			margin-right: rem(8);
		}
	}
}
</style>