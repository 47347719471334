<template>
	<div class="request-verify-wrapper">
		<font-awesome-icon class="request-verify-icon-sync" :icon="['far','sync-alt']"/>
		<h3 class="request-verify-title">Verifying</h3>
		<p class="request-verify-message">BIG portal will verify and contact you soon</p>
		<div class="request-verify-detail-wrapper">
			<div class="detail-box">
				<h3 class="detail-title">Request by</h3>
				<div class="detail-message">
					{{ requestBy }}
				</div>
			</div>
			<div class="detail-box">
				<h3 class="detail-title">Comment</h3>
				<div class="detail-message">
					{{ comment }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		requestBy: {
			type: String,
			default: ""
		},
		comment: {
			type: String,
			default: ""
		}
	}
};
</script>

<style lang="scss" scoped>
.request-verify {
	&-title {
		margin: rem(8) 0 rem(4);
		font-size: rem(20);
		color: $color-gunmetal-two;
	}

	&-message {
		margin: 0;
		font-weight: $font-weight-bold;
		color: $color-grey-4;
		margin-bottom: rem(24);
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: rem(78) 0 rem(50) 0;
	}

	&-icon-sync {
		font-size: rem(40);
		color: $color-gunmetal-two;
	}

	&-detail-wrapper {
		display: flex;
		flex-direction: column;
		min-width: rem(560);
		min-height: rem(210);
		background-color: $color-off-white;
		padding: rem(16);

		.detail {
			&-box {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				margin: rem(4) 0;
				color: $color-dark-blue-grey;
			}

			&-title {
				min-width: rem(70);
				font-size: rem(18);
			}

			&-message {
				margin: 0 rem(16);
				width: rem(444);
			}
		}
	}
}
</style>