<template>
	<div class="overview-container">
		<div class="overview-header">
			<div class="title" v-html="data.shipId"></div>
			<div class="value" v-html="data.soldToName"></div>
		</div>
		<template v-if="data.type !== 'EOrdering'">
			<div class="overview-section">
				<div
					class="overview-bar"
				>
					<BaseStandardIcon :data="data.overview.graph" iconSize="large"/>
				</div>
				<div class="overview-values">
					<OverviewValue
						v-for="item in data.overview.rows"
						:key="item.key"
						:data="item"
					>
					</OverviewValue>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import BaseStandardIcon from "@/components/BaseStandardIcon.vue";
import OverviewValue from "@/components/StandardModule/OverviewValue.vue";
import { MODULE_TYPES } from "../../enums/modules";
import { HTML_FLOW_RATE_UNIT_PER_HR, WAST_WATER_UNIT } from "../../selectors/transform/unit";
import { STATUS } from "../../enums/eOrdering/status";
import { getUri } from "../../selectors/helpers";

export default {
	name: "StandardOverview",

	components: {
		BaseStandardIcon,
		OverviewValue
	},

	props: {
		data: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			MODULE_TYPES,
			HTML_FLOW_RATE_UNIT_PER_HR,
			WAST_WATER_UNIT
		};
	},

	computed: {
		isTankType() {
			return this.data.type === MODULE_TYPES.TANK && this.data.productId !== MODULE_TYPES.BHY;
		},
		isBulkHydrogenType() {
			return this.data.type === MODULE_TYPES.TANK && this.data.productId === MODULE_TYPES.BHY;
		},
		isShowDownloadAttachment() {
			const allowStatus = [STATUS.COMPLETED, STATUS.DELIVERED];

			return allowStatus.includes(this.data.status) && false; // TODO: Remove && false when API ready
		}
	},

	methods: {
		handleDownloadAttachment() {
			const orderNo = this.data.orderNo;

			const path = `/e-ordering/infos/${orderNo}/pdf`;
			const uri = getUri(path, {});
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.overview {
	&-container {
		display: flex;
		width: 100%;
		flex-direction: column;
	}

	&-header {
		display: flex;
		margin-bottom: rem(12);
		font-size: rem(26);
		font-weight: 600;

		.title {
			flex: none;
			width: rem(162);
		}

		@include mobile {
			justify-content: center;
		}
	}

	&-section {
		display: flex;

		@media (max-width: rem(580)) {
			flex-flow: column wrap;
		}
	}

	&-info {
		display: flex;
		justify-content: space-between;

		p {
			margin: 0 0 rem(12);
			color: $color-dark-blue-grey;

			&:nth-child(even) {
				display: flex;
				font-weight: 600;
			}
		}

		@include mobile {
			margin-top: rem(8);
		}
	}

	&-info:last-of-type {
		p {
			margin: 0;
		}
	}

	&-values {
		flex: 1;

		.subHeader {
			font-weight: bold;
		}

		.title {
			flex: none;
			width: 50%;

			&.-has-icon {
				width: 40%;
				text-align: right;
				margin-bottom: 0;
			}

			.title-icon {
				font-size: $font-26;
			}
		}

		.value {
			&.-has-bar-g {
				width: 50%;
				display: flex;

				.html {
					width: rem(50);

					text-align: right;
				}

				.text-color-red {
					color: $color-red;
				}

				::v-deep {
					.bar-g-container {
						width: calc(100% - 50px);

						.bar-g-chart {
							width: 100%;
						}
					}
				}
			}

			.progress-bar {
				width: rem(96);
				position: relative;
				top: rem(2);
				margin-left: rem(8);
			}
		}

		.overview-group {

			// overview-group-header
			&-header {
				margin: rem(8) 0;
				padding: rem(4) rem(8);

				font-size: $font-18;
				font-weight: bold;

				background-color: $color-off-white;
			}

			//overview-group-header
			&-body {
				padding: 0 rem(8);
			}
		}

		.overview-attachment {
			margin-top: rem(8);
			padding: rem(12) rem(8) rem(8) rem(8);
			border-top: 1px solid $color-ice;

			.view-button {
				.icon-file {
					margin-right: 2px;
					font-size: rem(14);
				}
			}
		}
	}

	&-bar {
		min-width: rem(144);
		margin-right: rem(16);
		padding: rem(8);

		@include mobile {
			display: flex;
			justify-content: center;
		}

		/* stylelint-disable */
		&::v-deep {
			.nitro-fas-wrapper {
				width: rem(128);
				height: rem(128);
			}
		}
		/* stylelint-enable */

		@include mobile {
			align-self: center;
		}
	}
}
</style>

<style scoped>
/* we have to create second style because scss not supported to deep selector */
.html >>> sup {
	line-height: 0;
}
</style>
