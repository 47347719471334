<template>
	<div class="furnace-overview">
		<BaseLoading v-if="furnaceTemperature.isLoading" />
		<BaseCard>
			<template v-slot:header>
				<h4>Overview</h4>
				<div class="current-time">
					Last update: {{ furnaceTemperature.lastUpdate }}
				</div>
			</template>
			<template v-slot:body>
				<div v-if="hasOverview" class="body-furnace-image">
					<div class="furnace-image-wrapper">
						<img class="furnace-image" src="~@/assets/images/furnace-temperature.png"/>
						<div class="overlay-box" >
							<div class="value-box vertical glass-level"  v-if="getFurnaceTemperatureData.layoutOverview.values.glassLevel !== '-'">  <!--GLASS_LEVEL-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.glassLevel"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/glass-level.svg">
									<div class="value glass-level-bg">{{ getFurnaceTemperatureData.layoutOverview.values.glassLevel | twoDecimalComma }}</div>
								</div>
							</div>
							<div class="value-box vertical refiner-pressure"  v-if="getFurnaceTemperatureData.layoutOverview.values.refinerPressure !== '-'">  <!--REFINER_PRESSURE-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.refinerPressure"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/gauge-high-purple.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.refinerPressure | twoDecimalComma }}</div>
								</div>
							</div>
							<div class="value-box vertical refiner" v-if="getFurnaceTemperatureData.layoutOverview.values.refiner3 !== '-' || getFurnaceTemperatureData.layoutOverview.values.refiner2 !== '-' || getFurnaceTemperatureData.layoutOverview.values.refiner1 !== '-'"> <!--refiner-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.refiner"></span></div>
							</div>
							<div class="values-box refiner-group width-refiners absolute">   <!--Melters N-->
								<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.refiner3 !== '-'"> <!--refiner3-->
									<div class="thermometer">
										<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
										<div class="thermometer-name">3</div>
									</div>
									<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.refiner3 | currency }}</div>
								</div>
								<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.refiner2 !== '-'"> <!--refiner2-->
									<div class="thermometer">
										<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
										<div class="thermometer-name">2</div>
									</div>
									<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.refiner2 | currency }}</div>
								</div>
								<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.refiner1 !== '-'"> <!--refiner1-->
									<div class="thermometer">
										<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
										<div class="thermometer-name">1</div>
									</div>
									<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.refiner1 | currency }}</div>
								</div>
							</div>
							<div class="value-box vertical ng-refiner" v-if="getFurnaceTemperatureData.layoutOverview.values.ngRefiner !== '-'">  <!--NG_REFINER-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.ngRefiner"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/water.svg">
									<div class="value color-azure-bg">{{ getFurnaceTemperatureData.layoutOverview.values.ngRefiner | twoDecimalComma }}</div>
								</div>
							</div>
							<div class="value-box vertical melter-pressure" v-if="getFurnaceTemperatureData.layoutOverview.values.melterPressure !== '-'"> <!--MELTER_PRESSURE-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.melterPressure"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/gauge-high-purple.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterPressure | twoDecimalComma }}</div>
								</div>
							</div>
							<div class="value-box vertical melter-n width-melters melters">  <!--Melters N & S-->
								<div class="values-box width-melters">   <!--Melters N-->
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterCrown4 !== '-'"> <!--Melter4-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">4</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterCrown4 | currency }}</div>
									</div>
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterCrown3 !== '-'"> <!--Melter3-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">3</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterCrown3 | currency }}</div>
									</div>
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterCrown2 !== '-'"> <!--Melter2-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">2</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterCrown2 | currency }}</div>
									</div>
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterCrown1 !== '-'"> <!--Melter1-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">1</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterCrown1 | currency }}</div>
									</div>
								</div>
								<div class="values-box width-melters">   <!--Melters S-->
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterGlass4 !== '-'"> <!--Melter4-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">4</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterGlass4 | currency }}</div>
									</div>
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterGlass3 !== '-'"> <!--Melter3-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">3</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterGlass3 | currency }}</div>
									</div>
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterGlass2 !== '-'"> <!--Melter2-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">2</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterGlass2 | currency }}</div>
									</div>
									<div class="sequence-value-box" v-if="getFurnaceTemperatureData.layoutOverview.values.melterGlass1 !== '-'"> <!--Melter1-->
										<div class="thermometer">
											<img class="thermometer-image" src="~@/assets/images/temperature-three-quarters-white.svg">
											<div class="thermometer-name">1</div>
										</div>
										<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.melterGlass1 | currency }}</div>
									</div>
								</div>

							</div>
							<div class="value-box vertical ng-melter" v-if="getFurnaceTemperatureData.layoutOverview.values.ngMelter !== '-'">  <!--NG_MELTER-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.ngMelter"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/water.svg">
									<div class="value color-azure-bg">{{ getFurnaceTemperatureData.layoutOverview.values.ngMelter | twoDecimalComma }}</div>
								</div>
							</div>
							<div class="value-box vertical hopper1 hopper-width" v-if="getFurnaceTemperatureData.layoutOverview.values.hopper1 !== '-'">  <!--HOPPER1-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.tempHopper"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters.svg">
									<div class="value hopper-bg">{{ getFurnaceTemperatureData.layoutOverview.values.hopper1 | currency }}</div>
								</div>
							</div>
							<div class="value-box vertical hopper2 hopper-width" v-if="getFurnaceTemperatureData.layoutOverview.values.hopper2 !== '-'">  <!--HOPPER2-->
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters.svg">
									<div class="value hopper-bg">{{ getFurnaceTemperatureData.layoutOverview.values.hopper2 | currency }}</div>
								</div>
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.tempHopper2"></span></div>
							</div>
							<div class="value-box horizontal non-transform melter-tag-n" v-if="getFurnaceTemperatureData.layoutOverview.values.melterCrown1 !== '-' || getFurnaceTemperatureData.layoutOverview.values.melterCrown2 !== '-' || getFurnaceTemperatureData.layoutOverview.values.melterCrown3 !== '-' || getFurnaceTemperatureData.layoutOverview.values.melterCrown4 !== '-'">  <!--MELTER_N-->
								<div class="tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.melterN"></span></div>
							</div>
							<div class="value-box horizontal non-transform melter-tag-s" v-if="getFurnaceTemperatureData.layoutOverview.values.melterGlass1 !== '-' || getFurnaceTemperatureData.layoutOverview.values.melterGlass2 !== '-' || getFurnaceTemperatureData.layoutOverview.values.melterGlass3 !== '-' || getFurnaceTemperatureData.layoutOverview.values.melterGlass4 !== '-'">  <!--MELTER_S-->
								<div class="tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.melterS"></span></div>
							</div>
							<div class="value-box horizontal non-transform checker-tag-n" v-if="getFurnaceTemperatureData.layoutOverview.values.checkerTempN !== '-' || getFurnaceTemperatureData.layoutOverview.values.checkerPressureN !== '-'">  <!--CHECKER_N-->
								<div class="tag-name overlay-tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.checkerN"></span></div>
							</div>
							<div class="value-box horizontal non-transform checker-tag-s" v-if="getFurnaceTemperatureData.layoutOverview.values.checkerTempS !== '-' || getFurnaceTemperatureData.layoutOverview.values.checkerPressureS !== '-'">  <!--CHECKER_S-->
								<div class="tag-name overlay-tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.checkerS"></span></div>
							</div>
							<div class="value-box horizontal non-transform second-pressure-tag-n" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryPressureN !== '-' || getFurnaceTemperatureData.layoutOverview.values.secondaryTempN !== '-'">  <!--SECONDARY_PRESSURE_N-->
								<div class="tag-name overlay-tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.secondaryPressureN"></span></div>
							</div>
							<div class="value-box horizontal non-transform second-pressure-tag-s" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryPressureS !== '-' || getFurnaceTemperatureData.layoutOverview.values.secondaryTempS !== '-'">  <!--SECONDARY_PRESSURE_S-->
								<div class="tag-name overlay-tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.secondaryPressureS"></span></div>
							</div>
							<div class="value-box horizontal check-temp-n" v-if="getFurnaceTemperatureData.layoutOverview.values.checkerPressureN !== '-' || getFurnaceTemperatureData.layoutOverview.values.checkerTempN !== '-'">  <!--CheckPressure_TempN-->
								<div class="outside-icon-value" v-if="getFurnaceTemperatureData.layoutOverview.values.checkerPressureN !== '-'">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/gauge-high-white.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.checkerPressureN | twoDecimalComma }}</div>
								</div>
								<div class="outside-icon-value"  v-if="getFurnaceTemperatureData.layoutOverview.values.checkerTempN !== '-'">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters-white.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.checkerTempN | currency }}</div>
								</div>
							</div>
							<div class="value-box horizontal check-temp-s" v-if="getFurnaceTemperatureData.layoutOverview.values.checkerPressureS !== '-' || getFurnaceTemperatureData.layoutOverview.values.checkerTempS !== '-'">  <!--CheckPressure_TempS-->
								<div class="outside-icon-value" v-if="getFurnaceTemperatureData.layoutOverview.values.checkerPressureS !== '-'">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/gauge-high-white.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.checkerPressureS | twoDecimalComma }}</div>
								</div>
								<div class="outside-icon-value" v-if="getFurnaceTemperatureData.layoutOverview.values.checkerTempS !== '-'">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters-white.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.checkerTempS | currency }}</div>
								</div>
							</div>
							<div class="value-box horizontal regenerator-n regenerator-width" v-if="getFurnaceTemperatureData.layoutOverview.values.regeneratorN !== '-'">  <!--REGEN_N-->
								<div class="tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.regenN"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters-white.svg">
									<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.regeneratorN | currency }}</div>
								</div>
							</div>
							<div class="value-box horizontal regenerator-s regenerator-width" v-if="getFurnaceTemperatureData.layoutOverview.values.regeneratorS !== '-'">  <!--REGEN_S-->
								<div class="tag-name"><span class="white" v-html="getFurnaceTemperatureData.layoutOverview.layoutName.regenS"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters-white.svg">
									<div class="value color-orange-n-white-bg">{{ getFurnaceTemperatureData.layoutOverview.values.regeneratorS | currency }}</div>
								</div>
							</div>
							<div class="value-box vertical stack-pressure-n" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryTempN !== '-' || getFurnaceTemperatureData.layoutOverview.values.secondaryPressureN !== '-'">  <!--SECONDARY_PRESSURE_TEMP_N-->
								<div class="outside-icon-value" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryTempN !== '-'">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters.svg">
									<div class="value hopper-bg">{{ getFurnaceTemperatureData.layoutOverview.values.secondaryTempN | currency }}</div>
								</div>
								<div class="outside-icon-value" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryPressureN !== '-'">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/gauge-high-purple.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.secondaryPressureN | twoDecimalComma }}</div>
								</div>
							</div>
							<div class="value-box vertical stack-pressure-s" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryTempS !== '-' || getFurnaceTemperatureData.layoutOverview.values.secondaryPressureS !== '-'">  <!--SECONDARY_PRESSURE_TEMP_S-->
								<div class="outside-icon-value" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryTempS !== '-'">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters.svg">
									<div class="value hopper-bg">{{ getFurnaceTemperatureData.layoutOverview.values.secondaryTempS | currency }}</div>
								</div>
								<div class="outside-icon-value" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryPressureS !== '-'">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/gauge-high-purple.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.secondaryPressureS | twoDecimalComma }}</div>
								</div>
							</div>
							<div class="value-box vertical regenerator-temp-s" v-if="getFurnaceTemperatureData.layoutOverview.values.regeneratorTempS !== '-'">  <!--TEMP REG _S-->
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters.svg">
									<div class="value hopper-bg">{{ getFurnaceTemperatureData.layoutOverview.values.regeneratorTempS | currency }}</div>
								</div>
								<div class="tag-name long-tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.tempregS"></span></div>
							</div>
							<div class="value-box vertical second-pressure-tag" v-if="getFurnaceTemperatureData.layoutOverview.values.secondaryPressure !== '-'">  <!--Stack Secondary Pressure-->
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-medium" src="~@/assets/images/gauge-high-purple.svg">
									<div class="value purple-bg">{{ getFurnaceTemperatureData.layoutOverview.values.secondaryPressure | twoDecimalComma }}</div>
								</div>
								<div class="tag-name long-tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.secondaryPressureS"></span></div>
							</div>
							<div class="value-box vertical automatic" v-if="getFurnaceTemperatureData.layoutOverview.values.automatic !== '-'">  <!--AUTOMATIC-->
								<div class="tag-name"><span v-html="getFurnaceTemperatureData.layoutOverview.layoutName.automatic"></span></div>
								<div class="outside-icon-value">
									<img class="outside-icon-value-image width-small" src="~@/assets/images/temperature-three-quarters.svg">
									<div class="value hopper-bg">{{ getFurnaceTemperatureData.layoutOverview.values.automatic | currency }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<BaseNoData
					v-else
					description="Data unavailable"
				/>
			</template>
		</BaseCard>

	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
	name: "FurnaceOverview",

	components: {
		BaseCard,
		BaseLoading,
		BaseNoData
	},

	data() {
		return {
			furnaceId: this.$route.params.id,
			hasOverview: true
		};
	},

	computed: {
		...mapState(["furnaceTemperature"]),
		...mapGetters("furnaceTemperature", {
			getFurnaceTemperatureData: "getFurnaceTemperatureData"
		})
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("furnaceTemperature", {
			fetchFurnaceTemperature: "fetchFurnaceTemperature"
		}),

		fetchData() {
			return Promise.all([
				this.fetchFurnaceTemperature({ id: this.furnaceId })
			]);
		}
	}
};
</script>

<style lang="scss" scoped>
.furnace-overview {
	.body-furnace-image {
		display: flex;
		justify-content: center;

		.furnace-image-wrapper {
			position: relative;
			max-width: 100%;
			overflow-x: auto;

			.furnace-image {
				width: rem(1080);
				max-width: rem(1080);
			}

			.overlay-box {
				position: absolute;
				top: 0;

				width: 100%;
				height: 100%;

				font-size: $font-14;
				font-weight: bold;
				color: $color-white;

				.value-box {
					position: absolute;
					transform: translate(-50%, -50%);
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}

				.white {
					color: $color-white;
				}

				.horizontal {
					flex-direction: row;
				}

				.vertical {
					flex-direction: column;
				}

				.non-transform {
					transform: none;
				}

				.absolute {
					position: absolute;
					transform: translate(-50%, -50%);
				}

				.values-box {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					align-items: center;

					.sequence-value-box {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;
					}
				}

				.melters {
					left: rem(490);
					top: rem(286);
					height: rem(90);
					justify-content: space-evenly;
				}

				.width-melters {
					width: rem(216);
				}

				.width-refiners {
					width: rem(180);
				}

				.tag-name {
					line-height: rem(14);
					color: $color-black;
				}

				.long-tag-name {
					width: 120px;
					text-align: center;
				}

				.outside-icon-value {
					position: relative;

					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding-left: 4px;
					padding-bottom: 4px;

					line-height: rem(14);

					&-image {  //.outside-icon-value-image
						margin-right: 4px;
					}

					.width-medium {
						width: rem(16);
					}

					.width-small {
						width: $font-10;
					}
				}

				.thermometer {
					position: relative;

					display: flex;
					flex-direction: row;
					padding-bottom: 4px;

					line-height: rem(14);
					color: $color-black;

					&-image { //.thermometer-image
						width: rem(10);
					}

					.width-medium {
						width: rem(16);
					}

					&-name { //.thermometer-name
						position: absolute;
						left: rem(12);
						top: 2px;

						display: flex;
						align-items: center;
						color: $color-white;
					}
				}

				.value {
					height: rem(16);
					padding-top: 2px;
					padding-right: 4px;
					padding-left: 4px;

					line-height: rem(14);

					border-radius: rem(8);
					font-family: $font-family-sub2;
					font-size: $font-10;
				}

				.glass-level {
					left: rem(84);
					top: rem(334);
				}

				.glass-level-bg {
					background-color: rgb(24, 167, 55);
				}

				.refiner-pressure {
					left: rem(142);
					top: rem(224);
				}

				.color-azure-bg {
					background-color: $color-azure;
				}

				.refiner {
					left: rem(226);
					top: rem(340);
				}

				.refiner-group {
					left: rem(228);
					top: rem(286);
				}

				.color-orange-n-white-bg {
					margin: 2px;
					background-color: rgb(255, 255, 255);
					color: $color-pastel-orange;
				}

				.ng-refiner {
					left: rem(294);
					top: rem(216);
				}

				.melter-pressure {
					left: rem(388);
					top: rem(200);
				}

				.ng-melter {
					left: rem(492);
					top: rem(180);
				}

				.green-bg {
					background-color: $color-emerald;
				}

				.purple-bg {
					background-color: rgb(182, 3, 206);
				}

				.melter-tag-n {
					left: rem(436);
					top: rem(226);
				}

				.melter-tag-s {
					left: rem(436);
					top: rem(334);
				}

				.checker-tag-n {
					left: rem(632);
					top: rem(206);
				}

				.checker-tag-s {
					left: rem(632);
					top: rem(342);
				}

				.second-pressure-tag-n {
					left: rem(888);
					top: rem(174);
				}

				.second-pressure-tag-s {
					left: rem(888);
					top: rem(376);
				}

				.second-pressure-tag {
					left: rem(922);
					top: rem(435);
				}

				.overlay-tag-name {
					background-color: rgba(0, 0, 0, 0.35);
					border-radius: 4px;
					width: rem(58);
					height: rem(28);
					font-size: $font-10;
					text-align: center;
				}

				.hopper-width {
					width: rem(80);
				}

				.hopper1 {
					left: rem(570);
					top: rem(176);
				}

				.hopper2 {
					left: rem(570);
					top: rem(404);
				}

				.hopper-bg {
					background-color: rgb(230, 151, 5);
				}

				.check-temp-n {
					left: rem(658);
					top: rem(254);
				}

				.check-temp-s {
					left: rem(658);
					top: rem(324);
				}

				.regenerator-width {
					width: rem(124);
					height: rem(50);
				}

				.regenerator-n {
					left: rem(780);
					top: rem(240);
				}

				.regenerator-s {
					left: rem(780);
					top: rem(334);
				}

				.regenerator-temp-s {
					left: rem(776);
					top: rem(435);
				}

				.stack-pressure-n {
					left: rem(914);
					top: rem(236);
				}

				.stack-pressure-s {
					left: rem(914);
					top: rem(344);
				}

				.automatic {
					left: rem(1032);
					top: rem(298);
				}
			}
		}
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}
</style>