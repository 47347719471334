import { getFurnaceInfoAPI } from "@/services/api/furnaces.api";
import {
	FURNACE_INFO_REQUEST,
	AUTO_FURNACE_INFO_REQUEST,
	FURNACE_INFO_SUCCESS,
	AUTO_FURNACE_INFO_SUCCESS,
	FURNACE_INFO_FAILURE,
	FURNACE_INFO_CLEAR
} from "../../types";
import { parseFurnaceInfo } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	getters: {
		getLayout(state) {
			return state.data.layout;
		}
	},

	mutations: {
		[FURNACE_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[AUTO_FURNACE_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[FURNACE_INFO_SUCCESS](state, { data, status }) {
			state.data = parseFurnaceInfo(data);
			state.isLoading = false;
			state.statusCode = status;
		},
		[AUTO_FURNACE_INFO_SUCCESS](state, { data, status }) {
			state.data = parseFurnaceInfo(data);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[FURNACE_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[FURNACE_INFO_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async fetchFurnaceInfo({ commit }, id) {
			try {
				commit(FURNACE_INFO_REQUEST);
				const response = await getFurnaceInfoAPI(id);
				commit(FURNACE_INFO_SUCCESS, response);
			} catch (error) {
				commit(FURNACE_INFO_FAILURE, error);
			}
		},

		async autoFetchFurnaceInfo({ commit }, id) {
			try {
				commit(AUTO_FURNACE_INFO_REQUEST);
				const response = await getFurnaceInfoAPI(id);
				commit(FURNACE_INFO_SUCCESS, response);
			} catch (error) {
				commit(FURNACE_INFO_FAILURE, error);
			}
		},

		clearFurnaceInfo({ commit }) {
			commit(FURNACE_INFO_CLEAR);
		}
	}
};
