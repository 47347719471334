import { getCerHistoryAPI } from "../../../services/api/Cers.api";
import { parseCerHistory } from "../../../services/api/transforms/parseModules";

import {
	CER_HISTORY_REQUEST,
	CER_HISTORY_SUCCESS,
	CER_HISTORY_FAILURE,
	CER_HISTORY_CLEAR
} from "../../types";


export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[CER_HISTORY_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.statusCode = null;
		},
		[CER_HISTORY_SUCCESS](state, { data: { data, pagination }, status }) {
			state.data = parseCerHistory(data);
			state.pagination = pagination;
			state.isLoading = false;
			state.statusCode = status;
			state.isEmpty = data.length < 1;
		},
		[CER_HISTORY_FAILURE](state, error) {
			state.data = [];
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[CER_HISTORY_CLEAR](state) {
			state.data = [];
			state.isLoading = false;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		}
	},

	actions: {
		async getCerHistory({ commit }, { id, page }) {
			try {
				commit(CER_HISTORY_REQUEST);

				const response = await getCerHistoryAPI(id, { page });

				commit(CER_HISTORY_SUCCESS, response);
			} catch (error) {
				commit(CER_HISTORY_FAILURE, error);
			}
		},
		clearCerHistory({ commit }) {
			commit(CER_HISTORY_CLEAR);
		}
	}
};
