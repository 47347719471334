<template>
	<BaseLoading v-if="loading"></BaseLoading>
	<div v-else class="wrapper-meter-daily-consumption">
		<div class="wrapper-meter-daily-consumption-search-filter">
			<div class="wrapper-sort-by">
				<BaseSortBy
					:value="sortByIndex"
					:options="optionSort"
					@change="handleChangeSortBy"
				/>
			</div>
			<div v-if="permissions.dashboard.exportable" class="wrapper-input-search">
				<BaseDatePicker
					v-model="dailyPickerValue"
					:min-date="minDate"
					:max-date="maxDate"
					mode="range"
					size="small"
					position="right"
					class="custom-date-picker"
					@done="handleSearchRange"
				/>
				<p class="label-middle-input">Or</p>
				<div class="wrapper-custom-date-dropdown">
					<BaseDropdown
						class="custom-date-dropdown"
						:value="filter"
						:list="dailyDropdownValue"
						:searchable="false"
						size="small"
						placeholder="View by"
						@input="handleSearchDropdown"
					/>
				</div>
				<div class="wrapper-export-daily-consumption">
					<font-awesome-icon  class="csv-icon" :icon="['far', 'file-excel']"/>
					<a class="btn-link export-link" :href="getExportLink">Export as Excel</a>
				</div>
			</div>
		</div>
		<EmptyOrderHistory v-if="isEmpty" class="wrapper-empty">
			<template #head>
				<span>No consumption on this period</span>
			</template>
			<template #body>
				<span>Your consumption will appear here once  there is a record.</span>
			</template>
		</EmptyOrderHistory>
		<div v-else class="daily-consumption-table">
			<div class="daily-consumption-table-header">
				<div class="daily-consumption-table-header-column date">
					Date
				</div>
				<template v-for="(header,index) in headerColumn">
					<div :key="`${header}-${index}`" class="daily-consumption-table-header-column">
						{{header | capitalize}}
						<template v-if="header">
							(Nm<sup>3</sup>)
						</template>
					</div>
				</template>
				<div class="daily-consumption-table-header-column total">
					Total (Nm<sup>3</sup>)
				</div>
			</div>
			<template v-for="(object, objectIndex) in data">
				<div :key="`body-${objectIndex}`" :class="['daily-consumption-table-body', (objectIndex + 1) % 2 === 0 ? 'even' :'']">
					<div class="daily-consumption-table-body-column date">
						<div class="date-value">
							{{ object['date'] | dateFormat }}
						</div>
						<div class="counter-value">
							Counter:
						</div>
						<div class="volume-value">
							Volumn:
						</div>
					</div>
					<template v-for="(body, bodyIndex) in bodyColumn">
						<div :key="`${objectIndex}-${bodyIndex}`" class="daily-consumption-table-body-column">
							<template v-for="(key, keyIndex) in body">
								<div  v-if="keyIndex === 0" :key="`${key}-${keyIndex}`" class="counter-value">
									{{ object[key] | currency}}
								</div>
								<div  v-if="keyIndex === 1" :key="`${key}-${keyIndex}`" class="volume-value value">
									{{ object[key] | nagativeCurrencyWithNoTApplicable}}
								</div>
							</template>
						</div>
					</template>
					<div class="daily-consumption-table-body-column total">
						<div class="total-value">
							{{ object['totalConsumption'] | currency }}
						</div>
					</div>
				</div>
			</template>
			<BasePagination
				v-if="pagination.lastPage > 1"
				:value="pagination.currentPage"
				:totalPage="pagination.lastPage"
				class="module-pagination"
				@onPageChange="handleChangePage"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import BaseSortBy from "@/components/BaseSortBy.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BasePagination from "@/components/BasePagination.vue";
import { getDailyConsumptionAPI } from "@/services/api/meters.api";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import { cleanParams, cloneDeep } from "../../selectors/helpers";
import { getHeaderDailyConsumption, getKeyBodyDataDailyConsumption } from "../../selectors/transform/meterDailyConsumption";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import userPermission from "../../mixins/userPermission";

const { perThirtyDays, perThreeMonth, perSixMonth, perYear } = MODULE_SORT_DATE_CHART;

export default {
	components: {
		BaseDatePicker,
		BaseSortBy,
		BaseLoading,
		BasePagination,
		EmptyOrderHistory
	},

	mixins: [userPermission],

	data() {
		return {
			dailyPickerValue: this.$route.query.start && this.$route.query.end ? {
				start: dayjs(this.$route.query.start).format(),
				end: dayjs(this.$route.query.end).format()
			} : null,
			filter: this.$route.query.start && this.$route.query.end ? null : this.$route.query.filter || "30d",
			sortBy: this.$route.query.sort || null,
			query: {
				filter: "30d"
			},
			id: this.$route.params.id,
			data: [],
			pagination: null,
			loading: true,
			currentPage: this.$route.query.page || 1,
			sortByIndex: 1
		};
	},

	created() {
		this.fetchData();
	},

	methods: {
		...mapActions("activityLogAdd", {
			addActivityLogDailyConsumptionView: "addActivityLogDailyConsumptionView"
		}),
		fetchData() {
			try {
				const id = this.$route.params.id;
				const sortByDateRange = (this.dailyPickerValue !== null) ? {
					startDate: dayjs(this.dailyPickerValue.start).startOf("day").format(),
					endDate: dayjs(this.dailyPickerValue.end).endOf("day").format()
				} : {};
				this.query = {
					...sortByDateRange,
					filter: this.filter,
					page: this.currentPage,
					...(this.sortBy ? {
						sort: this.sortBy
					} : {})
				};
				getDailyConsumptionAPI(id, this.query)
					.then((response) => {
						const { data: { data = [], pagination = {} } } = response;
						this.sortByIndex = Number(this.$route.query.sortByIndex) || 0;
						this.pagination = pagination;
						this.data = data;
						this.loading = false;
						this.addActivityLogDailyConsumptionView({
							shopToId: this.id,
							httpStatus: response.status
						});
						this.$emit("loaded");
					})
					.catch((error) => {
						this.loading = false;
						this.addActivityLogDailyConsumptionView({
							shopToId: this.id,
							httpStatus: error.response.status
						});
						this.$emit("loaded");
					});
				return true;
			} catch (err) {
				this.loading = false;
				this.addActivityLogDailyConsumptionView({
					shopToId: this.id,
					httpStatus: 500
				});
				return err;
			}
		},

		handleSearchRange(rageValue = {}) {
			this.dailyPickerValue = rageValue;
			this.filter = null;
			this.currentPage = 1;
			this.redirectPage();
		},

		handleSearchDropdown(selectedValue) {
			this.filter = selectedValue;
			this.dailyPickerValue = null;
			this.currentPage = 1;
			this.redirectPage();
		},

		handleChangeSortBy(sortByValue) {
			this.sortByIndex = sortByValue.index;
			this.sortBy = sortByValue.code;
			this.currentPage = 1;
			this.redirectPage();
		},

		handleChangePage(page) {
			this.currentPage = page;
			this.redirectPage();
		},

		redirectPage() {
			const dateRange = (this.dailyPickerValue !== null) ? {
				start: dayjs(this.dailyPickerValue.start).startOf("day").format(),
				end: dayjs(this.dailyPickerValue.end).endOf("day").format()
			} : {};

			this.query = {
				...dateRange,
				filter: this.filter,
				page: this.currentPage,
				...(this.sortBy ? {
					sort: this.sortBy
				} : {}),
				sortByIndex: this.sortByIndex
			};
			this.$router.push({
				...this.name,
				query: this.query
			});
		}
	},
	computed: {
		...mapState(["meterConsumption"]),
		getExportLink() {
			const exportURIExcel = new URL(`${process.env.VUE_APP_BASE_API_URL}/meters/${this.id}/export-daily-consumptions`);

			const cloneQuery = cloneDeep({
				...this.query,
				...(
					this.dailyPickerValue ? {
						startDate: dayjs(this.dailyPickerValue.start).startOf("day"),
						endDate: dayjs(this.dailyPickerValue.end).endOf("day")
					} : {}
				),
				date: null
			});
			const { filter, sort, startDate, endDate } = cleanParams(cloneQuery);

			if (filter) {
				exportURIExcel.searchParams.append("filter", filter);
			}

			if (sort) {
				exportURIExcel.searchParams.append("sort", sort);
			}

			if (startDate) {
				exportURIExcel.searchParams.append("startDate", startDate);
			}
			if (endDate) {
				exportURIExcel.searchParams.append("endDate", endDate);
			}

			return exportURIExcel;
		},

		isEmpty() {
			return this.data.length === 0;
		},
		headerColumn() {
			const result = getHeaderDailyConsumption(this.data);
			return result;
		},

		bodyColumn() {
			return getKeyBodyDataDailyConsumption(this.headerColumn);
		},


		optionSort() {
			return {
				title: "Sort by",
				defaultAtIndex: this.sortByIndex,
				dropdown: [
					{
						index: 0,
						code: "date:asc",
						text: "Oldest"
					},
					{
						index: 1,
						code: "date:desc",
						text: "Newest"
					}
				],
				style: {
					position: "left",
					width: "150px"
				}
			};
		},

		maxDate() {
			return dayjs().subtract(1, "day").$d;
		},

		minDate() {
			return dayjs().subtract(1, "year").$d;
		},

		dailyDropdownValue() {
			return [
				{
					label: "Last 1 month",
					value: perThirtyDays
				},
				{
					label: "Last 3 months",
					value: perThreeMonth
				},
				{
					label: "Last 6 months",
					value: perSixMonth
				},
				{
					label: "Last 1 year",
					value: perYear
				}
			];
		}
	}
};
</script>

<style lang="scss" scoped>
.wrapper-meter-daily-consumption {
	.wrapper-empty {
		margin-top: rem(80);
	}

	.wrapper-sort-by {
		@include mobile {
			margin-bottom: rem(16);
		}
	}

	&-search-filter {
		display: flex;
		justify-content: space-between;

		@include mobile {
			background-color: $color-alabaster;
			flex-direction: column;
			margin: 0 rem(-16);
			padding: rem(24) rem(16);
		}

		.wrapper-input-search {
			display: flex;

			@include mobile {
				flex-direction: column;
				width: 100%;
			}
		}


		.label-middle-input {
			color: $color-grey-4;
			display: flex;
			align-items: center;
			margin: 0 rem(16);

			@include mobile {
				display: none;
			}
		}
	}

	.export-link {
		font-size: rem(14);
	}

	.custom-date-picker {
		min-width: rem(212);
		font-size: rem(16);
		/* stylelint-disable */
		&::v-deep .input {
			border-color: $color-silver;

			&, &::placeholder {
				color: $color-grey-4;
				font-size: $font-16;
				font-weight: $font-weight-bold;
				padding-top: rem(2);
			}
		}
		/* stylelint-enable */

		@include mobile {
			margin-bottom: rem(16);
			width: 100%;
		}
	}

	.wrapper-custom-date-dropdown {
		min-width: rem(148);
		margin-right: rem(24);

		@include mobile {
			margin-bottom: rem(16);
			margin-right: 0;
		}

		.custom-date-dropdown {
			width: auto;

			@include mobile {
				width: 100%;
			}

			/* stylelint-disable */
			&::v-deep .dropdown-value {
				border-color: $color-silver;
				background-color: $color-white;
			}

			&::v-deep .dropdown-value-text, &::v-deep .dropdown-placeholder {
				color: $color-grey-4;
				font-size: $font-16;
				font-weight: $font-weight-bold;
			}
			/* stylelint-enable */
		}
	}

	.wrapper-export-daily-consumption {
		display: flex;
		align-items: center;

		.csv-icon {
			margin-right: rem(8);
			font-size: rem(16);
			color: $color-cerulean;
			margin-top: rem(-4);
		}
	}

	.daily-consumption-table {
		width: 100%;
		margin-top: rem(32);

		&-header {
			@include fontBase();

			display: flex;
			align-items: center;
			padding: 0 rem(16) rem(14);
			border-bottom: 1px solid $color-emerald;
			font-size: rem(18);
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;

			&-column {
				width: calc(100% - 190px / 6);
				text-align: right;
				margin: rem(-4) rem(6) 0;

				&.date {
					text-align: left;
					margin: 0;
				}

				&.total {
					text-align: right;
				}
			}
		}

		&-body {
			@include ellipsisOneline;

			display: flex;
			align-items: center;
			padding: rem(16);

			&.even {
				background: $color-white-smoke;
			}

			&-column {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				height: rem(82);
				width: calc(100% - 190px / 6);
				text-align: right;
				margin: 0 rem(6);

				.date-value {
					@include fontBase();

					color: $color-dark-blue-grey;
					font-size: rem(20);
					font-weight: $font-weight-bold;
				}

				.volume-value {
					font-size: rem(18);
					color: $color-dark-blue-grey;

					&.value {
						font-weight: $font-weight-bold;
					}
				}

				.total-value {
					font-size: rem(24);
					color: $color-dark-blue-grey;
					font-weight: $font-weight-bold;
					margin: rem(-4);
				}

				.counter-value {
					@include fontBase();

					font-size: rem(18);
					color: $color-grey-4;
					margin: rem(4) 0;
				}

				&.date {
					text-align: left;
					margin: 0;
				}

				&.total {
					text-align: right;
					margin: 0;
				}
			}
		}
	}
}
</style>
