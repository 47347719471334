<template>
	<div
		ref="container-scrollbar"
		:class="['container-scrollbar', { 'scrolling-x': stateUI.holdingClickScrollbarX, 'scrolling-y': stateUI.holdingClickScrollbarY }]"
		:style="{ width: `${screenWidth}px`, height: `${screenHeight}px` }"
	>
		<slot></slot>
		<div class="scroll-x">
			<span class="rect"></span>
		</div>
		<div class="scroll-y">
			<span class="rect"></span>
		</div>
		<div class="control">
			<button class="zoom-btn" @click="onZoomIn">+</button>
			<span class="zoom-scale" @click="toggleZoomList">{{ Math.round(scaleX * 100) }}%</span>
			<button class="zoom-btn" @click="onZoomOut">-</button>
			<ul v-if="showZoomList" class="dropdown" v-click-outside="onClickOutSideDropdown">
				<li
					v-for="(scale, index) in zoomList"
					:key="index"
					:class="['item', { active: Math.round(scaleX * 100) === Math.round(scale * 100) }]"
					@click="zoomTo(scale)"
				>
					{{ Math.round(scale * 100) }}%
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
/* eslint-disable */

import {
	hideBodyScrollbar,
	autoBodyScrollbar
} from "../selectors/helpers/dom";

export default {
	props: ['viewportClass', 'screenWidth', 'screenHeight'],

	inheritAttrs: false,

	data() {
		return {
			observer: null,
			scaleX: 1,
			scaleY: 1,
			translateX: 0,
			translateY: 0,
			scrollX: 0,
			scrollY: 0,
			offsetLeftMin: 0,
			offsetLeftMax: 0,
			offsetTopMin: 0,
			offsetTopMax: 0,
			zoomList: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
			showZoomList: false,
			stateUI: {
				holdingClickScrollbarX: false,
				holdingClickScrollbarY: false,
			},
		}
	},

	mounted() {
		const container = this.$refs['container-scrollbar'];

		this.observer = new MutationObserver(this.handleDomsChange);
		this.observer.observe(container, {
			attributes: true,
			childList: true,
			subtree: true,
		});

		container.addEventListener('wheel', this.handleWheel);
		container.querySelector('.scroll-x .rect').addEventListener('mousedown', this.handleMouseDownScrollX);
		container.querySelector('.scroll-y .rect').addEventListener('mousedown', this.handleMouseDownScrollY);
		window.addEventListener('mouseup', this.handleMouseUp);
		window.addEventListener('mousemove', this.handleMouseMove);

		const isIos = [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

		if(isIos) {
			const start = { x: 0, y: 0 };
			const delta = { x: 0, y: 0 };
			
			container.addEventListener("touchstart", (event) => {
					hideBodyScrollbar();
					start.x = event.touches[0].clientX;
					start.y = event.touches[0].clientY;
				},
				false
			);

			container.addEventListener("touchmove", (event) => {
					delta.x = start.x - event.changedTouches[0].clientX;
					delta.y = start.y - event.changedTouches[0].clientY;
					start.x = event.changedTouches[0].clientX;
					start.y = event.changedTouches[0].clientY;
					this.handleWheel({
						deltaX: delta.x,
						deltaY: delta.y,
					});
				},
			  	false
			);
			
			container.addEventListener("touchend", (event) => {
					delta.x = start.x - event.changedTouches[0].clientX;
					delta.y = start.y - event.changedTouches[0].clientY;
					this.handleWheel({
						deltaX: delta.x,
						deltaY: delta.y,
					});
					autoBodyScrollbar();
				},
				false
			);
		}

		this.$nextTick(() => this.updateViewportOffsetMin());
	},

	destroyed() {
		// this.observer.disconnect();
		// const container = this.$refs['container-scrollbar'];
		// container.removeEventListener('wheel', this.handleWheel);
		// container.querySelector('.scroll-x .rect').removeEventListener('mousedown', this.handleMouseDownScrollX);
		// container.querySelector('.scroll-y .rect').removeEventListener('mousedown', this.handleMouseDownScrollY);
		// window.removeEventListener('mouseup', this.handleMouseUp);
		// window.removeEventListener('mousemove', this.handleMouseMove);
	},

	methods: {
		update() {
			this.updateViewport();
			this.updateViewportOffsetMin();
			this.updateScrollbar();
		},
		updateViewport() {
			const container = this.$refs['container-scrollbar'];
			const viewport = container.querySelector(`.${this.viewportClass}`);
			const values = `${this.scaleX} 0 0 ${this.scaleY} ${this.translateX} ${this.translateY}`
			const syntax = `matrix(${values})`
			viewport.setAttribute('transform', syntax);
		},
		updateViewportOffsetMin() {
			const container = this.$refs['container-scrollbar'];
			const viewport = container.querySelector(`.${this.viewportClass}`);
			const containerLeft = container.getBoundingClientRect().left;
			const containerTop = container.getBoundingClientRect().top;
			const viewportLeft = viewport.getBoundingClientRect().left;
			const viewportTop = viewport.getBoundingClientRect().top;
			this.offsetLeftMin = viewportLeft - containerLeft - this.translateX;
			this.offsetTopMin = viewportTop - containerTop - this.translateY;
		},
		setViewportCenter() {
			const container = this.$refs['container-scrollbar'];
			const viewport = container.querySelector(`.${this.viewportClass}`);
			const containerLeft = container.getBoundingClientRect().left;
			const containerTop = container.getBoundingClientRect().top;
			const viewportLeft = viewport.getBoundingClientRect().left;
			const viewportTop = viewport.getBoundingClientRect().top;
			const viewportWidth = viewport.getBoundingClientRect().width;
			const viewportHeight = viewport.getBoundingClientRect().height;
			
			if(viewportWidth < this.screenWidth) {
				const centerX = ((this.screenWidth / 2) - (viewportLeft - containerLeft)) - (viewportWidth / 2);
				this.translateX += centerX;
			} else if((viewportLeft - containerLeft) + viewportWidth > this.screenWidth && this.translateX > 0) {
				const tailX = (viewportLeft - containerLeft) - (((viewportLeft - containerLeft) + viewportWidth) - this.screenWidth)
				this.translateX = tailX;
			}

			if(viewportHeight < this.screenHeight) {
				const centerY = ((this.screenHeight / 2) - (viewportTop - containerTop)) - (viewportHeight / 2);
				this.translateY += centerY;
			} else if(this.translateY > Math.abs(this.offsetTopMin) || this.offsetTopMin < 0) {
				this.translateY = Math.abs(this.offsetTopMin);
			}
		},
		updateScrollbar() {
			const container = this.$refs['container-scrollbar'];
			const viewport = container.querySelector(`.${this.viewportClass}`);
			const viewportWidth = viewport.getBoundingClientRect().width;
			const viewportHeight = viewport.getBoundingClientRect().height;
			const scrollX = container.querySelector('.scroll-x');
			const scrollY = container.querySelector('.scroll-y');
			const scrollXRect = scrollX.querySelector('.rect');
			const scrollYRect = scrollY.querySelector('.rect');
			const scrollbarXLongSize = (() => {
				const offsetLeftMax = viewportWidth - this.screenWidth;
				const offsetLeftPercent = offsetLeftMax * 100 / viewportWidth;
				const offsetLeftPx = offsetLeftPercent * this.screenWidth / 100
				const scrollbarXLongSize = this.screenWidth - offsetLeftPx;
				this.offsetLeftMax = offsetLeftMax;
				return scrollbarXLongSize;
			})();
			const scrollbarYLongSize = (() => {
				const offsetTopMax = viewportHeight - this.screenHeight;
				const offsetTopPercent = offsetTopMax * 100 / viewportHeight;
				const offsetTopPx = offsetTopPercent * this.screenHeight / 100
				const scrollbarYLongSize = this.screenHeight - offsetTopPx;
				this.offsetTopMax = offsetTopMax;
				return scrollbarYLongSize;
			})();
			const offsetLeftPx = (() => {
				const offsetLeftPercent = (Math.abs(this.translateX) * 100) / viewportWidth;
				let offsetLeftPx = offsetLeftPercent * this.screenWidth / 100;
				if(scrollbarXLongSize + offsetLeftPx > this.screenWidth) {
					offsetLeftPx -= (scrollbarXLongSize + offsetLeftPx) - this.screenWidth
				}
				if(this.translateX > this.offsetLeftMin) {
					offsetLeftPx = 0;
				}
				return offsetLeftPx;
			})();
			const offsetTopPx = (() => {
				const offsetTop = -(this.translateY - Math.abs(this.offsetTopMin));
				const offsetTopPercent = (offsetTop * 100) / viewportHeight;
				let offsetTopPx = offsetTopPercent * this.screenHeight / 100;
				if(scrollbarYLongSize + offsetTopPx > this.screenHeight) {
					offsetTopPx -= (scrollbarYLongSize + offsetTopPx) - this.screenHeight
				}
				if(offsetTopPx < 0) {
					offsetTopPx = 0
				}
				return offsetTopPx;
			})();

			this.scrollX = offsetLeftPx;
			this.scrollY = offsetTopPx;

			scrollXRect.style.width = `${scrollbarXLongSize}px`;
			scrollYRect.style.height = `${scrollbarYLongSize}px`;
			scrollXRect.style.left = `${offsetLeftPx}px`;
			scrollYRect.style.top = `${offsetTopPx}px`;

			if(viewportWidth > this.screenWidth) {
				scrollX.style.display = 'block';
			} else {
				scrollX.style.display = 'none';
			}
			if(viewportHeight > this.screenHeight) {
				scrollY.style.display = 'block';
			} else {
				scrollY.style.display = 'none';
			}
		},
		handleDomsChange(mutationsList) {
			for(const mutation of mutationsList) {
				if(mutation.type === 'childList') {
					this.setViewportCenter();
					this.update();
					return;
				}
			}
		},
		handleWheel(event) {
			// this.scaleX += event.deltaY * -0.01;
			// this.scaleX = Math.min(Math.max(.125, this.scaleX), 2);
			// this.scaleY = this.scaleX;

			const offsetMargin = 50;

			const container = this.$refs['container-scrollbar'];
			const viewport = container.querySelector(`.${this.viewportClass}`);
			const viewportWidth = viewport.getBoundingClientRect().width;
			const viewportHeight = viewport.getBoundingClientRect().height;

			const tempX = this.translateX;

			if(viewportWidth > this.screenWidth) {
				this.translateX -= event.deltaX;

				// if(Math.abs(this.translateX) > this.offsetLeftMax && this.translateX < 0) {
				// 	this.translateX = -this.offsetLeftMax;
				// } else if(this.translateX > 0) {
				// 	this.translateX = 0;
				// }

				if(this.translateX < 0 && Math.abs(this.translateX) > (this.offsetLeftMax + offsetMargin) && Math.abs(event.deltaX) !== 0) {
					this.translateX = -((this.offsetLeftMax + offsetMargin));
				}

				if(this.translateX > 0 && this.translateX > (this.offsetLeftMin + offsetMargin) && Math.abs(event.deltaX) !== 0) {
					this.translateX = this.offsetLeftMin + offsetMargin;
				}
			}

			const tempY = this.translateY;

			if(viewportHeight > this.screenHeight) {
				this.translateY -= event.deltaY;

				// if(this.translateY < Math.abs(this.offsetTopMin) - this.offsetTopMax) {
				// 	this.translateY = -(this.offsetTopMax - Math.abs(this.offsetTopMin));
				// } else if(this.translateY > Math.abs(this.offsetTopMin)) {
				// 	this.translateY = Math.abs(this.offsetTopMin);
				// }

				if(this.translateY < 0) {
					if(this.offsetTopMin - offsetMargin > Math.abs(this.translateY) && Math.abs(event.deltaY) !== 0) {
						this.translateY = -(this.offsetTopMin) + offsetMargin;
					}
					if(Math.abs(this.translateY) > (this.offsetTopMin + this.offsetTopMax + offsetMargin) && Math.abs(event.deltaY) !== 0) {
						this.translateY = -((this.offsetTopMin + this.offsetTopMax + offsetMargin));
					}
				} else {
					if(this.translateY > (Math.abs(this.offsetTopMin) + offsetMargin)) {
						this.translateY = Math.abs(this.offsetTopMin) + offsetMargin;
					}
				}
			}

			if(tempX !== this.translateX) {
				if(event.preventDefault) {
					event.preventDefault();
				}
			}

			if(tempY !== this.translateY) {
				if(event.preventDefault) {
					event.preventDefault();
				}
			}

			this.update();
		},
		setZoom(scaleX, scaleY) {
			const container = this.$refs['container-scrollbar'];
			const viewport = container.querySelector(`.${this.viewportClass}`);
			const oldLeft = viewport.getBoundingClientRect().right;
			const oldTop = viewport.getBoundingClientRect().top;
			const oldX = this.translateX;
			const oldY = this.translateY;
			const newLeft = viewport.getBoundingClientRect().right;
			const newTop = viewport.getBoundingClientRect().top;
			const translateX = oldX + (oldLeft - newLeft);
			const translateY = oldY + (oldTop - newTop);

			this.scaleX = scaleX;
			this.scaleY = scaleY;
			this.update();
			
			this.translateX = translateX
			this.translateY = translateY
			this.update();

			this.setViewportCenter();
			this.update();
		},
		onZoomIn() {
			const maxZoom = this.zoomList[this.zoomList.length - 1];
			if(this.scaleX < maxZoom) {
				const scaleX = this.scaleX + 0.25
				const scaleY = this.scaleY + 0.25
				this.setZoom(scaleX, scaleY);
			}
			this.showZoomList = false;
		},
		onZoomOut() {
			const minZoom = this.zoomList[0];
			if(this.scaleX > minZoom) {
				const scaleX = this.scaleX - 0.25
				const scaleY = this.scaleY - 0.25
				this.setZoom(scaleX, scaleY);
			}
			this.showZoomList = false;
		},
		zoomTo(scale) {
			this.setZoom(scale, scale);
			this.showZoomList = false;
		},
		scrollXTo(value) {
			const translateX = this.offsetLeftMax * value / 100;
			this.translateX = -translateX;
			this.update();
		},
		scrollYTo(value) {
			// const translateY = ((-Math.abs(this.offsetTopMax) * value) / 100) + Math.abs(this.offsetTopMin);
			const translateY = ((this.offsetTopMax * (100 - value)) / 100) - (this.offsetTopMax + this.offsetTopMin);
			this.translateY = translateY;
			// this.update();
			this.updateViewport();
		},
		getScrollX(offsetLeft, offsetWidth) {
			const value = offsetLeft * 100 / (this.screenWidth - offsetWidth);
			return Math.min(Math.max(0, value), 100);
		},
		getScrollY(offsetTop, offsetHeight) {
			const value = offsetTop * 100 / (this.screenHeight - offsetHeight);
			return Math.min(Math.max(0, value), 100);
		},
		toggleZoomList() {
			this.showZoomList = !this.showZoomList;
		},
		handleMouseDownScrollX() {
			this.stateUI.holdingClickScrollbarX = true;
		},
		handleMouseDownScrollY() {
			this.stateUI.holdingClickScrollbarY = true;
		},
		handleMouseUp() {
			this.stateUI.holdingClickScrollbarX = false;
			this.stateUI.holdingClickScrollbarY = false;
		},
		handleMouseMove(event) {
			if(this.stateUI.holdingClickScrollbarX) {
				const container = this.$refs['container-scrollbar'];
				const scrollX = container.querySelector('.scroll-x');
				const scrollXRect = scrollX.querySelector('.rect');
				const movementX = event.movementX;
				const result = scrollXRect.offsetLeft + movementX;
				scrollXRect.style.left = `${result}px`;
				
				const value = this.getScrollX(scrollXRect.offsetLeft, scrollXRect.offsetWidth);
				this.scrollXTo(value);
			}

			if(this.stateUI.holdingClickScrollbarY) {
				const container = this.$refs['container-scrollbar'];
				const scrollY = container.querySelector('.scroll-y');
				const scrollYRect = scrollY.querySelector('.rect');
				const movementY = event.movementY;
				let result = scrollYRect.offsetTop + movementY;
				if(result < 0) {
					result = 0;
				} else if(result + scrollYRect.getBoundingClientRect().height > this.screenHeight) {
					result = this.screenHeight - scrollYRect.getBoundingClientRect().height;
				}
				scrollYRect.style.top = `${result}px`;
				
				const value = this.getScrollY(scrollYRect.offsetTop, scrollYRect.offsetHeight);
				this.scrollYTo(value);
			}
		},
		onClickOutSideDropdown() {
			this.showZoomList = false;
		},
	},
}
</script>

<style scoped>
.container-scrollbar {
	position: relative;
}

.container-scrollbar.scrolling-x,
.container-scrollbar.scrolling-y {
	user-select: none;
}

.scroll-x {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 8px;
	background-color: #f2f2f2;
	cursor: default;
}

.scroll-x .rect {
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 100%;
	background-color: #ccc;
	border-radius: 4px;
}

.scroll-y {
	position: absolute;
	top: 0;
	right: 0;
	width: 8px;
	height: 100%;
	background-color: #f2f2f2;
	cursor: default;
}

.scroll-y .rect {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 50px;
	background-color: #ccc;
	border-radius: 4px;
}

.scroll-x .rect:hover,
.scroll-y .rect:hover,
.container-scrollbar.scrolling-x .scroll-x .rect,
.container-scrollbar.scrolling-y .scroll-y .rect {
	background-color: rgb(155, 154, 154);
}

.control {
	position: absolute;
	bottom: 16px;
	display: flex;
	width: 104px;
	height: 24px;
	padding: 8px;
	background-color: #f9f9f9;
	border-radius: 8px;
	align-items: center;
}

.control .zoom-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 22px;
}

.control .zoom-scale {
	flex: 1;
	text-align: center;
	font-family: DBHeavent;
	font-weight: normal;
	font-size: 20px;
	color: #717171;
	cursor: pointer;
}

.control .dropdown {
	z-index: 1;
	position: absolute;
	top: -4px;
	left: 0;
	width: 100%;
	transform: translateY(-100%);
	background-color: #f9f9f9;
	margin: 0;
	padding: 0;
	border-radius: 8px;
}

.control .dropdown .item {
	margin: 0;
	padding: 0;
	list-style: none;
	height: 24px;
	font-family: TFSrivichai;
	font-size: 18px;
	text-align: center;
	color: #103332;
}

.control .dropdown .item.active,
.control .dropdown .item:hover {
	background-color: #009a44;
	color: #fff;
	border-radius: 4px;
	cursor: pointer;
}
</style>