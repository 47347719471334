<template>
	<div>
		<div v-if="powerBI.isLoading" class="loading-wrapper">
			<BaseLoading />
		</div>
		<PowerBI :data="powerBI" v-if="!powerBI.isLoading && !powerBI.isEmpty"></PowerBI>
		<BaseNoData
			v-if="!powerBI.isLoading && powerBI.isEmpty"
			class="graph"
			description="BIG portal will start showing the PowerBI <br/> right after we have information"
		/>
	</div>

</template>

<script>
import { mapState, mapActions } from "vuex";
import PowerBI from "@/components/PowerBI.vue";

export default {
	components: {
		PowerBI,
		BaseNoData: () => import("../components/BaseNoData.vue"),
		BaseLoading: () => import("../components/BaseLoading.vue")
	},
	data() {
		return {
			windowHeight: window.innerHeight,
			deviceId: this.$route.params.id
		};
	},
	async created() {
		await this.getPowerBIReport(this.deviceId);
		this.addActivityLogPowerBI({ deviceId: this.deviceId, status: 200 });
	},
	computed: {
		...mapState(["powerBI"])
	},
	methods: {
		...mapActions("powerBI", {
			getPowerBIReport: "getPowerBIReport"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogPowerBI: "addActivityLogPowerBI"
		})
	}
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: rem(318);
}
</style>