import {
	E_ORDERING_CREATE_SELECTED_DATES_CHANGE,
	E_ORDERING_CREATE_REMOVE_ORDER_SCHEDULE,
	E_ORDERING_CREATE_REMOVE_ORDER,
	E_ORDERING_CREATE_COUNT_SELECT_DATE,
	E_ORDERING_CREATE_RESET_SELECT_DATE
} from "../../../store/types";
import { ORDER } from "../../../enums/eOrdering/order";

const getInitialState = () => ({
	selectedDate: {},
	countSelectedDate: 0,
	isMaximumDate: false
});

export default {
	namespaced: true,

	state: getInitialState,

	getters: {
		countSelectedDateWithoutCurrent: (state) => (orderScheduleKey) => {
			const selectedDateWithoutCurrent = { ...state.selectedDate };

			delete selectedDateWithoutCurrent[orderScheduleKey];

			return Object.values(selectedDateWithoutCurrent).reduce((previous, current) => {
				return previous + current;
			}, 0);
		}
	},

	mutations: {
		[E_ORDERING_CREATE_SELECTED_DATES_CHANGE](state, { orderScheduleKey, dates }) {
			state.selectedDate[orderScheduleKey] = dates.length;
		},
		[E_ORDERING_CREATE_REMOVE_ORDER_SCHEDULE](state, { orderScheduleKey }) {
			state.selectedDate[orderScheduleKey] = 0;
		},
		[E_ORDERING_CREATE_REMOVE_ORDER](state, { orderIndex }) {
			const keysOfOrder = Object.keys(state.selectedDate)
				.filter((key) => key.startsWith(`order${orderIndex}`));

			keysOfOrder.forEach((keyOfOrder) => {
				state.selectedDate[keyOfOrder] = 0;
			});
		},
		[E_ORDERING_CREATE_COUNT_SELECT_DATE](state) {
			const countSelectedDate = Object.values(state.selectedDate).reduce((previous, current) => {
				return previous + current;
			}, 0);
			state.countSelectedDate = countSelectedDate;
			state.isMaximumDate = countSelectedDate === ORDER.MAXIMUM_CREATE_BATCH_DATE;
		},
		[E_ORDERING_CREATE_RESET_SELECT_DATE](state) {
			Object.assign(state, getInitialState());
		}
	},

	actions: {
		selectedDatesChange({ commit }, { orderScheduleKey, dates }) {
			commit(E_ORDERING_CREATE_SELECTED_DATES_CHANGE, {
				orderScheduleKey,
				dates
			});
			commit(E_ORDERING_CREATE_COUNT_SELECT_DATE);
		},

		removeOrderSchedule({ commit }, { orderScheduleKey }) {
			commit(E_ORDERING_CREATE_REMOVE_ORDER_SCHEDULE, {
				orderScheduleKey
			});
			commit(E_ORDERING_CREATE_COUNT_SELECT_DATE);
		},

		removeOrder({ commit }, { orderIndex }) {
			commit(E_ORDERING_CREATE_REMOVE_ORDER, {
				orderIndex
			});
			commit(E_ORDERING_CREATE_COUNT_SELECT_DATE);
		},

		resetSelectDate({ commit }) {
			commit(E_ORDERING_CREATE_RESET_SELECT_DATE);
		}
	}
};
