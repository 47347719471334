<template>
	<div class="no-gutter-top">
		<BaseLoading v-if="lngInfo.isLoading" />
		<div :class="['row row-eq-height', { 'hide-content': lngInfo.isLoading }]">
			<div class="col-12 no-gutter-sm col-xl-7">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ lngInfo.data.updatedAt}}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview :data="lngInfo.data" />
					</template>
				</BaseCard>
			</div>
			<ModuleLocation :info="lngInfo.data" />
		</div>

		<div class="tank-level-container">
			<div class="detail-card-header">
				<div v-if="showLastUpdate" class="last-update">
					Last update: {{ lngLevel.lastUpdate | dateTimeFormat  }}
				</div>
				<BaseDatePicker
					v-model="pickerValue"
					:min-date="lngLevelFilterOptions.date.picker.minDate"
					:max-date="lngLevelFilterOptions.date.picker.maxDate"
					:mode="lngLevelFilterOptions.date.picker.mode"
					size="small"
					position="right"
					class="custom-date-picker"
					@done="handleTankLevelSelectDateRange"
				/>
				<span>
					{{ lngLevelFilterOptions.date.condition || "or" }}
				</span>
				<BaseDropdown
					:value="dropdownValue"
					:list="lngLevelFilterOptions.date.dropdown.items"
					:disabledItem="lngLevelFilterOptions.date.dropdown.disabledItems"
					:searchable="false"
					size="small"
					:placeholder="`${lngLevelFilterOptions.date.dropdown.placeholder || 'View by'}`"
					class="custom-date-dropdown"
					@input="handleTankLevelSelectDateDropDown"
				/>
			</div>
			<CardLineChart
				:chartData="getLineChartConfig"
				:isLoading="lngLevel.isLoading"
				:isEmpty="lngLevel.isEmpty"
				:lastUpdate="lngLevel.lastUpdate"
				chartTitle="Tank level"
				type="tank"
			/>
			<CardBarChart
				:chartData="getMonthlyDataBarChart"
				:isLoading="lngMonthly.isLoading"
				:isEmpty="lngMonthly.isEmpty"
				:lastUpdate="lngMonthly.lastUpdate"
				chartTitle="Monthly delivery"
			/>
		</div>
		<GrafanaDisplay
			:deviceId="lngId"
		/>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import { transformTankLevelLineChartConfig } from "../selectors/transform/lineChart/TankInfo/tankLineChart";
import { MODULE_SORT_DATE_CHART } from "../enums/filterChart";
import { getDateNumber, getDateDiff } from "../selectors/helpers";
import { transformLngBarChart } from "../selectors/transform/barChart/lngInfo/lngBarChart";
import setupDevice from "../mixins/setupDevice";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export default {
	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		CardBarChart,
		CardLineChart,
		ModuleOverview,
		ModuleLocation,
		BaseDatePicker
	},
	mixins: [setupDevice],
	data() {
		return {
			lngId: this.$route.params.id,
			sortBy: perDay,
			pickerValue: null,
			dropdownValue: null,
			lngLevelFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 1 month",
								value: perThirtyDays
							},
							{
								label: "Last 3 months",
								value: perNinetyDays
							}
						]
					}
				}
			}
		};
	},

	computed: {
		...mapState(["lngInfo", "lngLevel", "lngMonthly"]),
		...mapGetters("lngLevel", {
			getLngLevelData: "getLngLevelData",
			getLngLevelStateData: "getLngLevelStateData"
		}),
		...mapGetters("lngMonthly", {
			getLngMonthlyData: "getLngMonthlyData"
		}),
		lngLevelSelected() {
			const dateRange = this.lngLevelFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},
		getLineChartConfig() {
			const sortBy = this.sortBy;
			const { diff } = this.lngLevelSelected;
			return transformTankLevelLineChartConfig(sortBy, this.getLngLevelData, {
				markLine: [
					{
						label: "Re-order",
						color: "#ffcb00",
						value: this.getLngLevelStateData.reorderLevel
					},
					{
						label: "Re-fill",
						color: "#ea7125",
						value: this.getLngLevelStateData.refillLevel
					},

					{
						label: "Empty",
						color: "#e03431",
						value: this.getLngLevelStateData.emptyLevel
					}],
				diffDay: diff
			}, this.isMobile);
		},
		getMonthlyDataBarChart() {
			return transformLngBarChart(this.getLngMonthlyData);
		},
		showLastUpdate() {
			return this.lngLevel.lastUpdate;
		}
	},

	async created() {
		await this.fetchData();

		const lngRangeDate = this.lngLevelFilterOptions.date.selected.range;

		if (lngRangeDate) {
			const dateNumber = getDateNumber(lngRangeDate);
			this.dropdownValue = dateNumber;
		}

		this.$emit("loaded", this.fetchData);
	},

	methods: {
		...mapActions("lngLevel", [
			"getLngLevel"
		]),
		...mapActions("lngMonthly", {
			getLngMonthly: "getLngMonthly"
		}),
		fetchData() {
			const { lngId } = this;
			return Promise.all([
				this.getLngLevel({ id: lngId, query: { filter: this.lngLevelSelected.dateNumber } }),
				this.getLngMonthly({ id: lngId })
			]);
		},
		handleTankLevelSelectDateRange(date) {
			const { start, end } = date;
			this.lngLevelFilterOptions.date.selected.range = {
				start,
				end
			};
			this.sortBy = "";
			this.getLngLevel({
				id: this.lngId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},
		handleTankLevelSelectDateDropDown(filterBy = "1d") {
			if (!this.lngLevelFilterOptions?.date.condition) {
				this.pickerValue = null;
			}
			this.dropdownValue = filterBy;
			this.sortBy = filterBy;
			this.getLngLevel({
				id: this.lngId,
				query: {
					filter: filterBy
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.row-eq-height,
.row-eq-height > [class^=col]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.card {
	width: 100%;
	height: calc(100% - rem(34));

	@include mobile {
		border-radius: 0;
	}
}

::v-deep {
	.overview-info:nth-child(2) {
		border-bottom: solid 1px $color-silver;
		margin-bottom: rem(12);
	}

	.tank-level-container {
		background-color: $color-alabaster;
		border-radius: rem(8);
		padding: rem(16);
		margin-bottom: rem(16);

		@include mobile {
			border-radius: 0;
			margin-left: rem(-16);
			margin-right: rem(-16);
			padding: rem(16) 0;
		}

		.card {
			margin-bottom: rem(16);

			@include mobile {
				border-radius: 0;
			}

			&-body {
				background-color: $color-white;
				border-bottom-left-radius: rem(4);
				border-bottom-right-radius: rem(4);
			}
		}
	}

	.detail-card-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: rem(16);

		@include mobile {
			align-items: flex-start;
			flex-direction: column;
			padding: 0 rem(16);
		}

		& > * {
			margin-left: rem(10);

			@include mobile {
				margin-left: 0;
			}
		}

		span {
			color: $color-grey-4;
		}

		> span {
			@include mobile {
				display: none;
			}
		}

		.custom-date-picker {
			@include mobile {
				margin-bottom: rem(12);
			}
		}
	}

	.last-update {
		min-width: rem(170);
		font-family: $font-family-base;
		font-size: rem(18);
		font-style: italic;
		color: $color-dark-blue-grey;
		margin-right: rem(14);

		@include mobile {
			margin-right: 0;
		}
	}

	.custom-date-picker {
		min-width: rem(200);

		@include mobile {
			width: 100%;
		}

		.input {
			border-color: $color-silver;

			&, &::placeholder {
				color: $color-grey-4;
				font-size: $font-16;
				font-weight: $font-weight-bold;
			}
		}
	}

	.custom-date-dropdown {
		width: rem(130);

		@include mobile {
			width: 100%;
		}

		.dropdown-value {
			border-color: $color-silver;
			background-color: $color-white;
			padding: 0 rem(40) 0 rem(16);
		}

		.dropdown-value-text,
		.dropdown-placeholder {
			color: $color-grey-4;
			font-size: $font-16;
			font-weight: $font-weight-bold;
		}
	}
}

.no-gutter-top {
	margin-top: rem(-1);

	@include desktop-large {
		margin-top: 0;
	}
}
</style>
