import { ROLES_LABEL, STAFF_ROLES } from "../../../enums/role";

export const getStaffRoles = () => {
	return Object.keys(ROLES_LABEL).map((key) => ({
		label: ROLES_LABEL[key],
		value: STAFF_ROLES[key]
	}));
};

export const getStaffRolesFilter = () => {
	return [
		{ label: "Role: All", value: null },
		...getStaffRoles()
	];
};