import { getNitroFasNitrogenFlowRateAPI } from "@/services/api/NitroFAS.api";
import { transformFlowRateLineChartData } from "../../../selectors/transform/lineChart/transformLineChart";
import {
	NITRO_FAS_NITROGEN_LINE_CHART_REQUEST,
	NITRO_FAS_NITROGEN_LINE_CHART_SUCCESS,
	NITRO_FAS_NITROGEN_LINE_CHART_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getTrasnformNitrogen: (state) => {
			return transformFlowRateLineChartData(state);
		}

	},

	mutations: {
		[NITRO_FAS_NITROGEN_LINE_CHART_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[NITRO_FAS_NITROGEN_LINE_CHART_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[NITRO_FAS_NITROGEN_LINE_CHART_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getNitrogenFlowRate({ commit }, { id, query = {} }) {
			try {
				commit(NITRO_FAS_NITROGEN_LINE_CHART_REQUEST);
				const { data } = await getNitroFasNitrogenFlowRateAPI(id, query);
				commit(NITRO_FAS_NITROGEN_LINE_CHART_SUCCESS, data);
			} catch (error) {
				commit(NITRO_FAS_NITROGEN_LINE_CHART_FAILURE, error);
			}
		}
	}
};
