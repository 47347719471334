<template>
	<div :class="['control', labelPosition]">
		<div class="label">
			<slot></slot>
		</div>
		<div class="form">
			<BaseFormInput
				v-model="input"
				:type="type"
				:inputOptions="inputOptions"
				:placeholder="placeholder"
				:is-error="isError"
				:error-message="errorMessage"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseFormInputLabel",

	props: {
		labelPosition: {
			type: String,
			default: "left",
			validator: (value) => [
				"top",
				"left"
			].includes(value)
		},
		inputOptions: {
			type: Array,
			default: () => ([])
		},
		placeholder: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "text"
		},
		isError: {
			type: Boolean,
			default: false
		},
		errorMessage: {
			type: String,
			default: ""
		}
	},

	data() {
		return {
			input: ""
		};
	},

	watch: {
		input(value) {
			this.$emit("input", value);
		}
	}
};
</script>

<style lang="scss" scoped>
.control {
	display: flex;
	width: 100%;

	&.left {
		flex-direction: row;
	}

	&.top {
		flex-direction: column;
	}

	.label {
		display: flex;
		align-items: center;
		min-width: rem(120);
		color: $color-dark-blue-grey;
		font-size: $font-18;
	}

	.form {
		flex-grow: 1;
	}
}
</style>