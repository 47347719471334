<template>
	<BaseError
		code="404"
		title="This page is not available"
		message="The page you are looking for might have been removed, had its name changed, or is temporarily unavailable."
	>
		<template v-slot:footer>
			<router-link
				:to="{ name: 'login'}"
				class="link"
			>
				<span>
					Go to BIG Portal homepage
				</span>
				<FontAwesomeIcon :icon="['far', 'chevron-circle-right']" />
			</router-link>
		</template>
	</BaseError>
</template>

<script>
import BaseError from "@/components/BaseError.vue";

export default {
	name: "page502",

	components: {
		BaseError
	}
};
</script>

<style lang="scss" scoped>
.link {
	display: flex;
	align-items: center;
	color: $color-cerulean;

	span {
		margin-right: $spacing-3;
	}
}
</style>