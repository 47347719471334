export const sortObject = (data, rest, sorting) => {
	if (rest.type === "date") {
		return data.sort((a, b) => {
			return sorting === "asc" ?
				new Date(a[rest.key]) - new Date(b[rest.key]) :
				new Date(b[rest.key]) - new Date(a[rest.key]);
		});
	}

	const sort = data.sort((a, b) => {
		const fa = a[rest.key].toLowerCase(),
			fb = b[rest.key].toLowerCase();

		if (fa < fb) {
			return -1;
		}
		if (fa > fb) {
			return 1;
		}
		return 0;
	});

	return sorting === "asc" ? sort : sort.reverse();
};