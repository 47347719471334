import dayjs from "dayjs";

import { getFurnaceTemperatureAPI, getFurnaceTemperatureChartListAPI } from "@/services/api/furnaces.api";
import { parseFurnaceTemperature, parseFurnaceTemperatureTagName } from "../../../services/api/transforms/parseModules";
import { dateTimeFormat } from "../../../selectors/helpers";
import {
	FURNACE_TEMPERATURE_CHART_REQUEST,
	FURNACE_TEMPERATURE_CHART_SUCCESS,
	FURNACE_TEMPERATURE_CHART_FAILURE,
	FURNACE_TEMPERATURE_CHART_LIST_REQUEST,
	FURNACE_TEMPERATURE_CHART_LIST_SUCCESS,
	FURNACE_TEMPERATURE_CHART_LIST_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: {},
		lastUpdate: "",
		timeUpdate: "",
		isLoading: false,
		isEmpty: false,
		isChartListLoading: false,
		chartList: [],
		keyNames: [],
		keyOfData: []
	},

	getters: {
		getFurnaceTemperatureChartData: (state) => {
			return {
				keys: state.keyNames,
				values: state.keyOfData.map((key) => state.data[key] || 0) || []
			};
		},

		getFurnaceTemperatureData: (state) => {
			return parseFurnaceTemperature(state.data);
		},

		getFurnaceTemperatureChartListFromDate: (state) => (selectedDate) => {
			if (!selectedDate) {
				return undefined;
			}
			const date = dayjs(selectedDate).format("YYYY-MM-DD");

			return state.chartList.find((data) => data.date === date)?.naturalGases ?? [];
		}
	},

	mutations: {
		[FURNACE_TEMPERATURE_CHART_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.lastUpdate = "";
			state.timeUpdate = "";
			state.isEmpty = false;
		},
		[FURNACE_TEMPERATURE_CHART_SUCCESS](state, response) {
			state.data = response;
			state.isEmpty = !response.timestamp;
			state.lastUpdate = dateTimeFormat(response.timestamp);
			state.timeUpdate = dateTimeFormat(response.timestamp, "HH:mm");
			state.isLoading = false;
		},
		[FURNACE_TEMPERATURE_CHART_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
			state.isEmpty = true;
			state.lastUpdate = "";
			state.timeUpdate = "";
		},
		[FURNACE_TEMPERATURE_CHART_LIST_REQUEST](state) {
			state.chartList = [];
			state.isChartListLoading = true;
			state.lastUpdate = "";
			state.timeUpdate = "";
			state.isEmpty = false;
		},
		[FURNACE_TEMPERATURE_CHART_LIST_SUCCESS](state, response) {
			state.chartList = response.data;
			const keyNameAndData = parseFurnaceTemperatureTagName(response.data);
			state.keyNames = keyNameAndData.keyNames;
			state.keyOfData = keyNameAndData.keyOfData;
			state.isChartListLoading = false;
		},
		[FURNACE_TEMPERATURE_CHART_LIST_FAILURE](state) {
			state.chartList = [];
			state.isChartListLoading = false;
		}
	},

	actions: {
		async fetchFurnaceTemperature({ commit }, { id = "", query = {} }) {
			try {
				commit(FURNACE_TEMPERATURE_CHART_REQUEST);

				const { data } = await getFurnaceTemperatureAPI(id, query);

				commit(FURNACE_TEMPERATURE_CHART_SUCCESS, data);
			} catch (error) {
				commit(FURNACE_TEMPERATURE_CHART_FAILURE);
			}
		},

		async fetchFurnaceTemperatureChartList({ commit }, { id = "" }) {
			try {
				commit(FURNACE_TEMPERATURE_CHART_LIST_REQUEST);

				const { data } = await getFurnaceTemperatureChartListAPI(id);

				commit(FURNACE_TEMPERATURE_CHART_LIST_SUCCESS, data);
			} catch (error) {
				commit(FURNACE_TEMPERATURE_CHART_LIST_FAILURE);
			}
		}
	}
};
