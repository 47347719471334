import httpClient from "./httpClient";

const ZLD_END_POINT = "/zlds";
const ZLD_VINY_THAI_END_POINT = "/zlds2";

const getZldInfoAPI = (id) => httpClient.get(`${ZLD_END_POINT}/${id}`);
const getZldLastMonthAPI = (id) => httpClient.get(`${ZLD_END_POINT}/${id}/last-months`);
const getZldRecycleWaterAPI = (id, params) => httpClient.get(`${ZLD_END_POINT}/${id}/recycle-waters`, { params });
const getOutletRecycleWaterAPI = (id, filter) => httpClient.get(`${ZLD_END_POINT}/${id}/outlets?filter=${filter}`);
const getElectricalConductivity = (id, params) => httpClient.get(`${ZLD_END_POINT}/${id}/electricals`, { params });
const getSilicaInRecycleWaterAPI = (id, params) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}/silicas`, { params });
// VinyThai API
const getZldVinyThaiInfoAPI = (id) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}`);
const getZldVinyThaiWaterFlowRate = (id, params) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}/water-flow-rates`, { params });
const getElectricalRecycleWaterAPI = (id, params) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}/electrical-recycles`, { params });
const getZldVinyThaiOrderHistory = (id, params = {}) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}/order-histories`, { params });

const getElectricalInletWaterAPI = (id, params) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}/electrical-inlets`, { params });
const getRecycleWaterProductionAPI = (id, params) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}/outlets`, { params });
const getZldVinyThaiConsumptionAPI = (id, params) => httpClient.get(`${ZLD_VINY_THAI_END_POINT}/${id}/consumption-approvals`, { params });
const putUpdateZldConsumptionApprovalApproveAPI = (id, payload) => httpClient.put(`${ZLD_VINY_THAI_END_POINT}/${id}/consumption-approvals/approve`, payload);
const putUpdateZldConsumptionApprovalVerifyAPI = (id, payload) => httpClient.put(`${ZLD_VINY_THAI_END_POINT}/${id}/consumption-approvals/verify`, payload);

export {
	getZldInfoAPI,
	getZldLastMonthAPI,
	getZldRecycleWaterAPI,
	getOutletRecycleWaterAPI,
	getElectricalConductivity,
	getZldVinyThaiInfoAPI,
	getZldVinyThaiWaterFlowRate,
	getSilicaInRecycleWaterAPI,
	getElectricalRecycleWaterAPI,
	getElectricalInletWaterAPI,
	getRecycleWaterProductionAPI,
	getZldVinyThaiOrderHistory,
	getZldVinyThaiConsumptionAPI,
	putUpdateZldConsumptionApprovalApproveAPI,
	putUpdateZldConsumptionApprovalVerifyAPI
};
