import { getSorTrendsAPI } from "@/services/api/sor.api";
import { SOR_TREND_REQUEST, SOR_TREND_SUCCESS, SOR_TREND_FAILURE } from "../../types";
import { parsePowerTrend } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parsePowerTrend({}),
		loading: true,
		empty: false
	},

	mutations: {
		[SOR_TREND_REQUEST](state) {
			state.detail = parsePowerTrend({});
			state.loading = true;
			state.empty = false;
		},
		[SOR_TREND_SUCCESS](state, data) {
			const detail = data;
			state.detail = parsePowerTrend(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[SOR_TREND_FAILURE](state) {
			state.detail = parsePowerTrend({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getSorTrend({ commit }, { id, params }) {
			try {
				commit(SOR_TREND_REQUEST);
				const { data } = await getSorTrendsAPI(id, params);
				commit(SOR_TREND_SUCCESS, data);
			} catch (error) {
				commit(SOR_TREND_FAILURE, error);
			}
		}
	}
};
