import { getCerInfoAPI } from "@/services/api/Cers.api";
import {
	CER_INFO_REQUEST,
	AUTO_CER_INFO_REQUEST,
	CER_INFO_SUCCESS,
	AUTO_CER_INFO_SUCCESS,
	CER_INFO_FAILURE,
	CER_INFO_CLEAR
} from "../../types";
import { parseCerInfo } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: {},
		loading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	mutations: {
		[CER_INFO_REQUEST](state) {
			state.data = {};
			state.loading = true;
			state.statusCode = null;
		},
		[AUTO_CER_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[CER_INFO_SUCCESS](state, { data, status }) {
			state.data = parseCerInfo(data);
			state.loading = false;
			state.statusCode = status;
		},
		[AUTO_CER_INFO_SUCCESS](state, { data, status }) {
			state.data = parseCerInfo(data);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[CER_INFO_FAILURE](state, error) {
			state.data = {};
			state.loading = false;
			state.statusCode = error.response.status;
		},
		[CER_INFO_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async getCerInfo({ commit }, { id }) {
			try {
				commit(CER_INFO_REQUEST);
				const response = await getCerInfoAPI(id);
				commit(CER_INFO_SUCCESS, response);
			} catch (error) {
				commit(CER_INFO_FAILURE, error);
			}
		},
		async autoGetCerInfo({ commit }, { id }) {
			try {
				commit(AUTO_CER_INFO_REQUEST);
				const response = await getCerInfoAPI(id);
				commit(AUTO_CER_INFO_SUCCESS, response);
			} catch (error) {
				commit(CER_INFO_FAILURE, error);
			}
		},

		clearCerInfo({ commit }) {
			commit(CER_INFO_CLEAR);
		}
	}
};
