import dayjs from "dayjs";
import { transformLineChart } from "..";
import { NM_UNIT } from "../../unit";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import { getConfigDataZoom } from "../helper/zoomConfig";
import { yAxisConfig } from "../helper/yAxisConfig";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Gas flow rate (${NM_UNIT}/hr)`;

const tooltipLineChartFormat = (name, value) => {
	const format = `
	<div>
		<div class="bar-name meter">
			${name}
		</div>
		<div class="bar-value">
			${value === null ? "-" : value} Nm<sup>3</sup>/hr
		</div>
	</div>`;
	return format;
};
const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipLineChartFormat(date, pointValue.data);
};

const optionLineChartXaxisPerDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		show: false
	},

	yAxisConfig,

	axisTick: {
		show: false
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

const optionLineChartXaxisForThreeDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		padding: [0, 0, 10, 0],
		show: false
	},

	yAxisConfig,

	axisTick: {
		show: false
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

const optionLineChartXaxisPerWeek = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		show: false
	},

	yAxisConfig,

	axisTick: {
		show: false
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

const optionLineChartMoreThanTwentyDay = ({ xAxis }) => ({
	yAxisText,

	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		show: false
	},

	yAxisConfig,

	axisTick: {
		show: false
	},

	tooltip: {
		formatter: tooltipFormatter
	},

	...getConfigDataZoom(xAxis),
	grid: {
		left: 16,
		right: 16,
		bottom: 54,
		containLabel: true
	}
});

export const transformGenGasFlowRateLineChartConfig = (sortBy = perDay, data = [], option = {}) => {
	const { diffDay } = option;
	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay(data),
		[perThreeDay]: optionLineChartXaxisForThreeDay(data),
		[perWeek]: optionLineChartXaxisPerWeek(data)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionLineChartMoreThanTwentyDay(data);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}

	const functionTransform = mappingOption[sortBy] || optionLineChartXaxisPerWeek(data);
	const transformFunc = transformLineChart(functionTransform);
	return transformFunc(data);
};
