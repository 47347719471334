<template>
	<div class="wrapper-base-icon-info" :style="wrapperStyle">
		<font-awesome-icon :style="{ fontSize : fontSize }" :icon="['far', iconName]"/>
		<p class="information"> {{ information }}</p>
	</div>
</template>

<script>
export default {
	props: {
		iconName: {
			type: String,
			default: "map-marked"
		},
		fontSize: {
			type: [String, Number],
			default: 40
		},
		information: {
			type: [String],
			default: ""
		},
		wrapperStyle: {
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style lang="scss" scoped>
.wrapper-base-icon-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.information {
	@include fontSub;

	font-size: rem(20);
	color: $color-dark-blue-grey;
	font-weight: $font-weight-bold;
}
</style>