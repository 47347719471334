<template>
	<BaseLoading v-if="loading"/>
	<div v-else>
		<BaseFilterExport
			v-if="permissions.dashboard.exportable"
			mode="range"
			class="custom-filter-export"
			@onSubmit="handleSubmitExport"
		/>
		<Sticky>
			<HeadColumns />
		</Sticky>
		<OrderItem
			v-for="(item, index) in data"
			:key="index"
			:item="item"
		/>
		<BasePagination
			v-if="hasMorePages"
			:value="pagination.currentPage"
			:totalPage="pagination.lastPage"
			:class="`custom-pagination`"
			@onPageChange="handlePageChange"
		/>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import HeadColumns from "@/components/BasePwrHistoryColumn.vue";
import OrderItem from "@/components/BasePwrHistoryItem.vue";
import Sticky from "@/components/Sticky.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";
import userPermission from "../../mixins/userPermission";

export default {
	mixins: [userPermission],

	props: {
		data: {
			type: Array,
			default: () => ([])
		},
		pagination: {
			type: Object,
			default: () => ({})
		},
		loading: {
			type: Boolean,
			default: false
		}
	},

	components: {
		BaseLoading,
		HeadColumns,
		OrderItem,
		Sticky,
		BaseFilterExport
	},

	computed: {
		hasMorePages() {
			return this.pagination.lastPage > 1;
		}
	},

	methods: {
		handlePageChange(e) {
			this.$emit("pageChange", e);
		},
		handleSubmitExport(form) {
			this.$emit("export", form);
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-pagination {
	display: flex;
	justify-content: flex-end;
}

.custom-filter-export {
	margin-top: rem(-8);
	margin-bottom: rem(11);
	justify-content: flex-end;
}
</style>