<template>
	<Form
		v-model="diagrams"
		:validateFormFn.sync="validateFormFn"
		:loading="loading"
		:title="formTitle"
		:query="query"
		:editable="editable"
		:zoomable="zoomable"
		:initEmptyNode="false"
		:viewOnly="false"
		:devices="devices"
		:shipTos="shipTos"
		@onEdit="handleClickEdit"
	>
		<template #action>
			<BaseAction
				:textSubmit="`Apply Change`"
				:textCancle="`Cancel`"
				:textRemove="`Delete all diagram`"
				:isSummited="isSummited.applyChange"
				:disabled="disabledSummitButton"
				@cancel="handleCancel"
				@submit="handleSumit"
				@remove="handleRemove"
			/>
			<BaseModalConfirmDelete
				:modal-name="MODAL_NAME_CONFIRM_DELETE_ALL_FLOW"
				:title="`Delete all diagram`"
				:message="`
					<div>
						Are you sure to delete the diagram?
					</div>
					<div>
						All diagrams in this ship-to will be deleted.
					</div>
				`"
				:isSummited="isSummited.confirmDelete"
				@onCancel="handleCancleDeleteAllFlow"
				@onConfirm="handleConfirmDeleteAllFlow"
			/>
			<BaseModalConfirmDelete
				:modal-name="MODAL_NAME_CONFIRM_DISCARD"
				:title="`Discard?`"
				:message="`
					<div>
						You will be lost all added information.
					</div>
					<div>
						Are you sure to discard?
					</div>
				`"
				:submitButtonText="`Yes, discard`"
				@onCancel="handleCancleDiscard"
				@onConfirm="handleConfirmDiscard"
			/>
			<BaseModalConfirmDelete
				:modal-name="MODAL_NAME_CONFIRM_LEAVE"
				:title="`Leave this page?`"
				:message="`
					<div>
						You have unsaved changes.
					</div>
					<div>
						Are you sure to leave without saving?
					</div>
				`"
				:submitButtonText="`Yes, discard`"
				@onCancel="handleCancleLeave"
				@onConfirm="handleConfirmLeave"
			/>
		</template>
	</Form>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Form from "@/views/FlowDiagram/Form.vue";
import BaseAction from "@/components/BaseAction.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { convertDiagrams, getBody, isValidDiagrams } from "../../selectors/helpers/flowDiagram";
import { getIsSuperAdmin } from "../../selectors/transform/staff";
import { TOAST_TYPES } from "../../enums/toast";
import { PAGE_NAME } from "../../enums/pagePermission";
import userPermission from "../../mixins/userPermission";

export default {
	components: {
		Form,
		BaseAction,
		BaseModalConfirmDelete
	},

	mixins: [userPermission],

	data() {
		return {
			MODAL_NAME_CONFIRM_DELETE_ALL_FLOW: "MODAL_NAME_CONFIRM_DELETE_ALL_FLOW",
			MODAL_NAME_CONFIRM_DISCARD: "MODAL_NAME_CONFIRM_DISCARD",
			MODAL_NAME_CONFIRM_LEAVE: "MODAL_NAME_CONFIRM_LEAVE",
			query: {
				id: this.$route.query.id
			},
			isSummited: {
				confirmDelete: false,
				applyChange: false
			},
			editable: false,
			diagrams: [],
			validateFormFn: null,
			isConfirmLeave: false,
			goTo: null
		};
	},

	computed: {
		...mapState(["user"]),
		...mapState("flowDiagramSingle", {
			flowDiagram: "data",
			flowDiagramLoading: "loading"
		}),
		...mapState("flowDiagramDeviceList", {
			devices: "data",
			devicesLoading: "loading"
		}),
		...mapState("flowDiagramShipTo", {
			shipTos: "data",
			shipTosLoading: "loading"
		}),
		loading() {
			return this.flowDiagramLoading || this.devicesLoading || this.shipTosLoading;
		},
		disabledSummitButton() {
			return !isValidDiagrams(this.diagrams);
		},
		hasPermission() {
			return getIsSuperAdmin(this.user.role) || this.permissions.diagram.manage;
		},
		zoomable() {
			return !this.editable;
		},
		formTitle() {
			return this.editable
				? "Edit diagram"
				: "View diagram";
		}
	},

	created() {
		if (!this.hasPermission) {
			this.$router.push({
				name: PAGE_NAME.FLOWDIAGRAM_LIST
			}).catch(() => {});
		}
	},

	async mounted() {
		await this.fetchData();
		this.resetState();
		window.addEventListener("beforeunload", this.handleBeforeUnLoad);
	},

	destroyed() {
		window.removeEventListener("beforeunload", this.handleBeforeUnLoad);
	},

	beforeRouteLeave(to, from, next) {
		if (!this.isConfirmLeave && this.editable) {
			this.goTo = to.fullPath;
			this.openModalConfirmLeave();
			next(false);
		} else {
			next(true);
		}
	},

	methods: {
		...mapActions("flowDiagramSingle", {
			getFlowDiagramSingle: "getFlowDiagramSingle"
		}),
		...mapActions("flowDiagramShipTo", {
			getFlowDiagramShipToList: "getFlowDiagramShipToList"
		}),
		...mapActions("flowDiagramDeviceList", {
			getFlowDiagramDeviceList: "getFlowDiagramDeviceList"
		}),
		...mapActions("flowDiagramUpdate", {
			updateFlowDiagram: "updateFlowDiagram"
		}),
		...mapActions("flowDiagramDelete", {
			deleteFlowDiagram: "deleteFlowDiagram"
		}),
		...mapActions("toast", {
			showToast: "show"
		}),
		async fetchData() {
			return Promise.all([
				this.getFlowDiagramSingle({
					id: this.query.id
				}),
				this.getFlowDiagramShipToList({
					perPage: 10000,
					show: "all"
				}),
				this.getFlowDiagramDeviceList({
					id: this.query.id,
					params: {
						type: "All"
					}
				})
			]);
		},
		resetState() {
			this.editable = false;
			try {
				this.diagrams = convertDiagrams(this.flowDiagram.diagrams, this.devices, { addEmptyNodes: false });
			} catch (e) {
				this.diagrams = [];
			}
		},
		handleClickEdit(state) {
			this.editable = state;
			try {
				this.diagrams = convertDiagrams(this.flowDiagram.diagrams, this.devices, { addEmptyNodes: true });
			} catch (e) {
				this.diagrams = [];
			}
		},
		handleCancel() {
			this.openModalConfirmDiscard();
		},
		async handleSumit() {
			const isValid = this.validateFormFn();
			if (isValid) {
				this.isSummited.applyChange = true;
				await this.updateFlowDiagram({
					id: this.query.id,
					data: getBody(this.diagrams)
				});
				this.isSummited.applyChange = false;
				this.editable = false;
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: { title: "Success", message: "Diagram successfully changed" }
				});
				await this.fetchData();
				this.resetState();
			}
		},
		handleRemove() {
			this.openModalConfirmDeleteAllFlow();
		},
		handleCancleDeleteAllFlow() {
			this.closeModalConfirmDeleteAllFlow();
		},
		async handleConfirmDeleteAllFlow() {
			this.isSummited.confirmDelete = true;
			await this.deleteFlowDiagram({
				id: this.query.id
			});
			this.showToast({
				type: TOAST_TYPES.RESPONSE_SUCCESS,
				props: { title: "Success", message: "Diagram deleted successfully" }
			});
			this.isConfirmLeave = true;
			this.$router.push({
				name: PAGE_NAME.FLOWDIAGRAM_LIST
			});
		},
		handleCancleDiscard() {
			this.closeModalConfirmDiscard();
		},
		handleConfirmDiscard() {
			this.resetState();
			this.closeModalConfirmDiscard();
		},
		handleCancleLeave() {
			this.closeModalConfirmLeave();
		},
		handleConfirmLeave() {
			this.isConfirmLeave = true;
			this.closeModalConfirmLeave();
			this.$router.push(this.goTo);
		},
		openModalConfirmDeleteAllFlow() {
			this.$modal.show(this.MODAL_NAME_CONFIRM_DELETE_ALL_FLOW);
		},
		closeModalConfirmDeleteAllFlow() {
			this.$modal.hide(this.MODAL_NAME_CONFIRM_DELETE_ALL_FLOW);
		},
		openModalConfirmDiscard() {
			this.$modal.show(this.MODAL_NAME_CONFIRM_DISCARD);
		},
		closeModalConfirmDiscard() {
			this.$modal.hide(this.MODAL_NAME_CONFIRM_DISCARD);
		},
		openModalConfirmLeave() {
			this.$modal.show(this.MODAL_NAME_CONFIRM_LEAVE);
		},
		closeModalConfirmLeave() {
			this.$modal.hide(this.MODAL_NAME_CONFIRM_LEAVE);
		},
		handleBeforeUnLoad(e) {
			if (this.editable) {
				// Cancel the event
				e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
				// Chrome requires returnValue to be set
				e.returnValue = "";
			}
		}
	}
};
</script>