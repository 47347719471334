import { getNumeral } from "./helper";

/*
	document ceat-sheet for config
	https://www.echartsjs.com/en/cheat-sheet.html
*/

export const transformLineChartStack = (option = {}) => ({ dateRange = [] }) => {
	const defaultGrid = {
		left: "24px",
		right: "24px",
		bottom: "0",
		containLabel: true
	};
	const defaultLegend = {
		show: false
	};

	const {
		yAxisText,
		series,
		axisLabel,
		axisTick,
		dataZoom = [],
		grid = defaultGrid,
		yAxisConfig = [],
		legend = defaultLegend,
		tooltip = {}
	} = option;
	return {
		legend,
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#103332",
			axisPointer: {
				crossStyle: {
					color: "red"
				}
			},
			padding: 8,
			...tooltip
		},
		color: ["#ea7125"],
		grid,
		xAxis: [
			{
				type: "category",
				data: dateRange,
				boundaryGap: false,
				axisPointer: {
					show: true,
					triggerTooltip: false,
					label: {
						show: false
					}
				},
				axisLabel: {
					margin: 8,
					fontSize: 14,
					show: true,
					...axisLabel
				},
				axisTick: {
					length: 4,
					lineStyle: {
						width: 1,
						color: "#586c63"
					},
					...axisTick
				},
				axisLine: {
					lineStyle: {
						color: "#cad9cf"
					}
				}
			}
		],
		yAxis: [
			{
				name: yAxisText,
				type: "value",
				color: "#59e07a",
				nameTextStyle: {
					color: "#717171",
					width: "100px",
					fontFamily: "TFSrivichai",
					fontWeight: "bold",
					fontSize: 20,
					padding: [0, 0, 24, -24],
					align: "left"
				},
				axisLabel: {
					margin: 8,
					fontSize: 14,
					formatter: getNumeral
				},
				splitNumber: 4,
				splitLine: {
					lineStyle: {
						color: "#cad9cf"
					}
				},
				axisTick: {
					length: 4,
					lineStyle: {
						width: 1,
						color: "#586c63"
					}
				},
				axisLine: {
					lineStyle: {
						color: "#cad9cf"
					}
				}
			},
			...yAxisConfig
		],
		axisLabel: {
			color: () => "#103332",
			fontSize: 14,
			fontFamily: "TFSrivichai"
		},
		dataZoom: [
			...dataZoom
		],
		series
	};
};