<template>
	<BaseCardIcon color="gray" class="no-shipto-container">
		<template v-slot:icon>
			<svg xmlns="http://www.w3.org/2000/svg" width="62" height="61" viewBox="0 0 62 61">
				<g fill="none" fill-rule="evenodd" transform="translate(1 1)">
					<path fill="#586C63" stroke="#AFC0B6" stroke-width="2" d="M42.523 0H9.857c-1.87 0-3.38 1.937-3.38 4.333v54h39.416v-54C45.893 1.937 44.38 0 42.523 0z"/>
					<path fill="#586C63" stroke="#AFC0B6" stroke-linejoin="round" stroke-width="2" d="M19.133 45.057H33.236V58.957H19.133z"/>
					<path fill-rule="nonzero" stroke="#586C63" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M0 58.347L52.283 58.347"/>
					<path fill="#FFF" fill-rule="nonzero" stroke="#AFC0B6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16.673 10.26L35.697 10.26M16.673 21.273L35.697 21.273M16.673 32.737L35.697 32.737"/>
					<circle cx="45.893" cy="31.18" r="13.8" fill="#586C63" stroke="#AFC0B6" stroke-width="2"/>
					<rect width="2.423" height="11.71" x="44.68" y="22.733" fill="#AFC0B6" fill-rule="nonzero" rx="1.212"/>
					<circle cx="45.893" cy="37.513" r="1.22" fill="#AFC0B6" fill-rule="nonzero"/>
				</g>
			</svg>
		</template>
		<template v-slot:head>
			<div class="no-ship-to-head">
				No ship-to
			</div>
		</template>
		<template v-slot:body>
			<div class="no-ship-to-body">
				Your ship-to information will appear here once you are assigned.
			</div>
		</template>
	</BaseCardIcon>
</template>

<script>
import BaseCardIcon from "@/components/BaseCardIcon.vue";

export default {
	name: "TruckIcon",

	components: {
		BaseCardIcon
	}
};
</script>

<style lang="scss" scoped>
.no-shipto-container {
	margin-top: rem(66);

	.no-ship-to-head {
		font-size: rem(48);
	}

	.no-ship-to-body {
		font-size: rem(20);
	}
}
</style>