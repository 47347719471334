<template>
	<aside :class="['main-sidebar', { collapse: !isSidebarOpen }]">
		<div class="sidebar-logo">
			<img src="/assets/images/logo-white.svg" alt="BIG-TH">
		</div>
		<div class="sidebar-action" @click="$emit('setSideBar')">
			<FontAwesomeIcon v-if="isSidebarOpen" :icon="['far', 'chevron-left']" />
			<FontAwesomeIcon v-else :icon="['far', 'chevron-right']" />
		</div>
		<div :class="['sidebar-profile-wrapper',{ collapse: !isSidebarOpen}]">
			<div class="sidebar-profile">
				<div class="profile-picture">
					<div class="avatar">
						<FontAwesomeIcon :icon="['fas', 'user']" class="icon" @click="toggleProfileModal"/>
					</div>
				</div>
				<div class="profile-detail">
					<div class="profile-name" @click="toggleProfileModal">
						{{ getProfile.fullName }}
						<FontAwesomeIcon class="icon-arrow" :icon="['far','chevron-circle-down']"/>
					</div>
					<div class="profile-role">
						{{ getProfile.roleName }}
					</div>
				</div>
			</div>
			<div v-if="this.isProfileOpen" class="sorting-selection" v-click-outside="toggleProfileModal">
				<ul :class="['sorting-options', { active: this.isProfileOpen }]">
					<li class="sorting-option">
						<button @click="showModal(MODAL_NAME_USER_PROFILE)">
							My Profile
						</button>
					</li>
					<li v-if="userTypeCustomer" class="sorting-option">
						<button @click="showModal(MODAL_REQUEST_CHANGE_PASSWORD)">
							Request change password
						</button>
					</li>
					<li class="sorting-option">
						<a class="logout-button" @click="getLogout">Logout</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="sidebar-menus">
			<SidebarMenuList />
			<BaseContact
				:type="contactDisplayType"
				:modalCompanyOverView="MODAL_COMPANY_OVERVIEW"
				:modalBussinessPhilosophy="MODAL_BUSINESS_PHILOSOPHY"
				@openModal="showModal"
			/>
		</div>
	</aside>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SidebarMenuList from "@/components/TheMainSidebarMenu.vue";
import BaseContact from "@/components/BaseContact.vue";
import { USER_TYPE } from "../enums/user";
import { MODAL_NAME } from "../enums/modal";

const { MODAL_NAME_USER_PROFILE, MODAL_NAME_CHANGE_PASSWORD, MODAL_COMPANY_OVERVIEW, MODAL_BUSINESS_PHILOSOPHY, MODAL_REQUEST_CHANGE_PASSWORD } = MODAL_NAME;

export default {
	name: "Sidebar",

	components: {
		SidebarMenuList,
		BaseContact
	},

	props: {
		isSidebarOpen: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			isProfileOpen: false,
			MODAL_NAME_USER_PROFILE,
			MODAL_NAME_CHANGE_PASSWORD,
			MODAL_REQUEST_CHANGE_PASSWORD,
			MODAL_COMPANY_OVERVIEW,
			MODAL_BUSINESS_PHILOSOPHY
		};
	},

	methods: {
		...mapActions({
			handleLogout: "user/getLogout",
			addActivityLogMeView: "activityLogAdd/addActivityLogMeView"
		}),
		async getLogout() {
			await this.handleLogout();

			if (this.userType === USER_TYPE.STAFF) {
				window.location.href = this.logoutAzureUrl;
			} else {
				window.location.href = "/login";
			}
		},
		toggleProfileModal() {
			this.isProfileOpen = !this.isProfileOpen;
		},
		showModal(modalName) {
			if (modalName === this.MODAL_NAME_USER_PROFILE) {
				this.addActivityLogMeView();
			}

			this.isProfileOpen = false;
			this.$modal.show(modalName);
		}
	},

	computed: {
		...mapState(["user"]),
		...mapGetters("user", {
			getProfile: "getProfile"
		}),
		logoutAzureUrl() {
			const azureEndPoint = process.env.VUE_APP_AZURE_AUTHORIZE_ENDPOINT;
			const loginRoute = process.env.VUE_APP_AZURE_LOGIN;
			return `${azureEndPoint}/logout?post_logout_redirect_uri=${loginRoute}`;
		},
		contactDisplayType() {
			return this.isSidebarOpen
				? "text"
				: "icon";
		},
		userType() {
			return this.getProfile.type;
		},
		userTypeCustomer() {
			return this.userType === USER_TYPE.CUSTOMER;
		}
	}
};
</script>

<style lang="scss" scoped>
.main-sidebar {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	width: rem($sidebar-width);
	min-height: 100%;
	height: 100%;
	transition: transform $transition-ease-in-out-fastest, width $transition-ease-in-out-fastest;
	background-color: $sidebar-bg-color;
	border-right: 1px solid $color-silver;
	z-index: 5;

	@include desktop {
		width: rem($sidebar-tablet-width);
	}

	.avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(48);
		height: rem(48);
		margin-right: rem(8);
		background-color: $color-silver;
		border-radius: 50%;

		.icon {
			height: rem(26);
			color: $sidebar-menu-color;
		}
	}

	.logout-button {
		cursor: pointer;
	}

	.sidebar-text {
		min-width: rem(110);
		transition: all $transition-ease-in-out-fastest;
	}

	.sidebar-menus {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: space-between;
		padding-top: rem(16);
		transition: all $transition-ease-in-out-fastest;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.sidebar-menus::-webkit-scrollbar {
		.container-menu-item-link {
			padding: rem(6);
		}
	}

	&.collapse {
		width: rem(80);
		box-sizing: content-box;

		.sidebar-text {
			opacity: 0;
		}

		.container-footer {
			justify-content: center;
			padding: rem(24) 0 rem(24) 0;
		}

		.sidebar-menus {
			overflow: auto;
		}

		.sidebar-menus::-webkit-scrollbar {
			width: 0;
			background: transparent;
		}
	}

	.sidebar-action {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 1;
		background-color: $color-white;
		color: $color-cerulean;
		width: rem(24);
		height: rem(24);
		border-radius: 50%;
		right: rem(-14);
		top: rem(158);
		box-shadow: 0 rem(4) rem(8) 0 $color-box-shadow;
		font-size: rem(8);
		cursor: pointer;
	}

	.sidebar-logo {
		background-color: $sidebar-menus-bg-color;
		display: flex;
		justify-content: center;
		height: rem(68);

		img {
			width: rem(160);
		}
	}

	.button-link {
		display: flex;
		align-items: center;
		border: 0;
		font-weight: $font-weight-bold;
		color: $color-cerulean;
		background-color: transparent;
	}

	.sidebar-menu {
		position: relative;

		// .sidebar-menu-link
		&-link {
			border-radius: rem(4);
			color: $sidebar-menu-color;
			font-weight: 600;
			position: relative;
			padding: rem(12) rem(15);
			margin-bottom: rem(8);
			display: flex;
			align-items: center;
			font-size: $font-18;

			&.collapse {
				margin-right: 0;
				padding: rem(12) rem(8);
			}

			[data-icon] {
				margin-right: rem(10);
				width: rem(24);
				color: $color-light-teal;
			}


			&.active {
				color: $sidebar-menu-active-color;
				background-color: $sidebar-menu-active-bg-color;

				[data-icon] {
					color: $sidebar-menu-active-color;
				}
			}
		}

		&:hover {
			[data-icon] {
				color: $sidebar-menu-active-color;
			}

			a {
				color: $sidebar-menu-active-color;
				background-color: $sidebar-menu-hover-bg-color;
			}
		}

		&.disabled, &.disabled:hover {
			a, [data-icon] {
				color: $color-light-teal;
				pointer-events: none;
			}
		}
	}

	.sidebar-profile-wrapper {
		position: relative;
		padding: rem(16);
		transition: all $transition-ease-in-out-fastest;

		&.collapse {
			padding: rem(16) rem(12);

			.sidebar-profile {
				position: relative;
				justify-content: center;
				padding-bottom: rem(30);

				.profile-picture {
					margin: 0;
				}

				.profile-detail {
					opacity: 0;
				}
			}

			.profile-name,
			.profile-role {
				display: none;
				opacity: 0;
			}

			.sorting-selection {
				left: rem(12);
				top: rem(80);
			}
		}

		.profile-detail {
			@include fontBase();

			flex: 1;
			display: flex;
			flex-direction: column;
			width: 0;
			transition: all $transition-ease-in-out-fastest;
			font-weight: $font-weight-bold;
		}

		.profile-name {
			position: relative;
			display: block;
			max-width: 100%;
			padding-right: rem(18);
			color: $color-cerulean;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: pointer;

			.icon-arrow {
				position: absolute;
				top: rem(4);
				right: rem(0);
			}
		}

		.profile-role {
			@include ellipsis(1);

			font-size: $font-16;
			font-weight: normal;
			color: $color-grey-4;
		}

		.sidebar-profile {
			display: flex;
			padding: rem(8) rem(0) rem(25);
			border-bottom: rem(1) solid $color-silver;
		}

		.profile-picture {
			width: rem(48);
			height: rem(48);
			margin-right: rem(8);
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.sorting-selection {
			position: absolute;
			width: rem(170);
			top: rem(52);
			left: rem(70);

			.sorting-value {
				color: $color-cerulean;
				margin-left: rem(4);
			}

			.sorting-options {
				@include fontBase();

				display: none;
				position: absolute;
				right: 0;
				width: 100%;
				padding: rem(8) 0;
				border-radius: rem(4);
				border: 1px solid $color-silver;
				background-color: $color-white;
				z-index: 5;

				&.active {
					display: block;
				}
			}

			.sorting-option {
				a, button {
					display: block;
					padding: 0 rem(8);
					color: $color-dark-blue-grey;
					height: rem(32);
					line-height: rem(32);

					&:hover {
						background-color: $color-ice-two;
					}

					.svg-inline--fa {
						font-size: $font-16;
						color: $color-cerulean;
						margin-right: rem(8);
					}
				}

				button {
					width: 100%;
					background: none;
					border: none;
					text-align: left;
				}
			}
		}
	}
}
</style>
