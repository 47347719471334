import dayjs from "dayjs";

import { transformShipTo } from "./transformShipTo";
import { transformDevices } from "./transformDevices";
import { DISPLAY_TIME } from "../../../enums/eOrdering/timeType";

export const transformPendingOrder = (pendingOrder = {}) => {
	const displayDeliveryDate = dayjs(pendingOrder?.deliveryDate).format("DD/MM/YY") ?? "-";
	const displayDeliveryTime = dayjs(pendingOrder?.deliveryDate).format("HH:mm") ?? "-";
	const createdDate = dayjs(pendingOrder?.createDate).format("D/MM/YY, HH:mm") ?? "-";
	const amount = pendingOrder?.amount ?? "-";
	const device = transformDevices(pendingOrder?.device) ?? null;
	const shipTo = transformShipTo(device?.shipTo)?.name ?? "-";

	return {
		id: pendingOrder.id,
		createdDate,
		deliveryDate: pendingOrder.deliveryDate,
		displayDeliveryDate,
		displayDeliveryTime: DISPLAY_TIME[displayDeliveryTime] || displayDeliveryTime,
		device,
		isUrgent: pendingOrder?.isUrgent ?? false,
		amount,
		poNo: pendingOrder?.poNumber ?? "-",
		note: pendingOrder?.note ?? "-",
		productName: pendingOrder?.productFullName ?? "-",
		shipId: pendingOrder?.shipId ?? "-",
		shipTo,
		module: pendingOrder.module,
		shipToName: pendingOrder.shipToNameEn,
		productId: pendingOrder.productId,
		status: pendingOrder?.status ?? 2,
		batchOrderDate: new Date(pendingOrder.reportDate)
	};
};

export const transformPendingOrderList = (pendingOrders = []) => {
	const allPendingOrders = pendingOrders.map(transformPendingOrder).sort((a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate));

	return {
		pendingOrders: allPendingOrders.filter((pendingOrder) => pendingOrder.status === 2),
		failedOrders: allPendingOrders.filter((pendingOrder) => pendingOrder.status === 0)
	};
};