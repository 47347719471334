import { CUSTOMER_GET_PERMISSION_REQUEST, CUSTOMER_GET_PERMISSION_SUCCESS, CUSTOMER_GET_PERMISSION_FAILURE } from "../../../store/types";
import { getPermissionListApi } from "../../../services/api/devicePermissions";
import { trasnformSoldToOptions, transformShipTos, transformDeivce, transformApplication } from "../../../selectors/transform/devicePermission";

export default {
	namespaced: true,

	state: {
		data: {
			devices: [],
			applications: [],
			shipTos: [],
			soldTos: []
		},
		isLoading: false,
		isError: false
	},
	getters: {
		getListSoldTosOptions(state) {
			return trasnformSoldToOptions(state.data.soldTos);
		},
		getListShipTosOptions(state) {
			return transformShipTos(state.data.shipTos);
		},
		getListApplicationOptions(state) {
			return transformApplication(state.data.devices);
		},

		getListDeviceOptions(state) {
			return transformDeivce(state.data.devices);
		}
	},
	mutations: {
		[CUSTOMER_GET_PERMISSION_REQUEST](state) {
			state.isLoading = true;
		},
		[CUSTOMER_GET_PERMISSION_SUCCESS](state, response) {
			const { devices = [], shipTos = [], soldTos = [] } = response;
			state.data.soldTos = soldTos;
			state.data.shipTos = shipTos;
			state.data.devices = devices;
			state.isLoading = false;
		},
		[CUSTOMER_GET_PERMISSION_FAILURE](state) {
			state.isLoading = false;
			state.isError = true;
		},
		CUSTOMER_CLEAR_PERMISSION_LIST(state) {
			state.data = {
				devices: [],
				applications: [],
				shipTos: [],
				soldTos: []
			};
			state.isLoading = false;
			state.isError = false;
		}
	},

	actions: {
		async fetchPermissionList({ commit }, payload = {
			company: "",
			soldToId: ""

		}) {
			try {
				commit(CUSTOMER_GET_PERMISSION_REQUEST);
				const payloadRequest = {
					...payload
				};
				const { data } = await getPermissionListApi(payloadRequest);
				commit(CUSTOMER_GET_PERMISSION_SUCCESS, data);
			} catch (e) {
				commit(CUSTOMER_GET_PERMISSION_FAILURE);
			}
		},
		clearPermissionList({ commit }) {
			commit("CUSTOMER_CLEAR_PERMISSION_LIST");
		}
	}
};
