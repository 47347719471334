var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideDropdown),expression:"onClickOutsideDropdown"}],class:['container-menu', { collapse: !_vm.sidebar.isSidebarOpen && !_vm.sidebar.isUITabletSidebarOpen }]},[_c('ul',{staticClass:"container-menu-list"},_vm._l((_vm.filterMenus),function(item,index){return _c('li',{key:index,ref:"menu",refInFor:true,class:['container-menu-item', { active: item.active }, { nested: item.isNestedMenus }, { 'dropdown-shown': _vm.dropdownActivedAt === index }],on:{"click":function($event){return _vm.toggleMenu(index)}}},[(item.isNestedMenus)?_c('VMenu',{attrs:{"triggers":['click', 'hover'],"placement":"right","delay":200},scopedSlots:_vm._u([(!_vm.sidebar.isSidebarOpen && !_vm.sidebar.isUITabletSidebarOpen && !_vm.isMobile)?{key:"popper",fn:function(){return [_c('div',{staticClass:"container-menu-item-nested-dropdown-list"},[(item.isShowTitle)?_c('div',{staticClass:"container-menu-item-title"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),_vm._l((item.children),function(submenu,indexSubMenu){return _c('div',{key:indexSubMenu,staticClass:"container-menu-item-normal"},[_c('router-link',{class:['container-menu-item-link', { disabled: submenu.isDisabled }, { active: submenu.active }],attrs:{"to":{ name: submenu.name }}},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon-outer",style:([
										{
											width: submenu.iconSize.width,
											height: submenu.iconSize.height
										}
									])},[(submenu.icon === _vm.CONFIG.ICON_NAME.HAND_HOLDING_HEART_ICON)?_c('HandHoldingHeartIcon'):(submenu.icon === _vm.CONFIG.ICON_NAME.IMAGE_LANDSPACE_ICON)?_c('ImageLandscapeIcon'):(submenu.icon === _vm.CONFIG.ICON_NAME.HAND_HOLDING_BOX_ICON)?_c('HandHoldingBoxIcon'):(submenu.icon === _vm.CONFIG.ICON_NAME.TABLE_LAYOUT_ICON)?_c('TableLayoutIcon'):_vm._e(),_c('FontAwesomeIcon',{attrs:{"icon":['far', submenu.icon]}})],1)]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(submenu.text)+" ")])])],1)})],2)]},proxy:true}:null],null,true)},[_c('div',{staticClass:"container-menu-item-nested",on:{"click":function($event){return _vm.toggleDropdown(index)}}},[_c('div',{staticClass:"container-menu-item-nested-head"},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon-outer",style:([
								{
									width: item.iconSize.width,
									height: item.iconSize.height
								}
							])},[(item.icon === _vm.CONFIG.ICON_NAME.LIST_CHECK_ICON)?_c('ListCheckIcon'):_c('FontAwesomeIcon',{attrs:{"icon":['far', item.icon]}})],1)]),(item.isShowTitle)?_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.sidebar.isSidebarOpen)?_c('span',{staticClass:"arrow"},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'chevron-circle-up']}})],1):_vm._e()]),(_vm.sidebar.isSidebarOpen || _vm.sidebar.isUITabletSidebarOpen)?_vm._l((item.children),function(submenu,indexSubMenu){return _c('div',{key:indexSubMenu,staticClass:"container-menu-item-normal"},[_c('router-link',{class:['container-menu-item-link', { disabled: submenu.isDisabled }, { active: submenu.active }],attrs:{"to":{ name: submenu.name }},nativeOn:{"click":function($event){return _vm.closeUITabletSidebar($event)}}},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon-outer",style:([
										{
											width: submenu.iconSize.width,
											height: submenu.iconSize.height
										}
									])},[(submenu.icon === _vm.CONFIG.ICON_NAME.HAND_HOLDING_HEART_ICON)?_c('HandHoldingHeartIcon'):(submenu.icon === _vm.CONFIG.ICON_NAME.IMAGE_LANDSPACE_ICON)?_c('ImageLandscapeIcon'):(submenu.icon === _vm.CONFIG.ICON_NAME.HAND_HOLDING_BOX_ICON)?_c('HandHoldingBoxIcon'):(submenu.icon === _vm.CONFIG.ICON_NAME.TABLE_LAYOUT_ICON)?_c('TableLayoutIcon'):_c('FontAwesomeIcon',{attrs:{"icon":['far', submenu.icon]}})],1)]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(submenu.text)+" ")])])],1)}):_vm._e()],2)]):_c('VMenu',{attrs:{"triggers":['click', 'hover'],"placement":"right","delay":200},scopedSlots:_vm._u([(!_vm.sidebar.isSidebarOpen && !_vm.sidebar.isUITabletSidebarOpen && !_vm.isMobile)?{key:"popper",fn:function(){return [_c('div',{staticClass:"container-menu-item-nested-dropdown-list"},[_c('router-link',{class:['container-menu-item-normal-link', { disabled: item.isDisabled }],attrs:{"to":{ name: item.name,
								query: Object.assign({}, (item.query ? item.query : {}))
							}}},[(item.isShowTitle)?_c('div',{staticClass:"container-menu-item-normal-title"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])],1)]},proxy:true}:null],null,true)},[_c('div',{staticClass:"container-menu-item-normal"},[_c('router-link',{class:['container-menu-item-link', { disabled: item.isDisabled }, { active: item.active }],attrs:{"to":{ name: item.name,
							query: Object.assign({}, (item.query ? item.query : {}))
						}},nativeOn:{"click":function($event){return _vm.closeUITabletSidebar($event)}}},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon-outer",style:([
								{
									width: item.iconSize.width,
									height: item.iconSize.height
								}
							])},[(item.icon === _vm.CONFIG.ICON_NAME.CHART_SIMPLE_ICON)?_c('ChartSimpleIcon'):(item.icon === _vm.CONFIG.ICON_NAME.STORE_ICON)?_c('StoreIcon'):_c('FontAwesomeIcon',{attrs:{"icon":['far', item.icon]}})],1)]),(item.isShowTitle)?_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])],1)])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }