<template>
	<BaseLayout :isLoading="loading">
		<BaseBackBtn :to="ALERT_AND_NOTIFICATION_LIST" class="page-back-btn" label="Back to Alert and notification"/>

		<h2 class="head-title">
			Edit rule
		</h2>

		<BaseCard>
			<template #header>
				<h4>Information</h4>
			</template>
			<template #body>
				<div class="container-form">
					<div class="item">
						<div class="label">
							Module
						</div>
						<div class="form">
							<strong>
								{{ query.moduleTitle }}
							</strong>
						</div>
					</div>
					<div class="item">
						<div class="label">
							Rule name
						</div>
						<div class="form">
							<BaseFormInput
								v-model="ruleName"
								:inputOptions="['small']"
								placeholder="e.g. Module notification"
								inline
							/>
						</div>
					</div>
					<div class="item">
						<div class="label">
							ref ID
						</div>
						<div class="form">
							{{ $route.params.id }}
						</div>
					</div>
				</div>
			</template>
		</BaseCard>

		<FormConditionNoSignal
			v-if="isNoSignal"
			:form.sync="noSignalForm"
			:notificationGroupOptions="notificationGroupOptions"
		/>

		<FormConditionSetpoint
			v-if="isSetpoint"
			:form.sync="setPointForm"
			:valid.sync="setPointFormValid"
			:notificationGroupOptions="notificationGroupOptions"
			:addableCondition="addableCondition"
		/>

		<ApplyToDevice
			:applyToData="dataApplyTo"
			:soldToValues="soldToValues"
			:shipToValues="shipToValues"
			:deviceValues="deviceValues"
			:isAutoUpdateShipTo="isAutoUpdateShipTo"
			:isAutoUpdateDevice="isAutoUpdateDevice"
			withApplyToAllShipto
			withApplyToAlldevice
			@handleChangeSoldTo="handleChangeSoldTo"
			@handleChangeShipTo="handleChangeShipTo"
			@handleChangeDevice="handleChangeDevice"
			@handleCheckUpdateShipTo="handleCheckUpdateShipTo"
			@handleCheckUpdateDevice="handleCheckUpdateDevice"
		/>

		<BaseCard>
			<template #header>
				<h4>Activate / Deactivate this account</h4>
			</template>
			<template #body>
				<div class="container-form-status">
					<div class="item">
						<div class="label">
							<div class="head">
								Activate or temporary deactivate this user account.
							</div>
							<div class="desc">
								Deactivate this user will only disable this user account to login the system.
							</div>
						</div>
						<div class="form">
							<BaseSwitch
								name="status"
								:checked="active"
								@input="handleChangeStatus"
							/>
							<span>
								{{ statusText }}
							</span>
						</div>
					</div>
				</div>
			</template>
		</BaseCard>

		<div class="wrapper-actions">
			<div class="btn-delete">
				<BaseButton
					size="small"
					type="secondary"
					link
					@click="openModalConfirmDelete"
				>
					Delete this rule
				</BaseButton>
			</div>
			<BaseButton
				class="btn-cancel"
				size="large"
				type="primary"
				outline
				rounded
				@click="handleCancle"
			>
				Cancel
			</BaseButton>

			<BaseButton
				:disabled="isDisabledCreate"
				size="large"
				type="primary"
				rounded
				@click="handleSubmit"
			>
				Apply change
			</BaseButton>
		</div>
		<DialogLayoutDelete
			:modal-name="MODAL_DELETE"
			title="Delete this rule?"
			message="Are you sure to delete this rule?"
			@onSubmit="handleSubmitDelete"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions } from "vuex";
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import ApplyToDevice from "@/components/layoutCustomisation/ApplyToDevice.vue";
import FormConditionNoSignal from "@/components/AlertAndNotification/FormConditionNoSignal.vue";
import FormConditionSetpoint from "@/components/AlertAndNotification/FormConditionSetpoint.vue";
import DialogLayoutDelete from "@/components/BaseDialogDelete.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import { updateNotification, getAddableDevice, getAddableCondition, getNotificationById, deleteNotificationById } from "../../services/api/notification.api";
import { getAllNotificationGroups } from "../../services/api/notificationGroup.api";
import { TOAST_TYPES } from "../../enums/toast";
import { PAGE_NAME } from "../../enums/pagePermission";
import { trasnformSoldToOptions } from "../../selectors/transform/devicePermission";

const { ALERT_AND_NOTIFICATION_LIST } = PAGE_NAME;

export default {
	components: {
		BaseBackBtn,
		BaseCard,
		BaseFormInput,
		BaseButton,
		ApplyToDevice,
		FormConditionNoSignal,
		FormConditionSetpoint,
		DialogLayoutDelete,
		BaseSwitch
	},

	data() {
		return {
			ALERT_AND_NOTIFICATION_LIST,
			MODAL_DELETE: "MODAL_DELETE",
			// query
			query: {
				type: null,
				module: null,
				moduleTitle: this.$route.query.title
			},
			// form
			loading: false,
			noSignalForm: {
				severity: null,
				frequency: 0,
				heading: null,
				description: null,
				notificationGroups: []
			},
			setPointForm: {
				severity: null,
				frequency: 0,
				queryTimeInSec: 0,
				conditions: [],
				heading: null,
				description: null,
				notificationGroups: []
			},
			setPointFormValid: false,
			ruleName: null,
			soldToValues: [],
			shipToValues: [],
			deviceValues: [],
			// receive data from apis
			dataApplyTo: {
				soldTos: [],
				shipTos: [],
				devices: []
			},
			notificationGroupList: [],
			addableCondition: [],
			// options
			isAutoUpdateShipTo: false,
			isAutoUpdateDevice: false,
			active: true
		};
	},

	computed: {
		isNoSignal() {
			return this.query.type === "NoSignal";
		},
		isSetpoint() {
			return this.query.type === "Setpoint";
		},
		isValidForm() {
			if (!this.ruleName) {
				return false;
			}
			if (this.isNoSignal) {
				if (!this.noSignalForm) {
					return false;
				}
				if (!this.noSignalForm.severity) {
					return false;
				}
				if (!this.noSignalForm.heading) {
					return false;
				}
				if (!this.noSignalForm.notificationGroups.length) {
					return false;
				}
			}
			if (this.isSetpoint) {
				if (!this.setPointForm) {
					return false;
				}
				if (!this.setPointForm.severity) {
					return false;
				}
				if (!this.setPointForm.frequency) {
					return false;
				}
				if (!this.setPointFormValid) {
					return false;
				}
				if (!this.setPointForm.queryTimeInSec) {
					return false;
				}
				if (!this.setPointForm.heading) {
					return false;
				}
				if (!this.setPointForm.notificationGroups.length) {
					return false;
				}
			}
			if (!this.deviceValues.length) {
				return false;
			}
			return true;
		},
		isDisabledCreate() {
			return !this.isValidForm;
		},
		notificationGroupOptions() {
			return this.notificationGroupList.map((v) => {
				const title = (() => {
					const channels = {
						email: "Email",
						line: "Line",
						in_app: "In-App"
					};
					const prefix = channels[v.channel] ? `[${channels[v.channel]}]` : `${v.channel}`;
					return `${prefix} ${v.name}`;
				})();
				return {
					id: v.id,
					code: v.id,
					key: title,
					name: title
				};
			});
		},
		statusText() {
			return this.active
				? "Activate"
				: "Deactivate";
		}
	},

	async created() {
		this.loading = true;

		if (!this.$route.query.type && !this.$route.query.module) {
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST
			});
		}

		const [
			{ data: dataApplyTo },
			{ data: addableCondition },
			{ data: notificationGroup },
			{ data: notiInfo }
		] = await Promise.all([
			getAddableDevice({ app: this.$route.query.module }),
			getAddableCondition({ app: this.$route.query.module }),
			getAllNotificationGroups(),
			getNotificationById(this.$route.params.id)
		]);

		this.dataApplyTo = dataApplyTo;
		this.addableCondition = addableCondition;
		this.notificationGroupList = notificationGroup;

		// set query state
		this.query.type = notiInfo.type;
		this.query.module = notiInfo.module;

		// set form state
		this.ruleName = notiInfo.name;
		this.active = !!notiInfo.isActive;

		this.dataApplyTo = dataApplyTo;
		this.soldToValues = trasnformSoldToOptions(notiInfo.appliedTo.soldTos);
		this.shipToValues = notiInfo.appliedTo.shipTos;
		this.deviceValues = notiInfo.appliedTo.devices.reduce((acc, cur) => {
			const { id, productId, shipId, app: applicationkey, shipToId, ...props } = cur;
			const newValue = {
				id,
				code: id,
				name: `${productId} - ${shipId}`,
				key: `${productId} - ${shipId}`,
				shipToId,
				app: applicationkey,
				...props
			};
			return [
				...acc,
				newValue
			];
		}, []);
		this.isAutoUpdateShipTo = notiInfo.appliedTo.isAutoUpdateShipTo ?? false;
		this.isAutoUpdateDevice = notiInfo.appliedTo.isAutoUpdateDevice ?? false;

		if (this.isNoSignal) {
			this.noSignalForm.severity = notiInfo.rules.severity;
			this.noSignalForm.frequency = notiInfo.frequency;
			this.noSignalForm.heading = notiInfo.rules.message.heading;
			this.noSignalForm.description = notiInfo.rules.message.description;
			this.noSignalForm.notificationGroups = notiInfo.rules.notifyTo.map((id) => {
				const contactItem = this.notificationGroupList.find((item) => item.id === id);
				const title = (() => {
					const channels = {
						email: "Email",
						line: "Line",
						in_app: "In-App"
					};
					const prefix = channels[contactItem.channel] ? `[${channels[contactItem.channel]}]` : `${contactItem.channel}`;
					return `${prefix} ${contactItem.name}`;
				})();
				return {
					id: contactItem.id,
					code: contactItem.id,
					key: title,
					name: title
				};
			});
		}

		if (this.isSetpoint) {
			this.setPointForm.severity = notiInfo.rules.severity;
			this.setPointForm.frequency = notiInfo.frequency;
			this.setPointForm.queryTimeInSec = Math.floor(notiInfo.rules.query * 60);
			this.setPointForm.conditions = (() => {
				if (notiInfo.rules.conditions && notiInfo.rules.conditions.length) {
					return notiInfo.rules.conditions.map((item) => ({
						field: item.field,
						function: item.function,
						operator: item.operator,
						value1: item.value1,
						value2: item.value2
					}));
				}
				return [
					{
						field: null,
						function: null,
						operator: null,
						value1: null,
						value2: null
					}
				];
			})();
			this.setPointForm.heading = notiInfo.rules.message.heading;
			this.setPointForm.description = notiInfo.rules.message.description;
			this.setPointForm.notificationGroups = notiInfo.rules.notifyTo.map((id) => {
				const contactItem = this.notificationGroupList.find((item) => item.id === id);
				const title = (() => {
					const channels = {
						email: "Email",
						line: "Line",
						in_app: "In-App"
					};
					const prefix = channels[contactItem.channel] ? `[${channels[contactItem.channel]}]` : `${contactItem.channel}`;
					return `${prefix} ${contactItem.name}`;
				})();
				return {
					id: contactItem.id,
					code: contactItem.id,
					key: title,
					name: title
				};
			});
		}

		this.loading = false;
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		handleCancle() {
			return this.$router.back();
		},
		handleCheckUpdateShipTo(value) {
			this.isAutoUpdateShipTo = value;
		},
		handleCheckUpdateDevice(value) {
			this.isAutoUpdateDevice = value;
		},
		handleChangeSoldTo(value) {
			this.soldToValues = value;
		},
		handleChangeShipTo(value) {
			this.shipToValues = value;
		},
		handleChangeDevice(value) {
			this.deviceValues = value;
		},
		getParams() {
			if (this.isNoSignal) {
				return {
					name: this.ruleName,
					module: this.query.module,
					type: this.query.type,
					frequency: this.noSignalForm.frequency,
					rules: {
						severity: this.noSignalForm.severity,
						query: 0,
						conditions: [],
						message: {
							heading: this.noSignalForm.heading,
							description: this.noSignalForm.description
						},
						notifyTo: this.noSignalForm.notificationGroups.map((item) => item.id)
					},
					appliedTo: {
						isAutoUpdateDevice: this.isAutoUpdateDevice,
						isAutoUpdateShipTo: this.isAutoUpdateShipTo,
						soldTos: this.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						shipTos: this.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						devices: this.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
					},
					isActive: this.active,
					condition: []
				};
			}
			if (this.isSetpoint) {
				return {
					name: this.ruleName,
					module: this.query.module,
					type: this.query.type,
					frequency: this.setPointForm.frequency,
					rules: {
						severity: this.setPointForm.severity,
						query: Math.floor(this.setPointForm.queryTimeInSec / 60),
						conditions: this.setPointForm.conditions
							.filter((item) => (
								!item.deletedAt
							))
							.map((item) => ({
								field: item.field,
								function: item.function,
								operator: item.operator,
								value1: item.value1,
								value2: item.value2
							})),
						message: {
							heading: this.setPointForm.heading,
							description: this.setPointForm.description
						},
						notifyTo: this.setPointForm.notificationGroups.map((item) => item.id)
					},
					appliedTo: {
						isAutoUpdateDevice: this.isAutoUpdateDevice,
						isAutoUpdateShipTo: this.isAutoUpdateShipTo,
						soldTos: this.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						shipTos: this.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						devices: this.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
					},
					isActive: this.active
				};
			}
			return {};
		},
		async handleSubmit() {
			try {
				const data = this.getParams();
				await updateNotification(this.$route.params.id, data);
				this.$router.push({
					name: PAGE_NAME.ALERT_AND_NOTIFICATION_LIST
				});
				this.showToast({
					type: TOAST_TYPES.LAYOUT_CUSTOMISATION,
					props: { message: `Notification rule update success.`, delay: 6000 }
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to save", message: "Notification rule unable to save.", delay: 6000 }
				});
			}
		},
		async handleSubmitDelete(data) {
			try {
				const { dataId } = data;
				await deleteNotificationById(dataId);
				this.$router.push({
					name: PAGE_NAME.ALERT_AND_NOTIFICATION_LIST
				});
				this.showToast({
					type: TOAST_TYPES.LAYOUT_CUSTOMISATION,
					props: { message: `Notification rule delete success.`, delay: 6000 }
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to delete", message: "Notification rule unable to delete.", delay: 6000 }
				});
			}
		},
		openModalConfirmDelete() {
			this.$modal.show(this.MODAL_DELETE, {
				dataId: this.$route.params.id
			});
		},
		handleChangeStatus(checked) {
			this.active = checked;
		}
	}
};
</script>
<style lang="scss" scoped>
.wrapper-actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}

	.btn-delete {
		margin-right: auto;
	}
}

.page-back-btn {
	margin-bottom: rem(24);
}

.head-title {
	font-family: $font-family-sub;
	font-size: $font-32;
	color: $color-dark-blue-grey;
	margin: 0 0 rem(24) 0;
}

.container-form {
	.item {
		margin-bottom: rem(24);
		display: flex;

		&:last-of-type {
			margin-bottom: 0;
		}

		.label {
			display: flex;
			align-items: center;
			min-width: rem(130);
		}

		.form {
			flex-grow: 1;
		}
	}
}

.container-form-status {
	.item {
		display: flex;
		justify-content: space-between;

		.label {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.head {
				color: $color-dark-blue-grey;
			}

			.desc {
				color: $color-grey-4;
			}
		}

		.form {
			span {
				font-size: $font-20;
				color: $color-dark-blue-grey;
				margin-left: rem(16);
			}
		}
	}
}

.custom0wrapper-multi-select {
	margin: 0;
}

.w-200 {
	width: rem(200);
	flex-grow: unset !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-16 {
	margin-bottom: rem(16) !important;
}

.mt-24 {
	margin-top: rem(24) !important;
}
</style>
