import { getUserTypesAPI } from "@/services/api/monitor.api";
import { USER_MONITOR_USER_TYPE_REQUEST, USER_MONITOR_USER_TYPE_SUCCESS, USER_MONITOR_USER_TYPE_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: {
			totalStaff: 0,
			totalCustomer: 0
		},
		isLoading: false
	},
	getters: {
		getTotalStaff(state) {
			return state.data.totalStaff;
		},
		getTotalCustomer(state) {
			return state.data.totalCustomer;
		}
	},

	mutations: {
		[USER_MONITOR_USER_TYPE_REQUEST](state) {
			state.isLoading = true;
			state.data = {
				totalStaff: 0,
				totalCustomer: 0
			};
		},
		[USER_MONITOR_USER_TYPE_SUCCESS](state, data) {
			state.data = {
				totalStaff: data.totalStaffs,
				totalCustomer: data.totalCustomers
			};
			state.isLoading = false;
		},
		[USER_MONITOR_USER_TYPE_FAILURE](state) {
			state.data = {
				totalStaff: 0,
				totalCustomer: 0
			};
			state.isLoading = false;
		}
	},

	actions: {
		async getUserTypes({ commit }, { filter }) {
			try {
				commit(USER_MONITOR_USER_TYPE_REQUEST);
				const { data } = await getUserTypesAPI({ filter });
				commit(USER_MONITOR_USER_TYPE_SUCCESS, data);
			} catch (error) {
				commit(USER_MONITOR_USER_TYPE_FAILURE);
			}
		}
	}
};
