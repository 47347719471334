<template>
	<modal :name="modalName" :scrollable="true" width="420" height="auto">
		<BaseModalContent theme="success" class="custom-modal">
			<template v-slot:header>
				My profile
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div class="item">
						<div class="label">
							Name
						</div>
						<div class="form">
							<strong>
								{{ getProfile.fullName }}
							</strong>
						</div>
					</div>
					<div class="item">
						<div class="label">
							Email
						</div>
						<div class="form email">
							<strong>
								{{ getProfile.email }}
							</strong>
						</div>
					</div>
					<div v-if="getProfile.type === USER_TYPE.CUSTOMER" class="item">
						<div class="label">
							Phone number
						</div>
						<div class="form">
							<strong>
								{{ getProfile.phoneNumber | allowDashString }}
							</strong>
						</div>
					</div>
					<div v-if="getProfile.type === USER_TYPE.CUSTOMER" class="item">
						<div class="label">
							Company
						</div>
						<div class="form">
							<strong>
								{{ getProfile.companyName }}
							</strong>
						</div>
					</div>
					<div class="item">
						<div class="label">
							System role
						</div>
						<div class="form">
							<strong>
								{{ getProfile.roleName }}
							</strong>
						</div>
					</div>
				</div>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import { mapGetters } from "vuex";
import BaseModalContent from "@/components/BaseModalContent.vue";
import { USER_TYPE } from "../../enums/user";

export default {
	name: "ModalUserProfile",

	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			MODAL_NAME_USER_PROFILE: "modal-user-profile",
			USER_TYPE
		};
	},

	computed: {
		...mapGetters("user", {
			getProfile: "getProfile"
		})
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			align-items: center;
			width: rem(92);
		}

		.form {
			flex-grow: 1;

			&.email {
				max-width: rem(262);
				word-break: break-word;
			}
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}

.custom-modal {
	/* stylelint-disable */
	&::v-deep .header {
		padding: $spacing-4 $spacing-7;
	}

	&::v-deep .body {
		padding: $spacing-7;
	}
	/* stylelint-enable */
}
</style>