import { getElectricalConductivity } from "@/services/api/Zlds.api";
import {
	ZLD_ELECTRICAL_REQUEST,
	ZLD_ELECTRICAL_SUCCESS,
	ZLD_ELECTRICAL_FAILURE
} from "../../types";
import { transformRecycleWaterLineChartData } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		empty: true
	},
	getters: {
		getDataElectricalConductivity: (state) => {
			return transformRecycleWaterLineChartData(state);
		}
	},

	mutations: {
		[ZLD_ELECTRICAL_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[ZLD_ELECTRICAL_SUCCESS](state, response) {
			state.data = response.data;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
			state.isEmpty = response.data.length === 0;
		},
		[ZLD_ELECTRICAL_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchElectricalConductivity({ commit }, { id = "", query = {} }) {
			try {
				commit(ZLD_ELECTRICAL_REQUEST);
				const { data = [] } = await getElectricalConductivity(id, query);

				commit(ZLD_ELECTRICAL_SUCCESS, data);
			} catch (error) {
				commit(ZLD_ELECTRICAL_FAILURE);
			}
		}
	}
};
