<template>
	<modal :name="modalName" :scrollable="true" :width="width" :height="height">
		<BaseModalContent theme="success" class="custom-modal">
			<template v-slot:header>
				Business Philosophy
				<span class="close-button" @click="closeModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<iframe class="iframe-about-us" src="https://www.bigth.com/en/company/philosophy"></iframe>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	name: "ModalAboutUs",

	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},
		width: {
			type: String,
			default: () => ("80%")
		},
		height: {
			type: String,
			default: () => ("80%")
		}
	},
	methods: {
		closeModal() {
			this.$modal.hide(this.modalName);
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-modal {
	/* stylelint-disable */
	&::v-deep {
		.header {
			padding: $spacing-4 $spacing-7;

			.close-button {
				color: $color-white;
			}
		}

		.body {
			padding: 0;
			height: calc(100% - 60px);
		}
	}
	/* stylelint-enable */
	.iframe-about-us {
		width: 100%;
		height: 100%;
		border: 0;
	}
}
</style>
