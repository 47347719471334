import httpClient from "./httpClient";

const SOLD_TO_END_POINT = "/sold-tos";
const updateSoldToPinAPI = (id) => httpClient.put(`${SOLD_TO_END_POINT}/${id}/pin`);
const updateSoldToUnpinAPI = (id) => httpClient.put(`${SOLD_TO_END_POINT}/${id}/unpin`);

export {
	updateSoldToPinAPI,
	updateSoldToUnpinAPI
};
