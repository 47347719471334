export const transformShipToStatusDevice = (item = {}) => {
	return { id: item.id,
		shipId: item.shipId,
		productName: item.productName,
		module: item.module,
		isActive: item.isActive,
		isVisible: item.isVisible,
		workspaceId: item.workspaceId,
		reportId: item.reportId	};
};

export const transformShipToStatusDevices = (items = []) => {
	return items.map((item) => transformShipToStatusDevice(item));
};

export const transformShipToStatus = (item = {}) => {
	return {
		id: item.id,
		name: item.name,
		code: item.code,
		locationKey: item.locationKey,
		isActive: item.isActive,
		isVisible: item.isVisible,
		devices: transformShipToStatusDevices(item.devices || [])
	};
};

export const transformShipToStatuses = (items = []) => {
	return items.map((item) => transformShipToStatus(item));
};