<template>
	<div class="furnace-info">
		<BaseLoading v-if="furnaceInfo.isLoading" />
		<div :class="[{'row row-eq-height': isShowShipTo}, { 'hide-content': furnaceInfo.isLoading }]">
			<div :class="['col-12 no-gutter-sm', { 'col-xl-7': isShowShipTo }]">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ furnaceInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview
							v-if="furnaceInfo.data.id"
							:data="furnaceInfo.data"
						/>
						<BaseNoData
							v-else
							description="BIG portal will start showing the overview right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
			<ModuleLocation v-if="isShowShipTo" :info="furnaceInfo.data" />
		</div>
		<div class="furnance-level-container">
			<FurnaceTemperatureChart
				:data="getFurnaceTemperatureChartData"
				:furnaceTemperature="furnaceTemperature"
				class="card-line-chart"
			/>
			<FurnacePressureChart
				:data="furnacePressure.detail.data"
				:updatedAt="furnacePressure.detail.updatedAt"
				:tagName="furnacePressure.detail.tagName"
				:legendPressureChart="furnacePressure.detail.legendPressureCharts"
				:loading="furnacePressure.loading"
				:empty="furnacePressure.empty"
				@filter="handleFilterPressure"
			/>
			<FurnaceNaturalGasChart
				:data="furnaceNaturalGas.detail.data"
				:updatedAt="furnaceNaturalGas.detail.updatedAt"
				:tagName="furnacePressure.detail.tagName"
				:loading="furnaceNaturalGas.loading"
				:empty="furnaceNaturalGas.empty"
				@filter="handleFilterNaturalGas"
			/>
			<FurnaceNaturalGasConsumptionChart
				:furnaceConsumption="furnaceConsumption"
				@filter="handleFilterConsumption"
			/>
			<FurnaceGlassLevelLineChart
				:data="furnaceGlassLevel.detail.data"
				:updatedAt="furnaceGlassLevel.detail.updatedAt"
				:loading="furnaceGlassLevel.loading"
				:empty="furnaceGlassLevel.empty"
				@filter="handleFilterGlassLevel"
			/>
			<GrafanaDisplay
				:deviceId="furnaceId"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import FurnaceTemperatureChart from "@/components/FurnaceModule/FurnaceTemperatureChart.vue";
import FurnacePressureChart from "@/components/FurnaceModule/FurnacePressureChart.vue";
import FurnaceNaturalGasConsumptionChart from "@/components/FurnaceModule/FurnaceNaturalGasConsumptionChart.vue";
import FurnaceNaturalGasChart from "@/components/FurnaceModule/FurnaceNaturalGasChart.vue";
import FurnaceGlassLevelLineChart from "@/components/FurnaceModule/FurnaceGlassLevelLineChart.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";

const { perDay } = MODULE_SORT_DATE_CHART;

export default {
	name: "furnaceInfo",
	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		BaseNoData,
		ModuleLocation,
		ModuleOverview,
		FurnaceTemperatureChart,
		FurnacePressureChart,
		FurnaceNaturalGasConsumptionChart,
		FurnaceNaturalGasChart,
		FurnaceGlassLevelLineChart
	},
	data() {
		return {
			furnaceId: this.$route.params.id
		};
	},

	computed: {
		...mapState(["furnaceInfo", "furnaceTemperature", "furnaceConsumption", "furnacePressure", "furnaceNaturalGas", "furnaceGlassLevel"]),
		...mapGetters("furnaceTemperature", {
			getFurnaceTemperatureChartData: "getFurnaceTemperatureChartData"
		}),

		isShowShipTo() {
			return this.furnaceInfo.data?.shipToLongitude;
		}
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("furnaceTemperature", {
			fetchFurnaceTemperature: "fetchFurnaceTemperature",
			fetchFurnaceTemperatureChartList: "fetchFurnaceTemperatureChartList"
		}),
		...mapActions("furnaceConsumption", {
			fetchConsumptionData: "fetchConsumptionData"
		}),
		...mapActions("furnacePressure", {
			fetchFurnacePressure: "fetchFurnacePressure"
		}),
		...mapActions("furnaceNaturalGas", {
			fetchFurnaceNaturalGas: "fetchFurnaceNaturalGas"
		}),
		...mapActions("furnaceGlassLevel", {
			fetchFurnaceGlassLevel: "fetchFurnaceGlassLevel"
		}),

		fetchData() {
			return Promise.all([
				this.fetchFurnaceTemperature({ id: this.furnaceId, query: { } }),
				this.fetchFurnaceTemperatureChartList({ id: this.furnaceId }),
				this.fetchConsumptionData({ id: this.furnaceId }),
				this.fetchFurnacePressure({
					id: this.furnaceId,
					params: {
						filter: perDay
					}
				}),
				this.fetchFurnaceNaturalGas({
					id: this.furnaceId,
					params: {
						filter: perDay
					}
				}),
				this.fetchFurnaceGlassLevel({
					id: this.furnaceId,
					params: {
						filter: perDay
					}
				})
			]);
		},

		handleFilterConsumption(dateNumber) {
			this.fetchConsumptionData({	id: this.furnaceId, filterBy: dateNumber });
		},

		handleFilterPressure(params = {}) {
			this.fetchFurnacePressure({ id: this.furnaceId, params });
		},

		handleFilterNaturalGas(params = {}) {
			this.fetchFurnaceNaturalGas({ id: this.furnaceId, params });
		},

		handleFilterGlassLevel(params = {}) {
			this.fetchFurnaceGlassLevel({ id: this.furnaceId, params });
		}
	}
};
</script>

<style lang="scss" scoped>
.furnace-info {
	::v-deep {
		.chart-title {
			position: relative;
			left: initial;

			margin-top: rem(24);

			transform: initial;
			-webkit-transform: initial;
		}
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.furnance-level-container {
	@include mobile {
		border-radius: 0;
		margin-left: rem(-16);
		margin-right: rem(-16);
		padding: rem(16) 0;
	}

	::v-deep {
		.detail-card-header {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: rem(16);

			@include mobile {
				align-items: flex-start;
				flex-direction: column;
				padding: 0 rem(16);

				& > * {
					margin-left: 0;
				}

				.button-group-container {
					width: 100%;

					.btn {
						justify-content: center;
						width: calc(100% / 3);
					}
				}
			}
		}
	}
}

</style>
