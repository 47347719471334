import { getMonitorActivityLogAPI } from "@/services/api/monitor.api";
import {
	USER_MONITOR_ACTIVITY_REQUEST,
	USER_MONITOR_ACTIVITY_SUCCESS,
	USER_MONITOR_ACTIVITY_FAILURE
} from "../../types";
import { parseActivityLogs } from "../../../services/api/transforms/parseUserMonitor";

export default {
	namespaced: true,

	state: {
		activityLog: {
			data: parseActivityLogs({}),
			isLoading: false
		}
	},

	getters: {
		activityLog: ({ activityLog }) => activityLog
	},

	mutations: {
		[USER_MONITOR_ACTIVITY_REQUEST](state) {
			state.activityLog.isLoading = true;
			state.activityLog.data = parseActivityLogs({});
		},
		[USER_MONITOR_ACTIVITY_SUCCESS](state, data) {
			state.activityLog.data = parseActivityLogs(data);
			state.activityLog.isLoading = false;
		},
		[USER_MONITOR_ACTIVITY_FAILURE](state) {
			state.activityLog.data = parseActivityLogs({});
			state.activityLog.isLoading = false;
		}
	},

	actions: {
		async getActivityLogs({ commit }) {
			try {
				commit(USER_MONITOR_ACTIVITY_REQUEST);
				const { data } = await getMonitorActivityLogAPI();
				commit(USER_MONITOR_ACTIVITY_SUCCESS, data);
			} catch (error) {
				commit(USER_MONITOR_ACTIVITY_FAILURE);
			}
		}
	}
};
