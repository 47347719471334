/**
 * Enum for overview type.
 * @readonly
 * @enum {EnumOverviewType}
 */
export const overviewType = Object.freeze({
	TITLE: "title",
	TITLE_COLOR_BAR: "titleColorBar",
	TITLE_PROGRESS_BAR: "titleProgressBar",
	SUBTITLE: "subtitle"
});

/**
 * Enum for StandModuleView.
 * @readonly
 * @enum {EnumOverviewType}
 */
export const standModuleView = Object.freeze({
	STANDARD_CREATE: "stdCreate",
	STANDARD_UPDATE: "stdUpdate"
});