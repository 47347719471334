import { getPowerConsumptionsAPI } from "@/services/api/pwr.api";
import { POWER_CONSUMPTION_REQUEST, POWER_CONSUMPTION_SUCCESS, POWER_CONSUMPTION_FAILURE } from "../../types";
import { parsePowerConsumption } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parsePowerConsumption({}),
		loading: true,
		empty: false
	},

	mutations: {
		[POWER_CONSUMPTION_REQUEST](state) {
			state.detail = parsePowerConsumption({});
			state.loading = true;
			state.empty = false;
		},
		[POWER_CONSUMPTION_SUCCESS](state, data) {
			const detail = data;
			state.detail = parsePowerConsumption(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[POWER_CONSUMPTION_FAILURE](state) {
			state.detail = parsePowerConsumption({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getPowerConsumption({ commit }, { id, params }) {
			try {
				commit(POWER_CONSUMPTION_REQUEST);
				const { data } = await getPowerConsumptionsAPI(id, params);
				commit(POWER_CONSUMPTION_SUCCESS, data);
			} catch (error) {
				commit(POWER_CONSUMPTION_FAILURE, error);
			}
		}
	}
};
