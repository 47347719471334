<template>
	<modal :name="modalName" :scrollable="true" width="390px" height="172px">
		<BaseModalContent theme="success" class="custom-modal">
			<template v-slot:header>
				Contact us
				<span class="close-button" @click="closeModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="contact-wrapper">
					<div class="contact-describe">
						Please contact our customer support team for support
					</div>
					<div class="email-contact">
						<div class="type">
							<FontAwesomeIcon :icon="['far', 'envelope']" class="icon"/>
						</div>
						<div class="text">
							<a class="email" href="mailto: mkt@bigth.com">
								mkt@bigth.com
							</a>
						</div>
					</div>
				</div>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	name: "ModalContactUs",

	props: {
		modalName: {
			type: String,
			default: ""
		}
	},

	components: {
		BaseModalContent
	},

	methods: {
		closeModal() {
			this.$modal.hide(this.modalName);
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-modal {
	/* stylelint-disable */
	&::v-deep {
		.header {
			padding: rem(12) rem(24);

			.close-button {
				color: $color-white;
			}
		}

		.body {
			padding: 0;
		}
	}
	/* stylelint-enable */
}

.contact-wrapper {
	display: flex;
	flex-direction: column;
	padding: rem(24);
}

.contact-describe {
	color: $color-dark-blue-grey;
	margin-bottom: rem(8);
}

.email-contact {
	display: flex;
	align-items: center;

	.email {
		color: $color-cerulean;
		font-weight: $font-weight-bold;
	}

	.type {
		width: rem(24);
		height: rem(24);
		margin-right: $spacing-3;
		background-color: $color-green-haze;
		border-radius: 50%;

		text-align: center;

		.icon {
			color: $color-white;
			font-size: $font-12;
		}
	}
}
</style>