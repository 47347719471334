import { getModules } from "@/services/api/modules.api";
import {
	MODULES_REQUEST,
	MODULES_SUCCESS,
	MODULES_FAILURE,
	UPDATE_SOLDTO_PIN_STATUS
} from "../../types";

export default {
	state: {
		data: [],
		isLoading: false,
		pagination: {},
		isNoShipTo: false
	},

	mutations: {
		[MODULES_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
		},
		[MODULES_SUCCESS](state, { data, pagination }) {
			state.isLoading = false;
			state.data = data;
			state.pagination = pagination;
		},
		[MODULES_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isNoShipTo = true;
		},
		[UPDATE_SOLDTO_PIN_STATUS](state, { id, status }) {
			state.data = state.data.map((item) => {
				return {
					...item,
					isPinned: (() => {
						if (item.soldToId === id) {
							return status;
						}
						return item.isPinned;
					})()
				};
			});
		}
	},

	actions: {
		async getModules({ commit }, {
			page = null,
			module = null,
			sort = null,
			q = null,
			productname = null,
			company = null,
			owner = null
		}) {
			try {
				commit(MODULES_REQUEST);

				const { data } = await getModules({
					page,
					module,
					sort,
					q,
					productname,
					soldToId: company,
					owner
				});

				commit(MODULES_SUCCESS, data);
			} catch (error) {
				commit(MODULES_FAILURE);
			}
		},

		updateSoldToPinStatus({ commit }, { id, status }) {
			commit(UPDATE_SOLDTO_PIN_STATUS, { id, status });
		}
	}
};
