import { getMonitorOverallAPI } from "@/services/api/monitor.api";
import {
	USER_MONITOR_OVERALL_REQUEST,
	USER_MONITOR_OVERALL_SUCCESS,
	USER_MONITOR_OVERALL_FAILURE
} from "../../types";
import { parseOverallMonitor } from "../../../services/api/transforms/parseOverallMonitor";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false
	},

	getters: {
		overall: ({ data }) => {
			return [
				{
					icon: {
						type: "far",
						name: "sign-in",
						width: "12px",
						height: "13px",
						top: "0px",
						left: "0px"
					},
					title: "Total sign-in",
					total: {
						number: data?.totalSignIn?.total,
						unit: "sessions"
					},
					growth: [
						{
							number: data?.totalSignIn?.thisMonth,
							title: "This month",
							showRank: true,
							showIntegerType: true
						},
						{
							number: data?.totalSignIn?.thisYear,
							title: "This year",
							showRank: true,
							showIntegerType: true
						}
					]
				},
				{
					icon: {
						type: "far",
						name: "user-plus",
						width: "15px",
						height: "13px",
						top: "-1px",
						left: "1px"
					},
					title: "Total user created",
					total: {
						number: data?.totalUsers?.total,
						unit: "users"
					},
					growth: [
						{
							number: data?.totalUsers?.thisMonth,
							title: "This month",
							showRank: true,
							showIntegerType: true
						},
						{
							number: data?.totalUsers?.thisYear,
							title: "This year",
							showRank: true,
							showIntegerType: true
						},
						{
							number: data?.totalUsers?.activeUser,
							title: "Active user",
							showRank: false,
							showIntegerType: false
						}
					]
				},
				{
					icon: {
						type: "far",
						name: "user-clock",
						width: "15px",
						height: "13px",
						top: "-1px",
						left: "1px"
					},
					title: "Total activity",
					total: {
						number: data?.totalActivities?.total,
						unit: "activities"
					},
					growth: [
						{
							number: data?.totalActivities?.thisMonth,
							title: "This month",
							showRank: true,
							showIntegerType: true
						},
						{
							number: data?.totalActivities?.thisYear,
							title: "This year",
							showRank: true,
							showIntegerType: true
						}
					]
				}
			];
		}
	},

	mutations: {
		[USER_MONITOR_OVERALL_REQUEST](state) {
			state.isLoading = true;
			state.data = {};
		},
		[USER_MONITOR_OVERALL_SUCCESS](state, data) {
			state.data = parseOverallMonitor(data);
			state.isLoading = false;
		},
		[USER_MONITOR_OVERALL_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		async getOverall({ commit }) {
			try {
				commit(USER_MONITOR_OVERALL_REQUEST);
				const { data } = await getMonitorOverallAPI();
				commit(USER_MONITOR_OVERALL_SUCCESS, data);
			} catch (error) {
				commit(USER_MONITOR_OVERALL_FAILURE);
			}
		}
	}
};
