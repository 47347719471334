import { getTanksLevelAPI } from "@/services/api/tanks.api";
import {
	TANK_LEVEL_REQUEST,
	TANK_LEVEL_SUCCESS,
	TANK_LEVEL_FAILURE
} from "../../types";
import { transformLineChartData, transformForecastMarkAreaData } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false,
		stateLevel: {},
		lastForecastDate: "",
		forecastData: []
	},

	getters: {
		getTankLevelData: (state) => {
			return transformLineChartData(state);
		},
		getLevelStateData: (state) => {
			return state.stateLevel;
		},
		getForecastMarkAreaData: (state) => {
			return transformForecastMarkAreaData(state.forecastData);
		}
	},

	mutations: {
		[TANK_LEVEL_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[TANK_LEVEL_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.stateLevel = {
				emptyLevel: response.emptyLevel || 0,
				refillLevel: response.refillLevel || 0,
				reorderLevel: response.reorderLevel || 0
			};
			state.forecastData = response.forecastData || [];
			state.lastForecastDate = response.lastForecastDate;
			state.isLoading = false;
		},
		[TANK_LEVEL_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getTankLevel({ commit }, { id = "", query = {} }) {
			try {
				commit(TANK_LEVEL_REQUEST);

				const { data = [] } = await getTanksLevelAPI(id, query);
				commit(TANK_LEVEL_SUCCESS, data);
			} catch (error) {
				commit(TANK_LEVEL_FAILURE);
			}
		}
	}
};
