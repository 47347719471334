<template>
	<BaseLayout pageTitle="Invoice">
		<SearchFilterInvoice
			v-if="renderComponent"
			:soldToOptions="soldToOptions"
			:shipToOptions="shipToOptions"
			@onSelectSoldTo="handleSelectSoldTo"
			@onSearchFilter="handleSearchInvoiceList"
			@clearFilter="handleClear"
		/>

		<div class="wrapper-invoice-layout">
			<div class="thead">
				<div class="thead-item head">
					Ship-to
				</div>
				<div class="thead-item">
					Invoice No.
				</div>
				<div class="thead-item align-right">
					Invoice date
				</div>
				<div class="thead-item align-right">
					Due date
				</div>
				<div class="thead-item align-right pr-3">
					Total
					<div class="subtitle">VAT & WHT included</div>
				</div>
				<div class="thead-item align-center">
					Status
				</div>
				<div class="thead-item">
					Attached file
				</div>
			</div>

			<BaseLoading
				v-if="isLoading"
			/>


			<BaseNoResult
				v-if="!isSearched && !isLoading"
				icon="file-invoice-dollar"
				title="Please select sold-to and ship-to"
				description="Invoice will be displayed once you select sold-to and ship-to"
			/>

			<BaseNoResult
				v-else-if="permissionDenied && isSearched"
				class="empty"
				icon="exclamation-circle"
				title="Permission required"
				description="Permission denied, please contact admin"
			/>

			<BaseNoResult
				v-else-if="isEmpty && !isLoading"
				class="empty"
				icon="file-invoice-dollar"
				title="No invoices found"
				description="Try adjusting your filter or contact BIG support"
			/>


			<div
				v-else-if="!isEmpty && isSearched && !isLoading"
				class="tbody-body-wrapper"
			>
				<BaseInvoiceCard
					v-for="invoice in invoiceLists"
					:key="invoice.soldToCode +'-'+invoice.invoiceNo"
					:shipToName="invoice.shipTo.name"
					:invoiceNumber="invoice.invoiceNo"
					:docType="invoice.docType"
					:dueDate="invoice.dueDate"
					:invoiceTotal="invoice.netPrice"
					:invoiceDate="invoice.invoiceDate"
					:status="invoice.statusDescription"
					:invoiceFileUrl="invoice.invoiceFileUrl"
					:receiptFileUrl="invoice.receiptFileUrl"
					:otherFileUrl="invoice.otherFileUrl"
					:deliveryOrders="invoice.deliveryOrders"
				/>
			</div>
		</div>
		<BasePagination
			v-if="pagination.lastPage > 1 && !isLoading"
			:value="pagination.currentPage"
			:totalPage="pagination.lastPage"
			class="module-pagination"
			@onPageChange="handlePageChange"
		/>

	</BaseLayout>
</template>

<script>
import dayjs from "dayjs";
import BaseLayout from "@/components/BaseLayout.vue";
import BaseInvoiceCard from "@/components/invoice/BaseInvoiceCard.vue";
import SearchFilterInvoice from "@/components/invoice/SearchFilterInvoice.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { trasnfromOptionShiptoList, transformOptionSoldToList } from "../../selectors/transform/invoice";
import { getOwnSoldToAPI, getInvoiceListAPI } from "../../services/api/invoice.api";
import { getShipTosListAPI } from "../../services/api/shipTos.api";

export default {
	components: {
		BaseLayout,
		BaseInvoiceCard,
		SearchFilterInvoice,
		BaseNoResult,
		BaseLoading
	},
	data() {
		let shipToIds = null;
		if (typeof this.$route.query.shipToIds === "string") {
			shipToIds = [
				this.$route.query.shipToIds
			];
		} else {
			shipToIds = [
				...(this.$route.query.shipToIds ? this.$route.query.shipToIds : [])
			];
		}

		return {
			isLoading: false,
			isSearched: false,
			isEmpty: false,
			permissionDenied: true,
			query: {
				soldToId: this.$route.query.soldToId ?? null,
				shipToIds,
				page: this.$route.query.page ?? 1,
				invoiceNumber: this.$route.query.invoiceNumber ?? null,
				startInvoiceDate: this.$route.query.startInvoiceDate ?? null,
				endInvoiceDate: this.$route.query.endInvoiceDate ?? null,
				startDueDate: this.$route.query.startDueDate ?? null,
				endDueDate: this.$route.query.endDueDate ?? null,
				status: this.$route.query.status ?? null
			},
			pagination: {},
			invoiceLists: [],
			soldToOptions: [],
			shipToOptions: [],
			renderComponent: true
		};
	},
	async created() {
		this.isLoading = true;
		const { data: { data = [] } } =	await getOwnSoldToAPI({
			deviceStatus: "all",
			all: 1
		});

		if (this.$route.query.soldToId) {
			await this.handleSelectSoldTo(this.$route.query.soldToId);
		}

		this.soldToOptions = trasnfromOptionShiptoList(data);
		this.forceRerender();

		if (this.query.soldToId && this.query.shipToIds.legnth !== 0) {
			const { data: { data: invoiceList = [], pagination = {}, isPermissionDenied = false } } = await getInvoiceListAPI({
				soldToId: this.query.soldToId,
				...(this.query.invoiceNumber ? { invoiceNumber: this.query.invoiceNumber } : {}),
				...(this.query.status ? { status: this.query.status } : {}),
				page: this.query.page,
				shipToIds: this.query.shipToIds.filter((code) => code !== "all"),
				startInvoiceDate: this.query.startInvoiceDate,
				endInvoiceDate: this.query.endInvoiceDate,
				startDueDate: this.query.startDueDate,
				endDueDate: this.query.endDueDate
			});

			this.isSearched = true;
			this.isEmpty = invoiceList.length === 0;
			this.pagination = pagination;
			this.invoiceLists = invoiceList;
			this.permissionDenied = isPermissionDenied;
		}
		this.isLoading = false;
	},
	methods: {
		forceRerender() {
			this.renderComponent = false;
			this.$nextTick(() => {
				this.renderComponent = true;
			});
		},
		async handleSelectSoldTo(soldToId) {
			const { data: { data = [] } } = await getShipTosListAPI({
				all: 1,
				soldToId,
				deviceStatus: "all"
			});
			this.shipToOptions = transformOptionSoldToList(data);
		},
		handlePageChange(page) {
			this.query.page = page;
			this.$router.push({
				...this.route,
				query: { ...this.query }
			}).catch(() => {});
		},

		handleClear() {
			this.$router.push({
				...this.route
			}).catch(() => {});
		},

		async handleSearchInvoiceList({ soldToId = "", shipToIds = [], invoiceNumber = "", ...props }) {
			const { startInvoiceDate, endInvoiceDate,
				startDueDate, endDueDate, status } = props;

			const invoiceDate = startInvoiceDate && endInvoiceDate ? {
				startInvoiceDate: dayjs(startInvoiceDate).startOf("day").format(),
				endInvoiceDate: dayjs(endInvoiceDate).endOf("day").format()
			} : {};


			const dueDate = startDueDate && endDueDate ? {
				startDueDate: dayjs(startDueDate).startOf("day").format(),
				endDueDate: dayjs(endDueDate).endOf("day").format()
			} : {};

			this.query = {
				soldToId,
				shipToIds,
				...(invoiceNumber ? { invoiceNumber } : {}),
				...invoiceDate,
				...dueDate,
				...(status ? { status } : {}),
				page: this.page
			};

			this.$router.push({
				...this.route,
				query: { ...this.query }
			}).catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.thead {
	display: flex;
	padding: rem(10) rem(24);
	border-bottom: 1px solid $color-emerald;
	font-weight: $font-weight-bold;
	font-size: rem(16);
	color: $color-dark-blue-grey;
	padding-right: rem(104);

	&.invoice-header {
		padding: rem(10) rem(104) 0 0;
	}
}

.thead-item,
.tbody-item {
	font-size: rem(18);

	width: calc(100% / 7);

	&.head {
		width: calc((100% / 7) * 2);
	}
}

.empty {
	/* stylelint-disable */
	&::v-deep {
		.no-result-icon {
			background-color: $color-light-teal;

			svg {
				color: $color-gunmetal-two;
			}
		}
	}
	/* stylelint-enable */
}

.attach-wrapper {
	display: flex;
	font-size: rem(16);
	margin-top: rem(-2);
	flex-wrap: wrap;
	justify-content: space-between;
	/* stylelint-disable */
	&::v-deep {
		.btn {
			&.small {
				padding: 0;
				margin: 0;
			}
		}
	}
	/* stylelint-enable */
}

.subtitle {
	font-size: rem(14);
	color: $color-grey-4;
	margin-top: rem(-4);
}

.align {
	&-right {
		text-align: right;
	}

	&-center {
		text-align: center;
	}
}

.tbody-wrapper {
	margin-top: rem(8);
}

.invoice-body {
	display: flex;
	padding-right: rem(104);
}

.invoice-card {
	&-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		margin: rem(16) 0;
		border: 1px solid $color-silver;
		border-radius: rem(4);

		&.active {
			.invoice-card-body {
				display: block;
			}

			.arrow-icon {
				transform: rotate(180deg);
				transition: transform 0.5ms linear;
			}
		}
	}

	&-header {
		display: flex;
		align-items: flex-start;
		position: relative;
		padding: rem(4) rem(104) 0 rem(24);
		min-height: rem(54);

		.arrow {
			position: absolute;
			top: rem(8);
			right: rem(24);
			color: $color-cerulean;
			cursor: pointer;

			&-icon {
				transition: transform 0.5ms linear;
			}
		}
	}

	&-body {
		display: none;
		background-color: $color-alabaster;
		min-height: rem(174);
		padding: rem(16) rem(24);
		border-radius: 0 0 rem(4) rem(4);

		.invoice-title {
			font-size: rem(20);
			color: $color-dark-blue-grey;
		}
	}
}

.status {
	font-weight: $font-weight-bold;

	&.in-progress {
		color: $color-grey-4;
	}

	&.paid {
		color: $color-emerald;
	}

	&.reject {
		color: $color-red;
	}
}

.icon {
	font-size: rem(14);
}

.ml {
	&-0 {
		margin-left: rem(0);
	}

	&-1 {
		margin-left: rem(4);
	}

	&-2 {
		margin-left: rem(8);
	}

	&-3 {
		margin-left: rem(12);
	}

	&-4 {
		margin-left: rem(16);
	}
}

.pl {
	&-2 {
		padding-left: rem(8);
	}

	&-14 {
		padding-left: rem(56);
	}
}

.pr {
	&-3 {
		padding-right: rem(12);
	}

	&-8 {
		padding-right: rem(32);
	}

	&-14 {
		padding-right: rem(56);
	}
}

.wrapper-invoice-layout {
	display: flex;
	flex-direction: column;
}

@include tablet() {
	.wrapper-invoice-layout {
		overflow: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.thead,
	.tbody-body-wrapper {
		min-width: rem(860);
	}
}

</style>