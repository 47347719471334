<template>
	<div>
		<GrafanaDisplay
			:deviceId="deviceId"
			:tab="tab"
		/>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";

export default {
	name: "StandardModuleGrafana",

	components: {
		GrafanaDisplay
	},

	data() {
		return {
			deviceId: this.$route.params.id,
			tab: this.$route.query.tab
		};
	},

	created() {
		this.addActivityLogGrafanaViewTab({
			deviceId: this.deviceId,
			title: this.tab,
			httpStatus: 200
		});
	},

	methods: {
		...mapActions("activityLogAdd", {
			addActivityLogGrafanaViewTab: "addActivityLogGrafanaViewTab"
		})
	}
};
</script>