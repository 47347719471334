<template>
	<BaseLayout :isLoading="isLoading">
		<BaseBackBtn class="page-back-btn" :to="PAGE_NAME.STANDARD_MODULE_LIST" label="Back to Standard module list"/>
		<StandardModuleOverviewForm :type="standModuleView.STANDARD_UPDATE" :editData="editData" @submit="showModalConfirmUpdate"/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_CONFIRM_UPDATE"
			title="Warning"
			:message="modalMessageConfirmUpdate"
			submitButtonText="Confirm"
			@onCancel="handleModalCancelUpdate"
			@onConfirm="handleSendPayload"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import StandardModuleOverviewForm from "@/components/StandardModule/StandardModuleOverviewForm.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { standModuleView } from "../../enums/standardModule";

export default {
	name: "standardModuleEdit",

	components: {
		BaseLayout,
		BaseBackBtn,
		StandardModuleOverviewForm,
		BaseModalConfirmDelete
	},

	data() {
		return {
			id: this.$route.params.id,
			PAGE_NAME,
			standModuleView,
			editData: null,
			MODAL_CONFIRM_UPDATE: "model-confirm-standard-module-update"
		};
	},

	async created() {
		await Promise.all([this.getGraphOptions(), this.getApiTagNameOptions(), this.getApplicationOptions(), this.getStandardTemplateEdit(this.$route.params.id)]);
		this.editData = this.data;
	},

	computed: {
		...mapState("standardModuleEdit", {
			data: "data",
			isSuccessUpdateStdModuleTemplate: "isSuccessUpdateStdModuleTemplate",
			isSuccessUpdateStdModuleTemplateData: "isSuccessUpdateStdModuleTemplateData",
			isLoading: "isLoading"
		}),

		...mapState("standardModuleCreate", {
			isSucesssCreateStdModuleTemplate: "isSucesssCreateStdModuleTemplate",
			createTemplateId: "createTemplateId"
		}),

		modalMessageConfirmUpdate() {
			return `<div>
				<h4>This change will replace everything to all devices specified in the Apply to section.</h4>
				<div>Are you sure you want to continue?</div>
			</div>`;
		}
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions("standardModuleCreate", {
			sendStandardTemplateCreate: "sendStandardTemplateCreate",
			getGraphOptions: "getGraphOptions",
			getApiTagNameOptions: "getApiTagNameOptions",
			getApplicationOptions: "getApplicationOptions"
		}),
		...mapActions("standardModuleEdit", {
			getStandardTemplateEdit: "getStandardTemplateEdit",
			updateStandardTemplate: "updateStandardTemplate"
		}),

		...mapActions("activityLogAdd", {
			addActivityLogStandardModuleCreate: "addActivityLogStandardModuleCreate"
		}),

		getApplyToData(forms) {
			return {
				soldTos: forms.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
				shipTos: forms.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
				devices: forms.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
			};
		},

		parseOverviewsUpdate(overviews = []) {
			return overviews.map((item, index) => {
				return {
					id: item.id ?? null,
					column_type: item.type,
					api_tag_name: item.apiTagName,
					title: item.title,
					value_min: item.min === "" ? null : item.min ?? null,
					value_max: item.max === "" ? null : item.max ?? null,
					priority: index + 1,
					status: item.status,
					bar_type: null
				};
			}) || [];
		},

		parseOverviewsCreate(overviews = []) {
			return overviews.map((item, index) => {
				return {
					column_type: item.type,
					api_tag_name: item.apiTagName,
					title: item.title,
					value_min: item.min === "" ? null : item.min ?? null,
					value_max: item.max === "" ? null : item.max ?? null,
					priority: index + 1,
					status: item.status,
					bar_type: null
				};
			}) || [];
		},

		showModalConfirmUpdate(data) {
			if (data.type === standModuleView.STANDARD_UPDATE) {
				this.$modal.show(this.MODAL_CONFIRM_UPDATE, data);
			} else {
				this.handleSendPayload(data);
			}
		},

		handleModalCancelUpdate() {
			this.$modal.hide(this.MODAL_CONFIRM_UPDATE);
		},

		async handleSendPayload({ type, forms, titleAsNewTemplate }) {
			if (type === standModuleView.STANDARD_UPDATE) {
				await this.handleSendPayloadForUpdate(forms);
			} else {
				await this.handleSendPayloadForCreateAsNewTemplate(forms, titleAsNewTemplate);
			}
		},
		async handleSendPayloadForUpdate(forms) {
			const stdModulePayload = {
				id: forms.id,
				title: forms.title,
				app: forms.appValue,
				api_url: forms.apiURL,
				graph_id: forms.graphValue,
				graph_unit: forms.graphUnit,
				graph_min: forms.graphMin === "" ? null : forms.graphMin ?? null,
				graph_max: forms.graphMax === "" ? null : forms.graphMax ?? null,
				graph_api_tags: forms.graphApiTagNames,
				condition_status: forms.status,
				overviews: this.parseOverviewsUpdate(forms.overviews),
				applied_to: this.getApplyToData(forms),
				// always disable history status when haven't historyApiURL
				history_status: forms.historyApiURL ? forms.historyStatus : false,
				analysis_status: forms.analysisStatus,
				history_api_url: forms.historyApiURL,
				history: {
					raw_select: forms.selectColumns,
					sql_select: forms.selectColumns.map((item) => { return `${item.select} AS ${item.as}`; }),
					sql_from: forms.from,
					sql_additional_where: forms.whereCondition
				}
			};
			await this.updateStandardTemplate(stdModulePayload);
			if (this.isSuccessUpdateStdModuleTemplate) {
				this.$router.push({
					name: PAGE_NAME.STANDARD_MODULE_LIST
				});
				if (this.isSuccessUpdateStdModuleTemplateData.length > 0) {
					const ListDevices = this.isSuccessUpdateStdModuleTemplateData.join(", ");
					this.showToast({
						type: TOAST_TYPES.RESPONSE_ERROR,
						props: {
							title: "Not able to apply to all selected device(s)",
							message: `It looks like there is/are some device(s) have been used in other templates.<br>Please remove these device(s) from the current templates that they are using, and then come back to apply to this template again.<br>List of device(s): ${ListDevices}`
						}
					});
				} else {
					this.showToast({
						type: TOAST_TYPES.RESPONSE_SUCCESS,
						props: {
							title: "Update the standard template successfully",
							message: "All apply-to devices have been replaced by this change."
						}
					});
				}
			}
		},
		async handleSendPayloadForCreateAsNewTemplate(forms, titleAsNewTemplate) {
			const stdModulePayload = {
				title: titleAsNewTemplate,
				app: forms.appValue,
				api_url: forms.apiURL,
				graph_id: forms.graphValue,
				graph_unit: forms.graphUnit,
				graph_min: forms.graphMin === "" ? null : forms.graphMin ?? null,
				graph_max: forms.graphMax === "" ? null : forms.graphMax ?? null,
				graph_api_tags: forms.graphApiTagNames,
				condition_status: forms.status,
				overviews: this.parseOverviewsCreate(forms.overviews),
				applied_to: { // case Save as New Template must save applied_to to empty list.
					devices: [],
					shipTos: [],
					soldTos: []
				},
				// always disable history status when haven't historyApiURL
				history_status: forms.historyApiURL ? forms.historyStatus : false,
				analysis_status: forms.analysisStatus,
				history_api_url: forms.historyApiURL,
				history: {
					raw_select: forms.selectColumns,
					sql_select: forms.selectColumns.map((item) => { return `${item.select} AS ${item.as}`; }),
					sql_from: forms.from,
					sql_additional_where: forms.whereCondition
				}
			};
			await this.sendStandardTemplateCreate(stdModulePayload);
			if (this.isSucesssCreateStdModuleTemplate) {
				await this.addActivityLogStandardModuleCreate(stdModulePayload.title);
				this.$router.push({
					name: PAGE_NAME.STANDARD_MODULE_EDIT,
					params: {
						id: this.createTemplateId
					}
				});
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Create a new template successfully.",
						message: "Your standard template now will be shown here."
					}
				});
			}
		}
	}
};
</script>
