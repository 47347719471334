import { getFurnacGlassLevelAPI } from "@/services/api/furnaces.api";
import { FURNACE_GLASS_LEVEL_REQUEST, FURNACE_GLASS_LEVEL_SUCCESS, FURNACE_GLASS_LEVEL_FAILURE } from "../../types";
import { parseFurnaceGlassLevel } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parseFurnaceGlassLevel({}),
		loading: true,
		empty: false
	},

	mutations: {
		[FURNACE_GLASS_LEVEL_REQUEST](state) {
			state.detail = parseFurnaceGlassLevel({});
			state.loading = true;
			state.empty = false;
		},
		[FURNACE_GLASS_LEVEL_SUCCESS](state, data) {
			const detail = data;
			state.detail = parseFurnaceGlassLevel(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[FURNACE_GLASS_LEVEL_FAILURE](state) {
			state.detail = parseFurnaceGlassLevel({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async fetchFurnaceGlassLevel({ commit }, { id, params }) {
			try {
				commit(FURNACE_GLASS_LEVEL_REQUEST);
				const { data } = await getFurnacGlassLevelAPI(id, params); // wait for api from Back-End
				commit(FURNACE_GLASS_LEVEL_SUCCESS, data);
			} catch (error) {
				commit(FURNACE_GLASS_LEVEL_FAILURE, error);
			}
		}
	}
};
