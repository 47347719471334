<template>
	<div :class="['module-item', { active: isActive }]">
		<div class="module-item-header" @click="toggle">
			<h2 class="module-item-name">{{ checkType }}: {{ items.date }}</h2>
			<div class="module-item-toggle">
				<a>
					{{ isActive ? 'Hide detail' : 'Show detail' }} <FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
				</a>
			</div>
		</div>
		<div class="module-item-body container">
			<div class="row container-item">
				<div class="container-item-body">
					<BaseTableCustom
						:tableOptions="tableOptions"
						:loading="false"
					>
						<template v-slot:thead>
							<tr>
								<th
									v-for="({ value, ...rest }, index) in table.thead"
									:key="index"
									:class="rest.class"
								>
									<div class="header-content">
										{{ value }}
										<BaseSort
											v-if="rest.key"
											:sortName="rest.key"
											:sortBy="getSortingBykey(rest.key)"
											@handleSort="handleSort($event, rest)"
										/>
									</div>
								</th>
							</tr>
						</template>
						<template v-slot:tbody>
							<tr
								v-for="(item, index) in table.tbody"
								:key="index"
							>
								<td>
									{{ item.shipTo }}
								</td>
								<td>
									{{ item.srNo }}
								</td>
								<td>
									#{{ item.orderNo }}
								</td>
								<td>
									<div :class="['text-date-time', { '-error': item.isUrgent }]">
										{{ showDeliveryDateTime(item) }}
										<FontAwesomeIcon
											class="icon-error"
											:icon="['far', 'exclamation-triangle']"
										/>
									</div>
								</td>
								<td>
									{{ item.shipId }}
								</td>
								<td>
									{{ item.device.name }}
								</td>
								<td class="quantity">
									<div :class="['text-quantity', { '-error': checkErrorQuantity(item.amount, item.minimumVolume) }]">
										{{ showAmount(item) }}
										<FontAwesomeIcon
											v-if="checkErrorQuantity(item.amount, item.minimumVolume)"
											class="icon-error"
											:icon="['far', 'exclamation-triangle']"
										/>
									</div>
								</td>
								<td>
									<EOrderStatus :status="item.status" />
								</td>
								<td class="">
									<div class="customization-item">
										<div class="customization-item-view-section btn-link">
											<router-link
												:to="{
													path: `/e-ordering/info/${item.orderNo}`,
												}"
												class="icon-btn"
												active-class="active"
												exact
											>
												<font-awesome-icon
													:icon="['far', 'eye']"
													class="icon"
												>
												</font-awesome-icon>
												View
											</router-link>
										</div>
										<template v-if="item.isEditable && hasManagePermission">
											<div class="divider">|</div>
											<div class="customization-item-edit-section btn-link">
												<router-link
													:to="{
														path: `/e-ordering/edit/${item.orderNo}`
													}"
													class="icon-btn"
													active-class="active"
													exact
												>
													<font-awesome-icon
														:icon="['far', 'edit']"
														class="icon"
													>
													</font-awesome-icon>
													Edit
												</router-link>
											</div>
										</template>

										<template v-if="!item.isEditable && hasManagePermission">
											<div class="divider">|</div>
											<button
												class="customization-item-edit-section btn-link"
												@click="showUnableEditModal"
												:disabled="item.status === STATUS_LABEL.DELIVERED"
											>
												<div
													class="icon-btn"
												>
													<font-awesome-icon
														:icon="['far', 'edit']"
														class="icon"
													>
													</font-awesome-icon>
													Edit
												</div>
											</button>
										</template>
									</div>
								</td>
							</tr>
						</template>
					</BaseTableCustom>
				</div>
			</div>
		</div>

		<ModalUnableEdit
			:modal-name="MODAL_UNABLE_EDIT"
			title="Unable to edit order"
			:message="messageModalUnableEdit"
			@onBack="hideUnableEditModal"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";

import BaseTableCustom from "@/components/BaseTableCustom.vue";
import EOrderStatus from "@/components/eOrdering/EOrderStatus.vue";
import ModalUnableEdit from "@/components/eOrdering/modal/ModalUnableEdit.vue";
import BaseSort from "@/components/BaseSort.vue";

import { formatCounter } from "../../selectors/helpers/formatCounter";
import { mapUnit } from "../../selectors/helpers/mapUnit";
import { sortObject } from "../../selectors/helpers/sortObject";
import { MASTERDATA_PERMISSIONS } from "../../enums/masterData";
import { STATUS_LABEL } from "../../enums/eOrdering/status";

export default {
	name: "ModuleListDelivery",

	components: {
		BaseTableCustom,
		EOrderStatus,
		BaseSort,
		ModalUnableEdit
	},

	props: {
		viewType: {
			type: String,
			default: "delivery"
		},
		items: {
			type: Object,
			default: () => ({})
		},
		initialIsActive: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			isActive: this.initialIsActive,
			tableOptions: [
				"is-striped is-fullwidth"
			],
			sort: {
				shipTo: "",
				srNo: "",
				orderNo: "",
				deliveryDate: "asc",
				shipId: "",
				product: ""
			},
			ordersList: this.items.orders,
			MODAL_UNABLE_EDIT: "model-unable-edit",
			STATUS_LABEL
		};
	},

	computed: {
		...mapState(["eOrderingInfo", "user"]),

		table() {
			return {
				thead: [
					{ value: "Ship-to", key: "shipTo", type: "string" },
					{ value: "SR No.", key: "srNo", type: "string" },
					{ value: "Order No.", key: "orderNo", type: "string" },
					{ value: this.isDeliveryView ? "Delivery time" : "Delivery date/time", key: "deliveryDate", type: "date" },
					{ value: "Tank No.", key: "shipId", type: "string" },
					{ value: "Product", key: "product", type: "string" },
					{ value: "Quantity", key: "", class: "quantity" },
					{ value: "Status", key: "" },
					{ value: "", key: "" }
				],

				tbody: this.ordersList
			};
		},

		checkType() {
			return this.isDeliveryView ? "Delivery Date" : "Order Date";
		},

		isDeliveryView() {
			return this.viewType === "delivery";
		},

		hasManagePermission() {
			return this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.EOrderingManage);
		},

		messageModalUnableEdit() {
			return `<div>
				<div>This order has already been processed.</div>
				<div>To edit order, please contact BIG to process.</div>
			</div>`;
		}
	},

	created() {
		sortObject(
			this.ordersList,
			{
				key: "deliveryDate",
				type: "date"
			},
			"asc"
		);
	},

	methods: {
		async toggle() {
			this.isActive = !this.isActive;
		},

		checkErrorQuantity(amount, minimum) {
			return amount < minimum;
		},

		showAmount(val) {
			return `${formatCounter(val.amount)} ${mapUnit(val.device, this.eOrderingInfo.configuration)}`;
		},

		getSortingBykey(key) {
			return this.sort[key];
		},

		handleSetSortTable(sortName, sortBy, rest) {
			Object.keys(this.sort).forEach((keyName) => {
				if (keyName === sortName) {
					this.sort[keyName] = sortBy;
					sortObject(this.ordersList, rest, sortBy);
				} else {
					this.sort[keyName] = "";
				}
			});
		},

		handleSort(value, rest) {
			const { sortName, sortBy } = value;
			this.handleSetSortTable(sortName, sortBy, rest);
		},

		async fetchCustomers() {
			this.filterQuery();
		},

		showUnableEditModal() {
			this.$modal.show(this.MODAL_UNABLE_EDIT);
		},

		hideUnableEditModal() {
			this.$modal.hide(this.MODAL_UNABLE_EDIT);
		},

		showDeliveryDateTime(order) {
			const deliveryDate = dayjs(order.deliveryDate).format("DD MMM YYYY");

			return this.isDeliveryView ? order.deliveryTime : `${deliveryDate}, ${order.deliveryTime}`;
		}
	}
};
</script>

<style lang="scss" scoped>
.module-item {
	border-radius: 4px;
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	margin-bottom: rem(16);
	line-height: 1.5;
	overflow: hidden;

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-toggle
	&-toggle {
		margin-left: auto;

		a {
			width: rem(120);
			display: inline-block;
			color: $color-cerulean;
			font-weight: 600;

			svg {
				color: $color-cerulean;
				font-size: $font-16;
				margin-left: rem(10);
				position: relative;
				top: rem(3);
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(20) rem(16);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}

.container-item {
	padding: 0 $spacing-5 $spacing-5 $spacing-5;

	&-head {
		@include fontSub();

		display: flex;
		justify-content: space-between;
	}

	&-body {
		margin-top: rem(14);

		/* stylelint-disable */
		&::v-deep .container-custom-main-table .action {
			text-align: right !important;
		}
		/* stylelint-enable */

		.text-date-time,
		.text-quantity {
			display: flex;

			.icon-error {
				display: none;
				width: rem(14);
				height: rem(12);

				margin-top: rem(6);
				margin-left: rem(4);
			}

			&.-error {
				color: $color-lipstick;

				.icon-error {
					display: block;
				}
			}
		}

		.customization-item {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.divider {
				color: $color-silver;
				padding: 0 rem(8);
			}

			.icon-btn {
				display: flex;
				align-items: center;
				color: $color-cerulean;
				font-size: rem(16);

				&:hover {
					color: $color-azure;
				}

				&:visited {
					color: $color-cerulean;
				}
			}

			.btn-link {
				border: none;
				background: none;

				&:disabled {
					color: $color-light-teal;

					.icon-btn {
						color: $color-light-teal;
					}
				}
			}

			.icon {
				font-size: rem(12);
			}
		}

		.quantity {
			width: 90px;

			.header-content,
			.text-quantity {
				justify-content: flex-end;
			}
		}
	}
}

.header-content {
	display: flex;
}
</style>
