<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<ModuleHeaderExample :data="standardModulePage.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<ul class="nav nav-tabs">
				<li v-for="(item,index) in standardModulePage.data.tabs" :key="index" class="nav-item">
					<router-link v-if="item.url === 'grafana'"
						:to="{
							name: PAGES[item.url],
							query: {tab:item.name}
						}"
						:active-class="$route.query.tab === item.name ? 'active' : ''"
						class="nav-link"
					>
						{{item.name}}
					</router-link>
					<router-link v-else
						:to="{
							name: PAGES[item.url]
						}"
						active-class="active"
						class="nav-link"
					>
						{{item.name}}
					</router-link>
				</li>
			</ul>
			<router-view
				@loaded="handleContentLoaded"
				:shipToName="shipToName"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ModuleHeaderExample from "@/components/dashboard/modules/ModuleHeaderExample.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import userPermission from "../../mixins/userPermission";
import { CONFIG } from "../../enums/config";

export default {
	name: "StandardModuleExample",

	components: {
		BaseLoading,
		ModuleHeaderExample
	},

	data() {
		return {
			PAGE_NAME,
			CONFIG,
			id: this.$route.params.id,
			templateId: this.$route.params.templateId,
			isLoading: true,
			fetchDevices: () => {},
			PAGES: {
				overview: PAGE_NAME.EX_STANDARD_MODULE_OVERVIEW,
				history: PAGE_NAME.EX_STANDARD_MODULE_HISTORY,
				analysis: PAGE_NAME.EX_STANDARD_MODULE_ANALYSIS,
				grafana: PAGE_NAME.EX_STANDARD_MODULE_GRAFANA
			}
		};
	},

	mixins: [userPermission],

	computed: {
		...mapState(["standardModulePage", "shipTos"]),
		...mapGetters("user", {
			getProfile: "getProfile"
		}),

		breadcrumb() {
			return {
				type: {
					name: this.standardModulePage.data.module
				},
				shiped: {
					name: this.standardModulePage.data.soldToName
				}
			};
		},

		shipToName() {
			return this.shipTos.data.name;
		}
	},

	async created() {
		await this.getStandardModuleByDevice({ templateId: this.templateId, id: this.id });
		this.isLoading = this.standardModulePage.isLoading;
		this.autoRefreshData = setInterval(async () => {
			// TODO: waiting for finish and add autoRefresh data Standard Module
			if (!this.standardModulePage.isLoading) {
				await this.autoGetStandardModuleByDevice({ templateId: this.templateId, id: this.id });
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		// save log for tab Overview only
		if (this.$route.name === PAGE_NAME.STANDARD_MODULE_OVERVIEW) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: 200
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);

				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("airCompInfo", {
			fetchAirCompInfo: "fetchAirCompInfo",
			autoFetchAirCompInfo: "autoFetchAirCompInfo",
			clearAirCompInfo: "clearAirCompInfo"
		}),

		...mapActions("standardModulePage", {
			getStandardModuleByDevice: "getStandardModuleByDevice",
			autoGetStandardModuleByDevice: "autoGetStandardModuleByDevice"
		}),

		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		handleContentLoaded() {
			this.fetchDevices();
		}
	},

	destroyed() {
		this.clearAirCompInfo();
	}
};
</script>