import dayjs from "dayjs";
import { numberFormat } from "../../selectors/helpers";
import { UNIT_TYPE } from "../../enums/unit";

export const transformedConsumption = (consumptions = [], unit) => {
	return consumptions.map((consumption) => {
		let unitValue = "";

		switch (unit) {
			case UNIT_TYPE.NM3:
				unitValue = consumption.nm3;
				break;
			case UNIT_TYPE.SM3:
				unitValue = consumption.sm3;
				break;
			case UNIT_TYPE.LITER:
				unitValue = consumption.kg;
				break;
			default:
				unitValue = consumption.kg;
				break;
		}
		return {
			date: dayjs(consumption.timestamp).format("DD MMM YYYY"),
			value: numberFormat(unitValue)
		};
	});
};

export const transformedTankConsumptionHistory = (tanks = [], unit) => {
	return tanks.map((tank) => {
		let unitValue = "";

		switch (unit) {
			case UNIT_TYPE.NM3:
				unitValue = tank.estimateConsumptionNm3;
				break;
			case UNIT_TYPE.SM3:
				unitValue = tank.estimateConsumptionSm3;
				break;
			case UNIT_TYPE.LITER:
				unitValue = tank.estimateConsumptionKg;
				break;
			default:
				unitValue = tank.estimateConsumptionKg;
				break;
		}

		return {
			monthDate: dayjs(tank.date).format("MMMM YYYY"),
			estimateConsumption: numberFormat(unitValue),
			consumptions: transformedConsumption(tank.consumptions, unit),
			unit: tank.unit
		};
	});
};