import { getFlowDiagramShipToAPI } from "@/services/api/flowdiagram.api";
import { FLOW_DIAGRAM_SHIPTO_REQUEST, FLOW_DIAGRAM_SHIPTO_SUCCESS, FLOW_DIAGRAM_SHIPTO_FAILURE } from "../../types";
import { parseShipTos } from "../../../services/api/transforms/parseFlowDiagram";

export default {
	namespaced: true,

	state: {
		data: parseShipTos([]),
		loading: false
	},

	mutations: {
		[FLOW_DIAGRAM_SHIPTO_REQUEST](state) {
			state.data = parseShipTos([]);
			state.loading = true;
		},
		[FLOW_DIAGRAM_SHIPTO_SUCCESS](state, { data }) {
			state.data = parseShipTos(data);
			state.loading = false;
		},
		[FLOW_DIAGRAM_SHIPTO_FAILURE](state) {
			state.data = parseShipTos([]);
			state.loading = false;
		}
	},

	actions: {
		async getFlowDiagramShipToList({ commit }, params) {
			try {
				commit(FLOW_DIAGRAM_SHIPTO_REQUEST);
				const { data } = await getFlowDiagramShipToAPI(params);
				commit(FLOW_DIAGRAM_SHIPTO_SUCCESS, data);
			} catch (error) {
				commit(FLOW_DIAGRAM_SHIPTO_FAILURE);
			}
		}
	}
};
