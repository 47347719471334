<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: isActive.keyword }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				v-model="form.keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Search with title"
				@input="handleKeywordChanged"
				@focus="setFocus('keyword', true)"
				@blur="setFocus('keyword', false)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-status', { active: isActive.status }]">
			<BaseDropdown
				:searchable="false"
				:list="statuses"
				:value="form.status"
				type="ghost"
				placeholder="Status: All"
				@input="handleStatusSelected"
				@active="setFocus('status', $event)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-type highlight-service-width', { active: isActive.type }]">
			<BaseDropdown
				:searchable="false"
				:list="highlightServices"
				:value="form.highlight"
				type="ghost"
				placeholder="Highlight service: All"
				@input="handlehighlightSelected"
				@active="setFocus('status', $event)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline>Search</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
export default {
	name: "SearchFilterForm",

	props: {
		statuses: {
			type: Array,
			default: () => ([])
		},
		highlightServices: {
			type: Array,
			default: () => ([])
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			form: {
				keyword: this.query.title || null,
				status: this.query.status || null,
				highlight: this.query.highlight || null
			},
			isActive: {
				keyword: false,
				status: false,
				highlight: false
			}
		};
	},

	methods: {
		handleKeywordChanged(value = null) {
			this.form.keyword = value || null;
		},

		handleStatusSelected(value = null) {
			this.form.status = value;
		},

		handlehighlightSelected(value = null) {
			this.form.highlight = value;
		},

		handleSubmit() {
			this.$emit("submit", this.form);
		},

		clearFilter() {
			this.form.keyword = null;
			this.form.status = null;
			this.form.highlight = null;
			this.$emit("submit", this.form);
		},

		setFocus(type, value) {
			this.isActive[type] = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-keyword {
	width: rem(268);
}

.input-group-role, .input-group-status, .input-group-type {
	width: rem(184);
}

.highlight-service-width {
	width: 200px;
}
</style>
