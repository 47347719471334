import httpClient from "./httpClient";


const STAFF = "/staffs";


const getAddStaffAPI = () => httpClient.post(`${STAFF}`, {
	id: "9d04e45b-f0f2-4014-84d0-37f716b0bbb3",
	roleCode: "ADMIN"
});

const getStaffsAPI = (params = {}) => httpClient.get(`${STAFF}`, { params });
const getStaffAPI = (id = "") => httpClient.get(`${STAFF}/${id}`);
const updateStaffsRoleAPI = (id, body) => httpClient.put(`${STAFF}/${id}`, body);
const deleteStaffAPI = (id) => httpClient.delete(`${STAFF}/${id}`);
const postStaffPermissionAPI = (data) => httpClient.post(`${STAFF}/permissions`, data);

export {
	getAddStaffAPI,
	getStaffsAPI,
	getStaffAPI,
	updateStaffsRoleAPI,
	deleteStaffAPI,
	postStaffPermissionAPI
};
