import { getCustomersAPI } from "../../../services/api/customerManageApi";
import { postCustomerChangePasswordAPI } from "../../../services/api/user.api";
import {
	CUSTOMER_LIST_SUCCESS,
	CUSTOMER_LIST_REQUEST,
	CUSTOMER_LIST_FAILURE,
	CUSTOMER_CHANGE_PASSWORD_REQUEST,
	CUSTOMER_CHANGE_PASSWORD_SUCCESS,
	CUSTOMER_CHANGE_PASSWORD_FAILURE,
	CUSTOMER_CHANGE_PASSWORD_CLEAR
} from "../../types";
import { parseCustomerList } from "../../../services/api/transforms/parseCustomer";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		customerChangePasswordLoading: false,
		customerChangePasswordSuccess: false,
		customerChangeErrorMessage: "",
		customerChangeErrorUnexpected: "",
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},
	mutations: {
		[CUSTOMER_LIST_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},

		[CUSTOMER_LIST_SUCCESS](state, { data, pagination }) {
			state.data = parseCustomerList(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},

		[CUSTOMER_LIST_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[CUSTOMER_CHANGE_PASSWORD_REQUEST](state) {
			state.customerChangePasswordLoading = true;
			state.customerChangePasswordSuccess = false;
			state.customerChangeErrorMessage = "";
			state.customerChangeErrorUnexpected = "";
		},
		[CUSTOMER_CHANGE_PASSWORD_SUCCESS](state) {
			state.customerChangePasswordLoading = false;
			state.customerChangePasswordSuccess = true;
			state.customerChangeErrorMessage = "";
			state.customerChangeErrorUnexpected = "";
		},
		[CUSTOMER_CHANGE_PASSWORD_FAILURE](state, error) {
			state.customerChangePasswordLoading = false;
			state.customerChangePasswordSuccess = false;

			if (error.response.status === 422) {
				const errors = error.response.data.errors;
				if (Object.keys(errors).length) {
					if (Object.keys(errors)[0] === "currentPassword") {
						state.customerChangeErrorMessage = "Your old password was incorrect, please try again";
					} else {
						state.customerChangeErrorUnexpected = "There is an unexpected error, please try again";
					}
				}
			}
		},
		[CUSTOMER_CHANGE_PASSWORD_CLEAR](state) {
			state.customerChangeErrorMessage = "";
			state.customerChangeErrorUnexpected = "";
		}
	},

	actions: {
		async fetchCustomerLists({ commit }, params = {}) {
			try {
				commit(CUSTOMER_LIST_REQUEST);

				const { data } = await getCustomersAPI(params);

				commit(CUSTOMER_LIST_SUCCESS, data);
			} catch (e) {
				commit(CUSTOMER_LIST_FAILURE);
			}
		},

		async putChangePassword({ commit }, { currentPassword, password, passwordConfirmation }) {
			try {
				commit(CUSTOMER_CHANGE_PASSWORD_REQUEST);
				await postCustomerChangePasswordAPI({ currentPassword, password, passwordConfirmation });
				commit(CUSTOMER_CHANGE_PASSWORD_SUCCESS);
			} catch (error) {
				commit(CUSTOMER_CHANGE_PASSWORD_FAILURE, error);
			}
		},

		clearChangePasswordError({ commit }) {
			commit(CUSTOMER_CHANGE_PASSWORD_CLEAR);
		}
	}
};
