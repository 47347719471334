import { getvalidateMessage, checkIfValid } from "../validateHelper";

export default {
	methods: {
		checkIfValid(name) {
			const field = this.$v[name];
			if (!field.$dirty) {
				return null;
			}

			if (!("required" in field) && !field.$error) {
				return null;
			}

			return (field.$invalid || field.$error);
		},
		validatePermissionForm() {
			this.$v.$touch();
		}
	},
	computed: {
		isModuleValueError() {
			const field = this.$v.moduleValue;
			return checkIfValid(field);
		},

		getModuleValueErrorMessage() {
			const field = this.$v.moduleValue;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isAppValueError() {
			const field = this.$v.appValue;
			return checkIfValid(field);
		},

		getAppValueErrorMessage() {
			const field = this.$v.appValue;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isTitleError() {
			const field = this.$v.title;
			return checkIfValid(field);
		},

		getTitleErrorMessage() {
			const field = this.$v.title;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length at max 200 characters"
			});
		},

		isBoxTitleError() {
			if (!this.statusDisplay) return false;
			const field = this.$v.boxTitle;
			return checkIfValid(field);
		},

		getBoxTitleErrorMessage() {
			const field = this.$v.boxTitle;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length at max 200 characters"
			});
		},

		isDescriptionError() {
			const field = this.$v.description;
			return checkIfValid(field);
		},

		getDescriptionErrorMessage() {
			const field = this.$v.description;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length at max 500 characters"
			});
		},

		isIntroTitleError() {
			const field = this.$v.introTitle;
			return checkIfValid(field);
		},

		getIntroTitleError() {
			const field = this.$v.introTitle;
			return getvalidateMessage(field, {
				lengthLimitMessage: "Field length at max 400 characters"
			});
		},

		isIntroDescriptionError() {
			const field = this.$v.introDescription;
			return checkIfValid(field);
		},

		getIntroDescriptionError() {
			const field = this.$v.introDescription;
			return getvalidateMessage(field, {
				lengthLimitMessage: "Field length at max 1000 characters"
			});
		},

		isUrlError() {
			const field = this.$v.vdoLink;
			return checkIfValid(field);
		},

		getUrlErrorMessage() {
			const field = this.$v.vdoLink;
			return getvalidateMessage(field, {
				invalidFormatMessage: "This must input URL https://www.youtube.com/embed/xxxxxxx"
			});
		},

		isDemoDeviceIdError() {
			const field = this.$v.demoDeviceId;
			return checkIfValid(field);
		},

		getDemoDeviceIdError() {
			const field = this.$v.demoDeviceId;
			return getvalidateMessage(field, {
				lengthLimitMessage: "Field length at max 40 characters"
			});
		},

		isPriorityNumberError() {
			const field = this.$v.priorityNumber;
			return checkIfValid(field);
		},

		getPriorityNumberError() {
			const field = this.$v.priorityNumber;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Invalid number. Only a positive number is allowed.",
				invalidMinValueMessage: "Invalid number. Only a positive number is allowed."
			});
		},

		isPriorityHighlightNumberError() {
			if (!this.statusDisplay) return false;
			const field = this.$v.priorityHighlightNumber;
			return checkIfValid(field);
		},

		getPriorityHighlightNumberError() {
			const field = this.$v.priorityHighlightNumber;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Invalid number. Only a positive number is allowed.",
				invalidMinValueMessage: "Invalid number. Only a positive number is allowed."
			});
		}
	}
};