<template>
	<Form
		v-model="diagrams"
		:validateFormFn.sync="validateFormFn"
		:loading="loading"
		:title="`Create diagram`"
		:query="query"
		:editable="editable"
		:zoomable="false"
		:initEmptyNode="true"
		:viewOnly="false"
		:devices="devices"
		:shipTos="shipTos"
	>
		<template #action>
			<BaseAction
				:textSubmit="`Create diagram`"
				:textCancle="`Cancel`"
				:isSummited="isSummited.create"
				:disabled="disabledSummitButton"
				@cancel="handleCancel"
				@submit="handleSumit"
			/>
			<BaseModalConfirmDelete
				:modal-name="MODAL_NAME_CONFIRM_LEAVE"
				:title="`Leave this page?`"
				:message="`
					<div>
						You have unsaved changes.
					</div>
					<div>
						Are you sure to leave without saving?
					</div>
				`"
				:submitButtonText="`Yes, discard`"
				@onCancel="handleCancleLeave"
				@onConfirm="handleConfirmLeave"
			/>
		</template>
	</Form>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Form from "@/views/FlowDiagram/Form.vue";
import BaseAction from "@/components/BaseAction.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { getBody, isValidDiagrams } from "../../selectors/helpers/flowDiagram";
import { getIsSuperAdmin } from "../../selectors/transform/staff";
import { TOAST_TYPES } from "../../enums/toast";
import { PAGE_NAME } from "../../enums/pagePermission";
import userPermission from "../../mixins/userPermission";

export default {
	components: {
		Form,
		BaseAction,
		BaseModalConfirmDelete
	},

	mixins: [userPermission],

	data() {
		return {
			MODAL_NAME_CONFIRM_LEAVE: "MODAL_NAME_CONFIRM_LEAVE",
			query: {
				id: this.$route.query.id
			},
			isSummited: {
				create: false
			},
			editable: true,
			diagrams: [],
			validateFormFn: null,
			isConfirmLeave: false,
			goTo: null
		};
	},

	computed: {
		...mapState(["user"]),
		...mapState("flowDiagramDeviceList", {
			devices: "data",
			devicesLoading: "loading"
		}),
		...mapState("flowDiagramShipTo", {
			shipTos: "data",
			shipTosLoading: "loading"
		}),
		loading() {
			return this.devicesLoading || this.shipTosLoading;
		},
		disabledSummitButton() {
			return !isValidDiagrams(this.diagrams);
		},
		hasPermission() {
			return getIsSuperAdmin(this.user.role) || this.permissions.diagram.manage;
		}
	},

	created() {
		if (!this.hasPermission) {
			this.$router.push({
				name: PAGE_NAME.FLOWDIAGRAM_LIST
			}).catch(() => {});
		}
	},

	mounted() {
		this.fetchData();
		window.addEventListener("beforeunload", this.handleBeforeUnLoad);
	},

	destroyed() {
		window.removeEventListener("beforeunload", this.handleBeforeUnLoad);
	},

	beforeRouteLeave(to, from, next) {
		if (!this.isConfirmLeave) {
			this.goTo = to.fullPath;
			this.openModalConfirmLeave();
			next(false);
		} else {
			next(true);
		}
	},

	methods: {
		...mapActions("flowDiagramShipTo", {
			getFlowDiagramShipToList: "getFlowDiagramShipToList"
		}),
		...mapActions("flowDiagramDeviceList", {
			getFlowDiagramDeviceList: "getFlowDiagramDeviceList"
		}),
		...mapActions("flowDiagramCreate", {
			createFlowDiagram: "createFlowDiagram"
		}),
		...mapActions("toast", {
			showToast: "show"
		}),
		async fetchData() {
			return Promise.all([
				this.getFlowDiagramShipToList({
					perPage: 10000,
					show: "all"
				}),
				this.getFlowDiagramDeviceList({
					id: this.query.id,
					params: {
						type: "All"
					}
				})
			]);
		},
		handleCancel() {
			this.openModalConfirmLeave();
			this.goTo = { name: PAGE_NAME.FLOWDIAGRAM_LIST };
		},
		async handleSumit() {
			const isValid = this.validateFormFn();
			if (isValid) {
				this.isSummited.create = true;
				await this.createFlowDiagram({
					id: this.query.id,
					data: getBody(this.diagrams)
				});
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: { title: "Success", message: "Diagram created successfully" }
				});
				this.isSummited.create = false;
				this.isConfirmLeave = true;
				this.$router.push({
					name: PAGE_NAME.FLOWDIAGRAM_LIST
				});
			}
		},
		handleCancleLeave() {
			this.closeModalConfirmLeave();
		},
		handleConfirmLeave() {
			this.isConfirmLeave = true;
			this.closeModalConfirmLeave();
			this.$router.push(this.goTo);
		},
		openModalConfirmLeave() {
			this.$modal.show(this.MODAL_NAME_CONFIRM_LEAVE);
		},
		closeModalConfirmLeave() {
			this.$modal.hide(this.MODAL_NAME_CONFIRM_LEAVE);
		},
		handleBeforeUnLoad(e) {
			// Cancel the event
			e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
			// Chrome requires returnValue to be set
			e.returnValue = "";
		}
	}
};
</script>