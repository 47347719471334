<template>
	<div class="container-item">
		<ColumnBody :date="item.body.date" :values="item.body.values"/>
		<ColumnFooter :total="item.footer.total" :percentages="item.footer.percentages"/>
	</div>
</template>

<script>

import ColumnBody from "@/components/dashboard/modules/MeterOrderHistory/ColumnBody.vue";
import ColumnFooter from "@/components/dashboard/modules/MeterOrderHistory/ColumnFooter.vue";

export default {
	name: "MeterOrderHistoryItem",

	components: {
		ColumnBody,
		ColumnFooter
	},

	props: {
		item: {
			type: Object,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.container-item {
	border: rem(1) solid $color-silver;
	border-radius: rem(4);
}
</style>