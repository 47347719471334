export const isValidElement = (element) => {
	return element instanceof HTMLElement;
};

export const addClass = (element, classes = "") => {
	if (!(isValidElement(element)) || typeof classes !== "string") {
		return;
	}
	element.classList.add(...classes.split(" "));
};

export const removeClass = (element, classes = "") => {
	if (!(isValidElement(element)) || typeof classes !== "string") {
		return;
	}
	element.classList.remove(...classes.split(" "));
};

// this function add class hide-scrollbar
// to set scrollbar on body to be hidden
export const hideBodyScrollbar = () => {
	addClass(document.body, "hide-scrollbar");
};

// this function just remove class hide-scrollbar from the body
export const autoBodyScrollbar = () => {
	removeClass(document.body, "hide-scrollbar");
};