<template>
	<div class="container-card">
		<p class="lastmonth-desc">
			{{ describe }}
		</p>
		<div class="container-item col-12">
			<BaseCard>
				<template v-slot:header>
					<div class="container-header">
						<h4>
							{{ date }}
						</h4>
						<div class="subtitle">
							{{ subTitle }}
						</div>
					</div>
				</template>
				<template v-slot:body>
					<div class="container-body">
						<div class="item">
							<div class="title">
								Beginning (<span v-html="HTML_FLOW_RATE_UNIT"></span>)
							</div>
							<div class="value">
								<span>
									{{ beginning }}
								</span>
							</div>
						</div>
						<div class="item">
							<div class="title">
								Ending (<span v-html="HTML_FLOW_RATE_UNIT"></span>)
							</div>
							<div class="value">
								<span>
									{{ ending }}
								</span>
							</div>
						</div>
						<div class="item">
							<div class="title">
								Net outlet water (<span v-html="HTML_FLOW_RATE_UNIT"></span>)
							</div>
							<div class="net-total value">
								<span>
									{{ netOutlet }}
								</span>
							</div>
						</div>
					</div>
				</template>
			</BaseCard>
		</div>
	</div>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import { HTML_FLOW_RATE_UNIT } from "../../../../selectors/transform/unit";

export default {
	name: "LastMonth",

	components: {
		BaseCard
	},

	props: {
		date: {
			type: String,
			required: true
		},
		describe: {
			type: String,
			required: true,
			default: "This is the summary of recycle water usage from last month. Please use this as reference."
		},
		subTitle: {
			type: String,
			required: true,
			default: "Meter - outlet water"
		},
		beginning: {
			type: String,
			required: true
		},
		ending: {
			type: String,
			required: true
		},
		netOutlet: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			HTML_FLOW_RATE_UNIT
		};
	}
};
</script>

<style lang="scss" scoped>
.container {
	&-card {
		.hide-content {
			visibility: hidden;
		}
	}

	&-item {
		display: flex;
		max-width: rem(495);
	}

	&-header {
		display: flex;

		.subtitle {
			display: flex;
			align-items: center;
			margin: rem($spacing-2) auto 0 rem($spacing-5);
			color: $color-dark-blue-grey;
		}
	}

	&-body {
		display: flex;

		.item {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			text-align: right;
			font-size: $font-18;
			color: $color-dark-blue-grey;

			.value {
				height: rem(24);

				span, strong {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					height: 100%;
				}
			}
		}

		.item:first-of-type {
			max-width: rem(77);
		}

		.item:last-of-type {
			flex-grow: 2;
			text-align: right;
			font-weight: 600;

			.net-total {
				font-size: $font-24;
				color: $color-emerald;
			}
		}
	}

	.lastmonth-desc {
		margin-top: 0;
		color: $color-dark-blue-grey;
		font-size: $font-20;
	}
}
</style>