<template>
	<BaseError
		code="502"
		title="Bad gateway"
	>
		<template v-slot:body>
			<div class="container-body">
				<p>
					Looks like the server is experiencing an error while <br/>
					processing your request. Please try again later.
				</p>
			</div>
		</template>
	</BaseError>
</template>

<script>
import BaseError from "@/components/BaseError.vue";

export default {
	name: "page502",

	components: {
		BaseError
	}
};
</script>

<style lang="scss" scoped>
.container-body {
	text-align: center;
}
</style>