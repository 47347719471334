<template>
	<div :class="['form-container', { 'has-error': item.isUrgent }]">
		<div class="form-schedule">
			<div :class="['form-quantity', { 'has-error': item.isUrgent  }]">
				<BaseFormQuantity
					:disabled="disabled"
					:selected-value="selectedQuantityType"
					:define-volume="formatVolume"
					:is-error="isErrorVolume"
					:minimum-volume="item.minimumVolume"
					:full-load="item.maximumVolume"
					:unit="item.unit"
					@blur="handleBlurVolume"
					@change="handleChangeVolumeType"
				/>
				<div
					v-if="isErrorVolume"
					class="text-error"
				>
					<FontAwesomeIcon
						v-if="isErrorVolume"
						class="icon-error"
						:icon="['far', 'exclamation-triangle']"
					/>
					Minimum volume is {{ item.minimumVolume }} kg. additional change may be applied.
				</div>
				<div
					v-else
					class="text-error"
				>
					Minimum volume = {{ item.minimumVolume }} kg , Maximum volume = {{ item.maximumVolume }} kg
				</div>
			</div>

			<div :class="['form-date-time', { 'has-error': item.isUrgent }]">
				<BaseFormDateTimePicker
					:order-index="orderIndex"
					:schedule-index="scheduleIndex"
					:order-type="orderType"
					:disabled="disabled"
					:value-date-shortcut="item.dateShortcut"
					:value-date="item.date"
					:value-dates="item.dates"
					:value-time="item.time"
					:value-time-type="item.timeType"
					@blur="handleDateTime"
				/>
				<div
					v-if="item.isUrgent"
					class="text-error"
				>
					<FontAwesomeIcon
						class="icon-error"
						:icon="['far', 'exclamation-triangle']"
					/>	This delivery is under 24 hour, please contact BIGTH <strong>098-258-5317</strong>, to confirm.
				</div>
				<div v-if="isRequireTime" class="text-require-time">
					Please select delivery time
				</div>

			</div>

			<div
				v-if="isDelete"
				:class="['icon-minus', { 'has-error': item.isUrgent }]"
				@click="handleRemoveSchedule"
			>
				<FontAwesomeIcon
					:icon="['far', 'minus-circle']"
				/>
			</div>
		</div>
		<ModalLessThan24Hr
			:modal-name="MODAL_LESS_THAN_24_HR"
			title="Delivery time less than 24 hr"
			message="To confirm order, please contact BIG for confirmation of delivery time."
			@onCancel="handleModalChange24Hr"
			@onConfirm="handleModalConfirm24Hr"
		/>
		<ModalVolumeLessThan
			:modal-name="MODAL_VOLUMN_LESS_THAN"
			title="Volume less than minimum"
			message="You order less then the minimum volume, Are you sure to order?"
			@onCancel="handleModalChangeVolume"
			@onConfirm="handleModalConfirmVolume"
		/>
	</div>
</template>
<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import moment from "moment";
import { mapActions } from "vuex";

import BaseFormQuantity from "@/components/eOrdering/formGroup/BaseFormQuantity.vue";
import BaseFormDateTimePicker from "@/components/eOrdering/formGroup/BaseFormDateTimePicker.vue";
import ModalLessThan24Hr from "@/components/eOrdering/modal/ModalCancelContact.vue";
import ModalVolumeLessThan from "@/components/eOrdering/modal/ModalVolumnLessThan.vue";

import { ORDER_TYPE } from "../../../enums/eOrdering/order";

export default {
	name: "BaseFormSchedule",

	components: {
		BaseFormQuantity,
		BaseFormDateTimePicker,
		ModalLessThan24Hr,
		ModalVolumeLessThan
	},

	props: {
		item: {
			type: Object,
			default: () => ({})
		},
		isDelete: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		orderType: {
			type: String,
			default: ORDER_TYPE.NORMAL
		},
		orderIndex: {
			type: Number,
			default: 0
		},
		scheduleIndex: {
			type: Number,
			default: 0
		}
	},

	data() {
		return {
			forms: this.item,
			isRequireTime: false,
			isErrorVolume: this.isError,
			isFirstLoaded: false,
			MODAL_LESS_THAN_24_HR: "modal-less-than-24-hr",
			MODAL_VOLUMN_LESS_THAN: "modal-volume-less-than"
		};
	},

	computed: {
		formatVolume() {
			if (this.item.volume) {
				return this.item.volume.toString();
			}
			return undefined;
		},
		selectedQuantityType() {
			const unit = this.item.unit === "Tube" ? "D" : this.item.selectedVolume;
			return unit;
		},

		isNormalOrder() {
			return this.orderType === ORDER_TYPE.NORMAL;
		}
	},

	watch: {
		item(val) {
			this.forms = val;
		}
	},

	methods: {
		...mapActions("eOrderingCreate", {
			removeOrderSchedule: "removeOrderSchedule"
		}),


		handleBlurVolume(val) {
			this.isErrorVolume = false;

			if (val < this.item.minimumVolume && this.forms.selectedVolume === "D") {
				this.$modal.show(this.MODAL_VOLUMN_LESS_THAN);
				this.isErrorVolume = true;
			}
			this.forms.volume = val;
		},

		handleChangeVolumeType(val) {
			if (val === "F") {
				this.isErrorVolume = false;
			}
			this.forms.selectedVolume = val;
		},

		showModalLessThan24HrWithNormal(val) {
			dayjs.extend(utc);

			const time = dayjs(val.time).format("HH:mm");
			const date = dayjs(val.date).format("YYYY-MM-DD");
			const dateTime = `${date}T${time}Z`;
			const hours = moment().diff(moment(dayjs(dateTime).toDate()), "hours");
			const diffHours = (hours * -1) - 7;
			this.forms.isUrgent = false;


			if (diffHours < 24) {
				this.forms.isUrgent = true;
				this.$modal.show(this.MODAL_LESS_THAN_24_HR);
			}

			this.forms.date = date;
			this.forms.deliveriesDate = `${date} ${time}`;
		},

		showModalLessThan24HrWithBatch(val) {
			dayjs.extend(utc);

			val.dates.sort((a, b) => {
				return a.date - b.date;
			});

			const firstDate = dayjs(val.dates[0].date).format("YYYY-MM-DD");
			const time = dayjs(val.time).format("HH:mm");
			const firstDateTime = `${firstDate}T${time}Z`;

			const hours = moment().diff(moment(dayjs(firstDateTime).toDate()), "hours");
			const diffHours = (hours * -1) - 7;
			this.forms.isUrgent = false;
			this.forms.dateShortcut = val.dateShortcut;


			if (diffHours < 24) {
				this.forms.isUrgent = true;
				this.$modal.show(this.MODAL_LESS_THAN_24_HR);
			}

			this.forms.dates = val.dates;
			this.forms.deliveriesDates = this.forms.dates.map((date) => {
				return `${dayjs(date.date).format("YYYY-MM-DD")} ${time}`;
			});
		},

		handleDateTime(val) {
			this.forms.time = val.time;
			this.forms.timeType = val.timeType;

			if (val.dates.length === 0) {
				this.forms.deliveriesDates = [];
			}

			// validate require time
			const requireTimeForBatchOrder = val.dates.length > 0 && !val.time;
			const requireTimeForNormalOrder = val.date && !val.time;
			if (requireTimeForBatchOrder || requireTimeForNormalOrder) {
				this.isRequireTime = true;
			} else {
				this.isRequireTime = false;
			}

			if (this.isNormalOrder) {
				if ((!!val.date && !!val.time)) {
					this.showModalLessThan24HrWithNormal(val);
				}
			} else if (val.dates.length > 0 && val.time) {
				this.showModalLessThan24HrWithBatch(val);
			}
		},

		handleModalChange24Hr() {
			this.$modal.hide(this.MODAL_LESS_THAN_24_HR);
		},

		handleModalConfirm24Hr() {
			this.$modal.hide(this.MODAL_LESS_THAN_24_HR);
		},

		handleModalChangeVolume() {
			this.$modal.hide(this.MODAL_VOLUMN_LESS_THAN);
		},

		handleModalConfirmVolume() {
			this.$modal.hide(this.MODAL_VOLUMN_LESS_THAN);
		},

		handleRemoveSchedule() {
			this.removeOrderSchedule({
				orderScheduleKey: `order${this.orderIndex}${this.scheduleIndex}`
			});
			this.$emit("onRemove");
		}
	}
};
</script>
<style lang="scss" scoped>
.form {
	// .form-container
	&-container {
		display: flex;
		flex-direction: column;

		&.has-error {
			padding-bottom: rem(8);
		}
	}

	// .form-schedule
	&-schedule {
		display: flex;
		flex-wrap: wrap;
		column-gap: rem(56);
	}

	// .form-date-time
	&-date-time {
		display: flex;
		height: rem(60);

		position: relative;

		&.has-error {
			height: rem(80);
		}
	}

	// .form-quantity
	&-quantity {
		display: flex;
		height: rem(72);

		position: relative;

		&.has-error {
			height: rem(72);
		}
	}

	.text-error {
		position: absolute;
		top: rem(40);

		font-size: $font-14;
		color: $color-grey-4;
	}

	.text-require-time {
		position: absolute;
		top: rem(40);

		font-size: $font-14;
		font-weight: bold;
		color: $color-red;
	}

	.icon-minus {
		margin-left: rem(24);
		transform: translateY(10px);
		cursor: pointer;

		&.has-error {
			margin-left: rem(24);
		}
	}

	.icon-error {
		width: rem(18);
		color: $color-lipstick;
		transform: translateY(10px);
		vertical-align: super;
	}
}
</style>;