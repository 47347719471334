export const transformSoldToManagement = (items = []) => {
	return items.map((item) => {
		return {
			id: item.id,
			name: item.company,
			code: item.code,
			key: item.key,
			workspaceId: item.workspaceId || "",
			reportId: item.reportId || ""
		};
	});
};