<template>
	<div class="container-event">
		<div class="container-event-info">
			<div
				:style="[
					{ backgroundColor: data.icon.colorBg }
				]"
				class="container-event-info-icon"
			>
				<div
					:style="[
						{ width: data.icon.width },
						{ height: data.icon.height },
						{ color: data.icon.colorSvg }
					]"
					class="container-event-info-icon-inner"
				>
					<font-awesome-icon
						:icon="data.icon.fa"
					/>
				</div>
			</div>
			<div class="container-event-info-text">
				<span class="container-event-info-text-email">
					{{ data.email }}
				</span>
				<span class="container-event-info-text-detail">
					{{ data.detail }}
				</span>
			</div>
		</div>
		<div class="container-event-created">
			{{ data.createdAt }}
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseListItemEvent",

	props: {
		/**
		 * { Object } data
		 * { Object } icon
		 * { Array } icon.fa : ['far', 'user-chart']
		 * { String } icon.width : '12px'
		 * { String } icon.height : '13px'
		 * { String } icon.colorBg : '#f00000'
		 * { String } icon.colorSvg : 'tan'
		 * { String } email
		 * { String } detail
		 * { String } createdAt : Just now
		 */
		data: {
			type: Object,
			default: () => ({})
		}
	}
};
</script>

<style lang="scss" scoped>
.container-event {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: rem(15);

	&:last-of-type {
		margin-bottom: 0;
	}

	&-info {
		flex: 1;
		display: flex;
		align-items: baseline;

		&-icon {
			position: relative;
			top: rem(3);
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(24);
			height: rem(24);
			margin-right: rem(8);
			border-radius: 50%;
			background-color: $color-pale-green;

			&-inner {
				display: flex;
				width: rem(12);
				height: rem(12);

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		&-text {
			flex: 1;
			display: inline-block;
			word-break: break-word;

			&-email {
				font-weight: $font-weight-bold;
				margin-right: rem(4);
			}

			&-detail {
				color: $color-grey-4;
			}
		}
	}

	&-created {
		width: rem(68);
		text-align: right;
		font-size: $font-16;
		color: $color-grey-4;
	}
}
</style>