<template>
	<div :class="['container-modal', theme]">
		<div class="header">
			<div class="title">
				<FontAwesomeIcon :icon="['fas', 'exclamation-circle']" class="icon" />
				<span v-if="title">{{ title }}</span>
				<slot v-else name="header"></slot>
			</div>
			<span
				class="close-button"
				@click="handleCancel"
			>
				<FontAwesomeIcon :icon="['far', 'times']" />
			</span>
		</div>
		<div class="body">
			<span v-if="message">{{ message }}</span>
			<slot v-else name="body"></slot>
		</div>
		<div class="footer">
			<a
				class="footer-link"
				@click="handleCancel"
			>
				{{ textCancel }}
			</a>
			<BaseButton
				:type="buttonType"
				size="large"
				@click="handleSubmit"
			>
				{{ textSubmit }}
			</BaseButton>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseModalContent",

	props: {
		theme: {
			type: String,
			default: "success",
			validator: (value) => [
				"success",
				"danger"
			].includes(value)
		},
		title: {
			type: String,
			default: null
		},
		message: {
			type: String,
			default: null
		},
		textCancel: {
			type: String,
			default: "Cancel"
		},
		textSubmit: {
			type: String,
			default: "OK"
		}
	},

	computed: {
		buttonType() {
			if (this.theme === "success") {
				return "primary";
			}
			return this.theme;
		}
	},

	methods: {
		handleCancel() {
			this.$emit("onCancel");
		},

		handleSubmit() {
			this.$emit("onSubmit");
		}
	}
};
</script>

<style lang="scss" scoped>
.container-modal {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: rem(4);

	&.light {
		.header {
			background: $color-alabaster;
			color: $color-dark-blue-grey;
			border-right: 1px solid $color-silver;
			border-left: 1px solid $color-silver;

			&::after {
				border-color: $color-alabaster;
			}

			.icon {
				color: $color-dark-blue-grey;
			}
		}
	}

	&.success {
		.header {
			background: $color-alabaster;
			color: $color-dark-blue-grey;

			&::after {
				border-color: $color-emerald;
			}

			.icon {
				color: $color-emerald;
			}
		}
	}

	&.danger {
		.header {
			background: $color-alabaster;
			color: $color-dark-blue-grey;

			&::after {
				border-color: $color-red;
			}

			.icon {
				color: $color-red;
			}
		}
	}

	&.small {
		max-width: rem(390);
	}

	&.medium {
		max-width: rem(600);
	}

	&.large {
		max-width: rem(996);
	}

	.header {
		@include fontSub();

		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: rem(58);
		margin-top: $spacing-3;
		padding: $spacing-4 $spacing-6;
		font-size: $font-24;
		font-weight: $font-weight-bold;

		&::after {
			content: " ";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: calc(100% + #{rem(24)});
			height: rem(8);
			border-top: 8px solid;
		}

		.title {
			display: flex;
			align-items: center;

			span {
				margin-left: $spacing-4;
			}
		}

		.close-button {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: $font-18;
			cursor: pointer;
		}
	}

	.body {
		flex-grow: 1;
		border-right: 1px solid $color-silver;
		border-left: 1px solid $color-silver;
		padding: $spacing-6;
		font-size: $font-20;
		color: $color-dark-blue-grey;
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		min-height: rem(96);
		padding: $spacing-6;
		border-top: 1px solid $color-silver;
		border-right: 1px solid $color-silver;
		border-bottom: 1px solid $color-silver;
		border-left: 1px solid $color-silver;
		border-radius: 0 0 rem(4) rem(4);

		.footer-link {
			margin-right: $spacing-5;
			color: $color-cerulean;
			font-weight: $font-weight-bold;
			font-size: $font-18;
			cursor: pointer;
		}
	}
}
</style>