import dayjs from "dayjs";
import Numeral from "numeral";

export const axisXLabelFormatter = (date) => {
	const startOfDate = dayjs(date)
		.startOf("day")
		.unix();
	const currentDate = dayjs(date).unix();
	const result = startOfDate === currentDate
		? dayjs(date).format("HH:mm\nD MMM")
		: dayjs(date).format("HH:mm");
	return result;
};

export const xAxisLabelWithOutTime = (date) => {
	const startOfDate = dayjs(date)
		.startOf("day")
		.unix();
	const currentDate = dayjs(date).unix();
	const result = startOfDate === currentDate
		? dayjs(date).format("D MMM")
		: dayjs(date).format("HH:mm");
	return result;
};

export const getForEachFourHours = (index, date) => {
	const minute = dayjs(date).get("minute");
	const hour = dayjs(date).get("hour");
	const isFourHour = hour % 4 === 0 && minute === 0;
	return isFourHour;
};

export const getForEachSixHours = (name, date) => {
	const hour = dayjs(date).get("hour");
	const minute = dayjs(date).get("minute");
	return hour % 6 === 0 && minute === 0;
};

export const getForEachTwelveHours = (name, date) => {
	const hour = dayjs(date).get("hour");
	const minute = dayjs(date).get("minute");
	return hour % 12 === 0 && minute === 0;
};

export const getForEachDay = (index, date) => {
	const startOfDate = dayjs(date)
		.startOf("day")
		.unix();
	const currentDate = dayjs(date).unix();
	return startOfDate === currentDate;
};

export const getForEachHour = (index, date) => {
	const minute = dayjs(date).get("minute");
	return minute === 0;
};

export const getForEachThirtyMinute = (index, date) => {
	const isThirtyMinute = dayjs(date).get("minute") % 30 === 0;
	return isThirtyMinute;
};

export const getForEachTwoHours = (index, date) => {
	const minute = dayjs(date).get("minute");
	const hour = dayjs(date).get("hour");
	const isTwoHour = hour % 2 === 0 && minute === 0;
	return isTwoHour;
};

export const getForEachThreeDay = (index, date) => {
	const day = dayjs(date).get("date");
	const hour = dayjs(date).get("hour");
	const minute = dayjs(date).get("minute");
	return day % 3 === 0 && hour === 0 && minute === 0;
};

export const getForEachFiveDay = (index, date) => {
	const day = dayjs(date).get("date");
	const hour = dayjs(date).get("hour");
	const minute = dayjs(date).get("minute");
	return day % 5 === 0 && hour === 0 && minute === 0;
};

export const getNumeral = (value) => Numeral(value).format("0[.]0a");
