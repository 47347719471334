import { getMonitorUserOverallAPI } from "@/services/api/monitor.api";
import {
	USER_MONITOR_OVERALL_REQUEST,
	USER_MONITOR_OVERALL_SUCCESS,
	USER_MONITOR_OVERALL_FAILURE
} from "../../types";
import { parseUserOverall } from "../../../services/api/transforms/parseUserMonitor";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false
	},

	getters: {
		overall: ({ data }) => {
			return [
				{
					topic: "BIG staff",
					items: [
						{
							icon: {
								type: "far",
								name: "sign-in",
								width: "12px",
								height: "13px",
								top: "0px",
								left: "0px",
								colorBg: "#59e07a"
							},
							title: "Total sign-in",
							total: {
								number: data?.staff?.totalSignIns,
								unit: "sessions"
							}
						},
						{
							icon: {
								type: "far",
								name: "user-plus",
								width: "13px",
								height: "10px",
								top: "0px",
								left: "0px",
								colorBg: "#59e07a"
							},
							title: "Total user created",
							total: {
								number: data?.staff?.totalUsers,
								unit: "accounts"
							}
						},
						{
							icon: {
								type: "far",
								name: "user-clock",
								width: "13px",
								height: "10px",
								top: "0px",
								left: "0px",
								colorBg: "#59e07a"
							},
							title: "Total activity",
							total: {
								number: data?.staff?.totalActivities,
								unit: "activities"
							}
						}
					]
				},
				{
					topic: "Customer",
					items: [
						{
							icon: {
								type: "far",
								name: "sign-in",
								width: "12px",
								height: "13px",
								top: "0px",
								left: "0px",
								colorBg: "#62e5f6"
							},
							title: "Total sign-in",
							total: {
								number: data?.customer?.totalSignIns,
								unit: "sessions"
							}
						},
						{
							icon: {
								type: "far",
								name: "user-plus",
								width: "13px",
								height: "10px",
								top: "0px",
								left: "0px",
								colorBg: "#62e5f6"
							},
							title: "Total user created",
							total: {
								number: data?.customer?.totalUsers,
								unit: "accounts"
							}
						},
						{
							icon: {
								type: "far",
								name: "user-clock",
								width: "13px",
								height: "10px",
								top: "0px",
								left: "0px",
								colorBg: "#62e5f6"
							},
							title: "Total activity",
							total: {
								number: data?.customer?.totalActivities,
								unit: "activities"
							}
						}
					]
				}
			];
		}
	},

	mutations: {
		[USER_MONITOR_OVERALL_REQUEST](state) {
			state.isLoading = true;
			state.data = {};
		},
		[USER_MONITOR_OVERALL_SUCCESS](state, data) {
			state.data = parseUserOverall(data);
			state.isLoading = false;
		},
		[USER_MONITOR_OVERALL_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		async getUserOverall({ commit }, { filter }) {
			try {
				commit(USER_MONITOR_OVERALL_REQUEST);
				const { data } = await getMonitorUserOverallAPI({ filter });
				commit(USER_MONITOR_OVERALL_SUCCESS, data);
			} catch (error) {
				commit(USER_MONITOR_OVERALL_FAILURE);
			}
		}
	}
};
