<template>
	<div class="head-column-container">
		<div class="head-column-left">
			<div class="item">
				<strong>Date</strong>
			</div>
		</div>
		<div :class="['head-column-right', isFullWidth]">
			<div class="item" v-for="({text, status}, index) in columns" :key="index">
				<FontAwesomeIcon :class="['icon', status]" :icon="['fas', 'circle']" />
				<strong :class="status">{{ text }}</strong>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MeterOrderHistoryColumnHead",

	props: {
		columns: {
			type: Array,
			required: true
		}
	},

	computed: {
		isFullWidth() {
			return this.columns.length > 6 ? "full-width" : null;
		}
	}
};
</script>

<style lang="scss" scoped>
.head-column {
	// .head-column-container
	&-container {
		display: flex;
		padding: rem(12) rem(18);
		border-bottom: 1px solid $color-emerald;
	}

	// .head-column-right
	&-right {
		display: flex;

		// .head-column-right.full-width
		&.full-width {
			width: 100%;
		}

		// .head-column-right.full-width .item
		&.full-width .item {
			flex-grow: 1;
		}

		// .head-column-right .item
		.item {
			width: rem(124);
			padding: 0 0 0 rem(16);
			text-align: right;
			font-size: $font-18;

			.premium-1,
			.premium-2,
			.premium-3 {
				width: rem(98);
			}

			strong {
				color: $color-dark-blue-grey;
			}

			// .head-column-right .item .icon
			.icon {
				position: relative;
				top: rem(-3);
				margin-right: rem(4);
				width: rem(6);
				height: rem(6);

				&.base-1 {
					color: $color-emerald;
				}

				&.base-2 {
					color: $color-cool-green;
				}

				&.base-3 {
					color: $color-lightish-green;
				}

				&.premium-1 {
					color: $color-dull-orange;
				}

				&.premium-2 {
					color: $color-pastel-orange;
				}

				&.premium-3 {
					color: $color-pale-peach;
				}
			}
		}
	}

	// .head-column-left
	&-left {
		width: rem(130);

		// .head-column-left .item
		.item {
			margin-left: rem(24);
			// .head-column-left .item strong
			strong {
				color: $color-dark-blue-grey;
			}
		}
	}
}
</style>