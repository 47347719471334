import { getMeterMonthlyConsumptionAPI } from "@/services/api/meters.api";
import {
	METER_MONTHLY_REQUEST,
	METER_MONTHLY_SUCCESS,
	METER_MONTHLY_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		empty: true
	},

	mutations: {
		[METER_MONTHLY_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[METER_MONTHLY_SUCCESS](state, response) {
			state.data = response.data;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
			state.isEmpty = response.data.length === 0;
		},
		[METER_MONTHLY_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchConsumptionData({ commit }, { id = "", filterBy = "7d" }) {
			try {
				commit(METER_MONTHLY_REQUEST);

				const { data = [] } = await getMeterMonthlyConsumptionAPI(id, filterBy);

				commit(METER_MONTHLY_SUCCESS, data);
			} catch (error) {
				commit(METER_MONTHLY_FAILURE);
			}
		}
	}
};
