import { checkDevice } from "../selectors/helpers/checkDevice";

export default {
	data() {
		return {
			isCheckingDevice: true,
			device: "mobile"
		};
	},
	mounted() {
		this.device = checkDevice();
		this.$nextTick(() => {
			this.setResizeEventListener();
			this.isCheckingDevice = false;
		});
	},
	beforeDestroy() {
		this.removeResizeEventListener();
	},
	computed: {
		isMobile() {
			return this.device === "mobile";
		}
	},
	methods: {
		setDevice() {
			this.device = checkDevice();
		},
		setResizeEventListener() {
			window.addEventListener("resize", this.setDevice);
		},
		removeResizeEventListener() {
			window.removeEventListener("resize", this.setDevice);
		}
	}
};
