
import { getNitroFasOrderHistoryAPI } from "@/services/api/NitroFAS.api";
import { parseNitroFasHistories } from "../../../services/api/transforms/parseModules";
import { NITRO_FAS_ORDER_HISTORY_REQUEST, NITRO_FAS_ORDER_HISTORY_SUCCESS, NITRO_FAS_ORDER_HISTORY_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: parseNitroFasHistories([]),
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		totalPage: 1
	},

	mutations: {
		[NITRO_FAS_ORDER_HISTORY_REQUEST](state) {
			state.data = parseNitroFasHistories([]);
			state.isLoading = true;
			state.statusCode = null;
			state.isEmpty = false;
		},
		[NITRO_FAS_ORDER_HISTORY_SUCCESS](state, { data, status }) {
			const response = data;
			state.data = parseNitroFasHistories(response.data);
			state.isEmpty = response.data.length === 0;
			state.isLoading = false;
			state.statusCode = status;
			state.totalPage = response.pagination.lastPage;
		},
		[NITRO_FAS_ORDER_HISTORY_FAILURE](state, error) {
			state.data = parseNitroFasHistories([]);
			state.isLoading = false;
			state.statusCode = error.response.status;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchNitroFASOrderHistory({ commit }, { id, params }) {
			try {
				commit(NITRO_FAS_ORDER_HISTORY_REQUEST);
				const response = await getNitroFasOrderHistoryAPI(id, params);
				commit(NITRO_FAS_ORDER_HISTORY_SUCCESS, response);
			} catch (error) {
				commit(NITRO_FAS_ORDER_HISTORY_FAILURE, error);
			}
		}
	}
};
