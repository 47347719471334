<template>
	<div>
		<CardBarChart v-if="showDataLabel"
			key="TotalFinishGood1"
			:chartData="chartOptions"
			:isLoading="loading"
			:isEmpty="empty"
			:lastUpdate="updatedAt"
			:lastUpdateLeft="true"
			:chartTitle="chartTitle"
			@dateSelected="handleSelectDateRange"
			:showDataLabel="showDataLabel"
			@handleLabelSwitch="handleLabelSwitch"
		/>
		<CardBarChart v-if="!showDataLabel"
			key="TotalFinishGood2"
			:chartData="chartOptions"
			:isLoading="loading"
			:isEmpty="empty"
			:lastUpdate="updatedAt"
			:lastUpdateLeft="true"
			:chartTitle="chartTitle"
			@dateSelected="handleSelectDateRange"
			:showDataLabel="showDataLabel"
			@handleLabelSwitch="handleLabelSwitch"
		/>
	</div>
</template>

<script>
import Numeral from "numeral";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import {
	getDateNumber,
	convertFilterByOfPowerChart,
	getDateReportFormat,
	getDateReportType
} from "../../selectors/helpers";
import { formatCounter } from "../../selectors/helpers/formatCounter";

export default {
	props: {
		data: {
			type: Array,
			default: () => ([])
		},
		updatedAt: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Boolean,
			default: false
		},
		chartTitle: {
			type: String,
			default: "Total Finished Good"
		},
		chartYAxisLabel: {
			type: String,
			default: "Total Finished Good (Units)"
		},
		barWidth: {
			required: true,
			type: Number
		},
		filterSelected: {
			required: true,
			type: String
		},
		showDataLabel: {
			type: Boolean,
			default: null
		}
	},

	components: {
		CardBarChart
	},

	computed: {
		chartOptions() {
			return {
				legend: {
					show: true,
					data: [
						"Defects",
						"Finished Goods"
					],
					icon: "circle",
					bottom: 0,
					left: 24,
					itemWidth: 10,
					itemHeight: 10,
					itemGap: 24,
					fontFamily: "TFSrivichai",
					fontSize: 16,
					fontColor: "#71717",
					fontWeight: "bold"
				},
				tooltip: {
					show: true,
					trigger: "item",
					backgroundColor: "#103332",
					axisPointer: {
						type: "none"
					},
					position: "inside",
					padding: 8,
					formatter: (value) => {
						return this.showTooltip([value]);
					}
				},
				grid: {
					left: "16px",
					right: "16px",
					bottom: "48px",
					containLabel: true
				},
				xAxis: {
					type: "category",
					data: (() => {
						return this.data.reduce((acc, cur) => {
							const d = getDateReportFormat(getDateReportType(this.filterSelected))(cur.date);
							acc.push(d);
							return acc;
						}, []);
					})(),
					axisLine: {
						lineStyle: {
							color: "#cad9cf"
						}
					},
					axisLabel: {
						interval: 0,
						fontSize: 14,
						rotate: this.filterSelected === "1m" ? -45 : 0,
						formatter: (value) => {
							const filterValue = this.data.filter((item) => {
								const dateFormated = getDateReportFormat(getDateReportType(this.filterSelected))(item.date);
								return dateFormated === value;
							});
							const products = `{${this.showDataLabel ? "labelValue" : "hide"}|${formatCounter(filterValue[0]?.products || 0)}}`;
							// const defects = `{${this.showDataLabel ? "labelValue" : "hide"}|${formatCounter(filterValue[0]?.defects || 0)}}`; //TODO: disable temporary - uncomment when client need to use Defect Value
							const divider = `${this.filterSelected === "1m" ? ` ` : `\n`}`;
							// return [`${value}${divider}${defects}${divider}${products}`];  //TODO: disable temporary - uncomment when client need to use Defect Value
							return [`${value}${divider}${products}`];
						},
						rich: {
							labelValue: {
								backgroundColor: "rgba(0, 50, 108, 0.09)",
								borderRadius: 4,
								margin: [0, 0, 4, 0],
								padding: [2, 4, 2, 4],
								lineHeight: 22,
								fontFamily: "TFSrivichai",
								fontSize: 14,
								align: "center"
							},
							hide: {
								backgroundColor: "rgba(0, 0, 0, 0.00)",
								color: "white",
								borderRadius: 4,
								margin: [0, 0, 4, 0],
								padding: [2, 4, 2, 4],
								fontFamily: "TFSrivichai",
								fontSize: 14,
								align: "center"
							}
						}
					},
					axisTick: {
						show: false
					}
				},
				yAxis: {
					name: this.chartYAxisLabel,
					type: "value",
					color: "#59e07a",
					nameLocation: "end",
					nameTextStyle: {
						color: "#717171",
						fontFamily: "TFSrivichai",
						fontWeight: "bold",
						fontSize: 20,
						padding: [
							0,
							0,
							24,
							-23
						],
						align: "left"
					},
					axisLabel: {
						fontSize: 14,
						formatter: (value) => {
							return Numeral(value).format("0[.]0a");
						}
					},
					axisLine: {
						lineStyle: {
							color: "#cad9cf"
						}
					},
					axisTick: {
						length: 4,
						lineStyle: {
							width: 1,
							color: "#586c63"
						}
					}
				},
				axisLabel: {
					fontSize: 14,
					fontFamily: "TFSrivichai",
					color: "#103332"
				},
				series: [
					{
						name: "Finished Goods",
						type: "bar",
						stack: "one",
						barWidth: `${this.barWidth}px`,
						color: "#00a8e1",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								acc.push({
									value: cur.products,
									itemStyle: {
										color: "#00a8e1",
										barBorderRadius: [
											0,
											0,
											0,
											0
										]
									}
								});
								return acc;
							}, []);
						})()
					}
					// TODO: disable temporary - uncomment when client need to use Defect Value
					// {
					// 	name: "Defects",
					// 	type: "bar",
					// 	stack: "one",
					// 	barWidth: `${this.barWidth}px`,
					// 	color: "#cf0360",
					// 	data: (() => {
					// 		return this.data.reduce((acc, cur) => {
					// 			acc.push({
					// 				value: cur.defects,
					// 				itemStyle: {
					// 					color: "#cf0360",
					// 					barBorderRadius: [
					// 						2,
					// 						2,
					// 						0,
					// 						0
					// 					]
					// 				}
					// 			});
					// 			return acc;
					// 		}, []);
					// 	})()
					// }
				]
			};
		}
	},

	methods: {
		handleSelectDateRange(dateRange) {
			const filterBy = getDateNumber(dateRange);
			const filter = convertFilterByOfPowerChart(filterBy);
			const params = { filter };
			this.$emit("filter", params);
		},

		showTooltip(values) {
			let result = "";

			values.forEach((value) => {
				result += `<div class="bar-container bar-name">
						<span>
							${value.seriesName}
						</span>
						<span>
							${formatCounter(value.data.value) || "-"}
						</span>
					</div>`;
			});

			return `<div style="min-width: 150px;">
								${result}
							</div>`;
		},

		handleLabelSwitch(check, name) {
			this.$emit("handleLabelSwitch", check, name);
		}
	}
};
</script>
