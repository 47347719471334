<template>
	<div :class="['module-item', { active: isActive }]">
		<div class="module-item-header" @click="toggle">
			<h2 class="module-item-name">{{ item.name }}</h2>
			<div class="module-item-code"><span class="module-item-label">Location key</span> {{ item.key }}</div>
			<div class="module-item-toggle">
				<a>
					{{ isActive ? 'Hide Details' : 'Show Details' }} <FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
				</a>
			</div>
		</div>
		<div class="module-item-body container">
			<div class="row container-item">
				<div class="container-item-head">
					<div class="container-item-head-status">
						<span class="label">
							Analytics
						</span>
					</div>
				</div>
				<div class="container-item-border"></div>
				<div class="container-item-body">
					<div>
						<BaseButton
							v-show="collapsePowerBI"
							width="170px" size="small"
							type="primary" outline
							@click="handleCollapse(collapsePowerBI)">
							Add Workspace & Report ID
						</BaseButton>
					</div>
					<div class="power-bi-box" v-show="!collapsePowerBI">
						<BaseFormInput
							v-model="workspaceId"
							label="Workspace ID"
							:inputOptions="['small']"
							:controlOptions="['input-powerbi']"
							:maxLength="36"
							:isErrorPowerBI="isWorkspaceIdInvalid"
							placeholder="Workspace ID"
							errorMessagePowerBI="The workspace ID must be 36 characters."
						/>
						<BaseFormInput
							v-model="reportId"
							label="Report ID"
							:inputOptions="['small']"
							:controlOptions="['input-powerbi']"
							:maxLength="36"
							:isErrorPowerBI="isReportIdInvalid"
							placeholder="Report ID"
							errorMessagePowerBI="The report ID must be 36 characters."
						/>
						<BaseButton
							class="margin-button"
							width="100px"
							size="small"
							type="primary"
							outline
							@click="handlePowerBISave(workspaceId,reportId,id)">
							Save ID
						</BaseButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		BaseFormInput,
		BaseButton
	},

	props: {
		item: {
			type: Object,
			default: () => ({})

		}
	},

	data() {
		return {
			tableOptions: [
				"is-striped is-fullwidth"
			],
			isActive: false,
			status: {
				parent: false
			},
			id: this.item.id,
			collapsePowerBI: true,
			workspaceId: this.item.workspaceId,
			reportId: this.item.reportId,
			isWorkspaceIdInvalid: false,
			isReportIdInvalid: false
		};
	},

	methods: {
		async toggle() {
			this.isActive = !this.isActive;
		},
		handlePowerBISave(workspaceId = "", reportId = "", id = "") {
			const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
			// This is regex check format GUID
			// ^ represents the starting of the string.
			// [{]? represents the ‘{‘ character that is optional.
			// [0-9a-fA-F]{8} represents the 8 characters from a-f, A-F, and 0-9.
			// – represents the hyphens.
			// ([0-9a-fA-F]{4}-){3} represents the 4 characters from a-f, A-F, and 0-9 that is repeated 3 times separated by a hyphen (-).
			// [0-9a-fA-F]{12} represents the 12 characters from a-f, A-F, and 0-9.
			// [}]? represents the ‘}’ character that is optional.
			// $ represents the ending of the string.
			let isWorkspaceIdInvalid = !regex.test(workspaceId);
			let isReportIdInvalid = !regex.test(reportId);
			if (workspaceId === "" && reportId === "") {
				isWorkspaceIdInvalid = false;
				isReportIdInvalid = false;
			}
			this.isWorkspaceIdInvalid = isWorkspaceIdInvalid;
			this.isReportIdInvalid = isReportIdInvalid;
			if (!isWorkspaceIdInvalid && !isReportIdInvalid) {
				this.$emit("powerBISave", { id, workspaceId, reportId });
				this.collapsePowerBI = true;
			}
		},
		handleCollapse(isCollapse) {
			this.collapsePowerBI = !isCollapse;
		}
	}
};
</script>

<style lang="scss" scoped>
.module-item {
	border-radius: 4px;
	border: 1px solid $color-silver;
	background-color: $color-grey-1;
	margin-bottom: rem(16);
	line-height: 1.5;
	overflow: hidden;

	// .module-item-name
	&-name {
		flex-basis: rem(380);
		margin-right: rem(30);
		font-size: $font-24;
		line-height: 1.2;
		color: $color-dark-blue-grey;
	}

	// .module-item-label
	&-label {
		font-size: $font-18;
		font-weight: 400;
		color: $color-gunmetal-two;
		margin-right: rem(8);
	}

	// .module-item-code, .module-item-key
	&-code,
	&-key {
		margin-left: auto;
		font-weight: 600;
		color: $color-dark-blue-grey;

		@include desktop {
			min-width: rem(130);
			margin-right: rem(10);
		}
	}

	// .module-item-toggle
	&-toggle {
		margin-left: rem(64);

		a {
			width: rem(120);
			display: inline-block;
			color: $color-cerulean;
			font-weight: 600;

			svg {
				color: $color-cerulean;
				font-size: $font-16;
				margin-left: rem(10);
				position: relative;
				top: rem(3);
			}
		}
	}

	// .module-item-header
	&-header {
		display: flex;
		width: 100%;
		padding: rem(20) rem(16);
		cursor: pointer;
	}

	// .module-item-body
	&-body {
		display: none;
		width: 100%;
		background-color: $color-white;
		padding: rem(16) rem(16) 0 rem(16);
	}

	.fa-chevron-circle-down {
		transition: 0.3s transform ease-in-out;
	}

	&.active {
		.module-item-body {
			display: block;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}
}

.container-item {
	padding: 0 $spacing-5 $spacing-5 $spacing-5;

	&-head {
		@include fontSub();

		display: flex;
		justify-content: space-between;
		margin-bottom: rem(16);

		&-status {
			.label {
				color: $color-dark-blue-grey;
				font-size: $font-20;
				margin-right: 8px;
			}

			.value {
				color: $color-dark-blue-grey;
				font-size: $font-20;
				font-weight: $font-weight-bold;
			}
		}

		&-action {
			display: flex;
			align-items: center;
			margin-right: rem(14);
		}
	}

	&-body {
		margin-top: rem(14);

		/* stylelint-disable */
		&::v-deep .container-custom-main-table .action {
			text-align: right !important;
		}
		/* stylelint-enable */
	}

	// .container-item-border
	&-border {
		width: 98%;
		border-bottom: 1px solid $color-silver;
	}
}

.power-bi-box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: rem(10);
}

.margin-button {
	margin-top: rem(12);
}

$max-width-power-bi-box: rem(700);

@media screen and (max-width: $max-width-power-bi-box) {
	.power-bi-box {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: rem(10);
	}
}
</style>
