/**
 * Parse Customer List
 * @param {Object} reponse customer list data { data:[] }
 * @returns {Companies}
 */
export function parseCustomerListToOptions({ data }) {
	return data.reduce((acc, next) => {
		return [
			...acc,
			{
				label: next.name,
				value: next.name
			}
		];
	}, []);
}