<template>
	<BaseLayout>
		<BaseBackBtn :to="PAGE_NAME.USERS" class="page-back-btn" label="Back to staff account"/>
		<div class="wrapper-staff-create">
			<div class="title-staff-edit">
				<div class="edit-controller">
					<h4 class="staff-edit-title">
						{{ isEditable ? "Edit BIG staff detail" : "View BIG staff detail" }}
					</h4>
					<BaseButton
						v-if="!isEditable"
						:disabled="isEditable"
						size="medium"
						type="primary"
						@click="handleClickEdit"
					>
						Edit details
					</BaseButton>
				</div>
				<p class="staff-create-at">
					Account created at
					<span class="create-at-time">
						{{ createdAt }}
					</span>
				</p>
			</div>
			<BaseCard class="card-staff-info">
				<template v-slot:header>
					<h4>Staff information</h4>
				</template>
				<template v-slot:body>
					<BasicForm
						:email.sync="$v.email.$model"
						:azureId.sync="azureId"
						:role.sync="$v.systemRole.$model"
						:isTestAccount.sync="isTestAccount"
						:validateForm.sync="validateBasicForm"
						:emailDisabled="true"
						:roleDisabled="!isEditable"
						:isTestAccountDisabled="!isEditable"
						@clearPermission="handleClearPermission"
					/>
				</template>
			</BaseCard>
			<BaseCard>
				<template v-slot:header>
					<h4>Permission</h4>
				</template>
				<template v-slot:body>
					<div v-if="permissionLoading" class="permission-loading-wrapper">
						<BaseLoading />
					</div>
					<div v-else>
						<BaseBadgeInfomation
							v-if="isEditable"
							class="badge-info-permission"
							title="Visibility Permission"
						>
							<template #content-body>
								<ul class="permission-list">
									<li class="permission-list-item">Your first selection will be set as user visibility level. To reset level, please <strong>clear all permission setting</strong></li>
									<li class="permission-list-item">Every selection will effect to item list in other levels.</li>
									<li class="permission-list-item">Permission can be duplicated from staff email. <span v-if="showDuplicateButton" class="view-text clickable" @click="onClickOpenModal">Duplicate permission</span></li>
								</ul>
							</template>
						</BaseBadgeInfomation>
						<BaseLoading
							v-if="isLoadingStaffPermissions"
						/>
						<template
							v-else
						>
							<div
								v-if="isEditable"
								class="form-permission-header"
							>
								<h3 class="permission-level">
									Level
								</h3>
								<div class="permission-visibility">
									<h3 class="visibility-label">
										Visibility
									</h3>
									<BaseButton
										v-if="systemRole !== STAFF_ROLES.SALES"
										size="small"
										type="secondary"
										link
										@click="handleClearPermission"
									>
										Clear all permission setting
									</BaseButton>
								</div>
							</div>
							<BaseMultipleSelect
								:options="getSoldToOptions"
								:value="getSoldToValues"
								:customLabel="getLabelSoldTo"
								:disabled="!isEditable || isDisabledSoldTo || systemRole === STAFF_ROLES.SALES"
								:is-error="isSoldToError"
								:error-message="getSoldToErrorMessage"
								label="Sold-to *"
								placeholder="Search and select sold-to"
								multiple
								@onSelectValue="handleSelectSoldTo"
								@onRemoveSelected="handleRemoveSoldTo"
							></BaseMultipleSelect>
							<BaseMultipleSelect
								:options="getShipToOptions"
								:value="getShipToValues"
								:customLabel="getLabelShipTo"
								:disabled="!isEditable || isDisabledShipTo || systemRole === STAFF_ROLES.SALES"
								:is-error="isShipToError"
								:error-message="getShipToErrorMessage"
								label="Ship-to *"
								placeholder="Search and select ship-to"
								multiple
								defaultHeight
								@onSelectValue="handleSelectShipTo"
								@onRemoveSelected="handleRemoveShipTo"
							></BaseMultipleSelect>
							<BaseCheckBox
								:disabled="!isEditable || isDisabledShipTo || systemRole === STAFF_ROLES.SALES"
								:checked="isAutoUpdateShipTo"
								class="form-checkbox"
								label="Auto update ship-to that will be added in the future"
								@handleCheck="handleCheckUpdateShipTo"
							/>
							<BaseMultipleSelect
								:options="getApplicationOptions"
								:value="getApplicationValues"
								:disabled="!isEditable || isDisabledApplication || systemRole === STAFF_ROLES.SALES"
								:is-error="isApplicationError"
								:error-message="getApplicationErrorMessage"
								label="Application *"
								placeholder="Search and select application"
								multiple
								@onSelectValue="handleSelectApplication"
								@onRemoveSelected="handleRemoveApplication"
							></BaseMultipleSelect>
							<BaseMultipleSelect
								:options="getDeviceOptions"
								:value="getDeviceValue"
								:disabled="!isEditable || isDisableDevice || systemRole === STAFF_ROLES.SALES"
								:is-error="isDeviceError"
								:error-message="getDeviceErrorMessage"
								label="Device *"
								placeholder="Search and select device"
								multiple
								defaultHeight
								@onSelectValue="handleSelectDevice"
								@onRemoveSelected="handelRemoveDevice"
							></BaseMultipleSelect>
							<BaseCheckBox
								:disabled="!isEditable || isDisableDevice || systemRole === STAFF_ROLES.SALES"
								:checked="isAutoUpdateDevice"
								class="form-checkbox"
								label="Auto update device that will be added in the future"
								@handleCheck="handleCheckUpdateDevice"
							/>
						</template>
					</div>
				</template>
			</BaseCard>
			<BaseCard class="card-active-status">
				<template v-slot:header>
					<h4>Activate / Deactivate this account</h4>
				</template>
				<template v-slot:body>
					<div class="wrapper-staff-status">
						<div class="status-form-group">
							<p class="status-description">Activate or temporary deactivate this user account.</p>
							<div class="form-status">
								<BaseSwitch
									class="switch-status"
									:checked="statusActive"
									:disabled="!isEditable"
									@input="handleChangeStatus"
								/>
								<label class="status-label">{{ getStatusLabel }}</label>
							</div>
						</div>
						<p class="deactivate-description">Deactivate this user will only disable this user account to login the system.</p>
					</div>
				</template>
			</BaseCard>
			<div v-if="isEditable" class="wrapper-confirm-create-staff">
				<button
					class="view-button mr-auto"
					@click="handleClickDelete"
				>
					Delete this user
				</button>
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleClickCancle"
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="large"
					type="primary"
					@click="handleSubmitMainForm(handleSendUpdateCustomerMainform)"
				>
					Apply Change
				</BaseButton>
			</div>
		</div>
		<DialogUserDelete :modal-name="MODAL_NAME_DELETE" @onSubmit="handleSubmitUserDelete"/>
		<ModalDuplicatePermission
			:modal-name="MODAL_NAME_DUP_PERMISSION"
			:emails="staffEmails"
			:loading="isLoadingStaffList"
			:onReset.sync="onResetFormModalDup"
			@onSubmit="handleSubmitModal"
		/>
	</BaseLayout>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import BaseCard from "@/components/BaseCard.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";
import BaseBadgeInfomation from "@/components/BaseBadgeInfomation.vue";
import BasicForm from "@/components/Staff/BasicForm.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import DialogUserDelete from "@/components/usermanagement/DialogUserDelete.vue";
import { validationMixin } from "vuelidate";
import BaseButton from "@/components/BaseButton.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModalDuplicatePermission from "@/components/Staff/ModalDuplicatePermission.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import updateCustomerValidateCustomMixin from "../../selectors/formValidate/userManagement/updateCustomerValidateCustomMixin";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { STAFF_ROLES } from "../../enums/role";
import devicePermissionHandleCustom from "../../selectors/mixins/userManagement/devicePermissionHandleCustom";

export default {
	name: "CustomerEdit",

	mixins: [
		validationMixin,
		updateCustomerValidateCustomMixin,
		devicePermissionHandleCustom
	],

	components: {
		BaseCard,
		BaseBadgeInfomation,
		BaseButton,
		BaseBackBtn,
		BaseMultipleSelect,
		BaseCheckBox,
		BasicForm,
		BaseLoading,
		DialogUserDelete,
		ModalDuplicatePermission,
		BaseSwitch
	},

	data() {
		return {
			PAGE_NAME,
			STAFF_ROLES,
			email: "",
			azureId: "",
			systemRole: "",
			statusActive: false,
			isTestAccount: false,
			validateBasicForm: null,
			isEditable: false,
			permissionLoading: false,
			isLoadingStaffList: false,
			isLoadingStaffPermissions: false,
			MODAL_NAME_DELETE: "modal-user-delete",
			MODAL_NAME_DUP_PERMISSION: "modal-duplicate-permission",
			onResetFormModalDup: null
		};
	},

	computed: {
		...mapState("staff", {
			staffDetail: "detail"
		}),
		...mapGetters("staff", {
			getStaffSoldToValues: "getStaffSoldToValues",
			getStaffShipToValues: "getStaffShipToValues",
			getStaffApplicationValues: "getStaffApplicationValues",
			getStaffDeviceValues: "getStaffDeviceValues",
			getIsUpdateShipTo: "getIsUpdateShipTo",
			getIsUpdateDeivce: "getIsUpdateDeivce",
			isAllSoldTo: "isAllSoldTo",
			isAllShipTo: "isAllShipTo",
			isAllApplication: "isAllApplication",
			isAllDevice: "isAllDevice"
		}),
		...mapGetters({
			getStaffs: "staff/getTransformStaffs"
		}),
		createdAt() {
			const d = this.staffDetail.data.createdAt;
			return dayjs(d).format("D MMM YYYY, HH:mm");
		},
		staffEmails() {
			return this.getStaffs.map((item) => item.email);
		},
		getStatusLabel() {
			if (this.statusActive) {
				return "Active";
			}
			return "Inactive";
		},
		showDuplicateButton() {
			return this.$v.systemRole.$model !== STAFF_ROLES.SALES && this.isEditable;
		}
	},

	async created() {
		this.permissionLoading = true;
		await this.fetchStaffDetail(this.$route.params.id);
		this.email = this.staffDetail.data.email;
		this.azureId = this.staffDetail.data.id;
		this.systemRole = this.staffDetail.data.role.code;
		this.statusActive = this.staffDetail.data.isActive;
		this.isTestAccount = this.staffDetail.data.isTestAccount;
		await this.fetchPermissionList({});
		await this.setInitialPermission();
		this.isAutoUpdateShipTo = this.getIsUpdateShipTo;
		this.isAutoUpdateDevice = this.getIsUpdateDeivce;
		this.permissionLoading = false;

		this.isLoadingStaffList = true;
		await this.fetchStaffLists({ perPage: 10000 });
		this.isLoadingStaffList = false;
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions({
			fetchStaffDetail: "staff/fetchStaffDetail",
			updateStaffRole: "staff/updateStaffRole",
			deleteStaffRole: "staff/deleteStaffRole",
			fetchStaffLists: "staff/fetchStaffLists",
			postStaffPermission: "staff/postStaffPermission"
		}),
		async handleSendUpdateCustomerMainform() {
			const { id } = this.$route.params;
			const data = {
				email: this.email,
				roleCode: this.systemRole,
				permissions: this.computePermission,
				isActive: this.statusActive,
				isTestAccount: this.isTestAccount
			};
			await this.updateStaffRole({ id, data });
			this.isEditable = false;
			await this.fetchStaffDetail(id);
			await this.fetchPermissionList({});
			this.isSelectCompany = false;
			this.showToast({
				type: TOAST_TYPES.STAFF_UPDATE,
				props: { email: this.email, delay: 6000 }
			});
			this.$v.$reset();
		},
		handleClickDelete() {
			this.$modal.show(this.MODAL_NAME_DELETE, { id: this.$route.params.id, email: this.email });
		},
		handleClickCancle() {
			this.$router.push({
				name: PAGE_NAME.USERS
			});
		},
		handleClickEdit() {
			this.isEditable = !this.isEditable;
		},
		async handleSubmitUserDelete({ id, email }) {
			await this.deleteStaffRole({ id });
			this.hideModalUserDelete();
			this.showToast({
				type: TOAST_TYPES.STAFF_DELETE,
				props: { email }
			});
			this.$router.push({
				name: PAGE_NAME.USERS
			});
		},
		hideModalUserDelete() {
			this.$modal.hide(this.MODAL_NAME_DELETE);
		},
		onClickOpenModal() {
			this.$modal.show(this.MODAL_NAME_DUP_PERMISSION);
		},
		async handleSubmitModal(email = "") {
			this.$modal.hide(this.MODAL_NAME_DUP_PERMISSION);
			this.isLoadingStaffPermissions = true;
			await this.postStaffPermission({ email });
			await this.fetchPermissionList({});
			await this.setInitialPermission();
			this.isAutoUpdateShipTo = this.getIsUpdateShipTo;
			this.isAutoUpdateDevice = this.getIsUpdateDeivce;
			this.isLoadingStaffPermissions = false;
		},
		handleChangeStatus() {
			if (this.isEditable) {
				this.statusActive = !this.statusActive;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.wrapper-staff-create {
	padding: rem(24) 0;
}

.password-validate-infomation {
	&.container-info {
		width: rem(343);
		padding: 0;
		background-color: transparent;
	}
}

.form-permission-header {
	display: flex;
	align-items: center;

	.permission {
		&-level {
			font-size: $font-20;
			width: rem(130);
		}

		&-visibility {
			display: flex;
			justify-content: space-between;
			flex-grow: 1;

			.visibility-label {
				font-size: $font-20;
			}
		}
	}
}

.badge-info {
	&-permission {
		margin-bottom: rem(24);
	}
}

.permission-list {
	margin-left: rem(18);
	list-style-position: inside;

	&-item {
		list-style-type: disc;
	}
}

.form-checkbox {
	margin-left: rem(130);
}

.create-staff-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.system-role-info-icon {
	font-size: rem(12);
	color: $color-cerulean;
	cursor: pointer;
	margin-left: rem(6);
}

.staff-create-input {
	margin: rem(24) 0;
}

.input-group-search {
	width: 100%;

	@include desktop {
		width: 100%;

		/* stylelint-disable */
		&::v-deep .dropdown-value {
			padding: 0;
		}
		/* stylelint-enable */
	}
}

.card-password {
	margin-bottom: rem(56);
}

.card-staff-info {
	margin-top: rem(40);
}

.info-password-description {
	font-size: rem(16);
	margin: 0;
	color: $color-dark-blue-grey;
}

.generate-password-description {
	color: $color-dark-blue-grey;
	margin: rem(32) 0 rem(-8) 0;
}

.wrapper-generate-password {
	display: flex;
	align-items: center;
}

.container-generate-password {
	display: flex;
	align-items: center;
	width: rem(494);

	.btn {
		display: flex;
		align-items: center;
	}

	.generate-password-btn {
		font-size: rem(20);
		min-width: rem(150);
		justify-content: center;
		font-weight: $font-weight-bold;
	}

	.redo-password-icon {
		margin-right: rem(8);
		font-weight: $font-weight-bold;
	}
}

.copy-password-btn {
	margin-left: rem(11);
}

.redo-password-icon,
.copy-password-icon {
	font-size: rem(16);
}

.copy-password-icon {
	margin-right: rem(4);
}

.wrapper-confirm-create-staff {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.edit-controller {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.staff-create-at {
	margin: 0;
	font-size: rem(18);
	color: $color-dark-blue-grey;

	.create-at-time {
		font-weight: $font-weight-bold;
	}
}

.staff-edit-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.permission-loading-wrapper {
	min-height: rem(562);
	display: flex;
	justify-content: center;
	align-items: center;
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}

.card-active-status {
	margin-bottom: rem(66);
}

.status-form-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.form-status {
	display: flex;
}

.switch-status {
	margin-right: rem(16);
}

.status-description,
.status-label {
	font-size: rem(20);
	color: $color-dark-blue-grey;
	margin: 0;
}


.status-label {
	min-width: rem(45);
}

.deactivate-description {
	font-size: rem(18);
	color: $color-grey-4;
	margin: 0;
}
</style>
