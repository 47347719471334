<template>
	<div :class="['container-toast', 'drop-shadow', color]">
		<button class="close" @click="hideToast">
			<FontAwesomeIcon :icon="['far', 'times']"/>
		</button>
		<div class="container-body">
			<div class="container-icon">
				<FontAwesomeIcon :icon="iconClass"/>
			</div>
			<div class="container-content">
				<template>
					<div v-if="$slots.title" class="title-slot">
						<slot name="title"></slot>
					</div>
					<div v-else class="title">
						<strong>
							{{ title }}
						</strong>
					</div>
				</template>
				<template>
					<div v-if="$slots.message" class="message-slot">
						<slot name="message"></slot>
					</div>
					<div v-else class="message">
						<span v-html="message"></span>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "ToastStaffManagement",

	props: {
		color: {
			type: String,
			default: "success",
			validator: (value) => {
				return [
					"success",
					"warning",
					"danger",
					"info"
				].includes(value);
			}
		},
		title: {
			type: String,
			required: true
		},
		message: {
			type: String,
			default: ""
		}
	},

	computed: {
		iconClass() {
			if (this.color === "success") {
				return ["fas", "check-circle"];
			}
			return ["fas", "exclamation-circle"];
		}
	},

	methods: {
		...mapActions({
			hideToast: "toast/hide"
		})
	}
};
</script>

<style lang="scss" scoped>
$border-radius-size: rem(4);

.container {
	&-toast {
		position: relative;
		background: $color-white;
		border-radius: $border-radius-size;
		min-height: rem(74);
		overflow: hidden;

		&::before {
			content: " ";
			display: block;
			position: absolute;
			width: 100%;
			height: rem(8);
		}

		&.success {
			&::before {
				background: $color-emerald;
			}

			.container-icon {
				color: $color-emerald;
			}

			.container-content {
				.title {
					color: $color-emerald;
				}
			}
		}

		&.warning {
			&::before {
				background: $color-marigold;
			}

			.container-icon {
				color: $color-marigold;
			}

			.container-content {
				.title {
					color: $color-marigold;
				}
			}
		}

		&.danger {
			&::before {
				background: $color-red;
			}

			.container-icon {
				color: $color-red;
			}

			.container-content {
				.title {
					color: $color-red;
				}
			}
		}

		&.info {
			&::before {
				background: $color-azure;
			}

			.container-icon {
				color: $color-azure;
			}

			.container-content {
				.title {
					color: $color-azure;
				}
			}
		}

		&.drop-shadow {
			box-shadow: $box-shadow;

			.container-body {
				border: none;
			}
		}

		.close {
			position: absolute;
			top: rem($spacing-5);
			right: rem($spacing-5);
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(24);
			height: rem(24);
			background: none;
			border: none;
			color: $color-boulder;
		}
	}

	&-body {
		display: flex;
		padding: $spacing-5 0 $spacing-5 0;
		margin-top: rem(8);
		border: 1px solid $color-silver;
		border-top: none;
		border-radius: 0 0 $border-radius-size $border-radius-size;
	}

	&-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: rem(90);
		font-size: $font-32;
	}

	&-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding-right: rem(50);

		.title, .message {
			display: flex;
			flex-grow: 1;

			span, strong {
				display: flex;
				align-items: center;
				line-height: rem(16);
			}
		}

		.title {
			font-size: $font-20;
			font-weight: $font-weight-bold;
		}

		.message {
			margin-top: rem(8);
			font-size: $font-16;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;

			/* stylelint-disable */
			&::v-deep .mx-2 {
				margin-right: $spacing-2;
				margin-left: $spacing-2;
			}

			&::v-deep .link {
				@include fontBase();

				font-size: rem(16);
				font-weight: $font-weight-bold;
				color: $color-cerulean;
			}
			/* stylelint-enable */
		}
	}
}
</style>