import { mappingGengasLabel } from "../../enums/layoutCustomisation/gengas";


export const parseGenGasOverview = ({ layout }) => {
	const keysArr = Object.keys(layout?.overview ?? {});
	if (!layout.hasOwnProperty("overviewDisplayType")) {
		layout.overviewDisplayType = {
			linePressure: "icon",
			oxygen: "icon"
		};
	}
	const computeOverview = [{
		key: "tagNumber",
		name: "TAG No.",
		title: "TAG No.",
		value: false,
		show: false,
		field: "TAG No.",
		unit: null
	}];
	for (let i = 0; i < keysArr.length; i++) {
		computeOverview.push({
			key: keysArr[i],
			name: keysArr[i],
			title: mappingGengasLabel[keysArr[i]],
			value: layout.overview[keysArr[i]],
			field: layout.hasOwnProperty("overviewField") && layout.overviewField.hasOwnProperty([`${keysArr[i]}Field`])
				? layout.overviewField[`${keysArr[i]}Field`]
				: null,
			unit: layout.hasOwnProperty("overviewField") && layout.overviewField.hasOwnProperty([`${keysArr[i]}FieldUnit`])
				? layout.overviewField[`${keysArr[i]}FieldUnit`]
				: null,
			show: true,
			type: layout?.overviewDisplayType?.[keysArr[i]],
			typeOptions: [{ label: "Text", value: "text" }, { label: "Icon", value: "icon" }]
		});
	}
	return computeOverview;
};

export const getConfigGenGasOverview = (overviewLists = []) => {
	const overview = {};
	const overviewField = {};
	const overviewDisplayType = {};
	for (let i = 0; i < overviewLists.length; i++) {
		if (overviewLists[i].show) {
			overview[overviewLists[i].key] = overviewLists[i].value;
			overviewField[`${overviewLists[i].key}Field`] = (overviewLists[i].field && overviewLists[i].field.trim()) || "";
			overviewField[`${overviewLists[i].key}FieldUnit`] = (overviewLists[i].unit && overviewLists[i].unit.trim()) || "";
			overviewDisplayType[`${overviewLists[i].key}`] = (overviewLists[i].type && overviewLists[i].type.trim()) || "";
		}
	}
	return {
		overview: { ...overview },
		overviewField: { ...overviewField },
		overviewDisplayType: { ...overviewDisplayType }
	};
};
