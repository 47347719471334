<template>
	<div>
		<div class="container-head">
			<Sticky>
				<ColumnHead :columns="filterHeadColumns"/>
			</Sticky>
		</div>
		<div class="container-items">
			<MeterOrderHistoryItem
				v-for="(item, index) in items"
				:key="index"
				:item="item"
				class="item"
			/>
		</div>
	</div>
</template>

<script>
import ColumnHead from "@/components/dashboard/modules/MeterOrderHistory/ColumnHead.vue";
import MeterOrderHistoryItem from "@/components/dashboard/modules/MeterOrderHistory/MeterOrderHistoryItem.vue";
import Sticky from "@/components/Sticky.vue";
import { NM_UNIT } from "../selectors/transform/unit";

export default {
	name: "BaseHistoryListsWithBarG",

	components: {
		ColumnHead,
		MeterOrderHistoryItem,
		Sticky
	},

	props: {
		headColumns: {
			type: Array,
			required: true,
			default: () => ([{
				text: `Base1 (${NM_UNIT})`,
				status: "base-1"
			},
			{
				text: `Base2 (${NM_UNIT})`,
				status: "base-2"
			},
			{
				text: `Base3 (${NM_UNIT})`,
				status: "base-3"
			},
			{
				text: `Premium1 (${NM_UNIT})`,
				status: "premium-1"
			},
			{
				text: `Premium2 (${NM_UNIT})`,
				status: "premium-2"
			},
			{
				text: `Premium3 (${NM_UNIT})`,
				status: "premium-3"
			}])
		},
		items: {
			type: Array,
			required: true,
			default: () => ([{
				body: {
					date: "Mar 2020",

					values: [{
						value: "674,040",
						percentage: "67.92%"
					},
					{
						value: "278,040",
						percentage: "26.92%"
					},
					{
						value: "124,040",
						percentage: "12.92%"
					},
					{
						value: "104,040",
						percentage: "10.92%"
					},
					{
						value: "104,040",
						percentage: "10.92%"
					},
					{
						value: "44,040",
						percentage: "4.92%"
					}]
				},

				footer: {
					total: "99,999,999",

					percentages: [{
						value: "40%",
						color: "base-1"
					},
					{
						value: "22%",
						color: "base-2"
					},
					{
						value: "20%",
						color: "base-3"
					},
					{
						value: "10%",
						color: "premium-1"
					},
					{
						value: "5%",
						color: "premium-2"
					},
					{
						value: "3%",
						color: "premium-3"
					}]
				}
			}])
		}
	},

	data() {
		return {
			NM_UNIT
		};
	},

	computed: {
		// we have to filter head columns because
		// we don't know how many type of data will response.
		// following there columns: Base1, Base2, Base3, Premium1, Premium2, Premium3.
		// so, we have to only display Base1 and Premium1 or all of them.
		filterHeadColumns() {
			return this.headColumns.filter((column) => column);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-items {
	margin: rem(24) auto;

	.item {
		margin-bottom: rem(18);
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}
</style>