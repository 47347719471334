<template>
	<BaseLayout pageTitle="Homepage" />
</template>

<script>

export default {
	name: "Home",

	created() {
		this.$router.replace({ name: "login" });
	}
};
</script>
