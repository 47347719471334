<template>
	<div class="container-filter">
		<div :class="['container-datepicker', ...wrapperClass]" v-click-outside="hideDatePicker">
			<BaseFormInput
				:value="displayDate"
				:readonly="true"
				class="custom-input has-icons-left medium"
				type="text"
				iconLeft="calendar"
				placeholder="Select date range"
				@click="handleInputClick"
			/>
			<div v-if="isShowDatePicker" class="container-datepicker-control">
				<div class="container-datepicker-control-inner" @click="isClickedDate = true">
					<div v-if="false" class="over-lay-disbled"></div>
					<v-date-picker
						color="green"
						mode="range"
						v-model="dateRange"
						:max-date="maxDateDisabled"
						:popover="{ placement: 'bottom', visibility: 'click' }"
						title-position="left"
						locale="en"
						is-inline
						is-expanded
						is-range
					/>
				</div>
				<div class="container-datepicker-control-footer">
					<div class="container-datepicker-control-footer-select">
						<button
							v-for="(item, index) in dateShortcutOptions"
							:key="index"
							:class="['btn', {'active': item.value === dateShortcut}]"
							@click="onClickOption($event, item)"
						>
							{{ item.text }}
						</button>
					</div>
					<BaseButton size="small" rounded class="custom-done-btn" @click="onDone">
						Select
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

export default {
	props: {
		autohide: {
			type: Boolean,
			default: false
		},
		position: {
			type: String,
			default: "left",
			validator: (value) => {
				return [
					"left",
					"right"
				].includes(value);
			}
		},
		dateInput: {
			type: Object,
			default: () => ({})
		},
		initialDateShortcut: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			dateRange: this.dateInput,
			isShowDatePicker: false,
			isClickedDate: false,
			dateShortcut: this.initialDateShortcut,
			dateShortcutOptions: [
				{
					text: "Custom",
					value: null
				},
				{
					text: "Last week",
					value: "7d"
				},
				{
					text: "Last 1 month",
					value: "1m"
				},
				{
					text: "Last 3 months",
					value: "3m"
				},
				{
					text: "Last 6 months",
					value: "6m"
				}
			],
			isActive: false
		};
	},

	computed: {
		wrapperClass() {
			return [
				this.positionClass
			];
		},

		positionClass() {
			return `position-${this.position}`;
		},

		displayDate() {
			const f = "DD/MM/YYYY";
			const date = this.dateRange;
			let str = "";

			try {
				str = date.start || date.end
					? `${dayjs(date.start).format(f)} - ${dayjs(date.end).format(f)}`
					: "Select date range";
			} catch (e) {
				str = "Select date range";
			}

			return str;
		},

		datePickerDisabled() {
			const isCustom = this.dateShortcut === null;
			return !isCustom;
		},

		maxDateDisabled() {
			return dayjs().subtract(-1, "year").toDate();
		}
	},

	watch: {
		dateRange() {
			if (this.autohide) {
				this.isShowDatePicker = false;
			}
			if (this.isClickedDate && this.dateShortcut) {
				this.dateShortcut = null;
			}

			if (this.dateRange) {
				this.$emit("onChange", this.dateRange);
			}
		}
	},

	methods: {
		hideDatePicker() {
			this.isShowDatePicker = false;
			this.isActive = false;

			this.$emit("active", this.isActive);
		},
		handleInputClick() {
			this.isShowDatePicker = !this.isShowDatePicker;
			this.isActive = !this.isActive;

			this.$emit("active", this.isActive);
		},
		onDone() {
			this.isShowDatePicker = false;
			this.isActive = false;

			const dateRange = {
				start: this.dateRange?.start || null,
				end: this.dateRange?.end || null
			};

			this.$emit("active", this.isActive);
			this.$emit("onSubmit", dateRange);
		},
		onClear() {
			this.dateRange = null;
		},
		onClickOption(event, item) {
			dayjs.extend(weekday);

			this.isShowDatePicker = false;

			this.dateShortcut = item.value;
			this.isClickedDate = false;
			switch (this.dateShortcut) {
				case null:
					this.dateRange = null;
					break;
				case "7d":
					this.dateRange = {
						start: dayjs().subtract(6, "day").toDate(),
						end: dayjs().subtract(0, "day").toDate()
					};
					break;
				case "1m":
					this.dateRange = {
						start: dayjs().subtract(1, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				case "3m":
					this.dateRange = {
						start: dayjs().subtract(3, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				case "6m":
					this.dateRange = {
						start: dayjs().subtract(6, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				case "last_1_year":
					this.dateRange = {
						start: dayjs().subtract(1, "year").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				default:
			}

			this.$emit("onFilter", this.dateShortcut);
			setTimeout(() => {
				this.isShowDatePicker = true;
				this.$emit("active", true);
			}, 10);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-filter {
	display: flex;
}

.container-datepicker {
	position: relative;
	width: rem(200);

	&.position-left > &-control {
		left: 0;
	}

	&.position-right > &-control {
		right: 0;
	}

	&-control {
		display: flex;
		position: absolute;
		z-index: 5;
		bottom: rem(-8);
		transform: translateY(100%);
		border-radius: rem($border-radius-normal);
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
		background-color: $color-white;
		overflow: hidden;

		&-inner {
			position: relative;
			display: flex;
			width: rem(272);

			.over-lay-disbled {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background: rgba(255, 255, 255, 0.5);
			}

			.vc-container {
				background: none;
				border: none;

				/* stylelint-disable */
				&::v-deep .vc-day {
					&-layer {
						.vc-highlight {
							&.vc-bg-blue-600 {
								background-color: $color-green-haze;
							}

							&.vc-border-blue-700 {
								border-color: $color-green-haze;
							}

							&-base {
								&-start, &-middle, &-end {
									background-color: $color-ice;
								}
							}
						}
					}

					&-content {
						@include fontBase();

						font-weight: normal;
						font-size: $font-20;

						&:first-child {
							&:hover {
								background-color: $color-ice;
							}
						}
					}
				}

				&::v-deep .is-today .vc-day-content {
					border: 1px solid $color-green-haze;
				}

				&::v-deep .vc-pane {
					.vc-title {
						@include fontSub();

						padding-right: rem(20);
						color: $color-dark-blue-grey;
						font-size: $font-20;
						font-weight: $font-weight-bold;

						&::after {
							content: " ";
							position: absolute;
							top: 8%;
							margin-left: rem(4);
							width: 0;
							height: 0;
							border-left: rem(5) solid transparent;
							border-right: rem(5) solid transparent;
							border-top: rem(5) solid $color-cerulean;
						}
					}
				}

				&::v-deep .vc-arrows-container {
					svg {
						color: $color-cerulean;
					}
				}

				&::v-deep .vc-weekday {
					@include fontBase();

					font-size: $font-16;
					font-weight: $font-weight-bold;
					color: $color-grey-4;
				}

				&::v-deep .vc-popover-content {
					background-color: $color-white;
					color: $color-mirage;

					.vc-grid-cell {
						.vc-nav-title {
							color: $color-mirage;
						}

						span.vc-grid-focus:not(.vc-nav-title):not(.vc-nav-arrow) {
							background-color: $color-green-haze;
							color: $color-white;
							border: none;
						}

						span {
							color: $color-mirage;

							&:hover {
								background-color: $color-ice;
								color: $color-mirage;
							}
						}
					}
				}

				&::v-deep .vc-grid-cell {
					&-col {
						&-1, &-7 {
							.vc-day-content {
								color: $color-grey-4;
							}
						}

						&-2, &-3, &-4, &-5, &-6 {
							.vc-day-content {
								color: $color-dark-blue-grey;
							}
						}
					}

					.vc-day-content {
						&.vc-text-white {
							color: $color-white;
						}

						&.vc-text-gray-400 {
							color: #cbd5e0;
							cursor: not-allowed;
						}
					}
				}
				/* stylelint-enable */
			}
		}

		&-footer {
			position: relative;
			width: rem(152);
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: rem(16);

			&::before {
				content: "";
				position: absolute;
				width: 1px;
				height: calc(100% - 32px);
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background: #cad9cf;
			}

			&-select {
				flex: 1;
				width: 100%;
				display: flex;
				flex-direction: column;

				.btn {
					width: 100%;
					background: none;
					border: none;
					outline: none;
					color: $color-dark-blue-grey;
					padding: rem(2) 16px;
					box-sizing: border-box;
					border-radius: rem(4);
					margin-bottom: rem(6);
					text-align: left;
					font-size: $font-18;

					&:last-of-type {
						margin-bottom: 0;
					}

					&.active {
						background-color: $color-ice;
						font-weight: $font-weight-bold;
					}
				}
			}

			.custom-done-btn {
				width: rem(64);
				margin-top: rem(10);
			}
		}
	}

	/* stylelint-disable */
	.custom-input {
		cursor: pointer;

		&.small {
			&::v-deep .input {
				height: rem(32);
				line-height: rem(32);
				padding: rem(1) rem(10) 0 rem(40);
			}
		}

		&.medium {
			&::v-deep .input {
				height: rem(40);
				line-height: rem(40);
				padding: rem(2) rem(10) 0 rem(40);
			}
		}

		&::v-deep .input {
			background-color: $color-alabaster;
			cursor: pointer;
			border: 0;
		}

		&::v-deep .is-left {
			width: rem(40);

			svg {
				width: rem(13);
				height: rem(16);
				color: $color-dark-blue-grey;
			}
		}
	}
	/* stylelint-enable */
}
</style>