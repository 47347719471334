import { putFlowDiagramAPI } from "@/services/api/flowdiagram.api";
import { FLOW_DIAGRAM_UPDATE_REQUEST, FLOW_DIAGRAM_UPDATE_SUCCESS, FLOW_DIAGRAM_UPDATE_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		loading: true
	},

	mutations: {
		[FLOW_DIAGRAM_UPDATE_REQUEST](state) {
			state.loading = true;
		},
		[FLOW_DIAGRAM_UPDATE_SUCCESS](state) {
			state.loading = false;
		},
		[FLOW_DIAGRAM_UPDATE_FAILURE](state) {
			state.loading = false;
		}
	},

	actions: {
		async updateFlowDiagram({ commit }, { id, data }) {
			try {
				commit(FLOW_DIAGRAM_UPDATE_REQUEST);
				await putFlowDiagramAPI(id, data);
				commit(FLOW_DIAGRAM_UPDATE_SUCCESS);
			} catch (error) {
				commit(FLOW_DIAGRAM_UPDATE_FAILURE);
			}
		}
	}
};
