import { dateTimeFormat, numberFormat } from "../../../../selectors/helpers";
import { replaceDeviceName } from "../../../../selectors/helpers/replace";

export const getOverview = (
	{
		forecastReorderAt = true,
		forecastEmptyAt = true,
		remain = true,
		usageRate = true,
		monthToDate = true,
		remainNm3 = true,
		usageRateNm3 = true,
		monthToDateNm3 = true,
		remainSm3 = true,
		usageRateSm3 = true,
		monthToDateSm3 = true
	},
	{
		forecastReorderAtField,
		forecastEmptyAtField,
		remainField,
		usageRateField,
		monthToDateField
	},
	input = {}
) => {
	const defaultFields = {
		forecastReorderAtField: forecastReorderAtField || "Forecasted Reorder",
		forecastEmptyAtField: forecastEmptyAtField || "Forecasted Empty",
		remainField: remainField || "Remaining",
		usageRateField: usageRateField || "Usage rate",
		monthToDateField: monthToDateField || "Month to date consumed"
	};

	const overview = [
		...(forecastReorderAt ? [{ title: `${defaultFields.forecastReorderAtField}`, value: dateTimeFormat(input.forecastReorderAt) }] : []),
		...(forecastEmptyAt ? [{ title: `${defaultFields.forecastEmptyAtField}`, value: dateTimeFormat(input.forecastEmptyAt) }] : []),
		...(remain ? [{ title: `${defaultFields.remainField} (kg)`, value: numberFormat(input.remain), type: "kg" }] : []),
		...(usageRate ? [{ title: `${defaultFields.usageRateField} (kg/hr)`, value: numberFormat(input.usageRate), type: "kg" }] : []),
		...(monthToDate ? [{ title: `${defaultFields.monthToDateField} (kg)`, value: numberFormat(input.monthToDateKg), type: "kg" }] : []),
		...(remainNm3 ? [{ title: `${defaultFields.remainField} (Nm<sup>3</sup>)`, value: numberFormat(input.remainNm3), type: "nm3" }] : []),
		...(usageRateNm3 ? [{ title: `${defaultFields.usageRateField} (Nm<sup>3</sup>/hr)`, value: numberFormat(input.usageRateNm3), type: "nm3" }] : []),
		...(monthToDateNm3 ? [{ title: `${defaultFields.monthToDateField} (Nm<sup>3</sup>)`, value: numberFormat(input.monthToDateNm3), type: "nm3" }] : []),
		...(remainSm3 ? [{ title: `${defaultFields.remainField} (Sm<sup>3</sup>)`, value: numberFormat(input.remainSm3), type: "sm3" }] : []),
		...(usageRateSm3 ? [{ title: `${defaultFields.usageRateField} (Sm<sup>3</sup>/hr)`, value: numberFormat(input.usageRateSm3), type: "sm3" }] : []),
		...(monthToDateSm3 ? [{ title: `${defaultFields.monthToDateField} (Sm<sup>3</sup>)`, value: numberFormat(input.monthToDateSm3), type: "sm3" }] : []),
		...(remain ? [{ title: `${defaultFields.remainField} (Liter)`, value: numberFormat(input.remain), type: "l" }] : []),
		...(usageRate ? [{ title: `${defaultFields.usageRateField} (Liter/hr)`, value: numberFormat(input.usageRate), type: "l" }] : []),
		...(monthToDate ? [{ title: `${defaultFields.monthToDateField} (Liter)`, value: numberFormat(input.monthToDateKg), type: "l" }] : [])
	];

	// Tank Pressure
	if (input.tankPressure) {
		overview.push({
			title: "Tank pressure (barG)",
			value: numberFormat(input.tankPressure.value),
			barG: {
				value: input.tankPressure.percent,
				slots: [
					{ value: input.tankPressure.normalPercent, color: "green" },
					{ value: input.tankPressure.warningPercent, color: "orange" },
					{ value: input.tankPressure.dangerPercent, color: "red" }
				]
			}
		});
	}

	// Line Pressure
	if (input.linePressure) {
		overview.push({
			title: "Line pressure (barG)",
			value: numberFormat(input.linePressure.value),
			barG: {
				value: input.linePressure.percent,
				slots: [
					{ value: input.linePressure.dangerPercent, color: "red" },
					{ value: input.linePressure.warningPercent, color: "orange" },
					{ value: input.linePressure.normalPercent, color: "green" }
				]
			}
		});
	}

	return overview;
};

/**
 * Parse Tank Info
 * @param {Object} input tank info data
 * @param {Object} defaultTemplate
 * @returns {TankInfo}
 */
export function parseTankInfo(input, defaultTemplate = {
	layout: {
		overview: {
			forecastReorderAt: true,
			forecastEmptyAt: true,
			remain: true,
			usageRate: true,
			monthToDate: true
		},
		overviewField: {
			forecastReorderAtField: "Forecasted Reorder",
			forecastEmptyAtField: "Forecasted Empty",
			remainField: "Remaining",
			usageRateField: "Usage rate",
			monthToDateField: "Month to date consumed",
			forecastReorderAtFieldUnit: "",
			forecastEmptyAtFieldUnit: "",
			remainFieldUnit: "kg",
			usageRateFieldUnit: "kg/hr",
			monthToDateFieldUnit: "kg"
		},
		shipToLocation: true,
		level: true,
		delivery: true
	}
}) {
	if (!input) {
		return {};
	}

	const overViewTemplate = input.layout?.overview || defaultTemplate.layout.overview;
	const overViewFieldTemplate = input.layout?.overviewField || defaultTemplate.layout.overviewField;
	const layoutTemplate = input.layout || defaultTemplate.layout;

	const overview = getOverview(overViewTemplate, overViewFieldTemplate, input);

	const customerView = [
		{ icon: `BuildingIcon`, title: `Name`, value: input.customerInfo?.name || "-" },
		{ icon: `IndustryWindowsIcon`, title: `Plant`, value: input.customerInfo?.plant || "-" },
		{ icon: `LocationDotIcon`, title: `Address`, value: input.customerInfo?.address || "-" },
		{ icon: `UserHeadsetIcon`, title: `Account owner`, value: input.customerInfo?.accountOwner || "-" },
		{ icon: `PhoneIcon`, title: "Telephone", value: input.customerInfo?.telephone || "-" },
		{ icon: `AtIcon`, title: `Email`, value: input.customerInfo?.email || "-" }
	];

	return {
		id: input.id,
		name: replaceDeviceName(input.name),
		currentLevel: input.currentLevel,
		shipId: input.shipId,
		shipToId: input.shipToId,
		shipToLatitude: input.shipToLatitude,
		shipToLongitude: input.shipToLongitude,
		deliveryStatus: input.deliveryStatus,
		tankPercent: input.tankPercent,
		tankStatus: input.levelStatus,
		type: input.type,
		updatedAt: dateTimeFormat(input.updatedAt),
		deviceCustomerName: input.deviceCustomerName,
		overview,
		layout: {
			...layoutTemplate
		},
		unit: input.unit,
		customerView,
		reportId: input.reportId,
		workspaceId: input.workspaceId
	};
}