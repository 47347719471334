/**
 * The hack for 100vh height for mobile
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
import debounce from "lodash.debounce";

export const hack100vhForMobile = () => {
	// Set --app-min-vh min-height
	const setMinVH = () => {
		document.documentElement.style.setProperty("--app-min-vh", `${window.innerHeight}px`);
	};

	// Add event on resize
	window.addEventListener("resize", debounce(setMinVH, 300));

	// First time execute
	setMinVH();
};
