<template>
	<modal :name="modalName" :scrollable="true" width="464" height="auto" @before-close="beforeCloseModal">
		<BaseModalContent theme="light">
			<template v-slot:header>
				Confirm approval
				<span class="close-button" @click="closeModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div v-if="!isHidePassword" class="item">
						<div class="label">
							Password
							<BaseTooltip
								message="Re-type your login password"
								width="181px"
								class="password-tooltip"
							>
								<font-awesome-icon :icon="['far', 'info-circle']" class="icon-info" />

							</Basetooltip>
						</div>
						<div class="form">
							<BaseFormInputPassword
								v-model="password"
								:valid.sync="isValidCurrentPassword"
								:showIconLeft="false"
								:customErrorMessage="errorMessage.password"
								formatType="simple"
								placeholder="Confirm your password"
								class="custom-input"
							/>
						</div>
					</div>
					<div class="item">
						<div class="label">
							Comment (optional)
						</div>
						<div class="form">
							<BaseFormInput
								v-model="comment"
								:showIconLeft="false"
								formatType="simple"
								placeholder="Input your comment here"
								class="custom-input"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					:disabled="isDisabledConfirmButton"
					class='confirm-approval'
					@click="handleConfirm"
				>
					Confirm approval
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";
import BaseFormInputPassword from "@/components/BaseFormInputPassword.vue";
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTooltip from "@/components/BaseTooltip.vue";


export default {
	name: "ApproveModal",
	components: {
		BaseModalContent,
		BaseFormInputPassword,
		BaseFormInput,
		BaseButton,
		BaseTooltip
	},
	data() {
		return {
			password: "",
			comment: "",
			isValidCurrentPassword: true
		};
	},
	props: {
		modalName: {
			type: String,
			default: () => ("")
		},
		errorMessage: {
			type: Object,
			default: () => ({
				password: ""
			})
		},
		isHidePassword: {
			type: Boolean,
			default: () => false
		},
		passwordErrorMessage: {
			type: String,
			default: () => ("")
		}
	},
	computed: {
		isDisabledConfirmButton() {
			return this.password.length < 8 && !this.isHidePassword;
		}
	},
	watch: {
		password() {
			this.$emit("clearError");
		}
	},
	methods: {
		beforeCloseModal() {
			this.password = "";
			this.comment = "";
		},

		closeModal() {
			this.$modal.hide(this.modalName);
			this.password = "";
			this.comment = "";
		},

		handleConfirm() {
			this.$emit("onConfirm", {
				password: this.password,
				comment: this.comment
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	.password-tooltip {
		width: rem(12);
		height: rem(12);
		margin-left: rem(8);
		margin-top: rem(-2);
		font-size: rem(12);
		color: $color-gunmetal-two;
		cursor: pointer;

		&:hover {
			color: $color-dark-blue-grey;
		}

			/* stylelint-disable */
			&::v-deep {
				.container-tooltip-popover {
					right: rem(-8);
					bottom: rem(28);
					word-break: break-word;
				}
			}
			/* stylelint-enable */
	}

	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			flex-grow: 1;
			align-items: center;
			color: $color-dark-blue-grey;
		}

		.form {
			width: rem(280);
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}

	.confirm-approval {
		width: rem(148);
		height: rem(40);
	}

	/* stylelint-disable */
	&::v-deep {
		.container-notify {
			max-width: 100%;
		}

		.container-body {
			padding: rem(8) 0;
			border-color: $color-red;
		}
	}
	/* stylelint-enable */
}
</style>