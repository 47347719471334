<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: isActive.keyword }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				v-model="form.keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Ship-to name, Ship-to code, Location key"
				@input="handleKeywordChanged"
				@focus="setFocus('keyword', true)"
				@blur="setFocus('keyword', false)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline>
			Search
		</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">
				Clear all.
			</a>
		</div>
	</form>
</template>

<script>
export default {
	props: {
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			form: {
				keyword: this.query.keyword
			},
			isActive: {
				keyword: false
			}
		};
	},

	methods: {
		handleKeywordChanged(value = null) {
			this.form.keyword = value || null;
		},
		handleSubmit() {
			this.$emit("submit", this.form);
		},
		clearFilter() {
			this.form.keyword = null;
			this.form.role = null;
			this.$emit("submit", this.form);
		},
		setFocus(type, value) {
			this.isActive[type] = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-keyword {
	width: rem(630);
}

.filter-form {
	margin-left: 0;
	margin-right: 0;
}
</style>
