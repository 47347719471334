import soldToManagementList from "../../views/SoldToManagement/List.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

const { SOLDTO_MANAGEMENT } = PAGE_NAME;

export default {
	path: "/soldto-management/list",
	name: SOLDTO_MANAGEMENT,
	component: soldToManagementList,
	meta: {
		requireAuth: true
	}
};