<template>
	<BaseIndexLayout>
		<router-view></router-view>
	</BaseIndexLayout>
</template>

<script>
import BaseIndexLayout from "@/components/BaseIndexLayout.vue";

export default {
	name: "Login",

	components: {
		BaseIndexLayout
	}
};
</script>