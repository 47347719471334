<template>
	<div class="permission-container">
		<div class="permission-content">
			<div :class="['permission-form',{'access-denied': requireAuthPermission}]">
				<div class="permission-action">
					<a
						:href="logOutAzureUrl"
						v-if="isAzureSignIn"
						class="arrow-action"
					>
						<FontAwesomeIcon :icon="['far', 'chevron-circle-left']" />
					</a>
					<div class="route-action">
						<a
							:href="logOutAzureUrl"
							v-if="isAzureSignIn"
						>
							Sign out Azure
						</a>
						<div
							v-else
							class="logout-user"
							@click="getLogout"
						>
							Sign out
						</div>
					</div>
				</div>
				<div class="permission-describe">
					<div :class="['exclamation',{'access-denied': requireAuthPermission}]">
						<FontAwesomeIcon :icon="['fas', 'exclamation-circle']" />
					</div>
					<div class="describe">
						<div class="azure-describe" v-if="isAzureSignIn">
							<h1 class="title">Permission Required!</h1>
							<p class="body">
								You’ve signed in as <strong>{{ getEmail }}</strong> which doesn’t have permission to access BIG Portal. You can choose a different account or sign in again once you have access.
							</p>
						</div>
						<div class="permission-describe access-denied" v-if="requireAuthPermission">
							<h1 class="title">Access denied</h1>
							<p class="body">
								Sorry, you don’t have permission to access this page.
							</p>
						</div>
						<div :class="['permission-action', 'route-action', {'access-denied': requireAuthPermission}]">
							<router-link
								:to="routeToHomePage"
								v-if="requireAuthPermission"
							>
								Go to BIG Portal homepage
							</router-link>
							<router-link
								:to="{ name: 'dashboard'}"
								v-if="requireAuthPermission"
								class="arrow-action"
							>
								<FontAwesomeIcon :icon="['far', 'chevron-circle-right']" />
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getRedirectRouteName } from "../selectors/helpers/permission";

export default {
	data() {
		return {
			requireAuthPermission: this.$route.query.requireAuth || false,
			isAzureSignIn: this.$route.query.azureSignIn || false,
			email: this.$route.query.email || false
		};
	},

	computed: {
		...mapGetters({
			getProfile: "user/getProfile"
		}),
		getEmail() {
			return this.email || this.getProfile.email;
		},
		logOutAzureUrl() {
			return `${process.env.VUE_APP_AZURE_AUTHORIZE_ENDPOINT}/logout?post_logout_redirect_uri=${process.env.VUE_APP_AZURE_LOGIN}`;
		},
		routeToHomePage() {
			const routeName = getRedirectRouteName(this.$store.state.user.rolePermissions);
			return {
				name: routeName
			};
		}
	},

	methods: {
		...mapActions({
			handleLogout: "user/getLogout"
		}),

		async getLogout() {
			await this.handleLogout();

			window.location.href = "/login";
		}
	}
};
</script>

<style lang="scss" scoped>
.permission-container {
	background: url("/assets/images/bg.png") no-repeat center center;
	background-size: cover;
	display: flex;
	height: 100vh;
	min-height: rem(500);
	justify-content: center;

	.permission-content {
		display: flex;
		align-items: center;
	}

	.permission-form {
		background-color: $color-white;
		padding: rem(33) rem(40) rem(40);
		width: rem(470);
		margin: 0 auto;
		border-top: rem(8) solid $color-marigold;

		&.access-denied {
			border-color: $color-dull-orange;
		}
	}

	.permission-action {
		color: $color-cerulean;
		display: flex;
		align-items: center;

		&.access-denied {
			justify-content: center;
			margin-top: rem(30);

			.arrow-action {
				margin-left: rem(8);
			}
		}

		.arrow-action {
			display: flex;
			align-items: center;
		}

		a {
			&:visited {
				color: $color-cerulean;
			}
		}
	}

	.route-action {
		font-family: $font-family-base;
		padding-left: rem(8);
		font-size: $font-18;
		font-weight: $font-weight-bold;
	}

	.permission-describe {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: rem(24);

		&.access-denied {
			padding: 0;
		}

		.describe {
			.title {
				font-family: $font-family-sub;
				color: $color-dark-blue-grey;
				text-align: center;
				letter-spacing: rem(0.5);
			}

			.body {
				text-align: center;
				font-size: $font-20;
			}

			.re-signin {
				text-align: center;
				margin: rem(20) 0;
			}

			.contact {
				display: flex;
				flex-direction: column;
				background-color: $color-ice-two;
				padding: rem(24);
				width: rem(390);
				height: rem(136);

				.contact-fyi {
					font-family: $font-family-base;
					font-size: $font-18;
					margin: 0 0 rem(8);
				}

				.email,
				.phone {
					display: flex;
					margin: rem(4) 0;
				}
			}
		}
	}

	.icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(24);
		height: rem(24);
		border-radius: rem(50);
		background-color: $color-emerald;
		margin-right: rem(8);

		img {
			width: rem(14);
			height: rem(15);
			margin-top: rem(-2);
		}
	}

	.link-to-email {
		font-family: $font-family-base;
		font-size: $font-18;
		font-weight: $font-weight-bold;
		color: $color-cerulean;
	}

	.exclamation {
		width: rem(48);
		height: rem(48);
		color: $color-marigold;
		font-size: $font-48;

		&.access-denied {
			color: $color-dull-orange;
		}
	}
}

.logout-user {
	cursor: pointer;
}
</style>
