import httpClient from "./httpClient";

const DEVICES_END_POINT = "/devices";
const END_POINT_SYNC = {
	Tank: "/tanks",
	Meter: "/meters",
	ZLD: "/zlds",
	ZLD2: "/zlds2",
	Application: "/",
	CER: "/cers",
	NitroFAS: "/nitrofas",
	O2Aeration: "/o2-aerations",
	GenGas: "/genGas",
	Energy: "/",
	PWR: "/pwrs",
	SOR: "/solarCells",
	LNG: "/lngs",
	BHY: "/bulk-hydrogens",
	Process: "/",
	Furnace: "/furnaces",
	Utility: "/",
	AIRCOMP: "/airComp"
};

const getDevicesListsAPI = (params) => httpClient.get(DEVICES_END_POINT, { params });
const getDeviceNoSignalAPI = (params) => httpClient.get(`${DEVICES_END_POINT}/no-signals`, { params });
const getDeviceInactiveAPI = (params) => httpClient.get(`${DEVICES_END_POINT}/inactives`, { params });
const updateDevicePinAPI = (id) => httpClient.put(`${DEVICES_END_POINT}/${id}/pin`);
const updateDeviceUnpinAPI = (id) => httpClient.put(`${DEVICES_END_POINT}/${id}/unpin`);
const getDeviceSyncAPI = (id, nameEndPoint) => httpClient.get(`${END_POINT_SYNC[nameEndPoint]}/${id}/refresh`);

export {
	getDevicesListsAPI,
	getDeviceNoSignalAPI,
	getDeviceInactiveAPI,
	updateDevicePinAPI,
	updateDeviceUnpinAPI,
	getDeviceSyncAPI
};
