<template>
	<div class="page-container">
		<div class="page-content">
			<div class="page-form">
				<div class="page-describe">
					<div class="exclamation">
						<div class="icon-status-code">
							<slot v-if="$slots.code" name="code"></slot>
							<span v-else>
								{{ code }}
							</span>
						</div>
					</div>
					<div class="describe">
						<div v-if="$slots.title">
							<slot name="title"></slot>
						</div>
						<h1 v-else class="title">{{ title }}</h1>

						<div v-if="$slots.body">
							<slot name="body"></slot>
						</div>
						<p v-else class="body">{{ message }}</p>

						<p v-if="$slots.footer || footer" class="footer">
							<slot v-if="$slots.footer" name="footer"></slot>
							<span v-else>{{ footer }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseError",

	props: {
		code: {
			type: String,
			default: ""
		},

		title: {
			type: String,
			default: ""
		},

		message: {
			type: String,
			default: ""
		},

		footer: {
			type: String,
			default: ""
		}
	}
};
</script>

<style lang="scss" scoped>
.page-container {
	background: url("/assets/images/bg.png") no-repeat center center;
	background-size: cover;
	display: flex;
	height: 100vh;
	min-height: rem(500);
	justify-content: center;

	.page-content {
		display: flex;
		align-items: center;

		.page-form {
			position: relative;
			background-color: $color-white;
			padding: rem(40) rem(36) rem(26);
			width: rem(470);
			margin: 0 auto;
			border-radius: rem(4);
			border: solid 1px $color-silver;
			overflow: hidden;

			&::before {
				content: " ";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: rem(8);
				background-color: $color-dull-orange;
			}
		}

		.page-describe {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-top: rem(6);

			.exclamation {
				.icon-status-code {
					display: flex;
					justify-content: center;
					align-items: center;
					width: rem(60);
					height: rem(60);
					background: $color-dull-orange;
					border-radius: 100%;
					font-size: $font-32;
					font-weight: $font-weight-bold;
					color: $color-white;

					span {
						position: relative;
						top: rem(2);
					}
				}
			}

			.describe {
				margin-top: $spacing-5;

				.title {
					font-family: $font-family-sub;
					color: $color-dark-blue-grey;
					text-align: center;
					letter-spacing: rem(0.5);
				}

				.body {
					text-align: center;
					font-size: $font-20;
				}

				.footer {
					display: flex;
					justify-content: center;
					margin-top: rem(34);
					font-size: $font-18;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}
</style>
