import { required, email, maxLength, minLength } from "vuelidate/lib/validators";
import { getvalidateMessage, checkIfValid } from "../validateHelper";
import { getPasswordValidate } from "../../../selectors/helpers";
import { CUSTOMER_ROLES } from "../../../enums/customerManagement/customerRole";

export default {
	validations: {
		name: {
			required,
			maxLength: maxLength(70)
		},

		email: {
			required,
			format(value) {
				return email(value);
			},
			isExist() {
				return !this.customerManagement.errors.email;
			}
		},

		phone: {
			maxLength: maxLength(70)
		},

		systemRole: {
			required
		},

		packageName: {
			required
		},

		company: {
			required,
			maxLength: maxLength(255)
		},

		password: {
			required,
			maxLength: maxLength(255),
			minLength: minLength(8),
			format(value) {
				return getPasswordValidate(value);
			}
		},
		soldToValues: {
			required(value) {
				if (this.systemRole === CUSTOMER_ROLES.CONTACT) {
					return true;
				}
				return value.length > 0;
			}
		},
		shipToValues: {
			required(value) {
				if (this.systemRole === CUSTOMER_ROLES.CONTACT) {
					return true;
				}
				return value.length > 0;
			}
		},
		applicationValues: {
			required(value) {
				if (this.systemRole === CUSTOMER_ROLES.CONTACT) {
					return true;
				}
				return value.length > 0;
			}
		},
		deviceValues: {
			required(value) {
				if (this.systemRole === CUSTOMER_ROLES.CONTACT) {
					return true;
				}
				return value.length > 0;
			}
		}
	},
	methods: {
		checkIfValid(name) {
			const field = this.$v[name];
			if (!field.$dirty) {
				return null;
			}

			if (!("required" in field) && !field.$error) {
				return null;
			}

			return (field.$invalid || field.$error);
		},
		handleSubmit(callback) {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				callback();
			}
		}
	},
	computed: {
		isSoldToError() {
			const field = this.$v.soldToValues;
			return checkIfValid(field);
		},

		getSoldToErrorMessage() {
			const field = this.$v.soldToValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isShipToError() {
			const field = this.$v.shipToValues;
			return checkIfValid(field);
		},

		getShipToErrorMessage() {
			const field = this.$v.shipToValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isApplicationError() {
			const field = this.$v.applicationValues;
			return checkIfValid(field);
		},

		getApplicationErrorMessage() {
			const field = this.$v.applicationValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isDeviceError() {
			const field = this.$v.deviceValues;
			return checkIfValid(field);
		},

		getDeviceErrorMessage() {
			const field = this.$v.deviceValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isNameError() {
			const field = this.$v.name;
			return checkIfValid(field);
		},

		getNameErrorMessage() {
			const field = this.$v.name;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length: 1 - 70 Characters"
			});
		},

		isEmailError() {
			const field = this.$v.email;
			return checkIfValid(field);
		},

		getEmailErrorMessage() {
			const field = this.$v.email;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Invalid email format",
				existMessage: "Email already exist"
			});
		},

		isPhoneError() {
			const field = this.$v.phone;
			return checkIfValid(field);
		},

		getPhoneErrorMessage() {
			const field = this.$v.phone;
			return getvalidateMessage(field, {
				lengthLimitMessage: "Field length: 1 - 70 Characters"
			});
		},

		isSystemRoleError() {
			const field = this.$v.systemRole;
			return checkIfValid(field);
		},

		getSystemRoleErrorMessage() {
			const field = this.$v.systemRole;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isPackageNameError() {
			const field = this.$v.packageName;
			return checkIfValid(field);
		},

		getPackageNameErrorMessage() {
			const field = this.$v.packageName;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isPasswordError() {
			const field = this.$v.password;
			return checkIfValid(field);
		},

		getPasswordErrorMessage() {
			const field = this.$v.password;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length at least 8 characters",
				invalidFormatMessage: "Password should contain at least one uppercase, one lowercase and one number, with optional symbols as ~!@#$%^&*()-+=?<>|[]{}_ :;.,`"
			});
		},

		isCompanyError() {
			const field = this.$v.company;
			return checkIfValid(field);
		},

		getCompanyErrorMessage() {
			const field = this.$v.company;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				lengthLimitMessage: "Field length: 1 - 255 Characters"
			});
		}
	}
};