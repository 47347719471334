<template>
	<BaseLayout pageTitle="Notification group">
		<BaseLoading
			v-if="loading"
			class="wrapper-loading"
		/>
		<div v-if="!loading">
			<div class="container-action-bar">
				<BaseButton
					size="large"
					@click="openModal"
				>
					Create new group
				</BaseButton>
			</div>
			<SearchFilter
				v-if="!isNoLayout"
				:keywordValue="keyword"
				:moduleValue="channel"
				:moduleLists="configOptions"
				@handleSelectModule="handleSelectModule"
				@handleKeywordChange="handleKeyWordChange"
				@handleSubmit="handleSubmitForm"
				@handleClear="handleClear"
			/>
			<div
				v-if="isShowList"
				class="wrapper-list-customisation"
			>
				<template v-for="item in notificationLists">
					<div  :key="item.id" class="customisation-item">
						<h2 class="customisation-item-layout-name">
							{{ item.name }}
						</h2>
						<div class="customisation-item-module">
							Channel <span class="module-type">{{ item.channel | capitalize }}</span>
						</div>
						<div class="customisation-item-edit-section btn-link">
							<BaseButton
								class="edit-btn"
								type="secondary"
								link
								@click="onClickEdit(item.channel, item.id)"
							>
								<font-awesome-icon
									:icon="['far', 'edit']"
									class="edit-icon"
								>
								</font-awesome-icon>
								Edit
							</BaseButton>
						</div>
					</div>
				</template>
			</div>

			<BaseEmpty
				v-if="isEmptyWithSearch && !isNoLayout"
				class="wrapper-empty"
				icon="search"
				wrapperColor="ice-two"
				iconColor="emerald"
				title="No matching search results"
				subTitle="Try adjusting your search by changing your group name or removing filters"
			/>

			<BaseEmpty
				v-if="isNoLayout"
				class="wrapper-empty"
				icon="file-times"
				wrapperColor="ice-two"
				iconColor="emerald"
				title="Please create group"
				subTitle="Notification group will be displayed once you created group"
			/>

			<BasePagination
				v-if="pagination.lastPage > 1"
				:value="pagination.currentPage"
				:totalPage="pagination.lastPage"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>

			<ModalSelectChannel
				:modal-name="MODAL_NAME_SELECT_CHANNEL"
				:configs="configs"
				@onSubmit="handleSelectChannel"
			/>
		</div>
	</BaseLayout>

</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BaseButton from "@/components/BaseButton.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseEmpty from "@/components/BaseEmpty.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import SearchFilter from "@/components/NotificationGroup/SearchFilter.vue";
import ModalSelectChannel from "@/components/NotificationGroup/ModalSelectChannel.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { getNotificationGroupLists } from "../../services/api/notificationGroup.api";

const {
	NOTIFICATION_GROUP_CREATE,
	NOTIFICATION_GROUP_EDIT,
	NOTIFICATION_GROUP_LIST
} = PAGE_NAME;

export default {
	components: {
		BaseButton,
		BaseEmpty,
		BaseLayout,
		SearchFilter,
		BaseLoading,
		ModalSelectChannel
	},
	data() {
		return {
			MODAL_NAME_SELECT_CHANNEL: `modal-select-channel`,
			loading: true,
			isNoLayout: false,
			notificationLists: [],
			pagination: {
				currentPage: Number(this.$route.query.page) || null,
				lastPage: 1,
				perPage: 12,
				total: 1
			},
			searchFilter: true,
			channel: this.$route.query.channel || "",
			keyword: this.$route.query.keyword || "",
			NOTIFICATION_GROUP_EDIT,
			createModuleLists: [
				{
					label: "Gen Gas", value: "GEN_GAS"
				},
				{
					label: "Application - ZLD (Vinythai)", value: "ZLD"
				}
			]
		};
	},

	async created() {
		this.loading = true;
		const computeQuery = {
			...(this.keyword ? { keyword: this.keyword } : {}),
			...(this.channel ? { channel: this.channel } : {}),
			...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {})
		};

		const [{ data: response }] = await Promise.all([
			getNotificationGroupLists(computeQuery),
			this.getNotificationConfigs()
		]);
		this.notificationLists = response.data;

		this.pagination = {
			lastPage: response.pagination.lastPage,
			perPage: response.pagination.perPage,
			total: response.pagination.total,
			currentPage: Number(this.$route.query.page) || 1
		};

		if ((!this.keyword && !this.channel) && response.data.length === 0) {
			this.isNoLayout = true;
		}

		this.loading = false;
	},

	computed: {
		...mapState("notiGroupConfig", {
			configs: "data"
		}),
		...mapGetters({
			getProfile: "user/getProfile"
		}),
		isShowList() {
			return this.notificationLists.length > 0;
		},
		isEmptyWithSearch() {
			return this.notificationLists.length === 0 && this.searchFilter;
		},
		configOptions() {
			const items = this.configs
				.filter((item) => (
					item.isActive
				))
				.map((item) => ({
					label: item.displayName,
					value: item.channel
				}));
			return [
				{
					value: "",
					label: "Channel: All"
				},
				...items
			];
		}
	},
	methods: {
		...mapActions({
			showToast: "toast/show"
		}),
		...mapActions("notiGroupConfig", {
			getNotificationConfigs: "getNotificationConfigs"
		}),
		handleClear() {
			this.keyword = "";
			this.channel = "";
			this.$router.push({
				name: NOTIFICATION_GROUP_LIST
			}).catch(() => {});
		},

		handleKeyWordChange(value) {
			this.keyword = value;
		},

		handleSelectModule(value) {
			this.channel = value;
		},

		handlePageChange(value) {
			this.$router.push({
				name: NOTIFICATION_GROUP_LIST,
				query: {
					...this.$route.query,
					page: value
				}
			});
		},

		onClickEdit(channel, id) {
			this.$router.push({
				name: NOTIFICATION_GROUP_EDIT,
				params: {
					id
				}
			});
		},

		handleSubmitForm() {
			this.$router.push({
				name: NOTIFICATION_GROUP_LIST,
				query: {
					...(this.keyword ? { keyword: this.keyword } : {}),
					...(this.channel ? { channel: this.channel } : {})
				}
			});
		},

		handleSelectChannel(form) {
			this.$router.push({
				name: NOTIFICATION_GROUP_CREATE,
				query: {
					channel: form.channel
				}
			});
		},

		openModal() {
			this.$modal.show(this.MODAL_NAME_SELECT_CHANNEL);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.wrapper-empty {
	margin-top: rem(102);
}

.wrapper-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: rem(500);
}

.wrapper-list-customisation {
	display: flex;
	flex-direction: column;
	margin-top: rem(20);
	margin-bottom: rem(40);
}

.customisation-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: rem(56);
	width: 100%;
	padding: rem(16);
	background-color: $color-alabaster;
	border: rem(1) solid $color-silver;
	border-radius: rem(4);
	margin: rem(4) 0;

	&-module {
		min-width: rem(120);
		color: $color-grey-4;
	}

	&-layout-name {
		@include ellipsisOneline;

		width: rem(368);
		font-weight: $font-weight-regular;
		color: $color-dark-blue-grey;
	}

	&-edit-section {
		display: flex;
		align-items: center;
	}

	.edit-btn {
		@include fontBase;

		color: $color-cerulean;
		font-size: rem(16);
		margin-right: rem(-17);
		font-weight: $font-weight-bold;

		&:visited {
			color: $color-cerulean;
		}
	}

	.edit-icon {
		font-size: rem(12);
	}

	.module-type {
		color: $color-dark-blue-grey;
		font-weight: $font-weight-bold;
		margin-left: rem(8);
	}
}
</style>