<template>
	<modal
		:name="modalName"
		:scrollable="true"
		width="450"
		height="auto"
		class="custom-modal"
		@before-open="beforeOpen"
	>
		<BaseModalContent rounded>
			<template v-slot:header>
				<span class="border-top"></span>
				<span class="title">
					<FontAwesomeIcon :icon="['fas', 'exclamation-circle']" class="icon" />
					{{ title }}
				</span>
				<span
					v-if="!isSummited"
					class="close-button"
					@click="$modal.hide(modalName)"
				>
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container" v-html="message"></div>
			</template>
			<template v-slot:footer>
				<button
					:disabled="isSummited"
					class="view-button"
					@click="onCancel"
				>
					{{ cancelButtonText }}
				</button>
				<BaseButton
					v-if="showConfirmButton"
					:disabled="isSummited"
					type="danger"
					size="medium"
					@click="onConfirm"
					rounded
				>
					{{ submitButtonText }}
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: null
		},
		title: {
			type: String,
			required: null
		},
		message: {
			type: String,
			required: null
		},
		isSummited: {
			type: Boolean,
			default: false
		},
		cancelButtonText: {
			type: String,
			default: "Cancel"
		},
		submitButtonText: {
			type: String,
			default: "Yes, delete"
		},
		showConfirmButton: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			params: null
		};
	},

	methods: {
		beforeOpen(event) {
			if (event.params !== undefined) {
				this.params = event.params;
			}
		},
		onCancel() {
			this.$emit("onCancel", this.params);
		},
		onConfirm() {
			this.$emit("onConfirm", this.params);
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-modal {
	/* stylelint-disable */
	&::v-deep .header {
		padding-top: rem(16);
	}
	&::v-deep .body {
		font-size: $font-20;
		color: $color-dark-blue-grey;
	}
	&::v-deep .v--modal-box {
		border-radius: $border-radius-normal;
		overflow: hidden;
	}
	/* stylelint-enable */
}

.view-button {
	margin-right: rem(44);
}

.border-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: rem(8);
	background: $color-red;
}

.title {
	display: flex;
	align-items: center;

	.icon {
		position: relative;
		top: rem(-2);
		width: rem(22);
		height: rem(22);
		margin-right: rem(10);
		color: $color-red;
	}
}
</style>