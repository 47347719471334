import { getTanksGasConsumptionRateAPI } from "@/services/api/tanks.api";
import {
	TANK_GAS_CONSUMPTION_RATE_REQUEST,
	TANK_GAS_CONSUMPTION_RATE_SUCCESS,
	TANK_GAS_CONSUMPTION_RATE_FAILURE
} from "../../types";
import { transformLineChartTankGasConsumptionData } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getTankGasConsumptionRateData: (state) => (unitValue) => {
			return transformLineChartTankGasConsumptionData(state, unitValue);
		}
	},

	mutations: {
		[TANK_GAS_CONSUMPTION_RATE_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[TANK_GAS_CONSUMPTION_RATE_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[TANK_GAS_CONSUMPTION_RATE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getTankGasConsumptionRate({ commit }, { id = "", query = {} }) {
			try {
				commit(TANK_GAS_CONSUMPTION_RATE_REQUEST);

				const { data = [] } = await getTanksGasConsumptionRateAPI(id, query);

				commit(TANK_GAS_CONSUMPTION_RATE_SUCCESS, data);
			} catch (error) {
				commit(TANK_GAS_CONSUMPTION_RATE_FAILURE);
			}
		}
	}
};
