export const setDashBoardQuery = (query = {}) => {
	return localStorage.setItem("dashboardQuery", JSON.stringify(query));
};

export const getDashBoardQuery = () => {
	return JSON.parse(localStorage.getItem("dashboardQuery"));
};

export const removeDashboardQuery = () => {
	return localStorage.removeItem("dashboardQuery");
};