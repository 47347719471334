import { postActivityLogsApi } from "@/services/api/activitylog.api";
import {
	ACTIVITYLOG_ADD_REQUEST,
	ACTIVITYLOG_ADD_SUCCESS,
	ACTIVITYLOG_ADD_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		isLoading: false
	},

	mutations: {
		[ACTIVITYLOG_ADD_REQUEST](state) {
			state.isLoading = true;
		},
		[ACTIVITYLOG_ADD_SUCCESS](state) {
			state.isLoading = false;
		},
		[ACTIVITYLOG_ADD_FAILURE](state) {
			state.isLoading = false;
		}
	},

	actions: {
		async addActivityLogMeView({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "Me.View",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogDashboardView({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "Dashboard.View",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogShipToView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogTripInfoView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View.TripInfo",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogHistoryView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View.History",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogReportView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View.Report",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogDailyConsumptionView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View.DailyConsumption",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogConsumptionApprovalView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View.ConsumptionApproval",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogLastMonthView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View.LastMonth",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogGrafanaView({ commit }, { shopToId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipTo.View.Grafana",
					httpStatus,
					param: shopToId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityOverall({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "SystemMonitoring.Overall",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogHealthMonitoring({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "SystemMonitoring.HealthMonitoring",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityUserMonitoring({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "SystemMonitoring.UserMonitoring",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogShipToStatus({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "ShipToVisibility.View",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogStandardModuleList({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "StandardModuleList.View",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogStandardModuleCreate({ commit }, title) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);
				await postActivityLogsApi({
					action: "StandardModule.create",
					httpStatus: 200,
					values: [title.toString()]
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogStandardModuleDelete({ commit }, title) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);
				await postActivityLogsApi({
					action: "StandardModule.delete",
					httpStatus: 200,
					values: [title.toString()]
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogSoldToManagement({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "SoldToManagement.View",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogExecutiveReport({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "Executive.PowerBi.View",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogMarketplace({ commit }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);
				await postActivityLogsApi({
					action: "Marketplace.View",
					httpStatus: 200
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},
		async addActivityLogMarketplaceModuleGroupDetail({ commit }, module) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);
				await postActivityLogsApi({
					action: "MarketplaceModuleGroupDetail.View",
					httpStatus: 200,
					values: [module.toString()]
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogMarketplaceModuleDetail({ commit }, title) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);
				await postActivityLogsApi({
					action: "MarketplaceModuleDetail.View",
					httpStatus: 200,
					values: [title.toString()]
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogPowerBI({ commit }, { deviceId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "PowerBI.View",
					httpStatus,
					param: deviceId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogGrafanaManagement({ commit }, { deviceId, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "GrafanaManagement.View",
					httpStatus,
					param: deviceId.toString()
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogGrafanaManagementAction({ commit }, { action, title, httpStatus = 200, deviceId, note }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action,
					httpStatus,
					values: [title.toString()],
					param: deviceId.toString(),
					note
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogSetUpPowerBI({ commit }, { httpStatus = 200, deviceId, note }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "PowerBI.SetUp",
					httpStatus,
					param: deviceId.toString(),
					note
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		},

		async addActivityLogGrafanaViewTab({ commit }, { deviceId, title, httpStatus = 200 }) {
			try {
				commit(ACTIVITYLOG_ADD_REQUEST);

				await postActivityLogsApi({
					action: "GrafanaManagement.View.Tab",
					httpStatus,
					param: deviceId.toString(),
					values: [title.toString()]
				});

				commit(ACTIVITYLOG_ADD_SUCCESS);
			} catch (error) {
				commit(ACTIVITYLOG_ADD_FAILURE);
			}
		}
	}
};
