import dayjs from "dayjs";
import { transformLineChart } from "../../lineChart";
import { M_UNIT } from "../../unit";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachDay,
	getForEachSixHours,
	getForEachHour,
	getForEachThirtyMinute,
	getForEachTwoHours,
	getForEachFiveDay
} from "../../lineChart/helper";
import { getConfigDataZoom } from "../../lineChart/helper/zoomConfig";

const { perDay, perThreeDay, perWeek } = MODULE_SORT_DATE_CHART;

const yAxisText = `Chilled water flowrate (${M_UNIT}/hr)`;

const tooltipTankLineChartFormat = (name, value) => {
	const format = `
	<div>
		<div class="bar-name meter">
			${name}
		</div>
		<div class="bar-value">
			${value === null ? "-" : value} M<sup>3</sup>/hr
		</div>
	</div>`;
	return format;
};

const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipTankLineChartFormat(date, pointValue.data);
};

const grid = {
	left: "60px",
	right: "80px",
	bottom: 76,
	containLabel: true
};

const optionLineChartXaxisPerDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachTwoHours
	},
	...getConfigDataZoom(xAxis),
	axisTick: {
		interval: getForEachThirtyMinute
	},
	tooltip: {
		formatter: tooltipFormatter
	},
	grid
});

const optionLineChartXaxisForThreeDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: getForEachFourHours
	},
	axisTick: {
		interval: getForEachHour
	},
	...getConfigDataZoom(xAxis),
	tooltip: {
		formatter: tooltipFormatter
	},
	grid
});

const optionLineChartXaxisPerWeek = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	axisTick: {
		interval: getForEachSixHours
	},
	...getConfigDataZoom(xAxis),
	tooltip: {
		formatter: tooltipFormatter
	},
	grid
});

const optionLineChartMoreThanTwentyDay = ({ xAxis = [] }) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachFiveDay
	},
	axisTick: {
		interval: (index, date) => {
			const hour = dayjs(date).get("hour");
			const minute = dayjs(date).get("minute");
			return hour === 0 && minute === 0;
		}
	},
	...getConfigDataZoom(xAxis),
	tooltip: {
		formatter: tooltipFormatter
	},
	grid
});

export const transformWaterFlowLineChartConfig = (sortBy = perDay, data = [], option = {}) => {
	const { diffDay } = option;
	const mappingOption = {
		[perDay]: optionLineChartXaxisPerDay(data),
		[perThreeDay]: optionLineChartXaxisForThreeDay(data),
		[perWeek]: optionLineChartXaxisPerWeek(data)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionLineChartMoreThanTwentyDay(data);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}

	const functionTransform = mappingOption[sortBy] || optionLineChartXaxisPerWeek(data);
	const transformFunc = transformLineChart(functionTransform);
	return transformFunc(data);
};
