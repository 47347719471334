import httpClient from "./httpClient";

const FURNACE_END_POINT = "/furnaces";

const getFurnaceInfoAPI = (id) => httpClient.get(`${FURNACE_END_POINT}/${id}`);
const getFurnaceTemperatureAPI = (id) => httpClient.get(`${FURNACE_END_POINT}/${id}/furnace-temp`);
const getFurnaceHistoriesAPI = (id, params) => httpClient.get(`${FURNACE_END_POINT}/${id}/order-histories`, { params });
const getFurnaceConsumptionAPI = (id, params) => httpClient.get(`${FURNACE_END_POINT}/${id}/natural-gas`, { params });
const getFurnacePressureAPI = (id, params) => httpClient.get(`${FURNACE_END_POINT}/${id}/pressure`, { params });
const getFurnaceNaturalGasAPI = (id, params) => httpClient.get(`${FURNACE_END_POINT}/${id}/air-flow-rate`, { params });
const getFurnaceReportsAPI = (id, params) => httpClient.get(`${FURNACE_END_POINT}/${id}/order-report`, { params });
const getSignedReportUrl = (id, params) => httpClient.get(`${FURNACE_END_POINT}/${id}/download-report`, { params });
const getFurnaceTemperatureChartListAPI = (id) => httpClient.get(`${FURNACE_END_POINT}/${id}/furnace-graph-temp`);
const getFurnacGlassLevelAPI = (id, params) => httpClient.get(`${FURNACE_END_POINT}/${id}/glass-level`, { params });

export {
	getFurnaceInfoAPI,
	getFurnaceHistoriesAPI,
	getFurnaceTemperatureAPI,
	getFurnaceConsumptionAPI,
	getFurnacePressureAPI,
	getFurnaceNaturalGasAPI,
	getFurnaceReportsAPI,
	getSignedReportUrl,
	getFurnaceTemperatureChartListAPI,
	getFurnacGlassLevelAPI
};
