
import dayjs from "dayjs";
import Numeral from "numeral";

export const getIsNumber = (value) => /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)$/.test(value);

export const dateTimeFormat = (dateTime, format = "D MMM YYYY, HH:mm") => {
	return dateTime
		? dayjs(dateTime).format(format)
		: "-";
};


export const numberFormat = (value, format = "0,0.00") => {
	const isNumber = getIsNumber(value);
	return isNumber
		? Numeral(value).format(format)
		: "-";
};
