import { CUSTOMER_GET_ROLE_PERMISSION_REQUEST, CUSTOMER_GET_ROLE_PERMISSION_SUCCESS, CUSTOMER_GET_ROLE_PERMISSION_FAILURE } from "../../../store/types";
import { getCustomerRolePermissions } from "../../../services/api/customerManageApi";
import { parseCustomerRolePermissions } from "../../../services/api/transforms/parseCustomer";

export default {
	namespaced: true,

	state: {
		data: parseCustomerRolePermissions(null),
		loading: false
	},
	mutations: {
		[CUSTOMER_GET_ROLE_PERMISSION_REQUEST](state) {
			state.loading = true;
		},
		[CUSTOMER_GET_ROLE_PERMISSION_SUCCESS](state, data) {
			state.data = parseCustomerRolePermissions(data);
			state.loading = false;
		},
		[CUSTOMER_GET_ROLE_PERMISSION_FAILURE](state) {
			state.loading = false;
		}
	},

	actions: {
		async getCustomerRolePermissions({ commit }) {
			try {
				commit(CUSTOMER_GET_ROLE_PERMISSION_REQUEST);
				const { data } = await getCustomerRolePermissions();
				commit(CUSTOMER_GET_ROLE_PERMISSION_SUCCESS, data);
			} catch (e) {
				commit(CUSTOMER_GET_ROLE_PERMISSION_FAILURE);
			}
		}
	}
};
