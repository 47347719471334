import httpClient from "./httpClient";

const METERS_END_POINT = "/meters";
const getMetersInfoAPI = (id) => httpClient.get(`${METERS_END_POINT}/${id}`);
const getDailyConsumptionAPI = (id, params) => httpClient.get(`${METERS_END_POINT}/${id}/daily-consumptions`, { params });
const getMeterMonthlyConsumptionAPI = (id, filterBy = "1d") => httpClient.get(`${METERS_END_POINT}/${id}/consumptions?filter=${filterBy}`);
const getMeterGasFlowRate = (id, params) => httpClient.get(`${METERS_END_POINT}/${id}/flow-rates`, { params });
const getMeterOrderHistory = (id, page = 1) => httpClient.get(`${METERS_END_POINT}/${id}/order-histories?page=${page}`);
const getConsumptionApprovalAPI = (id) => httpClient.get(`${METERS_END_POINT}/${id}/consumption-approvals`);
const putUpdateConsumptionApprovalApproveAPI = (id, payload) => httpClient.put(`${METERS_END_POINT}/${id}/consumption-approvals/approve`, payload);
const putUpdateConsumptionApprovalVerifyAPI = (id, payload) => httpClient.put(`${METERS_END_POINT}/${id}/consumption-approvals/verify`, payload);
export {
	getMetersInfoAPI,
	getMeterMonthlyConsumptionAPI,
	getMeterGasFlowRate,
	getMeterOrderHistory,
	getConsumptionApprovalAPI,
	putUpdateConsumptionApprovalApproveAPI,
	putUpdateConsumptionApprovalVerifyAPI,
	getDailyConsumptionAPI
};
