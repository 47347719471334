<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<ModuleHeaderExample :data="zldInfo.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_ZLD_INFO }"
						active-class="active"
						class="nav-link"
					>
						Equipment info
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_ZLD_LAST_MONTH }"
						active-class="active"
						class="nav-link"
					>
						Last month
					</router-link>
				</li>
				<li v-if="permissions.powerBI.read" class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.EX_MODULE_ZLD_POWERBI }"
						active-class="active"
						class="nav-link"
					>
						Analysis
					</router-link>
				</li>
			</ul>
			<router-view
				@loaded="handleContentLoaded"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import ModuleHeaderExample from "@/components/dashboard/modules/ModuleHeaderExample.vue";
import { USER_TYPE } from "../../enums/user";
import { PAGE_NAME } from "../../enums/pagePermission";
import { CONFIG } from "../../enums/config";
import userPermission from "../../mixins/userPermission";

export default {
	name: "ZldExampleModule",
	mixins: [userPermission],
	components: {
		BaseLoading,
		ModuleHeaderExample
	},

	data() {
		return {
			PAGE_NAME,
			id: this.$route.params.id,
			isLoading: true,
			fetchDevices: () => {}
		};
	},

	computed: {
		...mapState(["zldInfo", "shipTos", "pagePermission"]),
		...mapGetters("user", {
			getProfile: "getProfile"
		}),

		breadcrumb() {
			return {
				type: {
					name: "Application (ZLD)"
				},
				shiped: {
					name: this.shipTos.data.name
				}
			};
		},

		shipToId() {
			return this.zldInfo.data.shipToId || null;
		},

		showGrafana() {
			return this.getProfile.type === USER_TYPE.STAFF;
		}
	},

	async created() {
		await this.getZldInfo(this.id);
		this.autoRefreshData = setInterval(async () => {
			if (!this.zldInfo.isAutoFecthLoading) {
				await this.autoGetZldInfo(this.id);
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		// save log for tab info only
		if (this.$route.name === PAGE_NAME.ZLDINFO) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: this.zldInfo.statusCode
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);

				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("zldInfo", {
			getZldInfo: "getZldInfo",
			autoGetZldInfo: "autoGetZldInfo"
		}),
		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		handleContentLoaded() {
			this.fetchDevices();
		}
	}
};
</script>
