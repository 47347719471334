
import { getFurnaceReportsAPI } from "@/services/api/furnaces.api";
import { parseFurnaceReports } from "../../../services/api/transforms/parseModules";
import { FURNACE_REPORT_REQUEST, FURNACE_REPORT_SUCCESS, FURNACE_REPORT_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: parseFurnaceReports([]),
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[FURNACE_REPORT_REQUEST](state) {
			state.data = parseFurnaceReports([]);
			state.isLoading = true;
			state.statusCode = null;
			state.isEmpty = false;
		},
		[FURNACE_REPORT_SUCCESS](state, { data, status }) {
			const response = data;
			state.data = parseFurnaceReports(response.data);
			state.isEmpty = response.data.length === 0;
			state.isLoading = false;
			state.statusCode = status;
			state.pagination = response.pagination;
		},
		[FURNACE_REPORT_FAILURE](state, error) {
			state.data = parseFurnaceReports([]);
			state.isLoading = false;
			state.statusCode = error.response.status;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchFurnaceReports({ commit }, { id, params }) {
			try {
				commit(FURNACE_REPORT_REQUEST);
				const response = await getFurnaceReportsAPI(id, params);
				commit(FURNACE_REPORT_SUCCESS, response);
			} catch (error) {
				commit(FURNACE_REPORT_FAILURE, error);
			}
		}
	}
};
