import { getTripInfoAPI } from "../../../services/api/tanks.api";
import {
	TRIP_INFO_REQUEST,
	TRIP_INFO_SUCCESS,
	TRIP_INFO_FAILURE,
	TRIP_INFO_UPDATE_TRUCK_SUCCESS,
	TRIP_INFO_UPDATE_TRUCK_FAILURE
} from "../../types";
import { transformTripInformation } from "../../../selectors/transform/tripInformation";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		statusCode: null
	},

	getters: {
		getTripInfoDrivers(state) {
			return state.data.info && state.data.info.drivers ? state.data.info.drivers : [];
		},

		getTripInfoType(state) {
			return state.data.type;
		},

		getTruckLocation(state) {
			if (state.data.truckGps) {
				return {
					lat: state.data.truckGps.latitude,
					lng: state.data.truckGps.longitude
				};
			}
			return null;
		},

		getTripInfoInfomation(state) {
			return transformTripInformation(state.data.info);
		}
	},

	mutations: {
		[TRIP_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[TRIP_INFO_SUCCESS](state, { data, status }) {
			state.data = data;
			state.isLoading = false;
			state.statusCode = status;
		},
		[TRIP_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[TRIP_INFO_UPDATE_TRUCK_SUCCESS](state, { data }) {
			state.data.truckGps = data.truckGps;
		}
	},

	actions: {
		async getTripInfo({ commit }, { id }) {
			try {
				commit(TRIP_INFO_REQUEST);
				const response = await getTripInfoAPI(id);
				commit(TRIP_INFO_SUCCESS, response);
			} catch (error) {
				commit(TRIP_INFO_FAILURE, error);
			}
		},
		async getUpdateTruckLocation({ commit }, { id }) {
			try {
				const response = await getTripInfoAPI(id);
				commit(TRIP_INFO_UPDATE_TRUCK_SUCCESS, response);
			} catch (e) {
				commit(TRIP_INFO_UPDATE_TRUCK_FAILURE);
			}
		}
	}
};
