<template>
	<modal class="modal-edit-graph-grafana" name="modal-edit-graph-grafana" :scrollable="true" width="600" height="auto" @before-open="beforeOpen">
		<BaseModalContent>
			<template v-slot:header>
				Edit Grafana/iframe data
				<span class="close-button" @click="hideModal">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div class="container-form">
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="$v.urlIframe.$model"
								:inputOptions="['small']"
								:is-error="isUrlError"
								:error-message="getUrlErrorMessage"
								label="iframe"
								placeholder="Add iframe from grafana/iframe"
								inline
							/>
						</div>
					</div>
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="$v.title.$model"
								:inputOptions="['small']"
								:is-error="isTitleError"
								:error-message="getTitleErrorMessage"
								label="Title"
								placeholder="Enter name of the grafana/iframe data"
								inline
							/>
						</div>
					</div>
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="$v.priority.$model"
								:inputOptions="['small']"
								:is-error="isPriorityError"
								:error-message="getPriortyErrorMessage"
								label="Priority"
								placeholder="e.g. 1, 2, 3"
								inline
							/>
						</div>
					</div>
					<div v-if="isStandardModule" class="item">
						<div class="form">
							<BaseDropdown
								v-model="$v.grafanaTab.$model"
								:searchable="false"
								:list="params.listGrafanaTab"
								:placeholder="`Please Select`"
								:is-error="isTabError"
								:error-message="getTabErrorMessage"
								label="Tab"
								inline
								rounded
							/>
						</div>
					</div>
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="$v.previewWithEmail.$model"
								:inputOptions="['small']"
								class="input-preview-with-email"
								info-message="For partner's iframe, leave this field blank to use the currently logged-in user for preview."
								label="Preview with Email"
								placeholder="Enter email address"
								inline
							/>
						</div>
					</div>
					<div class="item-checkbox">
						<div class="form">
							<BaseCheckBox
								:checked="usePreviewEmail"
								label="Always use this email to preview on the website."
								@handleCheck="handleUsePreviewEmail"
							/>
						</div>
					</div>
					<div class="item">
						<div class="form">
							<BaseFormInput
								v-model="$v.workspaceId.$model"
								:inputOptions="['small']"
								class="input-workspace-id"
								label="workspace ID"
								placeholder="Enter workspace ID"
								inline
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					class="btn-cancel"
					type="cancel"
					size="medium"
					@click="hideModal"
					rounded
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="large"
					:disabled="$v.$invalid"
					@click="handleSubmit"
				>
					Save Changes
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import BaseModalContent from "@/components/BaseModalContent.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import { required, numeric, maxLength } from "vuelidate/lib/validators";
import { getvalidateMessage, checkIfValid } from "../../../selectors/formValidate/validateHelper";

export default {
	name: "ModalAddNewGraph",

	components: {
		BaseModalContent,
		BaseCheckBox
	},

	mixins: [
		validationMixin
	],

	data() {
		return {
			urlIframe: "",
			params: null,
			priority: null,
			title: "",
			grafanaTab: null,
			listGrafanaTab: [],
			isStandardModule: false,
			previewWithEmail: "",
			workspaceId: "",
			usePreviewEmail: false
		};
	},

	methods: {
		beforeOpen(event) {
			if (event.params !== undefined) {
				this.params = event.params;
				this.priority = event.params.item.priority ?? (event.params.key + 1);
				this.urlIframe = event.params.item.url ?? "";
				this.title = event.params.item.title ?? "";
				this.isStandardModule = this.params.isStandardModule ?? false;
				this.listGrafanaTab = this.params.listGrafanaTab;
				this.grafanaTab = this.params.keyTab ?? 0;
				this.previewWithEmail = event.params.item.previewWithEmail ?? "";
				this.usePreviewEmail = event.params.item.usePreviewEmail ?? false;
				this.workspaceId = event.params.item.workspaceId ?? "";
			}
		},

		hideModal() {
			this.$modal.hide("modal-edit-graph-grafana");
		},

		handleUsePreviewEmail(value) {
			this.usePreviewEmail = value;
		},

		handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			this.$emit("submit", {
				urlIframe: this.urlIframe,
				priority: this.priority,
				title: this.title,
				keyTab: this.params.keyTab ?? 0,
				key: this.params.key,
				indexNewEditGrafanaTab: this.grafanaTab,
				previewWithEmail: this.previewWithEmail,
				usePreviewEmail: this.usePreviewEmail,
				workspaceId: this.workspaceId
			});
			this.params = null;
			this.priority = "";
			this.title = null;
			this.urlIframe = null;
			this.grafanaTab = null;
			this.previewWithEmail = null;
			this.usePreviewEmail = false;
			this.workspaceId = null;
			this.hideModal();
		}
	},

	computed: {
		getUrlErrorMessage() {
			const field = this.$v.urlIframe;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "This iframe is not valid."
			});
		},

		isUrlError() {
			const field = this.$v.urlIframe;
			return checkIfValid(field);
		},

		getPriortyErrorMessage() {
			const field = this.$v.priority;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				invalidFormatMessage: "Must be a positive number only"
			});
		},

		getTitleErrorMessage() {
			const field = this.$v.title;
			return getvalidateMessage(field, {
				requiredMessage: "Please enter the title name",
				lengthLimitMessage: "Field length at max 255 characters"
			});
		},

		isPriorityError() {
			const field = this.$v.priority;
			return checkIfValid(field);
		},

		isTitleError() {
			const field = this.$v.title;
			return checkIfValid(field);
		},

		getTabErrorMessage() {
			const field = this.$v.grafanaTab;
			return getvalidateMessage(field, {
				requiredMessage: "Please select Grafana Tab"
			});
		},

		isTabError() {
			const field = this.$v.grafanaTab;
			return checkIfValid(field);
		}
	},

	validations() {
		if (this.isStandardModule) {
			const formsValidation = {
				urlIframe: {
					required,
					format(value) {
						const regex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
						return regex.test(value);
					}
				},

				priority: {
					required,
					format(value) {
						return (value > 0 && numeric(value));
					}
				},

				title: {
					required,
					maxLength: maxLength(255)
				},

				grafanaTab: {
					required
				},

				previewWithEmail: {},

				workspaceId: {}
			};

			return formsValidation;
		}

		const formsValidation = {
			urlIframe: {
				required,
				format(value) {
					const regex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
					return regex.test(value);
				}
			},

			priority: {
				required,
				format(value) {
					return (value > 0 && numeric(value));
				}
			},

			title: {
				required,
				maxLength: maxLength(255)
			},

			previewWithEmail: {},

			workspaceId: {}
		};

		return formsValidation;
	}

};
</script>

<style lang="scss" scoped>
.modal-edit-graph-grafana {
	::v-deep {
		.v--modal-box {
			overflow: initial !important;
		}
	}

	.container-form {
		&.active {
			margin-bottom: rem(40);
		}

		.item {
			margin-bottom: $spacing-6;
			display: flex;

			.label {
				display: flex;
				align-items: center;
				min-width: rem(120);
			}

			.form {
				flex-grow: 1;
			}
		}

		.item:last-of-type {
			margin-bottom: 0;
		}
	}

	.notify-error {
		margin-bottom: $spacing-6;
	}
}

.btn-cancel {
	margin-right: rem(24);
}

.input-preview-with-email {
	::v-deep {
		.info-message {
			color: $color-grey-2;
		}
	}
}

.item-checkbox {
	margin-left: rem(130);
}
</style>
