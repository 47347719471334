export const yAxisConfig = [
	{
		type: "category",
		color: "#59e07a",
		fontFamily: "TFSrivichai",
		position: "right",
		data: ["0", "20", "40", "60", "80", "100"],
		boundaryGap: false,
		nameTextStyle: {
			color: "#717171",
			width: "100px",
			fontSize: 12,
			padding: [0, 0, 24, -26],
			align: "left"
		},
		axisLabel: {
			margin: 8,
			fontSize: 14,
			formatter: `{value}%`
		},
		splitNumber: 4,
		splitLine: {
			lineStyle: {
				color: ["#cad9cf"]
			}
		},
		axisTick: {
			length: 4,
			alignWithLabel: true,
			lineStyle: {
				width: 1,
				color: "#586c63"
			}
		},
		axisLine: {
			lineStyle: {
				color: "#cad9cf"
			}
		}
	}
];