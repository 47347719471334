<template>
	<div class="container-inactive">
		<EmptyDevices
			v-if="isEmpty && !isLoading && !isFilter"
			message="No inactive devices"
		/>

		<BaseTableCustom
			v-else
			:tableOptions="tableOptions"
			:pagination="pagination"
			:loading="isLoading"
			@onPageChange="handlePageChange"
		>
			<template v-slot:thead>
				<tr>
					<th
						v-for="(value, index) in table.thead"
						:key="index"
					>
						{{ value }}
					</th>
				</tr>
			</template>
			<template v-slot:tbody>
				<tr
					v-for="(item, index) in table.tbody"
					:key="index"
				>
					<td class="max-width-130">
						<span class="device-id">
							{{ item.deviceId }}
						</span>
					</td>
					<td class="max-width-180">
						<p class="limit-lines-2">
							{{ item.shipToName }}
						</p>
					</td>
					<td class="max-width-130">
						{{ item.shipToKey }}
					</td>
					<td class="max-width-130">
						{{ item.module }}
					</td>
					<td class="max-width-130">
						{{ item.productType }}
					</td>
					<td class="max-width-140">
						{{ item.inactiveSince }}
					</td>
				</tr>
			</template>
		</BaseTableCustom>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EmptyDevices from "@/components/monitor/deviceHealth/EmptyDevices.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import { cloneDeep, cleanQuery } from "../../../selectors/helpers";

export default {
	name: "InactiveDevice",

	components: {
		EmptyDevices,
		BaseTableCustom
	},

	data() {
		return {
			query: {
				keyword: this.$route.query.keyword || null,
				productName: this.$route.query.productName || null,
				moduleName: this.$route.query.moduleName || null,
				page: this.$route.query.page || null
			},
			tableOptions: [
				"is-striped is-fullwidth"
			]
		};
	},

	computed: {
		...mapState(["devicesInactive"]),
		table() {
			return {
				thead: [
					"Device ID",
					"Ship-to name",
					"Ship-to key",
					"Module",
					"Product type",
					"Inactive since"
				],
				tbody: this.devicesInactive.data
			};
		},
		pagination() {
			return this.devicesInactive.pagination;
		},
		isLoading() {
			return this.devicesInactive.isLoading;
		},
		isEmpty() {
			return this.devicesInactive.isEmpty;
		},
		getQuery() {
			return {
				q: this.query.keyword,
				productname: this.query.productName,
				module: this.query.moduleName,
				page: this.query.page
			};
		},
		isFilter() {
			const cloneObj = cloneDeep(this.getQuery);
			const cleanObj = cleanQuery(cloneObj);

			return Object.keys(cleanObj).length > 0;
		}
	},

	created() {
		this.getDevicesInactive(this.getQuery);
	},

	methods: {
		...mapActions("devicesInactive", {
			getDevicesInactive: "getDevicesInactive"
		}),
		handlePageChange(pageNum) {
			this.$router.replace({ query: {
				...this.$route.query,
				page: pageNum
			} });
		}
	}
};
</script>

<style lang="scss" scoped>
td {
	word-wrap: break-word;
}

.device-id {
	font-size: $font-18;
	font-weight: $font-weight-bold;
}

.max-width-130 {
	max-width: rem(130);
}

.max-width-140 {
	max-width: rem(140);
}

.max-width-180 {
	max-width: rem(180);
}

.limit-lines-2 {
	@include ellipsis(2);
}
</style>