<template>
	<div>
		<div class="col-12">
			<BaseCard class="chart">
				<template v-slot:header>
					<h4>
						<div class="header-icon" v-if="headerIcon">
							<FontAwesomeIcon :icon="['far', headerIcon]" />
						</div>
						{{ chartTitle }}
						<BaseTooltip
							v-if="showTooltip"
							:message="tooltipMessage"
							width="260px"
							class="tooltip"
						>
							<font-awesome-icon :icon="['far', 'info-circle']" class="icon-info" />
						</BaseTooltip>
						<div v-if="showLastUpdate && lastUpdateLeft" class="last-update">
							Last update: {{ lastUpdateFormatted }}
						</div>
					</h4>
					<div class="detail-card-header">
						<div v-if="showLastUpdate && !lastUpdateLeft" class="last-update">
							Last update: {{ lastUpdateFormatted }}
						</div>
						<div v-if="showDateRangeLastUpdate" class="date-range">
							{{ showDateRangeLastUpdate }}
						</div>
						<template v-if="showDateFilter">
							<template v-if="type === 'gas-consumption'">
								<BaseDatePicker
									v-model="pickerValue"
									:min-date="filterOptions.date.picker.minDate"
									:max-date="filterOptions.date.picker.maxDate"
									:mode="filterOptions.date.picker.mode"
									size="small"
									position="right"
									class="custom-date-picker"
									@done="handleDatePickerSelected"
								/>
								<span>
									{{ filterOptions.date.condition || "or" }}
								</span>
								<BaseDropdown
									:value="dropdownValue"
									:list="filterOptions.date.dropdown.items"
									:disabledItem="filterOptions.date.dropdown.disabledItems"
									:searchable="false"
									size="small"
									:placeholder="`${filterOptions.date.dropdown.placeholder || 'View by'}`"
									class="custom-date-dropdown"
									@input="handleDateTankDropdownSelected"
								/>
							</template>
							<template v-else>
								<BaseButtonGroup v-bind="mapDateDropdownItems" @click="handleDateDropdownSelected"/>
							</template>
						</template>
					</div>
					<div v-if="showDataLabel !== null">
						<BaseSwitch
							:name="chartTitle"
							:checked="showDataLabel"
							@input="handleLabelSwitch"
						/>
						<span v-if="showDataLabel" class="label-switch">Show Data</span>
						<span v-if="!showDataLabel" class="label-switch">Hide Data</span>
					</div>
				</template>
				<template v-slot:body>
					<template v-if="isHassortSlot">
						<div class="wrapper-sort-tab">
							<slot name="sortTab"></slot>
						</div>
					</template>
					<div v-if="isLoading" class="loading-wrapper">
						<BaseLoading />
					</div>
					<BaseNoData
						v-if="isEmpty"
						class="graph"
						description="BIG portal will start showing the graph <br/> right after we have information"
					/>
					<BarChart v-else-if="!isLoading" :chartData="chartData" :type="type" :manualUpdate="manualUpdate" />
				</template>
			</BaseCard>
		</div>
	</div>
</template>

<script>
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import dayjs from "dayjs";
import BarChart from "./BarChart.vue";
import BaseCard from "../BaseCard.vue";
import BaseSwitch from "../BaseSwitch.vue";
import BaseTooltip from "../BaseTooltip.vue";
import { getDateNumber, getDateRange } from "../../selectors/helpers";

export default {
	components: {
		BarChart,
		BaseCard,
		BaseSwitch,
		BaseNoData: () => import("../BaseNoData.vue"),
		BaseLoading: () => import("../BaseLoading.vue"),
		BaseDatePicker,
		BaseButtonGroup,
		BaseTooltip
	},
	props: {
		isEmpty: {
			type: Boolean,
			default: false
		},
		headerIcon: {
			type: String,
			default: ""
		},
		lastUpdate: {
			type: String,
			default: ""
		},
		lastUpdateLeft: {
			type: Boolean,
			default: false
		},
		dateRange: {
			type: String,
			default: ""
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		chartTitle: {
			type: String,
			default: "Delivery"
		},
		tooltipMessage: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "tank",
			validator: (value) => [
				"tank",
				"meter",
				"gas-consumption"
			].includes(value)
		},
		chartData: {
			type: Object
		},
		filterOptions: {
			type: Object,
			default: () => ({})
		},
		showDataLabel: {
			type: Boolean,
			default: null
		},
		manualUpdate: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			pickerValue: null,
			dropdownValue: null
		};
	},
	created() {
		if (this.showDateFilter) {
			const rangeDate = this.filterOptions.date.selected.range;
			const dateNumber = getDateNumber(rangeDate);
			this.dropdownValue = dateNumber;
		}
	},
	computed: {
		showTooltip() {
			return this.tooltipMessage !== "";
		},
		isHassortSlot() {
			return this.$slots.sortTab;
		},
		showDateFilter() {
			return this.filterOptions.date !== undefined;
		},
		showLastUpdate() {
			return this.lastUpdateFormatted !== "";
		},
		showDateRangeLastUpdate() {
			return this.dateRange;
		},
		lastUpdateFormatted() {
			const format = "D MMM YYYY, HH:mm";
			const { lastUpdate } = this;
			return lastUpdate
				? dayjs(lastUpdate).format(format)
				: "";
		},
		mapDateDropdownItems() {
			if (!this.showDateFilter) {
				return null;
			}

			return {
				items: this.filterOptions.date.dropdown.items.map((item) => ({
					text: item.label,
					value: item.value,
					isDisabled: item.isDisabled
				})),
				active: this.dropdownValue
			};
		}
	},
	methods: {
		handleDateDropdownSelected(dateNumber) {
			const { start, end } = getDateRange(dateNumber);
			this.dropdownValue = dateNumber;
			this.$emit("dateSelected", { start, end });
			this.$emit("dateDropDown", dateNumber);
		},
		handleLabelSwitch(check, name) {
			this.$emit("handleLabelSwitch", check, name);
		},
		handleDateTankDropdownSelected(dateNumber) {
			if (dateNumber) {
				if (!this.filterOptions?.date.condition) {
					this.pickerValue = null;
				}
				this.dropdownValue = dateNumber;
				this.$emit("onSelectDropDown", dateNumber);
			}
		},
		handleDatePickerSelected(date) {
			this.dropdownValue = null;
			this.$emit("onSelectDatePicker", date);
		}
	}
};
</script>
<style lang="scss" scoped>
.wrapper-sort-tab {
	margin-bottom: rem(36);
}


.tooltip {
	position: relative;
	top: rem(-1);
	margin-left: rem(14);

	&:hover > .icon-info {
		color: $color-dark-blue-grey;
		cursor: pointer;
	}

	.icon-info {
		width: rem(12);
		height: rem(13);
		color: $color-gunmetal-two;
	}
}

.header-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-white;
	font-size: rem(12);
	height: rem(30);
	width: rem(30);
	background-color: $color-te-papa-green;
	border-radius: 50%;
	margin-right: rem(16);

	svg {
		margin: 0;
	}
}

.detail-card-header {
	display: flex;
	align-items: center;
	margin-bottom: 0 !important;

	@include mobile {
		align-items: flex-start;
		flex-direction: column;
		padding: 0;
		width: 100%;
	}


	& > * {
		margin-left: rem(10);
	}

	span {
		color: $color-grey-4;
	}
}

.loading-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: rem(318);
}

.last-update {
	@include fontBase();

	font-size: rem(18);
	font-style: italic;
	color: $color-dark-blue-grey;
	margin-right: rem(14);
}

h4 .last-update {
	margin-left: rem($spacing-3);
	font-weight: normal;
}

.date-range {
	@include fontBase();

	font-size: rem(18);
	color: $color-grey-4;
}

.custom-date-dropdown {
	width: auto;

	/* stylelint-disable */
	&::v-deep .dropdown-value {
		border-color: $color-silver;
		background-color: $color-white;
	}

	&::v-deep .dropdown-value-text, &::v-deep .dropdown-placeholder {
		color: $color-grey-4;
		font-size: $font-16;
		font-weight: $font-weight-bold;
	}
	/* stylelint-enable */
}

.label-switch {
	padding: rem(10);
}
</style>
