import httpClient from "./httpClient";

const MONITOR_END_POINT = "/monitors";

const getMonitorModuleDevicesAPI = () => httpClient.get(`${MONITOR_END_POINT}/devices`);
const getMonitorProductTypeAPI = () => httpClient.get(`${MONITOR_END_POINT}/products`);
const getUserRoleAPI = (params) => httpClient.get(`${MONITOR_END_POINT}/roles`, { params });
const getUserUsagesAPI = (params) => httpClient.get(`${MONITOR_END_POINT}/usages`, { params });
const getMonitorActivityLogAPI = () => httpClient.get(`${MONITOR_END_POINT}/activities`);
const getMonitorOverallAPI = () => httpClient.get(`${MONITOR_END_POINT}/overall`);
const getMonitorUserOverallAPI = (params) => httpClient.get(`${MONITOR_END_POINT}/userOveralls`, { params });
const getMonitorMostActiveUserAPI = (params) => httpClient.get(`${MONITOR_END_POINT}/users/activities`, { params });
const getUserLoginAPI = (params) => httpClient.get(`${MONITOR_END_POINT}/users/logins`, { params });
const getUserTypesAPI = (params) => httpClient.get(`${MONITOR_END_POINT}/users/types`, { params });

export {
	getMonitorModuleDevicesAPI,
	getMonitorProductTypeAPI,
	getUserRoleAPI,
	getUserUsagesAPI,
	getMonitorActivityLogAPI,
	getMonitorOverallAPI,
	getMonitorUserOverallAPI,
	getMonitorMostActiveUserAPI,
	getUserLoginAPI,
	getUserTypesAPI
};
