<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:iconSize="iconSize"
	>
		<template #icon>
			<WaterIcon
				:key="data.shipId"
				:shipId="data.shipId"
				:value="data.DO02"
				:unit="WAST_WATER_UNIT"
				color="grey"
				:size="iconSize"
			/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<BaseTag v-if="data.status" :class="tagClass" size="small">
					{{ status }}
				</BaseTag>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import WaterIcon from "@/components/deviceIcons/WaterIcon.vue";
import BaseTag from "@/components/BaseTag.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";

import { WAST_WATER_UNIT } from "../../../../selectors/transform/unit";
import { MODULE_DEVICE_STATUSES } from "../../../../enums/modules";

export default {
	name: "WaterDeviceWithCompany",

	components: {
		WaterIcon,
		BaseTag,
		BaseDeviceWithCompany
	},

	data() {
		return {
			WAST_WATER_UNIT
		};
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},
	computed: {
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		},
		tagClass() {
			const status = this.data.status;
			return status
				? MODULE_DEVICE_STATUSES[status]?.tagClass
				: null;
		},
		status() {
			return MODULE_DEVICE_STATUSES[this.data.status]?.title;
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
::v-deep {
	.container-detail-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-right: rem(12);
		margin-left: rem(16);

		.wrapper-icon {
			margin-top: rem(4);
			margin-left: rem(1);
		}
	}
}
/* stylelint-enable */
.container-detail {
	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		.tag {
			position: absolute;
			top: rem(16);
			right: rem(16);
		}
	}
}
</style>