import { postFlowDiagramAPI } from "@/services/api/flowdiagram.api";
import { FLOW_DIAGRAM_CREATE_REQUEST, FLOW_DIAGRAM_CREATE_SUCCESS, FLOW_DIAGRAM_CREATE_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		loading: true
	},

	mutations: {
		[FLOW_DIAGRAM_CREATE_REQUEST](state) {
			state.loading = true;
		},
		[FLOW_DIAGRAM_CREATE_SUCCESS](state) {
			state.loading = false;
		},
		[FLOW_DIAGRAM_CREATE_FAILURE](state) {
			state.loading = false;
		}
	},

	actions: {
		async createFlowDiagram({ commit }, { id, data }) {
			try {
				commit(FLOW_DIAGRAM_CREATE_REQUEST);
				await postFlowDiagramAPI(id, data);
				commit(FLOW_DIAGRAM_CREATE_SUCCESS);
			} catch (error) {
				commit(FLOW_DIAGRAM_CREATE_FAILURE);
			}
		}
	}
};
