/**
 * Enum for toast types.
 * @readonly
 * @enum {EnumToastType}
 */

export const TOAST_TYPES = Object.freeze({
	STAFF_CREATE: "toast_staff_create",
	STAFF_UPDATE: "toast_staff_update",
	STAFF_DELETE: "toast_staff_delete",
	REDIRECT_LOGIN: "toast_redirect_login",
	RESPONSE_ERROR: "toast_response_error",
	RESPONSE_SUCCESS: "toast_response_success",
	CUSTOMER_CREATE: "toast_customer_create",
	CUSTOMER_UPDATE: "taost_customer_update",
	CUSTOMER_UPDATE_PASSWORD: "taost_customer_update_password",
	METER_CONSUMPTION_APPROVE_SUCCESS: "toast_meter_consumption_approve_success",
	METER_CONSUMPTION_VERIFY_SUCCESS: "toast_meter_consumption_verify_success",
	LAYOUT_CUSTOMISATION: "toast_layout_customisation",
	PROCESSING: "toast_processing"
});