import { getAirCompFlowRateAPI } from "@/services/api/airComp.api";
import { AIR_COMP_FLOW_RATE_REQUEST, AIR_COMP_FLOW_RATE_SUCCESS, AIR_COMP_FLOW_RATE_FAILURE } from "../../types";
import { parseAirCompFlowRate } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parseAirCompFlowRate({}),
		loading: true,
		empty: false
	},

	mutations: {
		[AIR_COMP_FLOW_RATE_REQUEST](state) {
			state.detail = parseAirCompFlowRate({});
			state.loading = true;
			state.empty = false;
		},
		[AIR_COMP_FLOW_RATE_SUCCESS](state, data) {
			const detail = data;
			state.detail = parseAirCompFlowRate(detail);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[AIR_COMP_FLOW_RATE_FAILURE](state) {
			state.detail = parseAirCompFlowRate({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async fetchAirCompFlowRate({ commit }, { id, params }) {
			try {
				commit(AIR_COMP_FLOW_RATE_REQUEST);
				const { data } = await getAirCompFlowRateAPI(id, params);
				commit(AIR_COMP_FLOW_RATE_SUCCESS, data);
			} catch (error) {
				commit(AIR_COMP_FLOW_RATE_FAILURE, error);
			}
		}
	}
};