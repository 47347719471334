<template>
	<BaseLayout :isLoading="isLoading">
		<BaseBackBtn label="Back to Dashboard" />
		<BaseBreadcrumb :item="breadcrumb" class="has-dot-separator" />
		<ModuleHeaderStandard :data="standardModulePage.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<div class="row header-info">
				<ul v-if="!isMobile" class="nav nav-tabs">
					<li v-for="(item,index) in standardModulePage.data.tabs" :key="index" class="nav-item">
						<router-link v-if="item.url === 'grafana'"
							:to="{
								name: PAGES[item.url],
								query: {tab:item.name}
							}"
							:active-class="$route.query.tab === item.name ? 'active' : ''"
							class="nav-link"
						>
							{{item.name}}
						</router-link>
						<router-link v-else
							:to="{
								name: PAGES[item.url]
							}"
							active-class="active"
							class="nav-link"
						>
							{{item.name}}
						</router-link>
					</li>
				</ul>
				<BaseDropdown
					v-else
					:searchable="false"
					:list="navTabDataOptions"
					:value="query.module"
					placeholder="All"
					@input="handleStatusSelected"
					class="modules-mobile"
				/>
			</div>
			<router-view
				@loaded="handleContentLoaded"
				:shipToName="shipToName"
			/>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseBreadcrumb from "@/components/BaseBreadcrumb.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModuleHeaderStandard from "@/components/StandardModule/ModuleHeaderStandard.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import userPermission from "../../mixins/userPermission";
import { CONFIG } from "../../enums/config";
import setupDevice from "../../mixins/setupDevice";

export default {
	name: "StandardModulePage",
	components: {
		BaseBreadcrumb,
		BaseBackBtn,
		ModuleHeaderStandard
	},

	data() {
		return {
			PAGE_NAME,
			CONFIG,
			id: this.$route.params.id,
			templateId: this.$route.params.templateId,
			isLoading: true,
			query: {
				module: this.$route.name || null
			},
			fetchDevices: () => {},
			PAGES: {
				overview: PAGE_NAME.STANDARD_MODULE_OVERVIEW,
				history: PAGE_NAME.STANDARD_MODULE_HISTORY,
				analysis: PAGE_NAME.STANDARD_MODULE_ANALYSIS,
				grafana: PAGE_NAME.STANDARD_MODULE_GRAFANA
			}
		};
	},

	mixins: [userPermission, setupDevice],

	computed: {
		...mapState(["standardModulePage", "shipTos"]),
		...mapGetters("user", {
			getProfile: "getProfile"
		}),

		breadcrumb() {
			return {
				type: {
					name: this.standardModulePage.data.module
				},
				shiped: {
					name: this.standardModulePage.data.soldToName
				}
			};
		},
		shipToName() {
			return this.shipTos.data.name;
		},
		navTabDataOptions() {
			const list = this.standardModulePage.data.tabs.map((item) => ({
				label: item.name,
				value: this.PAGES[item.url]
			}));
			return list;
		}
	},

	async created() {
		await this.getStandardModuleByDevice({ templateId: this.templateId, id: this.id });
		this.isLoading = this.standardModulePage.isLoading;
		this.autoRefreshData = setInterval(async () => {
			// TODO: waiting for finish and add autoRefresh data Standard Module
			if (!this.standardModulePage.isLoading) {
				await this.autoGetStandardModuleByDevice({ templateId: this.templateId, id: this.id });
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		// save log for tab Overview only
		if (this.$route.name === PAGE_NAME.STANDARD_MODULE_OVERVIEW) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: 200
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);

				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("airCompInfo", {
			fetchAirCompInfo: "fetchAirCompInfo",
			autoFetchAirCompInfo: "autoFetchAirCompInfo",
			clearAirCompInfo: "clearAirCompInfo"
		}),

		...mapActions("standardModulePage", {
			getStandardModuleByDevice: "getStandardModuleByDevice",
			autoGetStandardModuleByDevice: "autoGetStandardModuleByDevice"
		}),

		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),

		handleContentLoaded() {
			this.fetchDevices();
		},

		handleStatusSelected(value) {
			this.$router.push({ name: value });
		}
	},

	destroyed() {
		this.clearAirCompInfo();
	}
};
</script>

<style lang="scss" scoped>
.header-info {
	margin-left: rem(-30);
	margin-right: rem(-30);
	padding: 0 rem(30);
	margin-bottom: rem(32);

	@include mobile {
		border-bottom: rem(1) solid $color-silver;
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;
		padding: 0 rem(16) rem(32);
		margin-bottom: 0;
	}

	@include desktop {
		border-bottom: rem(1) solid $color-silver;
		padding-bottom: rem(32);
	}

	@include desktop-large {
		margin-bottom: 0;
	}
}
</style>
