<template>
	<div>
		<Form
			v-model="diagrams"
			:loading="loading"
			:title="`Diagram`"
			:query="query"
			:editable="false"
			:zoomable="true"
			:initEmptyNode="false"
			:viewOnly="true"
			:shipTos="shipTos"
		/>
		<BaseModalAccessDenied
			:modal-name="MODAL_NAME_ACCESS_DENIED"
			:faIcon="['fas', 'exclamation-circle']"
			:title="`Access denied`"
			:message="`Sorry, you don’t have permission to access this page.`"
			:closeButtonText="`OK`"
			@onClose="handleCloseAccessDenied"
		/>
	</div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Form from "@/views/FlowDiagram/Form.vue";
import BaseModalAccessDenied from "@/components/BaseModalAccessDenied.vue";
import { convertDiagrams } from "../../selectors/helpers/flowDiagram";
import { getIsSuperAdmin } from "../../selectors/transform/staff";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	components: {
		Form,
		BaseModalAccessDenied
	},

	data() {
		return {
			MODAL_NAME_ACCESS_DENIED: "MODAL_NAME_ACCESS_DENIED",
			query: {
				id: this.$route.query.id
			},
			diagrams: []
		};
	},

	computed: {
		...mapState(["user"]),
		...mapState("flowDiagramSingle", {
			flowDiagram: "data",
			flowDiagramStatusCode: "statusCode",
			flowDiagramLoading: "loading"
		}),
		...mapState("flowDiagramShipTo", {
			shipTos: "data",
			shipTosLoading: "loading"
		}),
		...mapState("flowDiagramList", {
			flowDiagrams: "data",
			flowDiagramsLoading: "loading"
		}),
		...mapState("flowDiagramDeviceList", {
			devices: "data",
			devicesLoading: "loading"
		}),
		loading() {
			return this.flowDiagramLoading || this.shipTosLoading || this.flowDiagramsLoading || this.devicesLoading;
		},
		isSuperAdmin() {
			return getIsSuperAdmin(this.user.role);
		}
	},

	async mounted() {
		await this.fetchData();
		this.setState();
		if (this.flowDiagramStatusCode === 403) {
			this.$modal.show(this.MODAL_NAME_ACCESS_DENIED);
		}
	},

	methods: {
		...mapActions("flowDiagramSingle", {
			getFlowDiagramSingle: "getFlowDiagramSingle"
		}),
		...mapActions("flowDiagramShipTo", {
			getFlowDiagramShipToList: "getFlowDiagramShipToList"
		}),
		...mapActions("flowDiagramList", {
			getFlowDiagramList: "getFlowDiagramList"
		}),
		...mapActions("flowDiagramDeviceList", {
			getFlowDiagramDeviceList: "getFlowDiagramDeviceList"
		}),
		async fetchData() {
			return Promise.all([
				this.getFlowDiagramSingle({
					id: this.query.id
				}),
				this.getFlowDiagramShipToList({
					perPage: 10000,
					show: this.isSuperAdmin
						? "all"
						: "assigned"
				}),
				this.getFlowDiagramList({
					page: 1,
					perPage: 10000,
					ignoreDiagrams: true
				}),
				this.getFlowDiagramDeviceList({
					id: this.query.id,
					params: {
						type: "All"
					}
				})
			]);
		},
		setState() {
			try {
				this.diagrams = convertDiagrams(this.flowDiagram.diagrams, this.devices, { addEmptyNodes: false });
			} catch (e) {
				this.diagrams = [];
			}
		},
		handleCloseAccessDenied() {
			this.$modal.hide(this.MODAL_NAME_ACCESS_DENIED);
			this.$router.push({
				name: PAGE_NAME.FLOWDIAGRAM_LIST
			});
		}
	}
};
</script>