<template>
	<div class="overview-container">
		<div class="overview-header" v-if="data.type === 'AIRCOMP'">
			<div class="title" v-html="data.headerOverview.title"></div>
			<div class="value" v-html="data.headerOverview.value"></div>
		</div>
		<template v-if="data.type !== 'EOrdering'">
			<div class="overview-section">
				<div
					class="overview-bar"
				>
					<NitroFASIcon
						v-if="data.type === MODULE_TYPES.NITRO_FAS"
						:value="data.UPH"
						size="large"
					/>
					<TankIcon
						v-if="isTankType"
						:status="data.tankStatus"
						:value="data.currentLevel"
						:percentage="data.tankPercent"
						size="large"
					/>
					<BulkHydrogenIcon
						v-if="isBulkHydrogenType"
						:status="data.tankStatus"
						:value="data.currentLevel"
						:percentage="data.tankPercent"
						size="large"
					/>
					<MeterIcon
						v-else-if="data.type === MODULE_TYPES.METER"
						:value="data.flowRate"
						:percentage="data.flowRatePercent"
						size="large"
					/>
					<WaterIcon
						v-else-if="data.type === MODULE_TYPES.O2_AERATION"
						:shipId="data.shipId"
						:value="data.DO02"
						:unit="WAST_WATER_UNIT"
						color="grey"
						size="large"
					/>
					<WaterIcon
						v-else-if="data.type === MODULE_TYPES.ZLD"
						:shipId="data.shipId"
						:value="data.flowRate"
						:unit="HTML_FLOW_RATE_UNIT_PER_HR"
						color="blue"
						size="large"
					/>
					<WaterIcon
						v-else-if="data.type === MODULE_TYPES.ZLD2"
						:shipId="data.shipId"
						:value="data.flowRate"
						:unit="HTML_FLOW_RATE_UNIT_PER_HR"
						color="blue"
						size="large"
					/>
					<CerIcon
						v-else-if="data.type === MODULE_TYPES.CER"
						:value="data.outletTemperature"
						label="Outlet"
						size="large"
						class="cer-icon"
					/>
					<GenGasIcon
						v-else-if="data.type === MODULE_TYPES.GENGAS"
						:value="data.flowRate"
						:unit="HTML_FLOW_RATE_UNIT_PER_HR"
						:percentagePoint="data.flowRatePercent"
						:maxValue="data.maxRange"
						label="Outlet"
						size="large"
						class="cer-icon"
					/>
					<PowerIcon
						v-else-if="[MODULE_TYPES.PWR, MODULE_TYPES.SOR].includes(data.type)"
						:kilowatt="data.kw"
						:value="data.percentage"
						:empty="data.kw === undefined || data.maxRange === undefined"
						:weather="data.weather"
						size="large"
						class="pwr-icon"
					/>
					<LngIcon
						v-if="data.type === MODULE_TYPES.LNG"
						:status="data.tankStatus"
						:value="data.currentLevel"
						:percentage="data.tankPercent"
						size="large"
					/>
					<FurnaceIcon
						v-if="data.type === MODULE_TYPES.FURNACE"
						:key="data.shipId"
						:value="data.furnace"
						:regenN="data.regenN"
						:regenS="data.regenS"
						size="large"
					/>
					<AirCompIcon
						v-if="data.type === MODULE_TYPES.AIR_COMP"
						:key="data.shipId"
						:shipId="data.shipId"
						:value="data.specKw"
						:maxValue="data.maxRange"
						:secRate="data.secRate"
						:countAirCompActive="data.xcounter"
						size="large"
					/>
				</div>
				<div class="overview-values">
					<div
						v-for="item in overviewData"
						:key="item.key"
						class="overview-info"
					>
						<p :class="['title', { '-has-icon': item.titleType === 'icon' } , { 'subHeader': item.type === 'SubHeader' }]">
							<component v-if="item.titleType === 'icon'"  :is="item.title" class="title-icon" />
							<span v-else v-html="item.titleType === 'text' ? item.titleDisplay : item.title" class="html"></span>
						</p>
						<p :class="['value', { '-has-bar-g': item.barG }]">
							<span v-html="item.value" class="html"></span>
							<MeterBarG v-if="item.barG" :slots="item.barG.slots" :value="item.barG.value" />
							<span v-if="item.progress" class="progress-bar">
								<BarProgress :value="item.progress.value" />
							</span>
						</p>
					</div>
				</div>
			</div>
		</template>
		<div v-else class="overview-values" >
			<div
				v-for="(overviewGroup, overviewGroupIndex) in data.overviewGroups"
				:key="overviewGroupIndex"
				class="overview-group"
			>
				<div class="overview-group-header">
					{{ overviewGroup.title }}
				</div>
				<div
					v-for="(overview, overviewIndex) in overviewGroup.overviews"
					:key="overviewIndex"
					class="overview-info overview-group-body"
				>
					<p class="title">
						<span  v-html="overview.title" class="html"></span>
					</p>
					<p class="value">
						<span v-html="overview.value" class="html"></span>
					</p>
				</div>
			</div>
			<div class="overview-group">
				<div
					v-if="isShowDownloadAttachment"
					class="overview-info overview-attachment"
				>
					<p class="title">
						<span >Attachment</span>
					</p>
					<p class="value">
						<a   class="view-button clickable" @click.prevent="handleDownloadAttachment()" >
							<font-awesome-icon class="icon-file" :icon="['far', 'file-alt']"/>	Download COA
						</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TankIcon from "@/components/deviceIcons/TankIcon.vue";
import MeterIcon from "@/components/deviceIcons/MeterIcon.vue";
import WaterIcon from "@/components/deviceIcons/WaterIcon.vue";
import CerIcon from "@/components/deviceIcons/CerIcon.vue";
import GenGasIcon from "@/components/deviceIcons/GenGasIcon.vue";
import NitroFASIcon from "@/components/deviceIcons/NitroFASIcon.vue";
import PowerIcon from "@/components/deviceIcons/PowerIcon.vue";
import LngIcon from "@/components/deviceIcons/LngIcon.vue";
import FurnaceIcon from "@/components/deviceIcons/FurnaceIcon.vue";
import RegenNIcon from "@/components/icon/RegenNIcon.vue";
import RegenSIcon from "@/components/icon/RegenSIcon.vue";
import AutomaticIcon from "@/components/icon/AutomaticIcon.vue";
import GlasslevelIcon from "@/components/icon/GlasslevelIcon.vue";
import ClockIcon from "@/components/icon/ClockIcon.vue";
import PressureIcon from "@/components/icon/PressureIcon.vue";
import OxygenIcon from "@/components/icon/OxygenIcon.vue";
import NitrogenIcon from "@/components/icon/NitrogenIcon.vue";
import MeterBarG from "@/components/chart/BarG.vue";
import BarProgress from "@/components/chart/BarProgress.vue";
import BulkHydrogenIcon from "@/components/deviceIcons/BulkHydrogenIcon.vue";
import AirCompIcon from "@/components/deviceIcons/AirCompIcon.vue";

import { MODULE_TYPES, UNIT } from "../../../enums/modules";
import { HTML_FLOW_RATE_UNIT_PER_HR, WAST_WATER_UNIT } from "./../../../selectors/transform/unit";
import { STATUS } from "../../../enums/eOrdering/status";
import { getUri } from "../../../selectors/helpers";

export default {
	name: "ModuleOverview",

	components: {
		TankIcon,
		WaterIcon,
		MeterIcon,
		CerIcon,
		MeterBarG,
		BarProgress,
		NitroFASIcon,
		GenGasIcon,
		PowerIcon,
		LngIcon,
		BulkHydrogenIcon,
		RegenNIcon,
		RegenSIcon,
		AutomaticIcon,
		GlasslevelIcon,
		FurnaceIcon,
		AirCompIcon,
		ClockIcon,
		PressureIcon,
		NitrogenIcon,
		OxygenIcon
	},

	props: {
		data: {
			type: Object,
			default: null
		},
		unit: {
			type: String,
			default: ""
		}
	},

	data() {
		return {
			MODULE_TYPES,
			HTML_FLOW_RATE_UNIT_PER_HR,
			WAST_WATER_UNIT
		};
	},

	computed: {
		isTankType() {
			return this.data.type === MODULE_TYPES.TANK && this.data.productId !== MODULE_TYPES.BHY;
		},
		isBulkHydrogenType() {
			return this.data.type === MODULE_TYPES.TANK && this.data.productId === MODULE_TYPES.BHY;
		},
		isShowDownloadAttachment() {
			const allowStatus = [STATUS.COMPLETED, STATUS.DELIVERED];

			return allowStatus.includes(this.data.status) && false; // TODO: Remove && false when API ready
		},
		overviewData() {
			return this.data.overview.filter((item) => {
				if (this.unit === UNIT.KG) {
					return item.type === UNIT.KG || !item.type;
				} else if (this.unit === UNIT.NM3) {
					return item.type === UNIT.NM3 || !item.type;
				} else if (this.unit === UNIT.SM3) {
					return item.type === UNIT.SM3 || !item.type;
				} else if (this.unit === UNIT.LITER) {
					return item.type === UNIT.LITER || !item.type;
				}

				return this.data.overview;
			});
		}
	},

	methods: {
		handleDownloadAttachment() {
			const orderNo = this.data.orderNo;

			const path = `/e-ordering/infos/${orderNo}/pdf`;
			const uri = getUri(path, {});
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.overview {
	&-container {
		display: flex;
		width: 100%;
		flex-direction: column;
	}

	&-header {
		display: flex;
		margin-bottom: rem(12);
		font-size: rem(26);
		font-weight: 600;

		.title {
			flex: none;
			width: rem(162);
		}
	}

	&-section {
		display: flex;

		@media (max-width: rem(580)) {
			flex-flow: column;
		}
	}

	&-info {
		display: flex;
		justify-content: space-between;

		p {
			margin: 0 0 rem(12);
			color: $color-dark-blue-grey;

			&:nth-child(even) {
				display: flex;
				font-weight: 600;
			}
		}
	}

	&-info:last-of-type {
		p {
			margin: 0;
		}
	}

	&-values {
		flex: 1;

		.subHeader {
			font-weight: bold;
		}

		.title {
			flex: none;
			width: 50%;

			&.-has-icon {
				width: 40%;
				text-align: right;
				margin-bottom: 0;
			}

			.title-icon {
				font-size: $font-26;
			}
		}

		.value {
			&.-has-bar-g {
				width: 50%;
				display: flex;

				.html {
					width: rem(50);

					text-align: right;
				}

				::v-deep {
					.bar-g-container {
						width: calc(100% - 50px);

						.bar-g-chart {
							width: 100%;
						}
					}
				}
			}

			.progress-bar {
				width: rem(96);
				position: relative;
				top: rem(2);
				margin-left: rem(8);
			}
		}

		.overview-group {

			// overview-group-header
			&-header {
				margin: rem(8) 0;
				padding: rem(4) rem(8);

				font-size: $font-18;
				font-weight: bold;

				background-color: $color-off-white;
			}

			//overview-group-header
			&-body {
				padding: 0 rem(8);
			}
		}

		.overview-attachment {
			margin-top: rem(8);
			padding: rem(12) rem(8) rem(8) rem(8);
			border-top: 1px solid $color-ice;

			.view-button {
				.icon-file {
					margin-right: 2px;
					font-size: rem(14);
				}
			}
		}
	}

	&-bar {
		min-width: rem(144);
		margin-right: rem(16);
		padding: rem(8);
		/* stylelint-disable */
		&::v-deep {
			.nitro-fas-wrapper {
				width: rem(128);
				height: rem(128);
			}
		}
		/* stylelint-enable */

		@include mobile {
			margin: 0 auto;
		}
	}
}
</style>

<style scoped>
/* we have to create second style because scss not supported to deep selector */
.html >>> sup {
	line-height: 0;
}
</style>
