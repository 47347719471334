<template>
	<component
		:is="currentComponent"
		@change="handleChange"
	/>
</template>

<script>
import ListShipTo from "@/components/AlertAndNotification/ListShipTo.vue";
import ListRule from "@/components/AlertAndNotification/ListRule.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	components: {
		ListShipTo,
		ListRule
	},

	data() {
		return {
			query: {
				view: this.$route.query.view || "shipto"
			}
		};
	},

	computed: {
		currentComponent() {
			if (this.query.view === "shipto") {
				return ListShipTo;
			}
			return ListRule;
		}
	},

	methods: {
		handleChange(view) {
			this.query.view = view;
			this.$router.push({
				name: PAGE_NAME.ALERT_AND_NOTIFICATION_LIST,
				query: {
					...this.$route.query,
					view: this.query.view
				}
			}).catch(() => {});
		}
	}
};
</script>