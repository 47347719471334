import dayjs from "dayjs";
import { dateTimeFormat } from "../../../selectors/helpers";
import { replaceDeviceName } from "../../../selectors/helpers/replace";

/**
 * Parse Devices
 * @param {Array} input devices
 * @returns {Devices}
 */
export function parseDevices(input) {
	if (!input) {
		return [];
	}
	return input.map((item) => ({
		...item,
		lastUpdate: item.lastUpdate ? dayjs(item.lastUpdate).format("D MMM YYYY, HH:mm") : "",
		name: replaceDeviceName(item.name),
		productId: item?.productId ?? "",
		deviceCustomerName: item.deviceCustomerName || null,
		...(item.status ? {
			status: replaceDeviceName(item.status, [
				{
					from: "On",
					to: "ON"
				},
				{
					from: "OFF",
					to: "OFF"
				}
			])
		} : {
			status: "OFF"
		}
		)
	}));
}

/**
 * Parse Devices Inactive
 * @param {Array} input devices Inactive
 * @returns {DevicesInactive}
 */
export function parseDevicesInactive(input) {
	if (!input) {
		return [];
	}

	return input.map((item) => ({
		deviceId: item.deviceId,
		shipToName: item.shipToName,
		shipToKey: item.shipToKey,
		module: item.module,
		productType: item.productType,
		inactiveSince: item.inactiveSince === "-"
			? "-"
			: dateTimeFormat(item.inactiveSince)
	}));
}