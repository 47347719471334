import Numeral from "numeral";
import { MILI_QUE_UNIT } from "../../unit";
import { transformBarChart } from "..";

const optionRecycleWaterProduction = {
	yAxisText: `Recycle water production (${MILI_QUE_UNIT})`,
	series: {
		tooltip: {
			formatter: ({ value, name }) => `
        <div>
            <div class="bar-name meter">
                ${name}
            </div>
            <div class="bar-value">
                ${Numeral(value).format(0, 0)} m<sup>3</sup>
            </div>
        </div>`
		}
	}
};
export const transformRecycleWaterProduction = transformBarChart(optionRecycleWaterProduction);
