import dayjs from "dayjs";
import Numeral from "numeral";
import { replaceDeviceName } from "../../../selectors/helpers/replace";

export const dateTimeFormat = (dateTime, format = "D MMM YYYY, HH:mm") => {
	return dateTime
		? dayjs(dateTime).format(format)
		: "-";
};

const getIsNumber = (value) => /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)$/.test(value);

export const numberFormat = (value, format = "0,0.00") => {
	const isNumber = getIsNumber(value);
	return isNumber
		? Numeral(value).format(format)
		: "-";
};

/**
 * Parse Bulk Hydrogen Info
 * @param {Object} input bulk hydrogen info data
 * @returns {BulkHydrogenInfo}
 */
export function parseBhyInfo(input) {
	if (!input) {
		return {};
	}

	const overview = [
		{ title: "Forecasted Reorder", value: dateTimeFormat(input.forecastReorderAt) },
		{ title: "Forecasted Empty", value: dateTimeFormat(input.forecastEmptyAt) },
		{ title: "Remaining (psi)", value: numberFormat(input.remain) },
		{ title: "Usage rate (Nm³/hr)", value: numberFormat(input.usageRate) },
		{ title: "Month to date consumed (Nm³)", value: numberFormat(input.monthToDate) }
	];

	// Bhy Pressure
	if (input.tankPressure) {
		overview.push({
			title: "Tank pressure (barG)",
			value: numberFormat(input.tankPressure.value),
			barG: {
				value: input.tankPressure.percent,
				slots: [
					{ value: input.tankPressure.normalPercent, color: "green" },
					{ value: input.tankPressure.warningPercent, color: "orange" },
					{ value: input.tankPressure.dangerPercent, color: "red" }
				]
			}
		});
	}

	// Line Pressure
	if (input.linePressure) {
		overview.push({
			title: "Line pressure (barG)",
			value: numberFormat(input.linePressure.value),
			barG: {
				value: input.linePressure.percent,
				slots: [
					{ value: input.linePressure.dangerPercent, color: "red" },
					{ value: input.linePressure.warningPercent, color: "orange" },
					{ value: input.linePressure.normalPercent, color: "green" }
				]
			}
		});
	}

	return {
		id: input.id,
		name: replaceDeviceName(input.name),
		currentLevel: input.currentLevel,
		shipId: input.shipId,
		shipToId: input.shipToId,
		shipToLatitude: input.shipToLatitude,
		shipToLongitude: input.shipToLongitude,
		deliveryStatus: input.deliveryStatus,
		tankPercent: input.tankPercent,
		tankStatus: input.levelStatus,
		type: input.type,
		productId: input.productId,
		updatedAt: dateTimeFormat(input.updatedAt),
		deviceCustomerName: input.deviceCustomerName,
		overview,
		reportId: input.reportId,
		workspaceId: input.workspaceId
	};
}

/**
 * Parse Bulk Hydrogen History
 * @param {Array} input bulk hydrogen info data
 * @returns {BulkHydrogenHistory}
 */
export function parseBhyHistory(input) {
	if (!input) {
		return [];
	}

	return input.map((item) => ({
		date: item.date ? dateTimeFormat(item.date, "D MMM YYYY") : "-",
		doNumber: item.doNumber || "-",
		tripId: item.tripId || "-",
		tractorId: item.tractorId || "-",
		trailerId: item.trailerId || "-",
		orderAmount: numberFormat(item.orderAmount),
		actualAmount: numberFormat(item.actualAmount)
	}));
}