<template>
	<BaseScrollbar
		:viewportClass="`viewport`"
		:screenWidth="screenWidth"
		:screenHeight="screenHeight"
	>
		<div ref="refContainer">
			<svg :class="['svg', { 'view-mode': !editMode }]" preserveAspectRatio="xMidYMid meet" :viewBox="`0 0 ${screenWidth} ${screenHeight}`" >
				<g class="viewport"></g>
			</svg>
		</div>
	</BaseScrollbar>
</template>

<script>
/* eslint-disable */
import useTree from '@/use/tree';
import { ref } from '@vue/composition-api'
import BaseScrollbar from "@/components/BaseScrollbar";

export default {
  components: {
	  BaseScrollbar,
  },

  inheritAttrs: false,

  props: {
	screenWidth: {
	  type: Number,
	  default: 800,
	},
	screenHeight: {
	  type: Number,
	  default: 800,
	},
	tree: {
	  type: Object,
	  default: () => ({}),
	},
	zoomable: {
	  type: Boolean,
	  default: false,
	},
	editMode: {
		type: Boolean,
	  	default: false,
	},
  },

  setup(props, context) {
	const refContainer = ref(null)
	const options = {
	  style: {
		nodeWidth: 240, // 240
		nodeHeight: 93, // 93
		nodeDistance: 120, // 120
		nodeMargin: 24, // 24
	  },
	}
	const { nodes } = useTree(refContainer, props, context, options)
	return { refContainer, nodes }
  },
}
</script>

<style scoped>
.svg {
	font-family: Tahoma;
}

.svg >>> .node-empty,
.svg >>> .node-device {
	cursor: pointer;
}

.svg >>> .node-empty text {
	fill: #717171;
	font-family: "TFSrivichai";
	font-size: 16px;
	font-weight: bold;
}

.svg >>> .node-empty .icon path {
	fill: #717171;
}

.svg >>> .node-summary .title {
	font-family: "DBHeavent";
	font-size: 20px;
	font-weight: bold;
	fill: #717171;
}

.svg >>> .node-summary .route {
	font-family: "TFSrivichai";
	font-size: 16px;
	font-weight: bold;
	fill: #717171;
}

.svg >>> .node-summary .sub-title {
	font-family: "TFSrivichai";
	font-size: 14px;
	font-weight: bold;
	fill: #103332;
}

.svg >>> .node-summary .device-value,
.svg >>> .node-device .device-value {
	font-family: "DBHeavent";
	font-size: 32px;
	font-weight: bold;
	fill: #103332;
}

.svg >>> .node-summary .action,
.svg >>> .node-device .action {
	display: none;
}

.svg >>> .node-summary.show-action .action,
.svg >>> .node-device.show-action .action {
	display: block;
}

.svg >>> .node-summary.show-action .content,
.svg >>> .node-device.show-action .content {
	display: none;
}

.svg >>> .node-summary .action .edit-button,
.svg >>> .node-device .action .edit-button {
	font-family: "TFSrivichai";
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
}

.svg >>> .node-summary .action .remove-button,
.svg >>> .node-device .action .remove-button {
	cursor: pointer;
}

.svg.view-mode >>> .node-summary {
	cursor: default;
}

.svg >>> .node-device .device-title {
	font-family: "TFSrivichai";
	font-size: 14px;
	font-weight: bold;
	fill: #103332;
}

.svg >>> .node-summary .device-info-label,
.svg >>> .node-device .device-info-label {
	font-family: "TFSrivichai";
	font-size: 14px;
	fill: #103332;
}

.svg >>> .node-summary .device-info-value,
.svg >>> .node-device .device-info-value {
	font-family: "TFSrivichai";
	font-size: 16px;
	font-weight: bold;
	fill: #103332;
}

.svg >>> .node-device .device-header-title {
	font-family: "DBHeavent";
	font-size: 20px;
	font-weight: bold;
	fill: #103332;
}

.svg >>> .node-device .device-header-sub-title {
	font-family: "TFSrivichai";
	font-size: 16px;
	font-weight: bold;
	fill: #717171;
}
</style>