<template>
	<button
		:type="htmlType"
		:disabled="disabled"
		:class="[buttonClass, getMergeClass]"
		:style="[...styles]"
		@click="$emit('click', $event)"
	>
		<slot />
	</button>
</template>

<script>

export default {
	name: "BaseButton",
	props: {
		type: {
			type: String,
			default: "primary",
			validator: (value) => [
				"primary",
				"secondary",
				"danger",
				"cancel"
			].includes(value)
		},
		outline: {
			type: Boolean,
			default: false
		},
		link: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: "large",
			validator: (value) => ["small", "medium", "large"].includes(value)
		},
		disabled: {
			type: Boolean,
			default: false
		},
		merge: {
			type: String,
			default: ""
		},
		rounded: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: null
		},
		htmlType: {
			type: String,
			default: "submit"
		}
	},
	computed: {
		buttonClass() {
			const outline = this.outline ? "outline" : "";
			let link = "";

			if (!outline && this.link) {
				link = "link";
			} else {
				link = "";
			}

			const buttonClass = `btn btn-${this.type} ${outline} ${link}`;
			const roundedClass = this.roundedClass;
			switch (this.size) {
				case "medium": return `${buttonClass} ${roundedClass} medium`;
				case "small": return `${buttonClass} ${roundedClass} small`;
				default: return `${buttonClass} ${roundedClass} large`;
			}
		},
		roundedClass() {
			return this.rounded ? "rounded" : "";
		},

		getMergeClass() {
			if (this.merge === "right") {
				return "merge-right";
			} else if (this.merge === "left") {
				return "merge-left";
			}
			return false;
		},

		styles() {
			const arr = [];
			if (this.width) {
				arr.push({
					width: this.width
				});
			}
			return arr;
		}
	}
};
</script>
<style lang="scss" scoped>
.btn {
	outline: none;
	color: $color-white;
	border-radius: rem(4);
	padding: 0;

	&.rounded {
		border-radius: $border-radius-normal;
	}

	&-primary,
	&-secondary {
		&.outline {
			background-color: $color-white;

			&:disabled {
				color: $color-white;
				border: 0;

				&,
				&:hover {
					background-color: $color-light-teal;
					cursor: not-allowed;
				}
			}
		}
	}

	&-cancel {
		background-color: $color-white;
		color: $color-azure;
		border: unset;
	}

	&:disabled {
		&:hover {
			background-color: $color-light-teal;
			cursor: not-allowed;
		}
	}


	&.small {
		font-size: rem(16);
		height: rem(32);
		padding: 0 rem(9);
	}

	&.medium {
		height: rem(40);
		font-size: rem(20);
		padding: 0 rem(18);
	}

	&.large {
		font-size: rem(24);
		height: rem(48);
		padding: 0 rem(18);
	}

	&-primary {
		background-color: $color-emerald;
		border: solid 2px $color-emerald;

		&:disabled {
			border: solid 2px $color-light-teal;

			&,
			&:hover {
				border: solid 2px $color-light-teal;
				background-color: $color-light-teal;
				cursor: not-allowed;
			}
		}

		&:focus {
			background-color: $color-emerald;
			border: solid 2px $color-lightish-green;
		}

		&:hover {
			background-color: $color-jungle-green;
			border-color: $color-jungle-green;
		}

		&:active {
			border: solid 2px $color-lightish-green;
		}
	}

	&-secondary {
		background-color: $color-azure;
		border: solid 2px $color-azure;

		&:focus {
			background-color: $color-azure;
			border: solid 2px $color-bright-light-blue;
		}

		&:hover {
			background-color: $color-peacock-blue;
		}

		&:active {
			border: solid 2px $color-bright-light-blue;
		}

		&:disabled {
			&,
			&:hover {
				background-color: $color-light-teal;
				cursor: not-allowed;
			}
		}
	}

	&-danger {
		background-color: $color-red;

		&:focus {
			background-color: $color-red;
			border: solid 2px $color-orange-pink;
		}

		&:hover {
			background-color: $color-red;
		}

		&:active {
			border: solid 2px $color-lipstick;
		}

		&:disabled {
			&,
			&:hover {
				background-color: $color-light-teal;
				cursor: not-allowed;
			}
		}
	}

	&-danger.outline {
		border: 1px solid $color-red;
		color: $color-red;

		&.active {
			&,
			&:focus,
			&:hover,
			&:active {
				background-color: $color-red;
				color: $color-white;
			}
		}

		&:focus {
			background-color: $color-ice-two;
			border: solid 1px $color-red;
		}

		&:hover {
			background-color: $color-ice-two;
		}

		&:active {
			background-color: $color-bg-press-outline;
			border: solid 1px $color-red;
		}
	}

	&-primary.outline {
		border: 1px solid $color-emerald;
		color: $color-emerald;

		&.active {
			&,
			&:focus,
			&:hover,
			&:active {
				background-color: $color-emerald;
				color: $color-white;
			}
		}

		&:focus {
			background-color: $color-ice-two;
			border: solid 1px $color-emerald;
		}

		&:hover {
			background-color: $color-ice-two;
		}

		&:active {
			background-color: $color-bg-press-outline;
			border: solid 1px $color-lightish-green;
		}
	}

	&-secondary.outline {
		border: 1px solid $color-cerulean;
		color: $color-cerulean;

		&:focus {
			background-color: $color-ice-two;
			border: solid 1px $color-azure;
		}

		&:hover {
			background-color: $color-ice-two;
			border: solid 1px $color-azure;
			color: $color-azure;
		}

		&:active {
			background-color: $color-bg-press-outline;
			border: solid 1px $color-cerulean;
			color: $color-cerulean;
		}
	}

	&-secondary.link {
		background-color: transparent;
		border: none;
		color: $color-cerulean;

		&:focus {
			background-color: transparent;
			border: none;
		}

		&:hover {
			color: $color-azure;
			background-color: transparent;
		}

		&:active {
			color: $color-cerulean;
			background-color: transparent;
			border: none;
		}
	}

	&.merge {
		&-right {
			border-radius: 4px 0 0 4px;
		}

		&-left {
			border-radius: 0 4px 4px 0;
		}
	}
}



</style>