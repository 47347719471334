import Numeral from "numeral";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep, pipe } from "../helpers";
import { MODULE_TYPES } from "../../enums/modules";

export const getElecDevices = (items) => {
	const { PWR, SOR } = MODULE_TYPES;
	return items.filter((item) => [PWR, SOR].includes(item.type));
};

export const getMeterDevices = (items) => {
	const { METER, NITRO_FAS, GENGAS } = MODULE_TYPES;
	return items.filter((item) => [METER, NITRO_FAS, GENGAS].includes(item.type));
};

export const getNumeral = (value, format = "0,0", defaultValue = "n/a") => {
	if (value === undefined || value === null) {
		return defaultValue;
	}
	return Numeral(value).format(format);
};

export const getDeviceName = (id, name) => {
	return (name && name) || id;
};

// self invoked function
export const getNode = (nodeOption, nodes, compareKey, tempNodes = []) => {
	if (nodes.value === nodeOption[compareKey]) {
		return nodes;
	} else if (nodes.childs.length) {
		// step to last node from left to right
		if (nodes.childs.length > 1) {
			const exist = tempNodes.some((item) => item.value === nodes.value);
			if (!exist) {
				tempNodes.push({ ...nodes, index: 0 });
			}
		}
		return getNode(nodeOption, nodes.childs[0], compareKey, tempNodes);
	}
	// looking for latest node in temp
	let latestTempNode = tempNodes[tempNodes.length - 1];
	let latestTempNodeIndex = latestTempNode.index++;
	let done = false;
	while (!done) {
		// if still not found latest node, remove latest one
		if (!latestTempNode.childs[latestTempNodeIndex]) {
			tempNodes.splice(-1, 1);
			latestTempNode = tempNodes[tempNodes.length - 1];
			latestTempNodeIndex = latestTempNode.index++;
		} else {
			done = true;
		}
	}
	return getNode(nodeOption, latestTempNode.childs[latestTempNodeIndex], compareKey, tempNodes);
};

export const getNodeCurrent = (nodeOption, nodes) => {
	const compareKey = "value";
	return getNode(nodeOption, nodes, compareKey);
};

export const getNodeParent = (nodeOption, nodes) => {
	const compareKey = "ref";
	return getNode(nodeOption, nodes, compareKey);
};

// self invoked function
export const getNodeIds = (nodes, tempNodes = [], ids = []) => {
	if (!ids.includes(nodes.value)) {
		ids.push(nodes.value);
	}
	if (nodes.childs.length) {
		// step to last node from left to right
		if (nodes.childs.length > 1) {
			const exist = tempNodes.some((item) => item.value === nodes.value);
			if (!exist) {
				tempNodes.push({ ...nodes, index: 0 });
			}
		}
		return getNodeIds(nodes.childs[0], tempNodes, ids);
	}
	// looking for latest node in temp
	let latestTempNode = tempNodes[tempNodes.length - 1];
	// if not found any nodes left stop at this line
	if (!latestTempNode) {
		return ids;
	}
	let latestTempNodeIndex = latestTempNode.index++;
	let done = false;
	while (!done) {
		// if still not found latest node, remove latest one
		if (!latestTempNode.childs[latestTempNodeIndex]) {
			tempNodes.splice(-1, 1);
			latestTempNode = tempNodes[tempNodes.length - 1];
			// if not found any nodes left stop at this line
			if (!latestTempNode) {
				return ids;
			}
			latestTempNodeIndex = latestTempNode.index++;
		} else {
			done = true;
		}
	}
	return getNodeIds(latestTempNode.childs[latestTempNodeIndex], tempNodes, ids);
};

// self invoked function
export const deleteNode = (node, tree) => {
	const isRoot = tree.value === node.value;
	if (isRoot) {
		tree.type = "empty";
		tree.level = 1;
		tree.ref = null;
		tree.value = null;
		tree.detail = {};
		tree.childs = [];
		return true;
	}
	for (let i = 0; i < tree.childs.length; i++) {
		const current = tree.childs[i];
		if (current.value === node.value) {
			tree.childs.splice(i, 1);
			return true;
		}
		deleteNode(node, current);
	}
	return true;
};

// self invoked and side effect function
export const cleanUpTree = (nodes, tempNodes = []) => {
	for (let i = 0; i < nodes.childs.length; i++) {
		const node = nodes.childs[i];
		if (node.type === "empty") {
			nodes.childs.splice(i, 1);
		}
	}
	if (nodes.childs.length) {
		// step to last node from left to right
		if (nodes.childs.length > 1) {
			const exist = tempNodes.some((item) => item.value === nodes.value);
			if (!exist) {
				tempNodes.push({ ...nodes, index: 0 });
			}
		}
		return cleanUpTree(nodes.childs[0], tempNodes);
	}
	// looking for latest node in temp
	let latestTempNode = tempNodes[tempNodes.length - 1];
	// if not found any nodes left stop at this line
	if (!latestTempNode) {
		return true;
	}
	let latestTempNodeIndex = latestTempNode.index++;
	let done = false;
	while (!done) {
		// if still not found latest node, remove latest one
		if (!latestTempNode.childs[latestTempNodeIndex]) {
			tempNodes.splice(-1, 1);
			latestTempNode = tempNodes[tempNodes.length - 1];
			// if not found any nodes left stop at this line
			if (!latestTempNode) {
				return true;
			}
			latestTempNodeIndex = latestTempNode.index++;
		} else {
			done = true;
		}
	}
	return cleanUpTree(latestTempNode.childs[latestTempNodeIndex], tempNodes);
};

export const cleanUpDiagram = (diagrams) => {
	for (const diagram of diagrams) {
		// use side effect function to remove all empty nodes
		cleanUpTree(diagram.tree);
	}
	return diagrams;
};

// self invoked and side effect function
export const addEmptyNodes = (nodes, tempNodes = []) => {
	const isEmptyNode = nodes.type === "empty";
	const hasSomeEmptyNodesOnChilds = nodes.childs.some((item) => item.type === "empty");
	if (!isEmptyNode && !hasSomeEmptyNodesOnChilds) {
		nodes.childs.push({
			type: "empty",
			level: nodes.level + 1,
			ref: nodes.value,
			value: uuidv4(),
			childs: []
		});
	}
	if (nodes.childs.length) {
		// step to last node from left to right
		if (nodes.childs.length > 1) {
			const exist = tempNodes.some((item) => item.value === nodes.value);
			if (!exist) {
				tempNodes.push({ ...nodes, index: 0 });
			}
		}
		return addEmptyNodes(nodes.childs[0], tempNodes);
	}
	// looking for latest node in temp
	let latestTempNode = tempNodes[tempNodes.length - 1];
	// if not found any nodes left stop at this line
	if (!latestTempNode) {
		return true;
	}
	let latestTempNodeIndex = latestTempNode.index++;
	let done = false;
	while (!done) {
		// if still not found latest node, remove latest one
		if (!latestTempNode.childs[latestTempNodeIndex]) {
			tempNodes.splice(-1, 1);
			latestTempNode = tempNodes[tempNodes.length - 1];
			// if not found any nodes left stop at this line
			if (!latestTempNode) {
				return true;
			}
			latestTempNodeIndex = latestTempNode.index++;
		} else {
			done = true;
		}
	}
	return addEmptyNodes(latestTempNode.childs[latestTempNodeIndex], tempNodes);
};

export const toNumber = (value) => {
	const n = value.toString().replaceAll(/,/ig, "");
	return !isNaN(n)
		? Number(n)
		: 0;
};

export const getNodeSummaryElectricityTotal = (node) => {
	const types = ["device_power", "device_solor", "summary_electricity"];
	const initVal = { total: 0 };
	const value = node.childs.reduce((acc, cur) => {
		if (types.includes(cur.type)) {
			acc.total += toNumber(cur.detail.value);
		}
		return acc;
	}, { ...initVal });
	const mtd = node.childs.reduce((acc, cur) => {
		if (types.includes(cur.type)) {
			acc.total += toNumber(cur.detail.info[0].value);
		}
		return acc;
	}, { ...initVal });
	const cost = node.childs.reduce((acc, cur) => {
		if (types.includes(cur.type)) {
			acc.total += toNumber(cur.detail.info[1].value);
		}
		return acc;
	}, { ...initVal });
	return {
		value: getNumeral(value.total, "0,0"),
		mtd: getNumeral(mtd.total, "0,0"),
		cost: getNumeral(cost.total, "0,0")
	};
};

export const getNodeSummaryMeterTotal = (node) => {
	const types = ["device_meter", "device_nitrofas", "device_gengas", "summary_meter"];
	const initVal = { total: 0 };
	const value = node.childs.reduce((acc, cur) => {
		if (types.includes(cur.type)) {
			acc.total += toNumber(cur.detail.value);
		}
		return acc;
	}, { ...initVal });
	const mtd = node.childs.reduce((acc, cur) => {
		if (types.includes(cur.type)) {
			acc.total += toNumber(cur.detail.info[0].value);
		}
		return acc;
	}, { ...initVal });
	return {
		value: getNumeral(value.total, "0,0"),
		mtd: getNumeral(mtd.total, "0,0.00")
	};
};

export const setSummaryTotal = (node) => {
	if (node.type === "summary_electricity") {
		const summary = getNodeSummaryElectricityTotal(node);
		node.detail.value = summary.value;
		node.detail.info[0].value = summary.mtd;
		node.detail.info[1].value = summary.cost;
	} else if (node.type === "summary_meter") {
		const summary = getNodeSummaryMeterTotal(node);
		node.detail.value = summary.value;
		node.detail.info[0].value = summary.mtd;
	}
};

/**
 * calculate summary total. counting all children with in next level
 * @param {*} tree
 */
export const calculateSummaryTotal = (tree) => {
	const isRoot = tree.ref === null;
	if (isRoot) {
		setSummaryTotal(tree);
	}
	for (let i = 0; i < tree.childs.length; i++) {
		const current = tree.childs[i];
		setSummaryTotal(current);
		calculateSummaryTotal(current);
	}
};

/**
 * filter and transform diagrams for sending request with rest apis
 * @param {*} diagrams get diagrams from BaseFlowDiagramAdjust.vue component
 */
export const transformDiagrams = (diagrams) => {
	const results = diagrams
		.filter((item) => {
			return !item.deletedAt;
		})
		.map((item) => {
			return {
				name: item.input.name,
				tree: item.tree
			};
		});
	return results;
};

// side effect function
export const setNodeLatestDetail = (node, devices) => {
	const { METER, NITRO_FAS, PWR, SOR, GENGAS } = MODULE_TYPES;
	const device = devices.find((item) => node.value === item.id);
	if (node.detail && device) {
		if ([METER, GENGAS].includes(device.type)) {
			node.detail.head.id = getDeviceName(device.shipId, device.deviceCustomerName);
			node.detail.value = getNumeral(device.flowRate, "0,0", "0");
			node.detail.sub.value = device.flowRatePercent;
			node.detail.info[0].value = getNumeral(device.consumptionMTD, "0,0", "-");
		} else if ([NITRO_FAS].includes(device.type)) {
			node.detail.head.id = getDeviceName(device.shipId, device.deviceCustomerName);
			node.detail.value = getNumeral(device.uph, "0,0", "0");
			node.detail.sub.value = device.flowRatePercent;
			node.detail.info[0].value = getNumeral(device.consumptionMTD, "0,0", "-");
		} else if ([PWR, SOR].includes(device.type)) {
			node.detail.head.id = getDeviceName(device.shipId, device.deviceCustomerName);
			node.detail.value = getNumeral(device.kw, "0,0", "0");
			node.detail.info[0].value = getNumeral(device.mtdPower, "0,0", "-");
			node.detail.info[1].value = getNumeral(device.mtdPowerCost, "0,0", "-");
		}
	}
};

// self invoked function
// set detail to every single nodes in the tree
// map with devices list, giving by rest apis
export const getTreeLatestDetails = (tree, devices) => {
	setNodeLatestDetail(tree, devices);
	for (const node of tree.childs) {
		getTreeLatestDetails(node, devices);
	}
	return tree;
};

/**
 * convert diagrams from rest apis to using with our component
 * @param {*} diagrams get diagrams from rest apis
 * @param {*} devices latest devices info from rest apis
 */
export const convertDiagrams = (diagrams, devices, options = {}) => {
	const diagramsJson = JSON.parse(diagrams);
	const defaultOptions = {
		addEmptyNodes: false
	};
	const mergeOptions = {
		...defaultOptions,
		...options
	};
	if (mergeOptions.addEmptyNodes) {
		for (const diagram of diagramsJson) {
			// use side effect function to add empty nodes
			addEmptyNodes(diagram.tree);
		}
	}
	return diagramsJson.map((item) => {
		return {
			id: uuidv4(),
			input: {
				name: item.name
			},
			tree: getTreeLatestDetails(item.tree, devices),
			validateInputFn: null,
			deletedAt: null
		};
	});
};

export const getBody = (input = []) => {
	const filledInput = input.filter((item) => !item.deletedAt);
	const diagrams = pipe(cloneDeep, cleanUpDiagram, transformDiagrams)(filledInput);
	const searchName = input.reduce((acc, cur) => {
		acc.push(cur.input.name);
		return acc;
	}, []).join(", ");
	return { diagrams, searchName, isActive: true };
};

export const isValidDiagrams = (diagrams) => {
	for (const diagram of diagrams) {
		const deleted = diagram.deletedAt;
		if (!deleted && diagram.input.name === null) {
			return false;
		}
		if (!deleted && typeof diagram.input.name === "string" && !diagram.input.name.trim().length) {
			return false;
		}
		if (!deleted && diagram.tree === null) {
			return false;
		}
	}
	return true;
};

export const dashboardSvgString = (value) => {
	const angleStart = 210;
	const angleEnd = 330;
	// maxLength are length number between 210 to 330 degrees
	const maxLength = (360 - angleEnd) + angleStart; // 240
	const angleReduceLength = (maxLength * value) / 100;
	let angle = angleStart - angleReduceLength;
	if (angle <= 0) angle += 360;
	/* end of calculate an angle */
	const cx = 45;
	const cy = 45;
	const radius = 40;
	const radians = -((Math.PI / 180) * angle);
	const pointerX = (Math.cos(radians) * radius) + cx;
	const pointerY = (Math.sin(radians) * radius) + cy;
	/* end of calculate x and y to draw a line position */
	return `
      <svg viewBox="0 0 90 90" width="14" height="12">
        <defs>
          <marker id="helmet" viewBox="0 0 58 58" markerWidth="10" markerHeight="10" refX="15" refY="5" orient="auto">
            <g transform="rotate(90, 10, 10)">
              <path d="M0,6 A1,1 0 1,1 10,6
                      L10,10 L0,10 Z"
                    stroke="none"
                    fill="white" />
              <path d="M5,6 L5,10"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke="#00a8e1" />
            </g>
          </marker>
        </defs>
        <!-- semicircle -->
        <path d="M10,64 A40,40 0 1,1 80,64"
              fill="none"
              stroke-width="8"
              stroke-linecap="round"
              stroke="#f29d59" />
        <!-- pointer -->
        <line id="pointer"
              x1="45" y1="45"
              x2="${pointerX}" y2="${pointerY}"
              marker-end="url(#helmet)"
              stroke-width="6"
              stroke-linecap="round"
              stroke="#00a8e1" />
        <!-- small point on middle of the semicircle -->
        <circle cx="45" cy="45" r="6" fill="#00a8e1" />
      </svg>
    `;
};

export const sevenSegmentSvgString = () => {
	return `
      <svg viewBox="0 0 14 14" width="14" height="14">
        <g fill="none" fill-rule="evenodd">
            <g fill="#D8D8D8" fill-rule="nonzero">
                <g>
                    <g>
                        <g>
                            <path d="M10.46.8c.277 0 .5.224.5.5v.548h.192c.552 0 1 .448 1 1l-.001.212h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v1.3h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v1.3h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v1.3h.549c.276 0 .5.224.5.5 0 .277-.224.5-.5.5h-.549v.192c0 .552-.447 1-1 1l-.191-.001v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-1.3v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-1.3v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-1.3v.549c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5v-.549h-.212c-.552 0-1-.447-1-1v-.191H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-1.3H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-1.3H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-1.3H1.3c-.276 0-.5-.223-.5-.5 0-.276.224-.5.5-.5h.548v-.212c0-.552.448-1 1-1h.212V1.3c0-.276.224-.5.5-.5.277 0 .5.224.5.5v.548h1.3V1.3c0-.276.224-.5.5-.5.277 0 .5.224.5.5v.548h1.3V1.3c0-.276.224-.5.5-.5.277 0 .5.224.5.5v.548h1.3V1.3c0-.276.224-.5.5-.5z" transform="translate(-544 -361) translate(240 222) translate(292 103) translate(12 36)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
      </svg>
    `;
};

export const circleProgressSvgString = (value) => {
	const n = (240 * value) / 100;
	const strokeDashArray = `${n} 360`;
	const strokeWidth = value > 0 ? 4 : 0;
	return `
      <svg viewBox="0 0 80 80" class="rotate" style="transform: rotate(-90deg);" width="12" height="12">
        <circle cx="40"
                cy="40"
                r="38"
                fill="none"
                stroke="#dfece2"
                stroke-width="4"/>
        <circle cx="40"
                cy="40"
                r="38"
                fill="none"
                stroke="#009a44"
                stroke-width="${strokeWidth}"
                stroke-dashoffset="0"
                stroke-dasharray="${strokeDashArray}"
                stroke-linecap="round"/>
      </svg>
    `;
};

export const gengasSvgString = (value) => {
	const n = (261 * value) / 100;
	return `
	  <svg viewBox="0 0 100 100" style="transform: rotate(230deg);" width="12" height="12">
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="8" fill-opacity="0" stroke-linecap="round"  stroke="#62e5f6" style="stroke-dasharray: 211.027px, 289.027px; stroke-dashoffset: 0px;"></path>
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="8" fill-opacity="0" stroke="#009b44" stroke-linecap="round" style="stroke-dasharray: 168.821px, 289.027px; stroke-dashoffset: 0px;"></path>
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="12" stroke="#fff" fill-opacity="0" stroke-linecap="round" style="stroke-dasharray: 0px, 289.027px; stroke-dashoffset: 0px; transform: rotate(${n}deg); transform-origin: center center;"></path>
		<path d="M 50,50 m 0,-46
		  a 46,46 0 1 1 0,92
		  a 46,46 0 1 1 0,-92" stroke-width="8" fill-opacity="0" stroke-linecap="round" stroke="#009b44" style="stroke-dasharray: 0px, 289.027px;stroke-dashoffset: 0px;transform: rotate(${n}deg);transform-origin: center center;"></path>
	  </svg>
	`;
};