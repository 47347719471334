<template>
	<div :class="['main-content', { '-has-message': showMessageDateFilter }]">
		<div class="module-list">
			<!-- Module items -->
			<div class="module-items delivery-lists">

				<BaseLoading
					v-if="isLoading"
					class="devices-lists-loading"
				/>

				<div
					v-if="showMessageDateFilter && !isLoading"
					class="text-date-filter"
				>
					{{ showMessageDateFilter }}
				</div>

				<template v-if="!hasNoOrderList && !isLoading">
					<ModuleListDelivery
						v-for="(item, index) in eOrderingList.data"
						:key="index"
						:initial-is-active="expandFirstRow(index)"
						:view-type="viewType"
						:items="item"
					/>
				</template>

				<EmptyOrderInfomation v-if="hasNoOrderList && !isLoading"/>
			</div>
		</div>

		<BasePagination
			v-if="showPagination.lastPage > 1 && !isLoading"
			:value="showPagination.currentPage"
			:totalPage="showPagination.lastPage"
			class="module-pagination"
			@onPageChange="handlePageChange"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import ModuleListDelivery from "@/components/eOrdering/ModuleListDelivery.vue";
import EmptyOrderInfomation from "@/components/eOrdering/EmptyOrderInfomation.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	name: "EOrderingListView",

	components: {
		BaseLoading,
		EmptyOrderInfomation,
		ModuleListDelivery
	},

	props: {
		viewType: {
			type: String,
			default: "delivery"
		}
	},

	computed: {
		...mapState(["eOrderingList"]),

		hasNoOrderList() {
			return this.eOrderingList.isEmpty;
		},
		isLoading() {
			return this.eOrderingList.isLoading;
		},
		showPagination() {
			return this.eOrderingList.pagination;
		},
		showMessageDateFilter() {
			return this.eOrderingList.message;
		}
	},

	created() {
		this.fetchData();
	},

	methods: {
		...mapActions("eOrderingList", {
			getEOrderingOrderLists: "getEOrderingOrderLists"
		}),

		async fetchData() {
			let dateRange = {
				startDate: this.$route.query.startDate || null,
				endDate: this.$route.query.endDate || null
			};

			if (this.$route.query.filter) {
				dateRange = {
					startDate: null,
					endDate: null
				};
			}

			const params = {
				dateType: this.$route.query.view,
				filter: this.$route.query.filter,
				...dateRange,
				productname: this.$route.query.productname,
				status: this.$route.query.status,
				q: this.$route.query.q,
				page: this.$route.query.page
			};

			await this.getEOrderingOrderLists(params);
		},

		handlePageChange(val) {
			this.$router.push({
				name: PAGE_NAME.E_ORDERING_LIST,
				query: {
					...this.$route.query,
					page: val
				}
			});
		},

		expandFirstRow(val) {
			return val === 0;
		}
	}
};
</script>

<style lang="scss" scoped>
.main-content {
	padding: rem(24) 0;

	&.-has-message {
		padding: 0;
	}
}

.page-meta {
	font-family: $font-family-sub;
	color: $color-grey-2;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: rem(24);

	.group-pagination-sort-table {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.top-module-pagination {
			margin: 0 rem(-6) rem(10) 0;
		}
	}
}

.module-pagination {
	justify-content: flex-end;
	margin-right: rem(-6);
}

.empty-list {
	font-size: $font-20;
	font-weight: 600;
	margin-top: rem(32);
	text-align: center;
}

.text-date-filter {
	font-size: $font-16;
	color: $color-gunmetal-two;
	letter-spacing: rem(0.44);
	margin-bottom: rem(8);
}
</style>
