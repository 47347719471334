<template>
	<div class="reset-password-content">
		<div v-if="!isExpired" class="reset-password-form">
			<h1 class="reset-password-title">Create new password</h1>
			<form ref="form-login">
				<div class="form-group">
					<BaseFormInputPassword
						v-model="newPassword"
						:valid.sync="isValidNewPassword"
						:showIconLeft="false"
						formatType="compact"
						placeholder="New password"
						class="custom-input"
					/>
				</div>
				<div class="form-group">
					<BaseFormInputPassword
						v-model="confirmPassword"
						:valid.sync="isValidConfirmPassword"
						:matchWith="newPassword"
						:showIconLeft="false"
						formatType="compact"
						placeholder="Confirm new password"
						class="custom-input"
					/>
				</div>
				<BaseInfoList
					class="password-list-info"
					:list="infoList"
					:valid.sync="isValidFormat"
				>
					<template v-slot:title>
						In order to <b>protect your account</b>, your password must include:
					</template>
				</BaseInfoList>
				<button
					:disabled="disabledButton"
					type="button"
					class="btn btn-forgot-password"
					@click="onChangePassword"
				>
					Change Password
				</button>
			</form>
		</div>
		<div v-else class="box-notification-wrapper">
			<div class="box-notification-content">
				<div class="content">
					<font-awesome-icon class="icon" :icon="['fas', 'exclamation-circle']"/>
					<h1 class="reset-password-title">The link has expired</h1>
					<p class="reset-password-sub-title">
						The link you are trying to access has expired.
						Try going back to the
						<router-link
							:to="{ name: PAGE_NAME.LOGIN_PAGE }"
							class="btn-link"
						>
							login
						</router-link>
						or
						<router-link
							:to="{ name: PAGE_NAME.PASSWORD_FORGOT }"
							class="btn-link"
						>
							request new link
						</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BaseFormInputPassword from "@/components/BaseFormInputPassword.vue";
import BaseInfoList from "@/components/BaseInfoList.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { getHasLowwerCase, getHasNumber, getHasUpperCase } from "../../selectors/helpers/regex";
import { postResetPassword, postVerifyTokenPassword } from "../../services/api/password.api";

export default {
	name: "forgotPassword",

	components: {
		BaseFormInputPassword,
		BaseInfoList
	},

	data() {
		return {
			token: this.$route.query.token || "",
			isExpired: false,
			newPassword: "",
			confirmPassword: "",
			isValidCurrentPassword: null,
			isValidNewPassword: null,
			isValidConfirmPassword: null,
			isValidFormat: false,
			infoList: [
				{
					text: "8 characters minimum",
					active: false
				},
				{
					text: "1 number (0-9)",
					active: false
				},
				{
					text: "1 lowercase letter (a-z)",
					active: false
				},
				{
					text: "1 uppercase letter (A-Z)",
					active: false
				}
			],
			PAGE_NAME
		};
	},
	async created() {
		try {
			await postVerifyTokenPassword(this.token);
		} catch (e) {
			this.isExpired = true;
		}
	},

	computed: {
		disabledButton() {
			return !this.isValidNewPassword
				|| !this.isValidConfirmPassword
				|| !this.isValidFormat
				|| !this.isMatched;
		},

		isMatched() {
			return this.newPassword === this.confirmPassword;
		}
	},

	watch: {
		newPassword(value) {
			this.infoList[0].active = value.length >= 8;
			this.infoList[1].active = getHasNumber(value);
			this.infoList[2].active = getHasLowwerCase(value);
			this.infoList[3].active = getHasUpperCase(value);
		}
	},


	methods: {

		async onChangePassword() {
			try {
				await postResetPassword(this.token, {
					password: this.newPassword,
					passwordConfirmation: this.confirmPassword
				});

				this.$router.push({
					name: PAGE_NAME.LOGIN_PAGE
				});
			} catch (e) {
				this.isExpired = true;
			}
		}
	},

	destroyed() {
		window.removeEventListener("keypress", this.handleKeyPress);
	}
};
</script>

<style lang="scss" scoped>
.reset-password-content {
	display: flex;
	align-items: center;

	.reset-password-title,
	.reset-password-sub-title {
		text-align: center;
	}

	.reset-password-form {
		background-color: $color-white;
		padding: rem(40);
		width: 100%;
		max-width: rem(470);
		margin: 0 auto;
		border: solid rem(1) $color-silver;
		border-radius: rem(4);
	}

	.box-notification {
		&-wrapper {
			background-color: $color-white;
			width: 100%;
			max-width: rem(470);
			margin: 0 auto;
			border-radius: rem(4);
			min-width: rem(470);
			position: relative;

			&::before {
				content: " ";
				display: block;
				position: absolute;
				width: 100%;
				height: rem(12);
				background-color: $color-light-teal;
				border-radius: rem(4) rem(4) 0 0;
				top: 0;
				left: 0;
			}
		}

		&-content {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: white;
			padding: rem(40);
			border: 1px solid $color-silver;
			border-radius: rem(4) rem(4);
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: rem(-2);
		margin-bottom: rem(16);

		.reset-password-title,
		.reset-password-sub-title {
			margin: 0;
		}

		.btn-link {
			display: contents;
		}

		.icon {
			font-size: rem(70);
			color: $color-gunmetal-two;
		}
	}

	.reset-password-title {
		color: $color-dark-blue-grey;
		margin-bottom: rem(16);
		font-weight: 600;
	}

	.password-list-info {
		margin-bottom: rem(24);
	}

	.reset-password-sub-title {
		color: $color-dark-blue-grey;
		margin-top: rem(10);
		margin-bottom: rem(20);
		font-size: $font-24;
		word-break: break-word;
	}

	.btn-forgot-password {
		width: 100%;
		height: rem(48);
		background-color: $color-azure;
		color: $color-white;

		&:disabled {
			opacity: 0.5;
		}
	}

	.form-group {
		margin-bottom: rem(25);

		.form-control {
			height: rem(50);
		}
	}
		/* stylelint-disable */
	&::v-deep {
		.container-info {
			background-color: $color-white-smoke;
		}
	}
		/* stylelint-enable */
}

.re-forget-password {
	display: flex;
	align-items: center;
	justify-content: center;

	.re-forget-password-message {
		color: $color-grey-4;
	}
}

.redirect-to-login {
	display: flex;
	justify-content: flex-end;
	font-size: rem(18);
}

/* stylelint-disable */
// add amimation to working with `animationstart` event
.custom-input {
	:-webkit-autofill {
		animation-name: onAutoFillStart;
	}

	:not(:-webkit-autofill) {
		animation-name: onAutoFillCancel;
	}

	@keyframes onAutoFillStart {
		from {}
		to {}
	}

	@keyframes onAutoFillCancel {
		from {}
		to {}
	}
}
/* stylelint-enable */
</style>
