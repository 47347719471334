/**
 * This calculate a value as percentage into new maximum scale.
 * For example, a maximum scale of 150 and a value of 20, we would like
 * to know what is the percentage of 20 (value) in 150 (maxScale).
 *
 * @param {number} value - Value to be calculate into new maximum scale.
 * @param {number} maxScale - New maximum scale.
 */
export const calculateValueInScale = (value, maxScale) => {
	return (value * 100) / maxScale;
};

/**
 * This calculate a value as percentage from value, minimum of value and maximum of value
 * @param {number} value
 * @param {number} minimumOfValue
 * @param {number} maximumOfValue
 */
export const calculatePercentageFromMinMaxValue = (value = 0, minimumOfValue = 0, maximumOfValue = 100) => {
	return ((value - minimumOfValue) / (maximumOfValue - minimumOfValue)) * 100;
};