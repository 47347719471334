<template>
	<BaseLoading
		v-if="isLoading"
	/>
	<div
		v-else
		class="container-overall"
	>
		<div
			v-for="(section, index) in data"
			:key="index"
			class="container-overall-section"
		>
			<div class="container-overall-section-inner">
				<div class="container-overall-section-inner-topic">
					{{ section.topic }}
				</div>
				<div class="container-overall-section-inner-info">
					<div
						v-for="(item, _index) in section.items"
						:key="_index"
						class="container-overall-section-inner-info-item"
					>
						<div
							:style="[{
								backgroundColor: item.icon.colorBg
							}]"
							class="container-overall-section-inner-info-item-icon"
						>
							<div class="container-overall-section-inner-info-item-icon-wrapper">
								<div :style="[{
									width: item.icon.width,
									height: item.icon.height,
									top: item.icon.top,
									left: item.icon.left
								}]" class="container-overall-section-inner-info-item-icon-wrapper-outer">
									<FontAwesomeIcon :icon="[item.icon.type, item.icon.name]" />
								</div>
							</div>
						</div>
						<div class="container-overall-section-inner-info-item-title">
							{{ item.title }}
						</div>
						<div class="container-overall-section-inner-info-item-total">
							<div class="container-overall-section-inner-info-item-total-number">
								{{ item.total.number | friendlyNumber }}
							</div>
							<div class="container-overall-section-inner-info-item-total-unit">
								{{ item.total.unit }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";

export default {
	name: "BaseOverallTotal",

	components: {
		BaseLoading
	},

	props: {
		/**
		 * { String } topic
		 * { Array } items
		 * { Object } items.icon
		 * { String } items.title
		 * { Object } items.total
		 */
		data: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.container-overall {
	display: flex;
	height: rem(208);
	border-radius: 8px;
	background-color: $color-dark-blue-grey;

	&-section {
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 0 rem(16);
		align-items: center;
		color: $color-white;

		&::after {
			content: "";
			position: absolute;
			width: rem(1);
			height: 100%;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background-color: $color-gunmetal-two;
		}

		&:last-of-type::after {
			display: none;
		}

		&-inner {
			min-width: rem(136);

			&-topic {
				@include ellipsis(1);
				@include fontSub();

				height: rem(29);
				line-height: rem(29);
				margin-top: rem(32);
				font-size: $font-24;
				font-weight: $font-weight-bold;
			}

			&-info {
				display: flex;

				&-item {
					flex: 1;
					display: flex;
					flex-direction: column;
					min-width: rem(130);
					margin-right: rem(36);

					&:last-of-type {
						margin-right: 0;
					}

					&-icon {
						display: flex;
						justify-content: center;
						align-items: center;
						width: rem(32);
						height: rem(32);
						background-color: $color-baby-green;
						border-radius: 50%;
						margin-top: rem(24);

						&-wrapper {
							display: flex;
							justify-content: center;
							align-items: center;
							width: rem(20);
							height: rem(20);

							&-outer {
								position: relative;
								display: flex;

								svg {
									width: 100%;
									height: 100%;
									color: $color-dark-blue-grey;
								}
							}
						}
					}

					&-title {
						@include ellipsis(1);

						font-size: $font-20;
						margin-top: rem(8);
					}

					&-total {
						@include fontSub();

						display: flex;
						align-items: baseline;
						margin-top: rem(-12);

						&-number {
							font-size: $font-32;
							font-weight: $font-weight-bold;
							margin-right: rem(4);
						}

						&-unit {
							@include ellipsis(1);

							font-size: $font-20;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1299px) {
	.container-overall {
		display: flex;
		flex-direction: column;
		height: auto;
		border-radius: 8px;
		background-color: $color-dark-blue-grey;

		&-section {
			&:first-child {
				border-bottom: 1px solid $color-gunmetal-two;
			}

			&::after {
				display: none;
			}
		}
	}

	.container-overall-section-inner-topic {
		margin: 0;
	}

	.container-overall-section-inner {
		margin: 18px;
	}
}
</style>