<template>
	<BaseLayout :isLoading="loading">
		<BaseBackBtn :to="ALERT_AND_NOTIFICATION_LIST" class="page-back-btn"  label="Back to Alert and notification"/>

		<h2 class="head-title">
			Create new rule
		</h2>

		<BaseCard>
			<template #header>
				<h4>Information</h4>
			</template>
			<template #body>
				<div class="container-form">
					<div class="item">
						<div class="label">
							Module
						</div>
						<div class="form">
							<strong>
								{{ query.moduleTitle }}
							</strong>
						</div>
					</div>
					<div class="item">
						<div class="label">
							Rule name
						</div>
						<div class="form">
							<BaseFormInput
								v-model="ruleName"
								:inputOptions="['small']"
								placeholder="e.g. Module notification"
								inline
							/>
						</div>
					</div>
				</div>
			</template>
		</BaseCard>

		<FormConditionNoSignal
			v-if="isNoSignal"
			:form.sync="noSignalForm"
			:notificationGroupOptions="notificationGroupOptions"
		/>

		<FormConditionSetpoint
			v-if="isSetpoint"
			:form.sync="setPointForm"
			:valid.sync="setPointFormValid"
			:notificationGroupOptions="notificationGroupOptions"
			:addableCondition="addableCondition"
			:initialItemCount="1"
		/>

		<ApplyToDevice
			:applyToData="dataApplyTo"
			:soldToValues="soldToValues"
			:shipToValues="shipToValues"
			:deviceValues="deviceValues"
			:isAutoUpdateShipTo="isAutoUpdateShipTo"
			:isAutoUpdateDevice="isAutoUpdateDevice"
			withApplyToAllShipto
			withApplyToAlldevice
			@handleChangeSoldTo="handleChangeSoldTo"
			@handleChangeShipTo="handleChangeShipTo"
			@handleChangeDevice="handleChangeDevice"
			@handleCheckUpdateShipTo="handleCheckUpdateShipTo"
			@handleCheckUpdateDevice="handleCheckUpdateDevice"
		/>
		<div class="wrapper-actions">
			<BaseButton
				class="btn-cancel"
				size="large"
				type="primary"
				outline
				rounded
				@click="handleCancle"
			>
				Cancel
			</BaseButton>

			<BaseButton
				:disabled="isDisabledCreate"
				size="large"
				type="primary"
				rounded
				@click="handleSubmit"
			>
				Create rule
			</BaseButton>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions } from "vuex";
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import ApplyToDevice from "@/components/layoutCustomisation/ApplyToDevice.vue";
import FormConditionNoSignal from "@/components/AlertAndNotification/FormConditionNoSignal.vue";
import FormConditionSetpoint from "@/components/AlertAndNotification/FormConditionSetpoint.vue";
import { createNotification, getAddableDevice, getAddableCondition } from "../../services/api/notification.api";
import { getAllNotificationGroups } from "../../services/api/notificationGroup.api";
import { TOAST_TYPES } from "../../enums/toast";
import { PAGE_NAME } from "../../enums/pagePermission";

const { ALERT_AND_NOTIFICATION_LIST } = PAGE_NAME;

export default {
	components: {
		BaseBackBtn,
		BaseCard,
		BaseFormInput,
		BaseButton,
		ApplyToDevice,
		FormConditionNoSignal,
		FormConditionSetpoint
	},

	data() {
		return {
			ALERT_AND_NOTIFICATION_LIST,
			// query
			query: {
				type: this.$route.query.type,
				module: this.$route.query.module,
				moduleTitle: this.$route.query.title
			},
			// form
			loading: false,
			noSignalForm: null,
			setPointForm: null,
			setPointFormValid: false,
			ruleName: null,
			soldToValues: [],
			shipToValues: [],
			deviceValues: [],
			// receive data from apis
			dataApplyTo: {
				soldTos: [],
				shipTos: [],
				devices: []
			},
			notificationGroupList: [],
			addableCondition: [],
			// options
			isAutoUpdateShipTo: false,
			isAutoUpdateDevice: false,
			active: true
		};
	},

	async created() {
		this.loading = true;

		if (!this.$route.query.type && !this.$route.query.module) {
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST
			});
		}

		const [
			{ data: dataApplyTo },
			{ data: addableCondition },
			{ data: notificationGroup }
		] = await Promise.all([
			getAddableDevice({ app: this.$route.query.module }),
			getAddableCondition({ app: this.$route.query.module }),
			getAllNotificationGroups()
		]);

		this.dataApplyTo = dataApplyTo;
		this.addableCondition = addableCondition;
		this.notificationGroupList = notificationGroup;
		this.loading = false;
	},

	computed: {
		isNoSignal() {
			return this.query.type === "NoSignal";
		},
		isSetpoint() {
			return this.query.type === "Setpoint";
		},
		isValidForm() {
			if (!this.ruleName) {
				return false;
			}
			if (this.isNoSignal) {
				if (!this.noSignalForm) {
					return false;
				}
				if (!this.noSignalForm.severity) {
					return false;
				}
				if (!this.noSignalForm.heading) {
					return false;
				}
				if (!this.noSignalForm.notificationGroups.length) {
					return false;
				}
			}
			if (this.isSetpoint) {
				if (!this.setPointForm) {
					return false;
				}
				if (!this.setPointForm.severity) {
					return false;
				}
				if (!this.setPointForm.frequency) {
					return false;
				}
				if (!this.setPointFormValid) {
					return false;
				}
				if (!this.setPointForm.queryTimeInSec) {
					return false;
				}
				if (!this.setPointForm.heading) {
					return false;
				}
				if (!this.setPointForm.notificationGroups.length) {
					return false;
				}
			}
			if (!this.deviceValues.length) {
				return false;
			}
			return true;
		},
		isDisabledCreate() {
			return !this.isValidForm;
		},
		notificationGroupOptions() {
			return this.notificationGroupList.map((v) => {
				const title = (() => {
					const channels = {
						email: "Email",
						line: "Line",
						in_app: "In-App"
					};
					const prefix = channels[v.channel] ? `[${channels[v.channel]}]` : `${v.channel}`;
					return `${prefix} ${v.name}`;
				})();
				return {
					id: v.id,
					code: v.id,
					key: title,
					name: title
				};
			});
		}
	},
	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		handleCancle() {
			return this.$router.back();
		},
		handleCheckUpdateShipTo(value) {
			this.isAutoUpdateShipTo = value;
		},
		handleCheckUpdateDevice(value) {
			this.isAutoUpdateDevice = value;
		},
		handleChangeSoldTo(value) {
			this.soldToValues = value;
		},
		handleChangeShipTo(value) {
			this.shipToValues = value;
		},
		handleChangeDevice(value) {
			this.deviceValues = value;
		},
		getParams() {
			if (this.isNoSignal) {
				return {
					name: this.ruleName,
					module: this.query.module,
					type: this.query.type,
					frequency: this.noSignalForm.frequency,
					rules: {
						severity: this.noSignalForm.severity,
						query: 0,
						conditions: [],
						message: {
							heading: this.noSignalForm.heading,
							description: this.noSignalForm.description
						},
						notifyTo: this.noSignalForm.notificationGroups.map((item) => item.id)
					},
					appliedTo: {
						isAutoUpdateDevice: this.isAutoUpdateDevice,
						isAutoUpdateShipTo: this.isAutoUpdateShipTo,
						soldTos: this.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						shipTos: this.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						devices: this.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
					},
					isActive: this.active,
					condition: []
				};
			}
			if (this.isSetpoint) {
				return {
					name: this.ruleName,
					module: this.query.module,
					type: this.query.type,
					frequency: this.setPointForm.frequency,
					rules: {
						severity: this.setPointForm.severity,
						query: Math.floor(this.setPointForm.queryTimeInSec / 60),
						conditions: this.setPointForm.conditions
							.filter((item) => (
								!item.deletedAt
							))
							.map((item) => ({
								field: item.field,
								function: item.function,
								operator: item.operator,
								value1: item.value1,
								value2: item.value2
							})),
						message: {
							heading: this.setPointForm.heading,
							description: this.setPointForm.description
						},
						notifyTo: this.setPointForm.notificationGroups.map((item) => item.id)
					},
					appliedTo: {
						isAutoUpdateDevice: this.isAutoUpdateDevice,
						isAutoUpdateShipTo: this.isAutoUpdateShipTo,
						soldTos: this.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						shipTos: this.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
						devices: this.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
					},
					isActive: this.active
				};
			}
			return {};
		},
		async handleSubmit() {
			try {
				const data = this.getParams();
				await createNotification(data);
				this.$router.push({
					name: PAGE_NAME.ALERT_AND_NOTIFICATION_LIST
				});
				this.showToast({
					type: TOAST_TYPES.LAYOUT_CUSTOMISATION,
					props: { message: `Notification rule create success.`, delay: 6000 }
				});
			} catch (e) {
				this.showToast({
					type: TOAST_TYPES.RESPONSE_ERROR,
					props: { title: "Unable to save", message: "Notification rule unable to save.", delay: 6000 }
				});
			}
		},
		handleChangeStatus(checked) {
			this.active = checked;
		}
	}
};
</script>
<style lang="scss" scoped>
.wrapper-actions {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.page-back-btn {
	margin-bottom: rem(24);
}

.head-title {
	font-family: $font-family-sub;
	font-size: $font-32;
	color: $color-dark-blue-grey;
	margin: 0 0 rem(24) 0;
}

.container-form {
	.item {
		margin-bottom: rem(24);
		display: flex;

		&:last-of-type {
			margin-bottom: 0;
		}

		.label {
			display: flex;
			align-items: center;
			min-width: rem(130);
		}

		.form {
			flex-grow: 1;
		}
	}
}

.custom0wrapper-multi-select {
	margin: 0;
}

.w-200 {
	width: rem(200);
	flex-grow: unset !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-16 {
	margin-bottom: rem(16) !important;
}

.mt-24 {
	margin-top: rem(24) !important;
}
</style>
