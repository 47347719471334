import { deleteStandardModuleAPI, getStandardModuleListAPI } from "@/services/api/standardModule.api";
import { STANDARD_MODULE_LIST_REQUEST, STANDARD_MODULE_LIST_SUCCESS, STANDARD_MODULE_LIST_FAILURE,
	STANDARD_MODULE_DELETE_REQUEST, STANDARD_MODULE_DELETE_SUCCESS, STANDARD_MODULE_DELETE_FAILURE } from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		},
		deleteSuccess: false,
		templateApplied: false
	},

	mutations: {
		[STANDARD_MODULE_LIST_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
		},
		[STANDARD_MODULE_LIST_SUCCESS](state, { data, pagination }) {
			state.data = data;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[STANDARD_MODULE_LIST_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		},
		[STANDARD_MODULE_DELETE_REQUEST](state) {
			state.isLoading = true;
			state.deleteSuccess = false;
			state.templateApplied = false;
		},
		[STANDARD_MODULE_DELETE_SUCCESS](state, { data, status }) {
			state.isLoading = false;
			state.templateApplied = data.templateApplied;
			state.deleteSuccess = status === 204;
		},
		[STANDARD_MODULE_DELETE_FAILURE](state) {
			state.isLoading = false;
			state.deleteSuccess = false;
			state.templateApplied = false;
		}
	},

	actions: {
		async getStandardModuleList({ commit }, params = {}) {
			try {
				commit(STANDARD_MODULE_LIST_REQUEST);
				const { data } = await getStandardModuleListAPI(params);
				commit(STANDARD_MODULE_LIST_SUCCESS, data);
			} catch (error) {
				commit(STANDARD_MODULE_LIST_FAILURE);
			}
		},
		async deleteStandardModule({ commit }, id) {
			try {
				commit(STANDARD_MODULE_DELETE_REQUEST);
				const data = await deleteStandardModuleAPI(id);
				commit(STANDARD_MODULE_DELETE_SUCCESS, data);
			} catch (error) {
				commit(STANDARD_MODULE_DELETE_FAILURE);
			}
		}
	}
};
