/* eslint-disable camelcase */

import dayjs from "dayjs";

export const getTime = (value) => dayjs(value).format("HH:mm");
export const getDate = (value) => dayjs(value).format("DD MMM");

export const transformForecastMarkAreaData = (forecastData) => {
	if (forecastData.length > 0) {
		const lastForecastIndex = forecastData.length - 1;
		const lastTimestampOfLastForecastIndex = forecastData[lastForecastIndex].timestamp.length - 1;
		const firstForecastDate = forecastData?.[0].timestamp[0];
		const lastForecastDate = forecastData?.[lastForecastIndex].timestamp[lastTimestampOfLastForecastIndex];
		const markArea = {
			silent: true, // not trigger when hover mouse (don't need hover state color)
			itemStyle: {
				color: "rgba(223, 236, 226, 0.5)" // forecast background  color
			},
			data: [ // data forecast need 2 Object (First is Start render MarkArea , Second is End render MarkArea)
				[{
					xAxis: firstForecastDate
				},
				{
					xAxis: lastForecastDate
				}]
			]
		};
		return markArea;
	}
	return {};
};

export const transformLineChartData = (state) => {
	const { data = [] } = state;
	const xAxis = [];
	const yAxis = [];
	for (const val of data) {
		for (const { timestamp, level } of val.levels) {
			xAxis.push(timestamp);
			yAxis.push(level || 0);
		}
	}
	return {
		xAxis,
		yAxis
	};
};

export const transformLineChartTankGasConsumptionData = (state, unitValue) => {
	const indexSelectValue = unitValue === "l" ? "kg" : unitValue; // this line for check if client select liter value change to kg value (cuz api send only 3 values -> sm3, nm3, kg)
	const { data = [] } = state;
	const xAxis = [];
	const yAxis = [];
	for (const val of data) {
		val.gasConsumptionRates.forEach((gasConsumptionRate) => {
			xAxis.push(gasConsumptionRate.timestamp);
			yAxis.push(gasConsumptionRate[indexSelectValue] || 0);
		});
	}
	return {
		xAxis,
		yAxis
	};
};

export const transformValueChartData = (state) => {
	const { data = [] } = state;
	const xAxis = [];
	const yAxis = [];
	for (const val of data) {
		for (const { timestamp, value } of val.values) {
			xAxis.push(timestamp);
			yAxis.push(value || 0);
		}
	}
	return {
		xAxis,
		yAxis
	};
};

export const transformLineChartWithStack = (state = []) => {
	const xAxis = [];
	const yAxis = [];
	for (const val of state) {
		for (const { timestamp, value } of val.values) {
			xAxis.push(timestamp);
			yAxis.push(value || 0);
		}
	}
	return {
		xAxis,
		yAxis
	};
};




export const transformRecycleWaterLineChartData = (state) => {
	const { data = [] } = state;

	return data.reduce((acc, { values = [] }) => {
		const transformDate = values.map(({ timestamp }) => {
			return timestamp;
		});
		const transformValue = values.map(({ value }) => {
			return value;
		});

		return {
			xAxis: [
				...acc.xAxis,
				...transformDate
			],
			yAxis: [
				...acc.yAxis,
				...transformValue
			]
		};
	}, {
		xAxis: [],
		yAxis: []
	});
};

export const transformFlowRateLineChartData = (state) => {
	const { data = [] } = state;
	return data.reduce((acc, { flowRates = [] }) => {
		const transformDate = flowRates.map(({ timestamp }) => {
			return timestamp;
		});

		const transformValue = flowRates.map(({ value }) => {
			return value;
		});

		return {
			xAxis: [
				...acc.xAxis,
				...transformDate
			],
			yAxis: [
				...acc.yAxis,
				...transformValue
			]
		};
	}, {
		xAxis: [],
		yAxis: []
	});
};