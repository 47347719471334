import { getGenGasInfoAPI } from "@/services/api/genGas.api";
import { getLayoutTemplate } from "@/services/api/layoutCustomisation.api";
import {
	GENGAS_INFO_REQUEST,
	AUTO_GENGAS_INFO_REQUEST,
	GENGAS_INFO_SUCCESS,
	AUTO_GENGAS_INFO_SUCCESS,
	GENGAS_INFO_FAILURE,
	GENGAS_INFO_CLEAR
} from "../../types";
import { parseGenGasInfo } from "../../../services/api/transforms/parseModule/parseGengasInfo";

export default {
	namespaced: true,

	state: {
		data: {},
		loading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	getters: {
		getLayout(state) {
			return state.data.layout;
		}
	},

	mutations: {
		[GENGAS_INFO_REQUEST](state) {
			state.data = {};
			state.loading = true;
			state.statusCode = null;
		},
		[AUTO_GENGAS_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[GENGAS_INFO_SUCCESS](state, { info: { data, status }, template }) {
			state.data = parseGenGasInfo(data, template);
			state.loading = false;
			state.statusCode = status;
		},
		[AUTO_GENGAS_INFO_SUCCESS](state, { info: { data, status }, template }) {
			state.data = parseGenGasInfo(data, template);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[GENGAS_INFO_FAILURE](state, error) {
			state.data = {};
			state.loading = false;
			state.statusCode = error.response.status;
		},
		[GENGAS_INFO_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async getGenGasInfo({ commit }, { id }) {
			try {
				commit(GENGAS_INFO_REQUEST);
				const response = await getGenGasInfoAPI(id);
				const template = await getLayoutTemplate({
					app: "GENGAS"
				});

				commit(GENGAS_INFO_SUCCESS, {
					info: response,
					template: template.data
				});
			} catch (error) {
				commit(GENGAS_INFO_FAILURE, error);
			}
		},

		async autoGetGenGasInfo({ commit }, { id }) {
			try {
				commit(AUTO_GENGAS_INFO_REQUEST);
				const response = await getGenGasInfoAPI(id);
				const template = await getLayoutTemplate({
					app: "GENGAS"
				});
				commit(AUTO_GENGAS_INFO_SUCCESS, {
					info: response,
					template: template.data
				});
			} catch (error) {
				commit(GENGAS_INFO_FAILURE, error);
			}
		},

		clearGenGasInfo({ commit }) {
			commit(GENGAS_INFO_CLEAR);
		}
	}
};
