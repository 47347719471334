<template>
	<div class="container-action">
		<button
			v-if="textRemove"
			:disabled="isSummited"
			class="view-button mr-auto"
			@click="onRemove"
		>
			{{ textRemove }}
		</button>
		<BaseButton
			class="btn-cancel"
			size="medium"
			type="primary"
			outline
			rounded
			@click="onCancel"
		>
			{{ textCancle }}
		</BaseButton>
		<BaseButton
			:disabled="isSummited || disabled"
			size="medium"
			type="primary"
			rounded
			@click="onSubmit"
		>
			{{ textSubmit }}
		</BaseButton>
	</div>
</template>

<script>
export default {
	props: {
		textSubmit: {
			type: String,
			default: null
		},
		textCancle: {
			type: String,
			default: null
		},
		textRemove: {
			type: String,
			default: null
		},
		isSummited: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		onCancel() {
			this.$emit("cancel");
		},
		onSubmit() {
			this.$emit("submit");
		},
		onRemove() {
			this.$emit("remove");
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action {
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}
</style>