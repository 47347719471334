import { capitalize } from "../../filters";

export const getProductOptions = (productList = []) => {
	const allOption = { value: null, label: "Product type: All" };
	const otherOption = { value: "Other products", label: "Other products" };

	const options = productList.reduce((option, current) => {
		const isDuplicate = option.find(({ searchName }) => searchName === current.searchName);
		const isOtherProduct = current.searchName === "Other products";

		if (!isDuplicate && !isOtherProduct) {
			return option.concat([current]);
		}

		return option;
	}, []).map(({ searchName }) => {
		return { value: searchName, label: capitalize(searchName) };
	});

	options.sort((a, b) => {
		if (a.label < b.label) {
			return -1;
		}
		if (b.label < a.label) {
			return 1;
		}
		return 0;
	});

	return [allOption, ...options, otherOption];
};

export const getOwnersOptions = (ownerList = []) => {
	const options = ownerList.map((option) => {
		// Get rid of BIG\ from owner domain name
		const ownerEmail = option.ownerEmail;
		const label = `${option.ownerInitial} - ${ownerEmail}`;

		return { value: option.id, label };
	});

	const allOption = { value: null, label: "Sales: All" };

	return [allOption, ...options];
};

export const getCompaniesOptions = (companies = []) => {
	const options = companies.map((option) => {
		const companyName = option.companyName;
		const label = `${companyName}`;

		return { value: option.soldTosId, label, products: option.products };
	});
	const allOption = { value: null, label: "Companies: All" };

	return [allOption, ...options];
};

export const getShipToDisplay = (currentPage = 1, perPage, total) => {
	const end = perPage * currentPage;
	const start = end - (perPage - 1);

	if (end > total) {
		return `${start} - ${total}`;
	}

	if (Number(currentPage) === 1) {
		return `1 - ${perPage}`;
	}

	return `${start} - ${end}`;
};