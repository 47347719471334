<template>
	<div>
		<BaseLoading v-if="cerInfo.loading"/>

		<div :class="['row row-eq-height', { 'hide-content': cerInfo.loading }]">
			<div class="col-12 col-xl-7">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ cerInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview :data="cerInfo.data" />
					</template>
				</BaseCard>
			</div>
			<ModuleLocation :info="cerInfo.data" />
		</div>

		<CardLineChart
			:chartData="getWaterFlow"
			:isLoading="waterFlow.isLoading"
			:isEmpty="waterFlow.isEmpty"
			:lastUpdate="waterFlow.lastUpdate"
			:filterOptions="waterFlowFilterOptions"
			chartTitle="Water flow"
			@onSelectDatePicker="handleWaterFlowRateDateSelected"
			@onSelectDropDown="handleWaterFlowRateDateDropDown"
		/>

		<CardBarChart
			:chartData="getMoneySaving"
			:isEmpty="moneySaving.isEmpty"
			:isLoading="moneySaving.isLoading"
			:lastUpdate="moneySaving.lastUpdate"
			:filterOptions="moneySavingFilterOptions"
			chartTitle="Money saving"
			@dateSelected="handleMoneySavingDateSelected"
		/>
		<GrafanaDisplay
			:deviceId="cerId"
		/>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseCard from "@/components/BaseCard.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import { MODULE_SORT_DATE_CHART } from "../enums/filterChart";
import { transformWaterFlowLineChartConfig } from "../selectors/transform/lineChart/WaterFlow/index";
import { transformDataBarChart } from "../selectors/transform/barChart";
import { transformMoneySavingBarChart } from "../selectors/transform/barChart/CerInfo/moneySavingBarChart";
import { getDateNumber, getDateDiff } from "../selectors/helpers";

const { perDay, perThreeDay, perWeek, perTwoWeek, perYear } = MODULE_SORT_DATE_CHART;

export default {
	name: "cerInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		CardLineChart,
		CardBarChart,
		ModuleOverview,
		ModuleLocation
	},

	data() {
		return {
			cerId: this.$route.params.id,
			sortBy: perDay,
			waterFlowFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},

			moneySavingFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			}
		};
	},

	computed: {
		...mapState(["cerInfo", "waterFlow", "moneySaving"]),

		...mapGetters("waterFlow", {
			getTransformWaterFlowData: "getTransformWaterFlowData"
		}),

		waterFlowSelected() {
			const dateRange = this.waterFlowFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);

			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		moneySavingSelected() {
			const dateRange = this.moneySavingFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},

		getWaterFlow() {
			const sortBy = this.sortBy;
			const { diff } = this.waterFlowSelected;
			return transformWaterFlowLineChartConfig(sortBy, this.getTransformWaterFlowData, {
				diffDay: diff
			});
		},

		getMoneySaving() {
			// get data from vuex and transform
			const dateSort = this.moneySavingSelected.dateNumber;
			const dateReportFormat = [perWeek, perTwoWeek].includes(dateSort) ? "day" : "year";
			const options = { dateReportFormat };
			const xyAxis = transformDataBarChart(this.moneySaving, options);
			return transformMoneySavingBarChart(xyAxis);
		}
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("waterFlow", {
			fetchWaterFlow: "fetchWaterFlow"
		}),

		...mapActions("moneySaving", {
			fetchMoneySaving: "fetchMoneySaving"
		}),

		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),

		fetchData() {
			const { cerId } = this;
			return Promise.all([
				this.fetchWaterFlow({ id: cerId, query: { filter: this.waterFlowSelected.dateNumber } }),
				this.fetchMoneySaving({ id: cerId, filterBy: this.moneySavingSelected.dateNumber }),
				this.addActivityLogShipToView(this.cerId)
			]);
		},

		handleWaterFlowRateDateSelected(date) {
			const { start, end } = date;

			this.waterFlowFilterOptions.date.selected.range = {
				start,
				end
			};

			this.sortBy = "";
			this.fetchWaterFlow({
				id: this.cerId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},

		handleWaterFlowRateDateDropDown(filterBy) {
			this.sortBy = filterBy;
			this.fetchWaterFlow({
				id: this.cerId,
				query: { filter: filterBy }
			});
		},

		handleMoneySavingDateSelected(date) {
			const { cerId } = this;

			this.moneySavingFilterOptions.date.selected.range = date;

			this.fetchMoneySaving({
				id: cerId,
				filterBy: this.moneySavingSelected.dateNumber
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem($spacing-7);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^=col]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}
</style>
