<template>
	<BaseCardIcon color="none" class="no-result-container">
		<template v-slot:icon>
			<div class="bg-icon">
				<SquareDashedIcon v-if="icon === 'square-dashed'"></SquareDashedIcon>
				<ChartColumnIcon v-if="icon === 'chart-column'"></ChartColumnIcon>
				<FontAwesomeIcon v-else :icon="['far', icon]" />
			</div>
		</template>
		<template v-slot:head>
			<div class="no-result-to-head">
				{{title}}
			</div>
		</template>
		<template v-slot:body>
			<div class="no-result-to-body">
				{{description}}
			</div>
		</template>
	</BaseCardIcon>
</template>

<script>
import BaseCardIcon from "@/components/BaseCardIcon.vue";
import SquareDashedIcon from "@/components/icon/SquareDashedIcon.vue";
import ChartColumnIcon from "@/components/icon/ChartColumnIcon.vue";

export default {
	name: "EmptyResult",

	components: {
		BaseCardIcon,
		SquareDashedIcon,
		ChartColumnIcon
	},

	props: {
		icon: {
			type: String,
			default: "search"
		},

		title: {
			type: String,
			default: "No matching search results"
		},

		description: {
			type: String,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
.no-result-container {
	margin-top: rem(66);
	margin-left: auto;
	margin-right: auto;

	.no-result-to-head {
		font-size: rem(48);
		color: $color-grey-3;
	}

	.no-result-to-body {
		font-size: rem(20);
	}

	.bg-icon {
		background-color: $color-ice-two;
		padding: rem(22);
		border-radius: 50%;
	}

	.icon-wrapper svg {
		width: rem(60);
		height: rem(60);
		fill: $color-green-haze;
	}
}
</style>