<template>
	<div>
		<table class="consumption-approval-table">
			<thead>
				<tr class="consumption-approval-section-header">
					<template v-for="({ name, show }, index) in headers">
						<th
							class="consumption-approval-header-container"
							:key="name + index"
						>
							<div
								v-show="show"
								:class="['consumption-approval-header', name]"
							>
								{{ name | capitalize }}
							</div>
						</th>
					</template>
				</tr>
			</thead>
			<tbody>
				<template v-for="(item, index) in data">
					<tr
						class="consumption-approval-data-row"
						:key="item.head+'-'+index"
					>
						<template v-for="({ name }, index) in headers">
							<td
								:key="`data-${name}-${index}`"
								:class="['consumption-approval-data', name]"
							>
								<div v-if="name==='head'">
									{{ item[name] }}
									<div
										v-if="item.hint"
										class="hint"
									>
										{{ item.hint }}
									</div>
								</div>
								<div v-else class="data-value">
									{{ item[name] | currency }}
								</div>
							</td>
						</template>

					</tr>
				</template>
			</tbody>
		</table>
		<div class="sectiom-total-consumption">
			<h4 class="total-consumption-describe">
				Total consumption
			</h4>
			<BaseTooltip
				message="Total consumption is the sum of ‘actual consumption’ (bases + premiums) listed above"
				width="210px"
				class="total-consumption-tooltip"
			>
				<font-awesome-icon :icon="['far', 'info-circle']" class="icon-info" />
			</BaseTooltip>
			<div class="total-consumption-sumary">
				{{ totalConsumption | currency }}
			</div>
		</div>
	</div>
</template>

<script>
import BaseTooltip from "@/components/BaseTooltip.vue";

export default {
	components: {
		BaseTooltip
	},
	props: {
		headers: {
			type: Array,
			default: () => ([])
		},
		data: {
			type: Array,
			default: () => ([])
		},
		totalConsumption: {
			type: String,
			default: () => ("0")
		}
	}
};
</script>

<style lang="scss" scoped>
.consumption-approval {
	&-table {
		width: 100%;
		margin-bottom: rem(32);
	}

	&-section-header {
		border-bottom: 1px solid $color-emerald;
	}

	&-header-container {
		:first-child {
			padding-left: rem(16);
		}

		:last-child {
			padding-right: rem(16);
		}
	}

	&-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		&::before {
			content: " ";
			display: block;
			margin-right: rem(4);
			width: rem(6);
			height: rem(6);
			border-radius: 50%;
			background-color: salmon;
		}

		&.base1 {
			&::before {
				background-color: $color-emerald;
			}
		}

		&.base2 {
			&::before {
				background-color: $color-cool-green;
			}
		}

		&.base3 {
			&::before {
				background-color: $color-lightish-green;
			}
		}

		&.premium1 {
			&::before {
				background-color: $color-dull-orange;
			}
		}


		&.premium2 {
			&::before {
				background-color: $color-pastel-orange;
			}
		}

		&.premium3 {
			&::before {
				background-color: $color-pale-peach;
			}
		}
	}

	&-data-row {
		&:nth-child(2) {
			border-bottom: 1px solid $color-silver;
		}

		&:nth-child(3) {
			.head {
				color: $color-dark-blue-grey;
			}

			.consumption-approval-data {
				padding-bottom: rem(32);

				.data-value {
					margin-top: rem(-24);
				}
			}

			color: $color-dark-blue-grey;
			font-weight: $font-weight-bold;
		}


		&:nth-child(4) {
			border-bottom: 2px solid $color-gunmetal-two;
		}

		&:nth-child(5) {
			.head {
				color: $color-dark-blue-grey;
			}

			padding-top: rem(16);
			color: $color-dark-blue-grey;
			font-weight: $font-weight-bold;
		}
	}

	&-data {
		@include fontBase();

		color: $color-dark-blue-grey;
		padding: rem(8) 0;
		text-align: right;

		&.head {
			font-size: rem(20);
			font-weight: $font-weight-bold;
			color: $color-grey-4;
			text-align: left;
		}

		:first-child {
			padding-left: rem(16);
		}

		:last-child {
			padding-right: rem(16);
		}

		.hint {
			padding: 0;
			font-size: rem(16);
			color: $color-grey-4;
			font-weight: $font-weight-regular;
		}
	}
}

.sectiom-total-consumption {
	@include fontSub();

	display: flex;
	width: 100%;
	height: rem(45);
	background-color: $color-ice-two;
	justify-content: flex-end;
	align-items: center;
	padding: 0 rem(24);
	font-size: rem(24);

	.total-consumption {
		&-describe {
			margin-right: rem(6);
		}

		&-tooltip {
			font-weight: $font-weight-regular;
			margin-right: rem(16);
			margin-top: rem(4);
			font-size: rem(14);
			color: $color-gunmetal-two;
			cursor: pointer;

			&:hover {
				color: $color-dark-blue-grey;
			}
			/* stylelint-disable */
			&::v-deep {
				.container-tooltip-popover {
					right: rem(216);
					bottom: rem(120);
					word-break: break-word;
				}
			}
			/* stylelint-enable */
		}

		&-sumary {
			color: $color-emerald;
			font-weight: $font-weight-bold;
		}
	}
}
</style>