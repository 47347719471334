<template>
	<div v-if="showCookiePolicy" class="notice-cookie-policy-container">
		<div class="notice-cookie-policy">
			<p class="notice-cookie-policy-info">
				We use cookies to give you a better experience on <a class="notice-cookie-policy-info-link" href="/">{{ url }}</a>. By continuing to use our website, you are agreeing to the use of cookies as set out in our <a href="https://www.bigth.com/privacy-policy" class="notice-cookie-policy-info-link" target="_blank">Privacy Statement.</a>
			</p>
			<BaseButton
				class="notice-cookie-policy-accept-button"
				size="small"
				type="secondary"
				@click="handleCookieAccept"
			>
				Accept
			</BaseButton>
		</div>
	</div>
</template>

<script>
import { COOKIES_NAME } from "../enums/cookieName";

const { NOTICE_POLICY_KEY, NOTICE_POLICY_VALUE } = COOKIES_NAME;

export default {
	name: "NoticeCookiePolicy",

	data() {
		return {
			showCookiePolicy: false,
			url: window.location.hostname
		};
	},

	mounted() {
		this.showCookiePolicy = !this.getWithExpiry(NOTICE_POLICY_KEY);
	},

	methods: {
		handleCookieAccept() {
			const now = new Date();
			// `item` is an object which contains the original value
			// as well as the time when it's supposed to expire
			// 2678400 is 31days
			const item = {
				value: NOTICE_POLICY_VALUE,
				expiry: now.getTime() + 2678400
			};
			localStorage.setItem(NOTICE_POLICY_KEY, JSON.stringify(item));
			this.showCookiePolicy = false;
		},
		getWithExpiry(key) {
			const itemStr = localStorage.getItem(key);
			// if the item doesn't exist, return null
			if (!itemStr) {
				return null;
			}
			const item = JSON.parse(itemStr);
			const now = new Date();
			// compare the expiry time of the item with the current time
			if (now.getTime() > item.expiry) {
				// If the item is expired, delete the item from storage
				// and return null
				localStorage.removeItem(key);
				return null;
			}
			return item.value;
		}
	}
};
</script>

<style lang="scss" scoped>
.notice-cookie-policy-container {
	position: fixed;
	z-index: $z-index-notice-cookie-policy;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $color-white;
	box-shadow: $box-shadow-tooltip;
}

.notice-cookie-policy {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem(12);
	margin: 0 auto;
	max-width: rem(680);
}

.notice-cookie-policy-info {
	margin: 0;
	margin-right: rem(24);
	max-width: rem(580);
	font-size: $font-16;
	line-height: 16px;
	letter-spacing: 0;
}

.notice-cookie-policy-info-link {
	color: $color-azure;
	font-weight: bold;
}

.notice-cookie-policy-accept-button {
	/* stylelint-disable */
	&::v-deep {
		&.btn {
			padding: 0 rem(12);
			min-width: 74px;
		}
	}
	/* stylelint-enable */
}
</style>
