import { required, email } from "vuelidate/lib/validators";
import { getvalidateMessage, checkIfValid } from "../validateHelper";
import { STAFF_ROLES } from "../../../enums/role";

export default {
	validations: {
		email: {
			required,
			format(value) {
				return email(value);
			}
		},

		systemRole: {
			required
		},

		soldToValues: {
			required(value) {
				return value.length > 0;
			}
		},
		shipToValues: {
			required(value) {
				return value.length > 0;
			}
		},
		applicationValues: {
			required(value) {
				return value.length > 0;
			}
		},
		deviceValues: {
			required(value) {
				return value.length > 0;
			}
		}
	},
	methods: {
		checkIfValid(name) {
			const field = this.$v[name];
			if (!field.$dirty) {
				return null;
			}

			if (!("required" in field) && !field.$error) {
				return null;
			}

			return (field.$invalid || field.$error);
		},
		validatePermissionForm() {
			this.$v.$touch();
		},
		handleSubmit(callback) {
			// do not check permission form for `SALES` role
			if (this.$v.systemRole.$model === STAFF_ROLES.SALES) {
				this.validateBasicForm();
				callback();
			} else {
				this.validateBasicForm();
				this.validatePermissionForm();
				if (!this.$v.$invalid) {
					callback();
				}
			}
		}
	},
	computed: {
		isSoldToError() {
			const field = this.$v.soldToValues;
			return checkIfValid(field);
		},

		getSoldToErrorMessage() {
			const field = this.$v.soldToValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isShipToError() {
			const field = this.$v.shipToValues;
			return checkIfValid(field);
		},

		getShipToErrorMessage() {
			const field = this.$v.shipToValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isApplicationError() {
			const field = this.$v.applicationValues;
			return checkIfValid(field);
		},

		getApplicationErrorMessage() {
			const field = this.$v.applicationValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isDeviceError() {
			const field = this.$v.deviceValues;
			return checkIfValid(field);
		},

		getDeviceErrorMessage() {
			const field = this.$v.deviceValues;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isEmailError() {
			const field = this.$v.email;
			return checkIfValid(field);
		},

		isSystemRoleError() {
			const field = this.$v.systemRole;
			return checkIfValid(field);
		}
	}
};