import dayjs from "dayjs";
import { transformLineChart } from "../../lineChart";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import {
	getForEachDay,
	getForEachSixHours,
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachTwoHours,
	getForEachThreeDay,
	getForEachFiveDay,
	getForEachTwelveHours,
	xAxisLabelWithOutTime
} from "../../lineChart/helper";
import { yAxisConfig } from "../../lineChart/helper/yAxisConfig";
import { computeMarkLineConfig, computeMarkLineLegendConfig } from "../markLineTransform";
import { getConfigDataZoom } from "../helper/zoomConfig";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export const tooltipTankLineChartFormat = (name, value) => {
	const format = `
			<div>
				<div class="bar-name">
					${name}
				</div>
				<div class="bar-value">
					${value === null ? "-" : value} inch water
				</div>
			</div>`;
	return format;
};

const computeTankMarkLine = computeMarkLineConfig({
	lineStyle: {
		type: "dotted"
	}
});

const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipTankLineChartFormat(date, pointValue.data);
};

const optionTankLineChartXaxisPerWeek = ({ markLine = {}, markArea = {} }, { xAxis }, isMobile = false) => ({
	yAxisText: "Tank level (inch water)",
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachTwelveHours
	},

	grid: {
		left: "16px",
		right: "16px",
		bottom: 76,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeTankMarkLine(markLine),
		markArea
	},
	...computeMarkLineLegendConfig(markLine, isMobile)
});

const optionTankLineChartXaxisPerThreeDay = ({ markLine = {}, markArea = {} }, { xAxis }, isMobile = false) => ({
	yAxisText: "Tank level (inch water)",
	axisLabel: {
		padding: [0, 0, 10, 0],
		formatter: axisXLabelFormatter,
		interval: isMobile ? getForEachTwelveHours : getForEachFourHours,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachSixHours
	},

	grid: {
		left: "16px",
		right: "16px",
		bottom: 76,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeTankMarkLine(markLine),
		markArea
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

const optionTankLineChartXaxisPerDay = ({ markLine = {}, markArea = {} }, { xAxis }, isMobile = false) => ({
	yAxisText: "Tank level (inch water)",
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: isMobile ? getForEachFourHours : getForEachTwoHours,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: () => true
	},


	grid: {
		left: "16px",
		right: "16px",
		bottom: 76,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeTankMarkLine(markLine),
		markArea
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

const optionTankLineChartMoreThanTwentyDay = ({ markLine = {}, markArea = {} }, { xAxis }, isMobile = false) => ({
	yAxisText: "Tank level (inch water)",
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachThreeDay,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachThreeDay
	},

	grid: {
		left: "16px",
		right: "16px",
		bottom: 76,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeTankMarkLine(markLine),
		markArea
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

const optionTankLineChartThreeMonths = ({ markLine = {}, markArea = {} }, { xAxis }, isMobile = false) => ({
	yAxisText: "Tank level (inch water)",
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachFiveDay,
		rotate: isMobile ? 45 : 0
	},

	yAxisConfig,
	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachFiveDay
	},

	grid: {
		left: "16px",
		right: "16px",
		bottom: 76,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		...computeTankMarkLine(markLine),
		markArea
	},
	...computeMarkLineLegendConfig(markLine, isMobile)

});

export const transformTankLevelLineChartConfig = (sortBy = perDay, data = [], option = {}, isMobile = false) => {
	const { diffDay } = option;
	const mappingOption = {
		[perDay]: optionTankLineChartXaxisPerDay(option, data, isMobile),
		[perThreeDay]: optionTankLineChartXaxisPerThreeDay(option, data, isMobile),
		[perWeek]: optionTankLineChartXaxisPerWeek(option, data, isMobile),
		[perThirtyDays]: optionTankLineChartMoreThanTwentyDay(option, data, isMobile),
		[perNinetyDays]: optionTankLineChartThreeMonths(option, data, isMobile)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionTankLineChartMoreThanTwentyDay(option, data, isMobile);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}

	const functionTransform = mappingOption[sortBy] || optionTankLineChartXaxisPerWeek(option, data, isMobile);
	const transformFunc = transformLineChart(functionTransform);

	return transformFunc(data);
};
