
import { getMeterOrderHistory } from "@/services/api/meters.api";
import {
	METER_ORDER_HISTORY_REQUEST,
	METER_ORDER_HISTORY_SUCCESS,
	METER_ORDER_HISTORY_FAILURE
} from "../../types";
import { transformMeterHistoryHeader, transformMeterHistoryItems } from "../../../selectors/transform/meterHistory";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		statusCode: null,
		isEmpty: false,
		totalPage: 1
	},

	getters: {
		getHeaderHistory(state) {
			const result = transformMeterHistoryHeader(state);
			return result;
		},
		getItemsHistory(state) {
			const result = transformMeterHistoryItems(state);
			return result;
		}
	},

	mutations: {
		[METER_ORDER_HISTORY_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.statusCode = null;
			state.isEmpty = false;
		},
		[METER_ORDER_HISTORY_SUCCESS](state, resp) {
			const response = resp.data;
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
			state.statusCode = response.status;
			state.totalPage = response.pagination.lastPage;
		},
		[METER_ORDER_HISTORY_FAILURE](state, error) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.statusCode = error.response.status;
		}
	},

	actions: {
		async fetchMeterOrderHistory({ commit }, { id = "", page = "" }) {
			try {
				commit(METER_ORDER_HISTORY_REQUEST);

				const response = await getMeterOrderHistory(id, page);

				commit(METER_ORDER_HISTORY_SUCCESS, response);
			} catch (error) {
				commit(METER_ORDER_HISTORY_FAILURE, error);
			}
		}
	}
};
