import httpClient from "./httpClient";

const MAINTENANCE_END_POINT = "/maintenance";

const getMaintenanceStatusAPI = () => httpClient.get(`${MAINTENANCE_END_POINT}`);
const postMaintenanceStatusAPI = (params) => httpClient.post(`${MAINTENANCE_END_POINT}`, params);

export {
	getMaintenanceStatusAPI,
	postMaintenanceStatusAPI
};
