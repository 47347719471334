<template>
	<div class="meter-container">
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<template v-else>
			<BaseLoading v-if="meterOrderHistory.isLoading" />
			<MeterOrderHistoryList v-else :headColumns="getHeaderHistory" :items="getItemsHistory"/>
			<BasePagination
				v-if="meterOrderHistory.totalPage > 1 && !meterOrderHistory.isLoading"
				:value="page"
				:totalPage="meterOrderHistory.totalPage"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>
		</template>

	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";

import MeterOrderHistoryList from "@/components/dashboard/modules/MeterOrderHistory/MeterOrderHistoryList.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";

export default {
	name: "meterOrder",

	components: {
		MeterOrderHistoryList,
		BaseLoading,
		EmptyOrderHistory
	},

	data() {
		return {
			meterId: this.$route.params.id,
			page: Number(this.$route.query.page) || 1
		};
	},

	computed: {
		...mapState(["meterOrderHistory"]),
		...mapGetters("meterOrderHistory", {
			getHeaderHistory: "getHeaderHistory",
			getItemsHistory: "getItemsHistory"
		}),

		isEmpty() {
			return this.meterOrderHistory.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.meterId,
			httpStatus: this.meterOrderHistory.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions({ fetchMeterOrderHistory: "meterOrderHistory/fetchMeterOrderHistory" }),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		fetchData() {
			return this.fetchMeterOrderHistory({ id: this.meterId, page: this.page });
		},

		handlePageChange(page = 1) {
			this.fetchMeterOrderHistory({
				id: this.meterId,
				page
			});

			this.page = page;
			this.$router.replace({ query: { page } });
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.meter-container {
	@include mobile {
		padding-top: rem(32);
	}
}
</style>