/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */

export const MODAL_NAME = Object.freeze({
	MODAL_NAME_USER_PROFILE: "MODAL_NAME_USER_PROFILE",
	MODAL_NAME_CHANGE_PASSWORD: "MODAL_NAME_CHANGE_PASSWORD",
	MODAL_REQUEST_CHANGE_PASSWORD: "MODAL_REQUEST_CHANGE_PASSWORD",
	MODAL_COMPANY_OVERVIEW: "MODAL_COMPANY_OVERVIEW",
	MODAL_COMPANY_OVERVIEW_MOBILE: "MODAL_COMPANY_OVERVIEW_MOBILE",
	MODAL_BUSINESS_PHILOSOPHY: "MODAL_BUSINESS_PHILOSOPHY",
	MODAL_BUSINESS_PHILOSOPHY_MOBILE: "MODAL_BUSINESS_PHILOSOPHY_MOBILE",
	MODAL_CONTECT_BIGTH: "MODAL_CONTECT_BIGTH",
	MODAL_CONTACT_US: "MODAL_CONTACT_US",
	MODAL_ENABLE_MAINTENANCE_MODE: "MODAL_ENABLE_MAINTENANCE_MODE",
	MODAL_REQUEST_EXAMPLE_MODULE: "MODAL_REQUEST_EXAMPLE_MODULE"
});
