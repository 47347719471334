import httpClient from "./httpClient";

const FLOW_DIAGRAM = "/flow-diagrams";

const getFlowDiagramListAPI = (params) => httpClient.get(`${FLOW_DIAGRAM}`, { params });
const getFlowDiagramSingleAPI = (id) => httpClient.get(`${FLOW_DIAGRAM}/${id}`);
const postFlowDiagramAPI = (id, data) => httpClient.post(`${FLOW_DIAGRAM}/${id}`, data);
const putFlowDiagramAPI = (id, data) => httpClient.put(`${FLOW_DIAGRAM}/${id}`, data);
const deleteFlowDiagramAPI = (id) => httpClient.delete(`${FLOW_DIAGRAM}/${id}`);
const getFlowDiagramDeviceListAPI = (id, params) => httpClient.get(`${FLOW_DIAGRAM}/${id}/devices`, { params });
const getFlowDiagramShipToAPI = (params) => httpClient.get(`${FLOW_DIAGRAM}/ship-tos`, { params });

export {
	getFlowDiagramListAPI,
	getFlowDiagramSingleAPI,
	postFlowDiagramAPI,
	putFlowDiagramAPI,
	deleteFlowDiagramAPI,
	getFlowDiagramDeviceListAPI,
	getFlowDiagramShipToAPI
};
