import { getMonitorProductTypeAPI } from "@/services/api/monitor.api";
import {
	OVER_ALL_PRODUCT_TYPE_REQUEST,
	OVER_ALL_PRODUCT_TYPE_SUCCESS,
	OVER_ALL_PRODUCT_TYPE_FAILURE
} from "../../types";
import { parseProductType } from "../../../services/api/transforms/parseProductType";
import { sorterArray } from "../../../selectors/helpers";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false
	},

	getters: {
		mapProductType: ({ data }) => {
			const initialHeadCols = [
				{ title: "", subtitle: "" },
				{ title: "Total", subtitle: "(unit)" }
			];

			const headCols = Object.keys(data).reduce((acc, cur) => {
				const moduleName = cur;
				acc.push({
					title: moduleName,
					subtitle: "(unit)"
				});
				return acc;
			}, initialHeadCols);

			// we have to sort product names following from design.
			const sortOrderList = [
				"Oxygen",
				"Hydrogen",
				"Nitrogen",
				"Carbon Dioxide",
				"Argon",
				"Energy",
				"Other products"
			];

			const productsName = sorterArray(Object.keys(data).reduce((acc, cur) => {
				for (const item of data[cur]) {
					if (!acc.includes(item.product)) {
						acc.push(item.product);
					}
				}
				return acc;
			}, []), sortOrderList);

			const bodyRows = productsName.reduce((acc, cur) => {
				const moduleName = cur;

				const total = Object.keys(data).reduce((_acc, _cur) => {
					const count = data[_cur].reduce((__acc, __cur) => {
						let _count = 0;
						if (__cur.product === moduleName) {
							_count += __cur.total;
						}
						return __acc + _count;
					}, 0);
					return _acc + count;
				}, 0);

				const initialCols = [
					moduleName,
					total
				];

				const cols = Object.keys(data).reduce((_acc, _cur) => {
					data[_cur].forEach((item) => {
						if (item.product === moduleName) {
							_acc.push(item.total);
						}
					});
					return _acc;
				}, initialCols);

				return acc.push(cols) && acc;
			}, []);

			return {
				headCols,
				bodyRows
			};
		}
	},

	mutations: {
		[OVER_ALL_PRODUCT_TYPE_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
		},
		[OVER_ALL_PRODUCT_TYPE_SUCCESS](state, data) {
			state.data = parseProductType(data);
			state.isLoading = false;
		},
		[OVER_ALL_PRODUCT_TYPE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		}
	},

	actions: {
		async getProductType({ commit }) {
			try {
				commit(OVER_ALL_PRODUCT_TYPE_REQUEST);
				const { data } = await getMonitorProductTypeAPI();
				commit(OVER_ALL_PRODUCT_TYPE_SUCCESS, data);
			} catch (error) {
				commit(OVER_ALL_PRODUCT_TYPE_FAILURE);
			}
		}
	}
};
