import { getSorHistoryAPI } from "@/services/api/sor.api";
import { SOR_HISTORY_REQUEST, SOR_HISTORY_SUCCESS, SOR_HISTORY_FAILURE } from "../../types";
import { parsePowerHistories } from "../../../services/api/transforms/parseModules";
import { parsePagination } from "../../../services/api/transforms/parsePagination";

export default {
	namespaced: true,

	state: {
		data: parsePowerHistories([]),
		pagination: parsePagination({}),
		loading: true,
		statusCode: null,
		empty: false
	},

	mutations: {
		[SOR_HISTORY_REQUEST](state) {
			state.data = parsePowerHistories([]);
			state.loading = true;
			state.empty = false;
			state.statusCode = null;
		},
		[SOR_HISTORY_SUCCESS](state, { data: result, status }) {
			state.data = parsePowerHistories(result.data);
			state.pagination = parsePagination(result.pagination);
			state.loading = false;
			state.empty = !state.data.length;
			state.statusCode = status;
		},
		[SOR_HISTORY_FAILURE](state, error) {
			state.data = parsePowerHistories([]);
			state.loading = false;
			state.empty = false;
			state.statusCode = error.response.status;
		}
	},

	actions: {
		async getSorHistory({ commit }, { id, params }) {
			try {
				commit(SOR_HISTORY_REQUEST);
				const response = await getSorHistoryAPI(id, params);
				commit(SOR_HISTORY_SUCCESS, response);
			} catch (error) {
				commit(SOR_HISTORY_FAILURE, error);
			}
		}
	}
};
