<template>
	<div class="no-gutter-top">
		<BaseLoading v-if="powerInfo.isLoading" />
		<div
			:class="[
				'row',
				{ 'row-eq-height': powerInfo.data.layout.shipToLocation },
				{ 'hide-content': powerInfo.isLoading }
			]"
		>
			<div :class="['col-12 no-gutter-sm', { 'col-xl-7': powerInfo.data.layout.shipToLocation }]">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ powerInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview
							v-if="powerInfo.data.id"
							:data="powerInfo.data"
						/>
						<BaseNoData
							v-else
							description="BIG portal will start showing the overview right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
			<ModuleLocation
				v-if="powerInfo.data.layout.shipToLocation"
				:info="powerInfo.data"
			/>
		</div>
		<TrendChart
			v-if="powerInfo.data.layout.powerTrend"
			:data="powerTrend.detail.data"
			:updatedAt="powerTrend.detail.updatedAt"
			:loading="powerTrend.loading"
			:empty="powerTrend.empty"
			@filter="handleFilterTrend"
		/>
		<PeakDemandChart
			v-if="powerInfo.data.layout.peakDemand"
			:data="powerPeakDemand.detail.data"
			:updatedAt="powerPeakDemand.detail.updatedAt"
			:loading="powerPeakDemand.loading"
			:empty="powerPeakDemand.empty"
			@filter="handleFilterPeakDemand"
		/>
		<VoltageChart
			v-if="powerInfo.data.layout.voltage"
			:data="powerVoltage.detail.data"
			:updatedAt="powerVoltage.detail.updatedAt"
			:loading="powerVoltage.loading"
			:empty="powerVoltage.empty"
			@filter="handleFilterVoltage"
		/>
		<CurrentChart
			v-if="powerInfo.data.layout.current"
			:data="powerCurrent.detail.data"
			:updatedAt="powerCurrent.detail.updatedAt"
			:loading="powerCurrent.loading"
			:empty="powerCurrent.empty"
			@filter="handleFilterCurrent"
		/>
		<ConsumptionChart
			v-if="powerInfo.data.layout.consumption"
			:data="powerConsumption.detail.data"
			:updatedAt="powerConsumption.detail.updatedAt"
			:loading="powerConsumption.loading"
			:empty="powerConsumption.empty"
			@filter="handleFilterConsumption"
		/>
		<GrafanaDisplay
			:deviceId="id"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import TrendChart from "@/components/PwrModule/TrendChart.vue";
import PeakDemandChart from "@/components/PwrModule/PeakDemandChart.vue";
import VoltageChart from "@/components/PwrModule/VoltageChart.vue";
import CurrentChart from "@/components/PwrModule/CurrentChart.vue";
import ConsumptionChart from "@/components/PwrModule/ConsumptionChart.vue";

export default {
	name: "powerInfo",

	data() {
		return {
			id: this.$route.params.id
		};
	},

	components: {
		GrafanaDisplay,
		BaseCard,
		BaseNoData,
		ModuleOverview,
		ModuleLocation,
		TrendChart,
		PeakDemandChart,
		VoltageChart,
		CurrentChart,
		ConsumptionChart
	},

	computed: {
		...mapState(["powerInfo", "powerTrend", "powerPeakDemand", "powerVoltage", "powerCurrent", "powerConsumption"])
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("powerTrend", {
			getPowerTrend: "getPowerTrend"
		}),
		...mapActions("powerPeakDemand", {
			getPowerPeakDemand: "getPowerPeakDemand"
		}),
		...mapActions("powerVoltage", {
			getPowerVoltage: "getPowerVoltage"
		}),
		...mapActions("powerCurrent", {
			getPowerCurrent: "getPowerCurrent"
		}),
		...mapActions("powerConsumption", {
			getPowerConsumption: "getPowerConsumption"
		}),
		fetchData() {
			return Promise.all([
				this.getPowerTrend({ id: this.id }),
				this.getPowerPeakDemand({ id: this.id }),
				this.getPowerVoltage({ id: this.id }),
				this.getPowerCurrent({ id: this.id }),
				this.getPowerConsumption({ id: this.id })
			]);
		},
		handleFilterTrend(params = {}) {
			this.getPowerTrend({ id: this.id, params });
		},
		handleFilterPeakDemand(params = {}) {
			this.getPowerPeakDemand({ id: this.id, params });
		},
		handleFilterVoltage(params = {}) {
			this.getPowerVoltage({ id: this.id, params });
		},
		handleFilterCurrent(params = {}) {
			this.getPowerCurrent({ id: this.id, params });
		},
		handleFilterConsumption(params = {}) {
			this.getPowerConsumption({ id: this.id, params });
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^="col"]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.gengas-consumption-chart-wrapper {
	/* stylelint-disable */
	&:v-deep {
		.gengas-consumption-section {
			display: flex;
			flex-direction: column;
			width: rem(150);
		}

		.base-section,
		.premium-section {
			@include fontBase();

			display: flex;
			justify-content: space-between;
			font-size: rem(16);
		}
	}
	/* stylelint-enable */
}

.no-gutter-top {
	margin-top: rem(-1);

	@include desktop-large {
		margin-top: 0;
	}
}
</style>
