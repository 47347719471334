<template>
	<div>
		<BaseCardIcon color="gray">
			<template v-slot:icon>
				<svg class="svg" width="57px" height="62px" viewBox="0 0 57 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g id="4-Pipeline---P1-+-P2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="UI-Pipeline_Order-History---No-History" transform="translate(-770.000000, -346.000000)">
							<g id="Group" transform="translate(611.000000, 321.000000)">
								<g id="No-history-icon" transform="translate(160.500000, 28.000000)">
									<path d="M33.6503161,0 L3.85071908,0 C1.72402565,0 0,1.73265609 0,3.8699957 L0,54.1300043 C0,56.2673439 1.72402565,58 3.85071908,58 L41.1492809,58 C42.1705551,58 43.1500006,57.5922695 43.8721505,56.8665045 C44.5943004,56.1407395 45,55.156391 45,54.1300043 L45,11.5900129 L33.6503161,0 Z" id="Path" stroke="#AFC0B6" stroke-width="2" fill="#586C63"></path>
									<circle id="Oval" stroke="#AFC0B6" stroke-width="2" fill="#586C63" cx="42.5" cy="40.5" r="11.5"></circle>
									<path d="M32.9999993,0 L32.9999993,8.55843171 C32.9996517,9.47107284 33.3658822,10.3464526 34.0180883,10.9919092 C34.6702944,11.6373658 35.5550236,12 36.4775566,12 L44.9999993,12 L32.9999993,0 Z" id="Path" stroke="#AFC0B6" stroke-width="2" fill="#586C63"></path>
									<line x1="9" y1="16" x2="36" y2="16" id="Path" stroke="#AFC0B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
									<line x1="9" y1="26" x2="36" y2="26" id="Path" stroke="#AFC0B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
									<line x1="9" y1="36" x2="24" y2="36" id="Path" stroke="#AFC0B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
									<line x1="9" y1="47" x2="24" y2="47" id="Path" stroke="#AFC0B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
									<rect id="Rectangle" fill="#AFC0B6" fill-rule="nonzero" x="42" y="34" width="2" height="9" rx="1"></rect>
									<circle id="Oval" fill="#AFC0B6" fill-rule="nonzero" cx="43" cy="46" r="1"></circle>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</template>
			<template v-slot:head>
				<slot v-if="$slots.head" name="head"></slot>
				<span v-else>No order history</span>
			</template>
			<template v-slot:body>
				<slot v-if="$slots.body" name="body"></slot>
				<span v-else>Your order history will appear here once there is a record.</span>
			</template>
		</BaseCardIcon>
	</div>
</template>

<script>
import BaseCardIcon from "@/components/BaseCardIcon.vue";

export default {
	name: "EmptyOrderHistory",

	components: {
		BaseCardIcon
	}
};
</script>

<style lang="scss" scoped>
.svg {
	position: relative;
	left: rem(5);
}
</style>