<template>
	<BaseEmpty v-if="zldVinyThaiConsumption.isEmpty" class="empty">
		<template #head>
			<span>No last month consumption history</span>
		</template>
		<template #body>
			<span>Your last month consumption history will appear here once there is a record.</span>
		</template>
	</BaseEmpty>
	<div v-else>
		<BaseLoading v-if="zldVinyThaiConsumption.isLoading" isFullPage />
		<div v-else class="zld-consumption-approval-wrapper">
			<div class="zld-consumption-report">
				<div>
					Prepared by <strong class="prepared-by">{{ getPrepareBy  }}</strong>
				</div>
				<div>
					Reported date & time <strong class="report-date">{{ getReportDate }}</strong>
				</div>
			</div>
			<div class="zld-consumption-table-report">
				<BaseCard>
					<template #header>
						<div class="zld-consumption-card-header">
							<div class="zld-consumption-title">
								<h4>{{ getDateString }}</h4>
								<BaseTag v-if="getIsApproved" :class="['tag','small','green-light']">Approved</BaseTag>
								<BaseTag v-if="getIsVerifying" :class="['tag','small','yellow-mustard']">Verifying</BaseTag>
							</div>
							<div class="zld-consumption-card-header-detail">
								<div class="zld-consumption-card-header-unit">
									Unit: <div class="unit">m<sup>3</sup></div>
								</div>
								<div class="zld-consumption-card-header-counter">Counter:
									<div class="counter-number">
										{{ getCounterNumber }}
									</div>
								</div>
							</div>
						</div>
					</template>
					<template #body>

						<div v-if="zldVinyThaiConsumption.isLoading" class="loading-wrapper">
							<BaseLoading />
						</div>
						<RequestVerify
							v-if="getIsVerifying"
							:requestBy="getRequestBy"
							:comment="getComment"
						/>
						<ConsumptionTable
							v-else
							:headers="consumptionHeader"
							:data="getConsumptionApprovalData"
							:totalConsumption="getTotalConsumption"
							:lastMonthVolume="getLastMonthVolume"
							:averageWaterRecoveryRate="getAvgWaterRecoverFlowRate"
						/>
					</template>
				</BaseCard>
			</div>
			<div class="zld-consumption-attachment">
				<h3 class="header-attachment">
					Attached document
				</h3>
				<div class="attachment-link-container">
					<a
						:class="['attachment-link btn-link', { disabed: !getAdjustFormLink }]"
						:disabled="!getAdjustFormLink"
						:href="getAdjustFormLink"
						target="_blank"
					>
						<font-awesome-icon
							:icon="['far', 'file']"
							class="icon"
						/>
						Adjust volume report witness/customer witness
						<div
							v-if="!getAdjustFormLink"
							class="unavailable"
						>
							Unavailable
						</div>
					</a>
					<a
						:class="['attachment-link btn-link', { disabed: !getPowerFormLink }]"
						:disabled="!getPowerFormLink"
						:href="getPowerFormLink"
						target="_blank"
					>
						<font-awesome-icon
							:icon="['far', 'file']"
							class="icon"
						/>
						Plant power outage document
						<div
							v-if="!getPowerFormLink"
							class="unavailable"
						>
							Unavailable
						</div>
					</a>
				</div>
			</div>
			<div
				v-if="!zldVinyThaiConsumption.isLoading && !getIsApproved && !getIsVerifying && isShowApprove && permissions.dashboard.approval"
				:class="[
					'zld-consumption-approve-section',
					{ 'sidebar-collapsed': !isSideBarOpened },
				]"
			>
				<BaseButton
					class="request-verify-btn btn-secondary  link small"
					@click="showModal(MODAL_REQUEST_VERIFY)"
				>
					Request for verification
				</BaseButton>
				<BaseButton
					class="approve-btn"
					@click="showModal(MODAL_APROVE_CONSUMPTION)"
				>
					Approve
				</BaseButton>
			</div>
			<RequestVerifyModal
				v-if="!zldVinyThaiConsumption.isLoading && !getIsApproved && isShowApprove"
				:errorMessage="zldVinyThaiConsumption.errors"
				:modal-name="MODAL_REQUEST_VERIFY"
				:isHidePassword="getIsStaff"
				@clearError="clearError"
				@onConfirm="handleRequestVerify"
			/>
			<ApproveModal
				v-if="!zldVinyThaiConsumption.isLoading && !getIsApproved && isShowApprove"
				:errorMessage="zldVinyThaiConsumption.errors"
				:modal-name="MODAL_APROVE_CONSUMPTION"
				:isHidePassword="getIsStaff"
				@clearError="clearError"
				@onConfirm="handleApprove"
			/>
		</div>
	</div>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseTag from "@/components/BaseTag.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseEmpty from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import ConsumptionTable from "@/components/BaseConsumptionApproval/ZldConsumptionTable.vue";
import RequestVerify from "@/components/BaseConsumptionApproval/RequestVerify.vue";
import RequestVerifyModal from "@/components/BaseConsumptionApproval/RequestVerifyModal.vue";
import ApproveModal from "@/components/BaseConsumptionApproval/ApproveModal.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import { TOAST_TYPES } from "../../enums/toast";
import { STAFF_ROLES } from "../../enums/role";
import { CUSTOMER_ROLES } from "../../enums/customerManagement/customerRole";
import userPermission from "../../mixins/userPermission";

export default {
	components: {
		BaseCard,
		ConsumptionTable,
		BaseLoading,
		BaseTag,
		BaseEmpty,
		BaseButton,
		RequestVerifyModal,
		ApproveModal,
		RequestVerify
	},

	mixins: [userPermission],

	data() {
		return {
			meterId: this.$route.params.id,
			consumptionHeader: [
				{ show: false, name: "head" },
				{ show: true, name: "base1" },
				{ show: true, name: "base2" },
				{ show: true, name: "base3" }
			],
			MODAL_REQUEST_VERIFY: "MODAL_REQUEST_VERIFY",
			MODAL_APROVE_CONSUMPTION: "MODAL_APROVE_CONSUMPTION"
		};
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogConsumptionApprovalView({
			shopToId: this.meterId,
			httpStatus: this.zldVinyThaiConsumption.statusCode
		});
		this.$emit("loaded");
	},

	computed: {
		...mapState(["zldVinyThaiConsumption", "sidebar", "user"]),
		...mapGetters("zldVinyThaiConsumption", {
			getConsumptionApprovalData: "getConsumptionApprovalData",
			getTotalConsumption: "getTotalConsumption",
			getPrepareBy: "getPrepareBy",
			getDateRecord: "getDateRecord",
			getCounterNumber: "getCounterNumber",
			getAttechment: "getAttechment",
			getIsApproved: "getIsApproved",
			getIsVerifying: "getIsVerifying",
			getRequestBy: "getRequestBy",
			getComment: "getComment",
			getLastMonthVolume: "getLastMonthVolume",
			getAvgWaterRecoverFlowRate: "getAvgWaterRecoverFlowRate"
		}),
		isSideBarOpened() {
			return this.sidebar.isSidebarOpen;
		},
		getAdjustFormLink() {
			return this.getAttechment.adjustFormLink;
		},
		getPowerFormLink() {
			return this.getAttechment.powerFormLink;
		},
		getReportDate() {
			return dayjs(this.getDateRecord).format("D MMM YYYY, HH:mm");
		},
		getDateString() {
			return dayjs(this.getDateRecord).format("MMMM YYYY");
		},
		getIsStaff() {
			return this.user.role === STAFF_ROLES.SUPER_ADMIN;
		},
		isShowApprove() {
			return this.user.role === STAFF_ROLES.SUPER_ADMIN ||
				this.user.role === CUSTOMER_ROLES.POWERUSER ||
				this.user.role === CUSTOMER_ROLES.LEVEL_2_USER;
		}
	},
	methods: {
		...mapActions("zldVinyThaiConsumption", {
			getZldConsumptionApproval: "getZldConsumptionApproval",
			updateApproveZldConsumption: "updateApproveZldConsumption",
			updateVerifyZldConsumption: "updateVerifyZldConsumption",
			clearError: "clearError"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogConsumptionApprovalView: "addActivityLogConsumptionApprovalView"
		}),
		...mapActions("toast", {
			showToast: "show"
		}),

		fetchData() {
			return this.getZldConsumptionApproval({ id: this.meterId });
		},

		handleOpenModalVerify() {
			this.$modal.show("request-verify");
		},

		showModal(modalName) {
			this.$modal.show(modalName);
		},

		async handleRequestVerify(data) {
			await this.updateVerifyZldConsumption({
				id: this.meterId,
				payload: {
					password: data.password,
					comment: data.comment || null,
					dateString: dayjs(this.getDateString).format("MMM-YYYY")
				}
			});

			if (this.zldVinyThaiConsumption.isRequestVerifySuccess) {
				this.$modal.hide(this.MODAL_APROVE_CONSUMPTION);

				this.$router.push({
					...this.$route,
					query: {
						verify: true
					}
				}).catch(() => {});


				this.showToast({
					type: TOAST_TYPES.METER_CONSUMPTION_VERIFY_SUCCESS,
					props: { delay: 6000 }
				});
			}
		},

		async handleApprove(data) {
			await this.updateApproveZldConsumption({
				id: this.meterId,
				payload: {
					password: data.password,
					comment: data.comment || null,
					dateString: dayjs(this.getDateString).format("MMM-YYYY")
				}
			});

			if (this.zldVinyThaiConsumption.isApproveSuccess) {
				this.$modal.hide(this.MODAL_APROVE_CONSUMPTION);

				this.$router.push({
					...this.$route,
					query: {
						success: true
					}
				}).catch(() => {});
				this.showToast({
					type: TOAST_TYPES.METER_CONSUMPTION_APPROVE_SUCCESS,
					props: { dateInvoice: this.getDateString, delay: 6000 }
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: rem(318);
}

.empty {
	margin-top: $empty-order-margin-top;
}

.zld-consumption {
	&-table-report {
	/* stylelint-disable */
		&::v-deep {
			.card-body {
				overflow: auto;
			}
		}
	/* stylelint-enable */
	}

	&-title {
		display: flex;
		align-items: center;

		.tag {
			margin-left: rem(8);
		}
	}

	&-approve-section {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		height: rem(72);
		position: fixed;
		bottom: 0;
		margin-left: rem(-310);
		background-color: $color-white;
		box-shadow: $box-shadow-tooltip;
		transform: margin 300ms linear;
		transition: transform;
		padding: 0 rem(30);

		&.sidebar-collapsed {
			margin-left: rem(-102);
		}

		.request-verify-btn {
			font-size: rem(16);
		}

		.approve-btn {
			font-size: rem(24);
			height: rem(40);
			margin-left: rem(32);
		}
	}

	&-attachment {
		display: flex;
		height: rem(87);
		border-radius: rem(4);
		background-color: $color-alabaster;
		margin-top: rem(-10);
		margin-bottom: rem(96);
		padding: rem(16) rem(24);

		.header-attachment {
			@include fontSub();

			font-size: rem(20);
			margin-right: rem(64);
		}

		.attachment-link-container {
			font-size: rem(16);
			margin-top: rem(-4);

			.attachment-link {
				margin: rem(8) 0;

				.unavailable {
					color: $color-grey-4;
					font-size: rem(14);
					font-weight: $font-weight-regular;
					margin-left: rem(16);
					margin-bottom: rem(-2);
				}

				.icon {
					font-size: rem(12);
				}

				&.disabed {
					color: $color-grey-4;
					cursor: not-allowed;
					pointer-events: none;
				}
			}
		}
	}

	&-approval-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
	}

	&-report {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: rem(16);
		color: $color-dark-blue-grey;

		.prepared-by,
		.report-date {
			font-size: rem(20);
			margin-left: rem(16);
		}
	}

	&-card-header {
		display: flex;
		width: 100%;
		justify-content: space-between;



		&-detail {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: rem(18);
			color: $color-grey-4;

			.counter-number,
			.unit {
				margin-left: rem(4);
				font-weight: $font-weight-bold;
			}
		}

		&-unit {
			display: flex;
			align-items: center;
			margin-right: rem(40);

			.unit {
				margin-bottom: rem(4);
			}
		}

		&-counter {
			display: flex;
			margin-bottom: rem(-4);
		}
	}
}

@include desktop {
	.zld-consumption-approve-section {
		margin-left: rem(-280);
	}
}

@include tablet {
	.zld-consumption-approve-section {
		margin-left: rem(-30);
	}
}
</style>