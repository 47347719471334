<template>
	<div class="wrapper">
		<DesktopSidebar :isSidebarOpen="sidebar.isSidebarOpen" @setSideBar="handleClickSidebarAction"/>
		<TabletHeader />
		<TabletAccount />
		<TabletSidebar />
		<ToastMaintenanceMode
			v-if="showToastMaintenance"
			:collapse="sidebar.isSidebarOpen"
			class="hover-bar"
		/>
		<div :class="['content-wrapper', { collapse: !sidebar.isSidebarOpen }]">
			<section class="container content-container">
				<template v-if="!isLoading">
					<div class="content-header">
						<h1>
							{{ pageTitle }}
						</h1>
						<span v-if="pageSubtitle">
							<i>
								{{ pageSubtitle }}
							</i>
						</span>
					</div>
					<slot/>
				</template>
				<BaseLoading v-else isFullPage />
			</section>
		</div>
	</div>
</template>

<script>
import DesktopSidebar from "@/components/TheMainSidebar.vue";
import TabletHeader from "@/components/tablet/Header.vue";
import TabletAccount from "@/components/tablet/AccountMenu.vue";
import TabletSidebar from "@/components/tablet/Sidebar.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import ToastMaintenanceMode from "@/components/toast/ToastMaintenanceMode.vue";

import { mapState, mapActions } from "vuex";

export default {
	components: {
		DesktopSidebar,
		TabletHeader,
		TabletAccount,
		TabletSidebar,
		BaseLoading,
		ToastMaintenanceMode
	},

	methods: {
		...mapActions({
			handleToggleSidebar: "sidebar/handleToggleSidebar"
		}),

		handleClickSidebarAction() {
			this.handleToggleSidebar();
		}
	},

	computed: {
		...mapState(["sidebar", "maintenanceStatus", "user"]),

		showToastMaintenance() {
			return this.maintenanceStatus.data.status && this.user.isLoggedIn;
		}
	},

	props: {
		pageTitle: {
			type: String,
			default: null
		},

		pageSubtitle: {
			type: String,
			default: null
		},

		isLoading: {
			type: Boolean,
			default: false
		}
	}
};
</script>
