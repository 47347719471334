import Numeral from "numeral";
import { transformHorizontalBarChart } from "../horizontalBarChart";


const color = {
	overAll: "#00a8e1",
	userMonitor: "#cf0360"
};

export const transformBrowserUsagesData = (totalBrowsers = [], page = "overAll") => {
	const sumTotalBrower = totalBrowsers.reduce((acc, next) => acc + next.total, 0);
	const barColor = color[page];
	return {
		xAxis: {
			name: "Usage",
			type: "value",
			nameLocation: "center",
			nameTextStyle: {
				fontWeight: "bold",
				color: "#103332",
				padding: [20, 0, 0, 0]
			},
			position: "bottom",
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			axisLabel: {
				fontSize: 14
			},
			axisTick: {
				show: true,
				length: 4,
				lineStyle: {
					width: 1,
					color: "#586c63"
				}
			}
		},

		yAxis: {
			type: "category",
			inverse: true,
			data: totalBrowsers.map(({ browser }) => browser ?? "Undefined"),
			color: "#59e07a",
			nameLocation: "end",
			nameTextStyle: {
				color: "#717171",
				fontFamily: "TFSrivichai",
				fontWeight: "bold",
				fontSize: 20,
				padding: [0, 0, 24, -23],
				align: "left"
			},
			axisLabel: {
				fontSize: 14
			},
			axisLine: {
				lineStyle: {
					color: "#cad9cf"
				}
			},
			axisTick: {
				show: false
			}
		},
		series: [{
			type: "bar",
			barWidth: "14px",
			data: totalBrowsers.map(({ total, browser }) => ({
				name: browser,
				value: Number(total),
				itemStyle: {
					color: barColor,
					barBorderRadius: total > 0 ? [0, 50, 50, 0] : [50, 0, 0, 50]
				},
				label: {
					show: true,
					position: "right",
					fontSize: 16,
					fontFamily: "TFSrivichai",
					fontWeight: 600,
					color: barColor,
					formatter: ({ data }) => {
						return Numeral((data.value / sumTotalBrower)).format("0.0%");
					}
				}
			}))
		}]
	};
};
const option = {
	grid: {
		top: "0",
		left: 24,
		right: 32,
		bottom: 48,
		containLabel: true
	},
	tooltip: {
		formatter: ({ value, name }) => {
			return `
				<div class="users-usages-wrapper">
					<div class="usages-name">
						${name}
					</div>
					<div class="usages-value">
						${Numeral(value).format("0,0")} usages
					</div>
				</div>
			`;
		}
	}
};

export const transformBrowserUsagesBarChart = ({ totalBrowsers = [], page = "overAll" }) => {
	const data = transformBrowserUsagesData(totalBrowsers, page);
	return transformHorizontalBarChart(option)(data);
};
