import httpClient from "./httpClient";

const CUSTOMERS = "/customers";

const getCustomersAPI = (params = {}) => httpClient.get(`${CUSTOMERS}`, { params });
const postCustomersAPI = (payload = {}) => httpClient.post(`${CUSTOMERS}`, payload);
const getCustomerByIdAPI = (id) => httpClient.get(`${CUSTOMERS}/${id}`);
const putCustomerUpdateAPI = (id, payload = {}) => httpClient.put(`${CUSTOMERS}/${id}`, payload);
const putCustomerPasswordUpdateAPI = (id, payload) => httpClient.put(`${CUSTOMERS}/${id}/password`, payload);
const postCustomerPermissionAPI = (data) => httpClient.post(`${CUSTOMERS}/permissions`, data);
const getCustomerRolePermissions = () => httpClient.get(`${CUSTOMERS}/role-permissions`);

export {
	getCustomersAPI,
	postCustomersAPI,
	getCustomerByIdAPI,
	putCustomerUpdateAPI,
	putCustomerPasswordUpdateAPI,
	postCustomerPermissionAPI,
	getCustomerRolePermissions
};
