<template>
	<div :class="['container-form', { 'show-helper-checkbox-test-account': [STAFF_ROLES.SALES, STAFF_ROLES.SALES_PLUS].includes(form.role) }]">
		<div class="item">
			<div class="label">
				Email *
			</div>
			<div class="form">
				<BaseFormInputAutoComplete
					v-model="form.email"
					:is-error="!!errorEmailMessage"
					:error-message="errorEmailMessage"
					:inputOptions="['small']"
					:dropdownListContainerHeight="120"
					:list="azureUserList"
					:is-loading="emailLoading"
					:disabled="emailDisabled"
					@selected="handleSelectedEmail"
					@changed="debounceSearchEmail"
					@blur="handleBlurEmail"
					placeholder="e.g. sample@email.com"
				/>
			</div>
		</div>
		<div class="item">
			<div class="label">
				System role *
			</div>
			<div class="form form-role">
				<BaseDropdown
					:list="roles"
					:value="form.role"
					:dropdownListContainerHeight="90"
					:searchable="false"
					:isError="!!errorRoleMessage"
					:errorMessage="errorRoleMessage"
					:disabled="roleDisabled"
					placeholder="Please select a role"
					@input="handleRoleSelected"
				/>
				<span v-if="helperText" class="helper-text" v-html="helperText"/>
			</div>
		</div>
		<div class="item item-checkbox-test-account">
			<div class="label"></div>
			<div class="form">
				<BaseCheckBox
					:checked="form.isTestAccount"
					:disabled="isTestAccountDisabled"
					label="Test account"
					helperText="*Test account will not display on system monitoring"
					@handleCheck="handleIsTestAccountChange"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import { STAFF_ROLES, ROLES_LABEL } from "../../enums/role";
import { isValidEmail } from "../../selectors/helpers/regex";
import { getStaffRoles } from "../../selectors/transform/staff/staffRoledropdown";

export default {
	components: {
		BaseCheckBox
	},

	props: {
		email: {
			type: String
		},
		azureId: {
			type: String
		},
		role: {
			type: String
		},
		isTestAccount: {
			type: Boolean,
			default: false
		},
		emailDisabled: {
			type: Boolean,
			default: false
		},
		roleDisabled: {
			type: Boolean,
			default: false
		},
		isTestAccountDisabled: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			isValidEmail,
			STAFF_ROLES,
			ROLES_LABEL,
			form: {
				email: "",
				azureId: "",
				role: "",
				isTestAccount: false
			},
			validator: {
				isValidEmail: false,
				isValidRole: false
			},
			emailLoading: false,
			debounce: null,
			isSubmitted: false,
			errorRoleMessage: ""
		};
	},

	computed: {
		...mapState("insertStaff", {
			isLoading: "isLoading",
			errorEmailMessage: "errorEmailMessage"
		}),
		...mapGetters("insertStaff", {
			azureUserList: "getAzureUserList"
		}),

		roles() {
			return getStaffRoles();
		},

		disabledSubmitButton() {
			return !(this.isValidEmail(this.form.email) && this.validator.isValidRole) || this.isLoading;
		},

		matchedUser() {
			return this.azureUserList.find(({ label: email }) => email === this.form.email);
		},

		helperText() {
			if (this.form.role === STAFF_ROLES.SALES) {
				return `<b>* Sales staff permissions</b> will automatically sync with CRM system.`;
			} else if (this.form.role === STAFF_ROLES.SALES_PLUS) {
				return `<b>* Sales plus staff permissions</b> will automatically sync with CRM system and on top with portal permissions below.`;
			}
			return null;
		}
	},

	watch: {
		form: {
			handler() {
				this.isSubmitted = false;
				this.clearState();
			},
			deep: true
		},
		"form.email": {
			handler(newVal) {
				this.form.email = newVal;
				this.$emit("update:email", newVal);
			}
		},
		"form.azureId": {
			handler(newVal) {
				this.$emit("update:azureId", newVal);
			}
		},
		"form.role": {
			handler(newVal, oldVal) {
				this.validateRole(newVal, oldVal);
				this.$emit("update:role", newVal);
			}
		},
		"form.isTestAccount": {
			handler(newVal) {
				this.$emit("update:isTestAccount", newVal);
			}
		},
		emailLoading: {
			handler() {
				this.form.azureId = "";
			}
		},
		email(newVal) {
			this.form.email = newVal;
			this.form.azureId = this.azureId;
		},
		role(newVal) {
			this.form.role = newVal;
		},
		isTestAccount(newVal) {
			this.form.isTestAccount = newVal;
		}
	},

	created() {
		this.form.email = this.email;
		this.form.role = this.role;
		this.form.isTestAccount = this.isTestAccount;
		this.$emit("update:validateForm", this.validateForm);
	},

	methods: {
		...mapActions("insertStaff", {
			getAzureUserList: "getAzureUserList",
			clearAzureList: "clearAzureList",
			clearState: "clearState",
			setErrorEmailMessage: "setErrorEmailMessage"
		}),
		resetForm() {
			this.form.email = "";
			this.form.azureId = "";
			this.form.role = "";
			this.form.isTestAccount = false;
			this.validator.isValidEmail = false;
			this.validator.isValidRole = false;
			this.emailLoading = false;
			this.isSubmitted = false;
		},
		handleRoleSelected(value = "") {
			if (value === STAFF_ROLES.SALES) {
				this.$emit("clearPermission");
			}
			this.form.role = value;
		},
		handleSubmit() {
			this.isSubmitted = true;
			this.setAzureId();
			if (this.form.azureId === "") {
				this.setErrorEmailMessage("This email is not in BIG system.");
				return false;
			}
			this.setErrorEmailMessage("");
			this.$emit("onSubmit", this.form);
			return true;
		},
		beforeOpen() {
			this.resetForm();
			this.clearAzureList();
			this.clearState();
		},
		beforeClose() {
			this.resetForm();
			this.clearAzureList();
		},
		handleSelectedEmail({ label: email, value: azureId }) {
			this.form.email = email;
			this.form.azureId = azureId;
			this.checkEmail(email);
			this.clearAzureList();
		},
		debounceSearchEmail(email) {
			clearTimeout(this.debounce);
			this.form.email = email;
			this.clearAzureList();
			this.emailLoading = true;
			this.debounce = setTimeout(async () => {
				if (email) {
					await this.getAzureUserList({ q: email });
					this.emailLoading = false;
				} else {
					this.emailLoading = false;
				}
			}, 600);
		},
		validateEmail(email) {
			this.checkEmail(email);
			this.checkMessageErrorEmail();
		},
		validateRole(newVal, oldVal) {
			this.checkRole(newVal, oldVal);
			this.checkMessageErrorRole();
		},
		validateForm() {
			this.validateEmail(this.form.email);
			this.validateRole(this.form.role, "");
		},
		handleBlurEmail() {
			this.validateEmail(this.form.email);
		},
		checkEmail(email) {
			if (this.isValidEmail(email)) {
				this.validator.isValidEmail = true;
			} else {
				this.validator.isValidEmail = false;
			}
		},
		checkMessageErrorEmail() {
			if (this.validator.isValidEmail) {
				this.setErrorEmailMessage("");
			} else {
				this.setErrorEmailMessage("This email is invalid");
			}
		},
		checkRole(newVal, oldVal) {
			this.validator.isValidRole = newVal !== oldVal;
		},
		checkMessageErrorRole() {
			if (this.validator.isValidRole) {
				this.errorRoleMessage = "";
			} else {
				this.errorRoleMessage = "Required field";
			}
		},
		setAzureId() {
			if (this.form.azureId === "") {
				const user = this.matchedUser;
				if (user) {
					this.form.azureId = user.value;
				}
			}
		},
		handleIsTestAccountChange(value) {
			this.form.isTestAccount = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			align-items: center;
			min-width: rem(130);
		}

		.form {
			flex-grow: 1;
		}

		.form-role {
			position: relative;

			.helper-text {
				position: absolute;
				bottom: rem(-2);
				transform: translateY(100%);
				font-size: $font-14;
				color: $color-dark-blue-grey;
			}
		}
	}

	&.show-helper-checkbox-test-account .item-checkbox-test-account {
		margin-top: rem(40);
	}

	.item-checkbox-test-account {
		margin-top: rem(15);
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}

.notify-error {
	margin-bottom: $spacing-6;
}
</style>