<template>
	<div>
		<BaseCard
			:bodyStyle="[
				{ display: 'grid' },
				{ minHeight: '386px' },
				{ maxHeight: '386px' },
				{ paddingTop: '16px' }
			]"
		>
			<template #header>
				<h4>
					Most active users
				</h4>
				<span class="last-update-text">
					{{ dateRange }}
				</span>
			</template>
			<template #body>
				<BaseLoading
					v-if="isLoading"
					isFullSize
				/>
				<div
					v-else
					class="container-list"
				>
					<BaseTabPanel
						v-model="tabIndex"
						:items="tabs"
						class="custom-tab"
					/>
					<BaseNoData
						class="empty"
						v-if="mapData.length === 0"
						description="BIG portal will start showing the active customer right after we have information"
					/>
					<BaseListView
						v-else
					>
						<BaseListViewItemUser
							v-for="(item, index) in mapData"
							:key="index"
							:avatar="item.avatar"
							:name="item.name"
							:count="item.count"
						/>
					</BaseListView>
				</div>
			</template>
		</BaseCard>
	</div>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseListView from "@/components/BaseListView.vue";
import BaseTabPanel from "@/components/BaseTabPanel.vue";
import BaseListViewItemUser from "@/components/BaseListViewItemUser.vue";
import BaseNoData from "@/components/BaseNoData.vue";


export default {
	name: "MostActiveUser",

	props: {
		/**
		 * { Array } staffs
		 * { Array } customers
		 */
		data: {
			type: Object,
			default: () => ({})
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		dateRange: {
			type: String,
			default: ""
		}
	},

	components: {
		BaseCard,
		BaseLoading,
		BaseListView,
		BaseTabPanel,
		BaseListViewItemUser,
		BaseNoData
	},

	data() {
		return {
			tabIndex: 0,
			tabs: [
				{
					title: "BIG staff"
				},
				{
					title: "Customer"
				}
			]
		};
	},

	computed: {
		bigStaffs() {
			return this.data.staffs.map((item) => ({
				avatar: "/assets/images/profile.svg",
				name: item.displayName || item.email,
				count: item.activities
			}));
		},
		customers() {
			return this.data.customers.map((item) => ({
				avatar: "/assets/images/profile.svg",
				name: item.displayName || item.email,
				count: item.activities
			}));
		},
		mapData() {
			if (this.tabIndex === 0) {
				return this.bigStaffs;
			}
			return this.customers;
		}
	},

	watch: {
		// every time filter date was change then always set `tabIndex` to `0` as default
		isLoading() {
			this.tabIndex = 0;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: rem(-30);
}

.custom-tab {
	margin-left: rem(-24);
	margin-right: rem(-24);
	margin-bottom: rem(20);
}

.last-update-text {
	color: $color-grey-4;
}
</style>