
export const getCompaniesOptions = (companies = []) => {
	const options = companies.map((option) => {
		const companyName = option.companyName;
		const label = `${companyName}`;

		return { value: option.soldTosId, label };
	});

	return options;
};
