<template>
	<div class="head-column-container">
		<div class="head-column-left">
			<div class="item">
				<span>Total ({{ NM_UNIT }})</span>
				<strong>
					{{ total | currency }}
				</strong>
			</div>
		</div>
		<div class="head-column-right">
			<div class="percentage-icon">
				<PercentageIcon size="medium" :percentages="percentages" />
			</div>
		</div>
	</div>
</template>

<script>
import PercentageIcon from "@/components/deviceIcons/PercentageIcon.vue";
import { NM_UNIT } from "./../../../../selectors/transform/unit";

export default {
	name: "MeterOrderHistoryColumnFooter",

	components: {
		PercentageIcon
	},

	props: {
		total: {
			type: String,
			required: true
		},
		percentages: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			NM_UNIT
		};
	}
};
</script>

<style lang="scss" scoped>
.head-column {
	// .head-column-container
	&-container {
		display: flex;
		padding: rem(5) rem(24);
		background: $color-grey-1;
		border-radius: rem(4);
	}

	// .head-column-right
	&-right {
		display: flex;
		width: 100%;

		// .head-column-right .percentage-icon
		.percentage-icon {
			width: 100%;
			position: relative;
			top: rem(17);
		}
	}

	// .head-column-left
	&-left {
		width: rem(182);

		// .head-column-left .item
		.item {
			display: flex;
			flex-direction: column;
			margin-left: rem(20);

			// .head-column-left .item span
			span {
				font-size: $font-16;
				color: $color-grey-4;
			}

			span:first-of-type {
				margin: rem(2) 0 rem(-12) 0;
			}

			// .head-column-left .item strong
			strong {
				@include fontSub();

				font-size: $font-24;
				color: $color-grey-4;
			}
		}
	}
}
</style>