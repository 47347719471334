import Numeral from "numeral";
import { transformBarChart } from "../index";

const optionMoneySavingBarChart = {
	yAxisText: "Baht",
	series: {
		tooltip: {
			show: true,
			trigger: "axis",
			backgroundColor: "#103332",
			axisPointer: {
				type: "none"
			},
			padding: 8,
			formatter: ([{ value, name }]) => {
				return `
                    <div>
                        <div class="bar-name">
                            ${name}
                        </div>
                        <div class="bar-value">
                            ${Numeral(value).format(0, 0)} Baht
                        </div>
                    </div>
                `;
			}
		}
	}
};

export const transformMoneySavingBarChart = transformBarChart(optionMoneySavingBarChart);
