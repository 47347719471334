<template>
	<div :class="['container', { active: toggle }]">
		<div class="container-title">
			<button
				@click="onToggle"
			>
				{{ title }}
				({{ transformItems.length }})
				<FontAwesomeIcon :icon="['far', arrowIcon]" />
			</button>
		</div>
		<div class="container-items">
			<div
				v-for="(item, index) in transformItems"
				:key="index"
				:class="['container-item', { disabled: item.disabled }]"
				:title="item.subTitle"
			>
				<strong class="title">
					{{ item.title }}
				</strong>
				<strong class="sub-title">
					{{ item.subTitle }}
				</strong>
			</div>
		</div>
	</div>
</template>

<script>
import { getDeviceName } from "../selectors/helpers/flowDiagram";

export default {
	props: {
		title: {
			type: String,
			default: null
		},
		items: {
			type: Array,
			default: () => ([])
		},
		selectedNodeIds: {
			type: Array,
			default: () => ([])
		}
	},

	data() {
		return {
			toggle: true
		};
	},

	computed: {
		arrowIcon() {
			return this.toggle ?
				"chevron-up" :
				"chevron-down";
		},
		transformItems() {
			return this.items.map((item) => {
				const deviceName = getDeviceName(item.shipId, item.deviceCustomerName);
				return {
					title: item.name,
					subTitle: deviceName ? `(${deviceName})` : "",
					disabled: this.selectedNodeIds.includes(item.id)
				};
			});
		}
	},

	methods: {
		onToggle() {
			this.toggle = !this.toggle;
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	flex-direction: column;
	padding: rem(8) rem(16);
	background-color: $color-alabaster;
	border-radius: $border-radius-normal;

	&.active &-items {
		display: block;
	}

	&-title {
		color: $color-dark-blue-grey;

		button {
			background: none;
			border: none;
			font-size: $font-20;
			font-family: $font-family-base;
			font-weight: $font-weight-bold;
		}

		svg {
			margin-left: rem(4);
			font-size: rem(12);
		}
	}

	&-items {
		width: 100%;
		overflow: auto;
		display: none;
		margin-top: rem(2);
	}

	&-item {
		float: left;
		display: flex;
		align-items: center;
		padding: rem(3) rem(8);
		margin-right: rem(8);
		margin-bottom: rem(8);
		height: rem(30);
		background-color: $color-white;
		border: 1px solid $color-silver;
		border-radius: $border-radius-normal;
		max-width: 200px;

		&.disabled {
			opacity: 0.5;
		}

		.title {
			font-family: $font-family-sub;
			font-size: $font-20;
			color: $color-dark-blue-grey;
		}

		.sub-title {
			@include ellipsis(1);

			position: relative;
			top: rem(3);
			margin-left: rem(4);
			font-family: $font-family-base;
			font-size: $font-14;
			color: $color-grey-4;
		}
	}
}
</style>