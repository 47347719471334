<template>
	<div class="page-container">
		<div class="page-content">
			<div class="page-form">
				<div class="page-describe">
					<div class="describe">
						<div class="title">
							BIG Portal has been under maintenance.
						</div>
						<div class="body">
							<div class="description">
								{{ description }}
							</div>
							<a class="logout-button" @click="getLogout">Log out</a>
						</div>
						<div class="footer">
							<img src="/assets/images/undraw-city-life-gnpr-copy@3x.png" alt="BIG-TH">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// this component do not need to request to GET: /maintenance api because it already requested from middleware.
// you can see on: guard.js and beforeEnterMaintenanceModePage()
import { mapState, mapActions } from "vuex";

export default {
	computed: {
		...mapState("maintenanceStatus", {
			data: "data"
		}),
		description() {
			return this.data.description || "We apologize for any inconvenience.";
		}
	},

	methods: {
		...mapActions({
			handleLogout: "user/getLogout"
		}),

		async getLogout() {
			await this.handleLogout();

			window.location.href = "/login";
		}
	}
};
</script>

<style lang="scss" scoped>
.page-container {
	background: url("/assets/images/maintenance-page-bg.png") no-repeat center center;
	background-size: cover;
	display: flex;
	height: 100vh;
	justify-content: center;

	.page-content {
		display: flex;
		align-items: center;
		word-break: break-word;

		.page-form {
			position: relative;
			background-color: $color-white;
			padding: rem(40) rem(36) rem(26);
			width: rem(992);
			margin: 0 auto;
			border-radius: rem(4);
			border: solid 1px $color-silver;
			overflow: hidden;

			&::before {
				content: " ";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: rem(8);
				background-color: $color-green-haze;
			}
		}

		.page-describe {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.describe {
				.title {
					text-align: center;
					font-family: $font-family-sub;
					font-size: $font-32;
					color: $color-dark-blue-grey;
				}

				.body {
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;

					.description {
						font-family: $font-family-sub;
						font-size: $font-24;
						font-weight: normal;
						color: $color-dark-blue-grey;
					}

					.logout-button {
						white-space: nowrap;
						font-family: $font-family-sub;
						font-size: $font-20;
						font-weight: $font-weight-bold;
						color: $color-cerulean;
						cursor: pointer;

						padding: rem(8) rem(16);
						margin-top: rem(16);
					}
				}

				.footer {
					margin-right: rem(-36);
					margin-left: rem(-36);

					img {
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
