import httpClient from "./httpClient";

const END_POINT = "/pwrs";

const getPowerInfoAPI = (id) => httpClient.get(`${END_POINT}/${id}`);
const getPowerTrendsAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/power-trends`, { params });
const getPowerPeakDemandAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/peak-demands`, { params });
const getPowerVoltageAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/voltages`, { params });
const getPowerCurrentAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/currents`, { params });
const getPowerHistoryAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/histories`, { params });
const getPowerConsumptionsAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/consumptions`, { params });
const getPowerKwhAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/kwhs`, { params });

export {
	getPowerInfoAPI,
	getPowerTrendsAPI,
	getPowerPeakDemandAPI,
	getPowerVoltageAPI,
	getPowerCurrentAPI,
	getPowerHistoryAPI,
	getPowerConsumptionsAPI,
	getPowerKwhAPI
};
