<template>
	<div>
		<BaseLoading v-if="nitroFASInfo.loading"/>

		<div :class="['row row-eq-height', { 'hide-content': nitroFASInfo.loading }]">
			<div class="col-12 no-gutter-sm col-xl-7">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ nitroFASInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview :data="nitroFASInfo.data" class="custom-module-overview"/>
					</template>
				</BaseCard>
			</div>
			<ModuleLocation :info="nitroFASInfo.data" />
		</div>

		<div class="container-filter">
			<BaseButtonGroup
				:items="filterChartOptions"
				:active="filterSelected"
				:size="`small`"
				:class="`filter-button-group`"
				@click="handleClickedFilter"
			/>
		</div>

		<div class="nitrofas-level-container">
			<TotalFinishedGoodChart
				:data="nitroFasTotalFinishedGood.data"
				:updatedAt="nitroFasTotalFinishedGood.lastUpdate"
				:loading="nitroFasTotalFinishedGood.isLoading"
				:empty="nitroFasTotalFinishedGood.isEmpty"
				:barWidth="chartConfigs.totalFinishedGood.barWidth"
				:chartTitle="CHART_TOTAL_FINISHED_GOOD"
				:filterSelected="filterSelected"
				@filter="handleTotalFinishedGood"
				:showDataLabel="chartConfigs.switchShowLabel.totalFinishedGood"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<DrossTotalFinishedGoodChart
				:data="nitroFasDrossTotalFinishedGood.data"
				:updatedAt="nitroFasDrossTotalFinishedGood.lastUpdate"
				:loading="nitroFasDrossTotalFinishedGood.isLoading"
				:empty="nitroFasDrossTotalFinishedGood.isEmpty"
				:barWidth="chartConfigs.drossTotalFinishedGood.barWidth"
				:chartTitle="CHART_DROSS_TOTAL_FINISH_GOOD"
				:filterSelected="filterSelected"
				@filter="handleDrossTotalFinishedGood"
				:showDataLabel="chartConfigs.switchShowLabel.drossTotalFinishedGood"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<NitrogenTotalFinishedGoodChart
				:data="nitroFasNitrogenTotalFinishedGood.data"
				:updatedAt="nitroFasNitrogenTotalFinishedGood.lastUpdate"
				:loading="nitroFasNitrogenTotalFinishedGood.isLoading"
				:empty="nitroFasNitrogenTotalFinishedGood.isEmpty"
				:barWidth="chartConfigs.nitrogenTotalFinishedGood.barWidth"
				:filterSelected="filterSelected"
				:chartTitle="CHART_NITROGEN_TOTAL_FINISH_GOOD"
				@filter="handleNitrogenTotalFinishedGood"
				:showDataLabel="chartConfigs.switchShowLabel.nitrogenTotalFinishedGood"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<CardBarChart v-if="chartConfigs.switchShowLabel.nitrogenConsumption"
				key="Nitrogen consumption1"
				:chartData="getNitrogenConsumptionBarChartConfig"
				:lastUpdate="nitroFASNitrogenConsumption.lastUpdate"
				:lastUpdateLeft="true"
				:isLoading="nitroFASNitrogenConsumption.isLoading"
				:isEmpty="nitroFASNitrogenConsumption.isEmpty"
				:chartTitle="CHART_NITROGEN_CONSUMPTION"
				@dateDropDown="handleNitrogenConsumptionDateSelected"
				:showDataLabel="chartConfigs.switchShowLabel.nitrogenConsumption"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<CardBarChart v-if="!chartConfigs.switchShowLabel.nitrogenConsumption"
				key="Nitrogen consumption2"
				:chartData="getNitrogenConsumptionBarChartConfig"
				:lastUpdate="nitroFASNitrogenConsumption.lastUpdate"
				:lastUpdateLeft="true"
				:isLoading="nitroFASNitrogenConsumption.isLoading"
				:isEmpty="nitroFASNitrogenConsumption.isEmpty"
				:chartTitle="CHART_NITROGEN_CONSUMPTION"
				@dateDropDown="handleNitrogenConsumptionDateSelected"
				:showDataLabel="chartConfigs.switchShowLabel.nitrogenConsumption"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<CardBarChart v-if="chartConfigs.switchShowLabel.solderConsumption"
				key="Solder consumption1"
				:chartData="getSolderConsumptionBarChartConfig"
				:lastUpdate="nitroFasSolderConsumption.lastUpdate"
				:lastUpdateLeft="true"
				:isLoading="nitroFasSolderConsumption.isLoading"
				:isEmpty="nitroFasSolderConsumption.isEmpty"
				:chartTitle="CHART_SOLDER_CONSUMPTION"
				@dateDropDown="handleSolderConsumptionDateSelected"
				:showDataLabel="chartConfigs.switchShowLabel.solderConsumption"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<CardBarChart v-if="!chartConfigs.switchShowLabel.solderConsumption"
				key="Solder consumption2"
				:chartData="getSolderConsumptionBarChartConfig"
				:lastUpdate="nitroFasSolderConsumption.lastUpdate"
				:lastUpdateLeft="true"
				:isLoading="nitroFasSolderConsumption.isLoading"
				:isEmpty="nitroFasSolderConsumption.isEmpty"
				:chartTitle="CHART_SOLDER_CONSUMPTION"
				@dateDropDown="handleSolderConsumptionDateSelected"
				:showDataLabel="chartConfigs.switchShowLabel.solderConsumption"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<CardBarChart v-if="chartConfigs.switchShowLabel.drossGeneration"
				key="Dross generation1"
				:chartData="getDrossGenerationBarChartConfig"
				:lastUpdate="nitroFasDrossGernation.lastUpdate"
				:lastUpdateLeft="true"
				:isLoading="nitroFasDrossGernation.isLoading"
				:isEmpty="nitroFasDrossGernation.isEmpty"
				:chartTitle="CHART_DROSS_GENERATION"
				@dateDropDown="handleDrossGenerationDateSelected"
				:showDataLabel="chartConfigs.switchShowLabel.drossGeneration"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<CardBarChart v-if="!chartConfigs.switchShowLabel.drossGeneration"
				key="Dross generation2"
				:chartData="getDrossGenerationBarChartConfig"
				:lastUpdate="nitroFasDrossGernation.lastUpdate"
				:lastUpdateLeft="true"
				:isLoading="nitroFasDrossGernation.isLoading"
				:isEmpty="nitroFasDrossGernation.isEmpty"
				:chartTitle="CHART_DROSS_GENERATION"
				@dateDropDown="handleDrossGenerationDateSelected"
				:showDataLabel="chartConfigs.switchShowLabel.drossGeneration"
				@handleLabelSwitch="handleLabelSwitch"
			/>

			<CardLineChart
				:chartData="getNitrogenFlowRateLineChartConfig"
				:isLoading="nitroFASFlowRate.isLoading"
				:isEmpty="nitroFASFlowRate.isEmpty"
				:lastUpdate="nitroFASFlowRate.lastUpdate"
				:lastUpdateLeft="true"
				:filterOptions="nitrogenFlowRateDateFilterOption"
				chartTitle="Nitrogen flow rate"
				@onSelectDatePicker="handleNitrogenFlowRateDateSelect"
				@onSelectDropDown="handleNitrogenFlowRateDateDropdown"
			/>
			<GrafanaDisplay
				:deviceId="nitroID"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseCard from "@/components/BaseCard.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import TotalFinishedGoodChart from "@/components/NitroFASModule/TotalFinishedGoodChart.vue";
import DrossTotalFinishedGoodChart from "@/components/NitroFASModule/DrossTotalFinishedGoodChart.vue";
import NitrogenTotalFinishedGoodChart from "@/components/NitroFASModule/NitrogenTotalFinishedGoodChart.vue";
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import { CHART_NAME } from "../../enums/chartTitleSwitchShowData";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { getDateNumber, getDateReportFormat, getDateReportType } from "../../selectors/helpers";
import { trasnformnitroFASNitrogenFlowRateLineChart } from "../../selectors/transform/lineChart/NitroFAS/nitroFASNitrogen";
import { transformDataBarChart, transformBarChart } from "../../selectors/transform/barChart";
import { getBarChartWidth } from "../../selectors/helpers/chart";
import { NM_UNIT } from "../../selectors/transform/unit";

const { perDay, perThreeDay, perYear, perWeek, perTwoWeek, perMonth } = MODULE_SORT_DATE_CHART;
const { CHART_DROSS_GENERATION,
	CHART_SOLDER_CONSUMPTION,
	CHART_NITROGEN_CONSUMPTION,
	CHART_NITROGEN_TOTAL_FINISH_GOOD,
	CHART_DROSS_TOTAL_FINISH_GOOD,
	CHART_TOTAL_FINISHED_GOOD } = CHART_NAME;
export default {
	name: "nitroFASInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		ModuleOverview,
		ModuleLocation,
		CardLineChart,
		CardBarChart,
		TotalFinishedGoodChart,
		DrossTotalFinishedGoodChart,
		NitrogenTotalFinishedGoodChart,
		BaseButtonGroup
	},

	data() {
		return {
			CHART_DROSS_GENERATION,
			CHART_SOLDER_CONSUMPTION,
			CHART_NITROGEN_CONSUMPTION,
			CHART_NITROGEN_TOTAL_FINISH_GOOD,
			CHART_DROSS_TOTAL_FINISH_GOOD,
			CHART_TOTAL_FINISHED_GOOD,
			nitroID: this.$route.params.id,
			productionSortBy: perDay,
			nitrogenFlowRateSortBy: perDay,
			nitrogenConsumptionSortBy: perWeek,
			drossGenerationSortBy: perWeek,
			solderConsumptionSortBy: perWeek,
			filterSelected: perWeek,
			nitrogenFlowRateDateFilterOption: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(7 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							}
						]
					}
				}
			},
			chartConfigs: {
				switchShowLabel: {
					drossGeneration: true,
					solderConsumption: true,
					nitrogenConsumption: true,
					nitrogenTotalFinishedGood: true,
					drossTotalFinishedGood: true,
					totalFinishedGood: true
				},
				totalFinishedGood: {
					barWidth: 40
				},
				drossTotalFinishedGood: {
					barWidth: 40
				},
				nitrogenTotalFinishedGood: {
					barWidth: 40
				},
				nitrogenConsumption: {
					barWidth: 40
				},
				solderConsumption: {
					barWidth: 40
				},
				drossGeneration: {
					barWidth: 40
				},
				nitrogenFlowRate: {
					barWidth: 40
				}
			}
		};
	},

	computed: {
		...mapState([
			"nitroFASInfo",
			"nitroFASFlowRate",
			"nitroFASNitrogenConsumption",
			"nitroFasDrossGernation",
			"nitroFasSolderConsumption",
			"nitroFasTotalFinishedGood",
			"nitroFasDrossTotalFinishedGood",
			"nitroFasNitrogenTotalFinishedGood"
		]),
		...mapGetters("nitroFASFlowRate", {
			getTrasnformNitrogen: "getTrasnformNitrogen"
		}),
		getNitrogenFlowRateLineChartConfig() {
			const nitrogenFlowRateSortBy = this.nitrogenFlowRateSortBy;
			return trasnformnitroFASNitrogenFlowRateLineChart(nitrogenFlowRateSortBy, this.getTrasnformNitrogen);
		},
		getNitrogenConsumptionBarChartConfig() {
			const isShowDay = [perWeek, perTwoWeek, perMonth].indexOf(this.nitrogenConsumptionSortBy) >= 0;
			const options = {
				dateReportFormat: isShowDay ? "day" : "year",
				barColor: "#005942",
				barSize: this.chartConfigs.nitrogenConsumption.barWidth
			};
			const xyAxis = transformDataBarChart(this.nitroFASNitrogenConsumption, options);
			const optionNitrogenConsumption = {
				yAxisText: `Nitrogen consumption (${NM_UNIT})`,
				series: {
					tooltip: {
						show: true,
						trigger: "axis",
						backgroundColor: "#103332",
						axisPointer: {
							type: "none"
						},
						padding: 8,
						formatter: ([{ value, name }]) => {
							let result = "";
							if (value === null) {
								result = "n/a";
							} else {
								result = `${value} Nm<sup>3</sup>`;
							}
							return `
					<div>
						<div class="bar-name meter">
							${name}
						</div>
						<div class="bar-value">
							${result}
						</div>
					</div>
				`;
						}
					}
				},
				grid: {
					left: 16,
					right: 16,
					bottom: 0,
					containLabel: true
				},
				xAxisLabel: {
					interval: 0,
					fontSize: 14,
					rotate: this.filterSelected === "1m" ? -45 : 0,
					formatter: (value) => {
						const switchLabel = this.chartConfigs.switchShowLabel.nitrogenConsumption;
						const filterValue = this.nitroFASNitrogenConsumption?.data?.filter((item) => {
							const dateFormated = getDateReportFormat(getDateReportType(this.filterSelected))(item.date);
							return dateFormated === value;
						});
						const dataValue = `{${switchLabel ? "labelValue" : "hide"}|${filterValue[0]?.value || 0}}`;
						const divider = `${this.filterSelected === "1m" ? ` ` : `\n`}`;
						return [`${value}${divider}${dataValue}`];
					},
					rich: {
						labelValue: {
							backgroundColor: "rgba(0, 50, 108, 0.09)",
							borderRadius: 4,
							margin: [0, 0, 4, 0],
							padding: [2, 4, 2, 4],
							fontFamily: "TFSrivichai",
							fontSize: 14,
							align: "center"
						},
						hide: {
							backgroundColor: "rgba(0, 0, 0, 0.00)",
							color: "white",
							borderRadius: 4,
							margin: [0, 0, 4, 0],
							padding: [2, 4, 2, 4],
							fontFamily: "TFSrivichai",
							fontSize: 14,
							align: "center"
						}
					}
				}
			};
			return transformBarChart(optionNitrogenConsumption)(xyAxis);
		},
		getSolderConsumptionBarChartConfig() {
			const isShowDay = [perWeek, perTwoWeek, perMonth].indexOf(this.solderConsumptionSortBy) >= 0;
			const options = {
				dateReportFormat: isShowDay ? "day" : "year",
				barColor: "#00a8e1",
				barSize: this.chartConfigs.solderConsumption.barWidth
			};
			const xyAxis = transformDataBarChart(this.nitroFasSolderConsumption, options);
			const optionNitrogenSolderConsumption = {
				yAxisText: `Solder consumption (kg)`,
				series: {
					tooltip: {
						show: true,
						trigger: "axis",
						backgroundColor: "#103332",
						axisPointer: {
							type: "none"
						},
						padding: 8,
						formatter: ([{ value, name }]) => {
							let result = "";
							if (value === null) {
								result = "n/a";
							} else {
								result = `${value} kg`;
							}
							return `
					<div>
						<div class="bar-name meter">
							${name}
						</div>
						<div class="bar-value">
							${result}
						</div>
					</div>
				`;
						}
					}
				},
				grid: {
					left: 16,
					right: 16,
					bottom: 0,
					containLabel: true
				},
				xAxisLabel: {
					interval: 0,
					fontSize: 14,
					rotate: this.filterSelected === "1m" ? -45 : 0,
					formatter: (value) => {
						const switchLabel = this.chartConfigs.switchShowLabel.solderConsumption;
						const filterValue = this.nitroFasSolderConsumption?.data?.filter((item) => {
							const dateFormated = getDateReportFormat(getDateReportType(this.filterSelected))(item.date);
							return dateFormated === value;
						});
						const dataValue = `{${switchLabel ? "labelValue" : "hide"}|${filterValue[0]?.value || 0}}`;
						const divider = `${this.filterSelected === "1m" ? ` ` : `\n`}`;
						return [`${value}${divider}${dataValue}`];
					},
					rich: {
						labelValue: {
							backgroundColor: "rgba(0, 50, 108, 0.09)",
							borderRadius: 4,
							margin: [0, 0, 4, 0],
							padding: [2, 4, 2, 4],
							fontFamily: "TFSrivichai",
							fontSize: 14,
							align: "center"
						},
						hide: {
							backgroundColor: "rgba(0, 0, 0, 0.00)",
							color: "white",
							borderRadius: 4,
							margin: [0, 0, 4, 0],
							padding: [2, 4, 2, 4],
							fontFamily: "TFSrivichai",
							fontSize: 14,
							align: "center"
						}
					}
				}
			};
			return transformBarChart(optionNitrogenSolderConsumption)(xyAxis);
		},
		getDrossGenerationBarChartConfig() {
			const isShowDay = [perWeek, perTwoWeek, perMonth].indexOf(this.drossGenerationSortBy) >= 0;
			const options = {
				dateReportFormat: isShowDay ? "day" : "year",
				barSize: this.chartConfigs.drossGeneration.barWidth
			};
			const xyAxis = transformDataBarChart(this.nitroFasDrossGernation, options);
			const optionDrossGeneration = {
				yAxisText: `Dross generation (kg)`,
				series: {
					tooltip: {
						show: true,
						trigger: "axis",
						backgroundColor: "#103332",
						axisPointer: {
							type: "none"
						},
						padding: 8,
						formatter: ([{ value, name }]) => {
							let result = "";
							if (value === null) {
								result = "n/a";
							} else {
								result = `${value} kg`;
							}
							return `
					<div>
						<div class="bar-name meter">
							${name}
						</div>
						<div class="bar-value">
							${result}
						</div>
					</div>
				`;
						}
					}
				},
				grid: {
					left: 30,
					right: 30,
					bottom: 0,
					containLabel: true
				},
				xAxisLabel: {
					interval: 0,
					fontSize: 14,
					rotate: this.filterSelected === "1m" ? -45 : 0,
					formatter: (value) => {
						const switchLabel = this.chartConfigs.switchShowLabel.drossGeneration;
						const filterValue = this.nitroFasDrossGernation?.data?.filter((item) => {
							const dateFormated = getDateReportFormat(getDateReportType(this.filterSelected))(item.date);
							return dateFormated === value;
						});
						const dataValue = `{${switchLabel ? "labelValue" : "hide"}|${filterValue[0]?.value || 0}}`;
						const divider = `${this.filterSelected === "1m" ? ` ` : `\n`}`;
						return [`${value}${divider}${dataValue}`];
					},
					rich: {
						labelValue: {
							backgroundColor: "rgba(0, 50, 108, 0.09)",
							borderRadius: 4,
							margin: [0, 0, 4, 0],
							padding: [2, 4, 2, 4],
							fontFamily: "TFSrivichai",
							fontSize: 14,
							align: "center"
						},
						hide: {
							backgroundColor: "rgba(0, 0, 0, 0.00)",
							color: "white",
							borderRadius: 4,
							margin: [0, 0, 4, 0],
							padding: [2, 4, 2, 4],
							fontFamily: "TFSrivichai",
							fontSize: 14,
							align: "center"
						}
					}
				}
			};
			return transformBarChart(optionDrossGeneration)(xyAxis);
		},
		productionDateSelected() {
			const dateRange = this.productionFilterDateOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return { dateNumber, dateRange };
		},
		nitrogenFlowRateDateSelected() {
			const dateRange = this.nitrogenFlowRateDateFilterOption.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange
			};
		},
		filterChartOptions() {
			return [
				{
					text: "Last 7 days",
					value: perWeek
				},
				{
					text: "Last 2 weeks",
					value: perTwoWeek
				},
				{
					text: "Last 1 month",
					value: perMonth
				},
				{
					text: "Last 1 year",
					value: perYear
				}
			];
		}
	},

	async created() {
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("nitroFASFlowRate", {
			getNitrogenFlowRate: "getNitrogenFlowRate"
		}),
		...mapActions("nitroFASNitrogenConsumption", {
			getNitrogenConsumption: "getNitrogenConsumption"
		}),
		...mapActions("nitroFasDrossGernation", {
			getDrossGeneration: "getDrossGeneration"
		}),
		...mapActions("nitroFasSolderConsumption", {
			getSolderConsumption: "getSolderConsumption"
		}),
		...mapActions("nitroFasTotalFinishedGood", {
			getNitroFasTotalFinishedGood: "getNitroFasTotalFinishedGood"
		}),
		...mapActions("nitroFasDrossTotalFinishedGood", {
			getNitroFasDrossTotalFinishedGood: "getNitroFasDrossTotalFinishedGood"
		}),
		...mapActions("nitroFasNitrogenTotalFinishedGood", {
			getNitroFasNitrogenTotalFinishedGood: "getNitroFasNitrogenTotalFinishedGood"
		}),
		fetchData() {
			return Promise.all([
				this.getNitrogenFlowRate({ id: this.nitroID, query: { filter: this.nitrogenFlowRateDateSelected.dateNumber } }),
				this.getNitrogenConsumption({ id: this.nitroID }),
				this.getSolderConsumption({ id: this.nitroID }),
				this.getDrossGeneration({ id: this.nitroID }),
				this.getNitroFasTotalFinishedGood({ id: this.nitroID }),
				this.getNitroFasDrossTotalFinishedGood({ id: this.nitroID }),
				this.getNitroFasNitrogenTotalFinishedGood({ id: this.nitroID })
			]);
		},
		handleNitrogenFlowRateDateSelect(date) {
			const { start, end } = date;
			this.nitrogenFlowRateSortBy = perWeek;
			this.getNitrogenFlowRate({
				id: this.nitroID,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},
		handleNitrogenFlowRateDateDropdown(filterBy) {
			this.nitrogenFlowRateSortBy = filterBy;
			this.getNitrogenFlowRate({
				id: this.nitroID,
				query: { filter: filterBy }
			});
		},
		async handleNitrogenConsumptionDateSelected(date) {
			const { nitroID } = this;
			this.nitrogenConsumptionSortBy = date;
			this.chartConfigs.nitrogenConsumption.barWidth = getBarChartWidth(this.nitrogenConsumptionSortBy);
			await this.getNitrogenConsumption({
				id: nitroID,
				query: { filter: this.nitrogenConsumptionSortBy }
			});
		},
		async handleSolderConsumptionDateSelected(date) {
			const { nitroID } = this;
			this.solderConsumptionSortBy = date;
			this.chartConfigs.solderConsumption.barWidth = getBarChartWidth(this.solderConsumptionSortBy);
			await this.getSolderConsumption({
				id: nitroID,
				query: { filter: this.solderConsumptionSortBy }
			});
		},
		async handleDrossGenerationDateSelected(date) {
			const { nitroID } = this;
			this.drossGenerationSortBy = date;
			this.chartConfigs.drossGeneration.barWidth = getBarChartWidth(this.drossGenerationSortBy);
			await this.getDrossGeneration({
				id: nitroID,
				query: { filter: this.drossGenerationSortBy }
			});
		},
		handleTotalFinishedGood(params = {}) {
			this.getNitroFasTotalFinishedGood({ id: this.nitroID, params });
			this.chartConfigs.totalFinishedGood.barWidth = getBarChartWidth(params.filter);
		},
		handleDrossTotalFinishedGood(params = {}) {
			this.getNitroFasDrossTotalFinishedGood({ id: this.nitroID, params });
			this.chartConfigs.drossTotalFinishedGood.barWidth = getBarChartWidth(params.filter);
		},
		handleNitrogenTotalFinishedGood(params = {}) {
			this.getNitroFasNitrogenTotalFinishedGood({ id: this.nitroID, params });
			this.chartConfigs.nitrogenTotalFinishedGood.barWidth = getBarChartWidth(params.filter);
		},
		handleClickedFilter(value) {
			this.filterSelected = value;
			this.handleTotalFinishedGood({ filter: value });
			this.handleDrossTotalFinishedGood({ filter: value });
			this.handleNitrogenTotalFinishedGood({ filter: value });
			this.handleNitrogenConsumptionDateSelected(value);
			this.handleSolderConsumptionDateSelected(value);
			this.handleDrossGenerationDateSelected(value);
		},
		handleLabelSwitch(check, chartTitle) {
			switch (chartTitle) {
				case CHART_DROSS_GENERATION:
					this.chartConfigs.switchShowLabel.drossGeneration = !this.chartConfigs.switchShowLabel.drossGeneration;
					break;
				case CHART_SOLDER_CONSUMPTION:
					this.chartConfigs.switchShowLabel.solderConsumption = !this.chartConfigs.switchShowLabel.solderConsumption;
					break;
				case CHART_NITROGEN_CONSUMPTION:
					this.chartConfigs.switchShowLabel.nitrogenConsumption = !this.chartConfigs.switchShowLabel.nitrogenConsumption;
					break;
				case CHART_NITROGEN_TOTAL_FINISH_GOOD:
					this.chartConfigs.switchShowLabel.nitrogenTotalFinishedGood = !this.chartConfigs.switchShowLabel.nitrogenTotalFinishedGood;
					break;
				case CHART_DROSS_TOTAL_FINISH_GOOD:
					this.chartConfigs.switchShowLabel.drossTotalFinishedGood = !this.chartConfigs.switchShowLabel.drossTotalFinishedGood;
					break;
				case CHART_TOTAL_FINISHED_GOOD:
					this.chartConfigs.switchShowLabel.totalFinishedGood = !this.chartConfigs.switchShowLabel.totalFinishedGood;
					break;

				default:
					break;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.row-eq-height,
.row-eq-height > [class^=col]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.container-filter {
	display: flex;
	justify-content: flex-end;

	@include mobile {
		.button-group-container {
			width: 100%;

			&::v-deep .btn {
				font-size: $font-16;
				height: rem(48);
				justify-content: center;
				width: 50%;
			}
		}
	}
}

.filter-button-group {
	margin-top: rem(-7);
	margin-bottom: rem($spacing-6);

	@include mobile {
		margin-bottom: rem($spacing-3);
	}

	/* stylelint-disable */
	&::v-deep .button-group:first-child {
		border-radius: 8px 0 0 8px;
	}

	&::v-deep .button-group:last-child {
		border-radius: 0 8px 8px 0;
	}
	/* stylelint-enable */
}

.custom-module-overview {
	/* stylelint-disable */
	&::v-deep .overview-info:last-of-type {
		.title {
			width: 40%;
			text-align: right;
		}

		.value {
			justify-content: flex-end;
		}

		.bar-g-chart {
			width: rem(202);
		}
	}
	/* stylelint-enable */
}

.nitrofas-level-container {
	@include mobile {
		border-radius: 0;
		margin-left: rem(-16);
		margin-right: rem(-16);
		padding: rem(16) 0;
	}

	::v-deep {
		.chart {
			h4 {
				@include mobile {
					align-items: start;
					flex-direction: column;
				}

				.last-update {
					@include mobile {
						margin-left: 0;
					}
				}
			}

			.detail-card-header {
				@include mobile {
					margin-top: rem(8);
				}
			}
		}
	}
}
</style>
