/**
 * Parse Customer List
 * @param {Object} input customer list data
 * @returns {CustomerList}
 */
export function parseCustomerList(input) {
	if (!input) {
		return [];
	}

	return input.map((obj) => {
		return {
			id: obj.id,
			companyName: obj.companyName,
			name: obj.name,
			email: obj.email,
			role: obj.role.displayName,
			isActive: obj.isActive,
			isTestAccount: obj.isTestAccount
		};
	});
}

/**
 * Parse Customer Role Permissions
 * @param {Object} input customer role permisions
 * @returns {CustomerList}
 */
export function parseCustomerRolePermissions(input = {}) {
	const actions = {
		view: {
			title: null,
			active: null,
			permissions: [],
			preselect: []
		},
		export: {
			title: null,
			active: null,
			permissions: [],
			preselect: []
		},
		approval: {
			title: null,
			active: null,
			permissions: [],
			preselect: []
		},
		manage: {
			title: null,
			active: null,
			permissions: [],
			preselect: []
		}
	};
	const defaultObj = {
		dashboard: {
			title: null,
			actions: {
				...actions
			}
		},
		diagram: {
			title: null,
			actions: {
				...actions
			}
		},
		invoice: {
			title: null,
			actions: {
				...actions
			}
		},
		eOrdering: {
			title: null,
			actions: {
				...actions
			}
		}
	};
	if (!input || typeof input !== "object") {
		return defaultObj;
	}

	const resultObj = { ...defaultObj };
	for (const key of Object.keys(input)) {
		resultObj[key] = { ...input[key] };
	}
	return resultObj;
}