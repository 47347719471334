<template>
	<a href="#" class="back" @click="handlerBack">
		<FontAwesomeIcon :icon="['far', 'chevron-circle-left']" />
		{{ label ? label : "Back" }}
	</a>
</template>

<script>
import { getDashBoardQuery } from "../selectors/helpers/backtoDashBoard";
import { PAGE_NAME } from "../enums/pagePermission";
import { cleanParams } from "../selectors/helpers";

const { DASHBOARD } = PAGE_NAME;

export default {
	name: "BaseBackBtn",

	props: {
		to: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		}
	},
	methods: {
		handlerBack() {
			const { module = "", pageName, ...query } = getDashBoardQuery();
			const cleanQueryParams = cleanParams(JSON.parse(JSON.stringify(query)));

			const { to: name } = this.$props;
			if (name) {
				return this.$router.push({
					name
				});
			}

			if (pageName) {
				return this.$router.push({
					name: pageName,
					query: {
						...cleanQueryParams
					}
				});
			}

			if (module === null) {
				return this.$router.push({
					name: DASHBOARD,
					query: {
						...cleanQueryParams
					}
				});
			}

			return this.$router.push({
				path: `/dashboard/module/${module}`.trim(),
				query: {
					...cleanQueryParams
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.back {
	color: $back-color-btn;
	font-size: $font-18;
	font-weight: bold;
	display: inline-flex;
	align-items: center;

	.fa-chevron-circle-left {
		position: relative;
		top: -1px;
		font-size: $font-16;
		margin-right: rem(8);
	}
}
</style>
