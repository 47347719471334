<template>
	<div class="col-12 col-xl-5 no-gutter-sm">
		<BaseCard>
			<template v-slot:header>
				<h4>Ship-to location</h4>
			</template>
			<template v-slot:body>
				<ShipToMap
					v-if="info.shipToLatitude && info.shipToLongitude"
					:lat="info.shipToLatitude"
					:lng="info.shipToLongitude"
				/>
				<BaseNoData
					v-else
					description="BIG portal will start showing the location right after we have information"
				/>
			</template>
		</BaseCard>
	</div>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import ShipToMap from "@/components/map/ShipToMap.vue";

export default {
	name: "ModuleLocation",

	props: {
		info: {
			type: Object,
			default: () => ({})
		}
	},

	components: {
		BaseCard,
		BaseNoData,
		ShipToMap
	}
};
</script>

<style lang="scss" scoped>
.card {
	width: 100%;
	height: calc(100% - rem(34));

	@include mobile {
		border-radius: 0;
	}
}
</style>
