<template>
	<div id="app">
		<BaseToast/>
		<router-view :key="$route.fullPath" />
		<ModalUserProfile :modal-name="MODAL_NAME_USER_PROFILE"/>
		<ModalChangePasssord :modal-name="MODAL_NAME_CHANGE_PASSWORD"/>
		<ModalRequestChangePassword :modal-name="MODAL_REQUEST_CHANGE_PASSWORD"/>
		<ModalCompanyOverView width="100%" height="100%" :modal-name="MODAL_COMPANY_OVERVIEW_MOBILE"/>
		<ModalCompanyOverView :modal-name="MODAL_COMPANY_OVERVIEW"/>
		<ModalBusinessPhilosophy width="100%" height="100%" :modal-name="MODAL_BUSINESS_PHILOSOPHY_MOBILE"/>
		<ModalBusinessPhilosophy :modal-name="MODAL_BUSINESS_PHILOSOPHY"/>
		<ModalContactUs :modal-name="MODAL_CONTACT_US"/>
		<NoticeCookiePolicy />
	</div>
</template>

<script>
import BaseToast from "@/components/BaseToast.vue";
import ModalCompanyOverView from "@/components/modal/ModalCompanyOverView.vue";
import ModalBusinessPhilosophy from "@/components/modal/ModalBusinessPhilosophy.vue";
import ModalContactUs from "@/components/modal/ModalContactUs.vue";
import ModalUserProfile from "@/components/userprofile/ModalUserProfile.vue";
import ModalChangePasssord from "@/components/changepassword/ModalChangePassword.vue";
import ModalRequestChangePassword from "@/components/changepassword/ModalRequestChangePassword.vue";
import NoticeCookiePolicy from "@/components/NoticeCookiePolicy.vue";
import { MODAL_NAME } from "./enums/modal";

const {
	MODAL_NAME_USER_PROFILE,
	MODAL_NAME_CHANGE_PASSWORD,
	MODAL_REQUEST_CHANGE_PASSWORD,
	MODAL_COMPANY_OVERVIEW,
	MODAL_COMPANY_OVERVIEW_MOBILE,
	MODAL_BUSINESS_PHILOSOPHY,
	MODAL_BUSINESS_PHILOSOPHY_MOBILE,
	MODAL_CONTACT_US
} = MODAL_NAME;

export default {
	name: "App",
	data() {
		return {
			MODAL_NAME_USER_PROFILE,
			MODAL_NAME_CHANGE_PASSWORD,
			MODAL_REQUEST_CHANGE_PASSWORD,
			MODAL_COMPANY_OVERVIEW,
			MODAL_COMPANY_OVERVIEW_MOBILE,
			MODAL_BUSINESS_PHILOSOPHY,
			MODAL_BUSINESS_PHILOSOPHY_MOBILE,
			MODAL_CONTACT_US
		};
	},
	components: {
		BaseToast,
		ModalCompanyOverView,
		ModalBusinessPhilosophy,
		ModalUserProfile,
		ModalChangePasssord,
		ModalRequestChangePassword,
		ModalContactUs,
		NoticeCookiePolicy
	}
};
</script>
