<template>
	<section class="pagination-container">
		<ul class="pagination">
			<li>
				<button
					:disabled="isFirstPage"
					@click.prevent="prevPage"
					class="pagination-prev"
				>
					<FontAwesomeIcon :icon="['far', 'chevron-circle-left']" />
				</button>
			</li>
			<li
				v-for="paginator in paginators"
				:key="paginator.id"
				class="pagination-button"
			>
				<a
					:class="['pagination-link', {
						'current-page': currentPage === paginator.value,
						'disabled': !paginator.enable
					}]"
					href="#" @click.prevent="setPage(paginator.value)"
				>
					{{ paginator.value }}
				</a>
			</li>
			<li>
				<button
					:disabled="isLastPage"
					@click.prevent="nextPage"
					class="pagination-next"
				>
					<FontAwesomeIcon :icon="['far', 'chevron-circle-right']" />
				</button>
			</li>
		</ul>
	</section>
</template>

<script>
import { getPaginations } from "../selectors/base/pagination";

export default {
	name: "BasePagination",

	props: {
		totalPage: {
			type: Number,
			default: 1
		},
		offsetPage: {
			type: Number,
			default: 4
		},
		value: {
			type: Number,
			default: 1
		}
	},

	computed: {
		firstPage() {
			return 1;
		},
		lastPage() {
			return this.totalPage;
		},
		isFirstPage() {
			return this.currentPage === this.firstPage;
		},
		isLastPage() {
			return this.currentPage === this.lastPage;
		},
		currentPage() {
			return this.value;
		},
		paginators() {
			return getPaginations({
				firstPage: this.firstPage,
				lastPage: this.lastPage,
				currentPage: this.currentPage,
				offsetPage: this.offsetPage
			});
		}
	},

	methods: {
		nextPage() {
			this.$emit("onPageChange", this.value + 1);
		},
		prevPage() {
			this.$emit("onPageChange", this.value - 1);
		},
		setPage(n) {
			this.$emit("onPageChange", n);
		}
	}
};
</script>

<style lang="scss" scoped>
.pagination-container {
	@include fontBase();

	display: flex;
	align-items: center;
	justify-content: center;
	margin: rem(40) 0;

	.pagination-label {
		margin-right: rem(24);
	}

	.pagination {
		display: flex;
		align-items: center;

		.current-page {
			&,
			&:hover {
				background-color: $color-cerulean;
				color: $color-white;
				font-weight: 600;
			}
		}

		&-link, &-prev, &-next {
			color: $color-cerulean;
			display: block;
			width: rem(26);
			height: rem(26);
			border-radius: 50%;
			text-align: center;
			font-size: $font-18;
			font-weight: 600;
			margin: 0 rem(3);

			&.disabled, &:disabled {
				color: $color-light-teal;
				pointer-events: none;
			}
		}

		&-link {
			&:hover {
				background-color: $color-ice;
				color: $color-cerulean;
			}

			&.disabled {
				margin: 0 rem(8);
			}
		}

		&-prev, &-next {
			border: none;
			background: none;
			outline: none;
			display: flex;
			align-items: center;

			&:disabled:hover {
				cursor: default;
			}

			&:hover {
				opacity: 0.8;
			}
		}

		&-prev {
			margin-right: rem(24);
		}

		&-next {
			margin-left: rem(24);
		}
	}
}

</style>
