<template>
	<div>
		<CardBarChart v-if="showDataLabel"
			key="drossTotalFinishedGood1"
			:chartData="chartOptions"
			:isLoading="loading"
			:isEmpty="empty"
			:lastUpdate="updatedAt"
			:lastUpdateLeft="true"
			:chartTitle="chartTitle"
			@dateSelected="handleSelectDateRange"
			:showDataLabel="showDataLabel"
			@handleLabelSwitch="handleLabelSwitch"
		/>
		<CardBarChart v-if="!showDataLabel"
			key="drossTotalFinishedGood2"
			:chartData="chartOptions"
			:isLoading="loading"
			:isEmpty="empty"
			:lastUpdate="updatedAt"
			:lastUpdateLeft="true"
			:chartTitle="chartTitle"
			@dateSelected="handleSelectDateRange"
			:showDataLabel="showDataLabel"
			@handleLabelSwitch="handleLabelSwitch"
		/>
	</div>
</template>

<script>
import Numeral from "numeral";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import {
	getDateNumber,
	convertFilterByOfPowerChart,
	getDateReportFormat,
	getDateTooltipFormat,
	getDateReportType
} from "../../selectors/helpers";

export default {
	props: {
		data: {
			type: Array,
			default: () => ([])
		},
		updatedAt: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Boolean,
			default: false
		},
		chartTitle: {
			type: String,
			default: "Dross: Total Finished Good"
		},
		chartYAxisLabel: {
			type: String,
			default: "Dross : Total Finished Good (kg/Unit)"
		},
		barWidth: {
			required: true,
			type: Number
		},
		filterSelected: {
			required: true,
			type: String
		},
		showDataLabel: {
			type: Boolean,
			default: null
		}
	},

	components: {
		CardBarChart
	},

	computed: {
		chartOptions() {
			return {
				tooltip: {
					show: true,
					trigger: "axis",
					backgroundColor: "#103332",
					axisPointer: {
						type: "none"
					},
					padding: 8,
					formatter: ([a]) => {
						let value = "";
						if (a.data.value === null) {
							value = "n/a";
						} else {
							value = `${a.data.value} kg`;
						}
						return `
							<div>
								<div class="bar-name meter">
									${a.data.date}
								</div>
								<div class="bar-value">
									${value}
								</div>
							</div>
						`;
					}
				},
				grid: {
					left: "16px",
					right: "16px",
					bottom: "8px",
					containLabel: true
				},
				xAxis: {
					type: "category",
					data: (() => {
						return this.data.reduce((acc, cur) => {
							const d = getDateReportFormat(getDateReportType(this.filterSelected))(cur.date);
							acc.push(d);
							return acc;
						}, []);
					})(),
					axisLine: {
						lineStyle: {
							color: "#cad9cf"
						}
					},
					axisLabel: {
						interval: 0,
						fontSize: 14,
						rotate: this.filterSelected === "1m" ? -45 : 0,
						formatter: (value) => {
							const filterValue = this.data.filter((item) => {
								const dateFormated = getDateReportFormat(getDateReportType(this.filterSelected))(item.date);
								return dateFormated === value;
							});
							const dataValue = `{${this.showDataLabel ? "labelValue" : "hide"}|${filterValue[0]?.drossProducts || 0}}`;
							const divider = `${this.filterSelected === "1m" ? ` ` : `\n`}`;
							return [`${value}${divider}${dataValue}`];
						},
						rich: {
							labelValue: {
								backgroundColor: "rgba(0, 50, 108, 0.09)",
								borderRadius: 4,
								margin: [0, 0, 4, 0],
								padding: [2, 4, 2, 4],
								fontFamily: "TFSrivichai",
								fontSize: 14,
								align: "center"
							},
							hide: {
								backgroundColor: "rgba(0, 0, 0, 0.00)",
								color: "white",
								borderRadius: 4,
								margin: [0, 0, 4, 0],
								padding: [2, 4, 2, 4],
								fontFamily: "TFSrivichai",
								fontSize: 14,
								align: "center"
							}
						}
					},
					axisTick: {
						show: false
					}
				},
				yAxis: {
					name: this.chartYAxisLabel,
					type: "value",
					color: "#59e07a",
					nameLocation: "end",
					nameTextStyle: {
						color: "#717171",
						fontFamily: "TFSrivichai",
						fontWeight: "bold",
						fontSize: 20,
						padding: [
							0,
							0,
							24,
							-23
						],
						align: "left"
					},
					axisLabel: {
						fontSize: 14,
						formatter: (value) => {
							return Numeral(value).format("0[.]0a");
						}
					},
					axisLine: {
						lineStyle: {
							color: "#cad9cf"
						}
					},
					axisTick: {
						length: 4,
						lineStyle: {
							width: 1,
							color: "#586c63"
						}
					}
				},
				axisLabel: {
					fontSize: 14,
					fontFamily: "TFSrivichai",
					color: "#103332"
				},
				series: [
					{
						name: "Finished Goods",
						type: "bar",
						stack: "one",
						barWidth: `${this.barWidth}px`,
						color: "#ffcb00",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								acc.push({
									date: getDateTooltipFormat(getDateReportType(this.filterSelected))(cur.date),
									value: cur.drossProducts,
									itemStyle: {
										color: "#ffcb00",
										barBorderRadius: [
											2,
											2,
											0,
											0
										]
									}
								});
								return acc;
							}, []);
						})(),
						markLine: {
							silent: false,
							tooltip: {
								show: false
							},
							symbol: [
								"none",
								"none"
							],
							lineStyle: {
								type: "dashed",
								width: 2
							},
							label: {
								fontFamily: "TFSrivichai",
								color: "#fff",
								fontWeight: "bold",
								fontSize: 14,
								position: "insideMiddle"
							},
							data: [
								{
									name: "Control line 1",
									yAxis: 0.58,
									lineStyle: {
										color: "#ea7125"
									},
									label: {
										backgroundColor: "#ea7125",
										padding: [
											2,
											8
										],
										width: 50,
										height: 14,
										borderRadius: 100
									}
								},
								{
									name: "Control line 2",
									yAxis: 0,
									lineStyle: {
										color: "#ea7125"
									},
									label: {
										backgroundColor: "#ea7125",
										padding: [
											2,
											8
										],
										width: 50,
										height: 14,
										borderRadius: 100
									}
								}
							]
						}
					},
					{
						name: "Control line 1",
						type: "line",
						data: [
							0.58
						],
						symbol: "none"
					},
					{
						name: "Control line 2",
						type: "line",
						data: [
							0
						],
						symbol: "none"
					}
				]
			};
		}
	},

	methods: {
		handleSelectDateRange(dateRange) {
			const filterBy = getDateNumber(dateRange);
			const filter = convertFilterByOfPowerChart(filterBy);
			this.$emit("filter", { filter });
		},
		handleLabelSwitch(check, name) {
			this.$emit("handleLabelSwitch", check, name);
		}
	}
};
</script>
