<template>
	<div :class="['loading-container', { fullpage: isFullPage, fullsize: isFullSize }]">
		<div class="spinner">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseLoading",

	props: {
		isFullPage: {
			type: Boolean,
			default: false
		},
		isFullSize: {
			type: Boolean,
			default: false
		}
	}
};
</script>
<style lang="scss" scoped>
.loading-container {
	display: flex;

	&.fullpage {
		height: calc(100vh - #{rem(28)});
		align-items: center;
	}

	&.fullsize {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.spinner {
	margin: rem(20) auto;
	text-align: center;
}

.spinner > div {
	width: rem(14);
	height: rem(14);
	margin: 0 rem(2);
	background-color: $color-emerald;

	border-radius: 100%;
	display: inline-block;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { transform: scale(0); }

	40% { transform: scale(1); }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}
</style>
