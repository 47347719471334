import httpClient from "./httpClient";

const PASSWORD_FORGOT = "forgot-password";
const PASSWORD_CHANGE = "change-password";
const postForgotPassword = (email) => httpClient.post(PASSWORD_FORGOT, {
	email
});

const postResetPassword = (token, { password, passwordConfirmation }) => httpClient.post(
	`
	${PASSWORD_FORGOT}/reset`, {
		password,
		passwordConfirmation
	},
	{
		params: {
			token
		}
	}
);

const postVerifyTokenPassword = (token) => httpClient.post(`${PASSWORD_FORGOT}/verify`, {
	token
});

const postChangePassword = (email) => httpClient.post(`/me/${PASSWORD_CHANGE}`, {
	email
});

const postVerifyTokenChangePassword = (token) => httpClient.post(`/me/${PASSWORD_CHANGE}/verify`, {
	token
});

export {
	postForgotPassword,
	postResetPassword,
	postVerifyTokenPassword,
	postVerifyTokenChangePassword,
	postChangePassword
};
