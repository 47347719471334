import { getLngInfoAPI } from "@/services/api/lng.api";
import {
	TANK_INFO_REQUEST,
	AUTO_TANK_INFO_REQUEST,
	TANK_INFO_SUCCESS,
	AUTO_TANK_INFO_SUCCESS,
	TANK_INFO_FAILURE
} from "../../types";
import { parseLngInfo } from "../../../services/api/transforms/parseModules";

export default {

	state: {
		data: {},
		isLoading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	getters: {
		getLngShipToLocation(state) {
			const position = {
				lat: state.data.shipToLatitude,
				lng: state.data.shipToLongitude
			};
			return position;
		}
	},

	mutations: {
		[TANK_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[AUTO_TANK_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[TANK_INFO_SUCCESS](state, { data, status }) {
			state.data = parseLngInfo(data);
			state.isLoading = false;
			state.statusCode = status;
		},
		[AUTO_TANK_INFO_SUCCESS](state, { data, status }) {
			state.data = parseLngInfo(data);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[TANK_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		}
	},

	actions: {
		async getLngInfo({ commit }, id) {
			try {
				commit(TANK_INFO_REQUEST);
				const response = await getLngInfoAPI(id);
				commit(TANK_INFO_SUCCESS, response);
			} catch (error) {
				commit(TANK_INFO_FAILURE, error);
			}
		},

		async autoGetLngInfo({ commit }, id) {
			try {
				commit(AUTO_TANK_INFO_REQUEST);
				const response = await getLngInfoAPI(id);
				commit(AUTO_TANK_INFO_SUCCESS, response);
			} catch (error) {
				commit(TANK_INFO_FAILURE, error);
			}
		}
	}
};
