<template>
	<div :class="['container-form', { 'edit-mode': editMode }]">
		<div
			v-if="editMode"
			class="container-form-edit"
		>
			<div class="label">
				Diagram name
			</div>
			<div class="input">
				<BaseFormInput
					v-model="form.name"
					rounded
				/>
				<button
					v-if="removeable"
					class="view-button"
					@click="onRemove"
				>
					Delete this diagram
				</button>
			</div>
			<div
				class="icon"
				@click="onToggle"
			>
				<FontAwesomeIcon :icon="['far', toggleClass]" />
			</div>
		</div>
		<div
			v-else
			class="container-form-view"
			@click="onToggle"
		>
			<div class="label">
				{{ form.name }}
			</div>
			<div class="icon">
				<FontAwesomeIcon :icon="['far', toggleClass]" />
			</div>
		</div>
	</div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
	mixins: [
		validationMixin
	],

	props: {
		input: {
			type: Object,
			default: () => ({})
		},
		active: {
			type: Boolean,
			default: false
		},
		removeable: {
			type: Boolean,
			default: false
		},
		editMode: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			form: {
				name: null
			},
			toggle: false
		};
	},

	validations: {
		form: {
			name: {
				required
			}
		}
	},

	computed: {
		toggleClass() {
			return this.toggle ? "chevron-up" : "chevron-down";
		}
	},

	watch: {
		active(newValue) {
			this.toggle = newValue;
		},
		form: {
			handler(newValue) {
				this.$emit("update:input", newValue);
			},
			deep: true
		},
		input(newValue) {
			this.form = newValue;
		}
	},

	mounted() {
		this.toggle = this.active;
		this.$emit("update:validateFn", this.validate);
	},

	methods: {
		onToggle() {
			this.toggle = !this.toggle;
			this.$emit("onToggle", this.toggle);
		},
		onRemove() {
			this.$emit("onRemove");
		},
		validate() {
			this.$v.$touch();
			return !this.$v.$invalid;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	display: flex;
	flex-direction: column;
	padding-right: rem(2);
	margin-bottom: rem(20);

	&.edit-mode {
		margin-bottom: rem(40);
	}

	&-edit,
	&-view {
		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(24);
			cursor: pointer;

			svg {
				width: rem(14);
			}
		}
	}

	&-edit {
		display: flex;

		.label {
			display: flex;
			align-items: center;
			width: rem(140);
			font-size: $font-20;
			color: $color-grey-4;
		}

		.input {
			position: relative;
			flex: 1;
			margin-right: rem(19);

			.view-button {
				position: absolute;
				bottom: rem(-34);
				right: 0;
			}
		}
	}

	&-view {
		display: flex;
		justify-content: space-between;
		cursor: pointer;

		.label {
			font-family: $font-family-sub;
			font-size: $font-24;
			color: $color-dark-blue-grey;
		}
	}
}
</style>