import { getAirCompInfoAPI, getAirCompContactAPI } from "@/services/api/airComp.api";
import {
	AIR_COMP_INFO_REQUEST,
	AUTO_AIR_COMP_INFO_REQUEST,
	AIR_COMP_INFO_SUCCESS,
	AUTO_AIR_COMP_INFO_SUCCESS,
	AIR_COMP_INFO_FAILURE,
	AIR_COMP_INFO_CLEAR
} from "../../types";
import { parseAirCompInfo } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	getters: {
		getLayout(state) {
			return state.data.layout;
		}
	},

	mutations: {
		[AIR_COMP_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[AUTO_AIR_COMP_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[AIR_COMP_INFO_SUCCESS](state, response) {
			state.data = parseAirCompInfo(response.airCompInfo.data, response.contact.data);
			state.isLoading = false;
			state.statusCode = response.airCompInfo.status;
		},
		[AUTO_AIR_COMP_INFO_SUCCESS](state, response) {
			state.data = parseAirCompInfo(response.airCompInfo.data, response.contact.data);
			state.isAutoFecthLoading = false;
			state.statusCode = response.airCompInfo.status;
		},
		[AIR_COMP_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response;
		},
		[AIR_COMP_INFO_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async fetchAirCompInfo({ commit }, id) {
			try {
				commit(AIR_COMP_INFO_REQUEST);
				const airCompInfo = await getAirCompInfoAPI(id);
				const contact = await getAirCompContactAPI(id);
				commit(AIR_COMP_INFO_SUCCESS, { airCompInfo, contact });
			} catch (error) {
				commit(AIR_COMP_INFO_FAILURE, error);
			}
		},

		async autoFetchAirCompInfo({ commit }, id) {
			try {
				commit(AUTO_AIR_COMP_INFO_REQUEST);
				const airCompInfo = await getAirCompInfoAPI(id);
				const contact = await getAirCompContactAPI(id);

				commit(AUTO_AIR_COMP_INFO_SUCCESS, { airCompInfo, contact });
			} catch (error) {
				commit(AIR_COMP_INFO_FAILURE, error);
			}
		},

		clearAirCompInfo({ commit }) {
			commit(AIR_COMP_INFO_CLEAR);
		}
	}
};
