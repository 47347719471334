import { CUSTOMER_STATUS, CUSTOMER_STATUS_LABEL } from "../../../enums/customerManagement/customerStatus";

export const getCustomerStatuses = () => {
	return Object.keys(CUSTOMER_STATUS_LABEL).map((key) => ({
		label: CUSTOMER_STATUS_LABEL[key],
		value: CUSTOMER_STATUS[key]
	}));
};

export const getCustomerStatusesFilter = () => {
	return [
		{ label: "User status: All", value: null },
		...getCustomerStatuses()
	];
};