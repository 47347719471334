import { getNotificationConfigs } from "@/services/api/notificationGroup.api";
import { NOTI_GROUP_CONFIG_REQUEST, NOTI_GROUP_CONFIG_SUCCESS, NOTI_GROUP_CONFIG_FAILURE } from "../../types";
import { parseConfig } from "../../../services/api/transforms/parseNotiGroup";

export default {
	namespaced: true,

	state: {
		data: parseConfig([]),
		loading: false
	},

	mutations: {
		[NOTI_GROUP_CONFIG_REQUEST](state) {
			state.data = parseConfig([]);
			state.loading = true;
		},
		[NOTI_GROUP_CONFIG_SUCCESS](state, data) {
			state.data = parseConfig(data);
			state.loading = false;
		},
		[NOTI_GROUP_CONFIG_FAILURE](state) {
			state.data = parseConfig([]);
			state.loading = false;
		}
	},

	actions: {
		async getNotificationConfigs({ commit }) {
			try {
				commit(NOTI_GROUP_CONFIG_REQUEST);
				const { data } = await getNotificationConfigs();
				commit(NOTI_GROUP_CONFIG_SUCCESS, data);
			} catch (error) {
				commit(NOTI_GROUP_CONFIG_FAILURE, error);
			}
		}
	}
};
