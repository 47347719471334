
export const trasnfromOptionShiptoList = (data) => {
	const result = [];
	for (const val of data) {
		const payload = {
			code: val.id.toString(),
			key: val.name,
			name: val.name
		};
		result.push(payload);
	}
	return result;
};

export const transformOptionSoldToList = (data) => {
	const result = [

	];
	if (data.length > 0) {
		result.push({
			key: "All ship to selected",
			code: "all",
			name: "Select all ship to"
		});
	}
	for (const val of data) {
		const payload = {
			code: val.id.toString(),
			key: `${val.locationKey || val.key} - ${val.code}`,
			name: `${val.locationKey || val.key} - ${val.code} - ${val.name}`
		};
		result.push(payload);
	}
	return result;
};
