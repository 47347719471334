import { getNitroFasTotalFinishedGoodAPI } from "../../../services/api/NitroFAS.api";
import { NITRO_FAS_TOTAL_FINISHED_GOOD_REQUEST, NITRO_FAS_TOTAL_FINISHED_GOOD_SUCCESS, NITRO_FAS_TOTAL_FINISHED_GOOD_FAILURE } from "../../types";
import { parseNitroFasTotalFinishedGoods } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: parseNitroFasTotalFinishedGoods([]),
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},
	mutations: {
		[NITRO_FAS_TOTAL_FINISHED_GOOD_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[NITRO_FAS_TOTAL_FINISHED_GOOD_SUCCESS](state, response) {
			state.data = parseNitroFasTotalFinishedGoods(response.data);
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[NITRO_FAS_TOTAL_FINISHED_GOOD_FAILURE](state) {
			state.data = parseNitroFasTotalFinishedGoods([]);
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getNitroFasTotalFinishedGood({ commit }, { id = "", params = {} }) {
			try {
				commit(NITRO_FAS_TOTAL_FINISHED_GOOD_REQUEST);
				const { data } = await getNitroFasTotalFinishedGoodAPI(id, params);
				commit(NITRO_FAS_TOTAL_FINISHED_GOOD_SUCCESS, data);
			} catch (error) {
				commit(NITRO_FAS_TOTAL_FINISHED_GOOD_FAILURE);
			}
		}
	}
};
