<template>
	<div class="no-result">
		<div class="no-result-icon">
			<slot name="icon">
				<FontAwesomeIcon :icon="['far', icon]" />
			</slot>
		</div>
		<div class="no-result-title">{{ title }}</div>
		<p class="no-result-description">{{ description }}</p>
	</div>
</template>

<script>
export default {
	name: "BaseNoResult",

	props: {
		icon: {
			type: String,
			default: "search"
		},

		title: {
			type: String,
			default: "No matching search results"
		},

		description: {
			type: String,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
.no-result {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: rem(32);

	@include mobile {
		padding: rem(32) rem(16);
	}

	// .no-result-icon
	&-icon {
		display: flex;
		width: rem(114);
		height: rem(114);
		justify-content: center;
		align-items: center;
		background-color: $color-pale-green;
		border-radius: 50%;
		margin-bottom: rem(16);

		svg {
			color: $color-emerald;
			font-size: $font-56;
		}
	}

	// .no-result-title
	&-title {
		font-size: $font-48;
		margin-bottom: rem(8);
		line-height: 1.2;
	}

	// .no-result-description
	&-description {
		font-size: $font-20;
		color: $color-dark-blue-grey;
		margin-top: 0;
	}
}
</style>
