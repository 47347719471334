<template>
	<div :class="tagClass" :style="getStyleTag">
		<slot />
	</div>
</template>

<script>
export default {
	name: "BaseTagStandardModule",

	props: {
		size: {
			type: String,
			default: "large",
			validator: (value) => [
				"small",
				"large"
			].includes(value)
		},
		bgColor: {
			type: String,
			default: "#000000"
		},
		borderColor: {
			type: String,
			default: "#FFFFFF"
		},
		textColor: {
			type: String,
			default: "#FFFFFF"
		}
	},

	computed: {
		getStyleTag() {
			return [{ backgroundColor: this.bgColor }, { border: `1px solid ${this.borderColor}` }, { color: this.textColor }];
		},
		tagClass() {
			return ["tag", this.size];
		}
	}
};
</script>

<style lang="scss" scoped>
.tag {
	display: flex;
	color: $tag-color;
	font-weight: 600;
	border: 1px solid $color-gunmetal-two;
	border-radius: 4px;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-left: rem(10);
	padding: 0 rem(8);
	background-color: $color-white;

	&.small {
		font-size: $font-16;
		height: rem(20);
		line-height: rem(20);
	}
}
</style>
