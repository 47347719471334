import { getTanksInfoAPI } from "@/services/api/tanks.api";
import {
	TANK_INFO_REQUEST,
	AUTO_TANK_INFO_REQUEST,
	TANK_INFO_SUCCESS,
	AUTO_TANK_INFO_SUCCESS,
	TANK_INFO_FAILURE
} from "../../types";
import { parseTankInfo } from "../../../services/api/transforms/parseModule/parseTankInfo";
import { getLayoutTemplate } from "../../../services/api/layoutCustomisation.api";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	getters: {
		getShipToLocation(state) {
			if (state.data.shipToLatitude && state.data.shipToLongitude) {
				const position = {
					lat: state.data.shipToLatitude,
					lng: state.data.shipToLongitude
				};

				return position;
			}
			return null;
		},
		getLayout(state) {
			return state.data.layout;
		},
		getUnit(state) {
			return state.data.unit;
		}
	},

	mutations: {
		[TANK_INFO_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[AUTO_TANK_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[TANK_INFO_SUCCESS](state, { info: { data, status }, template }) {
			state.data = parseTankInfo(data, template);
			state.isLoading = false;
			state.statusCode = status;
		},
		[AUTO_TANK_INFO_SUCCESS](state, { info: { data, status }, template }) {
			state.data = parseTankInfo(data, template);
			state.isAutoFecthLoading = false;
			state.statusCode = status;
		},
		[TANK_INFO_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		}
	},

	actions: {
		async getTankInfo({ commit }, id) {
			try {
				commit(TANK_INFO_REQUEST);
				const response = await getTanksInfoAPI(id);
				const template = await getLayoutTemplate({
					app: "TANK"
				});

				commit(TANK_INFO_SUCCESS, {
					info: response,
					template: template.data
				});
			} catch (error) {
				commit(TANK_INFO_FAILURE, error);
			}
		},

		async autoGetTankInfo({ commit }, id) {
			try {
				commit(AUTO_TANK_INFO_REQUEST);
				const response = await getTanksInfoAPI(id);
				const template = await getLayoutTemplate({
					app: "TANK"
				});
				commit(AUTO_TANK_INFO_SUCCESS, {
					info: response,
					template: template.data
				});
			} catch (error) {
				commit(TANK_INFO_FAILURE, error);
			}
		}
	}
};
