<template>
	<div v-if="isShow" :class="['container-main', { 'active': isShow }]">
		<component :is="currentToastComponent" v-bind="{ ...props }"></component>
	</div>
</template>

<script>
import StaffCreate from "@/components/toast/StaffCreate.vue";
import StaffUpdate from "@/components/toast/StaffUpdate.vue";
import StaffDelete from "@/components/toast/StaffDelete.vue";
import CustomerCreate from "@/components/toast/CustomerCreate.vue";
import LayoutCustomisation from "@/components/toast/LayoutCustomisation.vue";
import CustomerUpdate from "@/components/toast/CustomerUpdate.vue";
import CustomerUpdatePassword from "@/components/toast/CustomerUpdatePassword.vue";
import ConsumptionApproveSuccess from "@/components/toast/ConsumptionApproveSuccess.vue";
import ConsumptionVerifySuccess from "@/components/toast/ConsumptionVerifySuccess.vue";
import RedirectLogin from "@/components/toast/RedirectLogin.vue";
import ResponseError from "@/components/toast/ResponseError.vue";
import RESPONSE_SUCCESS from "@/components/toast/ResponseSuccess.vue";
import ToastProcessing from "@/components/toast/ToastProcessing.vue";
import { mapState } from "vuex";
import { TOAST_TYPES } from "../enums/toast";

export default {
	name: "BaseToast",

	components: {
		StaffCreate,
		StaffUpdate,
		StaffDelete,
		RedirectLogin,
		ResponseError,
		RESPONSE_SUCCESS,
		CustomerCreate,
		CustomerUpdate,
		CustomerUpdatePassword,
		ConsumptionApproveSuccess,
		ConsumptionVerifySuccess,
		LayoutCustomisation,
		ToastProcessing
	},

	data() {
		return {
			TOAST_TYPES,
			StaffCreate,
			StaffUpdate,
			StaffDelete,
			RedirectLogin,
			ResponseError,
			RESPONSE_SUCCESS,
			CustomerCreate,
			CustomerUpdate,
			CustomerUpdatePassword,
			ConsumptionApproveSuccess,
			ConsumptionVerifySuccess,
			LayoutCustomisation,
			ToastProcessing
		};
	},

	computed: {
		...mapState("toast", {
			isShow: "isShow",
			type: "type",
			props: "props"
		}),

		currentToastComponent() {
			let component;
			switch (this.type) {
				case TOAST_TYPES.STAFF_CREATE:
					component = this.StaffCreate;
					break;
				case TOAST_TYPES.STAFF_UPDATE:
					component = this.StaffUpdate;
					break;
				case TOAST_TYPES.STAFF_DELETE:
					component = this.StaffDelete;
					break;
				case TOAST_TYPES.REDIRECT_LOGIN:
					component = this.RedirectLogin;
					break;
				case TOAST_TYPES.RESPONSE_ERROR:
					component = this.ResponseError;
					break;
				case TOAST_TYPES.RESPONSE_SUCCESS:
					component = this.RESPONSE_SUCCESS;
					break;
				case TOAST_TYPES.CUSTOMER_CREATE:
					component = this.CustomerCreate;
					break;

				case TOAST_TYPES.LAYOUT_CUSTOMISATION:
					component = this.LayoutCustomisation;
					break;

				case TOAST_TYPES.CUSTOMER_UPDATE:
					component = this.CustomerUpdate;
					break;
				case TOAST_TYPES.CUSTOMER_UPDATE_PASSWORD:
					component = this.CustomerUpdatePassword;
					break;
				case TOAST_TYPES.METER_CONSUMPTION_APPROVE_SUCCESS:
					component = this.ConsumptionApproveSuccess;
					break;
				case TOAST_TYPES.METER_CONSUMPTION_VERIFY_SUCCESS:
					component = this.ConsumptionVerifySuccess;
					break;
				case TOAST_TYPES.PROCESSING:
					component = this.ToastProcessing;
					break;
				default:
					component = false;
			}

			return component;
		}
	}
};
</script>

<style lang="scss" scoped>
$border-radius-size: rem(4);

.container {
	&-main {
		position: fixed;
		z-index: $z-index-toast;
		top: rem(32);
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		min-width: rem(390);
		visibility: hidden;

		&.active {
			visibility: visible;
			animation: slideindown 500ms, slideoutup 500ms 3.5s;
		}
	}

	&-toast {
		background: $color-white;
		border-radius: $border-radius-size;
		overflow: hidden;

		&::before {
			content: " ";
			display: block;
			position: absolute;
			width: 100%;
			height: rem(8);
		}

		&.success {
			&::before {
				background: $color-emerald;
			}

			.container-icon {
				color: $color-emerald;
			}

			.container-content {
				.title {
					color: $color-emerald;
				}
			}
		}

		&.warning {
			&::before {
				background: $color-marigold;
			}

			.container-icon {
				color: $color-marigold;
			}

			.container-content {
				.title {
					color: $color-marigold;
				}
			}
		}

		&.danger {
			&::before {
				background: $color-red;
			}

			.container-icon {
				color: $color-red;
			}

			.container-content {
				.title {
					color: $color-red;
				}
			}
		}

		&.drop-shadow {
			box-shadow: $box-shadow;

			.container-body {
				border: none;
			}
		}

		.close {
			position: absolute;
			top: rem($spacing-5);
			right: rem($spacing-5);
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(24);
			height: rem(24);
			background: none;
			border: none;
			color: $color-boulder;
		}
	}

	&-body {
		display: flex;
		padding: $spacing-5 0 $spacing-5 0;
		margin-top: rem(8);
		border: 1px solid $color-silver;
		border-top: none;
		border-radius: 0 0 $border-radius-size $border-radius-size;
	}

	&-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: rem(90);
		font-size: $font-32;
	}

	&-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding-right: rem(50);

		.title, .message {
			display: flex;
			flex-grow: 1;

			span, strong {
				display: flex;
				align-items: center;
				line-height: rem(16);
			}
		}

		.title {
			font-size: $font-20;
			font-weight: $font-weight-bold;
		}

		.message {
			margin-top: rem(8);
			font-size: $font-16;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}
	}
}

@keyframes slideindown {
	from {
		transform: translate3d(-50%, -100%, 0);
	}

	to {
		transform: translate3d(-50%, 0, 0);
	}
}

@keyframes slideoutup {
	from {
		transform: translate3d(-50%, 0, 0);
	}

	to {
		transform: translate3d(-50%, rem(-200), 0);
	}
}
</style>