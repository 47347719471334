import { getUserLoginAPI } from "@/services/api/monitor.api";
import { USER_MONITOR_USER_SIGNIN_REQUEST, USER_MONITOR_USER_SIGNIN_SUCCESS, USER_MONITOR_USER_SIGNIN_FAILURE } from "../../../store/types";
import { parseUserLogins } from "../../../services/api/transforms/parseUserMonitor";

export default {
	namespaced: true,

	state: {
		data: {
			staffLogins: [],
			customerLogins: []
		},
		isLoading: false
	},
	getters: {
		getCustomerLogins(state) {
			return state.data.customerLogins || [];
		},
		getStaffLogins(state) {
			return state.data.staffLogins || [];
		}
	},

	mutations: {
		[USER_MONITOR_USER_SIGNIN_REQUEST](state) {
			state.isLoading = true;
			state.data = {};
		},
		[USER_MONITOR_USER_SIGNIN_SUCCESS](state, data) {
			state.data = parseUserLogins(data);
			state.isLoading = false;
		},
		[USER_MONITOR_USER_SIGNIN_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		async getUserLogins({ commit }, { filter }) {
			try {
				commit(USER_MONITOR_USER_SIGNIN_REQUEST);
				const { data } = await getUserLoginAPI({ filter });
				commit(USER_MONITOR_USER_SIGNIN_SUCCESS, data);
			} catch (error) {
				commit(USER_MONITOR_USER_SIGNIN_FAILURE);
			}
		}
	}
};
