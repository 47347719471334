import Numeral from "numeral";
import { transformBarChart } from "..";

const tankIcon = require("@/assets/images/tank.png");
const meterIcon = require("@/assets/images/meter.png");
const applicationIcon = require("@/assets/images/application.png");
const genGasIcon = require("@/assets/images/gengas.png");
const PowerIcon = require("@/assets/images/electricity.png");

export const transformOveralDeviceModuleDataBarChart = (totalDevices = []) => {
	const xAxis = totalDevices.map((data) => data.module);
	const yAxis = [
		{
			name: "Active",
			type: "bar",
			barWidth: "12px",
			color: "#009a44",
			data: totalDevices.map((data) => ({
				value: data.activeDevices,
				total: data.total,
				itemStyle: {
					barBorderRadius: data.activeDevices > 0 ? [50, 50, 0, 0] : [0, 0, 50, 50]
				}
			}))
		},
		{
			name: "Inactive",
			type: "bar",
			barWidth: "12px",
			color: "#afc0b6",
			data: totalDevices.map((data) => ({
				value: data.inactiveDevices,
				total: data.total,
				itemStyle: {
					barBorderRadius: data.inactiveDevices > 0 ? [50, 50, 0, 0] : [0, 0, 50, 50]
				}
			}))
		},
		{
			name: "No signal",
			type: "bar",
			barWidth: "12px",
			color: "#cf0360",
			data: totalDevices.map((data) => ({
				value: data.noSignalDevices,
				total: data.total,
				itemStyle: {
					barBorderRadius: data.noSignalDevices > 0 ? [50, 50, 0, 0] : [0, 0, 50, 50]
				}
			}))
		}
	];
	return {
		xAxis,
		yAxis
	};
};
export const optionsDevicesModulesBarChart = (totalDevices = []) => ({
	yAxisText: "Device",
	xAxisLabel: {
		formatter(value, index) {
			return `{${value}| }\n{value| Total: ${Numeral(totalDevices[index].total).format(0, 0)}}`;
		},
		rich: {
			value: {
				lineHeight: 30,
				align: "center"
			},

			Tank: {
				height: 22,
				width: 68,
				backgroundColor: {
					image: tankIcon
				}
			},

			Meter: {
				height: 22,
				width: 68,
				backgroundColor: {
					image: meterIcon
				}
			},

			Application: {
				height: 22,
				width: 68,
				backgroundColor: {
					image: applicationIcon
				}
			},

			GenGas: {
				height: 22,
				width: 68,
				backgroundColor: {
					image: genGasIcon
				}
			},

			Electricity: {
				height: 22,
				width: 68,
				backgroundColor: {
					image: PowerIcon
				}
			}

		}
	},
	legend: {
		data: ["Active", "Inactive", "No signal"],
		icon: "circle",
		bottom: 0,
		left: 26,
		itemWidth: 10,
		itemHeight: 10,
		itemGap: 24
	},
	grid: {
		left: "24px",
		right: "24px",
		bottom: "79px",
		containLabel: true
	},
	series: {
		tooltip: {
			formatter: ({ data, seriesName }) => `
		<div class="modules-device-tooltip">
            <div>
                ${seriesName}
            </div>
            <div>
                ${Numeral(data.value).format(0, 0)}
            </div>
        </div>`
		}
	}
});

export const transformDeviceModuleBarChart = (totalDevices = []) => {
	const dataConfig = transformOveralDeviceModuleDataBarChart(totalDevices);
	return transformBarChart(optionsDevicesModulesBarChart(totalDevices))(dataConfig);
};
