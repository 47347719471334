import dayjs from "dayjs";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";

const { perWeek, perTwoWeek, perYear } = MODULE_SORT_DATE_CHART;

export const transformConsumptionDataForBarChart = (data = []) => {
	const result = [];
	for (let i = 0; i < data.length; i++) {
		const consumptionValue = {
			baseVal: data[i].totalBase,
			premiumVal: data[i].totalPremium
		};
		result.push(consumptionValue);
	}

	return result;
};

export const getConsumptionDateRange = (data = []) => {
	return data.map(({ date }) => date);
};

const showDateAndMonth = (date) => {
	const isFirstMonth = dayjs(date).get("month") === 0;
	const isFirstDay = dayjs(date).get("date") === 1;
	if (isFirstDay && isFirstMonth) {
		return dayjs(date).format("D MMM\nYYYY");
	}
	return dayjs(date).format("D MMM");
};

export const transformXaxisDateFormat = (sortDateBy = perWeek) => {
	const filterData = {
		[perWeek]: showDateAndMonth,

		[perTwoWeek]: showDateAndMonth,

		[perYear]: (date) => {
			const isFirstMonth = dayjs(date).get("month") === 0;
			if (isFirstMonth) {
				return dayjs(date).format("MMM\nYYYY");
			}
			return dayjs(date).format("MMM");
		}
	};
	return filterData[sortDateBy];
};



export const transformConsumptionStackBarChart = (
	baseBarConfig = {
		color: "#ea7125"
	},
	premiumBarConfig = {
		color: "#ffcb00"
	}
) => ({
	firstBarData = [],
	secondBarData = [],
	dateRange = [],
	mergeDataValue = [],
	dateReportFormat = "years"
}) => {
	const transformdate = transformXaxisDateFormat(dateReportFormat);

	const firstData = {
		name: "Premium",
		type: "bar",
		stack: "one",
		color: premiumBarConfig.color,
		data: secondBarData.reduce((acc, { value }, index) => {
			return [
				...acc,
				{
					value: Number(value),
					itemStyle: {
						color: premiumBarConfig.color,
						barBorderRadius: [2, 2, 0, 0]
					},
					...mergeDataValue[index]
				}
			];
		}, [])
	};

	const secondData = {
		name: "Base",
		type: "bar",
		stack: "one",
		color: baseBarConfig.color,
		data: firstBarData.reduce((acc, { value }, index) => {
			return [
				...acc,
				{
					value: Number(value),
					itemStyle: {
						color: baseBarConfig.color,
						barBorderRadius: [0, 0, 0, 0]
					},
					...mergeDataValue[index]
				}
			];
		}, [])
	};


	return {
		xAxis: dateRange.map((dateValue) => transformdate(dateValue)),
		yAxis: [
			{
				...secondData
			},
			{
				...firstData
			}
		]
	};
};
