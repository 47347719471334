export default {
	data() {
		return {
			containerOriginalOffsetTop: null
		};
	},

	mounted() {
		if (this.stickyHeader) {
			window.addEventListener("scroll", this.handleWindowScrollY);
		}
	},

	destroyed() {
		if (this.stickyHeader) {
			window.removeEventListener("scroll", this.handleWindowScrollY);
		}
	},

	methods: {
		handleWindowScrollY() {
			const $table = this.$refs.table;
			const $container = $table.querySelector("thead");
			if (this.containerOriginalOffsetTop === null) {
				this.containerOriginalOffsetTop = $table.offsetTop;
			}
			if (window.scrollY > this.containerOriginalOffsetTop) {
				$container.style.transform = `translateY(${window.scrollY - this.containerOriginalOffsetTop}px)`;
			} else if (window.scrollY < this.containerOriginalOffsetTop && window.scrollY > this.containerOriginalOffsetTop) {
				$container.style.transform = `translateY(${this.containerOriginalOffsetTop - window.scrollY}px)`;
			} else {
				$container.style.transform = `translateY(0px)`;
			}
		}
	}
};