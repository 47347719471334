<template>
	<CardBarChart
		:chartData="chartOptions"
		:filterOptions="filterOptions"
		:isLoading="loading"
		:isEmpty="empty"
		:lastUpdate="updatedAt"
		:chartTitle="chartTitle"
		@dateSelected="handleSelectDateRange"
	/>
</template>

<script>
import dayjs from "dayjs";
import Numeral from "numeral";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { getDateNumber, convertFilterByOfPowerChart } from "../../selectors/helpers";

const { perDay, perWeek, perTwoWeek, perYear } = MODULE_SORT_DATE_CHART;

export default {
	props: {
		data: {
			type: Array,
			default: () => ([])
		},
		updatedAt: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Boolean,
			default: false
		},
		chartTitle: {
			type: String,
			default: "Consumption"
		},
		chartYAxisLabel: {
			type: String,
			default: "Power Consumption (kWh)"
		}
	},

	data() {
		return {
			sortBy: perDay,
			filterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			}
		};
	},

	components: {
		CardBarChart
	},

	computed: {
		chartOptions() {
			return {
				legend: {
					show: true,
					data: [
						"Off-Peak",
						"Partial-Peak",
						"On-Peak",
						"kVAR"
					],
					icon: "circle",
					bottom: 0,
					left: 24,
					itemWidth: 10,
					itemHeight: 10,
					itemGap: 24,
					fontFamily: "TFSrivichai",
					fontSize: 16,
					fontColor: "#71717",
					fontWeight: "bold"
				},
				tooltip: {
					show: true,
					trigger: "item",
					backgroundColor: "#103332",
					axisPointer: {
						type: "none"
					},
					position: "inside",
					padding: 8,
					formatter: (values) => {
						return this.showTooltip([values]);
					}
				},
				grid: {
					left: "24px",
					right: "24px",
					bottom: "48px",
					containLabel: true
				},
				xAxis: {
					type: "category",
					data: (() => {
						return this.data.reduce((acc, cur) => {
							const d = dayjs(cur.date).format("D MMM");
							acc.push(d);
							return acc;
						}, []);
					})(),
					axisLine: {
						lineStyle: {
							color: "#cad9cf"
						}
					},
					axisLabel: {
						fontSize: 14
					},
					axisTick: {
						show: false
					}
				},
				yAxis: {
					name: this.chartYAxisLabel,
					type: "value",
					color: "#59e07a",
					nameLocation: "end",
					nameTextStyle: {
						color: "#717171",
						fontFamily: "TFSrivichai",
						fontWeight: "bold",
						fontSize: 20,
						padding: [
							0,
							0,
							24,
							-23
						],
						align: "left"
					},
					axisLabel: {
						fontSize: 14,
						formatter: (value) => {
							return Numeral(value).format("0[.]0a");
						}
					},
					axisLine: {
						lineStyle: {
							color: "#cad9cf"
						}
					},
					axisTick: {
						length: 4,
						lineStyle: {
							width: 1,
							color: "#586c63"
						}
					}
				},
				axisLabel: {
					fontSize: 14,
					fontFamily: "TFSrivichai",
					color: "#103332"
				},
				series: [
					{
						name: "Off-Peak",
						type: "bar",
						stack: "one",
						barWidth: "40px",
						color: "#b4b5b4",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								acc.push({
									value: cur.offPeak,
									itemStyle: {
										color: "#b4b5b4",
										barBorderRadius: [
											0,
											0,
											0,
											0
										]
									}
								});
								return acc;
							}, []);
						})()
					},
					{
						name: "Partial-Peak",
						type: "bar",
						stack: "one",
						barWidth: "40px",
						color: "#00a8e1",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								acc.push({
									value: cur.partialPeak,
									itemStyle: {
										color: "#00a8e1",
										barBorderRadius: [
											0,
											0,
											0,
											0
										]
									}
								});
								return acc;
							}, []);
						})()
					},
					{
						name: "On-Peak",
						type: "bar",
						stack: "one",
						barWidth: "40px",
						color: "#009a44",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								acc.push({
									value: cur.onPeak,
									itemStyle: {
										color: "#009a44",
										barBorderRadius: [
											0,
											0,
											0,
											0
										]
									}
								});
								return acc;
							}, []);
						})()
					},
					{
						name: "kVAR",
						type: "bar",
						stack: "one",
						barWidth: "40px",
						color: "#b20263",
						data: (() => {
							return this.data.reduce((acc, cur) => {
								acc.push({
									value: cur.kVAR,
									itemStyle: {
										color: "#b20263",
										barBorderRadius: [
											2,
											2,
											0,
											0
										]
									}
								});
								return acc;
							}, []);
						})()
					}
				]
			};
		}
	},

	methods: {
		handleSelectDateRange(dateRange) {
			const filterBy = getDateNumber(dateRange);
			const filter = convertFilterByOfPowerChart(filterBy);
			const params = { filter };
			this.$emit("filter", params);
		},
		showTooltip(values) {
			let total = 0;

			let result = "";

			values.forEach((val) => {
				total += val.data.value;
				result += `<div class="bar-container bar-name ">
									<span>
										${val.seriesName}
									</span>
									<span>
										${val.data.value === null ? "-" : Numeral(val.data.value).format("0[.]0a")}
									</span>
								</div>`;
			});
			return `
							<div style="min-width: 150px;">
								<div class="bar-container bar-value ">
									<span>
										Total
									</span>
									<span>
										${Numeral(total).format("0[.]0a")}
									</span>
								</div>
								${result}
							</div>
						`;
		}
	}
};
</script>