import dayjs from "dayjs";
import { transformLineChart } from "../../lineChart";
import { MODULE_SORT_DATE_CHART } from "../../../../enums/filterChart";
import { UNIT_TYPE, UNIT_DISPLAY_TYPE } from "../../../../enums/unit";
import {
	getForEachDay,
	getForEachSixHours,
	axisXLabelFormatter,
	getForEachFourHours,
	getForEachTwoHours,
	getForEachTwelveHours,
	getForEachThreeDay,
	getForEachFiveDay,
	xAxisLabelWithOutTime
} from "../../lineChart/helper";
import { getConfigDataZoom } from "../helper/zoomConfig";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export const tooltipTankLineChartFormat = (name, value, unit) => {
	const format = `
			<div>
				<div class="bar-name">
					${name}
				</div>
				<div class="bar-value">
					${value === null ? "-" : value} (${unit}/hr)
				</div>
			</div>`;
	return format;
};

const tooltipFormatter = ([pointValue]) => {
	const date = dayjs(pointValue.axisValue).format("HH.mm, D MMM YYYY");
	return tooltipTankLineChartFormat(date, pointValue.data, pointValue.seriesName);
};

const optionTankLineChartXaxisPerWeek = ({ xAxis }, yAxisText, displayUnit, isMobile) => ({
	yAxisText,
	axisLabel: {
		formatter: (value) => {
			return dayjs(value).format("D MMM");
		},
		interval: getForEachDay
	},

	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachTwelveHours
	},

	grid: {
		left: 16,
		right: 48,
		bottom: 50,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		name: displayUnit
	},
	legend: {
		show: false,
		selectedMode: false,
		bottom: 50,
		left: 68,
		data: {
			name: "Current State"
		},
		itemGap: isMobile ? 5 : 20
	}
});

const optionTankLineChartXaxisPerThreeDay = ({ xAxis }, yAxisText, displayUnit, isMobile) => ({
	yAxisText,
	axisLabel: {
		padding: [0, 0, 10, 0],
		formatter: axisXLabelFormatter,
		interval: isMobile ? getForEachTwelveHours : getForEachFourHours
	},

	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachSixHours
	},

	grid: {
		left: 16,
		right: 48,
		bottom: 50,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		name: displayUnit
	},
	legend: {
		show: false,
		selectedMode: false,
		bottom: 50,
		left: 68,
		data: {
			name: "Current State"
		},
		itemGap: 20
	}
});

const optionTankLineChartXaxisPerDay = ({ xAxis }, yAxisText, displayUnit, isMobile) => ({
	yAxisText,
	axisLabel: {
		formatter: axisXLabelFormatter,
		interval: isMobile ? getForEachFourHours : getForEachTwoHours
	},

	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: () => true
	},

	grid: {
		left: 16,
		right: 48,
		bottom: 50,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		name: displayUnit
	},
	legend: {
		show: false,
		selectedMode: false,
		bottom: 50,
		right: 0,
		left: 16,
		data: {
			name: "Current State"
		},
		itemGap: 20
	}

});

const optionTankLineChartMoreThanTwentyDay = ({ xAxis }, yAxisText, displayUnit, isMobile) => ({
	yAxisText,
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachThreeDay,
		rotate: isMobile ? 45 : 0
	},

	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachThreeDay
	},

	grid: {
		left: 16,
		right: 48,
		bottom: 50,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		name: displayUnit
	},
	legend: {
		show: false,
		selectedMode: false,
		bottom: 50,
		left: 68,
		data: {
			name: "Current State"
		},
		itemGap: 20
	}
});

const optionTankLineChartThreeMonths = ({ xAxis }, yAxisText, displayUnit, isMobile) => ({
	yAxisText,
	axisLabel: {
		formatter: xAxisLabelWithOutTime,
		interval: getForEachFiveDay,
		rotate: isMobile ? 45 : 0
	},

	...getConfigDataZoom(xAxis),

	axisTick: {
		interval: getForEachFiveDay
	},

	grid: {
		left: 16,
		right: 48,
		bottom: 50,
		containLabel: true
	},

	tooltip: {
		formatter: tooltipFormatter
	},
	series: {
		name: displayUnit
	},
	legend: {
		show: false,
		selectedMode: false,
		bottom: 50,
		left: 68,
		data: {
			name: "Current State"
		},
		itemGap: 20
	}
});

export const transformTankConsumptionLineChartConfig = (sortBy = perDay, data = [], option = {}, unit = "kg", isMobile = false) => {
	const { diffDay } = option;
	let displayUnit = "";

	switch (unit) {
		case UNIT_TYPE.NM3:
			displayUnit = UNIT_DISPLAY_TYPE.NM3;
			break;
		case UNIT_TYPE.SM3:
			displayUnit = UNIT_DISPLAY_TYPE.SM3;
			break;
		case UNIT_TYPE.LITER:
			displayUnit = UNIT_DISPLAY_TYPE.LITER;
			break;
		default:
			displayUnit = UNIT_DISPLAY_TYPE.KG;
			break;
	}

	const yAxisText = `Consumption rate (${displayUnit}/hr)`;
	const mappingOption = {
		[perDay]: optionTankLineChartXaxisPerDay(data, yAxisText, displayUnit, isMobile),
		[perThreeDay]: optionTankLineChartXaxisPerThreeDay(data, yAxisText, displayUnit, isMobile),
		[perWeek]: optionTankLineChartXaxisPerWeek(data, yAxisText, displayUnit, isMobile),
		[perThirtyDays]: optionTankLineChartMoreThanTwentyDay(data, yAxisText, displayUnit, isMobile),
		[perNinetyDays]: optionTankLineChartThreeMonths(data, yAxisText, displayUnit, isMobile)
	};

	if (diffDay > 20 && !mappingOption[sortBy]) {
		const functionTransform = optionTankLineChartMoreThanTwentyDay(data, yAxisText, displayUnit);
		const transformFunc = transformLineChart(functionTransform);

		return transformFunc(data);
	}

	const functionTransform = mappingOption[sortBy] || optionTankLineChartXaxisPerWeek(data, yAxisText, displayUnit);
	const transformFunc = transformLineChart(functionTransform);

	return transformFunc(data);
};
