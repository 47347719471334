import { getPowerInfoAPI } from "@/services/api/pwr.api";
import { getLayoutTemplate } from "@/services/api/layoutCustomisation.api";
import { AUTO_POWER_INFO_REQUEST, AUTO_POWER_INFO_SUCCESS, POWER_INFO_REQUEST, POWER_INFO_SUCCESS, POWER_INFO_FAILURE, POWER_INFO_CLEAR } from "../../types";
import { parsePowerInfo } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: {},
		loading: false,
		isAutoFecthLoading: false,
		statusCode: null
	},

	mutations: {
		[POWER_INFO_REQUEST](state) {
			state.data = {};
			state.loading = true;
			state.statusCode = null;
		},
		[AUTO_POWER_INFO_REQUEST](state) {
			state.isAutoFecthLoading = true;
		},
		[POWER_INFO_SUCCESS](state, { response, template }) {
			state.data = parsePowerInfo(response.data, template.data);
			state.loading = false;
			state.statusCode = response.status;
		},
		[AUTO_POWER_INFO_SUCCESS](state, { response, template }) {
			state.data = parsePowerInfo(response.data, template.data);
			state.isAutoFecthLoading = false;
			state.statusCode = response.status;
		},
		[POWER_INFO_FAILURE](state, error) {
			state.data = {};
			state.loading = false;
			state.statusCode = error.response.status;
		},
		[POWER_INFO_CLEAR](state) {
			state.data = {};
			state.loading = false;
		}
	},

	actions: {
		async getPowerInfo({ commit }, { id }) {
			try {
				commit(POWER_INFO_REQUEST);
				const response = await getPowerInfoAPI(id);
				const template = await getLayoutTemplate({ app: "PWR" });
				commit(POWER_INFO_SUCCESS, { response, template });
			} catch (error) {
				commit(POWER_INFO_FAILURE, error);
			}
		},

		async autoGetPowerInfo({ commit }, { id }) {
			try {
				commit(AUTO_POWER_INFO_REQUEST);
				const response = await getPowerInfoAPI(id);
				const template = await getLayoutTemplate({ app: "PWR" });
				commit(AUTO_POWER_INFO_SUCCESS, { response, template });
			} catch (error) {
				commit(POWER_INFO_FAILURE, error);
			}
		},

		clearPowerInfo({ commit }) {
			commit(POWER_INFO_CLEAR);
		}
	}
};
