/**
 * Enum for e-ordering order.
 * @readonly
 * @enum {EnumEOrderingOrder}
 */
export const ORDER = Object.freeze({
	MAXIMUM_CREATE_BATCH_SCHEDULE: 2,
	MAXIMUM_CREATE_SCHEDULE: 5,
	MAXIMUM_CREATE_FORM: 5,
	MAXIMUM_CREATE_BATCH_DATE: 65
});

/**
 * Enum for e-ordering order type.
 * @readonly
 * @enum {EnumEOrderingOrderType}
 */
export const ORDER_TYPE = Object.freeze({
	NORMAL: "normal",
	BATCH: "batch"
});
