<template>
	<BaseLayout pageTitle="Alert and notification">
		<BaseLoading
			v-if="loading"
			class="wrapper-loading"
		/>
		<div v-if="!loading">
			<div class="container-action-bar">
				<div class="wrapper-button-group">
					<BaseButtonGroup
						:items="buttonGroupOption.items"
						:active="buttonGroupOption.active"
						type="secondary"
						size="small"
						@click="onClickButtonGroup"
					/>
				</div>
				<BaseButton
					size="large"
					rounded
					@click="onClickOpenModal"
				>
					Create new rule
				</BaseButton>
			</div>
			<SearchFilterShipTo
				v-if="!isNoLayout"
				:keywordValue="keyword"
				:typeValue="typeSelected"
				:typeLists="dropdownTypeOptions"
				:moduleValue="moduleSelected"
				:moduleLists="dropdownModuleOptions"
				:statusValue="status"
				:statuses="statuses"
				@handleSelectType="handleSelectType"
				@handleSelectModule="handleSelectModule"
				@handleKeywordChange="handleKeyWordChange"
				@handleSelectStatus="handleStatusChange"
				@handleSubmit="handleSubmitForm"
				@handleClear="handleClear"
			/>
			<div
				v-if="isShowList"
				class="wrapper-list-customisation"
			>
				<div
					v-for="(item, index) in layoutLists"
					:key="index"
					:class="['customisation-card', { active: item.active }]"
				>
					<div class="customisation-head">
						<h2 class="customisation-head-layout-name">
							{{ item.name }}
						</h2>
						<div class="customisation-head-type">
							<span class="module-label">
								Ship-to code
							</span>
							<span class="module-type">
								{{ item.code || "-" }}
							</span>
						</div>
						<div class="customisation-head-module">
							<span class="module-label">
								Location key
							</span>
							<span class="module-type">
								{{ (item.locationKey || item.key) || "-" }}
							</span>
						</div>
						<div class="customisation-head-edit-section btn-link">
							<span @click="onToggle(item, index)" class="toggle-btn">
								Show rule
								<FontAwesomeIcon :icon="['far', 'chevron-circle-down']" />
							</span>
						</div>
					</div>
					<div v-if="item.active && item.loading" class="customisation-body loading">
						<BaseLoading />
					</div>
					<div v-if="item.active && !item.loading && !item.rules.length" class="customisation-body empty">
						No data
					</div>
					<div v-if="item.active && !item.loading && item.rules.length" class="customisation-body">
						<div
							v-for="(ruleItem, ruleIndex) in item.rules"
							:key="ruleIndex"
							class="customisation-body-inner"
						>
							<h2 class="customisation-body-inner-layout-name">
								{{ ruleItem.name }}
							</h2>
							<div class="customisation-body-inner-ref">
								<span class="module-label">
									ref ID
								</span>
								<span class="module-type">
									{{ ruleItem.id }}
								</span>
							</div>
							<div class="customisation-body-inner-type">
								<span class="module-label">
									Type
								</span>
								<span class="module-type">
									{{ ruleItem.type }}
								</span>
							</div>
							<div class="customisation-body-inner-module">
								<span class="module-label">
									Module
								</span>
								<span class="module-type">
									{{ getTitle(ruleItem.module) }}
								</span>
							</div>
							<div class="customisation-body-inner-status">
								<span class="module-type">
									<BaseBadge v-if="ruleItem.isActive" text="Active" size="small" color="success-dark"/>
									<BaseBadge v-else text="Inactive" size="small" color="grey" outline/>
								</span>
							</div>
							<div class="customisation-body-inner-edit-section btn-link">
								<router-link
									:to="{
										name: ALERT_AND_NOTIFICATION_EDIT,
										params:{
											id: ruleItem.id
										},
										query:{
											title: getTitle(ruleItem.module),
											type: ruleItem.type,
											module: ruleItem.module
										},
									}"
									class="edit-btn"
									active-class="active"
									exact
								>
									<font-awesome-icon
										:icon="['far', 'edit']"
										class="edit-icon"
									>
									</font-awesome-icon>
									Edit
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<BaseEmpty
				v-if="isEmptyWithSearch && !isNoLayout"
				class="wrapper-empty"
				icon="search"
				wrapperColor="very-pale-green"
				iconColor="emerald"
				title="No matching search results"
				subTitle="Try adjusting your search by changing your rule name or removing filters"
			/>

			<BaseEmpty
				v-if="isNoLayout"
				class="wrapper-empty"
				icon="file-times"
				wrapperColor="very-pale-green"
				iconColor="emerald"
				title="Please create rule"
				subTitle="Alert and notification list will be displayed once you created rule"
			/>

			<BasePagination
				v-if="pagination.lastPage > 1"
				:value="pagination.currentPage"
				:totalPage="pagination.lastPage"
				class="module-pagination"
				@onPageChange="handlePageChange"
			/>

			<ModalCreateModule
				class="modal-create-rule-module"
				header="Create new rule"
				modal-name="modal-create"
				@onSubmit="handleSubmitModule"/>
		</div>
	</BaseLayout>
</template>

<script>
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseEmpty from "@/components/BaseEmpty.vue";
import ModalCreateModule from "@/components/modal/ModalCreateModule.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import SearchFilterShipTo from "@/components/AlertAndNotification/SearchFilterShipTo.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { getModuleAvailable, getTypeAvailable, getNotificationShipToLists, getNotificationShipToRuleLists } from "../../services/api/notification.api";
import { parseTypes } from "../../services/api/transforms/parseAlertNoti";
import { MODULE_TYPES } from "../../enums/modules";

const {
	ALERT_AND_NOTIFICATION_CREATE,
	ALERT_AND_NOTIFICATION_LIST,
	ALERT_AND_NOTIFICATION_EDIT
} = PAGE_NAME;

export default {
	components: {
		BaseButton,
		BaseEmpty,
		ModalCreateModule,
		BaseLayout,
		SearchFilterShipTo,
		BaseLoading,
		BaseButtonGroup,
		BaseBadge
	},
	data() {
		return {
			ALERT_AND_NOTIFICATION_EDIT,
			loading: true,
			isNoLayout: false,
			layoutLists: [],
			moduleLists: [
				{
					value: "",
					label: "Module: All"
				}
			],
			pagination: {
				currentPage: Number(this.$route.query.page) || null,
				lastPage: 1,
				perPage: 12,
				total: 1
			},
			searchFilter: true,
			typeSelected: this.$route.query.type || "",
			moduleSelected: this.$route.query.module || "",
			keyword: this.$route.query.keyword || "",
			status: this.$route.query.isActive,
			createModuleLists: [],
			typeAvailable: [],
			buttonGroupOption: {
				active: this.$route.query.view || "shipto",
				items: [
					{
						value: "shipto",
						text: "Ship-to view",
						icon: ["far", "list-ul"]
					},
					{
						value: "rule",
						text: "Rule view",
						icon: ["far", "list-ul"]
					}
				]
			},
			statuses: [
				{ label: "Status: All", value: null },
				{ label: "Active", value: 1 },
				{ label: "Inactive", value: 0 }
			]
		};
	},

	computed: {
		isShowList() {
			return this.layoutLists.length > 0;
		},

		isEmptyWithSearch() {
			return this.layoutLists.length === 0 && this.searchFilter;
		},

		getModuleTranslation() {
			const result = this.createModuleLists.reduce((acc, next) => {
				return {
					...acc,
					[next.value]: next.label === "GenGas" ? "Gen Gas" : next.label
				};
			}, {});

			return result;
		},

		getSortModule() {
			const application = [];
			const moduleType = [];
			for (let i = 0; i < this.createModuleLists.length; i++) {
				if (this.createModuleLists[i].label.includes("Application")) {
					application.push(this.createModuleLists[i]);
				} else {
					moduleType.push(this.createModuleLists[i]);
				}
			}
			const mergeSort = moduleType.sort().concat(application.sort());
			const result = mergeSort.map((v) => {
				if (v.label === MODULE_TYPES.GENGAS) {
					return {
						...v,
						label: "Gen Gas"
					};
				}
				return v;
			});
			return result;
		},

		getSearchModuleLists() {
			return [
				...this.moduleLists,
				...this.getSortModule
			];
		},

		dropdownTypeOptions() {
			return this.typeAvailable.reduce((acc, cur) => {
				acc.push({
					label: cur.displayName,
					value: cur.type
				});
				return acc;
			}, []);
		},
		dropdownModuleOptions() {
			const type = this.typeAvailable.find((item) => (
				item.type === this.typeSelected
			));
			return !type ? [] : type.availableModules.map((item) => {
				return {
					label: item.displayName,
					value: item.module
				};
			});
		}
	},

	async created() {
		this.loading = true;

		const computeQuery = {
			...(this.keyword ? { keyword: this.keyword } : {}),
			...(this.typeSelected ? { type: this.typeSelected } : {}),
			...(this.moduleSelected ? { module: this.moduleSelected } : {}),
			...(this.status !== undefined && this.status !== "" ? { isActive: this.status } : {}),
			...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {})
		};
		const { data: typeAvailable } = await getTypeAvailable();
		const { data: moduleAvailable } = await getModuleAvailable();
		const { data: notificationList } = await getNotificationShipToLists(computeQuery);

		this.layoutLists = notificationList.data.map((item) => {
			return {
				...item,
				active: false,
				loading: false,
				rules: []
			};
		});
		this.createModuleLists = moduleAvailable;
		this.typeAvailable = parseTypes(typeAvailable);

		this.pagination = {
			lastPage: notificationList.pagination.lastPage,
			perPage: notificationList.pagination.perPage,
			total: notificationList.pagination.total,
			currentPage: Number(this.$route.query.page) || 1
		};

		if ((!this.keyword && !this.typeSelected && !this.moduleSelected && (this.status !== undefined && this.status !== "")) && notificationList.data.length === 0) {
			this.isNoLayout = true;
		}

		this.loading = false;
	},

	methods: {
		handleClear() {
			this.keyword = "";
			this.typeSelected = "";
			this.moduleSelected = "";
			this.status = "";
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					view: this.$route.query.view
				}
			}).catch(() => {});
		},

		handleKeyWordChange(value) {
			this.keyword = value;
		},

		handleStatusChange(value) {
			this.status = value;
		},

		handleSelectType(value) {
			this.typeSelected = value;
		},

		handleSelectModule(value) {
			this.moduleSelected = value;
		},

		handlePageChange(value) {
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					...this.$route.query,
					page: value
				}
			});
		},

		handleSubmitForm() {
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					...this.$route.query,
					...(this.keyword ? { keyword: this.keyword } : {}),
					...(this.status !== undefined || this.status !== "" ? { isActive: this.status } : {}),
					page: undefined
				}
			}).catch(() => {});
		},

		onClickOpenModal() {
			this.$modal.show("modal-create", {
				typeAvailable: this.typeAvailable
			});
		},

		handleSubmitModule(form) {
			const { typeSelected, moduleSelected } = form;
			const title = this.typeAvailable.reduce((acc, cur) => {
				const _item = cur.availableModules.find((_m) => _m.module === moduleSelected);
				if (_item) {
					acc.str = _item.displayName;
				}
				return acc;
			}, { str: "" }).str;
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_CREATE,
				query: {
					title,
					type: typeSelected,
					module: moduleSelected
				}
			});
		},

		onClickButtonGroup(value) {
			this.buttonGroupOption.active = value;
			this.$emit("change", value);
			this.$router.push({
				name: ALERT_AND_NOTIFICATION_LIST,
				query: {
					...this.$route.query,
					keyword: undefined,
					status: undefined,
					page: undefined
				}
			}).catch(() => {});
		},

		async onToggle(item, index) {
			if (!item.active) {
				this.layoutLists[index].active = true;
				this.layoutLists[index].loading = true;
				const { data } = await getNotificationShipToRuleLists(item.id, {
					isActive: this.$route.query.isActive === undefined || this.$route.query.isActive === "" ? null : this.$route.query.isActive
				});
				this.layoutLists[index].rules = data.map((ruleItem) => {
					return {
						...ruleItem
					};
				});
				this.layoutLists[index].loading = false;
			} else {
				this.layoutLists[index].active = false;
				this.layoutLists[index].loading = false;
				this.layoutLists[index].rules = [];
			}
		},

		getTitle(selectedModule) {
			return this.typeAvailable.reduce((acc, cur) => {
				const _item = cur.availableModules.find((_m) => _m.module === selectedModule);
				if (_item) {
					acc.str = _item.displayName;
				}
				return acc;
			}, { str: "" }).str;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.wrapper-button-group {
	display: flex;
	align-items: center;
	margin-right: rem(32);
}

.wrapper-empty {
	margin-top: rem(102);
}

.wrapper-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: rem(500);
}

.wrapper-list-customisation {
	display: flex;
	flex-direction: column;
	margin-top: rem(20);
	margin-bottom: rem(40);
}

.customisation-card {
	display: flex;
	flex-direction: column;
	margin-bottom: rem(5);

	&.active {
		.customisation-head {
			border-bottom: none;
			border-radius: rem(8) rem(8) 0 0;
		}

		.fa-chevron-circle-down {
			transform: rotate(180deg);
		}
	}

	.customisation-head,
	.customisation-body-inner {
		display: flex;
		flex: 1;
		align-items: baseline;
		padding: rem(16);
		background-color: $color-alabaster;
		border: rem(1) solid $color-silver;
		border-radius: rem(8);

		&.bordered {
			border-bottom: rem(1) solid $color-silver;
		}

		&-layout-name {
			@include ellipsis(2);

			flex: 1;
			padding-right: rem(30);
			font-weight: $font-weight-regular;
			color: $color-dark-blue-grey;
		}

		&-ref {
			display: flex;
			min-width: rem(100);
			color: $color-grey-4;
		}

		&-type {
			display: flex;
			min-width: rem(170);
			color: $color-grey-4;
		}

		&-module {
			display: flex;
			min-width: rem(200);
			color: $color-grey-4;
		}

		&-status {
			display: flex;
			min-width: rem(100);
		}

		&-edit-section {
			min-width: rem(80);
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.edit-btn {
			display: flex;
			align-items: center;
			color: $color-cerulean;
			font-size: rem(16);

			&:hover {
				color: $color-azure;
			}

			&:visited {
				color: $color-cerulean;
			}
		}

		.edit-icon {
			font-size: rem(12);
		}

		.module-label {
			display: flex;
			justify-content: flex-end;
			width: rem(80);
		}

		.module-type {
			color: $color-dark-blue-grey;
			font-weight: $font-weight-bold;
			margin-left: rem(8);
		}
	}

	.customisation-body {
		display: flex;
		flex-direction: column;
		background-color: $color-white;
	}

	.customisation-body-inner {
		position: relative;
		background-color: $color-white;
		padding: rem(16);
		border-radius: 0;
		border-top: none;
		border-bottom: none;

		&::after {
			content: "";
			position: absolute;
			width: calc(100% - #{rem(32)});
			height: rem(1);
			top: 0;
			left: rem(16);
			background-color: $color-silver;
		}

		&:first-of-type::after {
			display: none;
		}

		&:last-of-type {
			border-bottom: rem(1) solid $color-silver;
			border-radius: 0 0 rem(8) rem(8);
		}
	}

	.loading {
		padding-top: rem(10);
		border: rem(1) solid $color-silver;
		border-radius: 0 0 rem(8) rem(8);
	}

	.empty {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: rem(16);
		border: rem(1) solid $color-silver;
		border-radius: 0 0 rem(8) rem(8);
	}
}

.modal-create-rule-module {
	/* stylelint-disable */
	& v::deep {
		.container-form {
			max-height: rem(100);
		}
	}
	/* stylelint-enable */
}

.toggle-btn {
	display: flex;
	align-items: center;

	svg {
		transition: 0.3s transform ease-in-out;
		width: rem(12);
		height: rem(13);
		margin-left: rem(6);
		margin-top: rem(1);
	}
}

.module-pagination {
	justify-content: flex-end;
}
</style>