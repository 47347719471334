<template>
	<CardBarChart
		:chartData="consumptionChartData"
		:lastUpdate="furnaceConsumption.lastUpdate"
		:isLoading="furnaceConsumption.isLoading"
		:isEmpty="furnaceConsumption.isEmpty"
		:filterOptions="consumptionFilterOptions"
		chartTitle="Natural gas consumption"
		@dateSelected="handleConsumptionDateSelected"
	/>
</template>

<script>
import dayjs from "dayjs";

import CardBarChart from "@/components/chart/CardBarChart.vue";

import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { transformDataBarChartNaturalGasConsumption, transformBarChart } from "../../selectors/transform/barChart";
import { getDateNumber } from "../../selectors/helpers";
import { NM_UNIT } from "../../selectors/transform/unit";

const { perWeek, perTwoWeek, perYear } = MODULE_SORT_DATE_CHART;

export default {
	components: {
		CardBarChart
	},

	props: {
		furnaceConsumption: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			consumptionFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(7, "day").$d,
							end: dayjs().$d
						}
					},
					dropdown: {
						items: [
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 2 weeks",
								value: perTwoWeek
							},
							{
								label: "Last 12 months",
								value: perYear,
								isDisabled: true
							}
						]
					}
				}
			}
		};
	},

	computed: {
		consumptionSelected() {
			const dateRange = this.consumptionFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);

			return {
				dateNumber,
				dateRange
			};
		},

		consumptionChartData() {
			const dateSort = this.consumptionSelected.dateNumber;
			const dateReportFormat = [perWeek, perTwoWeek].includes(dateSort) ? "day" : "year";
			const options = { dateReportFormat, dateSort };
			const xyAxis = transformDataBarChartNaturalGasConsumption(this.furnaceConsumption, options);
			const optionFurnaceBarChart = {
				yAxisText: `Natural gas consumption (${NM_UNIT})`,
				xAxisText: "Date",
				legend: {
					show: true,
					data: [this.furnaceConsumption.tagName.tagnameMelter ?? "Melter", this.furnaceConsumption.tagName.tagnameRefiner ?? "Refiner"],
					icon: "circle",
					bottom: 0,
					left: 48,
					itemWidth: 10,
					itemHeight: 10,
					itemGap: 24,
					fontFamily: "TFSrivichai",
					fontSize: 16,
					fontColor: "#71717",
					fontWeight: "bold"
				},
				grid: {
					left: "48px",
					right: "74px",
					bottom: "48px",
					containLabel: true
				},
				series: {
				}
			};
			return transformBarChart(optionFurnaceBarChart)(xyAxis);
		}
	},

	methods: {
		handleConsumptionDateSelected(date) {
			this.consumptionFilterOptions.date.selected.range = date;

			this.$emit("filter", this.consumptionSelected.dateNumber);
		}
	}
};
</script>
