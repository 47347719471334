import store from "../../../store/index";

export const interceptorResponse = (response) => {
	return response;
};

export const interceptorResponseError = (error) => {
	//  blacklist endpoint that we want to ignore them for `403` code only.
	const ignoreEndPoints = [
		"/login", // customer login
		"/flow-diagrams"
	];
	const statusCode = error.response.status;
	const message = error.response.data.message;
	const requestEndpoint = error.response.config.url;
	let $error = null;
	let isIgnore = false;

	switch (statusCode) {
		case 400:
			$error = {
				title: "There was a problem with your request.",
				message: message || "Please try again."
			};
			break;
		case 401:
			$error = {
				title: "Your session has timed out.",
				message: `
					Please <span class="mx-2"><a href="/login" class="link">sign in</a></span> again.
				`,
				callback: () => (window.location = "/login")
			};
			break;
		case 404:
			// redirect to 404 page
			window.location = "/404";
			break;
		case 403:
			for (let i = 0; i < ignoreEndPoints.length; i++) {
				const item = ignoreEndPoints[i];
				if (requestEndpoint.search(item) >= 0) {
					isIgnore = true;
					break;
				}
			}
			if (!isIgnore) {
				window.location = "/permission?requireAuth=true";
			}
			break;
		case 500:
			$error = {
				title: "There was an unexpected error.",
				message: "Please try again."
			};
			break;
		case 502:
			// redirect to 502 page
			window.location = "/502";
			break;
		default:
	}

	if ($error) {
		store.dispatch("error/add", { ...$error, statusCode });
	}

	return Promise.reject(error);
};