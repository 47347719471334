import { PAGE_NAME } from "./pagePermission";
import { STAFF_ROLES } from "./role";
import { CUSTOMER_ROLES } from "./customerManagement/customerRole";
import { MASTERDATA_PERMISSIONS } from "./masterData";

const { SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, IT, SALES, SALES_PLUS, PLANNER } = STAFF_ROLES;
const { POWERUSER, PROCUREMENT, USER, LEVEL_2_USER, ORDER } = CUSTOMER_ROLES;
const {
	DashboardRead,
	FlowDiagramManage,
	FlowDiagramRead,
	InvoiceRead,
	EOrderingExport,
	EOrderingManage,
	EOrderingRead,
	PowerBIExecutiveRead
} = MASTERDATA_PERMISSIONS;

/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */
export const SIDE_NAV = Object.freeze([
	{
		name: PAGE_NAME.MARKETPLACE,
		icon: "store",
		iconSize: { width: "20px", height: "18px" },
		text: "Marketplace",
		permission: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, SALES, SALES_PLUS, IT, PLANNER, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
		relationRoutes: [
			PAGE_NAME.MARKETPLACE_EXAMPLE_MODULE,
			PAGE_NAME.MARKETPLACE_MODULE_GROUP_DETAIL,
			PAGE_NAME.EX_MODULE_GENGAS,
			PAGE_NAME.EX_MODULE_GENGAS_INFO,
			PAGE_NAME.EX_MODULE_GENGAS_HISTORY,
			PAGE_NAME.EX_MODULE_GENGAS_POWERBI,
			PAGE_NAME.EX_MODULE_AIRCOMP,
			PAGE_NAME.EX_MODULE_AIRCOMP_INFO,
			PAGE_NAME.EX_MODULE_AIRCOMP_HISTORY,
			PAGE_NAME.EX_MODULE_AIRCOMP_POWERBI,
			PAGE_NAME.EX_MODULE_FURNACE,
			PAGE_NAME.EX_MODULE_FURNACE_INFO,
			PAGE_NAME.EX_MODULE_FURNACE_OVERVIEW,
			PAGE_NAME.EX_MODULE_FURNACE_HISTORY,
			PAGE_NAME.EX_MODULE_FURNACE_REPORT,
			PAGE_NAME.EX_MODULE_FURNACE_POWERBI,
			PAGE_NAME.EX_MODULE_METER,
			PAGE_NAME.EX_MODULE_METER_INFO,
			PAGE_NAME.EX_MODULE_METER_DAILY_CONSUMPTION,
			PAGE_NAME.EX_MODULE_METER_CONSUMPTION_APPROVAL,
			PAGE_NAME.EX_MODULE_METER_HISTORY,
			PAGE_NAME.EX_MODULE_METER_POWERBI,
			PAGE_NAME.EX_MODULE_NITRO,
			PAGE_NAME.EX_MODULE_NITRO_INFO,
			PAGE_NAME.EX_MODULE_NITRO_HISTORY,
			PAGE_NAME.EX_MODULE_NITRO_POWERBI,
			PAGE_NAME.EX_MODULE_O2A,
			PAGE_NAME.EX_MODULE_O2A_INFO,
			PAGE_NAME.EX_MODULE_O2A_LAST_MONTH,
			PAGE_NAME.EX_MODULE_O2A_HISTORY,
			PAGE_NAME.EX_MODULE_O2A_POWERBI,
			PAGE_NAME.EX_MODULE_PWR,
			PAGE_NAME.EX_MODULE_PWR_INFO,
			PAGE_NAME.EX_MODULE_PWR_KWH,
			PAGE_NAME.EX_MODULE_PWR_HISTORY,
			PAGE_NAME.EX_MODULE_PWR_POWERBI,
			PAGE_NAME.EX_MODULE_SOR,
			PAGE_NAME.EX_MODULE_SOR_INFO,
			PAGE_NAME.EX_MODULE_SOR_HISTORY,
			PAGE_NAME.EX_MODULE_SOR_KWH,
			PAGE_NAME.EX_MODULE_SOR_POWERBI,
			PAGE_NAME.EX_MODULE_TANK,
			PAGE_NAME.EX_MODULE_TANK_INFO,
			PAGE_NAME.EX_MODULE_TANK_TRIP_INFO,
			PAGE_NAME.EX_MODULE_TANK_ORDERS,
			PAGE_NAME.EX_MODULE_TANK_POWERBI,
			PAGE_NAME.EX_MODULE_ZLD,
			PAGE_NAME.EX_MODULE_ZLD_INFO,
			PAGE_NAME.EX_MODULE_ZLD_LAST_MONTH,
			PAGE_NAME.EX_MODULE_ZLD_POWERBI,
			PAGE_NAME.EX_MODULE_ZLD_VINY_THAI,
			PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_INFO,
			PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_CONSUMPTION_APPROVAL,
			PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_HISTORY,
			PAGE_NAME.EX_MODULE_ZLD_VINY_THAI_POWERBI,
			PAGE_NAME.EX_MODULE_CER,
			PAGE_NAME.EX_MODULE_CER_INFO,
			PAGE_NAME.EX_MODULE_CER_HISTORY,
			PAGE_NAME.EX_MODULE_CER_POWERBI,
			PAGE_NAME.EX_MODULE_LNG,
			PAGE_NAME.EX_MODULE_LNG_INFO,
			PAGE_NAME.EX_MODULE_LNG_TRIP_INFO,
			PAGE_NAME.EX_MODULE_LNG_HISTORY,
			PAGE_NAME.EX_MODULE_LNG_POWERBI,
			PAGE_NAME.EX_MODULE_BHY,
			PAGE_NAME.EX_MODULE_BHY_INFO,
			PAGE_NAME.EX_MODULE_BHY_TRIP_INFO,
			PAGE_NAME.EX_MODULE_BHY_HISTORY,
			PAGE_NAME.EX_MODULE_BHY_POWERBI
		]
	},
	{
		icon: "laptop-code",
		iconSize: { width: "20px", height: "16px" },
		text: "System monitoring",
		children: [
			{
				name: PAGE_NAME.MONITOROVERALL,
				icon: "file-chart-line",
				iconSize: { width: "9px", height: "13px" },
				text: "Overall",
				permission: [SUPER_ADMIN, PLANNER],
				isDisabled: false
			},
			{
				name: PAGE_NAME.MONITORDEVICEHEALTH,
				icon: "laptop-medical",
				iconSize: { width: "15px", height: "13px" },
				text: "Device health monitoring",
				permission: [SUPER_ADMIN, PLANNER],
				isDisabled: false
			},
			{
				name: PAGE_NAME.MONITORUSER,
				icon: "user-chart",
				iconSize: { width: "15px", height: "13px" },
				text: "User monitoring",
				permission: [SUPER_ADMIN, PLANNER],
				isDisabled: false
			}
		]
	},
	{
		name: PAGE_NAME.DASHBOARD,
		icon: "tachometer",
		iconSize: { width: "18px", height: "16px" },
		text: "Dashboard",
		permission: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, IT, SALES, SALES_PLUS, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER, PLANNER],
		isDisabled: false,
		rolePermissions: [DashboardRead],
		relationRoutes: [PAGE_NAME.DASHBOARD_MODULES]
	},
	{
		name: PAGE_NAME.FLOWDIAGRAM_LIST,
		icon: "project-diagram",
		iconSize: { width: "18px", height: "14px" },
		text: "Diagram",
		permission: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, IT, SALES, SALES_PLUS, POWERUSER, USER, LEVEL_2_USER],
		isDisabled: false,
		rolePermissions: [FlowDiagramManage, FlowDiagramRead]
	},
	{
		name: PAGE_NAME.INVOICE,
		icon: "file-invoice-dollar",
		iconSize: { width: "20px", height: "18px" },
		text: "Invoice",
		permission: [SUPER_ADMIN, ADMIN, SALES, SALES_PLUS, POWERUSER, PROCUREMENT],
		rolePermissions: [InvoiceRead]
	},
	{
		name: PAGE_NAME.E_ORDERING_LIST,
		icon: "clipboard-list",
		iconSize: { width: "20px", height: "18px" },
		text: "e-Ordering",
		permission: [SUPER_ADMIN, POWERUSER, PROCUREMENT, PLANNER, ORDER],
		rolePermissions: [EOrderingExport, EOrderingRead, EOrderingManage],
		relationRoutes: [PAGE_NAME.E_ORDERING_CREATE, PAGE_NAME.E_ORDERING_EDIT, PAGE_NAME.E_ORDERING_INFO, PAGE_NAME.E_ORDERING_PENDING_ORDER_LIST]
	},
	{
		name: PAGE_NAME.POWERBI,
		icon: "chart-simple",
		iconSize: { width: "20px", height: "18px" },
		text: "Executive Report",
		permission: [SUPER_ADMIN, ADMIN, BUSINESS_SUPPORT, IT, SALES, SALES_PLUS, POWERUSER, PROCUREMENT, USER, LEVEL_2_USER],
		rolePermissions: [PowerBIExecutiveRead]
	},
	{
		icon: "list-check",
		iconSize: { width: "20px", height: "18px" },
		text: "Marketplace management",
		children: [
			{
				childrenRoutes: [PAGE_NAME.BIG_STORE_BANNER_CREATE, PAGE_NAME.BIG_STORE_BANNER_EDIT],
				name: PAGE_NAME.BIG_STORE_BANNER,
				icon: "image-landscape",
				iconSize: { width: "15px", height: "15px" },
				text: "Banner",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				childrenRoutes: [PAGE_NAME.BIG_STORE_OUR_DEVICE_CREATE, PAGE_NAME.BIG_STORE_OUR_DEVICE_EDIT],
				name: PAGE_NAME.BIG_STORE_OUR_DEVICE,
				icon: "hand-holding-box",
				iconSize: { width: "15px", height: "15px" },
				text: "Our device",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				childrenRoutes: [PAGE_NAME.BIG_STORE_OUR_SERVICE_CREATE, PAGE_NAME.BIG_STORE_OUR_SERVICE_EDIT],
				name: PAGE_NAME.BIG_STORE_OUR_SERVICE,
				icon: "hand-holding-heart",
				iconSize: { width: "15px", height: "15px" },
				text: "Our service",
				permission: [SUPER_ADMIN],
				isDisabled: false
			}
		]
	},
	{
		icon: "users-cog",
		iconSize: { width: "20px", height: "18px" },
		text: "User Management",
		children: [
			{
				childrenRoutes: [PAGE_NAME.USERS_CREATE, PAGE_NAME.USERS_EDIT],
				name: PAGE_NAME.USERS,
				icon: "clipboard-user",
				iconSize: { width: "9px", height: "13px" },
				text: "BIG staff account",
				permission: [SUPER_ADMIN, ADMIN, IT],
				isDisabled: false
			},
			{
				childrenRoutes: [PAGE_NAME.CUSTOMER_CREATE, PAGE_NAME.CUSTOMER_EDIT],
				name: PAGE_NAME.CUSTOMERS,
				icon: "user-friends",
				iconSize: { width: "15px", height: "13px" },
				text: "Customer account",
				permission: [SUPER_ADMIN, ADMIN, IT],
				isDisabled: false
			},
			{
				name: PAGE_NAME.ACTIVITYLOG,
				icon: "user-clock",
				iconSize: { width: "15px", height: "13px" },
				text: "Activity log",
				permission: [SUPER_ADMIN],
				isDisabled: false
			}
		]
	},
	{
		icon: "cog",
		iconSize: { width: "20px", height: "18px" },
		text: "Setting",
		permission: [SUPER_ADMIN],
		children: [
			{
				childrenRoutes: [
					PAGE_NAME.LAYOUT_CUSTOMISATION_GENGAS_CREATE,
					PAGE_NAME.LAYOUT_CUSTOMISATION_GENGAS_EDIT,
					PAGE_NAME.LAYOUT_CUSTOMISATION_POWER_CREATE,
					PAGE_NAME.LAYOUT_CUSTOMISATION_POWER_EDIT,
					PAGE_NAME.LAYOUT_CUSTOMISATION_SOR_CREATE,
					PAGE_NAME.LAYOUT_CUSTOMISATION_SOR_EDIT,
					PAGE_NAME.LAYOUT_CUSTOMISATION_ZLD_CREATE,
					PAGE_NAME.LAYOUT_CUSTOMISATION_ZLD_EDIT
				],
				name: PAGE_NAME.LAYOUT_CUSTOMISATION_LIST,
				icon: "columns",
				iconSize: { width: "12px", height: "13px" },
				text: " Layout customisation",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				childrenRoutes: [PAGE_NAME.ALERT_AND_NOTIFICATION_CREATE, PAGE_NAME.ALERT_AND_NOTIFICATION_EDIT],
				name: PAGE_NAME.ALERT_AND_NOTIFICATION_LIST,
				icon: "bell-on",
				iconSize: { width: "15px", height: "15px" },
				text: "Alert and notification",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				childrenRoutes: [PAGE_NAME.NOTIFICATION_GROUP_CREATE, PAGE_NAME.NOTIFICATION_GROUP_EDIT],
				name: PAGE_NAME.NOTIFICATION_GROUP_LIST,
				icon: "users",
				iconSize: { width: "15px", height: "13px" },
				text: "Notification group",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				name: PAGE_NAME.SHIPTO_STATUS_LIST,
				icon: "list-alt",
				iconSize: { width: "17px", height: "13px" },
				text: "Ship-to management",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				name: PAGE_NAME.SOLDTO_MANAGEMENT,
				icon: "building",
				iconSize: { width: "17px", height: "13px" },
				text: "Sold-to management",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				name: PAGE_NAME.MAINTENANCE,
				icon: "wrench",
				iconSize: { width: "12px", height: "13px" },
				text: "Maintenance mode",
				permission: [SUPER_ADMIN],
				isDisabled: false
			},
			{
				name: PAGE_NAME.STANDARD_MODULE_LIST,
				childrenRoutes: [
					PAGE_NAME.STANDARD_MODULE_CREATE,
					PAGE_NAME.STANDARD_MODULE_EDIT
				],
				icon: "table-layout",
				iconSize: { width: "17px", height: "13px" },
				text: "Standard module",
				permission: [SUPER_ADMIN],
				isDisabled: false
			}
		]
	}
]);


export const MAIN_PAGE = Object({
	name: "dashboard"
});