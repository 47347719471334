<template>
	<div
		:class="[
			'control',
			{ 'max-length': maxLength }
		]"
	>
		<textarea
			v-model="input"
			@keypress="handleKeyPress"
			:maxLength="maxLength"
			:placeholder="placeholder"
		/>
		<span class="max-length-message" v-if="isShowMaxLengthMessage">
			{{ lengthMessage }}/{{ maxLength }}
		</span>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ""
		},
		maxLength: {
			type: Number,
			default: null
		},
		placeholder: {
			type: String,
			default: ""
		}
	},


	data() {
		return {
			input: null
		};
	},

	computed: {
		isShowMaxLengthMessage() {
			return this.maxLength;
		},
		lengthMessage() {
			return (this.value && this.value.trim().length) || 0;
		}
	},

	watch: {
		input() {
			this.$emit("input", this.input);
		},
		value() {
			this.input = this.value;
		}
	},

	mounted() {
		this.input = this.value;
	},

	methods: {
		handleKeyPress(e) {
			const input = e.target.value || "";
			const isEmpty = input.trim().length === 0;
			if (e.code === "Space" && isEmpty) {
				e.preventDefault();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
textarea {
	width: 100%;
	height: rem(100);
	background-color: #f9f9f9;
	border-radius: 8px;
	border: solid 1px #cad9cf;
	padding: rem(6) rem(16);

	&:focus {
		border: rem(1) solid $color-emerald;
	}
}

.max-length-message {
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translateY(100%);
	font-size: $font-16;
	color: $color-grey-4;
}

.control {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	font-size: $font-20;
	clear: both;
	box-sizing: border-box;

	&.max-length {
		margin-bottom: rem(14);
	}
}

</style>