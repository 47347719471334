import httpClient from "./httpClient";

const NOTIFICATION = "/notifications";

const getAddableDevice = (query) => httpClient.get(`${NOTIFICATION}/addables`, {
	params: {
		...query
	}
});
const getAddableCondition = (params) => httpClient.get(`${NOTIFICATION}/conditions/addables`, { params });

const getNotificationById = (id) => httpClient.get(`${NOTIFICATION}/${id}`);

const getModuleAvailable = () => httpClient.get(`${NOTIFICATION}/modules`);
const getTypeAvailable = () => httpClient.get(`${NOTIFICATION}/types`);
const getNotificationLists = (query) => httpClient.get(`${NOTIFICATION}/`, {
	params: {
		...query
	}
});
const getNotificationShipToLists = (params) => httpClient.get(`${NOTIFICATION}/ship-tos`, { params });
const getNotificationShipToRuleLists = (id, params = {}) => httpClient.get(`${NOTIFICATION}/ship-tos/${id}`, { params });

const deleteNotificationById = (id) => httpClient.delete(`${NOTIFICATION}/${id}`);

const createNotification = (payload) => httpClient.post(`${NOTIFICATION}/`, payload);
const updateNotification = (id, payload) => httpClient.put(`${NOTIFICATION}/${id}`, payload);
export {
	getNotificationById,
	createNotification,
	getAddableDevice,
	getAddableCondition,
	getModuleAvailable,
	getTypeAvailable,
	getNotificationLists,
	getNotificationShipToLists,
	getNotificationShipToRuleLists,
	updateNotification,
	deleteNotificationById
};
