<template>
	<div>
		<BaseLoading v-if="bhyTripInfo.isLoading"/>
		<div v-else class="container-trip-info" >
			<div class="container-left">
				<div v-if="getTruckLocation" class="google-maps">
					<TripInfoMap
						:companyName="getShiptoCompany"
						:truckLocation="getTruckLocation"
						:shipToLocation="getBhyShipToLocation"
						:markStation="BIG_TANK_STATION"/>
				</div>
				<TruckIcon
					v-else
					:status="truckStatus"
					:class="['truck-icon',{ active: isMultiDrop }]"
				/>
			</div>
			<div v-if="isSingleDrop || isMultiDrop" class="container-right">
				<div class="info">
					<DriverInfo
						:class="['drivers-info',{ active: isSingleDrop || isMultiDrop }]"
						:deliveryOrderNo="getTripInfoInfomation.deliveryOrderNo"
						:tripId="getTripInfoInfomation.tripId"
						:tractorId="getTripInfoInfomation.tractorId"
						:trailerId="getTripInfoInfomation.trailerId"
						:drivers="getTripInfoDrivers"
					/>
					<!--TODO: isSiingleDrop on traicks.length > 0-->
					<Tracking
						v-if="getTrackingRoute.length>0"
						:tracks="getTrackingRoute"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import TruckIcon from "@/components/dashboard/modules/TankTripInfo/TruckIcon.vue";
import TripInfoMap from "@/components/map/TripInfoMap.vue";
import DriverInfo from "@/components/dashboard/modules/TankTripInfo/DriverInfo.vue";
import Tracking from "@/components/dashboard/modules/TankTripInfo/Tracking.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { BIG_TANK_STATION } from "../../enums/tripinfo";
import { TRUCK_STATUS, TRIPINFO_MAP_TYPE } from "../../enums/modules";

export default {
	name: "BulkHydrogenTripInfo",

	components: {
		TruckIcon,
		DriverInfo,
		Tracking,
		TripInfoMap,
		BaseLoading
	},

	data() {
		return {
			TRUCK_STATUS,
			BIG_TANK_STATION,
			TRIPINFO_MAP_TYPE,
			bhyId: this.$route.params.id,
			updateTruck: "",
			tracks: [
				{
					title: "BIG Site"
				},
				{
					title: this.getShiptoCompany
				}
			],
			// mockup data from api
			response: {
				type: null, // can be SingleDrop and MuktiDrop only
				map: null // can be null and object only
			}
		};
	},

	computed: {
		...mapState(["bhyInfo", "bhyTripInfo"]),

		...mapGetters({
			getShiptoCompany: "shipTos/getShiptoCompany",
			getBhyShipToLocation: "getBhyShipToLocation",
			getTripInfoDrivers: "bhyTripInfo/getTripInfoDrivers",
			getTripInfoType: "bhyTripInfo/getTripInfoType",
			getTripInfoInfomation: "bhyTripInfo/getTripInfoInfomation",
			getTruckLocation: "bhyTripInfo/getTruckLocation"
		}),

		isInTransit() {
			return this.getTripInfoType === this.TRIPINFO_MAP_TYPE.MULTI_DROP;
		},

		isNotDelivery() {
			return this.getTripInfoType === this.TRIPINFO_MAP_TYPE.NO_DELIVERY;
		},

		isSingleDrop() {
			return this.getTripInfoType !== this.TRIPINFO_MAP_TYPE.MULTI_DROP
			&& this.getTripInfoType !== this.TRIPINFO_MAP_TYPE.NO_DELIVERY;
		},

		isMultiDrop() {
			return this.getTripInfoType === this.TRIPINFO_MAP_TYPE.MULTI_DROP;
		},

		truckStatus() {
			return this.TRUCK_STATUS[this.bhyInfo.data.deliveryStatus];
		},

		getTrackingRoute() {
			if (this.getTruckLocation) {
				return [
					{
						title: "BIG site"
					},
					{
						title: this.getShiptoCompany
					}
				];
			}
			return [];
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogTripInfoView({
			httpStatus: this.bhyTripInfo.statusCode,
			shopToId: this.bhyId
		});
		this.$emit("loaded");
	},

	destroyed() {
		clearInterval(this.updateTruck);
	},

	methods: {
		...mapActions({
			getBhyTripInfo: "bhyTripInfo/getBhyTripInfo",
			getUpdateTruckLocation: "bhyTripInfo/getUpdateTruckLocation"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogTripInfoView: "addActivityLogTripInfoView"
		}),
		async fetchData() {
			await this.getBhyTripInfo({ id: this.bhyId });
			await this.handleUpdateTruck();
		},
		handleUpdateTruck() {
			this.updateTruck = setInterval(() => {
				this.getUpdateTruckLocation({
					id: this.bhyId
				});
			}, 1000 * 10);
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	&-trip-info {
		display: flex;
		margin-top: -$dashboard-module-nav-spacing;
		margin-left: -$main-content-padding;
		margin-bottom: -$main-content-padding;
	}

	&-left {
		display: flex;
		flex: 1;
		min-height: rem(500);
		justify-content: center;

		.truck-icon {
			margin-top: rem(64);
		}

		.google-maps {
			display: flex;
			flex: 1;
			position: relative;
			height: rem(767);
			overflow: hidden;
		}

		.google-maps iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}

	&-right {
		display: flex;
		align-items: flex-start;
		width: rem(345);
		border-left: 1px solid $color-silver;

		> .info {
			padding-left: rem(30);
			margin-bottom: rem(30);

			.drivers-info {
				border-bottom: 1px solid $color-silver;
				margin-bottom: $spacing-6;
			}

			hr {
				border: none;
				height: 1px;
				background: $color-silver;
				margin-bottom: $spacing-6;
			}
		}

		.info {
			.container-main {
				&:first-of-type {
					margin-top: $spacing-7;
				}
			}
		}
	}

	@include mobile {
		&-trip-info {
			margin-top: 0;
		}
	}

	@include tablet {
		&-trip-info {
			display: flex;
			flex-direction: column;
			margin-left: -$main-content-padding;
			margin-bottom: -$main-content-padding;
		}

		&-left {
			min-height: auto;
			margin-right: -$main-content-padding;

			.truck-icon {
				&.active {
					border-bottom: 1px solid $color-silver;
					padding-bottom: rem(20);
				}
			}
		}

		&-right {
			width: 100%;
			border: 0;
		}

		.info {
			width: 100%;
		}
	}
}
</style>
