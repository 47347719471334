import { PAGE_NAME } from "../../enums/pagePermission";

// key names are the permissions following by server side
export const mapRouteNameWithUserPermissions = {
	"Marketplace.Read": PAGE_NAME.MARKETPLACE,
	"Dashboard.Read": PAGE_NAME.DASHBOARD,
	"FlowDiagram.Read": PAGE_NAME.FLOWDIAGRAM_LIST,
	"Invoice.Read": PAGE_NAME.INVOICE,
	"EOrdering.Read": PAGE_NAME.E_ORDERING_LIST,
	"PowerBIExecutive.Read": PAGE_NAME.POWERBI
};

export const getRedirectRouteName = (rolePermissions = []) => {
	let routeName = null;

	for (const permissionKey of Object.keys(mapRouteNameWithUserPermissions)) {
		if (rolePermissions.includes(permissionKey)) {
			routeName = mapRouteNameWithUserPermissions[permissionKey];
			break;
		}
	}

	return routeName || PAGE_NAME.PERMISSION_REQUIRED;
};