import { BIG_STORE_OUR_DEVICE_CREATE_REQUEST,
	BIG_STORE_OUR_DEVICE_CREATE_SUCCESS,
	BIG_STORE_OUR_DEVICE_CREATE_FAILURE,
	BIG_STORE_OUR_DEVICE_REQUEST,
	BIG_STORE_OUR_DEVICE_SUCCESS,
	BIG_STORE_OUR_DEVICE_FAILURE,
	BIG_STORE_OUR_DEVICE_EDIT_REQUEST,
	BIG_STORE_OUR_DEVICE_EDIT_SUCCESS,
	BIG_STORE_OUR_DEVICE_EDIT_FAILURE,
	BIG_STORE_OUR_DEVICE_UPDATE_REQUEST,
	BIG_STORE_OUR_DEVICE_UPDATE_SUCCESS,
	BIG_STORE_OUR_DEVICE_UPDATE_FAILURE,
	BIG_STORE_OUR_DEVICE_DELETE_REQUEST,
	BIG_STORE_OUR_DEVICE_DELETE_SUCCESS,
	BIG_STORE_OUR_DEVICE_DELETE_FAILURE } from "../../types";
import { postOurDeviceCreateAPI, getOurdeviceByIdAPI, getOurDeviceAPI, putOurDeviceUpdateAPI, deleteDeviceAPI } from "../../../services/api/bigStoreManagement"; // getBannersAPI
import { parseOurDevicelist } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		ourDeviceCreateSuccess: false,
		ourDeviceUpdateSuccess: false,
		ourDeviceDeleteSuccess: false,
		errors: {},
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[BIG_STORE_OUR_DEVICE_REQUEST](state) {
			state.data = [];
			state.pagination = {
				currentPage: 1,
				lastPage: 1,
				total: 0
			};
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BIG_STORE_OUR_DEVICE_SUCCESS](state, { data, pagination }) {
			state.data = parseOurDevicelist(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[BIG_STORE_OUR_DEVICE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[BIG_STORE_OUR_DEVICE_CREATE_REQUEST](state) {
			state.isLoading = true;
			state.ourDeviceCreateSuccess = false;
		},
		[BIG_STORE_OUR_DEVICE_CREATE_SUCCESS](state, data) {
			state.data = data;
			state.isLoading = false;
			state.ourDeviceCreateSuccess = true;
		},
		[BIG_STORE_OUR_DEVICE_CREATE_FAILURE](state, errors) {
			state.errors = errors;
			state.ourDeviceCreateSuccess = false;
		},
		[BIG_STORE_OUR_DEVICE_UPDATE_REQUEST](state) {
			state.isLoading = true;
			state.ourDeviceUpdateSuccess = false;
		},
		[BIG_STORE_OUR_DEVICE_UPDATE_SUCCESS](state, { data, status }) {
			state.data = data;
			state.isLoading = false;
			state.ourDeviceUpdateSuccess = status === 204;
		},
		[BIG_STORE_OUR_DEVICE_UPDATE_FAILURE](state, errors) {
			state.errors = errors;
			state.ourDeviceUpdateSuccess = false;
		},
		[BIG_STORE_OUR_DEVICE_EDIT_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BIG_STORE_OUR_DEVICE_EDIT_SUCCESS](state, { data, pagination }) {
			state.data = parseOurDevicelist(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[BIG_STORE_OUR_DEVICE_EDIT_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[BIG_STORE_OUR_DEVICE_DELETE_REQUEST](state) {
			state.isLoading = true;
			state.ourDeviceDeleteSuccess = false;
		},
		[BIG_STORE_OUR_DEVICE_DELETE_SUCCESS](state, { status }) {
			state.isLoading = false;
			state.ourDeviceDeleteSuccess = status === 200;
		},
		[BIG_STORE_OUR_DEVICE_DELETE_FAILURE](state, errors) {
			state.errors = errors;
			state.ourDeviceDeleteSuccess = false;
		}

	},

	actions: {
		async sendOurDeviceDeleteAPI({ commit }, id) {
			try {
				commit(BIG_STORE_OUR_DEVICE_DELETE_REQUEST);
				const data = await deleteDeviceAPI(id);
				commit(BIG_STORE_OUR_DEVICE_DELETE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_OUR_DEVICE_DELETE_FAILURE, errors);
			}
		},
		async sendOurDeviceUpdate({ commit }, payload) {
			try {
				commit(BIG_STORE_OUR_DEVICE_UPDATE_REQUEST);
				const data = await putOurDeviceUpdateAPI(payload);
				commit(BIG_STORE_OUR_DEVICE_UPDATE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_OUR_DEVICE_UPDATE_FAILURE, errors);
			}
		},
		async sendCreateOurDevice({ commit }, payload) {
			try {
				commit(BIG_STORE_OUR_DEVICE_CREATE_REQUEST);
				const { data } = await postOurDeviceCreateAPI(payload);
				commit(BIG_STORE_OUR_DEVICE_CREATE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_OUR_DEVICE_CREATE_FAILURE, errors);
			}
		},
		async fetchOurDeviceList({ commit }, params = {}) {
			try {
				commit(BIG_STORE_OUR_DEVICE_REQUEST);
				const { data } = await getOurDeviceAPI(params);

				commit(BIG_STORE_OUR_DEVICE_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_OUR_DEVICE_FAILURE);
			}
		},
		async fetchOurDeviceById({ commit }, id) {
			try {
				commit(BIG_STORE_OUR_DEVICE_EDIT_REQUEST);
				const { data } = await getOurdeviceByIdAPI({ page: 1, deviceId: id });
				commit(BIG_STORE_OUR_DEVICE_EDIT_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_OUR_DEVICE_EDIT_FAILURE);
			}
		}
	}
};
