<template>
	<BaseLayout pageTitle="Customer account">
		<div class="container-action-bar" v-if="manageAble">
			<router-link
				:to="{ name: PAGE_NAME.CUSTOMER_CREATE }"
			>
				<BaseButton
					size="large"
				>
					Create customer account
				</BaseButton>
			</router-link>
		</div>
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:roles="roles"
					:statuses="statuses"
					:types="types"
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>

			<BaseNoAccounts v-if="isEmpty"/>

			<BaseTableCustom
				v-else
				:tableOptions="tableOptions"
				:pagination="pagination"
				:loading="isLoading"
				@onPageChange="handlePageChange"
			>
				<template v-slot:thead>
					<tr>
						<th
							v-for="({value, key}, index) in table.thead"
							:key="index"
						>
							<div class="header-content">
								{{ value }}
								<BaseSort
									v-if="key"
									:sortName="key"
									:sortBy="getSortingBykey(key)"
									@handleSort="handleSort"
								/>
							</div>
						</th>
					</tr>
				</template>
				<template v-slot:tbody>
					<tr
						v-for="(item, index) in table.tbody"
						:key="index"
						:class="[
							{
								inactive: !item.isActive
							}
						]"
					>
						<td class="max-width-sold-to">
							{{ item.companyName }}
						</td>
						<td class="max-width-default">
							{{ item.name }}
						</td>
						<td class="max-width-default">
							<BaseBadge v-if="item.isTestAccount" text="Test" size="small" color="grey"/>
							{{ item.email }}
						</td>
						<td class="max-width-default">
							{{ item.role }}
						</td>
						<td>
							<BaseBadge v-if="item.isActive" text="Active" size="small" color="success-dark"/>
							<BaseBadge v-else text="Inactive" size="small" outline/>
						</td>
						<td class="align-right action">
							<router-link
								v-if="manageAble"
								:to="`/customers/${item.id}`"
							>
								<button class="view-button btn-link">
									View detail
								</button>
							</router-link>
							<router-link
								v-if="manageAble"
								:to="{
									name: PAGE_NAME.ACTIVITYLOG,
									query: {
										page: 1,
										q: item.email,
										userType: 'customer',
									}
								}"
								target="_blank"
								class="ml-7"
							>
								<button class="view-button btn-link">
									View log
								</button>
							</router-link>
						</td>
					</tr>
				</template>
			</BaseTableCustom>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SearchFilterForm from "@/components/customerManagement/SearchFilterForm.vue";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseNoAccounts from "@/components/customerManagement/BaseNoAccounts.vue";
import BaseSort from "@/components/BaseSort.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import { getCustomerRolesFilter } from "../../selectors/transform/customer/customerRoleDropdown";
import { getCustomerStatusesFilter } from "../../selectors/transform/customer/customerStatusDropdown";
import { cleanParams, cloneDeep, allNull } from "../../selectors/helpers";
import { PAGE_NAME } from "../../enums/pagePermission";
import { STAFF_ROLES } from "../../enums/role";

export default {
	name: "customers",

	components: {
		SearchFilterForm,
		BaseTableCustom,
		BaseNoAccounts,
		BaseSort,
		BaseBadge
	},

	data() {
		return {
			query: {
				page: this.$route.query.page || null,
				q: this.$route.query.q || null,
				role: this.$route.query.role || null,
				status: this.$route.query.status || null,
				type: this.$route.query.type || null,
				sort: this.$route.query.sort || null
			},
			tableOptions: [
				"is-striped is-fullwidth"
			],
			sort: {
				companyName: "",
				name: "asc"
			},
			PAGE_NAME
		};
	},

	computed: {
		...mapState({
			response: (state) => {
				return state.customer;
			},
			user: (state) => {
				return state.user;
			}
		}),

		roles() {
			return getCustomerRolesFilter();
		},

		statuses() {
			return getCustomerStatusesFilter();
		},

		types() {
			return [
				{ label: "Type: All", value: null },
				{ label: "Normal", value: "normal" },
				{ label: "Test", value: "test" }
			];
		},

		table() {
			return {
				thead: [
					{ value: "Company name", key: "companyName" },
					{ value: "Name", key: "name" },
					{ value: "Email", key: "" },
					{ value: "System role", key: "" },
					{ value: "Status", key: "" },
					{ value: "", key: "" }
				],
				tbody: this.response.data
			};
		},

		pagination() {
			return this.response.pagination;
		},

		isFiltered() {
			return !Object.values(this.query).every((v) => v === null);
		},

		isLoading() {
			return this.response.isLoading;
		},

		isEmpty() {
			return this.response.isEmpty && !this.isFiltered;
		},

		manageAble() {
			return [
				STAFF_ROLES.SUPER_ADMIN,
				STAFF_ROLES.ADMIN
			].includes(this.user.role);
		},

		getQuery() {
			const cloneQuery = cloneDeep(this.query);
			const query = cleanParams(cloneQuery);
			return query;
		}
	},

	created() {
		const { sort = "" } = this.$route.query;
		const [sortName, sortBy] = sort.split(":");
		if (sortName && sortBy) {
			this.handleSetSortTable(sortName, sortBy);
		}


		this.fetchCustomers();
	},

	methods: {
		...mapActions({
			fetchCustomerLists: "customer/fetchCustomerLists"
		}),

		getSortingBykey(key) {
			return this.sort[key];
		},
		handleSetSortTable(sortName, sortBy) {
			Object.keys(this.sort).forEach((keyName) => {
				if (keyName === sortName) {
					this.sort[keyName] = sortBy;
				} else {
					this.sort[keyName] = "";
				}
			});
		},

		handleSort(value) {
			const { sortName, sortBy } = value;
			this.handleSetSortTable(sortName, sortBy);
			this.query.sort = `${sortName}:${sortBy}`;
			this.fetchCustomers();
		},

		async fetchCustomers() {
			this.filterQuery();
			this.$router.push({ query: this.getQuery }).catch(() => {});
			await this.fetchCustomerLists(this.getQuery);
		},

		handleSubmitFilter(form = {}) {
			this.query.q = form.keyword ? form.keyword.trim() : form.keyword;
			this.query.role = form.role;
			this.query.status = form.status;
			this.query.type = form.type;
			this.query.page = 1;
			this.fetchCustomers();
		},

		handlePageChange(pageNum) {
			this.query.page = pageNum;
			this.fetchCustomers();
		},

		// side effect
		filterQuery() {
			if (allNull(this.query.q, this.query.role, this.query.status, this.query.type) && Number(this.query.page) === 1) {
				this.query.page = null;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.header-content {
	display: flex;
}

.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}

td {
	word-wrap: break-word;
}

.max-width-sold-to {
	max-width: rem(204);
}

.max-width-default {
	max-width: rem(150);
}

.action {
	display: flex;
	justify-content: flex-end;
}

.ml-7 {
	margin-left: $spacing-7;
}
</style>
