/**
 * Enum for report types.
 * @readonly
 * @enum {EnumReportTypes}
 */
export const REPORT_TYPE = Object.freeze({
	lastDay: "lastDay",
	daily: "daily",
	weekly: "weekly",
	monthly: "monthly"
});
