<template>
	<div class="air-comp-icon">
		<div :class="[mainClasses]">
			<div class="air-comp-icon-details">
				<div class="air-comp-header">
				</div>
				<div :class="['air-comp-value', getAirCompValueClass]">{{ specKw }}</div>
				<div class="air-comp-unit" v-html="unit">
				</div>
			</div>
			<svg class="air-comp-icon-svg" viewBox="0 0 100 100">
				<path
					:d="getFlowRateStyle.pathString"
					:stroke-width="strokeWidth"
					:style="getFlowRateStyle.trailPathStyle"
					class="air-comp-icon-svg-bg"
					fill-opacity="0"
				></path>
				<path
					:d="getFlowRateStyle.pathString"
					:stroke-width="strokeWidth"
					:style="getGreenStokePathStyle"
					class="air-comp-icon-svg-green"
					fill-opacity="0"
				></path>
				<path
					:d="getFlowRateStyle.pathString"
					:stroke-width="strokeWidth"
					:style="pathPointStyles.strokePathStyle"
					class="air-comp-icon-svg-point-bg"
					fill-opacity="0"
				></path>
				<path
					:d="getFlowRateStyle.pathString"
					:stroke-width="strokeWidth"
					:style="pathPointStyles.strokePathStyle"
					class="air-comp-icon-svg-point"
					fill-opacity="0"
				></path>
			</svg>
		</div>
	</div>
</template>

<script>
import { parseAddCommaWhenIsNumber } from "../../../selectors/helpers/index";

export default {
	name: "AirCompIconNonFan",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},

		valueForPercentage: {
			type: [Number, String],
			default: 0
		},

		maxValue: {
			type: Number,
			default: 0
		},

		countAirCompActive: {
			type: Number,
			default: 0
		},

		size: {
			type: String,
			default: "small",
			validator: (value) => {
				return [
					"smallest",
					"small",
					"medium",
					"large"
				].includes(value);
			}
		},

		unit: {
			type: String,
			default: ""
		},

		min: {
			type: [String, Number],
			default: null
		},

		max: {
			type: [String, Number],
			default: null
		}
	},

	computed: {
		getPercentagePoint() {
			const calculatePercent = (((Number(this.valueForPercentage) - Number(this.min)) * 100) / (Number(this.max) - Number(this.min))) ?? 0;
			if (calculatePercent > 100) return 100;
			else if (calculatePercent < 0) return 0;
			return calculatePercent;
		},

		getAirCompValueClass() {
			if (this.getPercentagePoint <= 75) {
				return "green";
			}

			return "red";
		},

		mainClasses() {
			return ["air-comp-icon-container", this.size];
		},

		specKw() {
			if (this.value === "N/A") {
				return "N/A";
			}
			return parseAddCommaWhenIsNumber(this.value);
		},

		strokeWidth() {
			return this.type === "small" ? 6 : 8;
		},

		getFlowRateStyle() {
			// percent flowrate will default 80
			const percent = 80;
			const gapDegree = 0;
			const radius = 50 - (this.strokeWidth / 2);
			const beginPositionX = 0;
			const beginPositionY = -radius;
			const endPositionX = 0;
			const endPositionY = -2 * radius;

			const pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
			a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
			a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;

			const len = Math.PI * 2 * radius;
			const trailPathStyle = {
				strokeDasharray: `${len - gapDegree - 78}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};
			const strokePathStyle = {
				strokeDasharray: `${(percent / 100) * (len - gapDegree - 78)}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};

			return { pathString, trailPathStyle, strokePathStyle };
		},

		getGreenStokePathStyle() {
			const percent = 75;
			const gapDegree = 0;
			const radius = 50 - (this.strokeWidth / 2);
			const len = Math.PI * 2 * radius;

			return {
				strokeDasharray: `${(percent / 100) * (len - gapDegree - 78)}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};
		},

		getOrangeStokePathStyle() {
			const percent = 75;
			const gapDegree = 0;
			const radius = 50 - (this.strokeWidth / 2);
			const len = Math.PI * 2 * radius;

			return {
				strokeDasharray: `${(percent / 100) * (len - gapDegree - 78)}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};
		},


		pathPointStyles() {
			const gapDegree = 0;
			const radius = 50 - (this.strokeWidth / 2);
			const beginPositionX = 0;
			const beginPositionY = -radius;
			const endPositionX = 0;
			const endPositionY = -2 * radius;

			const pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
			a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
			a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;

			const len = Math.PI * 2 * radius;

			const trailPathStyle = {
				strokeDasharray: `${len - gapDegree - 78}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};
			const rotateDeg = (264 * (this.getPercentagePoint)) / 100;

			const strokePathStyle = {
				strokeDasharray: `0px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`,
				transform: `rotate(${rotateDeg}deg)`,
				transformOrigin: "center"
			};

			return { pathString, trailPathStyle, strokePathStyle };
		}
	}
};
</script>

<style lang="scss" scoped>
.air-comp-icon {
	$medium-width: rem(73);
	$medium-height: rem(73);

	margin-top: rem(-10);

	// .air-comp-icon-header
	&-header {
		display: flex;
		margin-bottom: rem(6);

		&.large {
			.air-comp-workings {
				width: rem(24);
				height: rem(24);
			}
		}

		.air-comp-workings {
			margin-left: auto;
			margin-right: rem(20);

			width: rem(12);
			height: rem(12);
			line-height: 1;
			overflow: hidden;

			border-radius: 100%;
			background-color: $color-bg-press-outline;

			&.-active-0 {
				background-color: $color-red;
			}

			&.-active-1 {
				&::before {
					content: "";
					display: block;
					margin-left: 50%;
					height: 50%;
					border-top-right-radius: 100%;
					background: $color-emerald;
					transform-origin: left;
				}
			}

			&.-active-2 {
				&::before {
					content: "";
					display: block;
					margin-left: 50%;
					height: 100%;
					border-radius: 0 100% 100% 0 / 50%;
					background: $color-emerald;
					transform-origin: left;
				}
			}

			&.-active-3 {
				&::before {
					content: "";
					display: block;
					margin-left: 50%;
					height: 100%;
					border-radius: 0 100% 100% 0 / 50%;
					background: $color-emerald;
					transform-origin: left;
					transform: rotate(0.25turn);
				}

				&::after {
					content: "";
					display: block;
					margin-left: 50%;
					height: 50%;
					border-top-right-radius: 100%;
					background: $color-emerald;
					transform-origin: left;
					margin-top: -100%;
				}
			}

			&.-active-4 {
				background: $color-emerald;
			}
		}
	}

	// .air-comp-icon-container
	&-container {
		position: relative;
		width: rem(73);
		height: rem(73);

		&.smallest {
			width: rem(58);
			height: rem(58);

			.air-comp-value {
				font-size: $font-18;
				margin-bottom: rem(-5);
			}

			.air-comp-unit {
				font-size: $font-12;
			}
		}

		&.medium {
			width: $medium-width;
			height: $medium-height;

			.air-comp-value {
				font-size: $font-18;
				margin-bottom: rem(-5);
			}

			.air-comp-unit {
				font-size: $font-14;
			}
		}

		&.large {
			width: rem(130);
			height: rem(130);

			.air-comp-header {
				margin-top: rem(26);
			}

			.air-comp-value {
				font-size: $font-40;
			}

			.air-comp-unit {
				font-size: $font-26;

				.fraction {
					margin-bottom: rem(8);

					.top {
						line-height: 0.6;
						font-size: $font-26;

						sup {
							font-size: $font-16;
						}
					}
				}
			}
		}
	}

	&-svg {
		width: 110%;
		height: 100%;
		transform: rotate(230deg);
		transform-origin: center;
		margin-left: -10%;

		path {
			stroke-linecap: round;
		}

		&-bg {
			stroke: $color-red;
		}

		&-orange {
			stroke: $color-dull-orange;
		}

		&-green {
			stroke: $color-emerald;
		}

		&-point {
			&-bg {
				stroke-width: rem(10);
				stroke: $color-bg-press-outline;
			}
		}
	}

	&-details {
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		color: $color-dark-blue-grey;
		text-align: center;
		white-space: nowrap;
		margin-left: -5%;

		.air-comp-header {
			margin-top: rem(14);
		}

		.air-comp-value {
			@include fontSub();

			color: $color-dark-blue-grey;
			font-size: $font-21;
			font-weight: 600;
			line-height: 1;
			margin-bottom: rem(-2);

			&.red {
				color: $color-red;
			}

			&.green {
				color: $color-emerald;
			}

			&.orange {
				color: $color-dull-orange;
			}
		}

		.air-comp-unit {
			color: $color-grey-4;
			font-size: $font-12;
			line-height: 1;
			white-space: normal;

			::v-deep p {
				color: $color-grey-4;
			}

			.fraction {
				position: relative;

				display: inline-block;
				vertical-align: middle;
				text-align: center;

				font-size: $font-10;
				line-height: 0.8;

				.top {
					line-height: 0.3;
				}

				.symbol {
					display: none;
				}

				.bottom {
					border-top: 1px solid $color-grey-3;
				}
			}

			.fraction > span {
				display: block;
			}
		}
	}
}
</style>
