import httpClient from "./httpClient";

const SHIPTO_STATUS_END_POINT = "/ship-tos/visibilities";
const getShipToStatusAPI = (params) => httpClient.get(`${SHIPTO_STATUS_END_POINT}`, { params });
const putShipToStatusAPI = (payload) => httpClient.put(`${SHIPTO_STATUS_END_POINT}`, payload);

export {
	getShipToStatusAPI,
	putShipToStatusAPI
};
