<template>
	<BaseLayout pageTitle="Standard module list">
		<div class="container-action-bar" v-if="manageAble">
			<router-link
				:to="{ name: PAGE_NAME.STANDARD_MODULE_CREATE }"
			>
				<BaseButton
					size="large"
				>
					Create new template
				</BaseButton>
			</router-link>
		</div>
		<div class="main-content">
			<div class="row">
				<SearchFilterForm
					:query="query"
					@submit="handleSubmitFilter"
				/>
			</div>
			<div class="row container-list">
				<StandardModuleList
					:loading="standardModule.isLoading"
					:items="standardModule.data"
					@handleModal="showModalDelete"
				/>
				<BasePagination
					v-if="standardModule.data.length > 0"
					:value="standardModule.pagination.currentPage"
					:totalPage="standardModule.pagination.lastPage"
					class="module-pagination"
					@onPageChange="handlePageChange"
				/>
				<span class="page-total">{{ getShipToLabel }} of {{ standardModule.pagination.total }} templates</span>
			</div>
		</div>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_STANDARD_MODULE"
			title="Delete the standard module?"
			:message="msgDeleteStandardModule"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleModalDeleteThisTemplate"
		/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_STANDARD_MODULE_TEMPLATE_APPLIED"
			title="Delete the standard module"
			:showConfirmButton="false"
			cancelButtonText="Go to edit this template"
			:message="msgDeleteStandardModuleTemplateApplied"
			@onCancel="handleModalCancelTemplateApplied"
		/>
	</BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SearchFilterForm from "@/components/StandardModule/SearchFilterForm.vue";
import StandardModuleList from "@/components/StandardModule/StandardModuleList.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { cleanParams } from "../../selectors/helpers/";
import { TOAST_TYPES } from "../../enums/toast";
import { PAGE_NAME } from "../../enums/pagePermission";
import { STAFF_ROLES } from "../../enums/role";
import { getShipToDisplay } from "../../selectors/helpers/dashboard";

export default {
	name: "standardModule",

	components: {
		SearchFilterForm,
		StandardModuleList,
		BaseModalConfirmDelete
	},

	data() {
		return {
			PAGE_NAME,
			query: {
				page: this.$route.query.page || null,
				keyword: this.$route.query.keyword || null
			},
			MODAL_DELETE_STANDARD_MODULE: "model-delete-standard-module",
			MODAL_DELETE_STANDARD_MODULE_TEMPLATE_APPLIED: "model-cant-delete-standard-module",
			templateName: ""
		};
	},

	computed: {
		...mapState(["standardModule"]),
		...mapState({
			user: (state) => {
				return state.user;
			}
		}),

		getShipToLabel() {
			return getShipToDisplay(
				this.standardModule.pagination.currentPage,
				this.standardModule.pagination.perPage,
				this.standardModule.pagination.total
			);
		},

		manageAble() {
			return [
				STAFF_ROLES.SUPER_ADMIN,
				STAFF_ROLES.ADMIN
			].includes(this.user.role);
		},

		msgDeleteStandardModule() {
			return `<div>
				<div>Are you sure to delete <span class="std-module-delete-header-name">${this.templateName}</span> ? </div>
			</div>`;
		},

		msgDeleteStandardModuleTemplateApplied() {
			return `<div>
				<div>You cannot delete <span class="std-module-delete-header-name">${this.templateName}</span> because there is/are some devices are being assigned in the Apply to section at the moment.</div>
				<div>Please remove all devices and then try again.</div>
			</div>`;
		}

	},

	async created() {
		await Promise.all([
			this.fetchData(),
			this.addActivityLogStandardModuleList()
		]);
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),
		...mapActions("standardModule", {
			getStandardModuleList: "getStandardModuleList",
			deleteStandardModule: "deleteStandardModule"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogStandardModuleList: "addActivityLogStandardModuleList",
			addActivityLogStandardModuleDelete: "addActivityLogStandardModuleDelete"
		}),
		updateUrl() {
			const query = cleanParams(JSON.parse(JSON.stringify(this.query)));
			this.$router.push({ query }).catch(() => {});
		},
		async fetchData() {
			this.updateUrl();
			await this.getStandardModuleList(this.query);
		},
		handleSubmitFilter(form = {}) {
			this.query.keyword = form.keyword;
			this.query.page = 1;
			this.fetchData();
		},
		handlePageChange(pageNum = 1) {
			this.query.page = pageNum;
			this.fetchData();
		},
		showModalDelete(params) {
			this.templateName = params.templateName;
			this.$modal.show(this.MODAL_DELETE_STANDARD_MODULE, params);
		},
		showModalTemplateApplied(id) {
			this.$modal.show(this.MODAL_DELETE_STANDARD_MODULE_TEMPLATE_APPLIED, id);
		},
		handleModalCancelTemplateApplied(id) {
			this.$modal.hide(this.MODAL_DELETE_STANDARD_MODULE_TEMPLATE_APPLIED);
			this.$router.push({
				name: PAGE_NAME.STANDARD_MODULE_EDIT,
				params: {
					id
				}
			});
		},
		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_STANDARD_MODULE);
		},
		async handleModalDeleteThisTemplate(params) {
			await this.deleteStandardModule(params.id);
			if (this.standardModule.templateApplied === true) {
				this.$modal.hide(this.MODAL_DELETE_STANDARD_MODULE);
				this.showModalTemplateApplied(params.id);
			} else if (this.standardModule.deleteSuccess) {
				await this.fetchStdModuleAfterDeleted();
				await this.addActivityLogStandardModuleDelete(this.templateName);
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Deleted the standard template successfully.",
						message: "Please do not forget to re-assign devices again if necessary."
					}
				});
				this.$modal.hide(this.MODAL_DELETE_STANDARD_MODULE);
			}
		},
		async fetchStdModuleAfterDeleted() {
			const calPage = this.standardModule.pagination.total === 1 ? 1 : Math.ceil((this.standardModule.pagination.total - 1) / this.standardModule.pagination.perPage);
			this.query.page = calPage < this.standardModule.pagination.currentPage ? calPage : this.standardModule.pagination.currentPage;
			await this.fetchData();
		}
	}
};
</script>

<style lang="scss" scoped>
.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.container-list {
	padding-left: $padding-base;
	padding-right: $padding-base;

	.module-pagination {
		display: flex;
		justify-content: flex-end;
	}
}

.page-total {
	display: flex;
	justify-content: flex-end;
	margin: rem(8) rem(8) 0 0;
	color: $color-grey-2;
	font-weight: $font-weight-bold;
}

.pagination-container {
	margin: rem(40) 0 0;
}

::v-deep .std-module-delete-header-name {
	font-weight: $font-weight-bold;
}
</style>
