<template>
	<div class="container-headtime">
		{{ time }}
	</div>
</template>

<script>
export default {
	name: "BaseListViewItemEvent",

	props: {
		time: {
			type: String,
			default: ""
		}
	}
};
</script>

<style lang="scss" scoped>
.container-headtime {
	height: rem(16);
	line-height: rem(16);
	font-size: $font-16;
	font-weight: $font-weight-bold;
	margin-top: rem(24);
	margin-bottom: rem(15);

	&:first-of-type {
		margin-top: 0;
	}
}
</style>