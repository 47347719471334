<template>
	<div
		:class="['container-sort', { active: isShowOptions }]"
		v-click-outside="onClickOutSide"
	>
		<a
			class="sorting-value"
			@click.prevent="toggleDropdown"
		>
			<span class="sorting-value-title">
				{{ mergeOptions.title }}:
			</span>
			<span class="sorting-value-selected">
				{{ selectedText }}
				<FontAwesomeIcon
					:icon="arrowIcon"
				/>
			</span>

		</a>
		<ul
			:style="[
				{ [mergeOptions.style.position]: 0 },
				{ width: mergeOptions.style.width }
			]"
			class="sorting-options"
		>
			<li
				v-for="(item, index) in mergeOptions.dropdown"
				:key="index"
				class="sorting-options-item"
			>
				<a
					:class="['sorting-options-item-link', { active: activeAtIndex === index }]"
					@click.prevent="onSelectItem(index)"
				>
					<span>
						{{ item.text }}
					</span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "BaseSortBy",

	props: {
		value: {
			type: Number,
			default: 0
		},
		/**
		 * options
		 * { Number } defaultAtIndex: default at index
		 * { Array } dropdown: list of items
		 * { Object } style
		 * { String } position: left, right
		 * { String } width: width of dropdown
		 */
		options: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			activeAtIndex: null,
			isShowOptions: false,
			mergeOptions: {
				defaultAtIndex: 0,
				dropdown: [],
				style: {
					position: "left",
					width: "100%"
				}
			}
		};
	},

	computed: {
		selectedText() {
			return this.mergeOptions.dropdown[this.activeAtIndex]?.text;
		},
		arrowIcon() {
			if (this.isShowOptions) {
				return ["far", "chevron-circle-up"];
			}

			return ["far", "chevron-circle-down"];
		}
	},

	watch: {
		activeAtIndex(newValue, oldValue) {
			if (oldValue === null) return;

			this.$emit("change", this.mergeOptions.dropdown[newValue]);
			this.$emit("input", newValue);
		},
		// when pearent change the `v-model`, we have also to change `activeAtIndex`.
		value(newValue) {
			this.activeAtIndex = newValue;
		}
	},

	created() {
		this.mergeOptions = {
			...this.mergeOptions,
			...this.options
		};
		this.activeAtIndex = this.mergeOptions.defaultAtIndex;
	},

	methods: {
		onSelectItem(index) {
			this.activeAtIndex = index;
			this.isShowOptions = false;
		},
		onClickOutSide() {
			this.isShowOptions = false;
		},
		toggleDropdown() {
			this.isShowOptions = !this.isShowOptions;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-sort {
	position: relative;

	&.active {
		.sorting-value {
			background-color: $color-ice-two;
		}

		.sorting-options {
			display: block;
		}
	}

	.sorting-value {
		padding: rem(4) rem(8);
		border-radius: 4px;
		cursor: pointer;

		&.active, &:hover {
			background-color: $color-ice-two;
		}

		&-title {
			@include fontBase();

			color: $color-dark-blue-grey;
		}

		&-selected {
			color: $color-cerulean;
			font-weight: $font-weight-bold;

			svg {
				position: relative;
				top: rem(2);
				font-size: $font-16;
				margin-left: rem(5);
			}
		}
	}

	.sorting-options {
		@include fontBase();

		display: none;
		position: absolute;
		bottom: rem(-8);
		transform: translateY(100%);
		width: 100%;
		padding: rem(8) 0;
		border-radius: 4px;
		box-shadow: 0 2px 8px 0 rgba(16, 51, 50, 0.2);
		border: 1px solid $color-silver;
		background-color: $color-white;
		z-index: 4;

		&-item {
			&-link {
				display: block;
				padding: rem(2) rem(18);
				color: $color-dark-blue-grey;
				cursor: pointer;

				&:hover, &.active {
					background-color: $color-ice-two;
				}

				.svg-inline--fa {
					font-size: $font-16;
					color: $color-cerulean;
					margin-right: rem(8);
				}
			}
		}
	}
}
</style>