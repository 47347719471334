import dayjs from "dayjs";
import { DEVICE_NOSIGNAL_FAILURE, DEVICE_NOSIGNAL_REQUEST, DEVICE_NOSIGNAL_SUCCESS } from "../../types";
import { getDeviceNoSignalAPI } from "../../../services/api/devices.api";
import { transformViewDevices } from "../../../services/api/transforms/parserViewDevices";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		lastUpdate: ""
	},
	getters: {
		getDevicesNoSignalLists(state) {
			return transformViewDevices(state.data);
		},
		getDevicesNoSignalLoading(state) {
			return state.isLoading;
		},
		getLastUpdate(state) {
			if (state.lastUpdate) {
				return dayjs(state.lastUpdate).format("D MMM YYYY, HH:mm");
			}
			return "";
		}
	},
	mutations: {

		[DEVICE_NOSIGNAL_SUCCESS](state, response) {
			const { data, updatedAt } = response;
			state.data = data;
			state.isLoading = false;
			state.lastUpdate = updatedAt;
		},
		[DEVICE_NOSIGNAL_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.lastUpdate = "";
		},
		[DEVICE_NOSIGNAL_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.lastUpdate = "";
		}

	},

	actions: {
		async getDevicesNoSignal({ commit }, params) {
			try {
				commit(DEVICE_NOSIGNAL_REQUEST);
				const { data } = await getDeviceNoSignalAPI(params);
				commit(DEVICE_NOSIGNAL_SUCCESS, data);
			} catch (e) {
				commit(DEVICE_NOSIGNAL_FAILURE);
			}
		}
	}
};
