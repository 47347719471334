<template>
	<BaseLayout pageTitle="Banner">
		<div class="container-action-bar" v-if="manageAble">
			<router-link
				:to="{ name: PAGE_NAME.BIG_STORE_BANNER_CREATE }"
			>
				<BaseButton
					size="large"
				>
					Create new banner
				</BaseButton>
			</router-link>
		</div>
		<div class="main-content">
			<BaseNoResult v-if="isEmpty" title="No data. Please Add New Banner"/>
			<BaseTableCustom
				v-else
				:tableOptions="tableOptions"
				:pagination="pagination"
				:loading="isLoading"
				@onPageChange="handlePageChange"
			>
				<template v-slot:thead>
					<tr>
						<th
							v-for="({value,key}, index) in table.thead"
							:key="index"
						>
							<div class="header-content">
								{{ value }}
								<BaseSort
									v-if="key"
									:sortName="key"
									:sortBy="getSortingBykey(key)"
									@handleSort="handleSort"
								/>
							</div>
						</th>
					</tr>
				</template>
				<template v-slot:tbody>
					<tr
						v-for="(item, index) in table.tbody"
						:key="index"
						:class="[
							{
								inactive: item.isActive === false
							}
						]"
					>
						<td class="max-width-sold-to">
							<img :src="item.partImg" height="200">
						</td>
						<td class="max-width-default">
							{{ item.priority }}
						</td>
						<td>
							<BaseBadge v-if="item.status" text="Active" size="small" color="success-dark"/>
							<BaseBadge v-else text="Inactive" size="small" outline/>
						</td>
						<td class="align-right action">
							<router-link
								v-if="manageAble"
								:to="`/big-store-banner/${item.id}`"
							>
								<button class="view-button btn-link">
									Edit
								</button>
							</router-link>
							<a
								href="#"
								@click="showModalDeleteBanner(item.id)"
								class="ml-7"
							>
								<button class="view-button btn-link">
									Delete
								</button>
							</a>
						</td>
					</tr>
				</template>
			</BaseTableCustom>
		</div>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_BANNER"
			title="Delete this banner?"
			:message="modalMessageDeleteBanner"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleModalDeleteThisBanner"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import BaseBadge from "@/components/BaseBadge.vue";
import BaseSort from "@/components/BaseSort.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { cleanParams, cloneDeep } from "../../selectors/helpers";
import { PAGE_NAME } from "../../enums/pagePermission";
import { STAFF_ROLES } from "../../enums/role";
import { TOAST_TYPES } from "../../enums/toast";

export default {
	name: "BigStoreBanner",

	components: {
		BaseTableCustom,
		BaseSort,
		BaseModalConfirmDelete,
		BaseNoResult,
		BaseBadge
	},

	data() {
		return {
			query: {
				page: this.$route.query.page || null,
				priority: this.$route.query.priority || null,
				sort: this.$route.query.sort || null
			},
			tableOptions: [
				"is-striped is-fullwidth"
			],
			sort: {
				priority: "asc"
			},
			PAGE_NAME,
			MODAL_DELETE_BANNER: "model-delete-banner"
		};
	},

	computed: {
		...mapState({
			response: (state) => {
				return state.bannerManagement;
			},
			user: (state) => {
				return state.user;
			}
		}),

		table() {
			return {
				thead: [
					{ value: "Image", key: "" },
					{ value: "Priority", key: "priority" },
					{ value: "Status", key: "" },
					{ value: "", key: "" }
				],
				tbody: this.response.data
			};
		},

		pagination() {
			return this.response.pagination;
		},

		isFiltered() {
			return !Object.values(this.query).every((v) => v === null);
		},

		isLoading() {
			return this.response.isLoading;
		},

		isEmpty() {
			return this.response.isEmpty && !this.isFiltered;
		},

		manageAble() {
			return [
				STAFF_ROLES.SUPER_ADMIN,
				STAFF_ROLES.ADMIN
			].includes(this.user.role);
		},

		getQuery() {
			const cloneQuery = cloneDeep(this.query);
			const query = cleanParams(cloneQuery);
			return query;
		},

		modalMessageDeleteBanner() {
			return `<div>
				<div>Are you sure to delete this banner? </div>
			</div>`;
		}
	},

	async created() {
		const { sort = "" } = this.$route.query;
		const [sortName, sortBy] = sort.split(":");
		if (sortName && sortBy) {
			this.handleSetSortTable(sortName, sortBy);
		}
		await this.fetchBanners();
		await this.fetchBannersWhenPageIsEmpty();
	},

	methods: {
		...mapActions({
			fetchBannerList: "bannerManagement/fetchBannerList",
			sendBannerDeleteAPI: "bannerManagement/sendBannerDeleteAPI"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		getSortingBykey(key) {
			return this.sort[key];
		},

		handleSetSortTable(sortName, sortBy) {
			Object.keys(this.sort).forEach((keyName) => {
				if (keyName === sortName) {
					this.sort[keyName] = sortBy;
				} else {
					this.sort[keyName] = "";
				}
			});
		},

		handleSort(value) {
			const { sortName, sortBy } = value;
			this.handleSetSortTable(sortName, sortBy);
			this.query.sort = `${sortName}:${sortBy}`;
			this.fetchBanners();
		},

		async fetchBanners() {
			this.$router.push({ query: this.getQuery }).catch(() => {});
			await this.fetchBannerList(this.getQuery);
		},

		handlePageChange(pageNum) {
			this.query.page = pageNum;
			this.fetchBanners();
		},

		showModalDeleteBanner(key) {
			this.$modal.show(this.MODAL_DELETE_BANNER, key);
		},

		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_BANNER);
		},

		async handleModalDeleteThisBanner(key) {
			await this.sendBannerDeleteAPI(key);
			if (this.response.bannerDeleteSuccess) {
				await this.fetchBanners();
				await this.fetchBannersWhenPageIsEmpty();
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Banner delete successful."
					}
				});
				this.$modal.hide(this.MODAL_DELETE_BANNER);
			}
		},

		async fetchBannersWhenPageIsEmpty() {
			if ((this.response.pagination.currentPage > this.response.pagination.lastPage) && this.response.pagination.lastPage !== 0) {
				this.query.page = (Number(this.query.page) - 1) ?? 1;
				await this.fetchBanners();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.header-content {
	display: flex;
}

.container-action-bar {
	display: flex;
	justify-content: flex-end;
	margin-top: rem(-52);
}

.view-button {
	@include fontBase();

	background: none;
	border: none;
	color: $color-cerulean;
	font-weight: 600;
	font-size: $font-18;

	&:hover {
		color: $color-azure;
		background-color: transparent;
	}
}

td {
	word-wrap: break-word;
}

.max-width-sold-to {
	max-width: rem(204);
}

.max-width-default {
	max-width: rem(150);
}

.action {
	display: flex;
	justify-content: flex-end;
}

.ml-7 {
	margin-left: $spacing-7;
}
</style>
