import httpClient from "./httpClient";

const NITRO_FAS_ENDPOINT = "/nitrofas";

const getNitroFasInfoAPI = (id) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}`);
const getNitroFasProductionLineChartAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/productions`, { params });
const getNitroFasNitrogenFlowRateAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/flow-rates`, { params });
const getNitroFasOrderHistoryAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/histories`, { params });
const getNitroFasAvgFlowRateAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/average-flow-rates`, { params });
const getNitroFasNitrogenConsumptionAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/consumptions`, { params });
const getNitroFasDrossGenerationAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/dross-generations`, { params });
const getNitroFasSolderConsumptionAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/solder-consumptions`, { params });
const getNitroFasTotalFinishedGoodAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/total-finished-goods`, { params });
const getNitroFasDrossTotalFinishedGoodAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/dross-total-finished-goods`, { params });
const getNitroFasNitrogenTotalFinishedGoodAPI = (id, params) => httpClient.get(`${NITRO_FAS_ENDPOINT}/${id}/nitrogen-total-finished-goods`, { params });

export {
	getNitroFasInfoAPI,
	getNitroFasProductionLineChartAPI,
	getNitroFasNitrogenFlowRateAPI,
	getNitroFasOrderHistoryAPI,
	getNitroFasAvgFlowRateAPI,
	getNitroFasNitrogenConsumptionAPI,
	getNitroFasDrossGenerationAPI,
	getNitroFasSolderConsumptionAPI,
	getNitroFasTotalFinishedGoodAPI,
	getNitroFasDrossTotalFinishedGoodAPI,
	getNitroFasNitrogenTotalFinishedGoodAPI
};
