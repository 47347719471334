<template>
	<div class="air-comp-info">
		<BaseLoading v-if="airCompInfo.isLoading" />
		<div :class="['row row-eq-height', { 'hide-content': airCompInfo.isLoading }]">
			<div :class="['col-12', 'col-xl-7']">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ airCompInfo.data.updatedAt }}
						</div>
					</template>
					<template v-slot:body>
						<ModuleOverview
							v-if="airCompInfo.data.id"
							:data="airCompInfo.data"
						/>
						<BaseNoData
							v-else
							description="BIG portal will start showing the overview right after we have information"
						/>
					</template>
				</BaseCard>
			</div>
			<div class="col-12 col-xl-5">
				<BaseCard>
					<template v-slot:header>
						<h4>Customer information</h4>
					</template>
					<template v-slot:body>
						<div class="overview-values">
							<div
								v-for="item in airCompInfo.data.customerView"
								:key="item.key"
								class="overview-info"
							>

								<p :class="['title', { '-has-icon': item.titleType === 'icon' }]">
									<AtIcon v-if="item.icon === 'AtIcon'"></AtIcon>
									<IndustryWindowsIcon v-else-if="item.icon === 'IndustryWindowsIcon'"></IndustryWindowsIcon>
									<LocationDotIcon v-else-if="item.icon === 'LocationDotIcon'"></LocationDotIcon>
									<PhoneIcon v-else-if="item.icon === 'PhoneIcon'"></PhoneIcon>
									<UserHeadsetIcon v-else-if="item.icon === 'UserHeadsetIcon'"></UserHeadsetIcon>
									<BuildingIcon v-else-if="item.icon === 'BuildingIcon'"></BuildingIcon>

									<component v-if="item.titleType === 'icon'"  :is="item.title" class="title-icon" />
									<span v-else v-html="item.titleType === 'text' ? item.titleDisplay : item.title" class="html"></span>
								</p>
								<p :class="['value', { '-has-bar-g': item.barG }]">
									<span :title="item.value"  v-html="item.value" class="html"></span>
								</p>
							</div>
						</div>
						<!-- <BaseNoData
							description="BIG portal will start showing the location right after we have information"
						/> -->
					</template>
				</BaseCard>
			</div>
		</div>
		<div class="container-filter">
			<div class="current-time">
				Last update: {{ airCompInfo.data.updatedAt }}
			</div>
			<BaseButtonGroup
				:items="filterChartOptions"
				:active="filterSelected"
				:size="`small`"
				:class="`filter-button-group`"
				@click="handleClickedFilter"
			/>
		</div>
		<AirCompPressureChart
			:data="airCompPressure.detail.data"
			:updatedAt="airCompPressure.detail.updatedAt"
			:loading="airCompPressure.loading"
			:empty="airCompPressure.empty"
			:averagePressure="airCompPressure.detail.averagePressure"
			:tagName="airCompPressure.detail.tagName"
			@filter="handleFilterPressure"
		/>
		<AirFlowRateChart
			:data="airCompFlowRate.detail.data"
			:updatedAt="airCompFlowRate.detail.updatedAt"
			:loading="airCompFlowRate.loading"
			:empty="airCompFlowRate.empty"
			:minFlowRate="airCompFlowRate.detail.minFlowRate"
			:maxFlowRate="airCompFlowRate.detail.maxFlowRate"
			:tagName="airCompFlowRate.detail.tagName"
			@filter="handleFilterPower"
		/>
		<AirCompPowerChart
			:data="airCompPower.detail.data"
			:updatedAt="airCompPower.detail.updatedAt"
			:loading="airCompPower.loading"
			:empty="airCompPower.empty"
			:minPower="airCompPower.detail.minPower"
			:maxPower="airCompPower.detail.maxPower"
			:tagName="airCompPower.detail.tagName"
			@filter="handleFilterPower"
		/>
		<AirCompOverallAirVolume
			:airCompOverallAirVolume="airCompOverallAirVolume"
			:filterSelected="filterSelected"
		/>
		<AirCompOverallEnergyConsumption
			:airCompOverallEnergy="airCompOverallEnergy"
			:filterSelected="filterSelected"
		/>
		<AirCompSECChart
			:airCompSEC="airCompSEC"
			:tagName="airCompSEC.tagName"
			:filterSelected="filterSelected"
		/>
		<AirCompEnergySaving
			:data="airCompEnergySaving.data"
			:updatedAt="airCompEnergySaving.lastUpdate"
			:loading="airCompEnergySaving.isLoading"
			:empty="airCompEnergySaving.isEmpty"
			:barWidth="chartConfigs.barWidth"
			:chartTitle="`Energy Saving`"
			:filterSelected="filterSelected"
			:tagName="airCompEnergySaving.tagName"
		/>
		<AirCompEstimatedCostSaving
			:filterSelected="filterSelected"
			:airCompPowerCost="airCompPowerCost"
		/>
		<GrafanaDisplay
			:deviceId="airCompId"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/dashboard/modules/ModuleLocation.vue";
import AirCompPressureChart from "@/components/AirCompModule/AirCompPressureChart.vue";
import AirCompSECChart from "@/components/AirCompModule/AirCompSECChart.vue";
import AirCompPowerChart from "@/components/AirCompModule/AirCompPowerChart.vue";
import AirFlowRateChart from "@/components/AirCompModule/AirFlowRateChart.vue";
import AirCompEstimatedCostSaving from "@/components/AirCompModule/AirCompEstimatedCostSaving.vue";
import AirCompOverallEnergyConsumption from "@/components/AirCompModule/AirCompOverallEnergyConsumption.vue";
import AirCompOverallAirVolume from "@/components/AirCompModule/AirCompOverallAirVolume.vue";
import AirCompEnergySaving from "@/components/AirCompModule/AirCompEnergySaving.vue";
import { getBarChartWidth } from "../../selectors/helpers/chart";
import BuildingIcon from "../../components/icon/BuildingIcon.vue";
import AtIcon from "../../components/icon/AtIcon.vue";
import IndustryWindowsIcon from "../../components/icon/IndustryWindowsIcon.vue";
import LocationDotIcon from "../../components/icon/LocationDotIcon.vue";
import PhoneIcon from "../../components/icon/PhoneIcon.vue";
import UserHeadsetIcon from "../../components/icon/UserHeadsetIcon.vue";


import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";

const { perWeek, perTwoWeek, perMonth, perYear } = MODULE_SORT_DATE_CHART;

export default {
	name: "airCompInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		BaseNoData,
		BaseButtonGroup,
		ModuleLocation,
		ModuleOverview,
		AirCompPressureChart,
		AirCompSECChart,
		AirCompPowerChart,
		AirFlowRateChart,
		AirCompEstimatedCostSaving,
		AirCompOverallEnergyConsumption,
		AirCompOverallAirVolume,
		AirCompEnergySaving,
		BuildingIcon,
		AtIcon,
		IndustryWindowsIcon,
		LocationDotIcon,
		PhoneIcon,
		UserHeadsetIcon
	},
	data() {
		return {
			airCompId: this.$route.params.id,
			chartConfigs: {
				barWidth: 0
			},
			filterSelected: perWeek
		};
	},

	computed: {
		...mapState(["airCompInfo", "airCompPower", "airCompPressure", "airCompSEC", "airCompPowerCost", "airCompFlowRate", "airCompOverallEnergy", "airCompOverallAirVolume", "airCompEnergySaving"]),
		isShowShipTo() {
			return this.airCompInfo.data?.shipToLongitude;
		},
		filterChartOptions() {
			return [
				{
					text: "Last 7 days",
					value: perWeek
				},
				{
					text: "Last 2 weeks",
					value: perTwoWeek
				},
				{
					text: "Last 1 month",
					value: perMonth
				},
				{
					text: "Last 1 year",
					value: perYear,
					isDisabled: true
				}
			];
		}
	},

	async created() {
		this.chartConfigs.barWidth = getBarChartWidth(perWeek);
		await this.fetchData();
		this.$emit("loaded");
	},

	methods: {
		...mapActions("airCompPressure", {
			fetchAirCompPressure: "fetchAirCompPressure"
		}),
		...mapActions("airCompSEC", {
			fetchAirCompSEC: "fetchAirCompSEC"
		}),
		...mapActions("airCompPower", {
			fetchAirCompPower: "fetchAirCompPower"
		}),
		...mapActions("airCompFlowRate", {
			fetchAirCompFlowRate: "fetchAirCompFlowRate"
		}),
		...mapActions("airCompPowerCost", {
			fetchAirCompPowerCostData: "fetchAirCompPowerCostData"
		}),
		...mapActions("airCompOverallEnergy", {
			fetchAirCompOverallEnergyData: "fetchAirCompOverallEnergyData"
		}),
		...mapActions("airCompOverallAirVolume", {
			fetchAirCompOverallAirVolumeData: "fetchAirCompOverallAirVolumeData"
		}),
		...mapActions("airCompEnergySaving", {
			getAirCompEnergySaving: "getAirCompEnergySaving"
		}),

		fetchData() {
			return Promise.all([
				this.fetchAirCompPressure({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				}),
				this.fetchAirCompSEC({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				}),
				this.fetchAirCompPower({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				}),
				this.fetchAirCompFlowRate({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				}),
				this.fetchAirCompPowerCostData({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				}),
				this.fetchAirCompOverallEnergyData({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				}),
				this.fetchAirCompOverallAirVolumeData({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				}),
				this.getAirCompEnergySaving({
					id: this.airCompId,
					params: {
						filter: this.filterSelected
					}
				})
			]);
		},

		handleFilterPressure(params = {}) {
			this.fetchAirCompPressure({ id: this.airCompId, params });
		},

		handleFilterSEC(params = {}) {
			this.fetchAirCompSEC({ id: this.airCompId, params });
		},

		handleFilterPower(params = {}) {
			this.fetchAirCompPower({ id: this.airCompId, params });
		},

		handleFilterPowerCost(dateNumber) {
			this.fetchAirCompPowerCostData({ id: this.airCompId, filterBy: dateNumber });
		},
		async handleClickedFilter(value) {
			this.filterSelected = value;
			await this.fetchData();
		}
	}
};
</script>

<style lang="scss" scoped>
.container-filter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: rem(34);
	gap: rem(16);
}

.airComp-info {
	::v-deep {
		.chart-title {
			position: relative;
			left: initial;

			margin-top: rem(24);

			transform: initial;
			-webkit-transform: initial;
		}
	}
}

.card {
	width: 100%;
	height: calc(100% - rem(34));
}

.overview {
	&-container {
		display: flex;
		width: 100%;
	}

	&-info {
		display: flex;
		justify-content: space-between;
		margin-bottom: rem(10);

		p {
			margin: 0 0 rem(12);

			display: flex;
			flex-direction: row;
			gap: rem(16);
			justify-content: flex-start;

			color: $color-dark-blue-grey;

			&:nth-child(even) {
				display: flex;
				font-weight: 600;
			}

			span {
				overflow-wrap: anywhere;
				text-align: end;
			}
		}
	}

	&-info:last-of-type {
		p {
			margin: 0;
		}
	}

	&-values {
		flex: 1;

		.title {
			flex: none;
			width: 30%;

			&.-has-icon {
				width: 40%;
				text-align: right;
				margin-bottom: 0;
			}

			.title-icon {
				font-size: $font-26;
			}
		}

		.value {
			&.-has-bar-g {
				width: 50%;
				display: flex;

				.html {
					width: rem(50);

					text-align: right;
				}

				::v-deep {
					.bar-g-container {
						width: calc(100% - 50px);

						.bar-g-chart {
							width: 100%;
						}
					}
				}
			}

			.progress-bar {
				width: rem(96);
				position: relative;
				top: rem(2);
				margin-left: rem(8);
			}
		}

		.overview-group {

			// overview-group-header
			&-header {
				margin: rem(8) 0;
				padding: rem(4) rem(8);

				font-size: $font-18;
				font-weight: bold;

				background-color: $color-off-white;
			}

			//overview-group-header
			&-body {
				padding: 0 rem(8);
			}
		}

		.overview-attachment {
			margin-top: rem(8);
			padding: rem(12) rem(8) rem(8) rem(8);
			border-top: 1px solid $color-ice;

			.view-button {
				.icon-file {
					margin-right: 2px;
					font-size: rem(14);
				}
			}
		}
	}

	&-bar {
		min-width: rem(144);
		margin-right: rem(16);
		padding: rem(8);
		/* stylelint-disable */
		&::v-deep {
			.nitro-fas-wrapper {
				width: rem(128);
				height: rem(128);
			}
		}
		/* stylelint-enable */
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

</style>