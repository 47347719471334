import { getFlowDiagramSingleAPI } from "@/services/api/flowdiagram.api";
import { FLOW_DIAGRAM_SINGLE_REQUEST, FLOW_DIAGRAM_SINGLE_SUCCESS, FLOW_DIAGRAM_SINGLE_FAILURE } from "../../types";
import { parseFlowDiagram } from "../../../services/api/transforms/parseFlowDiagram";

export default {
	namespaced: true,

	state: {
		data: parseFlowDiagram({}),
		statusCode: null,
		loading: false
	},

	mutations: {
		[FLOW_DIAGRAM_SINGLE_REQUEST](state) {
			state.data = parseFlowDiagram({});
			state.statusCode = null;
			state.loading = true;
		},
		[FLOW_DIAGRAM_SINGLE_SUCCESS](state, { data, status }) {
			state.data = parseFlowDiagram(data);
			state.statusCode = status;
			state.loading = false;
		},
		[FLOW_DIAGRAM_SINGLE_FAILURE](state, error) {
			state.data = parseFlowDiagram({});
			state.statusCode = error.response.status;
			state.loading = false;
		}
	},

	actions: {
		async getFlowDiagramSingle({ commit }, { id }) {
			try {
				commit(FLOW_DIAGRAM_SINGLE_REQUEST);
				const response = await getFlowDiagramSingleAPI(id);
				commit(FLOW_DIAGRAM_SINGLE_SUCCESS, response);
			} catch (error) {
				commit(FLOW_DIAGRAM_SINGLE_FAILURE, error);
			}
		}
	}
};
