<template>
	<BaseCard rounded>
		<template v-slot:header>
			<h4>Apply to</h4>
		</template>
		<template v-slot:body>
			<div class="form-permission-header">
				<h3 class="permission-level">
					Level
				</h3>
			</div>
			<BaseMultipleSelect
				:options="getSoldToOptions"
				:value="getSoldToValues"
				:customLabel="getLabelSoldTo"
				:disabled="isDisabledSoldTo"
				:limit="10"
				label="Sold-to"
				placeholder="Search and select sold-to"
				multiple
				@onSelectValue="handleSelectSoldTo"
				@onRemoveSelected="handleRemoveSoldTo"
			></BaseMultipleSelect>

			<BaseMultipleSelect
				:options="getShipToOptions"
				:value="getShipToValues"
				:customLabel="getLabelShipTo"
				:disabled="isDisabledShipTo"
				:limit="10"
				label="Ship-to"
				placeholder="Search and select ship-to"
				multiple
				defaultHeight
				@onSelectValue="handleSelectShipTo"
				@onRemoveSelected="handleRemoveShipTo"
			></BaseMultipleSelect>
			<BaseCheckBox
				v-if="withApplyToAllShipto"
				:disabled="isDisabledShipTo"
				:checked="isAutoUpdateShipTo"
				class="form-checkbox"
				label="Auto apply ship-to that will be added in the future"
				@handleCheck="handleCheckUpdateShipTo"
			/>
			<BaseMultipleSelect
				:options="getDeviceOptions"
				:value="getDeviceValue"
				:disabled="isDisableDevice"
				:limit="10"
				:isError="isDeviceError"
				:errorMessage="deviceErrorMessage"
				label="Device"
				placeholder="Search and select device"
				multiple
				defaultHeight
				@onSelectValue="handleSelectDevice"
				@onRemoveSelected="handelRemoveDevice"
			></BaseMultipleSelect>
			<BaseCheckBox
				v-if="withApplyToAlldevice"
				:disabled="isDisableDevice"
				:checked="isAutoUpdateDevice"
				class="form-checkbox"
				label="Auto update device that will be added in the future"
				@handleCheck="handleCheckUpdateDevice"
			/>
		</template>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";
import { PERMISSION_DROPDOWN_ALL } from "../../enums/customerManagement/devicePermission";
import {
	transformDeivce,
	transformShipTos,
	trasnformSoldToOptions
} from "../../selectors/transform/devicePermission";
import { onlyUnique } from "../../selectors/transform/meterHistory";

const { ALL_SOLD_TO, ALL_SHIP_TO, ALL_DEVICE } = PERMISSION_DROPDOWN_ALL;

export default {
	components: {
		BaseCard,
		BaseMultipleSelect,
		BaseCheckBox
	},
	props: {
		applyToData: {
			type: Object,
			default: () => ({
				soldTos: [],
				shipTos: [],
				devices: []
			})
		},

		withApplyToAllShipto: {
			type: Boolean,
			default: false
		},

		withApplyToAlldevice: {
			type: Boolean,
			default: false
		},

		isAutoUpdateDevice: {
			type: Boolean,
			default: false
		},

		isAutoUpdateShipTo: {
			type: Boolean,
			default: false
		},

		isDeviceError: {
			type: Boolean,
			default: false
		},

		deviceErrorMessage: {
			type: String,
			default: ""
		},

		soldToValues: {
			type: Array,
			default: () => ([])
		},
		shipToValues: {
			type: Array,
			default: () => ([])
		},
		deviceValues: {
			type: Array,
			default: () => ([])
		},
		defaultSoldToValue: {
			type: Array,
			default: () => ([])
		},
		defaultShipToValue: {
			type: Array,
			default: () => ([])
		},
		defaultDeviceValue: {
			type: Array,
			default: () => ([])
		}
	},

	computed: {

		getSoldToValues() {
			const isHasOnlyAll = this.getSoldToOptions.length - this.soldToValues.length === 1;
			if (this.soldToValues.some(({ code }) => code === "all") || isHasOnlyAll) {
				return this.getSoldToOptions;
			}
			return this.soldToValues;
		},

		getShipToValues() {
			const isHasOnlyAll = this.getShipToOptions.length - this.shipToValues.length === 1;

			if (this.shipToValues.some(({ code }) => code === "all") || isHasOnlyAll) {
				return this.getShipToOptions;
			}


			return this.shipToValues;
		},

		getDeviceValue() {
			if (this.getShipToValues.length > 0) {
				const isHasOnlyAll = this.getDeviceOptions.length - this.deviceValues.length === 1;

				if (this.deviceValues.some(({ code }) => code === "all") || isHasOnlyAll) {
					return this.getDeviceOptions;
				}
				const getShipToSelectedId = this.shipToValues.map(({ id }) => id);

				return this.deviceValues.filter(({ shipToId, code }) => {
					if (code === "all") {
						return true;
					}
					return getShipToSelectedId.indexOf(shipToId) !== -1;
				});
			}
			return [];
		},

		getSoldToOptions() {
			const soldToOptions = [
				ALL_SOLD_TO,
				...trasnformSoldToOptions(this.applyToData.soldTos)
			];
			if (soldToOptions.length > 1) {
				return soldToOptions;
			}
			return [];
		},

		getDeviceOptions() {
			const deviceOption = this.getCombineApplicaitonAndShipTo.reduce((acc, code) => {
				return [
					...acc,
					...(this.getListDeviceOptions[code] ? this.getListDeviceOptions[code] : [])
				];
			}, [ALL_DEVICE]);

			if (deviceOption.length > 1) {
				return deviceOption;
			}

			return [];
		},

		getShipToOptions() {
			if (this.soldToValues.length === 0) {
				return [];
			}

			const shipTosOptions = transformShipTos(this.applyToData.shipTos);

			const shipToOptions = this.soldToValues.reduce((acc, { id }) => {
				return [
					...acc,
					...(shipTosOptions[id] ? shipTosOptions[id] : [])
				];
			}, [ALL_SHIP_TO]);

			if (shipToOptions.length > 1) {
				return shipToOptions;
			}
			return [];
		},

		isDisabledSoldTo() {
			return false;
		},

		isDisabledShipTo() {
			return !this.soldToValues.length;
		},

		isDisableDevice() {
			return !this.shipToValues.length;
		},



		getListDeviceOptions() {
			return transformDeivce(this.applyToData.devices);
		},

		getCombineApplicaitonAndShipTo() {
			const allDeviceModule = this.applyToData.devices.map((device) => {
				return device.app;
			});

			const uniqueDeviceModule = allDeviceModule.filter(onlyUnique).map((data) => {
				return {
					code: data
				};
			});

			const applicationCombineShipTo = [...uniqueDeviceModule].reduce((acc, { code }) => {
				return [
					...acc,
					...this.shipToValues.map(({ id }) => `${code}-${id}`)
				];
			}, []);

			return applicationCombineShipTo;
		}

	},

	methods: {
		handleCheckUpdateShipTo() {
			this.$emit("handleCheckUpdateShipTo", !this.isAutoUpdateShipTo);
		},

		handleCheckUpdateDevice() {
			this.$emit("handleCheckUpdateDevice", !this.isAutoUpdateDevice);
		},
		getLabelSoldTo({ key }) {
			return key;
		},

		getLabelShipTo({ key, code }) {
			if (code === "all") {
				return `${key}`;
			}
			return `${key} - ${code}`;
		},
		// sold to selected and remove
		handleSelectSoldTo(value) {
			let soldToValues = this.soldToValues;
			if (value.code === "all") {
				soldToValues = [
					...this.getSoldToOptions
				];
			} else {
				soldToValues = [
					...this.soldToValues,
					value
				];
			}
			this.$emit("handleChangeSoldTo", soldToValues);
		},

		handleRemoveSoldTo(value) {
			let soldToValues = this.soldToValues;
			let shipToValues = this.shipToValues;
			let deviceValues = this.deviceValues;

			if (value.code === "all") {
				soldToValues = [];
				this.$emit("handleChangeSoldTo", soldToValues);
			} else {
				soldToValues = this.soldToValues
					.filter(({ id }) => id !== value.id)
					.filter(({ code }) => !(code === "all"));
				this.$emit("handleChangeSoldTo", soldToValues);
			}

			if (soldToValues.length === 0) {
				shipToValues = [];
				deviceValues = [];
				this.$emit("handleChangeDevice", deviceValues);
				this.$emit("handleChangeShipTo", shipToValues);
			}
			shipToValues = shipToValues.filter(({ soldToId }) => {
				return soldToId !== value.id;
			});

			const allShipToIdAvailable = shipToValues.map((v) => v.id);
			deviceValues = deviceValues.filter(({ shipToId }) => allShipToIdAvailable.indexOf(shipToId) > -1);

			this.$emit("handleChangeDevice", deviceValues);
			this.$emit("handleChangeShipTo", shipToValues);
		},
		// end sold to selected and remove
		handleSelectShipTo(value) {
			let shipToValues = [];
			if (value.code === "all") {
				shipToValues = [
					...this.getShipToOptions
				];
			} else {
				shipToValues = [
					...this.shipToValues,
					value
				];
			}
			this.$emit("handleChangeShipTo", shipToValues);
		},

		handleRemoveShipTo(value) {
			let shipToValues = this.shipToValues;
			let deviceValues = this.deviceValues;

			if (value.code === "all") {
				shipToValues = [];
				this.$emit("handleChangeShipTo", shipToValues);
			} else {
				shipToValues = this.shipToValues
					.filter(({ id }) => id !== value.id)
					.filter(({ code }) => !(code === "all"));
				this.$emit("handleChangeShipTo", shipToValues);
			}

			if (shipToValues.length === 0) {
				deviceValues = [];
				this.$emit("handleChangeDevice", deviceValues);
			}

			deviceValues = deviceValues.filter(({ shipToId }) => shipToId !== value.id);
			this.$emit("handleChangeDevice", deviceValues);
		},

		handleSelectDevice(value) {
			let deviceValues = this.deviceValues;

			if (value.code === "all") {
				deviceValues = [
					...this.getDeviceOptions
				];
			} else {
				deviceValues = [
					...this.deviceValues,
					value
				];
			}
			this.$emit("handleChangeDevice", deviceValues);
		},

		handelRemoveDevice(value) {
			let deviceValues = this.deviceValues;

			if (value.code === "all") {
				deviceValues = [];
			} else {
				deviceValues = deviceValues
					.filter(({ code }) => code !== value.code)
					.filter(({ code }) => !(code === "all"));
			}
			this.$emit("handleChangeDevice", deviceValues);
		}

		// ship to selected and remove
	}
};
</script>

<style lang="scss" scoped>
.form-permission-header {
	display: flex;
	align-items: center;

	.permission {
		&-level {
			font-size: $font-20;
			width: rem(130);
		}

		&-visibility {
			display: flex;
			justify-content: space-between;
			flex-grow: 1;

			.visibility-label {
				font-size: $font-20;
			}
		}
	}
}

.form-checkbox {
	margin-left: rem(130);
}
</style>