<template>
	<modal :name="modalName" :scrollable="true" width="420" height="auto" @before-close="handleCloseModal"  @before-open="beforeOpen">
		<BaseModalContent>
			<template v-slot:header>
				{{ header }}
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template v-slot:body>
				<div :class="['container-form', { active: state.dropdownShipToIdActive }]">
					<div class="item">
						<div class="form">
							<BaseDropdown
								:searchable="false"
								:list="dropdownTypeOptions"
								:value="typeSelected"
								:dropdownListContainerHeight="100"
								class="customer-create-input"
								label="Type"
								inline
								@input="handleSelectType"
								@onActiveChange="handleActiveChange"
							/>
						</div>
					</div>
					<div class="item">
						<div class="form">
							<BaseDropdown
								:searchable="false"
								:list="dropdownModuleOptions"
								:value="moduleSelected"
								:dropdownListContainerHeight="100"
								:disabled="moduleDisabled"
								class="customer-create-input"
								label="Select module"
								inline
								@input="handleSelectModule"
								@onActiveChange="handleActiveChange"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<BaseButton
					:disabled="isDisabledContinue"
					size="large"
					@click="handleSubmit"
				>Continue</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";

export default {
	name: "ModalCreateCustomisation",

	components: {
		BaseModalContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},

		header: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			typeAvailable: [],
			typeSelected: "",
			moduleSelected: "",
			state: {
				dropdownShipToIdActive: false
			}
		};
	},

	computed: {
		isDisabledContinue() {
			return !this.typeSelected || !this.moduleSelected;
		},
		dropdownTypeOptions() {
			return this.typeAvailable.reduce((acc, cur) => {
				acc.push({
					label: cur.displayName,
					value: cur.type
				});
				return acc;
			}, []);
		},
		dropdownModuleOptions() {
			const type = this.typeAvailable.find((item) => (
				item.type === this.typeSelected
			));
			return !type ? [] : type.availableModules.map((item) => {
				return {
					label: item.displayName,
					value: item.module
				};
			});
		},
		moduleDisabled() {
			return !this.typeSelected;
		}
	},
	methods: {
		beforeOpen(event) {
			this.typeAvailable = event.params.typeAvailable;
			this.state.dropdownShipToIdActive = false;
		},

		handleSelectType(value) {
			this.typeSelected = value;
			this.moduleSelected = "";
		},

		handleSelectModule(value) {
			this.moduleSelected = value;
		},

		handleActiveChange(isActive) {
			this.state.dropdownShipToIdActive = isActive;
		},

		handleSubmit() {
			const form = {
				typeSelected: this.typeSelected,
				moduleSelected: this.moduleSelected
			};
			this.$emit("onSubmit", form);
		},
		handleCloseModal() {
			this.typeSelected = "";
			this.moduleSelected = "";
		}
	}
};
</script>

<style lang="scss" scoped>
.container-form {
	&.active {
		margin-bottom: rem(40);
	}

	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			align-items: center;
			min-width: rem(120);
		}

		.form {
			flex-grow: 1;
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}
}

.notify-error {
	margin-bottom: $spacing-6;
}
</style>