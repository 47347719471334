import { getModuleDevicesAPI } from "@/services/api/modules.api";
import { getStandardModuleDeviceSyncAPI } from "@/services/api/standardModule.api";
import { getDeviceSyncAPI } from "@/services/api/devices.api";
import dayjs from "dayjs";

import {
	DEVICES_REQUEST,
	DEVICES_SUCCESS,
	DEVICES_FAILURE,
	DEVICE_LOAD_SYNC_SUCCESS
} from "../../types";
import { parseDevices } from "../../../services/api/transforms/parseDevice";
import { transformStdViewDevices, transformStdViewDevicesFromDeviceSync } from "../../../services/api/transforms/parserViewDevices";


export default {
	namespaced: true,

	state: {
		isLoading: false,
		isLoadedId: null,
		data: [],
		allData: []
	},

	mutations: {
		[DEVICES_REQUEST](state) {
			state.isLoading = true;
			state.isLoadedId = null;
			state.data = [];
		},
		[DEVICES_SUCCESS](state, { id, data }) {
			state.isLoading = false;
			state.isLoadedId = id;
			state.data = transformStdViewDevices(parseDevices(data));
			state.allData[id] = state.data;
		},
		[DEVICES_FAILURE](state) {
			state.isLoading = false;
			state.isLoadedId = null;
			state.data = [];
		},
		[DEVICE_LOAD_SYNC_SUCCESS](state, { currentDevice, newDevice }) {
			const changeFormatLastUpdate = { ...newDevice, lastUpdate: newDevice.lastUpdate ? dayjs(newDevice.lastUpdate).format("D MMM YYYY, HH:mm") : "" };
			state.data = state.allData[currentDevice.shipToId].map((device) => {
				if (device.id === currentDevice.id) {
					const tempDevice = { ...device, ...changeFormatLastUpdate };
					return transformStdViewDevicesFromDeviceSync(tempDevice);
				}
				return { ...device };
			});
			state.allData[currentDevice.shipToId] = state.data; // save last update data to All data
		}
	},

	actions: {
		async getDevices({ commit }, { id, query }) {
			try {
				commit(DEVICES_REQUEST);
				const { data } = await getModuleDevicesAPI(id, query);
				commit(DEVICES_SUCCESS, { id, data });
			} catch (error) {
				commit(DEVICES_FAILURE);
			}
		},
		async updateDeviceLoadSync({ commit }, currentDevice) {
			try {
				if (currentDevice.isStandardModule) {
					const { data } = await getStandardModuleDeviceSyncAPI(currentDevice.templateId, { device_id: currentDevice.id });
					commit(DEVICE_LOAD_SYNC_SUCCESS, { currentDevice, newDevice: data });
				} else {
					const isBHY = currentDevice.productId === "BHY";
					const productType = isBHY
						? "BHY"
						: currentDevice.type;
					const { data } = await getDeviceSyncAPI(currentDevice.id, productType);
					commit(DEVICE_LOAD_SYNC_SUCCESS, { currentDevice, newDevice: data });
				}
			} catch (error) {
				// error
			}
		}
	}
};
