<template>
	<BaseLayout>
		<BaseBackBtn class="page-back-btn" :to="PAGE_NAME.BIG_STORE_BANNER" label="Back to Banner"/>
		<div class="wrapper-banner-create">
			<h1 class="edit-banner-title">Edit banner</h1>
			<BaseCard class="card-banner-info">
				<template v-slot:header>
					<h4>General information</h4>
				</template>
				<template v-slot:body>
					<BaseUploadFile
						label="Upload image *"
						:is-invalid-file-type="isInvalidFileType"
						:is-invalid-file-size="isInvalidFileSize"
						:error-message="getUploadFileMessage"
						info-message="Allow only jpg/jpeg/png/svg file and file size must not over 2MB. Recommended resolution is 1280 x 720px."
						@handleFileChange="handleFileChange"
					/>
					<div :class="['control in-line',imageClass]">
						<label class="input-label"> </label>
						<img v-if="imageUrl !== ''" :src="imageUrl" height="200">
					</div>
					<BaseFormInput
						v-model="$v.url.$model"
						:inputOptions="['small']"
						:is-error="isUrlError"
						:error-message="getUrlErrorMessage"
						class="banner-edit-input"
						label="URL"
						placeholder="https://"
						inline
					/>
					<BaseFormInput
						v-model="$v.priorityNumber.$model"
						:inputOptions="['small']"
						:is-error="isPriorityNumberError"
						:error-message="getPriorityNumberError"
						info-message="Banner will be displayed ordered by priority number."
						class="banner-edit-input"
						label="Priority number *"
						placeholder="e.g. 1, 2, 3"
						inline
					/>
					<div class="control in-line margin-switch">
						<label class="input-label"> Status </label>
						<BaseSwitch
							class="switch-status"
							:checked="statusActive"
							@input="handleChangeStatus"
						/>
						<div>{{statusActive ? "Active":"Inactive"}}</div>
					</div>
				</template>
			</BaseCard>
			<div class="wrapper-confirm-edit-banner">
				<a
					href="#"
					@click="showModalDeleteBanner()"
					class="delete-this-banner"
				>
					<button class="view-button btn-link">
						Delete this banner
					</button>
				</a>
				<BaseButton
					class="btn-cancel"
					size="large"
					type="primary"
					outline
					@click="handleCancle"
				>
					Cancel
				</BaseButton>
				<BaseButton
					size="large"
					type="primary"
					@click="handleSubmit(handleSendPayload)"
				>
					Apply Change
				</BaseButton>
			</div>
		</div>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_BANNER"
			title="Delete this banner?"
			:message="modalMessageDeleteBanner"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleModalDeleteThisBanner"
		/>
	</BaseLayout>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import { validationMixin } from "vuelidate";
import BaseButton from "@/components/BaseButton.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import BaseUploadFile from "@/components/BaseUploadFile.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import BannerValidateMixin from "../../selectors/formValidate/bigStoreManagement/BannerValidateCustomMixin";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";
import { CONFIG } from "../../enums/config";

export default {
	name: "BigStoreBannerCreate",

	mixins: [
		validationMixin,
		BannerValidateMixin
	],

	components: {
		BaseCard,
		BaseButton,
		BaseModalConfirmDelete,
		BaseSwitch,
		BaseBackBtn,
		BaseUploadFile
	},

	data() {
		return {
			id: this.$route.params.id,
			PAGE_NAME,
			file: null,
			url: "",
			priorityNumber: "",
			priorityNumberOldData: "",
			imageUrl: "",
			statusActive: true,
			isInvalidFileType: false,
			isInvalidFileSize: false,
			progressUpload: 0,
			MODAL_DELETE_BANNER: "model-delete-banner"
		};
	},

	methods: {
		...mapActions("bannerManagement", {
			fetchBannerById: "fetchBannerById",
			sendBannerUpdateAPI: "sendBannerUpdateAPI",
			sendBannerDeleteAPI: "sendBannerDeleteAPI"
		}),

		...mapActions({
			showToast: "toast/show"
		}),

		handleCancle() {
			this.$router.push({
				name: PAGE_NAME.BIG_STORE_BANNER
			});
		},

		handleChangeStatus() {
			this.statusActive = !this.statusActive;
		},

		handleSubmit(callback) {
			this.validatePermissionForm();
			if (!this.$v.$invalid && (!this.isInvalidFileType && !this.isInvalidFileSize)) {
				callback();
			}
		},

		async handleSendPayload() {
			const bannnerPayload = {
				id: this.id,
				img: this.file,
				url: this.url || "",
				priority: this.priorityNumber,
				visible: this.statusActive === true ? 1 : 0
			};
			await this.sendBannerUpdateAPI(bannnerPayload, (event) => {
				this.progressUpload = Math.round((100 * event.loaded) / event.total);
			});
			if (this.bannerManagement.bannerUpdateSuccess) {
				this.$router.push({
					name: PAGE_NAME.BIG_STORE_BANNER
				});
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Banner updated successful."
					}
				});
			}
		},

		handleFileChange({ file }) {
			let isInvalidFileType = true;
			let isInvalidFileSize = false;
			if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/svg+xml") {
				isInvalidFileType = false;
			}
			if (file.size > CONFIG.FILE_SIZE["500KB"]) { // 500KB
				isInvalidFileSize = true;
			}
			this.isInvalidFileType = isInvalidFileType;
			this.isInvalidFileSize = isInvalidFileSize;
			this.file = file;
			this.imageUrl = URL.createObjectURL(file);
		},

		showModalDeleteBanner() {
			this.$modal.show(this.MODAL_DELETE_BANNER);
		},

		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_BANNER);
		},

		async handleModalDeleteThisBanner() {
			await this.sendBannerDeleteAPI(this.id);
			if (this.bannerManagement.bannerDeleteSuccess) {
				this.$router.push({
					name: PAGE_NAME.BIG_STORE_BANNER
				});
				this.showToast({
					type: TOAST_TYPES.RESPONSE_SUCCESS,
					props: {
						title: "Success",
						message: "Banner delete successful."
					}
				});
				this.$modal.hide(this.MODAL_DELETE_BANNER);
			}
		}
	},

	computed: {
		...mapState(["bannerManagement"]),

		getUploadFileMessage() {
			if (this.file == null) {
				return "No file chosen.";
			} else if (this.isInvalidFileType && this.isInvalidFileSize) {
				return "The file is too large. The maximum size limit is 2MB. Invalid file type. Only jpg/jpeg/png/svg are allowed.";
			} else if (this.isInvalidFileType) {
				return "Invalid file type. Only jpg/jpeg/png/svg are allowed.";
			} else if (this.isInvalidFileSize) {
				return "The file is too large. The maximum size limit is 2MB.";
			}
			return "";
		},

		modalMessageDeleteBanner() {
			return `<div>
				<div>Are you sure to delete this banner? </div>
			</div>`;
		},

		imageClass() {
			if (this.imageUrl !== "") return "image-detail-24";
			return "image-detail-16";
		}
	},

	async created() {
		await this.fetchBannerById(this.id);
		this.url = this.bannerManagement.data[0]?.url;
		this.priorityNumber = this.bannerManagement.data[0]?.priority;
		this.priorityNumberOldData = Number(this.bannerManagement.data[0]?.priority);
		this.statusActive = this.bannerManagement.data[0]?.status;
		this.imageUrl = this.bannerManagement.data[0]?.partImg;
	},

	beforeUnmount() {
		URL.revokeObjectURL(this.imageUrl);
	}
};
</script>
<style lang="scss" scoped>
.wrapper-banner-create {
	padding: rem(24) 0;
}

.switch-status {
	margin-right: rem(16);
}

.edit-banner-title {
	font-size: rem(32);
	color: $color-dark-blue-grey;
}

.banner-edit-input {
	margin: rem(24) 0;
}

.card-banner-info {
	margin-top: rem(40);
}

.wrapper-confirm-edit-banner {
	position: relative;
	display: flex;
	justify-content: flex-end;
	padding: rem(24) 0;
	border-top: 1px solid $color-silver;

	.btn-cancel {
		margin-right: rem(24);
	}
}

.control {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: $font-20;
	clear: both;
	box-sizing: border-box;

	&.in-line {
		flex-direction: row;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}

.delete-this-banner {
	position: absolute;
	left: 0;
}

.image-detail-24 {
	margin-top: rem(24);
}

.image-detail-16 {
	margin-top: rem(16);
}

.margin-switch {
	margin-top: rem(36);
}
</style>
