<template>
	<modal :name="modalName" :scrollable="true" width="340" height="auto" @before-open="beforeOpen">
		<BaseModalContent>
			<template v-slot:header>
				Setup inactive time
				<span class="close-button" @click="$modal.hide(modalName)">
					<FontAwesomeIcon :icon="['far', 'times']" />
				</span>
			</template>
			<template #body>
				<div class="container-form-group">
					<label>Tank</label>
					<BaseFormInputTime
						v-model="form.TANK_IN_SECOND"
						:min-hours="inputTimeOptions.minHours"
						:max-hours="inputTimeOptions.maxHours"
						:min-minutes="inputTimeOptions.minMinutes"
						:max-minutes="inputTimeOptions.maxMinutes"
						:valid.sync="validateFields.isValidTank"
						required
					/>
				</div>
				<div class="container-form-group">
					<label>Meter</label>
					<BaseFormInputTime
						v-model="form.METER_IN_SECOND"
						:min-hours="inputTimeOptions.minHours"
						:max-hours="inputTimeOptions.maxHours"
						:min-minutes="inputTimeOptions.minMinutes"
						:max-minutes="inputTimeOptions.maxMinutes"
						:valid.sync="validateFields.isValidMeter"
						required
					/>
				</div>
				<div class="container-form-group">
					<label>Application</label>
					<BaseFormInputTime
						v-model="form.APPLICATION_IN_SECOND"
						:min-hours="inputTimeOptions.minHours"
						:max-hours="inputTimeOptions.maxHours"
						:min-minutes="inputTimeOptions.minMinutes"
						:max-minutes="inputTimeOptions.maxMinutes"
						:valid.sync="validateFields.isValidApplication"
						required
					/>
				</div>
				<div class="container-form-group">
					<label>Energy</label>
					<BaseFormInputTime
						v-model="form.ELECTRICITY_IN_SECOND"
						:min-hours="inputTimeOptions.minHours"
						:max-hours="inputTimeOptions.maxHours"
						:min-minutes="inputTimeOptions.minMinutes"
						:max-minutes="inputTimeOptions.maxMinutes"
						:valid.sync="validateFields.isValidElectricity"
						required
					/>
				</div>
			</template>
			<template #footer>
				<BaseButton
					:disabled="disabledBtn"
					size="large"
					@click="handleSubmit"
				>
					Apply
				</BaseButton>
			</template>
		</BaseModalContent>
	</modal>
</template>

<script>
import BaseModalContent from "@/components/BaseModalContent.vue";
import BaseFormInputTime from "@/components/BaseFormInputTime.vue";

export default {
	name: "ModalSetupTime",

	components: {
		BaseModalContent,
		BaseFormInputTime
	},

	props: {
		modalName: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			inputTimeOptions: {
				minHours: 0,
				maxHours: 999,
				minMinutes: 0,
				maxMinutes: 59
			},
			form: {
				TANK_IN_SECOND: 0,
				METER_IN_SECOND: 0,
				APPLICATION_IN_SECOND: 0,
				ELECTRICITY_IN_SECOND: 0
			},
			validateFields: {
				isValidTank: false,
				isValidMeter: false,
				isValidApplication: false,
				isValidElectricity: false
			}
		};
	},

	computed: {
		disabledBtn() {
			return !this.validateFields.isValidTank
				|| !this.validateFields.isValidMeter
				|| !this.validateFields.isValidApplication
				|| !this.validateFields.isValidElectricity;
		}
	},

	methods: {
		handleSubmit() {
			this.$emit("onSubmit", this.form);
		},
		beforeOpen(event) {
			this.resetForm();
			this.setForm(event.params);
		},
		resetForm() {
			this.form.TANK_IN_SECOND = 0;
			this.form.METER_IN_SECOND = 0;
			this.form.APPLICATION_IN_SECOND = 0;
			this.form.ELECTRICITY_IN_SECOND = 0;
			this.validateFields.isValidTank = false;
			this.validateFields.isValidMeter = false;
			this.validateFields.isValidApplication = false;
			this.validateFields.isValidElectricity = false;
		},
		setForm({ TANK_IN_SECOND, METER_IN_SECOND, APPLICATION_IN_SECOND, ELECTRICITY_IN_SECOND }) {
			this.form.TANK_IN_SECOND = TANK_IN_SECOND;
			this.form.METER_IN_SECOND = METER_IN_SECOND;
			this.form.APPLICATION_IN_SECOND = APPLICATION_IN_SECOND;
			this.form.ELECTRICITY_IN_SECOND = ELECTRICITY_IN_SECOND;
		}
	}
};
</script>