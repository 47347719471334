<template>
	<div
		:class="['container-dropdown', { active: isShowOptions }]"
		v-click-outside="onClickOutSide"
	>
		<a
			class="dropdown-value"
			@click.prevent="toggleDropdown"
		>
			<span class="dropdown-value-title">
				{{ title }}
			</span>
			<span class="dropdown-value-selected">
				<FontAwesomeIcon
					:icon="arrowIcon"
				/>
			</span>
		</a>
		<div class="container-dropdown-options">
			<div v-if="searchable" class="form-search">
				<BaseFormInput
					v-model="searchText"
					placeholder="Search by ship-to name, ship-to code, Location key"
					rounded
				/>
			</div>
			<div v-if="empty" class="empty">
				No matching items found.
			</div>
			<ul class="dropdown-options">
				<li
					v-for="(item, index) in filterOptions"
					:key="index"
					class="dropdown-options-item"
				>
					<a
						:class="['dropdown-options-item-link', { active: input === item.value }]"
						@click.prevent="onSelectItem($event, item)"
					>
						<slot name="item" :item="item">
							<span>
								{{ item.text }}
							</span>
						</slot>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseSortBy",

	props: {
		value: {
			type: [Number, String, Object],
			default: null
		},
		title: {
			type: String,
			default: null
		},
		options: {
			type: Array,
			default: () => ([])
		},
		styles: {
			type: Object,
			default: () => ({})
		},
		searchable: {
			type: Boolean,
			default: false
		},
		searchKey: {
			type: String,
			default: "text"
		}
	},

	data() {
		return {
			input: null,
			isShowOptions: false,
			searchText: null
		};
	},

	computed: {
		arrowIcon() {
			if (this.isShowOptions) {
				return ["far", "chevron-circle-up"];
			}
			return ["far", "chevron-circle-down"];
		},
		filterOptions() {
			if (!this.searchText && this.searchable) {
				return this.options;
			}
			return this.options.filter((item) => {
				return item[this.searchKey].toLowerCase().search(this.searchText.toLowerCase()) !== -1;
			});
		},
		empty() {
			return !this.filterOptions.length;
		}
	},

	watch: {
		input(newValue) {
			this.$emit("change", newValue);
			this.$emit("input", newValue);
		},
		value(newValue) {
			this.input = newValue;
		}
	},

	created() {
		this.input = this.value;
	},

	methods: {
		onSelectItem(e, item) {
			this.input = item.value;
			this.isShowOptions = false;
		},
		onClickOutSide() {
			this.isShowOptions = false;
		},
		toggleDropdown() {
			this.isShowOptions = !this.isShowOptions;
		}
	}
};
</script>

<style lang="scss" scoped>
.container-dropdown {
	position: relative;

	&.active {
		.container-dropdown-options {
			display: block;
		}
	}

	.dropdown-value {
		cursor: pointer;

		&.active {
			background-color: $color-ice-two;
		}

		&-title {
			font-family: $font-family-sub;
			font-weight: $font-weight-bold;
			font-size: $font-24;
			color: $color-dark-blue-grey;
			margin-bottom: rem(2);
		}

		&-selected {
			color: $color-cerulean;
			font-weight: $font-weight-bold;

			svg {
				position: relative;
				top: rem(2);
				font-size: $font-16;
				margin-left: rem(5);
			}
		}
	}

	.container-dropdown-options {
		width: rem(378);
		display: none;
		z-index: 4;
		position: absolute;
		left: 0;
		bottom: rem(-8);
		transform: translateY(100%);
		padding: rem(8) 0;
		border-radius: 4px;
		box-shadow: 0 2px 8px 0 rgba(16, 51, 50, 0.2);
		border: 1px solid $color-silver;
		background-color: $color-white;

		.form-search {
			padding: 0 rem(8);
			margin-bottom: rem(8);
		}

		.empty {
			color: $color-black-30;
			text-align: center;
		}
	}

	.dropdown-options {
		@include fontBase();

		width: 100%;
		max-height: rem(300);
		overflow: auto;

		&-item {
			&-link {
				display: block;
				padding: rem(2) rem(18);
				color: $color-dark-blue-grey;
				cursor: pointer;

				&:hover, &.active {
					background-color: $color-ice-two;
				}

				.svg-inline--fa {
					font-size: $font-16;
					color: $color-cerulean;
					margin-right: rem(8);
				}
			}
		}
	}
}
</style>