<template>
	<BaseToastContent
		:message="message"
		title="Successfully confirmed your consumption"
	/>
</template>

<script>
import BaseToastContent from "@/components/BaseToastContent.vue";

export default {
	name: "ConsumptionSuccess",

	props: ["dateInvoice"],

	components: {
		BaseToastContent
	},

	computed: {
		message() {
			return `BIG will send you an invoice of ${this.dateInvoice} soon`;
		}
	}
};
</script>