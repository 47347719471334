import httpClient from "./httpClient";

const O2_AERATION_ENDPOINT = "/o2-aerations";

const getO2AerationInfoAPI = (id) => httpClient.get(`${O2_AERATION_ENDPOINT}/${id}`);
const getO2AerationOxygenDissolveAPI = (id, params) => httpClient.get(`${O2_AERATION_ENDPOINT}/${id}/dissolve-oxygens`, { params });
const getO2AerationOxygenConsumptionAPI = (id, params) => httpClient.get(`${O2_AERATION_ENDPOINT}/${id}/oxygen-consumptions`, { params });
const getO2AerationPowerConsumptionAPI = (id, params) => httpClient.get(`${O2_AERATION_ENDPOINT}/${id}/power-consumptions`, { params });
const getO2AerationLastMonthAPI = (id) => httpClient.get(`${O2_AERATION_ENDPOINT}/${id}/latest-usages`);
const getO2AerationHistoryAPI = (id) => httpClient.get(`${O2_AERATION_ENDPOINT}/${id}/order-histories`);
const getO2AerationOxygenFlowRateAPI = (id, params) => httpClient.get(`${O2_AERATION_ENDPOINT}/${id}/flow-rates`, { params });
export {
	getO2AerationInfoAPI,
	getO2AerationOxygenDissolveAPI,
	getO2AerationOxygenConsumptionAPI,
	getO2AerationPowerConsumptionAPI,
	getO2AerationLastMonthAPI,
	getO2AerationOxygenFlowRateAPI,
	getO2AerationHistoryAPI
};
