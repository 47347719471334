/**
 * Parse Activity logs
 * @param {Array} input activity logs
 * @returns {ActivityLogs}
 */
export function parseActivityLogs(input) {
	if (!input) {
		return [];
	}

	const mapLogs = (item) => ({
		loggedAt: item.loggedAt,
		email: item.email,
		action: item.action,
		event: item.event
	});

	return {
		staffs: input.staffs ? input.staffs.map(mapLogs) : [],
		customers: input.customers ? input.customers.map(mapLogs) : []
	};
}

/**
 * Parse User overall
 * @param {Object} input user overall
 * @returns {UserOverall}
 */
export function parseUserOverall(input) {
	if (!input) {
		return {};
	}

	return {
		staff: {
			totalSignIns: input?.staff?.totalSignIns,
			totalUsers: input?.staff?.totalUsers,
			totalActivities: input?.staff?.totalActivities
		},
		customer: {
			totalSignIns: input?.customer?.totalSignIns,
			totalUsers: input?.customer?.totalUsers,
			totalActivities: input?.customer?.totalActivities
		}
	};
}

/**
 * Parse Most active users
 * @param {Object} input Most active users
 * @returns {MostActiveUser}
 */
export function parseMostActiveUser(input) {
	if (!input) {
		return {};
	}

	const staffs = input?.staffs || [];
	const customers = input?.customers || [];

	return {
		staffs: staffs.map((item) => ({
			displayName: item.displayName,
			email: item.email,
			activities: item.activities
		})),
		customers: customers.map((item) => ({
			displayName: item.displayName,
			email: item.email,
			activities: item.activities
		}))
	};
}

export function parseUserLogins(input) {
	if (!input) {
		return {
			staffLogins: [],
			customerLogins: []
		};
	}
	return {
		staffLogins: input.staffs || [],
		customerLogins: input.customers || []
	};
}