<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-company', { active: isCompanyActive }]">
			<BaseDropdown
				:list="companies"
				:value="companyId"
				type="ghost"
				placeholderSearchInput="Type Company name"
				placeholder="Company Name: All"
				@input="filterCompany"
				@active="setFocus('company', $event)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline>Search</BaseButton>
	</form>
</template>

<script>
export default {
	name: "SearchFilterForm",

	props: {
		query: {
			type: Object,
			default: () => ({})
		},
		companies: {
			type: Array,
			default: () => ([])
		}
	},

	data() {
		return {
			companyId: this.query.company,
			isCompanyActive: false
		};
	},

	methods: {
		filterCompany(value) {
			this.companyId = value;
		},
		handleSubmit() {
			this.$emit("submit", this.companyId);
		},
		setFocus(type, value) {
			switch (type) {
				case "company":
					this.isCompanyActive = value;
					break;
				default:
					break;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-company {
	width: rem(394);
}
</style>
