import {
	getEOrderingInfoAPI,
	postEOrderingCreateAPI,
	getEOrderingConfigurationAPI,
	postEOrderingCreateBatchOrderAPI
} from "@/services/api/eOrdering.api";
import {
	E_ORDERING_INFO_REQUEST,
	E_ORDERING_INFO_SUCCESS,
	E_ORDERING_INFO_FAILURE,
	E_ORDERING_POST_REQUEST,
	E_ORDERING_POST_SUCCESS,
	E_ORDERING_POST_FAILURE,
	E_ORDERING_CONFIGURATION_REQUEST,
	E_ORDERING_CONFIGURATION_SUCCESS,
	E_ORDERING_CONFIGURATION_FAILURE
} from "../../types";
import { transformInfo } from "../../../selectors/transform/eOrdering/transformInfo";
import { transformAfterCreated } from "../../../selectors/transform/eOrdering/transformRespon";

export default {
	namespaced: true,

	state: {
		data: transformInfo({}),
		isLoading: false,
		isEmpty: false,
		created: transformAfterCreated({}),
		createdError: false,
		creating: false,
		configuration: []
	},

	getters: {
		getShipToLocation(state) {
			const position = {
				lat: state.data.device.shipToLatitude,
				lng: state.data.device.shipToLongitude
			};
			return position;
		},

		getShiptoCompany(state) {
			return state.data.shipTo;
		}
	},

	mutations: {
		[E_ORDERING_INFO_REQUEST](state) {
			state.isLoading = true;
			state.data = transformInfo({}, []);
		},
		[E_ORDERING_INFO_SUCCESS](state, data) {
			state.isLoading = false;
			state.data = transformInfo(data, state.configuration);
			state.isEmpty = data.length === 0;
		},
		[E_ORDERING_INFO_FAILURE](state) {
			state.data = transformInfo({}, []);
			state.isLoading = false;
		},
		[E_ORDERING_POST_REQUEST](state) {
			state.createdError = false;
			state.creating = true;
			state.created = transformAfterCreated({});
		},
		[E_ORDERING_POST_SUCCESS](state, data) {
			state.createdError = false;
			state.creating = false;
			state.created = transformAfterCreated(data);
		},
		[E_ORDERING_POST_FAILURE](state) {
			state.created = transformAfterCreated({});
			state.createdError = true;
			state.creating = false;
		},
		[E_ORDERING_CONFIGURATION_REQUEST](state) {
			state.configuration = [];
		},
		[E_ORDERING_CONFIGURATION_SUCCESS](state, data) {
			state.configuration = data;
		},
		[E_ORDERING_CONFIGURATION_FAILURE](state) {
			state.configuration = [];
		}
	},

	actions: {
		async getEOrderingInfo({ commit }, id) {
			try {
				commit(E_ORDERING_INFO_REQUEST);

				const { data } = await getEOrderingInfoAPI(id);

				commit(E_ORDERING_INFO_SUCCESS, data);
			} catch (error) {
				commit(E_ORDERING_INFO_FAILURE);
			}
		},

		async postEOrderingCreate({ commit }, params) {
			try {
				commit(E_ORDERING_POST_REQUEST);
				const { data } = await postEOrderingCreateAPI(params);
				commit(E_ORDERING_POST_SUCCESS, data);
			} catch (error) {
				commit(E_ORDERING_POST_FAILURE);
			}
		},

		async getEOrderingConfiguration({ commit }) {
			try {
				commit(E_ORDERING_CONFIGURATION_REQUEST);
				const { data } = await getEOrderingConfigurationAPI();
				commit(E_ORDERING_CONFIGURATION_SUCCESS, data);
			} catch (error) {
				commit(E_ORDERING_CONFIGURATION_FAILURE);
			}
		},

		async postEOrderingCreateBatchOrder({ commit }, params) {
			try {
				commit(E_ORDERING_POST_REQUEST);
				const { data } = await postEOrderingCreateBatchOrderAPI(params);
				commit(E_ORDERING_POST_SUCCESS, data);
			} catch (error) {
				commit(E_ORDERING_POST_FAILURE);
			}
		}
	}
};
