<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:wrapperClass="mainClass"
		:iconSize="iconSize"
	>
		<template #icon>
			<TankIcon
				:key="data.shipId"
				:status="data.levelStatus"
				:value="data.currentLevel"
				:percentage="data.tankPercent"
				:size="iconSize"
			/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div v-if="getDeviceID" class="container-detail-info-device-id">
					<span>
						Device ID
					</span>
					<span>
						{{ getDeviceID }}
					</span>
				</div>
				<div class="container-detail-info-date">
					<span>
						Last signal:
					</span>
					<span>
						{{ data.signalReceivedAt }}
					</span>
				</div>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import TankIcon from "@/components/deviceIcons/TankIcon.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";
import { MODULE_DEVICE_STATUSES, TANK_STATUS_CLASSES } from "../../../../enums/modules";

export default {
	name: "TankDevice",

	components: {
		TankIcon,
		BaseDeviceWithCompany
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	computed: {
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		},

		getDeviceID() {
			if (this.data.deviceCustomerName) {
				return this.data.shipId;
			}
			return false;
		},

		mainClass() {
			return TANK_STATUS_CLASSES[this.data.levelStatus || "EMPTY"];
		},
		tagClass() {
			const status = this.data.deliveryStatus;

			return status
				? MODULE_DEVICE_STATUSES[status].tagClass
				: null;
		},
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
::v-deep {
	.container-detail-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(8);
		margin-right: rem(12);
		margin-left: rem(16);

		.wrapper-icon {
			margin-top: rem(16);
			margin-left: rem(5);
		}
	}
}
/* stylelint-enable */
.container-detail {
	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		&-date {
			height: rem(14);
			margin-top: rem(8);
			font-size: $font-16;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
			line-height: rem(16);
		}

		.tag {
			position: absolute;
			top: rem(16);
			right: rem(16);
		}

		&-device-id {
			display: flex;
			align-items: center;
			height: rem(14);
			font-size: $font-14;
			color: $color-dark-blue-grey;
			line-height: rem(16);
			margin: rem(-4) 0 rem(-8);

			span {
				&:first-of-type {
					display: flex;
					width: rem(100);
				}

				&:last-of-type {
					flex: 1;
					font-weight: $font-weight-bold;
					text-align: right;
				}
			}
		}
	}
}
</style>