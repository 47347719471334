/**
 * Enum for module types.
 * @readonly
 * @enum {EnumModuleTypes}
 */

export const STAFF_ROLES = Object.freeze({
	SUPER_ADMIN: "SUPER_ADMIN",
	ADMIN: "ADMIN",
	BUSINESS_SUPPORT: "BUSINESS_SUPPORT",
	SALES: "SALES",
	SALES_PLUS: "SALES_PLUS",
	IT: "IT",
	PLANNER: "PLANNER"
});

/**
 * Enum for user roles label.
 * @readonly
 * @enum {EnumUserRoles}
 */
export const ROLES_LABEL = Object.freeze({
	ADMIN: "Admin",
	BUSINESS_SUPPORT: "Business Support",
	IT: "IT",
	SALES: "Sales",
	SALES_PLUS: "Sales Plus",
	SUPER_ADMIN: "Super Admin",
	PLANNER: "Planner"
});
