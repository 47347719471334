import httpClient from "./httpClient";

const CER_END_POINT = "/cers";
const getCerInfoAPI = (id) => httpClient.get(`${CER_END_POINT}/${id}`);
const getWaterFlowAPI = (id, params) => httpClient.get(`${CER_END_POINT}/${id}/water-flows`, { params });
const getMoneySavingAPI = (id, filterBy) => httpClient.get(`${CER_END_POINT}/${id}/money-savings?filter=${filterBy}`);
const getCerHistoryAPI = (id, params = {}) => httpClient.get(`${CER_END_POINT}/${id}/histories`, { params });

export {
	getCerInfoAPI,
	getWaterFlowAPI,
	getMoneySavingAPI,
	getCerHistoryAPI
};
