<template>
	<BaseCard>
		<template #header>
			<h4>Condition</h4>
		</template>
		<template #body>
			<div class="container-form">
				<div class="item">
					<div class="label">
						Type
					</div>
					<div class="form">
						<strong>
							No Signal
						</strong>
					</div>
				</div>
				<div class="item">
					<div class="label">
						Severity
					</div>
					<div class="form w-200">
						<BaseDropdown
							:searchable="false"
							:value="form.severity"
							:list="severityOptions"
							:placeholder="`Select severity level`"
							inline
							@input="handleSelectSeverity"
						/>
					</div>
				</div>
				<div class="item">
					<div class="label">
						Frequency
					</div>
					<div class="form w-200">
						<BaseFormInputTime
							v-model="form.frequency"
							:minHours="inputTimeOptions.minHours"
							:maxHours="inputTimeOptions.maxHours"
							:minMinutes="inputTimeOptions.minMinutes"
							:maxMinutes="inputTimeOptions.maxMinutes"
							:showTimeIcon="false"
						/>
					</div>
				</div>
			</div>

			<div class="container-form mt-24">
				<div class="item mb-16">
					<div class="label">
						<strong>
							Message
						</strong>
					</div>
					<div class="form"></div>
				</div>
				<div class="item">
					<div class="label">
						Heading
					</div>
					<div class="form">
						<BaseFormInput
							v-model="form.heading"
							:inputOptions="['small']"
							:maxLength="60"
							placeholder="e.g. Module alert"
							inline
						/>
					</div>
				</div>
				<div class="item">
					<div class="label">
						Description
					</div>
					<div class="form">
						<BaseFormInput
							v-model="form.description"
							:inputOptions="['small']"
							placeholder="Type a description (optional)"
							inline
						/>
					</div>
				</div>
			</div>

			<div class="container-form mt-24">
				<div class="item mb-16">
					<div class="label">
						<strong>
							Notify to
						</strong>
					</div>
					<div class="form"></div>
				</div>
				<div class="item">
					<div class="label">
						Send notify to
					</div>
					<div class="form">
						<BaseMultipleSelect
							:options="notificationGroupOptions"
							:value="form.notificationGroups"
							:limit="10"
							:isShowLabel="false"
							placeholder="Select notification group"
							class="custom-wrapper-multi-select"
							multiple
							@onSelectValue="handleSelectNotificationGroup"
							@onRemoveSelected="handleRemoveNotificationGroup"
						/>
					</div>
				</div>
			</div>
		</template>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import BaseFormInputTime from "@/components/BaseFormInputTime.vue";
import BaseMultipleSelect from "@/components/BaseMultipleSelect.vue";

export default {
	props: {
		notificationGroupOptions: {
			type: Array,
			default: () => ([])
		}
	},

	components: {
		BaseCard,
		BaseFormInputTime,
		BaseMultipleSelect
	},

	data() {
		return {
			form: {
				severity: null,
				frequency: 0,
				heading: null,
				description: null,
				notificationGroups: []
			},
			inputTimeOptions: {
				minHours: 0,
				maxHours: 999,
				minMinutes: 0,
				maxMinutes: 59
			},
			severityOptions: [
				{
					label: "Critical",
					value: "critical"
				},
				{
					label: "Medium",
					value: "medium"
				},
				{
					label: "General",
					value: "general"
				}
			]
		};
	},

	watch: {
		form: {
			handler() {
				this.$emit("update:form", this.form);
			},
			deep: true
		}
	},

	mounted() {
		if (this.$attrs.form) {
			this.form = { ...this.$attrs.form };
		}
	},

	methods: {
		handleSelectSeverity(value) {
			this.form.severity = value;
		},
		handleSelectNotificationGroup(item) {
			this.form.notificationGroups.push(item);
		},
		handleRemoveNotificationGroup(item) {
			const { notificationGroups } = this.form;
			const indexItem = notificationGroups.findIndex(({ id }) => id === item.id);
			const foundItem = indexItem > -1;
			if (foundItem) {
				notificationGroups.splice(indexItem, 1);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.head-title {
	font-family: $font-family-sub;
	font-size: $font-32;
	color: $color-dark-blue-grey;
	margin: 0 0 rem(24) 0;
}

.container-form {
	.item {
		margin-bottom: rem(24);
		display: flex;

		&:last-of-type {
			margin-bottom: 0;
		}

		.label {
			display: flex;
			align-items: center;
			min-width: rem(130);
		}

		.form {
			flex-grow: 1;
		}
	}
}

.custom-wrapper-multi-select {
	margin: 0;
}

.w-200 {
	width: rem(200);
	flex-grow: unset !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-16 {
	margin-bottom: rem(16) !important;
}

.mt-24 {
	margin-top: rem(24) !important;
}
</style>