import { getTanksConsumptionHistoryAPI } from "@/services/api/tanks.api";
import {
	TANK_CONSUMPTION_HISTORY_REQUEST,
	TANK_CONSUMPTION_HISTORY_SUCCESS,
	TANK_CONSUMPTION_HISTORY_FAILURE
} from "../../types";
import { transformedTankConsumptionHistory } from "../../../selectors/transform/tankConsumptionHistory";

export default {
	namespaced: true,

	state: {
		data: [],
		pagination: {},
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getTankConsumptionHistoryData: (state) => (unit) => {
			return transformedTankConsumptionHistory(state.data, unit);
		}
	},

	mutations: {
		[TANK_CONSUMPTION_HISTORY_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[TANK_CONSUMPTION_HISTORY_SUCCESS](state, response) {
			state.data = response.data;
			state.pagination = response.pagination;
			state.isEmpty = response.data.length === 0;
			state.isLoading = false;
		},
		[TANK_CONSUMPTION_HISTORY_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getTankConsumptionHistory({ commit }, { id = "", params = {} }) {
			try {
				commit(TANK_CONSUMPTION_HISTORY_REQUEST);

				const { data = [] } = await getTanksConsumptionHistoryAPI(id, params);

				commit(TANK_CONSUMPTION_HISTORY_SUCCESS, data);
			} catch (error) {
				commit(TANK_CONSUMPTION_HISTORY_FAILURE);
			}
		}
	}
};
