import httpClient from "./httpClient";

const END_POINT = "/solarCells";

const getSorInfoAPI = (id) => httpClient.get(`${END_POINT}/${id}`);
const getSorTrendsAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/power-trends`, { params });
const getSorVoltageAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/voltages`, { params });
const getSorCurrentAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/currents`, { params });
const getSorGenerationAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/generations`, { params });
const getSorHistoryAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/histories`, { params });
const getSorKwhAPI = (id, params) => httpClient.get(`${END_POINT}/${id}/kwhs`, { params });

export {
	getSorInfoAPI,
	getSorTrendsAPI,
	getSorVoltageAPI,
	getSorCurrentAPI,
	getSorGenerationAPI,
	getSorHistoryAPI,
	getSorKwhAPI
};
