<template>
	<div class="login-container" ref="container">
		<div class="login-content">
			<div class="login-form">
				<div class="login-logo">
					<img src="/assets/images/logo.svg" alt="BIG-TH">
				</div>
				<h1 class="login-title">Welcome to BIG Portal</h1>
				<p class="login-sub-title">BIG’s customer please sign in using the email and password registerd to BIG portal</p>
				<form ref="form-login">
					<BaseNotifyContent
						v-if="customerLoginErrorMessage"
						:show-shadow="false"
						:title="customerLoginErrorMessage"
						color="danger"
						class="custom-notify"
					/>
					<div class="form-group">
						<BaseFormInput
							v-model="email"
							:is-error="isValidEmail === false"
							:error-message="emailErrorMessage"
							:disabled="customerLoginLoading"
							type="email"
							placeholder="Email"
							iconLeft="envelope"
							class="custom-input has-icons-left"
							@blur="handleBlurEmail"
						/>
					</div>
					<div class="form-group">
						<BaseFormInputPassword
							v-model="password"
							:valid.sync="isValidPassword"
							:disabled="customerLoginLoading"
							placeholder="Password"
						/>
					</div>
					<div
						class="forgot-password"
					>
						<router-link
							class="btn-link"
							:to="{ name: PAGE_NAME.PASSWORD_FORGOT }"
						>
							Forgot passwords?
						</router-link>
					</div>
					<div class="form-group">
						<button
							:disabled="disableCustomerLoginButton"
							type="button"
							class="btn btn-submit-login"
							@click="customerLogin"
						>
							Customer sign in
						</button>
					</div>
					<div class="seperator">
						<span>Or</span>
					</div>
					<div class="form-group">
						<a :href="loginUrl" class="btn btn-secondary">Sign in using BIG account</a>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseFormInputPassword from "@/components/BaseFormInputPassword.vue";
import BaseNotifyContent from "@/components/BaseNotifyContent.vue";
import { PAGE_NAME } from "../enums/pagePermission";
import { isValidEmail as validEmail } from "../selectors/helpers/regex";
import { getRedirectRouteName } from "../selectors/helpers/permission";
import { CUSTOMER_ROLES } from "../enums/customerManagement/customerRole";

const { ORDER } = CUSTOMER_ROLES;
export default {
	name: "Login",

	components: {
		BaseFormInputPassword,
		BaseNotifyContent
	},

	data() {
		return {
			controlOptions: ["has-icons-left"],
			email: this.$cookies.get("loginEmail") || "",
			password: "",
			isValidEmail: null,
			isValidPassword: null,
			isBrowserAutoFilled: false,
			PAGE_NAME
		};
	},

	computed: {
		...mapState(["user"]),

		loginUrl() {
			// the state working with LoginCallback.vue components
			const state = encodeURIComponent(JSON.stringify({
				route: this.$route.query.route || PAGE_NAME.MARKETPLACE,
				params: {
					...this.$route.query.p
				},
				query: {
					...this.$route.query.q
				}
			}));
			return `
				${process.env.VUE_APP_AZURE_AUTHORIZE_ENDPOINT}/authorize?
				client_id=${process.env.VUE_APP_AZURE_CLIENT_ID}&
				scope=user.read.all&
				response_type=token
				&redirect_uri=${process.env.VUE_APP_AZURE_LOGIN_CALLBACK}&
				state=${state}&
				nonce=678910
			`;
		},

		disableCustomerLoginButton() {
			if (this.isBrowserAutoFilled) {
				return false;
			}

			return !this.isValidEmail || !this.isValidPassword || this.customerLoginLoading;
		},

		isLoggedIn() {
			return this.user.isLoggedIn;
		},

		customerLoginLoading() {
			return this.user.customerLoginLoading;
		},

		customerLoginErrorMessage() {
			return this.user.customerLoginErrorMessage;
		},

		emailErrorMessage() {
			if (this.email.trim().length === 0) {
				return "Required field";
			}
			return "Invalid email format.";
		}
	},

	watch: {
		email() {
			if (!this.isValidEmail) {
				if (validEmail(this.email)) {
					this.isValidEmail = true;
				}
			}
			this.isBrowserAutoFilled = false;
		},
		password() {
			this.isBrowserAutoFilled = false;
		}
	},

	mounted() {
		// selector input element
		if (this.email !== "") {
			this.handleBlurEmail(this.email);
		}

		const input = this.$refs["form-login"].querySelector(".custom-input .input");

		// handle evnet animationstart from input
		// to detect auto fill
		input.addEventListener("animationstart", (e) => {
			if (e.animationName.indexOf("onAutoFillStart") !== -1) {
				this.isBrowserAutoFilled = true;
			}
		});

		window.addEventListener("keypress", this.handleKeyPress);
	},

	methods: {
		...mapActions("user", {
			postCustomerLogin: "postCustomerLogin",
			clearCustomerState: "clearCustomerState"
		}),

		async customerLogin() {
			if (this.disableCustomerLoginButton) {
				return;
			}

			const { email, password } = this;

			await this.postCustomerLogin({ email, password });

			if (this.isLoggedIn) {
				const isOldLoginEmail = this.$cookies.get("loginEmail") !== null;

				if (isOldLoginEmail) {
					this.$cookies.remove("loginEmail");
				}

				this.$cookies.set("loginEmail", this.email);

				// redirect to route name as customer permissions
				await this.$store.dispatch("user/getUserInfo");

				const redirectToRouteName = this.$store.state.user.role === ORDER ? this.PAGE_NAME.E_ORDERING_LIST : getRedirectRouteName(this.$store.state.user.rolePermissions);
				const props = this.$router.resolve({ name: redirectToRouteName });

				window.location = props.href;
			}
		},

		handleBlurEmail(value) {
			this.isValidEmail = validEmail(value);
		},

		handleKeyPress(e) {
			const KEY = {
				Enter: false
			};

			KEY[e.code] = true;

			if (KEY.Enter) {
				this.customerLogin();
			}
		}
	},

	destroyed() {
		window.removeEventListener("keypress", this.handleKeyPress);
		this.clearCustomerState();
	}
};
</script>

<style lang="scss" scoped>
.login-container {
	background: url("/assets/images/bg.png") no-repeat center center;
	background-size: cover;
	display: flex;
	height: 100vh;
	min-height: rem(800);
	justify-content: center;

	.login-content {
		display: flex;
		align-items: center;

		.login-title,
		.login-sub-title,
		.login-staff,
		.login-logo {
			text-align: center;
		}

		.forgot-password {
			color: $color-cerulean;
			margin: rem(-12) 0 rem(8) 0;
			display: flex;
			justify-content: flex-end;
		}

		.login-form {
			background-color: $color-white;
			padding: rem(18) rem(40);
			width: 100%;
			max-width: rem(470);
			margin: 0 auto;
			border: solid rem(1) $color-silver;
			border-radius: rem(4);
		}

		.login-title {
			color: $color-dark-blue-grey;
			font-weight: 600;
		}

		.login-sub-title {
			color: $color-dark-blue-grey;
			margin-top: rem(10);
			margin-bottom: rem(20);
			font-size: $font-24;
		}

		.btn-submit-login {
			width: 100%;
			height: rem(48);
			background-color: $color-azure;
			color: $color-white;

			&:disabled {
				opacity: 0.5;
			}
		}

		.btn-secondary {
			width: 100%;
			height: rem(48);
			background-color: transparent;
			border-color: $color-emerald;
			color: $color-emerald;
		}

		.form-group {
			margin-bottom: rem(25);

			.form-control {
				height: rem(50);
			}
		}
	}
}

.seperator {
	position: relative;
	padding: rem(24) 0;
	margin-bottom: rem(18);
	text-align: center;

	span {
		background-color: $color-white;
		color: $color-grey-4;
		font-weight: bold;
		display: block;
		padding: 0 rem(8);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	&::after {
		content: "";
		background-color: $color-silver;
		display: block;
		height: rem(1);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
}

.custom-notify {
	margin-bottom: $spacing-6;
}

/* stylelint-disable */
// add amimation to working with `animationstart` event
.custom-input {
	:-webkit-autofill {
		animation-name: onAutoFillStart;
	}

	:not(:-webkit-autofill) {
		animation-name: onAutoFillCancel;
	}

	@keyframes onAutoFillStart {
		from {}
		to {}
	}

	@keyframes onAutoFillCancel {
		from {}
		to {}
	}
}
/* stylelint-enable */
</style>
