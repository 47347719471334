<template>
	<div :class="[mainClasses]">
		<div class="meter-icon-details">
			<div class="meter-value">{{ flowRate }}</div>
			<div class="meter-unit" v-html="unit"></div>
		</div>
		<svg class="meter-icon-svg" viewBox="0 0 100 100">
			<path
				:d="pathStyles.pathString"
				:stroke-width="strokeWidth"
				:style="pathStyles.trailPathStyle"
				class="meter-icon-svg-bg"
				fill-opacity="0"
			></path>
			<path
				v-if="this.percentage > 0"
				:d="pathStyles.pathString"
				:stroke-width="strokeWidth"
				:style="pathStyles.strokePathStyle"
				class="meter-icon-svg-progress"
				fill-opacity="0"
			></path>
		</svg>
	</div>
</template>

<script>
import { parseAddCommaWhenIsNumber, parseToTypeNumber } from "../../../selectors/helpers/index";

export default {
	name: "MeterIcon",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},

		percentage: {
			type: [Number, String],
			default: 0
		},

		size: {
			type: String,
			default: "small",
			validator: (value) => {
				return [
					"smallest",
					"small",
					"large"
				].includes(value);
			}
		},

		unit: {
			type: String,
			default: ""
		}
	},

	computed: {
		mainClasses() {
			return ["meter-icon-container", this.size];
		},

		flowRate() {
			return parseAddCommaWhenIsNumber(this.value);
		},

		strokeWidth() {
			return this.type === "small" ? 6 : 8;
		},

		pathStyles() {
			const percent = parseToTypeNumber(this.percentage);
			const gapDegree = 0;
			const radius = 50 - (this.strokeWidth / 2);
			const beginPositionX = 0;
			const beginPositionY = -radius;
			const endPositionX = 0;
			const endPositionY = -2 * radius;

			const pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
			a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
			a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;

			const len = Math.PI * 2 * radius;
			const trailPathStyle = {
				strokeDasharray: `${len - gapDegree}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};
			const strokePathStyle = {
				strokeDasharray: `${(percent / 100) * (len - gapDegree)}px ${len}px`,
				strokeDashoffset: `-${gapDegree / 2}px`
			};

			return { pathString, trailPathStyle, strokePathStyle };
		}
	}
};
</script>

<style lang="scss" scoped>
	.meter-icon {
		&-container {
			position: relative;
			width: rem(73);
			height: rem(73);

			&.smallest {
				width: rem(58);
				height: rem(58);

				.meter-value {
					font-size: $font-18;
					margin-bottom: rem(-5);
				}

				.meter-unit {
					font-size: $font-12;
				}
			}

			&.large {
				width: rem(130);
				height: rem(130);

				.meter-value {
					font-size: $font-40;
				}

				.meter-unit {
					font-size: $font-26;
				}
			}
		}

		&-svg {
			width: 100%;
			height: 100%;

			path {
				stroke-linecap: round;
			}

			&-bg {
				stroke: $color-ice;
			}

			&-progress {
				stroke: $color-emerald;
			}
		}

		&-details {
			display: flex;
			justify-content: center;
			flex-direction: column;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			color: $color-dark-blue-grey;
			text-align: center;
			white-space: nowrap;

			.meter-value {
				@include fontSub();

				color: $color-dark-blue-grey;
				font-size: $font-21;
				font-weight: 600;
				line-height: 1;
				margin-bottom: rem(-2);
			}

			.meter-unit {
				font-size: $font-14;
				font-weight: 600;
				line-height: 1;
			}
		}
	}
</style>
