<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: isActive.keyword }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				v-model="form.keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Search ship-to name or location key"
				@input="handleKeywordChanged"
				@focus="setFocus('keyword', true)"
				@blur="setFocus('keyword', false)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline>Search</BaseButton>
	</form>
</template>

<script>
export default {
	name: "SearchFilterForm",

	props: {
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			form: {
				keyword: this.query.keyword || null,
				role: this.query.role || null
			},
			isActive: {
				keyword: false,
				role: false
			}
		};
	},

	methods: {
		handleKeywordChanged(value = null) {
			this.form.keyword = value || null;
		},
		handleRoleSelected(value = null) {
			this.form.role = value;
		},
		handleSubmit() {
			this.$emit("submit", this.form);
		},
		setFocus(type, value) {
			this.isActive[type] = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-keyword {
	width: rem(394);
}
</style>
