<template>
	<div class="container-main">
		<div class="item">
			<div class="title">
				D/O No.
			</div>
			<div class="detail">
				{{ data.doNumber }}
			</div>
		</div>
		<div class="item">
			<div class="title">
				Trip ID
			</div>
			<div class="detail">
				{{ data.tripId }}
			</div>
		</div>
		<div class="item">
			<div class="title">
				Tractor ID
			</div>
			<div class="detail">
				{{ data.tractorId }}
			</div>
		</div>
		<div class="item">
			<div class="title">
				Trailer ID
			</div>
			<div class="detail">
				{{ data.trailerId }}
			</div>
		</div>
		<div class="item">
			<div class="title">
				Drivers
			</div>
			<div class="detail">
				<div class="driver-list" v-if="drivers.length> 0">
					<div  v-for="({ name }, index) in drivers" :key="index" class="profile">
						<!-- TODO: waiting fixing s3 images -->
						<!-- <img :src="imageUrl" class="avatar" alt=""> -->
						<span class="name">
							{{ name }}
						</span>
					</div>
				</div>
				<div v-else class="driver-list">
					-
				</div>
			</div>
		</div>
		<div class="item">
			<div class="title">
				Order amount
			</div>
			<div class="detail">
				{{ data.orderAmount }} Kg
			</div>
		</div>
		<div class="item">
			<div class="title">
				Call center
			</div>
			<div class="detail">
				<div class="tel">
					<a class="tel-link" href="tel:098-258-5317">098-258-5317</a>
					<a class="tel-link" href="tel:038-344-999">038-344-999</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DriverInfo",

	props: {
		data: {
			type: Object,
			default: () => {}
		},
		drivers: {
			type: Array,
			default: () => [],
			validator: (drivers) => {
				const isValidDriver = ({ imageUrl, name }) => {
					return typeof imageUrl === "string" && typeof name === "string";
				};
				return drivers.every(isValidDriver);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container-main {
	@include fontBase();

	min-width: rem(286);
	display: flex;
	flex-direction: column;
	color: $color-dark-blue-grey;

	.item {
		display: flex;
		margin-bottom: $spacing-4;

		.title, .detail {
			flex-grow: 1;
		}

		.detail {
			display: flex;
			justify-content: flex-end;
			font-weight: $font-weight-bold;

			.driver-list {
				display: flex;
				flex-direction: column;

				.name {
					@include ellipsisOneline;

					text-align: right;
				}

				.profile {
					display: flex;
					flex-grow: 1;
					justify-content: flex-end;
					margin-bottom: $spacing-3;

					.avatar {
						width: rem(40);
						height: rem(40);
						transform: translateY(rem(-7));
						margin-right: $spacing-3;
						border-radius: 50%;
					}
				}
			}

			.tel {
				display: flex;
				align-items: flex-end;
				flex-direction: column;

				&-link {
					color: $color-dark-blue-grey;
				}
			}
		}
	}
}
</style>