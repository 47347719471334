<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group input-group-keyword', { active: isActive }]">
			<span class="input-icon">
				<FontAwesomeIcon :icon="['far', 'search']" />
			</span>
			<BaseFormInput
				v-model="form.keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Template name"
				@input="handleKeywordChanged"
				@focus="setFocus(true)"
				@blur="setFocus(false)"
			/>
		</div>
		<BaseButton size="large" type="primary" outline rounded>Search</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
export default {
	name: "SearchFilterForm",

	props: {
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			form: {
				keyword: this.query.keyword || null
			},
			isActive: false
		};
	},

	methods: {
		handleKeywordChanged(value = null) {
			this.form.keyword = value || null;
		},
		handleSubmit() {
			this.$emit("submit", this.form);
		},
		setFocus(value) {
			this.isActive = value;
		},
		clearFilter() {
			this.form.keyword = null;
			this.$emit("submit", this.form);
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-keyword {
	width: rem(394);
}
</style>
