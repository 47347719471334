import { getZldRecycleWaterAPI } from "../../../services/api/Zlds.api";
import {
	ZLD_RECYCLE_WATER_REQUEST,
	ZLD_RECYCLE_WATER_SUCCESS,
	ZLD_RECYCLE_WATER_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: [],
		lastUpdate: "",
		isLoading: false,
		isEmpty: false
	},

	mutations: {
		[ZLD_RECYCLE_WATER_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[ZLD_RECYCLE_WATER_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[ZLD_RECYCLE_WATER_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async fetchRecycleWaterFlowrate({ commit }, { id = "", query = {} }) {
			try {
				commit(ZLD_RECYCLE_WATER_REQUEST);
				const { data } = await getZldRecycleWaterAPI(id, query);

				commit(ZLD_RECYCLE_WATER_SUCCESS, data);
			} catch (error) {
				commit(ZLD_RECYCLE_WATER_FAILURE);
			}
		}
	}
};
