<template>
	<div>
		<BaseNoData
			v-if="isEmpty"
			class="empty"
			description="BIG portal will start showing the graph <br/> right after we have information"
		/>
		<template v-else>
			<div class="header-container">
				<section>
					<BaseSortBy
						:value="sortByIndex"
						:options="optionSort"
						@change="handleChangeSortBy"
					/>
					<label for="">Unit:</label>
					<BaseDropdown
						:value="unitValue"
						:list="filterOptions.dropdown.items"
						:searchable="false"
						class="custom-unit-dropdown"
						@input="handleUnitDropdownSelected"
					>
						<template #prepend>
							<span class="prepend">Unit :</span>
						</template>
					</BaseDropdown>
				</section>
				<BaseFilterExport
					mode="range"
					:frequency="true"
					class="custom-filter-export"
					@onSubmit="handleSubmitExport"
				/>
			</div>
			<div class="consumptions-header">
				<span>Date</span>
				<span>Estimate consumption ({{ this.diplayunitValue }})</span>
			</div>
			<BaseLoading
				v-if="isLoading"
			/>
			<BaseCollapseBase
				v-for="item, index in getTankConsumptionHistoryData(this.unitValue)"
				:title="item.monthDate"
				:sub-title="item.estimateConsumption"
				:key="index"
				:is-expanded="index === 0 ? true : false"
				@checkCollapseOpen="setCollapseOpen"
			>
				<ul class="consumptions-list">
					<li
						v-for="item in item.consumptions" :key="item.index"
						class="consumptions-item"
					>
						<div>{{ item.date }}</div>
						<div class="consumptions-value">{{ item.value }}</div>
					</li>
				</ul>
			</BaseCollapseBase>
			<BasePagination
				v-if="tankConsumptionHistory.pagination.lastPage > 1"
				:value="tankConsumptionHistory.pagination.currentPage"
				:totalPage="tankConsumptionHistory.pagination.lastPage"
				@onPageChange="handleChangePage"
			/>
		</template>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapActions, mapGetters } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseSortBy from "@/components/BaseSortBy.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseCollapseBase from "@/components/BaseCollapseBase.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";

import { getUri } from "../../selectors/helpers";
import { UNIT_TYPE, UNIT_DISPLAY_TYPE } from "../../enums/unit";

export default {
	name: "TankConsumptionHistory",

	components: {
		BaseCollapseBase,
		BaseFilterExport,
		BaseLoading,
		BaseSortBy,
		BaseNoData
	},

	data() {
		return {
			tankId: this.$route.params.id,
			isCollapseOpen: true,
			sortByIndex: this.$route.query.sortByIndex ? Number(this.$route.query.sortByIndex) : 1,
			sortBy: this.$route.query.sort || null,
			currentPage: this.$route.query.page || 1,
			unitValue: this.$route.query.unitValue ? this.$route.query.unitValue : "kg",
			filterOptions: {
				dropdown: {
					items: [
						{
							label: UNIT_DISPLAY_TYPE.NM3,
							value: UNIT_TYPE.NM3
						},
						{
							label: UNIT_DISPLAY_TYPE.SM3,
							value: UNIT_TYPE.SM3
						},
						{
							label: UNIT_DISPLAY_TYPE.KG,
							value: UNIT_TYPE.KG
						},
						{
							label: UNIT_DISPLAY_TYPE.LITER,
							value: UNIT_TYPE.LITER
						}
					]
				}
			},
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				sort: this.$route.query.sort || null
			}
		};
	},

	computed: {
		...mapState(["tankConsumptionHistory"]),

		...mapGetters({
			getTankConsumptionHistoryData: "tankConsumptionHistory/getTankConsumptionHistoryData",
			getUnit: "tankInfo/getUnit"
		}),
		isEmpty() {
			return this.tankConsumptionHistory.isEmpty;
		},
		isLoading() {
			return this.tankConsumptionHistory.isLoading;
		},
		optionSort() {
			return {
				title: "Sort by",
				defaultAtIndex: this.sortByIndex,
				dropdown: [
					{
						index: 0,
						code: "date:asc",
						text: "Oldest"
					},
					{
						index: 1,
						code: "date:desc",
						text: "Newest"
					}
				],
				style: {
					position: "left"
				}
			};
		},
		diplayunitValue() {
			switch (this.unitValue) {
				case UNIT_TYPE.NM3:
					return UNIT_DISPLAY_TYPE.NM3;
				case UNIT_TYPE.SM3:
					return UNIT_DISPLAY_TYPE.SM3;
				case UNIT_TYPE.LITER:
					return UNIT_DISPLAY_TYPE.LITER;
				default:
					return UNIT_DISPLAY_TYPE.KG;
			}
		},
		unitType() {
			return this.getUnit.toLowerCase();
		}
	},

	async created() {
		if (!this.$route.query.unitValue) {
			this.unitValue = this.unitType;
		}
		await this.fetchData();
		this.$emit("loaded", this.fetchData);
	},

	methods: {
		...mapActions({
			getTankConsumptionHistory: "tankConsumptionHistory/getTankConsumptionHistory"
		}),
		async fetchData() {
			const { tankId } = this;
			await this.getTankConsumptionHistory({ id: tankId, params: this.queryParams });
		},
		handleUnitDropdownSelected(value) {
			this.unitValue = value;
		},
		handleChangePage(page) {
			this.currentPage = page;
			this.redirectPage();
		},
		handleChangeSortBy(sortByValue) {
			this.sortByIndex = sortByValue.index;
			this.sortBy = sortByValue.code;
			this.currentPage = 1;
			this.redirectPage();
		},
		redirectPage() {
			this.query = {
				page: this.currentPage,
				...(this.sortBy ? { sort: this.sortBy } : {}),
				sortByIndex: this.sortByIndex,
				unitValue: this.unitValue
			};

			this.$router.push({
				...this.name,
				query: this.query
			});
		},
		handleSubmitExport(form) {
			const params = {
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).startOf("day").toISOString(),
						endDate: dayjs(form.dateRange.end).endOf("day").toISOString()
					} : null
				),
				frequencyType: form.frequencyType,
				sort: this.sortBy ? this.sortBy : "date:desc",
				unit: this.unitValue
			};
			const path = `/tanks/${this.tankId}/export-consumption-history`;
			const uri = getUri(path, params);
			window.location = uri.href;
		},
		setCollapseOpen(value) {
			this.isCollapseOpen = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.header-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: rem(24);

	label {
		@include mobile {
			display: none;
		}
	}

	@include mobile {
		background-color: $color-alabaster;
		align-items: flex-end;
		flex-direction: column;
		margin: 0 rem(-16);
		padding: rem(24) rem(16);
	}

	section {
		display: flex;
		align-items: center;
		width: 100%;

		@include mobile {
			flex-direction: column;
			margin-bottom: rem(16);
		}
	}
}

.consumptions {
	&-header {
		border-bottom: rem(2) solid $color-jungle-green;
		display: flex;
		font-size: rem($font-20);
		font-weight: bold;
		color: $color-dark-blue-grey;
		justify-content: space-between;
		padding: rem(16) rem(188) rem(16) rem(24);
		margin-bottom: rem(12);

		@include mobile {
			padding: rem(16) rem(24);
		}
	}

	&-list {
		margin: rem(16) 0;
	}

	&-item {
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		padding: rem(8) rem(188) rem(8) rem(80);

		@include mobile {
			padding: rem(8) rem(48);
		}

		&:nth-child(odd) {
			background-color: $color-alabaster;
		}
	}

	&-value {
		font-weight: normal;
	}
}

::v-deep {
	.collapse-title {
		.icon-chevron {
			display: block;
		}
	}

	.container-filter {
		@include mobile {
			flex-direction: column;
			width: 100%;
		}

		.container-datepicker {
			@include mobile {
				margin-bottom: rem(16);
				width: 100%;
			}

			.custom-input {
				.input {
					@include mobile {
						background-color: $color-white;
						border-radius: rem(8);
					}
				}
			}
		}

		.container-dropdown {
			@include mobile {
				margin-bottom: rem(16);
				width: 100%;
			}

			.dropdown-value {
				@include mobile {
					background-color: $color-white;
					border-left: rem(1) solid $color-silver;
					border-radius: rem(8);
				}
			}
		}

		.custom-export-btn {
			@include mobile {
				border-radius: rem(8);
			}
		}
	}

	.container-sort {
		@include mobile {
			margin-bottom: rem(16);
			width: 100%;
		}

		.sorting-value {
			margin-right: rem(16);
			margin-left: rem(-8);
		}
	}

	.custom-unit-dropdown {
		margin-left: rem(16);
		max-width: rem(120);

		@include mobile {
			margin-left: 0;
			max-width: 100%;
		}

		.dropdown-value {
			background-color: $color-white;

			.dropdown-placeholder,
			.dropdown-value-text {
				@include mobile {
					position: absolute;
					left: rem(50);
					top: 0;
				}

				sup {
					line-height: rem(20);
				}
			}

			.prepend {
				display: none;

				@include mobile {
					display: block !important;
				}
			}

			.prepend-icon {
				position: absolute;
			}
		}
	}

	.pagination-container {
		justify-content: flex-end;
	}
}
</style>
