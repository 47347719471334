<template>
	<div :class="['container-power', size]">
		<div class="detail">
			<span class="value">
				{{ kw }}
			</span>
			<span class="unit" v-html="unit">
			</span>
		</div>
		<div class="icon">
			<img v-if="showWeatherIcon" :src="weatherIcon" class="weather"/>
			<!-- add viewBox equal width and height of it's orinal
			to let them responsive with parent container -->
			<svg width="100%" height="100%" viewBox="0 0 90 90">
				<defs>
					<!-- marker called helmet, set attributes to fit on top position of the pointer  -->
					<marker id="helmet" viewBox="0 0 60 60" markerWidth="10" markerHeight="10" refX="15" refY="5" orient="auto">
						<!-- rotate to the 90 degree as same angle of the lines pointer below -->
						<g transform="rotate(90, 10, 10)">
							<path d="M0 6, A1 1, 0, 1 1, 10 6
								L10 14, L00 14 Z"
								stroke="none"
								fill="white" />
							<path d="M5 6, L5 14"
								stroke-width="6"
								stroke-linecap="round"
								stroke="#00a8e1" />
						</g>
					</marker>
				</defs>
				<!-- use arcs to draw the semicircle and cut them of around 20% -->
				<path d="M10 64 A40 40, 0, 1 1, 80 64"
					fill="none"
					stroke-width="8"
					stroke-linecap="round"
					stroke="#f29d59" />
				<!-- use line to draw a pointer and set x2, y2 attributes with javascript -->
				<line ref="pointer"
					x1="45" y1="45"
					x2="0" y2="0"
					marker-end="url(#helmet)"
					stroke-width="4"
					stroke-linecap="round"
					stroke="#00a8e1" />
				<!-- draw a small circle  on middle of the semicircle -->
				<circle cx="45" cy="45" r="4" fill="#00a8e1" />
			</svg>
		</div>
	</div>
</template>

<script>
import { parseAddCommaWhenIsNumber, parseToTypeNumber } from "../../../selectors/helpers/index";
import { getWeatherIcon } from "../../../selectors/helpers/weather";

export default {
	props: {
		kilowatt: {
			type: [Number, String],
			default: 0
		},
		valueForPercentage: {
			type: [Number, String],
			default: 0
		},
		empty: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: "small",
			validator: (val) => {
				return ["small", "medium", "large"].includes(val);
			}
		},
		weather: {
			type: Object,
			default: null
		},
		unit: {
			type: String,
			default: ""
		},
		min: {
			type: [Number, String],
			default: 0
		},
		max: {
			type: [Number, String],
			default: 0
		}
	},

	computed: {
		getElectricityPercentage() {
			const calculatePercent = (((Number(this.valueForPercentage) - Number(this.min)) * 100) / (Number(this.max) - Number(this.min))) ?? 0;
			if (calculatePercent > 100) return 100;
			else if (calculatePercent < 0) return 0;
			return calculatePercent;
		},
		kw() {
			if (this.empty) {
				return "n/a";
			}
			return parseAddCommaWhenIsNumber(this.kilowatt);
		},
		weatherIcon() {
			return getWeatherIcon(this.weather.icon);
		},
		showWeatherIcon() {
			return this.weather && this.weather.icon;
		}
	},

	watch: {
		value(newVal) {
			this.setPointer(parseToTypeNumber(newVal));
		}
	},

	mounted() {
		this.setPointer(parseToTypeNumber(this.getElectricityPercentage));
	},

	methods: {
		// TODO: put this function to helper and add some unit test
		setPointer(value = 0) {
			const angleStart = 210;
			const angleEnd = 330;
			// maxLength are length number between 210 to 330 degrees
			const maxLength = (360 - angleEnd) + angleStart; // 240
			const angleReduceLength = (maxLength * value) / 100;
			let angle = angleStart - angleReduceLength;
			if (angle <= 0) angle += 360;
			/* end of calculate an angle */
			const cx = 45;
			const cy = 45;
			const radius = 41;
			const radians = -((Math.PI / 180) * angle);
			const x = (Math.cos(radians) * radius) + cx;
			const y = (Math.sin(radians) * radius) + cy;
			/* end of calculate x and y to draw a line position */
			const pointerEle = this.$refs.pointer;
			pointerEle.setAttribute("x2", x);
			pointerEle.setAttribute("y2", y);
		}
	}
};
</script>

<style lang="scss" scoped>
.container-power {
	$small-width: rem(58);
	$small-height: rem(58);
	$medium-width: rem(73);
	$medium-height: rem(73);
	$large-width: rem(130);
	$large-height: rem(130);

	position: relative;
	width: $small-width;
	height: $small-height;

	&.small {
		width: $small-width;
		height: $small-height;

		.detail {
			bottom: rem(0);

			.value {
				@include fontSub();

				height: rem(34);
				font-size: $font-14;
				line-height: rem(36);
			}

			.unit {
				@include fontBase();

				height: rem(14);
				font-size: $font-12;
				line-height: rem(16);
				margin-top: rem(-14);
			}
		}
	}

	&.medium {
		width: $medium-width;
		height: $medium-height;

		.detail {
			bottom: rem(0);

			.value {
				@include fontSub();

				height: rem(38);
				font-size: $font-18;
				line-height: rem(40);
			}

			.unit {
				@include fontBase();

				height: rem(18);
				font-size: $font-14;
				line-height: rem(20);
				margin-top: rem(-14);
			}
		}
	}

	&.large {
		width: $large-width;
		height: $large-height;

		.detail {
			bottom: rem(10);

			.value {
				@include fontSub();

				height: rem(46);
				font-size: $font-32;
				line-height: rem(48);
			}

			.unit {
				@include fontBase();

				height: rem(26);
				font-size: $font-24;
				line-height: rem(28);
				margin-top: rem(-15);
			}
		}
	}

	.detail {
		position: absolute;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		.value {
			@include fontSub();

			font-weight: 600;
			color: $color-dark-blue-grey;
		}

		.unit {
			@include fontBase();

			font-weight: 600;
			color: $color-grey-4;
		}
	}

	.icon {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.weather {
			position: absolute;
			top: rem(-16);
			right: rem(-9);
		}
	}
}
</style>