/**
 * Enum for customer role.
 * @readonly
 * @enum {EnumCustomerRole}
 */

export const CUSTOMER_STATUS = Object.freeze({
	ACTIVE: "active",
	INACTIVE: "inactive"
});

/**
 * Enum for customer roles label.
 * @readonly
 * @enum {EnumCustomerRoleLabel}
 */
export const CUSTOMER_STATUS_LABEL = Object.freeze({
	ACTIVE: "Active",
	INACTIVE: "Inactive"
});
