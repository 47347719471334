import { PAGE_NAME } from "../../enums/pagePermission";
import { SIDE_NAV } from "../../enums/sideNavs";

export const getMenuByUserRole = (item, user = {}) => {
	const permission = item.rolePermissions;
	let rolePermissions = false;

	if (permission) {
		user.rolePermissions.forEach((value) => {
			if (permission.includes(value)) {
				rolePermissions = permission.includes(value);
			}
		});
	}

	return rolePermissions;
};

export const getMenus = (menuActivedAt, routeName, { isFold = false, user = {}, permissions = {}, isVisibleExecutiveReportMenu }) => {
	return SIDE_NAV
		.map((item) => { // mapping menu details
			let children = item.children || [];
			const isNestedMenus = !!item.children;
			const isShowTitle = !isFold;

			if (isNestedMenus) {
				children = children.filter((c) => {
					return c.permission.includes(user.role);
				});
			}

			return {
				...item,
				isShowTitle,
				isNestedMenus,
				children
			};
		})
		.filter((item) => { // filter menus by user roles
			let permission = [];

			if (item.isNestedMenus) {
				permission = item.children.reduce((results, current) => {
					current.permission.forEach((p) => {
						results.push(p);
					});
					return results;
				}, []);
			} else {
				permission = item.permission;
			}

			return permission.includes(user.role) || getMenuByUserRole(item, user);
		})
		.filter((item) => { // filter menus by user permissions
			if (user.profile.type === "Customer") {
				if (item.name === PAGE_NAME.DASHBOARD) {
					return permissions.dashboard.read;
				} else if (item.name === PAGE_NAME.FLOWDIAGRAM_LIST) {
					return permissions.diagram.read;
				} else if (item.name === PAGE_NAME.INVOICE) {
					return permissions.invoice.read;
				} else if (item.name === PAGE_NAME.E_ORDERING_LIST) {
					return permissions.eOrdering.read;
				} else if (item.name === PAGE_NAME.POWERBI) {
					// filter have  some soldto have workspace&report ID Executive will show menu
					if (isVisibleExecutiveReportMenu) return permissions.powerBI.manage;
					return false;
				}
			}
			return true;
		})
		.map((item, index) => { // mapping menu and dropdown ui
			let active = false;
			if (menuActivedAt === null) {
				if (item.isNestedMenus) {
					active = item.children.map((sub) => {
						return sub.name;
					}).includes(routeName);
				} else if (item.relationRoutes && item.relationRoutes.length) {
					active = item.relationRoutes.includes(routeName) || item.name === routeName;
				} else {
					active = item.name === routeName;
				}
			} else {
				active = menuActivedAt === index;
			}

			let children = item.children;
			if (item.isNestedMenus && item.children.length) {
				children = children.map((sub) => {
					return {
						...sub,
						active: sub.name === routeName ||
                                ((sub.childrenRoutes && sub.childrenRoutes.includes(routeName)) || false)
					};
				});
			}

			return {
				...item,
				active,
				children
			};
		});
};

export const getMenuActiveAt = (filterMenus = [], routeName = "") => {
	for (let i = 0; i < filterMenus.length; i++) {
		if (filterMenus[i].active) { return i; }
		if (
			filterMenus[i].isNestedMenus &&
            filterMenus[i].children.length
		) {
			for (let j = 0; j < filterMenus[i].children.length; j++) {
				if (
					filterMenus[i].children[j].childrenRoutes &&
                    filterMenus[i].children[j].childrenRoutes.includes(routeName)
				) { return i; }
			}
		}
	}
	return -1;
};