/**
 * Parse overall monitor
 * @param {Object} input overall data
 * @returns {OverallMonitor}
 */
export function parseOverallMonitor(input) {
	if (!input) {
		return {};
	}

	return {
		totalSignIn: {
			thisMonth: input?.totalSignIn?.thisMonth,
			thisYear: input?.totalSignIn?.thisYear,
			total: input?.totalSignIn?.total
		},
		totalUsers: {
			thisMonth: input?.totalUsers?.thisMonth,
			thisYear: input?.totalUsers?.thisYear,
			total: input?.totalUsers?.total,
			activeUser: input?.totalUsers?.activeUser
		},
		totalActivities: {
			thisMonth: input?.totalActivities?.thisMonth,
			thisYear: input?.totalActivities?.thisYear,
			total: input?.totalActivities?.total
		}
	};
}