import marketplace from "../../views/Marketplace/index.vue";
import { PAGE_NAME } from "../../enums/pagePermission";

const { MARKETPLACE } = PAGE_NAME;

export default {
	path: "/marketplace",
	name: MARKETPLACE,
	component: marketplace,
	meta: {
		requireAuth: true
	}
};