import { BIG_STORE_OUR_SERVICE_CREATE_REQUEST,
	BIG_STORE_OUR_SERVICE_CREATE_SUCCESS,
	BIG_STORE_OUR_SERVICE_CREATE_FAILURE,
	BIG_STORE_OUR_SERVICE_REQUEST,
	BIG_STORE_OUR_SERVICE_SUCCESS,
	BIG_STORE_OUR_SERVICE_FAILURE,
	BIG_STORE_OUR_SERVICE_EDIT_REQUEST,
	BIG_STORE_OUR_SERVICE_EDIT_SUCCESS,
	BIG_STORE_OUR_SERVICE_EDIT_FAILURE,
	BIG_STORE_OUR_SERVICE_UPDATE_REQUEST,
	BIG_STORE_OUR_SERVICE_UPDATE_SUCCESS,
	BIG_STORE_OUR_SERVICE_UPDATE_FAILURE,
	BIG_STORE_OUR_SERVICE_DELETE_REQUEST,
	BIG_STORE_OUR_SERVICE_DELETE_SUCCESS,
	BIG_STORE_OUR_SERVICE_DELETE_FAILURE } from "../../../store/types";
import { postOurServiceCreateAPI, getOurServiceByIdAPI, getServicesAPI, putOurServiceUpdateAPI, deleteOurServiceAPI } from "../../../services/api/bigStoreManagement"; // getServicesAPI
import { parseOurServices } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		data: [],
		file: [],
		isLoading: false,
		ourServiceCreateSuccess: false,
		ourServiceUpdateSuccess: false,
		ourServiceDeleteSuccess: false,
		errors: {},
		isEmpty: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[BIG_STORE_OUR_SERVICE_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BIG_STORE_OUR_SERVICE_SUCCESS](state, { data, pagination }) {
			state.data = parseOurServices(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[BIG_STORE_OUR_SERVICE_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[BIG_STORE_OUR_SERVICE_CREATE_REQUEST](state) {
			state.isLoading = true;
			state.ourServiceCreateSuccess = false;
		},
		[BIG_STORE_OUR_SERVICE_CREATE_SUCCESS](state, data) {
			state.data = data;
			state.isLoading = false;
			state.ourServiceCreateSuccess = true;
		},
		[BIG_STORE_OUR_SERVICE_CREATE_FAILURE](state, errors) {
			state.errors = errors;
			state.ourServiceCreateSuccess = false;
		},
		[BIG_STORE_OUR_SERVICE_UPDATE_REQUEST](state) {
			state.isLoading = true;
			state.ourServiceUpdateSuccess = false;
		},
		[BIG_STORE_OUR_SERVICE_UPDATE_SUCCESS](state, { data, status }) {
			state.data = data;
			state.isLoading = false;
			state.ourServiceUpdateSuccess = status === 204;
		},
		[BIG_STORE_OUR_SERVICE_UPDATE_FAILURE](state, errors) {
			state.errors = errors;
			state.ourServiceUpdateSuccess = false;
		},
		[BIG_STORE_OUR_SERVICE_EDIT_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BIG_STORE_OUR_SERVICE_EDIT_SUCCESS](state, { data, pagination }) {
			state.data = parseOurServices(data);
			state.isEmpty = data.length === 0;
			state.pagination = pagination;
			state.isLoading = false;
		},
		[BIG_STORE_OUR_SERVICE_EDIT_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[BIG_STORE_OUR_SERVICE_DELETE_REQUEST](state) {
			state.isLoading = true;
			state.ourServiceDeleteSuccess = false;
		},
		[BIG_STORE_OUR_SERVICE_DELETE_SUCCESS](state, { status }) {
			state.isLoading = false;
			state.ourServiceDeleteSuccess = status === 200;
		},
		[BIG_STORE_OUR_SERVICE_DELETE_FAILURE](state, errors) {
			state.errors = errors;
			state.ourServiceDeleteSuccess = false;
		}

	},

	actions: {
		async sendOurServiceDeleteAPI({ commit }, id) {
			try {
				commit(BIG_STORE_OUR_SERVICE_DELETE_REQUEST);
				const data = await deleteOurServiceAPI(id);
				commit(BIG_STORE_OUR_SERVICE_DELETE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_OUR_SERVICE_DELETE_FAILURE, errors);
			}
		},
		async sendOurServiceUpdate({ commit }, payload) {
			try {
				commit(BIG_STORE_OUR_SERVICE_UPDATE_REQUEST);
				const data = await putOurServiceUpdateAPI(payload);
				commit(BIG_STORE_OUR_SERVICE_UPDATE_SUCCESS, data);
			} catch (e) {
				const { errors } = e.response.data;
				commit(BIG_STORE_OUR_SERVICE_UPDATE_FAILURE, errors);
			}
		},
		async sendCreateOurService({ commit }, payload) {
			try {
				commit(BIG_STORE_OUR_SERVICE_CREATE_REQUEST);
				const { data } = await postOurServiceCreateAPI(payload);
				commit(BIG_STORE_OUR_SERVICE_CREATE_SUCCESS, data);
			} catch (e) {
				const { errors } = e;
				commit(BIG_STORE_OUR_SERVICE_CREATE_FAILURE, errors);
			}
		},
		async fetchOurServiceList({ commit }, params = {}) {
			try {
				commit(BIG_STORE_OUR_SERVICE_REQUEST);
				const { data } = await getServicesAPI(params);

				commit(BIG_STORE_OUR_SERVICE_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_OUR_SERVICE_FAILURE);
			}
		},
		async fetchOurServiceById({ commit }, id) {
			try {
				commit(BIG_STORE_OUR_SERVICE_EDIT_REQUEST);
				const { data } = await getOurServiceByIdAPI({ page: 1, serviceId: id });
				commit(BIG_STORE_OUR_SERVICE_EDIT_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_OUR_SERVICE_EDIT_FAILURE);
			}
		}
	}
};
