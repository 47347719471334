import { getO2AerationOxygenDissolveAPI } from "@/services/api/o2Aeration.api";
import {
	O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_REQUEST,
	O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_SUCCESS,
	O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_FAILURE
} from "../../types";
import { transformFlowRateLineChartData } from "../../../selectors/transform/lineChart/transformLineChart";

export default {
	namespaced: true,

	state: {
		data: [],
		isLoading: false,
		isEmpty: false
	},

	getters: {
		getTransformOxygenDissolve: (state) => {
			return transformFlowRateLineChartData(state);
		}

	},

	mutations: {
		[O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_REQUEST](state) {
			state.data = [];
			state.isLoading = true;
			state.isEmpty = false;
		},
		[O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_SUCCESS](state, response) {
			state.data = response.data;
			state.isEmpty = response.data.length === 0;
			state.lastUpdate = response.updatedAt;
			state.isLoading = false;
		},
		[O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
			state.isEmpty = true;
		}
	},

	actions: {
		async getOxygenDissolve({ commit }, { id, query = {} }) {
			try {
				commit(O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_REQUEST);
				const { data } = await getO2AerationOxygenDissolveAPI(id, query);
				commit(O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_SUCCESS, data);
			} catch (error) {
				commit(O2_AERATION_OXYGEN_DISSOLVE_LINE_CHART_FAILURE, error);
			}
		}
	}
};
