<template>
	<div class="device-container">
		<router-link :to="deviceLink" :class="['container-detail', mainClass, iconSize]">
			<div class="container-detail-icon">
				<div class="wrapper-icon">
					<BulkHydrogenIcon
						:key="data.shipId"
						:status="data.levelStatus"
						:value="data.currentLevel"
						:percentage="data.tankPercent"
						:size="iconSize"
					/>
				</div>
			</div>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div v-if="getDeviceID" class="container-detail-info-device-id">
					<span>
						Device ID
					</span>
					<span>
						{{ getDeviceID }}
					</span>
				</div>
			</div>
		</router-link>
		<div class="pin-right" >
			<img @click="onClickSync" src="/assets/icons/icons-default-font-small@2x.png">
			<BaseTag v-if="data.deliveryStatus" :class="tagClass" size="small">
				{{ status }}
			</BaseTag>
		</div>
	</div>

</template>

<script>
import BulkHydrogenIcon from "@/components/deviceIcons/BulkHydrogenIcon.vue";
import { MODULE_DEVICE_STATUSES, TANK_STATUS_CLASSES } from "../../../../enums/modules";

export default {
	name: "TankDetail",

	components: {
		BulkHydrogenIcon
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	methods: {
		onClickSync() {
			this.$emit("onClickSync", this.data);
		}
	},

	computed: {
		mainClass() {
			return TANK_STATUS_CLASSES[this.data.levelStatus || "EMPTY"];
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		},

		getDeviceID() {
			if (this.data.deviceCustomerName) {
				return this.data.shipId;
			}
			return false;
		},

		tagClass() {
			const status = this.data.deliveryStatus;

			return status
				? MODULE_DEVICE_STATUSES[status].tagClass
				: null;
		},
		status() {
			return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].title;
		}
	}
};
</script>

<style lang="scss" scoped>
.device-container {
	position: relative;

	.pin-right {
		position: absolute;
		top: rem(8);
		right: rem(8);

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		gap: rem(8);
		height: 100%;
		cursor: pointer;

		img {
			width: rem(24);
			height: rem(24);
		}

		.tag {
			margin-right: rem(8);
		}
	}
}

.container-detail {
	@include fontBase();

	position: relative;
	display: flex;
	align-items: flex-start;
	padding-right: rem(11);
	margin-bottom: rem(16);
	border-radius: 4px;
	border: 1px solid $color-silver;
	height: rem(120);

	&:hover {
		background-color: $color-ice-two;

		/* stylelint-disable */
		&::v-deep .tank-icon-bg {
			background-image: url("/assets/images/h-tank-icon.svg");
		}
		/* stylelint-enable */
	}

	&.status-red {
		border: 1px solid $color-red;
	}

	&.status-orange {
		border: 1px solid $color-dull-orange;
	}

	&.status-yellow {
		border: 1px solid $color-mustard;
	}

	&.smallest > &-icon {
		width: rem(64);
		height: rem(64);
	}

	&-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(8);
		margin-right: rem(12);
		margin-left: rem(16);

		.wrapper-icon {
			margin-top: rem(16);
			margin-left: rem(5);
		}
	}

	&-info {
		flex: 1;
		margin-top: rem(16);

		&-shipid {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		&-device-id {
			display: flex;
			align-items: center;
			height: rem(14);
			font-size: $font-14;
			color: $color-dark-blue-grey;
			line-height: rem(16);
			margin-top: rem(4);

			span {
				&:first-of-type {
					display: flex;
					width: rem(100);
				}

				&:last-of-type {
					flex: 1;
					font-weight: $font-weight-bold;
					text-align: right;
				}
			}
		}
	}
}
</style>