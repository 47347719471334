import { getMarketplacesAPI, getMarketplaceModuleDetailAPI, getMarketplaceGroupModuleDetailAPI } from "../../../services/api/bigStoreManagement";
import { parseMarketplacelist, parseMarketplaceModuleDetail, parseMarketplaceGroupModuleDetail } from "../../../services/api/transforms/parseModules";

import {
	BIG_STORE_REQUEST,
	BIG_STORE_SUCCESS,
	BIG_STORE_FAILURE,
	BIG_STORE_MODULE_DETAIL_REQUEST,
	BIG_STORE_MODULE_DETAIL_SUCCESS,
	BIG_STORE_MODULE_DETAIL_FAILURE,
	BIG_STORE_GROUP_DETAIL_REQUEST,
	BIG_STORE_GROUP_DETAIL_SUCCESS,
	BIG_STORE_GROUP_DETAIL_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: {},
		moduleDetail: {},
		groupDetail: {},
		isEmptyModuleDetail: false,
		isLoadingModuleDetail: false,
		isEmptyGroupDetail: false,
		isLoadingGroupDetail: false,
		isLoading: false,
		isEmpty: false
	},
	mutations: {
		[BIG_STORE_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.isEmpty = false;
		},
		[BIG_STORE_SUCCESS](state, data) {
			state.data = parseMarketplacelist(data);
			state.isEmpty = data.length === 0;
			state.isLoading = false;
		},
		[BIG_STORE_FAILURE](state) {
			state.data = {};
			state.isLoading = false;
			state.isEmpty = true;
			state.pagination = {
				currentPage: 0,
				total: 0
			};
		},
		[BIG_STORE_MODULE_DETAIL_REQUEST](state) {
			state.moduleDetail = {};
			state.isLoadingModuleDetail = true;
			state.isEmptyModuleDetail = false;
		},
		[BIG_STORE_MODULE_DETAIL_SUCCESS](state, data) {
			state.moduleDetail = parseMarketplaceModuleDetail(data);
			state.isLoadingModuleDetail = false;
		},
		[BIG_STORE_MODULE_DETAIL_FAILURE](state) {
			state.moduleDetail = {};
			state.isLoadingModuleDetail = false;
			state.isEmptyModuleDetail = true;
		},
		[BIG_STORE_GROUP_DETAIL_REQUEST](state) {
			state.isLoadingGroupDetail = true;
			state.isEmptyGroupDetail = false;
		},
		[BIG_STORE_GROUP_DETAIL_SUCCESS](state, data) {
			state.groupDetail = parseMarketplaceGroupModuleDetail(data);
			state.isLoadingGroupDetail = false;
		},
		[BIG_STORE_GROUP_DETAIL_FAILURE](state) {
			state.groupDetail = {};
			state.isLoadingGroupDetail = false;
			state.isEmptyGroupDetail = true;
		}
	},

	actions: {
		async fetchMarketplaceList({ commit }, params = {}) {
			try {
				commit(BIG_STORE_REQUEST);

				const { data } = await getMarketplacesAPI(params);

				commit(BIG_STORE_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_FAILURE);
			}
		},
		async fetchMarketplaceModuleDetail({ commit }, params = {}) {
			try {
				commit(BIG_STORE_MODULE_DETAIL_REQUEST);

				const { data } = await getMarketplaceModuleDetailAPI(params);

				commit(BIG_STORE_MODULE_DETAIL_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_MODULE_DETAIL_FAILURE);
			}
		},
		async fetchMarketplaceGroupModuleDetail({ commit }, params = {}) {
			try {
				commit(BIG_STORE_GROUP_DETAIL_REQUEST);

				const { data } = await getMarketplaceGroupModuleDetailAPI(params);

				commit(BIG_STORE_GROUP_DETAIL_SUCCESS, data);
			} catch (e) {
				commit(BIG_STORE_GROUP_DETAIL_FAILURE);
			}
		}
	}
};
