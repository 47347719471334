<template>
	<div>
		<BaseLoading
			v-if="loading"
		/>
		<BaseNoResult
			v-else-if="items.length === 0"
			description="Try adjusting your search by changing your ship-to name."
		/>
		<template
			v-else
		>
			<SoldToListItem
				v-for="(item, index) in items"
				:key="index"
				:item="item"
				@powerBISave="handlePowerBISave"
			/>
		</template>
	</div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";
import BaseNoResult from "@/components/BaseNoResult.vue";
import SoldToListItem from "@/components/SoldToManagement/SoldToListItem.vue";

export default {
	components: {
		BaseLoading,
		BaseNoResult,
		SoldToListItem
	},

	props: {
		loading: {
			type: Boolean,
			default: false
		},
		items: {
			type: Array,
			default: () => ([])
		}
	},

	methods: {
		async handlePowerBISave(data = {}) {
			this.$emit("powerBISave", data);
		}
	}
};
</script>