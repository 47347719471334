<template>
	<BaseLayout :isLoading="isLoading">
		<BaseBackBtn label="Back to Dashboard" />
		<BaseBreadcrumb :item="breadcrumb" class="has-dot-separator" />
		<ModuleHeader :data="sorInfo.data" :fetch.sync="fetchDevices" />
		<div class="main-content">
			<ul class="nav nav-tabs">
				<template v-for="item in navTabData">
					<li
						v-if="item.permissions"
						:key="item.index"
						class="nav-item"
					>
						<router-link
							:to="{ name: item.routerName }"
							active-class="active"
							class="nav-link"
						>
							{{ item.title }}
						</router-link>
					</li>
				</template>
			</ul>
			<router-view
				@loaded="handleContentLoaded"
			/>
		</div>
	</BaseLayout>
</template>


<script>
import { mapState, mapActions } from "vuex";
import BaseBreadcrumb from "@/components/BaseBreadcrumb.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModuleHeader from "@/components/dashboard/modules/ModuleHeader.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { CONFIG } from "../../enums/config";
import userPermission from "../../mixins/userPermission";

export default {
	mixins: [userPermission],
	components: {
		BaseBreadcrumb,
		BaseBackBtn,
		ModuleHeader
	},

	data() {
		return {
			PAGE_NAME,
			id: this.$route.params.id,
			isLoading: true,
			fetchDevices: () => {}
		};
	},

	computed: {
		...mapState(["sorInfo", "shipTos"]),
		breadcrumb() {
			return {
				type: {
					name: "Solar cell"
				},
				shiped: {
					name: this.shipTos.data.name
				}
			};
		},
		shipToId() {
			return this.sorInfo.data.shipToId || null;
		},
		navTabData() {
			return [
				{
					title: "Meter information",
					routerName: PAGE_NAME.SOR_INFO,
					permissions: true
				},
				{
					title: "History",
					routerName: PAGE_NAME.SOR_HISTORY,
					permissions: true
				},
				{
					title: "Graph kWh",
					routerName: PAGE_NAME.SOR_KWH,
					permissions: true
				},
				{
					title: "Analysis",
					routerName: PAGE_NAME.SOR_POWERBI,
					permissions: this.permissions.powerBI.read && this.hasPowerBiReport
				}
			];
		},
		hasPowerBiReport() {
			return this.sorInfo.data.reportId && this.sorInfo.data.workspaceId;
		}
	},

	async created() {
		await this.getSorInfo({ id: this.id });

		this.autoRefreshData = setInterval(async () => {
			if (!this.sorInfo.isAutoFecthLoading) {
				await this.autoGetSorInfo({ id: this.id });
			}
		}, CONFIG.AUTO_REFRESH_DATA);

		if (this.$route.name === PAGE_NAME.SOR_INFO) {
			this.addActivityLogShipToView({
				shopToId: this.id,
				httpStatus: this.sorInfo.statusCode
			});
		}
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);
	},

	watch: {
		async shipToId(id) {
			if (id) {
				await this.getShipTo(id);
				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions("shipTos", ["getShipTo"]),
		...mapActions("sorInfo", {
			getSorInfo: "getSorInfo",
			clearSorInfo: "clearSorInfo",
			autoGetSorInfo: "autoGetSorInfo"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogShipToView: "addActivityLogShipToView"
		}),
		handleContentLoaded() {
			this.fetchDevices();
		}
	},

	destroyed() {
		this.clearSorInfo();
	}
};
</script>
