import { getZldLastMonthAPI } from "@/services/api/Zlds.api";
import {
	ZLD_LAST_MONTH_REQUEST,
	ZLD_LAST_MONTH_SUCCESS,
	ZLD_LAST_MONTH_FAILURE,
	ZLD_LAST_MONTH_CLEAR
} from "../../types";
import { parseZldLastMonth } from "../../../services/api/transforms/parseModules";
import { hasKeys } from "../../../selectors/helpers";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		statusCode: null
	},

	getters: {
		// this function check keys in data, it must has following keys.
		// notes: data will be only an empty object by default
		isEmpty: ({ data }) => {
			const keys = [
				"date",
				"beginning",
				"ending",
				"netOutlet"
			];
			return !hasKeys(data, keys);
		}
	},

	mutations: {
		[ZLD_LAST_MONTH_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[ZLD_LAST_MONTH_SUCCESS](state, { data, status }) {
			state.data = parseZldLastMonth(data);
			state.isLoading = false;
			state.statusCode = status;
		},
		[ZLD_LAST_MONTH_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[ZLD_LAST_MONTH_CLEAR](state) {
			state.data = {};
			state.isLoading = false;
		}
	},

	actions: {
		async getZldLastMonth({ commit }, { id }) {
			try {
				commit(ZLD_LAST_MONTH_REQUEST);

				const response = await getZldLastMonthAPI(id);

				commit(ZLD_LAST_MONTH_SUCCESS, response);
			} catch (error) {
				commit(ZLD_LAST_MONTH_FAILURE, error);
			}
		},

		clearZldLastMonth({ commit }) {
			commit(ZLD_LAST_MONTH_CLEAR);
		}
	}
};
