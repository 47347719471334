import axios from "axios";
import { interceptorResponse, interceptorResponseError } from "./interceptors/response";

const httpClient = axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URL,
	timeout: 60000,
	headers: {
		"Content-Type": "application/json"
	},
	withCredentials: true
});

httpClient.interceptors.response.use(interceptorResponse, interceptorResponseError);

export default httpClient;