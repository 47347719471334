<template>
	<div class="no-gutter-top">
		<BaseLoading v-if="tankInfo.isLoading" />

		<div :class="[{'row row-eq-height': isShowShipTo}, { 'hide-content': tankInfo.isLoading }]">
			<div :class="['col-12 no-gutter-sm', { 'col-xl-7': isShowShipTo }]">
				<BaseCard>
					<template v-slot:header>
						<h4>Overview</h4>
						<div class="current-time">
							Last update: {{ tankInfo.data.updatedAt }}
						</div>
						<BaseDropdown
							:value="unitValue"
							:list="filterOptions.dropdown.items"
							:searchable="false"
							size="small"
							class="custom-unit-dropdown"
							@input="handleUnitDropdownSelected"
						>
							<template #prepend>
								Unit :
							</template>
						</BaseDropdown>
					</template>
					<template v-slot:body>
						<ModuleOverview
							:data="tankInfo.data"
							:unit="unitValue"
						/>
					</template>
				</BaseCard>
			</div>
			<ModuleCustomerInfo :info="tankInfo.data" />
		</div>

		<div class="tank-level-container">
			<div class="detail-card-header">
				<div v-if="showLastUpdate" class="last-update">
					Last update: {{ tankLevel.lastUpdate | dateTimeFormat  }}
				</div>
				<BaseDatePicker
					v-model="pickerValue"
					:min-date="tankFilterOptions.date.picker.minDate"
					:max-date="tankFilterOptions.date.picker.maxDate"
					:mode="tankFilterOptions.date.picker.mode"
					size="small"
					position="right"
					class="custom-date-picker"
					@done="handleDatePickerSelected"
				/>
				<span>
					{{ tankFilterOptions.date.condition || "or" }}
				</span>
				<BaseDropdown
					:value="dropdownValue"
					:list="tankFilterOptions.date.dropdown.items"
					:disabledItem="tankFilterOptions.date.dropdown.disabledItems"
					:searchable="false"
					size="small"
					:placeholder="`${tankFilterOptions.date.dropdown.placeholder || 'View by'}`"
					class="custom-date-dropdown"
					@input="handleDateDropdownSelected"
				/>
			</div>
			<CardLineChart
				v-if="isShowLevel"
				:chartData="getLineChartTankLevelConfig"
				:isLoading="tankLevel.isLoading"
				:isEmpty="tankLevel.isEmpty"
				chartTitle="Tank level"
				type="tank"
			/>
			<!-- Gas consumption rate graph -->
			<CardLineChart
				:chartData="getLineChartGasConsumptionConfig"
				:isLoading="tankGasConsumptionRate.isLoading"
				:isEmpty="tankGasConsumptionRate.isEmpty"
				chartTitle="Gas consumption rate"
				type="tank"
			/>

		</div>
		<!-- Gas consumption graph -->
		<CardBarChart
			class="gas-consumption-container"
			:chartData="getBarChartGasConsumptionData"
			:manualUpdate="false"
			:isLoading="tankGasConsumption.isLoading"
			:isEmpty="tankGasConsumption.isEmpty"
			:filterOptions="gasConsumptionFilterOptions"
			:lastUpdate="tankGasConsumption.lastUpdate"
			chartTitle="Gas consumption"
			type="gas-consumption"
			@onSelectDatePicker="handleDateGasConsumptionSelectDateRange"
			@onSelectDropDown="handleDateGasConsumptionSelectDateDropDown"
		/>
		<GrafanaDisplay
			:deviceId="tankId"
		/>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import dayjs from "dayjs";
import GrafanaDisplay from "@/components/grafanaManagement/GrafanaDisplay.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import CardBarChart from "@/components/chart/CardBarChart.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleCustomerInfo from "@/components/dashboard/modules/ModuleCustomerInfo.vue";
import { transformTankLevelLineChartConfig } from "../../selectors/transform/lineChart/TankInfo/tankLineChart";
import { transformTankConsumptionLineChartConfig } from "../../selectors/transform/lineChart/TankInfo/tankGasConsumptionLineChart";
import { transformTankConsumptionBarChartConfig } from "../../selectors/transform/barChart/TankInfo/transformBarChart";
import { MODULE_SORT_DATE_CHART, SORT_DATE_TYPE } from "../../enums/filterChart";
import { UNIT_TYPE, UNIT_DISPLAY_TYPE } from "../../enums/unit";
import { getDateNumber, getDateDiff } from "../../selectors/helpers";
import setupDevice from "../../mixins/setupDevice";

const {
	perDay,
	perThreeDay,
	perWeek,
	perTwoWeek,
	perThirtyDays,
	perNinetyDays,
	perSixMonth,
	perYear
} = MODULE_SORT_DATE_CHART;

export default {
	name: "tankInfo",

	components: {
		GrafanaDisplay,
		BaseLoading,
		BaseCard,
		CardLineChart,
		CardBarChart,
		ModuleOverview,
		ModuleCustomerInfo,
		BaseDatePicker
	},
	mixins: [setupDevice],
	data() {
		return {
			tankId: this.$route.params.id,
			sortBy: perDay,
			unitValue: "",
			pickerValue: null,
			dropdownValue: null,
			filterOptions: {
				dropdown: {
					items: [
						{
							label: UNIT_DISPLAY_TYPE.NM3,
							value: UNIT_TYPE.NM3
						},
						{
							label: UNIT_DISPLAY_TYPE.SM3,
							value: UNIT_TYPE.SM3
						},
						{
							label: UNIT_DISPLAY_TYPE.KG,
							value: UNIT_TYPE.KG
						},
						{
							label: UNIT_DISPLAY_TYPE.LITER,
							value: UNIT_TYPE.LITER
						}
					]
				}
			},
			tankFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(90 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 1 month",
								value: perThirtyDays
							},
							{
								label: "Last 3 months",
								value: perNinetyDays
							}
						]
					}
				}
			},
			gasConsumptionFilterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(14, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(1, "year").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 14 days",
								value: perTwoWeek
							},
							{
								label: "Last 30 days",
								value: perThirtyDays
							},
							{
								label: "Last 90 days",
								value: perNinetyDays
							},
							{
								label: "Last 6 months",
								value: perSixMonth
							},
							{
								label: "Last 1 year",
								value: perYear
							}
						]
					}
				}
			},
			gasConsumptionSortBy: perTwoWeek,
			device: "mobile"
		};
	},

	computed: {
		...mapState(["tankInfo", "tankLevel", "tankGasConsumption", "tankGasConsumptionRate"]),

		...mapGetters({
			getTankLevelData: "tankLevel/getTankLevelData",
			getLevelStateData: "tankLevel/getLevelStateData",
			getForecastMarkAreaData: "tankLevel/getForecastMarkAreaData",
			getLayout: "tankInfo/getLayout",
			getTankGasConsumptionData: "tankGasConsumption/getTankGasConsumptionData",
			getTankGasConsumptionRateData: "tankGasConsumptionRate/getTankGasConsumptionRateData"
		}),

		isShowShipTo() {
			return this.getLayout.shipToLocation;
		},

		isShowLevel() {
			return this.getLayout.level;
		},

		isShowDelivery() {
			return this.getLayout.delivery;
		},

		tankLevelSelected() {
			const dateRange = this.tankFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},

		getLineChartTankLevelConfig() {
			const sortBy = this.sortBy;
			const { diff } = this.tankLevelSelected;

			return transformTankLevelLineChartConfig(sortBy, this.getTankLevelData, {
				markLine: [
					{
						label: "Re-order",
						color: "#ffcb00",
						value: this.getLevelStateData.reorderLevel
					},
					{
						label: "Re-fill",
						color: "#ea7125",
						value: this.getLevelStateData.refillLevel
					},

					{
						label: "Empty",
						color: "#e03431",
						value: this.getLevelStateData.emptyLevel
					},
					{
						label: "Forecast",
						color: "#dfece2",
						value: this.getForecastMarkAreaData
					}],
				diffDay: diff,
				markArea: this.getForecastMarkAreaData
			}, this.isMobile);
		},
		getLineChartGasConsumptionConfig() {
			const sortBy = this.sortBy;
			const { diff } = this.tankLevelSelected;

			return transformTankConsumptionLineChartConfig(sortBy, this.getTankGasConsumptionRateData(this.unitValue), {
				diffDay: diff
			}, this.unitValue, this.isMobile);
		},
		gasConsumptionSelected() {
			const dateRange = this.gasConsumptionFilterOptions.date.selected.range;
			const dateNumber = getDateNumber(dateRange);
			return {
				dateNumber,
				dateRange,
				diff: getDateDiff(dateRange)
			};
		},
		getBarChartGasConsumptionData() {
			const sortBy = this.gasConsumptionSortBy;

			const dateReportFormat = [perTwoWeek, perThirtyDays, perNinetyDays].includes(sortBy) ? "day" : "year";
			const options = { dateReportFormat };

			return transformTankConsumptionBarChartConfig(sortBy, this.getTankGasConsumptionData(this.unitValue, options), this.unitValue, this.isMobile);
		},
		unitType() {
			return this.tankInfo.data.unit ? this.tankInfo.data.unit.toLowerCase() : "kg";
		},
		showLastUpdate() {
			return this.tankLevel.lastUpdate;
		}
	},

	async created() {
		this.unitValue = this.unitType;
		await this.fetchData();

		// add lastForcastDate into tankLevel Graph maxdate selectedRange when having Forcast Data
		if (this.tankLevel.lastForecastDate) {
			this.tankFilterOptions.date.picker.maxDate = dayjs(this.tankLevel.lastForecastDate).$d;
		}

		const tankRangeDate = this.tankFilterOptions.date.selected.range;

		if (tankRangeDate) {
			const dateNumber = getDateNumber(tankRangeDate);
			this.dropdownValue = dateNumber;
		}

		this.$emit("loaded", this.fetchData);
	},

	methods: {
		...mapActions({
			getTankLevel: "tankLevel/getTankLevel",
			getTankGasConsumption: "tankGasConsumption/getTankGasConsumption",
			getTankGasConsumptionRate: "tankGasConsumptionRate/getTankGasConsumptionRate"
		}),

		fetchData() {
			const { tankId } = this;
			return Promise.all([
				this.getTankLevel({ id: tankId, query: { filter: this.tankLevelSelected.dateNumber } }),
				this.getTankGasConsumptionRate({ id: tankId, query: { filter: this.tankLevelSelected.dateNumber } }),
				this.getTankGasConsumption({ id: tankId, query: { filter: this.gasConsumptionSelected.dateNumber } })
			]);
		},
		handleUnitDropdownSelected(value) {
			this.unitValue = value;
		},
		handleDatePickerSelected(date) {
			const { start, end } = date;

			this.tankFilterOptions.date.selected.range = {
				start,
				end
			};

			this.dropdownValue = null;
			this.sortBy = "";
			this.getTankLevel({
				id: this.tankId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
			this.getTankGasConsumptionRate({
				id: this.tankId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},
		handleDateDropdownSelected(dateNumber = "1d") {
			if (dateNumber) {
				if (!this.tankFilterOptions?.date.condition) {
					this.pickerValue = null;
				}
				this.dropdownValue = dateNumber;
				this.sortBy = dateNumber;
				this.getTankLevel({
					id: this.tankId,
					query: {
						filter: dateNumber
					}
				});
				this.getTankGasConsumptionRate({
					id: this.tankId,
					query: {
						filter: dateNumber
					}
				});
			}
		},
		handleDateGasConsumptionSelectDateRange(date) {
			const { start, end } = date;

			this.gasConsumptionFilterOptions.date.selected.range = {
				start,
				end
			};

			// count date range to filter dateReportFormat
			const { diff } = this.gasConsumptionSelected;

			this.gasConsumptionSortBy = diff > SORT_DATE_TYPE.perThreeMonth ? perYear : perTwoWeek;
			this.getTankGasConsumption({
				id: this.tankId,
				query: {
					startDate: dayjs(start).startOf("day").toISOString(),
					endDate: dayjs(end).endOf("day").toISOString()
				}
			});
		},
		handleDateGasConsumptionSelectDateDropDown(dateNumber = "2w") {
			if (dateNumber) {
				if (!this.gasConsumptionFilterOptions?.date.condition) {
					this.pickerValue = null;
				}
				this.gasConsumptionSortBy = dateNumber;
				this.getTankGasConsumption({
					id: this.tankId,
					query: {
						filter: dateNumber
					}
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.table-row-equal {
	display: table;
	width: 100%;
	border-spacing: rem(15) 0;
	word-wrap: break-word;
	margin-bottom: rem(34);

	@include tablet {
		display: block;
	}
}

.hide-content {
	visibility: hidden;
}

.row-eq-height,
.row-eq-height > [class^=col]:last-of-type {
	@include desktop-large {
		display: flex;
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
	flex-basis: 70%;
	text-align: right;
}

.card {
	width: 100%;
	height: calc(100% - rem(34));

	@include mobile {
		border-radius: 0;
	}
}

::v-deep {
	.custom-unit-dropdown {
		margin-left: rem(16);
		max-width: rem(120);

		@include mobile {
			margin-left: 0;
			margin-top: rem(12);
			max-width: 100%;
		}

		.dropdown-value {
			background-color: $color-white;

			.dropdown-placeholder,
			.dropdown-value-text {
				position: absolute;
				left: rem(50);
				top: 0;

				sup {
					line-height: rem(20);
				}
			}

			.prepend-icon {
				position: absolute;
			}
		}
	}

	.overview-info:nth-child(2) {
		border-bottom: solid 1px $color-silver;
		margin-bottom: rem(12);
	}

	.tank-level-container {
		background-color: $color-alabaster;
		border-radius: rem(8);
		padding: rem(16);
		margin-bottom: rem(16);

		@include mobile {
			border-radius: 0;
			margin-left: rem(-16);
			margin-right: rem(-16);
			padding: rem(16) 0;
		}

		.card {
			margin-bottom: rem(16);

			@include mobile {
				border-radius: 0;
			}

			&-body {
				background-color: $color-white;
				border-bottom-left-radius: rem(4);
				border-bottom-right-radius: rem(4);
			}
		}
	}

	.detail-card-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: rem(16);

		@include mobile {
			align-items: flex-start;
			flex-direction: column;
			padding: 0 rem(16);
		}

		& > * {
			margin-left: rem(10);

			@include mobile {
				margin-left: 0;
			}
		}

		span {
			color: $color-grey-4;
		}

		> span {
			@include mobile {
				display: none;
			}
		}

		.custom-date-picker {
			@include mobile {
				margin-bottom: rem(12);
			}
		}
	}

	.last-update {
		min-width: rem(170);
		font-family: $font-family-base;
		font-size: rem(18);
		font-style: italic;
		color: $color-dark-blue-grey;
		margin-right: rem(14);

		@include mobile {
			margin-right: 0;
		}
	}

	.custom-date-picker {
		min-width: rem(200);

		@include mobile {
			width: 100%;
		}

		.input {
			border-color: $color-silver;

			&, &::placeholder {
				color: $color-grey-4;
				font-size: $font-16;
				font-weight: $font-weight-bold;
			}
		}
	}

	.custom-date-dropdown {
		width: rem(130);

		@include mobile {
			width: 100%;
		}

		.dropdown-value {
			border-color: $color-silver;
			background-color: $color-white;
			padding: 0 rem(40) 0 rem(16);
		}

		.dropdown-value-text,
		.dropdown-placeholder {
			color: $color-grey-4;
			font-size: $font-16;
			font-weight: $font-weight-bold;
		}
	}

	.gas-consumption-container {
		@include mobile {
			margin-left: rem(-16);
			margin-right: rem(-16);
			padding: rem(16) 0;
		}

		.card {
			@include mobile {
				border-radius: 0;
			}
		}
	}
}

.no-gutter-top {
	margin-top: rem(-1);

	@include desktop-large {
		margin-top: 0;
	}
}
</style>
