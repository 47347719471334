<template>
	<div class="container-tooltip">
		<div class="label">
			<slot></slot>
		</div>
		<div :style="[{ width }]" :class="['container-tooltip-popover', align]">
			<slot name="message">
				{{ message }}
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseTooltip",

	props: {
		message: {
			type: String,
			default: ""
		},
		width: {
			type: String,
			default: "260px"
		},
		align: {
			type: String,
			default: "right"
		}
	}
};
</script>

<style lang="scss" scoped>
.container-tooltip {
	position: relative;

	.label:hover ~ &-popover {
		display: block;
	}

	&-popover {
		@include fontBase();

		display: none;
		position: absolute;
		z-index: $z-index-tooltip;
		bottom: rem(4);
		transform: translate(100%, 100%);
		padding: rem(8);
		background-color: $sidebar-bg-color;
		border-radius: 4px;
		box-shadow: $box-shadow-tooltip;
		color: $color-dark-blue-grey;
		font-size: $font-18;
		font-weight: $font-weight-regular;

		&.left {
			left: 0;
			transform: translate(0, 100%);
		}

		&.right {
			right: rem(4);
		}
	}
}
</style>