/**
 * Enum for customer role.
 * @readonly
 * @enum {EnumCustomerRole}
 */

export const CUSTOMER_ROLES = Object.freeze({
	POWERUSER: "POWER_USER",
	PROCUREMENT: "PROCUREMENT",
	USER: "USER",
	LEVEL_2_USER: "LEVEL_2_USER",
	CONTACT: "CONTACT",
	ORDER: "ORDER"
});

/**
 * Enum for customer roles label.
 * @readonly
 * @enum {EnumCustomerRoleLabel}
 */
export const CUSTOMER_ROLES_LABEL = Object.freeze({
	POWERUSER: "Power User",
	PROCUREMENT: "Procurement",
	USER: "User",
	LEVEL_2_USER: "Level 2 User",
	CONTACT: "Contact",
	ORDER: "Order"
});
