<template>
	<div
		:class="[
			'control',
			controlOptions,
			errorClass,
			loadingClass, { 'in-line':inline },
			{ 'max-length': maxLength }
		]"
	>
		<label v-if="isShowLabel" class="input-label">
			{{ label }}
		</label>
		<editor
			ref="editor"
			class="input-editor"
			v-model="content"
			:api-key="apiKey"
			:init="editorOption"
			:disabled="disabled"
			:key="keyEditor"
		/>
		<span class="error-message" v-if="isShowErrorMessage">
			{{ errorMessage }}
		</span>
		<div class="container-icon-right">
			<span v-if="isShowErrorMessage" class="icon error is-right">
				<FontAwesomeIcon :icon="['fas', 'exclamation-circle']" />
			</span>
		</div>
		<span class="max-length-message" v-if="isShowMaxLengthMessage">
			{{ lengthMessage }}/{{ maxLength }}
		</span>
	</div>
</template>

<script>
import editor from "@tinymce/tinymce-vue";
import { v4 as uuidv4 } from "uuid";

export default {
	name: "BaseInputEditor",

	components: {
		editor
	},

	data() {
		return {
			apiKey: process.env.VUE_APP_TINYMCE_API_KEY,
			keyEditor: uuidv4(),
			textLength: 0
		};
	},

	props: {
		id: String,
		label: {
			type: String,
			default: ""
		},
		inline: Boolean,
		placeholder: {
			type: String,
			default: ""
		},
		value: {
			type: String,
			default: ""
		},
		error: String,
		inputOptions: {
			type: Array,
			default: () => ([])
		},
		controlOptions: {
			type: Array,
			default: () => ([])
		},
		isError: {
			type: Boolean,
			default: false
		},
		errorMessage: {
			type: String,
			default: ""
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		autofocus: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		maxLength: {
			type: Number,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		isShowLabel() {
			return this.label !== "";
		},
		isShowErrorMessage() {
			return this.isError && this.errorMessage;
		},
		isShowMaxLengthMessage() {
			return this.maxLength;
		},
		lengthMessage() {
			return (this.content && this.convertToPlain(this.content).length) || 0;
		},

		errorClass() {
			return {
				"is-error has-icons-right": this.isError
			};
		},

		loadingClass() {
			return {
				"has-icons-right is-loading": this.isLoading
			};
		},

		content: {
			get() {
				return this.value;
			},
			set(value) {
				this.handleChangeEditor(value);
			}
		},

		editorOption() {
			return {
				selector: "#inputTextEditor",
				inline: true,
				plugins: "importcss searchreplace autolink save directionality visualblocks link media template codesample table charmap  nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap code",
				mobile: {
					plugins: "importcss tinydrive searchreplace autolink save directionality visualblocks link media template codesample table charmap nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap code"
				},
				menubar: false,
				toolbar: "bold italic underline strikethrough subscript superscript",
				image_class_list: [
					{ title: "None", value: "" },
					{ title: "Some class", value: "class-name" }
				],
				content_style: ".tox-editor-container { border-radius: 8px !important; } .tox-editor-header { border-radius: 8px !important; } .tox-toolbar-overlord { border-radius: 8px !important; } .tox-toolbar__primary { border-radius: 8px !important; } .tox-tinymce-aux { display: none; }",
				toolbar_mode: "floating",
				placeholder: this.placeholder
			};
		}
	},

	methods: {
		convertToPlain(html) {
			const tempDivElement = document.createElement("div");
			tempDivElement.innerHTML = html;
			return tempDivElement.textContent || tempDivElement.innerText || "";
		},
		handleChangeEditor(value) {
			this.$emit("input", value);
			// TODO: comment for check max length for future.
			// this.textLength = (this.convertToPlain(value).length) || 0;
			// if (this.textLength < this.maxLength) {
			// 	return this.$emit("input", value);
			// }
			// if (this.maxLength === null) {
			// 	return this.$emit("input", value);
			// }
			// return this.$emit("input", this.value);
		}
	}
};
</script>

<style lang="scss" scoped>
// has-icons-left, has-icons-right
.input-wrapper {
	&.in-line {
		width: 100%;
		display: flex;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}

.input-label {
	font-size: $font-18;
}

.input {
	box-shadow: none;
	background-color: $color-grey-1;
	border: rem(1) solid $color-silver;
	color: $color-dark-blue-grey;
	max-width: 100%;
	width: 100%;
	display: block;
	padding: $control-padding-vertical $control-padding-horizontal;

	&:focus,
	&.is-focused,
	&:active,
	&.is-active {
		border: rem(1) solid $color-emerald;
	}

	&::placeholder {
		font-size: $font-18;
		color: $color-grey-4;
	}

	&[disabled] {
		background-color: $color-disabled-input;
		cursor: not-allowed;

		&:focus,
		&.is-focused,
		&:active,
		&.is-active {
			border: rem(1) solid $color-grey-3;
		}
	}

	&[readonly] {
		box-shadow: none;
	}

	&.is-rounded {
		border-radius: rem(8);
		padding-left: rem(16);
		padding-right: rem(16);
	}

	&.rounded {
		border-radius: $border-radius-normal;
	}

	&.is-static {
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
		padding-left: 0;
		padding-right: 0;
	}

	&.ghost {
		border: none;
		background: transparent;
	}

	&.small {
		height: rem(40);
	}

	&-filter {
		font-size: $font-18;
		height: rem(34);
	}

	&.merge {
		&-right {
			border-right: none;
			border-radius: 4px 0 0 4px;
		}

		&-left {
			border-left: none;
			border-radius: 0 4px 4px 0;
		}
	}
}

.label {
	color: $color-red;
	display: block;
	font-size: $font-20;
	font-weight: 600;

	&:not(:last-child) {
		margin-bottom: 0.5em;
	}

	&.is-small {
		font-size: $font-16;
	}

	&.is-medium {
		font-size: $font-32;
	}

	&.is-large {
		font-size: $font-48;
	}
}

.field {
	&:not(:last-child) {
		margin-bottom: 0.75rem;
	}

	&.has-addons {
		display: flex;
		justify-content: flex-start;

		.control {
			&:not(:last-child) {
				margin-right: rem(-1);
			}

			&:not(:first-child):not(:last-child) {
				.button,
				.input,
				.select select {
					border-radius: 0;
				}
			}

			&:first-child:not(:only-child) {
				.button,
				.input,
				.select select {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}

			&:last-child:not(:only-child) {
				.button,
				.input,
				.select select {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
				}
			}

			.button,
			.input,
			.select select {
				&:not([disabled]) {
					&:hover {
						z-index: 2;
					}

					&:focus,
					&:active {
						z-index: 3;
					}
				}
			}

			&.is-hovered {
				z-index: 2;
			}

			&.is-active,
			&.is-focused {
				z-index: 3;
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		&.has-addons-centered {
			justify-content: center;
		}

		&.has-addons-right {
			justify-content: flex-end;
		}

		&.has-addons-fullwidth {
			.control {
				flex-grow: 1;
				flex-shrink: 0;
			}
		}
	}

	&.is-grouped {
		display: flex;
		justify-content: flex-start;

		& > .control {
			flex-shrink: 0;

			&:not(:last-child) {
				margin-bottom: 0;
				margin-right: 0.75rem;
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		&.is-grouped-centered {
			justify-content: center;
		}

		&.is-grouped-right {
			justify-content: flex-end;
		}

		&.is-grouped-multiline {
			flex-wrap: wrap;

			& > .control {
				&:last-child,
				&:not(:last-child) {
					margin-bottom: 0.75rem;
				}
			}

			&:last-child {
				margin-bottom: -0.75rem;
			}

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}

	&.is-horizontal {
		@include tablet {
			display: flex;
		}
	}
}

.error-message {
	height: rem(20);
	position: absolute;
	bottom: rem(-20);
	font-size: $font-14;
	color: $color-red;
	font-weight: $font-weight-bold;

	&.has-label {
		margin-left: rem(130);
	}
}

.max-length-message {
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translateY(100%);
	font-size: $font-16;
	color: $color-grey-4;
}

.control {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	font-size: $font-20;
	clear: both;
	box-sizing: border-box;

	&.in-line {
		flex-direction: row;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}

	&.max-length {
		margin-bottom: rem(14);
	}

	&.has-icons-left,
	&.has-icons-right {
		.input,
		.select {
			&:focus {
				& ~ .icon {
					color: $color-dark-blue-grey;
				}
			}

			&.is-small ~ .icon {
				font-size: $font-16;
			}

			&.is-medium ~ .icon {
				font-size: $font-32;
			}

			&.is-large ~ .icon {
				font-size: $font-48;
			}
		}

		.container-icon-right {
			display: flex;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			padding-right: rem(16);
		}

		.icon {
			color: $color-gunmetal-two;
			height: 100%;
			position: absolute;
			top: 0;
			width: $input-height;
			z-index: 1;

			&.is-right {
				position: relative;
				width: rem(24);
				height: rem(24);
				margin-left: rem(6);

				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}

	&.has-icons-left {
		.input,
		.select select {
			padding-left: $input-height;
		}

		.input {
			&[disabled] {
				background-color: $color-disabled-input;
				cursor: not-allowed;

				&:focus,
				&.is-focused,
				&:active,
				&.is-active {
					border: rem(1) solid $color-grey-3;
				}
			}
		}

		.icon.is-left {
			left: 0;
		}
	}

	&.has-icons-right {
		.input,
		.select select {
			padding-right: $input-height;
		}

		.icon.is-right {
			right: 0;
		}
	}

	&.is-error {
		::v-deep {
			p {
				border-color: $color-red;
				padding-right: rem(78);
			}

			p:focus ~ .icon, .icon {
				color: $color-red;
			}
		}
	}

	&.is-error-power-bi {
		.input {
			border-color: $color-red;
		}

		.input:focus ~ .icon, .icon {
			color: $color-red;
		}

		.icon:not(.error):not(.loading) {
			color: $color-gunmetal-two;
		}

		.input-label {
			color: $color-red;
		}

		.max-length-message {
			color: $color-red;
		}
	}

	&.is-loading {
		&::after {
			position: absolute;
			right: 0.625em;
			top: 0.625em;
			z-index: 4;
		}

		&.is-small::after {
			font-size: $font-16;
		}

		&.is-medium::after {
			font-size: $font-32;
		}

		&.is-large::after {
			font-size: $font-48;
		}

		.icon {
			animation-name: spin;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		.input:focus ~ .icon, .icon {
			color: $color-dark-blue-grey;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

::v-deep {
	.mce-content-body {
		background-color: $color-grey-1;
	}

	.mce-content-body::before {
		left: rem(10) !important;
		top: rem(6);
	}

	p {
		position: relative;
		display: block;
		height: 40px;
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: $control-padding-vertical $control-padding-horizontal;
		color: $color-dark-blue-grey;
		border: 1px solid $color-silver;
		border-radius: rem(8);
		white-space: nowrap;
		overflow: hidden;
	}
}

</style>
