<template>
	<div class="overview-info">
		<p :class="['title' , { subheader : isSubTitle }]">
			<span v-html="data.title" class="html"></span>
			<span v-html="displayTitle" class="html"></span>
		</p>
		<p class="value">
			<span v-if="!isColorBar && !isProgressBar" v-html="data.value" :class="['html', { overvalue : isStyleTextColor }]"></span>
			<BarColor v-if="isColorBar" :data="data.dataValue" />
			<span v-if="isProgressBar" class="progress-bar">
				<BarProgress :data="data.dataValue" />
			</span>
		</p>
	</div>
</template>

<script>
import BarColor from "@/components/StandardModule/BarColor.vue";
import BarProgress from "@/components/StandardModule/BarProgress.vue";
import { overviewType } from "../../enums/standardModule";

export default {
	name: "OverviewValue",

	components: {
		BarColor,
		BarProgress
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				value: "",
				max: "",
				min: "",
				columnType: ""
			})
		}
	},

	data() {
		return {
			overviewType
		};
	},

	computed: {
		displayTitle() {
			if (this.isColorBar || this.isProgressBar) {
				return this.data.dataValue?.displayTitle ?? "";
			}
			return "";
		},
		isStyleTextColor() {
			const checkOverMin = this.data.min !== null ? this.convertToNumber(this.data.value) < this.convertToNumber(this.data.min) : false;
			const checkOverMax = this.data.max !== null ? this.convertToNumber(this.data.value) > this.convertToNumber(this.data.max) : false;
			return checkOverMin || checkOverMax;
		},
		isSubTitle() {
			return this.data.columnType === overviewType.SUBTITLE;
		},
		isProgressBar() {
			return this.data.columnType === overviewType.TITLE_PROGRESS_BAR;
		},
		isColorBar() {
			return this.data.columnType === overviewType.TITLE_COLOR_BAR;
		}
	},

	methods: {
		convertToNumber(text) {
			// regex remove  %, $, ฿, from value for convert to Number
			const numberText = (text ?? "").toString().replace(/,|%|$|฿/g, "");
			return Number(numberText);
		}
	}
};
</script>

<style lang="scss" scoped>
.overview {
	&-section {
		display: flex;
	}

	&-info {
		display: flex;
		justify-content: space-between;

		p {
			margin: 0 0 rem(12);
			color: $color-dark-blue-grey;

			&:nth-child(even) {
				display: flex;
				font-weight: 600;
			}

			.html {
				::v-deep p {
					margin: 0;
				}
			}

			.overvalue {
				color: $color-red;
			}
		}

		&:last-of-type {
			p {
				margin: 0;
			}
		}
	}

	&-values {
		flex: 1;

		.subheader {
			font-weight: bold;
		}

		.title {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			width: 60%;

			&.-has-icon {
				width: 40%;
				text-align: right;
				margin-bottom: 0;
			}

			.title-icon {
				font-size: $font-26;
			}
		}

		.value {
			justify-content: flex-end;
			width: 40%;

			::v-deep {
				.bar-g-container {
					width: 100%;

					.bar-g-chart {
						width: 100%;
					}
				}
			}

			.progress-bar {
				width: 100%;
				position: relative;
				top: rem(2);
				margin-left: rem(8);
			}
		}

		.overview-group {

			// overview-group-header
			&-header {
				margin: rem(8) 0;
				padding: rem(4) rem(8);

				font-size: $font-18;
				font-weight: bold;

				background-color: $color-off-white;
			}

			//overview-group-header
			&-body {
				padding: 0 rem(8);
			}
		}

		.overview-attachment {
			margin-top: rem(8);
			padding: rem(12) rem(8) rem(8) rem(8);
			border-top: 1px solid $color-ice;

			.view-button {
				.icon-file {
					margin-right: 2px;
					font-size: rem(14);
				}
			}
		}
	}

	&-bar {
		min-width: rem(144);
		margin-right: rem(16);
		padding: rem(8);
		/* stylelint-disable */
		&::v-deep {
			.nitro-fas-wrapper {
				width: rem(128);
				height: rem(128);
			}
		}
		/* stylelint-enable */
	}
}
</style>

<style scoped>
/* we have to create second style because scss not supported to deep selector */
.html >>> sup {
	line-height: 0;
}
</style>