var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{attrs:{"pageTitle":"Customer account"}},[(_vm.manageAble)?_c('div',{staticClass:"container-action-bar"},[_c('router-link',{attrs:{"to":{ name: _vm.PAGE_NAME.CUSTOMER_CREATE }}},[_c('BaseButton',{attrs:{"size":"large"}},[_vm._v(" Create customer account ")])],1)],1):_vm._e(),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('SearchFilterForm',{attrs:{"roles":_vm.roles,"statuses":_vm.statuses,"types":_vm.types,"query":_vm.query},on:{"submit":_vm.handleSubmitFilter}})],1),(_vm.isEmpty)?_c('BaseNoAccounts'):_c('BaseTableCustom',{attrs:{"tableOptions":_vm.tableOptions,"pagination":_vm.pagination,"loading":_vm.isLoading},on:{"onPageChange":_vm.handlePageChange},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('tr',_vm._l((_vm.table.thead),function(ref,index){
var value = ref.value;
var key = ref.key;
return _c('th',{key:index},[_c('div',{staticClass:"header-content"},[_vm._v(" "+_vm._s(value)+" "),(key)?_c('BaseSort',{attrs:{"sortName":key,"sortBy":_vm.getSortingBykey(key)},on:{"handleSort":_vm.handleSort}}):_vm._e()],1)])}),0)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.table.tbody),function(item,index){return _c('tr',{key:index,class:[
						{
							inactive: !item.isActive
						}
					]},[_c('td',{staticClass:"max-width-sold-to"},[_vm._v(" "+_vm._s(item.companyName)+" ")]),_c('td',{staticClass:"max-width-default"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"max-width-default"},[(item.isTestAccount)?_c('BaseBadge',{attrs:{"text":"Test","size":"small","color":"grey"}}):_vm._e(),_vm._v(" "+_vm._s(item.email)+" ")],1),_c('td',{staticClass:"max-width-default"},[_vm._v(" "+_vm._s(item.role)+" ")]),_c('td',[(item.isActive)?_c('BaseBadge',{attrs:{"text":"Active","size":"small","color":"success-dark"}}):_c('BaseBadge',{attrs:{"text":"Inactive","size":"small","outline":""}})],1),_c('td',{staticClass:"align-right action"},[(_vm.manageAble)?_c('router-link',{attrs:{"to":("/customers/" + (item.id))}},[_c('button',{staticClass:"view-button btn-link"},[_vm._v(" View detail ")])]):_vm._e(),(_vm.manageAble)?_c('router-link',{staticClass:"ml-7",attrs:{"to":{
								name: _vm.PAGE_NAME.ACTIVITYLOG,
								query: {
									page: 1,
									q: item.email,
									userType: 'customer',
								}
							},"target":"_blank"}},[_c('button',{staticClass:"view-button btn-link"},[_vm._v(" View log ")])]):_vm._e()],1)])})},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }