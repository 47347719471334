<template>
	<div class="wrapper-container" ref="container">
		<slot/>
	</div>
</template>

<script>

export default {
};
</script>

<style lang="scss" scoped>
.wrapper-container {
	background: url("/assets/images/bg.png") no-repeat center center;
	background-size: cover;
	display: flex;
	height: 100vh;
	min-height: rem(800);
	justify-content: center;
}
</style>
