<template>
	<div class="forgot-password-content">
		<div v-if="!isSentEmail" class="forgot-password-form">
			<h1 class="forgot-password-title">Forgot Password?</h1>
			<p class="forgot-password-sub-title">enter your email address and we'll send you an email with instructions.</p>
			<form ref="form-login">
				<div class="form-group">
					<BaseFormInput
						v-model="email"
						:is-error="isErrorEmail"
						:error-message="emailErrorMessage"
						:disabled="isForgotPasswordLoading"
						type="email"
						placeholder="Email"
						iconLeft="envelope"
						class="custom-input has-icons-left"
						@blur="handleBlurEmail"
					/>
				</div>
				<div class="form-group">
					<button
						:disabled="disableCustomerLoginButton"
						type="button"
						class="btn btn-forgot-password"
						@click="handleForgotPassword"
					>
						Reset password
					</button>
				</div>
			</form>
			<div
				class="redirect-to-login "
			>
				<router-link
					:to="{ name: PAGE_NAME.LOGIN_PAGE }"
					class="btn-link"
				>
					Return to log in
				</router-link>
			</div>
		</div>
		<div v-if="isSentEmail" class="forgot-password-form success">
			<h1 class="forgot-password-title">Email sent!</h1>
			<p class="forgot-password-sub-title">
				Please check you email
				<span class="email-highlight">{{ this.email }} <br/></span>
				for a link to reset your password.
			</p>
			<div class="re-forget-password">
				<span class="re-forget-password-message">Not your email address?</span>
				<BaseButton
					size="small"
					type="secondary"
					link
					@click="handleReForgetPassword"
				>
					Try again
				</BaseButton>
			</div>
		</div>
	</div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import { isValidEmail as validEmail } from "../../selectors/helpers/regex";
import { postForgotPassword } from "../../services/api/password.api";
import { PAGE_NAME } from "../../enums/pagePermission";

export default {
	name: "ForgotPassword",

	components: {
		BaseButton
	},

	data() {
		return {
			controlOptions: ["has-icons-left"],
			email: "",
			isValidEmail: null,
			isBrowserAutoFilled: false,
			isSentEmail: false,
			isForgotPasswordLoading: false,
			PAGE_NAME
		};
	},

	computed: {
		disableCustomerLoginButton() {
			if (this.isBrowserAutoFilled) {
				return false;
			}

			return !this.isValidEmail || this.customerLoginLoading;
		},

		emailErrorMessage() {
			if (this.email.trim().length === 0) {
				return "Required field";
			}
			return "Invalid email format.";
		},

		isErrorEmail() {
			return	this.isValidEmail === false;
		}
	},

	watch: {
		email() {
			if (!this.isValidEmail) {
				if (validEmail(this.email)) {
					this.isValidEmail = true;
				}
			}
			this.isBrowserAutoFilled = false;
		}
	},

	mounted() {
		// selector input element
		if (this.email !== "") {
			this.handleBlurEmail(this.email);
		}
		// handle evnet animationstart from input
		// to detect auto fill
		window.addEventListener("keypress", this.handleKeyPress);
	},

	methods: {
		async handleForgotPassword() {
			const res = await postForgotPassword(this.email);
			if (res.status === 200) {
				this.isSentEmail = true;
			}
		},

		handleReForgetPassword() {
			this.isSentEmail = false;
			this.email = "";
			this.isValidEmail = null;
		},

		handleBlurEmail(value) {
			this.isValidEmail = validEmail(value);
		},

		handleKeyPress(e) {
			const KEY = {
				Enter: false
			};

			KEY[e.code] = true;

			if (KEY.Enter) {
				this.handleForgotPassword();
			}
		}
	},

	destroyed() {
		window.removeEventListener("keypress", this.handleKeyPress);
	}
};
</script>

<style lang="scss" scoped>
.forgot-password-content {
	display: flex;
	align-items: center;

	.forgot-password-title,
	.forgot-password-sub-title {
		text-align: center;
	}

	.forgot-password-form {
		background-color: $color-white;
		padding: rem(40);
		width: 100%;
		max-width: rem(470);
		margin: 0 auto;
		border: solid rem(1) $color-silver;
		border-radius: rem(4);

		&.success {
			min-width: rem(470);
		}
	}

	.forgot-password-title {
		color: $color-dark-blue-grey;
		font-weight: 600;
	}

	.forgot-password-sub-title {
		color: $color-dark-blue-grey;
		margin-top: rem(10);
		margin-bottom: rem(20);
		font-size: $font-24;
		word-break: break-word;

		.email-highlight {
			font-weight: $font-weight-bold;
		}
	}

	.btn-forgot-password {
		width: 100%;
		height: rem(48);
		background-color: $color-azure;
		color: $color-white;

		&:disabled {
			opacity: 0.5;
		}
	}

	.form-group {
		margin-bottom: rem(25);

		.form-control {
			height: rem(50);
		}
	}
}

.re-forget-password {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: rem(-4);

	.re-forget-password-message {
		color: $color-grey-4;
	}
}

.redirect-to-login {
	display: flex;
	justify-content: flex-end;
	font-size: rem(18);
}

/* stylelint-disable */
// add amimation to working with `animationstart` event
.custom-input {
	:-webkit-autofill {
		animation-name: onAutoFillStart;
	}

	:not(:-webkit-autofill) {
		animation-name: onAutoFillCancel;
	}

	@keyframes onAutoFillStart {
		from {}
		to {}
	}

	@keyframes onAutoFillCancel {
		from {}
		to {}
	}
}
/* stylelint-enable */
</style>
