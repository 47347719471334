<template>
	<BaseToastContent
		:message="message"
		title="Success"
	/>
</template>

<script>
import BaseToastContent from "@/components/BaseToastContent.vue";

export default {
	name: "ToastStaffManagement",

	props: ["message"],

	components: {
		BaseToastContent
	}
};
</script>