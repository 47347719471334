<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<template
			v-else
		>
			<BaseLoading
				v-if="furnaceHistories.isLoading"
			/>
			<BaseFilterExport
				v-if="!furnaceHistories.isLoading && permissions.dashboard.exportable"
				mode="range"
				class="custom-filter-export"
				@onSubmit="handleSubmitExport"
			/>
			<BaseTable
				:item="item"
				:tableOptions="tableOptions"
				:columnOptions="columnOptions"
				:pagination="pagination"
				:loading="loading"
				:stickyHeader="true"
				@onPageChange="handlePageChange"
			/>
		</template>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";

import BaseLoading from "@/components/BaseLoading.vue";
import BaseTable from "@/components/BaseTable.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";

import { getUri } from "../../selectors/helpers";
import userPermission from "../../mixins/userPermission";
import { NM_UNIT } from "../../selectors/transform/unit";

export default {
	components: {
		BaseLoading,
		EmptyOrderHistory,
		BaseFilterExport,
		BaseTable
	},

	mixins: [userPermission],

	data() {
		return {
			furnaceId: this.$route.params.id,
			thead: [
				"Date",
				`Total production <br />(ton/month)`,
				`NG Melter consumption <br />(${NM_UNIT}/month)`,
				`NG Refiner consumption <br />(${NM_UNIT}/month)`,
				`Total NG consumption <br />(${NM_UNIT}/month)`,
				`Average Heating value <br />(Btu/${NM_UNIT})`,
				`Melter energy consumption<br />(MMBtu/month)`,
				`Refiner energy consumption <br />(MMBtu/month)`,
				"Total energy consumption <br />(MMBtu/month)"
			],

			tableOptions: [
				"is-striped is-fullwidth"
			],

			columnOptions: [
				{ className: "bold line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" },
				{ className: "line-height-1 nowrap" }
			],

			page: this.$route.query.page || 1
		};
	},

	computed: {
		...mapState(["furnaceHistories"]),
		data() {
			return this.furnaceHistories.data;
		},
		pagination() {
			return this.furnaceHistories.pagination;
		},
		loading() {
			return this.furnaceHistories.isLoading;
		},
		item() {
			return {
				thead: this.furnaceHistories.thead,
				tbody: this.data.map((item) => [item.dateString, item.production, item.ngMelterConsumption, item.ngRefinerConsumption, item.ngConsumption, item.ngHeating, item.enMelter, item.enRefiner, item.enConsumption])
			};
		},
		isEmpty() {
			return this.furnaceHistories.isEmpty;
		}
	},

	async created() {
		await this.fetchData();
		await this.addActivityLogHistoryView({
			shopToId: this.furnaceId,
			httpStatus: this.furnaceHistories.statusCode
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("furnaceHistories", {
			fetchFurnaceHistories: "fetchFurnaceHistories"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		fetchData() {
			const { furnaceId, page } = this;
			return this.fetchFurnaceHistories({
				id: furnaceId,
				params: { page }
			});
		},

		handlePageChange(page = 1) {
			this.page = page;
			this.fetchData();
			this.$router.replace({ query: { page } }).catch(() => {});
		},

		handleSubmitExport(form) {
			const params = {
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).format("YYYY-MM-DD"),
						endDate: dayjs(form.dateRange.end).format("YYYY-MM-DD")
					} : null
				)
			};
			const path = `/furnaces/${this.furnaceId}/export-order-histories`;
			const uri = getUri(path, params);
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.custom-filter-export {
	margin-top: rem(-8);
	margin-bottom: rem(11);
	justify-content: flex-end;
}
</style>