import { getSoldToManagementAPI } from "@/services/api/soldToManagement.api";
import { SOLDTO_MANAGEMENT_REQUEST, SOLDTO_MANAGEMENT_SUCCESS, SOLDTO_MANAGEMENT_FAILURE } from "../../types";
import { transformSoldToManagement } from "../../../selectors/transform/soldToManagement/transformSoldToManagement";

export default {
	namespaced: true,

	state: {
		data: transformSoldToManagement([]),
		isLoading: false,
		pagination: {
			currentPage: 1,
			lastPage: 1,
			total: 0
		}
	},

	mutations: {
		[SOLDTO_MANAGEMENT_REQUEST](state) {
			state.isLoading = true;
			state.data = [];
		},
		[SOLDTO_MANAGEMENT_SUCCESS](state, { data, pagination }) {
			state.data = transformSoldToManagement(data);
			state.pagination = pagination;
			state.isLoading = false;
		},
		[SOLDTO_MANAGEMENT_FAILURE](state) {
			state.data = [];
			state.isLoading = false;
		}
	},

	actions: {
		async getSoldToManagement({ commit }, params = {}) {
			try {
				commit(SOLDTO_MANAGEMENT_REQUEST);
				const { data } = await getSoldToManagementAPI({ page: params.page, soldToId: params.company });
				commit(SOLDTO_MANAGEMENT_SUCCESS, data);
			} catch (error) {
				commit(SOLDTO_MANAGEMENT_FAILURE);
			}
		}
	}
};
