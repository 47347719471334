<template>
	<div class="col-12 col-xl-5 no-gutter-sm">
		<BaseCard>
			<template v-slot:header>
				<h4>Customer information</h4>
			</template>
			<template v-slot:body>
				<div class="customer-values">
					<div
						v-for="item in info.customerView"
						:key="item.key"
						class="customer-info"
					>
						<p :class="['title', { '-has-icon': item.titleType === 'icon' }]">
							<AtIcon v-if="item.icon === 'AtIcon'"></AtIcon>
							<IndustryWindowsIcon v-else-if="item.icon === 'IndustryWindowsIcon'"></IndustryWindowsIcon>
							<LocationDotIcon v-else-if="item.icon === 'LocationDotIcon'"></LocationDotIcon>
							<PhoneIcon v-else-if="item.icon === 'PhoneIcon'"></PhoneIcon>
							<UserHeadsetIcon v-else-if="item.icon === 'UserHeadsetIcon'"></UserHeadsetIcon>
							<BuildingIcon v-else-if="item.icon === 'BuildingIcon'"></BuildingIcon>

							<component v-if="item.titleType === 'icon'"  :is="item.title" class="title-icon" />
							<span v-else v-html="item.titleType === 'text' ? item.titleDisplay : item.title" class="html"></span>
						</p>
						<p :class="['value', { '-has-bar-g': item.barG }]">
							<span :title="item.value"  v-html="item.value" class="html"></span>
						</p>
					</div>
				</div>
			</template>
		</BaseCard>
	</div>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import BuildingIcon from "../../../components/icon/BuildingIcon.vue";
import AtIcon from "../../../components/icon/AtIcon.vue";
import IndustryWindowsIcon from "../../../components/icon/IndustryWindowsIcon.vue";
import LocationDotIcon from "../../../components/icon/LocationDotIcon.vue";
import PhoneIcon from "../../../components/icon/PhoneIcon.vue";
import UserHeadsetIcon from "../../../components/icon/UserHeadsetIcon.vue";

export default {
	name: "ModuleCustomerInfo",

	components: {
		BaseCard,
		BuildingIcon,
		AtIcon,
		IndustryWindowsIcon,
		LocationDotIcon,
		PhoneIcon,
		UserHeadsetIcon
	},

	props: {
		info: {
			type: Object,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
.customer {
	&-info {
		display: flex;
		justify-content: space-between;
		margin-bottom: rem(10);

		p {
			margin: 0 0 rem(12);

			display: flex;
			flex-direction: row;
			gap: rem(16);
			justify-content: flex-start;

			color: $color-dark-blue-grey;

			&:nth-child(even) {
				display: flex;
				font-weight: 600;
			}

			span {
				overflow-wrap: anywhere;
				text-align: end;
			}
		}
	}

	&-values {
		flex: 1;

		.subHeader {
			font-weight: bold;
		}

		.title {
			flex: none;
			width: 45%;

			&.-has-icon {
				width: 45%;
				text-align: right;
				margin-bottom: 0;
			}

			.title-icon {
				font-size: $font-26;
			}
		}

		.value {
			&.-has-bar-g {
				width: 50%;
				display: flex;

				.html {
					width: rem(50);

					text-align: right;
				}

				::v-deep {
					.bar-g-container {
						width: calc(100% - 50px);

						.bar-g-chart {
							width: 100%;
						}
					}
				}
			}

			.progress-bar {
				width: rem(96);
				position: relative;
				top: rem(2);
				margin-left: rem(8);
			}
		}
	}
}

.card {
	@include mobile {
		border-radius: 0;
	}
}
</style>

<style scoped>
/* we have to create second style because scss not supported to deep selector */
.html >>> sup {
	line-height: 0;
}
</style>