<template>
	<BaseLayout>
		<BaseSwiperBanner v-if="!bigStoreManagement.data.bannersIsEmpty" :list="bigStoreManagement.data.banner"></BaseSwiperBanner>
		<div class="sub-header-title" v-if="!bigStoreManagement.data.highlightsIsEmpty">
			Our Hightlight Services
		</div>
		<SwiperService v-if="!bigStoreManagement.data.highlightsIsEmpty" :media="bigStoreManagement.data.highlight"></SwiperService>
		<div class="sub-header-title" v-if="!bigStoreManagement.data.devicesIsEmpty">
			Our Devices
		</div>
		<SwiperDevice v-if="!bigStoreManagement.data.devicesIsEmpty" :media="bigStoreManagement.data.device"></SwiperDevice>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseSwiperBanner from "../../components/BaseSwiperBanner.vue";
import SwiperService from "../../components/SwiperService.vue";
import SwiperDevice from "../../components/SwiperDevice.vue";

export default {
	name: "marketplace",

	components: {
		BaseSwiperBanner,
		SwiperService,
		SwiperDevice
	},

	computed: {
		...mapState(["bigStoreManagement"])
	},

	async created() {
		await this.addActivityLogMarketplace();
		await this.fetchMarketplaceList();
	},

	methods: {
		...mapActions("bigStoreManagement", {
			fetchMarketplaceList: "fetchMarketplaceList"
		}),
		...mapActions("activityLogAdd", {
			addActivityLogMarketplace: "addActivityLogMarketplace"
		})
	}
};
</script>

<style lang="scss" scoped>
.sub-header-title {
	display: flex;
	justify-content: center;
	font-size: $font-48;
	font-weight: $font-weight-bold;
	text-align: center;
}

@include mobile {
	::v-deep {
		.content-container {
			padding-left: 0;
			padding-right: 0;
		}

		.banner-container {
			margin: 0;

			img {
				border-radius: 0;
			}
		}

		.service-container {
			margin: 0;
			padding: 0;
		}

		.device-container {
			margin: rem(24) rem(32) rem(40) rem(32);
		}
	}
}
</style>
