<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:iconSize="iconSize"
		class="base-device-with-company"
	>
		<template #icon>
			<FurnaceIcon
				:key="data.shipId"
				:value="data.furnace"
				:size="iconSize"
				:regenN="data.regenN"
				:regenS="data.regenS"
			/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<div class="container-detail-info-shipid">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div class="container-detail-info-feeding-rate">
					<span>
						Feeding Rate (tph)
					</span>
					<span>
						{{ feedingRate }}
					</span>
				</div>
				<div class="container-detail-info-natural-gas">
					<span>
						Natural Gas (Nm<sup>3</sup>/hr)
					</span>
					<span>
						{{ naturalGas }}
					</span>
				</div>
				<div class="container-detail-info-air-flow-rate">
					<span>
						Air Flow Rate (Nm<sup>3</sup>/hr)
					</span>
					<span>
						{{ airFlowRate }}
					</span>
				</div>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import FurnaceIcon from "@/components/deviceIcons/FurnaceIcon.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";
import { numberFormat } from "../../../../selectors/helpers";

export default {
	name: "FurnaceDeviceWithCompany",

	components: {
		FurnaceIcon,
		BaseDeviceWithCompany
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	computed: {
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		},
		feedingRate() {
			const { feeding } = this.data;
			return numberFormat(feeding);
		},
		naturalGas() {
			const { ng } = this.data;
			return numberFormat(ng);
		},
		airFlowRate() {
			const { air } = this.data;
			return numberFormat(air);
		},
		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.tagNo;
		}
	}
};
</script>

<style lang="scss" scoped>
.base-device-with-company {
	/* stylelint-disable */
	::v-deep {
		.container-detail-icon {
			width: rem(80);
			height: rem(80);
			margin-top: rem(16);
			margin-right: rem(12);
			margin-left: rem(12);
		}
	}

/* stylelint-enable */
	.container-detail {
		&-info {
			flex: 1;
			margin: rem(8) 0;

			&-shipid {
				@include fontSub();

				display: flex;
				height: rem(22);
				align-items: center;

				font-size: $font-18;
				color: $color-dark-blue-grey;
			}

			&-name {
				@include fontSub();

				display: flex;
				height: rem(29);
				align-items: center;

				font-size: $font-24;
				font-weight: $font-weight-bold;
				color: $color-dark-blue-grey;
			}

			&-feeding-rate,
			&-natural-gas,
			&-air-flow-rate {
				margin-top: rem(4);
				display: flex;
				align-items: center;
				height: rem(14);

				font-size: $font-14;
				color: $color-dark-blue-grey;
				line-height: rem(16);

				span {
					&:first-of-type {
						display: flex;
						width: rem(100);

						sup {
							position: relative;
							top: rem(-3);
						}
					}

					&:last-of-type {
						flex: 1;
						text-align: right;
						font-weight: $font-weight-bold;
					}
				}
			}
		}
	}
}
</style>
