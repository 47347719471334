import { getPowerKwhAPI } from "@/services/api/pwr.api";
import { POWER_KWH_REQUEST, POWER_KWH_SUCCESS, POWER_KWH_FAILURE } from "../../types";
import { parseKwh } from "../../../services/api/transforms/parseModules";

export default {
	namespaced: true,

	state: {
		detail: parseKwh({}),
		loading: true,
		empty: false
	},

	mutations: {
		[POWER_KWH_REQUEST](state) {
			state.detail = parseKwh({});
			state.loading = true;
			state.empty = false;
		},
		[POWER_KWH_SUCCESS](state, data) {
			state.detail = parseKwh(data);
			state.loading = false;
			state.empty = !state.detail.data.length;
		},
		[POWER_KWH_FAILURE](state) {
			state.detail = parseKwh({});
			state.loading = false;
			state.empty = false;
		}
	},

	actions: {
		async getPowerKwh({ commit }, { id, params }) {
			try {
				commit(POWER_KWH_REQUEST);
				const { data } = await getPowerKwhAPI(id, params);
				commit(POWER_KWH_SUCCESS, data);
			} catch (error) {
				commit(POWER_KWH_FAILURE, error);
			}
		}
	}
};