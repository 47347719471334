export const replaceDeviceName = (name, customReplacer = []) => {
	// dataSet is list of devinces name to replace
	// from is original value
	// to is the value that we want to replace.
	const dataSet = [
		...customReplacer,
		{
			from: "CO2",
			to: "Carbon dioxide"
		},
		{
			from: "NitroFAS",
			to: "NitroFAS™"
		},
		{
			from: "O2 Aeration",
			to: "Waste Water"
		}
	];

	for (const data of dataSet) {
		if (name === data.from) {
			return data.to;
		}
	}

	return name;
};
