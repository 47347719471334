<template>
	<div ref="container">
		<slot></slot>
	</div>
</template>

<script>
export default {
	data() {
		return {
			containerOriginalOffsetTop: null
		};
	},

	mounted() {
		window.addEventListener("scroll", this.handleWindowScrollY);
	},

	destroyed() {
		window.removeEventListener("scroll", this.handleWindowScrollY);
	},

	methods: {
		handleWindowScrollY() {
			const $container = this.$refs.container;
			if (this.containerOriginalOffsetTop === null) {
				this.containerOriginalOffsetTop = $container.offsetTop;
			}
			if (window.scrollY > this.containerOriginalOffsetTop) {
				$container.style.top = `${window.scrollY - this.containerOriginalOffsetTop}px`;
			} else if (window.scrollY < this.containerOriginalOffsetTop && window.scrollY > this.containerOriginalOffsetTop) {
				$container.style.top = `${this.containerOriginalOffsetTop - window.scrollY}px`;
			} else {
				$container.style.top = "0px";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
div {
	width: 100%;
	position: relative;
	z-index: 1;
	background-color: $color-white;
}
</style>