import { getEOrderingTripInfoAPI } from "@/services/api/eOrdering.api";
import {
	E_ORDERING_TRIP_INFOS_REQUEST,
	E_ORDERING_TRIP_INFOS_SUCCESS,
	E_ORDERING_TRIP_INFOS_FAILURE,
	E_ORDERING_TRIP_INFOS_UPDATE_SUCCESS,
	E_ORDERING_TRIP_INFOS_UPDATE_FAILURE
} from "../../types";

export default {
	namespaced: true,

	state: {
		data: {},
		isLoading: false,
		statusCode: null
	},

	getters: {
		getTripInfoDrivers(state) {
			return state.data.info?.drivers ?? [];
		},

		getTripInfoType(state) {
			return state.data.type;
		},

		getTruckLocation(state) {
			if (state.data.truckGps) {
				return {
					lat: state.data.truckGps.latitude,
					lng: state.data.truckGps.longitude
				};
			}
			return false;
		},

		getTripInfoInfomation(state) {
			// TODO: single drop
			if (state.data.info) {
				return {
					deliveryOrderNo: state.data.info.doNumber ? state.data.info.doNumber.toString() : "-",
					tractorId: state.data.info.tractorId ? state.data.info.tractorId.toString() : "-",
					trailerId: state.data.info.trailerId ? state.data.info.trailerId.toString() : "-",
					tripId: state.data.info.tripId ? state.data.info.tripId.toString() : "-"
				};
			}
			return {
				deliveryOrderNo: "734613",
				tripId: "12423867",
				tractorId: "410",
				trailerId: "2534"
			};
		}
	},

	mutations: {
		[E_ORDERING_TRIP_INFOS_REQUEST](state) {
			state.data = {};
			state.isLoading = true;
			state.statusCode = null;
		},
		[E_ORDERING_TRIP_INFOS_SUCCESS](state, { data, status }) {
			state.data = data;
			state.isLoading = false;
			state.statusCode = status;
		},
		[E_ORDERING_TRIP_INFOS_FAILURE](state, error) {
			state.data = {};
			state.isLoading = false;
			state.statusCode = error.response.status;
		},
		[E_ORDERING_TRIP_INFOS_UPDATE_SUCCESS](state, { data }) {
			state.data = data;
		}
	},

	actions: {
		async getEOrderingTripInfo({ commit }, id) {
			try {
				commit(E_ORDERING_TRIP_INFOS_REQUEST);
				const response = await getEOrderingTripInfoAPI(id);
				commit(E_ORDERING_TRIP_INFOS_SUCCESS, response);
			} catch (error) {
				commit(E_ORDERING_TRIP_INFOS_FAILURE, error);
			}
		},
		async getUpdateEOrderingTripInfo({ commit }, id) {
			try {
				const response = await getEOrderingTripInfoAPI(id);
				commit(E_ORDERING_TRIP_INFOS_UPDATE_SUCCESS, response);
			} catch (error) {
				commit(E_ORDERING_TRIP_INFOS_UPDATE_FAILURE);
			}
		}
	}
};
