<template>
	<CardLineChart
		:chartData="chartOptions"
		:isLoading="loading"
		:isEmpty="empty"
		:lastUpdate="updatedAt"
		:chartTitle="`Air Flow Rate`"
	/>
</template>

<script>
import dayjs from "dayjs";
import Numeral from "numeral";
import CardLineChart from "@/components/chart/CardLineChart.vue";
import { MODULE_SORT_DATE_CHART } from "../../enums/filterChart";
import { numberFormat } from "../../services/api/transforms/parseModule";

const { perDay, perThreeDay, perWeek, perNinetyDays, perThirtyDays } = MODULE_SORT_DATE_CHART;

export default {
	props: {
		data: {
			type: Array,
			default: () => []
		},
		updatedAt: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		empty: {
			type: Boolean,
			default: false
		},
		minFlowRate: {
			type: String,
			default: "0"
		},
		maxFlowRate: {
			type: String,
			default: "0"
		},
		tagName: {
			type: String,
			default: `Air Flow Rate`
		}
	},

	data() {
		return {
			sortBy: perDay,
			filterOptions: {
				date: {
					selected: {
						range: {
							start: dayjs().subtract(1, "day").$d,
							end: dayjs().$d
						}
					},
					picker: {
						mode: "range",
						minDate: dayjs().subtract(30 - 1, "day").$d,
						maxDate: dayjs().$d
					},
					dropdown: {
						items: [
							{
								label: "Last 24 hours",
								value: perDay
							},
							{
								label: "Last 3 days",
								value: perThreeDay
							},
							{
								label: "Last 7 days",
								value: perWeek
							},
							{
								label: "Last 1 month",
								value: perThirtyDays
							},
							{
								label: "Last 3 months",
								value: perNinetyDays
							}
						],
						disabledItems: [perThirtyDays, perNinetyDays]
					}
				}
			}
		};
	},

	components: {
		CardLineChart
	},

	computed: {
		chartOptions() {
			return {
				tooltip: {
					show: true,
					trigger: "axis",
					backgroundColor: "#103332",
					axisPointer: {
						crossStyle: {
							color: "red"
						}
					},
					padding: 8,
					formatter: (values) => {
						return this.showToolTip(values);
					}
				},
				color: ["#ea7125"],
				grid: {
					left: "48px",
					right: "74px",
					bottom: 82,
					containLabel: true
				},
				xAxis: [
					{
						type: "category",
						name: "Time",
						nameTextStyle: {
							color: "#717171",
							fontFamily: "TFSrivichai",
							fontWeight: "bold",
							fontSize: 20
						},
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.airFlows.reduce((_acc, _cur) => {
									_acc.push(_cur.timestamp);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})(),
						boundaryGap: false,
						axisPointer: {
							show: true,
							triggerTooltip: false,
							label: {
								show: false
							}
						},
						axisLabel: {
							margin: 8,
							fontSize: 14,
							show: true,
							formatter: (value) => {
								const startOfDate = dayjs(value)
									.startOf("day")
									.unix();
								const currentDate = dayjs(value).unix();
								return startOfDate === currentDate
									? dayjs(value).format("HH:mm\nD MMM")
									: dayjs(value).format("HH:mm");
							}
						},
						axisTick: {
							length: 4,
							lineStyle: {
								width: 1,
								color: "#586c63"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						}
					}
				],
				yAxis: [
					{
						name: this.tagName,
						type: "value",
						color: "#59e07a",
						nameTextStyle: {
							color: "#717171",
							width: "100px",
							fontFamily: "TFSrivichai",
							fontWeight: "bold",
							fontSize: 20,
							padding: [0, 0, 24, -24],
							align: "left"
						},
						axisLabel: {
							margin: 8,
							fontSize: 14,
							formatter: (value) => Numeral(value).format("0[.]0a")
						},
						splitNumber: 4,
						splitLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						},
						axisTick: {
							length: 4,
							lineStyle: {
								width: 1,
								color: "#586c63"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#cad9cf"
							}
						}
					}
				],
				axisLabel: {
					fontSize: 14,
					fontFamily: "TFSrivichai",
					color: "#103332"
				},
				dataZoom: [
					{
						type: "inside"
					},
					{
						show: true,
						realtime: true,
						start: 0,
						end: 25,
						type: "slider",
						textStyle: {
							fontFamily: "TFSrivichai",
							fontSize: 14,
							width: 50,
							height: 50
						},
						handleIcon:
              "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
						handleSize: "80%",
						dataBackground: {
							areaStyle: {
								color: "#8392A5"
							},
							lineStyle: {
								opacity: 0.8,
								color: "#8392A5"
							}
						},
						handleStyle: {
							color: "#fff",
							shadowBlur: 3,
							shadowColor: "rgba(0, 0, 0, 0.6)",
							shadowOffsetX: 2,
							shadowOffsetY: 2
						},
						labelFormatter: (value, date) => {
							return dayjs(date).format("HH.mm\nD MMM YYYY");
						}
					}
				],
				series: [
					{
						name: "Air Flow",
						type: "line",
						smooth: true,
						itemStyle: {
							color: "#009a44"
						},
						data: (() => {
							return this.data.reduce((acc, cur) => {
								const arr = cur.airFlows.reduce((_acc, _cur) => {
									_acc.push(_cur.airFlow);
									return _acc;
								}, []);
								acc.push(...arr);
								return acc;
							}, []);
						})()
					}
				]
			};
		}
	},

	methods: {
		showToolTip(values) {
			return `<div>
						<div class="bar-name">
							${dayjs(values[0].axisValue).format("HH.mm, D MMM YYYY")}
						</div>
						<div class="bar-value">
								${values[0].data ? numberFormat(values[0].data) : "N/A"} ${values[0].seriesName}
						</div>
					</div>`;
		}
	}
};
</script>
