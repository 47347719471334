<template>
	<div
		:class="[
			'control',
			controlOptions,
			errorClass,
			loadingClass, { 'in-line':inline },
			getMergeClass,
			{ 'max-length': maxLength }
		]"
		@click="$emit('click', value)"
	>
		<label v-if="isShowLabel" class="input-label">
			{{ label }}
		</label>
		<Cleave
			:class="['input is-rounded', inputOptions, getMergeClass, { rounded }]"
			:type="type"
			:placeholder="placeholder"
			:value="value"
			:disabled="disabled"
			:readonly="readonly"
			:maxLength="maxLength"
			:options="options"
			:ref="`cleave`"
			@input="handleInput"
			@keypress="handleKeyPress"
			@blur="$emit('blur', value)"
			@focus="$emit('focus', value)"
		/>
		<span v-if="iconLeft" class="icon is-left" @click="handleClickIconLeft">
			<FontAwesomeIcon v-if="iconLeft" :icon="['far', iconLeft]" />
		</span>
		<div class="container-icon-right">
			<span v-if="iconRight" class="icon is-right" @click="handleClickIconRight">
				<FontAwesomeIcon v-if="iconRight" :icon="['far', iconRight]" />
			</span>
			<span v-if="isLoading" class="icon loading is-right">
				<slot name="loading"><FontAwesomeIcon :icon="['fas', 'spinner']" /></slot>
			</span>
		</div>
		<span :class="['error-message', {'has-label': label !== ''}]" v-if="isShowErrorMessage">
			{{ errorMessage }}
		</span>
		<span class="max-length-message" v-if="isShowMaxLengthMessage">
			{{ lengthMessage }}/{{ maxLength }}
		</span>
	</div>

</template>

<script>
import Cleave from "vue-cleave-component";

export default {
	name: "BaseFormInput",

	components: {
		Cleave
	},

	props: {
		id: String,
		label: {
			type: String,
			default: ""
		},
		inline: Boolean,
		type: {
			type: String,
			default: "text"
		},
		placeholder: {
			type: String,
			default: ""
		},
		value: {
			type: String,
			default: ""
		},
		error: String,
		disabled: Boolean,
		autocomplete: String,
		iconLeft: String,
		iconRight: String,
		inputOptions: {
			type: Array,
			default: () => ([])
		},
		controlOptions: {
			type: Array,
			default: () => ([])
		},
		isError: {
			type: Boolean,
			default: false
		},
		errorMessage: {
			type: String,
			default: ""
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		merge: {
			type: String,
			default: ""
		},
		autofocus: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		maxLength: {
			type: Number,
			default: null
		},
		decimalScale: {
			type: Number,
			default: 0
		}
	},

	data() {
		return {
			options: {
				numeral: true,
				numeralPositiveOnly: true,
				rawValueTrimPrefix: true,
				numeralDecimalScale: this.decimalScale,
				onValueChanged: (e) => {
					return e;
				}
			}
		};
	},

	computed: {
		isShowLabel() {
			return this.label !== "";
		},
		isShowErrorMessage() {
			return this.isError && this.errorMessage;
		},
		isShowMaxLengthMessage() {
			return this.maxLength;
		},
		lengthMessage() {
			return (this.value && this.value.trim().length) || 0;
		},

		errorClass() {
			return {
				"is-error has-icons-right": this.isError
			};
		},

		getMergeClass() {
			if (this.merge === "right") {
				return "merge-right";
			} else if (this.merge === "left") {
				return "merge-left";
			}
			return false;
		},

		loadingClass() {
			return {
				"has-icons-right is-loading": this.isLoading
			};
		}
	},

	mounted() {
		const input = this.$refs.cleave;

		if (this.autofocus) {
			input.focus();
		}
	},

	methods: {
		handleInput(value) {
			this.$emit("input", value);
		},

		handleClickIconLeft(e) {
			this.$emit("onClickIconLeft", e);
		},

		handleClickIconRight(e) {
			this.$emit("onClickIconRight", e);
		},

		handleKeyPress(e) {
			const input = e.target.value || "";
			const isEmpty = input.trim().length === 0;
			if (e.code === "Space" && isEmpty) {
				e.preventDefault();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
// has-icons-left, has-icons-right
.input-wrapper {
	&.in-line {
		width: 100%;
		display: flex;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}
}

.input-label {
	font-size: $font-18;
}

.input {
	box-shadow: none;
	background-color: $color-grey-1;
	border: rem(1) solid $color-silver;
	color: $color-dark-blue-grey;
	max-width: 100%;
	width: 100%;
	display: block;
	padding: $control-padding-vertical $control-padding-horizontal;

	&:focus,
	&.is-focused,
	&:active,
	&.is-active {
		border: rem(1) solid $color-emerald;
	}

	&::placeholder {
		font-size: $font-18;
		color: $color-grey-4;
	}

	&[disabled] {
		background-color: $color-disabled-input;
		cursor: not-allowed;

		&:focus,
		&.is-focused,
		&:active,
		&.is-active {
			border: rem(1) solid $color-grey-3;
		}
	}

	&[readonly] {
		box-shadow: none;
	}

	&.is-rounded {
		border-radius: rem(4);
		padding-left: rem(16);
		padding-right: rem(16);
	}

	&.rounded {
		border-radius: $border-radius-normal;
	}

	&.is-static {
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
		padding-left: 0;
		padding-right: 0;
	}

	&.ghost {
		border: none;
		background: transparent;
	}

	&.small {
		height: rem(40);
	}

	&-filter {
		font-size: $font-18;
		height: rem(34);
	}

	&.merge {
		&-right {
			border-right: none;
			border-radius: 4px 0 0 4px;
		}

		&-left {
			border-left: none;
			border-radius: 0 4px 4px 0;
		}
	}
}

.icon {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	height: $icon-dimensions;
	width: $icon-dimensions;

	&.is-small {
		height: $icon-dimensions-small;
		width: $icon-dimensions-small;
	}

	&.is-medium {
		height: $icon-dimensions-medium;
		width: $icon-dimensions-medium;
	}

	&.is-large {
		height: $icon-dimensions-large;
		width: $icon-dimensions-large;
	}
}

.label {
	color: $color-red;
	display: block;
	font-size: $font-20;
	font-weight: 600;

	&:not(:last-child) {
		margin-bottom: 0.5em;
	}

	&.is-small {
		font-size: $font-16;
	}

	&.is-medium {
		font-size: $font-32;
	}

	&.is-large {
		font-size: $font-48;
	}
}

.field {
	&:not(:last-child) {
		margin-bottom: 0.75rem;
	}

	&.has-addons {
		display: flex;
		justify-content: flex-start;

		.control {
			&:not(:last-child) {
				margin-right: rem(-1);
			}

			&:not(:first-child):not(:last-child) {
				.button,
				.input,
				.select select {
					border-radius: 0;
				}
			}

			&:first-child:not(:only-child) {
				.button,
				.input,
				.select select {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}

			&:last-child:not(:only-child) {
				.button,
				.input,
				.select select {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
				}
			}

			.button,
			.input,
			.select select {
				&:not([disabled]) {
					&:hover {
						z-index: 2;
					}

					&:focus,
					&:active {
						z-index: 3;
					}
				}
			}

			&.is-hovered {
				z-index: 2;
			}

			&.is-active,
			&.is-focused {
				z-index: 3;
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		&.has-addons-centered {
			justify-content: center;
		}

		&.has-addons-right {
			justify-content: flex-end;
		}

		&.has-addons-fullwidth {
			.control {
				flex-grow: 1;
				flex-shrink: 0;
			}
		}
	}

	&.is-grouped {
		display: flex;
		justify-content: flex-start;

		& > .control {
			flex-shrink: 0;

			&:not(:last-child) {
				margin-bottom: 0;
				margin-right: 0.75rem;
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		&.is-grouped-centered {
			justify-content: center;
		}

		&.is-grouped-right {
			justify-content: flex-end;
		}

		&.is-grouped-multiline {
			flex-wrap: wrap;

			& > .control {
				&:last-child,
				&:not(:last-child) {
					margin-bottom: 0.75rem;
				}
			}

			&:last-child {
				margin-bottom: -0.75rem;
			}

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}

	&.is-horizontal {
		@include tablet {
			display: flex;
		}
	}
}

.field-label {
	.label {
		font-size: inherit;
	}

	@include mobile {
		margin-bottom: 0.5rem;
	}

	@include tablet {
		flex: 0 1 0;
		margin-right: 1.5rem;
		text-align: right;

		&.is-normal {
			padding-top: 0.375em;
		}

		&.is-small {
			// font-size: $size-small
			padding-top: 0.375em;
		}

		&.is-medium {
			// font-size: $size-medium
			padding-top: 0.375em;
		}

		&.is-large {
			// font-size: $size-large
			padding-top: 0.375em;
		}
	}
}

.field-body {
	.field .field {
		margin-bottom: 0;

		@include tablet {
			display: flex;
			flex: 1 5 0;

			.field {
				margin-bottom: 0;
			}

			& > .field {
				flex-shrink: 1;

				&:not(.is-narrow) {
					flex-grow: 1;
				}

				&:not(:last-child) {
					margin-right: 0.75rem;
				}
			}
		}
	}
}

.error-message {
	height: rem(20);
	position: absolute;
	bottom: rem(-20);
	font-size: $font-14;
	color: $color-red;
	font-weight: $font-weight-bold;

	&.has-label {
		margin-left: rem(130);
	}
}

.max-length-message {
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translateY(100%);
	font-size: $font-16;
	color: $color-grey-4;
}

.control {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	font-size: $font-20;
	clear: both;
	box-sizing: border-box;

	&.in-line {
		flex-direction: row;
		align-items: center;

		.input-label {
			min-width: rem(130);
		}
	}

	&.max-length {
		margin-bottom: rem(14);
	}

	&.has-icons-left,
	&.has-icons-right {
		.input,
		.select {
			&:focus {
				& ~ .icon {
					color: $color-dark-blue-grey;
				}
			}

			&.is-small ~ .icon {
				font-size: $font-16;
			}

			&.is-medium ~ .icon {
				font-size: $font-32;
			}

			&.is-large ~ .icon {
				font-size: $font-48;
			}
		}

		.container-icon-right {
			display: flex;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			padding-right: rem(16);
		}

		.icon {
			color: $color-gunmetal-two;
			height: 100%;
			position: absolute;
			top: 0;
			width: $input-height;
			z-index: 4;

			&.is-right {
				position: relative;
				width: rem(24);
				height: rem(24);
				margin-left: rem(6);

				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}

	&.has-icons-left {
		.input,
		.select select {
			padding-left: $input-height;
		}

		.icon.is-left {
			left: 0;
		}
	}

	&.has-icons-right {
		.input,
		.select select {
			padding-right: $input-height;
		}

		.icon.is-right {
			right: 0;
		}
	}

	&.is-error {
		.input {
			border-color: $color-red;
		}

		.input:focus ~ .icon, .icon {
			color: $color-red;
		}

		.icon:not(.error):not(.loading) {
			color: $color-gunmetal-two;
		}
	}

	&.is-loading {
		&::after {
			position: absolute;
			right: 0.625em;
			top: 0.625em;
			z-index: 4;
		}

		&.is-small::after {
			font-size: $font-16;
		}

		&.is-medium::after {
			font-size: $font-32;
		}

		&.is-large::after {
			font-size: $font-48;
		}

		.icon {
			animation-name: spin;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		.input:focus ~ .icon, .icon {
			color: $color-dark-blue-grey;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
</style>
