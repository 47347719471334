<template>
	<div
		ref="collapseItem"
		class="collapse-item"
	>
		<div
			:class="[
				'accordion',
				{ 'not-expanded' : !expanded },
				className
			]"
		>
			<div
				class="collapse-title"
				@click="toggleAccordionState()"
			>
				<div class="collapse-title-row">
					{{ title }}
				</div>
				<div class="collapse-title-row">
					<span
						v-if="subTitle"
						class="sub-title"
					>
						{{ subTitle }}
					</span>
					<div class="icon icon-chevron">
						<FontAwesomeIcon :icon="['far', toggleIcon]" />
					</div>
				</div>
			</div>
			<transition-collapse>
				<div
					v-if="expanded"
					class="collapse-body"
				>
					<slot />
				</div>
			</transition-collapse>
		</div>
	</div>
</template>
<style lang="scss">
	@import "../assets/styles/components/collapse.scss";
</style>
<script>
import TransitionCollapse from "@/components/Transitions/TransitionCollapse.vue";

export default {
	components: {
		TransitionCollapse
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		subTitle: {
			type: [String, Number],
			default: ""
		},
		className: {
			type: String,
			default: ""
		},
		isExpanded: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			expanded: true
		};
	},
	computed: {
		toggleIcon() {
			return this.expanded ? "chevron-up" : "chevron-down";
		}
	},
	created() {
		this.expanded = this.isExpanded;

		// Watch parent in each component
		this.$parent.$on("closeAllCollapse", this.closeCollapse);
	},
	methods: {
		toggleAccordionState() {
			this.$emit("checkCollapseOpen", this.expanded);
			if (this.expanded) {
				this.closeCollapse();

				return;
			}

			// Trigger parent closeCollapse
			this.$parent.$emit("closeAllCollapse");
			this.expanded = !this.expanded;
		},
		closeCollapse() {
			this.expanded = false;
		}
	}
};
</script>
