<template>
	<div class="container-filter">
		<div :class="['container-datepicker', ...wrapperClass]" v-click-outside="hideDatePicker">
			<BaseFormInput
				:value="displayDate"
				:readonly="true"
				:class="['custom-input', 'has-icons-left', size]"
				type="text"
				iconLeft="calendar"
				:placeholder="placeholder"
				@click="handleInputClick"
			/>
			<div v-if="isShowDatePicker" class="container-datepicker-control">
				<div class="container-datepicker-control-inner">
					<div class="container-datepicker-control-picker" @click="isClickedDate = true">
						<div v-if="false" class="over-lay-disbled"></div>
						<v-date-picker
							color="green"
							:mode="mode"
							v-model="dateInput"
							:min-date="minDateDisabled"
							:max-date="maxDateDisabled"
							:popover="{ placement: 'bottom', visibility: 'click' }"
							title-position="left"
							locale="en"
							is-inline
							is-expanded
							:is-range="isRange"
						/>
					</div>
					<div class="container-datepicker-control-dropdown">
						<div class="container-datepicker-control-dropdown-select">
							<button
								v-for="(item, index) in dateShortcutOptions"
								:key="index"
								:class="['btn', {'active': item.value === dateShortcut}]"
								@click="onClickOption($event, item)"
							>
								{{ item.text }}
							</button>
						</div>
						<BaseButton v-if="!frequency" size="small" rounded class="custom-done-btn" @click="onDone">
							Select
						</BaseButton>
					</div>
				</div>
				<div v-if="frequency" class="container-datepicker-control-frequency">
					<div class="radio-fields">
						<label for="">Frequency</label>
						<div
							v-for="({ value, label }, index) in frequencyTypeRadios"
							:key="index"
							class="tax-invoice-type"
						>
							<BaseRadioButton
								v-model="frequencyType"
								:index="index"
								:value="value"
								:label="label"
								@change="onChangeFrequencyType(value)"
							/>
						</div>
					</div>
					<div v-if="frequencyType === 'hourly'" class="warning-msg">
						<p><FontAwesomeIcon :icon="['far', 'exclamation-triangle']"/> Hourly data is available to display only latest 3 months and support only for Excel or CSV export.</p>
						<p>Please contact your BIG representative to request longer period.</p>
					</div>
					<BaseButton size="small" rounded class="custom-done-btn" @click="onDone">
						Select
					</BaseButton>
				</div>
			</div>
		</div>
		<BaseModalConfirm
			modal-name="confirm-export"
			:title="`Export Confirmation`"
			:message="`
					<div>
						<strong>Hourly data</strong> is available only for the <strong>latest 3 months</strong>.
						If you need a longer period, please contact your BIG representative.
					</div>
					<div>
						Do you want to proceed with the export?
					</div>
				`"
			:submitButtonText="`Yes, Export anyway`"
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
		/>
		<BaseDropdown
			:value="fileType"
			:list="fileTypeOptions"
			:searchable="false"
			placeholder="Select file type "
			class="container-dropdown"
			@input="onSelectFileType"
		/>
		<BaseButton size="medium" class="custom-export-btn" @click="onClickExport">
			Export
		</BaseButton>
	</div>
</template>

<script>
import dayjs from "dayjs";
import BaseRadioButton from "@/components/BaseRadioButton.vue";
import BaseModalConfirm from "@/components/BaseModalConfirm.vue";

export default {
	name: "BaseFilterExport",
	components: {
		BaseRadioButton,
		BaseModalConfirm
	},
	props: {
		size: {
			type: String,
			default: "medium",
			validator: (value) => {
				return [
					"small",
					"medium"
				].includes(value);
			}
		},
		mode: {
			type: String,
			default: "single",
			validator: (value) => {
				return [
					"single",
					"multiple",
					"range"
				].includes(value);
			}
		},
		clearable: {
			type: Boolean,
			default: false
		},
		autohide: {
			type: Boolean,
			default: false
		},
		position: {
			type: String,
			default: "left",
			validator: (value) => {
				return [
					"left",
					"right"
				].includes(value);
			}
		},
		frequency: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			dateInput: {
				start: dayjs().subtract(1, "month").startOf("month").toDate(),
				end: dayjs().subtract(1, "month").endOf("month").toDate()
			},
			isShowDatePicker: false,
			isClickedDate: false,
			dateShortcut: null,
			dateShortcutOptions: [
				{
					text: "Custom",
					value: null
				},
				{
					text: "Last 1 month",
					value: "last_1_month"
				},
				{
					text: "Last 3 months",
					value: "last_3_months"
				},
				{
					text: "Last 6 months",
					value: "last_6_months"
				},
				{
					text: "Last 1 year",
					value: "last_1_year"
				}
			],
			fileType: "xlsx",
			fileTypeOptions: [
				{
					label: ".csv",
					value: "csv"
				},
				{
					label: ".xlsx",
					value: "xlsx"
				}
			],
			frequencyTypeRadios: [
				{
					value: "hourly",
					label: "Hourly"
				},
				{
					value: "daily",
					label: "Daily"
				}
			],
			frequencyType: "hourly"
		};
	},

	computed: {
		wrapperClass() {
			return [
				this.positionClass
			];
		},

		positionClass() {
			return `position-${this.position}`;
		},

		placeholder() {
			let str = "";

			if (this.mode === "single") {
				str = "Select date";
			} else if (this.mode === "multiple") {
				str = "Select date multiple";
			} else {
				str = "Select date range";
			}

			return str;
		},

		isRange() {
			return this.mode === "range";
		},

		displayDate() {
			const f = "DD/MM/YYYY";
			const date = this.dateInput;
			let str = "";

			if (this.mode === "single") {
				str = date
					? `${dayjs(date).format(f)}`
					: "";
			} else if (this.mode === "multiple") {
				str = date
					? date.map((d) => dayjs(d).format(f)).join(", ")
					: "";
			} else {
				str = date
					? `${dayjs(date.start).format(f)} - ${dayjs(date.end).format(f)}`
					: "";
			}

			return str;
		},

		datePickerDisabled() {
			const isCustom = this.dateShortcut === null;
			return !isCustom;
		},

		minDateDisabled() {
			return dayjs().subtract(3, "year").$d;
		},

		maxDateDisabled() {
			return dayjs().subtract(1, "day").toDate();
		}
	},

	watch: {
		dateInput() {
			if (this.autohide) {
				this.isShowDatePicker = false;
			}
			if (this.isClickedDate && this.dateShortcut) {
				this.dateShortcut = null;
			}
		}
	},

	methods: {
		hideDatePicker() {
			this.isShowDatePicker = false;
		},
		handleInputClick() {
			this.isShowDatePicker = !this.isShowDatePicker;
		},
		onDone() {
			this.isShowDatePicker = false;
		},
		onClear() {
			this.dateInput = null;
		},
		onClickOption(event, item) {
			this.isShowDatePicker = false;
			this.dateShortcut = item.value;
			this.isClickedDate = false;
			switch (this.dateShortcut) {
				case null:
					this.dateInput = null;
					break;
				case "last_1_month":
					this.dateInput = {
						start: dayjs().subtract(1, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				case "last_3_months":
					this.dateInput = {
						start: dayjs().subtract(3, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				case "last_6_months":
					this.dateInput = {
						start: dayjs().subtract(6, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				case "last_1_year":
					this.dateInput = {
						start: dayjs().subtract(1, "year").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
					break;
				default:
			}
			setTimeout(() => {
				this.isShowDatePicker = true;
			}, 10);
		},
		onSelectFileType(input) {
			this.fileType = input;
		},
		onChangeFrequencyType(value) {
			this.frequencyType = value;
		},
		onClickExport() {
			const date = this.dateInput;
			const todayDate = dayjs();
			const lastestMonth = todayDate.diff(date.start, "month", true);

			const form = {
				dateRange: {
					start: this.dateInput?.start || null,
					end: this.dateInput?.end || null
				},
				fileType: this.fileType,
				frequencyType: this.frequencyType
			};

			// select dropdown 6 months and one year with hourly
			if ((this.dateShortcut === "last_6_months" || this.dateShortcut === "last_1_year") && this.frequency) {
				if (this.frequencyType === "hourly") {
					this.openModalConfirm(form);
					return;
				}
			}

			// select date range older than 3 months and within 3 months
			if (this.isClickedDate && this.frequency) {
				if (lastestMonth > 3 && this.frequencyType === "hourly") {
					this.openModalConfirm(form);
					return;
				}
			}

			this.$emit("onSubmit", form);
		},
		handleConfirm(form) {
			this.closeModalConfirm();
			this.$emit("onSubmit", form);
		},
		handleCancel() {
			this.closeModalConfirm();
		},
		openModalConfirm(form) {
			this.$modal.show("confirm-export", form);
		},
		closeModalConfirm() {
			this.$modal.hide("confirm-export");
		}
	}
};
</script>

<style lang="scss" scoped>
.container-filter {
	display: flex;
}

.container-datepicker {
	position: relative;
	width: rem(236);

	&.position-left > &-control {
		left: 0;
	}

	&.position-right > &-control {
		right: 0;
	}

	&-control {
		display: flex;
		flex-direction: column;
		position: absolute;
		z-index: 5;
		bottom: rem(-8);
		transform: translateY(100%);
		border-radius: rem($border-radius-normal);
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
		background-color: $color-white;
		overflow: hidden;
		padding: 0 rem(8);

		@include mobile {
			width: 100%;
		}

		&-inner {
			position: relative;
			display: flex;
			max-width: rem(436);
			width: 100%;

			@include mobile {
				flex-direction: column;
			}

			.over-lay-disbled {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background: rgba(255, 255, 255, 0.5);
			}

			.vc-container {
				background: none;
				border: none;

				/* stylelint-disable */
				&::v-deep .vc-nav-header {
					.vc-nav-title {
						color: $color-mirage;

						&:hover {
							background-color: var(--gray-900);
							color: $color-white;
							border-color: transparent;
						}
					}
				}

				&::v-deep .vc-day {
					&-layer {
						.vc-highlight {
							&.vc-bg-blue-600 {
								background-color: $color-green-haze;
							}

							&.vc-border-blue-700 {
								border-color: $color-green-haze;
							}

							&-base {
								&-start, &-middle, &-end {
									background-color: $color-ice;
								}
							}
						}
					}

					&-content {
						@include fontBase();

						font-weight: normal;
						font-size: $font-20;

						&:first-child {
							&:hover {
								background-color: $color-ice;
							}
						}
					}
				}

				&::v-deep .is-today .vc-day-content {
					border: 1px solid $color-green-haze;
				}

				&::v-deep .vc-pane {
					.vc-title {
						@include fontSub();

						padding-right: rem(20);
						color: $color-dark-blue-grey;
						font-size: $font-20;
						font-weight: $font-weight-bold;

						&::after {
							content: " ";
							position: absolute;
							top: 8%;
							margin-left: rem(4);
							width: 0;
							height: 0;
							border-left: rem(5) solid transparent;
							border-right: rem(5) solid transparent;
							border-top: rem(5) solid $color-cerulean;
						}
					}
				}

				&::v-deep .vc-arrows-container {
					svg {
						color: $color-cerulean;
					}
				}

				&::v-deep .vc-weekday {
					@include fontBase();

					font-size: $font-16;
					font-weight: $font-weight-bold;
					color: $color-grey-4;
				}

				&::v-deep .vc-popover-content {
					background-color: $color-white;
					color: $color-mirage;

					.vc-grid-cell {
						.vc-nav-title {
							color: $color-mirage;
						}

						span.vc-grid-focus:not(.vc-nav-title):not(.vc-nav-arrow) {
							background-color: $color-green-haze;
							color: $color-white;
							border: none;
						}

						span {
							color: $color-mirage;

							&:hover {
								background-color: $color-ice;
								color: $color-mirage;
							}
						}
					}

					.vc-nav-arrow {
						&:hover {
							background-color: var(--gray-200);
						}

						&:focus {
							border-color: transparent;
						}

						.vc-svg-icon {
							path {
								fill: $color-cerulean;
							}
						}
					}
				}

				&::v-deep .vc-grid-cell {
					&-col {
						&-1, &-7 {
							.vc-day-content {
								color: $color-grey-4;
							}
						}

						&-2, &-3, &-4, &-5, &-6 {
							.vc-day-content {
								color: $color-dark-blue-grey;
							}
						}
					}

					.vc-day-content {
						&.vc-text-white {
							color: $color-white;
						}

						&.vc-text-gray-400 {
							color: #cbd5e0;
							cursor: not-allowed;
						}
					}
				}

				&::v-deep .vc-nav-item {
					&.is-current {
						color: var(--green-900);

						&:hover {
							&.is-current {
								color: $color-white;
								border-color: var(--gray-900);
							}
						}
					}
				}
				/* stylelint-enable */
			}
		}

		&-dropdown {
			position: relative;
			width: rem(152);
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: rem(16) 0 rem(16) rem(8);
			margin: 0 rem(8);

			@include mobile {
				flex-direction: row;
				order: 1;
				margin: 0;
				padding: rem(16) rem(8);
				width: 100%;
			}

			&::before {
				content: "";
				position: absolute;
				width: 1px;
				height: calc(100% - 32px);
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background: #cad9cf;

				@include mobile {
					display: none;
				}
			}

			&-select {
				flex: 1;
				width: 100%;
				display: flex;
				flex-direction: column;

				@include mobile {
					flex-flow: wrap;
					flex-direction: row;
				}

				.btn {
					width: 100%;
					background: none;
					border: none;
					outline: none;
					color: $color-dark-blue-grey;
					padding: rem(2) 16px;
					box-sizing: border-box;
					border-radius: rem(4);
					margin-bottom: rem(6);
					text-align: left;
					font-size: $font-18;

					@include mobile {
						width: 50%;
					}

					&:last-of-type {
						margin-bottom: 0;

						@include mobile {
							margin-bottom: rem(6);
						}
					}

					&.active {
						background-color: $color-ice;
						font-weight: $font-weight-bold;
					}
				}
			}

			.custom-done-btn {
				width: rem(64);
				margin-top: rem(10);
			}
		}

		&-frequency {
			border-top: rem(1) solid $color-silver;
			display: flex;
			flex-direction: column;
			margin: 0 rem(4);
			padding: rem(16) 0;

			@include mobile {
				order: 3;
			}

			label {
				margin-right: rem(24);
			}

			.radio-fields {
				display: flex;
				margin-bottom: rem(16);
			}

			.custom-done-btn {
				margin: 0 auto;
				max-width: rem(160);
				width: 100%;
			}
		}

		&-picker {
			@include mobile {
				order: 2;
			}
		}
	}

	/* stylelint-disable */
	.custom-input {
		cursor: pointer;

		&.small {
			&::v-deep .input {
				height: rem(32);
				line-height: rem(32);
				padding: rem(1) rem(10) 0 rem(40);
			}
		}

		&.medium {
			&::v-deep .input {
				height: rem(40);
				line-height: rem(40);
				padding: rem(2) rem(10) 0 rem(40);
			}
		}

		&::v-deep .input {
			background-color: $color-alabaster;
			border-radius: $border-radius-normal 0 0 $border-radius-normal;
			cursor: pointer;
		}

		&::v-deep .is-left {
			width: rem(40);

			svg {
				width: rem(13);
				height: rem(16);
				color: $color-dark-blue-grey;
			}
		}
	}
	/* stylelint-enable */
}

.container-dropdown {
	width: rem(138);

	/* stylelint-disable */
	&::v-deep .dropdown-value {
		background-color: $color-alabaster;
		border-radius: 0;
		border-left: none;
	}
	/* stylelint-enable */
}

.custom-export-btn {
	border-radius: 0 $border-radius-normal $border-radius-normal 0;
}

.warning-msg {
	p {
		color: $color-mustard;
		font-size: $font-16;
		font-weight: bold;
		margin-top: 0;
	}
}
</style>