import Numeral from "numeral";
import { transformPieChart } from "..";

const userTypeColorConfig = {
	staff: "#30c262",
	customer: "#00a8e1"
};
export const trasnformStaffDataBarCharts = (data) => {
	const getTotalUser = data.totalStaff + data.totalCustomer;
	const yAxis = [
		{
			name: "userType",
			type: "pie",
			radius: ["50%", "70%"],
			center: ["50%", "40%"],
			avoidLabelOverlap: true,
			label: {
				show: true,
				position: "center",
				formatter: `{total|${Numeral(getTotalUser).format("0,0")}}\n{describe|Users}`,
				rich: {
					total: {
						fontSize: 48,
						color: "#103332",
						fontFamily: "DBHeavent"
					},
					describe: {
						fontSize: 20,
						color: "#586c63",
						fontWeight: "lighter",
						fontFamily: "DBHeavent"
					}
				}
			},
			labelLine: {
				show: false
			},
			data: [
				{
					value: data.totalStaff,
					name: "BIG Staff",
					itemStyle: {
						color: data.totalStaff === 0 ? "#cad9cf" : userTypeColorConfig.staff
					},
					tooltip: {
						show: data.totalStaff !== 0,
						formatter: ({ data: StaffData }) => `
						<div class="staff-role-over-all">
							<div class="role-name">${StaffData.name}</div>
							<div class="role-value">${StaffData.value}</div>
						</div>
						`
					}
				},
				{
					value: data.totalCustomer,
					name: "Customer",
					itemStyle: {
						color: data.totalCustomer === 0 ? "#cad9cf" : userTypeColorConfig.customer
					},
					tooltip: {
						show: data.totalCustomer !== 0,
						formatter: ({ data: StaffData }) => `
						<div class="staff-role-over-all">
							<div class="role-name">${StaffData.name}</div>
							<div class="role-value">${StaffData.value}</div>
						</div>
						`
					}
				}
			]
		}
	];

	return {
		yAxis
	};
};

export const options = (userData) => {
	const getTotalUser = userData.totalStaff + userData.totalCustomer;



	const userObjectPercentage = {
		"BIG Staff": (userData.totalStaff / getTotalUser) * 100 || 0,
		Customer: (userData.totalCustomer / getTotalUser) * 100 || 0
	};

	return {
		legend: {
			data: ["BIG Staff", "Customer"],
			formatter: (name) => {
				return 	`${name} {percentage|${Numeral(userObjectPercentage[name] / 100).format("0.00%")}}`;
			},
			icon: "circle",
			bottom: -10,
			left: 24,
			itemWidth: 10,
			itemHeight: 10,
			itemGap: 24,
			textStyle: {
				rich: {
					percentage: {
						color: "#103332",
						fontSize: 20,
						fontWeight: 600,
						fontFamily: "DBHeavent"
					}
				}

			}
		}
	};
};

export const transformUserTypesPieChart = (totalStaffs = {
	totalStaff: 500,
	totalCustomer: 0
}) => {
	const data = trasnformStaffDataBarCharts(totalStaffs);
	const pieChartOptions = options(totalStaffs);
	return transformPieChart(pieChartOptions)(data);
};
