<template>
	<modal :name="modalName" width="496" height="auto" @before-open="beforeOpen">
		<BaseDialogContent
			:title="title"
			:message="message"
			:text-submit="textSubmit"
			theme="danger"
			@onCancel="handleCancel"
			@onSubmit="handleSubmit"
		/>
	</modal>
</template>

<script>
import BaseDialogContent from "@/components/BaseDialogContent.vue";

export default {
	name: "ModalLayoutDelete",

	components: {
		BaseDialogContent
	},

	props: {
		modalName: {
			type: String,
			required: true
		},

		title: {
			type: String,
			required: true,
			default: "Delete this layout?"
		},

		message: {
			type: String,
			required: true,
			default: "Are you sure to delete this layout? All assigned devices will be detached from this layout."
		},

		textSubmit: {
			type: String,
			default: "Yes, delete"
		}
	},

	data() {
		return {
			dataId: ""
		};
	},

	methods: {
		handleCancel() {
			this.$modal.hide(this.modalName);
			this.$emit("onCancel");
		},

		beforeOpen(event) {
			this.dataId = event.params.dataId;
		},

		handleSubmit() {
			this.$modal.hide(this.modalName);
			this.$emit("onSubmit", { dataId: this.dataId });
		}
	}
};
</script>

<style lang="scss" scoped>
.footer-link {
	margin-right: $spacing-5;
	color: $color-cerulean;
	font-weight: $font-weight-bold;
	font-size: $font-18;
	cursor: pointer;
}
</style>