<template>
	<div :class="['tablet-account', ...wrapperClass]">
		<div class="container-close">
			<button
				class="button-close"
				@click="toggleUITabletAccount"
			>
				<FontAwesomeIcon :icon="['far', 'times']" class="icon" />
			</button>
		</div>
		<div class="container-profile">
			<div class="avatar">
				<FontAwesomeIcon :icon="['fas', 'user']" class="icon" />
			</div>
			<div class="info">
				<p class="name">
					{{ userFullName }}
				</p>
				<p class="role">
					{{ userRoleName }}
				</p>
			</div>
		</div>
		<hr />
		<div class="container-action">
			<ul class="container-action-list">
				<li class="container-action-item">
					<button @click="showModal(MODAL_NAME_USER_PROFILE)">
						My profile
					</button>
				</li>
				<li v-if="userTypeCustomer" class="container-action-item">
					<button @click="showModal(MODAL_NAME_CHANGE_PASSWORD)">
						Change password
					</button>
				</li>
				<li class="container-action-item">
					<a
						class="logout-button"
						@click="getLogout"
					>
						Logout
					</a>
				</li>
			</ul>
		</div>
		<ModalUserProfile :modal-name="MODAL_NAME_USER_PROFILE"/>
		<ModalChangePasssord :modal-name="MODAL_NAME_CHANGE_PASSWORD"/>
	</div>
</template>

<script>
import ModalUserProfile from "@/components/userprofile/ModalUserProfile.vue";
import ModalChangePasssord from "@/components/changepassword/ModalChangePassword.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { USER_TYPE } from "../../enums/user";

export default {
	name: "TableAccount",

	components: {
		ModalUserProfile,
		ModalChangePasssord
	},

	data() {
		return {
			MODAL_NAME_USER_PROFILE: "modal-user-profile",
			MODAL_NAME_CHANGE_PASSWORD: "modal-change-password"
		};
	},

	computed: {
		...mapState(["sidebar", "user"]),

		...mapGetters({
			getProfile: "user/getProfile"
		}),

		wrapperClass() {
			return { show: this.isUITabletAccountOpen };
		},

		isUITabletAccountOpen() {
			return this.sidebar.isUITabletAccountOpen;
		},

		userRoleName() {
			return this.getProfile.roleName;
		},

		userFullName() {
			return this.getProfile.fullName;
		},

		logoutAzureUrl() {
			const azureEndPoint = process.env.VUE_APP_AZURE_AUTHORIZE_ENDPOINT;
			const loginRoute = process.env.VUE_APP_AZURE_LOGIN;
			return `${azureEndPoint}/logout?post_logout_redirect_uri=${loginRoute}`;
		},

		userType() {
			return this.getProfile.type;
		},
		userTypeCustomer() {
			return this.userType === USER_TYPE.CUSTOMER;
		}
	},

	methods: {
		...mapActions({
			toggleUITabletAccount: "sidebar/toggleUITabletAccount",
			addActivityLogMeView: "activityLogAdd/addActivityLogMeView",
			handleLogout: "user/getLogout"
		}),

		showModal(MODAL_NAME) {
			if (MODAL_NAME === this.MODAL_NAME_USER_PROFILE) {
				this.addActivityLogMeView();
			}

			this.$modal.show(MODAL_NAME);
		},

		async getLogout() {
			await this.handleLogout();

			if (this.userType === USER_TYPE.STAFF) {
				window.location.href = this.logoutAzureUrl;
			} else {
				window.location.href = "/login";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.tablet-account {
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: $z-index-tablet-account;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: rem(24);
	background-color: $sidebar-bg-color;
	opacity: 0;
	visibility: hidden;
	transition: $transition-fadeout-fastest;

	&.show {
		opacity: 1;
		visibility: visible;
		transition: $transition-fadein-fastest;
	}

	.logout-button {
		cursor: pointer;
	}

	.container {
		&-close {
			display: flex;
			justify-content: flex-end;

			.button-close {
				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(20);
				height: rem(20);
				background: none;
				border: none;
				color: $sidebar-menu-color;
			}
		}

		&-profile {
			display: flex;
			align-items: center;

			.avatar {
				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(48);
				height: rem(48);
				margin-right: rem(8);
				background-color: $color-silver;
				border-radius: 50%;

				.icon {
					height: rem(26);
					color: $sidebar-menu-color;
				}
			}

			.info {
				display: flex;
				flex-direction: column;
				flex: 1;

				p {
					margin: 0;
				}

				.name {
					color: $color-dark-blue-grey;
					font-weight: $font-weight-bold;
				}

				.role {
					color: $color-grey-4;
					font-size: $font-16;
				}
			}
		}

		&-action {
			&-list {
				display: flex;
				flex-direction: column;
			}

			&-item {
				margin-bottom: rem(24);

				&:last-of-type {
					margin-bottom: 0;
				}

				button, a {
					background: none;
					border: none;
					font-weight: $font-weight-bold;
					color: $color-cerulean;
				}
			}
		}
	}

	hr {
		height: rem(1);
		margin: rem(24) 0;
		background: $color-silver;
		border: none;
	}
}
</style>