import { mapState } from "vuex";
import { MASTERDATA_PERMISSIONS } from "../enums/masterData";

export default {
	computed: {
		...mapState(["user"]),
		permissions() {
			return {
				dashboard: {
					read: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.DashboardRead),
					exportable: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.ConsumptionExport),
					approval: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.ConsumptionApprovalManage)
				},
				diagram: {
					read: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.FlowDiagramRead),
					manage: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.FlowDiagramManage)
				},
				invoice: {
					read: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.InvoiceRead)
				},
				eOrdering: {
					read: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.EOrderingRead),
					exportable: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.EOrderingExport),
					manage: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.EOrderingManage)
				},
				powerBI: {
					read: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.PowerBIDevicesRead),
					manage: this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.PowerBIExecutiveRead)
				}
			};
		}
	}
};