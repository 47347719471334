import httpClient from "./httpClient";

const TANKS_END_POINT = "/tanks";
const getTanksInfoAPI = (id) => httpClient.get(`${TANKS_END_POINT}/${id}`);
const getTanksLevelAPI = (id, params = {}) => httpClient.get(`${TANKS_END_POINT}/${id}/levels`, { params });
const getTanksGasConsumptionAPI = (id, params = {}) => httpClient.get(`${TANKS_END_POINT}/${id}/gas-consumption`, { params });
const getTanksGasConsumptionRateAPI = (id, params = {}) => httpClient.get(`${TANKS_END_POINT}/${id}/gas-consumption-rate`, { params });
const getTankMonthlyAPI = (id) => httpClient.get(`${TANKS_END_POINT}/${id}/deliveries`);
const getTankHistoryAPI = (id, params = {}) => httpClient.get(`${TANKS_END_POINT}/${id}/order-histories`, { params });
const getTripInfoAPI = (id) => httpClient.get(`${TANKS_END_POINT}/${id}/trip-infos`);
const getTanksConsumptionHistoryAPI = (id, params) => httpClient.get(`${TANKS_END_POINT}/${id}/consumption-history`, { params });

export {
	getTanksInfoAPI,
	getTanksLevelAPI,
	getTanksGasConsumptionAPI,
	getTanksGasConsumptionRateAPI,
	getTankMonthlyAPI,
	getTankHistoryAPI,
	getTripInfoAPI,
	getTanksConsumptionHistoryAPI
};
